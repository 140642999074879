import React,{Component} from 'react';
import "./EmailConfirmationComponent.css";
import reject_email from "../../Assets/images/reject_email_invite.svg";
import { withRouter } from 'react-router-dom';
import { AxiosInstance } from '../../Utilities';
class EmailConfirmationFailComponent extends Component {
    constructor(props){
        super(props);
        this.state={
            id:""
        }
    }
    UNSAFE_componentWillMount(){
      const {id} = this.props.match.params;
      this.setState({
          id
      })
    }
    componentDidMount(){
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
        ? 6013
        : "event",
      "application/json") 
      .post("/event/ccemailAcceptReject",{
          data:this.state.id
      })
      .then(resp=>{
          var response = resp && resp.data;
          if (
             response &&
             (response.status === "200" || response.status === 200)
           ) {
           console.log("suucess");
         } 
         else if (
             response &&
             (response.status === "400" || response.status === 400)
           ) {
           console.log("something went wrong")
         }
      }).catch((err) => {
         var error = err.response;
         if (error && (error.status === "403" || error.status === 403)) {
           localStorage.clear();
           setTimeout(() => {
             window.location.href = "/";
           }, 500);
         } else if (
           error &&
           (error.status === "500" || error.status === 500)
         ) {
             console.log("internal server error")
            }
       })
    }
    render(){
    return (
        <div className="accept_email_invite">
            <img src={reject_email} />
            <h1>Rejected Event Invite!</h1>
            <p>You have rejected this event invite.</p>
        </div>
    );
}
}
 
export default withRouter(EmailConfirmationFailComponent);