import React from "react";
import { Grid } from "@material-ui/core";
import notification_icon from "../../../Assets/images/Icon ionic-md-information-circle.svg";
import plan_image from "../../../Assets/images/plan_image.svg";
import momentTimeZone from "moment-timezone";

const BillingEstimateComponent = (props) => {
  return (
    <div className="billing_page billing_history">
      <h3 className="billing_page_title">Bill estimate</h3>
      <div className="billing_estimate_container">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div className="billing_estimate_section billing_estimate_main">
              <p className="billing_estimate_time">
                {props.estimateData.startDate?momentTimeZone(props.estimateData.startDate)
                  .tz(props.timeZoneName)
                  .format("MMM DD, YYYY"):'-'}
                 &nbsp;-&nbsp; 
                {props.estimateData.endDate?momentTimeZone(props.estimateData.endDate)
                  .tz(props.timeZoneName)
                  .format("MMM DD, YYYY"):'-'}
              </p>
              <div className="billing_estimate_subscription_container">
                <img src={notification_icon} alt="important_img" />
                <p>
                  To make changes to your product subscriptions, go to{" "}
                  <span>Manage subscriptions</span>
                </p>
              </div>
              <div className="billing_estimate_plan_container">
                <img src={plan_image} alt="plan_img" />
                <div>
                  <h4 className="billing_estimate_plan_name">
                    {props.estimateData.plan?props.estimateData.plan:'-'}
                  </h4>
                  <h6 className="billing_estimate_plan_period">
                    {props.estimateData.usersCount?props.estimateData.usersCount:0} User / {props.estimateData.billingCycle === 0?'Monthly':'Yearly'}
                  </h6>
                </div>
                <p className="billing_estimate_plan_price">
                  ${props.estimateData.planCharge?parseFloat(props.estimateData.planCharge).toFixed(2):0.00}
                </p>
              </div>
              {/* <div className="billing_estimate_tax_container">
                <h6>Tax ({props.estimateData.taxPercent?props.estimateData.taxPercent:0})%</h6>
                <p className="billing_estimate_plan_price">
                  USD {props.estimateData.taxAmount?parseFloat(props.estimateData.taxAmount).toFixed(2):0.00}
                </p>
              </div> */}
              <div className="billing_estimate_total">
                <h5>
                  Total due{" "}
                  {props.estimateData.endDate?momentTimeZone(props.estimateData.endDate)
                    .tz(props.timeZoneName)
                    .format("MMM DD, YYYY"):'-'}
                </h5>
                <h5>USD {props.estimateData.estimatedAmt?parseFloat(props.estimateData.estimatedAmt).toFixed(2):0.00}</h5>
              </div>
              <p className="billing_estimate_message">
                Based on your current plan, you are being automatically charged
                $29.00
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="billing_estimate_section billing_estimate_contact">
              <h5>Bill estimate</h5>
              <p>FAQ</p>
              <p>Contact us about your bill</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BillingEstimateComponent;
