
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import momentTimeZone from "moment-timezone";
import moment from 'moment';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import React from "react";
import { TextField, withStyles } from '@material-ui/core';
export const getGooglePlaceObjectValues = (place) => {
  var placeObj = {
    address: place.formatted_address,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng()
  }
  return placeObj;
}
export const getCity = (addressArray) => {
  let city = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
      city = addressArray[i].short_name;
      return city;
    }
  }
};
export const getZipcode = (addressArray) => {
  let zipcode = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
      zipcode = addressArray[i].short_name;
      return zipcode;
    }
  }
};

export const getArea = (addressArray) => {
  let area = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
          area = addressArray[i].short_name;
          return area;
        }
      }
    }
  }
};

export const getState = (addressArray) => {
  let state = '';
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
        state = addressArray[i].short_name;
        return state;
      }
    }
  }
};

export const onGetDirectionHandler = (geomettry) => {
  window.open("https://www.google.com/maps/dir/?api=1&destination=" + geomettry.lat + "," + geomettry.lng);
};

export const exportToCSV = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'Team Data': ws }, SheetNames: ['Team Data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}
export const fetchDateFilterData = (item, timeZoneName) => {
  var data = {};
  if (item._id === "") {

    data.startDate = moment.tz(momentTimeZone().startOf("week").format("YYYY-MM-DD 00:00:00"), timeZoneName).utc();
    data.endDate = moment.tz(momentTimeZone().endOf("week").format("YYYY-MM-DD 23:59:59"), timeZoneName).utc();

  } else if (item._id === "last7days") {

    data.startDate = moment.tz(momentTimeZone().subtract(6, "d").format("YYYY-MM-DD 00:00:00"), timeZoneName).utc();
    data.endDate = moment.tz(momentTimeZone().format("YYYY-MM-DD 23:59:59"), timeZoneName).utc();

  } else if (item._id === "month") {
    data.startDate = moment.tz(momentTimeZone().subtract(29, "d").format("YYYY-MM-DD 00:00:00"), timeZoneName).utc();
    data.endDate = moment.tz(momentTimeZone().format("YYYY-MM-DD 23:59:59"), timeZoneName).utc();

  } else if (item._id === "quarter") {

    data.startDate = moment.tz(momentTimeZone().startOf("quarter").format("YYYY-MM-DD 00:00:00"), timeZoneName).utc();
    data.endDate = moment.tz(momentTimeZone().endOf("quarter").format("YYYY-MM-DD 23:59:59"), timeZoneName).utc();

  } else if (item._id === "year") {
    // data.startDate = momentTimeZone(momentTimeZone()
    //   .tz(timeZoneName)
    //   .format("YYYY-01-01 00:00:00")).utc();
    // data.endDate = momentTimeZone(momentTimeZone(momentTimeZone()
    //   .tz(timeZoneName)
    //   .endOf("year")._d).format("YYYY-MM-DD 23:59:59")).utc();
    data.startDate = moment.tz(momentTimeZone().startOf("year").format("YYYY-MM-DD 00:00:00"), timeZoneName).utc();
    data.endDate = moment.tz(momentTimeZone(moment().endOf("year")._d).format("YYYY-MM-DD 23:59:59"), timeZoneName).utc();
  }
  return data;
};

export const valid = (current) => {
  return current.isAfter(moment().subtract(1, "day"));
};
/** to disable previous date based on startDate */
export const disabledDates = (current, startDate) => {
  return current.isAfter(moment(startDate).subtract(1, "day"));
};

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});
export const TransitionGrow = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField);