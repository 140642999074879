import React, { Component, Suspense } from "react";
import {
  Grid, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Button,
} from "@material-ui/core";
import profile_img from "../../../Assets/images/dummy-profile-image.png";
import momentTimeZone from "moment-timezone";
import { upperFirst } from "lodash";
import Loader from "react-loader-spinner";
import Pagination from '@material-ui/lab/Pagination';
import carret_down from "../../../Assets/images/carret_down.svg";
import download_img from "../../../Assets/images/download_action.svg";
var CustomSelectWithSearchComponent = React.lazy(() =>
  import(
    "../../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
  )
);
const BillingSubscriptionDetails = (props) => {
  return (
    <div className="billing_page billing_overview billing_overview_container">
      <div className="billing_heading_section" >
        <h3 className="billing_page_title">Subscription Details</h3>


        <Suspense fallback={<div></div>}>

          <div
            className="new_custom_filter_div"
            onClick={props.openAccountFilterMenu}
          >

            <label className="leftName">Billing Contacts: </label>

            <label
              aria-label="account_filter"
              aria-controls="account_filter_select"
              area-aria-haspopup="true"
              className="rightName"
            >
              {upperFirst(props.accountName)}
            </label>
            <img className="carret" src={carret_down} alt="carret" />
          </div>
          <CustomSelectWithSearchComponent
            filterArray={props.accountOption}
            config={props.accountConfig.config}
            anchorEl={props.accountConfig.anchorElAccount}
            searchValue={props.searchAccountValue}
            handleClick={props.handleAccountSelect}
            closeCustomPopup={props.closeAccountFilterMenu}
            onSearchFilter={props.onAccountSearchFilter}
            placeholder="Search Billing Contacts"
          />
        </Suspense>
      </div>
      <div className="billing_overview_sections">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <div className="billing_overview_section">
              <h5 className="overview_section_heading">Billing Contacts</h5>
              <div className="bill_contact_container">
                <div className="bill_contact_image_section">
                  <img src={profile_img} alt="profile-image" className="bill_contact_profile" />
                </div>
                <div className="bill_contact_content_section">
                  <h4 className="bill_contact_username"> {props.Sub_contact_Details.firstName
                    ? props.Sub_contact_Details.firstName.charAt(0).toUpperCase() +
                    props.Sub_contact_Details.firstName.slice(1) : "-"} {props.Sub_contact_Details.lastName
                      ? props.Sub_contact_Details.lastName.charAt(0).toUpperCase() +
                      props.Sub_contact_Details.lastName.slice(1) : ""}
                  </h4>
                  <h6 className="bill_contact_email">{props.Sub_contact_Details.email ? props.Sub_contact_Details.email : "--"} </h6>
                </div>
              </div>
              <div className="bill_contact_details">
                <h4 className="bill_contact_heading">Phone</h4>
                <p>{props.Sub_contact_Details.phone ? props.Sub_contact_Details.phone : "--"}</p>
                <h4 className="bill_contact_heading">Company name</h4>
                <p>{props.Sub_contact_Details.companyName ? props.Sub_contact_Details.companyName : "--"}</p>
                <h4 className="bill_contact_heading">Company address</h4>
                <p>{props.Sub_contact_Details.companyAddress ? props.Sub_contact_Details.companyAddress + ", " + props.Sub_contact_Details.state + ", " + props.Sub_contact_Details.zipCode : "--"}</p>
              </div>
              <div className="bill_plan_section">
                <h5 className="bill_plan_title">Billing period</h5>
                <h5 className="bill_current_plan">{props.Sub_billing_Detail.billingCycle}</h5>
                <h5 className="bill_plan_switch" style={{ cursor: "pointer" }} onClick={() => props.switch_sub_PaymentHandler(props.Sub_billing_Detail.billingCycle, props.Sub_billing_Detail.billingId)} >Switch to {props.Sub_billing_Detail.billingCycle === "Monthly" ? "Anual" : "Monthly"} Payment</h5>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <div className="billing_overview_section billing_estimate">
              <h5 className="overview_section_heading">Billing Estimate</h5>
              <span className="billing_estimate_date">
                {props.Sub_billing_Detail.billingStartDate
                  ? momentTimeZone(props.Sub_billing_Detail.billingStartDate)
                    .tz(props.timeZoneName)
                    .format("MMM DD YYYY")
                  : "-"}
                &nbsp;-&nbsp;
                {props.Sub_billing_Detail.billingEndDate
                  ? momentTimeZone(props.Sub_billing_Detail.billingEndDate)
                    .tz(props.timeZoneName)
                    .format("MMM DD YYYY")
                  : "-"}
              </span>
              <div className="billing_estimate_price_secion">
                <div className="billing_estimate_price_plans_main">
                  <div className="billing_estimate_price_plans">
                    <p className="billing_estimate_price_plan">
                      {/* {props.estimateData.plan ? startCase(props.estimateData.plan) : "-"} */}
                      Professional Plan
                    </p>
                    <p className="billing_estimate_price_plan_amount">
                      ${" "}
                      {props.Sub_billing_Detail.estimatedBill
                        ? parseFloat(props.Sub_billing_Detail.estimatedBill).toFixed(2)
                        : 0.0}
                    </p>
                  </div>
                  <span className="billing_estimate_price_plan_duration">
                    {props.Sub_billing_Detail.countOfActiveUsers ? props.Sub_billing_Detail.countOfActiveUsers : 0} User /
                    {props.Sub_billing_Detail.billingCycle}
                  </span>
                </div>
                {/* <div className="billing_estimate_price_tax ">
                  <p>Tax</p>
                  <p>
                    ${" "}
                    {props.Sub_billing_Detail.taxAmount
                      ? parseFloat(props.Sub_billing_Detail.taxAmount).toFixed(2)
                      : 0.0}

                  </p>
                </div> */}
                <div className="billing_estimate_price_tax billing_estimate_amount">
                  <p>Exclusive of Taxes</p>
                  <p>
                    ${" "}
                    {props.Sub_billing_Detail.estimatedBill
                      ? parseFloat(props.Sub_billing_Detail.estimatedBill).toFixed(2)
                      : 0.0}
                  </p>
                </div>
                <div className="billing_estimate_price_total">
                  <p>TOTAL</p>
                  <p className="billing_estimate_price_total_amount">
                    USD{" "}
                    {props.Sub_billing_Detail.estimatedBill 
                      ? parseFloat(props.Sub_billing_Detail.estimatedBill ).toFixed(2)
                      : 0.0}
                  </p>
                </div>
              </div>
              <div className="billing_due_total">
                <h6>Total due {props.Sub_billing_Detail.billingEndDate
                  ? momentTimeZone(props.Sub_billing_Detail.billingEndDate)
                    .tz(props.timeZoneName)
                    .format("MMM DD, YYYY")
                  : "-"}</h6>
                <h6>USD {props.Sub_billing_Detail.estimatedBill 
                  ? parseFloat(props.Sub_billing_Detail.estimatedBill).toFixed(2)
                  : 0.0}</h6>
              </div>
              {/* <Button
                className="view_full_estimate_btn logout-button"
              onClick={() => props.onViewEstimate(4)}
              >
                View full estimate
              </Button> */}
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="billing_history_table">
              <TableContainer >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={'20%'}>Date</TableCell>
                      <TableCell width={'20%'}>Invoice number</TableCell>
                      <TableCell width={'15%'}>Billing Plan</TableCell>
                      <TableCell width={'15%'}>Amount ( $ )</TableCell>
                      <TableCell width={'10%'}>Status</TableCell>
                      {/* <TableCell width={'10%'}>Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody className="tablecell_container">
                    {props.loading ? (
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={props.loading}
                        />
                      </TableCell>
                    ) : !props.Sub_payment_History ||
                      props.Sub_payment_History.length === 0 ? (
                          <TableCell colSpan={9} style={{ textAlign: "center" }}>
                            <div className="no-data-found">No data Found</div>
                          </TableCell>
                        ) : (
                          (props.Sub_payment_History.length > 0
                            ? props.Sub_payment_History.slice()
                            : props.Sub_payment_History
                          ).map((row, index) => (
                            <TableRow>
                              <TableCell onClick={() => props.onClickHandler (10,row._id,)}><><div className="billing_history_date">{row.startDate ? momentTimeZone(row.startDate)
                                .tz(props.timeZoneName)
                                .format("MMM DD YYYY") : "--"}</div></></TableCell>
                              <TableCell onClick={() => props.onClickHandler (10,row._id,)}><><div className="billing_history_number">{row.invoiceNumber ? row.invoiceNumber : "--"}</div></></TableCell>
                              <TableCell onClick={() => props.onClickHandler (10,row._id,)}><><div className="billing_history_plan">{row.billingCycle ? row.billingCycle.charAt(0).toUpperCase() + row.billingCycle.slice(1) : '--'}</div></></TableCell>
                              <TableCell onClick={() => props.onClickHandler (10,row._id,)}><><div className="billing_history_amount"> {row.amount ? row.amount.toFixed(2) : "--"}</div></></TableCell>
                              <TableCell onClick={() => props.onClickHandler (10,row._id,)}><><div className="billing_history_status"><p class={row.status === 1 ? "billing_history_paid" : row.status === 2 ? "billing_history_unpaid" : "billing_history_paid"}> {row.status === 1 ? "Paid" : row.status === 2 ? "Unpaid" : "--"}</p></div></></TableCell>
                              {/* <TableCell><><div className="billing_history_action"><img style={{ cursor: "pointer" }} src={download_img}
                               onClick={() =>props.downlod_pdf(row)} 
                              /></div></></TableCell> */}
                            </TableRow>
                          ))
                        )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      {props.Sub_payment_History.length > 0 ?
                        <td colSpan={20} className="pagination_container pagination_container_history">
                          <Pagination
                            count={props.Sub_payment_pager.endPage}
                            page={props.pageNumber}
                            onChange={props.onPendingPagination}
                            showFirstButton
                            showLastButton
                          />
                        </td>
                        : ""}
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default BillingSubscriptionDetails;