import React, { Component } from "react";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import company_img from "../../Assets/images/sample_company.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import "./BillingComponent.css";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { AxiosInstance } from "../../Utilities";
import { Redirect, withRouter } from "react-router-dom";
import * as action from "../../Store/Actions/index";
import momentTimeZone from "moment-timezone";
import { exportToCSV } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import logodefaultimage from "../../Assets/images/logodefaultimage.png";
import billing_division_logo from "../../Assets/images/billing_division_logo.svg";
import billing_area_logo from "../../Assets/images/billing_area_logo.svg";
import billing_region_logo from "../../Assets/images/billing_region_logo.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import BillingSideBarComponent from "./BillingSideBarComponent/BillingSideBarComponent";
import BillingConfigComponent from "./BillingConfigComponent/BillingConfigComponent";
import BillingOverviewComponent from "./BillingOverviewComponent/BillingOverviewComponent";
import BillingDetailComponent from "./BillingDetailComponent/BillingDetailComponent";
import BillingHistoryComponent from "./BillingHistoryComponent/BillingHistoryComponent";
import BillingEstimateComponent from "./BillingEstimateComponent/BillingEstimateComponent";
import BillingSubscriptionComponent from "./BillingSubscriptionComponent/BillingSubscriptionComponent";
import BillingPaymentStatus from "./BillingPaymentStatus/BillingPaymentStatus";
import BillingSubscriptionDetails from "./BillingSubscriptionDetails/BillingSubscriptionDetails"
import BillingDetailsConfirmation from "../../SharedComponents/BillingDetailsConfirmation/BillingDetailsConfirmation"
import { isSafari } from "react-device-detect";
import  {CircularProgress,Dialog,DialogActions,DialogTitle,DialogContent,DialogContentText,Button} from '@material-ui/core';
import { TransitionGrow } from '../../SharedComponents/CommonUtilities/CommonUtilities.js';
import { jsPDF } from "jspdf";
import {saveAs} from 'file-saver';
import {
  companyNameRegx,
  emailRegx,
  phoneRegx,
  userName,
  addressRegx,
  zipcodeRegx,
} from "../../SharedComponents/Validation/formValidation";
import { Grid } from '@material-ui/core';
import csc from "country-state-city";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { startCase } from 'lodash';
import userIcon from "../../Assets/images/user-icon.png";
import CreditCardComponent from "./CreditCardComponent/CreditCardComponent";
import ManageSubscriptionComponent from "./ManageSubscriptionComponent/ManageSubscriptionComponent";
import CreditCardUpdateComponent from "./CreditCardComponent/CreditCardUpdateComponent";
import PageNotFoundComponent from "../PageNotFoundComponent/PageNotFoundComponent";
import { ThreeSixty } from "@material-ui/icons";
import BillingInvoiceComponent from "./BillingInvoiceComponent/BillingInvoiceComponent";
import SubscriptionErrorMessageComponent from "./SubscriptionErrorMessageComponent/SubscriptionErrorMessageComponent";
import BillingMultipleCardComponent from "./BillingMultipleCardComponent/BillingMultipleCardComponent";
import ViewFullEstimateComponent from "./ViewFullEstimateComponent/ViewFullEstimateComponent";
// import MyCheckoutForm from './MyCheckoutForm/MyCheckoutForm';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);


class BillingComponent extends Component {
  constructor(props) {
    super(props);
    var datas = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : ""
    var zoneName = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") :momentTimeZone.tz.guess();
    this.state = {
      timeZoneName: zoneName,
      userData: datas,
      openMulticardModal:false,
      multiCardArray:[],
      sub_paymentId:"",
      sub_billingId:"",
      sub_customerId:"",
      billingDetails:{},
      costSplitup:{},
      cardDetail:{},
      openCancel:false,
      disableCancel:false,
      sub_price:"",
      cardEditData:null,
      showedit:false,
      edit_card_month:"",
      edit_card_year:"",
      edit_card_holder:"",
      editError:{
        exp_year:"",
        exp_month:"",
        edit_card_holder:""
      },
      radio_checked:false,
      radio_checked_id:"",
      card_header:"Subscribe",
      openViewFullEstimateDialog:false,
      submittedSave: false,
      showConfigure: true,
      stripeData: null,
      showAddCard: true,
      isSubmit: false,
      showUpdateCardModal: false,
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
      company: "",
      billingPeriod: "0",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      stateId: "",
      countryName: "",
      editType: '',
      country: "",
      countryId: "",
      zipCode: "",
      subscribePlan: "",
      subscribePrice: "",
      subscribeId: "",
      countryArray: [],
      stateArray: [],
      cityArray: [],
      countryList: [],
      selected: false,
      disableState: true,
      disableCity: true,
      invoiceTo: [],
      updateTitle: "",

      openBillingContactEditModal: false,
      showAddCardModal: false,
      disableSave: true,
      cardErrors: {
        numberError: '',
        expiryError: ''
      },
      formError: {
        firstName: "",
        lastName: "",
        phoneNo: "",
        email: "",
        company: "",
        billingPeriod: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
        subscribePlan: "",
        subscribePrice: "",
        invoiceTo: "",
      },

      estimateData: {},
      billingDetailArray: [],
      compNo: 1,
      loading: true,
      pageNumber: 1,
      rowsPerPage: 10,
      statusId: "",
      typeFilter: "",
      typeFilterName: "",
      searchText: "",
      statusFilterName: "",
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      typeConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      over_billingDetail: {},
      over_cardDetail: {},
      over_contact: {},
      over_paymentHistory: [],
      over_invoiceCC:[],
      history_billingCycle: "",
      history_createdAt: "",
      history_invoiceNumber: "",
      history_startDate: "",
      history_status: "",
      history_subscription: "",
      paym_StatusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      paym_StatusTypeConfig: {
        config: {
          id: "type_filter_select",
        },
        anchorElStatus: null,
      },
      paym_StatusBillingCycleConfig: {
        config: {
          id: "type_filter_select",
        },
        anchorElStatus: null,
      },
      paym_statusStatusId: "",
      paym_status_StatusFilterName: "",
      pym_StatusTypeFilterName: "",
      pym_StatusTypeId: "",
      pym_StatusBillingCycleFilterName: "",
      pym_StatusBillingCycleId: "",
      pym_StatusdateFilter: { from: "", to: "" },
      pay_statusSearchFilter: "",
      tooltipprops: {},
      side_bar_header: {},
      Sub_billing_Detail: {},
      Sub_contact_Details: {},
      Sub_payment_History: [],
      Sub_payment_pager: {},
      billStatusNew: 0,
      showConform:false,

      accountName:"",
      accountOption: [],
      assigneeConfig: {
        anchorElAssignee: null,
        config: {
          id: "assignee_filter_select",
        },
      },
      accountConfig: {
        anchorElAccount: null,
        config: {
          id: "account_filter_select",
        },
      },
      searchAccountValue:"",
      billingId:"",
      Invoice_Detail:"",
    };
  }
  async UNSAFE_componentWillMount() {
    this.fetchBillingStatus();
    if (this.state.userData && this.state.userData.role.toLowerCase() !== "super admin") {
      await this.get_config_billing_enable();
      await this.fetch_autofil_detail();    
    }
    if (this.state.userData && this.state.userData.role.toLowerCase() === "super admin") {
      this.setState({
        compNo: 6
      })
    } else {
      this.setState({
        compNo: 1
      })
      //  if(this.state.showConfigure){
      //   this.setState({
      //     compNo: 5
      //   })
      //  }else{
      //   this.setState({
      //     compNo: 1
      //   })
      //  }
    }
    let obj = {}
    if (isSafari) {
      obj.open = false;
      this.setState({ tooltipprops: obj })
    }
  }

  fetch_autofil_detail=()=>{
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user",
      "application/json"
    )
      .get("/employee/billing/configure")
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          let respData=response.data;
          this.setState({
            firstName:respData.name?startCase(respData.name):"",
            phoneNo:respData.phone?respData.phone:"",
            email:respData.email?respData.email:"",
            company: respData.companyName?startCase(respData.companyName):""
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }
  fetchBillingStatus = () => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      "application/json"
    )
      .get("/employee/billingStatus")
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          let billingDetails=response.billingDetails?response.billingDetails.billingDetails:{};
          let cardDetail = response.billingDetails?response.billingDetails.cardDetail:{};
          this.setState({
            billStatusNew: response.billStatus,
            billingDetails,
            cardDetail
          },()=>{
            this.fetch_subscription_details()
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  get_config_billing_enable = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 6019
      : "subscription",
      "application/json")
      .get("/billing_configure")
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            showConfigure: response.data.showConfigure,
            subscribeId: response.data.subscribeSettingsId,
            subscribePrice: response.data.pricePerUser,
            sub_billingId:response.data.billingId,
          }, () => {
            if (this.state.showConfigure) {
              this.setState({
                compNo: 5
              }, () => {
                if (this.state.userData.role.toLowerCase() !== "super admin") { this.compenyDetail(); }
              })
            } else {
              this.fetchBillingOverviewDetail();
              this.fetchBillingEstimateDetail();
              if (this.state.userData.role.toLowerCase() !== "super admin") { this.compenyDetail(); }
            }
          })

        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // let propserror = {
          //   message: response.msg,
          //   icon: <img src={iconNotificationError} alt="success" />,
          //   type: "error",
          //   placement: "topRight",
          //   duration: 3,
          //   top: 200,
          //   className: "task_notification error-notify",
          // };
          // NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });

  }
  componentDidMount = () => {

    var countries = csc.getAllCountries();
    this.setState({
      countryArray: countries,
      countryList: countries,
    });
    this.setState({ loading: true }, () => {
      this.props.onPagination(
        this.state.pageNumber - 1,
        this.state.rowsPerPage
      );
    });
    // if (this.state.userData.role.toLowerCase() !== "super admin") { this.compenyDetail(); }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };

  /** Benakesh code start */

  openCardModalHandler=()=>{
    let data1 = {
      firstName: this.state.billingDetails.name,
      lastName: "",
      phone: this.state.billingDetails.phone,
      email: this.state.billingDetails.email,
      period: parseInt(this.state.billingDetails.billingCycle),
      subscriptionId: this.state.billingDetails.subscriptionId,
      addresLine1: this.state.billingDetails.address.line1,
      addressLine2: this.state.billingDetails.address.line2,
      city: this.state.billingDetails.address.city,
      state: this.state.billingDetails.address.state,
      country:this.state.billingDetails.countryCode,
      zipCode: this.state.billingDetails.address.postal_code,
    };
    localStorage.setItem("billingDetail", JSON.stringify(data1));

    let data={
      billingId:this.state.sub_billingId
    }
    this.setState({
      multiCardArray:[]
    })
    this.fetch_card_list_handler(data);
  
  }
  subscribeHandler=(e)=>{
    e.preventDefault();
    const {cardEditData}=this.state;
    if(this.state.billStatusNew === 1){
    let reqData = {
      type:0
    }
    if(this.state.sub_paymentId !== cardEditData.paymentMethodId){
      reqData.paymentMethodId=cardEditData.paymentMethodId;
      reqData.type=1;    
    }
    this.resubscribe_patment_handler(reqData);
  }else if(this.state.billStatusNew === 2){
    let newCard={
      paymentMethodId:cardEditData.paymentMethodId,
      customerId:this.state.sub_customerId
    }
    this.retry_payment_handler(newCard);
  }
  }
  subscribeNewHandler=(item,type)=>{
   if(this.state.billStatusNew === 2){
      let newCard={
        paymentMethodId:item.paymentMethodId,
        customerId:this.state.sub_customerId
      }
      this.retry_payment_handler(newCard);
    }else if(this.state.billStatusNew === 1){
      let data={
        ...item,
        type
      }
      this.resubscribe_patment_handler(data);
    }
  }
  updateHandler=(e)=>{
    e.preventDefault();
    const {cardEditData}=this.state;
    let reqData = {
      cardNumber: parseInt(cardEditData.last4),
      name: cardEditData.name,
      cardExpMonth: cardEditData.exp_month ,
      cardExpYear:cardEditData.exp_year,
      paymentMethodId: cardEditData.paymentMethodId
    }
    this.setCustomerDataUpdate(reqData)
  }
  closeCardModalHandler=()=>{
    this.setState({
      openMulticardModal:false,
      card_header:"Subscribe",
      cardErrors: {
        numberError: '',
        expiryError: ''
      },
    })
    localStorage.removeItem("billingDetail");
  }
  on_card_radio_check=(e,item)=>{
    this.setState({
      radio_checked:e.target.checked,
      radio_checked_id:item.paymentMethodId,
      cardEditData:item,
      showedit:false
    })
  }
  on_add_card_radio_check=(e,payment_id)=>{
    this.setState({
      radio_checked:e.target.checked,
      radio_checked_id:payment_id
    })
  }
  onCardEdit=(item,payment_id)=>{
    this.setState({
      cardEditData:item,
      radio_checked_id:payment_id,
      edit_card_month:item.exp_month,
      edit_card_year:item.exp_year,
      edit_card_holder:item.name
    },()=>{
      this.setState({
        showedit:true
      })
    })
  }

  onEditSaveHandler=(e)=>{
    e.preventDefault();
    const {editError,edit_card_month,edit_card_year,edit_card_holder} = this.state;
    if(edit_card_month === ""){
      editError.exp_month="Please enter expiry month";
    }else{
      editError.exp_month="";
    }
    
    if(edit_card_year === ""){
      editError.exp_year="Please enter expiry year";
    }else{
      editError.exp_year="";
    }
    if(edit_card_holder === ""){
      editError.edit_card_holder="Please enter card holder name";
    }else{
      editError.edit_card_holder="";
    }

    this.setState({
      editError
    },()=>{
      const isEmpty = Object.values(editError).every(
        (x) => x === null || x === ""
      );
      if(isEmpty){
        //call edit api
        let data={
          billingId:this.state.sub_billingId,
          exp_year:parseInt(this.state.edit_card_year),
          exp_month:parseInt(this.state.edit_card_month),
          name:this.state.edit_card_holder,
          paymentMethodId:this.state.radio_checked_id
        }
        this.edit_customer_card(data);
      }
    })

  }


  removeCardHandler=(e,payment_id)=>{
    e.preventDefault();
    let data={
      billingId:this.state.sub_billingId,
      paymentMethodId:payment_id
    }
    this.delete_customer_card(data);
  }
  onEditCancelHandler=(e)=>{
    e.preventDefault();
    const {editError}=this.state;
    editError.exp_year="";
    editError.exp_month="";
    this.setState({
      edit_card_month:"",
      edit_card_year:"",
      editError,
      showedit:false
    })
  }
  onEditChange=(e)=>{
    const {name,value}=e.target;
    const {editError} = this.state;
    switch(name){
      case 'edit_card_month':
            value.trim() === ""?
            editError.exp_month=""
            :!/\d{1,2}/.test(value.trim())
            ?editError.exp_month="Please enter valid month"
            :value.trim()>12
            ?editError.exp_month="Month should be between 1-12"
            :editError.exp_month="";
          break;
      case 'edit_card_year':
          value.trim() === ""
          ?editError.exp_year=""  
          :!/\d{4}/.test(value.trim())
          ?editError.exp_year="Please enter valid year"
          : momentTimeZone().year() > value.trim() || momentTimeZone().year() == value.trim() 
          ?editError.exp_year="Your card's expiration month is in the past."
          :editError.exp_year="";
          break;
      case 'edit_card_holder':
          value.trim() === ""
          ?editError.edit_card_holder=""
          :!userName.test(value.trim())
          ?editError.edit_card_holder="Please enter a valid name"
          :editError.edit_card_holder="";
          break;
      default:
          break;
    }

    this.setState({
      [name]:value.trim()
    })
  }
  handleChangeCard = ({ error }) => {
    const { cardErrors } = this.state;
    if (error) {
      cardErrors.numberError = error.message;
    } else {
      cardErrors.numberError = '';
    }
    this.setState({
      cardErrors
    })
  }

  handleChangeExpiry = ({ error }) => {
    const { cardErrors } = this.state;
    if (error) {
      cardErrors.expiryError = error.message;
    } else {
      cardErrors.expiryError = '';
    }
    this.setState({
      cardErrors
    })
  }

  setCustomerData = (data) => {
    this.setState({
      stripeData: data,
      showAddCardModal: true,
      showUpdateCardModal: false,
      showAddCard: false,
      disableSave: false,
      compNo:15
    },()=>{
      this.onSubscriptionSubmit();
    })
  }
  fetch_subscription_details=()=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
    "application/json")
    .get("/get_sub_details")
    .then(resp=>{
      let response = resp && resp.data;
      if(response && (response.status === "200" || response.status === 200)){
        this.setState({
          sub_customerId:response.data.customerId,
          sub_price: response.data.price
        })
      }
    }).catch(err=>{
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
      }
    });
  }

  edit_customer_card=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ? 6019
    : "subscription",
    "application/json")
    .post("/edit_customer_card", data)
    .then((resp) => {
      let response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {
        let propserror = {
          message: response.msg,
          icon: <img src={iconNotificationSuccess} alt="success" />,
          type: "success",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification success-notify",
        };
        NotificationComponent(propserror); 
        let getData={
          billingId:this.state.sub_billingId
        }
        this.fetch_card_list_handler(getData);
      }
      else if (
        response &&
        (response.status === "400" || response.status === 400)
      ) {
        let propserror = {
          message: response.msg,
          icon: <img src={iconNotificationError} alt="error" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    }).catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (
        error &&
        (error.status === "500" || error.status === 500)
      ) {
        let propserror = {
          message: "Something went wrong",
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    });
  }
  delete_customer_card=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ? 6019
    : "subscription",
    "application/json")
    .post("/delete_customer_card", data)
    .then((resp) => {
      let response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {
        let propserror = {
          message: response.msg,
          icon: <img src={iconNotificationSuccess} alt="success" />,
          type: "success",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification success-notify",
        };
        NotificationComponent(propserror); 
        let getData={
          billingId:this.state.sub_billingId
        }
        this.fetch_card_list_handler(getData);
      }
      else if (
        response &&
        (response.status === "400" || response.status === 400)
      ) {
        let propserror = {
          message: response.msg,
          icon: <img src={iconNotificationError} alt="error" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    }).catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (
        error &&
        (error.status === "500" || error.status === 500)
      ) {
        let propserror = {
          message: "Something went wrong",
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    });
  }

  retry_payment_handler = (data)=>{
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      "application/json"
    )
      .post("/retryPayment",data)
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(propserror);
            if(this.state.compNo === 2){
              this.fetchBillingDetail();
            }
            setTimeout(() => {
              this.props.history.replace("/dashboard")
            }, 1000);
        }else         if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            this.setState({
              openMulticardModal:false
            },()=>{
            setTimeout(() => {
              this.props.history.replace("/dashboard")
            }, 1000);
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
            // let propserror = {
            //   message: "Something went wrong",
            //   icon: <img src={iconNotificationError} alt="success" />,
            //   type: "error",
            //   placement: "topRight",
            //   duration: 3,
            //   top: 200,
            //   className: "task_notification error-notify",
            // };
            // NotificationComponent(propserror);
        }
      });
  }

  resubscribe_patment_handler=(data)=>{
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      "application/json"
    )
      .post("/reSubscribe",data)
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(propserror);
            if(this.state.compNo === 2){
              this.fetchBillingDetail();
            }
            this.setState({
              openMulticardModal:false
            },()=>{
              setTimeout(() => {
                this.props.history.replace("/dashboard")
              }, 500);
            })
           
        }else         if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            setTimeout(() => {
              this.props.history.replace("/billing")
            }, 500);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
            // let propserror = {
            //   message: "Something went wrong",
            //   icon: <img src={iconNotificationError} alt="success" />,
            //   type: "error",
            //   placement: "topRight",
            //   duration: 3,
            //   top: 200,
            //   className: "task_notification error-notify",
            // };
            // NotificationComponent(propserror);
        }
      });
  }

  fetch_card_list_handler=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ? 6019
    : "subscription",
    "application/json")
    .post("/get_customer_cards", data)
    .then((resp) => {
      let response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {
        if(response.cardDetails.length>0){
          this.setState({
            radio_checked_id:response.cardDetails[0].paymentMethodId,
            cardEditData:response.cardDetails[0]
          })
        }
        this.setState({
          multiCardArray:response.cardDetails,
          sub_paymentId:response.paymentMethodId,
          showedit:false
        },()=>{
          this.setState({
            openMulticardModal:true,
          })
        })       
      }
      else if (
        response &&
        (response.status === "400" || response.status === 400)
      ) {
        // let propserror = {
        //   message: response.msg,
        //   icon: <img src={iconNotificationError} alt="success" />,
        //   type: "error",
        //   placement: "topRight",
        //   duration: 3,
        //   top: 200,
        //   className: "task_notification error-notify",
        // };
        // NotificationComponent(propserror);
      }
    }).catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (
        error &&
        (error.status === "500" || error.status === 500)
      ) {
        let propserror = {
          message: "Something went wrong",
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    });
  }
  setCustomerDataUpdate = (item) => {
    this.setState({
      isSubmit: true
    })
    let reqData = {
      lastDigit: parseInt(item.cardNumber),
      cardHolder: item.name,
      expire: item.cardExpMonth + "/" + item.cardExpYear,
      paymentMethodId: item.paymentMethodId,
      billingId: this.state.sub_billingId
    }

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 6019
      : "subscription",
      "application/json")
      .put("/billing/cardDetail", reqData)
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(propserror);
          this.fetchBillingDetail();
          this.setState({
            compNo: 2,
            showUpdateCardModal: false,
            openMulticardModal:false,
            isSubmit: false
          })
        }
        else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      }).catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }
  onClickHandler = (id, rowId) => {

    const { formError } = this.state;
    formError.invoiceTo = "";
    this.setState(
      {
        compNo: parseInt(id),
        pageNumber: 1,
        rowsPerPage: 10,
        statusId: "",
        typeFilter: "",
        typeFilterName: "",
        searchText: "",
        statusFilterName: "",
        invoiceTo: [],
        formError,
        sub_Details: rowId,
        Sub_billing_Detail: "",
        Sub_contact_Details: "",
        Sub_payment_History: "",
        Sub_payment_pager: "",
        isData: "",

      },
      () => {
        if (this.state.compNo === 6) {
          this.componentDidMount();
        } else if (this.state.compNo === 1) {
          this.fetchBillingOverviewDetail();
          this.fetchBillingEstimateDetail();
        }
        else if (this.state.compNo === 3) {
          this.history_FetchDetail();
        } else if (this.state.compNo === 4) {
          this.get_config_billing_enable();
          this.fetchBillingEstimateDetail();
        } else if (this.state.compNo === 2) {
          this.fetchBillingDetail();
          this.fetchBillingEstimateDetail();
          this.get_config_billing_enable();
        } else if (this.state.compNo === 7) {
          this.get_config_billing_enable();
          this.fetchBillingEstimateDetail();
        } else if (this.state.compNo === 8) {
          this.paym_StatusFetchDetail();
        } else if (this.state.compNo === 9) {
          this.Subcription_Detail();
        } else if (this.state.compNo === 10) {
         this.billing_invoice_detail(rowId);
        }else if (this.state.compNo === 5) {
          this.setState({
            firstName: "",
            lastName: "",
            phoneNo: "",
            email: "",
            company: "",
            billingPeriod: "0",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            stateId: "",
            country: "",
            countryId: "",
            zipCode: "",
            subscribePlan: "",
            subscribePrice: "",
            subscribeId: "",
            selected: false,
            disableState: true,
            disableCity: true,
            formError: {
              firstName: "",
              lastName: "",
              phoneNo: "",
              email: "",
              company: "",
              billingPeriod: "",
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: "",
              country: "",
              zipCode: "",
              subscribePlan: "",
              subscribePrice: "",
              invoiceTo: "",
            },
          }, () => {
            this.fetch_autofil_detail();
            this.get_config_billing_enable();
          });
        }
        window.scrollTo(0, 0);
      }
    );
  };

  fetchBillingEstimateDetail = () => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription"
    )
      .post("/billing_estimate", { id: this.state.subscribeId })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            estimateData: response.data,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };

  fetchBillingDetail = () => {
    let data = {
      subscriptionId: this.state.subscribeId
    };

    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription"
    )
      .post("/billingDetails", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            billingDetailArray: response.data,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  handleCountrySearch = (value) => {
    const { countryArray } = this.state;
    this.setState({
      countryName: value
    })
    let countries = countryArray.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    if (countries.length > 0) {
      this.setState({
        countryList: countries,
      });
    } else {
      this.setState({
        countryList: countryArray,
      });
    }
  };
  handleStateSearch = (value) => {
    const { stateArray } = this.state;
    this.setState({
      state: value
    })
    let states = stateArray.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    if (states.length > 0) {
      this.setState({
        stateList: states,
      });
    } else {
      this.setState({
        stateList: stateArray,
      });
    }
  };

  handleCitySearch = (value) => {
    const { cityArray } = this.state;
    this.setState({
      city: value
    })
    let city = cityArray.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    if (city.length > 0) {
      this.setState({
        cityList: city,
      });
    } else {
      this.setState({
        cityList: cityArray,
      });
    }
  };

  onChangeCountry = (value) => {
    let items = value.split("key");
    let countryCode = items[0];
    let countryId = items[1];
    let countryName = items[2];
    const { formError } = this.state;
    formError.country = "";
    this.setState(
      {
        country: countryCode,
        countryId,
        countryName,
        disableState: false,
        formError,
      },
      () => {
        var states = csc.getStatesOfCountry(this.state.countryId);
        this.setState({
          stateArray: states,
          stateList: states,
          state: "",
          city: "",
          disableCity: true
        });
      }
    );
  };

  onChangeState = (value) => {
    let items = value.split("key");
    let stateName = items[0];
    let stateId = items[1];
    const { formError } = this.state;
    formError.state = "";
    this.setState(
      {
        state: stateName,
        stateId,
        disableCity: false,
        formError,
      },
      () => {
        let city = csc.getCitiesOfState(this.state.stateId);
        this.setState({
          cityArray: city,
          cityList: city,
          city: ""
        });
      }
    );
  };

  onChangeCity = (value) => {
    const { formError } = this.state;
    formError.city = "";
    this.setState({
      city: value,
      formError,
    });
  };

  handleBillingPeriodChange = (value) => {
    this.setState({
      billingPeriod: value,
    });
  };
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const { formError } = this.state;
    switch (name) {
      case "firstName":
        value.length >= 0 && value.trim() === ""
          ? (formError.firstName = "")
          : !userName.test(value)
            ? (formError.firstName = "Please enter valid first name")
            : (formError.firstName = "");
        break;
      case "lastName":
        value.length >= 0 && value.trim() === ""
          ? (formError.lastName = "")
          : !userName.test(value)
            ? (formError.lastName = "Please enter valid last name")
            : (formError.lastName = "");
        break;
      case "phoneNo":
        value.length >= 0 && value.trim() === ""
          ? (formError.phoneNo = "")
          : !phoneRegx.test(value)
            ? (formError.phoneNo =
              "Please enter the valid phone number e.g.: 800-555-5555 ")
            : (formError.phoneNo = "");
        break;
      case "email":
        value.length >= 0 && value.trim() === ""
          ? (formError.email = "")
          : !emailRegx.test(value)
            ? (formError.email = "Please enter a valid email")
            : (formError.email = "");
        break;
      case "company":
        value.length >= 0 && value.trim() === ""
          ? (formError.company = "")
          : !companyNameRegx.test(value)
            ? (formError.company = "Please enter a valid company name")
            : (formError.company = "");
        break;
      case "addressLine1":
        value.length >= 0 && value.trim() === ""
          ? (formError.addressLine1 = "")
          : !addressRegx.test(value)
            ? (formError.addressLine1 = "Please enter a valid address")
            : (formError.addressLine1 = "");
        break;
      case "addressLine2":
        value.length >= 0 && value.trim() === ""
          ? (formError.addressLine2 = "")
          : !addressRegx.test(value)
            ? (formError.addressLine2 = "Please enter a valid address")
            : (formError.addressLine2 = "");
        break;
      case "zipCode":
        value.length >= 0 && value.trim() === ""
          ? (formError.zipCode = "")
          : !zipcodeRegx.test(value)
            ? (formError.zipCode = "Please enter a valid zipcode")
            // : /^(?!0{5})$/.test(value)
            // ? (formError.zipCode = "Please enter a valid zipcode")
            : (formError.zipCode = "");
        break;
      case "invoiceTo":
        value.length >= 0 && value.trim() === ""
          ? (formError.invoiceTo = "")
          : !emailRegx.test(value)
            ? (formError.invoiceTo = "Please enter a valid email")
            : (formError.invoiceTo = "");
        break;
      default:
        break;
    }

    this.setState({
      [name]: value.trim(),
      formError,
    });
  };

  onSubscriptionSubmit = () => {
    // e.preventDefault();
    const { formError } = this.state;
    if (this.state.firstName.trim() === "") {
      formError.firstName = "Please enter first name";
    } else {
      formError.firstName = "";
    }
    if (this.state.phoneNo.trim() === "") {
      formError.phoneNo = "Please enter a phone number";
    } else {
      formError.phoneNo = "";
    }
    if (this.state.email.trim() === "") {
      formError.email = "Please enter an email";
    } else {
      formError.email = "";
    }
    if (this.state.company.trim() === "") {
      formError.company = "Please enter a company name";
    } else {
      formError.company = "";
    }
    if (this.state.addressLine1.trim() === "") {
      formError.addressLine1 = "Please enter an address";
    } else {
      formError.addressLine1 = "";
    }

    if (this.state.countryName.trim() === "") {
      formError.country = "Please select a country";
    } else {
      formError.country = "";
    }
    if (this.state.state.trim() === "") {
      formError.state = "Please enter a state";
    } else {
      formError.state = "";
    }

    if (this.state.city.trim() === "") {
      formError.city = "Please enter a city";
    } else {
      formError.city = "";
    }
    if (this.state.zipCode.trim() === "") {
      formError.zipCode = "Please enter a zipcode";
    }
    // else if(/^(?!0{5})$/.test(this.state.zipCode)){
    //   formError.zipCode = "Please enter a valid zipcode";
    // }
    else {
      formError.zipCode = "";
    }
    this.setState(
      {
        formError,
      },
      () => {
        const isEmpty = Object.values(formError).every(
          (x) => x === null || x === ""
        );
        if (isEmpty) {
          this.setState({
            submittedSave: true
          })
          let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phoneNo,
            email: this.state.email,
            period: parseInt(this.state.billingPeriod),
            subscriptionId: this.state.subscribeId,
            companyName: this.state.company,
            addresLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            state: this.state.state,
            country: this.state.countryName,
            countryCode: this.state.country,
            zipCode: this.state.zipCode,
            lastDigit: parseInt(this.state.stripeData.cardNumber),
            cardHolder: this.state.stripeData.name,
            expire: this.state.stripeData.cardExpMonth + "/" + this.state.stripeData.cardExpYear,
            customerId: this.state.stripeData.customerId,
            paymentMethodId: this.state.stripeData.paymentMethodId,
            priceId: this.state.stripeData.priceId,
          };
          localStorage.setItem("billingDetail", JSON.stringify(data));
          AxiosInstance(
            (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 6019
              : "subscription",
            "application/json"
          )
            .post("/billingSetup/store", data)
            .then((resp) => {
              let response = resp && resp.data;
              if (
                response &&
                (response.status === "201" || response.status === 201)
              ) {
              
                let propserror = {
                  message: response.msg,
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification success-notify",
                };
                NotificationComponent(propserror);
                setTimeout(()=>{
                  this.setState({
                    compNo: 1,
                    submittedSave: false,
                  });                  
                this.get_config_billing_enable();
                localStorage.removeItem("billingDetail");
                },1500);
              } else if (
                response &&
                (response.status === "400" || response.status === 400)
              ) {
                let propserror = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="success" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propserror);
                this.setState({
                  submittedSave: false
                })
              }else if (
                response &&
                (response.status === "402" || response.status === 402)
              ) {
                let propserror = {
                  message: "Customer Subscription Error",
                  icon: <img src={iconNotificationError} alt="success" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propserror);
                this.setState({
                  submittedSave: false
                })
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else if (
                error &&
                (error.status === "500" || error.status === 500)
              ) {
                let propserror = {
                  message: "Something went wrong",
                  icon: <img src={iconNotificationError} alt="success" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propserror);
                this.setState({
                  submittedSave: false
                })
              }
            });
        }
      }
    );
  };
  selectPlan = (plan, price) => {
    this.setState(
      {
        subscribePlan: plan,
        subscribePrice: price,
        subscribeId: "5f928cdfd6d4c6017c41e215",
        selected: true,
      },
      () => {
        localStorage.setItem("subscribePlan", this.state.subscribePlan);
        localStorage.setItem("subscribePrice", this.state.subscribePrice);
        localStorage.setItem("subscribeId", this.state.subscribeId);
      }
    );
  };
  onViewFullDetail = (e) => {
   e.preventDefault();
   let data={
     billingId:this.state.sub_billingId
   }
   this.fetch_cost_breakup(data);
   
  };

  closeViewFullEstimateDialog=()=>{
    this.setState({
      openViewFullEstimateDialog:false
    })
  }
  fetch_cost_breakup=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 6019
      : "subscription",
      "application/json")
      .post("/detailed_estimate", data)
      .then(resp => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
         this.setState({
           costSplitup:response.data
         },()=>{
          this.setState({
            openViewFullEstimateDialog:true
          })
         })
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            openViewFullEstimateDialog:false
          })
        }
      }).catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            openViewFullEstimateDialog:false
          })
        }
      });
  }
  switchPaymentHandler = (item) => {
    let cycle = item.billingDetail.billingCycle === 1 ? 0 : 1;
    let billingId = item._id;
    let data = {
      subscriptionId: this.state.subscribeId,
      billingCycle: cycle,
      billingId
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 6019
      : "subscription",
      "application/json")
      .put("/billing/switchBillingCycle", data)
      .then(resp => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            compNo: 2
          })
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(propserror);
          this.fetchBillingDetail();
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      }).catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  onInvoiceChange=(ids)=>{
    const {formError}=this.state;
    if(ids.length > 0){
      formError.invoiceTo="";
    }
    this.setState({
      invoiceTo:ids,
      formError
    })
  }
  onInvoiceSend = (e) => {
    e.preventDefault();
    if(this.state.invoiceTo.length>0 && this.state.formError.invoiceTo === ""){
    let data={
      billingId:this.state.sub_billingId,
      invoiceCC:this.state.invoiceTo
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ? 6019
    : "subscription",
    "application/json")
    .post("/billing/updateInvoice", data)
    .then(resp => {
      var response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {
        this.setState({
          invoiceTo:[]
        })
        let propserror = {
          message: response.msg,
          icon: <img src={iconNotificationSuccess} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification success-notify",
        };
        NotificationComponent(propserror);
        // this.fetchBillingDetail();
      } else if (
        response &&
        (response.status === "400" || response.status === 400)
      ) {
        let propserror = {
          message: response.msg,
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    }).catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (
        error &&
        (error.status === "500" || error.status === 500)
      ) {
        let propserror = {
          message: "Something went wrong",
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    });
  }else{
    const {formError} =this.state;
    formError.invoiceTo="Please enter a invoice cc email address";
    this.setState({
      formError
    })
  }
  };
  onPopConfirm=()=>{
    this.cancelSubscription();
  }
  handlePopConfirmClose=()=>{
    this.setState({
      disableCancel:false,
      openCancel:false
    })
  }
  getConfirmPopup=()=>{
    this.setState({
      openCancel:true
    })
  }
  cancelSubscription = () => {
    let data = {
      subscriptionId: this.state.subscribeId
    }
    this.setState({
      disableCancel:true
    })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 6019
      : "subscription",
      "application/json")
      .post("/subscription/cancel", data)
      .then(resp => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            disableCancel:false,
            openCancel:false
          })
          // window.location.reload();
          this.props.history.replace("/dashboard");
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            disableCancel:false,
            openCancel:false
          })
        }
      }).catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  // onChangePlan = () => {}

  onManageUser = () => {
    this.setState({
      compNo: 2
    }, () => {
      window.scrollTo(0, 0);
      this.fetchBillingDetail();
      this.fetchBillingEstimateDetail();
    })
  }

  onBillingDetailEdit = (item) => {
    let billingDetail={
      name:item.firstName+" "+item.lastName,
      phone:item.phone,
      email:item.email,
      billingCycle: parseInt(item.billingDetail.billingCycle),
      subscriptionId:item.subscriptionSettingsId,
      address:{
        line1:item.addresLine1,
        line2: item.addressLine2,
        city: item.city,
        state: item.state,
        postal_code: item.zipCode
      },
      countryCode: item.countryCode,
    }
   
    let data = {
      firstName: item.firstName,
      lastName: item.lastName,
      phone: item.phone,
      email: item.email,
      period: parseInt(item.billingDetail.billingCycle),
      subscriptionId: item.subscriptionSettingsId,
      companyName: item.companyName,
      addresLine1: item.addresLine1,
      addressLine2: item.addressLine2,
      city: item.city,
      state: item.state,
      country: item.countryName,

      zipCode: item.zipCode,
    };
    localStorage.setItem("billingDetail", JSON.stringify(data));
    this.setState({
      card_header:"Update Billing Card",
      billingDetails:billingDetail
    },()=>{
      this.openCardModalHandler();
    })
  
  
  };

  closeBillingContactEditModal = () => {
    this.setState({
      openBillingContactEditModal: false,
      disableState: true,
      disableCity: true
    }, () => {
      const { formError } = this.state;
      // formError.firstName="";
      // formError.lastName= "";
      // formError.phoneNo= "";
      // formError.email= "";
      // formError.billingPeriod= "";
      // formError.subscribePlan= "";
      // formError.subscribePrice= "";
      // formError.invoiceTo= "";

      formError.company = "";
      formError.addressLine1 = "";
      formError.addressLine2 = "";
      formError.city = "";
      formError.state = "";
      formError.country = "";
      formError.zipCode = "";
      this.setState({
        formError
      })
    })
  }

  updateBillingContactHandler = (type) => {
    const { formError } = this.state;
    if (this.state.company.trim() === "") {
      formError.company = "Please enter a company name";
    } else if (!companyNameRegx.test(this.state.company)) {
      formError.company = "Please enter a valid company name";
    } else {
      formError.company = "";
    }
    if (this.state.addressLine1.trim() === "") {
      formError.addressLine1 = "Please enter an address";
    } else if (!addressRegx.test(this.state.addressLine1)) {
      formError.addressLine1 = "Please enter a valid address";
    } else {
      formError.addressLine1 = "";
    }
    if (this.state.countryName.trim() === "") {
      formError.country = "Please select a country";
    } else if (this.state.addressLine2 !== "" && !addressRegx.test(this.state.addressLine2)) {
      formError.addressLine2 = "Please enter a valid address";
    } else {
      formError.country = "";
    }
    if (this.state.state.trim() === "") {
      formError.state = "Please enter a state";
    } else {
      formError.state = "";
    }

    if (this.state.city.trim() === "") {
      formError.city = "Please enter a city";
    } else {
      formError.city = "";
    }
    if (this.state.zipCode.trim() === "") {
      formError.zipCode = "Please enter a zipcode";
    } else if (!zipcodeRegx.test(this.state.zipCode)) {
      formError.zipCode = "Please enter a valid zipcode";
    }
    // else if(/^(?!0{5})$/.test(this.state.zipCode)){
    //   formError.zipCode = "Please enter a valid zipcode";
    // }
    else {
      formError.zipCode = "";
    }
    this.setState(
      {
        formError,
      },
      () => {
        const isEmpty = Object.values(formError).every(
          (x) => x === null || x === ""
        );
        if (isEmpty) {
          let data = {
            companyName: this.state.company,
            subscriptionId: this.state.subscribeId,
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            country: this.state.countryName,
            countryCode: this.state.country,
            state: this.state.state,
            city: this.state.city,
            zipCode: this.state.zipCode,
            billingId: this.state.sub_billingId
          }
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 6019
            : "subscription",
            "application/json")
            .put("/billing/billingContact", data)
            .then(resp => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                let propserror = {
                  message: response.msg,
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification success-notify",
                };
                NotificationComponent(propserror);
                this.setState({
                  compNo: 2,
                  openBillingContactEditModal: false
                })
                this.fetchBillingDetail();
              } else if (
                response &&
                (response.status === "400" || response.status === 400)
              ) {
                let propserror = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="success" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propserror);
              }
            }).catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else if (
                error &&
                (error.status === "500" || error.status === 500)
              ) {
                let propserror = {
                  message: "Something went wrong",
                  icon: <img src={iconNotificationError} alt="success" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propserror);
              }
            });
        }
      });

  }
  onContactEdit = (title, editData) => {
    let type = title === "Billing Contact" ? 2 : 1;
    this.setState({
      updateTitle: title,
      company: editData.companyName,
      addressLine1: editData.addresLine1,
      addressLine2: editData.addressLine2,
      city: editData.city,
      countryName: editData.country,
      country: editData.countryCode,
      state: editData.state,
      zipCode: editData.zipCode,
      editType: type
    }, () => {
      this.setState({
        openBillingContactEditModal: true
      })
    })
  };

  onMakeMeBillingContact = () => { };
  onManageContact = () => { };

  showAddCardHandler = () => {
    const { formError } = this.state;
    if (this.state.firstName.trim() === "") {
      formError.firstName = "Please enter first name";
    } else {
      formError.firstName = "";
    }
    if (this.state.phoneNo.trim() === "") {
      formError.phoneNo = "Please enter a phone number";
    } else {
      formError.phoneNo = "";
    }
    if (this.state.email.trim() === "") {
      formError.email = "Please enter an email";
    } else {
      formError.email = "";
    }
    if (this.state.company.trim() === "") {
      formError.company = "Please enter a company name";
    } else {
      formError.company = "";
    }
    if (this.state.addressLine1.trim() === "") {
      formError.addressLine1 = "Please enter an address";
    } else {
      formError.addressLine1 = "";
    }

    if (this.state.country.trim() === "") {
      formError.country = "Please select a country";
    } else {
      formError.country = "";
    }
    if (this.state.state.trim() === "") {
      formError.state = "Please enter a state";
    } else {
      formError.state = "";
    }

    if (this.state.city.trim() === "") {
      formError.city = "Please enter a city";
    } else {
      formError.city = "";
    }
    if (this.state.zipCode.trim() === "") {
      formError.zipCode = "Please enter a zipcode";
    }
    // else if(/^(?!0{5})$/.test(this.state.zipCode)){
    //   formError.zipCode = "Please enter a valid zipcode";
    // }
    else {
      formError.zipCode = "";
    }
    this.setState(
      {
        formError,
      },
      () => {
        const isEmpty = Object.values(formError).every(
          (x) => x === null || x === ""
        );
        if (isEmpty) {
          let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phoneNo,
            email: this.state.email,
            period: parseInt(this.state.billingPeriod),
            subscriptionId: this.state.subscribeId,
            companyName: this.state.company,
            addresLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            state: this.state.state,
            countryName: this.state.countryCode,
            country: this.state.country,
            zipCode: this.state.zipCode,
          };
          localStorage.setItem("billingDetail", JSON.stringify(data));
          this.setState({
            showAddCardModal: true,
            compNo:15
          })
        }
      });
  }
  closeAddCardHandler = () => {
    this.setState({
      showAddCardModal: false,
      compNo:2
    })
  }
  cancelBillingConfig=()=>{
   
    this.setState({
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
      company: "",
      billingPeriod: "0",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      stateId: "",
      country: "",
      countryId: "",
      zipCode: "",
      subscribePlan: "",
      subscribePrice: "",
      subscribeId: "",
      selected: false,
      disableState: true,
      disableCity: true,
      formError: {
        firstName: "",
        lastName: "",
        phoneNo: "",
        email: "",
        company: "",
        billingPeriod: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
        subscribePlan: "",
        subscribePrice: "",
        invoiceTo: "",
      },
      showAddCardModal:false,
      compNo:2
    },()=>{
      localStorage.removeItem("billingDetail");
      this.fetch_autofil_detail();
      this.get_config_billing_enable();
    })
  }

  closeCardUpdateModal = () => {
    this.setState({
      showUpdateCardModal: false,
      isSubmit: false
    })
  }
  /** Benakesh code end */




  /** shashi code start */

  companyFilter = (event) => {
    this.setState(
      { pageNumber: 1, [event.target.name]: event.target.value, loading: true },
      this.filter
    );
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  handleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ pageNumber: newPage, loading: true },
      () => {
        this.filter();
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      loading: true,
      pageNumber: 1,
    },
      () => {
        this.filter();
      }
    );
  };
  handleStatusSelect = (item) => {
    var { statusId, statusFilterName, statusConfig } = this.state;
    statusId = item._id;
    statusFilterName = item.name;
    statusConfig.anchorElStatus = null;
    this.setState(
      {
        statusConfig,
        statusFilterName,
        statusId,
        pageNumber: 1,
      },
      () => {
        this.filter();
      }
    );
  };

  /**
   * @author shashi
   * To open Type filter menu
   */
  openTypeFilterMenu = (event) => {
    const { typeConfig } = this.state;
    typeConfig.anchorElStatus = event.currentTarget;
    this.setState({
      typeConfig,
    });
  };
  /**
   * @author shashi
   * To close Type filter menu
   */
  filter = () => {
    this.setState({ loading: true });
    let data = {
      page: this.state.pageNumber,
      pageSize: this.state.rowsPerPage,
      status: this.state.statusId,
      searchText: this.state.searchFilter,
      type: this.state.typeFilter,
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  closeTypeFilterMenu = () => {
    const { typeConfig } = this.state;
    typeConfig.anchorElStatus = null;
    this.setState({
      typeConfig,
    });
  };
  /**
   * @author shashi
   * To display data based on selected status.
   */
  handleTypeSelect = (item) => {
    var { typeFilter, typeFilterName, typeConfig } = this.state;
    typeFilter = item._id;
    typeFilterName = item.name;
    typeConfig.anchorElStatus = null;

    this.setState(
      {
        typeConfig,
        typeFilterName,
        typeFilter,
      },
      () => {
        this.filter();
      }
    );
  };
  /**
   * @author shashi
   * To open status filter menu
   */
  openStatusFilterMenu = (event) => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author shashi
   * To close status filter menu
   */
  closeStatusFilterMenu = () => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
    });
  };

  /**
  * @author shashi
  * For fetching billing overview details.
  */
  fetchBillingOverviewDetail = () => {
    let data = {
      subscriptionId: this.state.subscribeId,
    };
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription"
    )
      .post("/billing/overview", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            over_billingDetail: response.data[0].billingDetail,
            over_cardDetail: response.data[0].cardDetail,
            over_contact: response.data[0].contact,
            over_paymentHistory: response.data[0].paymentHistory,
            over_invoiceCC:response.data[0].invoiceCC?response.data[0].invoiceCC:[]
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  /**
  * @author shashi
  * For fetching billing History details.
  */
  history_FetchDetail = () => {
    this.setState({ loading: true }, () => {
      this.props.history_onPagination(
        this.state.pageNumber - 1,
        this.state.rowsPerPage,
        this.state.subscribeId,
      );
    });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }
  /**
  * @author shashi
  * For  (pagination) Next Page  History details.
  */
  history_handleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ pageNumber: newPage, loading: true });
    this.props.history_onPagination(newPage, this.state.rowsPerPage, this.state.subscribeId);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  /**
  * @author shashi
  * For  (pagination) Row Per Page  History details.
  */
  history_handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      loading: true,
      pageNumber: 1,
    });
    this.props.history_onPagination(1, +event.target.value, this.state.subscribeId);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  /**
    * @author shashi
    * For  Pdf.
    */

  PdfGenerator = () => {
    var doc = new jsPDF('p', 'pt');
    doc.text(20, 20, 'Billing')
    doc.text(40, 40, this.state.history_invoiceNumber)
    doc.save("Billing" + this.state.history_invoiceNumber + ".pdf")
  }
  /**
    * @author shashi
    * For  Pdf set Value.
    */
  downlod_pdf = (row) => {
    this.setState({
      history_billingCycle: row.billingCycle,
      history_createdAt: row.createdAt,
      history_invoiceNumber: row.invoiceNumber,
      history_startDate: row.startDate,
      history_status: row.status,
      history_subscription: row.subscription,
    },
      () => {
        this.PdfGenerator();
      }
    )
  }

  /**
   * @author shashi
   * For fetching billing payment status details.
   */
  paym_StatusFetchDetail = () => {
    this.setState({ loading: true }, () => {
      this.props.paym_statusPagination(
        this.state.pageNumber - 1,
        this.state.rowsPerPage,
        this.state.subscribeId,
      );
    });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  }

  /**
 * @author shashi
 * For  (pagination) Next Page  History details.
 */
  paym_StatusHandleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ pageNumber: newPage, loading: true },
      () => {
        this.pym_StatusFilter();
      }

    );
  };
  /**
  * @author shashi
  * For  (pagination) Row Per Page  History details.
  */
  paym_StatusHandleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      loading: true,
      pageNumber: 1,
    },
      () => {
        this.pym_StatusFilter();
      }
    );
  };
  pay_statusFilter = (e) => {
    this.setState({ pageNumber: 1, loading: true, [e.target.name]: e.target.value, },
      () => {
        this.pym_StatusFilter();
      }
    );
  }
  /**
     * @author shashi
     * To open status filter menu
     */
  paym_StatusOpenStatusFilterMenu = (event) => {
    const { paym_StatusConfig } = this.state;
    paym_StatusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      paym_StatusConfig,
    });
  };
  /**
     * @author shashi
     * To close status filter menu
     */
  paym_StatusCloseStatusFilterMenu = () => {
    const { paym_StatusConfig } = this.state;
    paym_StatusConfig.anchorElStatus = null;
    this.setState({
      paym_StatusConfig,
    });
  };
  /**
     * @author shashi
     * To Select status filter menu
     */
  paym_StatusHandleStatusSelect = (item) => {
    var { paym_statusStatusId, paym_status_StatusFilterName, paym_StatusConfig } = this.state;
    paym_statusStatusId = item._id;
    paym_status_StatusFilterName = item.name;
    paym_StatusConfig.anchorElStatus = null;

    this.setState(
      {
        paym_StatusConfig,
        paym_status_StatusFilterName,
        paym_statusStatusId,
        pageNumber: 1,
        loading: true
      },
      () => {
        this.pym_StatusFilter();
      }
    );
  };

  /**
       * @author shashi
       * To open Type filter menu
       */
  paym_StatusOpenTypeFilterMenu = (event) => {
    const { paym_StatusTypeConfig } = this.state;
    paym_StatusTypeConfig.anchorElStatus = event.currentTarget;
    this.setState({
      paym_StatusTypeConfig,
    });
  };
  /**
     * @author shashi
     * To close Type filter menu
     */
  paym_StatusCloseTypeFilterMenu = () => {
    const { paym_StatusTypeConfig } = this.state;
    paym_StatusTypeConfig.anchorElStatus = null;
    this.setState({
      paym_StatusTypeConfig,
    });
  };
  /**
     * @author shashi
     * To Select Type filter menu
     */
  paym_StatusHandleTypeSelect = (item) => {
    var { pym_StatusTypeId, pym_StatusTypeFilterName, paym_StatusTypeConfig } = this.state;
    pym_StatusTypeId = item._id;
    pym_StatusTypeFilterName = item.name;
    paym_StatusTypeConfig.anchorElStatus = null;

    this.setState(
      {
        paym_StatusTypeConfig,
        pym_StatusTypeFilterName,
        pym_StatusTypeId,
        pageNumber: 1,
        loading: true
      },
      () => {
        this.pym_StatusFilter();
      }
    );
  };
  /**
   * @author shashi
   * To open Billing Cycle filter menu
   */
  paym_StatusOpenBillingCycleFilterMenu = (event) => {
    const { paym_StatusBillingCycleConfig } = this.state;
    paym_StatusBillingCycleConfig.anchorElStatus = event.currentTarget;
    this.setState({
      paym_StatusBillingCycleConfig,
    });
  };
  /**
     * @author shashi
     * To close Billing Cycle filter menu
     */
  paym_StatusCloseBillingCycleFilterMenu = () => {
    const { paym_StatusBillingCycleConfig } = this.state;
    paym_StatusBillingCycleConfig.anchorElStatus = null;
    this.setState({
      paym_StatusBillingCycleConfig,
    });
  };
  /**
     * @author shashi
     * To Select Billing Cycle filter menu
     */
  paym_StatusHandleBillingCycleSelect = (item) => {
    var { pym_StatusBillingCycleId, pym_StatusBillingCycleFilterName, paym_StatusBillingCycleConfig } = this.state;
    pym_StatusBillingCycleId = item._id;
    pym_StatusBillingCycleFilterName = item.name;
    paym_StatusBillingCycleConfig.anchorElStatus = null;

    this.setState(
      {
        paym_StatusBillingCycleConfig,
        pym_StatusBillingCycleFilterName,
        pym_StatusBillingCycleId,
        pageNumber: 1,
        loading: true
      },
      () => {
        this.pym_StatusFilter();
      }
    );
  };
  onDateChanage = (dateString) => {
    this.setState(
      { loading: true, pageNumber: 1, pym_StatusdateFilter: { from: dateString[0], to: dateString[1] } },
      () => {
        this.pym_StatusFilter()
      }
    );
  };
  pym_StatusFilter = () => {
    let data = {
      page: this.state.pageNumber,
      pageSize: this.state.rowsPerPage,
      status: this.state.paym_statusStatusId,
      searchText: this.state.pay_statusSearchFilter,
      type: this.state.pym_StatusTypeId,
      startDate: momentTimeZone(this.state.pym_StatusdateFilter.from)
        .tz(this.state.timeZoneName),
      endDate: momentTimeZone(this.state.pym_StatusdateFilter.to)
        .tz(this.state.timeZoneName),
      billingCycle: this.state.pym_StatusBillingCycleId,
    };

    this.props.paym_statusFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }
  /**
       * @author shashi
       * To fetch company name and logo
       */
  compenyDetail = () => {
    let data = {
      subscriptionId: this.state.subscribeId,
    }
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : "company"
    )
      .post("/company/subscription/details", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            side_bar_header: response.data
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // let propserror = {
          //   message: response.msg,
          //   icon: <img src={iconNotificationError} alt="success" />,
          //   type: "error",
          //   placement: "topRight",
          //   duration: 3,
          //   top: 200,
          //   className: "task_notification error-notify",
          // };
          // NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });

  };
  /**
       * @author shashi
       * To Export Payment Status 
       */
  ExportPaymentStatus = () => {
    let data = {
      startDate: momentTimeZone(this.state.pym_StatusdateFilter.from)
        .tz(this.state.timeZoneName),
      endDate: momentTimeZone(this.state.pym_StatusdateFilter.to)
        .tz(this.state.timeZoneName),
      type: this.state.pym_StatusTypeId,
      billingCycle: this.state.pym_StatusBillingCycleId,
      status: this.state.paym_statusStatusId,
      searchText: this.state.pay_statusSearchFilter,
      timezone: this.state.timeZoneName,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : 'subscription', "application/json")
      .post("/billing/paymentStatus/export", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          exportToCSV(response.data, 'Payment Status' + Date.now())
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }
  /**
* @author shashi
* For fetching Subcrition details.
*/
  Subcription_Detail = () => {
    let data = {
      page: this.state.pageNumber,
      subscriptionId: this.state.sub_Details,
      billingId: this.state.billingId ? this.state.billingId : null
    };
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription"
    )
      .post("/subscription/details", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            accountName:response.data.employee.name,
            Sub_billing_Detail: response.data.billingDetail,
            Sub_contact_Details: response.data.contactDetails,
            Sub_payment_History: response.data.paymentHistory,
            Sub_payment_pager: response.data.pager,
            isData: response.data.contactDetails.firstName,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  /**
  * @author shashi
  * For fetching Pagination.
  */
  onPendingPagination = (event, newPage) => {
    this.setState({ pageNumber: newPage, loading: true },
      () => {
        this.Subcription_Detail();
      }
    );
  }
  /**
   * @author shashi
   * For Subcrition details switch Bill Cycle.
   */
  switch_sub_PaymentHandler = (sub_cycle, id) => {
    let cycle = sub_cycle === "Monthly" ? 1 : 0;
    let billingId = id;
    let data = {
      subscriptionId: this.state.sub_Details,
      billingCycle: cycle,
      billingId
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 6019
      : "subscription",
      "application/json")
      .put("/billing/switchBillingCycle", data)
      .then(resp => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
          })
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(propserror);
          this.Subcription_Detail();
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      }).catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }
  sub_detail_restriction = (no, rowid, show) => {
    if (show === true) {
      this.onClickHandler(no, rowid)
    } else {this.setState({showConform:true}) }

  }
   /**
   *
   * TO CONTACT STATUS MENU OPEN
   */
  openAccountFilterMenu = (event) => {
    const { accountConfig } = this.state;
    accountConfig.anchorElAccount = event.currentTarget;
    this.setState(
      {
        accountConfig,
      },
      () => {
        this.accountFilter("");
      }
    );
  };
   /**
   *
   * @param {item object} item
   * TO ADD CONTACT FILTER VALUE ON SELECT
   */
  handleAccountSelect = (item) => {
    const { accountConfig } = this.state;
    accountConfig.anchorElAccount = null;
    this.setState(
      {
        billingId: item._id,
        accountName: item.name === "All" ? "" : item.name,
        accountConfig,
        searchAccountValue: "",
        nextpage: 1,
      },
      () => {
        this.Subcription_Detail();
      }
    );
  };
   /**
   *
   * TO HANDLE CONTACT MENU CLOSE
   */
  closeAccountFilterMenu = () => {
    const { accountConfig } = this.state;
    accountConfig.anchorElAccount = null;
    this.setState({
      accountConfig,
    });
  };
   /**
   *
   * @param {item object} item
   * TO HANDLE CONTACT FILTER VALUE ON SEARCH
   */
  onAccountSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchAccountValue: value,
    });
    this.accountFilter(value);
  };
  accountFilter = (search) => {
    const { accountOption } = this.state;
    let data ={
      search:search,
      subscriptionId: this.state.sub_Details,
    }
    //AxiosInstance(process.env.REACT_APP_NODE_ENV === "development" ? 6019 : 'subscription', "application/x-www-form-urlencoded; charset=UTF-8")
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : 'subscription', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/subscription/billingUses", data)
      .then((resp) => {
        var response = resp && resp.data;

        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = accountOption.splice();
         
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item.billingId;
              obj.name = item.employeeName;
              newArray.push(obj);
            });
          }
          this.setState({
            accountOption: newArray,
          });
        } else {
          this.setState({
            accountOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
  };


  /**
* @author shashi
* For fetching billing invoice details.
*/
billing_invoice_detail = (rowId) => {
  let data = {
    id:rowId
  };
  AxiosInstance(
    (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : 'subscription', "application/x-www-form-urlencoded; charset=UTF-8")
    .post("/subscription/invoiceDetail", data)
    .then((resp) => {
      var response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {
        this.setState({
          Invoice_Detail:response.data,
        });
      } else if (
        response &&
        (response.status === "400" || response.status === 400)
      ) {
        let propserror = {
          message: response.msg,
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    })
    .catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (error && (error.status === "500" || error.status === 500)) {
        let propserror = {
          message: "Something went wrong",
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    });
};

Download_Invoice =(id)=>{
  let data = {
    id: id
  };
  AxiosInstance(
    process.env.REACT_APP_NODE_ENV === "development" ? 6019 : "Subscription"
  )
    .post("/subscription/invoicePdf", data)
    .then((resp) => {
      var response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {
      
      //  const url = window.URL.createObjectURL(new Blob([response.data]))
      //  const link = document.createElement('a')
      //  link.href = link
      //  link.setAttribute("bill","bill.pdf")
      //  document.body.appendChild(link)

      var FileSaver = require('file-saver');
      var blob = new Blob(response.data, {type: "application/pdf;charset=utf-8"});
      FileSaver.saveAs(blob, "Billing.pdf");

      } else if (
        response &&
        (response.status === "400" || response.status === 400)
      ) {
        let propserror = {
          message: response.msg,
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    })
    .catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (error && (error.status === "500" || error.status === 500)) {
        let propserror = {
          message: "Something went wrong",
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    });

}


  render() {
    // if(this.state.userData && (this.state.userData.role.toLowerCase() !== "super admin" || this.state.userData.role.toLowerCase() !== "admin")){
    //   return <PageNotFoundComponent/>
    // }else{
    return (
      <div className="body-bg-color billing-page">
        <HeaderComponent />
        <Dialog open={this.state.openCancel} className="deleteServiceDialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
        <DialogTitle>
          {" "}
    <center> Cancel Subscription</center>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <center>
              {" "}
    Are you sure you want to cancel this subscription?
            </center>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancel"
            onClick={this.handlePopConfirmClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={this.state.disableCancel} 
            className="delete"
            onClick={this.onPopConfirm}
            style={{ color: "red" }}
            autoFocus
          >
            {this.state.disableCancel ? <CircularProgress size={20} /> : ''} Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ViewFullEstimateComponent
        closeViewFullEstimateDialog={this.closeViewFullEstimateDialog}
        openViewFullEstimateDialog={this.state.openViewFullEstimateDialog}
        costSplitup={this.state.costSplitup}
      
      />
        <div className="billing_container">
          <div className="billing_sidebar">
            {this.state.userData && this.state.userData.role.toLowerCase() !== "super admin" && this.state.side_bar_header ?

              <div className="sidebar_header">
                {this.state.side_bar_header.type === 1 ?
                  <>
                    <div className="comp-image">
                      {this.state.side_bar_header.logo === undefined || this.state.side_bar_header.logo === "" || this.state.side_bar_header.logo === null ?
                        <img src={logodefaultimage} />
                        :
                        <img src={"https://salesc2companyprofile.s3.amazonaws.com/" + this.state.side_bar_header.logo} />
                      }
                    </div>
                    <h5 className="sidebar_companyname">{this.state.side_bar_header.name ? startCase(this.state.side_bar_header.name) : "--"}</h5>
                  </>
                  : this.state.side_bar_header.type === 2 ?
                    <div className="comp_container">
                      <div className="team_level">
                        <img src={
                          this.state.side_bar_header.level && this.state.side_bar_header.level.toLowerCase() === "division"
                            ? billing_division_logo
                            : this.state.side_bar_header.level && this.state.side_bar_header.level.toLowerCase() === "area"
                              ? billing_area_logo
                              : this.state.side_bar_header.level && this.state.side_bar_header.level.toLowerCase() === "region"
                                ? billing_region_logo
                                : closeBtn
                        } />
                      </div>
                      <div className="comp_title">
                        <h5>{this.state.side_bar_header.name ? startCase(this.state.side_bar_header.name) : "-"}</h5>
                        <p>{this.state.side_bar_header.level ? startCase(this.state.side_bar_header.level) : "-"}</p>
                      </div>
                    </div>
                    : this.state.side_bar_header.type === 3 ?
                      <div className="comp_container">
                        <img src={this.state.side_bar_header.logo ? "https://salesc2userprofile.s3.amazonaws.com/" + this.state.side_bar_header.logo : userIcon} />
                        <div className="comp_title">
                          <h5>{this.state.side_bar_header.name ? startCase(this.state.side_bar_header.name) : "-"}</h5>
                          <p>{this.state.side_bar_header.role ? startCase(this.state.side_bar_header.role) : "-"}</p>
                        </div>
                      </div>
                      : ""}
              </div>
              : ""}
            <BillingSideBarComponent
              compNo={this.state.compNo}
              showConfigure={this.state.showConfigure}
              onClickHandler={this.onClickHandler}
              userData={this.state.userData}
            />
          </div>
          <div className="billing_main_container">
              {this.state.billStatusNew === 1 || this.state.billStatusNew === 2 || this.state.billStatusNew === 3?
                <SubscriptionErrorMessageComponent
                  billStatusNew={this.state.billStatusNew}
                  openCardModal={this.openCardModalHandler}
                />
              :""}
               <Elements stripe={stripePromise}>
              <BillingMultipleCardComponent
                closeCardModalHandler={this.closeCardModalHandler}
                subscribeHandler={this.subscribeHandler}
                updateHandler={this.updateHandler}
                setCustomerData={this.setCustomerDataUpdate}
                subscribeNewHandler={this.subscribeNewHandler}
                billStatus={this.state.billStatusNew}
                openMulticardModal={this.state.openMulticardModal}
                card_header={this.state.card_header}
                sub_paymentId={this.state.sub_paymentId}
                multiCardArray={this.state.multiCardArray}
                radio_checked={this.state.radio_checked}
                radio_checked_id={this.state.radio_checked_id}
                cardErrors={this.state.cardErrors}
                showedit={this.state.showedit}
                edit_card_month={this.state.edit_card_month}
                edit_card_year={this.state.edit_card_year}
                edit_card_holder={this.state.edit_card_holder}
                editError={this.state.editError}
                onEditChange={this.onEditChange}
                onEditSaveHandler={this.onEditSaveHandler}
                onEditCancelHandler={this.onEditCancelHandler}
                removeCard={this.removeCardHandler}
                onCardEdit={this.onCardEdit}
                handleChangeCard={this.handleChangeCard}
                handleChangeExpiry={this.handleChangeExpiry}
                on_card_radio_check={this.on_card_radio_check}
                on_add_card_radio_check={this.on_add_card_radio_check}
              />
              </Elements>
          {this.state.showAddCardModal && this.state.compNo===15?
          <Elements stripe={stripePromise}>
            <CreditCardComponent
              handleChangeCard={this.handleChangeCard}
              handleChangeExpiry={this.handleChangeExpiry}
              cardErrors={this.state.cardErrors}
              setCustomerData={this.setCustomerData}
              showAddCard={this.state.showAddCard}
              submittedSave={this.state.submittedSave}
              openCardModal={this.state.showAddCardModal}
              closeCardModal={this.closeAddCardHandler}
              onSubscriptionSubmit={this.onSubscriptionSubmit}
              cancelBillingConfig={this.cancelBillingConfig}
              subscribePrice={this.state.subscribePrice}
            />
          </Elements>
          :""}

          <Elements stripe={stripePromise}>
            <CreditCardUpdateComponent
              handleChangeCard={this.handleChangeCard}
              handleChangeExpiry={this.handleChangeExpiry}
              cardErrors={this.state.cardErrors}
              setCustomerData={this.setCustomerDataUpdate}
              openCardUpdateModal={this.state.showUpdateCardModal}
              closeCardUpdateModal={this.closeCardUpdateModal}
              subscribePrice={this.state.subscribePrice}
              isSubmit={this.state.isSubmit}
            />
          </Elements>

          <BillingDetailsConfirmation
          open={this.state.showConform}
          handleClose={()=>this.setState({showConform:false})}
          />
          {this.state.compNo === 1 ? (
            <BillingOverviewComponent
            onViewEstimate={this.onViewFullDetail}
              billStatusNew={this.state.billStatusNew}
              billingDetail={this.state.over_billingDetail}
              estimateData={this.state.estimateData}
              cardDetail={this.state.over_cardDetail}
              contact={this.state.over_contact}
              paymentHistory={this.state.over_paymentHistory}
              over_invoiceCC={this.state.over_invoiceCC}
              timeZoneName={this.state.timeZoneName}
            />
          ) : this.state.compNo === 2 ? (
            <BillingDetailComponent
            onInvoiceChange={this.onInvoiceChange}
            invoiceTo={this.state.invoiceTo}
              openCardModalHandler ={this.openCardModalHandler}
              closeCardModalHandler={this.closeCardModalHandler}
              on_card_radio_check={this.on_card_radio_check}
              on_add_card_radio_check={this.on_add_card_radio_check}
              openMulticardModal={this.state.openMulticardModal}
              sub_paymentId={this.state.sub_paymentId}
              multiCardArray={this.state.multiCardArray}
              radio_checked={this.state.radio_checked}
              radio_checked_id={this.state.radio_checked_id}

              billStatusNew={this.state.billStatusNew}
              estimateData={this.state.estimateData}
              timeZoneName={this.state.timeZoneName}
              billingDetailArray={this.state.billingDetailArray}
              countryList={this.state.countryList}
              stateList={this.state.stateList}
              cityList={this.state.cityList}
              disableState={this.state.disableState}
              disableCity={this.state.disableCity}
              formError={this.state.formError}
              openBillingContactEditModal={this.state.openBillingContactEditModal}
              updateTitle={this.state.updateTitle}
              company={this.state.company}
              addressLine1={this.state.addressLine1}
              addressLine2={this.state.addressLine2}
              city={this.state.city}
              countryName={this.state.countryName}
              state={this.state.state}
              zipCode={this.state.zipCode}
              editType={this.state.editType}
              onChangeCountry={this.onChangeCountry}
              onChangeState={this.onChangeState}
              onChangeCity={this.onChangeCity}
              handleCountrySearch={this.handleCountrySearch}
              handleStateSearch={this.handleStateSearch}
              handleCitySearch={this.handleCitySearch}
              closeBillingContactEditModal={this.closeBillingContactEditModal}
              onViewEstimate={this.onViewFullDetail}
              switchPaymentHandler={this.switchPaymentHandler}
              handleChange={this.handleChange}
              onInvoiceSend={this.onInvoiceSend}
              onBillingDetailEdit={this.onBillingDetailEdit}
              onContactEdit={this.onContactEdit}
              onMakeMeBillingContact={this.onMakeMeBillingContact}
              onManageContact={this.onManageContact}
              updateBillingContactHandler={this.updateBillingContactHandler}
              userData={this.state.userData}
            />
          ) : this.state.compNo === 3 ? (
            <BillingHistoryComponent
            onClickHandler={this.onClickHandler}
              billStatusNew={this.state.billStatusNew}
              downlod_pdf={this.downlod_pdf}
              pageOfItems={this.props.pageOfItems}
              pager={this.props.pager}
              loading={this.state.loading}
              timeZoneName={this.state.timeZoneName}
              rowsPerPage={this.state.rowsPerPage}
              pageNumber={this.state.pageNumber}
              handleCustomPaginagtionChange={this.history_handleCustomPaginagtionChange}
              handleChangeRowsPerPage={this.history_handleChangeRowsPerPage}

            />
          ) : this.state.compNo === 4 ? (
            <BillingEstimateComponent
              billStatusNew={this.state.billStatusNew}
              estimateData={this.state.estimateData}
              timeZoneName={this.state.timeZoneName}
            />
          ) : this.state.compNo === 5 ? (
            <BillingConfigComponent
              name={this.state.firstName}
              phone={this.state.phoneNo}
              email={this.state.email}
              company={this.state.company}

              billingPeriod={this.state.billingPeriod}
              subscribePlan={this.state.subscribePlan}
              selected={this.state.selected}
              countryList={this.state.countryList}
              stateList={this.state.stateList}
              cityList={this.state.cityList}
              disableState={this.state.disableState}
              disableCity={this.state.disableCity}
              formError={this.state.formError}
              showAddCard={this.state.showAddCard}

              submittedSave={this.state.submittedSave}
              onChangeCountry={this.onChangeCountry}
              onChangeState={this.onChangeState}
              onChangeCity={this.onChangeCity}
              handleCountrySearch={this.handleCountrySearch}
              handleStateSearch={this.handleStateSearch}
              handleCitySearch={this.handleCitySearch}
              onSubscriptionSubmit={this.onSubscriptionSubmit}
              handleChange={this.handleChange}
              handleBillingPeriodChange={this.handleBillingPeriodChange}
              selectPlan={this.selectPlan}
              showAddCardHandler={this.showAddCardHandler}
              disableSave={this.state.disableSave}
            />
          ) : this.state.compNo === 6 ? (
            <BillingSubscriptionComponent
              billStatusNew={this.state.billStatusNew}
              sub_detail_restriction={this.sub_detail_restriction}
              statusOption={this.state.statusOptions}
              typeOptions={this.state.typeOptions}
              loading={this.state.loading}
              statusFilterName={this.state.statusFilterName}
              statusConfig={this.state.statusConfig}
              typeConfig={this.state.typeConfig}
              typeFilterName={this.state.typeFilterName}
              searchFilter={this.state.searchFilter}
              companyFilter={this.companyFilter}
              rowsPerPage={this.state.rowsPerPage}
              timeZoneName={this.state.timeZoneName}
              pageNumber={this.state.pageNumber}
              pageOfItems={this.props.pageOfItems}
              pager={this.props.pager}
              handleCustomPaginagtionChange={this.handleCustomPaginagtionChange}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleStatusSelect={this.handleStatusSelect}
              handleTypeSelect={this.handleTypeSelect}
              closeTypeFilterMenu={this.closeTypeFilterMenu}
              closeStatusFilterMenu={this.closeStatusFilterMenu}
              openTypeFilterMenu={this.openTypeFilterMenu}
              openStatusFilterMenu={this.openStatusFilterMenu}
            />
          ) : this.state.compNo === 7 ? (
            <ManageSubscriptionComponent
            onViewEstimate={this.onViewFullDetail}
              billStatusNew={this.state.billStatusNew}
              estimateData={this.state.estimateData}
              timeZoneName={this.state.timeZoneName}
              onManageUser={this.onManageUser}
              cancelSubscription={this.getConfirmPopup}
            // onChangePlan={this.onChangePlan}
            />
          ) : this.state.compNo === 9 ? (
            <BillingSubscriptionDetails
            onClickHandler={this.onClickHandler}
            onAccountSearchFilter={this.onAccountSearchFilter}
            closeAccountFilterMenu={this.closeAccountFilterMenu}
            handleAccountSelect={this.handleAccountSelect}
            searchAccountValue={this.state.searchAccountValue}
            accountConfig={this.state.accountConfig}
            assigneeConfig={this.state.assigneeConfig}
            accountName={this.state.accountName}
            accountOption={this.state.accountOption}
            openAccountFilterMenu={this.openAccountFilterMenu}
              billStatusNew={this.state.billStatusNew}
              Sub_billing_Detail={this.state.Sub_billing_Detail}
              Sub_contact_Details={this.state.Sub_contact_Details}
              Sub_payment_History={this.state.Sub_payment_History}
              timeZoneName={this.state.timeZoneName}
              pageNumber={this.state.pageNumber}
              Sub_payment_pager={this.state.Sub_payment_pager}
              onPendingPagination={this.onPendingPagination}
              switch_sub_PaymentHandler={this.switch_sub_PaymentHandler}
            />
          ) : this.state.compNo === 10 ?
            <BillingInvoiceComponent
            Invoice_Detail={this.state.Invoice_Detail}
            timeZoneName={this.state.timeZoneName}
            Download_Invoice={this.Download_Invoice}
            /> 
            : this.state.compNo === 8 ?
                            <BillingPaymentStatus
                              billStatusNew={this.state.billStatusNew}
                              paym_StatusCloseStatusFilterMenu={this.paym_StatusCloseStatusFilterMenu}
                              paym_StatusOpenStatusFilterMenu={this.paym_StatusOpenStatusFilterMenu}
                              paym_StatusHandleStatusSelect={this.paym_StatusHandleStatusSelect}
                              paym_status_StatusFilterName={this.state.paym_status_StatusFilterName}
                              paym_StatusConfig={this.state.paym_StatusConfig}
                              paym_StatusOpenTypeFilterMenu={this.paym_StatusOpenTypeFilterMenu}
                              paym_StatusCloseTypeFilterMenu={this.paym_StatusCloseTypeFilterMenu}
                              paym_StatusHandleTypeSelect={this.paym_StatusHandleTypeSelect}
                              paym_StatusTypeConfig={this.state.paym_StatusTypeConfig}
                              pym_StatusTypeFilterName={this.state.pym_StatusTypeFilterName}
                              paym_StatusOpenBillingCycleFilterMenu={this.paym_StatusOpenBillingCycleFilterMenu}
                              paym_StatusCloseBillingCycleFilterMenu={this.paym_StatusCloseBillingCycleFilterMenu}
                              paym_StatusHandleBillingCycleSelect={this.paym_StatusHandleBillingCycleSelect}
                              onDateChanage={this.onDateChanage}
                              paym_StatusBillingCycleConfig={this.state.paym_StatusBillingCycleConfig}
                              pym_StatusBillingCycleFilterName={this.state.pym_StatusBillingCycleFilterName}
                              loading={this.state.loading}
                              pay_statusFilter={this.pay_statusFilter}
                              rowsPerPage={this.state.rowsPerPage}
                              pageNumber={this.state.pageNumber}
                              pageOfItems={this.props.pageOfItems}
                              pager={this.props.pager}
                              timeZoneName={this.state.timeZoneName}
                              handleCustomPaginagtionChange={this.paym_StatusHandleCustomPaginagtionChange}
                              handleChangeRowsPerPage={this.paym_StatusHandleChangeRowsPerPage}
                              ExportPaymentStatus={this.ExportPaymentStatus}
                              tooltipprops={this.state.tooltipprops}

                            />
                           : (
                              ""
                            )}
          </div>
        </div>
      </div>
    );
    // }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole) =>
      dispatch(action.SubscPagination(pageNo, pagerole)),
    onFilter: (states) => dispatch(action.SubscFilter(states)),

    history_onPagination: (pageNo, pagerole, subscribeId) =>
      dispatch(action.historyPagination(pageNo, pagerole, subscribeId)),
    history_onFilter: (states) => dispatch(action.historyFilter(states)),

    paym_statusPagination: (pageNo, pagerole, subscribeId) =>
      dispatch(action.paym_statusPagination(pageNo, pagerole, subscribeId)),
    paym_statusFilter: (states) => dispatch(action.paym_statusFilter(states)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BillingComponent));
