import axios from "axios";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
export function getOS() {
  if (MobileView && isMobile) {
    return 'mobile';
  }
  if (BrowserView && isBrowser) {
    return 'desktop';
  }
}
export const NOTIFICATION_ENDPOINT = process.env.REACT_APP_NODE_ENV === "development" ? "https://api.salesc2-dev.demo-fsit.com:7014/" : process.env.REACT_APP_NODE_ENV === "qa" ? "https://api.salesc2-dev.demo-fsit.com:7014/" : process.env.REACT_APP_NODE_ENV === "stage" ? "https://socket-stage.salesc2.com:8114/" : "https://socket.salesc2.com:8114/";
export const basedUrlForSuperAdmin = process.env.REACT_APP_NODE_ENV === "development" ? "https://api.salesc2-dev.demo-fsit.com:" : process.env.REACT_APP_NODE_ENV === "qa" ? "http://34.236.38.97:" : process.env.REACT_APP_NODE_ENV === "stage" ? "https://stage-api.salesc2.com/" : "https://api.salesc2.com/";
export const AxiosInstance = (port, contentType) => {
  const Axios = axios.create({
    baseURL: process.env.REACT_APP_NODE_ENV === "development"
      // ? `http://127.0.0.1:${port}/api/v1`
      ? `https://api.salesc2-dev.demo-fsit.com:${port}/api/v1`
      : process.env.REACT_APP_NODE_ENV === "qa"
        ? `http://34.236.38.97:${port}/api/v1`
        : process.env.REACT_APP_NODE_ENV === "stage"
          ? `https://stage-api.salesc2.com/${port}/api/v1`
          : process.env.REACT_APP_NODE_ENV === "production" ?
            `https://api.salesc2.com/${port}/api/v1` : ""
  });
  Axios.defaults.headers.common["Authorization"] = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : null;
  Axios.defaults.headers.common["Content-Type"] = contentType;
  Axios.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return Axios;
};

export const checkTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (timeZone === localStorage.getItem("timeZone")) {
    localStorage.setItem("commonTimeZone", true)
    localStorage.setItem("closePopup", true)
  } else {
    localStorage.setItem("commonTimeZone", false)
    localStorage.setItem("closePopup", false)
  }
}
export const checkCmsLabel = () => {
  const userData = JSON.parse(localStorage.getItem("userdata"))
  if (userData.role !== "Super Admin" && localStorage.getItem('cms') && localStorage.getItem('cms') != "undefined" && "companyId" in JSON.parse(localStorage.getItem('cms'))) {
    const labelChange = (JSON.parse(localStorage.getItem('cms')))?.labelChange?.label
    const roleChange = (JSON.parse(localStorage.getItem('cms')))?.roleChange?.label
    const cmsData = []
    const roleData = []
    labelChange.forEach((item) => {
      let key = Object.keys(item)[0];
      let value = item[key];
      key = key.replace(/\s/g, '');
      value = value.charAt(0).toUpperCase() + value.slice(1)
      cmsData[key] = value;
    });

    roleChange.forEach((item) => {
      let key = Object.keys(item)[0];
      let value = item[key];
      key = key.replace(/\s/g, '');
      value = value.charAt(0).toUpperCase() + value.slice(1)
      roleData[key] = value;
    });

    return { cmsData: cmsData, roleData: roleData }
  }
}