import React, { Component,Suspense } from "react";
import { Button, FormControl, TextField, Dialog, DialogContent, DialogTitle, Select, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SuperadminDepartmentComponent from "./SuperadminDepartmentComponent";
import AutocompleteMap from "./AutocompleteMap";
import addAccountIcon from "../../Assets/images/add_account_icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import add_dept_icon from "../../Assets/images/add_dept_icon.svg";
import IconButton from '@material-ui/core/IconButton';
import { AxiosInstance } from "../../Utilities";
import "./AccountsComponent.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import { Autocomplete } from "@material-ui/lab";
import { Scrollbars } from 'react-custom-scrollbars';
import { Transition} from '../../SharedComponents/CommonUtilities/CommonUtilities';
var AutoCompleteMapComponent = React.lazy(() =>
  import(
    "../../SharedComponents/AutoCompleteMapComponent/AutoCompleteMapComponent"
  )
);

const CssTextField = withStyles({
  root: {

    '& .MuiInput-underline:after': {
      borderBottomColor: '#14193A',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E2E5F2',
        borderWidth: 1
      },

      '&.Mui-focused fieldset': {
        borderColor: '#14193A',
        borderWidth: 1
      },
    },
  },
})(TextField, Select);
var i = 0;
export class AddAccountSuperadminModal extends Component {
  constructor(props) {
    super(props);
    let User = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      role: User.role,
      accountName: '',
      dialog: false,
      accountAddress: '',
      accountWebsite: '',
      accountDescription: '',
      filters: {
        company: [],
        area: [],
        division: [],
        region: [],
        territory: [],
      },
      departments: [
        {
          index: Math.random(),
          deptName: "",
        },
      ],
      formError: {
        accountName: '',
        accountAddress: '',
      },
      isValid: false,
      anchorEl: false,
      anchorElData: "",
      parentOption: [{
        id: "",
        name: "No Data",
      }],
      parentId: "",
      parentName: "",
      selectedParent: "",
      autocompleteVisible: false,
      city: '',
      area: '',
      state: '',
      zipcode: '',
      mapPosition: {
        lat: '',
        lng: ''
      },
      markerPosition: {
        lat: '',
        lng: ''
      }
    };
    this.addRowHandler = this.addRowHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.openParent = this.openParent.bind(this);
    this.closeParent = this.closeParent.bind(this);
    this.parentListHandler = this.parentListHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.autocompleteClose = this.autocompleteClose.bind(this);
    // this.AutocompleteHandler = this.AutocompleteHandler.bind(this);
  }
  componentDidMount = () => {
    let data = {
      roles: this.state.role,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6006:'user', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/users/filters", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          this.setState({ filters: response.data });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  };
  addRowHandler = (e) => {
    this.setState(
      (prevState) => ({
        departments: [...prevState.departments, {
          index: Math.random(),
          deptName: "",
          contacts: [
            {
              index: Math.random(),
              cname: "",
              phone: [{ index: Math.random(), phoneNo: "", phoneType: "work" }],
              email: [{ index: Math.random(), emailid: "", emailType: "work" }],
              isPrimary: false,
            },
          ],
        },
        ],
      }),
      () => {
        i++;
      }
    );
  };
  deleteRowHandler = (index) => {
    const taskList1 = this.props.modeldata.departments;
    taskList1.splice(index, 1);
    this.props.deleteHandler(taskList1)
  };
  addContHandler = (id) => {
    const elementsIndex = this.state.departments.findIndex(element => element.index === id);
    let newArray = [...this.state.departments];
    let newId = newArray[elementsIndex].contacts.length;
    newArray[elementsIndex].contacts = Object.values({
      ...newArray[elementsIndex].contacts, [newId]: {
        index: Math.random(),
        cname: "",
        phone: [{ index: Math.random(), phoneNo: "", phoneType: "work" }],
        email: [{ index: Math.random(), emailid: "", emailType: "work" }],
        isPrimary: false
      }
    })
    this.setState({ departments: newArray });
  };
  deleteContactRowHandler = (depId, index) => {
    const elementsIndex = this.state.departments.findIndex(element => element.index === depId);
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  addPhoneHandler = (did, cid) => {
    const deptIndex = this.state.departments.findIndex(element => element.index === did);
    let newArray = [...this.state.departments];
    let newId = newArray[deptIndex].contacts[cid].phone.length;
    newArray[deptIndex].contacts[cid].phone = Object.values({
      ...newArray[deptIndex].contacts[cid].phone, [newId]: {
        index: Math.random(), phoneNo: "", phoneType: "work"
      }
    })
    this.setState({ departments: newArray });
  };
  deletePhoneRowHandler = (depId, contId, index) => {
    const elementsIndex = this.state.departments.findIndex(element => element.index === depId);
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts[contId].phone.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  addEmailHandler = (did, cid) => {
    const deptIndex = this.state.departments.findIndex(element => element.index === did);
    let newArray = [...this.state.departments];
    let newId = newArray[deptIndex].contacts[cid].email.length;
    newArray[deptIndex].contacts[cid].email = Object.values({
      ...newArray[deptIndex].contacts[cid].email, [newId]: {
        index: Math.random(), emailid: "", emailType: "work"
      }
    })
    this.setState({ departments: newArray });
  };
  deleteEmailRowHandler = (depId, contId, index) => {
    const elementsIndex = this.state.departments.findIndex(element => element.index === depId);
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts[contId].email.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  onChangeHandler = (e) => {
    e.preventDefault();
    if (["deptName"].includes(e.target.name)) {
      let departments = [...this.state.departments];
      departments[e.target.dataset.id][e.target.name] = e.target.value;
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  onclickBtn = (e) => {
    const {accountName } = this.state;
    if (accountName === '') {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }

  }
  openParent = (event) => {
    this.setState({ autocompleteVisible: true });
  }
  closeParent = () => {
    this.setState({ anchorEl: false, anchorElData: "" });
  }
  parentListHandler = async (event) => {
    var name = event.target.value;
    var data = {
      searchText: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/parentAccount", data).then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          this.setState({
            parentOption: response.data,
          });
        } else {
          this.setState({
            parentOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
    var { parentOption } = this.state;
    var datas = await parentOption.filter((item) => {
      return item.name === name;
    });
    datas.map((parentData) => {
      this.setState({
        parentId: parentData.id,
        parentName: parentData.name,
      });
    });

  }
  onSelectHandler = (e) => {
    var name = e.target.value;
    var { parentOption } = this.state;
    var data = parentOption.filter((item) => {
      return item.name === name;
    });
    data.map((parentData) => {
      this.setState({
        parentId: parentData.id,
        parentName: parentData.name,
        selectedParent: parentData.name,
      },()=>{
        this.props.sendData({
          parentIds:this.state.parentId,
        })
      });
    });
  };

  autocompleteClose = (event) => {
    var name = event.target.value;
    if (typeof name === "undefined") {
      this.setState({ autocompleteVisible: false });
    }
  }
  AutocompleteHandler = (place) => {
    
    this.setState({ accountAddress: place.address, city: place.city, state: place.state, zipcode: place.zipcode, mapPosition: place.mapPosition})
  }
  onlevelClose = () => {
    this.setState({
      dialog: !this.state.dialog,
    });
  };
  onLevel = () => {
    this.setState({
      dialog: true,
    });
  }
  onCloseModal = () => {
    this.setState({ isValid: false,accountName:'' }, () => {
      this.props.onClose();
    });
  };
  render() {
     const { departments } = this.state;
    return (
      <div>
        <Dialog {...this.props} 
          aria-labelledby="form-dialog-title" 
          maxWidth='md' 
          className="add_company_dialog add_account_dialog" 
          TransitionComponent={Transition} 
          transitionDuration={600}>
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <img className="add-company_img" src={addAccountIcon} alt="add" />
            <span>Add Account</span>
            <IconButton onClick={this.onCloseModal} className="closeBtn" aria-label="show 17 new notifications" color="inherit">
              <img src={closeBtn}></img>
            </IconButton>
          </DialogTitle>
          <Scrollbars className="custom_scrollbar" style={{ width: "auto", height: 680}}>
          <DialogContent>
            <form onSubmit={this.props.onAccountSubmit}>
              <label className={this.state.autocompleteVisible === false ? "" : "hide"}>Name <span className="labelAsterisk label bl-label">*</span></label>
              <div className={this.state.autocompleteVisible === false ? "custom_upload add_account_name" : "hide"}>
              <span className="add_account_name_input">
                <CssTextField className="account_custom_upload" id="outlined-basic" name='accountName' variant="outlined" autoFocus margin="dense" type="text"
                   defaultValue={this.props.pend_reg ? this.props.pend_reg.name:""}
                  placeholder="Enter name" error={this.state.accountName === '' && this.state.isValid ? true : false} value={this.props.modeldata.accountName} onChange={this.props.onChangeHandler} />
                  <span className="error">{this.props.modeldata.formError.accountName !== '' ?  this.props.modeldata.formError.accountName  : ''}</span>
                  </span>
                  <span className="add_team_btn">
                <Button className="upload_btn primary-btn" variant="contained" onClick={this.openParent}>
                  Select Parent
           </Button>
           </span>
                <Menu
                  id="customized-menu"
                  anchorEl={this.state.anchorElData}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  keepMounted
                  open={this.state.anchorEl}
                  onClose={this.closeParent}
                >
                </Menu>
              </div>
              <div className={this.state.autocompleteVisible === false ? "hide" : ""}>
                <Grid container spacing={2} >
                  <Grid item xs={12} sm={6} style={{paddingBottom: 0}}>
                    <label className="label bl-label">Name <span className="labelAsterisk">*</span></label>
                    <CssTextField className="account_custom_upload" id="outlined-basic" value={this.props.modeldata.accountName} name='accountName' variant="outlined" autoFocus margin="dense" fullWidth type="text"
                       defaultValue={this.props.pend_reg ? this.props.pend_reg.name :""}
                      placeholder="Enter name" error={this.state.formError.accountName ? <span style={{ color: 'red', fontSize: '16px' }} >{this.state.formError.accountName}</span> : ''} onChange={this.props.onChangeHandler} />
                    {this.props.modeldata.formError.accountName !== '' ? <span className="error">{this.props.modeldata.formError.accountName}</span> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className="label bl-label">Parent Account Name</label>
                    <Autocomplete
                      id="parentName"
                      freeSolo
                      className="parent-autocomplete"
                      options={this.state.parentOption}
                      getOptionLabel={(option) => option.name}
                      onInputChange={this.autocompleteClose}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          name="parentName"
                          placeholder="Search here"
                          onChange={this.parentListHandler}
                          onSelect={this.onSelectHandler}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <label className="label bl-label">Website</label><CssTextField id="outlined-basic" name='accountWebsite' variant="outlined" margin="dense" type="text" fullWidth placeholder="Enter website" onChange={this.props.onChangeHandler} />
                  <span className="error">{this.props.modeldata.formError.accountWebsite ? this.props.modeldata.formError.accountWebsite : ''}</span>
                </Grid>
              </Grid>
              <label className="label bl-label">Address <span className="labelAsterisk">*</span></label>
             
             
              {/* <AutocompleteMap
                google={this.props.google}
                center={{ lat: 18.5204, lng: 73.8567 }}
                height='300px'
                zoom={15}
                 name="accountAddress"
                margin="dense"
                AutocompleteHandler={this.props.AutocompleteHandler}
              /> */}
               <Suspense fallback={<div></div>}>
                  <AutoCompleteMapComponent
                    onPlaceSelect={this.props.onGooglePlaceSelect}
                  />
                </Suspense>
              <span className="error">{this.props.modeldata.formError.accountAddress ? this.props.modeldata.formError.accountAddress : ''}</span>
              <label className="label bl-label">Description</label><CssTextField id="outlined-basic" rowsMax={3} multiline name='accountDescription' variant="outlined" margin="dense" type="text" fullWidth placeholder="Enter Description" className="Desc_height text_area" onChange={this.props.onChangeHandler} />
              <SuperadminDepartmentComponent
                departments={this.props.modeldata.departments ? this.props.modeldata.departments : []}
                addRow={this.props.addRowHandler}
                deleteRow={this.deleteRowHandler.bind(this)}
                onChangeHandler={this.props.onChangeHandler}
              />
               <span className="error">{this.props.modeldata.depError}</span>
              <Button className='add_dept_btn' variant="outlined" onClick={this.props.addRowHandler}><img src={add_dept_icon} alt="add dept"/>Add department</Button>
              <div className="add_company_action">
                <Button className="cancel_btn white-btn" onClick={this.onCloseModal} variant="outlined">
                  Cancel
                    </Button>
                <Button className="ok_btn green-btn" type="submit" variant="contained" onClick={this.props.onclickBtn} disabled={this.props.modeldata.isSubmit ||this.props.modeldata.isdep}>
                  {this.props.modeldata.isSubmit ? <CircularProgress size={20} /> : ''} Add Account
          </Button>
              </div>
            </form>
          </DialogContent>
          </Scrollbars>
        </Dialog>
        <Dialog
         onExit={this.clearForm}
          open={this.state.dialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog"
          TransitionComponent={Transition} 
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <span>Add Level</span>
            <IconButton
              onClick={this.onlevelClose}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn} alt="close"/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <label className="label bl-label">Area</label>
            <FormControl variant="outlined" fullWidth>
              <Select
                margin="dense"
                className="customSelect"
                name="area"
                fullWidth
                onChange={this.onLevelChangeHandler}
              >
              </Select>
            </FormControl>
            <label className="label bl-label">Region</label>
            <FormControl variant="outlined" fullWidth>
              <Select
                margin="dense"
                className="customSelect"
                name="region"
                fullWidth
                onChange={this.onLevelChangeHandler}
              >
              </Select>
            </FormControl>
            <label className="label bl-label">Territory</label>
            <FormControl variant="outlined" fullWidth>
              <Select
                margin="dense"
                className="customSelect"
                name="territory"
                fullWidth
                onChange={this.onLevelChangeHandler}
              >
              </Select>
            </FormControl>
            <div className="add_company_action white-btn">
              <Button
                className="cancel_btn white-btn"
                onClick={this.onlevelClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="ok_btn green-btn"
                onClick={this.addLevelOnClick}
                variant="contained"
              >
                Add Level
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
