import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Select,
  Grid,
} from "@material-ui/core";
// import profile_img from "../../../Assets/images/dummy-profile-image.png";
import credit_card_img from "../../../Assets/images/credit_card_icon.svg";
import info_img from "../../../Assets/images/info-circle.svg";
import bill_edit_img from "../../../Assets/images/bill_edit_icon.svg";
import momentTimeZone from "moment-timezone";
import BillingContactEditModal from "./BillingContactEditModal/BillingContactEditModal";
import {startCase, upperFirst} from 'lodash';
import { ReactMultiEmail, isEmail} from 'react-multi-email';
import 'react-multi-email/style.css';
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
const BillingDetailComponent = (props) => {
  return (
    <div className="billing_page billing_overview billing_detail">
      <BillingContactEditModal
      openBillingContactEditModal={props.openBillingContactEditModal}
      updateTitle={props.updateTitle}
      countryList={props.countryList}
      disableCity={props.disableCity}
      cityList={props.cityList}
      stateList={props.stateList}
      formError={props.formError}
      disableState={props.disableState}
      company={props.company}
      addressLine1={props.addressLine1}
      addressLine2={props.addressLine2}
      city={props.city}
      countryName={props.countryName}
      state={props.state}
      zipCode={props.zipCode}
      editType={props.editType}
      closeBillingContactEditModal={props.closeBillingContactEditModal}
      handleChange={props.handleChange}
      handleCountrySearch={props.handleCountrySearch}
      handleStateSearch={props.handleStateSearch}
      handleCitySearch={props.handleCitySearch}
      onChangeCountry={props.onChangeCountry}
      onChangeState={props.onChangeState}
      onChangeCity={props.onChangeCity}
      updateBillingContactHandler={props.updateBillingContactHandler}

      />
      <h3 className="billing_page_title">Billing Details</h3>
      <div className="billing_overview_sections">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div className="billing_overview_section">
              <div className="bill_details_container">
                <div>
                  <h5 className="bill_period_heading">Billing Period</h5>
                  <h5 className="bill_period">
                    {props.billingDetailArray &&
                    props.billingDetailArray.length > 0
                      ? props.billingDetailArray[0].billingContact.billingDetail
                          .billingCycle === 0
                        ? "Monthly"
                        : "Yearly"
                      : "-"}
                  </h5>
                </div>
                <button 
                  disabled={
                    (props.billingDetailArray &&
                      props.billingDetailArray.length > 0 && props.billingDetailArray[0].billingContact.billingDetail
                            .billingCycle === 1 ) && (props.userData && props.userData.role.toLowerCase() !== "super admin")?true:false
                  }
                className="switch_annual_paymt" onClick={()=>props.switchPaymentHandler(props.billingDetailArray[0].billingContact)} 
                >
                  Switch to {props.billingDetailArray &&
                    props.billingDetailArray.length > 0
                      ?props.billingDetailArray[0].billingContact.billingDetail
                          .billingCycle === 1
                        ? "monthly"
                        : "yearly":'-'} payment
                </button>
              </div>
              <h5 className="overview_section_heading">Billing Details</h5>
              <div className="bill_details_card_container">
                <div className="bill_details_image_section">
                  <img
                    src={credit_card_img}
                    alt="profile-image"
                    className="bill_contact_card_image"
                  />
                </div>
                <div className="bill_details_section">
                  <h4 className="bill_details_card_heading">Credit card</h4>
                  <h6 className="bill_details_card_number">
                    <span>.... .... .... </span>
                    {props.billingDetailArray &&
                    props.billingDetailArray.length > 0
                      ? props.billingDetailArray[0].billingContact.cardDetail.lastDigit
                      : "-"}
                  </h6>
                  <h6 className="bill_details_card_holder">
                    {props.billingDetailArray &&
                    props.billingDetailArray.length > 0
                      ? upperFirst(props.billingDetailArray[0].billingContact.cardDetail.cardHolder)
                      : "-"}
                  </h6>
                  <h6 className="bill_details_card_expire">
                    Expires{" "}
                    {props.billingDetailArray &&
                    props.billingDetailArray.length > 0
                      ? props.billingDetailArray[0].billingContact.cardDetail.expire
                      : "-"}
                  </h6>
                </div>
                <Button className="bill_edit_btn" onClick={()=>props.onBillingDetailEdit(props.billingDetailArray[0].billingContact)}>
                  <img src={bill_edit_img} alt="edit-image"/>
                  Edit
                </Button>
              </div>
              <h5 className="overview_section_heading">Contact Details</h5>
              <div className="contact_detail_main">
                <h5 className="contact_detail_main_header">Billing Contact</h5>
                <div className="bill_contact_container">
                  <div className="bill_contact_content_section">
                    <h4 className="bill_contact_username">
                      {props.billingDetailArray &&
                      props.billingDetailArray.length > 0
                        ? upperFirst(props.billingDetailArray[0].billingContact.firstName) +
                          " " +
                          upperFirst(props.billingDetailArray[0].billingContact.lastName)
                        : "-"}
                    </h4>
                    <h6 className="bill_contact_email">
                      {props.billingDetailArray &&
                      props.billingDetailArray.length > 0
                        ? props.billingDetailArray[0].billingContact.email
                        : "-"}
                    </h6>
                  </div>
                </div>
                <div className="contact_detail_main_values">
                  <h5 className="contact_detail_main_key">Company name</h5>
                  <p className="contact_detail_main_key_info">
                    {props.billingDetailArray &&
                    props.billingDetailArray.length > 0
                      ? upperFirst(props.billingDetailArray[0].billingContact.companyName)
                      : "-"}
                  </p>
                  <h5 className="contact_detail_main_key">Company address</h5>
                  <p className="contact_detail_main_key_info">
                    {props.billingDetailArray &&
                    props.billingDetailArray.length > 0
                      ? upperFirst(props.billingDetailArray[0].billingContact.addresLine1) +
                        " " +upperFirst(props.billingDetailArray[0].billingContact.addressLine2) +
                        " " +
                        props.billingDetailArray[0].billingContact.state +
                        " " +
                        props.billingDetailArray[0].billingContact.city +
                        " " +
                        props.billingDetailArray[0].billingContact.country +
                        " " +
                        props.billingDetailArray[0].billingContact.zipCode
                      : "-"}
                  </p>
                  <Button className="bill_edit_btn" onClick={()=>props.onContactEdit("Billing Contact",props.billingDetailArray[0].billingContact)}>
                    <img src={bill_edit_img} alt="edit-image" />
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="billing_overview_section billing_estimate">
              <h5 className="overview_section_heading">Billing Estimate</h5>
              <span className="billing_estimate_date">
                {props.estimateData.startDate
                  ? momentTimeZone(props.estimateData.startDate)
                      .tz(props.timeZoneName)
                      .format("MMM DD, YYYY")
                  : "-"}
                &nbsp;-&nbsp;
                {props.estimateData.endDate
                  ? momentTimeZone(props.estimateData.endDate)
                      .tz(props.timeZoneName)
                      .format("MMM DD, YYYY")
                  : "-"}
              </span>
              <div className="billing_estimate_price_secion">
                <div className="billing_estimate_price_plans_main">
                  <div className="billing_estimate_price_plans">
                    <p className="billing_estimate_price_plan">
                      {props.estimateData.plan ? startCase(props.estimateData.plan) : "-"}
                    </p>
                    <p className="billing_estimate_price_plan_amount">
                      ${" "}
                      {props.estimateData.planCharge
                        ? parseFloat(props.estimateData.planCharge).toFixed(2)
                        : 0.0}
                    </p>
                  </div>
                  <span className="billing_estimate_price_plan_duration">
                  {props.estimateData.usersCount?props.estimateData.usersCount:0} User / {props.estimateData.billingCycle === 0
                      ? "Monthly"
                      : "Yearly"}
                  </span>
                </div>
                <div className="billing_estimate_price_tax ">
                  <p>Exclusive of Taxes</p>
                  <p>
                    ${" "}
                    {props.estimateData.estimatedAmt
                      ? parseFloat(props.estimateData.estimatedAmt).toFixed(2)
                      : 0.0}
                  </p>
                </div>
                {/* <div className="billing_estimate_price_tax billing_estimate_amount">
                  <p>TAX ({props.estimateData.taxPercent?parseFloat(props.estimateData.taxPercent):0}%)</p>
                  <p>
                    ${" "}
                    {props.estimateData.taxAmount
                      ? parseFloat(props.estimateData.taxAmount).toFixed(2)
                      : 0.0}
                  </p>
                </div> */}

                <div className="billing_estimate_price_total">
                  <p>TOTAL</p>
                  <p className="billing_estimate_price_total_amount">
                    USD{" "}
                    {props.estimateData.estimatedAmt
                      ? parseFloat(props.estimateData.estimatedAmt).toFixed(2)
                      : 0.0}
                  </p>
                </div>
              </div>
              <Button
                className="view_full_estimate_btn logout-button"
                onClick={props.onViewEstimate}
              >
                View full estimate
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div className="add_company_dialog">
              <div className="billing_overview_section billing_send_invoice">
                <label className="label bl-label billing_send_invoice_label">
                  Send copies of invoices to{" "}
                  <span className="labelAsterisk">*</span>
                </label>
                {/* <CssTextField
                  name="invoiceTo"
                  variant="outlined"
                  margin="dense"
                  type="text"
                  fullWidth
                  placeholder="Enter email"
                  onChange={props.handleChange}
                /> */}
               <ReactMultiEmail
                    placeholder="Enter email address and press enter"
                    onChange={props.onInvoiceChange}
                    emails={props.invoiceTo}
                    getLabel={(
                      ccEmails,
                      index,
                      removeEmail,
                    ) => {
                      return (
                        <div data-tag key={index}>
                          {ccEmails}
                          <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                {props.formError.invoiceTo !== ""?
                <span className="error">
                  *{props.formError.invoiceTo}
                </span>:""}
                <div className="add_company_action">
                  <Button
                    className="ok_btn green-btn"
                    type="submit"
                    variant="contained"
                    onClick={props.onInvoiceSend}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BillingDetailComponent;
