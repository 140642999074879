import React, { useEffect, useState } from "react";
import "./CMSComponent.css";
import Grid from "@material-ui/core/Grid";
import iconlyLightEdit from "../../../Assets/images/Iconly-Light-Edit-Square.svg";
import SaveComponent from "./SaveComponent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Select } from "antd";
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    overflow: "initial",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#21BF73",
        border: `1px solid #21BF73`,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);

const AccountLabelComponent = ({
  value,
  cmsLabelData,
  cmsRoleLabelData,
  getCancelNoteList,
}) => {
  const [indexValue, setIndexValue] = useState(value);
  const [labelList, setLabelList] = useState(cmsLabelData);
  const [roleLabel, setRoleLabel] = useState(cmsRoleLabelData);
  const [listData, setListData] = useState([]);
  // let key
  // let patientSwitch = false
  // let caseNumberSwitch = false

  useEffect(() => {
    setListData(value === 2 ? cmsLabelData : cmsRoleLabelData);
  }, [cmsLabelData, cmsRoleLabelData]);

  const labelChangeHandler = (e, key, id) => {
    const { value } = e.target;
    if (indexValue === 2) {
      const temp = labelList?.map((list, index) =>
        index === id ? { ...list, [key]: value, isEdited: true } : list
      );
      setLabelList(temp);
      setListData(temp);
    } else {
      const temp = roleLabel?.map((list, index) =>
        index === id ? { ...list, [key]: value, isEdited: true } : list
      );
      setRoleLabel(temp);
      setListData(temp);
    }
  };

  const handleChecked = (e,key) =>{
    // if(e.target.name === "patientName" && e.target.checked === true){
    //   patientSwitch = true
    // } else if(e.target.name === "caseNumber" && key === true){
    //   caseNumberSwitch = true
    // } else{
    //   patientSwitch = false
    //   caseNumberSwitch = false
    // }
    if (indexValue === 2) {
      const temp = [...labelList]
      temp.forEach((list, index) => {
        if (list.value === e.target.name) {
          list.checked = !list.checked;
        }
      }
      );
      setLabelList(temp);
      setListData(temp);
    }
  }

  return (
    <>
      <Grid container spacing={2} className="cms_container">
        {listData &&
          listData.length > 0 &&
          listData.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <div className="form-group">
                {(item.value==="patientName"|| item.value==="caseNumber" || item.value === "price"|| item.value === "quantity") ?
                <div>
                  <label variant="h6">{Object.keys(item)[0]}</label>
                  <FormControlLabel
                    className="formControlLabel"
                    name={item?.value}
                    style ={{cursor:"pointer"}}
                    control={<AntSwitch color="primary" />}
                    onChange={handleChecked}
                    margin="dense"
                    labelPlacement="start"
                    checked={item.checked==true?true:false}
                  />
                </div>:
                <label variant="h6">{Object.keys(item)[0]}</label>}
                <div className="module_detail">
                  <input
                    type="text"
                    value={Object.values(item)[0]}
                    className={
                      Object.keys(item)[1] === ""
                        ? "text_input"
                        : "text_input_value"
                    }
                    placeholder={`Enter an alternate label for '${
                      Object.keys(item)[0]
                    }'`}
                    maxLength={ item.value === "price"|| item.value === "quantity" || item.value === "amount" ? 15: item.value === "product" ||  item.value === "item" ?  20 :  50}
                    onChange={(e) =>
                      labelChangeHandler(e, Object.keys(item)[0], index)
                    }
                    disabled={item.checked==false?true:false}
                  />
                  {item.isEdited && Object.values(value)[0] !== "" && (
                    <img src={iconlyLightEdit} alt="Edit" />
                  )}
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
      <div>
        <SaveComponent
          listData={listData}
          keyValue={indexValue === 2 ? "labelChange" : "roleChange"}
          getCancelNoteList={getCancelNoteList}
        />
      </div>
    </>
  );
};

export default AccountLabelComponent;
