import React from "react";
import "../CustomSelectWithSearchComponent/CustomSelectWithSearchComponent.css";
import { Menu} from "@material-ui/core";

const CustomSelectComponent = (props) => {
  return (
    <Menu
      {...props.config}
      className="new_filter_modal"
      anchorEl={props.anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(props.anchorEl)}
      onClose={props.closeCustomPopup}
      >
      <div className="new_filter_list" tabIndex="">
        {props.isAllRequired?""
        :
       <div className="new_filter_lists" onClick={() => props.handleClick({_id:"",name:""})}> <span >All</span></div>}
        {props.filterArray && props.filterArray?.length>0 ?
        props.filterArray.map((item) => {
          return (
            <div className="new_filter_lists" key={item._id} onClick={() => props.handleClick(item)}>
              <span>{item.name}</span>
            </div>
          );
        }):""}
      </div>
    </Menu>
  );
};

export default CustomSelectComponent;
