import React, { Component , useEffect, useState } from 'react';
import "../../../../EventComponent/EventDetailComponent/EventDetailComponent.css"
import {
  Typography,
  Button,
  TextField,
  withStyles,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { Scrollbars } from 'react-custom-scrollbars';
import closeBtn from "../../../../../Assets/images/close_btn.svg";
import IconButton from '@material-ui/core/IconButton';
import {  Grid , TableCell,Table, TableRow, TableBody, TableContainer, TableHead} from "@material-ui/core";
import { AxiosInstance } from "../../../../../Utilities";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import iconNotificationError from "../../../../../Assets/images/icon-notify-error.svg";
import { NotificationComponent } from "../../../../../SharedComponents/NotificationComponent/NotificationComponent";
import userIcon from "../../../../../Assets/images/user-icon.png";
import momentTimeZone from "moment-timezone";
import pendingIcon from "../../../../../Assets/images/Group 777.svg";
import conformIcon from "../../../../../Assets/images/Group 788.svg";
import unavailableIcon from "../../../../../Assets/images/Group 776.svg";
import attendeTabClose from "../../../../../Assets/images/attende_tab_close.svg";
import attendeTabAdd from "../../../../../Assets/images/event_tab_add.svg";
import { Transition } from '../../../../../SharedComponents/CommonUtilities/CommonUtilities';
import { TransitionGrow } from "../../../../../SharedComponents/CommonUtilities/CommonUtilities";
import userBusy from"../../../../../Assets/images/user-busy.svg";
import taskWarningIcon from "../../../../../Assets/images/task_warning_icon.svg";
import warning_img from "../../../../../Assets/images/Icon ionic-ios-warning-yellow.svg";
import { Select } from "antd";
import { withRouter } from 'react-router-dom';
import "./additionalRepsComponent.css";
import TaskDetailsComponent from "../../../TaskDetails/TaskDetailsComponent";
const { Option } = Select;
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
const AdditionalRepresentativeComponent = (props) => {
const [ employeeId, setEmployeeId] = useState("")
const [isFocused, setIsFocused] = useState(false)
const [additionalReps, setAdditionalReps] = useState([]);
const [selectedAdditionalReps, setSelectedAdditionalReps] = useState([]);
const [taskattendeesDefault,setTaskattendeesDefault] = useState([]);
  const handleClose = () => { props.onCloseHandle(false); setIsFocused(false) };;
const handleShow = () => props.onCloseHandle(true);
const [item, setItem] = useState("")
  const handleChange = (event) => {
    event.preventDefault()
    setItem(event.target.value)
  }
const deleteChangeHandler = (index) =>{
  setEmployeeId(index)
  props.deleteReps(index)
  }

  const onFocusChange = () => {
    setIsFocused(true)
  }
  const onFocusLeave = () => {
    setIsFocused(false)
  }

 return (
   <div>
     <div className="tab-container-title">Additional Representatives</div>
     <div className="tab-container-title">
       <Grid container spacing={2} className="cc_email_container" >
         <>
           {props?.additionalRepData?.length > 0 ? props?.additionalRepData?.map((data, index) => (
             <>
               <Grid item xs={12} sm={6} md={4} key={data.additionalRepId}>
                 <div className="event_attendee_tab">
                   <div >
                     <img
                       className="event_attendee_tab_icon"
                       src={
                         data.repProfilePath
                           ? `https://salesc2userprofile.s3.amazonaws.com/${data.repProfilePath}`
                           : userIcon
                       }
                       alt="back"
                     />
                     <span  >
                       <LightTooltip title={data.repAssigneeValue} {...props.tooltipprops} arrow >
                         <span >{data.repAssigneeValue}</span>
                       </LightTooltip>
                     </span>
                     {data.repTaskStatus === 0 ?
                       <LightTooltip title='Pending'{...props.tooltipprops} >
                         <img src={pendingIcon} alt="pendingIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                       </LightTooltip>
                       : data.repTaskStatus === 1 ?
                         <LightTooltip title='Accepted' {...props.tooltipprops} arrow >
                           <img src={conformIcon} alt="conformIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                         </LightTooltip>
                         : data.repTaskStatus === 2 ?
                           <LightTooltip title='Rejected' {...props.tooltipprops} arrow >
                             <img src={unavailableIcon} alt="unavailableIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                           </LightTooltip>
                           : null}
                   </div>
                   {data?.additionalRepId && data?.additionalRepId ?
                     <img
                       className={"event_attendee_tab_close "}
                       style={{ cursor: "pointer" }}
                       onClick={() => deleteChangeHandler(data.additionalRepId)}
                       src={attendeTabClose}
                       alt="back"
                     />
                     : ""}
                 </div>
               </Grid>
             </>
            )) : 
             <div className="Acc-Table">
               <div className="table_container">
                 <TableContainer>
                   <Table aria-label="customized table">
                      <StyledTableRow>
                       <TableCell colSpan={9} style={{ textAlign: "center" }}>
                         No data found
                       </TableCell>
                     </StyledTableRow>
                   </Table>
                 </TableContainer>
               </div>
             </div>
            }
           <>
             <Grid item xs={12} sm={6} md={4}>
               <div
                 onClick={handleShow}
                 className="event_attendee_tab event_attendee_tab_add"
               >
                 <div>
                   <img
                     className="event_attendee_tab_icon"
                     src={attendeTabAdd}
                     alt="back"
                   />
                   <span>Add Attendee</span>
                 </div>
               </div>
             </Grid>
           </>
           <Dialog open={props.show} className={(isFocused == true) ? "deleteServiceDialog additional-rep" : "deleteServiceDialog additional-reps"} TransitionComponent={TransitionGrow}  //Dialog box for adding additional representative for single task
             transitionDuration={600} onClose={handleClose}>
             <DialogTitle id="form-dialog-title" className="add_user_header">
               <span>Additional Representative</span>
               <IconButton onClick={handleClose} className="closeBtn additionalRepCloseBtn" aria-label="show 17 new notifications" color="inherit">
                 <img src={closeBtn} alt="close btn" />
               </IconButton>
             </DialogTitle>
             <DialogContent>
               <div className="custom-ant-select" style={{ display: props.hideAttendeeField ? "none" : "block" }}>
                 <Select
                   className="text_area"
                   {...props.additionalReps}
                   margin="dense"
                   mode='multiple'
                   style={{ width: 500 }}
                   showArrow
                   showSearch
                   autoClearSearchValue={true}
                   size="large"
                   onFocus={onFocusChange}
                   onBlur={onFocusLeave}
                   getPopupContainer={(node) => node.parentNode}
                   onChange={props.onMultipleAttendeeSearch}
                 >
                   {props.additionalReps.length > 0 ?
                     props.additionalReps.map(vals => (
                       <Option
                         disabled={vals.status === 'unavailable' ? true : false}
                         key={vals._id}
                         value={vals._id}
                       >
                         {vals.profilePath ?
                           <img src={`https://salesc2userprofile.s3.amazonaws.com/${vals.profilePath}`}
                             className="auto-assignee-profile-pic profile-pic"
                             alt={vals.profilePath}
                           /> :
                           <img src={userIcon} alt="noImage"
                             className="auto-assignee-profile-pic profile-pic" />}
                         <span className="auto-assignee-profile-name">{vals.name}{vals.status === 'busy' ?
                           <img src={taskWarningIcon} alt="taskWarningIcon" className="auto-assignee-priority-icon" />
                           : vals.status === 'unavailable' ?
                             <img src={userBusy} alt="userBusy" className="auto-assignee-priority-icon" /> : null}</span>
                       </Option>
                     ))
                     : ""
                   }
                 </Select>
               </div>
               <div 
                  className={(isFocused == true) ? "add_company_action additional_rep" : "add_company_action"}
               >
                 <Button className="cancel_btn white-btn" onClick={handleClose} variant="outlined">
                   Cancel
                 </Button>
                 <Button className="ok_btn green-btn" variant="contained" 
                   onClick={() => { props.onSubmit(); setIsFocused(false) }}
                 >
                   Save
                 </Button>
               </div>
             </DialogContent>
           </Dialog>
         </>
       </Grid>
     </div>

     <Dialog open={props.showdeleteReps} className="deleteServiceDialog" TransitionComponent={TransitionGrow}   //Dialog box for removing additional representative for single task
       transitionDuration={600}>
       <DialogTitle>
         {" "}
         <center> Remove Additional Representative</center>{" "}
       </DialogTitle>
       <DialogContent>
         <DialogContentText id="alert-dialog-description">
           <center>
             {" "}
             Are you sure you want to remove this Additional Representative? This action cannot be undone.
           </center>
         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button
           className="cancel"
           onClick={props.onhandleClose}
           color="primary"
         >
           Cancel
         </Button>
         <Button
           className="delete"
           onClick={() => props.ondeleteReps(employeeId)}
           style={{ color: "red" }}
           autoFocus
         > Remove
         </Button>
       </DialogActions>
     </Dialog>


     <Dialog open={props.popupOpen}   //Dialog box for adding additional reps for recurring task
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={600}
      onClose={props.popupClose}
    >
      <DialogTitle id="form-dialog-title">
        <IconButton
          className="closeButton"
          onClick={props.popupClose}
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <img src={closeBtn} alt="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent className='recurring_popup'>
            <Grid container spacing={2} className="register-form-top">
              <Grid item xs={12} sm={6} className="cancel-task-img">
                <img className="waring-img" src={warning_img} alt="warring" />
              </Grid>

              <Grid item xs={12} sm={6} className="register-form-right">
                <label className='cancel-task-label'>
                  Add Additional Representative
                </label>
                <div className='cancel-task-msg'>
                  This is one appointment in a series What do you want to open?
                </div>
                <div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      defaultValue="whole_series"
                      onChange={handleChange}
                    >
                      <FormControlLabel value="one_task" control={<Radio />} label="Just this one" />
                      <FormControlLabel value="whole_series" control={<Radio />} label="The entire series" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <div className="cancel-task_action">
              <Button
                className="cancel_btn white-btn"
                onClick={props.popupClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="ok_btn green-btn"
                type="submit"
                variant="contained"
                onClick={() => {props.addRepHandler(item);props.popupClose(false)}}
              >
                Add
              </Button>
            </div>
      </DialogContent>
    </Dialog>


    <Dialog open={props.removePopupOpen}    //Dialog box for removing additional reps for recurring task
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={600}
      onClose={props.removePopupClose}
    >
      <DialogTitle id="form-dialog-title">
        <IconButton
          className="closeButton"
          onClick={props.removePopupClose}
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <img src={closeBtn} alt="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent className='recurring_popup'>
        
            <Grid container spacing={2} className="register-form-top">
              <Grid item xs={12} sm={6} className="cancel-task-img">
                <img className="waring-img" src={warning_img} alt="warring" />
              </Grid>

              <Grid item xs={12} sm={6} className="register-form-right">
                <label className='cancel-task-label'>
                  Remove Additional Representative
                </label>
                <div className='cancel-task-msg'>
                  This is one appointment in a series What do you want to open?
                </div>
                <div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      defaultValue="whole_series"
                      onChange={handleChange}
                    >
                      <FormControlLabel value="one_task" control={<Radio />} label="Just this one" />
                      <FormControlLabel value="whole_series" control={<Radio />} label="The entire series" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <div className="cancel-task_action">
              <Button
                className="cancel_btn white-btn"
                onClick={props.removePopupClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="ok_btn green-btn"
                type="submit"
                variant="contained"
                onClick={() => props.ondeleteRepresentatives(item,employeeId)}
              >
                Remove
              </Button>
            </div>
      </DialogContent>
    </Dialog>
   </div>
    )
  }

export default withRouter(AdditionalRepresentativeComponent); 
