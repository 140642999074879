import React, { Component, Suspense } from "react";
import "./PendingRegistrationsComponent.css";
import { withStyles } from "@material-ui/core/styles";
import * as action from "../../Store/Actions/index";
import {
  Typography,
  Button,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter
  
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AxiosInstance } from "../../Utilities";
import searchIcon from "../../Assets/images/search_icon.svg";
import Loader from "react-loader-spinner";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";
var UpdatePendingRegistrationModel = React.lazy(() =>
  import("./UpdatePendingRegistrationModel/UpdatePendingRegistrationModel")
);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
class PendingRegistrationsComponent extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      auth_username: userData.username,
      role: userData.role,
      companysiz: userData.companySize,
      auth_email: userData.email,
      auth_companyId: userData.companyId,
      createdBy: userData.username,
      vertical: "top",
      horizontal: "center",
      serviceTypelist: [],
      editservice: "",
      editserviceType: "",
      rowsPerPage: 10,
      page: 1,
      pageNumber:1,
      pendingModel: false,
      modelData: {},
      loading: true,
      addnote: "",
      responseMsg: "",
      alertType: "",
      noteError: "",
      isSubmit: false,
      iscancel: false,
      tooltipprops:{}
    };
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    this.props.onPagination(this.state.pageNumber, this.state.rowsPerPage);
    this.setState({ isValid: false });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
  };
  addNoteHandelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    
    if (!/\S\w*/.test(e.target.value)) {
      this.setState({
        noteError: "* Please enter short note",
      });
    } else if (e.target.value.length > 250) {
      this.setState({
            noteError: "* Note length should not be max 250 character",
          });
    } else {
      this.setState({ noteError: "" });
    }
  };
  /**
   * @author Shashi
   * CANCEL REGISTRATION
   */
  cancelRegistration = () => {
    var { noteError, addnote } = this.state;
    if (addnote === "") {
      noteError = "* Please enter short note";
    } 
    var Data = {
      notes: this.state.addnote,
    };
    this.setState({ noteError }, () => {
      if (this.state.noteError === "") {
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
          .patch(`/account/register/reject/${this.state.modelData._id}`, Data)
          .then((res) => {
            if (res.data.status === 200) {
              this.setState(
                {
                  iscancel: true,
                  serviceTypelist: res.data.data,
                  responseMsg: res.data.msg,
                  alertType: "success",
                },
                () => {
                  setTimeout(() => {
                    this.setState({ responseMsg: "" }, this.handleClose());
                  }, 3000);
                }
              );
              this.componentDidMount();
            } else {
              this.setState({
                iscancel: false,
                responseMsg: res.data.msg,
                alertType: "error",
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
            }
          });
      }
    });
  };
  /**
   * @author Shashi
   * ACCEPT REGISTRATION
   */
  acceptRegistration = () => {
    var { noteError, addnote } = this.state;
    if (addnote === "") {
      noteError = "* Please enter short note";
    }
    var Data = {
      notes: this.state.addnote,
    };
    this.setState({ noteError }, () => {
      if (this.state.noteError === "") {
        this.setState({ isSubmit: true });
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
          .patch(`/account/register/accept/${this.state.modelData._id}`, Data)
          .then((res) => {
            if (res.data.status === 201) {
              this.setState({
                isSubmit: false,
                responseMsg: res.data.msg,
                alertType: "success",
              });
              if (this.state.modelData.newAccount === false) {
                this.props.history.push({
                  pathname:
                    "/account-details/" + this.state.modelData.accountId,
                  state: {
                    UserData: res.data,
                  },
                });
              } else {
                this.props.history.push({
                  pathname: "/account",
                  state: {
                    UserData: res.data,
                  },
                });
              }
              this.handleClose();
            } else {
              this.setState({
                isSubmit: false,
                responseMsg: res.data.msg,
                alertType: "error",
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
            }
          });
      }
    });
  };
  /**
   * @author Shashi
   * MODEL CLOSE FUNCTION
   */
  handleClose = () => {
    this.setState({
      iscancel: false,
      isSubmit: false,
      pendingModel: false,
      addnote: "",
      isValid: false,
      alertType: "",
      adderrorMassage: "",
      responseMsg: "",
      noteError: "",
    });
  };
  /**
   * @author Shashi
   * GET DATA FROM TABLE FOR DETAILS
   */
  onViewHandleClick = (row) => {
    this.setState({ modelData: row, pendingModel: true });
  };
  /**
   * @author Shashi
   * FOR NEXT PAGE
   */
  handleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ pageNumber: newPage }, this.filter);
  };
  /**
   * @author Shashi
   * For ROWS PER PAGE
   */
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value }, this.filter);
  };
  Search = (e) => {
    let name = e.target.value;
    this.setState({ serviceName: name }, this.filter);
  };
  /**
   * @author Shashi
   * FOR FILTER
   */
  Typefilter = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.filter);
  };
  filter = () => {
    this.setState({ loading: true });
    let data = {
      pagesize: this.state.rowsPerPage,
      nextpage: this.state.pageNumber,
      serviceType: this.state.serviceTypeFilter,
      search: this.state.serviceName,
      divisionId: this.state.divisionId,
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
  };
  render() {
    let { loading } = this.state;
    let PendingRegistrations = (
      <div className="body-bg-color">
        <HeaderComponent/>
        <Suspense fallback={<div></div>}>
          {/* <HeaderComponent /> */}
          <UpdatePendingRegistrationModel
            modelData={this.state}
            open={this.state.pendingModel}
            handleClose={this.handleClose}
            addNoteHandelChange={this.addNoteHandelChange}
            cancelRegistration={this.cancelRegistration}
            acceptRegistration={this.acceptRegistration}
          />
        </Suspense>

        <div className="header-add-btn header-add-btn-service">
          <Typography variant="h5" className="tableTitle" component="div">
            Pending Registrations
          </Typography>
        </div>
        <div className="Acc-Table">
          <div className="filter-search">
            <div style={{ display: "flex" }}>
            </div>
            <CssTextField
              id="outlined-basic"
              name="serviceName"
              variant="outlined"
              type="text"
              placeholder="Search Here"
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon} alt="search"/>
                  </InputAdornment>
                ),
              }}
              onChange={this.Search}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>HOSPITAL</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>PHONE</TableCell>
                    <TableCell>EMAIL</TableCell>
                    <TableCell>ADDRESS</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={loading}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ) : !this.props.pageOfItems ||
                    this.props.pageOfItems.length === 0 ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        No data found
                      </TableCell>
                    </StyledTableRow>
                  ) : (
                    (this.state.rowsPerPage > 0
                      ? this.props.pageOfItems.slice()
                      : this.props.pageOfItems
                    ).map((row, index) => (
                      <StyledTableRow key={row._id}>
                        <TableCell>
                          {(this.props.pager.currentPage - 1) *
                            this.state.rowsPerPage +
                            (index + 1)}
                        </TableCell>
                        <TableCell>{row.accountname}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          <LightTooltip title={row.phone} {...this.state.tooltipprops} arrow>
                            <a href={"tel:" + row.phone} rel="noopener noreferrer">{row.phone}</a>
                          </LightTooltip>
                        </TableCell>
                        <TableCell>
                          <LightTooltip title={row.email} {...this.state.tooltipprops} arrow>
                            <a href={"mailto:" + row.email} rel="noopener noreferrer">{row.email}</a>
                          </LightTooltip>
                        </TableCell>
                        <TableCell>{row.address}</TableCell>
                        <TableCell>
                          <div
                            className="table_action_btns"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <Button
                              className="logout-button primary-btn"
                              variant="contained"
                              onClick={() => this.onViewHandleClick(row)}
                            >
                              Update
                            </Button>
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
                <TableFooter>
                  {this.props.pageOfItems &&
                      this.props.pageOfItems.length !== 0?
                    <TableRow>
                      <td colSpan={20} className="pagination_container">
                    <CustomPaginationComponent
                    count={this.props.pager.endPage}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.pageNumber}
                    onPageChange={this.handleCustomPaginagtionChange}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                  </td>
                  </TableRow>
                  :null}
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
    return <div>{PendingRegistrations}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
    responseData: state.pagination.responseData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole) =>
      dispatch(action.pendingpagination(pageNo, pagerole)),
    onFilter: (value) => dispatch(action.pendingfilter(value)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PendingRegistrationsComponent));
