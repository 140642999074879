import React, { Component, Suspense } from "react";
import "./DashboardComponent.css";
import {
  Grid, Dialog,
  DialogContent,
  DialogTitle, IconButton, Button, TextField, Select, withStyles
} from "@material-ui/core";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { AxiosInstance, checkTimeZone } from "../../Utilities";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationAssigned from "../../Assets/images/icon-notify-assigned.svg";
import iconNotificationUpdate from "../../Assets/images/icon-notify-update.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconClose from "../../Assets/images/close_btn.svg";
import taskAssignThree from "../../Assets/images/task_asign_three.png";
import taskWarningIcon from "../../Assets/images/task-warning-icon.svg";
import logo from "../../Assets/images/Sales_C2_logo.svg";
import { onGetDirectionHandler } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import momentTimeZone from 'moment-timezone';
import moment from 'moment';
import carret_down from "../../Assets/images/carret_down.svg";
import { isNull, upperFirst } from 'lodash';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { Transition, TransitionGrow } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import PendingAcceptanceComponent from './PendingAcceptanceComponent/PendingAcceptanceComponent';
import { AWS_AxiosInstance } from "../../AWS_AxiosInstance";
import { Autocomplete } from "@material-ui/lab";
var ScheduledConfirmationModel = React.lazy(() =>
  import("../../SharedComponents/ScheduledConfirmationModel/ScheduledConfirmationModel")
);
var TodaysTaskComponent = React.lazy(() =>
  import("./TodaysTaskComponent/TodaysTaskComponent")
);
var PendingTaskComponent = React.lazy(() =>
  import("./PendingTaskComponent/PendingTaskComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent")
);
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);


var TaskSummaryComponent = React.lazy(() =>
  import("./TaskSummaryComponent/TaskSummaryComponent")
);

var FooterComponent = React.lazy(() =>
  import("../../SharedComponents/FooterComponent/FooterComponent")
);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },

})(TextField, Select);
let cancelOption = [
  {
    id: "one",
    text: "",
  },
]

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    var zoneName = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    this.state = {
      openCancelPopup: false,
      customCancelValue:false,
      cancelValue:"",
      customCancelText:"",
      cancelUpdateStatus: false,
      isRecurring:"",
      isRecurringTask:"",
      recurringStatus:"",
      recurringState:"",
      scheduleId:"",
      dateId:"",
      timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
      isMobile: "",
      assigneeError: "",
      updateStatusError: "",
      taskComponent: false,
      pendingComponent: false,
      isLoading: false,
      opens: false,
      pendingdialogopen: false,
      pendingassignee: "",
      pendingassigneeId: "",
      selectedStates: "",
      updateStatusTaskId: "",
      taskStatus: "Pending",
      assigneeTaskId: "",
      inprog_startDate: "",
      taskslist: {
        tasks: {},
        config: {
          task_sort_by: {
            selectProps: {
              variant: "outlined",
              displayEmpty: true,
              name: "sortBy",
            },
            defaultMenu: "Sort By",
            menuItems: [],
          },
          task_filter: {
            autocompletedProps: {
              className: "task_filter",
              id: "task_filter",
            },
            textFieldProps: {
              variant: "outlined",
              name: "task_filter_name",
              placeholder: "Users",
            },
          },
          territory_filter: {
            autocompletedProps: {

              id: "territory_filter_control",
            },
            textFieldProps: {
              variant: "outlined",
              name: "territory_filter_name",
              placeholder: "Territory",
            },
          },
        },
        sortBy: "",
        task_filter: [],
        territory_filter: [],
        territory_search_filter: [],
        anchorEl: null,
        anchorElUser: null,
        userSearchValue: "",
        territorySearchValue: "",
        task_filter_name: "",
        territory_filter_name: "All",
        territory_filter_id: "",
        task_filter_id: "",
        terrId: "",
        showaddModal: "",
        task_list_data: [],
        updatestatusdialog: false,
        selected: null,
        status: "",
        statusNote: "",
        statusSelected: "",
      },
      pendinglist: {
        config: {
          task_sort_by: {
            selectProps: {
              variant: "outlined",
              displayEmpty: true,
              name: "serviceType",
            },
            defaultMenu: "All Service",
            menuItems: [],
          },
          task_filter: {
            autocompletedProps: {
              className: "pending_task_filter",
              id: "pending_task_filter",
            },
            textFieldProps: {
              variant: "outlined",
              name: "pendingassignee",
              placeholder: "Enter name",
            },
          },
        },
        serviceType: "",
        serviceName: "",
        pending_task_filter: [
          {
            id: "",
            name: "",
          },
        ],
        anchorElService: null,
        reassign_to: "",
        pending_list_data: [],
        pendingstatusdialog: false,
      },
      statusMenuProps: {
        anchorEl: null,
        open: false,
      },
      pendingReassignProps: {
        anchorEl: null,
        open: false,
      },
      searchLoader: false,

      taskSummary: {},
      summary_territory: {
        id: "summary_territory_filter_control",
        anchorElSummaryTerritory: null,
        summaryTerritory: [],
      },
      summary_month: {
        id: "summary_date_filter_control",
        anchorElDate: null,
        summaryDate: [
          { _id: "", name: "Today" },
          { _id: "last7days", name: "Last 7 Days" },
          { _id: "month", name: "Last 30 Days" },
          { _id: "quarter", name: "This Quarter" },
          { _id: "year", name: "This Year" }
        ],
      },
      toDay: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
      // momentTimeZone().tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName),
      startDate: "",
      endDate: "",
      summaryTerritoryFilter: "",
      summaryTerritorySearch: "",
      summaryMonthFilter: "",
      summaryTerritoryFilterId: "",
      summarySelected: "today",
      page: 1,
      pageSize: 5,

      eventsTypes: {
        type: '',
        name: "",
        menuItems: [
          { _id: "", name: "All" },
          { _id: "task", name: "Tasks" },
          { _id: "personal", name: "Personal Events" },
          { _id: "meeting", name: "Work Event" }
        ],
        anchorElevents: null,
      },
      pendingTypeList: {
        anchorElPendingTypes: null,
        pendingType: "Pending Acceptance",
        menuItems: [
          { _id: "Pending Acceptance", name: "Pending Acceptance" },
          { _id: "Pending Assignment", name: "Pending Assignment" }
        ]
      },
      upcomingTask: {
        type: "",
        name: "All",
        menuItems: [
          { _id: "", name: "All" },
          { _id: "task", name: "Tasks" },
          { _id: "event", name: "Events" }
        ],
        anchorElTaskTypes: null,
      },
      pendingAcceptanceList: [],
      pendingAcceptanceListPager: null,
      taskConflictModalOpen: false,
      taskConflictModalData: null,
      diffTime: null,
      taskRejectBtnClicked: false,
      taskAcceptBtnClicked: false,
      btnClickId: null,
      onLoad: false,
      recurringType:false,
      recurringData:false,
      // footer lists
      footerList: ["Customer Support", "Android App", "iPhone App", "Terms of Service", "Privacy Policy"]

    };
    this.get_assignee_name_handler = this.get_assignee_name_handler.bind(this);
    this.closeModalOnMouseLeave = this.closeModalOnMouseLeave.bind(this);
  }

  componentWillMount() {
    this.getTimeZones();
    this.setState({
      onLoad: true
    })
    this.fetch_no_of_task();
    this.fecthTerritoryFilters("");
    this.getServiceType();

    const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
    // moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
    const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
    //  moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
    this.fetchTaskList({
      startDate: start,
      endDate: end
    });
    // this.fetchPendingList();
    let data = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      type: this.state.eventsTypes.type,
      // startDate: moment(start).utc(),
      // endDate: moment(end).utc()
      today: start,
      // momentTimeZone().tz(this.state.timeZoneName)
    }

    this.fetchPendingAcceptanceList(data);

    this.fecthSummaryTerritoryFilters();
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          isMobile: window.innerWidth < 600 ? true : false,
          taskComponent: true,
          pendingComponent: false,
        });
      },
      false
    );
  };
  componentDidMount() {
    const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : this.state.timeZoneName).utc();
    const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : this.state.timeZoneName).utc();
    this.fetchTaskSummary({
      startDate: start,
      endDate: end
    });
    this.fetchCancelNotes() 
  }
  fetchCancelNotes = () =>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/cms")
      .then(async (resp) => {
        if(resp.data.status===200 || resp.data.status==="200"){
          if(resp.data.data[0].canceledNotes.notes){
            cancelOption=resp.data.data[0].canceledNotes.notes
          } else{
            cancelOption=[
              {
                id: "one",
                name: "No data",
              },
            ]
          }
        } else{
          cancelOption=[
            {
              id: "one",
              name: "No data",
            },
          ]
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      })
  }
  getTimeZones = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
      .get("/employee/timezone")
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          this.setState({
            timeZoneName: response.data.timezone ? response.data.timezone : momentTimeZone.tz.guess(),

          });
          localStorage.setItem("timeZone", response.data.timezone ? response.data.timezone : momentTimeZone.tz.guess());
          checkTimeZone();
        } else if (response && (response.status === "400" || response.status === 400)) {

        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  fetchTaskSummary = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .post("/dashboard/summary", data)
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          var data = response.taskSummary;
          this.setState({
            taskSummary: data
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  }
  getServiceType = () => {
    // var { pendinglist } = this.state;
    /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
    AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") 
    ? 6007 
    :process.env.REACT_APP_NODE_ENV === "stage"
    ?'/'
    : 'service', "application/json") */
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/json")
      .get("/getServicetypes")
      .then((res) => {
        var response = res && res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var { pendinglist } = this.state;
          response.data.map((type) => {
            var types = {};
            types._id = type._id;
            types.value = type.name;
            types.name = type.name;
            pendinglist.config.task_sort_by.menuItems.push(types);
          });
          this.setState({
            pendinglist,
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      })
  }

  fetch_no_of_task() {
    var { taskslist } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .get("/dashboard/no-of-task")
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          taskslist = {
            ...taskslist,
            tasks: {
              total: response.data.Total_task,
              completed: response.data.Completed_task,
            },
          };
          this.setState({
            taskslist,
          });
        } else {
          taskslist = {
            ...taskslist,
            tasks: {},
          };
          this.setState({
            taskslist,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  }
  /**
   * for mobile view
   * @param {task or pending} type
   */
  toggleComponent = (type) => {
    switch (type) {
      case "task":
        this.setState({
          taskComponent: true,
          pendingComponent: false,
        });
        break;
      case "pending":
        this.setState({
          taskComponent: false,
          pendingComponent: true,
        });
        break;
      default:
        this.setState({
          taskComponent: false,
          pendingComponent: false,
        });
        break;
    }
  };
  /**
   * method to fetch today's task list
   */
  fetchTaskList(data) {
    var { taskslist } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .post("/dashboard/today-assigned-tasks", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          (response && response.status === "200") ||
          response.status === 200
        ) {
          taskslist.task_list_data = [];
          taskslist.task_list_data = response.data;
          this.setState({
            taskslist,
            recurringState:response.data,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          taskslist.task_list_data = [];
          this.setState({
            taskslist
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  /**
   * method to fetch pending task list
   */
  fetchPendingList = () => {
    var { pendinglist } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .get("/dashboard/pending-tasks",{ params:{
        serviceType: pendinglist.serviceType
      }})
      .then((resp) => {
        var response = resp && resp.data;
        if (
          (response && response.status === "200") ||
          response.status === 200
        ) {
          var { pendinglist } = this.state;
          pendinglist = { ...pendinglist, pending_list_data: response.data};
          this.setState({
            pendinglist,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };

  openlistenerhandle = () => {
    var { pendinglist } = this.state;
    pendinglist = { ...pendinglist, reassign_to: "" };

    this.setState({ opens: !this.state.opens, pendinglist });
  };

  /**
   * close modals on mouse leave
   */
  closeModalOnMouseLeave = () => {
    var { statusMenuProps } = this.state;
    var statusMenuProps = { ...statusMenuProps };
    statusMenuProps.anchorEl = null;
    statusMenuProps.open = false;
    this.setState({
      statusMenuProps,
    });
  };

  showUpdateStatusClose = (val) => {
    var { statusMenuProps } = this.state;
    statusMenuProps.anchorEl = null;
    statusMenuProps.open = Boolean(statusMenuProps.anchorEl);
    this.setState({
      statusMenuProps,
    });
  };

  /**
   * set tasklist state on change of filters
   */
  onChangeHandler = (e) => {
    var { name, value } = e.target;
    var { taskslist } = this.state;
    taskslist[name] = value;
    this.setState({
      taskslist,
    });
  }; 
  onChangeHandlers = (e) =>{
    this.setState({
      customCancelText:e.target.value
    })
  }
  /**
   * @author Benakesh
   * @param {task/event data } data
   * To VIEW DETAIL OF THE TASK ON CARD CLICK 
   */
  taskDetailHandler = (data) => {
    if (data.type === "1") {
      this.props.history.replace(`/eventdetail/${data._id}`);
    } else if (data.type === "0" && data.recurringStatus === "AutoGenerated") {
      this.props.history.replace(`/task/${data.taskScheduleId}?dateId=${data.dateId}&status=AutoGenerated`);
    } else if (data.type === "0" && data.recurringStatus !== "AutoGenerated") {
      this.props.history.replace(`/task/${data._id}`);
    }
  }
  /**
   *
   * @param {geo location lat lang} geomettry
   * redirect to google map to show geolocation
   */
  onGetDirectionHandler = (geomettry) => {
    onGetDirectionHandler(geomettry);
  };

  /**
   * To show all tasks related to user on click of open task from dashbaord
   */
  onViewAllTask = () => {
    this.props.history.replace("/task");
  };
  openSchedulerHandler = () => {
    this.props.history.replace("/schedule-task");
  }
  onUpdateStatusSelect = (val, name) => {
    this.setState({
      cancelValue:""
  })
    if(name==="cancelled" || name==""){
      this.setState({
        cancelUpdateStatus:true
      })
    } else{
      this.setState({
        cancelUpdateStatus:false
      })
    }
    var { taskslist } = this.state;
    taskslist = { ...taskslist, selected: val };
    this.setState({
      taskslist,
    });
  };

  /**
   *
   * @param {target element} val
   *  to show dialog on update status button click
   */
  onupdatestatusdialoghandle = (item) => {
    var { taskslist, statusMenuProps } = this.state;
    statusMenuProps.anchorEl = null;
    statusMenuProps.open = Boolean(statusMenuProps.anchorEl);

    taskslist = {
      ...taskslist,
      updatestatusdialog: !taskslist.updatestatusdialog,
      status: item.status,
    };
    this.setState({
      taskslist,
      statusMenuProps,
      updateStatusTaskId: item._id,
      scheduleId:item?.taskScheduleId ? item?.taskScheduleId : "",
      dateId:item?.dateId ? item?.dateId : ""
    });
  };

  /**
   * to close update status dialog
   */
  onupdatestatusdialoghandleClose = () => {
    var { taskslist } = this.state;
    taskslist = {
      ...taskslist,
      updatestatusdialog: !taskslist.updatestatusdialog,
      status: "",
      selected: null,
    };
    this.setState({
      taskslist,
      updateStatusError: "",
    });
  };
  onDialogStatusExit = () => {
    var { statusMenuProps, taskslist } = this.state;
    taskslist.updatestatusdialog = false;
    statusMenuProps.anchorEl = null;
    statusMenuProps.open = false;
    this.setState({
      statusMenuProps,
      taskslist,
      updateStatusTaskId: ""
    })
  }
  /**
   *
   * @param {selected status} val
   * @param {task} id
   * on click of update status call api to update status of task
   */
  onclickStatus = (val, id, startDate, task_list) => {
    const { statusMenuProps, taskslist } = this.state;
    taskslist.updatestatusdialog = false;
    statusMenuProps.open = false;
    statusMenuProps.anchorEl = null;
    this.setState(
      {
        selectedStates: val,
        updateStatusTaskId: id,
        statusMenuProps,
        taskslist,
        inprog_startDate: startDate,
        scheduleId:task_list?.taskScheduleId ? task_list?.taskScheduleId : "",
        dateId:task_list?.dateId ? task_list?.dateId : ""
      },
      () => {
        if(val=="cancelled"){
          this.setState({
            openCancelPopup: true
          })
        } else{
           this.updateStatus();
        }
      }
    );
  };
  /**
    * @author Shashi
    * TO UPDATE TASK STATUS INPROGRESS
    */
  onInprogress = () => {
    this.updateStatusHandle();
  }
  /**
    * @author Shashi
    * TO CHECK BEFORE DATE TASK STATUS INPROGRESS
    */
  updateStatus = () => {
    let currentDate = moment().tz(this.state.timeZoneName);
    let taskStartDate = moment(this.state.inprog_startDate).tz(this.state.timeZoneName);
    if (this.state.selectedStates === "inprogress") {
      if (currentDate.isBefore(taskStartDate)) {
        this.setState({ showaddModal: true, })
      }
      else {
        this.updateStatusHandle();
      }
    } else {
      this.updateStatusHandle();
    }
  }
  onCloseModal = () => {
    this.setState({ showaddModal: false, })
  }
  onclickStatus1 = (val, id) => {
    this.setState({
      selectedStates: val,
      updateStatusTaskId: id,
    });
  };
  changeValues =()=>{
    this.setState({
        cancelValue:""
    })
  }
  updateStatusHandle = () => {
    var {
      taskslist,
      selectedStates,
      updateStatusTaskId,
      statusMenuProps,
      cancelValue,
      customCancelText
    } = this.state;
    var data = {};

    if (this.state.selectedStates === "cancelled") {
      if (this.state.scheduleId === "" && this.state.dateId === "") {
        data.note = customCancelText;
        data.cancelReason=cancelValue
      } else {
        data.notes = customCancelText;
        data.cancelReason=cancelValue
      }
    
      // if (this.state.cancelValue !== "custom") {
      //   if (this.state.scheduleId === "" && this.state.dateId === "") {
      //     data.note = cancelValue + " " + data.note;
      //   } else {
      //     data.notes = cancelValue + " " + data.notes;
      //   }
      // } else if (taskslist.statusNote !== "") {
      //   if (this.state.scheduleId === "" && this.state.dateId === "") {
      //     data.note = taskslist.statusNote;
      //   } else {
      //     data.notes = taskslist.statusNote;
      //   }
      // }
    } else if (taskslist.statusNote !== "") {
      if (this.state.scheduleId === "" && this.state.dateId === "") {
        data.note = taskslist.statusNote;
        if (this.state.cancelValue !== "") {
          data.note = this.state.cancelValue + " " + data.note;
        }
      } else {
        data.notes = taskslist.statusNote;
        if (this.state.cancelValue !== "") {
          data.notes = this.state.cancelValue + " " + data.notes;
        }
      }
    }
    
    if (taskslist.task_filter_id !== "") {
      data.assignedTo = taskslist.task_filter_id;
    }
    if (selectedStates !== "" && updateStatusTaskId !== "") {
      data.taskStatus = selectedStates;
      if (this.state.scheduleId != "" && this.state.dateId !="") {
      data.dateId = this.state.dateId;
      data.timeZone=this.state.timeZoneName
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
          .put(`/recurringTask/${this.state.updateStatusTaskId}/${this.state.scheduleId}`, data)
          .then(resp => {
            var responseData = resp && resp.data;
            if (
              responseData &&
              (responseData.status === "200" || responseData.status === 200)
            ) {
              taskslist.updatestatusdialog = false;
              this.setState(
                {
                  openCancelPopup: false,
                  showaddModal: false,
                  taskslist,
                  [statusMenuProps.open]: false,
                  [statusMenuProps.anchorEl]: null,
                },
                () => {
                  let data = {};
                  const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
                  const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
                  data.type = this.state.upcomingTask.type;
                  data.assignedTo = this.state.taskslist.task_filter_id;
                  data.territoryId = this.state.taskslist.territory_filter_id;
                  data.startDate = start;
                  data.endDate = end;
                  taskslist.task_list_data=[];
                  this.setState({
                    taskslist
                  }, () =>{
                    this.fetchTaskList(data)
                  })
                }
              );
              var props = {
                message: responseData.msg,
                icon: <img src={iconNotificationAssigned} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification",
              };
              NotificationComponent(props);
            } else {
              taskslist.updatestatusdialog = this.state.taskslist.selected
                ? taskslist.updatestatusdialog
                : false;
              this.setState({
                taskslist,
                [statusMenuProps.open]: false,
                [statusMenuProps.anchorEl]: null,
                updateStatusError: "",
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === '403' || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500)
            } else {
              console.log("Internal Server Error")
            }
            taskslist.updatestatusdialog = this.state.taskslist.selected
              ? taskslist.updatestatusdialog
              : false;
            this.setState({
              taskslist,
              [statusMenuProps.open]: false,
              [statusMenuProps.anchorEl]: null,
              updateStatusError: "",
            });
          });
      }
      else if(this.state.scheduleId=="" && this.state.dateId=="") {
        data.taskStatus = selectedStates;
      data._id = updateStatusTaskId;
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
        .post("/assign_task", data)
        .then((resp) => {
          var responseData = resp && resp.data;
          if (
            responseData &&
            (responseData.status === "200" || responseData.status === 200)
          ) {
            taskslist.updatestatusdialog = false;
            // this.state.taskslist.selected
            //   ? !taskslist.updatestatusdialog
            //   : false;
            this.setState(
              {
                openCancelPopup: false,
                showaddModal: false,
                taskslist,
                [statusMenuProps.open]: false,

                [statusMenuProps.anchorEl]: null,
              },
              () => {
                let data = {};
                const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
                //  moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
                const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
                //  moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');

                data.type = this.state.upcomingTask.type;
                data.assignedTo = this.state.taskslist.task_filter_id;
                data.territoryId = this.state.taskslist.territory_filter_id;
                data.startDate = start;
                data.endDate = end;
                taskslist.task_list_data=[];
                  this.setState({
                    taskslist
                  }, () =>{
                    this.fetchTaskList(data)
                  })
              }
            );
            var props = {
              message: responseData.msg,
              icon: <img src={iconNotificationAssigned} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification",
            };
            NotificationComponent(props);
          } else {
            taskslist.updatestatusdialog = this.state.taskslist.selected
              ? taskslist.updatestatusdialog
              : false;
            this.setState({
              taskslist,
              [statusMenuProps.open]: false,
              [statusMenuProps.anchorEl]: null,
              updateStatusError: "",
            });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === '403' || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500)
          } else {
            console.log("Internal Server Error")
          }
          taskslist.updatestatusdialog = this.state.taskslist.selected
            ? taskslist.updatestatusdialog
            : false;
          this.setState({
            taskslist,
            [statusMenuProps.open]: false,
            [statusMenuProps.anchorEl]: null,
            updateStatusError: "",
          });
        });
      }
      // })
    } else {
      this.setState({
        updateStatusError: "Please select task status",
      });
    }
  };

  /**pending assignment actions starts*/

  /**
   *
   * @param {assignee Id} assigneeId
   * @param {taskid} taskId
   *  To change assignee on dropdown click
   */
  onclickReassignee = (assigneeId, taskId,item) => {
    this.setState(
      {
        pendingassigneeId: assigneeId,
        assigneeTaskId: taskId,
        recurringData: item ? item :""
      },
      () => {
        this.updateAssigneeHandler();
      }
    );
  };

  onclickUnassigned = (taskId,item) => {
    this.setState(
      {
        pendingassigneeId: "",
        assigneeTaskId: taskId,
        taskStatus: "Unassigned",
        recurringData:item ? item :""
      },
      () => {
        this.updateAssigneeHandler();
      }
    );
  };



  /**
   * To change assignee on click in dialog
   */
  updateAssigneeHandler = () => {
    var {
      assigneeTaskId,
      pendingassigneeId,
      pendinglist,
      pendingReassignProps,
      taskStatus,
      recurringData
    } = this.state;
    var data = {};
    // if (assigneeTaskId !== "" && pendingassigneeId !== "") {
    if (assigneeTaskId !== "" && pendingassigneeId !== "") {
      data.assignedTo = pendingassigneeId;
      data.taskStatus = "Pending";
      data._id = assigneeTaskId;
    } else {
      data.taskStatus = "Unassigned";
      data._id = assigneeTaskId;
    }

    // var data = {
    //   assignedTo: pendingassigneeId,
    //   taskStatus: taskStatus,
    //   _id: assigneeTaskId,
    // };
    if (this.state.recurringData == true){
      data.recurringType = this.state.recurringData;
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .post("/assign_task", data)
      .then((resp) => {
        var responseData = resp && resp.data;
        if (
          responseData &&
          (responseData.status === "200" || responseData.status === 200)
        ) {
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
            .get("/dashboard/pending-tasks")
            .then((resps) => {
              var response = resps && resps.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                var {
                  pendinglist,
                  pendingReassignProps
                } = this.state;
                pendinglist.pending_list_data = response.data;
                this.setState(
                  {
                    pendinglist,
                  },
                  () => {
                    this.on_Pending_showUpdateStatusClose();
                    var propsone = {
                      message: "Reassigned successfuly",
                      icon: (
                        <img src={iconNotificationAssigned} alt="success" />
                      ),
                      type: "success",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification",
                    };
                    NotificationComponent(propsone);
                  }
                );
              } else if (
                (responseData && responseData.status === "400") ||
                responseData.status === 400
              ) {
                pendinglist.pending_list_data = [];
                this.setState({
                  pendinglist,
                  [pendingReassignProps.open]: false,
                  [pendingReassignProps.anchorEl]: null,
                });
                var props = {
                  message: responseData.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(props);
              }
            }).catch(err => {
              var error = err.response;
              if (error && (error.status === '403' || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500)
              } else if (error && (error.status === '500' || error.status === 500)) {
                var propsErr = {
                  message: "Something went wrong",
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propsErr);
              }
            })
        } else {
          this.setState({
            pendinglist,
            [pendingReassignProps.open]: false,
            [pendingReassignProps.anchorEl]: null,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
        this.setState({
          pendinglist,
          [pendingReassignProps.open]: false,
          [pendingReassignProps.anchorEl]: null,
        });
      });
    // } else {
    //   this.setState({
    //     assigneeError: "Please select assignee name",
    //   });
    // }
  };

  /** on reassignee dialog close */
  on_Pending_showUpdateStatusClose = () => {
    var { pendingReassignProps } = this.state;
    pendingReassignProps.anchorEl = null;
    pendingReassignProps.open = false;
    this.setState({
      pendingReassignProps,
    });
  };
  /**
   *
   * @param {target} e
   * on select of reassignee
   */
  onReasignSelect = (e) => {
    var { pendinglist } = this.state;
    var { value } = e.target;
    pendinglist = { ...pendinglist, reassign_to: value };
    this.setState({
      pendinglist,
    });
  };

  pendingupdatestatushandler = (e, taskId, territoryId , item) => {
    var { pendinglist, pendingReassignProps, pendingdialogopen } = this.state;
    pendingReassignProps.anchorEl = null;
    pendingReassignProps.open = false;

    pendinglist = {
      ...pendinglist,
      pendingstatusdialog: !pendinglist.pendingstatusdialog,
    };
    this.setState({
      pendinglist,
      pendingReassignProps,
      assigneeTaskId: taskId,
      terrId: territoryId,
      pendingassignee: "",
      pendingdialogopen: !pendingdialogopen
    });
  };

  /**
   *
   * @param {targte element} e
   * @param {taskid} taskId
   * @param {territory id} territoryId
   *  To fetch assignee name on mouse hover
   */
  get_assignee_name_handler = (e, taskId, territoryId) => {
    var { pendingReassignProps } = this.state;
    pendingReassignProps.anchorEl = null;
    pendingReassignProps.open = false;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .post("/dashboard/get-assign-employee", { taskId, territoryId })
      .then((resp) => {
        var response = resp && resp.data;
        var { pendinglist } = this.state;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var data = pendinglist.pending_list_data.filter((item) => {
            return item._id === taskId;
          });
          var index = pendinglist.pending_list_data.findIndex(
            (x) => x._id === taskId
          );
          data[0].employees = response.employees;
          pendinglist = {
            ...pendinglist,
            [pendinglist.pending_list_data[index]]: data,
          };
          this.setState({
            pendinglist,
            pendingReassignProps,
            pendingdialogopen: false,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };
  /**
   *
   * @param {target} e
   * on select of reassignee
   */
  onReassigneeSelect = (e) => {
    e.preventDefault();
    var { value } = e.target;
    this.setState({
      pendingassignee: value,
    });
  };

  /**
   *
   * @param {target} e
   * on change of assignee in dialog
   */
  onReassigneeChange = (e) => {
    var { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      let data = {
        search: value,
        taskId: this.state.assigneeTaskId,
        territoryId: this.state.terrId,
      }
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
        .post("/employeeFilterStatus", data)
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            var { pendinglist } = this.state;
            pendinglist.pending_task_filter = response.data;
            this.setState({
              pendinglist,
            });
          } else if (
            (response && response.status === "400") ||
            response.status === 400
          ) {
            let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            var task_list = {
              ...pendinglist,
              pending_task_filter: {
                id: "",
                name: "",
              },
            };
            this.setState({
              pendinglist: task_list,
            });
          }
        }).catch(err => {
          var error = err.response;
          if (error && (error.status === '403' || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500)
          } else if (error && (error.status === '500' || error.status === 500)) {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        })
    });
  };

  /**
   *
   * @param {target} e
   * on select of employee in dialog
   */
  onAssigneeAutoCompleteSelect = (e) => {
    var { value } = e.target;
    if (!e.target.value) {
      this.onReassigneeChange(e);
    } else {
      var { pendinglist, pendingassignee, pendingassigneeId } = this.state;
      var data = pendinglist.pending_task_filter.filter((item) => {
        return item.name === value;
      });
      if (data.length > 0) {
        pendingassignee = data[0].name;
        pendingassigneeId = data[0]._id;
        this.setState({
          pendingassigneeId,
          pendingassignee,
        });
      }
    }

  };

  /**pending assignment actions ends */

  /**custom filter */

  /**
   * 
   * @param {value} search
   * TO FETCH THE TERRITORY FILTERS 
   */
  fecthTerritoryFilters = (search) => {
    const { taskslist } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : 'level', "application/json")
      .post("/dashboardLevelFilter", {
        search,
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (response.status === "200" || response.status === 200) {
          var data = response.data;
          var newArray = taskslist.territory_filter.splice();
          // var newArrayFilter=taskslist.territory_search_filter.splice();
          if (this.state.taskslist.territorySearchValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }

          // newArrayFilter.push({_id:"",name:"All"});
          // newArray.push({_id:"",name:"All"});
          if (data.length > 0) {
            data.map(item => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
              // newArrayFilter.push(obj);
            });
          }

          taskslist.territory_filter = newArray;
          // taskslist.territory_search_filter=newArrayFilter;
          this.setState({
            taskslist,
            searchLoader: false
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          var task_list = {
            ...taskslist,
            // territory_search_filter:[]
          };
          this.setState({
            taskslist: task_list,
            searchLoader: false
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
        this.setState({
          searchLoader: false
        })
      })
  }
  /**
   * FETCH TODAYS TASK BASED ON TERRITORY FILTERS
   */
  fetchTodaysTaskBasedOnTerritoryFilter = () => {
    const { taskslist } = this.state;
    const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
    // moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
    const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
    // moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');

    let data = {
      territoryId: taskslist.territory_filter_id,
      type: this.state.upcomingTask.type,
      startDate: start,
      endDate: end
    }
    if (taskslist.task_filter_id === "all") {
      data.searchValue = true;
      var filterData = taskslist.task_filter;
      var assignedToArray = [];
      filterData.map((item) => {
        if(item._id != "all"){
          var items = item._id;
          assignedToArray.push(items);
        }
      });
      data.assignedTo = assignedToArray;
    } else {
      data.assignedTo = taskslist.task_filter_id;
    }
    taskslist.task_list_data = [];
    this.setState({
      taskslist
    }, () => {
      this.fetchTaskList(data)
    })

  }

  /**
   * 
   * @param {value} search
   * TO FETCH THE USER FILTER LIST 
   */
  fetchUserListFilters = (search) => {
    const { taskslist } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .get("/employeeFilter", { params:{
        search: search
      }})
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var data = response.data;
          var newArray = taskslist.task_filter.splice();
          newArray.push({ _id: "all", name: "All" })
          if (taskslist.userSearchValue === "") {
            // newArray.unshift({ _id: "", name: "Me" });
          } else {
            newArray.shift({ _id: "all", name: "All" });
          }

          if (data.length > 0) {
            data.map(item => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            })
          }
          taskslist.task_filter = newArray;
          this.setState({
            taskslist,
            searchLoader: false
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          var task_list = {
            ...taskslist,
            task_filter: {
              _id: "",
              name: "Me",
            },
          };
          this.setState({
            taskslist: task_list,
            searchLoader: false
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
        this.setState({
          searchLoader: false
        })
      })
  }

  upcomingTaskFilterOpen = (event) => {
    const { upcomingTask } = this.state;
    upcomingTask.anchorElTaskTypes = event.currentTarget;
    this.setState({
      upcomingTask
    })
  }
  upcomingTaskFilterClose = () => {
    const { upcomingTask } = this.state;
    upcomingTask.anchorElTaskTypes = null;
    this.setState({
      upcomingTask
    })
  }

  handleTaskTypeChange = (item) => {
    console.log(item)
    const { upcomingTask } = this.state;
    upcomingTask.type = item._id;
    upcomingTask.name = item._id === "" ? "All" : item.name;
    upcomingTask.anchorElTaskTypes = null;
    this.setState({
      upcomingTask
    }, () => {
      const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
      //  moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
      const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
      //  moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');

      let data = {};
      data.type = this.state.upcomingTask.type;
      data.assignedTo = this.state.taskslist.task_filter_id;
      data.territoryId = this.state.taskslist.territory_filter_id;
      data.startDate = start;
      data.endDate = end;
      this.fetchTaskList(data);
    })
  }

  /**
   * 
   * @param {event} event
   * TO OPEN THE TERRITORY FILER MENU 
   */
  openCustomSinglePopup = (event) => {
    this.fecthTerritoryFilters("");
    const { taskslist } = this.state;
    taskslist.anchorEl = event.currentTarget;
    setTimeout(() => {
      this.setState({
        taskslist
      })
    }, 800)
  }
  /**
 * 
 * @param {event} event
 * TO OPEN THE USER FILER MENU 
 */
  openCustomUserFilterPopup = (event) => {
    this.fetchUserListFilters("");
    const { taskslist } = this.state;
    taskslist.anchorElUser = event.currentTarget;
    setTimeout(() => {
      this.setState({
        taskslist
      })
    }, 800);
  }
  /**
   * 
   * @param {event} event
   * TO OPEN THE SERVICE FILER MENU 
   */
  openCustomServiceSelectPopup = (event) => {
    const { pendinglist } = this.state;
    pendinglist.anchorElService = event.currentTarget;
    this.setState({
      pendinglist
    })
  }
  typeSelectSelectPopupOpen = (event) => {
    const { eventsTypes } = this.state;
    eventsTypes.anchorElevents = event.currentTarget;
    this.setState({
      eventsTypes
    })
  }
  typeSelectSelectPopupClose = (event) => {
    const { eventsTypes } = this.state;
    eventsTypes.anchorElevents = null;
    this.setState({
      eventsTypes
    })
  }
  pendingTypeSelectSelectPopupOpen = (event) => {
    const { pendingTypeList } = this.state;
    pendingTypeList.anchorElPendingTypes = event.currentTarget;
    this.setState({
      pendingTypeList
    })
  }
  pendingTypeSelectSelectPopupClose = (event) => {
    const { pendingTypeList } = this.state;
    pendingTypeList.anchorElPendingTypes = null;
    this.setState({
      pendingTypeList
    })
  }


  /**
   * 
   * @param {event} event
   * TO CLOSE THE SERVICE FILER MENU 
   */
  closeCustomServiceSelectPopup = (event) => {
    const { pendinglist } = this.state;
    pendinglist.anchorElService = null;
    this.setState({
      pendinglist
    })
  }
  /**
   * 
   * @param {event} event
   * TO CLOSE THE USER FILER MENU 
   */
  closeCustomUserFilterPopup = () => {
    const { taskslist } = this.state;
    taskslist.anchorElUser = null;
    taskslist.userSearchValue = "";
    this.setState({
      taskslist
    })
  }
  /**
   * 
   * @param {event} event
   * TO CLOSE THE TERRITORY FILER MENU 
   */
  CloseCustomSinglePopup = () => {
    const { taskslist } = this.state;
    taskslist.anchorEl = null;
    taskslist.territorySearchValue = "";
    this.setState({
      taskslist
    })
  }

  /**
   * 
   * @param {item object} item
   * TO SET SERVICE TYPE ON CLICK OF ITEM
   */
  handleServiceClick = (item) => {
    const { pendinglist } = this.state;
    pendinglist.serviceType = item._id;
    pendinglist.serviceName = item.name;
    pendinglist.anchorElService = null;
    this.setState({
      pendinglist
    }, () => {
      this.fetchPendingList();
    })

  }

  handleEventTypeClick = (item) => {
    const { eventsTypes } = this.state;
    eventsTypes.type = item._id;
    eventsTypes.name = item.name;
    eventsTypes.anchorElevents = null;
    this.setState({
      eventsTypes,
      page: 1
    }, () => {
      // const start = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
      // const end = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
      let data = {
        page: this.state.page,
        pageSize: this.state.pageSize,
        type: this.state.eventsTypes.type,
        today: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
        // momentTimeZone().tz(this.state.timeZoneName)
        // startDate: moment(start).utc(),
        // endDate: moment(end).utc()
      }
      this.fetchPendingAcceptanceListFilter(data);
    })
  }
  /**
   * 
   * @param {item object} item
   * TO SET USER TYPE ON CLICK OF ITEM
   */
  handleUserFilterClick = (item) => {
    const { taskslist } = this.state;
    if (item.name.toLowerCase() === 'me') {
      taskslist.task_filter_name = "";
    } else if (item.name.toLowerCase() === "all") {
      taskslist.task_filter_name = "all";
    } else {
      taskslist.task_filter_name = item.name;
    }

    // if(item.name.toLowerCase() === "all" && item.name.toLowerCase() !== 'me'){
    //   taskslist.searchAll = true;
    // }else{
    //   taskslist.searchAll = false;
    // }
    taskslist.task_filter_id = item._id;
    taskslist.anchorElUser = null;
    taskslist.userSearchValue = "";
    this.setState({
      taskslist
    }, () => {
      this.fetchTodaysTaskBasedOnTerritoryFilter();
    });
  }
  /**
   * 
   * @param {item object} item
   * TO SET TERRITORY TYPE ON CLICK OF ITEM
   */
  handleTerritoryClick = (item) => {
    const { taskslist } = this.state;
    taskslist.territory_filter_name = item.name;
    taskslist.territory_filter_id = item._id;
    taskslist.anchorEl = null;
    taskslist.territorySearchValue = "";
    this.setState({
      taskslist
    }, () => {
      this.fetchTodaysTaskBasedOnTerritoryFilter();
    });
  }

  /**
   * 
   * @param {event target} e
   * TO SET TERRITORY ON SEARCH 
   */
  handleTerritorySearch = (e) => {
    const { value } = e.target;
    const { taskslist } = this.state;
    taskslist.territorySearchValue = value;
    this.setState({
      taskslist
    })
    this.fecthTerritoryFilters(value);
  }
  /**
   * 
   * @param {event target} e
   * TO SET USER ON SEARCH 
   */
  handleUserFilterSearch = (e) => {
    const { value } = e.target;
    const { taskslist } = this.state;
    taskslist.userSearchValue = value;
    this.setState({
      taskslist
    })
    this.fetchUserListFilters(value);

  }


  /**
   * Task summary
   */
  onTodayHandler = () => {
    const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
    //  moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
    const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
    //  moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
    let data = {
      startDate: start,
      endDate: end
    }
    this.setState({
      summarySelected: "today"
    })
    this.fetchTaskSummary(data);
  }
  onThisWeekHandler = () => {
    var startDate = moment.tz(momentTimeZone().startOf("week").format("YYYY-MM-DD 00:00:00"), this.state.timeZoneName).utc();

    var endDate = moment.tz(momentTimeZone().format("YYYY-MM-DD 23:59:59"), this.state.timeZoneName).utc();
    // var startDate = momentTimeZone(momentTimeZone()
    // .startOf("week")
    // .tz(this.state.timeZoneName)
    // .format("YYYY-MM-DD 00:00:00")).utc();

    // var endDate = momentTimeZone(momentTimeZone()
    // .endOf("week")
    // .tz(this.state.timeZoneName)
    // .format("YYYY-MM-DD 23:59:59")).utc();

    // momentTimeZone(new Date()).tz(this.state.timeZoneName);
    this.setState({
      summarySelected: "week"
    })
    let data = {
      startDate,
      endDate
    }
    this.fetchTaskSummary(data);

  }

  onSummaryFilterOpen = (event) => {
    this.fecthSummaryTerritoryFilters();
    const { summary_territory } = this.state;
    summary_territory.anchorElSummaryTerritory = event.currentTarget;
    this.setState({
      summary_territory,
      summarySelected: "",
    })
  }
  onSummaryFilterClose = () => {
    const { summary_territory } = this.state;
    summary_territory.anchorElSummaryTerritory = null;
    this.setState({
      summary_territory
    })
  }
  onSummaryDateFilterOpen = (event) => {
    const { summary_month } = this.state;
    summary_month.anchorElDate = event.currentTarget;
    this.setState({
      summary_month,
      summarySelected: "",
    })
  }
  onSummaryDateFilterClose = () => {
    const { summary_month } = this.state;
    summary_month.anchorElDate = null;
    this.setState({
      summary_month
    })
  }

  onSummaryDateFilter = (item) => {
    var { summary_month } = this.state;
    summary_month.anchorElDate = null;
    var data = {};
    if (item._id === "") {
      const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
      //  moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
      const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
      // moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
      // data.today = momentTimeZone().tz(this.state.timeZoneName).format('YYYY-MM-DD');
      data.startDate = start;
      data.endDate = end;
    } else if (item._id === "last7days") {

      data.startDate = moment.tz(momentTimeZone().subtract(6, 'd').format("YYYY-MM-DD 00:00:00"), this.state.timeZoneName).utc();
      data.endDate = moment.tz(momentTimeZone().format("YYYY-MM-DD 23:59:59"), this.state.timeZoneName).utc();
    } else if (item._id === 'month') {
      data.startDate = moment.tz(momentTimeZone().subtract(29, 'd').format("YYYY-MM-DD 00:00:00"), this.state.timeZoneName).utc();
      data.endDate = moment.tz(momentTimeZone().format("YYYY-MM-DD 23:59:59"), this.state.timeZoneName).utc();//toDay.format('YYYY-MM-')+toDay.daysInMonth();

    } else if (item._id === 'quarter') {
      data.startDate = moment.tz(momentTimeZone().startOf("quarter").format("YYYY-MM-DD 00:00:00"), this.state.timeZoneName).utc();
      data.endDate = moment.tz(momentTimeZone().endOf('quarter').format("YYYY-MM-DD 23:59:59"), this.state.timeZoneName).utc();
    } else if (item._id === 'year') {
      data.startDate = moment.tz(momentTimeZone().startOf('year').format("YYYY-01-01 00:00:00"), this.state.timeZoneName).utc();
      data.endDate = moment.tz(momentTimeZone().endOf('year').format("YYYY-MM-DD 23:59:59"), this.state.timeZoneName).utc();
      // data.endDate = momentTimeZone(momentTimeZone(momentTimeZone().tz(this.state.timeZoneName).endOf('year')._d).format("YYYY-MM-DD 23:59:59")).utc();
    }
    this.fetchTaskSummary(data);
    this.setState({
      summary_month,
      summarySelected: item.name.toLowerCase() === "today" ? "today" : "",
      summaryMonthFilter: item.name.toLowerCase() === "today" ? "" : item.name
    })

  }
  onSummaryTerritoryFilter = (item) => {
    const { summary_territory } = this.state;
    summary_territory.anchorElSummaryTerritory = null;

    this.setState({
      summary_territory,
      summaryTerritorySearch: "",
      summaryTerritoryFilter: item.name.toLowerCase() === 'all' ? "" : item.name,
      summaryTerritoryFilterId: item._id,
      summarySelected: item.name.toLowerCase() === 'all' ? "" : item.name
    }, () => {
      var data = {}
      if (this.state.summaryTerritoryFilterId === "") {
        // data.today=this.state.toDay;
        this.fetchTaskSummary();
      } else {
        data.territoryId = this.state.summaryTerritoryFilterId;
        this.fetchTaskSummary(data);
      }


    })
  }

  onSummaryTerritoryFilterSearch = (e) => {
    const { value } = e.target;
    this.setState({
      summaryTerritorySearch: value
    })
    this.fecthSummaryTerritoryFilters(value);
  }

  fecthSummaryTerritoryFilters = (search) => {
    const { summary_territory } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : 'level', "application/json")
      .post("/dashboardLevelFilter", {
        search,
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (response.status === "200" || response.status === 200) {
          var data = response.data;
          var newArray = summary_territory.summaryTerritory.splice();
          if (this.state.summaryTerritorySearch === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map(item => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          summary_territory.summaryTerritory = newArray;
          this.setState({
            summary_territory
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          summary_territory = {
            ...summary_territory,
            summaryTerritory: []
          };
          this.setState({
            summary_territory
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
        this.setState({
          searchLoader: false
        })
      })
  }

  handlePendingChange = (item) => {
    const { pendingTypeList } = this.state;
    pendingTypeList.pendingType = item._id;
    pendingTypeList.anchorElPendingTypes = null;
    this.setState({
      pendingTypeList
    }, () => {
      if (this.state.pendingTypeList.pendingType.toLowerCase() === 'pending acceptance') {
        this.setState({
          page: 1,
          onLoad: false
        })
        // const start = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
        // const end = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
        let data = {
          page: 1,
          pageSize: this.state.pageSize,
          type: this.state.eventsTypes.type,
          today: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
          //momentTimeZone().tz(this.state.timeZoneName)
          // startDate: moment(start).utc(),
          // endDate: moment(end).utc()
        }
        this.fetchPendingAcceptanceList(data);
      } else {
        this.fetchPendingList();
      }
    })
  }
  onPendingPaginationChange = (event, value) => {
    this.setState({
      page: value,
      onLoad: false
    }, () => {
      // const start = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
      // const end = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
      let data = {
        page: this.state.page,
        pageSize: this.state.pageSize,
        type: this.state.eventsTypes.type,
        today: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
        //momentTimeZone().tz(this.state.timeZoneName)
        // startDate: moment(start).utc(),
        // endDate: moment(end).utc()
      }
      this.fetchPendingAcceptanceList(data);
    })
  }
  fetchPendingAcceptanceList = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .post('/pending/assignments', data)
      .then(resp => {
        let response = resp && resp.data;
        if (response && (response.status === '200' || response.status === 200)) {
          if (response.data.length === 0) {
            const { pendingTypeList } = this.state;
            pendingTypeList.pendingType = "Pending Assignment";
            this.setState({
              pendingTypeList
            })
          } else {
            this.setState({
              pendingAcceptanceList: response.data,
              pendingAcceptanceListPager: response.pager
            })
          }
        } else if (response && (response.status === '401' || response.status === 401)) {
          const { pendingTypeList, eventsTypes } = this.state;
          if (this.state.onLoad) {
            pendingTypeList.pendingType = "Pending Assignment";
          }
          eventsTypes.type = "";
          this.fetchPendingList();
          this.setState({
            pendingAcceptanceList: [],
            pendingAcceptanceListPager: null,
            pendingTypeList,
            eventsTypes
          });
          // let props = {
          //   message: response.msg,
          //   icon: <img src={iconNotificationError} alt="error" />,
          //   type: "error",
          //   placement: "topRight",
          //   duration: 2,
          //   top: 200,
          //   className: "task_notification error-notify",
          // };
          // if(!this.state.onLoad){
          //   NotificationComponent(props);
          // }
        } else if (response && (response.status === '400' || response.status === 400)) {
          this.setState({
            pendingAcceptanceList: [],
            pendingAcceptanceListPager: null
          })
          // let props = {
          //   message: response.msg,
          //   icon: <img src={iconNotificationError} alt="error" />,
          //   type: "error",
          //   placement: "topRight",
          //   duration: 2,
          //   top: 200,
          //   className: "task_notification error-notify",
          // };
          // NotificationComponent(props);
        }
      }).catch(err => {
        this.setState({
          pendingAcceptanceList: [],
          pendingAcceptanceListPager: null
        })
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
  }

  fetchPendingAcceptanceListFilter = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .post('/pending/assignments', data)
      .then(resp => {
        let response = resp && resp.data;
        if (response && (response.status === '200' || response.status === 200)) {
          this.setState({
            pendingAcceptanceList: response.data,
            pendingAcceptanceListPager: response.pager
          })

        } else if (response && (response.status === '401' || response.status === 401)) {
          this.setState({
            pendingAcceptanceList: [],
            pendingAcceptanceListPager: null,
          });
          // var propsErr = {
          //   message: response.msg,
          //   icon: <img src={iconNotificationError} alt="error" />,
          //   type: "error",
          //   placement: "topRight",
          //   duration: 2,
          //   top: 200,
          //   className: "task_notification error-notify",
          // };
          // NotificationComponent(propsErr);
        } else if (response && (response.status === '400' || response.status === 400)) {
          this.setState({
            pendingAcceptanceList: [],
            pendingAcceptanceListPager: null
          })
          // let props = {
          //   message: response.msg,
          //   icon: <img src={iconNotificationError} alt="error" />,
          //   type: "error",
          //   placement: "topRight",
          //   duration: 2,
          //   top: 200,
          //   className: "task_notification error-notify",
          // };
          // NotificationComponent(props);
        }
      }).catch(err => {
        this.setState({
          pendingAcceptanceList: [],
          pendingAcceptanceListPager: null
        })
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
  }

  onAcceptanceHandler = (item, type, page, length) => {
    var recurringStatus = item.scheduleRules;
    if (type === 'accept' && !item.conflict) {
      this.setState({
        taskAcceptBtnClicked: true,
        btnClickId: item._id
      })
    } else if (type === 'reject') {
      this.setState({
        taskRejectBtnClicked: true,
        btnClickId: item._id
      })
    }
    if (item.conflict && item.type === 'task') {
      if (type === 'accept') {
        this.onTaskAcceptRejectHandler(item._id, type, page, length, recurringStatus)
        var end_date = momentTimeZone(item.endDate).tz(this.state.timeZoneName);
        var start_date = momentTimeZone(item.startDate).tz(this.state.timeZoneName);
        var diffTime = end_date.diff(start_date, 'hours');
        var diffMinutes = end_date.diff(start_date, 'minutes');
        var diffSeconds = end_date.diff(start_date, 'seconds');
        diffTime = diffTime === 0 ? diffMinutes + " Minutes" : diffMinutes === 0 ? diffSeconds + " Seconds" : diffTime + " Hours";
      

        this.setState({
          taskConflictModalData: item, diffTime
        }, () => {
          this.setState({
            taskConflictModalOpen: true
          })
        });
      } else {
        this.onTaskAcceptRejectHandler(item._id, type, page, length, recurringStatus)
      }
    } else if (!item.conflict && item.type === 'task') {
      //task accept api
      this.onTaskAcceptRejectHandler(item._id, type, page, length, recurringStatus)
    } else if (item.conflicts && (item.type === 'personal' || item.type === 'meeting')) {
      //event conflict
      if (type === 'accept') {
        this.onEventAcceptRejectHandler(item._id, type, page, length)
        var end_date = momentTimeZone(item.endDate).tz(this.state.timeZoneName);
        var start_date = momentTimeZone(item.startDate).tz(this.state.timeZoneName);
        var diffTime = end_date.diff(start_date, 'hours');
        var diffMinutes = end_date.diff(start_date, 'minutes');
        var diffSeconds = end_date.diff(start_date, 'seconds');
        diffTime = diffTime === 0 ? diffMinutes + " Minutes" : diffMinutes === 0 ? diffSeconds + " Seconds" : diffTime + " Hours";

        this.setState({
          taskConflictModalData: item, diffTime
        }, () => {
          this.setState({
            taskConflictModalOpen: true
          })
        });
      } else {
        this.onEventAcceptRejectHandler(item._id, type, page, length)
      }
    } else if (!item.conflicts && (item.type === 'personal' || item.type === 'meeting')) {
      //event accept
      this.onEventAcceptRejectHandler(item._id, type, page, length);
    }
  }

  onEventAcceptRejectHandler = (id, type,page, length) => {
    let data = {};
    data.id = id;
    data.type = type === 'accept' ? 1 : 2;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .post('/eventAcceptReject', data)
      .then(resp => {
        let response = resp && resp.data;
        if (response && (response.status === '200' || response.status === 200)) {
          // const start = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
          // const end = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
          let data = {
            page: length === 1 ? page - 1 : page,
            pageSize: this.state.pageSize,
            type: this.state.eventsTypes.type,
            // today: momentTimeZone().tz(this.state.timeZoneName)
            today: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            // startDate: moment(start).utc(),
            // endDate: moment(end).utc()
          }
          this.setState({
            onLoad: false,
            page: length === 1 ? page - 1 : page,
          })
          const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
          const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
          this.fetchTaskList({
            startDate: start,
            endDate: end
          });
          this.fetchPendingAcceptanceList(data);
          let props = {
            message: `Event ${type === 'accept' ? "Accepted" : "Rejected"} Successfully`,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(props);
        } else if (response && (response.status === '400' || response.status === 400)) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      }).catch(err => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
  }

  onTaskAcceptRejectHandler = (id, type, page, length, recurringStatus) => {
    var data = {};
    data.taskStatus = type === 'accept' ? "assigned" : "rejected";
    data._id = id;
    if (recurringStatus?.isRecurring == true){
      data.recurringType = true
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .post("/assign_task", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          // const start = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
          // const end = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
          let data = {
            page: length === 1 ? page - 1 : page,
            pageSize: this.state.pageSize,
            type: this.state.eventsTypes.type,
            // today: momentTimeZone().tz(this.state.timeZoneName)
            // startDate: moment(start).utc(),
            // endDate: moment(end).utc()
            today: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
          }
          this.setState({
            onLoad: false,
            page: length === 1 ? page - 1 : page,
          })
          const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
          const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
          this.fetchTaskList({
            startDate: start,
            endDate: end
          });
          this.fetchPendingAcceptanceList(data);
          var props = {
            message: `Task ${type === 'accept' ? "Accepted" : "Rejected"} Successfully`,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(props);
        } else {
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  }

  openConflictModalClose = () => {
    this.setState({
      taskConflictModalOpen: false,
      taskAcceptBtnClicked: false,
      taskRejectBtnClicked: false,
      diffTime: null
    })
  }

  onRescheduleTaskHandler = (item) => {
    if (item.type === 'task') {
      // var location = window.location.href.indexOf("task") > -1 ? refId : `task/${refId}`;
      // this.props.history.replace(location);
      if (window.location.pathname.indexOf("/task") === 0 || window.location.pathname.indexOf("/task") === -1) {
        this.props.history.replace(`/task/${item.conflict_id}`);
      }
      this.setState({
        taskConflictModalOpen: false
      })
    } else {
      if (window.location.pathname.indexOf("/eventdetail") === 0 || window.location.pathname.indexOf("/eventdetail") === -1) {
        this.props.history.replace(`/eventdetail/${item.conflict_id}`);
      }

    }
  }
  onRescheduleTaskHandler1 = (id, type) => {
    if (type === 'task') {
      // var location = window.location.href.indexOf("task") > -1 ? refId : `task/${refId}`;
      // this.props.history.replace(location);
      if (window.location.pathname.indexOf("/task") === 0 || window.location.pathname.indexOf("/task") === -1) {
        this.props.history.replace(`/task/${id}`);
      }
      this.setState({
        taskConflictModalOpen: false
      })
    } else {
      if (window.location.pathname.indexOf("/eventdetail") === 0 || window.location.pathname.indexOf("/eventdetail") === -1) {
        this.props.history.replace(`/eventdetail/${id}`);
      }

    }
  }
  onSelectServiceHandler = (e) =>{
    if(e.target.value){
      this.setState({
        customCancelValue:true,
        cancelValue: e.target.value
      })
    } else{
      this.setState({
        customCancelValue: false,
        cancelValue: ""
      })
    }
  }
  onSelectServiceHandler = (e) =>{
    if(e.target.value){
      this.setState({
        customCancelValue:true,
        cancelValue: e.target.value
      })
    } else{
      this.setState({
        customCancelValue: false,
        cancelValue: ""
      })
    }
  }
  onCancelTaskExit = () => {
    this.setState({
      customCancelValue: "",
      customCancelText: ""
    })
  }
  onCancelTaskDialogClose = () => {
    this.setState({
      openCancelPopup: false
    })
  }
  render() {
    var {
      taskslist,
      statusMenuProps,
      pendingReassignProps,
      isMobile,
      taskComponent,
      pendingComponent,
    } = this.state;
    const taskConflictModal = (
      <Dialog
        open={this.state.taskConflictModalOpen}
        aria-labelledby="form-dialog-title"
        className="add_company_dialog task_assignment_dialog update_status_dialog task_conflict_border"
        TransitionComponent={TransitionGrow}
        transitionDuration={600}
      >
        <DialogTitle
          id="form-dialog-title"
          className="add_user_header task_assignment_header"
        >
          <img src={logo} alt="Sales-Icon" width="142px" height="auto"></img>
          <IconButton
            aria-label="close"
            className="closeBtn updateStatusCloseBtn"
            onClick={this.openConflictModalClose}
          >
            <img src={iconClose}></img>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="task_assign_model_container">
            {this.state.taskConflictModalData ?
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <img className="task-img-width" src={taskAssignThree}></img>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="tasking-assign-right">
                    <h1>{this.state.taskConflictModalData.type === 'task' ? 'Task Assignment' : 'Conflict Found'}</h1>
                    <p><img className="warning_icon" src={taskWarningIcon}></img>
                      This {this.state.taskConflictModalData.type === 'task' ? 'task' : 'event'} conflicts with&nbsp;{upperFirst(this.state.taskConflictModalData.conflict_title)}.</p>
                    <span>{`
                  ${momentTimeZone(this.state.taskConflictModalData.startDate).tz(this.state.timeZoneName).format('MMMM DD YYYY')} 
                  at 
                  ${momentTimeZone(this.state.taskConflictModalData.startDate).tz(this.state.timeZoneName).format('hh:mm A')} 
                  - 
                  ${momentTimeZone(this.state.taskConflictModalData.endDate).tz(this.state.timeZoneName).format('hh:mm A')} 
                  (${this.state.diffTime ? this.state.diffTime : ""})`}</span>
                  </div>
                  <div className="add_company_action task_assign_action">
                    <Button className="cancel_btn white-btn" onClick={this.openConflictModalClose}>Skip</Button>
                    <Button className="ok_btn green-btn" onClick={() => this.onRescheduleTaskHandler(this.state.taskConflictModalData)}>Reassign/Reschedule</Button>
                  </div>
                </Grid>
              </Grid>
              : ""}
          </div>
        </DialogContent>
      </Dialog>
    )
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />
    } else {
      return (
        <div className="body-bg-color dashboard-page">
          {/* <Suspense fallback={<div></div>}> */}
          <HeaderComponent />
          {/* </Suspense> */}
          <div className="dashboard-container">
            {taskConflictModal}
            <div className="dashboard_task_tab">
              <div
                className={`dashboard_task_tab_one dashboard_task_tab_common ${isMobile && taskComponent ? "dashboard_task_tab_active" : ""
                  }`}
                onClick={() => this.toggleComponent("task")}
              >
                Upcoming Tasks
              </div>
              <div
                className={`dashboard_task_tab_two dashboard_task_tab_common  ${isMobile && pendingComponent ? "dashboard_task_tab_active" : ""
                  }`}
                onClick={() => this.toggleComponent("pending")}
              >
                Pending Assignments
              </div>
            </div>
            <Grid container spacing={2} className="grid_container">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className={
                  isMobile && taskComponent
                    ? "taskblock"
                    : !isMobile && (taskComponent || !taskComponent)
                      ? "taskblock"
                      : "taskhide"
                }
              >

                <Suspense fallback={<div></div>}>
                  <ScheduledConfirmationModel
                    modelData={this.state}
                    open={this.state.showaddModal}
                    handleClose={this.onCloseModal}
                    onInprogress={this.onInprogress}
                  />
                  <TodaysTaskComponent
                    isRecurring={this.state.isRecurring}
                    isRecurringTask={this.state.isRecurringTask}
                    recurringStatus={this.state.recurringStatus}
                    taskDetailHandler={this.taskDetailHandler}
                    typoName="Upcoming Tasks"
                    totalItems={
                      Object.entries(taskslist.tasks).length !== 0 &&
                        taskslist.tasks.total > 0
                        ? `${taskslist.tasks.completed} out of ${taskslist.tasks.total} completed`
                        : ""
                    }
                    task_list_data={this.state.taskslist.task_list_data}
                    timeZoneName={this.state.timeZoneName}
                    list={this.state.taskslist}
                    statusMenuProps={this.state.statusMenuProps}
                    onChangeHandler={this.onChangeHandler}
                    showUpdateStatusClose={this.showUpdateStatusClose}
                    onGetDirectionHandler={this.onGetDirectionHandler}

                    onViewAllTask={this.onViewAllTask}
                    open={this.state.taskslist.updatestatusdialog}
                    onupdatestatusdialog={this.onupdatestatusdialoghandle}
                    onUpdateStatusSelect={this.onUpdateStatusSelect}
                    selected={this.state.taskslist.selected}
                    status={this.state.taskslist.status}
                    onupdatestatusdialoghandleClose={
                      this.onupdatestatusdialoghandleClose
                    }
                    updateStatus={this.updateStatus}
                    onclickStatus={this.onclickStatus}
                    onclickStatus1={this.onclickStatus1}
                    onDialogStatusExit={this.onDialogStatusExit}
                    updateStatusTaskId={this.state.updateStatusTaskId}
                    selectedValue={this.state.taskslist.statusSelected}
                    closeModalOnMouseLeave={this.closeModalOnMouseLeave}
                    searchLoader={this.state.searchLoader}

                    openCustomSinglePopup={this.openCustomSinglePopup}
                    CloseCustomSinglePopup={this.CloseCustomSinglePopup}
                    handleClick={this.handleTerritoryClick}
                    onSearchFilter={this.handleTerritorySearch}

                    openCustomUserFilterPopup={this.openCustomUserFilterPopup}
                    closeCustomUserFilterPopup={this.closeCustomUserFilterPopup}
                    handleUserFilterClick={this.handleUserFilterClick}
                    handleUserFilterSearch={this.handleUserFilterSearch}


                    upcomingTask={this.state.upcomingTask}
                    upcomingTaskFilterOpen={this.upcomingTaskFilterOpen}
                    upcomingTaskFilterClose={this.upcomingTaskFilterClose}
                    handleTaskTypeChange={this.handleTaskTypeChange}

                    cancelUpdateStatus={this.state.cancelUpdateStatus}
                    cancelOption={cancelOption}
                    serviceListHandlers={this.serviceListHandlers}
                    onSelectServiceHandler={this.onSelectServiceHandler}
                    changeValues={this.changeValues}
                    onChangeHandlers={this.onChangeHandlers}
                  />
                </Suspense>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className={
                  isMobile && pendingComponent
                    ? "pendingblock"
                    : !isMobile && (taskComponent || !taskComponent)
                      ? "pendingblock"
                      : "pendinghide"
                }
              >
                {this.state.pendingTypeList && this.state.pendingTypeList.pendingType === 'Pending Assignment' ?
                  <Suspense
                    fallback={
                      <div className="lazyLoader">
                        {" "}
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={75}
                          width={75}
                          visible={true}
                        />
                      </div>
                    }
                  >
                    <PendingTaskComponent
                      openScheduler={this.openSchedulerHandler}
                      taskDetailHandler={this.taskDetailHandler}
                      typoName="Pending Assignment"

                      handlePendingChange={this.handlePendingChange}
                      pendingTypeList={this.state.pendingTypeList}
                      pendingTypeSelectSelectPopupOpen={this.pendingTypeSelectSelectPopupOpen}
                      pendingTypeSelectSelectPopupClose={this.pendingTypeSelectSelectPopupClose}

                      task_list_data={this.state.pendinglist.pending_list_data}
                      list={this.state.pendinglist}
                      timeZoneName={this.state.timeZoneName}
                      statusMenuProps={pendingReassignProps}
                      onStatusUpadteModal={this.on_Pending_StatusUpadteModal}
                      showUpdateStatusClose={this.on_Pending_showUpdateStatusClose}
                      openlistenerhandle={this.openlistenerhandle}
                      open={this.state.opens}
                      reassign_to={this.state.pendinglist.reassign_to}
                      onReasignSelect={this.onReasignSelect}
                      onassignee={this.get_assignee_name_handler}
                      pendingdialogopen={this.state.pendingdialogopen}
                      pendingupdatestatushandler={this.pendingupdatestatushandler}
                      pendingassignee={this.state.pendingassignee}
                      onReassigneeSelect={this.onReassigneeSelect}
                      onReassigneeChange={this.onReassigneeChange}
                      onAssigneeAutoCompleteSelect={
                        this.onAssigneeAutoCompleteSelect
                      }
                      updateAssignee={this.updateAssigneeHandler}
                      onclickReassignee={this.onclickReassignee}
                      onclickUnassigned={this.onclickUnassigned}

                      assigneeError={this.state.assigneeError}
                      handleClick={this.handleServiceClick}
                      openCustomServiceSelectPopup={this.openCustomServiceSelectPopup}
                      closeCustomServiceSelectPopup={this.closeCustomServiceSelectPopup}
                    />
                  </Suspense> :
                  this.state.pendingTypeList && this.state.pendingTypeList.pendingType === 'Pending Acceptance' ?
                    <PendingAcceptanceComponent

                      list={this.state.eventsTypes}
                      pendingAcceptanceList={this.state.pendingAcceptanceList}
                      timeZoneName={this.state.timeZoneName}
                      handlePendingChange={this.handlePendingChange}
                      handleEventTypeClick={this.handleEventTypeClick}
                      typeSelectSelectPopupClose={this.typeSelectSelectPopupClose}
                      typeSelectSelectPopupOpen={this.typeSelectSelectPopupOpen}

                      pendingTypeList={this.state.pendingTypeList}
                      pendingTypeSelectSelectPopupOpen={this.pendingTypeSelectSelectPopupOpen}
                      pendingTypeSelectSelectPopupClose={this.pendingTypeSelectSelectPopupClose}

                      onPendingPagination={this.onPendingPaginationChange}
                      page={this.state.page}
                      count={this.state.pendingAcceptanceListPager}

                      onAcceptanceHandler={this.onAcceptanceHandler}
                      taskRejectBtnClicked={this.state.taskRejectBtnClicked}
                      taskAcceptBtnClicked={this.state.taskAcceptBtnClicked}
                      btnClickId={this.state.btnClickId}
                      detailViewHandler={this.onRescheduleTaskHandler1}

                    /> : null
                }
              </Grid>
            </Grid>
            {this.state.taskSummary ?
              // <Grid container spacing={3}>
              <>
                <div className="dashboard_task_filters">
                  <label className="tableTitle">Task Summary</label>
                  <div className="new_custom_filters">
                    <div className="new_custom_filter_div ">
                      <span
                        className={`rightName ${this.state.summarySelected === 'today' ? "selected" : ""}`}
                        onClick={this.onTodayHandler}>Today</span>
                    </div>
                    <div className="new_custom_filter_div ">
                      <span

                        onClick={this.onThisWeekHandler}
                        className={`rightName ${this.state.summarySelected === 'week' ? "selected" : ""}`}>This week</span>
                    </div>
                    {/* <div className="todays_tasks_grid_heading_two"> */}
                    <Suspense fallback={<div></div>}>
                      <div className="new_custom_filter_div "
                        onClick={this.onSummaryDateFilterOpen}>
                        {this.state.summaryMonthFilter ? "" :
                          <label className="leftName">Filter By: </label>
                        }
                        <label
                          aria-label="summary_date_filter"
                          aria-controls="summary_date_filter_control"
                          area-aria-haspopup="true"
                          className="rightName"
                        >
                          {this.state.summaryMonthFilter ? upperFirst(this.state.summaryMonthFilter) : `Today`}
                        </label>
                        <img className="carret" src={carret_down} alt="carret" />
                      </div>
                      <CustomSelectComponent
                        isAllRequired={true}
                        filterArray={this.state.summary_month.summaryDate}
                        closeCustomPopup={this.onSummaryDateFilterClose}
                        config={this.state.summary_month.id}
                        anchorEl={this.state.summary_month.anchorElDate}
                        handleClick={this.onSummaryDateFilter}
                      />
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                      <div className="new_custom_filter_div "
                        onClick={this.onSummaryFilterOpen}>
                        {this.state.summaryTerritoryFilter ? "" :
                          <label className="leftName">Territory: </label>}
                        <label
                          aria-label="summary_territory_filter"
                          aria-controls="summary_territory_filter_control"
                          area-aria-haspopup="true"
                          className="rightName"
                        >
                          {this.state.summaryTerritoryFilter ? upperFirst(this.state.summaryTerritoryFilter) : `All`}

                        </label>
                        <img className="carret" src={carret_down} alt="carret" />
                      </div>
                      <CustomSelectWithSearchComponent
                        filterArray={this.state.summary_territory.summaryTerritory}
                        config={this.state.summary_territory.id}
                        placeholder="Search Territory"
                        anchorEl={this.state.summary_territory.anchorElSummaryTerritory}
                        searchValue={this.state.summaryTerritorySearch}
                        handleClick={this.onSummaryTerritoryFilter}
                        closeCustomPopup={this.onSummaryFilterClose}
                        onSearchFilter={this.onSummaryTerritoryFilterSearch}
                      />
                    </Suspense>

                  </div>
                </div>
                <Suspense fallback={<div></div>}>

                  <TaskSummaryComponent
                    taskSummary={this.state.taskSummary}
                  />

                </Suspense>
              </>
              // </Grid>
              : ""}
              {/* {this.state.openCancelPopup && */}
            <Dialog
              onExit={this.onCancelTaskExit}
              open={this.state.openCancelPopup}
              aria-labelledby="form-dialog-title"
              className="add_company_dialog add_account_dialog update_status_dialog"
              TransitionComponent={TransitionGrow}
              transitionDuration={600}
            >
              <DialogTitle
                id="form-dialog-title"
                className="add_user_header"
              >
                <span>Cancel Task</span>
                <IconButton
                  aria-label="close"
                  className="closeBtn updateStatusCloseBtn"
                  onClick={this.onCancelTaskDialogClose}
                >
                  <img src={iconClose} alt="close"></img>
                </IconButton>
              </DialogTitle>
              <DialogContent>
              {cancelOption && cancelOption.length  > 0 && 
                <>
              <label className="label">Select Reason</label>
                <Autocomplete
                  id="cancel"
                  className="cusautocomplete"
                  options={cancelOption}
                  getOptionLabel={(option) => option.text}
                  onInputChange={() => { }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      margin="dense"
                      name="serviceName"
                      placeholder="Search cancel value"
                      onChange={this.serviceListHandlers}
                      onSelect={this.onSelectServiceHandler}
                    />
                  )}
                />
                </>}
                  <div style={{ marginTop: "20px" }}>
                    <label className="label">Add note</label>
                    <CssTextField
                      id="outlined-basic"
                      name="addNote"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      fullWidth
                      placeholder="Enter text"
                      onChange={this.onChangeHandlers}
                    />
                  </div>

                <div className="add_company_action">
                  <Button
                    className="ok_btn green-btn"
                    onClick={() => this.updateStatus()}
                    color="primary"
                  >
                    Cancel Task
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            
          </div>
          <Suspense fallback={<div></div>}>
            <FooterComponent
              footerLists={this.state.footerList}
            />
          </Suspense>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true
  };
};

export default connect(
  mapStateToProps
)(withRouter(DashboardComponent));
