import React from "react";
import { withStyles } from "@material-ui/core/styles";
import closeBtn from "../../../Assets/images/close_btn.svg";
import IconButton from "@material-ui/core/IconButton";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  DialogTitle,
  Select,
  FormControl,
} from "@material-ui/core";
import { Transition } from "../../../SharedComponents/CommonUtilities/CommonUtilities";


const ChangeLevelModal = (props) => {
  return (
    <div>
      <div>
        <Dialog
          open={props.openModel}
          onClose={props.onClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog add_account_dialog"
          TransitionComponent={Transition} 
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <span>Edit User details</span>
            <IconButton
              onClick={props.onClose}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn} alt="close"/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <form>
              <span
                className={
                  props.modelData.hideRole ? "hide-edit-1" : "show-edit-1"
                }
              >
                <label className="label bl-label">Select Role</label>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    margin="dense"
                    className="customSelect"
                    labelId="demo-simple-select-outlined-label"
                    name="Ptrole"
                    id="demo-simple-select-outlined"
                    fullWidth
                    defaultValue={props.modelData.role}
                    onChange={props.onChangeHandler}
                  >
                    <MenuItem value="">None</MenuItem>
                    {props.options.map((mitem) => (
                      <MenuItem
                        value={
                          mitem.value.toLowerCase() === "select role"
                            ? ""
                            : mitem.value
                        }
                      >
                        {mitem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                  <span className="modal-err-msg error">
                    {props.modelData.formError.Ptrole}
                    <br></br>
                  </span>
                {props.modelData.role !== props.modelData.Ptrole ? (
                  <span
                    className={
                      props.modelData.hideLevel ? "hide-edit" : "show-edit"
                    }
                  >
                    {(props.modelData.Ptrole === "Division Manager" ||
                      props.modelData.Ptrole === "Area Manager" ||
                      props.modelData.Ptrole === "Team Lead" ||
                      props.modelData.Ptrole === "Sales Rep" ||
                      props.modelData.Ptrole === "Territory Manager") &&
                    props.modelData.companySize.toLowerCase() === "large" ? (
                      <>
                        <label className="label">Division</label>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            margin="dense"
                            className="customSelect"
                            placeholder="Division"
                            labelId="demo-simple-select-outlined-label"
                            name="Ptdivision"
                            id="demo-simple-select-outlined"
                            fullWidth
                            defaultValue={
                              props.modelData.level === "division"
                                ? props.modelData.levelId
                                : props.modelData.Ptdivision
                            }
                            onChange={props.onChangeHandler}
                          >
                            <MenuItem value="">None</MenuItem>
                            {props.modelData.divisionFilter !== undefined
                              ? props.modelData.divisionFilter.map((val) => (
                                  <MenuItem value={val._id}>
                                    {val.name}
                                  </MenuItem>
                                ))
                              : ""}
                          </Select>
                        </FormControl>
                          <span className="modal-err-msg error">
                            {props.modelData.formError.Ptdivision}
                            <br></br>
                          </span>
                      </>
                    ) : (
                      ""
                    )}
                    {(props.modelData.Ptrole === "Area Manager" ||
                      props.modelData.Ptrole === "Team Lead" ||
                      props.modelData.Ptrole === "Sales Rep" ||
                      props.modelData.Ptrole === "Territory Manager") &&
                    (props.modelData.companySize.toLowerCase() === "medium" ||
                      props.modelData.companySize.toLowerCase() === "large") ? (
                      <>
                        <label className="label bl-label">Area</label>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            margin="dense"
                            className="customSelect"
                            placeholder="Area"
                            labelId="demo-simple-select-outlined-label"
                            name="Ptarea"
                            id="demo-simple-select-outlined"
                            fullWidth
                            defaultValue={
                              props.modelData.level === "area"
                                ? props.modelData.levelId
                                : props.modelData.Ptarea
                            }
                            onChange={props.onChangeHandler}
                          >
                            <MenuItem value="">None</MenuItem>
                            {props.modelData.areaFilter.map((val) => (
                              <MenuItem value={val._id}>{val.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                          <span className="modal-err-msg error">
                            {props.modelData.formError.Ptarea}
                            <br></br>
                          </span>
                      </>
                    ) : (
                      ""
                    )}
                    {props.modelData.Ptrole === "Team Lead" ||
                    props.modelData.Ptrole === "Sales Rep" ||
                    props.modelData.Ptrole === "Territory Manager" ? (
                      <>
                        <label className="label bl-label">Region</label>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            margin="dense"
                            className="customSelect"
                            placeholder="Region"
                            labelId="demo-simple-select-outlined-label"
                            name="Ptregion"
                            id="demo-simple-select-outlined"
                            fullWidth
                            defaultValue={
                              props.modelData.level === "region"
                                ? props.modelData.levelId
                                : props.modelData.Ptregion
                            }
                            onChange={props.onChangeHandler}
                          >
                            <MenuItem value="">None</MenuItem>
                            {props.modelData.regionFilter.map((val) => (
                              <MenuItem value={val._id}>{val.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                          <span className="modal-err-msg error">
                            {props.modelData.formError.Ptregion}
                            <br></br>
                          </span>
                      </>
                    ) : (
                      ""
                    )}
                    {props.modelData.Ptrole === "Sales Rep" || props.modelData.Ptrole === "Territory Manager"? (
                      <>
                        <label className="label bl-label">Territory</label>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            margin="dense"
                            className="customSelect"
                            placeholder="Territory"
                            labelId="demo-simple-select-outlined-label"
                            name="Ptterritory"
                            id="demo-simple-select-outlined"
                            fullWidth
                            defaultValue={
                              props.modelData.level === "territory"
                                ? props.modelData.levelId
                                : ""
                            }
                            onChange={props.onChangeHandler}
                          >
                            <MenuItem value="">None</MenuItem>
                            {props.modelData.territoryFilter.map((val) => (
                              <MenuItem value={val._id}>{val.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                          <span className="modal-err-msg error">
                            {props.modelData.formError.Ptterritory}
                            <br></br>
                          </span>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                ) : (
                  ""
                )}
              </span>
              <span
                className={
                  props.modelData.hideRole ? "show-edit-1" : "hide-edit-1"
                }
              >
                {(props.modelData.level === "division" ||
                  props.modelData.level === "area" ||
                  props.modelData.level === "region" ||
                  props.modelData.level === "territory") &&
                props.modelData.companySize.toLowerCase() === "large" ? (
                  <>
                    <label className="label bl-label">Division</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        placeholder="Division"
                        labelId="demo-simple-select-outlined-label"
                        name="Ptdivision"
                        id="demo-simple-select-outlined"
                        fullWidth
                        defaultValue={
                          props.modelData.level === "division"
                            ? props.modelData.levelId
                            : props.modelData.division_id
                        }
                        onChange={props.onChangeHandler}
                      >
                        <MenuItem value="">None</MenuItem>
                        {props.modelData.divisionFilter !== undefined
                          ? props.modelData.divisionFilter.map((val) => (
                              <MenuItem value={val._id}>{val.name}</MenuItem>
                            ))
                          : ""}
                      </Select>
                    </FormControl>
                      <span className="modal-err-msg error">
                        {props.modelData.formError.Ptdivision}
                        <br></br>
                      </span>
                  </>
                ) : (
                  ""
                )}
                {(props.modelData.level === "area" ||
                  props.modelData.level === "region" ||
                  props.modelData.level === "territory") &&
                (props.modelData.companySize.toLowerCase() === "medium" ||
                  props.modelData.companySize.toLowerCase() === "large") ? (
                  <>
                    <label className="label bl-label">Area</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        placeholder="Area"
                        labelId="demo-simple-select-outlined-label"
                        name="Ptarea"
                        id="demo-simple-select-outlined"
                        fullWidth
                        defaultValue={
                          props.modelData.level === "area"
                            ? props.modelData.levelId
                            : props.modelData.area_id
                        }
                        onChange={props.onChangeHandler}
                      >
                        <MenuItem value="">None</MenuItem>
                        {props.modelData.areaFilter.map((val) => (
                          <MenuItem value={val._id}>{val.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      <span className="modal-err-msg error">
                        {props.modelData.formError.Ptarea}
                        <br></br>
                      </span>
                  </>
                ) : (
                  ""
                )}
                {props.modelData.level === "region" ||
                props.modelData.level === "territory" ? (
                  <>
                    <label className="label bl-label">Region</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        placeholder="Region"
                        labelId="demo-simple-select-outlined-label"
                        name="Ptregion"
                        id="demo-simple-select-outlined"
                        fullWidth
                        defaultValue={
                          props.modelData.level === "region"
                            ? props.modelData.levelId
                            : props.modelData.region_id
                        }
                        onChange={props.onChangeHandler}
                      >
                        <MenuItem value="">None</MenuItem>
                        {props.modelData.regionFilter.map((val) => (
                          <MenuItem value={val._id}>{val.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      <span className="modal-err-msg error">
                        {props.modelData.formError.Ptregion}
                        <br></br>
                      </span>
                  </>
                ) : (
                  ""
                )}
                {props.modelData.level === "territory" ? (
                  <>
                    <label className="label bl-label">Territory</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        placeholder="Territory"
                        labelId="demo-simple-select-outlined-label"
                        name="Ptterritory"
                        id="demo-simple-select-outlined"
                        fullWidth
                        defaultValue={
                          props.modelData.level === "territory"
                            ? props.modelData.levelId
                            : ""
                        }
                        onChange={props.onChangeHandler}
                      >
                        <MenuItem value="">None</MenuItem>
                        {props.modelData.territoryFilter.map((val) => (
                          <MenuItem value={val._id}>{val.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      <span className="modal-err-msg error">
                        {props.modelData.formError.Ptterritory}
                        <br></br>
                      </span>
                  </>
                ) : (
                  ""
                )}
              </span>
              <div className="add_company_action">
                <Button
                  className="cancel_btn white-btn"
                  onClick={props.onClose}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  className="ok_btn green-btn"
                  onClick={props.onChangelevel}
                  variant="contained"
                  disabled={props.modelData.inValid}
                >
                  Submit
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ChangeLevelModal;
