import React, { Component, Suspense } from "react";
import "./CompanyComponent.css";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import * as action from "../../Store/Actions/index";
import { upperFirst, lowerCase } from "lodash";
import moment from 'moment';
import {
  Box,
  Typography,
  Button,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { connect } from "react-redux";
import carret_down from "../../Assets/images/carret_down.svg";
import { Redirect, withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import { AddCompanyModal } from "./AddCompanyModal";
import { AxiosInstance } from "../../Utilities";

import searchIcon from "../../Assets/images/search_icon.svg";
import adminviewIcon from "../../Assets/images/admin_view.svg";
import viewAction from "../../Assets/images/view_action.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  emailRegx,
  phoneRegx,
} from "../../SharedComponents/Validation/formValidation";
import validator from "validator";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import Delete from "../../Assets/images/delete.svg";
import restorecompany from "../../Assets/images/Group 759.svg";
import {
  getGooglePlaceObjectValues,
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../SharedComponents/CommonUtilities/CommonUtilities";
import DateDiff from 'date-diff';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { TransitionGrow } from '../../SharedComponents/CommonUtilities/CommonUtilities';
import momentTimeZone from 'moment-timezone';
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { DesktopWindows } from "@material-ui/icons";
import { isSafari } from "react-device-detect";
// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const url =  new URL(window.location.href);  
const statusValue = url.searchParams.get('status');
const companyValue = url.searchParams.get('company');
const searchFilterValue = url.searchParams.get('search');
class CompanyComponent extends Component {
  constructor(props) {
    super(props);
    let userRole = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata")).role
      : "";
    var zoneName = momentTimeZone.tz.guess();
    this.state = {
      statusFilterNames:'',
      companySizeFilter:'',
      showaddModal: false,
      timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
      userRole: userRole,
      companyList: [],
      companyName: "",
      companyCode: "",
      companyLogo: null,
      companyLogoo:"",
      isuplode: true,
      file: null,
      companyPhone: "",
      showaddModal: false,
      regData: {},
      companyEmail: "",
      disable: false,
      companyAddress: "",
      companyWebsite: "",
      Address: "",
      companySize: "",
      companyDescription: "",
      responseMsg: "",
      alertType: "",
      page: 0,
      pageNumber: 1,
      rowsPerPage: 10,
      loading: true,
      isValid: false,
      formError: {
        companyName: "",
        companyCode: "",
        companyPhone: "",
        companyEmail: "",
        companyAddress: "",
        companyWebsite: "",
        companySize: "",
        billingConfigType: "",
        billingPrice: ""
      },
      isSubmit: false,
      delete_id: "",
      statusFilterName: "",
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      deletedConfig: {
        config: {
          id: "deleted_filter_select",
        },
        anchorElDeleted: null,
      },
      deletedStatus: "",
      deletedFilterName: "",


      billingConfigType: '1',
      billingTrialEnds: [
        momentTimeZone(new Date()).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
        momentTimeZone(new Date()).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).add(14, 'days')
      ],
      billingTrialDays: 14,
      billingPrice: 29.99,
      tooltipprops: {}
    };
  }
  UNSAFE_componentWillMount() {
    let obj = {}
    if (isSafari) {
      obj.open = false;
      this.setState({ tooltipprops: obj })
    }
  }
  componentDidMount = () => {
    window.onpopstate = () =>{
      window.location.reload()
    }
    const queryString = this.props.location.search;
    const pageParameter = new URLSearchParams(queryString).get("page");
    const pageNumber = parseInt(pageParameter);
    if (this.props.location.state === null || this.props.location.state === undefined) {
      this.setState({
        showaddModal: false,
      });
    } else {
      this.setState({
        regData: this.props.location.state
          ? this.props.location.state.UserData.data
          : null,
        showaddModal: true,
      });
    }
    if(statusValue && statusValue !== "All"){
      var deletedOptions = [
        { _id: "true", name: "Deleted" },
      ];
      const deleteValue = deletedOptions.find(item => item.name === statusValue)
      this.setState({
        deletedFilterName: deleteValue?.name ? deleteValue?.name : ""
      })
    }
    if(companyValue && companyValue !== "All"){
      var statusOptions = [
        { _id: "small", name: "Small" },
        { _id: "medium", name: "Medium" },
        { _id: "large", name: "Large" },
      ];
      const companyValues = statusOptions.find(item => item.name.toLocaleLowerCase() === companyValue.toLocaleLowerCase())
      this.setState({
        statusFilterName: companyValues?._id ? companyValues?._id : ""
      })
    }
    this.setState({ loading: true }, () => {
      this.props.onPagination(pageNumber?pageNumber:this.state.pageNumber - 1, this.state.rowsPerPage, this.state.deletedFilterName, this.state.statusFilterName, searchFilterValue);
    });
    setTimeout(() => {
      this.componentWillReceiveProps()
    }, 500);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };

  componentWillReceiveProps = () => {
    if (
      this.props.location.state === null ||
      this.props.location.state === undefined
    ) {
      this.setState({
        companyName: "",
        companyPhone: "",
        companyEmail: "",

      });
    } else {
      this.setState({
        refferalId: this.state.regData ? this.state.regData[0]._id : "",
        companyName: this.state.regData ? this.state.regData[0].companyName : "",
        companyPhone: this.state.regData ? this.state.regData[0].phone : "",
        companyEmail: this.state.regData ? this.state.regData[0].email : "",
      });
    }
  };
  handleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ pageNumber: newPage, loading: true },
      () => {
        this.filter();
      }
    );

    // this.props.onPagination(newPage, this.state.rowsPerPage);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, loading: true, pageNumber: 1, },
      () => {
        this.filter();
      }
    );

    // this.props.onPagination(this.state.pageNumber, +event.target.value);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  companyFilter = (event) => {
    this.setState(
      { pageNumber: 1, [event.target.name]: event.target.value, loading: true },
      () => {
        let data = {
          deleted: this.state.deletedStatus ? this.state.deletedStatus : this.state.deletedFilterName === "Deleted" ? "true" :'',
          page: this.state.pageNumber,
          pageSize: this.state.rowsPerPage,
          companySize: this.state.companySize ? this.state.companySize : this.state.statusFilterName,
          searchText: this.state.searchFilter,
        };
        this.props.onFilter(data);
      }
    );
    this.updateURL(event.target.value)
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  makeAdminHandler = (id) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/json")
      .post("/superadmin/createAdminAccess", { companyId: id })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          localStorage.clear();
          localStorage.setItem("userdata", JSON.stringify(response.data));
          localStorage.setItem("token", response.token.split(" ")[1]);
          localStorage.setItem("switchedToSuperAdmin", true);
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  onFileuploadHandler = (event) => {
    const { formError } = this.state;
    var x = document.getElementsByClassName("input_two");
    x[0].innerHTML = "Upload logo file in jpg, png, svg format";
    if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|svg)$/)) {
      formError.companyLogo = "* Upload valid image";
      this.setState({
        companyLogo: "",
        file: null,
        loaded: 0,
        isuplode: true,
      });
    } else {
      formError.companyLogo = "";
      var y = document.getElementsByClassName("input_two");
      y[0].innerHTML = event.target.files[0].name;
      this.setState({
        companyLogoo: event.target.files[0],
        file: URL.createObjectURL(event.target.files[0]),
        loaded: 0,
        isuplode: false,
      });
    }
    this.setState({ formError });
  };
  handleChange = (event) => {
    const { formError, } = this.state;
    let identifier = event.target.name;
    let value = event.target.value;
    switch (identifier) {
      case "billingPrice":
        validator.isEmpty(value)
          ? formError.billingPrice = "Please enter a billing price"
          : !/^(?!0+$)\d{1,6}(\.\d{1,2})?$/.test(value)
            ? formError.billingPrice = "Please enter the valid price. e.g: $2,500"
            : formError.billingPrice = "";
        break;
      case "companyName":
        value.trim() === "" ?
          formError.companyName = "* Please enter the company name"
          : !validator.isLength(value.trim(), { max: 50 }) ?
            formError.companyName = "* Company Name should not exceed 50 characters."
            : formError.companyName = "";
        break;
      case "address":
        if (!validator.isLength(value.trim(), { max: 100 })) {
          formError.companyAddress = "* Address should not exceed 100 characters";
        } else {
          formError.companyAddress = "";
        }
        break;
      case "companyCode":
        if (!validator.isLength(value.trim(), { max: 4 })) {
          formError.companyCode = "* Code should not exceed 4 characters";
        } else {
          formError.companyCode = "";
        }
        break;
      case "companyEmail":
        if (!emailRegx.test(value.trim()) && value.trim().length > 0) {
          formError.companyEmail =
            "* Please check the email address is in valid format e.g. name@domain.com";
        } else if (!validator.isLength(value, { max: 100 })) {
          formError.companyEmail = "* Email should not exceed 100 characters";
        } else {
          formError.companyEmail = "";
        }
        break;
      case "companyPhone":
        if (value.trim() === 0) {
          formError.companyPhone = "";
        }
        else if (!phoneRegx.test(value.trim()) && value.trim().length > 0) {
          formError.companyPhone = "* Please enter the valid phone number e.g.: 800-555-5555";
        } else {
          formError.companyPhone = "";
        }
        break;
      case "companyLogo":
        if (!phoneRegx.test(value.trim()) && value.trim().length > 0) {
          formError.companyLogo = "* Upload a valid image";
        } else {
          formError.companyLogo = "";
        }
        break;
      case "companyWebsite":
        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);
        if (value.trim() === 0) {
          formError.companyWebsite = "";
        } else if (!regex.test(value.trim()) && value.trim().length > 0) {
          formError.companyWebsite = "* Enter valid website url";
        } else {
          formError.companyWebsite = "";
        }
        break;
      case "companySize":
        if (value.trim() === "") {
          formError.companySize = "*Please select the company size";
        } else {
          formError.companySize = "";
        }
        break;
      default:
        break;
    }

    this.setState({
      [event.target.name]: event.target.value,
      formError,
    });
  };
  checkCompanyId = () => {
    let data = {
      companyName: this.state.companyName,
      companyId: this.state.companyCode,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/company/checkCompanyId", data)
      .then((resp) => {
        if (resp.data.status === 200 || resp.status === 200) {
          this.setState({ companyCode: resp.data.companyId });
        } else {
          this.setState({ responseMsg: resp.data.message, alertType: "error" });
          this.setState({ companyCode: resp.data.companyId });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  Addcompanyfn = async (e) => {
    e.preventDefault();
    const { formError } = this.state;
    if (this.state.companyName.trim() === "") {
      formError.companyName = "*Please enter a company name";
    } else {
      formError.companyName = "";
    }

    // if (this.state.Address.trim() === "") {
    //   formError.companyAddress = "*Please enter the company address";
    // } else {
    //   formError.companyAddress = "";
    // }

    // if(this.state.companyCode.trim() === ""){
    //   formError.companyCode="*Please enter the company code";
    // }else{
    //   formError.companyCode="";
    // }

    // if (this.state.companyPhone.trim() === "") {
    //   formError.companyPhone = "*Please enter the company phone";
    // } else {
    //   formError.companyPhone = "";
    // }

    if (this.state.companyEmail.trim() === "") {
      formError.companyEmail = "*Please enter the company email";
    } else {
      formError.companyEmail = "";
    }

    // if (this.state.companyWebsite.trim() === "") {
    //   formError.companyWebsite = "*Please enter the company website";
    // } else {
    //   formError.companyWebsite = "";
    // }

    if (this.state.companySize.trim() === "") {
      formError.companySize = "*Please select the company size";
    } else {
      formError.companySize = "";
    }

    if (this.state.billingPrice === "") {
      formError.billingPrice = "Please enter a billing price";
    } else {
      formError.billingPrice = "";
    }

    this.setState({
      formError
    }, () => {
      const isEmpty = Object.values(formError).every(
        (x) => x === null || x === ""
      );
      if (isEmpty) {

        this.setState({ isSubmit: true, responseMsg: "" });
        let trialStart = momentTimeZone(this.state.billingTrialEnds[0]).tz(this.state.timeZoneName).utc(); //.format('MM-DD-YYYY hh:mm A');
        let trialEnd = momentTimeZone(this.state.billingTrialEnds[1]).tz(this.state.timeZoneName).utc();//.format('MM-DD-YYYY hh:mm A');
        //  console.log("trial--",trialStart,trialEnd)
        //  this.state.billingTrialEnds,"\n",trialStart, trialEnd, moment(momentTimeZone(this.state.billingTrialEnds[0]).tz(this.state.timeZoneName)).utc(), new Date(trialStart).toUTCString())
        let Filedata = new FormData();
        Filedata.append("refferalId", this.state.refferalId);
        Filedata.append("companyName", this.state.companyName);
        Filedata.append("companyId", this.state.companyCode);
        Filedata.append("address", this.state.Address);
        Filedata.append("website", this.state.companyWebsite);
        Filedata.append("email", this.state.companyEmail);
        Filedata.append("phone", this.state.companyPhone);
        Filedata.append("companyLogo", this.state.companyLogo);
        Filedata.append("size", this.state.companySize);
        Filedata.append("description", this.state.companyDescription);
        Filedata.append("billingType", this.state.billingConfigType);
        Filedata.append("pricePerUser", parseFloat(this.state.billingPrice));
        // Filedata.append("billingTrialDays",this.state.billingTrialDays);
        Filedata.append("trialStart", trialStart);//new Date(trialStart).toUTCString());
        Filedata.append("trialEnd", trialEnd);//new Date(trialEnd).toUTCString());

        if (!this.state.isValid) {
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/company", Filedata)
            .then((resp) => {
              if (resp.data.status === 201) {
                this.setState(
                  {
                    responseMsg: "Company details added successfully",
                    alertType: "success",
                  },
                  () => {
                    setTimeout(() => {
                      this.componentDidMount();
                      this.setState({ showaddModal: false, isSubmit: false });
                    }, 2000);
                  }
                );
              } else {
                this.setState({
                  responseMsg: resp.data.msg,
                  alertType: "error",
                  isSubmit: false,
                });
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
              this.setState({
                responseMsg: "Something went wrong, Please try again later",
                alertType: "error",
                isSubmit: false,
              });
            });
        } else {
          this.setState({ isSubmit: false });
        }
      }
    });
  };
  onclickBtn = (e) => {
    //e.preventDefault();
    const {
      companyName,
      companyCode,
      companyPhone,
      companyEmail,
      Address,
      companySize,
      billingPrice,
      companyWebsite,
    } = this.state;
    if (
      // companyWebsite === "" ||
      companyName === "" ||
      companyCode === "" ||
      // companyPhone === "" ||
      companyEmail === "" ||
      // Address === "" ||
      companySize === "" ||
      billingPrice === ""
    ) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  onViewHandleClick = (row) => {
    this.setState({ delete_id: row._id, showdelete: true });
  };
  onDelete = () => {
    this.setState({ disable: true });
    var { delete_id } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .delete("/company/delete/" + delete_id)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false,
            },
            () => {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          let data = {
            page: this.state.pageNumber,
            pageSize: this.state.rowsPerPage,
            companySize: this.state.companySize,
            searchText: this.state.searchFilter,
          };
          this.props.onFilter(data);
          setTimeout(() => {
            this.setState({ loading: false });
          }, 2000);
        } else {
          this.setState({ disable: false, showdelete: false });
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          let data = {
            page: this.state.pageNumber,
            pageSize: this.state.rowsPerPage,
            companySize: this.state.companySize,
            searchText: this.state.searchFilter,
          };
          this.props.onFilter(data);
          setTimeout(() => {
            this.setState({ loading: false });
          }, 2000);
        }
      });
  };
  restoreCompany = (row) => {
    var data = {
      companyId: row._id
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/json")
      .put("/company/restore", data)
      .then((res) => {
        if (res.data.status === "200") {
          this.setState(
            {
              loading: true,
              isSubmit: false,
            },
            () => {
              var props = {
                message: res.data.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.filter()
        } else {
          this.setState({ isSubmit: false });
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.componentDidMount();
        }
      });
  };
  /**
   * @author Murali
   * To open status filter menu
   */
  openStatusFilterMenu = (event) => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author Murali
   * To close status filter menu
   */
  closeStatusFilterMenu = () => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author Murali
   * To display data based on selected status.
   */
  handleStatusSelect = (item) => {
    var { companySize, statusFilterName, statusConfig } = this.state;
    companySize = item._id;
    statusFilterName = item.name;
    statusConfig.anchorElStatus = null;

    this.setState(
      {
        statusConfig,
        statusFilterName,
        companySize,
        loading: true,
        pageNumber: 1,
        companySizeFilter:item.name===""?"All":item.name
      },
      () => {
        this.filter()
      }
    );
  };
  opendeletedFilterMenu = (event) => {
    const { deletedConfig } = this.state;
    deletedConfig.anchorElDeleted = event.currentTarget;
    this.setState({
      deletedConfig,
    });
  };
  closedeletedFilterMenu = () => {
    const { deletedConfig } = this.state;
    deletedConfig.anchorElDeleted = null;
    this.setState({
      deletedConfig,
    });
  };
  handleDeletedSelect = (item) => {
    var { deletedStatus, deletedFilterName, deletedConfig } = this.state;
    deletedStatus = item._id;
    deletedFilterName = item.name;
    deletedConfig.anchorElDeleted = null;

    this.setState(
      {
        deletedConfig,
        deletedFilterName,
        deletedStatus,
        loading: true,
        pageNumber: 1,
        statusFilterNames:item.name===""?"All":item.name,
      },
      () => {
        this.filter();
      }
    )
  };
  closeaddModal = () => {
    this.props.history.replace("" + "/company", null);
    this.setState({ showaddModal: false })
  }
  isuplode = () => {
    this.setState({ isuplode: true,
      companyLogo:this.state.companyLogoo   })
  }
  valid = (current) => {
    return current && current < moment().subtract(1, 'days');
  };

  /**
  * @author Shashi
  * @param {place}
  * Google Address function
  */
  onGooglePlaceSelect = (place) => {
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    this.setState({
      eventAddress: formattedPlace.address,
      Address: formattedPlace.address,
      eventLocation: [formattedPlace.lng, formattedPlace.lat],
    });
  };


  onDateRangeChanage = (date, dateString) => {
    var diffDate = new DateDiff(momentTimeZone(dateString[1]), momentTimeZone(dateString[0]));
    this.setState({
      billingTrialDays: diffDate.days(),
      billingTrialEnds: [momentTimeZone(dateString[0]), momentTimeZone(dateString[1])]
    })
  }
  onDialogClose = () => {
    const { formError } = this.state;
    formError.companyName = "";
    formError.companyCode = "";
    formError.companyPhone = "";
    formError.companyEmail = "";
    formError.companyAddress = "";
    formError.companyWebsite = "";
    formError.companySize = "";
    formError.billingConfigType = "";
    formError.billingPrice = "";
    this.setState({
      formError,
      Address: "",
      companyWebsite: "",
      companySize: "",
      billingConfigType: "1",
      billingPrice: 29.99,
      billingTrialDays: 14,
      billingTrialEnds: [
        momentTimeZone().tz(this.state.timeZoneName),
        momentTimeZone().tz(this.state.timeZoneName).add(13, 'days')
      ],
      isValid: false
    })

  }
  filter = () => {
    let data = {
      page: this.state.pageNumber,
      pageSize: this.state.rowsPerPage,
      companySize: this.state.companySize ? this.state.companySize : this.state.statusFilterName,
      searchText: this.state.searchFilter ? this.state.searchFilter : searchFilterValue,
      deleted: this.state.deletedStatus ? this.state.deletedStatus : this.state.deletedFilterName === "Deleted" ? "true" :'',
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    this.updateURL()
  }
  updateURL = (e) => {
    const {deletedFilterName, statusFilterName, statusFilterNames, companySizeFilter, searchFilter} = this.state
    const url =  new URL(window.location.href);  
    const queryParams = {
      status: deletedFilterName || statusFilterNames || url.searchParams.get('status') || '',
      company: statusFilterName || companySizeFilter || url.searchParams.get('company') || '',
      search: e=== "" ? "" : searchFilter ? searchFilter : url.searchParams.get('search') || ''
    };

    const filteredParams = Object.entries(queryParams)
    .filter(([key, value]) => value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    this.props.history.push(`/company?page=${this.state.pageNumber?this.state.pageNumber:'1'}&${filteredParams}`);
  }
  navigateDetail = (row) => {
    this.props.history.push("/company-details/" + row._id);
  }

  render() {
    let handleClose = () => this.setState({ showdelete: false });
    let { loading } = this.state;
    var statusOptions = [
      { _id: "small", name: "Small" },
      { _id: "medium", name: "Medium" },
      { _id: "large", name: "Large" },
    ];
    var deletedOptions = [
      { _id: "true", name: "Deleted" },
    ];
    let CompanyPage = (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        <Box component="span" className="company-comp">
          <AddCompanyModal
            open={this.state.showaddModal}
            onClose={this.closeaddModal}
            modeldata={this.state}
            isValid={this.state.showaddModal}
            onFileuploadHandler={this.onFileuploadHandler}
            handleChange={this.handleChange}
            checkCompanyId={this.checkCompanyId}
            Addcompanyfn={this.Addcompanyfn}
            onclickBtn={this.onclickBtn}
            isuplode={this.isuplode}
            onGooglePlaceSelect={this.onGooglePlaceSelect}
            valid={this.valid}
            onDateRangeChanage={this.onDateRangeChanage}
            onDialogClose={this.onDialogClose}
            billingTrialDays={this.state.billingTrialDays}
            billingTrialEnds={this.state.billingTrialEnds}
          />
          <Dialog
            open={this.state.showdelete}
            // onClose={this.handleClose}
            className="deleteServiceDialog"
            TransitionComponent={TransitionGrow}
            transitionDuration={600}
          >
            <DialogTitle>
              {" "}
              <center> Delete Company</center>{" "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <center>
                  Are you sure you want to delete this Company? This action cannot be undone.
                </center>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="cancel white-btn"
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.disable}
                className="delete red-btn"
                onClick={() => this.onDelete()}
                style={{ color: "red" }}
                autoFocus
              >
                {this.state.disable ? <CircularProgress size={20} /> : ""}{" "}
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <div className="header-add-btn header-add-btn-account">
            <Typography variant="h5" className="tableTitle" component="div">
              Company
            </Typography>
            <div className="services_btn_right">
              <NavLink
                to={"/new-company"}
                className="navbars"
              >
                <Button
                  variant="contained"
                  className="tableaddBtn add_service_main_btn grey-btn"
                  color="default"
                  style={{ boxShadow: "none" }}
                >

                  Company Referrals
            </Button>
              </NavLink>
              <Button
                variant="contained"
                className="tableaddBtn grey-btn"
                color="default"
                style={{ boxShadow: "none" }}
                onClick={() =>
                  this.setState({
                    showaddModal: true,
                    companyCode: "",
                    file: null,
                    responseMsg: "",
                  })
                }
              >
                Add Company
            </Button>
            </div>
          </div>
          <div style={{ maxWidth: "100%" }} className="Comp-Table Acc-Table">
            <div className="filter-search">
              <div className="new_custom_filters">
                {this.state.userRole.toLowerCase() !== "super admin" ? ("") : (
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div"
                      onClick={this.opendeletedFilterMenu}
                    >
                      {this.state.deletedFilterName !== "" ? (
                        ""
                      ) : (
                          <label className="leftName">Status: </label>
                        )}
                      <label
                        aria-label="deleted_filter"
                        aria-controls="deleted_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.deletedFilterName === ""
                          ? `All`
                          : upperFirst(this.state.deletedFilterName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectComponent
                      filterArray={deletedOptions}
                      config={this.state.deletedConfig.config}
                      anchorEl={this.state.deletedConfig.anchorElDeleted}
                      handleClick={this.handleDeletedSelect}
                      closeCustomPopup={this.closedeletedFilterMenu}
                    />
                  </Suspense>
                )}
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openStatusFilterMenu}
                  >
                    {this.state.statusFilterName !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Company Size: </label>
                      )}
                    <label
                      aria-label="status_filter"
                      aria-controls="status_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.statusFilterName === ""
                        ? `All`
                        : upperFirst(this.state.statusFilterName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectComponent
                    filterArray={statusOptions}
                    config={this.state.statusConfig.config}
                    anchorEl={this.state.statusConfig.anchorElStatus}
                    handleClick={this.handleStatusSelect}
                    closeCustomPopup={this.closeStatusFilterMenu}
                  />
                </Suspense>
              </div>
              <CssTextField
                id="outlined-basic"
                name="searchFilter"
                variant="outlined"
                type="text"
                placeholder="Search Here"
                onChange={this.companyFilter}
                value={this.state.searchFilter ? this.state.searchFilter : (searchFilterValue&&this.state.searchFilter) !=="" ? searchFilterValue : ""}
                style={{
                  color: "#303960",
                  fontFamily: "Roboto",
                  backgroundColor: "#F9F9F9",
                  opacity: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: "pointer" }}
                    >
                      <img src={searchIcon} alt="search-icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="table_container">
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={'2%'}>#</TableCell>
                      <TableCell width={'8%'}>CODE</TableCell>
                      <TableCell width={'20%'}>NAME</TableCell>
                      <TableCell width={'10%'}>PHONE</TableCell>
                      <TableCell width={'15%'}>EMAIL</TableCell>
                      <TableCell width={'20%'}>ADDRESS</TableCell>
                      <TableCell width={'10%'}>WEBSITE</TableCell>
                      <TableCell width={'5%'}>SIZE</TableCell>
                      <TableCell width={'10%'}>
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {loading ? (
                      <StyledTableRow>
                        <TableCell colSpan={9} style={{ textAlign: "center" }}>
                          <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={50}
                            width={50}
                            visible={loading}
                          />
                        </TableCell>
                      </StyledTableRow>
                    ) : !this.props.pageOfItems ||
                      this.props.pageOfItems.length === 0 ? (
                          <StyledTableRow>
                            <TableCell colSpan={9} style={{ textAlign: "center" }}>
                              No data Found
                        </TableCell>
                          </StyledTableRow>
                        ) : (
                          (this.state.rowsPerPage > 0
                            ? this.props.pageOfItems.slice()
                            : this.props.pageOfItems
                          ).map((row, index) => (
                            <StyledTableRow className="tablecell_container" key={row._id} >
                              <TableCell onClick={() => this.navigateDetail(row)}>
                                {(this.props.pager.currentPage - 1) *
                                  this.state.rowsPerPage +
                                  (index + 1)}
                              </TableCell>
                              <TableCell onClick={() => this.navigateDetail(row)}
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row.companyId}
                              </TableCell>
                              <TableCell onClick={() => this.navigateDetail(row)}

                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.companyName} {...this.state.tooltipprops} arrow>

                                  <span>{row.companyName}</span>

                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.phone} {...this.state.tooltipprops} arrow>
                                  <span>  {row.phone ?
                                    <a
                                      href={"tel:" + row.phone}
                                      rel="noopener noreferrer"
                                    >
                                      {row.phone}
                                    </a>
                                    : "--"}</span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.email} {...this.state.tooltipprops} arrow>
                                  <span>{row.email ?
                                    <a
                                      href={"mailto:" + row.email}
                                      rel="noopener noreferrer"
                                    >
                                      {row.email}
                                    </a>
                                    : "--"}
                                  </span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell onClick={() => this.navigateDetail(row)}
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.address} {...this.state.tooltipprops} arrow placement='left-start'>

                                  <span>{row.address ? row.address :"--"}</span>

                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.website} {...this.state.tooltipprops} arrow>
                                  <a
                                    href={lowerCase(row.website.substring(0, 3)) === 'www' ? '//' + row.website : row.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {row.website
                                      ? row.website
                                        .replace(
                                          /^(?:https?:\/\/)?(?:www\.)?/i,
                                          ""
                                        )
                                        .split("/")[0]
                                      : "--"}
                                  </a>
                                </LightTooltip>
                              </TableCell>
                              <TableCell onClick={() => this.navigateDetail(row)}>

                                {row.size !== undefined
                                  ? row.size.charAt(0).toUpperCase() +
                                  row.size.slice(1)
                                  : "N/A"}

                              </TableCell>
                              <TableCell>
                                <div
                                  className="table_action_btns"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  {/* <div>
                                <img src={addIcontable} alt="add"></img>
                              </div> */}
                                  {/* <div>
                                <img src={openMenutable} alt="openmenu"></img>
                              </div> */}
                                  {!row.deletedAt ?
                                    <>
                                      <LightTooltip title="Switch to Admin view" {...this.state.tooltipprops} arrow>
                                        <a>
                                          <div
                                            onClick={() => this.makeAdminHandler(row._id)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img src={adminviewIcon} alt="openmenu"></img>
                                          </div>
                                        </a>
                                      </LightTooltip>
                                      <LightTooltip title="View Company Details" {...this.state.tooltipprops} arrow>
                                        <NavLink
                                          to={"/company-details/" + row._id}
                                          className="navbars"
                                        >
                                          <img
                                            src={viewAction}
                                            alt="openmenu"
                                            style={{ cursor: "pointer" }}
                                          ></img>
                                        </NavLink>
                                      </LightTooltip>

                                      <LightTooltip title="Delete Company" {...this.state.tooltipprops} arrow>
                                        <a>
                                          <img
                                            src={Delete}
                                            alt="openmenu"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.onViewHandleClick(row)}
                                          ></img>
                                        </a>
                                      </LightTooltip>
                                    </>
                                    :
                                    <LightTooltip title="Restore Company" {...this.state.tooltipprops} arrow>
                                      <a>
                                        <img
                                          src={restorecompany}
                                          alt="Restore company"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => this.restoreCompany(row)}
                                        ></img>
                                      </a>
                                    </LightTooltip>}
                                </div>
                              </TableCell>
                            </StyledTableRow>
                          ))
                        )}
                  </TableBody>
                  <TableFooter>
                    {this.props.pageOfItems &&
                      this.props.pageOfItems.length !== 0 ?
                      <TableRow>
                        <td colSpan={20} className="pagination_container">
                          <CustomPaginationComponent
                            count={this.props.pager.endPage}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.pager.currentPage? this.props.pager.currentPage:this.state.pageNumber}
                            onPageChange={this.handleCustomPaginagtionChange}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </td>
                      </TableRow>
                      : null}
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Box>
      </div>
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <div>{CompanyPage}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole, status, company, search) =>
      dispatch(action.companyPagination(pageNo, pagerole, status, company, search)),
    onFilter: (states) => dispatch(action.oncompanyFilter(states)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyComponent));
