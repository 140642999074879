import React from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
} from "@material-ui/core";
import download from "../../../Assets/images/download.svg"
import momentTimeZone from "moment-timezone";

const BillingInvoiceComponent = (props) => {
    return (
        <div className="billing_page billing_invoice">
            {/* <div className="billing_heading_section">
                <h3 className="billing_page_title">Billing Invoice</h3>
                <Button className="logout-button primary-btn"  onClick={() => props.Download_Invoice (props.Invoice_Detail._id)}><img src={download} />Download Invoice</Button>
            </div> */}
            <div className="manage_subscriptions_section billing_invoice_section">
                <div className="invoice_heading">
                    <div className="invoice_heading_left">
                        <h5 className="company_name">SalesC2Inc.</h5>
                        <div className="mail_section">
                            <h6>Bill to</h6>
                            <p>{props.Invoice_Detail ? props.Invoice_Detail.billTo : "--"}</p>
                        </div>
                        {/* <h4 className="bill_due">$ {props.Invoice_Detail ? props.Invoice_Detail.amount.toFixed(2) : "--"} due {props.Invoice_Detail.dueDate ? momentTimeZone(props.Invoice_Detail.dueDate)
                            .tz(props.timeZoneName)
                            .format("MMMM DD, YYYY") : "--"}</h4> */}
                    </div>
                    <div className="invoice_heading_right">
                        <h5 className="invoice">Invoice</h5>
                        {/* <div className="mail_section">
                            <h6>Paid By</h6>
                            <p>{props.Invoice_Detail ? props.Invoice_Detail.paidBy : "--"}</p>
                        </div> */}
                        <ul className="invoice_date">
                            <li><span className="invoice_date_label">Invoice number: </span><span
                                className="invoice_date_content">{props.Invoice_Detail ? props.Invoice_Detail.invoiceNumber : "--"}</span></li>
                            {/* <li><span className="invoice_date_label">Date of issue: </span><span className="invoice_date_content">
                                {props.Invoice_Detail.issueDate ? momentTimeZone(props.Invoice_Detail.issueDate)
                                    .tz(props.timeZoneName)
                                    .format("MMMM DD, YYYY") : "--"} </span></li> */}
                            <li><span className="invoice_date_label">Date of Billing: </span><span className="invoice_date_content">
                                {props.Invoice_Detail.dueDate ? momentTimeZone(props.Invoice_Detail.dueDate)
                                    .tz(props.timeZoneName)
                                    .format("MMMM DD, YYYY") : "--"}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="invoice_detail">
                    <ul className="invoice_detail_first">
                        <li><span className="invoice_detail_label">Full name: </span><span> {props.Invoice_Detail ? props.Invoice_Detail.firstName.charAt(0).toUpperCase() + props.Invoice_Detail.firstName.slice(1) : '--'}  {props.Invoice_Detail ? props.Invoice_Detail.lastName.charAt(0).toUpperCase() + props.Invoice_Detail.lastName.slice(1) : ''}    </span></li>
                        <li><span className="invoice_detail_label">Company: </span><span>{props.Invoice_Detail ? props.Invoice_Detail.companyName.charAt(0).toUpperCase() + props.Invoice_Detail.companyName.slice(1) : '--'}</span></li>
                        <li><span className="invoice_detail_label">Address: </span><span>{props.Invoice_Detail ? props.Invoice_Detail.addresLine1 : "--"} {props.Invoice_Detail ? props.Invoice_Detail.addresLine2 : "--"}</span></li>
                    </ul>
                    <ul className="invoice_detail_second">
                        <li><span className="invoice_detail_label">City: </span><span>{props.Invoice_Detail ? props.Invoice_Detail.city : "--"}</span></li>
                        <li><span className="invoice_detail_label">State: </span><span>{props.Invoice_Detail ? props.Invoice_Detail.state : "--"}</span></li>
                        <li><span className="invoice_detail_label">Zip: </span><span>{props.Invoice_Detail ? props.Invoice_Detail.zipCode : "--"}</span></li>
                    </ul>
                    <ul className="invoice_detail_third">
                        <li><span className="invoice_detail_label">Country: </span><span>{props.Invoice_Detail ? props.Invoice_Detail.country : "--"}</span></li>
                    </ul>
                </div>
                <div className="invoice_amount">
                    <div className="invoice_table">
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableCell width={'5%'} style={{ textAlign: "left" }}>#</TableCell>
                                    <TableCell width={'35%'} style={{ textAlign: "left" }}>DESCRIPTION</TableCell>
                                    {/* <TableCell width={'20%'} style={{ textAlign: "center" }}>Qty</TableCell> */}
                                    {/* <TableCell width={'20%'} style={{ textAlign: "center" }}>Unitprice</TableCell> */}
                                    <TableCell width={'20%'} style={{ textAlign: "center" }}>Amount</TableCell>
                                </TableHead>
                                <TableBody>
                                    <TableCell>1</TableCell>
                                    <TableCell> {props.Invoice_Detail ? "Professional Plan" : "--"} </TableCell>
                                    {/* <TableCell style={{ textAlign: "center" }}> {props.Invoice_Detail ? 1 : 0}</TableCell> */}
                                    {/* <TableCell style={{ textAlign: "center" }}>$ {props.Invoice_Detail ? props.Invoice_Detail.amount.toFixed(2) : "--"}</TableCell> */}
                                    <TableCell style={{ textAlign: "center" }}>$ {props.Invoice_Detail ? props.Invoice_Detail.amount.toFixed(2) : "--"}</TableCell>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="invoice_total">
                        <h5 className="sub_total"><span className="invoice_total_label">Sub-total (Exclusive of Taxes):</span>$ {props.Invoice_Detail ? props.Invoice_Detail.amount.toFixed(2) : "--"}</h5>
                        {/* <h5 className="amount_due"><span className="invoice_total_label">Tax({props.Invoice_Detail ? props.Invoice_Detail.taxPercent : "00"} %) </span>$ {props.Invoice_Detail ? props.Invoice_Detail.taxAmount : "00"}</h5> */}
                        <h3 className="total_amount"><span className="invoice_total_label">USD  {props.Invoice_Detail.amount 
                      ? parseFloat( props.Invoice_Detail.amount).toFixed(2)
                      : 0.0}</span>  </h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillingInvoiceComponent;