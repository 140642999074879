import React,{Suspense} from 'react';
import {withGoogleMap,withScriptjs} from 'react-google-maps';
import { GoogleMapsAPI } from '../../../client-config';
import "./MapViewComponent.css";
import HeaderComponent from "../../../SharedComponents/HeaderComponent/HeaderComponent";
var GoogleMapComponent = React.lazy(()=>import('../GoogleMapComponent/GoogleMapComponent'));
// var HeaderComponent = React.lazy(()=>import('../../../SharedComponents/HeaderComponent/HeaderComponent'));

const MapViewComponent = (props) => {
    const MapWrapper= withScriptjs(withGoogleMap((props) =>
    <Suspense fallback={<div></div>}> 
        <GoogleMapComponent
        /> 
    </Suspense>));
    return (
        <div className="map-container">
            {/* <Suspense fallback={<div></div>}> */}
                <HeaderComponent/>
            {/* </Suspense> */}
            <MapWrapper
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GoogleMapsAPI}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%`}} />}
               
            />
        </div>
    );
}
 
export default  MapViewComponent;