import React from 'react';
import Loader from "react-loader-spinner";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
const LoadingComponent = (props) => {
  if(props.isAuthenticated){
    return <Redirect to="/" />
  }else{
    return (
    <div className="lazyLoader">
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={75}
          width={75}
          visible={true}
        />
      </div>
    );
  }
}
 
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true
  };
};

export default connect(
  mapStateToProps
)(LoadingComponent);