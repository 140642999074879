import React,{Component} from 'react';
import validator from 'validator';
import PropTypes from 'prop-types';
import { Container, Grid, Card, TextField,InputLabel, Button,AppBar,
        Toolbar,IconButton,InputAdornment,withStyles } from '@material-ui/core';
import {  withRouter} from "react-router-dom";
import { AxiosInstance } from "../../../Utilities";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import iconNotificationUpdate from "../../../Assets/images/icon-notify-update.svg";
import logo from "../../../Assets/images/Sales_C2_logo.svg";
import logo2 from "../../../Assets/images/logo_icon_signup.svg";
import eyeIcon from "../../../Assets/images/eye-icon.svg";
import eyeCloseIcon from "../../../Assets/images/eye-close-icon.svg"
import "./ResetPasswordComponent.css";
import {Tooltip} from 'antd';
import { passwordReg } from "../../../SharedComponents/Validation/formValidation";
import PageNotFoundComponent from '../../PageNotFoundComponent/PageNotFoundComponent';

const CssTextField = withStyles({
    root: {
     
      '& .MuiInput-underline:after': {
        borderBottomColor: '#14193A',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#E2E5F2',
          borderWidth: 1
        },
        
        '&.Mui-focused fieldset': {
          borderColor: '#14193A',
          borderWidth: 1
        },
      },
    },
  })(TextField);

class ResetPasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            hidden:true,
            hidden1:true,
            username:"",
            password:"",
            userId:"",
            notValidUser:false,
            confirmPassword:"",
            formError:{
                password:"",
                confirmPassword:""
            }
            
         }
         this.on_Password_Change_Handler=this.on_Password_Change_Handler.bind(this);
         this.on_Reset_Password_Handler_Cancel=this.on_Reset_Password_Handler_Cancel.bind(this);
         this.on_Reset_Password_Handler=this.on_Reset_Password_Handler.bind(this);
    }
    /**
     * To fetch the user name brefore rendering the page
     * based on {userid} from url params
     */
    UNSAFE_componentWillMount(){
        const { params }= this.props.match;
        let { id } = params;
       if(params && id){
           this.setState({
               userId:id
           });
            AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6001:'authentication',"application/json")
            .get(`/employee/${id}`)
              .then((resp) => {
                  var response=resp && resp.data;
                  if(response && (response.status === "200" || response.status === 200)){
                    this.setState({
                        username:response.data.name
                    })  
                  }else if(response && (response.status === 400 || response.status === "400" || response.status === 404 || response.status === "404")){
                    this.setState({
                      notValidUser:true
                    })
                  }
              }).catch(err=>{
                var error=err.response;
                if(error && (error.status === '403' || error.status === 403)){
                    localStorage.clear();
                    setTimeout(()=>{
                        window.location.href="/";
                    },500)
                }else{
                    console.log("Internal Server Error")
                }
              });
        }
    }
    /**
     * @author Benakesh
     * @param {target element} e 
     * To set the state on form field change
     */
    on_Password_Change_Handler=(e)=>{
        var {name,value}=e.target;
        var {formError}=this.state;
        switch(name){
            case 'password': value.trim().length>0?
                            formError.password="":
                            !passwordReg.test(value)? 
                            formError.password="* Password should contains special character, number ,character and length should be greater than 8":
                            formError.password="";
                            break;
            case 'confirmPassword': 
                            value.trim().length>0?
                            formError.confirmPassword="":
                            !passwordReg.test(value)? 
                            formError.confirmPassword="* Password should contains special character, number ,character and length should be greater than 8":
                            formError.confirmPassword="";
                            break;
            default: break;
        }
        this.setState({
            [name]:value,
            formError
        })
    }

    /**
     * @author Benakesh
     * @param {target element} e 
     * To call reset password api
     * if no error in the form
     */
    on_Reset_Password_Handler=(e)=>{
        e.preventDefault();
        var {formError,confirmPassword,password,userId}=this.state;
        if(password.trim() === "" && confirmPassword.trim() === ""){
            formError.password="* Please enter a new password";
            formError.confirmPassword="* Please enter confirmation password";
        }else{
            formError.password="";
            formError.confirmPassword="";
        }
        if(confirmPassword.trim().length>0 && !validator.equals(password.trim(),confirmPassword.trim())){
            formError.confirmPassword="* Confirm password should be same as a new password";
        }else if(password.trim() === ""){
            formError.password="* Please enter a new password";
        }else if(confirmPassword.trim() === ""){
            formError.confirmPassword="* Please enter a confirmation password";
        }else{
            formError.confirmPassword="";
            formError.password=""
        }

        this.setState({
             formError
        },()=>{
            if(formError.password === "" && formError.confirmPassword === "" 
                && (confirmPassword !== "" && password !== "" && userId !== "")){
                    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6001:'authentication',"application/json")
                    .put("/employee/update",{
                            userId:userId.trim(),
                            password:confirmPassword.trim()})
                    .then(resp=>{
                        var response=resp && resp.data;
                        if(response && (response.status === "204" || response.status === 204)){
                            this.setState({
                                password:"",
                                confirmPassword:"",
                                formError:{
                                    password:"",
                                    confirmPassword:""
                                }
                            })
                            var props = {
                                message: response.msg,
                                icon: (
                                  <img src={iconNotificationUpdate} alt="success" />
                                ),
                                type: "success",
                                placement: "topRight",
                                duration: 1.5,
                                top: 200,
                                className: "task_notification update-notify",
                              };
                              NotificationComponent(props);
                              localStorage.clear();
                              setTimeout(()=>{
                                this.props.history.replace("/")
                              },2500)
                        }else{
                            var propsErr = {
                                message: response.msg,
                                icon: (
                                  <img src={iconNotificationError} alt="success" />
                                ),
                                type: "error",
                                placement: "topRight",
                                duration: 2,
                                top: 200,
                                className: "task_notification error-notify",
                              };
                              NotificationComponent(propsErr);
                        }
                    })
                    .catch(err=>{
                        var error=err.response;
                      if(error && (error.status === '403' || error.status === 403)){
                          localStorage.clear();
                          setTimeout(()=>{
                              window.location.href="/";
                          },500)
                      }else{
                          console.log("Internal Server Error")
                      }
                       var propsEr = {
                            message: "Internal Server Error",
                            icon: (
                              <img src={iconNotificationError} alt="success" />
                            ),
                            type: "error",
                            placement: "topRight",
                            duration: 2,
                            top: 200,
                            className: "task_notification error-notify",
                          };
                          NotificationComponent(propsEr);
                    })
                }
        })
       
    }

    /**
     * @author Benakesh
     * To go to login form if user clicked home button
     */
    on_Reset_Password_Handler_Cancel=()=>{
       this.props.history.replace("/")
    }
   
    render() { 
        const {formError,hidden,hidden1,password,confirmPassword}=this.state;
        if(this.state.notValidUser){
            return <PageNotFoundComponent/>;
        }else{
            return (
                <div className="signup-body-bg-color signup-section">
                <Container fixed className="signup-body">
                    <Card className="signup-root">
                        <Grid container>
                            <Grid item xs={12} className="card-header">
                                <div className="header-content">
                                    <div className="signup-logo">
                                        <img src={logo2} alt="Sales-Icon" height="auto" />
                                    </div>
                                    <p className="signup-name">
                                        Hi {this.state.username}
                                    </p>
                                    <h3 className="pragraph-text">
                                        Welcome to SalesC2. Fill in to reset your password.
                                    </h3>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="header-content">
                            <Grid item xs={12}>
                                <form onSubmit={this.on_Reset_Password_Handler} autoComplete="off">
                                    <InputLabel  className="loginLabel label bl-label">
                                        Enter New Password <span className="labelAsterisk"> *</span>
                                    </InputLabel>
                                    <CssTextField  
                                        id="outlined-basic-password" 
                                        type={
                                                hidden ? "password" : "text"
                                            } 
                                        name="password" 
                                        placeholder="Enter password" 
                                        className="inputHeight mar-bot-10" 
                                        variant="outlined" 
                                        onChange={this.on_Password_Change_Handler}  
                                        value={password} 
                                        InputProps={{   
                                            endAdornment: 
                                                <InputAdornment 
                                                    position="end" 
                                                    style={{cursor:'pointer'}} 
                                                    onClick={
                                                        () => this.setState(
                                                                { 
                                                                    hidden: !this.state.hidden 
                                                                })
                                                        }
                                                    >
                                                    <Tooltip title={this.state.hidden?"Show Password":"Hide Password"} placement="bottom">
                                                        <img src={this.state.hidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
                                                    </Tooltip>
                                                </InputAdornment>,
                                        }}
                                    />
                                    <span className="error">{formError.password}</span>
                                    <InputLabel  className="loginLabel label bl-label">
                                        Confirm Password <span className="labelAsterisk"> *</span>
                                    </InputLabel>
                                    <CssTextField  
                                        id="outlined-basic-password" 
                                        type={
                                            hidden1 ? "password" : "text"
                                        } 
                                        name="confirmPassword" 
                                        placeholder="Confirm password" 
                                        className="inputHeight mar-bot-10" 
                                        variant="outlined" 
                                        onChange={this.on_Password_Change_Handler}  
                                        value={confirmPassword} 
                                        InputProps={{   
                                            endAdornment: 
                                                <InputAdornment 
                                                    position="end" 
                                                    style={{cursor:'pointer'}} 
                                                    onClick={
                                                        () => this.setState(
                                                                { 
                                                                    hidden1: !this.state.hidden1 
                                                                })
                                                        }
                                                    >
                                                    <Tooltip title={this.state.hidden1?"Show Password":"Hide Password"} placement="bottom">
                                                        <img src={this.state.hidden1 ? eyeIcon:eyeCloseIcon } alt="eye"/>
                                                    </Tooltip>
                                                </InputAdornment>,
                                        }}
                                        />
                                        <span className="error">{formError.confirmPassword}</span>
                                    <div className="buttonGroup">
                                        <Button 
                                            className="cancelBtn cancel-button go-home-btn white-btn" 
                                            variant="contained" 
                                            color="primary"
                                            onClick={this.on_Reset_Password_Handler_Cancel} 
                                        >
                                        Go to Home
                                        </Button>
                                        <Button 
                                            className="signupBtn success-button green-btn" 
                                            type="submit" 
                                            variant="contained" 
                                            color="primary"
                                        >
                                            Reset Now
                                        </Button>

                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </div>
            );
      }
    }
}
 
ResetPasswordComponent.propTypes={
    password:PropTypes.string,
    confirmPassword:PropTypes.string,
    formError:PropTypes.shape({
        password:PropTypes.string,
        confirmPassword:PropTypes.string
    }) 
}
export default withRouter(ResetPasswordComponent);