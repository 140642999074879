import React, { useState, useEffect } from 'react'
import { AxiosInstance, checkTimeZone } from "../../Utilities";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import close from "../../Assets/images/close_btn.svg"
import "./TimezoneText.css"

function TimezoneTextComponent(props) {
  const commonTimeZone = localStorage.getItem("commonTimeZone")
  const closeTimeZonePopup = localStorage.getItem("closePopup")
  const [timeZone, setTimeZone] = useState("")
  const [apptimeZone, setAppTimeZone] = useState("")
  const [closePopup, setClosePopup] = useState(false)

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(userTimeZone);
    checkTimeZones()
  }, []);

  const checkTimeZones = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/employee/timezone")
      .then((res) => {
        if (res.data.status === "200" || res.data.status === 200) {
          setAppTimeZone(res.data.data.timezone);
        }
      })
      .catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  }
  const onClosePopup = () => {
    localStorage.setItem("ignoreTimeZone", timeZone)
    localStorage.removeItem("closePopup");
    setClosePopup(true)
  }
  const changeTimeZone = () => {
    console.log("timezoneclose..")
    const data = { timezone: timeZone }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
      .put("/employee/timezone", data)
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          checkTimeZone()
          window.location.reload()
          var propsErr = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(propsErr);
        } else if (response && (response.status === "400" || response.status === 400)) {

        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  return (
    <div className="textContainer">
      {((commonTimeZone === "false" || commonTimeZone === false) && (closeTimeZonePopup === "false" || closeTimeZonePopup === false) && (localStorage.getItem("ignoreTimeZone") !== timeZone) && closePopup === false) &&
        <div className="timeZoneText">
          <div>Would you like to set your profile Time zone ({apptimeZone}) to match your current location ({timeZone})?
            <button className="updatebtn" onClick={changeTimeZone}>Update</button>
            <span className="closebtn">
              <img src={close} alt="close" onClick={onClosePopup}></img>
            </span>
          </div>
        </div>
      }
    </div>
  )
}

export default TimezoneTextComponent