import React, { Component, Suspense } from "react";
import "./AccountsComponent.css";
import { withStyles } from "@material-ui/core/styles";
import * as action from "../../Store/Actions/index";
import Loader from "react-loader-spinner";
import { upperFirst } from "lodash";
import {
  Typography,
  Button,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import viewAction from "../../Assets/images/view_action.svg";
import { AxiosInstance, checkCmsLabel } from "../../Utilities";
import searchIcon from "../../Assets/images/search_icon.svg";
import AddAccountModal from './AddAccountModal';
import { QuickViewModal } from './QuickViewModal';
import carret_down from "../../Assets/images/carret_down.svg";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";

var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import(
    "../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
  )
);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const url =  new URL(window.location.href);  
const territoryValue = url.searchParams.get('territory');
const stateValue = url.searchParams.get('state');
const searchFilterValue = url.searchParams.get('search');
class AccountsComponent extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      territoryFilterName:'',
      stateFilterName:'',
      cmsLabelChange:[],
      role: userData.role,
      companySize: userData.companySize,
      showaddModal: false,
      showviewModal: false,
      accountslist: [],
      page: 0,
      pageNumber: 1,
      rowsPerPage: 10,
      quickviewData: "",
      stateFilter: stateValue ? stateValue : "",
      stateName: "",
      teamModel: "",
      territoryFilter: territoryValue ? territoryValue : "",
      searchFilter: "",
      DivisionFilter: "",
      territoryOption: [],
      DivisionOption: [],
      stateList: [],
      territoryId: "",
      territoryName: "",
      divisionId: "",
      divisionName: "",
      territoryname: "",
      loading: true,
      divisionConfig: {
        anchorElDivision: null,
        config: {
          id: "division_filter_select",
        },
      },
      searchDivisionValue: "",
      territoryConfig: {
        anchorElTerritory: null,
        config: {
          id: "territory_filter_select",
        },
      },
      searchTerritoryValue: "",
      searchStateValue:"",
      stateConfig: {
        anchorElState: null,
        config: {
          id: "state_filter_select",
        },
      },
      tooltipprops:{},
      pageNumber:1
    };
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    // window.onpopstate = () =>{
    //   window.location.reload()
    // }
    const fetchCmsData = checkCmsLabel()
      if(fetchCmsData){
        this.setState({ cmsLabelChange : fetchCmsData.cmsData})
      }
    const queryString = this.props.location.search;
    const pageParameter = new URLSearchParams(queryString).get("page");
    const pageNumber = parseInt(pageParameter);
    this.setState({
      searchFilter:searchFilterValue,
      nextpage: pageNumber?pageNumber:1
    })
    if (this.props.location.state === null || this.props.location.state === undefined) {
      this.setState({
        showaddModal: false,
      });
    } else {
      this.setState({
        regData: this.props.location.state
          ? this.props.location.state.UserData.data
          : null,
        showaddModal: true,
        teamModel: true,
      });
    }
    const url1 =  new URL(window.location.href);  
    const territoryValue1 = url1.searchParams.get('territory');
    const stateValue1 = url1.searchParams.get('state');
    const searchFilterValue1 = url1.searchParams.get('search');
    this.props.onPagination(pageNumber?pageNumber:this.state.pageNumber - 1, this.state.rowsPerPage, territoryValue || territoryValue1, stateValue || stateValue1, searchFilterValue || searchFilterValue1);
    this.fetchState({search:""});
    this.territoryListHandler("");
    setTimeout(() => {
      const terrValue = territoryValue || territoryValue1
      this.setState({ loading: false });
      if(terrValue && terrValue !== "All"){
        const territory = this.state.territoryOption.find(item => item._id === terrValue)
        this.setState({
          territoryName: territory?.name ? territory?.name : ""
        })
      } else if(terrValue && terrValue === "All"){
        this.setState({
          territoryFilter: ""
        })
      }
    }, 2000);
  };

  fetchState=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
    .get("/account/states",{
      params: data
    })
    .then((resp) => {
      var response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {
        var newArray = this.state.stateList.splice();
     
        if (this.state.searchStateValue === "") {
          newArray.unshift({ _id: "", name: "All" });
        } else {
          newArray.shift({ _id: "", name: "All" });
        }
        if (response.data.length > 0) {
          response.data.map((item) => {
            var obj = {};
            obj._id = item.stateName;
            obj.name = item.stateName;
            newArray.push(obj);
          });
        }
        this.setState({
          stateList: newArray,
        });
        //this.setState({ stateList: response.data });
      }else  if (
        response &&
        (response.status === "404" || response.status === 404)
      ) {
        this.setState({
          stateList: [],
        })
      }
    })
    .catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else {
        console.log("Internal Server Error");
      }
    });
  }
  // handleChangePage = (event, newPage) => {
  //   this.setState({ loading: true });
  //   this.props.onPagination(newPage + 1, this.state.rowsPerPage);
  //   setTimeout(() => {
  //     this.setState({ loading: false });
  //   }, 2000);
  // };
  handleCustomPaginagtionChange = (event, value) => {
    this.setState({ pageNumber: value, loading: true },()=>{
    this.Userfilter();
    });
    // this.props.onPagination(value, this.state.rowsPerPage);

    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }
  handleChangeRowsPerPage = (event) => {
    this.setState({ pageNumber: 1, rowsPerPage: +event.target.value, loading: true },()=>{
     this.Userfilter();
    });
    // this.props.onPagination(this.state.pageNumber, +event.target.value);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  onQuickViewhandler = (id) => {
    let data = {
      accountId: id,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/account-details", {
        params: data
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let responseData = response.data[0];
          this.setState({ showviewModal: true, quickviewData: responseData });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  AccountTerritoryFilter = async (e) => {
    var name = e.target.value;
    var data = {
      search: name,
    };
    if (this.state.DivisionName === undefined) {
    } else {
      this.setState({ territoryname: name }, this.Userfilter);
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get(`/account/territory-filter`, {
        params: {
          search: name
        }
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            territoryOption: response.data,
          });
        } else {
          this.setState({
            territoryOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  onSelectHandler = (e) => {
    var name = e.target.value;
    if (name === "") {
      this.AccountTerritoryFilter(e);
    } else {
      var { territoryOption } = this.state;
      var data = territoryOption.filter((item) => {
        return item.name === name;
      });
      data.map((territoryData) => {
        this.setState(
          {
            territoryId: territoryData._id,
            territoryName: territoryData.name,
            loading: true,
          },
          this.Userfilter
        );
      });
    }
  };
  accountAutoClose = (e) => {
    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ territoryId: "" }, this.Userfilter);
    }
  };

  accountDivisionAutoClose = (e) => {
    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ DivisionId: "" }, this.Userfilter);
    }
  };

  statefilter = (e) => {
    this.setState({ [e.target.name]: e.target.value },
      this.Userfilter
    );
  };
  accountSearch = (e) => {
    let name = e.target.value;
    this.setState({ pageNumber: 1,  searchFilter: name }, this.Userfilter);
  };
  Userfilter = () => {
    this.setState({ loading: true });
    let data = {
      page: this.state.pageNumber,
      pageSize: this.state.rowsPerPage,
      state: this.state.stateFilter === "All"?"":this.state.stateFilter,
      territory: this.state.territoryId,
      searchText: this.state.searchFilter,
      divisionId: this.state.DivisionId,
      territoryName: this.state.territoryname,
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    this.updateURL()
  };

  updateURL = () => {
    const {territoryFilter, stateFilter, territoryFilterName, searchFilter, stateFilterName} = this.state
    const url =  new URL(window.location.href);  
    const queryParams = {
      territory: territoryFilter || territoryFilterName || url.searchParams.get('territory') || '',
      state: stateFilter || stateFilterName || url.searchParams.get('state') || '',
      search: searchFilter || ''
    };

    const filteredParams = Object.entries(queryParams)
    .filter(([key, value]) => value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    this.props.history.push(`/account?page=${this.state.pageNumber?this.state.pageNumber:'1'}&${filteredParams}`);
  }
  /**
   * @author Murali
   * To open territory filter menu
   */
  openDivisionFilterMenu = (event) => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = event.currentTarget;
    this.setState(
      {
        divisionConfig,
      },
      () => {
        this.divisionListHandler("");
      }
    );
  };
  /**
   * @author Murali
   * To close territory filter menu
   */
  closeDivisionFilterMenu = () => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = null;
    this.setState({
      divisionConfig,
    });
  };
  /**
   * @author Murali
   * To display territory based on search
   */
  onDivisionSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchDivisionValue: value,
    });
    this.divisionListHandler(value);
  };
  /**
   * @author Murali
   * To display data based on selected territory
   */
  handleDivisionSelect = (item) => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = null;

    this.setState(
      {
        DivisionFilter: item._id,
        DivisionId: item._id,
        divisionName: item.name === "All" ? "" : item.name,
        divisionConfig,
        searchDivisionValue: "",
      },
      this.Userfilter
    );
    this.props.history.push(`/account?page=1`);
  };
  /**
   * @author Murali
   * To get territory names in filter
   */
  divisionListHandler = (search) => {
    const { DivisionOption } = this.state;

    let data = {
      search: search
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/account/division-filter", {
        params: data
      })
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = DivisionOption.splice();
          if (this.state.searchDivisionValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            DivisionOption: newArray,
          });
        } else {
          this.setState({
            DivisionOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Murali
   * To open territory filter menu
   */
  openTerritoryFilterMenu = (event) => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = event.currentTarget;
    this.setState(
      {
        territoryConfig,
      },
      () => {
        this.territoryListHandler("");
      }
    );
  };
  /**
   * @author Murali
   * To close territory filter menu
   */
  closeTerritoryFilterMenu = () => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = null;
    this.setState({
      territoryConfig,
    });
  };
  /**
   * @author Murali
   * To display territory based on search
   */
  onTerritorySearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchTerritoryValue: value,
    });
    this.territoryListHandler(value);
  };
  onStateSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchStateValue: value,
    });
    this.fetchState({search:value});
  };
  /**
   * @author Murali
   * To display data based on selected territory
   */
  handleTerritorySelect = (item) => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = null;

    this.setState(
      {
        territoryFilter: item._id,
        territoryId: item._id,
        territoryName: item.name === "All" ? "" : item.name,
        territoryFilterName: item.name === "All" ? "All" : item.name,
        territoryConfig,
        searchTerritoryValue: "",
        pageNumber: 1,
      },
      this.Userfilter
    );
  };
  /**
   * @author Murali
   * To get territory names in filter
   */
  territoryListHandler = (search) => {
    const { territoryOption } = this.state;

    let data = {
      search: search
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get(`/account/territory-filter`, {
        params: {
          search: search
        }
      })
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = territoryOption.splice();
          if (this.state.searchTerritoryValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            territoryOption: newArray,
          });
        } else {
          this.setState({
            territoryOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  openStateFilterMenu = (event) => {
    const { stateConfig } = this.state;
    stateConfig.anchorElState = event.currentTarget;
    this.setState({
      stateConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleStateSelect = (item) => {
    this.setState({ loading: true });
    var {
      stateFilter,
      stateConfig,
    } = this.state;
    stateFilter = item.name;
    stateConfig.anchorElState = null;
    this.setState(
      { pageNumber: 1,
        stateConfig,
        stateFilter,
        stateFilterName: item.name === "All" ? "All" : item.name
      },
      this.Userfilter

    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeStateFilterMenu = () => {
    const { stateConfig } = this.state;
    stateConfig.anchorElState = null;
    this.setState({
      stateConfig,
    });
  };
  closeaddModal = () => {
    this.props.history.replace("" + "/account", null);
    this.setState({ showaddModal: false, teamModel: "" })
  }
  navigateDetail=(row)=>{
    this.props.history.push("/account-details/" + row.id);
  }

  render() {
    const { role, loading } = this.state;
    let closeviewModal = () => this.setState({ showviewModal: false });
    let page = 0;
    let AccountsPage = (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        {/* <Box component="span" m={2} className="account-comp"> */}

        <AddAccountModal
          open={this.state.showaddModal}
          onClose={this.closeaddModal}
          modeldata={this.props.location.state}
          teamData={this.state}
        />
         
        <QuickViewModal
          open={this.state.showviewModal}
          onClose={closeviewModal}
          modeldata={this.state.quickviewData}
        />
        <div className="header-add-btn account_header">
          <Typography variant="h5" className="tableTitle" component="div">
          {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"}
          </Typography>
          <div className="services_btn_right">
            <NavLink
              to={"/pending-account"}
              className="navbars"
            >
              <Button
                variant="contained"
                className="tableaddBtn add_service_main_btn grey-btn"
                color="default"
                style={{ boxShadow: "none" }}
              >

                Pending invites
            </Button>
            </NavLink>
            <Button
              variant="contained"
              className="tableaddBtn grey-btn"
              color="default"
              style={{ boxShadow: "none" }}
              onClick={() => this.setState({ showaddModal: true })}
            >
              Add {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"}
          </Button>
          </div>
        </div>
        <div className="Acc-Table">
          <div className="filter-search">
            <div className="new_custom_filters">
              <div style={{ display: "flex" }}>
                {(role.toLowerCase() === "super admin" ||
                  role.toLowerCase() === "admin") &&
                  this.state.companySize === "large" ? (
                    <>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div"
                          onClick={this.openDivisionFilterMenu}
                        >
                          {this.state.DivisionFilter !== "" ? (
                            ""
                          ) : (
                              <label className="leftName bl-label">Division: </label>
                            )}
                          <label
                            aria-label="division_filter"
                            aria-controls="division_filter_select"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.DivisionFilter === ""
                              ? `All`
                              : upperFirst(this.state.divisionName)}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectWithSearchComponent
                          filterArray={this.state.DivisionOption}
                          config={this.state.divisionConfig.config}
                          anchorEl={this.state.divisionConfig.anchorElDivision}
                          searchValue={this.state.searchDivisionValue}
                          handleClick={this.handleDivisionSelect}
                          closeCustomPopup={this.closeDivisionFilterMenu}
                          onSearchFilter={this.onDivisionSearchFilter}
                          placeholder="Search Division"
                        />
                      </Suspense>

                    </>
                  ) : (
                    ""
                  )}
                <>
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openTerritoryFilterMenu}
                    >
                      {this.state.territoryFilter !== "" ? (
                        ""
                      ) : (
                          <label className="leftName bl-label">Territory: </label>
                        )}
                      <label
                        aria-label="territory_filter"
                        aria-controls="territory_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.territoryFilter === ""
                          ? `All`
                          : upperFirst(this.state.territoryName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.territoryOption}
                      config={this.state.territoryConfig.config}
                      anchorEl={this.state.territoryConfig.anchorElTerritory}
                      searchValue={this.state.searchTerritoryValue}
                      handleClick={this.handleTerritorySelect}
                      closeCustomPopup={this.closeTerritoryFilterMenu}
                      onSearchFilter={this.onTerritorySearchFilter}
                      placeholder="Search Territory"
                    />
                  </Suspense>

                </>
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openStateFilterMenu}
                  >
                    {this.state.stateFilter !== "" ? (
                      ""
                    ) : (
                        <label className="leftName bl-label">State: </label>
                      )}
                    <label
                      aria-label="state_filter"
                      aria-controls="state_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.stateFilter === ""
                        ? `All`
                        : upperFirst(this.state.stateFilter)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  {/* <CustomSelectComponent
                    filterArray={this.state.stateList}
                    config={this.state.stateConfig.config}
                    anchorEl={this.state.stateConfig.anchorElState}
                    handleClick={this.handleStateSelect}
                    closeCustomPopup={this.closeStateFilterMenu}
                  /> */}
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.stateList}
                      config={this.state.stateConfig.config}
                      anchorEl={this.state.stateConfig.anchorElState}
                      searchValue={this.state.searchStateValue}
                      handleClick={this.handleStateSelect}
                      closeCustomPopup={this.closeStateFilterMenu}

                      onSearchFilter={this.onStateSearchFilter}
                      placeholder="Search State"
                    />
                </Suspense>
              </div>
            </div>
            <CssTextField
              id="outlined-basic"
              name="searchFilter"
              variant="outlined"
              type="text"
              placeholder="Search Here"
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
              value={this.state.searchFilter}
              onChange={this.accountSearch}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>NO OF DEP</TableCell>
                    <TableCell>ADDRESS</TableCell>
                    <TableCell>STATE</TableCell>
                    <TableCell>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablecell_container">
                  {loading ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={loading}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ) : !this.props.pageOfItems ||
                    this.props.pageOfItems.length === 0 ? (
                        <StyledTableRow>
                          <TableCell colSpan={9} style={{ textAlign: "center" }}>
                            No data found
                      </TableCell>
                        </StyledTableRow>
                      ) : (
                        (this.state.rowsPerPage > 0
                          ? this.props.pageOfItems.slice()
                          : this.props.pageOfItems
                        ).map((row, index) => (
                          <StyledTableRow key={row._id}>
                            <TableCell onClick={() => this.navigateDetail(row)}>
                              {(this.props.pager.currentPage - 1) *
                                this.state.rowsPerPage +
                                (index + 1)}
                            </TableCell>
                            <TableCell>
                              <span
                                onClick={() => this.onQuickViewhandler(row.id)}
                                style={{ cursor: "pointer" }}
                              >
                                {row.accountName}
                              </span>
                            </TableCell>
                            <TableCell onClick={() => this.navigateDetail(row)}>{row.departments}</TableCell>
                            <TableCell onClick={() => this.navigateDetail(row)}>
                              {row.accountAddress !== ""
                                ? row.accountAddress
                                : "N/A"}
                            </TableCell>
                            <TableCell onClick={() => this.navigateDetail(row)}>{row.state}</TableCell>
                            <TableCell onClick={() => this.navigateDetail(row)}>
                              <div
                                className="table_action_btns"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "left",
                                }}
                              >
                                {/* <div>
                              <LightTooltip title="Add Task" {...this.state.tooltipprops} arrow>
                                <img src={addAction} alt="add"></img>
                              </LightTooltip>
                            </div> */}
                                <div>

                                  <NavLink
                                    to={"/account-details/" + row.id}
                                    className="navbars"
                                  > <LightTooltip title="View Account" {...this.state.tooltipprops} arrow>
                                      <img
                                        src={viewAction}
                                        alt="openmenu"
                                        style={{ cursor: "pointer" }}
                                      ></img>
                                    </LightTooltip>

                                  </NavLink>

                                  {/* <a href={'/account-details/'+row.id}><img src={viewAction} alt="openmenu" style={{ cursor: 'pointer' }}></img></a>  */}
                                </div>
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        ))
                      )}
                </TableBody>
                <TableFooter>
                  {/* <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={9}
                      count={
                        !this.props.pager ? 0 : this.props.pager.totalItems
                      }
                      rowsPerPage={this.state.rowsPerPage}
                      page={
                        !this.props.pager ? 0 : this.props.pager.currentPage - 1
                      }
                      labelDisplayedRows={({ from, to, count }) =>
                        `Showing ${to} of ${count} entries`
                      }
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow> */}
                  {this.props.pageOfItems &&
                    this.props.pageOfItems.length !== 0 ?
                    <TableRow>
                      <td colSpan={20} className="pagination_container">
                        <CustomPaginationComponent
                          count={this.props.pager.endPage}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.props.pager.currentPage? this.props.pager.currentPage: this.state.pageNumber}
                          onPageChange={this.handleCustomPaginagtionChange}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </td>
                    </TableRow> : null}
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* </Box> */}
      </div>
    );

    return <div>{AccountsPage}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole, territory, state, search) =>
      dispatch(action.accountpagination(pageNo, pagerole, territory, state, search)),
    onFilter: (states) => dispatch(action.onaccountfilter(states)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsComponent));
