import React, { useState } from "react";
import "./PayWithNewCardComponent.css";
import {
  useElements,
  useStripe,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { AxiosInstance } from "../../Utilities";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import iconClose from "../../Assets/images/close_btn.svg";
import credit_card from "../../Assets/images/Icon awesome-credit-card.svg";

import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import {
  CssTextField,
  Transition,
} from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { userName } from "../../SharedComponents/Validation/formValidation";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";

const PayWithNewCardComponent = (props) => {
  const stripes = useStripe();
  const elements = useElements();
  const [uname, setnames] = useState("");
  const [nameError, setNameError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const billingData = JSON.parse(localStorage.getItem("billingDetail"));
  const setname = (e) => {
    const { value, name } = e.target;
    var error = "";
    switch (name) {
      case "uname":
        value.length >= 0 && value.trim() === ""
          ? (error = "")
          : !userName.test(value)
          ? (error = "Please enter valid name")
          : (error = "");
        break;
      default:
        break;
    }
    setNameError(error);
    setnames(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uname.trim() === "") {
      setNameError("Please enter name");
    } else {
      setNameError("");
      setIsSubmit(true);

      // var customer= await AxiosInstance(
      //   (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      //   "application/json"
      // ).post("/create-customer", {
      //   email: billingData.email,
      // })
      // var cus = customer && customer.data;
      // var customersId = cus && cus.data;
      // var id = customersId.id;
      const { error, paymentMethod } = await stripes.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city: billingData.city,
            country: billingData.country,
            line1: billingData.addresLine1,
            line2: billingData.addressLine2,
            postal_code: billingData.zipCode,
            state: billingData.state,
          },
          email: billingData.email,
          name: uname,
          phone: billingData.phone,
        },
      });

      if (!error) {
        // let price = await AxiosInstance(
        //   (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
        //     ? 6019
        //     : "subscription",
        //   "application/json"
        // ).post("/create-price", {
        //   unit_amount: 0.0,
        //   // billingData.price,
        //   currency: "usd",
        //   productName: "Professional plan",
        //   billingCycle: billingData.period,
        // });
        // let priceId = price.data.data.id;
        // name: paymentMethod.billing_details.name,
        // email: paymentMethod.billing_details.email,
        // cardNumber: paymentMethod.card.last4,
        // cardExpMonth: paymentMethod.card.exp_month,
        // cardExpYear: paymentMethod.card.exp_year,
        // customerId: billingData.customerId,
        let pid = paymentMethod.id;
        let stripeData = {
          paymentMethodId: pid
        };
        props.setCustomerData(stripeData);
        setIsSubmit(false);
      } else {
        let propserror = {
          message: error.message,
          icon: <img src={iconNotificationError} alt="success" />,
          type: "error",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propserror);
      }
    }
  };

  const opts = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
  return (
    <Dialog
      open={props.openCardModal}
      className="add_company_dialog credit_card_dialog"
      TransitionComponent={Transition}
      transitionDuration={600}
      onExit={
        (() => props.closeCardModal(),
        () => setnames(""),
        () => setNameError(""))
      }
    >
      <DialogTitle
        id="form-dialog-title"
        className="add_user_header task_assignment_header"
      >
        <span>Pay With Credit Card</span>
        <IconButton
          aria-label="close"
          className="closeBtn updateStatusCloseBtn"
          onClick={props.closeCardModal}
        >
          <img src={iconClose}></img>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div>
            <label className="label bl-label">
              Name on card<span className="labelAsterisk"> *</span>
            </label>
            <CssTextField
              name="uname"
              variant="outlined"
              type="text"
              fullWidth
              margin="dense"
              value={uname}
              placeholder="Enter name"
              onChange={setname}
            />
            <span className="error">
              {nameError !== "" ? "*" + nameError : ""}
            </span>
          </div>
          <div>
            <label className="label bl-label">
              Card number<span className="labelAsterisk"> *</span>
            </label>
            <CardNumberElement
              className="card_detail_input_field"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
                showIcon: true,
              }}
              onChange={props.handleChangeCard}
            />
            <span className="error">{props.cardErrors.numberError}</span>
          </div>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} className="card_expiration_date">
              <div>
                <label className="label bl-label">
                  Expiration date<span className="labelAsterisk"> *</span>
                </label>
                <CardExpiryElement
                  className="card_detail_input_field"
                  options={opts}
                  onChange={props.handleChangeExpiry}
                />
                <span className="error"> {props.cardErrors.expiryError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="card_cvc_number">
              <div>
                <label className="label bl-label">
                  CVC<span className="labelAsterisk"> *</span>
                </label>
                <div className="card_cvc_number_field">
                  <CardCvcElement
                    className="card_detail_input_field"
                    options={opts}
                  />
                  <img src={credit_card} alt="credit_card_icon" />
                </div>
              </div>
              <span className="error"></span>
            </Grid>
          </Grid>
          <div className="add_company_action">
            <Button
              type="submit"
              className="card_pay_button ok_btn green-btn"
              disabled={isSubmit ? true : false}
            >
              Pay
              {isSubmit ? <CircularProgress size={20} /> : ""}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PayWithNewCardComponent;
