import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

const isUserAuthenticated = () => {
  return true;
};

const AuthGuard = ({ component: Component, path, ...rest }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = isUserAuthenticated();
      setIsLoggedIn(authenticated);
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  return (
    <Route
      {...rest}
      path={path}
      render={props => {
        if (isLoading) {
          return <div>Loading...</div>;
        }
        return isLoggedIn ?
          <Component {...props} /> :
          <Redirect
            to={{
              path: '/',
              state: { from: props.location }
            }}
          />;
      }}
    />
  );
};

export default AuthGuard;
