import React from 'react';
import {Dialog,DialogTitle,IconButton,DialogContent,Button,TextField} from '@material-ui/core';
import iconClose from "../../../../Assets/images/close_btn.svg";
import { Transition } from "../../../../SharedComponents/CommonUtilities/CommonUtilities";
import { Scrollbars } from 'react-custom-scrollbars';
import { withStyles } from "@material-ui/core/styles";
import {Select} from 'antd';
const {Option} = Select;

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
var i = 0;
const BillingContactEditModal = (props) => {
    return (
        <Dialog
            open={props.openBillingContactEditModal}   
            className="add_company_dialog update_billing_contact"
            TransitionComponent={Transition}
            transitionDuration={600} 
            onExit={props.closeBillingContactEditModal}
        >
              <DialogTitle
        id="form-dialog-title"
        className="add_user_header task_assignment_header"
      >
        <span>Update {props.updateTitle}</span>
        <IconButton
          aria-label="close"
          className="closeBtn updateStatusCloseBtn"
          onClick={props.closeBillingContactEditModal}
        >
          <img src={iconClose}></img>
        </IconButton>
      </DialogTitle>
      <Scrollbars className="custom_scrollbar" style={{ width: "auto", height:590}}>
      <DialogContent>
         <form>
         <div>
              <label className="label bl-label">Company Name<span className="labelAsterisk">*</span></label>
              <CssTextField
                name="company"
                variant="outlined"
                type="text"
                margin="dense"
                fullWidth
                value={props.company}
                placeholder="Enter Company Name"
                onChange={props.handleChange}
              />
             <span className="error">
                 {props.formError.company !== ""?'*'+ props.formError.company:""}
              </span>
            </div>  
            <div>
              <label className="label bl-label">Address Line 1<span className="labelAsterisk">*</span></label>
              <CssTextField
                name="addressLine1"
                variant="outlined"
                type="text"
                margin="dense"
                fullWidth
                value={props.addressLine1}
                placeholder="Enter Address1"
                onChange={props.handleChange}
              />
               <span className="error">
                 {props.formError.addressLine1 !== ""?'*'+ props.formError.addressLine1:""}
              </span>
            </div> 
            <div>
              <label className="label bl-label">Address Line 2</label>
              <CssTextField
                name="addressLine2"
                variant="outlined"
                type="text"
                margin="dense"
                fullWidth
                value={props.addressLine2}
                placeholder="Enter Address2"
                onChange={props.handleChange}
              />
               <span className="error">
                 {props.formError.addressLine2 !== ""?'*'+ props.formError.addressLine2:""}
              </span>
            </div> 
            <div className="custom-ant-select">
              <label className="label bl-label">Country<span className="labelAsterisk">*</span></label>
              <Select
                placeholder="Search Country"
                style={{width:"200px"}}
                value={props.countryName}
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                margin="dense"
                filterOption={false}
                onSearch={props.handleCountrySearch}
                onChange={props.onChangeCountry}
                notFoundContent={null}
                getPopupContainer={(node) => node.parentNode}
              >
                {props.countryList.map(item=>(
                  <Option key={item.id} value={item.sortname+"key"+item.id+"key"+item.name}>{item.name}</Option>
                ))}
              </Select>
               <span className="error">
                 {props.formError.country !== ""?'*'+ props.formError.country:""}
              </span>
            </div> 

             <div className="custom-ant-select">
              <label className="label bl-label">State<span className="labelAsterisk">*</span></label>
              <Select
                placeholder="Search State"
                style={{width:"200px"}}
                showSearch
                value={props.state}
                disabled={props.disableState?true:false}
                defaultActiveFirstOption={false}
                showArrow={false}
                margin="dense"
                filterOption={false}
                onSearch={props.handleStateSearch}
                onChange={props.onChangeState}
                notFoundContent={null}
                getPopupContainer={(node) => node.parentNode}
              >
                {props.stateList && props.stateList.length>0 && props.stateList.map(item=>(
                  <Option key={item.id} value={item.name+"key"+item.id}>{item.name}</Option>
                ))}
              </Select>
              <span className="error">
                 {props.formError.state !== ""?'*'+ props.formError.state:""}
              </span>
            </div> 

            <div className="custom-ant-select">
              <label className="label bl-label">City<span className="labelAsterisk">*</span></label>
              <Select
                placeholder="Search City"
                style={{width:"200px"}}
                showSearch
                disabled={props.disableCity?true:false}
                defaultActiveFirstOption={false}
                showArrow={false}
                margin="dense"
                value={props.city}
                filterOption={false}
                onSearch={props.handleCitySearch}
                onChange={props.onChangeCity}
                notFoundContent={null}
                getPopupContainer={(node) => node.parentNode}
              >
                {props.cityList && props.cityList.length>0 && props.cityList.map(item=>(
                  <Option key={item.id} value={item.name}>{item.name}</Option>
                ))}
              </Select>
               <span className="error">
                 {props.formError.city !== ""?'*'+ props.formError.city:""}
              </span>
            </div>

              <div>
              <label className="label bl-label">Zipcode <span className="labelAsterisk">*</span></label>
              <CssTextField
                name="zipCode"
                variant="outlined"
                type="text"
                margin="dense"
                fullWidth
                value={props.zipCode}
                placeholder="Enter ZipCode"
                onChange={props.handleChange}
              />
              <span className="error">
                 {props.formError.zipCode !== ""?'*'+ props.formError.zipCode:""}
              </span>
            </div>  
            <div className="add_company_action">
            <Button
                onClick={props.closeBillingContactEditModal}
                color="primary"
                className="cancel_btn white-btn"
            >
                Cancel
                </Button>
            <Button
                className="ok_btn green-btn"
                onClick={() => props.updateBillingContactHandler(props.editType)}
                color="primary"
            >
                Update
                </Button>
            </div>
         </form>
      </DialogContent>
      </Scrollbars>
      </Dialog>
    );
}
 
export default BillingContactEditModal;