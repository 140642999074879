import React, { Component, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import {
  Tooltip,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  CircularProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Delete from "../../Assets/images/delete.svg";
import OpenMenu from "../../Assets/images/Icon material-mode-edit.svg";
import { AxiosInstance } from "../../Utilities";
import filterIcon from "../../Assets/images/filter_icon.svg";
import searchIcon from "../../Assets/images/search_icon.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import { data } from "jquery";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { TransitionGrow } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { isSafari } from "react-device-detect";
var AddEventTypesModel = React.lazy(() =>
  import("./AddEventTypesModel/AddEventTypesModel")
);
var EditEventTypesModel = React.lazy(() =>
  import("./EditEventTypesModel/EditEventTypesModel")
);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
function serchingFor(term) {
  return function (x) {
    return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
  };
}
class EventTypesComponent extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      auth_username: userData.username,
      role: userData.role,
      companysiz: userData.companySize,
      auth_email: userData.email,
      auth_companyId: userData.companyId,
      createdBy: userData.username,
      showaddModal: false,
      showEditModal: false,
      deleteRecord: {},
      showdelete: false,
      responseMs: "",
      name: "",
      eventType: 1,
      isValid: !this.props.isValid,
      vertical: "top",
      horizontal: "center",
      errorMsg: "",
      responseMsg: "",
      alertType: "",
      isLoading: false,
      id: "",
      editname: "",
      editeventType: "",
      hideedit: true,
      adhide: true,
      deleteServiceTypeId: "",
      adderrorMassage: "",
      loading: true,
      adderror: "",
      isSubmit: false,
      eventTypeList: [],
      term: "",
      EventFilter: "2",
      perPage: 10,
      currentPage: 0,
      offset: 0,
      orgtableData: [],
      superorgData: [],
      superarrlength: [],
      tooltipprops:{}
    };
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    this.setState({ isValid: false });
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6013:'event', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/get_eventType", data)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          var data = res.data.data;
          var slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: res.data.data,
            superorgData: res.data.data,
            superarrlength: res.data.data,
            eventTypeList: slice,
          });
        } else if (
          res &&
          (res.data.status === "401" || res.data.status === 401)
        ) {
          this.setState({
            responseMsg: res.data.msg,
            alertType: "error",
            isSubmit: false,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };
  loadMoreData() {
    const data = this.state.orgtableData.filter(serchingFor(this.state.term));

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      superarrlength: data,
      pageCount: Math.ceil(data.length / this.state.perPage),
      eventTypeList: slice,
    });
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 });
    const selectedPage = newPage;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ perPage: +event.target.value }, () => {
      const data = this.state.orgtableData.filter(serchingFor(this.state.term));
      const slice = data.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        superarrlength: data,
        pageCount: Math.ceil(data.length / this.state.perPage),
        eventTypeList: slice,
      });
    });
  };
  onViewHandleClick = (row) => {
    this.setState({
      _id: row._id,
      editname: row.name,
      editeventType: row.type,
      showEditModal: true,
    });
  };
  onDeleteHandleClick = (row) => {
    this.setState({ showdelete: true, deleteRecord: row });
  };
  onDelete = () => {
    var serviceData = {
      id: this.state.deleteRecord._id,
    };
    this.setState({ isSubmit: true });
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6013:'event', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/delete_eventType", serviceData)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          this.setState(
            {
              responseMsg: "Event type deleted successfully",
              alertType: "success",
            },
            () => {
              setTimeout(() => {
                this.setState(
                  { showaddModal: false, isSubmit: false },
                  this.handleClose
                );
                this.componentDidMount();
              }, 2000);
            }
          );
        } else if (
          res &&
          (res.data.status === "401" || res.data.status === 401)
        ) {
          this.setState({
            responseMsg: res.data.msg,
            alertType: "error",
            isSubmit: false,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  handleClose = () => {
    this.setState({
      adderror: "",
      showEditModal: false,
      hideedit: true,
      showaddModal: false,
      showaddTypeModal: false,
    });
    this.setState({
      adhide: true,
      isValid: false,
      alertType: "",
      serviceType: "",
      service: "",
      adddivisionId: "",
      adderrorMassage: "",
      responseMsg: "",
      showdelete: false,
      name: "",
    });
  };
  addhandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  addChange = (e) => {
    var name = /\S\w*/;
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.match(name)) {
      this.setState({
        adderror: "",
      });
    } else {
      this.setState({ adderror: "* Please enter a name" });
    }
  };
  onAddSubmit = (e) => {
    e.preventDefault();
    var Data = {
      type: +this.state.eventType,
      name: this.state.name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6013:'event', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/add_eventType", Data)
      .then((res) => {
        if (!this.state.isValid && this.state.adderror === "") {
          this.setState({ isSubmit: true });
          if (res.data.status === 200) {
            this.setState(
              {
                responseMsg: "Event type add successfully",
                alertType: "success",
                isSubmit: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({ showaddModal: false }, this.handleClose);
                }, 2000);
              }
            );
            this.componentDidMount();
          } else {
            this.setState({
              responseMsg: res.data.msg,
              alertType: "error",
              isSubmit: false,
            });
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  edithandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onEditServiceSubmit = (e) => {
    e.preventDefault();
    var Data = {
      name: this.state.editname,
      type: +this.state.editeventType,
      id: this.state._id,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6013:'event', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/update_eventType", Data)
      .then((res) => {
        if (!this.state.isValid && this.state.adderror === "") {
          this.setState({ isSubmit: true });
          if (res.data.status === 200) {
            this.setState(
              {
                responseMsg: "Event type updated successfully",
                alertType: "success",
                isSubmit: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({ showaddModal: false }, this.handleClose);
                }, 2000);
              }
            );
            this.componentDidMount();
          } else {
            this.setState({
              responseMsg: res.data.msg,
              alertType: "error",
              isSubmit: false,
            });
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  serviceSearch = (e) => {
    let name = e.target.value;
    this.setState({ term: name }, () => {
      const data = this.state.orgtableData.filter(serchingFor(this.state.term));
      const slice = data.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        superarrlength: data,
        pageCount: Math.ceil(data.length / this.state.perPage),
        eventTypeList: slice,
      });
    });
  };
  serviceTypefilter = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      var data = {
        type: this.state.EventFilter,
      };
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6013:'event', "application/x-www-form-urlencoded; charset=UTF-8")
        .post("/get_eventType", data)
        .then((res) => {
          if (res.data.status === 200 || res.data.status === "200") {
            var data = res.data.data.filter(serchingFor(this.state.term));
            var slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );
            this.setState(
              {
                superarrlength: data,
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: res.data.data,
                superorgData: res.data.data,
                // superarrlength: res.data.data,
                eventTypeList: slice,
              },
              () => {
                this.loadMoreData();
              }
            );
          } else if (
            res &&
            (res.data.status === "401" || res.data.status === 401)
          ) {
            this.setState({
              responseMsg: res.data.msg,
              alertType: "error",
              isSubmit: false,
            });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    });
  };
  onclickBtn = (e) => {
    const { name, eventType } = this.state;
    if (name === "" || eventType === "") {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  editonclickBtn = (e) => {
    const { editname, editeventType } = this.state;
    if (editname === "" || editeventType === "") {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  render() {
    var statusOptions = [
      { key: "Work Event", value: 1 },
      { key: "Personal Event", value: 0 },
    ];
    let { loading } = this.state;
    let EventTypePage = (
      <div className="body-bg-color">
        <HeaderComponent/>
        <Suspense fallback={<div></div>}>
          <AddEventTypesModel
            modelData={this.state}
            open={this.state.showaddModal}
            handleClose={this.handleClose}
            addhandleChange={this.addhandleChange}
            addChange={this.addChange}
            onAddSubmit={this.onAddSubmit}
            services={this.state}
            onclickBtn={this.onclickBtn}
            DivisionFilter={this.DivisionFilter}
            onDivisionaddHandler={this.onDivisionaddHandler}
          />
          <EditEventTypesModel
            addserviceChange={this.addChange}
            modelData={this.state}
            open={this.state.showEditModal}
            handleClose={this.handleClose}
            addhandleChange={this.edithandleChange}
            onServiceSubmit={this.onEditServiceSubmit}
            services={this.state}
            onclickBtn={this.editonclickBtn}
            row={this.state.modalRecord}
          />
        </Suspense>

        <Dialog
          open={this.state.showdelete}
          className="deleteServiceDialog"
          TransitionComponent={TransitionGrow} 
          transitionDuration={600}
        >
          {this.state.responseMsg !== "" ? (
            <Alert severity={this.state.alertType}>
              {this.state.responseMsg}
            </Alert>
          ) : null}
          <DialogTitle>
            {" "}
            <center>Delete Event Type</center>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <center>
                {" "}
                Are you sure you want to delete this Event Type? This action cannot be undone.
              </center>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="cancel"
              onClick={this.handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.isSubmit}
              className="delete"
              onClick={() => this.onDelete()}
              style={{ color: "red" }}
              autoFocus
            >
              {this.state.isSubmit ? <CircularProgress size={20} /> : ""} Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="header-add-btn header-add-btn-service">
          <Typography variant="h5" className="tableTitle" component="div">
            Event Types
          </Typography>

          <div className="services_btn_right">
            <Button
              variant="contained"
              className="tableaddBtn grey-btn"
              color="default"
              style={{ boxShadow: "none" }}
              onClick={() => this.setState({ showaddModal: true })}
            >
              Add Event Types
            </Button>
          </div>
        </div>
        <div className="Acc-Table">
          <div className="filter-search">
            <div style={{ display: "flex" }}>
              <FormControl
                variant="outlined"
                style={{ marginRight: "25px", width: "150px" }}
              >
                <InputLabel className="custom_filter_label">
                  Event Type
                </InputLabel>
                <Select
                  className="custom_filter"
                  placeholder="Event Type"
                  value={this.state.EventFilter}
                  name="EventFilter"
                  IconComponent={() => (
                    <img
                      alt="filterIcon"
                      src={filterIcon}
                      className="filter-class"
                    />
                  )}
                  onChange={this.serviceTypefilter}
                >
                  <MenuItem value="2">All</MenuItem>
                  {statusOptions.map((mitem) => (
                    <MenuItem key={mitem.key} value={mitem.value}>
                      {mitem.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <CssTextField
              id="outlined-basic"
              name="serviceName"
              variant="outlined"
              type="text"
              placeholder="Search Here"
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon} alt = "searchIcon" />
                  </InputAdornment>
                ),
              }}
              onChange={this.serviceSearch}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>NO</TableCell>
                    <TableCell>EVENT TYPE</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={loading}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ) : this.state.eventTypeList.length === 0 ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        No data found
                      </TableCell>
                    </StyledTableRow>
                  ) : (
                    this.state.eventTypeList.map((row, index) => (
                      <StyledTableRow key={row._id}>
                        <TableCell>{this.state.offset + (index + 1)}</TableCell>
                        <TableCell
                          style={{
                            maxWidth: "0",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <LightTooltip
                          {...this.state.tooltipprops}
                            title={row.type === 1 ? "Work Event" : "Personal Event"}
                            arrow
                          >
                            <span href={"mailto:" + row.type}>
                              {row.type === 1 ? "Work Event" : "Personal Event"}
                            </span>
                          </LightTooltip>
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "0",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <LightTooltip title={row.name} {...this.state.tooltipprops} arrow>
                            <span href={"mailto:" + row.name}>{row.name}</span>
                          </LightTooltip>
                        </TableCell>
                        <TableCell>
                          <div
                            className="table_action_btns"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <div>
                              <LightTooltip title="Delete" {...this.state.tooltipprops} arrow>
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.onDeleteHandleClick(row)}
                                  src={Delete}
                                  alt="add"
                                ></img>
                              </LightTooltip>
                            </div>
                            <div>
                              <LightTooltip title="Edit Event Type" {...this.state.tooltipprops} arrow>
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.onViewHandleClick(row)}
                                  src={OpenMenu}
                                  alt="openmenu"
                                ></img>
                              </LightTooltip>
                            </div>
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={9}
                      count={this.state.superarrlength.length}
                      rowsPerPage={this.state.perPage}
                      page={this.state.currentPage}
                      labelDisplayedRows={({ from, to, count }) =>
                        `Showing ${!to ? 0 : to} of ${
                          count === undefined ? 0 : count
                        } entries`
                      }
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
    return <div>{EventTypePage}</div>;
  }
}
export default withRouter(EventTypesComponent);
