import React, { Component, Suspense } from "react";
import "./CompanyComponent.css";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import * as action from "../../Store/Actions/index";
import { upperFirst } from "lodash";
import {
  Box,
  Typography,
  Button,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  IconButton,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import addCompanyIcon from "../../Assets/images/add_company_icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import { Redirect, withRouter, NavLink } from "react-router-dom";
import viewAction from "../../Assets/images/view_action.svg";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AxiosInstance } from "../../Utilities";
import { connect } from "react-redux";
import carret_down from "../../Assets/images/carret_down.svg";
import jwt from "jsonwebtoken";
import searchIcon from "../../Assets/images/search_icon.svg";
import accept from "../../Assets/images/Group 679.svg";
import reject from "../../Assets/images/Group 680.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import CustomPaginationComponent from '../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent';
import { TransitionGrow } from '../../SharedComponents/CommonUtilities/CommonUtilities';
import { Alert } from "@material-ui/lab";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { isSafari } from "react-device-detect";
var RejectModal = React.lazy(() => import("../../SharedComponents/RejectModal/RejectModal"));
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustemPendingSelectComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
  )
);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
class NewCompanyComponent extends Component {
  constructor(props) {
    super(props);
    let userRole = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata")).role
      : "";
    this.state = {
      showaddModal: false,
      userRole: userRole,
      companyList: [],
      assignedOption: [],
      searchAssigneeValue: "",
      assignedName: "",
      companyName: "",
      companyCode: "",
      companyLogo: null,
      descriptioneror: {
        error: "",
      },
      description: "",
      showconform: false,
      file: null,
      companyPhone: "",
      companyEmail: "",
      disable: false,
      companyAddress: "",
      acceptId: "",
      companyWebsite: "",
      companySize: "",
      companyDescription: "",
      responseMsg: "",
      regId: "",
      alertType: "",
      page: 0,
      pageNumber: 1,
      rowsPerPage: 10,
      loading: true,
      isValid: false,
      assigneeConfig: {
        anchorElAssignee: null,
        config: {
          id: "assignee_filter_select",
        },
      },
      formError: {
        companyName: "",
        companyCode: "",
        companyPhone: "",
        companyEmail: "",
        companyAddress: "",
        companyWebsite: "",
        companySize: "",
      },
      isSubmit: false,
      delete_id: "",
      statusFilterName: "",
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      tooltipprops:{}
    };
  }
  /**
   * @author Shashi
   */
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      this.props.onPagination(this.state.pageNumber - 1, this.state.rowsPerPage);
    });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };
  /**
   * @author Shashi
   * for next page
   */
  handleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ pageNumber: newPage, loading: true },
      () => {
        this.filter()
      }
    );

    // this.props.onPagination(newPage, this.state.rowsPerPage);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  /**
   * @author Shashi
   * for next rows
   */
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, loading: true,pageNumber:1 },
      () => {
        this.filter()
      }
    );
    // this.props.onPagination(this.state.pageNumber, +event.target.value);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  /**
  * @author Shashi
  * for filter
  */
  companyFilter = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, loading: true },
      this.filter
    );
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  /**
   * @author Shashi
   * set index value in state
   */
  onViewHandleClick = (row) => {
    this.setState({ delete_id: row._id, showdelete: true });
  };
  /**
   * @author shashi
   * To open status filter menu
   */
  openStatusFilterMenu = (event) => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author shashi
   * To close status filter menu
   */
  closeStatusFilterMenu = () => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author shashi
   * To display data based on selected status.
   */
  handleStatusSelect = (item) => {
    var { companySize, statusFilterName, statusConfig } = this.state;
    companySize = item._id;
    statusFilterName = item.name;
    statusConfig.anchorElStatus = null;

    this.setState(
      {
        statusConfig,
        statusFilterName,
        companySize,
        pageNumber:1
      },
      () => {
        this.filter();
      }
    );
  };
  /**
  *
  * TO HANDLE MENU OPEN
  */
  openAssigneeFilterMenu = (event) => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = event.currentTarget;
    this.setState(
      {
        assigneeConfig,
      },
      () => {
        this.employeeFilter("");
      }
    );
  };
  /**
  *
  * @param {item object} item
  * TO ADD ASSIGNEE FILTER VALUE ON SELECT
  */
  handleAssigneeSelect = (item) => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = null;

    this.setState(
      {
        assignedId: item._id,
        assignedName: item.name === "All" ? "" : item.name,
        assigneeConfig,
        searchAssigneeValue: "",
        pageNumber:1
      },
      () => {
        this.filter();
      }
    );
  };
  /**
   *
   * TO HANDLE ASSIGNEE MENU CLOSE
   */
  closeAssigneeFilterMenu = () => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = null;
    this.setState({
      assigneeConfig,
    });
  };
  /**
 *
 * @param {item object} item
 * TO HANDLE ASSIGNEE FILTER VALUE ON SEARCH
 */
  onAssigneeSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchAssigneeValue: value,
    });
    this.employeeFilter(value);
  };
  /**
  *
  * @param {value} search
  * TO FETCH THE EMPLOYEE FILTER
  */
  employeeFilter = (search) => {
    const { assignedOption } = this.state;
    var Data = {
      accountName:search
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/account-filter", { params:Data })
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = assignedOption.splice();
          if (this.state.searchAssigneeValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.accountName;
              newArray.push(obj);
            });
          }
          this.setState({
            assignedOption: newArray,
          });
        } else {
          this.setState({
            assignedOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  filter = () => {
    this.setState({ loading: true })
    let data = {
      page: this.state.pageNumber,
      pageSize: this.state.rowsPerPage,
      status: this.state.companySize,
      searchText: this.state.searchFilter,
      accountId: this.state.assignedId,
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1300);
  }

  onViewReject = (row) => {
    this.setState({ showaddModal: true, regId: row._id })
  }

  onViewAccepthandel = (row) => {
    this.setState({ acceptId: row._id, showconform: true })
  }


  /**
  *
  * for Accept company api
  */
  onViewAccept = (e) => {
    var Data = {
      id: this.state.acceptId,
    };
    this.setState({ isSubmit: true });
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/newCompany/accept", Data)
      .then((res) => {
        if (res.data.status === 201 || res.data.status === 200) {
          this.setState({
            isSubmit: false,
            responseMsg: res.data.msg,
            alertType: "success",
          });
          setTimeout(() => {
            this.onCloseModal()
            this.filter();
          }, 1000);
        } else {
          this.setState({
            isSubmit: false,
            responseMsg: res.data.msg,
            alertType: "error",
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
        }
      });
  }

  onCreatBy = (row) => {

    this.setState({ acceptId: row._id,},
      () => {
        this.onCreate()
      }
    )
  }
  onCreate = (e) => {
    var Data = {
      id: this.state.acceptId,
    };
    this.setState({ isSubmit: true });
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/newCompany/show", Data)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            isSubmit: false,
            responseMsg: res.data.msg,
            alertType: "success",
          });
          this.props.history.push({
            pathname:
              "/company",
            state: {
              UserData: res.data,
            },
          });
        } else {
          this.setState({
            isSubmit: false,
            responseMsg: res.data.msg,
            alertType: "error",
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
        }
      });
  }

  adddeshandleChange = (e) => {
    var { descriptioneror } = this.state;
    this.setState({ [e.target.name]: e.target.value });
    if (!/\S\w*/.test(e.target.value)) {
      descriptioneror.error =
        "* Please enter a note";
    } else if (e.target.value.length > 250) {
      descriptioneror.error =
        "* Note length should not be max 250 character";
    } else {
      descriptioneror.error = "";
    }
  };
  /**
 *
 * To Reject company api
 */
  onSubmitReject = () => {
    var { descriptioneror } = this.state;
    if (this.state.description === "") {
      descriptioneror.error = "* Please enter a note";
    }
    this.setState(
      {
        descriptioneror,
      },
      () => {
        const isEmpty = Object.values(descriptioneror).every(
          (x) => x === null || x === ""
        );
        if (isEmpty || descriptioneror.error === "") {
          this.setState({ isSubmit: true });
          var data = {
            id: this.state.regId,
            notes: this.state.description,
          };
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/json")
            .post("/newCompany/reject", data)
            .then((res) => {
              if (res.data.status === "200" || res.data.status === 200) {
                this.setState(
                  {
                    isSubmit: false,
                  },
                  () => {
                    var props = {
                      message: res.data.msg,
                      icon: <img src={iconNotificationSuccess} alt="success" />,
                      type: "success",
                      placement: "topRight",
                      duration: 3,
                      top: 200,
                      className: "task_notification update-notify",
                    };
                    NotificationComponent(props);
                  }
                );
                this.onCloseModal();
                this.filter();
              } else {
                this.setState({ isSubmit: false });
                var props = {
                  message: res.data.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(props);
                this.filter();
              }
            });
        }
      }
    );

  }
  /**
  *
  * for Close Modeal 
  */
  onCloseModal = () => {
    this.setState({
      responseMsg:"",
      responseMsg:"",
      showconform: false,
      showaddModal: false,
      description: "",
      descriptioneror: {
        error: "",
      },
    });
  };
  render() {

    var statusOptions = [
      { _id: "accepted", name: "Accepted" },
      { _id: "rejected", name: "Rejected" },
    ];
    let CompanyPage = (
      <div className="body-bg-color">
        <HeaderComponent />
        <Suspense fallback={<div></div>}>
          {/* <HeaderComponent /> */}
          <RejectModal
            onSubmitReject={this.onSubmitReject}
            adddeshandleChange={this.adddeshandleChange}
            modelData={this.state}
            open={this.state.showaddModal}
            handleClose={this.onCloseModal}
          />
          <Dialog open={this.state.showconform} className="deleteServiceDialog create-service-dialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
            {this.state.responseMsg !== "" ? (
              <Alert severity={this.state.alertType}>
                {this.state.responseMsg}
              </Alert>
            ) : null}
            <IconButton
              onClick={this.onCloseModal}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn} alt="close" />
            </IconButton>
            <DialogTitle>
              <center> Accept Request</center>{" "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <center>
                  {" "}
                  Are you want to create new company
              </center>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="cancel"
                onClick={() => this.onViewAccept()}

                color="primary"
              >
                Accept
            </Button>
              <Button
                disabled={this.state.isSubmit}
                className="ok_btn green-btn"
                onClick={() => this.onCreate()}
                autoFocus
              >
                Create a New Company
                {/* {this.state.isSubmit ? <CircularProgress size={20} /> : ""} Delete */}
              </Button>
            </DialogActions>
          </Dialog>


        </Suspense>
        <Box component="span" className="company-comp">
          <div className="header-add-btn">
            <Typography variant="h5" className="tableTitle" component="div">
              Company Referrals
            </Typography>
          </div>
          <div style={{ maxWidth: "100%" }} className="Comp-Table Acc-Table">
            <div className="filter-search">
              <div className="new_custom_filters">
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openStatusFilterMenu}
                  >
                    {this.state.statusFilterName !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Status: </label>
                      )}
                    <label
                      aria-label="status_filter"
                      aria-controls="status_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.statusFilterName === ""
                        ? `Pending`
                        : upperFirst(this.state.statusFilterName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectComponent
                    filterArray={statusOptions}
                    config={this.state.statusConfig.config}
                    anchorEl={this.state.statusConfig.anchorElStatus}
                    handleClick={this.handleStatusSelect}
                    closeCustomPopup={this.closeStatusFilterMenu}
                  />
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openAssigneeFilterMenu}
                  >
                    {this.state.assignedName !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Account: </label>
                      )}
                    <label
                      aria-label="assignee_filter"
                      aria-controls="assignee_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.assignedName === ""
                        ? `All`
                        : upperFirst(this.state.assignedName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectWithSearchComponent
                    filterArray={this.state.assignedOption}
                    config={this.state.assigneeConfig.config}
                    anchorEl={this.state.assigneeConfig.anchorElAssignee}
                    searchValue={this.state.searchAssigneeValue}
                    handleClick={this.handleAssigneeSelect}
                    closeCustomPopup={this.closeAssigneeFilterMenu}
                    onSearchFilter={this.onAssigneeSearchFilter}
                    placeholder="Search Account"
                  />
                </Suspense>
              </div>
              <CssTextField
                id="outlined-basic"
                name="searchFilter"
                variant="outlined"
                type="text"
                placeholder="Search Here"
                onChange={this.companyFilter}
                style={{
                  color: "#303960",
                  fontFamily: "Roboto",
                  backgroundColor: "#F9F9F9",
                  opacity: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: "pointer" }}
                    >
                      <img src={searchIcon} alt="search-icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="table_container">
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>COMPANY NAME</TableCell>
                      <TableCell>DIVISION</TableCell>
                      <TableCell>FIRST NAME</TableCell>
                      <TableCell>LAST NAME</TableCell>
                      <TableCell style={{ width: "12%" }} >EMAIL</TableCell>
                      <TableCell style={{ width: "12%" }} >PHONE</TableCell>
                      <TableCell>REFERRED ACCOUNT</TableCell>
                      <TableCell style={{ width: "10%" }}>
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.isLoading ? (
                      <StyledTableRow>
                        <TableCell colSpan={9} style={{ textAlign: "center" }}>
                          <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={50}
                            width={50}
                            visible={this.props.isLoading}
                          />
                        </TableCell>
                      </StyledTableRow>
                    ) : this.props.pageOfItems &&
                      this.props.pageOfItems.length === 0 && !this.props.isLoading? (
                          <StyledTableRow>
                            <TableCell colSpan={9} style={{ textAlign: "center" }}>
                              No data Found
                        </TableCell>
                          </StyledTableRow>
                        ) : (
                          (this.state.rowsPerPage > 0
                            ? this.props.pageOfItems.slice()
                            : this.props.pageOfItems
                          ).map((row, index) => (
                            <StyledTableRow key={row._id}>
                              <TableCell>
                                {(this.props.pager.currentPage - 1) *
                                  this.state.rowsPerPage +
                                  (index + 1)}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row.companyName}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.divisionName ? row.divisionName:"--"} {...this.state.tooltipprops} arrow>
                                  <span>{row.divisionName ? row.divisionName:"--"}</span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.firstName ?row.firstName:"--"} {...this.state.tooltipprops} arrow>
                                  <span>{row.firstName ?row.firstName:"--"}</span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.lastName ?row.lastName:"--"} {...this.state.tooltipprops} arrow>
                                  <span>{row.lastName ?row.lastName:"--"}</span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.email?row.email:"--"} {...this.state.tooltipprops} arrow>
                                  <a
                                    href={"mailto:" + row.email}
                                    rel="noopener noreferrer"
                                  >
                                    {row.email ? row.email : "--"}
                                  </a>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.phone ? row.phone :"--"} {...this.state.tooltipprops} arrow>
                                  <a
                                    href={"tel:" + row.phone}
                                    rel="noopener noreferrer"
                                  >
                                    {row.phone ? row.phone :"--"}
                                  </a>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.accountName} {...this.state.tooltipprops} arrow>
                                  <span>{row.accountName}</span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell>
                                {row.status === "accepted" ? (

                                  <>
                                    {row.companyId ?
                                      <div
                                        className="table_action_btns"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <LightTooltip title="View Company Details" {...this.state.tooltipprops} arrow>
                                          <NavLink
                                            to={"/company-details/" + row.companyId}
                                            className="navbars"
                                          >
                                            <img
                                              src={viewAction}
                                              alt="openmenu"
                                              style={{ cursor: "pointer" }}
                                            ></img>
                                          </NavLink>
                                        </LightTooltip>
                                      </div>

                                      : 
                                        <div
                                          onClick={() => this.onCreatBy(row)}
                                        >
                                          <LightTooltip title="Create a Company" {...this.state.tooltipprops} arrow>
                                          <img src={addCompanyIcon}></img>
                                          </LightTooltip>
                                        </div>}
                                  </>
                                ) : row.status === "rejected" ? (

                                  <div
                                    className="table_action_btns"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <LightTooltip title="Rejected" {...this.state.tooltipprops} arrow>
                                      <img
                                        src={reject}
                                        alt="openmenu"
                                      ></img>
                                    </LightTooltip>
                                  </div>
                                ) : (
                                      <div
                                        className="table_action_btns"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <LightTooltip title="Accept" {...this.state.tooltipprops} arrow>
                                          <div
                                            onClick={() => this.onViewAccepthandel(row)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img src={accept} alt="openmenu"></img>
                                          </div>
                                        </LightTooltip>
                                        <LightTooltip title="Reject" {...this.state.tooltipprops} arrow>
                                          <img
                                            src={reject}
                                            alt="openmenu"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.onViewReject(row)}
                                          ></img>
                                        </LightTooltip>
                                      </div>
                                    )}
                              </TableCell>
                            </StyledTableRow>
                          ))
                        )}
                  </TableBody>
                  <TableFooter>
                    {this.props.pageOfItems &&
                      this.props.pageOfItems.length !== 0 ?
                      <TableRow>
                        <td colSpan={20} className="pagination_container">
                          <CustomPaginationComponent
                            count={this.props.pager.endPage}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.pageNumber}
                            onPageChange={this.handleCustomPaginagtionChange}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </td>
                      </TableRow>
                      : null}
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Box>
      </div>
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <div>{CompanyPage}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
    pager: state.pagination.pager,
    isLoading:state.pagination.isLoading,
    pageOfItems: state.pagination.pageOfItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole) =>
      dispatch(action.newcompanyPagination(pageNo, pagerole)),
    onFilter: (states) => dispatch(action.onNewcompanyFilter(states)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewCompanyComponent));
