import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import "../ManageAccountComponent.css";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Loader from "react-loader-spinner";
import googleimg from '../../../Assets/images/google-calendar.svg';
import outlookimg from '../../../Assets/images/outlook-calendar.svg'
import recurring_completed from '../../../Assets/images/recurring_completed.svg'
import { AxiosInstance } from "../../../Utilities";
import iconNotificationSuccess from "../../../Assets/images/icon-notify-success.svg"
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg"
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent"

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    overflow: "initial",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#21BF73",
        border: `1px solid #21BF73`,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const CalendarIntegrationComponent = (props) => {
  const {integrations} = props;
  const outlook = integrations.find(el=> el.name === 'outlook'); 
   const google = integrations.find(el=> el.name === 'google'); 
  
   const syncNow = () =>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
    .put("/outlooksync")
    .then((res)=>{
      if(res.data.status==200 || res.data.status=="200"){
        var props = {
          message: res.data.msg,
          icon: <img src={iconNotificationSuccess} alt="success" />,
          type: "success",
          placement: "topRight",
          duration: 3,
          top: 200,
          className: "task_notification update-notify",
        };
        NotificationComponent(props);
      } else{
        var propsErr = {
          message: res.data.msg,
          icon: <img src={iconNotificationError} alt="error" />,
          type: "error",
          placement: "topRight",
          duration: 2,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(propsErr);
      }
    })
   }

  return (
    <div className="integrations-container">
      <div className="integrations_top">
            <label className="integrations_title">
              Integrations  
            </label>
        </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className="integration-row">
            <TableRow className="integrations-header">
              <TableCell width={"15%"}>
                <img src={outlookimg}/>
              </TableCell>
              <TableCell width={"80%"}>
                <h5>Outlook Calendar</h5>
                <div style={{fontSize: "13px"}}>
                  Outlook email and calendar, plus Office Online apps like Word, Excel and powerpoint. Sign in to access your Outlook, Hotmail or live email account
                  </div>
              </TableCell>
              <TableCell width={"25%"} style={{ textAlign: "center" }}>
                {!outlook || !outlook.connectedStatus ?(
            <Button className="ok_btn white-btn" style={{background: "#F0F1F4 0% 0% no-repeat padding-box", borderRadius: "5px",opacity: "1"}} onClick={props.CalendarIntegration}>
            Connect
          </Button>
             ): (
               <>
              <Button className="ok_btn white-btn" style={{border: "1px solid #303960", borderRadius: "5px",opacity: "1"}} onClick={()=> props.signOut('outlook')}>
                Disconnect
              </Button>
              <Button className="ok_btn white-btn" style={{border: "1px solid #12A45E",color: "#12A45E",fontSize: "12px",marginTop: "10px", padding:"5px 15px"}} onClick={syncNow}>
            <img src={recurring_completed} alt="sync" style={{marginRight: "10px"}}></img> Sync
          </Button>
               </>
             )}
                
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        
      </TableContainer>
    </div>
  );
};
export default CalendarIntegrationComponent;