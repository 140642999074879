import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import "../ManageAccountComponent.css";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Loader from "react-loader-spinner";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    overflow: "initial",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#21BF73",
        border: `1px solid #21BF73`,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const reminders = ["task_start", "task_stop"];
const NotificationSettingComponent = (props) => {
  return (
    <div className="notifications-container">
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="notifications-header">
              <TableCell width={"50%"}>
                <h4></h4>
              </TableCell>
              <TableCell width={"25%"} style={{ textAlign: "center" }}>
                <h5>Notification</h5>
              </TableCell>
              <TableCell width={"25%"} style={{ textAlign: "center" }}>
                <h5>Email</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          {Object.entries(props.notificationSetting).length !== 0 ? (
            <TableBody className="notifications-body">
              {props.notificationSetting &&
              props.notificationSetting.allNotification &&
              props.notificationSetting.allNotification.length > 0
                ? props.notificationSetting.allNotification.map((item) => (
                    <TableRow key={item.name}>
                      <TableCell>
                        <p>All Notifications</p>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <FormControlLabel
                          control={
                            <AntSwitch
                              color="primary"
                              checked={Boolean(item.notification)}
                              onChange={() =>
                                props.onSettingChange(
                                  item,
                                  "allNotification",
                                  "notification"
                                )
                              }
                            />
                          }
                          margin="dense"
                          labelPlacement="start"
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <FormControlLabel
                          control={
                            <AntSwitch
                              color="primary"
                              checked={Boolean(item.email)}
                              onChange={() =>
                                props.onSettingChange(
                                  item,
                                  "allNotification",
                                  "email"
                                )
                              }
                            />
                          }
                          margin="dense"
                          labelPlacement="start"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : ""}

              <TableRow>
                <TableCell className="notifications-title">
                  <h6>Tasks</h6>
                </TableCell>
              </TableRow>
              {props.notificationSetting &&
              props.notificationSetting.task &&
              props.notificationSetting.task.length > 0
                ? props.notificationSetting.task.map((item) => (
                    <TableRow key={item.name}>
                      <TableCell>
                        <p>
                          {item.name === "task_assigned"
                            ? "Task has been assigned to you"
                            : item.name === "task_accepted"
                            ? "Your Task assignment has been accepted"
                            : item.name === "task_rejected"
                            ? "Your Task assignment has been rejected"
                            : item.name === "task_rescheduled"
                            ? "A Task assigned to you has been rescheduled"
                            : item.name === "task_cancelled"
                            ? "A Task assigned to you has been cancelled"
                            : item.name === "task_uncovered"
                            ? "A Task created by you has been marked uncovered"
                            : item.name === "task_create_territory"
                            ? "Task has been created in your Territory"
                            : item.name === "task_create_territory_clinical"
                            ? "Task has been created in ClinicalC2 for your Territory"
                            : ""}
                        </p>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <FormControlLabel
                          control={
                            <AntSwitch
                              disabled={false}
                              color="primary"
                              checked={Boolean(item.notification)}
                              onChange={() =>
                                props.onSettingChange(
                                  item,
                                  "task",
                                  "notification"
                                )
                              }
                            />
                          }
                          margin="dense"
                          labelPlacement="start"
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <FormControlLabel
                          control={
                            <AntSwitch
                              disabled={
                                item.name === "task_rejected" ? true : false
                              }
                              color="primary"
                              checked={Boolean(item.email)}
                              onChange={() =>
                                props.onSettingChange(item, "task", "email")
                              }
                            />
                          }
                          margin="dense"
                          labelPlacement="start"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
              <TableRow>
                <TableCell className="notifications-title">
                  <h6>Events</h6>
                </TableCell>
              </TableRow>
              {props.notificationSetting &&
              props.notificationSetting.event &&
              props.notificationSetting.event.length > 0
                ? props.notificationSetting.event.map((item) => (
                    <TableRow key={item.name}>
                      <TableCell>
                        <p>
                          {item.name === "event_create"
                            ? "You've been invited to an event"
                            : item.name === "event_reschedule"
                            ? "Your event has been rescheduled"
                            : item.name === "event_cancelled"
                            ? "Your event has been cancelled"
                            : item.name === "add_attendee"
                            ? "New attendees have been added to your event"
                            : item.name === "remove_attendee"
                            ? "You've been removed from an event"
                            : item.name === "attendee_confirmed"
                            ? "An attendee has been confirmed to your event"
                            : ""}
                        </p>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <FormControlLabel
                          control={
                            <AntSwitch
                              color="primary"
                              checked={Boolean(item.notification)}
                              onChange={() =>
                                props.onSettingChange(
                                  item,
                                  "event",
                                  "notification"
                                )
                              }
                            />
                          }
                          margin="dense"
                          labelPlacement="start"
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <FormControlLabel
                          control={
                            <AntSwitch
                              disabled={
                                item.name === "attendee_confirmed"
                                  ? true
                                  : false
                              }
                              color="primary"
                              checked={Boolean(item.email)}
                              onChange={() =>
                                props.onSettingChange(item, "event", "email")
                              }
                            />
                          }
                          margin="dense"
                          labelPlacement="start"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
              <TableRow>
                <TableCell className="notifications-title">
                  <h6>Reminders</h6>
                </TableCell>
              </TableRow>
              {props.notificationSetting &&
                props.notificationSetting.reminders &&
                props.notificationSetting.reminders.length > 0
                ? props.notificationSetting.reminders.map((item) => (
                  <TableRow key={item.name}>
                    <TableCell>
                      <p>
                        {item.name === "task_start"
                          ? "Task Start"
                          : item.name === "task_stop"
                            ? "Task Stop"
                            : ""}
                      </p>
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <FormControlLabel
                        control={
                          <AntSwitch
                            color="primary"
                            checked={Boolean(item.notification)}
                            onChange={() =>
                              props.onSettingChange(
                                item,
                                "reminders",
                                "notification"
                              )
                            }
                          />
                        }
                        margin="dense"
                        labelPlacement="start"
                      />
                    </TableCell>
                  </TableRow>
                ))
                : ""}
            </TableBody>
          ) : (
            <Loader
              style={{ textAlign: "center", marginLeft: "80%" }}
              type="ThreeDots"
              color="#00BFFF"
              height={45}
              width={45}
              visible={true}
            />
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default NotificationSettingComponent;
