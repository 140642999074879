import React from 'react';
import { Button, TextField,Grid,withStyles, CircularProgress} from "@material-ui/core";
import tick_green from "../../../Assets/images/upload_img.png";
import {Select} from 'antd';
const {Option} = Select;
const CssTextField = withStyles({
  root: {
    border: "0px solid #E2E5F2",
    borderRadius: "5px",
    margin: "2px 0 4px",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
        transition: "all 0.3s"
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
const BillingConfigComponent = (props) => {
  return (
    <div className="billing_page billing_configure " data-test="billing_configure">
    <h3 className="billing_page_title">Configure Billing</h3>
    <div className="billing_configure_form">
      <h5 className="billing_form_title" data-test="billing_header">
        Set up your billing account below.
      </h5>
      <form onSubmit={props.onSubscriptionSubmit} autoComplete="off" autoSave="off" data-test="billing_config_form">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div>
              <label className="label bill_label">First Name <span className="labelAsterisk">*</span></label>
              <CssTextField
                data-test="first_name"
                id="outlined-basic"
                name="firstName"
                variant="outlined"
                type="text"
                fullWidth
                value={props.name?props.name:""}
                placeholder="Enter First Name"
                onChange={props.handleChange}
                // disabled={props.showAddCard?false:true}
              />
              <span className="error" data-test="first_name_error">
                 {props.formError && props.formError.firstName !== ""?'*'+ props.formError.firstName:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div>
              <label className="label bill_label">Last Name</label>
              <CssTextField
              data-test="last_name"
                id="outlined-basic"
                name="lastName"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Enter Last Name"
                onChange={props.handleChange}
                // disabled={props.showAddCard?false:true}
              />
              <span className="error">
                 {props.formError && props.formError.lastName !== ""?'*'+ props.formError.lastName:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div>
              <label className="label bill_label">Phone<span className="labelAsterisk">*</span></label>
              <CssTextField
              data-test="phone_no"
                id="outlined-basic"
                name="phoneNo"
                variant="outlined"
                type="text"
                fullWidth
                value={props.phone?props.phone:""}
                placeholder="Enter Phone Number"
                onChange={props.handleChange}
                // disabled={props.showAddCard?false:true}
              />
              <span className="error">
                 {props.formError && props.formError.phoneNo !== ""?'*'+ props.formError.phoneNo:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div>
              <label className="label bill_label">Email<span className="labelAsterisk">*</span></label>
              <CssTextField
              data-test="email_address"
                id="outlined-basic"
                name="email"
                variant="outlined"
                type="text"
                fullWidth
                value={props.email?props.email:""}
                placeholder="Enter Email"
                onChange={props.handleChange}
                // disabled={props.showAddCard?false:true}
              />
              <span className="error">
                 {props.formError &&  props.formError.email !== ""?'*'+ props.formError.email:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div>
              <label className="label bill_label">Company<span className="labelAsterisk">*</span></label>
              <CssTextField
              data-test="company_name"
                id="outlined-basic"
                name="company"
                variant="outlined"
                type="text"
                fullWidth
                value={props.company?props.company:""}
                placeholder="Enter Company Name"
                onChange={props.handleChange}
                // disabled={props.showAddCard?false:true}
              />
             <span className="error">
                 {props.formError &&  props.formError.company !== ""?'*'+ props.formError.company:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div className="custom-ant-select">
              <label className="label bill_label">Billing Period<span className="labelAsterisk">*</span></label>
                <Select
                data-test="billing_period"
                placeholder="Select Billing Period"
                style={{width:"200px"}}
                defaultValue={props.billingPeriod}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onChange={props.handleBillingPeriodChange}
                notFoundContent={null}
                // disabled={props.showAddCard?false:true}
              >
               <Option value="0">Monthly</Option>
               <Option value="1">Yearly</Option>
              </Select>
              <span className="error"></span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div>
              <label className="label bill_label">Address line 1<span className="labelAsterisk">*</span></label>
              <CssTextField
              data-test="address_1"
                id="outlined-basic"
                name="addressLine1"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Enter Address1"
                onChange={props.handleChange}
                // disabled={props.showAddCard?false:true}
              />
               <span className="error">
                 {props.formError && props.formError.addressLine1 !== ""?'*'+ props.formError.addressLine1:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div>
              <label className="label bill_label">Address line 2</label>
              <CssTextField
              data-test="address_2"
                id="outlined-basic"
                name="addressLine2"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Enter Address2"
                onChange={props.handleChange}
                // disabled={props.showAddCard?false:true}
              />
              <span className="error">
                 {props.formError && props.formError.addressLine2 !== ""?'*'+ props.formError.addressLine2:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
          <div className="custom-ant-select">
              <label className="label bill_label">Country<span className="labelAsterisk">*</span></label>
              <Select
              data-test="country"
                placeholder="Search Country"
                style={{width:"200px"}}
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={props.handleCountrySearch}
                onChange={props.onChangeCountry}
                notFoundContent={null}
                // disabled={props.showAddCard?false:true}
              >
                {props.countryList && props.countryList.length>0 && props.countryList.map(item=>(
                  <Option key={item.id} value={item.sortname+"key"+item.id+"key"+item.name}>{item.name}</Option>
                ))}
              </Select>
               <span className="error">
                 {props.formError && props.formError.country !== ""?'*'+ props.formError.country:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div className="custom-ant-select">
              <label className="label bill_label">State<span className="labelAsterisk">*</span></label>
              <Select
              data-test="state"
                placeholder="Search State"
                style={{width:"200px"}}
                showSearch
                disabled={props.disableState?true:false}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={props.handleStateSearch}
                onChange={props.onChangeState}
                notFoundContent={null}
              >
                {props.stateList && props.stateList.length>0 && props.stateList.map(item=>(
                  <Option key={item.id} value={item.name+"key"+item.id}>{item.name}</Option>
                ))}
              </Select>
              <span className="error">
                 {props.formError && props.formError.state !== ""?'*'+ props.formError.state:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
          <div className="custom-ant-select">
              <label className="label bill_label">City<span className="labelAsterisk">*</span></label>
              <Select
              data-test="city"
                placeholder="Search City"
                style={{width:"200px"}}
                showSearch
                disabled={props.disableCity?true:false}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={props.handleCitySearch}
                onChange={props.onChangeCity}
                notFoundContent={null}
              >
                {props.cityList && props.cityList.length>0 && props.cityList.map(item=>(
                  <Option key={item.id} value={item.name}>{item.name}</Option>
                ))}
              </Select>
               <span className="error">
                 {props.formError && props.formError.city !== ""?'*'+ props.formError.city:""}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="billing_form_field">
            <div>
              <label className="label bill_label">Zipcode <span className="labelAsterisk">*</span></label>
              <CssTextField
              data-test="zipcode"
                id="outlined-basic"
                name="zipCode"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Enter ZipCode"
                onChange={props.handleChange}
                // disabled={props.showAddCard?false:true}
              />
              <span className="error">
                 {props.formError && props.formError.zipCode !== ""?'*'+ props.formError.zipCode:""}
              </span>
            </div>
          </Grid>
         
          {/* <Grid item xs={12}  className="billing_form_field">
              <Grid item xs={6} style={{float:"left"}}> 
              <div style={{border:props.subscribePlan === "Basic"?'1px solid green':'#FFFF'}}>
                  <p>Basic</p>
                  <p>$29.99</p>
                  <Button  onClick={()=>props.selectPlan('Basic','29.99')} color="secondary" style={{cursor:"pointer"}}>
                      Subscribe
                  </Button>
              </div>
              </Grid>
              <Grid item xs={6}  style={{float:"left"}}> 
              <div style={{border:props.subscribePlan === "Premium"?'1px solid green':'#FFFF'}}>
                  <p>Premium</p>
                  <p>$100</p>
                  <Button onClick={()=>props.selectPlan('Premium','100')} color="secondary" style={{cursor:"pointer"}}>
                      Subscribe
                  </Button>
              </div>
              </Grid>
          </Grid> */}
          {props.showAddCard?
          <Grid item xs={12} className="billing_form_field">
            <Button
              data-test="add_card"
              className="add_card_btn grey-btn"
              variant="outlined"
              onClick={()=>props.showAddCardHandler()}
            >
              Confirm & Proceed
            </Button>
          </Grid>:
          // <Grid item xs={12}>
          // <div className="card_added_success" data-test="add_card_success">
          //   <span><img alt="green_tick" src={tick_green}/> Card Added Successfully. 
          //   </span> 
          // </div>
          // </Grid>
          ""
          }
            {/* <Grid item xs={12} className="billing_form_field">
            <div className="add_company_action">
              <Button
              data-test="cancel"
                className="cancel_btn white-btn"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
              data-test="save_config"
                className="ok_btn green-btn"
                type="submit"
                variant="contained"
                disabled={props.disableSave || props.submittedSave?true:false}
              >
                Save
                {props.submittedSave?<CircularProgress size={20}/>:""}
              </Button>
            </div>
          </Grid> */}
        </Grid>
      </form>
    </div>
  </div>
  );
}
 
export default BillingConfigComponent;