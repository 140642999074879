import React, { Component, Suspense } from "react";
import "./AccountsComponent.css";
import { withStyles } from "@material-ui/core/styles";
import * as action from "../../Store/Actions/index";
import Loader from "react-loader-spinner";
import { upperFirst } from "lodash";
import {
  Typography,
  Button,
  
  
  Select,
  TextField,
  InputAdornment,
  
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TableFooter,
  
  Tooltip,
} from "@material-ui/core";
import validator from "validator";
import { formValidation } from "../../SharedComponents/Validation/formValidation";
import { Autocomplete } from "@material-ui/lab";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";

import viewAction from "../../Assets/images/view_action.svg";

import { AxiosInstance } from "../../Utilities";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Delete from "../../Assets/images/delete.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import searchIcon from "../../Assets/images/search_icon.svg";
import carret_down from "../../Assets/images/carret_down.svg";
import { AddAccountSuperadminModal } from "./AddAccountSuperadminModal";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { TransitionGrow} from '../../SharedComponents/CommonUtilities/CommonUtilities';
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";
import {
  getGooglePlaceObjectValues,
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../SharedComponents/CommonUtilities/CommonUtilities";
import CustomSelectWithSearchComponent from "../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent";
// var HeaderComponent = React.lazy(() => import("../../SharedComponents/HeaderComponent/HeaderComponent"));
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
var i = 0;
const url =  new URL(window.location.href);
const stateValue = url.searchParams.get('state');
const searchFilterValue = url.searchParams.get('search');
class AccountsSuperadminComponent extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      role: userData.role,
      companySize: userData.companySize,
      eventLocation:{},
      showaddModal: false,
      showsuperaddModal: false,
      showviewModal: false,
      accountslist: [],
      page: 0,
      pageNumber:1,
      rowsPerPage: 10,
      depError:"",
      searchStateValue:"",
      quickviewData: "",
      stateFilter: "",
      searchFilter: "",
      DivisionFilter: "",
      territoryOption: [],
      DivisionOption: [],
      stateList: [],
      territoryId: "",
      territoryName: "",
      divisionId: "",
      divisionName: "",
      territoryname: "",
      accountName: "",
      dialog: false,
      accountAddress: "",
      accountWebsite: "",
      accountDescription: "",
      showdelete: false,
      disable: false,
      delete_id: "",
      loading: true,
      isSubmit: false,
      isdep:false,
      filters: {
        company: [],
        area: [],
        division: [],
        region: [],
        territory: [],
      },
      departments: [
        {
          index: Math.random(),
          deptName: "",
          depError:"",
        },
      ],
      formError: {
        accountName: "",
        accountAddress: "",
        accountDescription: "",
        accountWebsite: "",
      },
      isValid: false,
      anchorEl: false,
      anchorElData: "",
      parentOption: [
        {
          id: "",
          name: "No Data",
        },
      ],
      parentId: "",
      parentName: "",
      selectedParent: "",
      autocompleteVisible: false,
      city: "",
      area: "",
      state: "",
      zipcode: "",
      mapPosition: {
        lat: "",
        lng: "",
      },
      markerPosition: {
        lat: "",
        lng: "",
      },
      regData: "",
      stateConfig: {
        anchorElState: null,
        config: {
          id: "state_filter_select",
        },
      },
      tooltipprops:{}
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    window.onpopstate = () =>{
      window.location.reload()
    }
    const queryString = this.props.location.search;
    const pageParameter = new URLSearchParams(queryString).get("page");
    const pageNumber = parseInt(pageParameter);
    this.setState({
      searchFilter:searchFilterValue,
      nextpage: pageNumber?pageNumber:1
    })
    if (this.props.location.state === null || this.props.location.state === undefined) {
      this.setState({
        showsuperaddModal: false,
      });
    } else {
      this.setState({
        regData: this.props.location.state
          ? this.props.location.state.UserData.data
          : null,
        showsuperaddModal: true,
      });
    }
    this.fetchState()
    setTimeout(() => {
      this.setState({ loading: false });
      if(stateValue && stateValue !== "All"){
        const state = this.state.stateList.find(item => item.name === stateValue)
        this.setState({
          stateFilter: state?.name ? state?.name : ""
        })
      } else if(stateValue && stateValue === "All"){
        this.setState({
          stateFilter: ""
        })
      }
      this.props.onPagination(this.state.pageNumber-1, this.state.rowsPerPage, "", stateValue, searchFilterValue);
      this.fetchState({search:""});
    }, 2500);
  };
  componentWillReceiveProps = () => {
    if (this.props.location.state === undefined || this.props.location.state === null) {
      this.setState({ accountName: "" })
    } else
      this.setState({
        accountName: this.state.regData.name,
      });
  };
  fetchState=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
    .get("/account/states",{
      params: data
    })
    .then((resp) => {
      var response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {

        var newArray = this.state.stateList.splice();    
        if (this.state.searchStateValue === "") {
          newArray.unshift({ _id: "", name: "All" });
        } else {
          newArray.shift({ _id: "", name: "All" });
        }
        if (response.data.length > 0) {
          response.data.map((item) => {
            var obj = {};
            obj._id = item.stateName;
            obj.name = item.stateName;
            newArray.push(obj);
          });
        }
        this.setState({
          stateList: newArray,
        });
        //this.setState({ stateList: response.data });
      }else  if (
        response &&
        (response.status === "404" || response.status === 404)
      ) {
        this.setState({
          stateList: [],
        })
      }
    })
    .catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else {
        console.log("Internal Server Error");
      }
    });
  }
  // handleChangePage = (event, newPage) => {
  //   this.setState({ loading: true });
  //   this.props.onPagination(newPage + 1, this.state.rowsPerPage);
  //   setTimeout(() => {
  //     this.setState({ loading: false });
  //   }, 2000);
  // };
  handleCustomPaginagtionChange=(event,value)=>{
    this.setState({ pageNumber:value,loading: true },()=>{
      this.Userfilter();
    });
    // this.props.onPagination(value, this.state.rowsPerPage);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }
  handleChangeRowsPerPage = (event) => {
    this.setState({ pageNumber: 1, rowsPerPage: +event.target.value, loading: true },()=>{
      this.Userfilter();
    });
    // this.props.onPagination(this.state.pageNumber, +event.target.value);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  onQuickViewhandler = (id) => {
    let data = {
      accountId: id,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/accountDetails", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let responseData = response.data;
          this.setState({ showviewModal: true, quickviewData: responseData });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  AccountDivisionFilter = async (e) => {
    var name = e.target.value;
    var data = {
      divisionName: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/account/divisionFilter", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            DivisionOption: response.data,
          });
        } else {
          this.setState({
            DivisionOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  onDivisionSelectHandler = (e) => {
    var name = e.target.value;
    if (name !== "") {
      var { DivisionOption } = this.state;
      var data = DivisionOption.filter((item) => {
        return item.name === name;
      });
      data.map((territoryData) => {
        this.setState(
          {
            DivisionId: territoryData._id,
            DivisionName: territoryData.name,
          },
          this.Userfilter
        );
      });
    } else {
      this.AccountDivisionFilter(e);
    }
  };
  accountDivisionAutoClose = (e) => {
    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ DivisionId: "" }, this.Userfilter);
    }
  };

  statefilter = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.Userfilter);
  };
  accountSearch = (e) => {
    let name = e.target.value;
    this.setState({ pageNumber: 1, searchFilter: name }, this.Userfilter);
  };
  Userfilter = () => {
    this.setState({ loading: true });
    let data = {
      page: this.state.pageNumber,
      pageSize: this.state.rowsPerPage,
      state: this.state.stateFilter === "All"?"":this.state.stateFilter,
      territory: this.state.territoryId,
      searchText: this.state.searchFilter,
      divisionId: this.state.DivisionId,
      territoryName: this.state.territoryname,
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    this.updateURL()
  };

  updateURL = () => {
    const {stateFilter, territoryFilterName, searchFilter, stateFilterName} = this.state
    const url =  new URL(window.location.href);  
    const queryParams = {
      state: stateFilter || stateFilterName || url.searchParams.get('state') || '',
      search: searchFilter ||  ''
    };

    const filteredParams = Object.entries(queryParams)
    .filter(([key, value]) => value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    this.props.history.push(`/account?page=${this.state.pageNumber?this.state.pageNumber:'1'}&${filteredParams}`);
  }
  onChangeHandler = (e) => {
    const { formError } = this.state;
    e.preventDefault();
    if (["deptName"].includes(e.target.name)) {
      let departments = [...this.state.departments];
      if(e.target.value.length === 0) {
        departments[e.target.dataset.id]['depError'] = "";
        this.setState({isdep:false})
       } else if (!/\S\w*/.test(e.target.value)) {
        departments[e.target.dataset.id]['depError'] = "* Please enter valid department name";
        this.setState({isdep:true})
      }else {
        departments[e.target.dataset.id]['depError'] = "";
        this.setState({isdep:false})
      }
      var dep = this.state.departments.filter(value => value.deptName === e.target.value)
      if (dep.length > 0) {
        this.setState({depError:"* Department already exists"})
      }else{
        this.setState({depError:""})
      }
      departments[e.target.dataset.id][e.target.name] = e.target.value;
    } else if (
      ["accountName", "accountWebsite", "accountDescription"].includes(
        e.target.name
      )
    ) {
      switch (e.target.name) {
        case "accountName":
          if (!validator.isLength(e.target.value, { max: 50 })) {
            formError.accountName =
              "* Account name should not exceed 50 characters";
            this.setState({ isValid: true });
          } else {
            formError.accountName = "";
          }
          break;
        case "accountDescription":
          if (!validator.isLength(e.target.value, { max: 100 })) {
            formError.accountDescription =
              "* Account description should not exceed 100 characters";
            this.setState({ isValid: true });
          } else {
            formError.accountDescription = "";
          }
          break;
        case "accountWebsite":
          var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
          var regex = new RegExp(expression);
          if (
            !e.target.value.match(regex) &&
            e.target.value.trim().length > 0
          ) {
            formError.accountWebsite = "* Enter valid website url";
            this.setState({ isValid: true });
          } else {
            formError.accountWebsite = "";
          }
          break;
        default: break;
      }
      this.setState({ [e.target.name]: e.target.value, formError });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  sendData=(data)=>{
    this.setState({
      parentId:data.parentIds
    })
  }
  onAccountSubmit = (e) => {
    e.preventDefault();
    const {
      accountName,
      accountAddress,
      accountWebsite,
      city,
      state,
      zipcode,
      parentId,
      departments,
      accountDescription,
      mapPosition,
    } = this.state;

    if (!this.state.isValid && this.state.depError === "") {
      let data = {
        accountName: accountName,
        accountAddress: accountAddress,
        accountWebsite: accountWebsite,
        accountDescription: accountDescription,
        departments: departments,
        parentId: parentId,
        city: city,
        state: state,
        zipcode: zipcode,
        coordinates: this.state.eventLocation,
      };
      this.setState({ isSubmit: true });
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
        .post("/accounts", data)
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "201" ||
              response.status === "200" ||
              response.status === 200 ||
              response.status === 201)
          ) {
              this.componentDidMount();
              this.setState({ showsuperaddModal: false, isSubmit: false },()=>{
                var props = {
                  message: resp.data.msg,
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "success",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification update-notify",
                };
                NotificationComponent(props);
              });
          } else {
            this.setState({
              isSubmit: false,
              responseMsg: resp.data.msg,
              alertType: "error",
            });
            var props = {
              message: resp.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props);
          }
        })
        .catch((err) => {
          this.setState({ isSubmit: false });
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };
  onclickBtn = (e) => {
    const { formError, accountName, accountAddress } = this.state;
    if (accountName === "") {
      formError.accountName = "* Please enter the name";
      this.setState({ isValid: true, formError });
    } else {
      formError.accountName = "";
      this.setState({ isValid: false, formError });
    }
    if (accountAddress === "") {
      formError.accountAddress = "* Please enter the address";
      this.setState({ isValid: true, formError });
    } else {
      formError.accountAddress = "";
      this.setState({ isValid: false, formError });
    }
    if (
      formError.accountName !== "" &&
      formError.accountWebsite !== "" &&
      formError.accountDescription !== ""
    ) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  
  // AutocompleteHandler = (place) => {
  //   const { formError } = this.state;
  //   formError.accountAddress = '';
  //   this.setState({
  //     accountAddress: place.address,
  //     city: place.city,
  //     state: place.state,
  //     zipcode: place.zipcode,
  //     mapPosition: place.mapPosition,
  //     formError
  //   });
  // };
  onGooglePlaceSelect = (place) => {
    var { formError } = this.state;
    formError.accountAddress = '';
    this.setState({ formError });
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    this.setState({
      accountAddress: formattedPlace.address,
           city: formattedPlace.city,
           state: formattedPlace.state,
          zipcode: formattedPlace.zipcode,
          mapPosition: formattedPlace.mapPosition,
          formError,
 
 
      Address: formattedPlace.address,
      eventAddress: formattedPlace.address,
      eventLocation: {lng:formattedPlace.lng, lat:formattedPlace.lat},
    });
  };
  pending = () => {
    window.location.href = "/pending-registration";
  };
  onViewHandleClick = (row) => {
    this.setState({ delete_id: row._id, showdelete: true });
  };
  onDelete = () => {
    this.setState({ disable: true });
    var data = {
      accountId: this.state.delete_id,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .delete(`/account/delete/${this.state.delete_id}`)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false,
            },
            () => {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.Userfilter();
        } else {
          this.setState({ disable: false, showdelete: false });
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.Userfilter();
        }
      });
  };
  supercloseaddModal = () => {
    this.props.history.replace("" + "/account", null);
    this.setState({
      regData: "",
      isdep:false,
      showsuperaddModal: false,
      accountName: "",
      accountAddress: "",
      accountWebsite: "",
      formError: {
        accountName: "",
        accountAddress: "",
        accountDescription: "",
        accountWebsite: "",
      },
      city: "",
      state: "",
      zipcode: "",
      parentId: "",
      departments: "",
      accountDescription: "",
      mapPosition: "",
    });
  };
  onDelete;
  addRowHandler = (e) => {
    this.setState(
      (prevState) => ({
        departments: [...prevState.departments, {
          index: Math.random(),
          deptName: "",
        },
        ],
      }),
      () => {
        i++;
      }
    );
  };
  deleteHandler = (value) => {
  this.setState({
   departments : value
      })
  }
  openStateFilterMenu = (event) => {
    const { stateConfig } = this.state;
    stateConfig.anchorElState = event.currentTarget;
    this.setState({
      stateConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleStateSelect = (item) => {
    this.setState({ loading: true });
    var {
      stateFilter,
      stateConfig,
    } = this.state;
    stateFilter = item.name;
    stateConfig.anchorElState = null;
    this.setState(
      {
        pageNumber: 1,
        stateConfig,
        stateFilter,
      },
      this.Userfilter

    );
  };
  onStateSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchStateValue: value,
    });
    this.fetchState({search:value});
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeStateFilterMenu = () => {
    const { stateConfig } = this.state;
    stateConfig.anchorElState = null;
    this.setState({
      stateConfig,
    });
  };
  navigateDetail=(row)=>{
    this.props.history.push("/account-details/" + row.id);
  }
  render() {
    let { loading } = this.state;
    let handleClose = () => this.setState({ isdep:false, showdelete: false });
    let AccountsPage = (
      <div className="body-bg-color">

        {/* <Suspense fallback={<div></div>}> */}
          <HeaderComponent />

        {/* </Suspense> */}
        <AddAccountSuperadminModal
          open={this.state.showsuperaddModal}
          onClose={this.supercloseaddModal}
          modeldata={this.state}
          onChangeHandler={this.onChangeHandler}
          addRowHandler={this.addRowHandler}
          onAccountSubmit={this.onAccountSubmit}
          sendData={this.sendData}
          onclickBtn={this.onclickBtn}
          AutocompleteHandler={this.AutocompleteHandler}
          pend_reg={this.state.regData}
          onGooglePlaceSelect={this.onGooglePlaceSelect}
          deleteHandler={this.deleteHandler}
        />
        <Dialog open={this.state.showdelete} className="deleteServiceDialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
          <DialogTitle>
            {" "}
            <center> Delete Account</center>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <center>
                {" "}
                Are you sure you want to delete this Account? This action cannot be undone.
              </center>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="cancel white-btn"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.disable}
              className="delete red-btn"
              onClick={() => this.onDelete()}
              style={{ color: "red" }}
              autoFocus
            >
              {this.state.disable ? <CircularProgress size={20} /> : ""} Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="header-add-btn header-add-btn-account">
          <Typography variant="h5" className="tableTitle" component="div">
            Account
          </Typography>
          <div className="services_btn_right">
            <NavLink
              to={"/pending-registration"}
              className="navbars"
            >
              <Button
                variant="contained"
                className="tableaddBtn add_service_main_btn grey-btn"
                color="default"
                style={{ boxShadow: "none" }}
              >

                Pending Registration
            </Button>
            </NavLink>
            <Button
              variant="contained"
              className="tableaddBtn grey-btn"
              color="default"
              style={{ boxShadow: "none" }}
              onClick={() => this.setState({ showsuperaddModal: true })}
            >
              Add Account
            </Button>
          </div>
          {/* <Button variant="contained" className="tableaddBtn" color="default" style={{ boxShadow: 'none' }} onClick={() => this.setState({ showaddModal: true })}>Add Account</Button> */}
        </div>
        <div className="Acc-Table">
          <div className="filter-search">
            <div style={{ display: "flex" }}>
              {(this.state.role === "super admin" ||
                this.state.role === "admin") &&
                this.state.companySize === "large" ? (
                  <Autocomplete
                    style={{ marginRight: "25px", width: "150px" }}
                    options={this.state.DivisionOption}
                    onInputChange={this.accountDivisionAutoClose}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        name="DivisionFilter"
                        placeholder="Division"
                        onChange={this.AccountDivisionFilter}
                        onSelect={this.onDivisionSelectHandler}
                      />
                    )}
                  />
                ) : (
                  ""
                )}
              <Suspense fallback={<div></div>}>
                <div
                  className="new_custom_filter_div"
                  onClick={this.openStateFilterMenu}
                >
                  {this.state.stateFilter !== "" ? (
                    ""
                  ) : (
                      <label className="leftName">State: </label>
                    )}
                  <label
                    aria-label="state_filter"
                    aria-controls="state_filter_select"
                    area-aria-haspopup="true"
                    className="rightName"
                  >
                    {this.state.stateFilter === ""
                      ? `All`
                      : upperFirst(this.state.stateFilter)}
                  </label>
                  <img className="carret" src={carret_down} alt="carret" />
                </div>
                {/* <CustomSelectComponent
                  filterArray={this.state.stateList}
                  config={this.state.stateConfig.config}
                  anchorEl={this.state.stateConfig.anchorElState}
                  handleClick={this.handleStateSelect}
                  closeCustomPopup={this.closeStateFilterMenu}
                /> */}
                  <CustomSelectWithSearchComponent
                      filterArray={this.state.stateList}
                      config={this.state.stateConfig.config}
                      anchorEl={this.state.stateConfig.anchorElState}
                      searchValue={this.state.searchStateValue}
                      handleClick={this.handleStateSelect}
                      closeCustomPopup={this.closeStateFilterMenu}

                      onSearchFilter={this.onStateSearchFilter}
                      placeholder="Search State"
                    />
              </Suspense>
            </div>
            <CssTextField
              id="outlined-basic"
              name="searchFilter"
              variant="outlined"
              type="text"
              placeholder="Search Here"
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon} alt="search" />
                  </InputAdornment>
                ),
              }}
              value={this.state.searchFilter}
              onChange={this.accountSearch}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "5%" }}>#</TableCell>
                    <TableCell style={{ width: "20%" }}>NAME</TableCell>
                    <TableCell style={{ width: "10%" }}>NO OF DEP</TableCell>
                    <TableCell style={{ width: "30%" }}>ADDRESS</TableCell>
                    <TableCell style={{ width: "15%" }}>STATE</TableCell>
                    <TableCell style={{ width: "10%"}}>
                      ACTIONS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablecell_container"> 
                  {loading ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={loading}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ) : !this.props.pageOfItems ||
                    this.props.pageOfItems.length === 0 ? (
                        <StyledTableRow>
                          <TableCell colSpan={9} style={{ textAlign: "center" }}>
                            No data found
                      </TableCell>
                        </StyledTableRow>
                      ) : (
                        (this.state.rowsPerPage > 0
                          ? this.props.pageOfItems.slice()
                          : this.props.pageOfItems
                        ).map((row, index) => (
                          <StyledTableRow key={row._id}>
                            <TableCell onClick={() => this.navigateDetail(row)} >
                              {(this.props.pager.currentPage - 1) *
                                this.state.rowsPerPage +
                                (index + 1)}
                            </TableCell>
                            <TableCell  onClick={() => this.navigateDetail(row)}
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row.accountName}
                            </TableCell>
                            <TableCell  onClick={() => this.navigateDetail(row)}>{row.departments}</TableCell>
                            <TableCell  onClick={() => this.navigateDetail(row)}
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row.accountAddress !== ""
                                ? row.accountAddress
                                : "N/A"}
                            </TableCell>
                            <TableCell  onClick={() => this.navigateDetail(row)}>{row.state}</TableCell>
                            <TableCell>
                              <div
                                className="table_action_btns"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {/* <div>
                              <LightTooltip title="Add Task" {...this.state.tooltipprops} arrow>
                                <img src={addAction} alt="add"></img>
                              </LightTooltip>
                            </div> */}
                                <div>
                                  <a href={"/account-details/" + row.id} rel="noopener noreferrer">
                                    <LightTooltip title="View Account" {...this.state.tooltipprops} arrow>
                                      <img
                                        src={viewAction}
                                        alt="openmenu"
                                        style={{ cursor: "pointer" }}
                                      ></img>
                                    </LightTooltip>
                                  </a>
                                </div>
                                <LightTooltip title="Delete" {...this.state.tooltipprops} arrow>
                                  <a>
                                  <img
                                    src={Delete}
                                    alt="Delete"
                                    onClick={() => this.onViewHandleClick(row)}
                                  ></img>
                                  </a>
                                </LightTooltip>
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        ))
                      )}
                </TableBody>
                <TableFooter>
                  {/* <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={9}
                      count={
                        !this.props.pager ? 0 : this.props.pager.totalItems
                      }
                      rowsPerPage={this.state.rowsPerPage}
                      page={
                        !this.props.pager ? 0 : this.props.pager.currentPage - 1
                      }
                      labelDisplayedRows={({ from, to, count }) =>
                        `Showing ${to} of ${count} entries`
                      }
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow> */}
                   {this.props.pageOfItems &&
                      this.props.pageOfItems.length !== 0?
                  <TableRow>
                    <td colSpan={20} className="pagination_container">
                      <CustomPaginationComponent
                      count={this.props.pager.endPage}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.pageNumber}
                      onPageChange={this.handleCustomPaginagtionChange}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                    </td>
                  </TableRow>:null}
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* </Box> */}
      </div>
    );

    return <div>{AccountsPage}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole, territory, state, search) =>
      dispatch(action.accountpagination(pageNo, pagerole, territory, state, search)),
    onFilter: (states) => dispatch(action.onaccountfilter(states)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsSuperadminComponent));
