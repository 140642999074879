import React, { Component, Suspense } from "react";
import { AxiosInstance } from "../../Utilities";
import moment from 'moment';
import {
  Breadcrumbs,
  Link,
  Typography,
  Grid,
  Button,
  TextField,
  Select,
  Tooltip,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import DateDiff from 'date-diff';
import { withStyles } from "@material-ui/core/styles";
import editIcon from "../../Assets/images/view_edit_icon.svg";
import backIcon from "../../Assets/images/breadcrumb_back.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import close_icon_red from "../../Assets/img/close_icon_red.svg";
import emailIcon from "../../Assets/images/icon-quick-email.svg";
import ComponyIcon from "../../Assets/images/company_icon.svg";
import iconQuickPhone from "../../Assets/images/icon-quick-phone.svg";
import iconLocation from "../../Assets/images/Icon-location-on.svg";
import iconwebsite from "../../Assets/images/icon-website.svg";
import comp_size from "../../Assets/images/people (5).svg";
import validator from "validator";
import logodefaultimage from "../../Assets/images/logodefaultimage.png";
import AutoComplete from "react-google-autocomplete";
import { GoogleMapsAPI } from "../../client-config";
import { lowerCase } from "lodash";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import {
  getGooglePlaceObjectValues,
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../SharedComponents/CommonUtilities/CommonUtilities";
import momentTimeZone from 'moment-timezone';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import BillingDetailComponent from "./BillingDetailComponent/BillingDetailComponent";
import { isSafari } from "react-device-detect";
// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
class CompanyDetailsComponent extends Component {
  constructor(props) {
    super(props);
    var zoneName = momentTimeZone.tz.guess();
    let data = window.location.href;
    const [baseUrl, companyId] = data.split("/company-details/");
    this.state = {
      timeZoneName: localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName,
      company: "",
      companyId: companyId,
      companyName: "",
      companyLogo: "",
      phoneNum: "",
      emailId: "",
      address: "",
      website: "",
      ishideSize: true,
      description: "",
      editCompanySize: "",
      isSize: true,
      isCompany: true,
      isPhone: true,
      isEmail: true,
      isAddress: true,
      isWebsite: true,
      isDesc: true,
      isImage: true,
      isBillingType:true,
      isPricePerUser:true,
      isBillingDate:true,
      isPriceDisable:false,
      billingTypeDisable:false,
      billingsDatesDisable:false,
      billingTrialDays:14,
      billingConfigType:1,
      pricePerUser:0,
      Csize: "",
      logoPic: null,
      company_name: "",
      _id: "",
      companySize: "",
      phoneNo: "",
      adress: "",
      web: "",
      desc: "",
      logo: "",
      hideName: "showName",
      hidePhone: "showPhone",
      hideEmail: "showEmail",
      hideAddress: "showAddress",
      hideWebsite: "showWebsite",
      hideSize: "showSize",
      hideDesc: "showDesc",
      billingTypeEdit:"billingTypeEdit",
	    pricePerUserEdit:"pricePerUserEdit",
	    billingDateEdit:"billingDateEdit",
      formError: {
        companyname: "",
        phoneNo: "",
        email: "",
        address: "",
        website: "",
        description: "",
        logoPic: "",
        logoImg: "",
        billingType:"",
        pricePerUser:"",
        billingDate:""
      },

      billingsTialDates:[],
      tooltipprops:{}
    };
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  /**
   * @author Akshatha
   * To get the details of a company
   */
  componentDidMount = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/companyDetail/" + this.state.companyId)
      .then((resp) => {
        if (resp.status === "200" || resp.data.status === 200) {
          let response = resp.data.data;
          const {billingsTialDates}=this.state;
          billingsTialDates[0]= momentTimeZone(response.trialStart).tz(this.state.timeZoneName);
          billingsTialDates[1]=momentTimeZone(response.trialEnd).tz(this.state.timeZoneName);

          var diffDate=new DateDiff(momentTimeZone(response.trialEnd),momentTimeZone(response.trialStart));

          this.setState({
            company: response,
            companyName: response.companyName,
            companyLogo: response.companyLogo,
            phone: response.phone,
            email: response.email,
            address: response.address,
            website: response.website,
            description: response.description,
            Csize: response.size,
            companySize: response.size,
            _id: response._id,
            billingsTialDates,
            billingTrialDays:diffDate.days(),
            pricePerUser: response.pricePerUser?response.pricePerUser:0,
            billingConfigType:response.billingType?response.billingType:1

          });
        } else {
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  /**
   * @author Akshatha
   * @param {Name} name
   * To change the string to editable text box
   */
  editInput = (name) => {
    let { formError } = this.state;
    switch (name) {
      case "isCompany":
        formError.companyname = "";
        this.setState({ isCompany: !this.state.isCompany, formError, iscompany: true });
        break;
      case "isPhone":
        formError.phoneNo = "";
        this.setState({ isPhone: !this.state.isPhone, formError, isphone: true });
        break;
      case "isEmail":
        formError.email = "";
        this.setState({ isEmail: !this.state.isEmail, formError, isemail: true });
        break;
      case "isAddress":
        formError.address = "";
        this.setState({ isAddress: !this.state.isAddress, formError, isaddress: true });
        break;
      case "isWebsite":
        formError.website = "";
        this.setState({ isWebsite: !this.state.isWebsite, formError, iswebsite: true });
        break;
      case "isDesc":
        formError.description = "";
        this.setState({ isDesc: !this.state.isDesc, formError, isdesc: true });
        break;
      case "isImage":
        formError.logoPic = "";
        this.setState({ isImage: !this.state.isImage, formError });
        break;
      case "isBillingType":
        formError.billingType="";
        this.setState({
          isBillingType:!this.state.isBillingType,formError
        });
        break;
      case "isPricePerUser":
        formError.pricePerUser="";
        this.setState({
          isPricePerUser:!this.state.isPricePerUser,formError
        });
        break;
      case "isBillingDate":
        formError.billingDate="";
        this.setState({
          isBillingDate:!this.state.isBillingDate,formError
        });
      break;
      default:
        break;
    }
  };
  /**
   * @author Akshatha
   * @param {Name} stateVal
   * To update the values individually
   */
  updateHandler = (stateVal) => {
    const {
      companyId,
      companyName,
      phoneNum,
      logoPic,
      address,
      website,
      description,
      emailId,
      formError,
      pricePerUser,
      billingConfigType,
      billingsTialDates
    } = this.state;
    if (phoneNum === "") {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
    if (emailId === "") {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
    let data = {};
    switch (stateVal) {
      case "isImage":
        data = new FormData();
        data.append("companyLogo", logoPic);
        data.append("id", companyId);
        break;
      case "isCompany":
        data.id = companyId;
        data.companyName = companyName;
        break;
      case "isPhone":
        data.id = companyId;
        data.phone = phoneNum;
        break;
      case "isEmail":
        data.id = companyId;
        data.email = emailId;
        break;
      case "isAddress":
        data.id = companyId;
        data.address = address;
        break;
      case "isWebsite":
        data.id = companyId;
        data.website = website;
        break;
      case "isDesc":
        data.id = companyId;
        data.description = description;
        break;
      case "isPricePerUser":
        data.id=companyId;
        data.pricePerUser=parseFloat(pricePerUser);
        break;
      case "isBillingType":
        data.id=companyId;
        data.billingType= parseInt(billingConfigType);
        break;
      case 'isBillingDate':
        data.id=companyId;
        data.trialStart= momentTimeZone(momentTimeZone(billingsTialDates[0]).tz(this.state.timeZoneName)).utc();
        data.trialEnd= momentTimeZone(momentTimeZone(billingsTialDates[1]).tz(this.state.timeZoneName)).utc();
        break;
      default:
        break;
    }
    if (
      formError.phoneNo === "" &&
      formError.companyname === "" &&
      formError.email === "" &&
      formError.address === "" &&
      formError.website === "" &&
      formError.description === "" &&
      formError.logoPic === "" &&
      formError.pricePerUser === ""
    ) {
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/x-www-form-urlencoded; charset=UTF-8")
        .put("/companyDetail", data)
        .then((resp) => {
          var response = resp && resp.data;
          if (response && (response.status === 200 || response.status === "200")) {
            if (stateVal === "isCompany") {
              this.setState({ companyName: companyName });
            } else if (stateVal === "isPhone") {
              this.setState({ phone: phoneNum });
            } else if (stateVal === "isEmail") {
              this.setState({ email: emailId });
            } else if (stateVal === "isAddress") {
              this.setState({ address: address });
            } else if (stateVal === "isWebsite") {
              this.setState({ website: website });
            } else if (stateVal === "isDesc") {
              this.setState({ description: description });
            } else if (stateVal === "isImage") {
              this.setState({ companyLogo: logoPic });
             } else if(stateVal === "isPricePerUser"){
              this.setState({ pricePerUser: pricePerUser ,isPriceDisable:false});
            }else if(stateVal === "isBillingType"){
              this.setState({
                billingTypeDisable:false
              })
            }else if(stateVal === 'isBillingDate'){
              this.setState({
                billingsDatesDisable:false
              })
            }
            var props = {
              message: "Details updated successfully.",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification success-notify level-error",
            };
            NotificationComponent(props);
            this.editInput(stateVal);
            this.componentDidMount();
          } else if(response && (response.status === "400" || response.status === 400)){
            var props = {
              message:response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify level-error",
            };
            NotificationComponent(props);
          }else {
          }
        }).catch(err=>{
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        })
    }
    this.setState({ logoPic: null });
  };
  /**
   * @author Akshatha
   * @param {Event} e
   * To get the values of the edited fields
   */
  onChangeHandler = (e) => {
    e.preventDefault();
    var phoneno = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const { formError } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
    switch (name) {
    case "pricePerUser":
        validator.isEmpty(value)
        ? formError.pricePerUser="Please enter a billing price"
        : !/^(?!0+$)\d{1,6}(\.\d{1,2})?$/.test(value)
        ? formError.pricePerUser="Please enter the valid price. e.g: $2,500"
        : formError.pricePerUser="";
    break;
      case "companyName":
        this.setState({ iscompany: false })
        validator.isEmpty(value)
          ? (formError.companyname = "Enter Company name")
          : !/^[a-zA-Z,'. ]*$/.test(value)
            ? (formError.companyname = "Special characters are not allowed")
            : !validator.isLength(value, { min: 2, max: 100 })
              ? (formError.companyname =
                "Company name should be min 2 and max 100 character")
              : (formError.companyname = "");
        break;
      case "phoneNum":
        this.setState({ isphone: false })
        validator.isEmpty(value)
          ? (formError.phoneNo = "")
          : !phoneno.test(value)
            ? (formError.phoneNo =
              "*Please enter the valid phone number e.g.: 800-555-5555 ")
            : (formError.phoneNo = "");
        break;
      case "emailId":
        this.setState({ isemail: false })
        validator.isEmpty(value)
          ? (formError.email = "Enter Email id")
          : !validator.isEmail(value)
            ? (formError.email =
              "Please check the email address is in valid format e.g. name@domain.com")
            : !validator.isLength(value, { min: 2, max: 100 })
              ? (formError.email = "Email should be min 2 and max 100 character")
              : (formError.email = "");
        break;
      case "address":
        this.setState({ isaddress: false })
        validator.isEmpty(value)
          ? (formError.address = "")
          : (formError.address = "");
        break;
      case "website":
        this.setState({ iswebsite: false })
        validator.isEmpty(value)
          ? (formError.website = "")
          : !expression.test(value)
            ? (formError.website = "*Enter valid website url")
            : (formError.website = "");
        break;
      case "description":
        this.setState({ isdesc: false })
        validator.isEmpty(value)
          ? (formError.description = "Enter Description")
          : !validator.isLength(value, { min: 1, max: 256 })
            ? (formError.description = "Description should be max 256 character")
            : (formError.description = "");
        break;
      default:
        break;
    }

    if(formError.pricePerUser !== ""){
      this.setState({isPriceDisable:false})
    }else{
      this.setState({isPriceDisable:true})
    }
    if(name === "billingConfigType"){
      this.setState({billingTypeDisable:true})
    }
    this.setState({
      formError
    });
  };

  onDateRangeChanage=(date, dateString)=>{
    var diffDate=new DateDiff(momentTimeZone(dateString[1]),momentTimeZone(dateString[0]));
    this.setState({
      billingTrialDays:diffDate.days(),
      billingsTialDates:[momentTimeZone(dateString[0]),momentTimeZone(dateString[1])]
    },()=>{
      this.setState({
        billingsDatesDisable:true
      })
    })
  }
  valid = (current) => {
    return current && current < moment(this.state.billingsTialDates[0]);
 };

  onGooglePlaceSelect = (place) => {
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    this.setState({
      address: formattedPlace.address,
      isaddress: false,
    });
  };
  /**
   * @author Akshatha
   * @param {Event} event
   * To get the uploaded file and name
   */
  onFileuploadHandler = (event) => {
    const { formError } = this.state;
    var x = document.getElementsByClassName("input_two");
    x[0].innerHTML = "Upload logo file in jpg, png, svg format";
    if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|svg)$/)) {
      formError.logoPic = "Upload valid image";
      this.setState({
        logoPic: null,
        file: "",
        loaded: 0,
      });
    } else {
      formError.logoPic = "";
      var x = document.getElementsByClassName("input_two");
      x[0].innerHTML = event.target.files[0].name;
      this.setState({
        logoPic: event.target.files[0],
        file: URL.createObjectURL(event.target.files[0]),
        loaded: 0,
      });
    }
    this.setState({ formError });
  };
  /**
   * @author Akshatha
   * @param {value} showvalue
   * To show the edit button onhover
   */
  showButton = (showvalue) => {
    switch (showvalue) {
      case "hideName":
        this.setState({ hideName: "" })
        break;
      case "hidePhone":
        this.setState({ hidePhone: "" })
        break;
      case "hideEmail":
        this.setState({ hideEmail: "" })
        break;
      case "hideAddress":
        this.setState({ hideAddress: "" })
        break;
      case "hideWebsite":
        this.setState({ hideWebsite: "" })
        break;
      case "hideSize":
        this.setState({ hideSize: "" })
        break;
      case "hideDesc":
        this.setState({ hideDesc: "" })
        break;
      case "billingTypeEdit":
        this.setState({
          billingTypeEdit:""
        });
        break;
      case "pricePerUserEdit":
        this.setState({
          pricePerUserEdit:""
        });
        break;
      case "billingDateEdit":
        this.setState({
          billingDateEdit:""
        });
        break;
      default:
        break;

    }
  };
  /**
   * @author Akshatha
   * @param {value} hidevalue
   * To hide the edit button on release
   */
  hideButton = (hidevalue) => {
    switch (hidevalue) {
      case "hideName":
        this.setState({ hideName: "showName" })
        break;
      case "hidePhone":
        this.setState({ hidePhone: "showPhone" })
        break;
      case "hideEmail":
        this.setState({ hideEmail: "showEmail" })
        break;
      case "hideAddress":
        this.setState({ hideAddress: "showAddress" })
        break;
      case "hideWebsite":
        this.setState({ hideWebsite: "showWebsite" })
        break;
      case "hideSize":
        this.setState({ hideSize: "showSize" })
        break;
      case "hideDesc":
        this.setState({ hideDesc: "showDesc" })
        break;
        case "billingTypeEdit":
        this.setState({
          billingTypeEdit:"billingTypeEdit"
        });
        break;
      case "pricePerUserEdit":
        this.setState({
          pricePerUserEdit:"pricePerUserEdit"
        });
        break;
      case "billingDateEdit":
        this.setState({
          billingDateEdit:"billingDateEdit"
        });
        break;
      default:
        break;
    }
  }
  onChangeCompanySize = (e) => {
    this.setState({ ishideSize: false, [e.target.name]: e.target.value });
    // if (e.target.value === this.state.Csize) {
    //   this.setState({ ishideSize: true, })
    // } else {this.setState({ ishideSize: false,}) }
  }
  onSubmitCompanySize = () => {
    var Data = {
      companySize: this.state.companySize
    };
    this.setState({ isSubmit: true, ishideSize: true });
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/companysizeEdit/" + this.state._id, Data)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false,
              isSubmit: false,
              isSize: true,
            },
            () => {
              var props = {
                message: res.data.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
        } else {
          this.setState({ isSubmit: false })
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.componentDidMount();
        }
      }).catch(err=>{
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })

  }
  onHandelcompenySize = (e) => {
    this.setState({ isSize: true })
    this.componentDidMount()
  }

  makeAdminHandler = () => {
   let data={companyId: this.state.companyId}
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/json")
      .post("/superadmin/createAdminAccess", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          localStorage.clear();
          localStorage.setItem("userdata", JSON.stringify(response.data));
          localStorage.setItem("token", response.token.split(" ")[1]);
          localStorage.setItem("switchedToSuperAdmin", true);
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  handleGoBack = () =>{
    this.props.history.goBack();
  }

  render() {
    var option = [
      { _id: "small", name: "Small - Only Regions" },
      { _id: "medium", name: "Medium - Areas and Regions" },
      { _id: "large", name: "Large - Divisions, Areas and Regions" },
    ];
    const {
      isAddress,
      isCompany,
      isWebsite,
      isEmail,
      isPhone,
      isDesc,
      isImage,
      companyLogo,
    } = this.state;
    let logoImg = "";
    if (companyLogo === "" && companyLogo === undefined) {
      logoImg = logodefaultimage;
    } else {
      logoImg = "https://salesc2companyprofile.s3.amazonaws.com/" + companyLogo;
    }
    if (companyLogo === null) {
      logoImg = logodefaultimage;
    } else {
      logoImg = "https://salesc2companyprofile.s3.amazonaws.com/" + companyLogo;
    }
    return (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        <div className="select_parent">
          <div className="breadCrumbs">
            <img className="bread-crumb-back" src={backIcon} alt="back" />
            <Breadcrumbs className="bread-crumb-main" aria-label="breadcrumb">
              <Link
                className="bread-crumb-link"
                color="inherit"
                // href="/Company"
                onClick={this.handleGoBack}
              >
                Company
              </Link>
              <Typography className="bread-crumb-name">
                Company Detail
              </Typography>
            </Breadcrumbs>
          </div>
          <div className="comp_flex comp-detail">
            <div className="comp-layout">
              <div className="header-logo">
                {isImage && (
                  <>
                    <div className="comp-image">
                      <img src={logoImg} alt=""></img>
                    </div>
                    <Button
                      className="logo_btn primary-btn"
                      value="Change Logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.editInput("isImage")}
                    >
                      Change Logo
                    </Button>
                  </>
                )}
                {!isImage && (
                  <>
                    <div className="profile_upload">
                      <div>
                        <div className="input_two">
                          Upload logo file in jpg, png, svg format
                        </div>
                        <input
                          className="input_one"
                          type="file"
                          name="logoPic"
                          onChange={this.onFileuploadHandler}
                        />
                      </div>
                      <Button
                        className="suc-button primary-btn"
                        variant="contained"
                        onClick={() => this.updateHandler("isImage")}
                        disabled={
                          this.state.formError.logoPic !== "" ||
                            this.state.logoPic === null
                            ? true
                            : false
                        }
                      >
                        Upload
                      </Button>
                      <Button
                        className="level_cancel fail-button"
                        variant="contained"
                        onClick={() => {
                          const { formError } = this.state;
                          formError.logoPic = "";
                          this.setState({
                            isImage: !this.state.isImage,
                            formError,
                          });
                        }}
                      >
                        X
                      </Button>
                    </div>
                  </>
                )}{" "}
                  <Button
                      className="switch_login_btn"
                      value="Change Logo"
                      style={{ cursor: "pointer" }}
                      onClick={this.makeAdminHandler}
                    >
                      Login as {this.state.company !== undefined ? this.state.company.companyName : ""}
                    </Button>
              </div>
            
              <div className="header-logo-error modal-err-msg">
                {this.state.formError.logoPic
                  ? this.state.formError.logoPic
                  : ""}
              </div>
              <div className="header-comp ">
                <div>
                  <div className="comp-name"
                    onMouseEnter={() => this.showButton("hideName")} onMouseLeave={() => this.hideButton("hideName")}>
                    <img className="comp-img" src={ComponyIcon} alt="" />
                    {this.state.isCompany && (
                      <span className="custom-span">
                        <LightTooltip title={this.state.company.companyName} {...this.state.tooltipprops} arrow>
                          <span>{this.state.company !== undefined ? this.state.company.companyName : ""}</span>
                        </LightTooltip>
                      </span>
                    )}
                    {!this.state.isCompany && (
                      <span className="edit-input">
                        <CssTextField
                          id="outlined-basic"
                          variant="outlined"
                          name="companyName"
                          className="comp_name"
                          autoFocus
                          placeholder="Enter Company Name"
                          type="text"
                          defaultValue={
                            this.state.company !== undefined
                              ? this.state.company.companyName
                              : ""
                          }
                          onChange={this.onChangeHandler}

                        />
                        <Button
                          className="add_more_btn"
                          onClick={() => this.updateHandler("isCompany")}
                          disabled={this.state.iscompany}
                        >
                          Save
                      </Button>
                      </span>
                    )}{" "}
                    {isCompany && (
                      <img
                        src={editIcon}
                        className={this.state.hideName}

                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => this.editInput("isCompany")}
                      />
                    )}
                    {!isCompany && (
                      <img
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => this.editInput("isCompany")}
                      />
                    )}
                  </div>
                  <div className="modal-err-msg">
                    {
                      this.state.formError.companyname
                        ? this.state.formError.companyname
                        : ""
                    }
                  </div>
                </div>
                <Button
                  variant="contained"
                  className="compCodeBtn grey-btn"
                  color="default"
                  style={{ boxShadow: "none", cursor: "default" }}
                >
                  Company Code:
                  <span>
                    {this.state.company !== undefined
                      ? this.state.company.companyId
                      : ""}
                  </span>
                </Button>

              </div>
              <div className="comp-container">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="comp-contact"
                      onMouseEnter={() => this.showButton("hidePhone")} onMouseLeave={() => this.hideButton("hidePhone")}>
                      <h5 className="contact-title">Phone:</h5>
                      <div className="contact-content">
                        <img src={iconQuickPhone} alt="" />
                        {this.state.isPhone && (
                          <span className="custom-span">
                            <LightTooltip title={this.state.company.phone} {...this.state.tooltipprops} arrow>
                              <a
                                href={"tel:" + this.state.company.phone}
                                rel="noopener noreferrer"
                              >
                                {this.state.company !== undefined ? this.state.company.phone : ""}
                              </a>
                            </LightTooltip>
                          </span>
                        )}
                        {!this.state.isPhone && (
                          <span className="edit-input">
                            <CssTextField
                              id="outlined-basic"
                              variant="outlined"
                              name="phoneNum"
                              className="comp_name"
                              autoFocus
                              placeholder="Enter Phone No"
                              type="text"
                              defaultValue={
                                this.state.company !== undefined
                                  ? this.state.company.phone
                                  : ""
                              }
                              onChange={this.onChangeHandler}
                            />
                            <Button
                              className="add_more_btn"
                              onClick={() => this.updateHandler("isPhone")}
                              disabled={this.state.isphone}
                            >
                              Save
                            </Button>
                          </span>
                        )}{" "}
                        {isPhone && (
                          <img
                            src={editIcon}
                            className={this.state.hidePhone}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isPhone")}
                          />
                        )}
                        {!isPhone && (
                          <img
                            src={close_icon_red}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isPhone")}
                          />
                        )}
                      </div>
                      <div className="modal-err-msg">
                        {this.state.formError.phoneNo
                          ? this.state.formError.phoneNo
                          : ""}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="comp-contact"
                      onMouseEnter={() => this.showButton("hideEmail")} onMouseLeave={() => this.hideButton("hideEmail")}>
                      <h5 className="contact-title">Email:</h5>
                      <div className="contact-content">
                        <img src={emailIcon} alt="" />
                        {this.state.isEmail && (
                          <span className="custom-span">
                            <LightTooltip title={this.state.company.email} {...this.state.tooltipprops} arrow>
                              <a
                                href={"mailto:" + this.state.company.email}
                                rel="noopener noreferrer"
                              >
                                {this.state.company !== undefined ? this.state.company.email : ""}
                              </a>
                            </LightTooltip>
                          </span>
                        )}
                        {!this.state.isEmail && (
                          <span className="edit-input">
                            <CssTextField
                              id="outlined-basic"
                              variant="outlined"
                              name="emailId"
                              autoFocus
                              placeholder="Enter Email"
                              type="text"
                              defaultValue={
                                this.state.company !== undefined
                                  ? this.state.company.email
                                  : ""
                              }
                              onChange={this.onChangeHandler}
                            />
                            <Button
                              className="add_more_btn"
                              onClick={() => this.updateHandler("isEmail")}
                              disabled={this.state.isemail}
                            >
                              Save
                            </Button>
                          </span>
                        )}{" "}
                        {isEmail && (
                          <img
                            src={editIcon}
                            className={this.state.hideEmail}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isEmail")}
                          />
                        )}
                        {!isEmail && (
                          <img
                            src={close_icon_red}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isEmail")}
                          />
                        )}
                      </div>
                      <div className="modal-err-msg">
                        {this.state.formError.email
                          ? this.state.formError.email
                          : ""}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="comp-contact"
                      onMouseEnter={() => this.showButton("hideAddress")} onMouseLeave={() => this.hideButton("hideAddress")}>
                      <h5 className="contact-title">Address:</h5>
                      <div className="contact-content">
                        <img src={iconLocation} alt="" />
                        {this.state.isAddress && (
                          <LightTooltip title={this.state.company.address} {...this.state.tooltipprops} arrow>
                          <span className="custom-span">
                              <span>{this.state.company !== undefined ? this.state.company.address : ""}</span>
                          </span>
                          </LightTooltip>
                        )}
                        {!this.state.isAddress && (
                          <span className="edit-input">
                            <Suspense fallback={<div></div>}>
                              <AutoComplete
                                style={{
                                  width: "100%",
                                }}
                                name="address"
                                autoFocus
                                apiKey={GoogleMapsAPI}
                                types={[]}
                                defaultValue={this.state.company !== undefined
                                  ? this.state.company.address
                                  : ""}
                                onPlaceSelected={this.onGooglePlaceSelect}
                                onChange={this.onChangeHandler}

                              />
                            </Suspense>
                            <Button
                              className="add_more_btn"
                              onClick={() => this.updateHandler("isAddress")}
                              disabled={this.state.isaddress}

                            >
                              Save
                            </Button>
                          </span>
                        )}{" "}
                        {isAddress && (
                          <img
                            src={editIcon}
                            className={this.state.hideAddress}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isAddress")}
                          />
                        )}
                        {!isAddress && (
                          <img
                            src={close_icon_red}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isAddress")}
                          />
                        )}
                      </div>
                      <div className="modal-err-msg">
                        {this.state.formError.address
                          ? this.state.formError.address
                          : ""}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="comp-contact"
                      onMouseEnter={() => this.showButton("hideWebsite")} onMouseLeave={() => this.hideButton("hideWebsite")}>
                      <h5 className="contact-title">Website:</h5>
                      <div className="contact-content">
                        <img src={iconwebsite} alt="" />
                        {this.state.isWebsite && (
                          <span className="custom-span">
                            <LightTooltip title={this.state.company.website} {...this.state.tooltipprops} arrow>
                              <a
                                href={lowerCase(this.state.company.website && this.state.company.website.substring(0, 3)) === 'www' ? '//' + this.state.company.website : this.state.company.website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {this.state.company.website !== undefined
                                  ? this.state.company.website
                                    .replace(
                                      /^(?:https?:\/\/)?(?:www\.)?/i,
                                      ""
                                    )
                                    .split("/")[0]
                                  : "N/A"}
                              </a>
                            </LightTooltip>
                          </span>
                        )}
                        {!this.state.isWebsite && (
                          <span className="edit-input">
                            <CssTextField
                              id="outlined-basic"
                              variant="outlined"
                              name="website"
                              autoFocus
                              placeholder="Enter Website"
                              type="text"
                              defaultValue={
                                this.state.company !== undefined
                                  ? this.state.company.website
                                  : ""
                              }
                              onChange={this.onChangeHandler}
                            />
                            <Button
                              className="add_more_btn"
                              onClick={() => this.updateHandler("isWebsite")}
                              disabled={this.state.iswebsite}
                            >
                              Save
                            </Button>
                          </span>
                        )}{" "}
                        {isWebsite && (
                          <img
                            src={editIcon}
                            className={this.state.hideWebsite}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isWebsite")}
                          />
                        )}
                        {!isWebsite && (
                          <img
                            src={close_icon_red}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isWebsite")}
                          />
                        )}
                      </div>
                      <div className="modal-err-msg">
                        {this.state.formError.website
                          ? this.state.formError.website
                          : ""}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                
                <div className="comp-contact comp-size"
                  onMouseEnter={() => this.showButton("hideSize")} onMouseLeave={() => this.hideButton("hideSize")}>
                  <h5 className="contact-title">Company Size:</h5>
                  <div className="contact-content">
                    <img src={comp_size} alt="" />
                    {this.state.isSize && (
                      <span className="custom-span">
                        <LightTooltip {...this.state.tooltipprops} title={this.state.companySize
                          ? this.state.companySize.charAt(0).toUpperCase() +
                          this.state.companySize.slice(1)
                          : "-"} arrow>
                          <span>{this.state.companySize
                            ? this.state.companySize.charAt(0).toUpperCase() +
                            this.state.companySize.slice(1)
                            : "-"}</span>
                        </LightTooltip>
                      </span>
                    )}
                    {!this.state.isSize && (
                      <span className="edit-input">
                        <FormControl variant="outlined" fullWidth className="ms-b-10" margin="dense">
                          <Select
                            margin="dense"
                            className="customSelect"
                            name="companySize"
                            fullWidth
                            onChange={this.onChangeCompanySize}
                            defaultValue={this.state.companySize}
                          >
                            {option.map((mitem) => (
                              <MenuItem key={mitem._id} value={mitem._id}>
                                {mitem.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          className="add_more_btn"
                          onClick={() => this.onSubmitCompanySize()}
                          disabled={this.state.ishideSize}
                        >
                          Save
                            </Button>
                      </span>
                    )}{" "}
                    {this.state.isSize && (
                      <img
                        src={editIcon}
                        className={this.state.hideSize}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => this.setState({ isSize: false })}
                      />
                    )}
                    {!this.state.isSize && (
                      <img
                        src={close_icon_red}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={this.onHandelcompenySize}
                      />
                    )}
                  </div>

                </div>
                <div className="comp-description"
                  onMouseEnter={() => this.showButton("hideDesc")} onMouseLeave={() => this.hideButton("hideDesc")}>
                  <h5 className="description-title">Description:</h5>
                  <div className="description-content">
                    {this.state.isDesc && (
                      <span className="custom-span">
                        <LightTooltip {...this.state.tooltipprops} title={this.state.company.description} arrow>
                          <span>{this.state.company !== undefined ? this.state.company.description : ""}</span>
                        </LightTooltip>
                      </span>
                    )}
                    {!this.state.isDesc && (
                      <span className="edit-input">
                        <CssTextField
                          id="outlined-basic"
                          variant="outlined"
                          name="description"
                          className="text_area"
                          rowsMax={3}
                          multiline
                          autoFocus
                          placeholder="Enter Description"
                          type="text"
                          defaultValue={
                            this.state.company !== undefined
                              ? this.state.company.description
                              : ""
                          }
                          onChange={this.onChangeHandler}
                        />
                        <Button
                          className="add_more_btn"
                          onClick={() => this.updateHandler("isDesc")}
                          disabled={this.state.isdesc}
                        >
                          Save
                        </Button>
                      </span>
                    )}{" "}
                    {isDesc && (
                      <img
                        className="description-edit"
                        src={editIcon}
                        className={this.state.hideDesc}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => this.editInput("isDesc")}
                      />
                    )}
                    {!isDesc && (
                      <img
                        src={close_icon_red}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => this.editInput("isDesc")}
                      />
                    )}
                  </div>
                  <div className="modal-err-msg">
                    {this.state.formError.description
                      ? this.state.formError.description
                      : ""}
                  </div>
                </div>
                <BillingDetailComponent
                detail={this.state.company}
                isBillingType={this.state.isBillingType}                
                isPricePerUser={this.state.isPricePerUser}
                isBillingDate={this.state.isBillingDate}
                billingTypeEdit={this.state.billingTypeEdit}
                pricePerUserEdit={this.state.pricePerUserEdit}
                billingDateEdit={this.state.billingDateEdit}
                pricePerUser={this.state.pricePerUser}
                billingsTialDates={this.state.billingsTialDates}
                billingTrialDays={this.state.billingTrialDays}
                updateHandler={this.updateHandler}
                formError={this.state.formError}
                showButton={this.showButton}
                hideButton={this.hideButton}
                valid={this.valid}
                editInput={this.editInput}
                onChangeHandler={this.onChangeHandler}
                isPriceDisable={this.state.isPriceDisable}
                billingType={this.state.billingConfigType}
                billingTypeDisable={this.state.billingTypeDisable}
                onDateRangeChanage={this.onDateRangeChanage}
                billingsDatesDisable={this.state.billingsDatesDisable}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CompanyDetailsComponent;
