import React, { Component, Suspense } from "react";
import "../TeamComponent.css";
import { withStyles } from "@material-ui/core/styles";
import * as action from "../../../Store/Actions/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import jwt from "jsonwebtoken";
import TerrainIcon from "../../../Assets/images/user-level-icon.svg";
import ContactMailIcon from "../../../Assets/images/user-contact-icon.svg";
import close_icon_red from "../../../Assets/img/close_icon_red.svg";
import recurring_assigned from "../../../Assets/images/recurring_assigned.svg";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  Select,
} from "@material-ui/core";
import userIcon from "../../../Assets/images/user-icon.png";
import iconQuickPhone from "../../../Assets/images/icon-quick-phone.svg";
import emailIcon from "../../../Assets/images/icon-quick-email.svg";
import CompanyIcon from "../../../Assets/images/company_icon.svg";
import backIcon from "../../../Assets/images/breadcrumb_back.svg";
import ExpandMoreIcon from "../../../Assets/images/expansion_dd.svg";
import profileeditIcon from "../../../Assets/images/profile-pic-edit.svg";
import editIcon from "../../../Assets/images/view_edit_icon.svg";
import { AxiosInstance, checkCmsLabel } from "../../../Utilities";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationSuccess from "../../../Assets/images/icon-notify-success.svg";
import ChangeLevelModal from "./ChangeLevelModal";
import { Menu, Dropdown } from "antd";
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import { phoneRegx } from "../../../SharedComponents/Validation/formValidation";
import CustomPaginationComponent from '../../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent';
import { ProfileURL } from "../../../client-config";
import validator from "validator";
import momentTimeZone from "moment-timezone";
import HeaderComponent from "../../../SharedComponents/HeaderComponent/HeaderComponent";
// var HeaderComponent = React.lazy(() =>
//   import("../../../SharedComponents/HeaderComponent/HeaderComponent")
// );
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
class EmployeeDetailsComponent extends Component {
  constructor(props) {
    super(props);
    var zoneName = momentTimeZone.tz.guess();
    let userRole = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata")).role
      : "";
    let accDta = window.location.href;
    const [baseUrl, userId] = accDta.split("/user/");
    this.state = {
      roleLabelChange: [],
      timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
      userRole: userRole,
      userId: userId,
      userName: "",
      companyName: "",
      companySize: "",
      role: "",
      phoneNum: "",
      emailId: "",
      territory: "",
      address: "",
      level: "",
      isCompany: true,
      isRole: true,
      isPhone: true,
      isEmail: true,
      isTerritory: true,
      isAddress: true,
      isName: true,
      isImage: true,
      profilePic: null,
      file: null,
      page: "0",
      pageNumber: 1,
      user_name: "",
      rowsPerPage: "5",
      openModal: false,
      levelId: "",
      area_id: "",
      region_id: "",
      division_id: "",
      divisionFilter: [],
      areaFilter: [],
      regionFilter: [],
      territoryFilter: [],
      Ptdivision: "",
      Ptarea: "",
      Ptregion: "",
      Ptterritory: "",
      Ptrole: "",
      hideLevel: false,
      hideRole: false,
      loading: true,
      inValid: false,
      phoneNo: "",
      profileClicked: true,
      today: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).startOf("day").utc(),
      User_Status: "",
      formError: {
        phoneNo: "",
        userName: "",
        profilePic: "",
        Ptdivision: "",
        Ptarea: "",
        Ptregion: "",
        Ptterritory: "",
        Ptrole: "",
      },
    };
    this.editInput = this.editInput.bind(this);
    console.log(momentTimeZone().tz(this.state.timeZoneName).startOf("day").utc());
  }

  /**
   * @author Murali
   * To get the details & upcoming tasks of an user
   */
  componentDidMount = () => {
    const fetchCmsData = checkCmsLabel()
    if (fetchCmsData) {
      this.setState({ roleLabelChange: fetchCmsData.roleData })
    }
    const { pageNumber, rowsPerPage, userId, today } = this.state;
    this.props.onPagination(pageNumber - 1, rowsPerPage, userId, today);
    let data = {
      employee_id: userId,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
      .get(`/user-detail/${userId}`)
      .then((resp) => {
        if (resp.data.status === "200" || resp.data.status === 200) {
          let response = resp.data.data;
          this.setState(
            {
              user_name: response.name,
              User_Status: response.status,
              companyName: response.companyName,
              companySize: response.companySize,
              role: response.role,
              Ptrole: response.role,
              phoneNo: response.phone,
              emailId: response.email,
              territory: response.levelName,
              level: response.level,
              profilepath: response.profilePath,
              levelId: response.levelId,
              Ptterritory: response.levelId,
              companyId: response.companyId,
              division_id:
                response.division_id !== undefined ? response.division_id : "",
              Ptdivision:
                response.division_id !== undefined ? response.division_id : "",
              region_id:
                response.region_id !== undefined ? response.region_id : "",
              Ptregion:
                response.region_id !== undefined ? response.region_id : "",
              area_id: response.area_id !== undefined ? response.area_id : "",
              Ptarea: response.area_id !== undefined ? response.area_id : "",
              address: response.address !== undefined ? response.address : "",
              loading: false,
            },
            () => {
              this.getFilters(
                this.state.companyId,
                this.state.userRole,
                "Company"
              );
            }
          );
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Murali
   * To close the modal
   */
  onCloseModal = () => {
    this.setState({
      openModal: false,
      hideLevel: false,
      hideRole: false,
    });
  };
  /**
   * @author Murali
   * @param {State Name} name
   * To change the string to editable text box and get dropdown values in dialog
   */
  editInput = (name) => {
    // console.log("state data....",)
    const {
      level,
      levelId,
      division_id,
      area_id,
      region_id,
      userRole,
      companyId
    } = this.state;
    if (name === "isRole") {
      this.setState(
        { childType: this.state.level, openModal: true, hideLevel: true },
        () => {
          if (level === "area") {
            this.getFilters(division_id, userRole, "Division");
            this.getFilters(levelId, userRole, "Area");
          } else if (level === "division") {
            this.getFilters(levelId, userRole, "Division");
          } else if (level === "region") {
            this.getFilters(division_id, userRole, "Division");
            this.getFilters(area_id, userRole, "Area");
            this.getFilters(levelId, userRole, "Region");
          } else if (level === "territory") {
            this.getFilters(division_id, userRole, "Division");
            this.getFilters(area_id, userRole, "Area");
            this.getFilters(region_id, userRole, "Region");
          } else {
            this.getFilters(companyId, userRole, "Company");
          }
        }
      );
    } else if (name === "isTerritory") {
      this.setState(
        {
          childType: this.state.level,
          openModal: true,
          hideLevel: false,
          hideRole: true,
        },
        () => {
          if (level === "area") {
            this.getFilters(division_id, userRole, "Division");
          } else if (level === "region") {
            this.getFilters(division_id, userRole, "Division");
            this.getFilters(area_id, userRole, "Area");
          } else if (level === "territory") {
            this.getFilters(division_id, userRole, "Division");
            this.getFilters(area_id, userRole, "Area");
            this.getFilters(region_id, userRole, "Region");
          }
        }
      );
    }
    switch (name) {
      case "isCompany":
        this.setState({ isCompany: !this.state.isCompany });
        break;
      case "isPhone":
        const { formError } = this.state;
        formError.phoneNo = '';
        this.setState({ isPhone: !this.state.isPhone, formError });
        break;
      case "isEmail":
        this.setState({ isEmail: !this.state.isEmail });
        break;
      case "isAddress":
        this.setState({ isAddress: !this.state.isAddress });
        break;
      case "isName":
        this.setState({ isName: !this.state.isName });
        break;
      case "isImage":
        this.setState({ isImage: !this.state.isImage, profilePic: null, profileClicked: true });
        break;
      default:
        break;
    }
  };
  /**
   * @author Murali
   * @param {State Name} stateVal
   * To update the values individually
   */
  updateHandler = (stateVal) => {
    const {
      userId,
      userName,
      phoneNum,
      profilePic,
      address,
      Ptrole,
      role,
      emailId
    } = this.state;
    if (phoneNum === "") {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
    let data = {};
    switch (stateVal) {
      case "isImage":
        data = new FormData();
        data.append("profilePhoto", profilePic);
        data.append("userId", userId);
        data.append("userRole", Ptrole !== "" ? Ptrole : role);
        // document.getElementsByClassName("suc-button")[0].innerHTML =
        //   "Uploading...";
        break;
      case "isName":
        data.userId = userId;
        data.name = userName;
        data.userRole = Ptrole !== "" ? Ptrole : role;
        break;
      case "isPhone":
        data.userId = userId;
        data.phone = this.state.phoneNum;
        data.userRole = Ptrole !== "" ? Ptrole : role;
        break;
      case "isAddress":
        data.userId = userId;
        data.address = address;
        data.userRole = Ptrole !== "" ? Ptrole : role;
        break;
      case "isEmail":
        data.userId = userId;
        data.email = emailId;
        break
      default:
        break;
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
      .put("/users", data)
      .then((resp) => {
        console.log(resp)
        if ((resp.status === 200 && resp.data.status === 200) || resp.data.status === "200") {
          this.componentDidMount();
          let props = {
            message: "Details updated successfully.",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 4,
            top: 100,
            className: "task_notification success-notify level-error",
          };
          NotificationComponent(props);

          if (stateVal === "isName") {
            this.setState({ user_name: userName });
          } else if (stateVal === "isPhone") {
            this.setState({ phoneNo: phoneNum });
          } else if (stateVal === "isImage") {
            this.setState({
              isImage: true
            })
            document.getElementsByClassName("suc-button")[0].innerHTML =
              "Upload";
          } else if (stateVal === "isEmail") {
            this.setState({
              email: true
            })
          }
          this.editInput(stateVal);
        }
        else if (resp.data.status === 400) {
          this.setState({
            email: true
          })
          let props = {
            message: resp.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "success",
            placement: "topRight",
            duration: 4,
            top: 200,
            className: "task_notification error-notify level-error",
          };
          NotificationComponent(props);
        }
        else {
          if (stateVal === "isImage") {

            document.getElementsByClassName("suc-button")[0].innerHTML =
              "Upload";
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Murali
   * @param {Event Nextpage} nextpage
   * To handle pagination for tasks table
   */
  handleCustomPaginagtionChange = (event, nextpage) => {
    this.setState({
      pageNumber: nextpage
    })
    this.props.onPagination(nextpage, this.state.rowsPerPage, this.state.userId, this.state.today);
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      { rowsPerPage: +event.target.value },

      () => {
        this.props.onPagination(this.state.pageNumber, this.state.rowsPerPage, this.state.userId, this.state.today);
      }
    );
  };

  onFileInputFocus = () => {
    this.setState({
      profileClicked: true
    })
  }
  /**
   * @author Murali
   * @param {Event} event
   * To get the uploaded file and name
   */
  onFileuploadHandler = (event) => {
    const { formError } = this.state;
    var x = document.getElementsByClassName("input_two");
    x[0].innerHTML = "Upload logo file in jpg, png, svg format";
    if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|svg)$/)) {
      formError.profilePic = "Upload valid image";
      this.setState({
        profilePic: null,
        file: "",
        loaded: 0,
        profileClicked: true,
      });
    } else {
      formError.profilePic = "";
      var x = document.getElementsByClassName("input_two");
      x[0].innerHTML = event.target.files[0].name;
      this.setState({
        profilePic: event.target.files[0],
        file: URL.createObjectURL(event.target.files[0]),
        loaded: 0,
        profileClicked: false
      });
    }
    this.setState({ formError });
  };
  /**
   * @author Murali
   * @param {Event} e
   * To get the values of the edited fields
   */
  onChangeHandler = (e) => {
    e.preventDefault();
    const { formError } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    if (name === "Ptrole") {
      this.setState({ hideLevel: false });
    }
    switch (name) {
      case "userName":
        if (value.trim().length === 0) {
          formError.userName = "* Please enter a username";
        } else if (!validator.isLength(value, { max: 50 })) {
          formError.userName = "* User Name should not exceed 50 characters.";
        } else {
          formError.userName = "";
        }
        break;
      case "phoneNum":
        if (value.trim().length === 0) {
          formError.phoneNo = "";
        } else if (!phoneRegx.test(value) && value.trim().length > 0) {
          formError.phoneNo = "*Please enter the valid phone number e.g.: 800-555-5555";
        } else {
          formError.phoneNo = "";
        }
        break;
      case "Ptdivision":
        if (value.trim().length === 0) {
          formError.Ptdivision = "* Please select Division";
        } else {
          formError.Ptdivision = "";
        }
        break;
      case "Ptarea":
        if (value.trim().length === 0) {
          formError.Ptarea = "* Please select Area";
        } else {
          formError.Ptarea = "";
        }
        break;
      case "Ptregion":
        if (value.trim().length === 0) {
          formError.Ptregion = "* Please select Region";
        } else {
          formError.Ptregion = "";
        }
        break;
      case "Ptterritory":
        if (value.trim().length === 0) {
          formError.Ptterritory = "* Please select a territory";
        } else {
          formError.Ptterritory = "";
        }
        break;
      case "Ptrole":
        if (value.trim().length === 0) {
          formError.Ptrole = "* Please select Role";
        } else {
          formError.Ptrole = "";
        }
        break;
    }
    this.setState({ [name]: value, formError }, () => {
      const {
        companySize,
        Ptdivision,
        Ptarea,
        Ptregion,
        Ptterritory,
        inValid,
        Ptrole,
      } = this.state;
      if (name === "Ptdivision") {
        this.getFilters(value, this.state.userRole, "Division");
        this.setState({ regionFilter: [], territoryFilter: [] });
        if (Ptrole === "Division Manager") {
          this.setState({ inValid: false });
        } else if (Ptrole === "Area Manager") {
          this.setState({ inValid: true });
        } else if (Ptrole === "Team Lead") {
          this.setState({ inValid: true });
        } else if (Ptrole === "Sales Rep" || Ptrole === "Territory Manager") {
          this.setState({ inValid: true });
        }
      } else if (name === "Ptarea") {
        this.getFilters(value, this.state.userRole, "Area");
        this.setState({ territoryFilter: [] });
        if (Ptrole === "Area Manager") {
          this.setState({ inValid: false });
        } else if (Ptrole === "Team Lead") {
          this.setState({ inValid: true });
        } else if (Ptrole === "Sales Rep" || Ptrole === "Territory Manager") {
          this.setState({ inValid: true });
        }
      } else if (name === "Ptregion") {
        this.getFilters(value, this.state.userRole, "Region");
        if (Ptrole === "Team Lead") {
          this.setState({ inValid: false });
        } else if (Ptrole === "Sales Rep" || Ptrole === "Territory Manager") {
          this.setState({ inValid: true });
        }
      } else {
        if (Ptrole === "Sales Rep" || Ptrole === "Territory Manager") {
          this.setState({ inValid: false });
        }
      }
      if (name === "Ptrole") {
        if (value === "Sales Rep" || value === "Territory Manager") {
          if (
            companySize === "large" &&
            Ptdivision !== "" &&
            Ptarea !== "" &&
            Ptregion !== "" &&
            Ptterritory !== ""
          ) {
            this.setState({ inValid: false });
          } else if (
            companySize === "medium" &&
            Ptarea !== "" &&
            Ptregion !== "" &&
            Ptterritory !== ""
          ) {
            this.setState({ inValid: false });
          } else if (
            companySize === "small" &&
            Ptregion !== "" &&
            Ptterritory !== ""
          ) {
            this.setState({ inValid: false });
          } else {
            this.setState({ inValid: true });
          }
        } else if (value === "Team Lead") {
          if (
            companySize === "large" &&
            Ptdivision !== "" &&
            Ptarea !== "" &&
            Ptregion !== ""
          ) {
            this.setState({ inValid: false });
          } else if (
            companySize === "medium" &&
            Ptarea !== "" &&
            Ptregion !== ""
          ) {
            this.setState({ inValid: false });
          } else if (companySize === "small" && Ptregion !== "") {
            this.setState({ inValid: false });
          } else {
            this.setState({ inValid: true });
          }
        } else if (value === "Area Manager") {
          if (companySize === "large" && Ptdivision !== "" && Ptarea !== "") {
            this.setState({ inValid: false });
          } else if (companySize === "medium" && Ptarea !== "") {
            this.setState({ inValid: false });
          } else {
            this.setState({ inValid: true });
          }
        } else if (value === "Division Manager") {
          if (companySize === "large" && Ptdivision !== "") {
            this.setState({ inValid: false });
          } else {
            this.setState({ inValid: true });
          }
        }
      }
    });
  };
  /**
   * @author Murali
   * @param {companyId/divisionId/areaId/regionId} id
   * @param {User Role} role
   * @param {Filter type} type
   * To get the values in the dropdown
   */
  getFilters = (id, role, type) => {
    let data = {};
    if (type === "Company") {
      data.companyId = id;
      data.roles = role;
    } else if (type === "Division") {
      data.divisionId = id;
      data.roles = role;
    } else if (type === "Area") {
      data.areaId = id;
      data.roles = role;
    } else if (type === "Region") {
      data.regionId = id;
      data.roles = role;
    }

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/users/filters", data)
      .then((resp) => {
        if (resp.status === 200) {
          let response = resp.data.data;
          if (type === "Company") {
            this.setState({ divisionFilter: response.division });
          } else if (type === "Division") {
            this.setState({ areaFilter: response.area });
          } else if (type === "Area") {
            this.setState({ regionFilter: response.region });
          } else if (type === "Region") {
            this.setState({ territoryFilter: response.territory });
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Murali
   * To get the values of the dialog and call the update API
   */
  onChangelevel = () => {
    const {
      role,
      Ptrole,
      userId,
      Ptterritory,
      Ptarea,
      Ptdivision,
      Ptregion,
      area_id,
      division_id,
      region_id,
      levelId,
      level,
      companySize,
      formError,
      inValid,
    } = this.state;
    let data = {};
    if (Ptrole !== "") {
      data.userId = userId;
      data.role = Ptrole;

      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
        .put("/users", data)
        .then((resp) => {
          if (resp.status === 200 && resp.data.status === 200) {
            if (Ptrole === "Sales Rep" || Ptrole === "Territory Manager") {
              data.userId = userId;
              data.level = "territory";
              data.levelId = Ptterritory !== "" ? Ptterritory : levelId;
            } else if (Ptrole === "Team Lead") {
              data.userId = userId;
              data.level = "region";
              data.levelId =
                Ptregion !== ""
                  ? Ptregion
                  : level === "region"
                    ? levelId
                    : region_id;
            } else if (Ptrole === "Area Manager") {
              data.userId = userId;
              data.level = "area";
              data.levelId =
                Ptarea !== "" ? Ptarea : level === "area" ? levelId : area_id;
            } else if (Ptrole === "Division Manager") {
              data.userId = userId;
              data.level = "division";
              data.levelId =
                Ptdivision !== ""
                  ? Ptdivision
                  : level === "division"
                    ? levelId
                    : division_id;
            }
            AxiosInstance(
              (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user',
              "application/x-www-form-urlencoded; charset=UTF-8"
            )
              .put("/users", data)
              .then((resp) => {
                if (resp.status === 200 && resp.data.status === 200) {
                  var props = {
                    message: "Details updated successfully.",
                    icon: <img src={iconNotificationSuccess} alt="success" />,
                    type: "success",
                    placement: "topRight",
                    duration: 4,
                    top: 200,
                    className: "task_notification success-notify level-error",
                  };
                  this.componentDidMount();
                  this.onCloseModal();
                  NotificationComponent(props);
                }
              })
              .catch((err) => {
                var error = err.response;
                if (error && (error.status === "403" || error.status === 403)) {
                  localStorage.clear();
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 500);
                } else {
                  console.log("Internal Server Error");
                }
              });
          } else {
            var props = {
              message: resp.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification error-notify level-error",
            };
            NotificationComponent(props);
            this.onCloseModal();
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    } else {
      if (role === "Sales Rep") {
        data.userId = userId;
        data.level = "territory";
        data.levelId = Ptterritory !== "" ? Ptterritory : levelId;
      } else if (role === "Team Lead") {
        data.userId = userId;
        data.level = "region";
        data.levelId =
          Ptregion !== "" ? Ptregion : level === "region" ? levelId : region_id;
      } else if (
        (role === "manager" && level === "area") ||
        role === "Area Manager"
      ) {
        data.userId = userId;
        data.level = "area";
        data.levelId =
          Ptarea !== "" ? Ptarea : level === "area" ? levelId : area_id;
      } else if (
        (role === "manager" && level === "division") ||
        role === "Division Manager"
      ) {
        data.userId = userId;
        data.level = "division";
        data.levelId =
          Ptdivision !== ""
            ? Ptdivision
            : level === "division"
              ? levelId
              : division_id;
      }
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
        .put("/users", data)
        .then((resp) => {
          if (resp.status === 200 && resp.data.status === 200) {
            var props = {
              message: "Details updated successfully.",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification success-notify level-error",
            };
            this.componentDidMount();
            this.onCloseModal();
            NotificationComponent(props);
          } else {
            var props = {
              message: resp.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification error-notify level-error",
            };
            NotificationComponent(props);
            this.onCloseModal();
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };
  /**
   *
   * @param {selected status} val
   * @param {task} id
   * on click of update status call api to update status of task
   */
  onclickStatus = (val, item) => {
    if (item?.recurringStatus === "AutoGenerated") {
      var data = {
        dateId: item?.dateId,
        timeZone: this.state.timeZoneName
      };
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
        .put(`/recurringTask/${item?._id}/${item?.taskScheduleId}`, data)
        .then((resp) => {
          if (resp.data.status === "200" || resp.data.status === 200) {
            var props = {
              message: "Status updated successfully.",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification success-notify level-error",
            };
            NotificationComponent(props);
            const { pageNumber, rowsPerPage, userId, today } = this.state;
            this.props.onPagination(pageNumber, rowsPerPage, userId, today);
          } else {
            var props = {
              message: resp.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification error-notify level-error",
            };
            NotificationComponent(props);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });

    } else {
      let data = {
        taskStatus: val,
        _id: item._id,
      };
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
        .post("/assign_task", data)
        .then((resp) => {
          if (resp.data.status === "200" || resp.data.status === 200) {
            var props = {
              message: "Status updated successfully.",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification success-notify level-error",
            };
            NotificationComponent(props);
            const { pageNumber, rowsPerPage, userId, today } = this.state;
            this.props.onPagination(pageNumber, rowsPerPage, userId, today);
          } else {
            var props = {
              message: resp.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification error-notify level-error",
            };
            NotificationComponent(props);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };


  loginAsUser = () => {
    let Data = { id: this.state.userId }
    localStorage.setItem('old_token', localStorage.getItem('token') ? localStorage.getItem('token') : "")
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .get(`/report/superadmin/createUserAccess/${this.state.userId}`)
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          // localStorage.clear();
          localStorage.setItem("userdata", JSON.stringify(response.data));
          localStorage.setItem("token", response.token.split(' ')[1]);
          const userData = JSON.parse(localStorage.getItem("userdata"))
          if (userData.role !== "Super Admin") {
            this.checkCmsData()
          } else {
            window.location.href = "/dashboard";
          }
          localStorage.setItem("switchedToSuperAdminFromUser", true)
          setTimeout(() => {
            window.location.href = "/"
          }, 1500)
        }
      })
      .catch(err => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  };
  checkCmsData = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/cms")
      .then((response) => {
        if (response.status === 200) {
          const cmsData = response.data.data[0] ? response.data.data[0] : {}
          localStorage.setItem("cms", JSON.stringify(cmsData))
        } else {
          console.log("Something went wrong")
        }
      })
  }
  resendInvitation = () => {
    let Data = { userId: this.state.userId }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/json")
      .post("/resend/invitation", Data)
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "201" || response.status === 201)) {
          var props = {
            message: "Invitation sent successfully",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 4,
            top: 200,
            className: "task_notification success-notify level-error",
          };
          NotificationComponent(props);
        } else {
          var props = {
            message: resp.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 4,
            top: 200,
            className: "task_notification error-notify level-error",
          };
          NotificationComponent(props);
          this.onCloseModal();
        }
      })
      .catch(err => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          var props = {
            message: "Internal server error",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 4,
            top: 200,
            className: "task_notification error-notify level-error",
          };
          NotificationComponent(props);
          this.onCloseModal();
        }
      });
  }
  handleGoBack = () =>{
    this.props.history.goBack();
  }
  render() {
    const {
      userRole,
      companyName,
      phoneNum,
      emailId,
      territory,
      role,
      address,
      userName,
      pageNumber,
      rowsPerPage,
      isAddress,
      isCompany,
      isEmail,
      isPhone,
      isRole,
      isTerritory,
      profilepath,
      isName,
      isImage,
      file,
      profilePic,
      loading,
      companySize,
      phoneNo,
      user_name,
    } = this.state;
    let classadmin = "";
    if (
      userRole ? userRole.toLowerCase() === "super admin" : "" ||
        userRole ? userRole.toLowerCase() === "admin" : ""
    ) {
      classadmin = "show-edit";
    } else {
      classadmin = "hide-edit";
    }
    let profileImg = "";
    if (profilePic === null) {
      profileImg = ProfileURL + profilepath;
    } else {
      profileImg = file;
    }
    if (profilepath !== "" && profilepath !== undefined) {
      profileImg = ProfileURL + profilepath;
    } else {
      profileImg = userIcon;
    }
    var options = [];
    if (userRole !== undefined &&
      (userRole ? userRole.toLowerCase() === "super admin" &&
        companySize !== "medium" &&
        companySize !== "small" : "")
    ) {
      options.push(
        // { key: "8", value: "super admin", name: "Super Admin" },
        { key: "2", value: "Admin", name: "Admin" },
        { key: "3", value: "Division Manager", name: "Division Leader" },
        { key: "4", value: "Area Manager", name: "Area Director" },
        { key: "5", value: "Team Lead", name: "Regional Manager" },
        { key: "6", value: "Territory Manager", name: "Territory Manager" },
        { key: "7", value: "Sales Rep", name: "Representative" }
      );
    } else if (
      userRole ? userRole.toLowerCase() === "super admin" : "" &&
        companySize ? companySize.toLowerCase() === "small" : ""
    ) {
      options.push(
        // { key: "8", value: "super admin", name: "Super Admin" },
        { key: "2", value: "Admin", name: "Admin" },
        { key: "3", value: "Team Lead", name: "Regional Manager" },
        { key: "4", value: "Territory Manager", name: "Territory Manager" },
        { key: "5", value: "Sales Rep", name: "Representative" }
      );
    } else if (
      userRole ? userRole.toLowerCase() === "super admin" : "" &&
        this.state.companySize ? this.state.companySize.toLowerCase() === "medium" : ""
    ) {
      options.push(
        // { key: "8", value: "super admin", name: "Super Admin" },
        { key: "2", value: "Admin", name: "Admin" },
        { key: "3", value: "Area Manager", name: "Area Director" },
        { key: "4", value: "Team Lead", name: "Regional Manager" },
        { key: "5", value: "Territory Manager", name: "Territory Manager" },
        { key: "6", value: "Sales Rep", name: "Representative" }
      );
    } else if (
      userRole ? userRole.toLowerCase() === "admin" : "" &&
        companySize ? companySize.toLowerCase() !== "medium" : "" &&
          companySize ? companySize.toLowerCase() !== "small" : ""
    ) {
      options.push(
        { key: "2", value: "Admin", name: "Admin" },
        { key: "3", value: "Division Manager", name: "Division Leader" },
        { key: "4", value: "Area Manager", name: "Area Director" },
        { key: "5", value: "Team Lead", name: "Regional Manager" },
        { key: "6", value: "Territory Manager", name: "Territory Manager" },
        { key: "7", value: "Sales Rep", name: "Representative" }
      );
    } else if (
      userRole ? userRole.toLowerCase() === "admin" : "" &&
        companySize ? companySize.toLowerCase() === "small" : ""
    ) {
      options.push(
        { key: "2", value: "Admin", name: "Admin" },
        { key: "3", value: "Team Lead", name: "Regional Manager" },
        { key: "4", value: "Territory Manager", name: "Territory Manager" },
        { key: "5", value: "Sales Rep", name: "Representative" }
      );
    } else if (
      userRole ? userRole.toLowerCase() === "admin" : "" &&
        this.state.companySize ? this.state.companySize.toLowerCase() === "medium" : ""
    ) {
      options.push(
        { key: "2", value: "Admin", name: "Admin" },
        { key: "3", value: "Area Manager", name: "Area Director" },
        { key: "4", value: "Team Lead", name: "Regional Manager" },
        { key: "5", value: "Territory Manager", name: "Territory Manager" },
        { key: "6", value: "Sales Rep", name: "Representative" }
      );
    }
    var statusOnePending = [
      { id: "1", value: "assigned", name: "Accept" },
      { id: "2", value: "rejected", name: "Reject" },
      { id: "3", value: "uncovered", name: "Uncovered" },

    ]
    var statusOne = [

      { id: "1", value: "cancelled", name: "Cancelled" },
      { id: "2", value: "uncovered", name: "Uncovered" },
    ];
    var statusTwo = [

      { id: "1", value: "cancelled", name: "Cancelled" },
      { id: "2", value: "uncovered", name: "Uncovered" },
      { id: "3", value: "inprogress", name: "In Progress" },
      { id: "4", value: "completed", name: "Completed" }
    ];
    var statusThree = [

      { id: "1", value: "completed", name: "Completed" },
    ];


    let EmployeeDetailsPage = (
      <div className="body-bg-color add_company_dialog">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        <Box component="div" className="company-comp account-detail">
          <ChangeLevelModal
            modelData={this.state}
            options={options}
            openModel={this.state.openModal}
            onClose={this.onCloseModal}
            onChangeHandler={this.onChangeHandler}
            onChangelevel={this.onChangelevel}
          />
          <div className="select_parent">
            <div className="breadCrumbs">
              <img className="bread-crumb-back" src={backIcon} alt="back" />
              <Breadcrumbs className="bread-crumb-main" aria-label="breadcrumb">
                <Link className="bread-crumb-link" color="inherit" onClick={this.handleGoBack}>
                  Users
                </Link>
                <Typography className="bread-crumb-name">
                  User Details
                </Typography>
              </Breadcrumbs>
            </div>
            <div className="user-detail-wrapper">
              <div className="user-detail-header">
                {isImage && (
                  <>
                    <img src={profileImg} className="user-profile-img"></img>
                    <img
                      className={classadmin}
                      className={`${classadmin} profile_photo_edit`}
                      src={profileeditIcon}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.editInput("isImage")}
                    />
                  </>
                )}
                {!isImage && (
                  <>
                    <div className="profile_upload">
                      <div>
                        <div className="input_two">
                          Upload logo file in jpg, png, svg format
                        </div>
                        <input
                          className="input_one"
                          type="file"
                          name="profilePic"
                          onChange={this.onFileuploadHandler}
                          onFocus={this.onFileInputFocus}
                        />
                      </div>

                      {/* <Button
                      className="suc-button primary-btn disabled-btn"
                      variant="contained"
                      onClick={() => this.updateHandler("isImage")}
                      disabled={
                        this.state.formError.profilePic !== "" ||
                        this.state.profilePic === null
                          ? true
                          : false
                      }
                    >
                      Upload
                    </Button>
                    <Button
                      className="level_cancel fail-button"
                      variant="contained"
                      onClick={() => {
                        const { formError } = this.state;
                        formError.profilePic = "";
                        this.setState({
                          isImage: !this.state.isImage,
                          formError,
                        });
                      }}
                    >
                      X
                    </Button> */}
                      <span className="edit-input-header edit-profile-buttons">
                        <Button
                          className="add_more_btn"
                          onClick={() => this.updateHandler("isImage")}
                          disabled={
                            this.state.profileClicked ? true : false
                          }
                        >
                          Upload
                        </Button>
                        <img
                          src={close_icon_red}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const { formError } = this.state;
                            formError.profilePic = "";
                            this.setState({
                              isImage: !this.state.isImage,
                              formError,
                              profileClicked: true
                            });
                          }}
                        />
                      </span>
                    </div>
                  </>
                )}{" "}
                {isName && (
                  <>
                    <Typography
                      variant="h5"
                      className="tableTitle"
                      component="div"
                    >
                      {user_name}
                    </Typography>
                    <img
                      className={classadmin}
                      src={editIcon}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={() => this.editInput("isName")}
                    />
                  </>
                )}
                {!isName && (
                  <span className="edit-input-header">
                    <CssTextField
                      id="outlined-basic"
                      name="userName"
                      variant="outlined"
                      type="text"
                      placeholder="Enter user name"
                      defaultValue={user_name}
                      onChange={this.onChangeHandler}
                      error={this.state.formError.userName !== "" ? true : false}
                      helperText={
                        this.state.formError.userName
                          ? this.state.formError.userName
                          : ""
                      }
                    />
                    <Button
                      className="add_more_btn"
                      onClick={() => this.updateHandler("isName")}
                      disabled={
                        this.state.formError.userName !== "" ? true : false
                      }
                    >
                      Save
                    </Button>
                    <img
                      src={close_icon_red}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.editInput("isName")}
                    />
                  </span>
                )}{" "}
              </div>
              {userRole.toLowerCase() === "super admin" && this.state.User_Status !== "Registered" ?

                <Button
                  disabled={this.state.User_Status === "Activated" ? false : true}
                  onClick={this.loginAsUser}
                  className="switch_login_btn"
                  style={{ cursor: "pointer" }}
                >
                  Login as {user_name !== undefined ? user_name : ""}
                </Button>
                : (userRole.toLowerCase() === "super admin" || userRole.toLowerCase() === "admin") && this.state.User_Status === "Registered" ?
                  <Button
                    disabled={false}
                    onClick={this.resendInvitation}
                    className="switch_login_btn"
                    style={{ cursor: "pointer", backgroundColor: "rgba(48,57,96,.1607843137254902)" }}
                  >
                    Resend Invitation
                  </Button>
                  : ""}
            </div>
            <div className="modal-err-msg">
              {this.state.formError.profilePic
                ? this.state.formError.profilePic
                : ""}
            </div>
          </div>

          {loading ? (
            <div className="account-detail-container user-detail-container">
              <Grid spacing={2} container>
                <Grid item xs={12} md={8} className="accordion-load">
                  <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={75}
                    width={75}
                    visible={loading}
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="account-detail-container user-detail-container">
              <Grid spacing={2} container>
                <Grid
                  item
                  xs={12}
                  md={role.toLowerCase() === "sales rep" || role.toLowerCase() === "team lead" || role.toLowerCase() === "territory manager" ? 4 : 6}
                >
                  <Accordion
                    className="account-expansion"
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={<img src={ExpandMoreIcon}></img>}
                      style={{ borderBottom: "1px solid #0000000F" }}
                    >
                      <Typography
                        className="account-expansion-text"
                        variant="h6"
                        component="span"
                      >
                        User details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="info-card">
                        {role.toLowerCase() !== "super admin" ? (
                          <>
                            <p className="info-card-sub-name">Company :</p>
                            <div className="info-card-detail">
                              <div className="info-card-detail-left">
                                <img src={CompanyIcon} />
                                {isCompany && (
                                  <span className="custom-span">
                                    {companyName}
                                  </span>
                                )}
                                {!isCompany && (
                                  <span className="edit-input">
                                    <CssTextField
                                      id="outlined-basic"
                                      name="companyName"
                                      variant="outlined"
                                      type="text"
                                      placeholder="Enter company name"
                                      defaultValue={companyName}
                                      onChange={this.onChangeHandler}
                                    />
                                    <Button
                                      className="add_more_btn"
                                      onClick={() =>
                                        this.updateHandler("isCompany")
                                      }
                                    >
                                      Save
                                    </Button>
                                  </span>
                                )}{" "}
                              </div>
                              {/* <img
                          className={classadmin}
                          src={editIcon}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.editInput("isCompany")}
                        /> */}
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <p className="info-card-sub-name">Role :</p>
                        <div className="info-card-detail">
                          <div className="info-card-detail-left">
                            <img src={ContactMailIcon} />
                            {isRole && (
                              // <span className="custom-span">
                              //   {role.charAt(0).toUpperCase() + role.slice(1)}
                              // </span>

                              <span className="custom-span">
                                {role.toLowerCase() === "super admin" ? (
                                  <>
                                    {" "}
                                    <span>
                                      {role
                                        ? "Super Admin"
                                        : "-"}
                                    </span>
                                  </>
                                ) : role.toLowerCase() === "admin" ? (
                                  <>
                                    {" "}
                                    <span >
                                      {role
                                        ? (this.state.roleLabelChange.Admin ? this.state.roleLabelChange.Admin : "Admin")
                                        : "-"}
                                    </span>
                                  </>
                                ) : role.toLowerCase() === "division manager" ? (
                                  <>
                                    {" "}
                                    <span >
                                      {role
                                        ? (this.state.roleLabelChange.DivisionLeader ? this.state.roleLabelChange.DivisionLeader : "Division Leader")
                                        : "-"}
                                    </span>
                                  </>
                                ) : role.toLowerCase() === "territory manager" ? (
                                  <>
                                    {" "}
                                    <span>
                                      {role
                                        ? (this.state.roleLabelChange.TerritoryManager ? this.state.roleLabelChange.TerritoryManager : "Territory Manager")
                                        : "-"}
                                    </span>
                                  </>

                                ) : role.toLowerCase() === "sales rep" ? (
                                  <>
                                    {" "}
                                    <span>
                                      {role
                                        ? (this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative")
                                        : "-"}
                                    </span>
                                  </>

                                ) : role.toLowerCase() === "area manager" ? (
                                  <>
                                    {" "}
                                    <span>
                                      {role
                                        ? (this.state.roleLabelChange.AreaDirector ? this.state.roleLabelChange.AreaDirector : "Area Director")
                                        : "-"}
                                    </span>
                                  </>
                                ) : role.toLowerCase() === "team lead" ? (
                                  <>
                                    {" "}
                                    <span >
                                      {role
                                        ? (this.state.roleLabelChange.RegionalManager ? this.state.roleLabelChange.RegionalManager : "Regional Manager")
                                        : "-"}
                                    </span>
                                  </>
                                ) : ("")}

                              </span>
                            )}
                          </div>
                          <img
                            className={classadmin}
                            src={editIcon}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isRole")}
                          />
                        </div>

                        <p className="info-card-sub-name">Phone :</p>
                        <div className="info-card-detail">
                          <div className="info-card-detail-left">
                            <img src={iconQuickPhone} />
                            {isPhone && (
                              <span className="custom-span">{phoneNo ? phoneNo : "--"}</span>
                            )}
                            {!isPhone && (
                              <span
                                className="edit-input"
                                style={{ alignItems: "center" }}
                              >
                                <CssTextField
                                  id="outlined-basic"
                                  name="phoneNum"
                                  variant="outlined"
                                  type="text"
                                  placeholder="Enter phone number"
                                  defaultValue={phoneNo}
                                  onChange={this.onChangeHandler}
                                  error={
                                    this.state.formError.phoneNo !== ""
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    this.state.formError.phoneNo
                                      ? this.state.formError.phoneNo
                                      : ""
                                  }
                                />

                                <Button
                                  className="add_more_btn"
                                  onClick={() => this.updateHandler("isPhone")}
                                  disabled={
                                    this.state.formError.phoneNo !== ""
                                      ? true
                                      : false
                                  }
                                >
                                  Save
                                </Button>
                                <img
                                  src={close_icon_red}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.editInput("isPhone")}
                                />
                              </span>
                            )}{" "}
                          </div>
                          {isPhone && (
                            <img
                              className={classadmin}
                              src={editIcon}
                              style={{ cursor: "pointer" }}
                              onClick={() => this.editInput("isPhone")}
                            />
                          )}
                        </div>

                        <p className="info-card-sub-name">Email :</p>
                        <div className="info-card-detail info-card-url">
                          <div className="info-card-detail-left info-email-detail-left">
                            <img src={emailIcon} className="info-card-img-align-start info-card-img-website" />
                            {isEmail && (
                              <span className="custom-span">{emailId}</span>
                            )}
                            {!isEmail && (
                              <span className="edit-input">
                                <CssTextField
                                  id="outlined-basic"
                                  name="emailId"
                                  variant="outlined"
                                  type="text"
                                  placeholder="Enter email address"
                                  defaultValue={emailId}
                                  onChange={this.onChangeHandler}
                                />
                                <Button
                                  className="add_more_btn"
                                  onClick={() => this.updateHandler("isEmail")}
                                >
                                  Save
                                </Button>
                              </span>
                            )}{" "}
                          </div>

                          {(userRole.toLowerCase() === "super admin" || userRole.toLowerCase() === "admin") && (this.state.User_Status === "Registered" || this.state.User_Status === "Activated") &&
                            (isEmail ?
                              <img
                                src={editIcon}
                                style={{ cursor: "pointer", display: "block" }}
                                onClick={() => this.setState({
                                  isEmail: false
                                })}
                                alt="edit"
                              /> :
                              <img
                                src={close_icon_red}
                                alt="correct_icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.setState({
                                  isEmail: true
                                })}
                              />)
                          }
                        </div>
                        {role.toLowerCase() !== "admin" &&
                          role.toLowerCase() !== "super admin" ? (
                          <>
                            <p className="info-card-sub-name">Team :</p>
                            <div className="info-card-detail">
                              <div className="info-card-detail-left">
                                <img src={TerrainIcon} />
                                {isTerritory && (
                                  <span className="custom-span">
                                    {territory}
                                  </span>
                                )}
                                {!isTerritory && (
                                  <span className="edit-input">
                                    <CssTextField
                                      id="outlined-basic"
                                      name="territory"
                                      variant="outlined"
                                      type="text"
                                      placeholder="Enter territory"
                                      defaultValue={territory}
                                      onChange={this.onChangeHandler}
                                    />
                                    <Button
                                      className="add_more_btn"
                                      onClick={() =>
                                        this.updateHandler("isTerritory")
                                      }
                                    >
                                      Save
                                    </Button>
                                  </span>
                                )}{" "}
                              </div>

                              <img
                                className={classadmin}
                                src={editIcon}
                                style={{ cursor: "pointer" }}
                                onClick={() => this.editInput("isTerritory")}
                              />
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {/* <p className="info-card-sub-name">Address :</p>
                      <div className="info-card-detail info-card-detail-last">
                        <div className="info-card-detail-left">
                          <img src={iconLocation} />
                          {isAddress && (
                            <span className="custom-span">{address !== '' ? address : 'NA'}</span>
                          )}
                          {!isAddress && (
                            <span className="edit-input">
                              <CssTextField
                                id="outlined-basic"
                                name="address"
                                variant="outlined"
                                type="text"
                                placeholder="Enter address"
                                defaultValue={address}
                                onChange={this.onChangeHandler}
                              />
                              <Button
                                className="add_more_btn"
                                onClick={() => this.updateHandler("isAddress")}
                              >
                                Save
                              </Button>
                            </span>
                          )}{" "}
                        </div>
                        {isAddress && (
                        <img
                          className={classadmin}
                          src={editIcon}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.editInput("isAddress")}
                        />
                        )}
                        {!isAddress && (
                        <img
                        src={close_icon_red}
                        style={{ cursor: "pointer"}}
                        onClick={() => this.editInput("isAddress")}
                      />
                        )}
                      </div> */}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {role.toLowerCase() === "sales rep" || role.toLowerCase() === "team lead" || role.toLowerCase() === "territory manager" ? (
                  <Grid item xs={12} md={8}>
                    <Accordion
                      className="account-expansion upcoming-task"
                      expanded={true}
                    >
                      <AccordionSummary
                        expandIcon={<img src={ExpandMoreIcon}></img>}
                        style={{ borderBottom: "1px solid #0000000F" }}
                      >
                        <Typography
                          className="account-expansion-text"
                          variant="h6"
                          component="span"
                        >
                          Upcoming Tasks
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="Acc-Table">
                          <div className="table_container">
                            <TableContainer>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>NO</TableCell>
                                    <TableCell >TASKS</TableCell>
                                    <TableCell style={{ width: "25%" }}>
                                      DATE
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "20%",
                                        textAlign: "center",
                                      }}
                                    >
                                      ACTIONS
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {!this.props.pageOfItems ||
                                    this.props.pageOfItems.length === 0 ? (
                                    <StyledTableRow>
                                      <TableCell
                                        colSpan={9}
                                        style={{ textAlign: "center" }}
                                      >
                                        No data found
                                      </TableCell>
                                    </StyledTableRow>
                                  ) : (
                                    (rowsPerPage > 0
                                      ? this.props.pageOfItems.slice(
                                        this.state.page * rowsPerPage,
                                        this.state.page * rowsPerPage + rowsPerPage
                                      )
                                      : this.props.pageOfItems
                                    ).map((row, index) => (
                                      <StyledTableRow key={row.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.taskName} {row.taskStatus === "assigned" && row.recurringStatus === "AutoGenerated" && <img className="recurring_assigned" src={recurring_assigned} alt="img" />}</TableCell>
                                        <TableCell className="date-color">
                                          {momentTimeZone(row.start || row.startDate)
                                            .tz(this.state.timeZoneName)
                                            .format("MM-DD-YYYY hh:mm A")}
                                        </TableCell>
                                        <TableCell>
                                          <Dropdown
                                            overlay={
                                              <Menu>
                                                {row.taskStatus ?
                                                  <>
                                                    {row.taskStatus.toLowerCase() === "overdue" ||
                                                      row.taskStatus.toLowerCase() === "assigned"
                                                      ? statusTwo.map((sts) => (
                                                        <Menu.Item {...this.props} key={sts.id} onClick={() =>
                                                          this.onclickStatus(
                                                            sts.value,
                                                            row
                                                          )
                                                        }>{sts.name}</Menu.Item>
                                                      )) :
                                                      row.taskStatus.toLowerCase() === "inprogress" ?
                                                        statusThree.map((sts) => (
                                                          <Menu.Item {...this.props} key={sts.id} onClick={() =>
                                                            this.onclickStatus(
                                                              sts?.value,
                                                              row
                                                            )
                                                          }>{sts.name}</Menu.Item>
                                                        )) :
                                                        row.taskStatus.toLowerCase() === "unassigned" ||
                                                          row.taskStatus.toLowerCase() === "pending"
                                                          ?
                                                          row.taskStatus.toLowerCase() === (this.state.taskAssignee ? this.state.taskAssignee.toLowerCase() : "") && this.state.taskStatus.toLowerCase() === 'pending' ?
                                                            statusOnePending.map(sts => (
                                                              <Menu.Item {...this.props} key={sts.id} onClick={() =>
                                                                this.onclickStatus(
                                                                  sts.value,
                                                                  row
                                                                )
                                                              }>{sts.name}</Menu.Item>
                                                            )) :
                                                            statusOne.map((sts) => (
                                                              <Menu.Item {...this.props} key={sts.id} onClick={() =>
                                                                this.onclickStatus(
                                                                  sts.value,
                                                                  row
                                                                )
                                                              }>{sts.name}</Menu.Item>
                                                            )) : ""}

                                                    {/* {statusUpdate.map((sts) => (
                                                  <Menu.Item
                                                    key={sts.id}
                                                    onClick={() =>
                                                      this.onclickStatus(
                                                        sts.value,
                                                        row._id
                                                      )
                                                    }
                                                  >
                                                    {sts.name}
                                                  </Menu.Item>
                                               ))} */}
                                                  </>
                                                  : ""}
                                              </Menu>
                                            }
                                            placement="bottomCenter"
                                            arrow
                                          >
                                            <p
                                              className={`update_status_btn_user`}
                                            >
                                              Update
                                            </p>
                                          </Dropdown>
                                        </TableCell>
                                      </StyledTableRow>
                                    ))
                                  )}
                                </TableBody>
                                <TableFooter>
                                  {/* <TableRow>
                                    <TablePagination
                                      rowsPerPageOptions={[5, 10, 25]}
                                      colSpan={9}
                                      count={
                                        !this.props.pager
                                          ? 0
                                          : this.props.pager.totalItems
                                      }
                                      rowsPerPage={
                                        rowsPerPage !== "" ||
                                        rowsPerPage !== undefined
                                          ? rowsPerPage
                                          : 5
                                      }
                                      page={
                                        !this.props.pager
                                          ? 0
                                          : this.props.pager.currentPage - 1
                                      }
                                      labelDisplayedRows={({
                                        from,
                                        to,
                                        count,
                                      }) =>
                                        `Showing ${!to ? 0 : to} of ${
                                          count === undefined ? 0 : count
                                        } entries`
                                      }
                                      SelectProps={{
                                        inputProps: {
                                          "aria-label": "rows per page",
                                        },
                                      }}
                                      onChangePage={this.handleChangePage}
                                      onChangeRowsPerPage={
                                        this.handleChangeRowsPerPage
                                      }
                                      // ActionsComponent={TablePaginationActions}
                                    />
                                  </TableRow> */}
                                  {this.props.pageOfItems &&
                                    this.props.pageOfItems.length !== 0 ?
                                    <TableRow>
                                      <td colSpan={20} className="pagination_container">
                                        <CustomPaginationComponent
                                          count={this.props.pager.endPage}
                                          rowsPerPage={this.state.rowsPerPage}
                                          page={this.state.pageNumber}
                                          onPageChange={this.handleCustomPaginagtionChange}
                                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                      </td>
                                    </TableRow>
                                    : null}
                                </TableFooter>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          )}
        </Box>
      </div>
    );

    return <div>{EmployeeDetailsPage}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    pager: state.taskpagination.pager,
    pageOfItems: state.taskpagination.pageOfItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole, userId, today) =>
      dispatch(action.userTaskpagination(pageNo, pagerole, userId, today)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmployeeDetailsComponent));
