import React from "react";
import {
    Button,
    Grid,
  } from "@material-ui/core";
import plan_image from "../../../Assets/images/plan_image.svg";
import momentTimeZone from "moment-timezone";
import {startCase} from 'lodash';
const ManageSubscriptionComponent = (props) => {
  return (
    <div className="billing_page manage_subscriptions">
      <h3 className="billing_page_title">Manage Subscriptions</h3>
      <div className="billing_overview_sections">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div className="manage_subscriptions_section">
              <h4 className="active_subscription_heading">
                Active subscriptions
              </h4>
              <div className="subscription_plan_container">
                <div className="subscription_plan_heading_section">
                  <img src={plan_image} alt="logo" />
                  <h5>SalesC2 {props.estimateData.plan ? startCase(props.estimateData.plan) : "-"}</h5>
                </div>
                <div className="subscription_plan_detail_section">
                  <div>
                    <h5>Users</h5>
                    <h6>{props.estimateData.usersCount?props.estimateData.usersCount:0} Users</h6>
                  </div>
                  <div>
                    <h5>Next billing date</h5>
                    <h6> {props.estimateData.endDate
                  ? momentTimeZone(props.estimateData.endDate)
                      .tz(props.timeZoneName)
                      .format("MMM DD, YYYY")
                  : "-"}</h6>
                  </div>
                  <div>
                    <h5>Price estimate</h5>
                    <h6>${" "}{props.estimateData.estimatedAmt
                      ? parseFloat(props.estimateData.estimatedAmt).toFixed(2)
                      : 0.0}</h6>
                  </div>
                </div>
                <div className="subscription_plan_actions">
                  <div className="subscription_plan_change">
                    {/* <h5>{props.estimateData.plan ? startCase(props.estimateData.plan) : "-"}</h5>
                    <p onClick={props.onChangePlan}>Change</p> */}
                  </div>
                  <Button className="manage-btn grey-btn" color="default" onClick={props.onManageUser}>
                    Manage Users
                  </Button>
                </div>
              </div>
              <div>
                <p className="subscription_plan_cancel" onClick={props.cancelSubscription}>
                Cancel Subscription
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="billing_overview_section billing_estimate">
              <h5 className="overview_section_heading">Billing Estimate</h5>
              <span className="billing_estimate_date">
                {props.estimateData.startDate
                  ? momentTimeZone(props.estimateData.startDate)
                      .tz(props.timeZoneName)
                      .format("MMM DD, YYYY")
                  : "-"}
                &nbsp;-&nbsp;
                {props.estimateData.endDate
                  ? momentTimeZone(props.estimateData.endDate)
                      .tz(props.timeZoneName)
                      .format("MMM DD, YYYY")
                  : "-"}
              </span>
              <div className="billing_estimate_price_secion">
                <div className="billing_estimate_price_plans_main">
                  <div className="billing_estimate_price_plans">
                    <p className="billing_estimate_price_plan">
                      {props.estimateData.plan ? startCase(props.estimateData.plan) : "-"}
                    </p>
                    <p className="billing_estimate_price_plan_amount">
                      ${" "}
                      {props.estimateData.planCharge
                        ? parseFloat(props.estimateData.planCharge).toFixed(2)
                        : 0.0}
                    </p>
                  </div>
                  <span className="billing_estimate_price_plan_duration">
                  {props.estimateData.usersCount?props.estimateData.usersCount:0} User / {props.estimateData.billingCycle === 0
                      ? "Monthly"
                      : "Yearly"}
                  </span>
                </div>
                <div className="billing_estimate_price_tax ">
                  <p>Exclusive of Taxes</p>
                  <p>
                    ${" "}{props.estimateData.estimatedAmt
                      ? parseFloat(props.estimateData.estimatedAmt).toFixed(2)
                      : 0.0}
                  </p>
                </div>
                {/* <div className="billing_estimate_price_tax billing_estimate_amount">
                  <p>TAX ({props.estimateData.taxPercent?parseFloat(props.estimateData.taxPercent):0}%)</p>
                  <p>
                    ${" "}
                    {props.estimateData.taxAmount
                      ? parseFloat(props.estimateData.taxAmount).toFixed(2)
                      : 0.0}
                  </p>
                </div> */}

                <div className="billing_estimate_price_total">
                  <p>TOTAL</p>
                  <p className="billing_estimate_price_total_amount">
                    USD{" "}
                    {props.estimateData.estimatedAmt
                      ? parseFloat(props.estimateData.estimatedAmt).toFixed(2)
                      : 0.0}
                  </p>
                </div>
              </div>
               <Button
                className="view_full_estimate_btn logout-button"
                onClick={props.onViewEstimate}
              >
                View full estimate
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ManageSubscriptionComponent;
