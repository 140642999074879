import React, { Component, Suspense } from "react";
import "date-fns";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Menu, Dropdown, notification } from "antd";
import jwt from "jsonwebtoken";
import moment from "moment";
import "moment/locale/en-nz";
import qs from "qs";
import momentTimeZone from "moment-timezone";
import validator from "validator";
import iconClose from "../../../Assets/images/close_btn.svg";
import { Autocomplete } from "@material-ui/lab";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  Grid,
  TextField,
  Select,
  IconButton,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  withStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import backIcon from "../../../Assets/images/breadcrumb_back.svg";
import { AxiosInstance, checkCmsLabel } from "../../../Utilities";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationSuccess from "../../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import recurring_assigned from "../../../Assets/images/recurring_assigned.svg";
import recurring_pending from "../../../Assets/images/recurring_pending.svg";
import recurring_inprogress from "../../../Assets/images/recurring_inprogress.svg";
import recurring_completed from "../../../Assets/images/recurring_completed.svg";
import recurring_uncovered from "../../../Assets/images/recurring_uncovered.svg";
import recurring_unassigned from "../../../Assets/images/recurring_unassigned.svg";
import recurring_cancelled from "../../../Assets/images/recurring_cancelled.svg";
import recurring_task from "../../../Assets/images/recurring_acceptance.svg";
import { startCase } from "lodash";
import userIcon from "../../../Assets/images/user-icon.png";
import userBusy from "../../../Assets/images/user-busy.svg";
import taskWarningIcon from "../../../Assets/images/task_warning_icon.svg";
import HeaderComponent from "../../../SharedComponents/HeaderComponent/HeaderComponent";
import {
  Transition,
  TransitionGrow,
} from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import emergency from "../../../Assets/images/Group 757.svg";
import warning_img from "../../../Assets/images/Icon ionic-ios-warning-yellow.svg";
import { isSafari } from "react-device-detect";
import { AWS_AxiosInstance } from "../../../AWS_AxiosInstance";
import AdditionalRepresentativeComponent from "./TabComponent/AdditionalRepresentative/AdditionalRepresentativeComponent";
import { data } from "jquery";
import RecurringScheduleComponent from "./RecurringScheduleComponent/RecurringScheduleComponent";
import { ContactsOutlined } from "@material-ui/icons";
import PatientInfoComponent from "./PatientInfoComponent/PatientInfoComponent";
var ActualDurationComponent = React.lazy(() =>
  import("./DurationComponent/ActualDurationComponent")
);
var ScheduledConfirmationModel = React.lazy(() =>
  import(
    "../../../SharedComponents/ScheduledConfirmationModel/ScheduledConfirmationModel"
  )
);
var DurationComponent = React.lazy(() =>
  import("./DurationComponent/DurationComponent")
);
var ReassignRepRecurringComponent = React.lazy(() =>
  import("./RecurringComponent/ReassignRepRecurringComponent")
);
var CancelTaskModalComponent = React.lazy(() =>
  import("./RecurringComponent/CancelTaskModalComponent")
);
var StepperComponent = React.lazy(() =>
  import("./StepperComponent/StepperComponent")
);
var DescriptionComponent = React.lazy(() =>
  import("./DescriptionComponent/DescriptionComponent")
);
var ServiceComponent = React.lazy(() =>
  import("./ServiceComponent/ServiceComponent")
);
var TabComponent = React.lazy(() => import("./TabComponent/TabComponent"));
var TimelineComponent = React.lazy(() =>
  import("./TimelineComponent/TimelineComponent")
);
var AccountInfoComponent = React.lazy(() =>
  import("./AccountInfoComponent/AccountInfoComponent")
);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
const CssAutoTextfield = withStyles({
  root: {
    borderWidth: 0,
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 0,
      },
    },
  },
})(TextField);
const CssAutocomplete = withStyles({
  root: {
    width: "100%",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: "0 !important",
      },
    },
  },
})(Autocomplete);
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    overflow: "initial",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#21BF73",
        border: `1px solid #21BF73`,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
let cancelOption = [
  {
    id: "one",
    text: "",
  },
];

class TaskDetailsComponent extends Component {
  constructor(props) {
    super(props);
    var zoneName = momentTimeZone.tz.guess();
    let userRole = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata")).role
      : "";
    var userdatas = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    let accDta = window.location.href;
    const [baseUrl, userId] = accDta.split("/task/");

    this.state = {
      cmsLabelChange: [],
      timelineCancelReason: "",
      cancelValue: "",
      customCancelValue: false,
      newStartDate: "",
      newEndDate: "",
      recurringText: "",
      additionalReps: [],
      selectedAdditionalReps: "",
      taskattendeesDefault: "",
      show: false,
      newProduct: false,
      timeZoneName: localStorage.getItem("timeZone")
        ? localStorage.getItem("timeZone")
        : zoneName,
      userRole: userRole.toLowerCase(),
      userdatas: userdatas,
      userId: userId,
      service: [
        {
          _id: "",
          name: "",
        },
      ],
      account: [
        {
          _id: "",
          name: "",
        },
      ],
      department: [
        {
          _id: "",
          name: "",
        },
      ],
      productOptions: [
        {
          id: "",
          name: "",
        },
      ],
      productFilter: [],
      assignToOption: [],
      accountInfo: [],
      products: [],
      taskSchedules: "",
      isRecurring: false,
      expenses: [],
      timelines: [],
      taskId: "",
      taskAssine: "",
      taskAssigneeValue: [],
      additionalRepData: [],
      taskAssignee: "",
      taskAssigneeId: "",
      taskStatus: "",
      tooltipprops: {},
      profile_path: "",
      repTaskStatus: "",
      taskDescription: "",
      taskDescriptionError: "",
      isEmergency: "",
      newDesc: "",
      taskName: "",
      conflict: false,
      conflictName: "",
      serviceAutoComplete: {
        autocompletedProps: {
          className: "task_filter",
          id: "task_filter",
        },
        textFieldProps: {
          variant: "outlined",
          name: "serviceName",
          placeholder: "Select value",
        },
      },
      accountAutoComplete: {
        autocompletedProps: {
          className: "task_filter",
          id: "task_filter",
        },
        textFieldProps: {
          variant: "outlined",
          name: "accountName",
          placeholder: "Select value",
        },
      },
      deptAutoComplete: {
        autocompletedProps: {
          className: "task_filter",
          id: "task_filter",
        },
        textFieldProps: {
          variant: "outlined",
          name: "departmentName",
          placeholder: "Select value",
        },
      },
      asignToAutoComplete: {
        autocompletedProps: {
          className: "task_filter",
          id: "task_filter",
        },
        textFieldProps: {
          variant: "outlined",
          name: "assignToName",
          placeholder: "Select",
        },
      },
      department_contact: [],
      address: "",
      coordinates: [],
      serviceName: "",
      serviceId: "",
      serviceNameDefault: "",
      accountName: "",
      accountId: "",
      accountNameDefault: "",
      taskMessage: "",
      noteError: "",
      isStartDateEdits: false,
      recurringEndDate: "",
      startDateDialog: false,
      startDateDialogs: false,
      departmentName: "",
      departmentId: "",
      departmentNameDefault: "",
      addNote: "",
      addNoteTab: "",
      addNoteError: "",
      addNoteTabError: "",
      DatesizeIncrease: "",
      DatesizeIncreases: "",
      activeTab: "1",
      isDesc: true,
      deletetaskId: "",
      deleteproductId: "",
      showaddModal: false,
      isStartDate: true,
      isStartDates: true,
      isEndDate: true,
      isEndDates: true,
      isRecurringEndDate: true,
      isService: true,
      isAccount: true,
      isDepartment: true,
      isAssignee: false,
      changeAccountDialog: false,
      onEditDialogOpen: false,
      productEditItem: null,
      productName: "",
      productId: "",
      prodrowId: "",
      price: "",
      quantity: "",
      priceNew: "",
      quantityNew: "",
      isTrue: false,
      isProducts: false,
      selectedStatus: "",
      reassignTaskOpen: false,
      addRepTaskOpen: false,
      removeRepTaskOpen: false,
      cancelTaskOpen: false,
      reschdeuleTaskOpen: false,
      updateStatusDialogMenu: false,
      onAddExpenseDialogOpen: false,
      anchorEl: null,
      onAddDialogOpen: false,
      priority: "",
      expenseAmount: "",
      description: "",
      taskRepDetail: null,
      showdeleteReps: false,
      popupOpen: false,
      isSubmit: false,
      indexid: "",
      addExpenseError: {
        expenseAmount: "",
        description: "",
      },
      addProductError: {
        priceNew: "",
        quantityNew: "",
        productNew: "",
      },
      editProductError: {
        price: "",
        quantity: "",
        productNew: "",
      },
      assignToName: "",
      newAssigneeId: "",
      newAssigneeName: "",
      departmentClear: false,
      onCancelTaskDialogOpen: false,
      departments: [
        {
          _id: "",
          name: "",
        },
      ],
      eventDetail: null,

      startDateError: "",
      startDateErrors: "",

      endDateError: "",
      endDateErrors: "",

      dateRangeProps: {
        startDate: {
          initialViewDate: moment.tz(
            momentTimeZone().format("MM-DD-YYYY HH:mm A"),
            localStorage.getItem("timeZone")
              ? localStorage.getItem("timeZone")
              : zoneName
          ),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate: moment.tz(
            momentTimeZone().format("MM-DD-YYYY HH:mm A"),
            localStorage.getItem("timeZone")
              ? localStorage.getItem("timeZone")
              : zoneName
          ),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control",
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        time: "",
      },
      dateRangePropsNew: {
        startDate: {
          initialViewDate: momentTimeZone(new Date().setMinutes(0))
            .tz(
              localStorage.getItem("timeZone")
                ? localStorage.getItem("timeZone")
                : zoneName
            )
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            value: "",
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate: momentTimeZone(new Date().setMinutes(0))
            .tz(
              localStorage.getItem("timeZone")
                ? localStorage.getItem("timeZone")
                : zoneName
            )
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control",
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDateNew: {
          initialViewDate: momentTimeZone(new Date().setMinutes(0))
            .tz(
              localStorage.getItem("timeZone")
                ? localStorage.getItem("timeZone")
                : zoneName
            )
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: false,
            value: "",
            className: "endDate form-control",
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        time: "",
      },
      actualdateRangeProps: {
        startDate: {
          initialViewDate: moment.tz(
            momentTimeZone().format("MM-DD-YYYY HH:mm A"),
            localStorage.getItem("timeZone")
              ? localStorage.getItem("timeZone")
              : zoneName
          ),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate: moment.tz(
            momentTimeZone().format("MM-DD-YYYY HH:mm A"),
            localStorage.getItem("timeZone")
              ? localStorage.getItem("timeZone")
              : zoneName
          ),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control",
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        time: "",
      },
      actualdateRangePropsNew: {
        startDate: {
          initialViewDate: momentTimeZone(new Date().setMinutes(0))
            .tz(
              localStorage.getItem("timeZone")
                ? localStorage.getItem("timeZone")
                : zoneName
            )
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            value: "",
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate: momentTimeZone(new Date().setMinutes(0))
            .tz(
              localStorage.getItem("timeZone")
                ? localStorage.getItem("timeZone")
                : zoneName
            )
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control",
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDateNew: {
          initialViewDate: momentTimeZone(new Date().setMinutes(0))
            .tz(
              localStorage.getItem("timeZone")
                ? localStorage.getItem("timeZone")
                : zoneName
            )
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: false,
            value: "",
            className: "endDate form-control",
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        time: "",
      },
      editEndDates: false,
      tooltipprops: {},
      isRecurring: false,
      recurringTaskId: "",
      dateId: "",
      cancelledTaskNote: "",
      patientName: "",
      caseNumber: "",
      newPatientName: "",
      newCaseNumber: "",
      isPatientName: true,
      isCaseNumber: true,
      revertStatus: "",
      revertModal: false,
      onRevertDialog: false,
      priceEnable: false,
      quantityEnable: false,
    };

    this.editInput = this.editInput.bind(this);
    this.editInputs = this.editInputs.bind(this);
    this.updateHandler = this.updateHandler.bind(this);
    this.clearFieldValueHandler = this.clearFieldValueHandler.bind(this);
    this.clearDepartmentHandler = this.clearDepartmentHandler.bind(this);
    this.updateActualHandler = this.updateActualHandler.bind(this);
  }
  /**
   * @author Benakesh
   * TO SET INITIAL STATE ON PAGE LOAD
   */
  UNSAFE_componentWillMount() {
    let obj = {};
    if (isSafari) {
      obj.open = false;
      this.setState({ tooltipprops: obj });
    }
  }
  componentDidMount = () => {
    const cmsDetail = localStorage.getItem("cms");
    if (cmsDetail) {
      const labelChange = JSON.parse(cmsDetail).labelChange;
      if (labelChange && labelChange.label) {
        labelChange.label.map((item) => {
          if (item.value == "price") {
            this.setState({
              priceEnable: item.checked,
            });
          }
          if (item.value == "quantity") {
            this.setState({
              quantityEnable: item.checked,
            });
          }
        });
      }
    }
    const fetchCmsData = checkCmsLabel();
    if (fetchCmsData) {
      this.setState({
        cmsLabelChange: fetchCmsData.cmsData,
      });
    }
    window.scrollTo(0, 0);
    var { id } = this.props.match.params;
    this.getRepresentativeFilter();
    var dateId = "";
    var searchString = qs.parse(this.props.location.search.split("?")[1]);
    if (searchString && searchString["status"] === "AutoGenerated") {
      dateId = searchString["dateId"];
    }
    if (dateId) {
      this.recurringTask(id, dateId);
      this.setState({
        isRecurring: true,
        dateId,
      });
    } else {
      this.initialFetch(id);
      this.initialFetchRepDetails(id);
    }
    this.fetchCancelNotes();
  };

  fetchCancelNotes = () => {
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .get("/cms")
      .then(async (resp) => {
        if (resp.data.status === 200 || resp.data.status === "200") {
          if (resp.data.data[0].canceledNotes.notes) {
            cancelOption = resp.data.data[0].canceledNotes.notes;
          } else {
            cancelOption = [
              {
                id: "one",
                name: "No data",
              },
            ];
          }
        } else {
          cancelOption = [
            {
              id: "one",
              name: "No data",
            },
          ];
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };

  employeeFilter = () => {
    var data = {
      search: "",
      startDate: new Date(
        this.state.dateRangeProps.startDate.initialViewDate
      ).toUTCString(),
      endDate: new Date(
        this.state.dateRangeProps.endDate.initialViewDate
      ).toUTCString(),
    };
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/employeeFilterStatus", data)
      .then(async (resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          await this.setAssigneeState(response);
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };

  componentDidUpdate() {
    var { id } = this.props.match.params;
    var dateId = "";
    var searchString = qs.parse(this.props.location.search.split("?")[1]);
    if (searchString && searchString["status"] === "AutoGenerated") {
      dateId = searchString["dateId"];
    }
    if (id !== this.state.taskId) {
      if (!dateId) {
        this.initialFetch(id);
        this.initialFetchRepDetails(id);
      } else if (dateId) {
        this.recurringTask(id, dateId);
      }
    }
  }

  initialFetch = (id) => {
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .post("/task/detail", { id, timeZone: this.state.timeZoneName })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var data = response.data;
          var end_date = moment(data.endDate);
          var start_date = moment(data.startDate);
          var diffTime = end_date.diff(start_date, "hours");
          var diffMinutes = end_date.diff(start_date, "minutes");
          var diffSeconds = end_date.diff(start_date, "seconds");
          var diffHours = diffMinutes / 60;
          var diff_Hours = Math.floor(diffHours);
          var diff_minute = (diffHours - diff_Hours) * 60;
          var diffMinute = Math.round(diff_minute);
          diffTime = (diff_Hours > 0 ? diff_Hours + " Hours " : "").concat(
            diffMinute > 0 ? diffMinute + " Minutes" : ""
          );
          var actual_end_date = moment(data.timeline_dates[1]);
          var actual_start_date = moment(data.timeline_dates[0]);
          var actual_startdate = moment(
            actual_start_date.format("YYYY-MM-DDTHH:mm:00")
          );
          var actual_enddate = moment(
            actual_end_date.format("YYYY-MM-DDTHH:mm:00")
          );
          var actual_diffTime = actual_enddate.diff(actual_startdate, "hours");
          var actual_diffMinutes = actual_enddate.diff(
            actual_startdate,
            "minutes"
          );
          var actual_diffSeconds = actual_end_date.diff(
            actual_start_date,
            "seconds"
          );
          var actualHours = actual_diffMinutes / 60;
          var actual_Hours = Math.floor(actualHours);
          var actual_diffMinute = (actualHours - actual_Hours) * 60;
          var actualdiffMinute = Math.round(actual_diffMinute);
          actual_diffTime = (
            actual_Hours > 0 ? actual_Hours + " Hours " : ""
          ).concat(actualdiffMinute > 0 ? actualdiffMinute + " Minutes" : "");
          const {
            dateRangeProps,
            dateRangePropsNew,
            actualdateRangeProps,
            actualdateRangePropsNew,
          } = this.state;
          dateRangeProps.startDate.initialViewDate = momentTimeZone(
            data.startDate
          ).tz(this.state.timeZoneName);
          dateRangeProps.endDate.initialViewDate = momentTimeZone(
            data.endDate
          ).tz(this.state.timeZoneName);
          dateRangeProps.time = diffTime;
          dateRangePropsNew.startDate.initialViewDate = momentTimeZone(
            data.startDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.startDate.inputProps.value = momentTimeZone(
            data.startDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.endDate.initialViewDate = momentTimeZone(
            data.endDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.endDate.inputProps.value = momentTimeZone(
            data.endDate
          ).tz(this.state.timeZoneName);

          dateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(
            data.endDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.endDateNew.inputProps.value = momentTimeZone(
            data.endDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.time = diffTime;

          actualdateRangeProps.startDate.initialViewDate = momentTimeZone(
            data.timeline_dates[0]
          ).tz(this.state.timeZoneName);
          actualdateRangeProps.endDate.initialViewDate = momentTimeZone(
            data.timeline_dates[1]
          ).tz(this.state.timeZoneName);
          actualdateRangeProps.time = actual_diffTime;
          actualdateRangePropsNew.startDate.initialViewDate = momentTimeZone(
            data.timeline_dates[0]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.startDate.inputProps.value = momentTimeZone(
            data.timeline_dates[0]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.endDate.initialViewDate = momentTimeZone(
            data.timeline_dates[1]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.endDate.inputProps.value = momentTimeZone(
            data.timeline_dates[1]
          ).tz(this.state.timeZoneName);

          actualdateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(
            data.timeline_dates[1]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.endDateNew.inputProps.value = momentTimeZone(
            data.timeline_dates[1]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.time = actual_diffTime;
          this.setState(
            {
              isEmergency: data.isEmergency,
              conflict: data.conflict,
              conflictName: data.conflictName,
              account: data.account,
              service: data.service,
              products: data.products,
              expenses: data.expenses,
              timelines: data.timeline_data,
              taskId: data._id,
              taskAssignee: data.task_assignedName,
              taskSchedules: data.scheduleRules ? data.scheduleRules : "",
              isRecurring: data.scheduleRules.isRecurring
                ? data.scheduleRules.isRecurring
                : data.isRecurringTask
                ? data.isRecurringTask
                : false,
              profile_path: data.task_assignedImage,
              taskAssigneeValue: [
                {
                  name: data.task_assignedName
                    ? data.task_assignedName
                    : "Unassigned",
                },
              ],
              taskAssine: data.task_assignedName
                ? data.task_assignedName
                : "Unassigned",
              taskAssigneeId: data.task_assignedId,
              taskStatus: data.taskStatus,
              taskDescription: data.taskDescription,
              taskName: data.taskName,
              dateRangeProps,
              dateRangePropsNew,
              actualdateRangeProps,
              actualdateRangePropsNew,
              department: data.department_details,
              department_contact: data.department_contacts,
              address: data.address ? data.address : "",
              coordinates: data.coordinates ? data.coordinates : "",
              startDate: data.startDate,
              endDate: data.endDate,
              recurringText: data.recurringText,
              newStartDate: momentTimeZone(
                dateRangeProps.startDate.initialViewDate
              )
                .tz(this.state.timeZoneName)
                .format("MM-DD-YYYY h:mm A"),
              newEndDate: momentTimeZone(dateRangeProps.endDate.initialViewDate)
                .tz(this.state.timeZoneName)
                .format("MM-DD-YYYY h:mm A"),
              patientName: data.patientName ? data.patientName : "",
              caseNumber: data.caseNumber ? data.caseNumber : "",
              timelineCancelReason: data.cancelReason,
              revertStatus: data.revertStatus ? data.revertStatus : "",
            },
            () => {
              this.employeeFilter();
              this.setState({
                serviceName: this.state.service[0].name,
                serviceId: this.state.service[0]._id,
                serviceNameDefault: this.state.service[0].name,
                accountNameDefault: this.state.account[0].name,
                accountId: this.state.account[0]._id,
                departmentNameDefault: this.state.department[0].name,
                departmentId: this.state.department[0]._id,
              });
            }
          );
        } else if (
          response &&
          (response.status === "401" || response.status === 401)
        ) {
          notification.info({
            duration: 4000,
            message: (
              <div>
                <p>
                  This task doesn't have service/department/account. So we are
                  navigation back to task list page.
                </p>
              </div>
            ),
          });
          setTimeout(() => {
            window.location.href = "/task";
          }, 5000);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  recurringTask = (id, dateId) => {
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .get(`recurringTask/details/${id}/${dateId}`)
      .then((resp) => {
        var response = resp && resp.data;
        if (response && response.msg === "Success") {
          var data = response.data;
          var end_date = moment(data?.endDate);
          var start_date = moment(data?.startDate);
          var diffTime = end_date?.diff(start_date, "hours");
          var diffMinutes = end_date?.diff(start_date, "minutes");
          var diffSeconds = end_date?.diff(start_date, "seconds");
          var diffHours = diffMinutes / 60;
          var diff_Hours = Math.floor(diffHours);
          var diff_minute = (diffHours - diff_Hours) * 60;
          var diffMinute = Math.round(diff_minute);
          diffTime = (diff_Hours > 0 ? diff_Hours + " Hours " : "").concat(
            diffMinute > 0 ? diffMinute + " Minutes" : ""
          );
          var actual_end_date = moment(data?.timeline_data[1]);
          var actual_start_date = moment(data?.timeline_data[0]);
          var actual_startdate = moment(
            actual_start_date?.format("YYYY-MM-DDTHH:mm:00")
          );
          var actual_enddate = moment(
            actual_end_date?.format("YYYY-MM-DDTHH:mm:00")
          );
          var actual_diffTime = actual_enddate?.diff(actual_startdate, "hours");
          var actual_diffMinutes = actual_enddate?.diff(
            actual_startdate,
            "minutes"
          );
          var actual_diffSeconds = actual_end_date?.diff(
            actual_start_date,
            "seconds"
          );
          var actualHours = actual_diffMinutes / 60;
          var actual_Hours = Math.floor(actualHours);
          var actual_diffMinute = (actualHours - actual_Hours) * 60;
          var actualdiffMinute = Math.round(actual_diffMinute);
          actual_diffTime = (
            actual_Hours > 0 ? actual_Hours + " Hours " : ""
          ).concat(actualdiffMinute > 0 ? actualdiffMinute + " Minutes" : "");
          const {
            dateRangeProps,
            dateRangePropsNew,
            actualdateRangeProps,
            actualdateRangePropsNew,
          } = this.state;
          dateRangeProps.startDate.initialViewDate = momentTimeZone(
            data?.startDate
          ).tz(this.state.timeZoneName);
          dateRangeProps.endDate.initialViewDate = momentTimeZone(
            data?.endDate
          ).tz(this.state.timeZoneName);
          dateRangeProps.time = diffTime;
          dateRangePropsNew.startDate.initialViewDate = momentTimeZone(
            data?.startDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.startDate.inputProps.value = momentTimeZone(
            data?.startDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.endDate.initialViewDate = momentTimeZone(
            data?.endDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.endDate.inputProps.value = momentTimeZone(
            data?.endDate
          ).tz(this.state.timeZoneName);

          dateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(
            data?.endDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.endDateNew.inputProps.value = momentTimeZone(
            data?.endDate
          ).tz(this.state.timeZoneName);
          dateRangePropsNew.time = diffTime;

          actualdateRangeProps.startDate.initialViewDate = momentTimeZone(
            data?.timeline_data[0]
          ).tz(this.state.timeZoneName);
          actualdateRangeProps.endDate.initialViewDate = momentTimeZone(
            data?.timeline_data[1]
          ).tz(this.state.timeZoneName);
          actualdateRangeProps.time = actual_diffTime;
          actualdateRangePropsNew.startDate.initialViewDate = momentTimeZone(
            data?.timeline_data[0]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.startDate.inputProps.value = momentTimeZone(
            data?.timeline_data[0]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.endDate.initialViewDate = momentTimeZone(
            data?.timeline_data[1]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.endDate.inputProps.value = momentTimeZone(
            data?.timeline_data[1]
          ).tz(this.state.timeZoneName);

          actualdateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(
            data?.timeline_data[1]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.endDateNew.inputProps.value = momentTimeZone(
            data?.timeline_data[1]
          ).tz(this.state.timeZoneName);
          actualdateRangePropsNew.time = actual_diffTime;
          var additionalRepDatas = response?.additionalReps;
          var repDatas = [];
          additionalRepDatas.map((datas) => {
            repDatas.push({
              additionalRepId: datas?.employeeId,
              repAssigneeValue: datas?.name,
              repEmail: datas?.email,
              repTaskStatus: datas?.status,
              repTaskId: datas?.taskId,
              repProfilePath: datas?.profile,
            });
          });
          var recurringTaskId = data.taskId;
          this.setState(
            {
              timelineCancelReason: data.cancelReason,
              isEmergency: data.isEmergency,
              account: data.account,
              service: data.service,
              products: data.products,
              expenses: data.expenses,
              timelines: data.timeline_data,
              taskId: data._id,
              taskSchedules: data?.taskSchedules,
              taskAssignee: data.task_assignedName,
              profile_path: data.task_assignedImage,
              taskAssigneeValue: [
                {
                  name: data.task_assignedName
                    ? data.task_assignedName
                    : "Unassigned",
                },
              ],
              taskAssine: data.task_assignedName
                ? data.task_assignedName
                : "Unassigned",
              taskAssigneeId: data.task_assignedId,
              taskStatus: data.taskStatus,
              taskDescription: data.taskDescription,
              taskName: data.taskName,
              dateRangeProps,
              dateRangePropsNew,
              actualdateRangeProps,
              actualdateRangePropsNew,
              department: data.department_details,
              department_contact: data.department_contacts,
              address: data.address ? data.address : "",
              coordinates: data.coordinates ? data.coordinates : "",
              additionalRepData: repDatas,
              recurringTaskId: recurringTaskId,
              recurringTaskStatus: data.taskSchedules.isRecurring,
              data_taskId: data.taskId,
              recurringText: data.recurringText,
              newStartDate: momentTimeZone(
                dateRangeProps.startDate.initialViewDate
              )
                .tz(this.state.timeZoneName)
                .format("MM-DD-YYYY h:mm A"),
              newEndDate: momentTimeZone(dateRangeProps.endDate.initialViewDate)
                .tz(this.state.timeZoneName)
                .format("MM-DD-YYYY h:mm A"),
              patientName: data.patientName,
              caseNumber: data.caseNumber,
            },
            () => {
              this.employeeFilter();
              this.setState({
                serviceName: this.state.service[0].name,
                serviceId: this.state.service[0]._id,
                serviceNameDefault: this.state.service[0].name,
                accountNameDefault: this.state.account[0].name,
                accountId: this.state.account[0]._id,
                departmentNameDefault: this.state.department[0].name,
                departmentId: this.state.department[0]._id,
              });
            }
          );
        } else if (
          response &&
          (response.status === "401" || response.status === 401)
        ) {
          notification.info({
            duration: 4000,
            message: (
              <div>
                <p>
                  This task doesn't have service/department/account. So we are
                  navigation back to task list page.
                </p>
              </div>
            ),
          });
          setTimeout(() => {
            window.location.href = "/task";
          }, 5000);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  initialFetchRepDetails = (id) => {
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .get(`/task/additionalRepDetails/${id}`)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var data = response.data;
          var repData = [];
          data.map((data) => {
            repData.push({
              additionalRepId: data?.employeeId,
              repAssigneeValue: data?.name,
              repEmail: data?.email,
              repTaskStatus: data?.status,
              repProfilePath: data?.profile,
            });
          });
          this.setState({
            additionalRepData: repData,
          });
        } else if (
          response &&
          (response.status === "401" || response.status === 401)
        ) {
          notification.info({
            duration: 4000,
            message: (
              <div>
                <p>
                  This task doesn't have service/department/account. So we are
                  navigation back to task list page.
                </p>
              </div>
            ),
          });
          setTimeout(() => {
            window.location.href = "/task";
          }, 5000);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  changeStartDateDialogClose = () => {
    const { dateRangeProps, dateRangePropsNew } = this.state;
    dateRangePropsNew.startDate.initialViewDate = momentTimeZone(
      dateRangeProps.startDate.initialViewDate
    ).tz(this.state.timeZoneName);
    dateRangePropsNew.startDate.inputProps.value = momentTimeZone(
      dateRangeProps.startDate.initialViewDate
    ).tz(this.state.timeZoneName);
    dateRangePropsNew.endDate.initialViewDate = momentTimeZone(
      dateRangeProps.endDate.initialViewDate
    ).tz(this.state.timeZoneName);
    dateRangePropsNew.endDate.inputProps.value = momentTimeZone(
      dateRangeProps.endDate.initialViewDate
    ).tz(this.state.timeZoneName);

    this.setState(
      {
        startDateDialog: false,
      },
      () => this.editInput("isStartDate")
    );
  };
  changeStartDateDialogCloses = () => {
    const { actualdateRangeProps, actualdateRangePropsNew } = this.state;
    actualdateRangePropsNew.startDate.initialViewDate = momentTimeZone(
      actualdateRangeProps.startDate.initialViewDate
    ).tz(this.state.timeZoneName);
    actualdateRangePropsNew.startDate.inputProps.value = momentTimeZone(
      actualdateRangeProps.startDate.initialViewDate
    ).tz(this.state.timeZoneName);
    actualdateRangePropsNew.endDate.initialViewDate = momentTimeZone(
      actualdateRangeProps.endDate.initialViewDate
    ).tz(this.state.timeZoneName);
    actualdateRangePropsNew.endDate.inputProps.value = momentTimeZone(
      actualdateRangeProps.endDate.initialViewDate
    ).tz(this.state.timeZoneName);
    this.setState(
      {
        startDateDialogs: false,
      },
      () => this.editInputs("isStartDates")
    );
  };
  resetStartDateDialog = () => {
    const { dateRangeProps, dateRangePropsNew } = this.state;
    dateRangePropsNew.startDate.initialViewDate = momentTimeZone(
      dateRangeProps.startDate.initialViewDate
    ).tz(this.state.timeZoneName);
    dateRangePropsNew.endDate.initialViewDate = momentTimeZone(
      dateRangeProps.endDate.initialViewDate
    ).tz(this.state.timeZoneName);

    this.setState({
      isStartDateEdit: false,
    });
    this.setState({
      isStartDateEdits: false,
    });
  };
  resetStartDateDialogs = () => {
    const { actualdateRangeProps, actualdateRangePropsNew } = this.state;
    actualdateRangePropsNew.startDate.initialViewDate = momentTimeZone(
      actualdateRangeProps.startDate.initialViewDate
    ).tz(this.state.timeZoneName);
    actualdateRangePropsNew.endDate.initialViewDate = momentTimeZone(
      actualdateRangeProps.endDate.initialViewDate
    ).tz(this.state.timeZoneName);

    this.setState({
      isStartDateEdits: false,
    });
  };
  /**
   * @author Benakesh
   * @param {status name} val
   * TO UPDATE TASK STATUS
   */
  onclickStatus = (val) => {
    let currentDateDetail = moment().tz(this.state.timeZoneName);
    let taskStartDateDetail = moment(
      this.state.dateRangeProps.startDate.initialViewDate
    ).tz(this.state.timeZoneName);
    if (val === "inprogress") {
      this.setState({ selectedStatus: val });
      if (currentDateDetail.isBefore(taskStartDateDetail)) {
        this.setState({ selectedStatus: val, showaddModal: true });
      } else {
        this.setState(
          {
            selectedStatus: val,
          },
          () => {
            this.updateStatusHandle();
          }
        );
      }
    } else {
      this.setState(
        {
          selectedStatus: val,
        },
        () => {
          this.updateStatusHandle();
        }
      );
    }
  };
  /**
   * @author Shashi
   * TO UPDATE TASK STATUS INPROGRESS
   */
  onInprogress = () => {
    this.updateStatusHandle();
  };
  updateStatus = () => {
    let currentDates = moment().tz(this.state.timeZoneName);
    let taskStartDates = moment(
      this.state.dateRangeProps.startDate.initialViewDate
    ).tz(this.state.timeZoneName);
    if (this.state.selectedStatus === "inprogress") {
      if (currentDates.isBefore(taskStartDates)) {
        this.setState({ showaddModal: true });
      } else {
        this.updateStatusHandle();
      }
    } else {
      this.updateStatusHandle();
    }
  };
  /**
   * @author Benakesh
   * TO SET OPEN UPDATE STATUS DIALOG
   */
  onupdatestatusdialog = () => {
    this.setState({
      updateStatusDialogMenu: true,
      selectedStatus: "",
    });
  };
  /**
   * TO UPDATE REVERT STATUS
   */
  revertModalHandler = () => {
    this.setState({
      revertModal: true,
      onRevertDialog: true,
    });
  };

  revertModalCloseHandler = () => {
    this.setState({
      revertModal: false,
      onRevertDialog: false,
    });
  };

  revertStatusHandler = () => {
    let data = {
      taskStatus: this.state.revertStatus,
      _id: this.state.taskId,
      timeZone: this.state.timeZoneName,
      type: "revertStatus",
    };
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .post("/assign_task", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            revertModal: false,
            onRevertDialog: false,
          });
          this.initialFetch(this.state.taskId);
          var props = {
            message: "Status Updated Successfully",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          var propsErr = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };

  /**
   * @author Benakesh
   * TO ADD NEW PRODUCT
   */
  onAddProductDialog = () => {
    var {
      priceNew,
      quantityNew,
      productNew,
      taskId,
      productName,
      addProductError,
      taskSchedules,
    } = this.state;
    if (validator.isEmpty(productName)) {
      addProductError.productNew = "Please select the product";
    }
    if (this.state.priceEnable && this.state.quantityEnable) {
      if (validator.isEmpty(priceNew)) {
        addProductError.priceNew = "Please enter the product price";
      }
      if (priceNew && !/^(?!0+$)\d{1,6}(\.\d{1,2})?$/.test(priceNew)) {
        addProductError.priceNew = "Please enter the valid price. e.g: $2,500";
      }
      if (validator.isEmpty(quantityNew)) {
        addProductError.quantityNew = "Please enter the product quantity";
      }
      if (quantityNew && !/^(?!0+$)\d{1,3}$/.test(quantityNew)) {
        addProductError.quantityNew =
          "Please enter the valid Quantity. e.g: 50, 10..etc";
      }
      if (quantityNew === 0 || quantityNew === "0") {
        addProductError.quantityNew =
          "Quantity should not be 0,undefined,or null";
      }
    } else {
      addProductError.priceNew = "";
      addProductError.quantityNew = "";
      addProductError.productNew = "";
    }
    this.setState(
      {
        addProductError,
      },
      () => {
        if (
          this.state.addProductError.priceNew === "" &&
          this.state.addProductError.quantityNew === "" &&
          this.state.addProductError.productNew === ""
        ) {
          var newprods = {
            task_id: taskId,
            price: priceNew ? parseFloat(priceNew) : 0,
            quantity: quantityNew ? parseInt(quantityNew) : 0,
            item: productName,
          };
          if (
            taskSchedules &&
            taskSchedules.isRecurring == true &&
            this.state.dateId
          ) {
            newprods.recurringType = true;
            newprods.dataTaskId = this.state.data_taskId;
            newprods.dateId = this.state.dateId;
          }
          if (!this.state.newProduct) {
            newprods.productId = this.state.productId;
          }

          AxiosInstance(
            process.env.REACT_APP_NODE_ENV === "development" ||
              process.env.REACT_APP_NODE_ENV === "qa"
              ? 6008
              : "task",
            "application/json"
          )
            .post("/task/products_edit", newprods)
            .then((resp) => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                this.setState(
                  {
                    products: response.data,
                  },
                  () => {
                    this.onAddDialogClose();
                    var props = {
                      message: "Product Added Successfully",
                      icon: <img src={iconNotificationSuccess} alt="success" />,
                      type: "success",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification update-notify",
                    };
                    NotificationComponent(props);
                    if (
                      taskSchedules &&
                      taskSchedules.isRecurring == true &&
                      this.state.dateId
                    ) {
                      this.props.history.replace(`/task/${response?.taskId}`);
                    }
                  }
                );
              } else if (
                response &&
                (response.status === "400" || response.status === 400)
              ) {
                var propsErr = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propsErr);
              } else if (
                response &&
                (response.status === "401" || response.status === 401)
              ) {
                var propsErr = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propsErr);
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
            });
        }
      }
    );
  };

  /**
   * @author Benakesh
   * TO CLOSE ADD PRODUCT DIALOG
   */
  onAddDialogClose = () => {
    this.setState({
      onAddDialogOpen: !this.state.onAddDialogOpen,
    });
  };
  /**
   * @author Benakesh
   * TO CLEAR ADD PRODUCT FIELDS ON EXIT OF DIALOG
   */
  onAddDialogExit = () => {
    this.setState({
      priceNew: "",
      quantityNew: "",
      productNew: "",
      isProducts: false,
    });
  };
  /**
   * @author Benakesh
   * TO OPEN A ADD PRODUCT DIALOG
   */
  onAddProduct = () => {
    this.setState({
      onAddDialogOpen: true,
    });
  };
  /**
   * @author Benakesh
   * TO OPEN A ADD EXPENSE DIALOG
   */
  addNewExpense = () => {
    this.setState({
      onAddExpenseDialogOpen: true,
    });
  };
  /**
   * @author Benakesh
   * TO RESET ADD EXPENSE FIELD ON EXIT OF ADD EXPENSE DIALOG
   */
  onAddExpenseDialogExit = () => {
    this.setState({
      expenseAmount: "",
      description: "",
      addExpenseError: {
        expenseAmount: "",
        description: "",
      },
    });
  };
  /**
   * @author Benakesh
   * TO CLOSE THE ADD EXPENSE DIALOG
   */
  onAddExpenseDialogClose = () => {
    this.setState({
      onAddExpenseDialogOpen: !this.state.onAddExpenseDialogOpen,
    });
  };
  //

  /**
   * @author Benakesh
   * ADD NEW EXPENSE
   */
  onAddNewExpenseHandler = () => {
    var { expenseAmount, description, addExpenseError } = this.state;
    if (validator.isEmpty(description) && validator.isEmpty(expenseAmount)) {
      addExpenseError.expenseAmount = "Please add the expense amount";
      addExpenseError.description = "Please add a description";
    } else {
      addExpenseError.expenseAmount = "";
      addExpenseError.description = "";
    }
    if (validator.isEmpty(expenseAmount)) {
      addExpenseError.expenseAmount = "Please add the expense amount";
    } else if (!/^(?!0+$)\d{1,6}(\.\d{1,2})?$/.test(expenseAmount)) {
      addExpenseError.expenseAmount =
        "Please enter the valid amount. Eg: 95.50, 100";
    } else if (
      !/^(?:0?[1-9]\d*(?:\.\d*[0-9]*)?)|(:?^0.[1-9]+0)$/.test(expenseAmount)
    ) {
      addExpenseError.expenseAmount = "Please enter valid amount";
    } else if (expenseAmount === 0 || expenseAmount === "0") {
      addExpenseError.expenseAmount = "Price should not be 0,undefined,or null";
    } else if (validator.isEmpty(description)) {
      addExpenseError.description = "Please add a description";
    } else {
      addExpenseError.description = "";
      addExpenseError.expenseAmount = "";
    }
    this.setState(
      {
        addExpenseError,
      },
      () => {
        if (
          this.state.addExpenseError.expenseAmount === "" &&
          this.state.addExpenseError.description === ""
        ) {
          var data = {
            task_id: this.state.taskId,
            amount: parseFloat(this.state.expenseAmount),
            description: this.state.description,
          };
          this.addExpenseApi(data, "add");
        }
      }
    );
  };
  /**
   * @author Benakesh
   * @param {EXPENSE ROW OBJECT} expense
   * TO REMOVE EXPENSE
   */
  onExpenseRemove = (expense) => {
    var data = {
      task_id: this.state.taskId,
      amount: parseInt(expense.amount),
      description: expense.description,
      expensesId: expense._id,
      type: "delete",
    };
    this.addExpenseApi(data, "remove");
  };
  /**
   * @author Benakesh
   * @param {EXPENSE DATA} data
   * @param {EDIT/DELETE} types
   * TO EDIT OR DELETE EXPENSE
   */
  addExpenseApi = (data, types) => {
    if (this.state.taskSchedules.isRecurring === true && this.state.dateId) {
      data.recurringType = true;
      data.dataTaskId = this.state.data_taskId;
      data.dateId = this.state.dateId;
    }
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .post("/expenses_edit", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState(
            {
              expenses: response.data,
            },
            () => {
              if (types === "add") {
                this.onAddExpenseDialogClose();
              }
              var props = {
                message:
                  types === "add"
                    ? "Expense Added Successfully"
                    : "Expense Removed Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: `task_notification ${
                  types === "add" ? "success-notify" : "success-notify"
                }`,
              };
              NotificationComponent(props);
              if (response.Id && response.Id != "") {
                this.props.history.replace(`/task/${response.Id}`);
              }
            }
          );
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          var propsErr = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        } else if (
          response &&
          (response.status === "401" || response.status === 401)
        ) {
          var propsErr = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };
  /**
   * @author Benakesh
   * CANCEL TASK DIALOG
   */
  onCancelTaskDialog = () => {
    this.setState({
      onCancelTaskDialogOpen: true,
    });
  };
  /**
   * @author Benakesh
   * TO CLOSE TASK CANCEL DIALOG
   */
  onCancelTaskDialogClose = () => {
    this.setState({
      onCancelTaskDialogOpen: false,
    });
  };
  /**
   * @author Benakesh
   * THE CANCEL TASK HANDLER
   */
  onCancelTask = () => {
    var recurringData = this.state.taskSchedules;
    if (recurringData?.isRecurring == true) {
      this.setState({
        cancelTaskOpen: true,
      });
    } else {
      this.setState(
        {
          selectedStatus: "cancelled",
        },
        async () => {
          await this.updateStatusHandle();
          await this.onCancelTaskDialogClose();
        }
      );
    }
  };
  /**
   * @author Benakesh
   * ON CANCEL DIALOG EXIT
   */
  onCancelTaskExit = () => {
    this.setState({
      selectedStatus: "",
      addNote: "",
    });
  };

  /**
   * @author Benakesh
   * TO UPDATE STATUS FROM DIALOG
   */
  updateStatusHandle = () => {
    var { taskId, selectedStatus, addNote, recurringTaskStatus, cancelValue } =
      this.state;
    var data = {};
    // if (recurringTaskStatus === true) {
    //   if(cancelValue === "Custom"){
    //     data.notes = cancelValue + " " + addNote;
    //   } else{
    //     data.notes = addNote;
    //   }
    // }else{
    //   if(cancelValue === "Custom"){
    //     data.note = addNote
    //   } else{
    //     data.note = cancelValue + " " + addNote
    //   }
    // }

    if (recurringTaskStatus) {
      data.notes = addNote;
      // if (cancelValue !== "Custom") {
      //   data.notes = cancelValue + " " + data.notes;
      // }
      data.cancelReason = cancelValue;
    } else {
      data.note = addNote;
      // if (cancelValue !== "Custom") {
      //   data.note = cancelValue + " " + data.note;
      // }
      data.cancelReason = cancelValue;
    }
    if (selectedStatus !== "" && taskId !== "") {
      if (this.state.recurringTaskStatus === true) {
        data.dateId = this.state.dateId;
        data.taskId = taskId;
        data.taskStatus = selectedStatus;
        data.timeZone = this.state.timeZoneName;
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .put(`/recurringTask/${this.state.data_taskId}/${data.taskId}`, data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState(
                {
                  onCancelTaskDialogOpen: false,
                  taskStatus: selectedStatus,
                  updateStatusDialogMenu: false,
                  showaddModal: false,
                  selectedStatus: "",
                  addNote: "",
                },
                () => {
                  this.componentDidMount();
                }
              );
              var props = {
                message: "Status Updated Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              window.location.href = `/task/${response?.createdTaskId}`;
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          });
      } else {
        data.taskStatus = selectedStatus;
        data._id = taskId;
        data.timeZone = this.state.timeZoneName;
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .post("/assign_task", data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState(
                {
                  onCancelTaskDialogOpen: false,
                  taskStatus: selectedStatus,
                  updateStatusDialogMenu: false,
                  showaddModal: false,
                  selectedStatus: "",
                  addNote: "",
                },
                () => {
                  this.componentDidMount();
                }
              );
              var props = {
                message: "Status Updated Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (
              error &&
              (error.status === "500" || error.status === 500)
            ) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          });
      }
    } else {
      this.setState({
        updateStatusError: "Please select task status",
      });
    }
  };
  /**
   * @author Benakesh
   * TO CLOSE UPDATE STATUS DIALOG
   */
  handleUpdateDialogClose = () => {
    this.setState({
      updateStatusDialogMenu: !this.state.updateStatusDialogMenu,
    });
  };
  /**
   * @author Benakesh
   * @param {FIELD NAME} field
   * TO UPDATE FIELD WHILE EDITING
   */
  updateHandler = (field) => {
    var {
      taskId,
      accountId,
      accountName,
      departmentId,
      departmentName,
      serviceId,
      serviceName,
      dateRangePropsNew,
      newDesc,
      startDateError,
      newPatientName,
      newCaseNumber,
    } = this.state;
    var data = {};
    switch (field) {
      case "isDesc":
        data.task_id = taskId;
        data.taskDescription = newDesc;
        break;
      case "isStartDate":
        data.startDate = new Date(
          dateRangePropsNew.startDate.initialViewDate
        ).toUTCString();
        data.task_id = taskId;
        data.endDate = new Date(
          dateRangePropsNew.endDate.initialViewDate
        ).toUTCString();
        break;
      case "isRecurringEndDate":
        data.startDate = new Date(
          dateRangePropsNew.startDate.initialViewDate
        ).toUTCString();
        data.task_id = taskId;
        data.endDate = new Date(
          dateRangePropsNew.endDate.initialViewDate
        ).toUTCString();
        break;
      case "isEndDate":
        data.endDate = new Date(
          dateRangePropsNew.endDate.initialViewDate
        ).toUTCString();
        data.task_id = taskId;
        break;
      case "isAccount":
        data.accountId = accountId;
        data.accountName = accountName;
        data.departmentId = this.state.departmentId;
        data.departmentName = departmentName;
        data.task_id = taskId;
        break;
      case "isDepartment":
        data.departmentId = departmentId;
        data.departmentName = departmentName;
        data.task_id = taskId;
        break;
      case "isService":
        data.serviceId = serviceId;
        data.serviceName = serviceName;
        data.task_id = taskId;
        break;
      case "isPatientName":
        data.task_id = taskId;
        data.patientName = newPatientName;
        break;
      case "isCaseNumber":
        data.task_id = taskId;
        data.caseNumber = newCaseNumber;
        break;
      default:
        break;
    }
    if (
      this.state.dateRangePropsNew.startDate.inputProps.value === "" &&
      this.state.dateRangePropsNew.endDate.inputProps.value === ""
    ) {
      startDateError = "Please enter start date and end date";
    } else if (
      momentTimeZone(
        this.state.dateRangePropsNew.startDate.inputProps.value
      ).tz(this.state.timeZoneName) >=
      momentTimeZone(this.state.dateRangePropsNew.endDate.inputProps.value).tz(
        this.state.timeZoneName
      )
    ) {
      startDateError =
        "End date should not be less than or equal to start date";
    } else {
      startDateError = "";
    }
    this.setState({ startDateError });
    if (startDateError === "") {
      data.timeZone = this.state.timeZoneName;
      if (this.state.isRecurring === true && this.state.dateId) {
        delete data.task_id;
        data["dateId"] = this.state.dateId;
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .put(
            `/recurringTask/${this.state.recurringTaskId}/${this.state.taskId}`,
            data
          )
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              if (field === "isAccount") {
                this.setState(
                  {
                    departmentNameDefault: this.state.departmentName,
                    accountNameDefault: this.state.accountName,
                  },
                  () => {
                    this.componentDidMount();
                  }
                );
              } else if (field === "isDepartment") {
                this.setState(
                  {
                    departmentNameDefault: this.state.departmentName,
                  },
                  () => {
                    this.componentDidMount();
                  }
                );
              } else if (field === "isService") {
                this.setState(
                  {
                    serviceNameDefault: this.state.serviceName,
                  },
                  () => {
                    this.componentDidMount();
                  }
                );
              } else if (
                field === "isStartDate" ||
                field === "isRecurringEndDate" ||
                field === "isDesc" ||
                field === "isPatientName" ||
                field === "isCaseNumber"
              ) {
                this.componentDidMount();
              }
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.editInput(field);
              this.props.history.replace(`/task/${response.createdTaskId}`);
              this.componentDidMount();
            } else if (
              response &&
              (response.status === "400" || response.status === 400)
            ) {
              const { dateRangePropsNew } = this.state;
              dateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(
                this.state.dateRangePropsNew.endDate.initialViewDate
              ).tz(this.state.timeZoneName);
              dateRangePropsNew.endDateNew.inputProps.value = momentTimeZone(
                this.state.dateRangePropsNew.endDate.initialViewDate
              )
                .tz(this.state.timeZoneName)
                .format("MM-DD-YYYY HH:mm A");
              this.setState({
                dateRangePropsNew,
              });
              var props2 = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props2);
            } else {
              var props1 = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props1);
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      } else {
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .put("/task/edit", data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              if (field === "isAccount") {
                this.setState(
                  {
                    departmentNameDefault: this.state.departmentName,
                    accountNameDefault: this.state.accountName,
                  },
                  () => {
                    this.componentDidMount();
                  }
                );
              } else if (field === "isDepartment") {
                this.setState(
                  {
                    departmentNameDefault: this.state.departmentName,
                  },
                  () => {
                    this.componentDidMount();
                  }
                );
              } else if (field === "isService") {
                this.setState(
                  {
                    serviceNameDefault: this.state.serviceName,
                  },
                  () => {
                    this.componentDidMount();
                  }
                );
              } else if (
                field === "isStartDate" ||
                field === "isEndDate" ||
                field === "isDesc" ||
                field === "isPatientName" ||
                field === "isCaseNumber"
              ) {
                this.componentDidMount();
              }
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.editInput(field);
            } else if (
              response &&
              (response.status === "400" || response.status === 400)
            ) {
              const { dateRangePropsNew } = this.state;
              dateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(
                this.state.dateRangePropsNew.endDate.initialViewDate
              ).tz(this.state.timeZoneName);
              dateRangePropsNew.endDateNew.inputProps.value = momentTimeZone(
                this.state.dateRangePropsNew.endDate.initialViewDate
              )
                .tz(this.state.timeZoneName)
                .format("MM-DD-YYYY HH:mm A");
              this.setState({
                dateRangePropsNew,
              });
              var props2 = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props2);
            } else {
              var props1 = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props1);
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      }
    }
  };

  updateActualHandler = (field) => {
    var {
      taskId,
      accountId,
      accountName,
      departmentId,
      departmentName,
      serviceId,
      serviceName,
      actualdateRangePropsNew,
      newDesc,
      startDateErrors,
      newPatientName,
      newCaseNumber,
    } = this.state;
    var data = {};
    switch (field) {
      case "isDesc":
        data.task_id = taskId;
        data.taskDescription = newDesc;
        break;
      case "isStartDates":
        data.startDate = new Date(
          actualdateRangePropsNew.startDate.initialViewDate
        ).toUTCString();
        data.task_id = taskId;
        data.endDate = new Date(
          actualdateRangePropsNew.endDate.initialViewDate
        ).toUTCString();
        break;
      case "isEndDates":
        data.startDate = new Date(
          actualdateRangePropsNew.startDate.initialViewDate
        ).toUTCString();
        data.endDate = new Date(
          actualdateRangePropsNew.endDate.initialViewDate
        ).toUTCString();
        data.task_id = taskId;
        break;
      case "isAccount":
        data.accountId = accountId;
        data.accountName = accountName;
        data.departmentId = this.state.departmentId;
        data.departmentName = departmentName;
        data.task_id = taskId;
        break;
      case "isDepartment":
        data.departmentId = departmentId;
        data.departmentName = departmentName;
        data.task_id = taskId;
        break;
      case "isService":
        data.serviceId = serviceId;
        data.serviceName = serviceName;
        data.task_id = taskId;
        break;
      case "isPatientName":
        data.task_id = taskId;
        data.patientName = newPatientName;
        break;
      case "isCaseNumber":
        data.task_id = taskId;
        data.caseNumber = newCaseNumber;
        break;
      default:
        break;
    }
    if (
      this.state.actualdateRangePropsNew.startDate.inputProps.value === "" &&
      this.state.actualdateRangePropsNew.endDate.inputProps.value === ""
    ) {
      startDateErrors = "Please enter start date and end date";
    } else if (
      momentTimeZone(
        this.state.actualdateRangePropsNew.startDate.inputProps.value
      ).tz(this.state.timeZoneName) >=
      momentTimeZone(
        this.state.actualdateRangePropsNew.endDate.inputProps.value
      ).tz(this.state.timeZoneName)
    ) {
      startDateErrors =
        "End date should not be less than or equal to start date";
    } else {
      startDateErrors = "";
    }
    this.setState({ startDateErrors });
    if (startDateErrors === "") {
      data.timeZone = this.state.timeZoneName;
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6008
          : "task",
        "application/json"
      )
        .put("/task/complete", data)
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            if (field === "isAccount") {
              this.setState(
                {
                  departmentNameDefault: this.state.departmentName,
                  accountNameDefault: this.state.accountName,
                },
                () => {
                  this.componentDidMount();
                }
              );
            } else if (field === "isDepartment") {
              this.setState(
                {
                  departmentNameDefault: this.state.departmentName,
                },
                () => {
                  this.componentDidMount();
                }
              );
            } else if (field === "isService") {
              this.setState(
                {
                  serviceNameDefault: this.state.serviceName,
                },
                () => {
                  this.componentDidMount();
                }
              );
            } else if (
              field === "isStartDates" ||
              field === "isEndDates" ||
              field === "isDesc" ||
              field === "isPatientName" ||
              field === "isCaseNumber"
            ) {
              this.componentDidMount();
            }
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(props);
            this.editInput(field);
            this.editInputs(field);
          } else if (
            response &&
            (response.status === "400" || response.status === 400)
          ) {
            const { actualdateRangePropsNew } = this.state;
            actualdateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(
              this.state.actualdateRangePropsNew.endDate.initialViewDate
            ).tz(this.state.timeZoneName);
            actualdateRangePropsNew.endDateNew.inputProps.value =
              momentTimeZone(
                this.state.actualdateRangePropsNew.endDate.initialViewDate
              )
                .tz(this.state.timeZoneName)
                .format("MM-DD-YYYY HH:mm A");
            this.setState({
              actualdateRangePropsNew,
            });
            var props2 = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props2);
          } else {
            var props1 = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props1);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };

  onUpdateAssignee = () => {
    var { taskId, newAssigneeId, newAssigneeName } = this.state;
    var data = {
      timezone: this.state.timeZoneName,
    };
    // if (newAssigneeId !== "" && newAssigneeName !== "") {
    if (newAssigneeId !== "" && newAssigneeName !== "") {
      data.assignedTo = newAssigneeId;
      data.taskStatus = "Pending";
      data._id = taskId;
    } else {
      data.taskStatus = "Unassigned";
      data._id = taskId;
    }
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .post("/assign_task", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState(
            {
              isAssignee: true,
              taskAssignee: newAssigneeName,
              newAssigneeId: "",
              newAssigneeName: "",
            },
            () => {
              this.componentDidMount();
            }
          );
          var props = {
            message: "Task Assigned Successfully",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          this.setState({
            isAssignee: true,
            newAssigneeId: "",
            newAssigneeName: "",
          });
          var propsErr = {
            message: "Failed To Assign Task",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
    // }
  };

  /**
   * @author Benakesh
   * @param {FIELD NAME} name
   * TO SHOW AND HIDE EDIT FIELDS
   */
  editInput = (name) => {
    switch (name) {
      case "isDesc":
        this.setState({ isDesc: !this.state.isDesc });
        break;
      case "isStartDate":
        this.setState({
          startDateError: "",
          isStartDate: !this.state.isStartDate,
          startDateDialog: true,
          isStartDateEdit: false,
        });
        break;
      case "isEndDate":
        this.setState({
          isEndDate: !this.state.isEndDate,
          editEndDates: true,
          endDateError: "",
          startDateError: "",
        });
        break;
      case "isService":
        this.setState({ isService: !this.state.isService, serviceName: "" });
        break;
      case "isAccount":
        this.setState({
          isAccount: !this.state.isAccount,
          changeAccountDialog: true,
        });
        break;
      case "isDepartment":
        this.setState({
          isDepartment: !this.state.isDepartment,
          departmentName: "",
        });
        break;
      case "isAssignee":
        this.setState({ isAssignee: !this.state.isAssignee });
        break;
      case "isPatientName":
        this.setState({ isPatientName: !this.state.isPatientName });
        break;
      case "isCaseNumber":
        this.setState({ isCaseNumber: !this.state.isCaseNumber });
        break;
      default:
        break;
    }
  };
  editInputs = (name) => {
    switch (name) {
      case "isDesc":
        this.setState({ isDesc: !this.state.isDesc });
        break;
      case "isStartDates":
        this.setState({
          startDateErrors: "",
          isStartDates: !this.state.isStartDates,
          startDateDialogs: true,
          isStartDateEdits: false,
        });
        break;
      case "isEndDates":
        this.setState({
          isEndDates: !this.state.isEndDates,
          editEndDates: true,
          endDateErrors: "",
          startDateErrors: "",
        });
        break;
      case "isService":
        this.setState({ isService: !this.state.isService, serviceName: "" });
        break;
      case "isAccount":
        this.setState({
          isAccount: !this.state.isAccount,
          changeAccountDialog: true,
        });
        break;
      case "isDepartment":
        this.setState({
          isDepartment: !this.state.isDepartment,
          departmentName: "",
        });
        break;
      case "isAssignee":
        this.setState({ isAssignee: !this.state.isAssignee });
        break;
      case "isPatientName":
        this.setState({ isPatientName: !this.state.isPatientName });
        break;
      case "isCaseNumber":
        this.setState({ isCaseNumber: !this.state.isCaseNumber });
        break;
      default:
        break;
    }
  };

  /**
   * @author Benakesh
   * @param {TARGET ELEMENT} e
   * SET STATE ON CHANGE EVENT
   */
  onChangeHandler = (e) => {
    e.preventDefault();
    var {
      taskDescriptionError,
      addExpenseError,
      addProductError,
      editProductError,
    } = this.state;
    var { name, value } = e.target;

    switch (name) {
      case "taskDescription":
        // !validator.isLength(value,{min:1,max:250})
        value.trim().length > 3000
          ? (taskDescriptionError =
              "Description length should be less than 3000 character")
          : (taskDescriptionError = "");
        this.setState({ taskDescriptionError }, () => {
          if (this.state.taskDescriptionError === "") {
            this.setState({ newDesc: value });
          }
        });
        break;
      case "patientName":
        this.setState({ newPatientName: value });
        break;
      case "caseNumber":
        this.setState({ newCaseNumber: value });
        break;
      case "addNote":
        this.setState({ [name]: value, cancelledTaskNote: value });
        break;
      case "addNoteTab":
        this.setState({ [name]: value, addNoteTabError: "" });
        break;
      case "price":
        editProductError.price = "";
        this.setState({ [name]: value, editProductError });
        break;
      case "quantity":
        editProductError.quantity = "";
        this.setState({ [name]: value, editProductError });
        break;
      case "selectedStatus":
        this.setState({ [name]: value });
        break;
      case "priceNew":
        addProductError.priceNew = "";
        this.setState({ [name]: value, addProductError });
        break;
      case "productNew":
        addProductError.productNew = "";
        this.setState({ [name]: value, addProductError });
        break;
      case "quantityNew":
        addProductError.quantityNew = "";
        this.setState({ [name]: value, addProductError });
        break;
      case "expenseAmount":
        addExpenseError.expenseAmount = "";
        this.setState({ [name]: value, addExpenseError });
        break;
      case "description":
        value.trim().length > 3000
          ? (addExpenseError.description =
              "Description length should be less than 3000 character")
          : (addExpenseError.description = "");
        this.setState({ addExpenseError }, () => {
          if (this.state.addExpenseError.description === "") {
            this.setState({ [name]: value.trim() });
          }
        });
        break;
      default:
        break;
    }
  };
  /**
   * @author Benakesh
   * METHOD TO ADD NOTE
   */
  addNoteHandler = () => {
    if (this.state.addNoteTab.trim() === "") {
      this.setState({ addNoteTabError: "Please add a note" });
    } else {
      if (this.state.dateId && this.state.dateId !== "") {
        let data = {
          notes: this.state.addNoteTab,
          dateId: this.state.dateId,
          timeZone: this.state.timeZoneName,
        };
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .put(
            `/recurringTask/${this.state.data_taskId}/${this.state.taskId}`,
            data
          )
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                addNoteTab: "",
                addNoteTabError: "",
                timelines: response.data,
              });
              var props = {
                message: "Status Updated Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              window.location = `/task/${response?.createdTaskId}`;
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          });
      } else {
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .post("/task/notes", {
            task_id: this.state.taskId,
            notes: this.state.addNoteTab,
          })
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                addNoteTab: "",
                addNoteTabError: "",
                timelines: response.data,
              });
              var props = {
                message: "Added Note Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.componentDidMount();
            } else if (
              response &&
              (response.status === "400" || response.status === 400)
            ) {
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
              this.setState({
                addNoteTab: "",
                addNoteTabError: "",
              });
            } else if (
              response &&
              (response.status === "401" || response.status === 401)
            ) {
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
              this.setState({
                addNoteTab: "",
                addNoteTabError: "",
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (
              error &&
              (error.status === "500" || error.status === 500)
            ) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          });
      }
    }
  };
  /**
   * @author Benakesh
   * @param {TARGET ELEMENT} e
   * ON AUTOCOMPLETE FIELD VALUE CHANGE
   */
  onAutoCompleteChange = (e) => {
    var { name, value } = e.target;
    switch (name) {
      case "accountName":
        this.fetchAutoCompleteList(
          "accountName",
          value,
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6005
            : "account",
          "/account-filter"
        );
        break;
      case "departmentName":
        this.fetchAutoCompleteList(
          "departmentName",
          value,
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6005
            : "account",
          "/account/department"
        );
        break;
      case "serviceName":
        this.fetchAutoCompleteList(
          "serviceName",
          value,
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6007
            : "service",
          "/getServiceFilter"
        );

        break;
      case "productName":
        this.fetchAutoCompleteList(
          "productName",
          value,
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "/productFilter"
        );
        break;
      case "assignToName":
        this.fetchAutoCompleteList(
          "assignToName",
          value,
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "/employeeFilterStatus"
        );
        break;
      default:
        break;
    }
  };

  /**
   * @author Benakesh
   * @param {FIELD NAME} name
   * @param {FIELD VALUE} value
   * @param {PORT NUMBER} port
   * @param {API ENDPOINT} apiname
   * COMMON API TO FETCH DATA ON AUTOCOMPLETE CHANGE
   */
  fetchAutoCompleteList = async (name, value, port, apiname) => {
    var { accountId } = this.state;
    var data = {};
    if (name === "departmentName") {
      data.deptName = value;
      data.accountId = accountId;
    } else if (name === "accountName") {
      data.accountName = value;
    } else if (name === "serviceName") {
      data.search = value;
    } else if (name === "productName") {
      data.search = value;
    } else if (name === "assignToName") {
      data.search = value;
      data.startDate = new Date(
        this.state.dateRangeProps.startDate.initialViewDate
      ).toUTCString();
      data.endDate = new Date(
        this.state.dateRangeProps.endDate.initialViewDate
      ).toUTCString();
    }
    AxiosInstance(port, "application/json")
      .post(apiname, data)
      .then(async (resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          if (name === "accountName") {
            await this.setAccountState(response, value);
          } else if (name === "departmentName") {
            await this.setDepartmentState(response, value);
          } else if (name === "serviceName") {
            await this.setServiceState(response, value);
          } else if (name === "productName") {
            await this.setProductState(response, value);
          } else if (name === "assignToName") {
            await this.setAssigneeState(response);
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  AWS_fetchAutoCompleteList = async (name, value, port, apiname) => {
    var { accountId } = this.state;
    // await this.setState({ [name]: value });
    var data = {};
    if (name === "departmentName") {
      data.deptName = value;
      data.accountId = accountId;
    } else if (name === "accountName") {
      data.accountName = value;
    } else if (name === "serviceName") {
      data.search = value;
    } else if (name === "productName") {
      data.search = value;
    } else if (name === "assignToName") {
      data.search = value;
      data.startDate = new Date(
        this.state.dateRangeProps.startDate.initialViewDate
      ).toUTCString();
      data.endDate = new Date(
        this.state.dateRangeProps.endDate.initialViewDate
      ).toUTCString();
    }
    AWS_AxiosInstance(port, "application/json")
      .post(apiname, data)
      .then(async (resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          if (name === "accountName") {
            await this.setAccountState(response, value);
          } else if (name === "departmentName") {
            await this.setDepartmentState(response, value);
          } else if (name === "serviceName") {
            await this.setServiceState(response, value);
          } else if (name === "productName") {
            await this.setProductState(response, value);
          } else if (name === "assignToName") {
            await this.setAssigneeState(response);
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Benakesh
   * @param {API RESPONSE} response
   * @param {FIELD VALUE} value
   * TO SET ASSIGNEE DATA
   */
  setAssigneeState = (response) => {
    var assignee = [{ _id: "", name: "Unassigned" }];
    response.data.map((assigne) => {
      var assigneObj = {};
      assigneObj._id = assigne._id;
      assigneObj.name = assigne.name;
      assigneObj.status = assigne.status;
      assigneObj.default = assigne.default;
      if (assigne.hasOwnProperty("profilePath")) {
        assigneObj.profilePath = assigne.profilePath;
      } else {
        assigneObj.profilePath = "";
      }
      assignee.push(assigneObj);
    });
    this.setState({
      assignToOption: assignee,
    });
  };
  /**
   * @author Benakesh
   * @param {API RESPONSE} response
   * @param {FIELD VALUE} value
   * TO SET PRODUCT STATE
   */
  setProductState = (response, value) => {
    if (response.data.length === 0) {
      this.setState({
        newProduct: true,
        productOptions: [
          {
            _id: "one",
            name: "",
          },
        ],
      });
    } else {
      var prods = [];
      response.data.map((prod) => {
        var prodObj = {};
        prodObj._id = prod._id;
        prodObj.name = prod.name;
        prods.push(prodObj);
      });
      let productArray = [];
      let productData = this.state.products;
      if (this.state.products.length > 0) {
        productArray = prods.filter(
          (obj1) => !productData.some((obj2) => obj2.productId === obj1._id)
        );
        this.setState({
          productFilter: productArray,
        });
      } else {
        this.setState({
          productFilter: prods,
        });
      }
      this.setState({
        newProduct: false,
        productOptions: this.state.productFilter,
        isProducts: true,
      });
    }
  };
  /**
   * @author Benakesh
   * @param {API RESPONSE} response
   * @param {FIELD VALUE} value
   * TO SET ACCOUNT STATE
   */
  setAccountState = (response, value) => {
    var accounts = [];
    response.data.map((account) => {
      var acc = {};
      acc._id = account._id;
      acc.name = account.accountName;
      accounts.push(acc);
    });
    this.setState({
      account: accounts,
    });
    var data = this.state.account.filter((item) => {
      return item.name === value;
    });
    if (data.length > 0) {
      data.map((accountData) => {
        this.setState({
          accountName: accountData.name,
          accountId: accountData.id,
        });
      });
    }
  };
  /**
   * @author Benakesh
   * @param {API RESPONSE} response
   * @param {FIELD VALUE} value
   * TO SET DEPARTMENT STATE
   */
  setDepartmentState = async (response, value) => {
    var depts = [];
    await response.finalDept[0].departmentsDetails.map((dept) => {
      var dep = {};
      dep._id = dept.id;
      dep.name = dept.name;
      depts.push(dep);
    });
    await this.setState({
      department: depts,
      isTrue: true,
    });

    var data = this.state.department.filter((item) => {
      return item.name === value;
    });
    if (data.length > 0) {
      data.map((deptData) => {
        this.setState({
          departmentName: deptData.name,
          departmentId: deptData.id,
        });
      });
    }
  };
  /**
   * @author Benakesh
   * @param {API RESPONSE} response
   * @param {FIELD VALUE} value
   * TO SET SERVICE STATE
   */
  setServiceState = async (response, value) => {
    var serviceArray = [];
    await response.data.map((service) => {
      var serviceObj = {};
      serviceObj._id = service._id;
      serviceObj.name = service.name;
      serviceArray.push(serviceObj);
    });
    await this.setState({
      service: serviceArray,
    });
    var data = await this.state.service.filter((item) => {
      return item.name === value;
    });
    if (data.length > 0) {
      data.map((serviceData) => {
        this.setState({
          serviceName: serviceData.name,
          serviceId: serviceData._id,
        });
      });
    }
  };

  onDepartmentChanged = (e, value, reason) => {
    if (reason === "clear") {
      this.setState({
        isTrue: false,
      });
    }
  };
  /**
   * @author Benakesh
   * @param {TARGET ELEMENT} e
   * TO SET STATE ON AUTOCOMPLETE SELECT BASED ON FIELD NAME
   */
  onAutoCompleteSelect = (e) => {
    if (!e.target.value) {
      this.setState({
        newAssigneeId: "",
        newAssigneeName: "",
      });
      this.setState({
        productId: "",
        productName: "",
      });
      this.onAutoCompleteChange(e);
    } else {
      var { name, value } = e.target;
      var { service, account, department, productOptions, assignToOption } =
        this.state;
      if (name === "accountName") {
        var data = account.filter((item) => {
          return item.name === value;
        });
        data.map((accountData) => {
          this.setState({
            accountId: accountData._id,
            accountName: accountData.name,
            departmentName: "",
            departments: [],
          });
        });
      } else if (name === "productName") {
        var datas = productOptions.filter((item) => {
          return item.name === value;
        });
        datas.map((prodData) => {
          this.setState({
            productId: prodData._id,
            productName: prodData.name,
          });
        });
      } else if (name === "serviceName") {
        var datass = service.filter((item) => {
          return item.name === value;
        });
        datass.map((serviceData) => {
          this.setState({
            serviceId: serviceData._id,
            serviceName: serviceData.name,
          });
        });
      } else if (name === "departmentName") {
        var data2 = department.filter((item) => {
          return item.name === value;
        });
        data2.map((departmentData) => {
          this.setState({
            departmentId: departmentData._id,
            departmentName: departmentData.name,
            departments: data2,
          });
        });
      } else if (name === "assignToName") {
        var rules = this.state.taskSchedules;
        var data1 = assignToOption.filter((item) => {
          return item.name === value;
        });
        data1.map((assigneeData) => {
          this.setState(
            {
              newAssigneeId: assigneeData._id,
              newAssigneeName: assigneeData.name,
              profile_path: assigneeData.profilePath,
            },
            () => {
              if (value !== this.state.taskAssine) {
                if (rules.isRecurring == true) {
                  this.setState({
                    reassignTaskOpen: true,
                  });
                } else {
                  this.onUpdateAssignee();
                }
              }
            }
          );
        });
      }
    }
  };

  clearProduct = (e, value, reason) => {
    if (reason === "clear") {
      this.setState({
        productName: "",
      });
    }
  };

  /**
   * @author Benakesh
   * TO CLOSE UPDATE ACCOUNT DIALOG
   */
  changeAccountDialogClose = () => {
    this.setState({ changeAccountDialog: !this.state.changeAccountDialog });
  };
  clearFieldValueHandler = (e, val, reason) => {
    if (reason === "clear" || reason === "reset") {
      this.setState({
        departmentClear: true,
        departmentName: "",
        departments: [],
        department: [
          {
            _id: "",
            name: "",
          },
        ],
      });
    }
  };

  clearDepartmentHandler = (e, val, reason) => {
    if (reason === "clear" || reason === "reset") {
      this.setState({
        departmentClear: true,
        departmentName: "",
      });
    }
  };

  /**
   * @author Benakesh
   * TO UPDATE ACCOUNT DATA FROM ACCOUNT DIALOG
   */
  updateAccountData = () => {
    this.updateHandler("isAccount");
  };
  /**
   * @author Benakesh
   * TO RESET ACCOUNT AND DEPARTMENT STATE ON ACCOUNT DIALOG CLOSE
   */
  resetState = () => {
    this.setState({
      isTrue: false,
      account: [
        {
          _id: "",
          name: "",
        },
      ],
      department: [
        {
          _id: "",
          name: "",
        },
      ],
      departments: [
        {
          _id: "",
          name: "",
        },
      ],
      isAccount: true,
    });
  };
  /**
   * @author Benakesh
   * @param {TAB KEY} key
   * TO HIDE CONTENT BASED ON TAB
   */
  onActiveTabChange = (key) => {
    this.setState({
      activeTab: key,
    });
  };
  /**
   * @author Benakesh
   * @param {PRODUCT ROW} row
   * TO EDIT THE PRODUCT
   */
  onProductEdit = (row) => {
    this.setState(
      {
        productEditItem: row,
        productName: row.name,
        productId: row.productId,
        price: row.price,
        quantity: row.quantity,
        productOptions: [
          {
            _id: row._id,
            name: row.name,
          },
        ],
      },
      () => {
        this.setState({
          onEditDialogOpen: true,
        });
      }
    );
  };
  /**
   * @author Benakesh
   * @param {PRODUCT ROW ID} id
   * TO EDIT PRODUCT ON CLICK OD UPDATE
   */
  onEditDialogUpdate = (id) => {
    var { productId, quantity, taskId, price, editProductError } = this.state;

    if (this.state.priceEnable && this.state.quantityEnable) {
      if (validator.isEmpty(price)) {
        editProductError.price = "Please enter the product price";
      }
      if (price && !/^(?!0+$)\d{1,6}(\.\d{1,2})?$/.test(price)) {
        editProductError.price = "Please enter the valid price. e.g: $2,500";
      }
      if (validator.isEmpty(quantity)) {
        editProductError.quantity = "Please enter the product quantity";
      }
      if (quantity && !/^(?!0+$)\d{1,3}$/.test(quantity)) {
        editProductError.quantity =
          "Please enter the valid Quantity. e.g: 50, 10..etc";
      }
      if (quantity === 0 || quantity === "0") {
        editProductError.quantity =
          "Quantity should not be 0,undefined,or null";
      }
    } else {
      editProductError.price = "";
      editProductError.quantity = "";
      editProductError.productNew = "";
    }
    if (validator.isEmpty(productId)) {
      editProductError.productNew = "Please select the product";
    }

    this.setState(
      {
        editProductError,
      },
      () => {
        if (
          this.state.editProductError.price === "" &&
          this.state.editProductError.quantity === "" &&
          this.state.editProductError.productNew === ""
        ) {
          AxiosInstance(
            process.env.REACT_APP_NODE_ENV === "development" ||
              process.env.REACT_APP_NODE_ENV === "qa"
              ? 6008
              : "task",
            "application/json"
          )
            .post("/task/products_edit", {
              task_id: taskId,
              price: parseFloat(price),
              quantity: parseInt(quantity),
              productId: productId,
              productsId: id,
            })
            .then((resp) => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                this.setState(
                  {
                    products: response.data,
                    isProducts: true,
                    productName: "",
                    productOptions: [],
                  },
                  () => {
                    this.onEditDialogClose();
                    var props = {
                      message: "Status Updated Successfully",
                      icon: <img src={iconNotificationSuccess} alt="success" />,
                      type: "success",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification update-notify",
                    };
                    NotificationComponent(props);
                  }
                );
              } else {
                this.setState({
                  isProducts: true,
                });
                var propsErr = {
                  message: "Update Status Failed",
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propsErr);
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
            });
        }
      }
    );
  };
  /**
   * @author Benakesh
   * ON EDIT DIALOG CLOSE
   */
  onEditDialogClose = () => {
    this.setState({
      onEditDialogOpen: !this.state.onEditDialogOpen,
    });
  };
  /**
   * @author Benakesh
   * ON EDIT DIALOG CLOSE
   */
  onEditDialogExit = () => {
    this.setState({
      editProductError: {
        price: "",
        quantity: "",
        productNew: "",
      },
      isProducts: false,
    });
  };
  //recurring reassign for reps
  onUpdateAssigneeRecurrings = (data, newAssigneeName) => {
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .post("/assign_task", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState(
            {
              isAssignee: true,
              taskAssignee: this.state.newAssigneeName,
              newAssigneeId: "",
              newAssigneeName: "",
              reassignTaskOpen: false,
            },
            () => {
              this.componentDidMount();
            }
          );
          var props = {
            message: "Task Assigned Successfully",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          this.setState({
            isAssignee: true,
            newAssigneeId: "",
            newAssigneeName: "",
          });
          var propsErr = {
            message: "Failed To Assign Task",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
    // }
  };
  //recurring reassign for single task

  onUpdateAssigneeRecurring = (datas, taskId, recurringId) => {
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .put(`/recurringTask/${recurringId}/${taskId}`, datas)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
          this.setState({
            isRecurring: false,
            taskId: response.createdTaskId,
            reassignTaskOpen: false,
          });
          this.props.history.replace(`/task/${response.createdTaskId}`);
          // window.location.href = `/task/${response.createdTaskId}`;
          this.componentDidMount();
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          var props1 = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props1);
        } else {
          var props2 = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props2);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  //

  /**new date picker code start*/

  valid = (current) => {
    return current && current < moment().subtract(1, "day");
  };
  valids = (current) => {
    return current && current > moment();
  };

  disabledDates = (current) => {
    return (
      current &&
      current.isBefore(
        moment(this.state.dateRangePropsNew.startDate.initialViewDate)
      )
    ); //.subtract(1, "day"));
  };
  disabledDatess = (current) => {
    return (
      current &&
      current.isAfter(
        moment(this.state.actualdateRangePropsNew.endDate.initialViewDate)
      )
    ); //.subtract(1, "day"));
  };
  disabledDatesBefore = (current) => {
    return (
      current &&
      current.isBefore(
        moment(this.state.actualdateRangePropsNew.startDate.initialViewDate)
      )
    );
  };
  disabledNewDates = (current) => {
    return current.isAfter(
      moment(this.state.dateRangePropsNew.startDate.initialViewDate).subtract(
        1,
        "day"
      )
    );
  };
  disabledNewDatess = (current) => {
    return current.isAfter(
      moment(
        this.state.actualdateRangePropsNew.startDate.initialViewDate
      ).subtract(1, "day")
    );
  };

  onStartDateChange = (date, dateString) => {
    const { dateRangePropsNew } = this.state;
    dateRangePropsNew.startDate.initialViewDate = momentTimeZone(date);
    dateRangePropsNew.startDate.inputProps.value = momentTimeZone(date);
    dateRangePropsNew.endDate.inputProps.disabled = false;
    dateRangePropsNew.endDate.inputProps.value = "";
    this.setState({
      dateRangePropsNew,
    });
  };
  onStartDateChanges = (date, dateString) => {
    const { actualdateRangePropsNew } = this.state;
    actualdateRangePropsNew.startDate.initialViewDate = momentTimeZone(date);
    actualdateRangePropsNew.startDate.inputProps.value = momentTimeZone(date);
    actualdateRangePropsNew.endDate.inputProps.disabled = false;
    actualdateRangePropsNew.endDate.inputProps.value = "";
    this.setState({
      actualdateRangePropsNew,
    });
  };
  onEndDateChange = (date, dateString) => {
    const { dateRangePropsNew } = this.state;
    dateRangePropsNew.endDate.initialViewDate = momentTimeZone(date);
    dateRangePropsNew.endDate.inputProps.value = momentTimeZone(date);
    this.setState({
      dateRangePropsNew,
      isStartDateEdit: true,
      isStartDateEdits: true,
      editEndDate: true,
      editEndDates: true,
    });
  };
  onEndDateChanges = (date, dateString) => {
    const { actualdateRangePropsNew } = this.state;
    actualdateRangePropsNew.endDate.initialViewDate = momentTimeZone(date);
    actualdateRangePropsNew.endDate.inputProps.value = momentTimeZone(date);
    this.setState({
      actualdateRangePropsNew,
      isStartDateEdits: true,
      editEndDate: true,
      editEndDates: true,
    });
  };
  onEndDateNewChange = (value) => {
    const { dateRangePropsNew } = this.state;
    dateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(value)
      .tz(this.state.timeZoneName)
      .format("MM-DD-YYYY hh:mm A");
    dateRangePropsNew.endDateNew.inputProps.value = momentTimeZone(value)
      .tz(this.state.timeZoneName)
      .format("MM-DD-YYYY hh:mm A");
    this.setState({
      dateRangePropsNew,
      isStartDateEdit: false,
      isStartDateEdits: false,
      editEndDates: false,
    });
  };
  onEndDateNewChanges = (value) => {
    const { actualdateRangePropsNew } = this.state;
    actualdateRangePropsNew.endDateNew.initialViewDate = momentTimeZone(value)
      .tz(this.state.timeZoneName)
      .format("MM-DD-YYYY hh:mm A");
    actualdateRangePropsNew.endDateNew.inputProps.value = momentTimeZone(value)
      .tz(this.state.timeZoneName)
      .format("MM-DD-YYYY hh:mm A");
    this.setState({
      actualdateRangePropsNew,
      isStartDateEdits: false,
      editEndDates: false,
    });
  };

  /** new date picker code end */

  /**
   * @author Benakesh
   * RENDER TASK DETAIL PAGE
   */

  handlechecked = (event) => {
    this.setState(
      {
        isEmergency: event.target.checked,
      },

      () => {
        var data = {
          task_id: this.state.taskId,
          isEmergency: this.state.isEmergency,
          timeZone: this.state.timeZoneName,
        };
        if (this.state.isRecurring === true) {
          delete data.task_id;
          data["dateId"] = this.state.dateId;
          AxiosInstance(
            process.env.REACT_APP_NODE_ENV === "development" ||
              process.env.REACT_APP_NODE_ENV === "qa"
              ? 6008
              : "task",
            "application/json"
          )
            .put(
              `/recurringTask/${this.state.recurringTaskId}/${this.state.taskId}`,
              data
            )
            .then((resp) => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                var props = {
                  message: response.msg,
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "success",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification update-notify",
                };
                NotificationComponent(props);
                this.setState({
                  isRecurring: false,
                  taskId: response.createdTaskId,
                });
                this.props.history.replace(`/task/${response.createdTaskId}`);
                // window.location.href = `/task/${response.createdTaskId}`;
                this.componentDidMount();
              } else if (
                response &&
                (response.status === "400" || response.status === 400)
              ) {
                var props1 = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(props1);
              } else {
                var props2 = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(props2);
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
            });
        } else {
          AxiosInstance(
            process.env.REACT_APP_NODE_ENV === "development" ||
              process.env.REACT_APP_NODE_ENV === "qa"
              ? 6008
              : "task",
            "application/json"
          )
            .put("/task/edit", data)
            .then((resp) => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                var props = {
                  message: response.msg,
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "success",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification update-notify",
                };
                NotificationComponent(props);
                this.componentDidMount();
              } else if (
                response &&
                (response.status === "400" || response.status === 400)
              ) {
                var props1 = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(props1);
              } else {
                var props2 = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(props2);
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
            });
        }
      }
    );
  };

  DatesizeIncrease = (e) => {
    if (e === true) {
      setTimeout(() => {
        this.setState({ DatesizeIncrease: "auto_incress" });
      }, 100);
    } else if (e === false) {
      this.setState({ DatesizeIncrease: "" });
    }
  };
  DatesizeDecreas = (e) => {
    if (e === true) {
      setTimeout(() => {
        this.setState({ DatesizeIncrease: "auto_incress" });
      }, 100);
    } else if (e === false) {
      this.setState({ DatesizeIncrease: "" });
    }
  };
  DatesizeDecreass = (e) => {
    if (e === true) {
      setTimeout(() => {
        this.setState({ DatesizeIncreases: "auto_incress" });
      }, 100);
    } else if (e === false) {
      this.setState({ DatesizeIncreases: "" });
    }
  };
  onCloseModal = () => {
    this.setState({ showaddModal: false, showdelete: false });
  };
  onDeleteHandleClick = (row) => {
    this.setState({
      deletetaskId: row._id,
      deleteproductId: row.productId,
      showdelete: true,
    });
  };
  onDelete = () => {
    this.setState({ disable: true });
    var productData = {
      taskId: this.state.taskId,
      productId: this.state.deleteproductId,
    };
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/task/removeProduct", productData)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false,
            },
            () => {
              var props = {
                message: res.data.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.componentDidMount();
        } else {
          this.setState({ disable: false, loading: false });
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      });
  };
  deleteReps = (index) => {
    this.setState({ showdeleteReps: true });
  };
  onhandleClose = () => {
    this.setState({
      showdeleteReps: false,
    });
    this.componentDidMount();
  };
  //recurring code
  onReassignPopupClose = () => {
    this.setState(
      {
        isAssignee: true,
        taskAssignee: this.state.newAssigneeName,
        taskAssine: this.state.taskAssine,
        taskAssigneeValue: this.state.taskAssigneeValue[0],
        newAssigneeId: "",
        newAssigneeName: "",
        reassignTaskOpen: false,
      },
      () => {
        this.componentDidMount();
      }
    );
  };
  //recurring cancel
  onCancelPopupClose = () => {
    this.setState({
      cancelTaskOpen: false,
    });
  };
  onPopupClose = () => {
    this.setState({
      addRepTaskOpen: false,
    });
  };
  removePopupClose = () => {
    this.setState({
      removeRepTaskOpen: false,
    });
  };
  //reassign recurring task
  reassignTaskHandler = (item) => {
    var { taskId, recurringTaskId, newAssigneeId, newAssigneeName, dateId } =
      this.state;
    var data = {
      timezone: this.state.timeZoneName,
    };
    if (newAssigneeId !== "" && newAssigneeName !== "") {
      data.assignedTo = newAssigneeId;
      data.taskStatus = "Pending";
    } else {
      data.taskStatus = "Unassigned";
    }
    if (item == "recurrenceOnce") {
      if (this.state.dateId) {
        let datas = {};
        if (newAssigneeId !== "" && newAssigneeName !== "") {
          datas.assignedTo = newAssigneeId;
          datas.taskStatus = "Pending";
          datas.dateId = dateId;
          datas.timeZone = this.state.timeZoneName;
        } else {
          datas.taskStatus = "Unassigned";
          datas.dateId = dateId;
          datas.timeZone = this.state.timeZoneName;
        }
        this.onUpdateAssigneeRecurring(datas, taskId, recurringTaskId);
      } else {
        let assignValue = {};
        if (newAssigneeId !== "" && newAssigneeName !== "") {
          assignValue.assignedTo = newAssigneeId;
          assignValue.taskStatus = "Pending";
          assignValue._id = taskId;
          assignValue.timezone = this.state.timeZoneName;
        } else {
          var taskId = this.state.dateId ? recurringTaskId : taskId;
          assignValue.taskStatus = "Unassigned";
          assignValue._id = taskId;
          assignValue.timezone = this.state.timeZoneName;
        }
        this.onUpdateAssigneeRecurrings(assignValue, newAssigneeName);
      }
    } else {
      var taskId = this.state.dateId ? recurringTaskId : taskId;
      data.recurringType = true;
      data._id = taskId;
      this.onUpdateAssigneeRecurrings(data, newAssigneeName);
    }
  };
  //cancel recurring task
  cancelTaskHandler = (item) => {
    this.setState({
      cancelValue: item,
    });
    var data;
    if (item == "recurrenceOnce") {
      if (this.state.dateId) {
        data = {
          cancelReason: this.state.cancelValue,
          notes: this.state.cancelledTaskNote,
          dateId: this.state.dateId,
          timeZone: this.state.timeZoneName,
          taskStatus: "cancelled",
        };
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/x-www-form-urlencoded; charset=UTF-8"
        )
          .put(
            `/recurringTask/${this.state.recurringTaskId}/${this.state.taskId}`,
            data
          )
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                cancelTaskOpen: false,
                onCancelTaskDialogOpen: false,
              });
              var propsErr = {
                message: "Task Updated Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(propsErr);
              this.props.history.replace(`/task/${response.createdTaskId}`);
              this.componentDidMount();
            } else {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
              this.setState({
                cancelTaskOpen: false,
                onCancelTaskDialogOpen: false,
              });
            }
          });
      } else {
        data = {
          cancelReason: this.state.cancelValue,
          note: this.state.cancelledTaskNote,
          _id: this.state.taskId,
          timeZone: this.state.timeZoneName,
          taskStatus: "cancelled",
        };
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .post("/assign_task", data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                cancelTaskOpen: false,
                onCancelTaskDialogOpen: false,
              });
              var props = {
                message: "First recurrence  Updated Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.componentDidMount();
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
              this.setState({
                cancelTaskOpen: false,
                onCancelTaskDialogOpen: false,
              });
              this.componentDidMount();
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (
              error &&
              (error.status === "500" || error.status === 500)
            ) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          });
      }
    } else {
      data = {
        cancelReason: this.state.cancelValue,
        notes: this.state.cancelledTaskNote,
        timeZone: this.state.timeZoneName,
      };
      var cancelId = this.state.dateId
        ? this.state.recurringTaskId
        : this.state.taskId;
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6008
          : "task",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .post(`/recurringTask/cancelTask/${cancelId}`, { data })
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            this.setState({
              cancelTaskOpen: false,
              onCancelTaskDialogOpen: false,
            });
            var propsErr = {
              message: "Task Updated Successfully",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(propsErr);
            this.props.history.replace(`/task`);
          } else {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
            this.setState({
              cancelTaskOpen: false,
              onCancelTaskDialogOpen: false,
            });
          }
        });
    }
  };
  ondeleteReps = (data) => {
    this.setState({
      isSubmit: true,
      showdeleteReps: false,
    });
    var { id } = this.props.match.params;
    if (this.state.taskSchedules.isRecurring === true) {
      this.setState({
        removePopupOpen: true,
      });
    } else {
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6008
          : "task",
        "application/json"
      )
        .put(`/task/removeAdditionalRep/${id}`, {
          employeeId: data,
          timeZone: this.state.timeZoneName,
        })
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            this.setState({
              isSubmit: false,
              selectedAdditionalReps: "",
            });

            var props = {
              message: "Removed Successfully",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(props);
            this.componentDidMount();
            window.location.href = `/task/${response.data[0]._id}`;
          } else if (
            response &&
            (response.status === "400" || response.status === 400)
          ) {
            this.setState({
              isSubmit: false,
            });
            var propsErr = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };

  ondeleteRepresentatives = (data, employeeId) => {
    this.setState({
      isSubmit: true,
      showdeleteReps: false,
      popupOpen: false,
    });
    var { id } = this.props.match.params;
    if (data == "one_task") {
      if (this.state.dateId) {
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .put(`/recuringTask/removeAdditionalRep/${this.state.data_taskId}`, {
            employeeId: employeeId,
            dateId: this.state.dateId,
            timeZone: this.state.timeZoneName,
          })
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                isSubmit: false,
              });
              var props = {
                message: "Removed Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              window.location.href = `/task/${response.data._id}`;
              this.initialFetchRepDetails(response.data._id);
            } else if (
              response &&
              (response.status === "400" || response.status === 400)
            ) {
              this.setState({
                isSubmit: false,
              });
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      } else {
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .put(`/task/removeAdditionalRep/${id}`, {
            employeeId: employeeId,
            timeZone: this.state.timeZoneName,
          })
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                isSubmit: false,
              });

              var props = {
                message: "Removed Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.onhandleClose(false);
              window.location.href = `/task/${response.data[0]._id}`;
              this.initialFetchRepDetails(response.data[0]._id);
            } else if (
              response &&
              (response.status === "400" || response.status === 400)
            ) {
              this.setState({
                isSubmit: false,
              });
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      }
    } else {
      let taskDetailId = id;
      if (this.state.dateId) {
        id = this.state.data_taskId;
      }
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6008
          : "task",
        "application/json"
      )
        .put(`/task/removeAdditionalRep/${id}`, {
          employeeId: employeeId,
          recurringTask: true,
          timeZone: this.state.timeZoneName,
        })
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            this.setState({
              isSubmit: false,
            });

            var props = {
              message: "Removed Successfully",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(props);
            this.initialFetchRepDetails(id);
            if (this.state.dateId) {
              window.location.href = `/task/${taskDetailId}?dateId=${this.state.dateId}&status=AutoGenerated`;
            } else {
              window.location.href = `/task/${response.data[0]._id}`;
            }
          } else if (
            response &&
            (response.status === "400" || response.status === 400)
          ) {
            this.setState({
              isSubmit: false,
            });
            var propsErr = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };

  onMultipleAttendeeSearch = (value) => {
    this.setState(
      {
        selectedAdditionalReps: "",
      },
      () => {
        this.setState({
          selectedAdditionalReps: value,
        });
      }
    );
  };
  onMultipleAttendeeOnFocusHandler = (e) => {
    var search = e.target.value;
    this.getRepresentativeFilter(search);
  };
  getRepresentativeFilter = (search) => {
    let data = {
      limit: false,
      acountId: this.accountId,
      startDate: this.startDate,
      endDate: this.endDate,
    };
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/json"
    )
      .post("/employeeFilterStatus", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var tempPropData = [...this.state.additionalRepData];
          tempPropData.push({ additionalRepId: this.state.taskAssigneeId });
          var arrayData = response.data.filter(
            (value, index, self) =>
              tempPropData.findIndex(
                (search_value) =>
                  JSON.stringify(search_value.additionalRepId) ===
                  JSON.stringify(value._id)
              ) < 0
          );
          this.setState({
            additionalReps: arrayData,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            additionalReps: [],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
        this.setState({
          additionalReps: [],
        });
      });
  };
  popupClose = () => {
    setTimeout(() => {
      this.setState({
        popupOpen: false,
      });
    }, 100);
  };
  removePopupClose = () => {
    setTimeout(() => {
      this.setState({
        removePopupOpen: false,
      });
    }, 100);
  };
  addRepHandler = (item) => {
    // var notes = this.state.taskMessage;
    var { taskId, selectedAdditionalReps } = this.state;
    var data = {
      timeZone: this.state.timeZoneName,
    };
    if (selectedAdditionalReps !== "") {
      data.additionalRepresentative = selectedAdditionalReps;
      data.taskStatus = "Pending";
      data._id = taskId;
    } else {
      data.taskStatus = "Unassigned";
      data._id = taskId;
    }
    if (item == "one_task") {
      if (this.state.dateId) {
        data.dateId = this.state.dateId;
        delete data._id;
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .put(`/recurringTask/${this.state.data_taskId}/${taskId}`, data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState(
                {
                  // taskStatus: selectedStatus,
                  updateStatusDialogMenu: false,
                  showaddModal: false,
                  selectedStatus: "",
                  addNote: "",
                },
                () => {
                  this.componentDidMount();
                }
              );
              var props = {
                message: "Additional Representative added Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.onCloseHandle(false);
              this.props.history.replace(`/task/${response.createdTaskId}`);
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          });
      } else {
        data.task_id = taskId;
        AxiosInstance(
          process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "qa"
            ? 6008
            : "task",
          "application/json"
        )
          .put("/task/edit", data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                additionalReps: response.data,
              });
              var props = {
                message: "Additional Representative added Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.onCloseHandle(false);
              this.componentDidMount();
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              let propserror = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                additionalReps: [],
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (
              error &&
              (error.status === "500" || error.status === 500)
            ) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
            this.setState({
              additionalReps: [],
            });
          });
      }
    } else {
      let data = {
        task_id: this.state.data_taskId ? this.state.data_taskId : taskId,
        additionalRepresentative: this.state.selectedAdditionalReps,
        addReps: true,
        timeZone: this.state.timeZoneName,
      };
      data.recurringTask = true;
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6008
          : "task",
        "application/json"
      )
        .put("/task/edit", data)
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            this.setState({
              additionalReps: response.data,
            });
            this.onCloseHandle(false);
            this.componentDidMount();
            var props = {
              message: "Additional Representative added Successfully",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(props);
          } else if (
            (response && response.status === "400") ||
            response.status === 400
          ) {
            let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            this.setState({
              additionalReps: [],
            });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
          this.setState({
            additionalReps: [],
          });
        });
    }
  };
  onSubmit = () => {
    //adding additional reps for previously created task
    let data = {
      task_id: this.state.taskId,
      additionalRepresentative: this.state.selectedAdditionalReps,
      addReps: true,
    };
    if (this.state.taskSchedules.isRecurring === true) {
      if (data.additionalRepresentative.length > 0) {
        this.setState({
          popupOpen: true,
        });
      }
    } else {
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6008
          : "task",
        "application/json"
      )
        .put("/task/edit", data)
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            this.setState({
              additionalReps: response.data,
            });
            this.onCloseHandle(false);
            this.componentDidMount();
          } else if (
            (response && response.status === "400") ||
            response.status === 400
          ) {
            let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            this.setState({
              additionalReps: [],
            });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
          this.setState({
            additionalReps: [],
          });
        });
    }
  };
  onCloseHandle = (data) => {
    this.setState({
      show: data,
    });
  };
  onSelectServiceHandler = (e) => {
    if (e.target.value) {
      this.setState({
        customCancelValue: true,
        cancelValue: e.target.value,
      });
    } else {
      this.setState({
        customCancelValue: false,
        cancelValue: "",
      });
    }
  };
  serviceListHandlers = (e) => {
    console.log(e);
  };
  handleGoBack = () =>{
    this.props.history.goBack();
  }

  render() {
    var statusOne = [{ id: "3", value: "uncovered", name: "Uncovered" }];

    var hideEdit = "";
    if (
      this.state.taskStatus.toLowerCase() === "cancelled" ||
      this.state.taskStatus.toLowerCase() === "completed" ||
      this.state.taskStatus.toLowerCase() === "uncovered"
    ) {
      hideEdit = "none";
    } else {
      hideEdit = "block";
    }

    var statusOnePending = [
      { id: "1", value: "assigned", name: "Accept" },
      { id: "2", value: "rejected", name: "Reject" },
      { id: "3", value: "uncovered", name: "Uncovered" },
    ];
    // taskAssignee taskStatus
    var statusTwo = [
      { id: "1", value: "inprogress", name: "In Progress" },
      { id: "2", value: "uncovered", name: "Uncovered" },
      { id: "3", value: "completed", name: "Completed" },
    ];
    var statusThree = [{ id: "1", value: "completed", name: "Completed" }];

    const { taskName } = this.state;
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <div>
          <div className="body-bg-color add_company_dialog task_detail_dialog">
            {/* <Suspense fallback={<div></div>}> */}
            <HeaderComponent />
            {/* </Suspense> */}

            <Suspense fallback={<div></div>}>
              <ScheduledConfirmationModel
                modelData={this.state}
                open={this.state.showaddModal}
                handleClose={this.onCloseModal}
                onInprogress={this.onInprogress}
              />

              <Dialog
                open={this.state.showdelete}
                className="deleteServiceDialog"
                TransitionComponent={TransitionGrow}
                transitionDuration={600}
              >
                <DialogTitle>
                  {" "}
                  <center>Remove Product</center>{" "}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <center>
                      {" "}
                      Are you sure you want to Remove this Product? This action
                      cannot be undone.
                    </center>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="cancel"
                    onClick={this.onCloseModal}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={this.state.disable}
                    className="delete"
                    onClick={() => this.onDelete()}
                    style={{ color: "red" }}
                    autoFocus
                  >
                    {this.state.disable ? <CircularProgress size={20} /> : ""}{" "}
                    Remove
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                onExit={this.onCancelTaskExit}
                open={this.state.onCancelTaskDialogOpen}
                aria-labelledby="form-dialog-title"
                className="add_company_dialog add_account_dialog update_status_dialog"
                TransitionComponent={TransitionGrow}
                transitionDuration={600}
              >
                <DialogTitle id="form-dialog-title" className="add_user_header">
                  <span>Cancel Task</span>
                  <IconButton
                    aria-label="close"
                    className="closeBtn updateStatusCloseBtn"
                    onClick={this.onCancelTaskDialogClose}
                  >
                    <img src={iconClose} alt="close"></img>
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {cancelOption && cancelOption.length > 0 && (
                    <>
                      <label className="label">Select Reason</label>
                      <Autocomplete
                        id="cancel"
                        className="cusautocomplete"
                        options={cancelOption}
                        getOptionLabel={(option) =>
                          option.text ? option.text : ""
                        }
                        onInputChange={() => {}}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            name="serviceName"
                            placeholder="Search cancel value"
                            onChange={this.serviceListHandlers}
                            onSelect={this.onSelectServiceHandler}
                          />
                        )}
                      />
                    </>
                  )}
                  <div style={{ marginTop: "20px" }}>
                    <label className="label">Add note</label>
                    <CssTextField
                      id="outlined-basic"
                      name="addNote"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      fullWidth
                      placeholder="Enter text"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="add_company_action">
                    <Button
                      className="ok_btn green-btn"
                      onClick={() => this.onCancelTask()}
                      color="primary"
                    >
                      Cancel Task
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={this.state.revertModal}
                onClose={this.state.onRevertDialog}
                aria-labelledby="form-dialog-title"
                className="deleteServiceDialog create-service-dialog"
                TransitionComponent={TransitionGrow}
                transitionDuration={600}
              >
                <DialogTitle>
                  <center>Revert Status?</center>{" "}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <center> Do you want to revert this task status?</center>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="cancel"
                    onClick={() => this.revertModalCloseHandler()}
                    color="primary"
                  >
                    No
                  </Button>
                  <Button
                    className="ok_btn green-btn"
                    onClick={() => this.revertStatusHandler()}
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Suspense>
            <Suspense fallback={<div></div>}>
              <Dialog
                open={this.state.updateStatusDialogMenu}
                aria-labelledby="form-dialog-title"
                className="add_company_dialog add_account_dialog update_status_dialog"
                TransitionComponent={Transition}
                transitionDuration={600}
              >
                <DialogTitle id="form-dialog-title" className="add_user_header">
                  <span>Update Status</span>
                  <IconButton
                    aria-label="close"
                    className="closeBtn updateStatusCloseBtn"
                    onClick={this.handleUpdateDialogClose}
                  >
                    <img src={iconClose} alt="close"></img>
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <label className="label select_status_label bl-label">
                    Select status
                  </label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      value={this.state.selectedStatus}
                      displayEmpty={true}
                      className="customSelect"
                      name="selectedStatus"
                      fullWidth
                      onChange={this.onChangeHandler}
                    >
                      {this.state.taskStatus.toLowerCase() === "overdue" ||
                      this.state.taskStatus.toLowerCase() === "assigned"
                        ? statusTwo.map((sts) => (
                            <MenuItem
                              key={sts.id}
                              value={
                                sts.value.toLowerCase() === "update status"
                                  ? ""
                                  : sts.value
                              }
                            >
                              {sts.name}
                            </MenuItem>
                          ))
                        : this.state.taskStatus.toLowerCase() === "inprogress"
                        ? statusThree.map((sts) => (
                            <MenuItem
                              key={sts.id}
                              value={
                                sts.value.toLowerCase() === "update status"
                                  ? ""
                                  : sts.value
                              }
                            >
                              {sts.name}
                            </MenuItem>
                          ))
                        : this.state.taskStatus.toLowerCase() ===
                            "unassigned" ||
                          this.state.taskStatus.toLowerCase() === "pending"
                        ? this.state.userdatas.username.toLowerCase() ===
                            (this.state.taskAssignee
                              ? this.state.taskAssignee.toLowerCase()
                              : "") &&
                          this.state.taskStatus.toLowerCase() === "pending"
                          ? statusOnePending.map((sts) => (
                              <MenuItem
                                key={sts.id}
                                value={
                                  sts.value.toLowerCase() === "update status"
                                    ? ""
                                    : sts.value
                                }
                              >
                                {sts.name}
                              </MenuItem>
                            ))
                          : statusOne.map((sts) => (
                              <MenuItem
                                key={sts.id}
                                value={
                                  sts.value.toLowerCase() === "update status"
                                    ? ""
                                    : sts.value
                                }
                              >
                                {sts.name}
                              </MenuItem>
                            ))
                        : ""}
                    </Select>
                  </FormControl>
                  <span className="error"></span>
                  <label className="label bl-label">Add note</label>
                  <CssTextField
                    id="outlined-basic"
                    name="addNote"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    fullWidth
                    placeholder="Enter text"
                    onChange={this.onChangeHandler}
                  />
                  <span className="error"></span>
                  <div className="add_company_action">
                    <Button
                      onClick={this.handleUpdateDialogClose}
                      color="primary"
                      className="cancel_btn white-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={this.state.selectedStatus !== "" ? false : true}
                      className="ok_btn green-btn"
                      onClick={this.updateStatus}
                      color="primary"
                    >
                      Update
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </Suspense>
            <Box
              component="div"
              className="company-comp account-detail task-detail"
            >
              <div className="select_parent">
                <Suspense fallback={<div></div>}>
                  <div className="breadCrumbs">
                    <img
                      className="bread-crumb-back"
                      src={backIcon}
                      alt="back"
                    />
                    <Breadcrumbs
                      className="bread-crumb-main"
                      aria-label="breadcrumb"
                    >
                      <Link
                        className="bread-crumb-link"
                        color="inherit"
                        // href="/task"
                        onClick={this.handleGoBack}
                      >
                        Task
                      </Link>
                      <Typography className="bread-crumb-name">
                        Task Details
                      </Typography>
                    </Breadcrumbs>
                  </div>
                </Suspense>
                <div className="account-detail-header task-detail-header">
                  <Suspense fallback={<div></div>}>
                    {this.state.isEmergency === true &&
                    this.state.conflict === false ? (
                      <Typography
                        variant="h5"
                        className="tableTitle"
                        component="div"
                      >
                        {taskName ? startCase(taskName) : ""}
                        <LightTooltip
                          title="Emergency Task"
                          {...this.state.tooltipprops}
                          arrow
                        >
                          <img
                            className="priority_icon"
                            src={emergency}
                            alt="priority icon"
                          />
                        </LightTooltip>
                      </Typography>
                    ) : this.state.conflict === true &&
                      this.state.isEmergency === false ? (
                      <Typography
                        variant="h5"
                        className="tableTitle"
                        component="div"
                      >
                        {taskName ? startCase(taskName) : ""}
                        <LightTooltip
                          title={`This task is conflict with ${this.state.conflictName}`}
                          {...this.state.tooltipprops}
                          arrow
                        >
                          <img
                            className="priority_icon"
                            src={warning_img}
                            alt="priority icon"
                          />
                        </LightTooltip>
                      </Typography>
                    ) : this.state.isRecurring === true &&
                      this.state.isEmergency === false ? (
                      <Typography
                        variant="h5"
                        className="tableTitle"
                        component="div"
                      >
                        {taskName ? startCase(taskName) : ""}
                        <LightTooltip
                          title={
                            this.state.isRecurring === true &&
                            this.state.taskStatus == "assigned"
                              ? "Assigned"
                              : this.state.isRecurring === true &&
                                this.state.taskStatus == "Pending"
                              ? "Pending"
                              : this.state.isRecurring === true &&
                                this.state.taskStatus == "inprogress"
                              ? "Inprogress"
                              : this.state.isRecurring === true &&
                                this.state.taskStatus == "completed"
                              ? "Completed"
                              : this.state.isRecurring === true &&
                                this.state.taskStatus == "uncovered"
                              ? "Uncovered"
                              : this.state.isRecurring === true &&
                                this.state.taskStatus == "Unassigned"
                              ? "Unassigned"
                              : this.state.isRecurring === true &&
                                this.state.taskStatus == "cancelled"
                              ? "Cancelled"
                              : "Recurring Task"
                          }
                          {...this.state.tooltipprops}
                          arrow
                        >
                          <span className="priority_icon">
                            {this.state.isRecurring === true &&
                            this.state.taskStatus == "assigned" ? (
                              <img
                                src={recurring_assigned}
                                alt="recurringImg"
                              />
                            ) : this.state.isRecurring === true &&
                              this.state.taskStatus == "Pending" ? (
                              <img src={recurring_pending} alt="recurringImg" />
                            ) : this.state.isRecurring === true &&
                              this.state.taskStatus == "inprogress" ? (
                              <img
                                src={recurring_inprogress}
                                alt="recurringImg"
                              />
                            ) : this.state.isRecurring === true &&
                              this.state.taskStatus == "completed" ? (
                              <img
                                src={recurring_completed}
                                alt="recurringImg"
                              />
                            ) : this.state.isRecurring === true &&
                              this.state.taskStatus == "uncovered" ? (
                              <img
                                src={recurring_uncovered}
                                alt="recurringImg"
                              />
                            ) : this.state.isRecurring === true &&
                              this.state.taskStatus == "Unassigned" ? (
                              <img
                                src={recurring_unassigned}
                                alt="recurringImg"
                              />
                            ) : this.state.isRecurring === true &&
                              this.state.taskStatus == "cancelled" ? (
                              <img
                                src={recurring_cancelled}
                                alt="recurringImg"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </LightTooltip>
                      </Typography>
                    ) : this.state.conflict === true &&
                      this.state.isEmergency === true ? (
                      <Typography
                        variant="h5"
                        className="tableTitle"
                        component="div"
                      >
                        {taskName ? startCase(taskName) : ""}
                        <LightTooltip
                          title="Emergency Task"
                          {...this.state.tooltipprops}
                          arrow
                        >
                          <img
                            className="priority_icon"
                            src={emergency}
                            alt="priority icon"
                          />
                        </LightTooltip>
                        <LightTooltip
                          title={`This task is conflict with ${this.state.conflictName}`}
                          {...this.state.tooltipprops}
                          arrow
                        >
                          <img
                            className="priority_icon"
                            src={warning_img}
                            alt="priority"
                          />
                        </LightTooltip>
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        className="tableTitle"
                        component="div"
                      >
                        {taskName ? startCase(taskName) : ""}
                      </Typography>
                    )}
                  </Suspense>
                  <div className="task-detail-header-btns">
                    {/* <div className={`assigned-to-btn ${this.state.isAssignee ? "" : "assigned-to-btn-edit"}`}> */}
                    <div className="assigned-to-btn-edit assigned-to-btn">
                      <span className="assigned-to-btn-rep">
                        Rep:
                        {this.state.profile_path ? (
                          <img
                            src={`https://salesc2userprofile.s3.amazonaws.com/${this.state.profile_path}`}
                            className="auto-assignee-profile-pic profile-pic"
                            alt={this.state.profile_path}
                          />
                        ) : (
                          <img
                            src={userIcon}
                            alt="noImage"
                            className="auto-assignee-profile-pic profile-pic"
                          />
                        )}
                      </span>
                      <span
                        disabled={true}
                        className="edit-input task-assignee-person"
                      >
                        {this.state.taskAssigneeValue[0] ? (
                          <CssAutocomplete
                            disabled={
                              this.state.taskStatus === "inprogress"
                                ? true
                                : false
                            }
                            {...this.state.asignToAutoComplete
                              .autocompletedProps}
                            freeSolo
                            options={this.state.assignToOption}
                            defaultValue={this.state.taskAssigneeValue[0]}
                            getOptionLabel={(option) => option.name}
                            getOptionDisabled={(option) =>
                              option.status === "unavailable" ? true : false
                            }
                            renderOption={(option) => (
                              <>
                                {option.profilePath ? (
                                  <img
                                    src={`https://salesc2userprofile.s3.amazonaws.com/${option.profilePath}`}
                                    className="auto-assignee-profile-pic profile-pic"
                                    alt={option.profilePath}
                                  />
                                ) : (
                                  <img
                                    src={userIcon}
                                    alt="noImage"
                                    className="auto-assignee-profile-pic profile-pic"
                                  />
                                )}
                                {option.default === "true" ? (
                                  <span className="auto-assignee-profile-name task-detail-assignee-name">
                                    <span className="auto-assignee-name-field">
                                      {option.name}
                                    </span>
                                    {" (Assign to me) "}
                                    {option.status === "busy" ? (
                                      <img
                                        src={taskWarningIcon}
                                        alt="taskWarningIcon"
                                        className="auto-assignee-priority-icon"
                                      />
                                    ) : option.status === "unavailable" ? (
                                      <img
                                        src={userBusy}
                                        alt="userBusy"
                                        className="auto-assignee-priority-icon"
                                      />
                                    ) : null}
                                  </span>
                                ) : (
                                  <span className="auto-assignee-profile-name task-detail-assignee-name assingee-to-other">
                                    <span className="auto-assignee-name-field">
                                      {option.name}
                                    </span>
                                    {option.status === "busy" ? (
                                      <img
                                        src={taskWarningIcon}
                                        alt="taskWarningIcon"
                                        className="auto-assignee-priority-icon"
                                      />
                                    ) : option.status === "unavailable" ? (
                                      <img
                                        src={userBusy}
                                        alt="userBusy"
                                        className="auto-assignee-priority-icon"
                                      />
                                    ) : null}
                                  </span>
                                )}
                              </>
                            )}
                            renderInput={(params) => (
                              <CssAutoTextfield
                                {...params}
                                {...this.state.asignToAutoComplete
                                  .textFieldProps}
                                onChange={this.onAutoCompleteChange}
                                onSelect={this.onAutoCompleteSelect}
                              />
                            )}
                          ></CssAutocomplete>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div className="task_detail_drop_btn">
                      <Suspense fallback={<div></div>}>
                        <Dropdown
                          style={{ display: `${hideEdit} !important` }}
                          overlay={
                            <Menu>
                              {this.state.taskStatus.toLowerCase() ===
                                "unassigned" ||
                              this.state.taskStatus.toLowerCase() ===
                                "pending" ? (
                                this.state.userdatas.username.toLowerCase() ===
                                  (this.state.taskAssignee
                                    ? this.state.taskAssignee.toLowerCase()
                                    : "") &&
                                this.state.taskStatus.toLowerCase() ===
                                  "pending" ? (
                                  statusOnePending.map((sts) => (
                                    <MenuItem
                                      key={sts.id}
                                      onClick={
                                        sts.value.toLocaleLowerCase() !==
                                        "update status"
                                          ? () => this.onclickStatus(sts.value)
                                          : () => this.onupdatestatusdialog()
                                      }
                                      value={
                                        sts.value.toLowerCase() ===
                                        "update status"
                                          ? ""
                                          : sts.value
                                      }
                                    >
                                      {sts.name}
                                    </MenuItem>
                                  ))
                                ) : (
                                  statusOne.map((sts) => (
                                    <Menu.Item
                                      key={sts.id}
                                      onClick={
                                        sts.value.toLocaleLowerCase() !==
                                        "update status"
                                          ? () => this.onclickStatus(sts.value)
                                          : () => this.onupdatestatusdialog()
                                      }
                                    >
                                      {sts.name}
                                    </Menu.Item>
                                  ))
                                )
                              ) : this.state.taskStatus.toLowerCase() ===
                                "inprogress" ? (
                                statusThree.map((sts) => (
                                  <Menu.Item
                                    key={sts.id}
                                    onClick={
                                      sts.value.toLocaleLowerCase() !==
                                      "update status"
                                        ? () => this.onclickStatus(sts.value)
                                        : () => this.onupdatestatusdialog()
                                    }
                                  >
                                    {sts.name}
                                  </Menu.Item>
                                ))
                              ) : this.state.taskStatus.toLowerCase() ===
                                  "assigned" ||
                                this.state.taskStatus.toLowerCase() ===
                                  "overdue" ? (
                                statusTwo.map((sts) => (
                                  <Menu.Item
                                    key={sts.id}
                                    onClick={
                                      sts.value.toLocaleLowerCase() !==
                                      "update status"
                                        ? () => this.onclickStatus(sts.value)
                                        : () => this.onupdatestatusdialog()
                                    }
                                  >
                                    {sts.name}
                                  </Menu.Item>
                                ))
                              ) : this.state.taskStatus.toLowerCase() ===
                                  "completed" ||
                                this.state.taskStatus.toLowerCase() ===
                                  "cancelled" ||
                                this.state.taskStatus.toLowerCase() ===
                                  "uncovered" ||
                                this.state.taskStatus.toLowerCase() ===
                                  "rejected" ? (
                                <Menu.Item>
                                  Task is {this.state.taskStatus.toLowerCase()}
                                  !.
                                </Menu.Item>
                              ) : (
                                ""
                              )}
                            </Menu>
                          }
                          placement="bottomCenter"
                          arrow
                        >
                          <Button
                            style={{ display: hideEdit }}
                            className="update_status_btn primary-btn"
                            onClick={() => {
                              this.onupdatestatusdialog();
                            }}
                          >
                            Update Status
                          </Button>
                        </Dropdown>
                      </Suspense>
                      {this.state.taskStatus.toLowerCase() === "cancelled" ||
                      this.state.taskStatus.toLowerCase() === "completed" ||
                      this.state.taskStatus.toLowerCase() === "uncovered" ? (
                        ""
                      ) : (
                        <Tooltip title="Cancel This Task">
                          <Button
                            className="task_detail_cancel_btn white-btn"
                            onClick={() => this.onCancelTaskDialog()}
                          >
                            Cancel
                          </Button>
                        </Tooltip>
                      )}
                      {this.state.revertStatus && (
                        <Button
                          className="revert_status_btn revert-btn"
                          onClick={() => {
                            this.revertModalHandler(this.state.revertStatus);
                          }}
                        >
                          Revert Status
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <Suspense fallback={<div></div>}>
                  <StepperComponent taskStatus={this.state.taskStatus} />
                </Suspense>
              </div>
              <div className="account-detail-container user-detail-container">
                <Grid spacing={2} container>
                  <Grid item xs={12} md={4}>
                    <div className="priority_container">
                      <FormControlLabel
                        disabled={
                          this.state.taskStatus === "completed" ||
                          this.state.taskStatus === "cancelled" ||
                          this.state.taskStatus === "uncovered" ||
                          this.state.isRecurring === true
                            ? true
                            : false
                        }
                        checked={this.state.isEmergency}
                        name="isEmergency"
                        control={<AntSwitch color="primary" />}
                        onChange={this.handlechecked}
                        label="Priority"
                        margin="dense"
                        labelPlacement="start"
                      />
                      <label className="label labelEmergency">
                        <span className="labelAsterisk ">Emergency</span>
                      </label>
                    </div>
                    <Suspense fallback={<div></div>}>
                      {this.state.taskStatus === "completed" ? (
                        <ActualDurationComponent
                          startDateErrors={this.state.startDateErrors}
                          endDateErrors={this.state.endDateErrors}
                          timeZoneName={this.state.timeZoneName}
                          taskStatus={this.state.taskStatus}
                          isStartDates={this.state.isStartDates}
                          isEndDates={this.state.isEndDates}
                          handleDateSelects={this.handleDateSelects}
                          resetStartDateDialogs={this.resetStartDateDialogs}
                          isStartDateEdits={this.state.isStartDateEdits}
                          updateActualHandler={this.updateActualHandler}
                          editInputs={this.editInputs}
                          startDateDialogs={this.state.startDateDialogs}
                          changeStartDateDialogCloses={
                            this.changeStartDateDialogCloses
                          }
                          DatesizeIncreases={this.DatesizeIncreases}
                          DatesizeDecreass={this.DatesizeDecreass}
                          actualdateRangeProps={this.state.actualdateRangeProps}
                          actualdateRangePropsNew={
                            this.state.actualdateRangePropsNew
                          }
                          editEndDates={this.state.editEndDates}
                          valids={this.valids}
                          disabledDatess={this.disabledDatess}
                          disabledNewDatess={this.disabledNewDatess}
                          disabledDatesBefore={this.disabledDatesBefore}
                          onStartDateChanges={this.onStartDateChanges}
                          onEndDateChanges={this.onEndDateChanges}
                          onEndDateNewChanges={this.onEndDateNewChanges}
                          modelData={this.state}
                        />
                      ) : (
                        <></>
                      )}
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                      <DurationComponent
                        startDateError={this.state.startDateError}
                        endDateError={this.state.endDateError}
                        timeZoneName={this.state.timeZoneName}
                        taskStatus={this.state.taskStatus}
                        isStartDate={this.state.isStartDate}
                        isEndDate={this.state.isEndDate}
                        handleDateSelect={this.handleDateSelect}
                        resetStartDateDialog={this.resetStartDateDialog}
                        isStartDateEdit={this.state.isStartDateEdit}
                        updateHandler={this.updateHandler}
                        editInput={this.editInput}
                        startDateDialog={this.state.startDateDialog}
                        changeStartDateDialogClose={
                          this.changeStartDateDialogClose
                        }
                        DatesizeIncrease={this.DatesizeIncrease}
                        DatesizeDecreas={this.DatesizeDecreas}
                        dateRangeProps={this.state.dateRangeProps}
                        dateRangePropsNew={this.state.dateRangePropsNew}
                        editEndDates={this.state.editEndDates}
                        valid={this.valid}
                        disabledDate={this.disabledDate}
                        disabledNewDate={this.disabledNewDate}
                        onStartDateChange={this.onStartDateChange}
                        onEndDateChange={this.onEndDateChange}
                        onEndDateNewChange={this.onEndDateNewChange}
                        disabledDates={this.disabledDates}
                        modelData={this.state}
                        dateId={this.state.dateId}
                        isRecurringEndDate={this.state.isRecurringEndDate}
                        onPopupOpenReschedule={this.onPopupOpenReschedule}
                        onPopupCloseReschedule={this.onPopupCloseReschedule}
                        taskSchedules={this.state.taskSchedules}
                        reschdeuleRecurringHandler={
                          this.reschdeuleRecurringHandler
                        }
                        recurringEndDate={this.state.recurringEndDate}
                        recurringTaskId={this.state.taskId}
                        taskId={this.state.recurringTaskId}
                        newStartDate={this.state.newStartDate}
                        newEndDate={this.state.newEndDate}
                      />
                    </Suspense>

                    <Suspense fallback={<div></div>}>
                      <AccountInfoComponent
                        clearFieldValue={this.clearFieldValueHandler}
                        clearDepartment={this.clearDepartmentHandler}
                        departmentClear={this.state.departmentClear}
                        taskStatus={this.state.taskStatus}
                        isAccount={this.state.isAccount}
                        isDepartment={this.state.isDepartment}
                        accountAutoComplete={this.state.accountAutoComplete}
                        deptAutoComplete={this.state.deptAutoComplete}
                        account={this.state.account}
                        department={this.state.department}
                        departments={this.state.departments}
                        accountName={this.state.accountName}
                        accountNameDefault={this.state.accountNameDefault}
                        departmentNameDefault={this.state.departmentNameDefault}
                        departmentName={this.state.departmentName}
                        department_contact={this.state.department_contact}
                        address={this.state.address}
                        coordinates={this.state.coordinates}
                        handleDateSelect={this.handleDateSelect}
                        updateHandler={this.updateHandler}
                        editInput={this.editInput}
                        changeAccountDialog={this.state.changeAccountDialog}
                        changeAccountDialogClose={this.changeAccountDialogClose}
                        onAutoCompleteChange={this.onAutoCompleteChange}
                        onAutoCompleteSelect={this.onAutoCompleteSelect}
                        updateAccountData={this.updateAccountData}
                        isTrue={this.state.isTrue}
                        resetState={this.resetState}
                        onDepartmentChanged={this.onDepartmentChanged}
                        taskSchedules={this.state.taskSchedules}
                        departmentLabel={this.state.cmsLabelChange.Department}
                        accountLabel={this.state.cmsLabelChange.Account}
                      />
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                      {this.state.patientName || this.state.caseNumber ? (
                        <PatientInfoComponent
                          isPatientName={this.state.isPatientName}
                          isCaseNumber={this.state.isCaseNumber}
                          patientName={this.state.patientName}
                          caseNumber={this.state.caseNumber}
                          editInput={this.editInput}
                          updateHandler={this.updateHandler}
                          onChangeHandler={this.onChangeHandler}
                          taskSchedules={this.state.taskSchedules}
                        />
                      ) : (
                        ""
                      )}
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                      {this.state.taskSchedules.isRecurring === true ||
                      this.state.isRecurring === true ? (
                        <RecurringScheduleComponent
                          isRecurring={this.state.isRecurring}
                          recurringText={this.state.recurringText}
                          taskSchedules={this.state.taskSchedules}
                        />
                      ) : (
                        ""
                      )}
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                      <DescriptionComponent
                        taskStatus={this.state.taskStatus}
                        isDesc={this.state.isDesc}
                        taskDescription={this.state.taskDescription}
                        taskDescriptionError={this.state.taskDescriptionError}
                        onChangeHandler={this.onChangeHandler}
                        updateHandler={this.updateHandler}
                        editInput={this.editInput}
                        taskSchedules={this.state.taskSchedules}
                        descriptionLabel={this.state.cmsLabelChange.Description}
                      />
                    </Suspense>
                    {/* reassign rep for recurring */}
                    <Suspense fallback={<div></div>}>
                      {this.state.reassignTaskOpen == true ? (
                        <ReassignRepRecurringComponent
                          data={this.state.reassignTaskOpen}
                          onReassignPopupClose={this.onReassignPopupClose}
                          reassignTaskHandler={this.reassignTaskHandler}
                        />
                      ) : (
                        <></>
                      )}
                    </Suspense>
                    {/* cancel task for recurring */}
                    <Suspense fallback={<div></div>}>
                      {this.state.cancelTaskOpen == true ? (
                        <CancelTaskModalComponent
                          cancelTaskOpen={this.state.cancelTaskOpen}
                          timeZoneName={this.state.timeZoneName}
                          dateId={this.state.dateId}
                          taskId={this.state.recurringTaskId}
                          cancelValue={this.state.cancelValue}
                          cancelTaskHandler={this.cancelTaskHandler}
                          onPopupClose={this.onCancelPopupClose}
                          recurringTaskId={this.state.taskId}
                          onCancelTaskDialogClose={this.onCancelTaskDialogClose}
                        />
                      ) : (
                        <></>
                      )}
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                      <ServiceComponent
                        taskStatus={this.state.taskStatus}
                        isService={this.state.isService}
                        service={this.state.service}
                        serviceName={this.state.serviceName}
                        serviceNameDefault={this.state.serviceNameDefault}
                        serviceAutoComplete={this.state.serviceAutoComplete}
                        onAutoCompleteChange={this.onAutoCompleteChange}
                        onAutoCompleteSelect={this.onAutoCompleteSelect}
                        updateHandler={this.updateHandler}
                        editInput={this.editInput}
                        taskSchedules={this.state.taskSchedules}
                        serviceLabel={this.state.cmsLabelChange.Service}
                      />
                    </Suspense>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Suspense fallback={<div></div>}>
                      <TabComponent
                        popupClose={this.popupClose}
                        popupOpen={this.state.popupOpen}
                        removePopupOpen={this.state.removePopupOpen}
                        removePopupClose={this.removePopupClose}
                        addRepHandler={this.addRepHandler}
                        ondeleteRepresentatives={this.ondeleteRepresentatives}
                        accountId={this.state.account}
                        endDate={this.state.endDate}
                        startDate={this.state.startDate}
                        taskAssigneeId={this.state.taskAssigneeId}
                        timeZoneName={this.state.timeZoneName}
                        taskStatus={this.state.taskStatus}
                        onChangeHandler={this.onChangeHandler}
                        addNoteHandler={this.addNoteHandler}
                        addNote={this.state.addNote}
                        addNoteTab={this.state.addNoteTab}
                        products={this.state.products}
                        addNoteError={this.state.addNoteError}
                        addNoteTabError={this.state.addNoteTabError}
                        onAddProduct={this.onAddProduct}
                        productName={this.state.productName}
                        clearProduct={this.clearProduct}
                        onProductEdit={this.onProductEdit}
                        onDeleteHandleClick={this.onDeleteHandleClick}
                        activeTab={this.state.activeTab}
                        onActiveTabChange={this.onActiveTabChange}
                        onEditDialogUpdate={this.onEditDialogUpdate}
                        onEditDialogClose={this.onEditDialogClose}
                        onEditDialogExit={this.onEditDialogExit}
                        editProductError={this.state.editProductError}
                        onEditDialogOpen={this.state.onEditDialogOpen}
                        productEditItem={this.state.productEditItem}
                        price={this.state.price}
                        quantity={this.state.quantity}
                        priceNew={this.state.priceNew}
                        quantityNew={this.state.quantityNew}
                        productNew={this.state.productNew}
                        isProducts={this.state.isProducts}
                        productOptions={this.state.productOptions}
                        onAutoCompleteChange={this.onAutoCompleteChange}
                        onAutoCompleteSelect={this.onAutoCompleteSelect}
                        onAddDialogClose={this.onAddDialogClose}
                        onAddProductDialog={this.onAddProductDialog}
                        onAddDialogExit={this.onAddDialogExit}
                        onAddDialogOpen={this.state.onAddDialogOpen}
                        addProductError={this.state.addProductError}
                        expenses={this.state.expenses}
                        addNewExpense={this.addNewExpense}
                        onExpenseRemove={this.onExpenseRemove}
                        onAddExpenseDialogExit={this.onAddExpenseDialogExit}
                        onAddExpenseDialogOpen={
                          this.state.onAddExpenseDialogOpen
                        }
                        onAddExpenseDialogClose={this.onAddExpenseDialogClose}
                        onAddNewExpenseHandler={this.onAddNewExpenseHandler}
                        addExpenseError={this.state.addExpenseError}
                        ondeleteReps={this.ondeleteReps}
                        onMultipleAttendeeOnFocusHandler={
                          this.onMultipleAttendeeOnFocusHandler
                        }
                        onMultipleAttendeeSearch={this.onMultipleAttendeeSearch}
                        getRepresentativeFilter={this.getRepresentativeFilter}
                        additionalReps={this.state.additionalReps}
                        onSubmit={this.onSubmit}
                        onCloseHandle={this.onCloseHandle}
                        show={this.state.show}
                        tooltipprops={this.state.tooltipprops}
                        showdeleteReps={this.state.showdeleteReps}
                        onhandleClose={this.onhandleClose}
                        deleteReps={this.deleteReps}
                        isSubmit={this.state.isSubmit}
                        additionalRepData={this.state.additionalRepData}
                        taskSchedules={this.state.taskSchedules}
                        cmsLabelChange={this.state.cmsLabelChange}
                        priceEnable={this.state.priceEnable}
                        quantityEnable={this.state.quantityEnable}
                      />
                    </Suspense>
                    {this.state.activeTab === "1" ? (
                      <Suspense fallback={<div></div>}>
                        <TimelineComponent
                          timeZoneName={this.state.timeZoneName}
                          timelines={this.state.timelines}
                          cancelReason={this.state.timelineCancelReason}
                        />
                      </Suspense>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
  };
};

export default connect(mapStateToProps)(withRouter(TaskDetailsComponent));
