import React, { Component, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as action from "../../Store/Actions/index";
import Loader from "react-loader-spinner";
import { isSafari } from "react-device-detect";
import {
  Box,
  Typography,
  Button,
  Select,
  TextField,
  InputAdornment,
  Tooltip,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  CircularProgress,
  TablePagination,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import { AxiosInstance, checkCmsLabel } from "../../Utilities";
import suspended from "../../Assets/images/users (5).svg";
import suspendedcross from "../../Assets/images/user (5).svg";
import restoreuser from "../../Assets/images/Exclusion 1.svg";
import openMenutable from "../../Assets/images/open-menu-table.svg";
import searchIcon from "../../Assets/images/search_icon.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { upperFirst } from "lodash";
import carret_down from "../../Assets/images/carret_down.svg";
import "./TeamComponent.css";
import Delete from "../../Assets/images/delete.svg";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { TransitionGrow } from '../../SharedComponents/CommonUtilities/CommonUtilities';
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
var SettingsModel = React.lazy(() => import("./SettingsModel/SettingsModel"));
var AddEmployeeModal = React.lazy(() => import("./AddEmployeeModal"));
var CustomUserSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomUserSelectComponent")
);
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import(
    "../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
  )
);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
const url =  new URL(window.location.href);  
const territoryValue = url.searchParams.get('territory');
const searchValue = url.searchParams.get('search');
const statusValue = url.searchParams.get("status")
const divisionValue = url.searchParams.get("division")
const areaValue = url.searchParams.get("area")
const regionValue = url.searchParams.get("region")
const companyValue = url.searchParams.get("company")
const roleValue = url.searchParams.get("role")
class TeamComponent extends Component {
  constructor(props) {
    super(props);
    let User = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      roleData:'',
      roleFilterNames:'',
      roleFilterValue:'',
      statusFilterName:'',
      statusFilter:'',
      statusFilterValue:'',
      companyFilterValue:'',
      regionFilterValue:'',
      areaFilterValue:'',
      divisionFilterValue: "",
      territoryFilterName:"",
      roleLabelChange:[],
      pageNumber: 1,
      openModel: false,
      loggedInRole: User.role,
      companyList: [],
      companyOption: [],
      filters: {
        company: [],
        area: [],
        division: [],
        region: [],
        territory: [],
      },
      page: 0,
      rowsPerPage: 10,
      companyId: User.companyId,
      role: User.role.toLowerCase(),
      companySize: User.companySize.toLowerCase(),
      userId: "",
      companySizeFilter: "large",
      companyFilter: "",
      divisionFilter: "",
      areaFilter: "",
      regionFilter: "",
      territoryFilter: territoryValue ? territoryValue : "",
      roleFilter: "",
      roleFiltername: "",
      searchFilter: "",
      orderBy: "name",
      order: "asc",
      loading: true,
      compFilter: false,
      showaddModal: false,
      suspendUser_id: "",
      delete_id: "",
      description: "",
      status: "",
      suspendedUserFilterName: "",
      isSubmit: false,
      areaName: '',
      regionName: '',
      territoryName: '',
      divisionName: '',
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      descriptioneror: {
        error: "",
      },
      companyConfig: {
        anchorElCompany: null,
        config: {
          id: "company_filter_select",
        },
      },
      searchCompanyValue: "",
      divisionConfig: {
        anchorElDivision: null,
        config: {
          id: "division_filter_select",
        },
      },
      areaConfig: {
        anchorElArea: null,
        config: {
          id: "area_filter_select",
        },
      },
      regionConfig: {
        anchorElRegion: null,
        config: {
          id: "region_filter_select",
        },
      },
      territoryConfig: {
        anchorElTerritory: null,
        config: {
          id: "territory_filter_select",
        },
      },
      roleConfig: {
        anchorElRole: null,
        config: {
          id: "role_filter_select",
        },
      },
      tooltipprops: {}
    };
    this.Userfilter = this.Userfilter.bind(this);
  }
  UNSAFE_componentWillMount() {
    let obj = {}
    if (isSafari) {
      obj.open = false;
      this.setState({ tooltipprops: obj })
    }
  }
  componentDidMount = () => {
    window.onpopstate = () =>{
      window.location.reload()
    }
    this.setState({ loading: true, searchFilter:searchValue });
    const fetchCmsData = checkCmsLabel()
    this.companyListHandler()
    if(fetchCmsData){
      this.setState({ roleLabelChange : fetchCmsData.roleData})
    }
    const { page, rowsPerPage, companyId, role, userId, status, pageNumber } = this.state;

    const queryString = this.props.location.search;
    const pageParameter = new URLSearchParams(queryString).get("page");
    const pageValue = parseInt(pageParameter);
    if(statusValue && statusValue !== "All"){
      var statusOptions = [
        { _id: "all", name: "All Users", key:"alluser" },
        { _id: "inactive", name: "Suspended Users", key:'suspened' },
        { _id: "deleted", name: "Deleted Users", key:'deleted' }
      ];
      const statusValues = statusOptions.find(item => item.key === statusValue)
      this.setState({
        statusFilterName: statusValues?._id ? statusValues?._id : "",
        suspendedUserFilterName: statusValues?.name ? statusValues?.name :''
      })
    }
    if(roleValue && roleValue !== "All"){
      var options = [];
    if (this.state.loggedInRole.toLowerCase() === "super admin") {
      options.push(
        { name: "Super Admin", _id: "super admin", key:"superadmin" },
        { name: "Admin", _id: "admin", key:"admin" },
        // { name: "Manager", _id: "manager" },
        { name: "Division Leader", _id: "division manager", key:"divisionleader" },
        { name: "Area Director", _id: "area manager", key:"areadirector" },
        { name: "Regional Manager", _id: "team lead", key:"regionalmanager" },
        { name: "Territory Manager", _id: "territory manager", key:"territorymanager" },
        { name: "Representative", _id: "sales rep", key:"representative" }
      );
    } else if (this.state.loggedInRole.toLowerCase() === "admin") {
      options.push(
        { name: "Admin", _id: "admin", key:"admin" },
        { name: "Division Leader", _id: "division manager", key:"divisionleader" },
        { name: "Area Director", _id: "area manager", key:"areadirector" },
        { name: "Regional Manager", _id: "team lead", key:"regionalmanager" },
        { name: "Territory Manager", _id: "territory manager", key:"territorymanager" },
        { name: "Representative", _id: "sales rep", key:"representative" }
      );
    } else if (this.state.loggedInRole.toLowerCase() === "manager") {
      options.push(
        { name: "Division Leader", _id: "division manager", key:"divisionleader" },
        { name: "Area Director", _id: "area manager", key:"areadirector" },
        { name: "Regional Manager", _id: "team lead", key:"regionalmanager" },
        { name: "Territory Manager", _id: "territory manager", key:"territorymanager" },
        { name: "Representative", _id: "sales rep", key:"representative" }
      );
    }
    const roleValues = options.find(item => item.key === roleValue)
      this.setState({
        roleFilterNames: roleValues?._id ? roleValues?._id : "",
        roleFiltername: roleValues?.name ? roleValues?.name :''
      })
    }
    setTimeout(() => {
      if(companyValue && companyValue !== "All"){
        const company = this.state.companyOption.find(item => item._id === companyValue)
        this.setState({
          companyName: company?.name ? company?.name : ""
        })
      } else if(companyValue && companyValue === "All"){
        this.setState({
          companyFilter: ""
        })
      }
    this.props.onPagination(pageValue?pageValue:pageNumber - 1, rowsPerPage, companyId, role, userId, territoryValue, searchValue, companyValue, divisionValue, areaValue, regionValue, this.state.statusFilterName, this.state.roleFilterNames);
    },1000)
    let data = {
      // companyId: role === 'super admin' ? '' : companyId,
      roles: role,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/users/filters", data)
      .then((resp) => {
        if (resp.status === 200) {
          this.setState({ filters: resp.data.data });
          if(territoryValue && territoryValue !== "All"){
            const territory = resp.data?.data?.territory.find(item => item._id === territoryValue)
            this.setState({
              territoryName: territory?.name ? territory?.name : ""
            })
          } else if(territoryValue && territoryValue === "All"){
            this.setState({
              territoryFilter: ""
            })
          }
          if(divisionValue && divisionValue !== "All"){
            const division = resp.data?.data?.division.find(item => item._id === divisionValue)
            this.setState({
              divisionName: division?.name ? division?.name : ""
            })
          } else if(divisionValue && divisionValue === "All"){
            this.setState({
              divisionFilter: ""
            })
          }
          if(areaValue && areaValue !== "All"){
            const area = resp.data?.data?.area.find(item => item._id === areaValue)
            this.setState({
              areaName: area?.name ? area?.name : ""
            })
          } else if(areaValue && areaValue === "All"){
            this.setState({
              areaFilterFilter: ""
            })
          }
          if(regionValue && regionValue !== "All"){
            const region = resp.data?.data?.region.find(item => item._id === regionValue)
            this.setState({
              regionName: region?.name ? region?.name : ""
            })
          } else if(regionValue && regionValue === "All"){
            this.setState({
              regionFilter: ""
            })
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
  };
  handleCustomPaginagtionChange = (event, value) => {
    this.setState({
      pageNumber: value,
      loading: true
    }, () => {
      const {
        rowsPerPage,
        companyId,
        role,
        userId,
        companyFilter,
        companySizeFilter,
        divisionFilter,
        areaFilter,
        regionFilter,
        territoryFilter,
        roleFilter,
        searchFilter,
        compFilter,
        status
      } = this.state;
      let company = "";
      if (companyId !== "") {
        company = companyId;
      } else {
        company = companyFilter;
      }
      let data = {
        page: value,
        pagesize: rowsPerPage,
        companyId: compFilter ? companyFilter : companyId,
        role: role,
        userId: userId,
        company: companyFilter,
        companySize: companySizeFilter,
        division: divisionFilter,
        area: areaFilter,
        region: regionFilter,
        territory: territoryFilter,
        filterRole: roleFilter,
        searchText: searchFilter,
        status: status,
      };
      this.props.onFilter(data);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000);
      this.updateURL()
    })
  }

  handleChangeRowsPerPage = (event) => {
    const { pageNumber, companyId, role, userId, status } = this.state;
    this.setState({ rowsPerPage: +event.target.value, loading: true, pageNumber: 1 },
      () => {
        this.filterFn();
      }
    );
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  onCloseModal = () => {
    this.setState({
      showaddModal: false,
      openModel: false,
      description: "",
      descriptioneror: {
        error: "",
      },
    });
  };

  Userfilter = (event) => {
    let name = event.target.name;
    let filterdata = "";
    this.setState(
      { pageNumber: 1, [event.target.name]: event.target.value, loading: true },
      () => {
        const {
          companyFilter,
          divisionFilter,
          areaFilter,
          regionFilter,
          territoryFilter,
        } = this.state;
        switch (name) {
          case "companyFilter":
            filterdata = {
              // companyData: this.state.filters.company,
              companyId: companyFilter,
            };
            break;
          case "divisionFilter":
            this.setState(
              {
                areaFilter: "",
                regionFilter: "",
                territoryFilter: "",
                pageNumber: 1,
              },
              () => {
                this.filterFn();
              }
            );
            filterdata = {
              // companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              divisionId: divisionFilter,
            };
            break;
          case "areaFilter":
            this.setState(
              {
                regionFilter: "",
                territoryFilter: "",
              },
              () => {
                this.filterFn();
              }
            );
            filterdata = {
              // companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              areaData: this.state.filters.area,
              areaId: areaFilter,
            };

            break;
          case "regionFilter":
            this.setState(
              {
                territoryFilter: "",
                pageNumber: 1,
              },
              () => {
                this.filterFn();
              }
            );
            filterdata = {
              // companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              areaData: this.state.filters.area,
              regionData: this.state.filters.region,
              regionId: regionFilter,
              pageNumber: 1,
            };
            break;
          default:
            this.filterFn();
            break;
        }
        if (
          name !== "roleFilter" &&
          name !== "territoryFilter" &&
          name !== "searchFilter"
        ) {
          AxiosInstance(
            (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user',
            "application/x-www-form-urlencoded; charset=UTF-8"
          )
            .post("/users/filters", filterdata)
            .then((resp) => {
              if (resp.status === 200) {
                this.setState({ filters: resp.data.data });
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
            });
        }
      }
    );
  };
  filterFn = () => {
    const {
      pageNumber,
      rowsPerPage,
      companyId,
      role,
      userId,
      companyFilter,
      divisionFilter,
      areaFilter,
      regionFilter,
      territoryFilter,
      roleFilter,
      searchFilter,
      status,
    } = this.state;
    let data = {
      page: pageNumber,
      pagesize: rowsPerPage,
      companyId: companyId,
      role: role,
      userId: userId,
      company: companyFilter ? companyFilter : companyValue !== "All" ? companyValue : "",
      division: divisionFilter ? divisionFilter : divisionValue !== "All" ? divisionValue : "",
      area: areaFilter? areaFilter : areaValue !== "All" ? areaValue : "",
      region: regionFilter ? regionFilter : regionValue !== "All" ? regionValue : "",
      territory: territoryFilter ? territoryFilter : territoryValue !== "All" ? territoryValue : "",
      filterRole: roleFilter ? roleFilter : this.state.roleFiltername,
      searchText: searchFilter ? searchFilter : searchValue,
      status: status,
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    this.updateURL()
  };

  updateURL = () => {
    const {territoryFilter, territoryFilterName, searchFilter, statusFilter, statusFilterValue, divisionFilter, divisionFilterValue, areaFilter, areaFilterValue, regionFilterValue, regionFilter, companyFilterValue, companyFilter, roleData, roleFilterValue} = this.state
    const url =  new URL(window.location.href);  
    const queryParams = {
      territory: territoryFilter || territoryFilterName || url.searchParams.get('territory') || '',
      search: searchFilter || '',
      status: statusFilter || statusFilterValue || url.searchParams.get('status') || '',
      division: divisionFilter || divisionFilterValue || url.searchParams.get('division') || '',
      area: areaFilter || areaFilterValue || url.searchParams.get('area') || '',
      region: regionFilter || regionFilterValue || url.searchParams.get('region') || '',
      company: companyFilter || companyFilterValue || url.searchParams.get('company') || '',
      role: roleData || roleFilterValue || url.searchParams.get('role') || ''
    };

    const filteredParams = Object.entries(queryParams)
    .filter(([key, value]) => value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    this.props.history.push(`/user?page=${this.state.pageNumber?this.state.pageNumber:'1'}&${filteredParams}`);
  }

  compfilter = (id, size) => {
    this.setState(
      {
        divisionFilter: "",
        areaFilter: "",
        regionFilter: "",
        territoryFilter: "",
      },
      () => {
        const {
          pageNumber,
          rowsPerPage,
          companyId,
          role,
          userId,
          divisionFilter,
          areaFilter,
          regionFilter,
          territoryFilter,
          roleFilter,
          searchFilter,
        } = this.state;
        let filterdata = "";
        filterdata = { companyId: id, companySize: size };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/users/filters", filterdata)
          .then((resp) => {
            if (resp.status === 200) {
              this.setState({ filters: resp.data.data });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
        let data = {
          page: pageNumber,
          pagesize: rowsPerPage,
          companyId: companyId,
          role: role,
          userId: userId,
          company: id,
          companySize: size,
          division: divisionFilter,
          area: areaFilter,
          region: regionFilter,
          territory: territoryFilter,
          filterRole: roleFilter,
          searchText: searchFilter,
        };
        this.props.onFilter(data);
        setTimeout(() => {
          this.setState({ loading: false });
        }, 2000);
      }
    );
  };
  adddeshandleChange = (e) => {
    var { descriptioneror } = this.state;
    this.setState({ [e.target.name]: e.target.value });

    if (!/\S\w*/.test(e.target.value)) {
      descriptioneror.error =
        "*  Please enter description";
    } else if (e.target.value.length > 250) {
      descriptioneror.error =
        "* Description length should not be max 250 character";
    } else {
      descriptioneror.error = "";
    }
  };
  onViewHandleClick = (row) => {
    this.setState({ suspendUser_id: row._id, showaddModal: true });
  };
  onSuspSubmit = (e) => {
    var { descriptioneror } = this.state;
    if (this.state.description === "") {
      descriptioneror.error = "* Please enter description";
    }
    this.setState(
      {
        descriptioneror,
      },
      () => {
        const isEmpty = Object.values(descriptioneror).every(
          (x) => x === null || x === ""
        );
        if (isEmpty || descriptioneror.error === "") {
          this.setState({ isSubmit: true });
          var data = {
            userId: this.state.suspendUser_id,
            notes: this.state.description,
          };
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/json")
            .post("/employee/suspend", data)
            .then((res) => {
              if (res.data.status === "200") {
                this.setState(
                  {
                    isSubmit: false,
                  },
                  () => {
                    var props = {
                      message: res.data.msg,
                      icon: <img src={iconNotificationSuccess} alt="success" />,
                      type: "success",
                      placement: "topRight",
                      duration: 3,
                      top: 200,
                      className: "task_notification update-notify",
                    };
                    NotificationComponent(props);
                  }
                );
                this.onCloseModal();
                this.filterFn();
              } else {
                this.setState({ isSubmit: false });
                var props = {
                  message: res.data.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(props);
              }
            });
        }
      }
    );
  };
  revokeSuspension = (row) => {
    var data = {
      userId: row._id,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/json")
      .get(`/employee/unsuspend/${row._id}`)
      .then((res) => {
        if (res.data.status === "200") {
          this.setState(
            {
              isSubmit: false,
            },
            () => {
              var props = {
                message: res.data.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.onCloseModal();
          this.filterFn();
        } else {
          this.setState({ isSubmit: false });
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      });
  };
  onDelClick = (row) => {
    this.setState({ delete_id: row._id, showdelete: true });
  };
  onDelete = () => {
    this.setState({ disable: true });
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
      .delete("/employee/delete/" + this.state.delete_id)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false,
            },
            () => {
              var props = {
                message: 'User deleted successfully',
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.filterFn();
        } else {
          this.setState({ disable: false });
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      });
  };
  restoreUser = (row) => {
    var data = {
      userId: row._id,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/json")
      .get(`/employee/restore/${row._id}`, data)
      .then((res) => {
        if (res.data.status === "200") {
          this.setState(
            {
              loading: true,
              isSubmit: false,
            },
            () => {
              var props = {
                message: res.data.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.filterFn();
        } else {
          this.setState({ isSubmit: false });
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      });
  };

  openStatusFilterMenu = (event) => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      statusConfig,
      searchFilter: '',
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleStatusSelect = (item) => {
    this.setState({ loading: true });
    var {
      suspendedUserFilterName,
      status,
      statusConfig,
    } = this.state;
    status = item._id;
    suspendedUserFilterName = item.name;
    statusConfig.anchorElStatus = null;
    this.setState(
      {
        statusConfig,
        suspendedUserFilterName,
        status,
        pageNumber: 1,
        statusFilter: item.key,
        statusFilterValue: item.name === "" ? "All" : item.name
      },
      () => {
        this.filterFn()

      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeStatusFilterMenu = () => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author Murali
   * To open territory filter menu
   */
  openCompanyFilterMenu = (event) => {
    const { companyConfig } = this.state;
    companyConfig.anchorElCompany = event.currentTarget;
    this.setState(
      {
        companyConfig,
      },
      () => {
        this.companyListHandler("");
      }
    );
  };
  /**
   * @author Murali
   * To close territory filter menu
   */
  closeCompanyFilterMenu = () => {
    const { companyConfig } = this.state;
    companyConfig.anchorElCompany = null;
    this.setState({
      companyConfig,
    });
  };
  /**
   * @author Murali
   * To display territory based on search
   */
  onCompanySearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchCompanyValue: value,
    });
    this.companyListHandler(value);
  };
  /**
   * @author Murali
   * To display data based on selected territory
   */
  handleCompanySelect = (item) => {
    const { companyConfig } = this.state;
    companyConfig.anchorElCompany = null;
    let data = "";
    this.setState(
      {
        companyFilter: item._id,
        companyName: item.name === "All" ? "" : item.name,
        companyFilterValue: item.name === "All" ? "All" : item.name,
        companySize: item.size,
        companyConfig,
        searchCompanyValue: "",
        salesrepName: "",
        compFilter: true,
        loading: true,
        pageNumber: 1,
        salesrepOption: [
          {
            _id: "",
            name: "All",
          },
        ],
      },
      () => {
        this.filterFn();
        // this.compfilter(this.state.companyFilter, this.state.companySize);
      }
    );
  };
  /**
   * @author Murali
   * To get territory names in filter
   */
  companyListHandler = (search) => {
    const { companyOption } = this.state;

    let data = {
      companyName: search
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/companylist", data)
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.finalCompany;
          var newArray = companyOption.splice();
          if (this.state.searchCompanyValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item.id;
              obj.name = item.name;
              obj.size = item.size;
              newArray.push(obj);
            });
          }
          this.setState({
            companyOption: newArray,
          });
        } else {
          this.setState({
            companyOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  openDivisionFilterMenu = (event) => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = event.currentTarget;
    this.setState({
      divisionConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleDivisionSelect = (item) => {
    this.setState({ loading: true });
    var {
      divisionFilter,
      divisionName,
      divisionConfig,
    } = this.state;
    divisionFilter = item._id;
    divisionName = item.name;
    divisionConfig.anchorElDivision = null;
    this.setState(
      {
        divisionConfig,
        divisionName,
        divisionFilter,
        areaFilter: "",
        regionFilter: "",
        territoryFilter: "",
        pageNumber: 1,
        divisionFilterValue : item.name === "" ? "All" : item.name
      },
      () => {

        this.filterFn();
        this.getFilters('divisionFilter')
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeDivisionFilterMenu = () => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = null;
    this.setState({
      divisionConfig,
    });
  };
  openAreaFilterMenu = (event) => {
    const { areaConfig } = this.state;
    areaConfig.anchorElArea = event.currentTarget;
    this.setState({
      areaConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleAreaSelect = (item) => {
    this.setState({ loading: true });
    var {
      areaFilter,
      areaName,
      areaConfig,
    } = this.state;
    areaFilter = item._id;
    areaName = item.name;
    areaConfig.anchorElArea = null;
    this.setState(
      {
        areaConfig,
        areaName,
        areaFilter,
        regionFilter: "",
        territoryFilter: "",
        pageNumber: 1,
        areaFilterValue: item.name === "" ? "All" : item.name
      },
      () => {

        this.filterFn();
        this.getFilters('areaFilter')
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeAreaFilterMenu = () => {
    const { areaConfig } = this.state;
    areaConfig.anchorElArea = null;
    this.setState({
      areaConfig,
    });
  };
  openRegionFilterMenu = (event) => {
    const { regionConfig } = this.state;
    regionConfig.anchorElRegion = event.currentTarget;
    this.setState({
      regionConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleRegionSelect = (item) => {
    this.setState({ loading: true });
    var {
      regionFilter,
      regionName,
      regionConfig,
    } = this.state;
    regionFilter = item._id;
    regionName = item.name;
    regionConfig.anchorElRegion = null;
    this.setState(
      {
        regionConfig,
        regionName,
        regionFilter,
        territoryFilter: "",
        pageNumber: 1,
        regionFilterValue: item.name === "" ? "All" : item.name
      },
      () => {

        this.filterFn();
        this.getFilters('regionFilter')
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeRegionFilterMenu = () => {
    const { regionConfig } = this.state;
    regionConfig.anchorElRegion = null;
    this.setState({
      regionConfig,
    });
  };
  openTerritoryFilterMenu = (event) => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = event.currentTarget;
    this.setState({
      territoryConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleTerritorySelect = (item) => {
    this.setState({ loading: true });
    var {
      territoryFilter,
      territoryName,
      territoryConfig,
    } = this.state;
    territoryFilter = item._id;
    territoryName = item.name;
    territoryConfig.anchorElTerritory = null;
    this.setState(
      {
        territoryConfig,
        territoryName,
        territoryFilter,
        territoryFilterName: item.name === "" ? "All" : item.name,
        pageNumber: 1,
      },
      () => {

        this.filterFn();
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeTerritoryFilterMenu = () => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = null;
    this.setState({
      territoryConfig,
    });
  };
  openRoleFilterMenu = (event) => {
    const { roleConfig } = this.state;
    roleConfig.anchorElRole = event.currentTarget;
    this.setState({
      roleConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleRoleSelect = (item) => {
    this.setState({ loading: true });
    var {
      roleFilter,
      roleFiltername,
      roleConfig,
    } = this.state;
    roleFilter = item._id;
    roleFiltername = item.name;
    roleConfig.anchorElRole = null;
    this.setState(
      {
        roleFilterValue: item.name === "" ? "All" : item.name,
        roleData: item.key,
        roleConfig,
        roleFiltername,
        roleFilter,
        pageNumber: 1,
      },
      () => {
        this.filterFn();
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 2000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeRoleFilterMenu = () => {
    const { roleConfig } = this.state;
    roleConfig.anchorElRole = null;
    this.setState({
      roleConfig,
    });
  };
  getFilters = (name) => {
    let filterdata = "";
    this.setState(
      { loading: true },
      () => {
        const {
          companyFilter,
          divisionFilter,
          areaFilter,
          regionFilter,
          territoryFilter,
        } = this.state;
        switch (name) {
          case "companyFilter":
            filterdata = {
              // companyData: this.state.filters.company,
              companyId: companyFilter,
            };
            break;
          case "divisionFilter":
            this.setState(
              {
                areaFilter: "",
                regionFilter: "",
                territoryFilter: "",
                pageNumber: 1,
              },
              () => {
                this.filterFn();
              }
            );
            filterdata = {
              // companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              divisionId: divisionFilter,
            };
            break;
          case "areaFilter":
            this.setState(
              {
                regionFilter: "",
                territoryFilter: "",
              },
              () => {
                this.filterFn();
              }
            );
            filterdata = {
              // companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              areaData: this.state.filters.area,
              areaId: areaFilter,
            };

            break;
          case "regionFilter":
            this.setState(
              {
                territoryFilter: "",
                pageNumber: 1,
              },
              () => {
                this.filterFn();
              }
            );
            filterdata = {
              // companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              areaData: this.state.filters.area,
              regionData: this.state.filters.region,
              regionId: regionFilter,
              pageNumber: 1,
            };
            break;
          default:
            this.filterFn();
            break;
        }
        if (
          name !== "roleFilter" &&
          name !== "territoryFilter" &&
          name !== "searchFilter"
        ) {
          AxiosInstance(
            (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user',
            "application/x-www-form-urlencoded; charset=UTF-8"
          )
            .post("/users/filters", filterdata)
            .then((resp) => {
              if (resp.status === 200) {
                this.setState({ filters: resp.data.data });
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
            });
        }
      }
    );
  };
  navigateDetail = (row) => {
    this.props.history.push("user/" + row._id);
  }
  render() {
    let handleClose = () => this.setState({ showdelete: false });
    var { loggedInRole, role, companySizeFilter, loading } = this.state;

    var options = [];
    if (loggedInRole.toLowerCase() === "super admin") {
      options.push(
        { name: "Super Admin", _id: "super admin", key:"superadmin" },
        { name: "Admin", _id: "admin", key:"admin" },
        // { name: "Manager", _id: "manager" },
        { name: "Division Leader", _id: "division manager", key:"divisionleader" },
        { name: "Area Director", _id: "area manager", key:"areadirector" },
        { name: "Regional Manager", _id: "team lead", key:"regionalmanager" },
        { name: "Territory Manager", _id: "territory manager", key:"territorymanager" },
        { name: "Representative", _id: "sales rep", key:"representative" }
      );
    } else if (loggedInRole.toLowerCase() === "admin") {
      options.push(
        { name: "Admin", _id: "admin", key:"admin" },
        { name: "Division Leader", _id: "division manager", key:"divisionleader" },
        { name: "Area Director", _id: "area manager", key:"areadirector" },
        { name: "Regional Manager", _id: "team lead", key:"regionalmanager" },
        { name: "Territory Manager", _id: "territory manager", key:"territorymanager" },
        { name: "Representative", _id: "sales rep", key:"representative" }
      );
    } else if (loggedInRole.toLowerCase() === "manager") {
      options.push(
        { name: "Division Leader", _id: "division manager", key:"divisionleader" },
        { name: "Area Director", _id: "area manager", key:"areadirector" },
        { name: "Regional Manager", _id: "team lead", key:"regionalmanager" },
        { name: "Territory Manager", _id: "territory manager", key:"territorymanager" },
        { name: "Representative", _id: "sales rep", key:"representative" }
      );
    }
    var statusOptions = [
      { _id: "all", name: "All Users", key:"alluser" },
      { _id: "inactive", name: "Suspended Users", key:'suspened' },
      { _id: "deleted", name: "Deleted Users", key:'deleted' }
    ];

    return (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        <Box component="span" className="company-comp">
          <Suspense fallback={<div></div>}>
            <AddEmployeeModal
              openModel={this.state.openModel}
              onClose={this.onCloseModal}
            />
            <SettingsModel
              onSuspSubmit={this.onSuspSubmit}
              adddeshandleChange={this.adddeshandleChange}
              modelData={this.state}
              open={this.state.showaddModal}
              handleClose={this.onCloseModal}
            />
          </Suspense>

          <Dialog open={this.state.showdelete} className="deleteServiceDialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
            <DialogTitle>
              {" "}
              <center> Delete User</center>{" "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <center>
                  Are you sure you want to delete this User? Your action cannot
                  be undone
                </center>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="cancel" onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={this.state.disable}
                className="delete"
                onClick={() => this.onDelete()}
                style={{ color: "red" }}
                autoFocus
              >
                {this.state.disable ? <CircularProgress size={20} /> : ""}{" "}
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <div className="header-add-btn">
            <Typography variant="h5" className="tableTitle" component="div">
              {loggedInRole.toLowerCase() === 'super admin' ? 'Users' : 'Team'}
            </Typography>
            {loggedInRole.toLowerCase() !== "sales rep" ? (
              <Button
                variant="contained"
                className="tableaddBtn grey-btn"
                color="default"
                style={{
                  backgrounColor: "#21BF73",
                  boxShadow: "none",
                  cursor:
                    this.state.loggerInRole === "manager" ||
                      this.state.loggerInRole === "sales rep"
                      ? "not-allowed !important"
                      : "pointer",
                }}
                disabled={
                  this.state.loggerInRole === "manager" ||
                  this.state.loggerInRole === "sales rep"
                }
                onClick={() => {
                  this.setState({ openModel: true });
                }}
              >
                Add User
              </Button>
            ) : (
                ""
              )}
          </div>
          <div style={{ maxWidth: "100%" }} className="User-Table Acc-Table">
            <div className="filter-search">
              <div className="new_custom_filters">
                {this.state.role === "super admin" ||
                  this.state.role === "admin" ? (

                    <Suspense fallback={<div></div>}>
                      <div
                        className="new_custom_filter_div"
                        onClick={this.openStatusFilterMenu}
                      >
                        {this.state.suspendedUserFilterName !== "" ? (
                          ""
                        ) : (
                            <label className="leftName">Status: </label>
                          )}
                        <label
                          aria-label="status_filter"
                          aria-controls="status_filter_select"
                          area-aria-haspopup="true"
                          className="rightName"
                        >
                          {this.state.suspendedUserFilterName === ""
                            ? `Active Users`
                            : upperFirst(this.state.suspendedUserFilterName)}
                        </label>
                        <img className="carret" src={carret_down} alt="carret" />
                      </div>
                      <CustomUserSelectComponent
                        filterArray={statusOptions}
                        config={this.state.statusConfig.config}
                        anchorEl={this.state.statusConfig.anchorElStatus}
                        handleClick={this.handleStatusSelect}
                        closeCustomPopup={this.closeStatusFilterMenu}
                      />
                    </Suspense>
                  ) : (
                    ""
                  )}
                {this.state.role === "super admin" ? (
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openCompanyFilterMenu}
                    >
                      {this.state.companyFilter !== "" ? (
                        ""
                      ) : (
                          <label className="leftName">Company: </label>
                        )}
                      <label
                        aria-label="company_filter"
                        aria-controls="company_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.companyFilter === ""
                          ? `All`
                          : upperFirst(this.state.companyName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.companyOption}
                      config={this.state.companyConfig.config}
                      anchorEl={this.state.companyConfig.anchorElCompany}
                      searchValue={this.state.searchCompanyValue}
                      handleClick={this.handleCompanySelect}
                      closeCustomPopup={this.closeCompanyFilterMenu}
                      onSearchFilter={this.onCompanySearchFilter}
                      placeholder="Search Company"
                    />
                  </Suspense>
                ) : (
                    ""
                  )}
                {(role.toLowerCase() === "super admin" &&
                  companySizeFilter === "large") ||
                  (role.toLowerCase() === "admin" &&
                    this.state.companySize === "large") ? (
                    <>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div"
                          onClick={this.openDivisionFilterMenu}
                        >
                          {this.state.divisionFilter !== "" ? (
                            ""
                          ) : (
                              <label className="leftName">Division: </label>
                            )}
                          <label
                            aria-label="division_filter"
                            aria-controls="division_filter_select"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.divisionFilter === ""
                              ? `All`
                              : upperFirst(this.state.divisionName)}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectComponent
                          filterArray={this.state.filters.division}
                          config={this.state.divisionConfig.config}
                          anchorEl={this.state.divisionConfig.anchorElDivision}
                          handleClick={this.handleDivisionSelect}
                          closeCustomPopup={this.closeDivisionFilterMenu}
                        />
                      </Suspense>
                    </>
                  ) : (
                    ""
                  )}
                {(role.toLowerCase() === "super admin" &&
                  companySizeFilter !== "small") ||
                  ((role.toLowerCase() === "admin" ||
                    role.toLowerCase() === "division manager") &&
                    this.state.companySize !== "small") ? (
                    <>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div"
                          onClick={this.openAreaFilterMenu}
                        >
                          {this.state.areaFilter !== "" ? (
                            ""
                          ) : (
                              <label className="leftName">Area: </label>
                            )}
                          <label
                            aria-label="area_filter"
                            aria-controls="area_filter_select"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.areaFilter === ""
                              ? `All`
                              : upperFirst(this.state.areaName)}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectComponent
                          filterArray={this.state.filters.area}
                          config={this.state.areaConfig.config}
                          anchorEl={this.state.areaConfig.anchorElArea}
                          handleClick={this.handleAreaSelect}
                          closeCustomPopup={this.closeAreaFilterMenu}
                        />
                      </Suspense>
                    </>
                  ) : (
                    ""
                  )}
                {role.toLowerCase() === "super admin" ||
                  role.toLowerCase() === "admin" ||
                  role.toLowerCase() === "division manager" ||
                  role.toLowerCase() === "area manager" ? (
                    <>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div"
                          onClick={this.openRegionFilterMenu}
                        >
                          {this.state.regionFilter !== "" ? (
                            ""
                          ) : (
                              <label className="leftName">Region: </label>
                            )}
                          <label
                            aria-label="region_filter"
                            aria-controls="region_filter_select"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.regionFilter === ""
                              ? `All`
                              : upperFirst(this.state.regionName)}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectComponent
                          filterArray={this.state.filters.region}
                          config={this.state.regionConfig.config}
                          anchorEl={this.state.regionConfig.anchorElRegion}
                          handleClick={this.handleRegionSelect}
                          closeCustomPopup={this.closeRegionFilterMenu}
                        />
                      </Suspense>

                    </>
                  ) : (
                    ""
                  )}
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openTerritoryFilterMenu}
                  >
                    {this.state.territoryFilter !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Territory: </label>
                      )}
                    <label
                      aria-label="territory_filter"
                      aria-controls="territory_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.territoryFilter === ""
                        ? `All`
                        : upperFirst(this.state.territoryName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectComponent
                    filterArray={this.state.filters?.territory}
                    config={this.state.territoryConfig?.config}
                    anchorEl={this.state.territoryConfig?.anchorElTerritory}
                    handleClick={this.handleTerritorySelect}
                    closeCustomPopup={this.closeTerritoryFilterMenu}
                  />
                </Suspense>

                {this.state.role === "super admin" ||
                  this.state.role === "admin" ||
                  this.state.role === "manager" ? (
                    <>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div"
                          onClick={this.openRoleFilterMenu}
                        >
                          {this.state.roleFilter !== "" ? (
                            ""
                          ) : (
                              <label className="leftName">Role: </label>
                            )}
                          <label
                            aria-label="role_filter"
                            aria-controls="role_filter_select"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.roleFilter === ""
                              ? `All`
                              : upperFirst(this.state.roleFiltername)}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectComponent
                          filterArray={options}
                          config={this.state.roleConfig.config}
                          anchorEl={this.state.roleConfig.anchorElRole}
                          handleClick={this.handleRoleSelect}
                          closeCustomPopup={this.closeRoleFilterMenu}
                        />
                      </Suspense>
                    </>
                  ) : (
                    ""
                  )}
              </div>
              <CssTextField
                id="outlined-basic"
                name="searchFilter"
                variant="outlined"
                type="text"
                placeholder="Search Here"
                style={{
                  color: "#303960",
                  fontFamily: "Roboto",
                  backgroundColor: "#F9F9F9",
                  opacity: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: "pointer" }}
                    >
                      <img alt="searchIcon" src={searchIcon} />
                    </InputAdornment>
                  ),
                }}
                value={this.state.searchFilter}
                onChange={this.Userfilter}
              />
            </div>
            <div className="table_container">
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "5%" }}>#</TableCell>
                      <TableCell style={{ width: "15%" }}>NAME</TableCell>
                      <TableCell style={{ width: "10%" }}>ROLE</TableCell>
                      <TableCell style={{ width: "25%" }}>EMAIL</TableCell>
                      <TableCell style={{ width: "15%" }}>PHONE</TableCell>
                      <TableCell style={{ width: "15%" }}>TEAM</TableCell>
                      {loggedInRole.toLowerCase() === "super admin" ? (
                        <TableCell style={{ width: "15%" }}>COMPANY</TableCell>
                      ) : (
                          ""
                        )}
                      <TableCell style={{ width: "10%" }}>
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="tablecell_container">
                    {loading ? (
                      <StyledTableRow>
                        <TableCell colSpan={9} style={{ textAlign: "center" }}>
                          <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={50}
                            width={50}
                            visible={loading}
                          />
                        </TableCell>
                      </StyledTableRow>
                    ) : !this.props.pageOfItems ||
                      this.props.pageOfItems.length === 0 ? (
                          <StyledTableRow>
                            <TableCell colSpan={9} style={{ textAlign: "center" }}>
                              No data found
                        </TableCell>
                          </StyledTableRow>
                        ) : (
                          (this.state.rowsPerPage > 0
                            ? this.props.pageOfItems.slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                            )
                            : this.props.pageOfItems
                          ).map((row, index) => (
                            <StyledTableRow key={row._id}>
                              <TableCell onClick={() => this.navigateDetail(row)}>
                                {(this.props.pager.currentPage - 1) *
                                  this.state.rowsPerPage +
                                  (index + 1)}
                              </TableCell>
                              <TableCell onClick={() => this.navigateDetail(row)}
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.name} {...this.state.tooltipprops} arrow><span>{row.name}</span></LightTooltip>
                              </TableCell>
                              {row.role.toLowerCase() === "super admin" ? (
                                <TableCell onClick={() => this.navigateDetail(row)}>
                                  {" "}
                                  <span>
                                    {row.role
                                      ? "Super Admin"
                                      : "-"}
                                  </span>
                                </TableCell>
                              ) : row.role.toLowerCase() === "admin" ? (
                                <TableCell onClick={() => this.navigateDetail(row)}>
                                  {" "}
                                  <span >
                                    {row.role
                                      ? (this.state.roleLabelChange.Admin ? this.state.roleLabelChange.Admin : "Admin")
                                      : "-"}
                                  </span>
                                </TableCell>
                              ) : row.role.toLowerCase() === "division manager" ? (
                                <TableCell onClick={() => this.navigateDetail(row)}>
                                  {" "}
                                  <span >
                                    {row.role
                                      ? (this.state.roleLabelChange.DivisionLeader ? this.state.roleLabelChange.DivisionLeader : "Division Leader")
                                      : "-"}
                                  </span>
                                </TableCell>
                              ) : row.role.toLowerCase() === "territory manager" ? (
                                <TableCell onClick={() => this.navigateDetail(row)}>
                                  {" "}
                                  <span>
                                    {row.role
                                      ? (this.state.roleLabelChange.TerritoryManager ? this.state.roleLabelChange.TerritoryManager : "Territory Manager")
                                      : "-"}
                                  </span>
                                </TableCell>

                              ) : row.role.toLowerCase() === "sales rep" ? (
                                <TableCell onClick={() => this.navigateDetail(row)}>
                                  {" "}
                                  <span>
                                    {row.role
                                      ? (this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative")
                                      : "-"}
                                  </span>
                                </TableCell>

                              ) : row.role.toLowerCase() === "area manager" ? (
                                <TableCell onClick={() => this.navigateDetail(row)}>
                                  {" "}
                                  <span>
                                    {row.role
                                      ? (this.state.roleLabelChange.AreaDirector ? this.state.roleLabelChange.AreaDirector : "Area Director")
                                      : "-"}
                                  </span>
                                </TableCell>


                              ) : row.role.toLowerCase() === "team lead" ? (
                                <TableCell onClick={() => this.navigateDetail(row)}>
                                  {" "}
                                  <span >
                                    {row.role
                                      ? (this.state.roleLabelChange.RegionalManager ? this.state.roleLabelChange.RegionalManager : "Regional Manager")
                                      : "-"}
                                  </span>
                                </TableCell>
                              ) : (
                                              ""
                                            )}
                            
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.email} {...this.state.tooltipprops} arrow>
                                  <div className="tooltip-container">
                                    <a href={"mailto:" + row.email} rel="noopener noreferrer">{row.email}</a>
                                  </div>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row.phone ? <LightTooltip title={row.phone} {...this.state.tooltipprops} arrow>
                                  <a href={"tel:" + row.phone} rel="noopener noreferrer">{row.phone}</a>
                                </LightTooltip> : "--"}
                              </TableCell>
                              <TableCell onClick={() => this.navigateDetail(row)}>
                                {row.levelName !== "" && row.levelName !== undefined
                                  ? row.levelName : "--"}
                              </TableCell>
                              {loggedInRole.toLowerCase() === "super admin" ? (
                                <TableCell onClick={() => this.navigateDetail(row)}>{!row.companyName ? "--" : row.companyName}</TableCell>
                              ) : (
                                  ""
                                )}
                              <TableCell>
                                <div
                                  className="table_action_btns"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  {/* <div>
                                <LightTooltip title="Add Task" arrow>
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={addIcontable}
                                    alt="add"
                                  ></img>
                                </LightTooltip>
                              </div> */}
                                  {row.deleted === true ? (
                                    <div>
                                      <LightTooltip
                                        title="Restore User"
                                        {...this.state.tooltipprops}
                                        arrow
                                      >
                                        <img
                                          src={restoreuser}
                                          alt="Restore User"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.restoreUser(row)
                                          }
                                        ></img>
                                      </LightTooltip>
                                    </div>
                                  ) :
                                    <>
                                      <div>

                                        <NavLink
                                          to={"user/" + row._id}
                                          className="navbars"
                                        >
                                          <LightTooltip title="View User" {...this.state.tooltipprops} arrow>
                                            <img src={openMenutable} alt="openmenu" />
                                          </LightTooltip>
                                        </NavLink>

                                      </div>

                                      {this.state.role.toLowerCase() === "admin" ||
                                        this.state.role.toLowerCase() ===
                                        "super admin" ? (
                                          <>
                                            {row.suspend === false ? (
                                              <div>
                                                <a>
                                                  <LightTooltip
                                                    title="Suspend User"
                                                    {...this.state.tooltipprops}
                                                    arrow
                                                  >
                                                    <img
                                                      src={suspended}
                                                      alt="Suspend User"
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() =>
                                                        this.onViewHandleClick(row)
                                                      }
                                                    ></img>
                                                  </LightTooltip>
                                                </a>
                                              </div>
                                            ) : (
                                                <div>
                                                  <a>
                                                    <LightTooltip
                                                      title="Revoke Suspension"
                                                      {...this.state.tooltipprops}
                                                      arrow
                                                    >
                                                      <img
                                                        src={suspendedcross}
                                                        alt="Revoke Suspension"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() =>
                                                          this.revokeSuspension(row)
                                                        }
                                                      ></img>
                                                    </LightTooltip>
                                                  </a>
                                                </div>
                                              )}
                                            <div>
                                              <a>
                                                <LightTooltip title="Delete User" {...this.state.tooltipprops} arrow>
                                                  <img
                                                    src={Delete}
                                                    alt="openmenu"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => this.onDelClick(row)}
                                                  ></img>
                                                </LightTooltip>
                                              </a>

                                            </div>

                                          </>
                                        ) : (
                                          ""
                                        )}
                                    </>
                                  }
                                </div>
                              </TableCell>
                            </StyledTableRow>
                          ))
                        )}
                  </TableBody>
                  <TableFooter>
                    {this.props.pageOfItems &&
                      this.props.pageOfItems.length !== 0 ?
                      <TableRow>
                        <td colSpan={20} className="pagination_container">
                          <CustomPaginationComponent
                            count={this.props.pager.totalPages}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.pager.currentPage? this.props.pager.currentPage: this.state.pageNumber}
                            onPageChange={this.handleCustomPaginagtionChange}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </td>
                      </TableRow> : null}
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Box>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole, companyId, role, userId, territory, search, company, division, area, region, status, filterRole) =>
      dispatch(
        action.userpagination(pageNo, pagerole, companyId, role, userId, territory, search, company, division, area, region, status, filterRole)
      ),
    onFilter: (states) => dispatch(action.onuserfilter(states)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TeamComponent));
