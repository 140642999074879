import React, { Component } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Card,
  CardContent,
  Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import closeBtn from "../../Assets/images/close_btn.svg";
import IconButton from "@material-ui/core/IconButton";
import "./AccountsComponent.css";
import DirectionsIcon from "@material-ui/icons/Directions";
import ExpandMoreIcon from "../../Assets/images/expansion_dd.svg";
import hospitalIcon from "../../Assets/images/icon-hospital.svg";
import iconWebsite from "../../Assets/images/icon-website.svg";
import iconQuickPhone from "../../Assets/images/icon-quick-phone.svg";
import iconCity from "../../Assets/images/Icon-location-city.svg";
import emailIcon from "../../Assets/images/icon-quick-email.svg";
import personBlueIcon from "../../Assets/images/Icon-blue-person.svg";
import phoneBlueIcon from "../../Assets/images/Icon-blue-phone.svg";
import emailBlueIcon from "../../Assets/images/Icon-blue-email.svg";
import { Transition } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { lowerCase } from "lodash";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
export class QuickViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departments: [],
    };
  }
  gotoMap = (lat, lon) => {
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" + lat + "," + lon
    );
  };

  render() {
    return (
      <div>
        <Dialog
          {...this.props}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          className="add_company_dialog account_quick_view_dialog"
          TransitionComponent={Transition}
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <img src={hospitalIcon} alt="hospital" />
            <span>{this.props.modeldata.accountName}</span>
            <IconButton
              onClick={this.props.onClose}
              className="closeBtn"
              color="inherit"
            >
              <img src={closeBtn} alt="close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <div className="quickItem">
                  <div className="quickItemName">Address :</div>
                  <div className="quickItemValue">
                    <img className="addrs-img" src={iconCity} alt="city" />
                    <div className="quickItemText">
                      {this.props.modeldata.address},{this.props.modeldata.city}
                      ,{this.props.modeldata.state}-
                      {this.props.modeldata.zipCode}{" "}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="quickItem">
                  <div className="quickItemName">Website :</div>
                  <div className="quickItemValue">
                    <img src={iconWebsite} alt="website" />
                    {this.props.modeldata.website ?
                      <div className="quickItemText">
                        <div className="website_title">
                          <LightTooltip title={this.props.modeldata.website} {...this.state.tooltipprops} placement="bottom-start">
                        <a
                          href={lowerCase(this.props.modeldata.website && this.props.modeldata.website.substring(0, 3)) === 'www' ? '//' + this.props.modeldata.website : this.props.modeldata.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >

                              {this.props.modeldata.website}</a></LightTooltip> </div></div>
                      : <div className="quickItemText">--</div>}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="quickItem">
                  <Grid item xs={12} sm={4}>
                    <div className="select_parent">
                      <Button
                        className="upload_btn"
                        variant="contained"
                        onClick={() =>
                          this.gotoMap(
                            this.props.modeldata.coordinates[1],//mongodb saves a coordinates[1] as latitude
                            this.props.modeldata.coordinates[0]//mongodb saves a coordinates[0] as longitude
                          )
                        }
                        startIcon={<DirectionsIcon />}
                      >
                        Get Direction
                      </Button>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Typography variant="h6" component="div" className="dept-header">
              Departments
            </Typography>
            {this.props.modeldata.departments !== undefined
              ? this.props.modeldata.departments.map((data, index) => (
                <ExpansionPanel className="account-quick-exp">
                  <ExpansionPanelSummary
                    expandIcon={<img src={ExpandMoreIcon} alt="expand" />}
                  >
                    <Typography
                      className="account-quick-exp-title"
                      variant="h6"
                      component="span"
                    >
                      {data.name}
                    </Typography>
                    {/* <span className="plus-task-green">+ Task</span> */}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                      {data.contact.length === 0 ? (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          <Typography
                            className="account-quick-exp-title"
                            variant="h6"
                            component="span"
                          >
                            No contacts added.
                          </Typography>
                        </div>
                      ) : (
                        data.contact.map((contact, index) => (
                          <>
                            <Grid item xs={12} sm={4}>
                              <Card variant="outlined">
                                <CardContent className="flex-div">
                                  <div className="qucikDepView">
                                    <img src={personBlueIcon} alt="phone" />
                                    <div className="qucikDepViewValue">
                                      {contact.name}
                                    </div>
                                  </div>
                                  <div className="qucikDepView">
                                    <img src={phoneBlueIcon} alt="phone" />
                                    {contact.phone.map((phone, index) => (
                                      <span className="qucikDepViewValue">
                                        {phone.phoneNo}({phone.phoneType}),
                                      </span>
                                    ))}
                                  </div>
                                  <div className="qucikDepView">
                                    <img src={emailBlueIcon} alt="email" />
                                    {contact.email.map((email, index) => (
                                      <span className="qucikDepViewValue">
                                        {email.emailid}({email.emailType}),
                                      </span>
                                    ))}
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          </>
                        ))
                      )}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))
              : ""}
            <div className="add_company_action">
              <Button className="ok_btn green-btn" variant="contained">
                <a
                  href={"/account-details/" + this.props.modeldata._id}
                  style={{ color: "white" }}
                  rel="noopener noreferrer"
                >
                  Detailed View
                </a>
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
