import React from "react";
import { Button, Grid ,InputAdornment} from "@material-ui/core";
import { CssTextField } from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import eyeIcon from "../../../Assets/images/eye-icon.svg";
import eyeCloseIcon from "../../../Assets/images/eye-close-icon.svg";
import {Tooltip} from 'antd';

const PasswordAndSecurityComponent = (props) => {
  return (
    <div className="notifications-container password-container">
      <div className="password_header">
        <h5>Password & Security</h5>
      </div>
      <div className="password_body add_company_dialog">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <label className="label bl-label">
              Current Password <span className="labelAsterisk">*</span>
            </label>
            <CssTextField
              name="oldPassword"
              variant="outlined"
              margin="dense"
              value={props.oldPassword}
              type={props.oldHidden?"password":"text"}
              fullWidth
              placeholder="Enter current password"
              onChange={props.onPasswordChange}
              InputProps={{   
                endAdornment: 
                    <InputAdornment 
                        position="end" 
                        style={{cursor:'pointer'}} 
                        onClick={()=>props.onIconChange("oldHidden")}
                        >
                      <Tooltip title={props.oldHidden?"Show Password":"Hide Password"} placement="bottom">    
                        <img src={props.oldHidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
                      </Tooltip>
                    </InputAdornment>,
            }}
            />
            <span className="error">{
              props.changePasswordError.oldPassword !== ""?`*${props.changePasswordError.oldPassword}`:""
            }</span>
            <label className="label bl-label">
              New Password <span className="labelAsterisk">*</span>
            </label>
            <CssTextField
              name="newPassword"
              variant="outlined"
              margin="dense"
              value={props.newPassword}
              type={props.newHidden?"password":"text"}
              fullWidth
              onChange={props.onPasswordChange}
              placeholder="Enter a new password"
              InputProps={{   
                endAdornment: 
                    <InputAdornment 
                        position="end" 
                        style={{cursor:'pointer'}} 
                        onClick={()=>props.onIconChange("newHidden")}
                        >
                      <Tooltip title={props.newHidden?"Show Password":"Hide Password"} placement="bottom">  
                        <img src={props.newHidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
                      </Tooltip>
                    </InputAdornment>,
            }}
            />
            <span className="error">
              {props.changePasswordError.newPassword !== ""?`*${props.changePasswordError.newPassword}`:""}
            </span>
            <label className="label bl-label">
              Confirm Password <span className="labelAsterisk">*</span>
            </label>
            <CssTextField
              name="confirmPassword"
              variant="outlined"
              value={props.confirmPassword}
              margin="dense"
              type={props.confirmHidden?"password":"text"}
              fullWidth
              onChange={props.onPasswordChange}
              placeholder="Confirm the password"
              InputProps={{   
                endAdornment: 
                    <InputAdornment 
                        position="end" 
                        style={{cursor:'pointer'}} 
                        onClick={()=>props.onIconChange("confirmHidden")}
                        >
                      <Tooltip title={props.confirmHidden?"Show Password":"Hide Password"} placement="bottom">
                        <img src={props.confirmHidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
                      </Tooltip>  
                    </InputAdornment>,
            }}
            />
            <span className="error">
              {props.changePasswordError.confirmPassword !== ""?`*${props.changePasswordError.confirmPassword}`:""}
            </span>
            {/* error msg comes here inside condision because it is last child */}
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="password_rules">
            <h6>Password Must contain:</h6>
             <ul>
                 <li>At least 1 upper Case letter (A-Z)</li>
                 <li>At least 1 number (0-9)</li>
                 <li>At least 1 special character</li>
                 <li>At least 8 characters</li>
             </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="add_company_action">
              <Button className="cancel_btn white-btn" variant="outlined" onClick={props.cancelBtn}>
                Cancel
              </Button>
              <Button
                className="ok_btn green-btn"
                type="submit"
                variant="contained"
                disabled={props.passwordSettingSubmit?true:false}
                onClick={props.onPasswordSubmitHandler}
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PasswordAndSecurityComponent;
