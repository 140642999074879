import React from "react";
import "../../HeaderComponent/HeaderComponent.css";
import "../../../App.css"
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import momentTimeZone from 'moment-timezone';
import { upperFirst } from 'lodash';
import userIcon from "../../../Assets/images/user-icon.png";
import { Transition } from "../../CommonUtilities/CommonUtilities";
import iconClose from "../../../Assets/images/close_btn.svg";
import logo from "../../../Assets/images/Sales_C2_logo.svg";
import taskAssignTwo from "../../../Assets/images/task_assign_two.png";
import taskAssignOne from "../../../Assets/images/task_assign_one.png";
import taskAssignAlert from "../../../Assets/images/task_asign_alert.svg";
import taskWarningIcon from "../../../Assets/images/task_warning_icon.svg";
import eventAssignOne from '../../../Assets/images/eventAssignOne.svg';
import recurring_pending from '../../../Assets/images/recurring_pending.svg'

const TaskAssignModal = (props) => {
  return (
    <Dialog
      open={props.taskAssignModalOpen}
      aria-labelledby="form-dialog-title"
      className={`add_company_dialog task_assignment_dialog update_status_dialog task_assignment_accept_dialog 
      ${props.taskAssigneeData && props.taskAssigneeData.priority
          ? "task_emergency"
          : ""
        }
      ${props.taskAssigneeData && (props.taskAssigneeData.conflict==true)
          ? "task_conflict"
          : ""
        }
      ${props.taskAssigneeData &&
          (props.taskAssigneeData.conflict==true) &&
          props.taskAssigneeData.priority
          ? "task_priority_conflict"
          : ""
        }`}
      TransitionComponent={Transition}
      transitionDuration={600}
    >
      <DialogTitle
        id="form-dialog-title"
        className="add_user_header task_assignment_header task_assignment_modal_header"
      >
        <img src={logo} alt="Sales-Icon" width="142px" height="auto"></img>
        <IconButton
          aria-label="close"
          className="closeBtn updateStatusCloseBtn"
          onClick={props.taskAssignModalClose}
        >
          <img src={iconClose}></img>
        </IconButton>
      </DialogTitle>
      <DialogContent className="task_assignment_modal_content">
        <div className="task_assign_model_container">
          {props.taskAssigneeData ? (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={5}>
                <img
                  className="task-img-width"
                  src={
                   ( props.taskAssigneeData.priority && (props.taskAssigneeData.type === "0" || props.taskAssigneeData.type === 0))
                      ? taskAssignTwo
                      : !props.taskAssigneeData.priority && (props.taskAssigneeData.type === "0" || props.taskAssigneeData.type === 0)
                        ? taskAssignOne
                        : (props.taskAssigneeData.type === "1" || props.taskAssigneeData.type === 1)
                          ? eventAssignOne
                          : eventAssignOne
                  }
                ></img>
              </Grid>
              <Grid item xs={12} sm={7}>
                <div className="tasking-assign-right">
                  <div className="task_event_heading">
                    <h1 className={(props.taskAssigneeData.type === "0" || props.taskAssigneeData.type === 0) ? "task_heading" : "event_heading"}>{
                      (props.taskAssigneeData.type === "0" || props.taskAssigneeData.type === 0)
                        ? "Task Assignment"
                        : (props.taskAssigneeData.type === "1" || props.taskAssigneeData.type === 1)
                          ? props.taskAssigneeData.eventType : "Event Assignment"}
                    </h1>
                    {(props.taskAssigneeData.type === "1" || props.taskAssigneeData.type === 1) && props.taskAssigneeData.priority ? <span className={"task_event_emergency"}>EMERGENCY</span> : ""}
                  </div>
                  <p>
                    {props.taskAssigneeData.priority && (props.taskAssigneeData.type === "0" || props.taskAssigneeData.type === 0) ? (
                      <img src={taskAssignAlert} />
                    ) : (
                      ""
                    )}
                    <div>
                    {(props.taskAssigneeData.type === "0" || props.taskAssigneeData.type === 0)
                      ? upperFirst(props.taskAssigneeData.taskName)
                      : upperFirst(props.taskAssigneeData.title)}.
                    {((props.taskAssigneeData.type === "0" || props.taskAssigneeData.type === 0) && 
                    (props.taskAssigneeData?.scheduleRules?.isRecurring === true)) ? 
                    <img src={recurring_pending}></img> : ""}
                    </div>
                  </p>
                  {props.taskAssigneeData.organizer ?
                    <div className="dashboard_task_event_attendees_section">
                      <div className="dashboard_task_event_attendees dashboard_task_event_organizer">
                        <span className="dashboard_task_event_attendee">Organizer :</span>
                        <img
                          className="event_attendee_tab_icon event_organizer_icon"
                          src={
                            props.taskAssigneeData.organizer
                              ? `https://salesc2userprofile.s3.amazonaws.com/${props.taskAssigneeData.organizer.profilePath}`
                              : userIcon
                          }
                          alt="back"
                        />
                        {props.taskAssigneeData.organizer.name ? props.taskAssigneeData.organizer.name : "--"}
                      </div>
                    </div>
                    : ""}
                  <span className="task_event_timing">{`
                  ${momentTimeZone(props.taskAssigneeData.startDate)
                      .tz(props.timeZoneName)
                      .format("MMMM DD YYYY")} 
                  at 
                  ${momentTimeZone(props.taskAssigneeData.startDate)
                      .tz(props.timeZoneName)
                      .format("hh:mm A")} 
                  - ${momentTimeZone(props.taskAssigneeData.endDate)
                      .tz(props.timeZoneName)
                      .format("hh:mm A")} 
                  (${props.diffTime ? props.diffTime : ""})`}</span>
                  {(props.taskAssigneeData.conflict==true) ? (
                    <div className="task_assign_errror_msg">
                      <img src={taskWarningIcon}></img>
                      {
                        (props.taskAssigneeData.type === "0" || props.taskAssigneeData.type === 0)
                          ? `This task conflicts with ${props.taskAssigneeData.conflictName}`
                          : `This event conflicts with ${props.taskAssigneeData.conflictName}`
                      }
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                    {(props.taskAssigneeData?.scheduleRules?.isRecurring===true) || (props.isRecurring?.isRecurring===true)?
                    <><span>Recurring schedule</span><div className="recurring-text">{props.taskAssigneeData.recurringText}</div></>
                    :""}
                </div>
                <div className="add_company_action task_assign_action task_assign_action_buttons">
                  <Button
                    className="cancel_btn white-btn"
                    onClick={() =>
                      (props.taskAssigneeData.type === 0 || props.taskAssigneeData.type === "0")
                        ? props.onTaskHandler("reject", props.taskAssigneeData)
                        : props.onEventHandler(props.taskAssigneeData, "reject")
                    }
                  >
                    Reject
                  </Button>
                  {props.taskAssigneeData.conflict == true ?
                    <Button
                      className="ok_btn green-btn"
                      onClick={() => (props.taskAssigneeData.type === 0 || props.taskAssigneeData.type === "0")
                      ? props.openConflictModalHandler(props.taskAssigneeData)
                      : props.openEventConflictModalHandler(props.taskAssigneeData)}
                    >
                      Accept</Button> :
                    <Button
                      className="ok_btn green-btn"
                      onClick={() =>
                        (props.taskAssigneeData.type === 0 || props.taskAssigneeData.type === "0")
                          ? props.onTaskHandler("accept", props.taskAssigneeData)
                          : props.onEventHandler(props.taskAssigneeData, "accept")
                      }>
                      Accept
                    </Button>
                  }
                </div>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TaskAssignModal;
