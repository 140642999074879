import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import iconClose from "../../../Assets/images/close_btn.svg";
import {
  CssTextField,
  Transition,
} from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import { startCase } from "lodash";
const ViewFullEstimateComponent = (props) => {
  return (
    <Dialog
      open={props.openViewFullEstimateDialog}
      className="add_company_dialog credit_card_dialog cost_breakup_dialog"
      TransitionComponent={Transition}
      transitionDuration={600}
      onExit={props.closeViewFullEstimateDialog}
    >
      <DialogTitle
        id="form-dialog-title"
        className="add_user_header task_assignment_header"
      >
        <span>Cost Break-up</span>
        <IconButton
          aria-label="close"
          className="closeBtn updateStatusCloseBtn"
          onClick={props.closeViewFullEstimateDialog}
        >
          <img src={iconClose}></img>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="cost_breakup_table">
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableCell width={"33.333%"} style={{ textAlign: "center" }}>
                  Number of Users
                </TableCell>
                <TableCell width={"33.333%"} style={{ textAlign: "center" }}>
                  Days Active
                </TableCell>
                <TableCell width={"33.333%"} style={{ textAlign: "center" }}>
                  Cost ($)
                </TableCell>
              </TableHead>
              {props.costSplitup && props.costSplitup.splitUp ? (
                props.costSplitup.splitUp.map((item) => (
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <div>{item.count}</div>
                      </TableCell>
                      <TableCell>
                        <div>{item.days}</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {item.price ? parseFloat(item.price).toFixed(2) : 0}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: "center" }}>
                      <div className="no-data-found">No data Found</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <div className="cost_breakup_total">
            <h5 className="sub_total">
              <span className="cost_breakup_total_label">
                Sub-total (Exclusive of Taxes):
              </span>
              <span className="cost_breakup_total_amount">
                ${" "}
                {props.costSplitup.total
                  ? parseFloat(props.costSplitup.total).toFixed(2)
                  : 0}
              </span>
            </h5>
            {/* <h5 className="amount_due"><span className="cost_breakup_total_label">Tax({props.costSplitup.taxPercent?props.costSplitup.taxPercent:0}%):</span><span className="cost_breakup_total_amount">$ {props.costSplitup.taxAmount?parseFloat(props.costSplitup.taxAmount).toFixed(2):0}</span></h5> */}
            <h3 className="total_amount">
              <span className="cost_breakup_total_label">Total:</span>
              <span className="cost_breakup_total_amount">
                ${" "}
                {props.costSplitup.total
                  ? parseFloat(props.costSplitup.total).toFixed(2)
                  : 0}
              </span>
            </h3>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewFullEstimateComponent;
