import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {Dialog,Menu,Typography,Divider,Button} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from "../../Assets/images/Sales_C2_logo.svg";
import notificationIcon from "../../Assets/images/notification-icon.svg";
import { ProfileURL } from '../../client-config';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import closeBtn from "../../Assets/images/close_btn.svg";
import userIcon from "../../Assets/images/user-icon.png";
import settingsIcon from "../../Assets/images/settings-icon.svg";
import Axios from "axios";
import { AxiosInstance, basedUrlForSuperAdmin } from "../../Utilities";
import { Transition } from "../CommonUtilities/CommonUtilities";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import { NotificationComponent } from "../NotificationComponent/NotificationComponent";
export class MobileHeaderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBilling:false,
      userData :localStorage.getItem("userdata")
                ? JSON.parse(localStorage.getItem("userdata"))
                : "",
      profilePath: localStorage.getItem("userdata") ? JSON.parse(localStorage.getItem("userdata")).profilePath : "",
      anchorEl : null,
    };
    
  }
  
  UNSAFE_componentWillMount(){
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ? 6019
    : "subscription",
  "application/json")
  .get("/show_billing")
  .then((resp) => {
    let response = resp && resp.data;
    if (
      response &&
      (response.status === "200" || response.status === 200)
    ) {
     this.setState({
      showBilling:response.data.showBilling
     })
    } else if (
      response &&
      (response.status === "400" || response.status === 400)
    ) {
      // let propserror = {
      //   message: response.msg,
      //   icon: <img src={iconNotificationError} alt="success" />,
      //   type: "error",
      //   placement: "topRight",
      //   duration: 3,
      //   top: 200,
      //   className: "task_notification error-notify",
      // };
      // NotificationComponent(propserror);
    }
  })
  .catch((err) => {
    var error = err.response;
    if (error && (error.status === "403" || error.status === 403)) {
      localStorage.clear();
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    } else if (
      error &&
      (error.status === "500" || error.status === 500)
    ) {
      let propserror = {
        message: "Something went wrong",
        icon: <img src={iconNotificationError} alt="success" />,
        type: "error",
        placement: "topRight",
        duration: 3,
        top: 200,
        className: "task_notification error-notify",
      };
      NotificationComponent(propserror);
    }
  });
  }

  handleProfileMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };
  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    })
  };
  switchBackToSuperAdminHandler = () => {
    if(localStorage.getItem('old_token')){
     Axios.defaults.headers['Authorization']=`Bearer `+localStorage.getItem('old_token');
     Axios.get(`${basedUrlForSuperAdmin}${(process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company'}/api/v1/superadmin/createSuperAdminAccess`).then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          localStorage.clear();
          localStorage.setItem("userdata", JSON.stringify(response.data));
          localStorage.setItem("token", response.token.split(' ')[1]);
          setTimeout(() => {
            window.location.href = "/"
          }, 1500)
        }else if (response && (response.status === "400" || response.status === 400)) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      }).catch(err=>{
  			var error=err.response;
            if(error && (error.status === '403' || error.status === 403)){
                localStorage.clear();
                setTimeout(()=>{
                    window.location.href="/";
                },500)
            }else if(error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
			});
    }else{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company')
      .get('/superadmin/createSuperAdminAccess').then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          localStorage.clear();
          localStorage.setItem("userdata", JSON.stringify(response.data));
          localStorage.setItem("token", response.token.split(' ')[1]);
          setTimeout(() => {
            window.location.href = "/"
          }, 1500)
        }else if (response && (response.status === "400" || response.status === 400)) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      }).catch(err=>{
  			var error=err.response;
            if(error && (error.status === '403' || error.status === 403)){
                localStorage.clear();
                setTimeout(()=>{
                    window.location.href="/";
                },500)
            }else if(error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
      });
    }
  }
  logoutHandler = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  render() {
    const { userData, profilePath } = this.state;
    let profileImg = "";
    if (profilePath !== undefined && profilePath !== "") {
      profileImg = ProfileURL + profilePath;
    } else {
      profileImg = userIcon;
    }
    return (
      <div>
        <Dialog
          className="mobile_menu_dialog"
          {...this.props}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition} 
          transitionDuration={600}
        >
          <div id="header-navbar" className="grow">
          <Menu
        anchorEl={this.state.anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleMenuClose}
        className="header-profile mobile-profile"
      >
        
        <Typography className="user-name">{userData.username}</Typography>
        <Typography className="user-email">{userData.email}</Typography>
        <NavLink to="/profile" onClick={this.handleMenuClose}>
          <img src={settingsIcon} className="accountimg" alt="account"/>
          <Typography variant="inherit">Manage Settings</Typography>
        </NavLink>
        <Divider component="li" className="hr-line" />
        {localStorage.getItem('switchedToSuperAdmin') || localStorage.getItem('switchedToSuperAdminFromUser') ? <Button
          className="logout-button"
          variant="contained"
          onClick={this.switchBackToSuperAdminHandler}
        >
          Switch to Super Admin
        </Button> : <Button
            className="logout-button primary-btn"
            variant="contained"
            onClick={this.logoutHandler}
          >
            Logout
        </Button>}
      </Menu>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  className="header-logo"
                  color="inherit"
                  aria-label="open drawer"
                >
                  {/* <MenuIcon /> */}
                  <img
                    src={logo}
                    alt="Sales-Icon"
                    width="35px"
                    height="auto"
                  ></img>
                </IconButton>

                <div className="grow" />
                <div className="sectionDesktop">
                  <IconButton className="add_task_btn_mobile">+</IconButton>
                  <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <img src={notificationIcon} alt="notificiation"/>
                  </IconButton>
                  <IconButton
                    onClick={this.props.onClose}
                    className="mobile-menu-icon"
                  >
                    <img src={closeBtn} alt="close"/>
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
          </div>
          <div className="mobile-menu">
            <List component="nav" aria-label="menu list">
              <ListItem className="mobile-menu-list" button>
                <NavLink to="/dashboard" className="navbars">
                  Dashboard
                </NavLink>
              </ListItem>
              {userData && userData.role.toLowerCase() === "super admin" ? (
                <ListItem className="mobile-menu-list" button>
                  <NavLink to="/company" className="navbars">
                    Company
                  </NavLink>
                </ListItem>
              ) : (
                ""
              )}
              {userData.role.toLowerCase() === "super admin" ||
              userData.role.toLowerCase() === "admin" ? (
                <ListItem className="mobile-menu-list" button>
                  <NavLink to="/user" className="navbars">
                    Users
                  </NavLink>
                </ListItem>
              ) : (
                <ListItem className="mobile-menu-list" button>
                  <NavLink to="/user" className="navbars">
                    Team
                  </NavLink>
                </ListItem>
              )}

              <ListItem className="mobile-menu-list" button>
                <NavLink to="/account" className="navbars">
                  Accounts
                </NavLink>
              </ListItem>
              {userData && userData.role.toLowerCase() === "super admin" ? (
                ""
              ) : (
                <ListItem className="mobile-menu-list" button>
                  <React.Fragment>
                    <NavLink to="/products" className="navbars">
                      Products
                    </NavLink>
                  </React.Fragment>
                </ListItem>
              )}
              {userData &&
              (userData.role.toLowerCase() === "managers" ||
                userData.role.toLowerCase() === "manager" ||
                userData.role.toLowerCase() === "super admin") ? (
                ""
              ) : (
                <ListItem className="mobile-menu-list" button>
                  <NavLink to="/services" className="navbars">
                    Services
                  </NavLink>
                </ListItem>
              )}
              {userData && userData.role.toLowerCase() === "super admin" ? (
                ""
              ) : (
                <ListItem className="mobile-menu-list" button>
                  <React.Fragment>
                    <NavLink to="/task" className="navbars">
                      Tasks
                    </NavLink>
                  </React.Fragment>
                </ListItem>
              )}
              {userData && userData.role.toLowerCase() === "admin" ? (
                <ListItem className="mobile-menu-list" button>
                  <NavLink to="/org-structure" className="navbars">
                  Organization Structure
                  </NavLink>
                </ListItem>
              ) : (
                ""
              )}
                {userData && userData.role.toLowerCase() === "admin" ? (
                <ListItem className="mobile-menu-list" button>
                <React.Fragment>
                  <NavLink to="/reports" className="navbars">
                  Reports
                  </NavLink>
                </React.Fragment>
              </ListItem>
              ) : (
                <ListItem className="mobile-menu-list" button>
                  <React.Fragment>
                    <NavLink to="/reports" className="navbars">
                    Reports
                    </NavLink>
                  </React.Fragment>
                </ListItem>
              )}
              {this.state.showBilling?
              <ListItem className="mobile-menu-list" button>
                  <React.Fragment>
                    <NavLink to="/billing" className="navbars">
                    {userData &&
              userData.role.toLowerCase() === "super admin"?"Subscriptions":"Billing"}
                    </NavLink>
                  </React.Fragment>
                </ListItem>:""}
              {userData &&
              (userData.role.toLowerCase() === "super admin" ||
                userData.role.toLowerCase() === "admin") ? (
                ""
              ) : (
                <>
                  <ListItem className="mobile-menu-list" button>
                    <NavLink to="/calendar" className="navbars">
                      Calendar
                    </NavLink>
                  </ListItem>
                  <ListItem className="mobile-menu-list" button>
                    <NavLink to="/map" className="navbars">
                      Map
                    </NavLink>
                  </ListItem>
                </>
              )}

              {/* <ExpansionPanel className="mobile-menu-expansion">
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="">More</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel> */}

              <ListItem className="mobile-menu-list" button onClick={this.handleProfileMenuOpen}>
                <ListItemIcon className="user_icon" >
                  <img src={profileImg} className="profile-pic" alt="profile"/> 
                </ListItemIcon>
                <ListItemText primary={userData.username} />
              </ListItem>
            </List>
          </div>
        </Dialog>
      </div>
    );
  }
}
