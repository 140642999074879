import React, { Component, Suspense } from "react";
import "./ReportsGraphComponent.css";
import * as action from "../../Store/Actions/index";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from "@material-ui/core";
import { Select, Menu, Dropdown, DatePicker, Empty, Tabs } from "antd";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import GetAppIcon from '@material-ui/icons/GetApp';
import carret_down from "../../Assets/images/carret_down.svg";
import jwt from "jsonwebtoken";
import momentTimeZone from "moment-timezone";
import Loader from "react-loader-spinner";
import { AxiosInstance } from "../../Utilities";
import { upperFirst } from "lodash";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import { exportToCSV } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import NumberFormat from 'react-number-format';

import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
var CommonBarGraphwithSeparator = React.lazy(() => import("../../SharedComponents/ChartsComponent/CommonBarGraphComponent/CommonBarGraphwithSeparator"));
var CommonPieChartComponent = React.lazy(() =>
  import("../../SharedComponents/ChartsComponent/CommonPieChartComponent/CommonPieChartComponent")
);
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
var CustomSelectSwitchComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectSwitchComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import(
    "../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
  )
);
var CommonBarGraphComponent = React.lazy(() =>
  import(
    "../../SharedComponents/ChartsComponent/CommonBarGraphComponent/CommonBarGraphComponent"
  )
);
var CommonBarGraphDurationComponent = React.lazy(() =>
  import(
    "../../SharedComponents/ChartsComponent/CommonBarGraphComponent/CommonBarGraphDurationComponent"
  )
);
const { RangePicker } = DatePicker;
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);

class ReportsGraphComponent extends Component {
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var zoneName = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    this.state = {
      timeZoneName: zoneName,
      userData: userData,
      loggedInRole: userData.role,
      role: userData.role.toLowerCase(),
      page: 0,
      rowsPerPage: 10,
      dateFilter: {
        startDate: moment.tz(moment().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(moment().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
      },
      // dateFilter: {
      //   startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
      //   endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      // },
      companyOption: [],
      filters: {
        company: [],
        area: [],
        division: [],
        region: [],
        territory: [],
      },
      companySize: userData.companySize.toLowerCase(),
      companyId: userData.companyId,
      // userId: "",
      companySizeFilter: "large",
      acountFilter: "",
      healthFilter: "",
      healthName: "",
      companyFilter: "",
      divisionFilter: "",
      areaFilter: "",
      regionFilter: "",
      territoryFilter: "",
      roleFilter: "",
      roleFiltername: "",
      employeeFilter: "",
      employeeName: "",
      accountFilter: "",
      healthOption: [],
      accountName: "",
      searchAssigneeValue: "",
      searchFilter: "",
      orderBy: "name",
      StatusSwitchToName: "",
      StatusSwitchToFilter: "account",
      order: "asc",
      accountOption: [],
      assignedId: "",
      assignedName: "",
      assignedOption: [],
      assigneeConfig: {
        anchorElAssignee: null,
        config: {
          id: "assignee_filter_select",
        },
      },
      healthConfig: {
        anchorElAccount: null,
        config: {
          id: "account_filter_select",
        },
      },
      searchAccountValue: "",
      loading: true,
      compFilter: false,
      regionName: "",
      territoryName: "",
      divisionName: "",
      level: "",
      levelId: "",
      StatusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      descriptioneror: {
        error: "",
      },
      companyConfig: {
        anchorElCompany: null,
        config: {
          id: "company_filter_select",
        },
      },
      searchCompanyValue: "",
      divisionConfig: {
        anchorElDivision: null,
        config: {
          id: "division_filter_select",
        },
      },
      areaConfig: {
        anchorElArea: null,
        config: {
          id: "area_filter_select",
        },
      },
      regionConfig: {
        anchorElRegion: null,
        config: {
          id: "region_filter_select",
        },
      },
      territoryConfig: {
        anchorElTerritory: null,
        config: {
          id: "territory_filter_select",
        },
      },
      roleConfig: {
        anchorElRole: null,
        config: {
          id: "role_filter_select",
        },
      },
      employeeConfig: {
        anchorElEmployee: null,
        config: {
          id: "employee_filter_select",
        },
      },
      employeeOption: [],
      searchEmployeeValue: "",
      accountOption: [],
      searchAccountValue: "",
      RepChartData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          bar: {

            pointWidth: 25,
            minPointLength: 2,
          },
          column: {
            color: '#67f3b1',
            stacking: "percent",
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
          }
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.f} Tasks </b>`,
        },
        startDate: moment.tz(moment().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(moment().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
        // endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      },
      DurationRepChartData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          bar: {

            pointWidth: 25,
            minPointLength: 2,
          },
          column: {
            color: '#67f3b1',
            stacking: "percent",
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
          }
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.f} Hours </b>`,
        },
        startDate: moment.tz(moment().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(moment().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
        // endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      },
      AccSatisfactionData: {
        xAxis: [],
        yAxis: [],
        xAxisName: "",
        yAxisName: "",
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
          }
        },
        config: {},
        // tooltip: {
        //   pointFormat: `{series.name}: <b>{point.y}K</b>`,
        // },
        startDate: moment.tz(moment().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(moment().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
        // endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      },
      activeReportData: {
        xAxis: [],
        yAxis: [],
        xAxisName: "",
        yAxisName: "",
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
            color: "#A93BAC",
          }
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`,
        },
        startDate: moment.tz(moment().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(moment().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
        // endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      },
      activeAccountData: {
        xAxis: [],
        yAxis: [],
        xAxisName: "",
        yAxisName: "",
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
            color: "#CF9339",
          }
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`,
        },
        startDate: moment.tz(moment().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(moment().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
        // endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      },
      PipelineReportData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
            }, showInLegend: true
          },
        },
        config: {
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.2f} hr</b>`
        },
        startDate: moment.tz(moment().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(moment().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
        // endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      },
      revenueData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
            minPointLength: 2,
            pointWidth: 25,
          },
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`,
        },
        startDate: moment.tz(moment().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(moment().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
        // endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      },
      reportType: "Revenue Report",
      tableData: [],
      tableHeaders: [],
      accountRep: [],
      anchorEl: null,

    };
  }


  /**
   * @author Murali
   * To get events and tasks for the current month
   */
  UNSAFE_componentWillMount = () => {
    this.accountFilter("");
    // this.setState({
    //   dateFilter: {
    //     startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc(),
    //     endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'),this.state.timeZoneName).utc(),
    //   },
    // },()=>{
    // let data = {

    //   startDate: this.state.RepChartData.startDate,
    //   endDate: this.state.RepChartData.endDate,
    // };
    const { role } = this.state;
    if (role === "super admin") {
      console.log("date---", this.state.dateFilter.startDate, "\n", this.state.dateFilter.endDate);

      this.companyListHandler("");
    } else {
      console.log("date---", this.state.dateFilter.startDate, "\n", this.state.dateFilter.endDate);
      let Revdata = {
        companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
        companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
        level: this.state.level !== "" ? this.state.level : null,
        levelId: this.state.levelId !== "" ? this.state.levelId : null,
        // startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
        // endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
        startDate: this.state.dateFilter.startDate,
        endDate: this.state.dateFilter.endDate,
        type: this.state.dateFilter.endDate,
        accountId: this.state.acountFilter ? this.state.acountFilter : null
      };
      this.fetchAccSatisfactionData(Revdata, "revenue");

    }
    // })
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { role } = this.state;
    // this.setState({
    //   dateFilter: {
    //     startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc(),
    //     endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'),this.state.timeZoneName).utc(),
    //   },
    // })
    let data = {
      // companyId: role === 'super admin' ? '' : companyId,
      roles: role,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/users/filters", data)
      .then((resp) => {
        if (resp && resp.status === 200) {
          this.setState({ filters: resp.data.data });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  fetchRepUtilizationData = (data, type) => {
    this.setState({ loading: true })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post("/report" + type, data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { RepChartData } = this.state;
          RepChartData.xAxis = response.data.xaxis;
          RepChartData.yAxis = response.data.yaxis;
          this.setState({
            RepChartData,
            tableData: response.tableData,
            tableHeaders: response.tableHeaders,
            loading: false
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({ loading: false })
        }
      });
  };


  fetchAccountActivityReportRepData = (data) => {
    this.setState({ loading: true })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post("/report/repUtilizationChart", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { RepChartData } = this.state;
          RepChartData.xAxis = response.data.xaxis;
          RepChartData.yAxis = response.data.yaxis;
          this.setState({
            RepChartData,
            tableData: response.tableData,
            tableHeaders: response.tableHeaders,
            loading: false
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({ loading: false })
        }
      });
  };


  fetchAccSatisfactionData = (data, type) => {
    this.setState({ loading: true })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post("/report/" + type, data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { AccSatisfactionData } = this.state;
          AccSatisfactionData.xAxis = response.data.xaxis;
          AccSatisfactionData.yAxis = response.data.yaxis;
          if (type === "completed") {
            AccSatisfactionData.yAxisName = "Account Satisfaction";
            AccSatisfactionData.xAxisName = "";
          } else if (type === "uncovered") {
            AccSatisfactionData.yAxisName = "Uncovered Tasks";
            AccSatisfactionData.xAxisName = "";
          } else if (type === "cancelled") {
            AccSatisfactionData.yAxisName = "Cancellations";
            AccSatisfactionData.xAxisName = "";
          }
          this.setState({
            AccSatisfactionData,
            tableData: response.tableData,
            tableHeaders: response.tableHeaders,
            loading: false
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({ loading: false })
        }
      });
  };
  fetchPipelinePiChartData = (data, url) => {
    this.setState({ loading: true })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post(url, data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { PipelineReportData } = this.state;
          // RepChartData.xAxis = response.data.xaxis;
          PipelineReportData.yAxis = response.data;
          this.setState({
            PipelineReportData, tableData: response.tableData,
            tableHeaders: response.tableHeaders,
            loading: false
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let props = {
            message: 'Something went wrong',
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.setState({ loading: false })
        } else if (error && (error.status === "404" || error.status === 404)) {
          let props = {
            message: 'Something went wrong',
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.setState({ loading: false })
        }
      });

  }
  fetchactiveReportData = (data, type) => {
    this.setState({ loading: true })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post("/report/mostActive/" + type, data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { activeReportData, activeAccountData } = this.state;
          activeReportData.xAxis = response.data.xaxis;
          activeReportData.yAxis = response.data.yaxis;
          activeAccountData.xAxis = response.data.xaxis;
          activeAccountData.yAxis = response.data.yaxis;
          if (type === "account") {
            activeAccountData.yAxisName = "Most Active Accounts";
            activeAccountData.xAxisName = "";
          } else if (type === "employee") {
            activeReportData.yAxisName = "Most Active Reps";
            activeReportData.xAxisName = "";
          }
          this.setState({
            activeReportData,
            tableData: response.tableData,
            tableHeaders: response.tableHeaders,
            loading: false
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({ loading: false })
        }
      });
  };
  fetchRevenueData = (data, url) => {
    this.setState({ loading: true })
    const { role, companyFilter, companyId, companySizeFilter, companySize, level, levelId } = this.state;
    let fnalData = '';
    if (url === '/report/daysTask') {
      fnalData = {
        companyId: role === "super admin" ? companyFilter : companyId,
        companySize: role === "super admin" ? companySizeFilter : companySize,
        level: level !== "" ? level : null,
        levelId: levelId !== "" ? levelId : null,
      }
    } else {
      fnalData = data;
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post(url, fnalData)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { revenueData } = this.state;
          revenueData.xAxis = response.data.xaxis;
          revenueData.yAxis = response.data.yaxis;
          this.setState({
            revenueData,
            tableData: response.tableData,
            tableHeaders: response.tableHeaders,
            loading: false
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({ loading: false })
        }
      });
  };
  onDateChanage = (date, dateString) => {
    const {
      companyId,
      companySize,
      companySizeFilter,
      role,
      companyFilter,
      levelId,
      level,
      StatusSwitchToFilter,
      reportType,
    } = this.state;
    if (dateString[0] !== "" && dateString[1] !== "") {
      this.setState(
        {
          dateFilter: {
            startDate: moment.tz(moment(date[0]._d).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            endDate: moment.tz(moment(date[1]._d).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
            // startDate: date[0]._d,// momentTimeZone(dateString[0]).format("YYYY-MM-DD"),
            // endDate: date[1]._d,// momentTimeZone(dateString[1]).format("YYYY-MM-DD"),
          },
        },
        () => {
          let data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: level !== "" ? level : null,
            levelId: levelId !== "" ? levelId : null,
            startDate: this.state.dateFilter.startDate,
            endDate: this.state.dateFilter.endDate,

            // startDate: moment.tz(momentTimeZone(date[0]._d).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            // endDate: moment.tz(momentTimeZone(date[1]._d).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
            groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
          };
          let Produ_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: level !== "" ? level : null,
            levelId: levelId !== "" ? levelId : null,
            startDate: this.state.dateFilter.startDate,
            endDate: this.state.dateFilter.endDate,
            // startDate: moment.tz(momentTimeZone(date[0]._d).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            // endDate: moment.tz(momentTimeZone(date[1]._d).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
          };
          let Accountdata = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            // level: level !== "" ? level : null,
            // levelId: levelId !== "" ? levelId : null,
            startDate: this.state.dateFilter.startDate,
            endDate: this.state.dateFilter.endDate,
            // startDate: moment.tz(momentTimeZone(date[0]._d).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            // endDate: moment.tz(momentTimeZone(date[1]._d).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
            accountId: this.state.healthFilter ? this.state.healthFilter : null
          };

          let Acc_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: level !== "" ? level : null,
            levelId: levelId !== "" ? levelId : null,
            startDate: this.state.dateFilter.startDate,
            endDate: this.state.dateFilter.endDate,
            // startDate: moment.tz(momentTimeZone(date[0]._d).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            // endDate: moment.tz(momentTimeZone(date[1]._d).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
          };
          let Event_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: level !== "" ? level : null,
            levelId: levelId !== "" ? levelId : null,
            startDate: this.state.dateFilter.startDate,
            endDate: this.state.dateFilter.endDate,
            // startDate: moment.tz(momentTimeZone(date[0]._d).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            // endDate: moment.tz(momentTimeZone(date[1]._d).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
            // userId: this.state.assignedId ? this.state.assignedId : null
          };
          let Revdata = {
            companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
            companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
            level: this.state.level !== "" ? this.state.level : null,
            levelId: this.state.levelId !== "" ? this.state.levelId : null,
            startDate: this.state.dateFilter.startDate,
            endDate: this.state.dateFilter.endDate,
            // startDate: moment.tz(momentTimeZone(date[0]._d).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            // endDate: moment.tz(momentTimeZone(date[1]._d).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
            type: this.state.reportType,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };
          if (reportType === "Activity Report") {
            this.fetchRepUtilizationData(data, "/repUtilizationChart");
          } else if (reportType === "System Activity Report") {
            this.fetchRepUtilizationData(Accountdata, "/accountActivityReport");
          } else if (reportType === "Duration Report") {
            this.fetchRepUtilizationData(data, "/duration");
          } else if (reportType === "Event Report") {
            this.fetchAccSatisfactionData(Event_data, "eventDetails");
          } else if (reportType === "Product Utilization Report") {
            this.fetchAccSatisfactionData(Acc_data, "productUtilization");
          } else if (reportType === "Account Satisfaction") {
            this.fetchAccSatisfactionData(data, "task/completed");
          } else if (reportType === "Forecasted Revenue Report") {
            this.fetchAccSatisfactionData(Acc_data, "forecaste");
          }
          else if (reportType === "Uncovered Tasks") {
            this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
          } else if (reportType === "Cancellations") {
            this.fetchAccSatisfactionData(data, "task/cancelled");
          } else if (reportType === "Revenue Report") {
            this.fetchAccSatisfactionData(Revdata, "revenue");
          } else if (reportType === "Revenue Tasks Report") {
            this.fetchAccSatisfactionData(data, "compnayRevenueTask");
          }
          else if (reportType === "Expense Report") {
            this.fetchAccSatisfactionData(Revdata, "revenue");
          }
          else if (reportType === "Pipeline Report") {
            this.fetchPipelinePiChartData(data, "/report/pipeLine");
          } else if (reportType === "Products Breakdown") {
            this.fetchPipelinePiChartData(Produ_data, "/report/productDetails");
          } else if (reportType === "Most Active Accounts") {
            this.fetchactiveReportData(data, "account");
          } else if (reportType === "Most Active Reps") {
            this.fetchactiveReportData(data, "employee");
          } else if (reportType === "Revenue and Expenses") {
            this.fetchRevenueData(data, '/report/expensesRevenue');
          } else if (reportType === "Days between Tasks") {
            this.fetchRevenueData(data, '/report/daysTask');
          }
        }
      );
    } else {
      var zoneName = momentTimeZone.tz.guess();
      this.setState(
        {
          dateFilter: {
            startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
            endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
          },
        },
        () => {
          let Produ_data = "";
          let Event_data = "";
          let Accountdata = "";
          let Acc_data = "";
          let data = "";
          let Revdata = "";
          if (level !== "" && levelId !== "") {
            Acc_data = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              level: level !== "" ? level : null,
              levelId: levelId !== "" ? levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
            };
            Event_data = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              level: level !== "" ? level : null,
              levelId: levelId !== "" ? levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              // userId: this.state.assignedId ? this.state.assignedId : null
            };
            Revdata = {
              companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
              companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
              level: this.state.level !== "" ? this.state.level : null,
              levelId: this.state.levelId !== "" ? this.state.levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              type: this.state.reportType,
              accountId: this.state.acountFilter ? this.state.acountFilter : null
            };
            data = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              level: level !== "" ? level : null,
              levelId: levelId !== "" ? levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
            };
            Accountdata = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              // level: level !== "" ? level : null,
              // levelId: levelId !== "" ? levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              accountId: this.state.healthFilter ? this.state.healthFilter : null
            };
            Produ_data = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              level: level !== "" ? level : null,
              levelId: levelId !== "" ? levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),

            };
          } else {
            Acc_data = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              level: level !== "" ? level : null,
              levelId: levelId !== "" ? levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
            };
            Event_data = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              level: level !== "" ? level : null,
              levelId: levelId !== "" ? levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              // userId: this.state.assignedId ? this.state.assignedId : null
            };
            Revdata = {
              companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
              companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
              level: this.state.level !== "" ? this.state.level : null,
              levelId: this.state.levelId !== "" ? this.state.levelId : null,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              type: this.state.reportType,
              accountId: this.state.acountFilter ? this.state.acountFilter : null
            };
            data = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
            };
            Produ_data = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
            };

            Accountdata = {
              companyId: role === "super admin" ? companyFilter : companyId,
              companySize:
                role === "super admin" ? companySizeFilter : companySize,
              startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).utc(),
              accountId: this.state.healthFilter ? this.state.healthFilter : null
            };
          }
          if (reportType === "Activity Report") {
            this.fetchRepUtilizationData(data, "/repUtilizationChart");
          } else if (reportType === "System Activity Report") {
            this.fetchRepUtilizationData(Accountdata, "/accountActivityReport");
          } else if (reportType === "Duration Report") {
            this.fetchRepUtilizationData(data, "/duration");
          }
          else if (reportType === "Event Report") {
            this.fetchAccSatisfactionData(Event_data, "eventDetails");
          }
          else if (reportType === "Account Satisfaction") {
            this.fetchAccSatisfactionData(data, "task/completed");
          } else if (reportType === "Product Utilization Report") {
            this.fetchAccSatisfactionData(data, "productUtilization");
          }
          else if (reportType === "Forecasted Revenue Report") {
            this.fetchAccSatisfactionData(Acc_data, "forecaste");
          }
          else if (reportType === "Uncovered Tasks") {
            this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
          } else if (reportType === "Cancellations") {
            this.fetchAccSatisfactionData(data, "task/cancelled");
          } else if (reportType === "Expense Report") {
            this.fetchAccSatisfactionData(Revdata, "revenue");
          } else if (reportType === "Revenue Report") {
            this.fetchAccSatisfactionData(Revdata, "revenue");

          } else if (reportType === "Revenue Tasks Report") {
            this.fetchAccSatisfactionData(data, "compnayRevenueTask");
          }
          else if (reportType === "Pipeline Report") {
            this.fetchPipelinePiChartData(data, "/report/pipeLine");
          } else if (reportType === "Products Breakdown") {
            this.fetchPipelinePiChartData(Produ_data, "/report/productDetails");
          } else if (reportType === "Most Active Accounts") {
            this.fetchactiveReportData(data, "account");
          } else if (reportType === "Most Active Reps") {
            this.fetchactiveReportData(data, "employee");
          } else if (reportType === "Revenue and Expenses") {
            this.fetchRevenueData(data, '/report/expensesRevenue');
          } else if (reportType === "Days between Tasks") {
            this.fetchRevenueData(data, '/report/daysTask');
          }
        }
      );
    }
  };
  getReports = (value) => {
    const {
      companyFilter,
      role,
      companyId,
      companySize,
      companySizeFilter,
      level,
      levelId,
      dateFilter,
      StatusSwitchToFilter,
    } = this.state;
    this.setState({ reportType: value, });
    let data = {
      companyId: role === "super admin" ? companyFilter : companyId,
      companySize: role === "super admin" ? companySizeFilter : companySize,
      level: level !== "" ? level : null,
      levelId: levelId !== "" ? levelId : null,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
    };
    let Produ_data = {
      companyId: role === "super admin" ? companyFilter : companyId,
      companySize: role === "super admin" ? companySizeFilter : companySize,
      level: level !== "" ? level : null,
      levelId: levelId !== "" ? levelId : null,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };
    let Accountdata = {
      companyId: role === "super admin" ? companyFilter : companyId,
      companySize: role === "super admin" ? companySizeFilter : companySize,
      // level: level !== "" ? level : null,
      // levelId: levelId !== "" ? levelId : null,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      accountId: this.state.healthFilter ? this.state.healthFilter : null
    };
    let Acc_data = {
      companyId: role === "super admin" ? companyFilter : companyId,
      companySize: role === "super admin" ? companySizeFilter : companySize,
      level: level !== "" ? level : null,
      levelId: levelId !== "" ? levelId : null,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };
    let Event_data = {
      companyId: role === "super admin" ? companyFilter : companyId,
      companySize: role === "super admin" ? companySizeFilter : companySize,
      level: level !== "" ? level : null,
      levelId: levelId !== "" ? levelId : null,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      // userId: this.state.assignedId ? this.state.assignedId : null
    };
    let Revdata = {
      companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
      companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
      level: this.state.level !== "" ? this.state.level : null,
      levelId: this.state.levelId !== "" ? this.state.levelId : null,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      type: value,
      accountId: this.state.acountFilter ? this.state.acountFilter : null
    };
    if (value === "Activity Report") {
      this.fetchRepUtilizationData(data, "/repUtilizationChart");
    } else if (value === "System Activity Report") {
      this.fetchRepUtilizationData(Accountdata, "/accountActivityReport");
    } else if (value === "Duration Report") {
      this.fetchRepUtilizationData(data, "/duration");
    }
    else if (value === "Event Report") {
      this.fetchAccSatisfactionData(Event_data, "eventDetails");
    }
    else if (value === "Account Satisfaction") {
      this.fetchAccSatisfactionData(data, "task/completed");
    } else if (value === "Product Utilization Report") {
      this.fetchAccSatisfactionData(Acc_data, "productUtilization");
    }
    else if (value === "Forecasted Revenue Report") {
      this.fetchAccSatisfactionData(Acc_data, "forecaste");
    }
    else if (value === "Uncovered Tasks") {
      this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
    } else if (value === "Revenue Report") {
      this.fetchAccSatisfactionData(Revdata, "revenue");

    } else if (value === "Revenue Tasks Report") {
      this.fetchAccSatisfactionData(data, "compnayRevenueTask");
    }
    else if (value === "Expense Report") {
      this.fetchAccSatisfactionData(Revdata, "revenue");
    }

    else if (value === "Cancellations") {
      this.fetchAccSatisfactionData(data, "task/cancelled");
    } else if (value === "Pipeline Report") {
      this.fetchPipelinePiChartData(Produ_data, "/report/pipeLine");
    } else if (value === "Products Breakdown") {
      this.fetchPipelinePiChartData(Produ_data, "/report/productDetails");
    } else if (value === "Most Active Accounts") {
      this.fetchactiveReportData(data, "account");
    } else if (value === "Most Active Reps") {
      this.fetchactiveReportData(data, "employee");
    } else if (value === "Revenue and Expenses") {
      this.fetchRevenueData(data, '/report/expensesRevenue');
    } else if (value === "Days between Tasks") {
      this.fetchRevenueData(data, '/report/daysTask');
    }
  };

  /**
   * @author Murali
   * To open territory filter menu
   */
  openCompanyFilterMenu = (event) => {
    const { companyConfig } = this.state;
    companyConfig.anchorElCompany = event.currentTarget;
    this.setState(
      {
        companyConfig,
      },
      () => { }
    );
  };
  /**
   * @author Murali
   * To close territory filter menu
   */
  closeCompanyFilterMenu = () => {
    const { companyConfig } = this.state;
    companyConfig.anchorElCompany = null;
    this.setState({
      companyConfig,
    });
  };
  /**
   * @author Murali
   * To display territory based on search
   */
  onCompanySearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchCompanyValue: value,
    });
    this.companyListHandler(value);
  };
  /**
   * @author Murali
   * To display data based on selected territory
   */
  handleCompanySelect = (item) => {
    const { companyConfig, dateFilter, reportType, StatusSwitchToFilter, } = this.state;
    companyConfig.anchorElCompany = null;
    this.setState(
      {
        companyFilter: item._id,
        companyName: item.name === "All" ? "" : item.name,
        companySize: item.size,
        companySizeFilter: item.size,
        companyConfig,
        searchCompanyValue: "",
        salesrepName: "",
        compFilter: true,
        loading: true,
        level: "",
        levelId: "",
        salesrepOption: [
          {
            _id: "",
            name: "All",
          },
        ],
      },
      () => {
        let data = {
          companyId: item._id,
          companySize: item.size,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
        };
        let Produ_data = {
          companyId: item._id,
          companySize: item.size,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,

        };
        let Acc_data = {
          companyId: item._id,
          companySize: item.size,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,

        };
        let Event_data = {
          companyId: item._id,
          companySize: item.size,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          // userId: this.state.assignedId ? this.state.assignedId : null
        };
        let Revdata = {
          companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
          companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
          level: this.state.level !== "" ? this.state.level : null,
          levelId: this.state.levelId !== "" ? this.state.levelId : null,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          type: this.state.reportType,
          accountId: this.state.acountFilter ? this.state.acountFilter : null
        };
        if (reportType === "Activity Report") {
          this.fetchRepUtilizationData(data, "/repUtilizationChart");
        } else if (reportType === "Duration Report") {
          this.fetchRepUtilizationData(data, "/duration");
        }
        else if (reportType === "Event Report") {
          this.fetchAccSatisfactionData(Event_data, "eventDetails");
        }
        else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "task/completed");
        } else if (reportType === "Product Utilization Report") {
          this.fetchAccSatisfactionData(Acc_data, "productUtilization");
        }

        else if (reportType === "Forecasted Revenue Report") {
          this.fetchAccSatisfactionData(Acc_data, "forecaste");
        }
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
        } else if (reportType === "Cancellations") {
          this.fetchAccSatisfactionData(data, "task/cancelled");

        } else if (reportType === "Revenue Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");
        } else if (reportType === "Company Revenue Tasks Report") {
          this.fetchAccSatisfactionData(data, "compnayRevenueTask");
        }
        else if (reportType === "Expense Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");
        }
        else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data, "/report/pipeLine");
        } else if (reportType === "Products Breakdown") {
          this.fetchPipelinePiChartData(Produ_data, "/report/productDetails");
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        } else if (reportType === "Revenue and Expenses") {
          this.fetchRevenueData(data, '/report/expensesRevenue');
        } else if (reportType === "Days between Tasks") {
          this.fetchRevenueData(data, '/report/daysTask');
        }
        this.compfilter(this.state.companyFilter, this.state.companySize);
      }
    );
  };
  /**
   * @author Murali
   * To get territory names in filter
   */
  companyListHandler = (search) => {
    const { companyOption, dateFilter, reportType, role,
      companyId, } = this.state;

    let data = {
      companyName: search,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/companylist", data)
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.finalCompany;
          this.setState(
            {
              companyFilter: data[0].id,
              companySizeFilter: data[0].size,
              companyName: data[0].name,
            },
            () => {
              if (search === '') {
                let chartData = {
                  // startDate: moment.tz(momentTimeZone(dateFilter.startDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
                  // endDate: moment.tz(momentTimeZone(dateFilter.endDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
                  startDate: this.state.dateFilter.startDate,
                  endDate: this.state.dateFilter.endDate,

                  companyId: data[0].id,
                  companySize: data[0].size,
                };
                let Event_data = {
                  // startDate: moment.tz(momentTimeZone(dateFilter.startDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
                  // endDate: moment.tz(momentTimeZone(dateFilter.endDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
                  startDate: this.state.dateFilter.startDate,
                  endDate: this.state.dateFilter.endDate,

                  companyId: data[0].id,
                  companySize: data[0].size,
                  // userId: this.state.assignedId ? this.state.assignedId : null
                };
                let Acc_data = {
                  // startDate: moment.tz(momentTimeZone(dateFilter.startDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
                  // endDate: moment.tz(momentTimeZone(dateFilter.endDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
                  startDate: this.state.dateFilter.startDate,
                  endDate: this.state.dateFilter.endDate,

                  companyId: data[0].id,
                  companySize: data[0].size,
                };
                let Revdata = {
                  companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
                  companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
                  level: this.state.level !== "" ? this.state.level : null,
                  levelId: this.state.levelId !== "" ? this.state.levelId : null,
                  startDate: this.state.dateFilter.startDate,
                  endDate: this.state.dateFilter.endDate,

                  //moment.tz(momentTimeZone(dateFilter.startDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
                  //moment.tz(momentTimeZone(dateFilter.endDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
                  type: this.state.reportType,
                  accountId: this.state.acountFilter ? this.state.acountFilter : null
                };

                if (reportType === "Activity Report") {
                  this.fetchRepUtilizationData(chartData, "/repUtilizationChart");
                } else if (reportType === "Duration Report") {
                  this.fetchRepUtilizationData(data, "/duration");
                }
                else if (reportType === "Event Report") {
                  this.fetchAccSatisfactionData(Event_data, "eventDetails");
                }

                else if (reportType === "Account Satisfaction") {
                  this.fetchAccSatisfactionData(chartData, "task/completed");
                } else if (reportType === "Product Utilization Report") {
                  this.fetchAccSatisfactionData(Acc_data, "productUtilization");
                }
                else if (reportType === "Forecasted Revenue Report") {
                  this.fetchAccSatisfactionData(Acc_data, "forecaste");
                }
                else if (reportType === "Uncovered Tasks") {
                  this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
                } else if (reportType === "Cancellations") {
                  this.fetchAccSatisfactionData(chartData, "task/cancelled");
                } else if (reportType === "Revenue Report") {
                  this.fetchAccSatisfactionData(Revdata, "revenue");

                } else if (reportType === "Revenue Tasks Report") {
                  this.fetchAccSatisfactionData(data, "compnayRevenueTask");
                }
                else if (reportType === "Expense Report") {
                  this.fetchAccSatisfactionData(Revdata, "revenue");
                } else if (reportType === "Pipeline Report") {
                  this.fetchPipelinePiChartData(data, "/report/pipeLine");
                } else if (reportType === "Products Breakdown") {
                  this.fetchPipelinePiChartData(data, "/report/productDetails");
                } else if (reportType === "Most Active Accounts") {
                  this.fetchactiveReportData(data, "account");
                } else if (reportType === "Most Active Reps") {
                  this.fetchactiveReportData(data, "employee");
                } else if (reportType === "Revenue and Expenses") {
                  this.fetchRevenueData(data, '/report/expensesRevenue');
                } else if (reportType === "Days between Tasks") {
                  this.fetchRevenueData(data, '/report/daysTask');
                }
              }
              this.compfilter(data[0].id, data[0].size);
            }
          );
          var newArray = companyOption.splice();
          // if (this.state.searchCompanyValue === "") {
          //   newArray.unshift({ _id: "", name: "All" });
          // } else {
          //   newArray.shift({ _id: "", name: "All" });
          // }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item.id;
              obj.name = item.name;
              obj.size = item.size;
              newArray.push(obj);
            });
          }
          this.setState({
            companyOption: newArray,
          });
        } else {
          this.setState({
            companyOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  openDivisionFilterMenu = (event) => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = event.currentTarget;
    this.setState({
      divisionConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleDivisionSelect = (item) => {
    this.setState({ loading: true });
    var {
      divisionFilter,
      divisionName,
      divisionConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      reportType,
      dateFilter,
      StatusSwitchToFilter,
    } = this.state;
    divisionFilter = item._id;
    divisionName = item.name;
    divisionConfig.anchorElDivision = null;
    this.setState(
      {
        divisionConfig,
        divisionName,
        divisionFilter,
        areaFilter: "",
        regionFilter: "",
        territoryFilter: "",
        level: "division",
        levelId: divisionFilter,
      },
      () => {
        let Produ_data = "";
        let Event_data = "";
        let Acc_data = "";
        let Revdata = "";
        let data = "";
        if (divisionFilter !== "") {
          Revdata = {
            companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
            companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
            level: this.state.level !== "" ? this.state.level : null,
            levelId: this.state.levelId !== "" ? this.state.levelId : null,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            type: this.state.reportType,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: "division",
            levelId: divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
          };
          Produ_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: "division",
            levelId: divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,

          };
          Acc_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: "division",
            levelId: divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
          Event_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: "division",
            levelId: divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };
        } else {
          Revdata = {
            companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
            companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
            level: this.state.level !== "" ? this.state.level : null,
            levelId: this.state.levelId !== "" ? this.state.levelId : null,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            type: this.state.reportType,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
          };
          Produ_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
          Acc_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            // userId: this.state.assignedId ? this.state.assignedId : null
          };

        }
        if (reportType === "Activity Report") {
          this.fetchRepUtilizationData(data, "/repUtilizationChart");
        } else if (reportType === "Duration Report") {
          this.fetchRepUtilizationData(data, "/duration");
        }
        else if (reportType === "Event Report") {
          this.fetchAccSatisfactionData(Event_data, "eventDetails");
        }

        else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "task/completed");
        } else if (reportType === "Product Utilization Report") {
          this.fetchAccSatisfactionData(Acc_data, "productUtilization");
        }
        else if (reportType === "Forecasted Revenue Report") {
          this.fetchAccSatisfactionData(Acc_data, "forecaste");
        }
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
        } else if (reportType === "Cancellations") {
          this.fetchAccSatisfactionData(data, "task/cancelled");
        } else if (reportType === "Revenue Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");

        } else if (reportType === "Revenue Tasks Report") {
          this.fetchAccSatisfactionData(data, "compnayRevenueTask");
        }
        else if (reportType === "Expense Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");
        }
        else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data, "/report/pipeLine");
        } else if (reportType === "Products Breakdown") {
          this.fetchPipelinePiChartData(Produ_data, "/report/productDetails");
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        } else if (reportType === "Revenue and Expenses") {
          this.fetchRevenueData(data, '/report/expensesRevenue');
        } else if (reportType === "Days between Tasks") {
          this.fetchRevenueData(data, '/report/daysTask');
        }
        this.getFilters("divisionFilter");
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeDivisionFilterMenu = () => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = null;
    this.setState({
      divisionConfig,
    });
  };
  openAreaFilterMenu = (event) => {
    const { areaConfig } = this.state;
    areaConfig.anchorElArea = event.currentTarget;
    this.setState({
      areaConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleAreaSelect = (item) => {
    this.setState({ loading: true });
    var {
      areaFilter,
      areaName,
      areaConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      divisionFilter,
      reportType,
      StatusSwitchToFilter,
    } = this.state;
    areaFilter = item._id;
    areaName = item.name;
    areaConfig.anchorElArea = null;
    this.setState(
      {
        areaConfig,
        areaName,
        areaFilter,
        regionFilter: "",
        territoryFilter: "",
        level: "area",
        levelId: areaFilter,
      },
      () => {
        let Produ_data = "";
        let Event_data = "";
        let Acc_data = "";
        let size = role === "super admin" ? companySizeFilter : companySize;
        let data = "";
        let Revdata = "";
        if (size === "medium" && areaFilter === "") {
          Revdata = {
            companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
            companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
            level: this.state.level !== "" ? this.state.level : null,
            levelId: this.state.levelId !== "" ? this.state.levelId : null,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            type: this.state.reportType,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
          };
          Produ_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
          Acc_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        } else {
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: areaFilter !== "" ? "area" : "division",
            levelId: areaFilter !== "" ? areaFilter : divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
          };
          Produ_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: areaFilter !== "" ? "area" : "division",
            levelId: areaFilter !== "" ? areaFilter : divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
          Acc_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: areaFilter !== "" ? "area" : "division",
            levelId: areaFilter !== "" ? areaFilter : divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
          Event_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: areaFilter !== "" ? "area" : "division",
            levelId: areaFilter !== "" ? areaFilter : divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            // userId: this.state.assignedId ? this.state.assignedId : null
          };
          Revdata = {
            companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
            companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
            level: this.state.level !== "" ? this.state.level : null,
            levelId: this.state.levelId !== "" ? this.state.levelId : null,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            type: this.state.reportType,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };
        }


        if (reportType === "Activity Report") {
          this.fetchRepUtilizationData(data, "/repUtilizationChart");
        } else if (reportType === "Duration Report") {
          this.fetchRepUtilizationData(data, "/duration");
        }
        else if (reportType === "Event Report") {
          this.fetchAccSatisfactionData(Event_data, "eventDetails");
        }

        else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "task/completed");
        } else if (reportType === "Product Utilization Report") {
          this.fetchAccSatisfactionData(Acc_data, "productUtilization");
        }
        else if (reportType === "Forecasted Revenue Report") {
          this.fetchAccSatisfactionData(Acc_data, "forecaste");
        }
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
        } else if (reportType === "Cancellations") {
          this.fetchAccSatisfactionData(data, "task/cancelled");
        } else if (reportType === "Revenue Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");

        } else if (reportType === "Expense Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");
        } else if (reportType === "Revenue Tasks Report") {
          this.fetchAccSatisfactionData(data, "compnayRevenueTask");
        }

        else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data, "/report/pipeLine");
        } else if (reportType === "Products Breakdown") {
          this.fetchPipelinePiChartData(Produ_data, "/report/productDetails");
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        } else if (reportType === "Revenue and Expenses") {
          this.fetchRevenueData(data, '/report/expensesRevenue');
        } else if (reportType === "Days between Tasks") {
          this.fetchRevenueData(data, '/report/daysTask');
        }
        this.getFilters("areaFilter");
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeAreaFilterMenu = () => {
    const { areaConfig } = this.state;
    areaConfig.anchorElArea = null;
    this.setState({
      areaConfig,
    });
  };
  openRegionFilterMenu = (event) => {
    const { regionConfig } = this.state;
    regionConfig.anchorElRegion = event.currentTarget;
    this.setState({
      regionConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleRegionSelect = (item) => {
    this.setState({ loading: true });
    var {
      regionFilter,
      regionName,
      regionConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      areaFilter,
      reportType,
      StatusSwitchToFilter,
    } = this.state;
    regionFilter = item._id;
    regionName = item.name;
    regionConfig.anchorElRegion = null;
    this.setState(
      {
        regionConfig,
        regionName,
        regionFilter,
        territoryFilter: "",
        level: "region",
        levelId: regionFilter,
      },
      () => {
        let Produ_data = "";
        let Event_data = "";
        let Acc_data = "";
        let size = role === "super admin" ? companySizeFilter : companySize;
        let Revdata = "";
        let data = "";
        if (size === "small" && regionFilter === "") {
          Revdata = {
            companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
            companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
            level: this.state.level !== "" ? this.state.level : null,
            levelId: this.state.levelId !== "" ? this.state.levelId : null,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            type: this.state.reportType,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };

          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
          };
          Produ_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
          Acc_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };
        } else {
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: regionFilter !== "" ? "region" : "area",
            levelId: regionFilter !== "" ? (regionFilter.trim() === "" ? null : regionFilter) : areaFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
          Produ_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: regionFilter !== "" ? "region" : "area",
            levelId: regionFilter !== "" ? regionFilter : areaFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
          Event_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: regionFilter !== "" ? "region" : "area",
            levelId: regionFilter !== "" ? regionFilter : areaFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            // userId: this.state.assignedId ? this.state.assignedId : null
          };
          Acc_data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: regionFilter !== "" ? "region" : "area",
            levelId: regionFilter !== "" ? (regionFilter.trim() === "" ? null : regionFilter) : areaFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };
          Revdata = {
            companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
            companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
            level: this.state.level !== "" ? this.state.level : null,
            levelId: this.state.levelId !== "" ? this.state.levelId : null,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
            type: this.state.reportType,
            accountId: this.state.acountFilter ? this.state.acountFilter : null
          };

        }

        if (reportType === "Activity Report") {
          this.fetchRepUtilizationData(data, "/repUtilizationChart");
        } else if (reportType === "Duration Report") {
          this.fetchRepUtilizationData(data, "/duration");
        }
        else if (reportType === "Event Report") {
          this.fetchAccSatisfactionData(Event_data, "eventDetails");
        }
        else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "task/completed");
        } else if (reportType === "Product Utilization Report") {
          this.fetchAccSatisfactionData(Acc_data, "productUtilization");
        }
        else if (reportType === "Forecasted Revenue Report") {
          this.fetchAccSatisfactionData(Acc_data, "forecaste");
        }
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
        } else if (reportType === "Cancellations") {
          this.fetchAccSatisfactionData(data, "task/cancelled");
        } else if (reportType === "Revenue Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");

        } else if (reportType === "Revenue Tasks Report") {
          this.fetchAccSatisfactionData(data, "compnayRevenueTask");
        }
        else if (reportType === "Expense Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");
        }
        else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data, "/report/pipeLine");
        } else if (reportType === "Products Breakdown") {
          this.fetchPipelinePiChartData(Produ_data, "/report/productDetails");
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        } else if (reportType === "Revenue and Expenses") {
          this.fetchRevenueData(data, '/report/expensesRevenue');
        } else if (reportType === "Days between Tasks") {
          this.fetchRevenueData(data, '/report/daysTask');
        }
        this.getFilters("regionFilter");
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeRegionFilterMenu = () => {
    const { regionConfig } = this.state;
    regionConfig.anchorElRegion = null;
    this.setState({
      regionConfig,
    });
  };
  openTerritoryFilterMenu = (event) => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = event.currentTarget;
    this.setState({
      territoryConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleTerritorySelect = (item) => {
    this.setState({ loading: true });
    var {
      territoryFilter,
      territoryName,
      territoryConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      reportType,
      regionFilter,
      StatusSwitchToFilter,
    } = this.state;
    territoryFilter = item._id;
    territoryName = item.name;
    territoryConfig.anchorElTerritory = null;
    this.setState(
      {
        territoryConfig,
        territoryName,
        territoryFilter,
        level: "territory",
        levelId: territoryFilter,
      },
      () => {
        let data = {
          companyId: role === "super admin" ? companyFilter : companyId,
          companySize: role === "super admin" ? companySizeFilter : companySize,
          level: territoryFilter !== "" ? "territory" : "region",
          levelId: territoryFilter !== "" ? territoryFilter : null,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
        };
        let Produ_data = {
          companyId: role === "super admin" ? companyFilter : companyId,
          companySize: role === "super admin" ? companySizeFilter : companySize,
          level: territoryFilter !== "" ? "territory" : "region",
          levelId: territoryFilter !== "" ? territoryFilter : null,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        };
        let Event_data = {
          companyId: role === "super admin" ? companyFilter : companyId,
          companySize: role === "super admin" ? companySizeFilter : companySize,
          level: territoryFilter !== "" ? "territory" : "region",
          levelId: territoryFilter !== "" ? territoryFilter : regionFilter,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          // userId: this.state.assignedId ? this.state.assignedId : null
        };
        let Acc_data = {
          companyId: role === "super admin" ? companyFilter : companyId,
          companySize: role === "super admin" ? companySizeFilter : companySize,
          level: territoryFilter !== "" ? "territory" : "region",
          levelId: territoryFilter !== "" ? territoryFilter : (regionFilter.trim() === "" ? null : regionFilter),
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          accountId: this.state.acountFilter ? this.state.acountFilter : null
        };
        let Revdata = {
          companyId: this.state.role === "super admin" ? this.state.companyFilter : this.state.companyId,
          companySize: this.state.role === "super admin" ? this.state.companySizeFilter : this.state.companySize,
          level: this.state.level !== "" ? this.state.level : null,
          levelId: this.state.levelId !== "" ? this.state.levelId : null,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          type: this.state.reportType,
          accountId: this.state.acountFilter ? this.state.acountFilter : null
        };
        if (reportType === "Activity Report") {
          this.fetchRepUtilizationData(data, "/repUtilizationChart");
        } else if (reportType === "Duration Report") {
          this.fetchRepUtilizationData(data, "/duration");
        }
        else if (reportType === "Event Report") {
          this.fetchAccSatisfactionData(Event_data, "eventDetails");
        }
        else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "task/completed");
        } else if (reportType === "Product Utilization Report") {
          this.fetchAccSatisfactionData(Acc_data, "productUtilization");
        }
        else if (reportType === "Forecasted Revenue Report") {
          this.fetchAccSatisfactionData(Acc_data, "forecaste");
        }
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(Acc_data, "task/uncovered");
        } else if (reportType === "Cancellations") {
          this.fetchAccSatisfactionData(data, "task/cancelled");
        } else if (reportType === "Revenue Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");

        } else if (reportType === "Revenue Tasks Report") {
          this.fetchAccSatisfactionData(data, "compnayRevenueTask");
        }
        else if (reportType === "Expense Report") {
          this.fetchAccSatisfactionData(Revdata, "revenue");
        }
        else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data, "/report/pipeLine");
        } else if (reportType === "Products Breakdown") {
          this.fetchPipelinePiChartData(Produ_data, "/report/productDetails");
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        } else if (reportType === "Revenue and Expenses") {
          this.fetchRevenueData(data, '/report/expensesRevenue');
        } else if (reportType === "Days between Tasks") {
          this.fetchRevenueData(data, '/report/daysTask');
        }
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeTerritoryFilterMenu = () => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = null;
    this.setState({
      territoryConfig,
    });
  };

  getFilters = (name) => {
    let filterdata = "";
    this.setState({ loading: true }, () => {
      const {
        companyFilter,
        divisionFilter,
        areaFilter,
        regionFilter,

      } = this.state;
      switch (name) {
        case "companyFilter":
          filterdata = {
            companyData: this.state.filters.company,
            companyId: companyFilter,
          };
          break;
        case "divisionFilter":
          this.setState(
            {
              areaFilter: "",
              regionFilter: "",
              territoryFilter: "",
            },
            () => { }
          );
          filterdata = {
            companyData: this.state.filters.company,
            divisionData: this.state.filters.division,
            divisionId: divisionFilter,
          };
          break;
        case "areaFilter":
          this.setState(
            {
              regionFilter: "",
              territoryFilter: "",
            },
            () => { }
          );
          filterdata = {
            companyData: this.state.filters.company,
            divisionData: this.state.filters.division,
            areaData: this.state.filters.area,
            areaId: areaFilter,
          };

          break;
        case "regionFilter":
          this.setState(
            {
              territoryFilter: "",
            },
            () => { }
          );
          filterdata = {
            companyData: this.state.filters.company,
            divisionData: this.state.filters.division,
            areaData: this.state.filters.area,
            regionData: this.state.filters.region,
            regionId: regionFilter,
          };
          break;
        default:
          break;
      }
      if (
        name !== "roleFilter" &&
        name !== "territoryFilter" &&
        name !== "searchFilter"
      ) {
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/users/filters", filterdata)
          .then((resp) => {
            if (resp.status === 200) {
              this.setState({ filters: resp.data.data });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      }
    });
  };
  compfilter = (id, size) => {
    this.setState(
      {
        divisionFilter: "",
        areaFilter: "",
        regionFilter: "",
        territoryFilter: "",
      },
      () => {
        let filterdata = "";
        filterdata = { companyId: id, companySize: size };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/users/filters", filterdata)
          .then((resp) => {
            if (resp.status === 200) {
              this.setState({ filters: resp.data.data });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      }
    );
  };
  getSalesrep(id, event) {
    const {
      companyFilter,
      companyId,
      role,
      companySizeFilter,
      companySize,
      level,
      levelId,
      dateFilter,
    } = this.state;
    let data = {
      companyId: role === "super admin" ? companyFilter : companyId,
      companySize: role === "super admin" ? companySizeFilter : companySize,
      level: level,
      levelId: levelId,
      startDate: this.state.dateFilter.startDate,
      endDate: this.state.dateFilter.endDate,

      // startDate: moment.tz(momentTimeZone(dateFilter.startDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
      // endDate: moment.tz(momentTimeZone(dateFilter.endDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
      accountId: id,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/report/repUtilizationEmployees", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({ accountRep: response.data });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  }
  disabledDate(current) {
    // Can not select days after today and today
    return current > momentTimeZone().endOf('day');
  }
  /**
   * @author Murali
   * To open territory filter menu
   */
  openEmployeeFilterMenu = (event) => {
    const { employeeConfig } = this.state;
    employeeConfig.anchorElEmployee = event.currentTarget;
    this.setState(
      {
        employeeConfig,
      },
      () => {
        this.employeeListHandler('');
      }
    );
  };
  /**
   * @author Murali
   * To close territory filter menu
   */
  closeEmployeeFilterMenu = () => {
    const { employeeConfig } = this.state;
    employeeConfig.anchorElEmployee = null;
    this.setState({
      employeeConfig,
    });
  };
  /**
   * @author Murali
   * To display territory based on search
   */
  onEmployeeSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchEmployeeValue: value,
    });
    this.employeeListHandler(value);
  };
  /**
   * @author Murali
   * To display data based on selected territory
   */
  handleEmployeeSelect = (item) => {
    const { employeeConfig } = this.state;
    employeeConfig.anchorElEmployee = null;
    this.setState(
      {
        employeeFilter: item._id,
        employeeName: item.name === "All" ? "" : item.name,
        employeeConfig,
        searchEmployeeValue: "",
      },
    );
  };
  /**
   * @author Murali
   * To get territory names in filter
   */
  employeeListHandler = (search) => {
    const { employeeOption } = this.state;

    let data = {
      companyId: this.state.companyFilter,
      companySize: this.state.companySizeFilter,
      levelId: this.state.levelId !== "" ? this.state.levelId : null,
      level: this.state.level !== "" ? this.state.level : null,
      search: search,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/report/employee_filter", data)
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          this.setState(
            {
              employeeFilter: data[0]._id,
              employeeName: data[0].name,
            },
            () => {

            }
          );
          var newArray = employeeOption.splice();
          if (this.state.searchEmployeeValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            employeeOption: newArray,
          });
        } else {
          this.setState({
            employeeOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Murali
   * To close territory filter menu
   */
  closeAccountFilterMenu = () => {
    const { healthConfig } = this.state;
    healthConfig.anchorElAccount = null;
    this.setState({
      healthConfig,
    });
  };
  /**
   * @author Murali
   * To display territory based on search
   */
  onAccountSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchAccountValue: value,
    });
    this.accountListHandler(value);
  };
  /**
   * @author Murali
   * To display data based on selected territory
   */
  handleAccountSelect = (item) => {
    const { healthConfig } = this.state;
    healthConfig.anchorElAccount = null;
    this.setState(
      {
        accountFilter: item._id,
        accountName: item.name === "All" ? "" : item.name,
        healthConfig,
        searchAccountValue: "",
      },
      () => {

      }
    );
  };
  /**
   * @author Murali
   * To get territory names in filter
   */
  accountListHandler = (search) => {
    const { accountOption } = this.state;

    let data = {
      search: search,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/report/account_filter", data)
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          this.setState(
            {
              healthFilter: data[0]._id,
              healthName: data[0].name,
            },
            () => {

            }
          );
          var newArray = accountOption.splice();
          // if (this.state.searchAccountValue === "") {
          //   newArray.unshift({ _id: "", name: "All" });
          // } else {
          //   newArray.shift({ _id: "", name: "All" });
          // }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            accountOption: newArray,
          });
        } else {
          this.setState({
            accountOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  getTeamReport = () => {
    const { role, companyId, companyFilter, companySizeFilter, companySize, level, levelId, dateFilter, employeeFilter, accountFilter } = this.state;
    let data = {
      companyId: role === "super admin" ? companyFilter : companyId,
      companySize: role === "super admin" ? companySizeFilter : companySize,
      level: level ? level : null,
      levelId: levelId ? levelId : null,
      startDate: this.state.dateFilter.startDate,
      endDate: this.state.dateFilter.endDate,

      // startDate: moment.tz(momentTimeZone(dateFilter.startDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
      // endDate: moment.tz(momentTimeZone(dateFilter.endDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
      employeeId: employeeFilter ? employeeFilter : null,
      accountId: accountFilter ? accountFilter : null,
    };
    data.timeZone = this.state.timeZoneName;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post("/report/teamReport", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          exportToCSV(response.data, 'Team Summary Report ' + Date.now())
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
  };
  openHealthFilterMenu = (event) => {
    const { healthConfig } = this.state;
    healthConfig.anchorElAccount = event.currentTarget;
    this.setState(
      {
        healthConfig,
      },
      () => {
        this.accountFilter("");
      }
    );
  };

  accountFilter = (search) => {
    const { healthOption } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/report/getParentAccount", {
        params:{
          search: search
        }
      })
      .then((resp) => {
        var response = resp && resp.data;

        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = healthOption.splice();
          // if (this.state.searchAccountValue === "") {
          //   newArray.unshift({ _id: "", name: "All" });
          // } else {
          //   newArray.shift({ _id: "", name: "All" });
          // }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;

              newArray.push(obj);
            });
          }
          this.setState({
            healthFilter: data[0]._id,
            healthName: data[0].name,
            healthOption: newArray,
          });
        } else {
          this.setState({
            healthOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
  };
  /**
  *
  * @param {item object} item
  * TO ADD ACCOUNT FILTER VALUE ON SELECT
  */

  handleHealthSelect = (item) => {
    const { healthConfig, dateFilter, reportType, role,
      companyId, companyFilter, companySizeFilter, companySize } = this.state;
    healthConfig.anchorElAccount = null;
    this.setState(
      {
        healthFilter: item._id,
        healthName: item.name === "" ? "" : item.name,
        healthConfig,
        searchAccountValue: "",
        nextpage: 1,
      },
      () => {
        let data = {
          companyId: role === "super admin" ? companyFilter : companyId,
          companySize:
            role === "super admin" ? companySizeFilter : companySize,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          accountId: this.state.healthFilter ? this.state.healthFilter : null
        };
        if (reportType === "System Activity Report") {
          this.fetchRepUtilizationData(data, "/accountActivityReport");
        }
      }
    );
  };

  /**
 *
 * TO HANDLE ACCOUNT MENU CLOSE
 */
  closeHealthFilterMenu = () => {
    const { healthConfig } = this.state;
    healthConfig.anchorElAccount = null;
    this.setState({
      healthConfig,
    });
  };
  /**
  *
  * @param {item object} item
  * TO HANDLE ACCOUNT FILTER VALUE ON SEARCH
  */
  onHealthSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchAccountValue: value,
    });
    this.accountFilter(value);
  };

  /**
  *
  * @param {item object} item
  * TO ADD STATUS FILTER VALUE ON SELECT
  */
  handleRoleSelect = (item) => {
    var {
      territoryFilter,
      territoryName,
      territoryConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      reportType,
      regionFilter,
    } = this.state;
    let Acc_data = {
      companyId: role === "super admin" ? companyFilter : companyId,
      companySize: role === "super admin" ? companySizeFilter : companySize,
      level: this.state.level !== "" ? this.state.level : null,
      levelId: this.state.levelId !== "" ? this.state.levelId : null,
      startDate: this.state.dateFilter.startDate,
      endDate: this.state.dateFilter.endDate,

      // startDate: moment.tz(momentTimeZone(dateFilter.startDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
      // endDate: moment.tz(momentTimeZone(dateFilter.endDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
      accountId: this.state.acountFilter ? this.state.acountFilter : null,
      // role:this.state.roleFilter ? this.state.roleFilter : null,
    };
    this.setState({ loading: true });
    var {
      roleFilter,
      roleFiltername,
      roleConfig,
    } = this.state;
    roleFilter = item._id;
    roleFiltername = item.name;
    roleConfig.anchorElRole = null;
    this.setState(
      {
        roleConfig,
        roleFiltername,
        roleFilter,
        pageNumber: 1,
      },
      () => {
        this.fetchAccSatisfactionData(Acc_data, "eventDetails");
      },

    );
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000)
  };
  /**
  *
  * TO HANDLE STATUS MENU CLOSE
  */
  closeRoleFilterMenu = () => {
    const { roleConfig } = this.state;
    roleConfig.anchorElRole = null;
    this.setState({
      roleConfig,
    });
  };



  /**
    *
    * TO HANDLE STATUS MENU OPEN
    */
  openSwitchToFilterMenu = (event) => {
    const { StatusConfig } = this.state;
    StatusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      StatusConfig,
    });
  };
  /**
      *
      * @param {item object} item
      * TO ADD STATUS FILTER VALUE ON SELECT
      */
  handleSwitchStatusSelect = (item) => {
    var {
      regionFilter,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      StatusSwitchToFilter,
    } = this.state;


    var { StatusSwitchToFilter, StatusSwitchToName, StatusConfig, reportType, dateFilter } = this.state;
    StatusSwitchToFilter = item._id;
    StatusSwitchToName = item.name;
    StatusConfig.anchorElStatus = null;
    this.setState(
      {
        StatusConfig,
        StatusSwitchToName,
        StatusSwitchToFilter,
      },
      () => {
        let data = {
          companyId: role === "super admin" ? companyFilter : companyId,
          companySize:
            role === "super admin" ? companySizeFilter : companySize,
          level: this.state.level !== "" ? this.state.level : null,
          levelId: this.state.levelId !== "" ? this.state.levelId : null,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          groupBy: StatusSwitchToFilter ? StatusSwitchToFilter : null
        };
        if (this.state.reportType === "Activity Report") {
          this.fetchRepUtilizationData(data, "/repUtilizationChart");
        } else if (this.state.reportType === "Duration Report") {
          this.fetchRepUtilizationData(data, "/duration");
        }
      }
    );
  };

  /**
*
* TO HANDLE STATUS MENU CLOSE
*/
  closeStatusSwitchFilterMenu = () => {
    const { StatusConfig } = this.state;
    StatusConfig.anchorElStatus = null;
    this.setState({
      StatusConfig,
    });
  };






  /**
  *
  * TO HANDLE User MENU OPEN
  */
  openUserFilterMenu = (event) => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = event.currentTarget;
    this.setState(
      {
        assigneeConfig,
      },
      () => {
        this.employeeFilter("");
      }
    );
  };

  /**
     *
     * @param {item object} item
     * TO ADD User FILTER VALUE ON SELECT
     */
  handleUserSelect = (item) => {

    const { assigneeConfig, territoryFilter,
      level,
      levelId,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      reportType,
      regionFilter,
      StatusSwitchToFilter, } = this.state;
    assigneeConfig.anchorElAssignee = null;

    this.setState(
      {
        assignedId: item._id,
        assignedName: item.name === "All" ? "" : item.name,
        assigneeConfig,
        searchAssigneeValue: "",
        nextpage: 1,
      },
      () => {
        let Event_data = {
          companyId: role === "super admin" ? companyFilter : companyId,
          companySize: role === "super admin" ? companySizeFilter : companySize,
          level: level !== "" ? level : null,
          levelId: levelId !== "" ? levelId : null,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          // userId: this.state.assignedId ? this.state.assignedId : null
        };
        if (reportType === "Event Report") {
          this.fetchAccSatisfactionData(Event_data, "eventDetails");
        }
      }
    );
  };

  /**
    *
    * TO HANDLE User MENU CLOSE
    */
  closeUserFilterMenu = () => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = null;
    this.setState({
      assigneeConfig,
    });
  };

  /**
    *
    * @param {item object} item
    * TO HANDLE User FILTER VALUE ON SEARCH
    */
  onUserSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchAssigneeValue: value,
    });
    this.employeeFilter(value);
  };

  employeeFilter = (search) => {
    const { assignedOption } = this.state;

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/report/userFilter", { search })
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = assignedOption.splice();
          if (this.state.searchAssigneeValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            assignedOption: newArray,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            assignedOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };

  render() {
    const {
      loggedInRole,
      role,
      companySizeFilter,
      tableData,
      tableHeaders,
      accountRep,
      loading
    } = this.state;


    var statusOptions = [
      { name: "Representative", _id: "employee" },
    ];
    const regex = /^[^:]*$/;

    const { Option } = Select;
    const { TabPane } = Tabs;
    let CompanyPage = (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        <Box component="span" className="company-comp">
          <div className="header-add-btn report_header_btn">
            <Typography variant="h5" className="tableTitle" component="div">
              Reports
            </Typography>
            <div>
              {this.state.reportType === 'Team Summary Report' ? '' :
                <>
                  {/* <PrintButton id={"singlePage"} label={"Download PDF"} />
              <span style={{display:'none'}}>
              <SinglePage id={"singlePage"} data={this.state}/>
              </span> */}
                  {/* <Button
                className="report_header_btn_one"
                variant="contained"
                color="default"
              >
                <img src={iconPdf} alt="PDF" />
                Download PDF
              </Button> 
              <Button
                className="report_header_btn_two"
                variant="contained"
                color="default"
                onClick={(e) => exportToCSV(this.state.tableData, this.state.reportType + Date.now())}
              >
                <img src={iconXls} alt="XLS" />
                Download XLs
              </Button> */}
                </>
              }
            </div>
          </div>
          <div className="report_filter">
            <div className="new_custom_filters">
              <Select
                className="report_ant_select selectBoi"
                placeholder="Select a report"
                defaultValue="Revenue Report"
                name="reportName"
                suffixIcon={
                  <img className="carret" src={carret_down} alt="carret" />
                }
                onChange={this.getReports}
              >
                <Option value="Revenue Report">
                  Revenue Report
                </Option>
                <Option value="Forecasted Revenue Report">
                  Forecasted Revenue Report
                </Option>
                <Option value="Expense Report">
                  Expense Report
                </Option>
                <Option value="Activity Report">
                  Activity Report
                </Option>
                <Option value="System Activity Report">
                  System Activity Report
                </Option>
                <Option value="Duration Report">
                  Duration Report
                </Option>
                <Option value="Uncovered Tasks">Uncovered Tasks</Option>

                <Option value="Event Report">
                  Event Report
                </Option>
                <Option value="Product Utilization Report">
                  Product Utilization Report
                </Option>


                {/* <Option value="Account Satisfaction">
                  Account Satisfaction
                </Option> */}

                <Option value="Products Breakdown">
                  Products Breakdown
                </Option>

                {/* <Option value="Cancellations">
                  Cancellations
                </Option> */}
                {/* <Option value="Days between Tasks">Days between Tasks</Option> */}
                {/* <Option value="Pipeline Report">Pipeline Report</Option> */}
                {/* <Option value="Most Active Accounts">
                  Most Active Accounts
                </Option> */}
                {/* <Option value="Most Active Reps">Most Active Reps</Option> */}


                {/* <Option value="Revenue Tasks Report">
                  Revenue Tasks Report
                </Option> */}
                <Option value="Team Summary Report">Team Summary Report</Option>


                {/* {loggedInRole === "super admin" ? (
                  <Option value="Finance report">Finance report</Option>
                ) : (
                    ""
                  )} */}
              </Select>

              <div className="new_custom_filter_div">
                <label className="leftName">Date Range: </label>
                <RangePicker
                  className="filter_date_range"
                  name="datepicker"
                  format="MM-DD-YYYY"
                  separator="-"
                  placeholder={["MM-DD-YYYY", "MM-DD-YYYY"]}
                  suffixIcon={
                    <img className="carret" alt="carret" src={carret_down} />
                  }
                  onChange={(date, dateString) =>
                    this.onDateChanage(date, dateString)
                  }
                />
              </div>
              {this.state.reportType !== "System Activity Report" && this.state.role === "super admin" ? (
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openCompanyFilterMenu}
                  >
                    {this.state.companyFilter !== "" ? (
                      ""
                    ) : (
                      <label className="leftName">Company: </label>
                    )}
                    <label
                      aria-label="company_filter"
                      aria-controls="company_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.companyFilter === ""
                        ? ``
                        : upperFirst(this.state.companyName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectWithSearchComponent
                    filterArray={this.state.companyOption}
                    config={this.state.companyConfig.config}
                    anchorEl={this.state.companyConfig.anchorElCompany}
                    searchValue={this.state.searchCompanyValue}
                    handleClick={this.handleCompanySelect}
                    closeCustomPopup={this.closeCompanyFilterMenu}
                    onSearchFilter={this.onCompanySearchFilter}
                    placeholder="Search Company"
                  />
                </Suspense>
              ) : (
                ""
              )}
              {(this.state.reportType !== "System Activity Report" && role.toLowerCase() === "super admin" &&
                companySizeFilter === "large") ||
                (role.toLowerCase() === "admin" &&
                  this.state.companySize === "large") ? (
                <>
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openDivisionFilterMenu}
                    >
                      {this.state.divisionFilter !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Division: </label>
                      )}
                      <label
                        aria-label="division_filter"
                        aria-controls="division_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.divisionFilter === ""
                          ? `All`
                          : upperFirst(this.state.divisionName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectComponent
                      filterArray={this.state.filters.division}
                      config={this.state.divisionConfig.config}
                      anchorEl={this.state.divisionConfig.anchorElDivision}
                      handleClick={this.handleDivisionSelect}
                      closeCustomPopup={this.closeDivisionFilterMenu}
                    />
                  </Suspense>
                </>
              ) : (
                ""
              )}
              {(this.state.reportType !== "System Activity Report" && role.toLowerCase() === "super admin" &&
                companySizeFilter !== "small") ||
                ((role.toLowerCase() === "admin" ||
                  role.toLowerCase() === "division manager") &&
                  this.state.companySize !== "small") ? (
                <>
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openAreaFilterMenu}
                    >
                      {this.state.areaFilter !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Area: </label>
                      )}
                      <label
                        aria-label="area_filter"
                        aria-controls="area_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.areaFilter === ""
                          ? `All`
                          : upperFirst(this.state.areaName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectComponent
                      filterArray={this.state.filters.area}
                      config={this.state.areaConfig.config}
                      anchorEl={this.state.areaConfig.anchorElArea}
                      handleClick={this.handleAreaSelect}
                      closeCustomPopup={this.closeAreaFilterMenu}
                    />
                  </Suspense>
                </>
              ) : (
                ""
              )}
              {this.state.reportType !== "System Activity Report" && role.toLowerCase() === "super admin" ||
                role.toLowerCase() === "admin" ||
                role.toLowerCase() === "division manager" ||
                role.toLowerCase() === "area manager" ? (
                <>
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openRegionFilterMenu}
                    >
                      {this.state.regionFilter !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Region: </label>
                      )}
                      <label
                        aria-label="region_filter"
                        aria-controls="region_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.regionFilter === ""
                          ? `All`
                          : upperFirst(this.state.regionName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectComponent
                      filterArray={this.state.filters.region}
                      config={this.state.regionConfig.config}
                      anchorEl={this.state.regionConfig.anchorElRegion}
                      handleClick={this.handleRegionSelect}
                      closeCustomPopup={this.closeRegionFilterMenu}
                    />
                  </Suspense>
                </>
              ) : (
                ""
              )}
              {this.state.reportType === "System Activity Report" ?
                "" :
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openTerritoryFilterMenu}
                  >
                    {this.state.territoryFilter !== "" ? (
                      ""
                    ) : (
                      <label className="leftName">Territory: </label>
                    )}
                    <label
                      aria-label="territory_filter"
                      aria-controls="territory_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.territoryFilter === ""
                        ? `All`
                        : upperFirst(this.state.territoryName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectComponent
                    filterArray={this.state.filters.territory}
                    config={this.state.territoryConfig.config}
                    anchorEl={this.state.territoryConfig.anchorElTerritory}
                    handleClick={this.handleTerritorySelect}
                    closeCustomPopup={this.closeTerritoryFilterMenu}
                  />
                </Suspense>
              }
              {this.state.reportType === "Event Report" ?
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openUserFilterMenu}
                  >
                    {this.state.assignedName !== "" ? (
                      ""
                    ) : (
                      <label className="leftName">User: </label>
                    )}
                    <label
                      aria-label="assignee_filter"
                      aria-controls="assignee_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.assignedName === ""
                        ? `All`
                        : upperFirst(this.state.assignedName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectWithSearchComponent
                    filterArray={this.state.assignedOption}
                    config={this.state.assigneeConfig.config}
                    anchorEl={this.state.assigneeConfig.anchorElAssignee}
                    searchValue={this.state.searchAssigneeValue}
                    handleClick={this.handleUserSelect}
                    closeCustomPopup={this.closeUserFilterMenu}
                    onSearchFilter={this.onUserSearchFilter}
                    placeholder="Search User"
                  />
                </Suspense>
                : ""}

              {this.state.reportType === "Activity Report" || this.state.reportType === "Duration Report" ?
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openSwitchToFilterMenu}
                  >
                    {/* {this.state.StatusSwitchToName !== "" ? (
                      "View:"
                    ) : (
                        <label className="leftName">View: </label>
                      )} */}
                    <label className="leftName">View: </label>
                    <label
                      aria-label="status_filter"
                      aria-controls="status_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.StatusSwitchToName === ""
                        ? `Account`
                        : upperFirst(this.state.StatusSwitchToName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectSwitchComponent
                    filterArray={statusOptions}
                    config={this.state.StatusConfig.config}
                    anchorEl={this.state.StatusConfig.anchorElStatus}
                    handleClick={this.handleSwitchStatusSelect}
                    closeCustomPopup={this.closeStatusSwitchFilterMenu}
                  />
                </Suspense>
                : ""}
              {this.state.reportType === "System Activity Report" ?
                <Suspense fallback={<div></div>}>

                  <div
                    className="new_custom_filter_div"
                    onClick={this.openHealthFilterMenu}
                  >
                    {this.state.healthName !== "" ? (
                      ""
                    ) : (
                      <label className="leftName">Health System: </label>
                    )}
                    <label
                      aria-label="account_filter"
                      aria-controls="account_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.healthName === ""
                        ? ``
                        : upperFirst(this.state.healthName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectWithSearchComponent
                    filterArray={this.state.healthOption}
                    config={this.state.healthConfig.config}
                    anchorEl={this.state.healthConfig.anchorElAccount}
                    searchValue={this.state.searchAccountValue}
                    handleClick={this.handleHealthSelect}
                    closeCustomPopup={this.closeHealthFilterMenu}
                    onSearchFilter={this.onHealthSearchFilter}
                    placeholder="Search Health System"
                  />
                </Suspense>
                : ""}

              {this.state.reportType === 'Team Summary Report' ?
                <>
                  <Suspense fallback={<div></div>}>
                    <div className="new_custom_filter_div" onClick={this.openEmployeeFilterMenu}>
                      {this.state.employeeFilter !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Employee: </label>
                      )}
                      <label
                        aria-label="employee_filter"
                        aria-controls="employee_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.employeeFilter === ""
                          ? `All`
                          : upperFirst(this.state.employeeName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.employeeOption}
                      config={this.state.employeeConfig.config}
                      anchorEl={this.state.employeeConfig.anchorElEmployee}
                      searchValue={this.state.searchEmployeeValue}
                      handleClick={this.handleEmployeeSelect}
                      closeCustomPopup={this.closeEmployeeFilterMenu}
                      onSearchFilter={this.onEmployeeSearchFilter}
                      placeholder="Search Employee"
                    />
                  </Suspense>
                </>
                : ''}
            </div>

          </div>
          {this.state.reportType === 'Team Summary Report' ?
            <div
              className="Comp-Table Acc-Table reports_down_tab"
            >
              <Button
                className="report_header_btn_down green-btn"
                variant="contained"
                color="default"
                onClick={this.getTeamReport}
              >
                <GetAppIcon />
                Download Report
              </Button>
            </div> :
            <div
              style={{ maxWidth: "100%" }}
              className="Comp-Table Acc-Table reports_tab"
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Graphs" key="1">
                  <Typography color="inherit" className="graph-table-header graph-header">
                    {this.state.reportType}
                  </Typography>
                  {loading ?
                    <div style={{ textAlign: 'center' }}>
                      <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        visible={loading}
                      /> </div> :
                    <>
                      {this.state.reportType === "Activity Report" || this.state.reportType === "System Activity Report"
                        ? (
                          this.state.RepChartData.yAxis.length > 0 &&
                            this.state.RepChartData.xAxis.length > 0 ? (
                            <Suspense fallback={<div></div>}>
                              <div className="report-graph utilization">
                                <CommonBarGraphComponent
                                  configuration={this.state.RepChartData.config}
                                  plotOptions={this.state.RepChartData.plotOptions}
                                  chartType="column"
                                  xAxis={this.state.RepChartData.xAxis}
                                  yAxisTitle={"Task (%)"}
                                  tooltip={this.state.RepChartData.tooltip}
                                  gridLineWidth={true}
                                  labelJustify="justify"
                                  formatter={true}
                                  seriesData={this.state.RepChartData.yAxis}
                                />
                              </div>
                            </Suspense>
                          ) : (
                            <Empty
                              description={
                                <span
                                  style={{
                                    color: "#303960",
                                  }}
                                >
                                  No data found
                                </span>
                              }
                            />
                          )
                        ) : (
                          ""
                        )}

                      {this.state.reportType === "Duration Report"
                        ? (
                          this.state.RepChartData.yAxis.length > 0 &&
                            this.state.RepChartData.xAxis.length > 0 ? (
                            <Suspense fallback={<div></div>}>
                              <div className="report-graph utilization">
                                <CommonBarGraphDurationComponent
                                  formatter={true}
                                  configuration={this.state.RepChartData.config}
                                  plotOptions={this.state.RepChartData.plotOptions}
                                  chartType="column"
                                  xAxis={this.state.RepChartData.xAxis}
                                  yAxisTitle={"Duration"}
                                  tooltip={this.state.DurationRepChartData.tooltip}
                                  gridLineWidth={true}
                                  labelJustify="justify"
                                  seriesData={this.state.RepChartData.yAxis}
                                />
                              </div>
                            </Suspense>
                          ) : (
                            <Empty
                              description={
                                <span
                                  style={{
                                    color: "#303960",
                                  }}
                                >
                                  No data found
                                </span>
                              }
                            />
                          )
                        ) : (
                          ""
                        )}
                      {
                        this.state.reportType === "Revenue Report" ? this.state.AccSatisfactionData.yAxis.length > 0 &&
                          this.state.AccSatisfactionData.xAxis.length > 0 ? (<Suspense fallback={<div></div>}>
                            <div className={this.state.reportType === "Account Satisfaction" ? "report-graph account-satisfaction" : this.state.reportType === "Uncovered Tasks" ? "report-graph uncovered" : "report-graph cancelled"}>
                              <span>
                                <CommonBarGraphwithSeparator
                                  configuration={this.state.AccSatisfactionData.config}
                                  plotOptions={this.state.AccSatisfactionData.plotOptions}
                                  chartType="column"
                                  xAxis={this.state.AccSatisfactionData.xAxis}
                                  yAxisTitle={"Revenue Report ($)"}
                                  xAxisTitle={this.state.AccSatisfactionData.xAxisName}
                                  labelJustify="justify"
                                  thousandSep={true}
                                  tooltip={{ pointFormat: `{series.name}: <b>$</b><b>{point.y}</b>` }}
                                  //this.state.AccSatisfactionData.tooltip}
                                  seriesData={this.state.AccSatisfactionData.yAxis}
                                />
                              </span>
                            </div>
                          </Suspense>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                              </span>
                            }
                          />
                        ) : ""
                      }
                      {this.state.reportType === "Account Satisfaction" ||
                        this.state.reportType === "Event Report" ||
                        this.state.reportType === "Uncovered Tasks" ||
                        this.state.reportType === "Forecasted Revenue Report" ||
                        this.state.reportType === "Product Utilization Report" ||
                        this.state.reportType === "Revenue Tasks Report" ||
                        this.state.reportType === "Cancellations" ? (
                        this.state.AccSatisfactionData.yAxis.length > 0 &&
                          this.state.AccSatisfactionData.xAxis.length > 0 ? (
                          <Suspense fallback={<div></div>}>
                            <div className={this.state.reportType === "Account Satisfaction" ? "report-graph account-satisfaction" : this.state.reportType === "Uncovered Tasks" ? "report-graph uncovered" : "report-graph cancelled"}>
                              <span>
                                <CommonBarGraphComponent
                                  configuration={this.state.AccSatisfactionData.config}
                                  plotOptions={this.state.AccSatisfactionData.plotOptions}
                                  chartType="column"
                                  xAxis={this.state.AccSatisfactionData.xAxis}
                                  yAxisTitle={this.state.AccSatisfactionData.yAxisName}
                                  xAxisTitle={this.state.AccSatisfactionData.xAxisName}
                                  labelJustify="justify"
                                  thousandSep={this.state.reportType === "Revenue Report" ? true : false}
                                  tooltip={{ pointFormat: `{series.name}: <b>{point.y}</b>` }}
                                  //this.state.AccSatisfactionData.tooltip}
                                  seriesData={this.state.AccSatisfactionData.yAxis}
                                />
                              </span>
                            </div>
                          </Suspense>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                              </span>
                            }
                          />
                        )
                      ) : (
                        ""
                      )}


                      {
                        this.state.reportType === "Expense Report" ? (
                          this.state.AccSatisfactionData.yAxis.length > 0 &&
                            this.state.AccSatisfactionData.xAxis.length > 0 ? (
                            <Suspense fallback={<div></div>}>
                              <div className={this.state.reportType === "Account Satisfaction" ? "report-graph account-satisfaction" : this.state.reportType === "Uncovered Tasks" ? "report-graph uncovered" : "report-graph cancelled"}>
                                <span>
                                  <CommonBarGraphComponent
                                    configuration={this.state.AccSatisfactionData.config}
                                    plotOptions={this.state.AccSatisfactionData.plotOptions}
                                    chartType="column"
                                    xAxis={this.state.AccSatisfactionData.xAxis}
                                    yAxisTitle={this.state.AccSatisfactionData.yAxisName}
                                    xAxisTitle={this.state.AccSatisfactionData.xAxisName}
                                    labelJustify="justify"
                                    thousandSep={this.state.reportType === "Revenue Report" ? true : false}
                                    tooltip={{ pointFormat: `Expense: <b>{point.y}</b>` }}
                                    //this.state.AccSatisfactionData.tooltip}
                                    seriesData={this.state.AccSatisfactionData.yAxis}
                                  />
                                </span>
                              </div>
                            </Suspense>
                          ) : (
                            <Empty
                              description={
                                <span
                                  style={{
                                    color: "#303960",
                                  }}
                                >
                                  No data found
                                </span>
                              }
                            />
                          )
                        ) : (
                          ""
                        )}



                      {this.state.reportType === "Most Active Reps" ? (
                        this.state.activeReportData.yAxis.length > 0 &&
                          this.state.activeReportData.xAxis.length > 0 ? (
                          <Suspense fallback={<div></div>}>
                            <span>
                              <CommonBarGraphComponent
                                configuration={this.state.activeReportData.config}
                                plotOptions={this.state.activeReportData.plotOptions}
                                chartType="column"
                                xAxis={this.state.activeReportData.xAxis}
                                yAxisTitle={this.state.activeReportData.yAxisName}
                                xAxisTitle={this.state.activeReportData.xAxisName}
                                labelJustify="justify"
                                tooltip={this.state.activeReportData.tooltip}
                                seriesData={this.state.activeReportData.yAxis}
                              />
                            </span>
                          </Suspense>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                              </span>
                            }
                          />
                        )
                      ) : (
                        ""
                      )}


                      {this.state.reportType === "Most Active Accounts" ? (
                        this.state.activeAccountData.yAxis.length > 0 &&
                          this.state.activeAccountData.xAxis.length > 0 ? (
                          <Suspense fallback={<div></div>}>
                            <span>
                              <CommonBarGraphComponent
                                configuration={this.state.activeAccountData.config}
                                plotOptions={this.state.activeAccountData.plotOptions}
                                chartType="column"
                                xAxis={this.state.activeAccountData.xAxis}
                                yAxisTitle={this.state.activeAccountData.yAxisName}
                                xAxisTitle={this.state.activeAccountData.xAxisName}
                                labelJustify="justify"
                                tooltip={this.state.activeAccountData.tooltip}
                                seriesData={this.state.activeAccountData.yAxis}
                              />
                            </span>
                          </Suspense>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                              </span>
                            }
                          />
                        )
                      ) : (
                        ""
                      )}
                      {this.state.reportType === "Pipeline Report" || this.state.reportType === "Products Breakdown" ? (
                        this.state.PipelineReportData.yAxis.length > 0 ? (
                          <Suspense fallback={<div></div>}>
                            <span className="pie-chart">
                              <CommonPieChartComponent
                                title={""}
                                // from='reports'
                                type={'pie'}
                                pointFormat={'{series.name}: <b>{point.percentage:.1f}%</b>'}
                                valueSuffix={'%'}
                                dataLabels={'<b>{point.name}</b>: {point.percentage:.1f} %'}
                                legend={true}
                                seriesName={'Company'}
                                seriesData={this.state.PipelineReportData.yAxis}
                              />
                            </span>
                          </Suspense>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                              </span>
                            }
                          />
                        )
                      ) : (
                        ""
                      )}
                      {this.state.reportType === "Revenue and Expenses" ? (
                        this.state.revenueData.yAxis.length > 0 &&
                          this.state.revenueData.xAxis.length > 0 ? (
                          <span className="report-graph cost-revenue">
                            <Suspense fallback={<div></div>}>
                              <CommonBarGraphComponent
                                className="vertical-barchart"
                                configuration={this.state.revenueData.config}
                                plotOptions={this.state.revenueData.plotOptions}
                                chartType="bar"
                                xAxis={this.state.revenueData.xAxis}
                                yAxisTitle={this.state.revenueData.yAxisName}
                                xAxisTitle={this.state.revenueData.xAxisName}
                                labelJustify="justify"
                                tooltip={this.state.revenueData.tooltip}
                                seriesData={this.state.revenueData.yAxis}
                                xAxisLabelRotate={-90}
                              />
                            </Suspense>
                          </span>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                              </span>
                            }
                          />
                        )
                      ) : (
                        ""
                      )}
                      {this.state.reportType === 'Days between Tasks' ? (
                        this.state.revenueData.yAxis.length > 0 &&
                          this.state.revenueData.xAxis.length > 0 ? (
                          <span className="report-graph cost-revenue">
                            <Suspense fallback={<div></div>}>
                              <CommonBarGraphComponent
                                className="vertical-barchart"
                                configuration={this.state.revenueData.config}
                                plotOptions={this.state.revenueData.plotOptions}
                                chartType="column"
                                xAxis={this.state.revenueData.xAxis}
                                yAxisTitle={this.state.revenueData.yAxisName}
                                xAxisTitle={this.state.revenueData.xAxisName}
                                labelJustify="justify"
                                tooltip={this.state.revenueData.tooltip}
                                seriesData={this.state.revenueData.yAxis}

                              />
                            </Suspense>
                          </span>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                              </span>
                            }
                          />
                        )
                      ) : (
                        ""
                      )}
                    </>
                  }
                </TabPane>
                <TabPane tab="Table" key="2">
                  <Typography color="inherit" className="graph-table-header table-header">
                    {this.state.reportType}
                  </Typography>
                  <div className="report-tab-table">
                    <TableContainer>
                      <Table aria-label="customized table">
                        <TableHead class="report-tab-table-header">
                          <TableRow>
                            <TableCell style={{ textAlign: "center" }}>#</TableCell>
                            {tableHeaders.map((header, i) => (
                              <TableCell style={{ textAlign: "center" }}>{header === "Revenue" || header === "Revenue( $ )" ? "Revenue" : header}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody class="report-tab-table-body">
                          {!tableData || tableData.length === 0 ? (
                            <StyledTableRow>
                              <TableCell
                                colSpan={9}
                                style={{ textAlign: "center" }}
                              >
                                No data Found
                              </TableCell>
                            </StyledTableRow>
                          ) : (
                            tableData.map((row, index) => (
                              <StyledTableRow>
                                <TableCell class="report_table">{index + 1} </TableCell>
                                {this.state.reportType === 'Pipeline Report' ? (
                                  <>
                                    <TableCell class="report_table">{row.Name ? row.Name : row.Type}</TableCell>
                                    <TableCell class="report_table">{"bena"}</TableCell></>) :
                                  tableHeaders.map((header, i) =>
                                    header === "Account" ? (
                                      <TableCell
                                      class="report_table"
                                        onMouseEnter={(event) =>
                                          this.getSalesrep(row._id, event)
                                        }
                                      >
                                        <Dropdown
                                          overlay={
                                            <Menu className="report-popup-menu">
                                              {/* <Menu.Item className="popup-header">
                                                Representative
                                          </Menu.Item> */}
                                              <Divider className="popup-divider" />
                                              {accountRep
                                                ? accountRep.map((assign) => (
                                                  <Menu.Item key={assign._id} className="popup-list">
                                                    {assign.name}
                                                  </Menu.Item>
                                                ))
                                                : ""}
                                            </Menu>
                                          }
                                          placement="bottomLeft"
                                          arrow
                                        >
                                          <span>{row[header]}</span>
                                        </Dropdown>
                                      </TableCell>
                                    ) : (
                                      <TableCell class="report_table">
                                        {header === "Revenue" && regex.test(row[header]) ?
                                          <NumberFormat class="report-tab-numbers" value={row[header] ? row[header] : 0} displayType={'text'} thousandSeparator={true} />
                                          : row[header]}
                                      </TableCell>
                                    )
                                  )}
                              </StyledTableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
                      rowsPerPageOptions={[1, 2, 25]}
                      component="div"
                      count={tableData.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    /> */}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          }
        </Box>
      </div>
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <div>{CompanyPage}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole) =>
      dispatch(action.companyPagination(pageNo, pagerole)),
    onFilter: (states) => dispatch(action.oncompanyFilter(states)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportsGraphComponent));
