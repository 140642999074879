import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import iconClose from "../../../Assets/images/close_btn.svg";
import amex_card from "../../../Assets/images/american_card.png";
import visa_card from "../../../Assets/images/visa_card.png";
import uniconpay_card from "../../../Assets/images/uniconpay_card.png";
import dinar_card from "../../../Assets/images/dinar_card.png";
import discover_card from "../../../Assets/images/discover_card.png";
import jcb_card from "../../../Assets/images/jcb_card.png";
import master_card from "../../../Assets/images/master_card.png";
import no_card_brand from "../../../Assets/images/no_card_brand.png";
import {
  CssTextField,
  Transition,
} from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import {
  useElements,
  useStripe,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { AxiosInstance } from "../../../Utilities";
import credit_card from "../../../Assets/images/Icon awesome-credit-card.svg";
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import { userName } from "../../../SharedComponents/Validation/formValidation";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";
import {startCase} from 'lodash';
const BillingMultipleCardComponent = (props) => {
  const stripes = useStripe();
  const elements = useElements();
  const [uname, setnames] = useState("");
  const [nameError, setNameError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const billingData = JSON.parse(localStorage.getItem("billingDetail"));
  const setname = (e) => {
    const { value, name } = e.target;
    var error = "";
    switch (name) {
      case "uname":
        value.length >= 0 && value.trim() === ""
          ? (error = "")
          : !userName.test(value)
          ? (error = "Please enter valid name")
          : (error = "");
        break;
      default:
        break;
    }
    setNameError(error);
    setnames(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uname.trim() === "") {
      setNameError("Please enter name");
    } else {
      setNameError("");
      setIsSubmit(true);
      const { error, paymentMethod } = await stripes.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city: billingData.city,
            country: billingData.country,
            line1: billingData.addresLine1,
            line2: billingData.addressLine2,
            postal_code: billingData.zipCode,
            state: billingData.state,
          },
          email: billingData.email,
          name: uname,
          phone: billingData.phone,
        },
      });

      if (!error) {
        if(props.card_header && props.card_header.toLowerCase() === "subscribe"){
          //subscribe api's
          let stripeData = {
            paymentMethodId: paymentMethod.id,
          };
          let type=1;
          props.subscribeNewHandler(stripeData,type);
        }else{
          //edit api
        let stripeData = {
          name: paymentMethod.billing_details.name,
          email: paymentMethod.billing_details.email,
          cardNumber: paymentMethod.card.last4,
          cardExpMonth: paymentMethod.card.exp_month,
          cardExpYear: paymentMethod.card.exp_year,
          paymentMethodId: paymentMethod.id,
        };
        props.setCustomerData(stripeData);
        }
       setIsSubmit(false);
      }
    }
  };

  const opts = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
  return (
    <Dialog
      open={props.openMulticardModal}
      className="add_company_dialog credit_card_dialog multi_card_dialog"
      TransitionComponent={Transition}
      transitionDuration={600}
      // onExit={()=>props.closeCardModal(),()=>setnames(""),()=>setNameError("")}
      onExit={()=>props.closeCardModalHandler,()=>setnames(""),()=>setNameError("")}
    >
      <DialogTitle
        id="form-dialog-title"
        className="add_user_header task_assignment_header"
      >
        <span>{props.card_header}</span>
        <IconButton
          aria-label="close"
          className="closeBtn updateStatusCloseBtn"
          onClick={props.closeCardModalHandler}
        >
          <img src={iconClose}></img>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="bill_multi_card_container">
          {props.billStatus === 2 && props.card_header === "Subscribe"?
        props.multiCardArray &&
        props.multiCardArray.length > 0 &&
        props.multiCardArray.map((item) => (
          props.sub_paymentId !== item.paymentMethodId?
          <div
            className="bill_details_card_container"
            key={item.paymentMethodId}
          >
           
            <>
            <div className="bill_card_left">
              <div className="bill_card_select">
              <label className="bill_card_select_container">
              <input
                  type="radio"
                  value={item.paymentMethodId}
                  checked={
                    props.radio_checked_id &&
                    props.radio_checked_id === item.paymentMethodId 
                  }
                  onChange={(event) =>
                    props.on_card_radio_check(event, item)
                  }
                />
              <span className="checkmark"></span>
              </label>
              </div>
              <div className="bill_details_section">
                <h6 className="bill_details_card_number">
                  <span>.... .... .... </span>
                  {item.last4}
                </h6>
                
                {props.showedit &&
                item.paymentMethodId === props.radio_checked_id ? (
                  <div>
                    <div>
                      <label className="label bl-label">
                        Name on card
                        <span className="labelAsterisk"> *</span>
                      </label>
                      <CssTextField
                        name="edit_card_holder"
                        variant="outlined"
                        type="text"
                        fullWidth
                        margin="dense"
                        value={props.edit_card_holder}
                        placeholder="Enter name"
                        onChange={props.onEditChange}
                      />
                      <span className="error">
                        {props.editError && props.editError.edit_card_holder !== ""
                          ? "*" + props.editError.edit_card_holder
                          : ""}
                      </span>
                    </div>
                    <div className="expiry_details_field">
                    <div>
                      <label className="label bl-label">
                        Expiry Month
                        <span className="labelAsterisk"> *</span>
                      </label>
                      <CssTextField
                        name="edit_card_month"
                        variant="outlined"
                        type="text"
                        fullWidth
                        margin="dense"
                        value={props.edit_card_month}
                        placeholder="Enter expiry month"
                        onChange={props.onEditChange}
                      />
                      <span className="error">
                        {props.editError && props.editError.exp_month !== ""
                          ? "*" + props.editError.exp_month
                          : ""}
                      </span>
                    </div>
                    

                    <div>
                      <label className="label bl-label">
                        Expiry Year<span className="labelAsterisk"> *</span>
                      </label>
                      <CssTextField
                        name="edit_card_year"
                        variant="outlined"
                        type="text"
                        fullWidth
                        margin="dense"
                        value={props.edit_card_year}
                        placeholder="Enter expiry Year"
                        onChange={props.onEditChange}
                      />
                      <span className="error">
                        {props.editError && props.editError.exp_year !== ""
                          ? "*" + props.editError.exp_year
                          : ""}
                      </span>
                    </div>
                    </div>
                    {props.showedit &&
                item.paymentMethodId === props.radio_checked_id?
                <div className="bill_card_buttons">
                <Button onClick={props.onEditCancelHandler}>Cancel</Button>
                <Button onClick={props.onEditSaveHandler}>Save</Button>
              </div>: "" }
                  </div>
                ) : (
                  <>
                  <h6 className="bill_details_card_holder">{item.name?startCase(item.name):"-"}</h6>
                  <h6 className="bill_details_card_expire">
                    Expires {item.exp_month}/{item.exp_year}
                  </h6>
                  </>
                )}
              </div>
            </div>
            <div className="bill_card_right">
              <img src={
                 item.brand.toLowerCase() === "visa"
                 ?visa_card
                : item.brand.toLowerCase() === "american express"
                ? amex_card
                : item.brand.toLowerCase() === "diners club"
                ? dinar_card
                : item.brand.toLowerCase() === "discover"
                ? discover_card
                : item.brand.toLowerCase() === "jcb"
                ? jcb_card
                : item.brand.toLowerCase() === "mastercard"
                ? master_card
                : item.brand.toLowerCase() === "unionpay"
                ? uniconpay_card
                : no_card_brand
                } 
                alt="card_img" className="atm_card_image"/>
              {props.showedit &&
                item.paymentMethodId === props.radio_checked_id?
              //   <div className="bill_card_buttons">
              //   <Button onClick={props.onEditCancelHandler}>Cancel</Button>
              //   <Button onClick={props.onEditSaveHandler}>Save</Button>
              // </div>:
              "":
              <div className="bill_card_buttons">
                <Button
                  disabled={props.sub_paymentId === item.paymentMethodId}
                  onClick={(event)=>props.removeCard(event,item.paymentMethodId)}
                >
                  Remove
                </Button>
                <Button
                  onClick={() =>
                    props.onCardEdit(item, item.paymentMethodId)
                  }
                >
                  Edit
                </Button>
              </div>}
            </div>
          </>
          </div>:""
        ))
          :  props.multiCardArray &&
          props.multiCardArray.length > 0 &&
          props.multiCardArray.map((item) => (
            <div
              className="bill_details_card_container"
              key={item.paymentMethodId}
            >
              <div className="bill_card_left">
                <div className="bill_card_select">
                <label className="bill_card_select_container">
                  <input
                    type="radio"
                    value={item.paymentMethodId}
                    checked={
                      props.radio_checked_id &&
                      props.radio_checked_id === item.paymentMethodId 
                    }
                    onChange={(event) =>
                      props.on_card_radio_check(event, item)
                    }
                  />
                  <span className="checkmark"></span>
              </label>
                </div>
                <div className="bill_details_section">
                  <h6 className="bill_details_card_number">
                    <span>.... .... .... </span>
                    {item.last4}
                  </h6>
                  
                  {props.showedit &&
                  item.paymentMethodId === props.radio_checked_id ? (
                    <div>
                      <div>
                        <label className="label bl-label">
                          Name on card
                          <span className="labelAsterisk"> *</span>
                        </label>
                        <CssTextField
                          name="edit_card_holder"
                          variant="outlined"
                          type="text"
                          fullWidth
                          margin="dense"
                          value={props.edit_card_holder}
                          placeholder="Enter name"
                          onChange={props.onEditChange}
                        />
                        <span className="error">
                          {props.editError && props.editError.edit_card_holder !== ""
                            ? "*" + props.editError.edit_card_holder
                            : ""}
                        </span>
                      </div>
                      <div className="expiry_details_field">
                      <div>
                        <label className="label bl-label">
                          Expiry Month
                          <span className="labelAsterisk"> *</span>
                        </label>
                        <CssTextField
                          name="edit_card_month"
                          variant="outlined"
                          type="text"
                          fullWidth
                          margin="dense"
                          value={props.edit_card_month}
                          placeholder="Enter expiry month"
                          onChange={props.onEditChange}
                        />
                        <span className="error">
                          {props.editError && props.editError.exp_month !== ""
                            ? "*" + props.editError.exp_month
                            : ""}
                        </span>
                      </div>

                      <div>
                        <label className="label bl-label">
                          Expiry Year<span className="labelAsterisk"> *</span>
                        </label>
                        <CssTextField
                          name="edit_card_year"
                          variant="outlined"
                          type="text"
                          fullWidth
                          margin="dense"
                          value={props.edit_card_year}
                          placeholder="Enter expiry Year"
                          onChange={props.onEditChange}
                        />
                        <span className="error">
                          {props.editError && props.editError.exp_year !== ""
                            ? "*" + props.editError.exp_year
                            : ""}
                        </span>
                      </div>
                      </div>
                      {props.showedit &&
                  item.paymentMethodId === props.radio_checked_id?
                  <div className="bill_card_buttons">
                  <Button onClick={props.onEditCancelHandler}>Cancel</Button>
                  <Button onClick={props.onEditSaveHandler}>Save</Button>
                </div> : ""}
                    </div>
                  ) : (
                    <>
                    <h6 className="bill_details_card_holder">{item.name?startCase(item.name):"-"}</h6>
                    <h6 className="bill_details_card_expire">
                      Expires {item.exp_month}/{item.exp_year}
                    </h6>
                    </>
                  )}
                </div>
              </div>
              <div className="bill_card_right">
                <img src={ 
                  item.brand.toLowerCase() === "visa"
                  ?visa_card
                :item.brand.toLowerCase() === "american express"
                ? amex_card
                : item.brand.toLowerCase() === "diners club"
                ? dinar_card
                : item.brand.toLowerCase() === "discover"
                ? discover_card
                : item.brand.toLowerCase() === "jcb"
                ? jcb_card
                : item.brand.toLowerCase() === "mastercard"
                ? master_card
                : item.brand.toLowerCase() === "unionpay"
                ? uniconpay_card
                : no_card_brand} alt="card_img" className="atm_card_image"/>
                {props.showedit &&
                  item.paymentMethodId === props.radio_checked_id?
                //   <div className="bill_card_buttons">
                //   <Button onClick={props.onEditCancelHandler}>Cancel</Button>
                //   <Button onClick={props.onEditSaveHandler}>Save</Button>
                // </div>
                "":
                <div className="bill_card_buttons">
                  <Button
                    disabled={props.sub_paymentId === item.paymentMethodId}
                    onClick={(event)=>props.removeCard(event,item.paymentMethodId)}
                  >
                    Remove
                  </Button>
                  <Button
                    onClick={() =>
                      props.onCardEdit(item, item.paymentMethodId)
                    }
                  >
                    Edit
                  </Button>
                </div>}
              </div>
            </div>
          ))}
          <div className="bill_details_card_container">
            <div className="bill_card_left">
              <div className="bill_card_select">
              <label className="bill_card_select_container">
                <input
                  type="radio"
                  checked={
                    props.radio_checked_id &&
                    props.radio_checked_id === "add_card"
                  }
                  onChange={(event) =>
                    props.on_add_card_radio_check(event, "add_card")
                  }
                />
                <span className="checkmark"></span>
              </label>
              </div>
              <div className="bill_details_section">
                <h6 className="bill_details_card_number">Add Credit Card</h6>
                {props.radio_checked_id &&
                props.radio_checked_id === "add_card" ? (
                  <div>
                    <div>
                      <label className="label bl-label">
                        Name on card<span className="labelAsterisk"> *</span>
                      </label>
                      <CssTextField
                        name="uname"
                        variant="outlined"
                        type="text"
                        fullWidth
                        margin="dense"
                        value={uname}
                        placeholder="Enter name"
                        onChange={setname}
                      />
                      <span className="error">
                        {nameError !== "" ? "*" + nameError : ""}
                      </span>
                    </div>
                    <div>
                      <label className="label bl-label">
                        Card number<span className="labelAsterisk"> *</span>
                      </label>
                      <CardNumberElement
                        className="card_detail_input_field"
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                          showIcon: true,
                        }}
                        onChange={props.handleChangeCard}
                      />
                      <span className="error">
                        {props.cardErrors.numberError}
                      </span>
                    </div>
                    <Grid container spacing={6}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        className="card_expiration_date"
                      >
                        <div>
                          <label className="label bl-label">
                            Expiration date
                            <span className="labelAsterisk"> *</span>
                          </label>
                          <CardExpiryElement
                            className="card_detail_input_field"
                            options={opts}
                            onChange={props.handleChangeExpiry}
                          />
                          <span className="error">
                            {" "}
                            {props.cardErrors.expiryError}
                          </span>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        className="card_cvc_number"
                      >
                        <div>
                          <label className="label bl-label">
                            CVC<span className="labelAsterisk"> *</span>
                          </label>
                          <div className="card_cvc_number_field">
                            <CardCvcElement
                              className="card_detail_input_field"
                              options={opts}
                            />
                            <img src={credit_card} alt="credit_card_icon" />
                          </div>
                        </div>
                        <span className="error"></span>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="add_company_action">
            <Button className="ok_btn green-btn" type="submit" 
              onClick={
                props.card_header && props.card_header.toLowerCase() !== "subscribe" && props.radio_checked_id === "add_card"
                ?handleSubmit
                : props.card_header && props.card_header.toLowerCase() !== "subscribe" && props.radio_checked_id !== "add_card"
                ?props.updateHandler
                :props.card_header && props.card_header.toLowerCase() === "subscribe" && props.radio_checked_id === "add_card"
                ?handleSubmit
                :props.card_header && props.card_header.toLowerCase() === "subscribe" && props.radio_checked_id !== "add_card"
                ?props.subscribeHandler
                :""
              }>
              {props.card_header && props.card_header.toLowerCase() === "subscribe"?"Subscribe":"Update"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BillingMultipleCardComponent;
