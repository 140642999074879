import React from 'react';
import closeBtn from "../../../Assets/images/close_btn.svg";
import IconButton from '@material-ui/core/IconButton';
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    MenuItem,
    DialogTitle,
    Select,
    FormControl,
    CircularProgress,
} from "@material-ui/core";
import { Transition } from '../../../SharedComponents/CommonUtilities/CommonUtilities';
const AcceptInviteAccount = props => {
    return (
        <div >
            <div>
                <Dialog {...props.modelData}
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="form-dialog-title" 
                    maxWidth='md' 
                    className="add_company_dialog add_account_dialog"
                    TransitionComponent={Transition} 
                    transitionDuration={600}>
                    <DialogTitle id="form-dialog-title" className="add_user_header">
                        <span>Add Team</span>
                        <IconButton onClick={props.handleClose} className="closeBtn" aria-label="show 17 new notifications" color="inherit">
                            <img src={closeBtn} alt="close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        <label className="label bl-label">Area</label>
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                margin="dense"
                                className="customSelect"
                                placeholder="Area"
                                name="areaFilter"
                                fullWidth
                              onChange={props.areaHandelChange}
                            >
                                 <MenuItem value="">None</MenuItem>
                                 {props.modelData.areaList.map((mitem) => (
                                            <MenuItem key={mitem._id} value={mitem._id}>
                                                {mitem.name}
                                            </MenuItem>

                                        ))}
                            </Select>
                        </FormControl>
                        <span className="error"></span>
                        <label className="label bl-label">Region</label>
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                margin="dense"
                                className="customSelect"
                                placeholder="Region"
                                name="regionFilter"
                                fullWidth
                               onChange={props.regionHandelChange}
                            >
                                 <MenuItem value="">None</MenuItem>
                                {props.modelData.regionList.map((mitem) => (
                                            <MenuItem key={mitem._id} value={mitem._id}>
                                                {mitem.name}
                                            </MenuItem>

                                        ))}
                            </Select>
                        </FormControl>
                        <span className="error"></span>
                        <label className="label bl-label">Territory</label>
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                margin="dense"
                                className="customSelect"
                                placeholder="Territory"
                                name="territoryFilter"
                                fullWidth
                                 onChange={props.territoryHandelchnge}
                            >
                                 <MenuItem value="">None</MenuItem>
                                {props.modelData.territoryList.map((mitem) => (
                                            <MenuItem key={mitem._id} value={mitem._id}>
                                                {mitem.name}
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                        <span className="error"></span>
                        <div className="add_company_action">
                            <Button className="cancel_btn white-btn" onClick={props.handleClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button className="ok_btn green-btn" variant="contained" onClick={props.acceptRequest } disabled={props.modelData.isAdd}>
                                {props.modelData.isSubmit ? <CircularProgress size={20} /> : ''} Add Team
                             </Button>
                        </div>

                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};
export default AcceptInviteAccount;