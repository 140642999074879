import React, { useState } from "react";
import "./CreditCardComponent.css";
import {
  useElements,
  useStripe,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { AxiosInstance } from "../../../Utilities";
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  TextField,
  IconButton,
  DialogContent,
  Button,
  Grid,
  CircularProgress
} from "@material-ui/core";
import tick_green from "../../../Assets/images/upload_img.png";
import iconClose from "../../../Assets/images/close_btn.svg";
import credit_card from "../../../Assets/images/Icon awesome-credit-card.svg";

import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../../Assets/images/icon-notify-success.svg";
import {
  Transition,
} from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import { userName } from "../../../SharedComponents/Validation/formValidation";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";


const CssTextField = withStyles({
  root: {
      '& .MuiInput-underline:after': {
          borderBottomColor: '#14193A',
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: '#E2E5F2',
              borderWidth: 1
          },
          '&.Mui-focused fieldset': {
              borderColor: '#14193A',
              borderWidth: 1
          },
      },
  },
})(TextField); 

const CreditCardComponent = (props) => {
  const stripes = useStripe();
  const elements = useElements();
  const [uname, setnames] = useState("");
  const [nameError, setNameError] = useState("");
  const [isSubmit,setIsSubmit]=useState(false);
  const billingData = JSON.parse(localStorage.getItem("billingDetail"));
  const setname = (e) => {
    const { value, name } = e.target;
    var error = "";
    switch (name) {
      case "uname":
        value.length >= 0 && value.trim() === ""
          ? (error = "")
          : !userName.test(value)
          ? (error = "Please enter valid name")
          : (error = "");
        break;
      default:
        break;
    }
    setNameError(error);
    setnames(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(uname.trim() === ""){
      setNameError("Please enter name");
    }else{
      setNameError("");
    setIsSubmit(true);
    var customer= await AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      "application/json"
    ).post("/create-customer", {
      email: billingData.email,
    })
    var cus = customer && customer.data;
    var customersId = cus && cus.data;
    var id = customersId.id;
    const { error, paymentMethod } = await stripes.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          city: billingData.city,
          country: billingData.country,
          line1: billingData.addresLine1,
          line2: billingData.addressLine2,
          postal_code: billingData.zipCode,
          state: billingData.state,
        },
        email: billingData.email,
        name: uname,
        phone: billingData.phone,
      },
    });

    if (!error) {
      
      let price = await AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
          ? 6019
          : "subscription",
        "application/json"
      ).post("/create-price", {
        unit_amount: props.subscribePrice,
        currency: "usd",
        productName: "Professional plan",
        billingCycle: billingData.period
      });
      let pid = paymentMethod.id;
      let priceId = price.data.data.id;

      let stripeData={
        name:paymentMethod.billing_details.name,
        email:paymentMethod.billing_details.email,
        cardNumber:paymentMethod.card.last4,
        cardExpMonth:paymentMethod.card.exp_month,
        cardExpYear:paymentMethod.card.exp_year,
        customerId:id,
        paymentMethodId:pid,
        priceId: priceId,
      }
      props.setCustomerData(stripeData);
      setIsSubmit(false);
    }else{
      let propserror = {
        message: error.message,
        icon: <img src={iconNotificationError} alt="error" />,
        type: "error",
        placement: "topRight",
        duration: 3,
        top: 200,
        className: "task_notification error-notify",
      };
      NotificationComponent(propserror);
      setIsSubmit(false);
    }
  }
  };


  const opts = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    }
  };
  return (
    // <Dialog
    //   open={props.openCardModal}
    //   className="add_company_dialog credit_card_dialog"
    //   TransitionComponent={Transition}
    //   transitionDuration={600}
    //   onExit={()=>props.closeCardModal(),()=>setnames(""),()=>setNameError("")}
    // >
    //   <DialogTitle
    //     id="form-dialog-title"
    //     className="add_user_header task_assignment_header"
    //   >
    //     <span>Add Credit Card</span>
    //     <IconButton
    //       aria-label="close"
    //       className="closeBtn updateStatusCloseBtn"
    //       onClick={props.closeCardModal}
    //     >
    //       <img src={iconClose}></img>
    //     </IconButton>
    //   </DialogTitle>
    //   <DialogContent>
    // <div></div>
    // props.showAddCard?
    <div className="billing_page">
        <h5 className="billing_page_title" data-test="billing_header">
        Add Credit Card
      </h5>
    <div className="manage_subscriptions_section credit_card_add_section">
        <form onSubmit={handleSubmit} className="credit_card_dialog">
          <div>
            <label className="label bl-label">
              Name on card<span className="labelAsterisk"> *</span>
            </label>
            <div className="add_company_dialog">
            <CssTextField
              name="uname"
              variant="outlined"
              type="text"
              fullWidth
              margin= "dense"
              value={uname}
              placeholder="Enter name"
              onChange={setname}
            />
            <span className="error">
              {nameError !== "" ? "*" + nameError : ""}
            </span>
            </div>
          </div>
          <div >
            <label className="label bl-label">Card number<span className="labelAsterisk"> *</span></label>
            <CardNumberElement
              className="card_detail_input_field"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
                showIcon: true,
              }}
              onChange={props.handleChangeCard}
            />
            <span className="error">{props.cardErrors.numberError}</span>
          </div>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} className="card_expiration_date">
          <div>
            <label className="label bl-label">Expiration date<span className="labelAsterisk"> *</span></label>
            <CardExpiryElement
              className="card_detail_input_field"
              options={opts}
              onChange={props.handleChangeExpiry}
            />
            <span className="error"> {props.cardErrors.expiryError}</span>
          </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="card_cvc_number">
          <div>
            <label className="label bl-label">CVC<span className="labelAsterisk"> *</span></label>
            <div className="card_cvc_number_field">
            <CardCvcElement
              className="card_detail_input_field"
              options={opts}
            />
            <img src={credit_card} alt="credit_card_icon"/>
            </div>
            </div>
            <span className="error"></span>
          </Grid>
          </Grid>
          <div className="add_company_action">
          <Button
             data-test="cancel"
             className="cancel_btn white-btn"
             variant="outlined"
             onClick={props.cancelBillingConfig}
           >
             Back
           </Button>
          <Button 
            type="submit"
            className="ok_btn green-btn" 
            disabled={isSubmit?true:false}
            >
            Confirm
            {isSubmit?<CircularProgress size={20} />:""}
          </Button>
          </div>
        </form>
        </div>
        </div>
        // :
        // <>
        // <Grid item xs={12}>
        // <div className="card_added_success" data-test="add_card_success">
        //   <span><img alt="green_tick" src={tick_green}/> Card Added Successfully. 
        //   </span> 
        // </div>
        // </Grid>
        //  <Grid item xs={12} className="billing_form_field">
        //  <div className="add_company_action">
        //    <Button
        //      data-test="cancel"
        //      className="cancel_btn white-btn"
        //      variant="outlined"
        //      onClick={props.cancelBillingConfig}
        //    >
        //      Cancel
        //    </Button>
        //    <Button
        //    data-test="save_config"
        //      className="ok_btn green-btn"
        //      type="submit"
        //      variant="contained"
        //      disabled={props.disableSave || props.submittedSave?true:false}
        //      onClick={props.onSubscriptionSubmit}
        //    >
        //      Save
        //      {props.submittedSave?<CircularProgress size={20}/>:""}
        //    </Button>
        //  </div>
        // </Grid>
        // </>
    //   </DialogContent>
    // </Dialog>
  );
};

export default CreditCardComponent;
