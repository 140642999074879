import React, { Component } from "react";
import { Route, Router, withRouter, Switch } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import LoginComponent from "./Components/LoginComponent/LoginComponent";
import CompanyComponent from "./Components/CompanyComponent/CompanyComponent";
import NewCompanyComponent from "./Components/CompanyComponent/NewCompanyComponent";
import CompanyDetailsComponent from "./Components/CompanyComponent/CompanyDetailsComponent";
import CalendarComponent from "./Components/CalendarComponent/CalendarComponent";
import AccountsComponent from "./Components/AccountsComponent/AccountsComponent";
import LevelsComponent from "./Components/LevelsComponent/LevelsComponent";
import AccountDetailsComponent from "./Components/AccountsComponent/AccountDetails/AccountDetailsComponent";
import AccountsSuperadminComponent from "./Components/AccountsComponent/AccountsSuperadminComponent";
import EmployeeDetailsComponent from "./Components/EmployeeComponent/EmployeeDetails/EmployeeDetailsComponent";
import SignupComponent from "./Components/SignupComponent/SignupComponent";
import AddEmployeeModal from "./Components/EmployeeComponent/AddEmployeeModal";
import * as action from "./Store/Actions";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import TeamComponent from "./Components/EmployeeComponent/TeamComponent";
import SchedulerComponent from "./Components/SchedulerComponent/SchedulerComponent";
import ProductCategories from "./Components/ProductCategories/ProductCategories";
import ServicesComponent from "./Components/ServicesComponent/ServicesComopnent";
import TaskComponent from "./Components/TaskComponent/TaskComponent";
import DashboardComponent from "./Components/DashboardComponent/DashboardComponent";
import PageNotFoundComponent from "./Components/PageNotFoundComponent/PageNotFoundComponent";
import ResetPasswordComponent from "./Components/LoginComponent/ResetPasswordComponent/ResetPasswordComponent";
import TaskDetailsComponent from "./Components/TaskComponent/TaskDetails/TaskDetailsComponent";
import PendingRegistrationsComponent from "./Components/PendingRegistrationsComponent/PendingRegistrationsComponent"
import ProductComponent from "./Components/ProductComponent/ProductComponent";
import MapViewComponent from "./Components/MapComponent/MapViewComponent/MapViewComponent";
import ReportsGraphComponent from "./Components/ReportsGraphComponent/ReportsGraphComponent";
import PendingInviteAccountComponent from "./Components/AccountsComponent/PendingInviteAccountComponent";
import EventDetailComponent from "./Components/EventComponent/EventDetailComponent/EventDetailComponent";
import EventTypesComponent from "./Components/EventTypesComponent/EventTypesComponent"
import AdminManagerDashboardComponent from "./Components/AdminManagerDashboardComponent/AdminManagerDashboardComponent";
import SuperAdminDashboardComponent from "./Components/SuperAdminDashboardComponent/SuperAdminDashboardComponent";
import BillingConfigComponent from './Components/BillingComponent/BillingConfigComponent/BillingConfigComponent';
// fonts
import "../src/Assets/fonts/stylesheet.css";
import LoadingComponent from "./SharedComponents/LoadingComponent/LoadingComponent";
import BillingComponent from "./Components/BillingComponent/BillingComponent";
import EmailConfirmationComponent from "./Components/EmailConfirmationComponent/EmailConfirmationComponent";
import EmailConfirmationFailComponent from "./Components/EmailConfirmationComponent/EmailConfirmationFailComponent";
import ManageAccountComponent from "./Components/ManageAccountComponent/ManageAccountComponent";
import CancelSubscriptionComponent from "./Components/CancelSubscriptionComponent/CancelSubscriptionComponent";
import TimezoneTextComponent from "./SharedComponents/TimezoneComponent/TimezoneTextComponent";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: localStorage.getItem("userdata")
        ? JSON.parse(localStorage.getItem("userdata"))
        : "",
      showPopup: false
    };
  }
  componentDidMount() {
    this.props.authCheck();
    setTimeout(() => {
      this.setState({
        showPopup: true
      })
    }, 2000)
  }
  render() {
    const { userData } = this.state;
    return (
      <Router history={this.props.history}>
        <Switch>
          <Route path="/" exact component={LoginComponent} />
          <Route path="/signup/:id" component={SignupComponent} />
          <AuthGuard
            path="/user/add"
            isLoggedIn={this.props.isAuthenticated}
            component={AddEmployeeModal}
          />
          <AuthGuard
            path="/billing"
            isLoggedIn={this.props.isAuthenticated}
            component={BillingComponent}
          />
          <Route path="/event/mail/accept/:id" exact component={EmailConfirmationComponent} />
          <Route path="/event/mail/reject/:id" exact component={EmailConfirmationFailComponent} />

          <AuthGuard path="/user" exact isLoggedIn={this.props.isAuthenticated} component={TeamComponent} />
          <AuthGuard path="/user/:id" isLoggedIn={this.props.isAuthenticated} component={EmployeeDetailsComponent} />
          <AuthGuard path="/map" isLoggedIn={this.props.isAuthenticated} component={MapViewComponent} />
          <AuthGuard path="/eventdetail/:id" exact isLoggedIn={this.props.isAuthenticated} component={EventDetailComponent} />
          <Route path="/resetpassword/:id" component={ResetPasswordComponent} />
          <AuthGuard
            path="/account-details"
            isLoggedIn={this.props.isAuthenticated}
            component={AccountDetailsComponent}
          />
          <AuthGuard
            path="/product-category"
            isLoggedIn={this.props.isAuthenticated}
            component={ProductCategories}
          />

          <AuthGuard
            path="/event-types"
            isLoggedIn={this.props.isAuthenticated}
            component={EventTypesComponent}
          />
          <AuthGuard
            path="/products"
            isLoggedIn={this.props.isAuthenticated}
            component={ProductComponent}
          />
          <AuthGuard
            path="/profile"
            isLoggedIn={this.props.isAuthenticated}
            component={ManageAccountComponent}
          />
          <AuthGuard
            path="/pending-registration"
            isLoggedIn={this.props.isAuthenticated}
            component={PendingRegistrationsComponent}
          />
          <AuthGuard
            path="/company"
            isLoggedIn={this.props.isAuthenticated}
            component={CompanyComponent}
          />
          <AuthGuard
            path="/new-company"
            isLoggedIn={this.props.isAuthenticated}
            component={NewCompanyComponent}
          />
          <AuthGuard
            path="/company-details"
            isLoggedIn={this.props.isAuthenticated}
            component={CompanyDetailsComponent}
          />
          <AuthGuard
            path="/schedule-task"
            isLoggedIn={this.props.isAuthenticated}
            component={SchedulerComponent}
          />
          <AuthGuard
            path="/reports"
            isLoggedIn={this.props.isAuthenticated}
            component={ReportsGraphComponent}
          />
          <AuthGuard
            path="/calendar"
            exact
            isLoggedIn={this.props.isAuthenticated}
            component={CalendarComponent}
          />
          {/* <AuthGuard
            path="/subscription"
            isLoggedIn={this.props.isAuthenticated}
            component={CancelSubscriptionComponent}
          /> */}
          {this.state.userData &&
            (this.state.userData.role.toLowerCase() === "super admin" ||
              this.state.userData.role.toLowerCase() === "managers" ||
              this.state.userData.role.toLowerCase() === "manager") ? (
            ""
          ) : (
            <AuthGuard
              path="/services"
              isLoggedIn={this.props.isAuthenticated}
              component={ServicesComponent}
            />
          )}
          {this.state.userData && this.state.userData.role.toLowerCase() === "super admin" ? (
            ""
          ) : (
            <AuthGuard
              path="/task"
              exact
              isLoggedIn={this.props.isAuthenticated}
              component={TaskComponent}
            />
          )}
          {this.state.userData && this.state.userData.role.toLowerCase() === "super admin" ? (
            ""
          ) : (
            <AuthGuard
              path="/task/:id"
              isLoggedIn={this.props.isAuthenticated}
              component={TaskDetailsComponent}
            />
          )}
          {this.state.userData && (
            this.state.userData.role.toLowerCase() === "area manager"
            || this.state.userData.role.toLowerCase() === "division manager"
            || this.state.userData.role.toLowerCase() === "admin") ?
            <AuthGuard
              path="/dashboard"
              isLoggedIn={this.props.isAuthenticated}
              component={AdminManagerDashboardComponent}
            />
            : this.state.userData && this.state.userData.role.toLowerCase() === "super admin" ?
              <AuthGuard
                path="/dashboard"
                isLoggedIn={this.props.isAuthenticated}
                component={SuperAdminDashboardComponent}
              />
              : this.state.userData && (this.state.userData.role.toLowerCase() === "team lead" || this.state.userData.role.toLowerCase() === "sales rep") ? <AuthGuard
                path="/dashboard"
                isLoggedIn={this.props.isAuthenticated}
                component={DashboardComponent}
              /> : <LoadingComponent />}
          {this.state.userData && this.state.userData.role.toLowerCase() == "admin" ? (
            <AuthGuard
              path="/org-structure"
              isLoggedIn={this.props.isAuthenticated}
              component={LevelsComponent}
            />
          ) : (
            ""
          )}
          {this.state.userData && this.state.userData.role.toLowerCase() === "super admin" ? (
            <AuthGuard
              path="/account"
              exact
              isLoggedIn={this.props.isAuthenticated}
              component={AccountsSuperadminComponent}
            />

          ) : (
            <>
              <AuthGuard
                path="/account"
                exact
                isLoggedIn={this.props.isAuthenticated}
                component={AccountsComponent}
              />
              <AuthGuard
                path="/pending-account"
                exact
                isLoggedIn={this.props.isAuthenticated}
                component={PendingInviteAccountComponent}
              />
            </>
          )}
          <Route component={PageNotFoundComponent} />
        </Switch>
        {this.state.showPopup == true && this.state.userData && this.state?.userData?.role?.toLowerCase() !== "super admin" && <TimezoneTextComponent />}
      </Router>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? true : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authCheck: () => dispatch(action.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
