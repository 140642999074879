import React from 'react'
import { withStyles } from '@material-ui/core/styles';
 import closeBtn from "../../Assets/images/close_btn.svg";
import IconButton from '@material-ui/core/IconButton';
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    Select,
    Tooltip,
    CircularProgress,
    DialogContentText,
} from "@material-ui/core";
import { TransitionGrow } from '../CommonUtilities/CommonUtilities';
const { Option, OptGroup } = Select;
const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#14193A',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E2E5F2',
                borderWidth: 1
            },
            '&.Mui-focused fieldset': {
                borderColor: '#14193A',
                borderWidth: 1
            },
        },
    },
})(TextField, Select);
/**
   * @author Shashi
   */
const RejectModal = props => {
    return (
        <div >
            <div>
                <Dialog  {...props.modelData}
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="form-dialog-title" 
                    maxWidth='md' 
                    className=" add_company_dialog add_account_dialog reject_company_modal"
                    TransitionComponent={TransitionGrow} 
                    transitionDuration={600}>
                    <DialogTitle id="form-dialog-title" className="add_user_header">
                        <span>Reject Invite</span>
                        <IconButton onClick={props.handleClose} className="closeBtn" aria-label="show 17 new notifications" color="inherit">
                            <img src={closeBtn}></img>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className="reject_text">
                        </DialogContentText>
                        <label className="label bl-label">Note: <span className="labelAsterisk">*</span></label>
                        <CssTextField className="text_area" multiline rowsMax={3} variant="outlined" margin="dense" fullWidth name='description' placeholder="Add a note" onChange={props.adddeshandleChange} />
                        {props.modelData.descriptioneror.error !== "" ?
                            <span className="error"> {props.modelData.descriptioneror.error}</span> : ""}
                        <div className="add_company_action">
                            <Button className="cancel_btn white-btn" onClick={props.handleClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button disabled={props.modelData.isSubmit} className="delete red-btn" variant="contained" onClick={props.onSubmitReject} >
                                {props.modelData.isSubmit ? <CircularProgress size={20} /> : ''}  Reject
                             </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    );
};
export default RejectModal;