import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField ,Button, withStyles} from "@material-ui/core";
import close_icon_red from "../../../Assets/img/close_icon_red.svg";
import editIcon from "../../../Assets/images/view_edit_icon.svg";
import Delete from "../../../Assets/images/delete.svg";
import change_parent from "../../../Assets/images/change_parent.png";

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField);
const ChangeParentComponent = (props) => {
  return (
    <div className="info-card-detail">
      {/* <p>Parent Account:</p> */}
      {props.hasParent && !props.showChangeParent ? (
        <>
        <div className="info-card-detail-left">
          <img src={change_parent} alt="icons" className="info-card-img-align-start"/>
          <span className="custom-span">{props.parentAccount.accountName}</span>
        </div>
         <div style={{display:"flex",alignItems:"center"}}>
          <img
            alt="close"
            src={editIcon}
            style={{ cursor: "pointer" }}
            className="edit_parent_icon"
            onClick={() => props.editInput()}
          />
          <img
          alt="delete"
          src={Delete}
          onClick={() => props.deleteParent()}
          style={{ cursor: "pointer",height: "14px" }}
          />
        </div>
        </>
      ) : !props.hasParent && props.showChangeParent ? (
        <div className={props.showChangeParent === false ? "hide" : ""} className="edit_autocomplete_parent">
          {/* <label>Parent Account Name</label> */}
          <div className="info-card-detail-left">
          <img src={change_parent} alt="icons" className="info-card-img-align-start"/>
          <Autocomplete
            style={{ width: 300 }}
            id="parentName"
            freeSolo
            className="parent-autocomplete"
            options={props.parentOption}
            getOptionLabel={(option) => option.name}
            onInputChange={props.autocompleteClose}
            onFocus={props.onFocusComplete}
            renderInput={(params) => (
              <CssTextField
                {...params}
                variant="outlined"
                name="parentName"
                placeholder="Search Parent Account"
                onChange={props.parentListHandler}
                onSelect={props.onSelectHandler}
              />
            )}
          />
          <img
            alt="close"
            src={close_icon_red}
            style={{ cursor: "pointer" }}
            onClick={() => props.editInputClose()}
          />
          </div>
        </div>
      ) : !props.hasParent && !props.showChangeParent ? (
        // <button type="button" onClick={props.onChangeParentHandler}>
        //   Change Parent
        // </button>
        <div className="info-card-detail-left">
        <img src={change_parent} alt="icons" className="info-card-img-align-start"/>
                    <Button
                    variant="contained"
                    className="tableaddBtn grey-btn"
                    color="default"
                    style={{ boxShadow: "none" }}
                    onClick={props.onChangeParentHandler}
                  >
                   Change Parent
                </Button>
                </div>
      ) : props.hasParent && props.showChangeParent ? (
        <div className={props.showChangeParent === false ? "hide" : ""} className="edit_autocomplete_parent">
          {/* <label>Parent Account Name</label> */}
         <div className="info-card-detail-left">
          <img src={change_parent} alt="icons" className="info-card-img-align-start"/>
          <Autocomplete
            style={{ width: 300 }}
            id="parentName"
            freeSolo
            className="parent-autocomplete new_autocomplete"
            options={props.parentOption}
            getOptionLabel={(option) => option.name}
            onInputChange={props.autocompleteClose}
            onFocus={props.onFocusComplete}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="parentName"
                placeholder="Search Parent Account"
                onChange={props.parentListHandler}
                onSelect={props.onSelectHandler}
              />
            )}
          />
           <img
            alt="close"
            src={close_icon_red}
            style={{ cursor: "pointer" }}
            onClick={() => props.editInputClose()}
          />
          </div>
         
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ChangeParentComponent;
