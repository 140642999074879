import React from "react";
import { Grid, Button, MenuItem,Select,FormControl,InputAdornment } from "@material-ui/core";
import { CssTextField } from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import editIcon from "../../../Assets/images/view_edit_icon.svg";
import close_icon_red from "../../../Assets/img/close_icon_red.svg";
import carret_down from "../../../Assets/images/carret_down.svg";
import { DatePicker } from "antd";
import moment from "moment";
import momentTimeZone from 'moment-timezone';


const { RangePicker } = DatePicker;
const BillingDetailComponent = (props) => {
  return (
    <div className="billing-container">
      <div className="header-comp billing-header">
        <div>
          <h3 className="comp-name billing-detail-heading">Billing Detail</h3>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <div className="comp-contact">
            <h5 className="contact-title">Billing Type:</h5>
            <div
              className="contact-content"
              onMouseEnter={() => props.showButton("billingTypeEdit")}
              onMouseLeave={() => props.hideButton("billingTypeEdit")}
            >
              {props.isBillingType && (
                <span className="custom-span">{
                  props.detail.billingType === 1?'Organizational'
                                  : props.detail.billingType === 2?'Team'
                                  : props.detail.billingType === 3?'Individual':""}</span>
              )}
              {!props.billingTypeEdit && props.isBillingType && (
                <img
                  src={editIcon}
                  alt="edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.editInput("isBillingType")}
                />
              )}
              {!props.isBillingType && (
                <span className="edit-input">
                  
                  <FormControl variant="outlined" fullWidth margin="dense">
                  <Select
                    margin="dense"
                    className="customSelect"
                    placeholder="Select billing type"
                    name="billingConfigType"
                    fullWidth
                    onChange={props.onChangeHandler}
                    defaultValue={props.billingType === 1 || props.billingType === '1'?'1'
                                  : props.billingType === 2 || props.billingType === '2'?'2'
                                  : props.billingType === 3 || props.billingType === '3'?'3':""}
                  >
                    <MenuItem value="1">
                      Organizational
                    </MenuItem>
                    <MenuItem value="2">Team</MenuItem>
                    <MenuItem value="3">Individual</MenuItem>
                  </Select>
                </FormControl>
                  <Button
                    className="add_more_btn"
                    onClick={() => props.updateHandler("isBillingType")}
                    disabled={props.billingTypeDisable?false:true}
                  >
                    Save
                  </Button>
                  {!props.isBillingType && (
                    <img
                      src={close_icon_red}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => props.editInput("isBillingType")}
                    />
                  )}
                </span>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div
            className="comp-contact"
            onMouseEnter={() => props.showButton("pricePerUserEdit")}
            onMouseLeave={() => props.hideButton("pricePerUserEdit")}
          >
            <h5 className="contact-title">Pricing/User:</h5>
            <div className="contact-content">
              {props.isPricePerUser && (
                <span className="custom-span">${props.detail && props.detail.pricePerUser?props.detail.pricePerUser:0}</span>
              )}
              {!props.pricePerUserEdit && props.isPricePerUser && (
                <img
                  src={editIcon}
                  alt="edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.editInput("isPricePerUser")}
                />
              )}
              {!props.isPricePerUser && (
                <span className="edit-input">
                  <CssTextField
                    id="outlined-basic"
                    variant="outlined"
                    name="pricePerUser"
                    className="comp_name"
                    autoFocus
                    placeholder="Enter price per user"
                    type="text"
                    value={props.pricePerUser}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="dollar_symbol">$</InputAdornment>
                      ),
                    }}
                    onChange={props.onChangeHandler}
                  />
                  <Button
                    className="add_more_btn"
                    onClick={() => props.updateHandler("isPricePerUser")}
                    disabled={props.isPriceDisable?false:true}
                  >
                    Save
                  </Button>
                  {!props.isPricePerUser && (
                    <img
                      src={close_icon_red}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => props.editInput("isPricePerUser")}
                    />
                  )}
                </span>

              )}
            </div>
            <div className="modal-err-msg">{props.formError.pricePerUser !== ""?props.formError.pricePerUser:""}</div>
          </div>
        </Grid>

        {props.detail && props.detail.trial?
        <Grid item xs={12} sm={12} md={6}>
          <div
            className="comp-contact trail_period_section"
            onMouseEnter={() => props.showButton("billingDateEdit")}
            onMouseLeave={() => props.hideButton("billingDateEdit")}
          >
            <h5 className="contact-title">Trial Period:</h5>
            <div className="contact-content">
              {props.isBillingDate && (
                <div className="trail_field">
                <span className="custom-span">{momentTimeZone(props.billingsTialDates[0]).format("MM/DD/YYYY")} to {momentTimeZone(props.billingsTialDates[1]).format("MM/DD/YYYY")}</span>
                <span className="remaining_days">{props.billingTrialDays?props.billingTrialDays:0} days</span>
                 </div> 
              )}
              {!props.billingDateEdit && props.isBillingDate && (
                <img
                  src={editIcon}
                  alt="edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.editInput("isBillingDate")}
                />
              )}
              {!props.isBillingDate && (
                <span className="edit-input">
                    <div className="trail_field">
                      <RangePicker
                        className="filter_date_range "
                        name="datepicker"
                        format="MM/DD/YYYY"
                        allowClear={false}
                        inputReadOnly={true}
                        separator="to"
                        value={[
                          moment(props.billingsTialDates[0], "MM/DD/YYYY"),
                          moment(props.billingsTialDates[1], "MM/DD/YYYY"),
                        ]}
                        disabledDate={props.valid}
                        placeholder={["MM/DD/YYYY", "MM/DD/YYYY"]}
                        suffixIcon={
                          <img
                            className="carret"
                            alt="carret"
                            src={carret_down}
                          />
                        }
                        getPopupContainer={(node) => node.parentNode}
                        onChange={props.onDateRangeChanage}
                      />
                      <span className="remaining_days">{props.billingTrialDays?props.billingTrialDays:0} days</span>
                    </div>
                    <span>
                  <Button
                    className="add_more_btn"
                    onClick={() => props.updateHandler("isBillingDate")}
                    disabled={props.billingsDatesDisable?false:true}
                  >
                    Save
                  </Button>
                  {!props.isBillingDate && (
                    <img
                      src={close_icon_red}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => props.editInput("isBillingDate")}
                    />
                  )}
                  </span>
                </span>
              )}
            </div>
          </div>
        </Grid>:""}
      </Grid>
    </div>
  );
};

export default BillingDetailComponent;
