import React, { Component, Suspense } from "react";
import Grid from "@material-ui/core/Grid";
import "./SuperAdminDashboardComponent.css";
import momentTimeZone from "moment-timezone";
import search_icon from "../../Assets/img/search_icon.png";
import userIcon from "../../Assets/images/user-icon.png";
import no_company_logo from "../../Assets/img/no_company_logo.png";
import { AxiosInstance, checkTimeZone } from "../../Utilities";
import { withRouter, Redirect } from 'react-router-dom';
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import carret_down from "../../Assets/images/carret_down.svg";
import { upperFirst } from "lodash";
import { fetchDateFilterData } from "../../SharedComponents/CommonUtilities/CommonUtilities";
// import { Autocomplete } from "@material-ui/lab";
import { AutoComplete } from 'antd';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { connect } from "react-redux";
import moment from 'moment';
import jwt from "jsonwebtoken";
import { isSafari } from "react-device-detect";
const { Option } = AutoComplete;

var CommonLineGraphComponent = React.lazy(() =>
  import("../../SharedComponents/ChartsComponent/CommonLineGraphComponent/CommonLineGraphComponent")
);
var CommonSemiCircleGraphComponent = React.lazy(() =>
  import("../../SharedComponents/ChartsComponent/CommonSemiCircleGraphComponent/CommonSemiCircleGraphComponent")
);
var CommonPieChartComponent = React.lazy(() =>
  import("../../SharedComponents/ChartsComponent/CommonPieChartComponent/CommonPieChartComponent")
);
var PendingRegistrationListComponent = React.lazy(() =>
  import("./PendingRegistrationListComponent/PendingRegistrationListComponent")
);
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent")
);

// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
var CommonSummaryComponent = React.lazy(() =>
  import("../../SharedComponents/CommonSummaryComponent/CommonSummaryComponent")
);

class SuperAdminDashboardComponent extends Component {
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var dateFilterArray = [
      { _id: "", name: "This Week" },
      { _id: "last7days", name: "Last 7 Days" },
      { _id: "month", name: "Last 30 Days" },
      { _id: "quarter", name: "This Quarter" },
      { _id: "year", name: "This Year" },
    ];
    var zoneName = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    this.state = {
      loggedInData: userData,
      timeZoneName: zoneName,
      datePickerData: null,
      userSearchValue: "",
      summarySelected: "today",
      summaryFilterName: "",
      summaryCompanyFilterName: "",
      summaryCompanyFilterId: "",
      noOfActiveFilterName: "",
      noOfTaskFilterName: "",
      noOfLoginFilterName: "",
      summaryCompanyReportFilterName: "",
      companyFilter: {
        data: [],
        anchorElCompany: null,
        autocompletedProps: {
          id: "company_filter_control",
          companySearchValue: ""
        }
      },
      summary_date: {
        id: "date_filter_control",
        anchorElDateFilter: null,
        summaryDate: dateFilterArray,
      },
      summary_company_date: {
        id: "company_report_filter_control",
        anchorElCompanyDateFilter: null,
        summaryDate: dateFilterArray,
      },
      no_of_task: {
        id: "no_of_task_filter_control",
        anchorElNoOfTask: null,
        summaryDate: dateFilterArray,
      },
      no_of_login: {
        id: "no_of_login_filter_control",
        anchorElNoOfLogin: null,
        summaryDate: dateFilterArray,
      },
      no_of_active_account: {
        id: "no_of_active_filter_control",
        anchorElNoOfActive: null,
        summaryDate: dateFilterArray,
      },
      tableHeader: [
        { name: 'HOSPITAL', styles: { width: '25%' } },
        { name: 'NAME', styles: { width: '20%' } },
        { name: 'PHONE', styles: { width: '25%' } },
        { name: 'EMAIL', styles: { width: '30%' } }],
      pendingRegistration: [],
      taskSummaryData: {},
      companyReportData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          },
        },
        config: {
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.2f} hr</b>`
        },
        startDate:moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate:moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
       
        //  momentTimeZone()
        //   .startOf("week")
        //   .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
        //   .format("YYYY-MM-DD 00:00:00"),
        //  momentTimeZone()
        //   .endOf("week")
        //   .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
        //   .format("YYYY-MM-DD 23:59:59"),
      },

      activeAccountData: {
        yAxis: [],
        average: 0
      },
      noOfTaskCreated: {
        xAxis: [],
        yAxis: [],
        startDate:moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate:moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone()
        //   .startOf("week")
        //   .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
        //   .format("YYYY-MM-DD 00:00:00"),
        // endDate: momentTimeZone()
        //   .endOf("week")
        //   .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
        //   .format("YYYY-MM-DD 23:59:59"),
      },
      loginCountReports: {
        xAxis: [],
        yAxis: [],
        startDate:moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate:moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
        // startDate: momentTimeZone()
        //   .startOf("week")
        //   .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
        //   .format("YYYY-MM-DD 00:00:00"),
        // endDate: momentTimeZone()
        //   .endOf("week")
        //   .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
        //   .format("YYYY-MM-DD 23:59:59"),
      },
      companyQuickAccessArray: [],
      companyQuickAccessValue: [],
      userQuickAccessArray: [],
      userQuickAccessValue: [],
      accountQuickAccessArray: [],
      accountQuickAccessValue: [],
      tooltipprops: {}
    };
  }

  componentWillMount() {
    
    this.getTimeZones();
    // window.scrollTo(0, 0);
    const start =moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
    //  moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
    const end =  moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
    // moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
    this.fetchDashboardCard({
      startDate:start,
      endDate: end,
      companyId: this.state.summaryCompanyFilterId
    });
    this.fetchPendingRegistrationList();
    this.fetchCompanyFilter("", "filter");
    this.fetchCompantPiChartData({
      startDate: this.state.companyReportData.startDate,
      endDate: this.state.companyReportData.endDate
    });
    this.fetchActiveAccountData();
    this.fetchNoOfTaskCreated({
      startDate: this.state.noOfTaskCreated.startDate,
      endDate: this.state.noOfTaskCreated.endDate
    });
    this.fetchLoginCountReports({
      startDate: this.state.loginCountReports.startDate,
      endDate:this.state.loginCountReports.endDate
    })
    let obj = {}
    if (isSafari) {
      obj.open = false;
      this.setState({ tooltipprops: obj })
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  getTimeZones=()=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user","application/json")
    .get("/employee/timezone")
    .then(resp=>{
      var response = resp && resp.data;
      if(response && (response.status === "200" || response.status === 200)){
        this.setState({
          timeZoneName:response.data.timezone?response.data.timezone:momentTimeZone.tz.guess(),
        });
        localStorage.setItem("timeZone",response.data.timezone?response.data.timezone:momentTimeZone.tz.guess());
        checkTimeZone();
      }else if(response && (response.status === "400" || response.status === 400)){

      }
    }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }

  fetchDashboardCard = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post("/report/superadmin/dashboardCards", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            taskSummaryData: response.data
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // this.commonNotificationFunction(response.msg,iconNotificationError,"error","error-notify");
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }

  fetchPendingRegistrationList = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/json")
      .post("/pendingAccount")
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          this.setState({
            pendingRegistration: response.data
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }

  fetchCompanyFilter = (search, filter) => {
    console.log(search)
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .get("/report/superadmin/companyFilter", { params:search})
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          if (filter === "filter") {
            const { companyFilter } = this.state;
            var data = response.data;
            var newArray = companyFilter.data.splice();
            if (this.state.companyFilter.autocompletedProps.companySearchValue === "") {
              newArray.unshift({ _id: "", name: "All" });
            } else {
              newArray.shift({ _id: "", name: "All" });
            }
            if (data.length > 0) {
              data.map(item => {
                var obj = {};
                obj._id = item._id;
                obj.name = item.companyName;
                newArray.push(obj);
              });
            }
            companyFilter.data = newArray;
            this.setState({
              companyFilter
            });
          } else if (filter === "quickfilter") {
            var dataResp = response.data;
            this.setState({
              companyQuickAccessArray: dataResp
            })
          }
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // this.commonNotificationFunction( response.msg,iconNotificationError,"error","error-notify");
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }

  fetchUserQuickAccess = (search) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .get("/report/superadmin/employeeFilter", {
        params:search
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var data = response.data;
          this.setState({
            userQuickAccessArray: data
          })
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // this.commonNotificationFunction(response.msg,iconNotificationError,"error","error-notify");
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }
  fetchAccountQuickAccess = (search) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .get("/report/superadmin/accountFilter", {
        params:search
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var data = response.data;
          this.setState({
            accountQuickAccessArray: data
          })
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // this.commonNotificationFunction(response.msg,iconNotificationError,"error","error-notify");
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }
  fetchCompantPiChartData = (data) => {
    console.log(data)
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .get(`/report/superadmin/companyReport/${data.startDate}/${data.endDate}`)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { companyReportData } = this.state;
          // RepChartData.xAxis = response.data.xaxis;
          companyReportData.yAxis = response.data;
          this.setState({
            companyReportData,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // this.commonNotificationFunction(response.msg,iconNotificationError,"error","error-notify");
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });

  }

  fetchActiveAccountData = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .post("/report/superadmin/activeAccountReport", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { activeAccountData } = this.state;
          // RepChartData.xAxis = response.data.xaxis;
          activeAccountData.yAxis = response.data;
          activeAccountData.average = response.average;
          this.setState({
            activeAccountData,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // this.commonNotificationFunction(response.msg,iconNotificationError,"error","error-notify");
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }
  fetchNoOfTaskCreated = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .get(`/report/superadmin/taskReports/${data.startDate}/${data.endDate}`)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { noOfTaskCreated } = this.state;
          noOfTaskCreated.xAxis = response.data.xaxis;
          noOfTaskCreated.yAxis = response.data.yaxis;
          this.setState({
            noOfTaskCreated,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // this.commonNotificationFunction(response.msg,iconNotificationError,"error","error-notify");
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }
  fetchLoginCountReports = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .get(`/report/superadmin/loginReports/${data.startDate}/${data.endDate}`, data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { loginCountReports } = this.state;
          loginCountReports.xAxis = response.data.xaxis;
          loginCountReports.yAxis = response.data.yaxis;
          this.setState({
            loginCountReports,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // this.commonNotificationFunction(response.msg,iconNotificationError,"error","error-notify");
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }

  viewAllPendingRegistration = () => {
    this.props.history.replace('/pending-registration');
  }
  onTodayHandler = () => {
    const start = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
    const end = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
    let data = {
      startDate: start,
      endDate: end,
      companyId: this.state.summaryCompanyFilterId
    };
    this.setState({
      summarySelected: "today",
      summaryFilterName: ""
    });
    this.fetchDashboardCard(data);
  };
  onThisWeekHandler = () => {
    var toDay = momentTimeZone().tz(this.state.timeZoneName);
    // const start = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
    // const end = moment().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
    this.setState({
      summarySelected: "weeks",
    });
    let data = {
      startDate: moment.tz(momentTimeZone(moment().startOf("week")._i).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
      // momentTimeZone(momentTimeZone().format('YYYY-MM-DD 00:00:00')).utc(),
      endDate:moment.tz(momentTimeZone(moment().endOf("week")._i).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
      //  momentTimeZone(momentTimeZone(toDay.endOf("week")._d).format("YYYY-MM-DD 23:59:59")).utc(),
    };
    this.fetchDashboardCard(data);
  };
  onDateFilterOpen = (event) => {
    this.setState({
      summarySelected: "",
    });
    this.setCustomFilterOpenClose(
      "summary_date",
      "anchorElDateFilter",
      event.currentTarget
    );
  };

  onCompanyFilterOpen = (event) => {
    const { companyFilter } = this.state;
    companyFilter.autocompletedProps.companySearchValue = "";
    this.setState({
      summarySelected: "",
      companyFilter
    });
    this.setCustomFilterOpenClose(
      "companyFilter",
      "anchorElCompany",
      event.currentTarget
    );
  }
  onCompanyDateFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "summary_company_date",
      "anchorElCompanyDateFilter",
      event.currentTarget
    );
  }
  onNoOfTaskFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "no_of_task",
      "anchorElNoOfTask",
      event.currentTarget
    );
  }
  onNoOfTaskFilterClose = () => {
    this.setCustomFilterOpenClose(
      "no_of_task",
      "anchorElNoOfTask",
      null
    );
  }
  onNoOfLoginFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "no_of_login",
      "anchorElNoOfLogin",
      event.currentTarget
    );
  }
  onNoOfLoginFilterClose = () => {
    this.setCustomFilterOpenClose(
      "no_of_login",
      "anchorElNoOfLogin",
      null
    );
  }
  onNoOfActiveFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "no_of_active_account",
      "anchorElNoOfActive",
      event.currentTarget
    );
  }
  onNoOfActiveFilterClose = () => {
    this.setCustomFilterOpenClose(
      "no_of_active_account",
      "anchorElNoOfActive",
      null
    );
  }
  onCompanyFilterClose = () => {
    this.setCustomFilterOpenClose(
      "companyFilter",
      "anchorElCompany",
      null
    );
  }
  onCompanyDateFilterClose = () => {
    this.setCustomFilterOpenClose(
      "summary_company_date",
      "anchorElCompanyDateFilter",
      null
    );
  }

  onDateFilterClose = () => {
    this.setCustomFilterOpenClose("summary_date", "anchorElDateFilter", null);
  };

  setCustomFilterOpenClose = (stateName, anchorField, event) => {
    var field = this.state[stateName];
    field[anchorField] = event;
    this.setState({
      [stateName]: field,
    });
  };

  onNoOfActiveFilterClick = async (item) => {
    var { no_of_active_account } = this.state;
    no_of_active_account.anchorElNoOfActive = null;
    var data = await fetchDateFilterData(item, this.state.timeZoneName);
    // var apiData={
    //   date:data
    // }
    this.fetchActiveAccountData({
      startDate: data.startDate,
      endDate: data.endDate
    })
    this.setState({
      no_of_active_account,
      noOfActiveFilterName:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  }
  onDateFilter = async (item) => {
    var { summary_date } = this.state;
    summary_date.anchorElDateFilter = null;
    var data = {};
    if (item._id === "") {
      data.startDate = moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
      // momentTimeZone(momentTimeZone()
      //   .startOf("week")
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-MM-DD 00:00:00")).utc();
      data.endDate =moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
      //  momentTimeZone(momentTimeZone()
      //   .endOf("week")
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-MM-DD 23:59:59")).utc();
    } else if (item._id === "last7days") {
      data.startDate = moment.tz(momentTimeZone().subtract(7,"d").format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
      // momentTimeZone(momentTimeZone()
      //   .subtract(7, "d")
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-MM-DD 00:00:00")).utc();
      data.endDate =moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
      //  momentTimeZone(momentTimeZone()
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-MM-DD 23:59:59")).utc();
    } else if (item._id === "month") {
      data.startDate = moment.tz(momentTimeZone().subtract(30,"d").format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
      // momentTimeZone(momentTimeZone()
      //   .subtract(30, "d")
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-MM-01 00:00:00")).utc();
      data.endDate =moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
      //  momentTimeZone(momentTimeZone()
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-MM-DD 23:59:59")).utc();
    } else if (item._id === "quarter") {
      data.startDate = moment.tz(momentTimeZone().startOf("quarter").format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
      data.endDate =moment.tz(momentTimeZone().endOf("quarter").format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
     
      // data.startDate = momentTimeZone(momentTimeZone()
      //   .startOf("quarter")
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-MM-DD 00:00:00")).utc();
      // data.endDate = momentTimeZone(momentTimeZone()
      //   .endOf("quarter")
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-MM-DD 23:59:59")).utc();
    } else if (item._id === "year") {
      data.startDate =moment.tz(momentTimeZone().format('YYYY-01-01 00:00:00'), this.state.timeZoneName).utc();
      //  momentTimeZone(momentTimeZone()
      //   .tz(this.state.timeZoneName)
      //   .format("YYYY-01-01 00:00:00")).utc();
      data.endDate = moment.tz(momentTimeZone(moment().endOf("year")._d).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
      // momentTimeZone(momentTimeZone(momentTimeZone()
      //   .tz(this.state.timeZoneName)
      //   .endOf("year")._d).format("YYYY-MM-DD 23:59:59")).utc();
    }

    var datas = await fetchDateFilterData(item, this.state.timeZoneName);
    datas.companyId = this.state.summaryCompanyFilterId;
    this.fetchDashboardCard(datas);
    this.setState({
      summary_date,
      datePickerData: data,
      summaryFilterName:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  };

  onCompanyReportDateFilter = async (item) => {
    var { summary_company_date } = this.state;
    summary_company_date.anchorElCompanyDateFilter = null;
    var data = await fetchDateFilterData(item, this.state.timeZoneName);
    this.fetchCompantPiChartData(data);
    this.setState({
      summary_company_date,
      summaryCompanyReportFilterName:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  }
  onNoOfTaskFilterClick = async (item) => {
    var { no_of_task } = this.state;
    no_of_task.anchorElNoOfTask = null;
    var data = await fetchDateFilterData(item, this.state.timeZoneName);
    this.fetchNoOfTaskCreated(data);
    this.setState({
      no_of_task,
      noOfTaskFilterName:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  }
  onNoOfLoginFilterClick = async (item) => {
    var { no_of_login } = this.state;
    no_of_login.anchorElNoOfLogin = null;
    var data = await fetchDateFilterData(item, this.state.timeZoneName);
    this.fetchLoginCountReports(data);
    this.setState({
      no_of_login,
      noOfLoginFilterName:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  }
  companyClickHandler = (item) => {
    this.setState({
      summaryCompanyFilterName: item.name.toLowerCase() === 'all' ? "" : item.name,
      summaryCompanyFilterId: item._id
    })
    let data = {
      companyId: item._id
    };
    if (this.state.datePickerData !== null) {
      data.startDate = this.state.datePickerData.startDate;
      data.endDate = this.state.datePickerData.endDate;
    }
    this.fetchDashboardCard({
      data
    });
    this.setCustomFilterOpenClose(
      "companyFilter",
      "anchorElCompany",
      null
    );
  }
  onCompanySearchFilter = (e) => {
    const { value } = e.target;
    const { companyFilter } = this.state;
    companyFilter.autocompletedProps.companySearchValue = value;
    this.setState({
      companyFilter
    });
    this.fetchCompanyFilter({
      search: value
    }, "filter")
  }
  loginAsCompany = (id) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/json")
      .post("/superadmin/createAdminAccess", { companyId: id }).then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          localStorage.clear();
          localStorage.setItem("userdata", JSON.stringify(response.data));
          localStorage.setItem("token", response.token.split(' ')[1]);
          localStorage.setItem("switchedToSuperAdmin", true)
          setTimeout(() => {
            window.location.href = "/"
          }, 1500)
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }

      });
  }

  loginAsUser = (id) => {
    localStorage.removeItem("timeZone");
    localStorage.setItem('old_token', localStorage.getItem('token') ? localStorage.getItem('token') : "")
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6016 : 'report', "application/json")
      .get(`/report/superadmin/createUserAccess/${id}`)
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          // localStorage.clear();
          localStorage.setItem("userdata", JSON.stringify(response.data));
          localStorage.setItem("token", response.token.split(' ')[1]);
          const userData = JSON.parse(localStorage.getItem("userdata"))
          if(userData.role !== "Super Admin"){
            this.checkCmsData()
          } else{
            window.location.href = "/dashboard";
          }
          localStorage.setItem("switchedToSuperAdminFromUser", true)
          setTimeout(() => {
            window.location.href = "/"
          }, 1500)
        }
      })
      .catch(err => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        } else if (error && (error.status === "404" || error.status === 404)) {
          this.commonNotificationFunction("Something went wrong", iconNotificationError, "error", "error-notify");
        }
      });
  }

  checkCmsData = () =>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6008:'task',"application/x-www-form-urlencoded; charset=UTF-8")
    .get("/cms")
    .then((response) => {
      if(response.status === 200) {
        const cmsData = response.data.data[0]? response.data.data[0] :{}
        localStorage.setItem("cms",JSON.stringify(cmsData))
      } else{
        console.log("Something went wrong")
      }
    })
  }

  commonNotificationFunction = (msg, icon, NotificationType, classNames) => {
    let props = {
      message: msg,
      icon: <img src={icon} alt="notifcation icon" />,
      type: NotificationType,
      placement: "topRight",
      duration: 3,
      top: 200,
      className: `task_notification ${classNames}`,
    };
    NotificationComponent(props);
  }

  manageAccount = (id) => {
    this.props.history.replace(`/account-details/${id}`);
  }
  manageCompany = (id) => {
    this.props.history.replace(`/company-details/${id}`);
  }
  manageUser = (id) => {
    this.props.history.replace(`/user/${id}`);
  }

  onAutoCompleteUserSearch = (value) => {
    if (value.trim().length >= 3) {
      this.fetchUserQuickAccess({
        search: value
      });
    }
  }
  onAutoCompleteUserSelect = (value) => {
    var data = this.state.userQuickAccessArray.filter(item => {
      return item.name.toLowerCase() === value.toLowerCase();
    });
    this.setState({
      userQuickAccessValue: data
    })
  }

  onAutoCompleteCompanySearch = (value) => {
    if (value.trim().length >= 3) {
      this.fetchCompanyFilter({ search: value }, 'quickfilter');
    }
  }
  onAutoCompleteCompanySelect = (value) => {
    var data = this.state.companyQuickAccessArray.filter(item => {
      return item.companyName.toLowerCase() === value.toLowerCase();
    });
    this.setState({
      companyQuickAccessValue: data
    })
  }
  onAutoCompleteAccountSearch = (value) => {
    if (value.trim().length >= 3) {
      this.fetchAccountQuickAccess({
        search: value
      });
    }
  }
  onAutoCompleteAccountSelect = (value) => {
    var data = this.state.accountQuickAccessArray.filter(item => {
      return item.accountName.toLowerCase() === value.toLowerCase();
    });
    this.setState({
      accountQuickAccessValue: data
    })
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />
    } else {
      return (
        <React.Fragment>
          <div className="body-bg-color">
            {/* <Suspense fallback={<div></div>}> */}
            <HeaderComponent />
            {/* </Suspense> */}
            <div className="chart-conatiner">
              <div className="dashboard_task_filters">
                <label className="tableTitle">Task Summary</label>
                <div className="new_custom_filters">
                  <div className="new_custom_filter_div current-selected">
                    <span
                      className={`rightName ${this.state.summarySelected === "today" ? "selected" : ""
                        }`}
                      onClick={this.onTodayHandler}
                    >
                      Today
                  </span>
                  </div>
                  {/* <div className="new_custom_filter_div filter-option">
                  <span
                    onClick={this.onThisWeekHandler}
                    className={`rightName ${
                      this.state.summarySelected === "weeks" ? "selected" : ""
                      }`}
                  >
                    This Week
                  </span>
                </div> */}
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div filter-option"
                      onClick={this.onDateFilterOpen}
                    >
                      {this.state.summaryFilterName ? (
                        ""
                      ) : (
                          <label className="leftName">Filter By: </label>
                        )}
                      <label
                        aria-label="summary_date_filter"
                        aria-controls="date_filter_control"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.summaryFilterName
                          ? upperFirst(this.state.summaryFilterName)
                          : `This Week`}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectComponent
                      isAllRequired={true}
                      filterArray={this.state.summary_date.summaryDate}
                      closeCustomPopup={this.onDateFilterClose}
                      config={this.state.summary_date.id}
                      anchorEl={this.state.summary_date.anchorElDateFilter}
                      handleClick={this.onDateFilter}
                    />
                  </Suspense>
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div filter-option"
                      onClick={this.onCompanyFilterOpen}
                    >
                      {this.state.summaryCompanyFilterName ? (
                        ""
                      ) : (
                          <label className="leftName">Company: </label>
                        )}
                      <label
                        aria-label="summary_date_filter1"
                        aria-controls="company_filter_control"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.summaryCompanyFilterName
                          ? upperFirst(this.state.summaryCompanyFilterName)
                          : `All`}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>

                    <CustomSelectWithSearchComponent
                      filterArray={this.state.companyFilter.data}
                      config={this.state.companyFilter.autocompletedProps}
                      anchorEl={this.state.companyFilter.anchorElCompany}
                      searchValue={this.state.companyFilter.companySearchValue}
                      handleClick={this.companyClickHandler}
                      placeholder="Search Company"
                      closeCustomPopup={this.onCompanyFilterClose}
                      onSearchFilter={this.onCompanySearchFilter}
                    />
                  </Suspense>

                </div>
              </div>
              <Suspense fallback={<div></div>}>
                <CommonSummaryComponent
                  taskSummary={this.state.taskSummaryData}
                />
              </Suspense>
              {/* access */}
              <Grid container spacing={2} className="chart-section">
                <Grid item xs={12} md={4}>
                  <div className="access-section">
                    <div className="chart-header">
                      <h5 className="chart-title">Company Quick Access</h5>
                    </div>
                    <div className="chart-search">
                      <div className="search-box">
                        <AutoComplete
                          className="search-field"
                          onSearch={this.onAutoCompleteCompanySearch}
                          onSelect={this.onAutoCompleteCompanySelect}
                        >
                          {this.state.companyQuickAccessArray.map(item => (
                            <Option key={item._id} value={item.companyName}>{upperFirst(item.companyName)}</Option>
                          ))}
                        </AutoComplete>
                        <img className="search-icon" src={search_icon} alt="search" />
                      </div>
                      <label className="search-label">You can search the company by Name, Email address or Phone number.</label>
                    </div>
                    {this.state.companyQuickAccessValue.length > 0 ?
                      <div className="result-detial">
                        {this.state.companyQuickAccessValue.map(item => (
                          <>
                            <div className="result-header">
                              <img src={item.companyLogo ? `https://salesc2companyprofile.s3.amazonaws.com/${item.companyLogo}` : no_company_logo} alt="company logo" />
                              <h3 className="acc-name">{upperFirst(item.companyName)}</h3>
                            </div>
                            <div className="result-contact">
                              <div>
                                <label className="contact-label">Phone No:</label>
                                <label className="contact-detial"><a href={"tel:" + item.phone} rel="noopener noreferrer">{item.phone}</a></label>
                              </div>
                              <div>
                                <label className="contact-label">Company Size:</label>
                                <label className="contact-detial">{upperFirst(item.size)}</label>
                              </div>
                              <div>
                                <label className="contact-label">Email Address:</label>
                                <label className="contact-detial"><a href={"mailto:" + item.email} rel="noopener noreferrer">{item.email}</a></label>
                              </div>
                            </div>
                            <div className="result-actions">
                              <button className="default-btn primary-btn" onClick={() => this.manageCompany(item._id)}>Manage</button>
                              <button className="default-btn primary-btn" onClick={() => this.loginAsCompany(item._id)}>Login as {upperFirst(item.companyName)}</button>
                            </div>
                          </>
                        ))}
                      </div>
                      : ""}
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="access-section">
                    <div className="chart-header">
                      <h5 className="chart-title">User Quick Access</h5>
                    </div>
                    <div className="chart-search">
                      <div className="search-box">
                        <AutoComplete
                          className="search-field"
                          onSearch={this.onAutoCompleteUserSearch}
                          onSelect={this.onAutoCompleteUserSelect}
                        >
                          {this.state.userQuickAccessArray.map(item => (
                            <Option key={item._id} value={item.name}>{upperFirst(item.name)}</Option>
                          ))}
                        </AutoComplete>
                        <img className="search-icon" src={search_icon} alt="search" />
                      </div>
                      <label className="search-label">You can search the user by Name, Email address or Phone number.</label>
                    </div>
                    {this.state.userQuickAccessValue.length > 0 ?
                      <div className="result-detial">
                        {this.state.userQuickAccessValue.map(item => (
                          <>
                            <div className="result-header">
                              <img src={item.profilePath ? `https://salesc2userprofile.s3.amazonaws.com/${item.profilePath}` : userIcon} alt="user icon" />
                              <h3 className="acc-name">{upperFirst(item.name)}</h3>
                            </div>
                            <div className="result-contact">
                              <div>
                                <label className="contact-label">Phone No:</label>
                                <label className="contact-detial"><a href={"tel:" + item.phone} rel="noopener noreferrer">{item.phone}</a></label>
                              </div>
                              <div>
                                <label className="contact-label">Role:</label>
                                {item.role.toLowerCase() === "super admin" ? (
                                <>
                                  {" "}
                                  <span>
                                    {item.role
                                      ? "Super Admin"
                                      : "-"}
                                  </span>
                                </>
                              ) : item.role.toLowerCase() === "admin" ? (
                                <>
                                  {" "}
                                  <span >
                                    {item.role
                                      ? "Admin"
                                      : "-"}
                                  </span>
                                </>
                              ) : item.role.toLowerCase() === "division manager" ? (
                                <>
                                  {" "}
                                  <span >
                                    {item.role
                                      ? "Division Leader"
                                      : "-"}
                                  </span>
                                </>
                              ) : item.role.toLowerCase() === "territory manager" ? (
                                <>
                                  {" "}
                                  <span>
                                    {item.role
                                      ? "Territory Manager"
                                      : "-"}
                                  </span>
                                </>

                              ) : item.role.toLowerCase() === "sales rep" ? (
                                <>
                                  {" "}
                                  <span>
                                    {item.role
                                      ? "Representative"
                                      : "-"}
                                  </span>
                                </>

                              ) : item.role.toLowerCase() === "area manager" ? (
                                <>
                                  {" "}
                                  <span>
                                    {item.role
                                      ? "Area Director"
                                      : "-"}
                                  </span>
                                </>
                              ) : item.role.toLowerCase() === "team lead" ? (
                                <>
                                  {" "}
                                  <span >
                                    {item.role
                                      ? "Regional Manager"
                                      : "-"}
                                  </span>
                                </>
                              ) : ("")}
                              </div>
                              <div>
                                <label className="contact-label">Email Address:</label>
                                <label className="contact-detial"><a href={"mailto:" + item.email} rel="noopener noreferrer">{item.email}</a></label>
                              </div>
                              {item.status.toLowerCase() !== 'activated' ?
                                <div>
                                  <label className="contact-label">Status:</label>
                                  <label className="contact-detial">
                                    {item.status.toLowerCase() === 'inactivated' ? "Suspended"
                                      : item.status.toLowerCase() === 'registered' ? "Not accepted Invite" : "Deleted"}</label>
                                </div>

                                : ""}
                              <div>
                                <label className="contact-label">Company name:</label>
                                <label className="contact-detial">
                                  {item.companyName}</label>
                              </div>

                            </div>
                            <div className="result-actions">
                              <button className="default-btn primary-btn" onClick={() => this.manageUser(item._id)}>Manage</button>
                              {item.status.toLowerCase() === "activated" ? <button className="default-btn primary-btn" onClick={() => this.loginAsUser(item._id)}>Login as {upperFirst(item.name)}</button> : ""
                              }
                            </div>
                          </>
                        ))}
                      </div> : ""}
                  </div>
                </Grid><Grid item xs={12} md={4}>
                  <div className="access-section">
                    <div className="chart-header">
                      <h5 className="chart-title">Account Quick Access</h5>
                    </div>
                    <div className="chart-search">
                      <div className="search-box">
                        <AutoComplete
                          className="search-field"
                          onSearch={this.onAutoCompleteAccountSearch}
                          onSelect={this.onAutoCompleteAccountSelect}
                        >
                          {this.state.accountQuickAccessArray.map(item => (
                            <Option key={item._id} value={item.accountName}>{upperFirst(item.accountName)}</Option>
                          ))}
                        </AutoComplete>
                        <img className="search-icon" src={search_icon} alt="search" />
                      </div>
                      <label className="search-label">You can search the account by Name.</label>
                    </div>
                    {this.state.accountQuickAccessValue.length > 0 ?
                      <div className="result-detial">
                        {this.state.accountQuickAccessValue.map(item => (
                          <>
                            <div className="result-header">
                              <span className="acc-initial">{item.accountName.charAt(0)}</span>
                              <h3 className="acc-name">{upperFirst(item.accountName)}</h3>
                            </div>
                            <div className="result-contact">
                              <div>
                                <label className="contact-label">Address:</label>
                                <label className="contact-detial">{upperFirst(item.address.formatedAddress)}</label>
                              </div>
                              <div>
                                <label className="contact-label">Departments:</label>
                                <label className="contact-detial">{upperFirst(item.departments)}</label>
                              </div>
                              <div>
                                <label className="contact-label">Website Address:</label>
                                <label className="contact-detial"><a target="_blank" href={`https://${item.website}`} rel="noopener noreferrer">{item.website}</a></label>
                              </div>
                            </div>
                            <div className="result-actions">
                              <button className="default-btn primary-btn" onClick={() => this.manageAccount(item._id)}>Manage</button>
                            </div>
                          </>))}
                      </div>
                      : ""}
                  </div>
                </Grid>
              </Grid>
              {/* chart */}
              <Grid container spacing={2} className="chart-section table-section">
                <Grid item xs={12} md={6}>
                  <div className="task-graph">
                    <div className="chart-header">
                      <h5 className="chart-title">Pending Registrations</h5>
                      <button className="view-btn grey-btn" onClick={this.viewAllPendingRegistration}>View All</button>
                    </div>
                    <Suspense fallback={<div></div>}>
                      <PendingRegistrationListComponent
                        tableHeader={this.state.tableHeader}
                        tableValues={this.state.pendingRegistration}
                        tooltipprops={this.state.tooltipprops}
                      />
                    </Suspense>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="task-graph company-report">
                    <div className="chart-header">
                      <h5 className="chart-title">Company Report</h5>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div filter-option"
                          onClick={this.onCompanyDateFilterOpen}
                        >
                          {this.state.summaryCompanyReportFilterName ? (
                            ""
                          ) : (
                              <label className="leftName"></label>
                            )}
                          <label
                            aria-label="summary_date_filter2"
                            aria-controls="company_report_filter_control"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.summaryCompanyReportFilterName
                              ? upperFirst(this.state.summaryCompanyReportFilterName)
                              : `This Week`}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectComponent
                          isAllRequired={true}
                          filterArray={this.state.summary_company_date.summaryDate}
                          closeCustomPopup={this.onCompanyDateFilterClose}
                          config={this.state.summary_company_date.id}
                          anchorEl={this.state.summary_company_date.anchorElCompanyDateFilter}
                          handleClick={this.onCompanyReportDateFilter}
                        />
                      </Suspense>
                    </div>
                    <Suspense fallback={<div></div>}>
                      <CommonPieChartComponent
                        title={""}
                        type={'pie'}
                        pointFormat={'{series.name}: <b>{point.percentage:.1f}%</b>'}
                        valueSuffix={' %'}
                        dataLabels={'<b>{point.name}</b>: {point.percentage:.1f} %'}
                        legend={true}
                        seriesName={'Company'}
                        seriesData={this.state.companyReportData.yAxis}
                      />
                    </Suspense>
                  </div>
                </Grid>
              </Grid>
              {/* mini-chart */}
              <Grid container spacing={2} className="chart-section">
                <Grid item xs={12} md={4}>
                  <div className="task-graph active-accounts">
                    <div className="chart-header">
                      <h5 className="chart-title">Active Accounts</h5>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div filter-option"
                          onClick={this.onNoOfActiveFilterOpen}
                        >
                          {this.state.noOfActiveFilterName ? (
                            ""
                          ) : (
                              <label className="leftName"></label>
                            )}
                          <label
                            aria-label="summary_date_filter3"
                            aria-controls="no_of_active_filter_control"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.noOfActiveFilterName
                              ? upperFirst(this.state.noOfActiveFilterName)
                              : `This Week`}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectComponent
                          isAllRequired={true}
                          filterArray={this.state.no_of_active_account.summaryDate}
                          closeCustomPopup={this.onNoOfActiveFilterClose}
                          config={this.state.no_of_active_account.id}
                          anchorEl={this.state.no_of_active_account.anchorElNoOfActive}
                          handleClick={this.onNoOfActiveFilterClick}
                        />
                      </Suspense>
                    </div>
                    <Suspense fallback={<div></div>}>
                      <CommonSemiCircleGraphComponent
                        // title={`${this.state.activeAccountData.average}%`}
                        title={""}
                        pointFormat={'{series.name}: <b>{point.percentage:.2f}%</b>'}
                        valueSuffix={' %'}
                        center={['50%', '75%']}
                        size={'110%'}
                        seriesName={'Account'}
                        innerSize={'60%'}
                        legend={true}
                        seriesData={this.state.activeAccountData.yAxis}
                      />
                    </Suspense>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="task-graph tasks-created">
                    <div className="chart-header">
                      <h5 className="chart-title">Number of Tasks Created</h5>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div filter-option"
                          onClick={this.onNoOfTaskFilterOpen}
                        >
                          {this.state.noOfTaskFilterName ? (
                            ""
                          ) : (
                              <label className="leftName"></label>
                            )}
                          <label
                            aria-label="summary_date_filter4"
                            aria-controls="no_of_task_filter_control"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.noOfTaskFilterName
                              ? upperFirst(this.state.noOfTaskFilterName)
                              : `This Week`}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectComponent
                          isAllRequired={true}
                          filterArray={this.state.no_of_task.summaryDate}
                          closeCustomPopup={this.onNoOfTaskFilterClose}
                          config={this.state.no_of_task.id}
                          anchorEl={this.state.no_of_task.anchorElNoOfTask}
                          handleClick={this.onNoOfTaskFilterClick}
                        />
                      </Suspense>
                    </div>
                    <Suspense fallback={<div></div>}>
                      <CommonLineGraphComponent
                        title={""}
                        yAxisTitle={'Number of Tasks'}
                        format={'MMM DD'}
                        timeZone={this.state.timeZoneName}
                        xAxisData={this.state.noOfTaskCreated.xAxis}
                        seriesData={this.state.noOfTaskCreated.yAxis}
                      />
                    </Suspense>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="task-graph quick-access">
                    <div className="chart-header">
                      <h5 className="chart-title">Number of Logins</h5>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div filter-option"
                          onClick={this.onNoOfLoginFilterOpen}
                        >
                          {this.state.noOfLoginFilterName ? (
                            ""
                          ) : (
                              <label className="leftName"></label>
                            )}
                          <label
                            aria-label="summary_date_filter5"
                            aria-controls="no_of_login_filter_control"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.noOfLoginFilterName
                              ? upperFirst(this.state.noOfLoginFilterName)
                              : `This Week`}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectComponent
                          isAllRequired={true}
                          filterArray={this.state.no_of_login.summaryDate}
                          closeCustomPopup={this.onNoOfLoginFilterClose}
                          config={this.state.no_of_login.id}
                          anchorEl={this.state.no_of_login.anchorElNoOfLogin}
                          handleClick={this.onNoOfLoginFilterClick}
                        />
                      </Suspense>
                    </div>
                    <Suspense fallback={<div></div>}>
                      <CommonLineGraphComponent
                        title={""}
                        yAxisTitle={'Number of Logins'}
                        format={'MMM DD'}
                        timeZone={this.state.timeZoneName}
                        xAxisData={this.state.loginCountReports.xAxis}
                        seriesData={this.state.loginCountReports.yAxis}
                      />
                    </Suspense>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true
  };
};

export default connect(
  mapStateToProps
)(withRouter(SuperAdminDashboardComponent));
