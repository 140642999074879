import React,{Component} from 'react';
import "./EmailConfirmationComponent.css";

import accept_email from "../../Assets/images/accept_email_invite.svg";
import { AxiosInstance } from '../../Utilities';
import { withRouter } from 'react-router-dom';

class EmailConfirmationComponent extends Component {
    constructor(props){
        super(props);
        this.state={
            id:""
        }
    }
    UNSAFE_componentWillMount(){
        const {id} = this.props.match.params;
        this.setState({
            id
        })
      }
    componentDidMount(){
       AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
       ? 6013
       : "event",
     "application/json") 
     .post("/event/ccemailAcceptReject",{
        data:this.state.id
    })
     .then(resp=>{
         var response = resp && resp.data;
         if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
          console.log("suucess");
        } 
        else if (
            response &&
            (response.status === "400" || response.status === 400)
          ) {
          console.log("something went wrong")
        }
     }).catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (
          error &&
          (error.status === "500" || error.status === 500)
        ) {
            console.log("internal server error")
           }
      })
    }


    render(){
        return (
            <div className="accept_email_invite">
                <img src={accept_email} />
                <h1>Accepted Event Invite!</h1>
                <p>You have successfully confirmed your availability for this event.</p>
            </div>
        );
  }
}
 
export default withRouter(EmailConfirmationComponent);