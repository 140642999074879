import * as actionType from "./ActionTypes";
import { AxiosInstance } from "../../Utilities";


/**
 * pagination actions
 */

const paginationRequest = (type) => {
  return {
    type: actionType.TASKPAGINATION_REQUEST,
    isLoading: true
  };
};

const paginationSuccess = (pager, pageOfItems, type) => {
  return {
    type: actionType.TASKPAGINATION_SUCCESS,
    pager: pager,
    pageOfItems: pageOfItems,
    isLoading: false
  };
};

const paginationFailure = (error, type) => {
  return {
    type: actionType.TASKPAGINATION_FAILURE,
    error: error,
    isLoading: false
  };
};

/**
 * dispatching pagination actions
 */
export const taskpagination = (pageNo, pageSize,account,service,assignedToValue,status,priority,search,startDate,endDate,count) => {
  const pageData = {
    nextpage: pageNo,
    pagesize: pageSize,
    accountId: account !== "All" ? account : '',
    assignedTo: assignedToValue !== "All" ? assignedToValue : '',
    emergency: priority !== "All" ? priority : '',
    service_type: service !== "All" ? service : '',
    taskStatus: status !== "All" ? status : '',
    taskSerch: search ? search : '',
    startDate: startDate ? startDate : '',
    endDate: endDate ? endDate : ''
  };
  let type = "/get_task";
  return apis(pageData, type, count);
};

export const userTaskpagination = (pageNo, pageSize, userId, today) => {
  const pageData = {
    nextpage: pageNo,
    pagesize: pageSize,
    employeeId: userId,
    today: today,
  };
  let type = "/userDetail/upcomingTask";
  return apis(pageData, type);
};

// export const onSearchData = (searchData) => {
//   let type = "/transaction/search";
//   return apis({ searchData }, type);
// };

export const onSearchByStatus = (value) => {
  let type = "/get_task";
  return apis(value, type);
};
export const onSearchByDate = (value) => {
  let type = "/get_task";
  return apis(value, type);
};

export const onSearchByAssigend = (value) => {
  let type = "/get_task";
  return apis(value, type);
};
export const onSearchByAccount = (value) => {
  let type = "/get_task";
  return apis(value, type);
};
export const onSearchByService = (value) => {
  console.log("value for service", value)
  let type = "/get_task";
  return apis(value, type);
};

export const onSearchByCompany = (filterData, count) => {
  let type = "/get_task";
  return apis(filterData, type, count);
};

const apis = (value, type, count) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.status === 200) {
          let apiCallCount = localStorage.getItem("apiCallCount")
          if(count && apiCallCount){
            if (count == apiCallCount) {
              dispatch(
                paginationSuccess(response.data.pagination, response.data.data)
              );
            } 
          }else{
            dispatch(
              paginationSuccess(response.data.pagination, response.data.data)
            );
          }
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};

/**
 * calling pagination searcg api and updating pageination states
 */
// export const paginationOnSearch = (searchValue) => {
//   return (dispatch) => {
//     dispatch(paginationRequest());
//     axios
//       .post("/search", { searchValue })
//       .then((response) => {
//         if (response.data.success) {
//           dispatch(
//             paginationSuccess(response.data.pager, response.data.pageOfItems)
//           );
//         } else {
//           dispatch(paginationFailure(response.data.message));
//         }
//       })
//       .catch((error) => {
//         dispatch(paginationFailure(error));
//       });
//   };
// };

// export const assignedTo  = (value) => {
//   return (dispatch) => {
//     dispatch(paginationRequest());
//     axios
//       .post("/search", { searchValue })
//       .then((response) => {
//         if (response.data.success) {
//           dispatch(
//             paginationSuccess(response.data.pager, response.data.pageOfItems)
//           );
//         } else {
//           dispatch(paginationFailure(response.data.message));
//         }
//       })
//       .catch((error) => {
//         dispatch(paginationFailure(error));
//       });
//   };
// };