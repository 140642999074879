import React from 'react';
import DateTime from 'react-datetime';
import "./DateTimeRangePickerComponent.css";
 
const DateTimeRangePickerComponent = (props)=> {
        return (
           <div className="date_picker_input">
                <DateTime 
                    initialViewDate={props.dateProps.initialViewDate} 
                    inputProps={props.dateProps.inputProps} 
                    timeConstraints={props.dateProps.timeConstraint}
                    closeOnSelect={props.closeOnSelect}
                    timeFormat={props.dateProps.timeFormat}
                    dateFormat={props.dateProps.dateFormat}
                    locale={props.dateProps.locale}
                    isValidDate={props.valid}
                    onChange={props.onDateChange}
                    {...props.initialValue}
                />
           </div>
        );
    }
 
export default DateTimeRangePickerComponent;