import React, { Component, Suspense } from "react";
import "./TaskComponent.css";
import * as action from "../../Store/Actions/index";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import searchIcon from "../../Assets/images/search_icon.svg";
import carret_down from "../../Assets/images/carret_down.svg";
import { connect } from "react-redux";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import { withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import OpenMenu from "../../Assets/images/open-menu.svg";
import emergency from "../../Assets/images/Icon.png";
import { AxiosInstance, checkCmsLabel } from "../../Utilities";
import { Autocomplete } from "@material-ui/lab";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import { upperFirst } from "lodash";
import Loader from "react-loader-spinner";
import momentTimeZone from "moment-timezone";
import moment from "moment";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import { isSafari } from "react-device-detect";
import recurring_assigned from "../../Assets/images/recurring_assigned.svg"
import recurring_pending from "../../Assets/images/recurring_pending.svg"
import recurring_inprogress from "../../Assets/images/recurring_inprogress.svg"
import recurring_completed from "../../Assets/images/recurring_completed.svg"
import recurring_uncovered from "../../Assets/images/recurring_uncovered.svg"
import recurring_unassigned from "../../Assets/images/recurring_unassigned.svg";
import recurring_cancelled from "../../Assets/images/recurring_cancelled.svg";

var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import(
    "../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
  )
);
const { RangePicker } = DatePicker;
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
const url =  new URL(window.location.href);  
const accountValue = url.searchParams.get('account');
const serviceValue = url.searchParams.get('servicetype');
const assignedToValue = url.searchParams.get('assignedto');
const status = url.searchParams.get('status');
const priority = url.searchParams.get('priority')
const searchFilterValue = url.searchParams.get('search')
const startDateValue = url.searchParams.get('startDate')
const endDateValue = url.searchParams.get('endDate')

const defaultStartDate = startDateValue;
const defaultEndDate = endDateValue;
class TaskComponent extends Component {
  constructor(props) {
    super(props);
    var zoneName = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      statusFilterValue:"",
      accountFilterValue:'',
      assignedToFilterValue:'',
      serviceTypeValue:'',
      priorityValue:'',
      cmsLabelChange:[],
      timeZoneName: zoneName,
      auth_username: userData.username,
      role: userData.role,
      auth_email: userData.email,
      auth_companyId: userData.companyId,
      showaddModal: false,
      showEditModal: false,
      modalRecord: {},
      deleteRecord: {},
      dateFilter: { from: "", to: "" },
      rowsPerPage: "10",
      open: "",
      nextpage: 1,
      pagesize: 10,
      companyOption: [
        {
          id: "",
          name: "",
        },
      ],
      taskSerch: "",
      emergencyFilterName: "",
      emergencyFilter: priority ? priority : "",
      assignedOption: [],
      accountOption: [],
      statusFilter: status ? status : "",
      serviceTypeOption: [],
      statusFilterName: "",
      assigendFilter: "",
      serviceTypeFilter: serviceValue ? serviceValue : "",
      serviceTypeFilterName: "",
      serviceTypeName: "",
      searchTypeServiceValue: "",
      accountFilter: "",
      companyFilter: "",
      assignedId: assignedToValue ? assignedToValue : "",
      assignedName: "",
      accountId: accountValue ? accountValue : "",
      accountName: "",
      companyId: "",
      companyName: "",
      searchAssigneeValue: "",
      searchAccountValue: "",
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },

      emergencyConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      serviceConfig: {
        config: {
          id: "service_filter_select",
        },
        anchorElService: null,
      },
      serviceTypeConfig: {
        config: {
          id: "service_filter_select",
        },
        anchorElService: null,
      },


      assigneeConfig: {
        anchorElAssignee: null,
        config: {
          id: "assignee_filter_select",
        },
      },
      accountConfig: {
        anchorElAccount: null,
        config: {
          id: "account_filter_select",
        },
      },
      tooltipprops: {},
      pageNumber:1
    };
  }

  UNSAFE_componentWillMount() {
    let obj = {}
    if (isSafari) {
      obj.open = false;
      this.setState({ tooltipprops: obj })
    }
  }
  componentDidMount = () => {
    window.onpopstate = () =>{
      window.location.reload()
    }
  const fetchCmsData = checkCmsLabel()
    if(fetchCmsData){
      this.setState({ cmsLabelChange : fetchCmsData.cmsData})
    }
    const queryString = this.props.location.search;
    const pageParameter = new URLSearchParams(queryString).get("page");
    const pageNumber = parseInt(pageParameter);
    this.setState({
      taskSerch:searchFilterValue,
      nextpage: pageNumber ? pageNumber : 1
    })
    this.accountFilter();
    this.props.onPagination(pageNumber?pageNumber:this.state.nextpage, this.state.pagesize,accountValue,serviceValue,assignedToValue,status,priority,searchFilterValue, startDateValue, endDateValue,"");
    this.employeeFilter();
    this.serviceTypeFilter();
    setTimeout(() => {
      if(accountValue && accountValue !== "All"){
        const accounts = this.state.accountOption.find(item => item._id === accountValue)
        this.setState({
          accountName: accounts.name ? accounts.name : ""
        })
      }
      if(serviceValue && serviceValue !== "All"){
        const services = this.state.serviceTypeOption.find(item => item._id === serviceValue)
        this.setState({
          serviceTypeName: services.name ? services.name : ""
        })
      }
      if(assignedToValue && assignedToValue !== "All"){
        const employee = this.state.assignedOption.find(item => item._id === assignedToValue)
        this.setState({
          assignedName: employee?.name ? employee?.name : ""
        })
      }
      if(status && status !== "All"){
        var statusOptions = [
          { _id: "", name: "All"},
          { _id: "upcomming", name: "Upcoming" },
          { _id: "Unassigned", name: "Unassigned" },
          { _id: "Pending", name: "Pending" },
          { _id: "assigned", name: "Assigned" },
          { _id: "inprogress", name: "In Progress" },
          { _id: "completed", name: "Completed" },
          { _id: "cancelled", name: "Cancelled" },
          { _id: "uncovered", name: "Uncovered" },
        ];
        const statusValues = statusOptions.find(item => item._id === status)
        this.setState({
          statusFilterName: statusValues?.name ? statusValues?.name : ""
        })
      }
      if(priority && priority !== "All"){
        var emergencyOptions = [
          { _id: "emergency", name: "Emergency" },
          { _id: "notemergency", name: "Normal" },
    
        ];
        const priorityValue = emergencyOptions.find(item => item._id === priority)
        this.setState({
          emergencyFilterName: priorityValue.name ? priorityValue.name : ""
        })
      }
    },1000);
  };

  /**
   *
   * @param {value} search
   * TO FETCH THE ACCOUNT FILTER
   */
  accountFilter = (search) => {
    const { accountOption } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/account_filter", { params:{
        search:search ? search :''
      } })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = accountOption.splice();
          if (this.state.searchAccountValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            accountOption: newArray,
          });
        } else {
          this.setState({
            accountOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   *
   * @param {value} search
   * TO FETCH THE EMPLOYEE FILTER
   */
  employeeFilter = (search) => {
    const { assignedOption } = this.state;

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/employeeFilter", { params:{
        search: search
      } })
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = assignedOption.splice();
          if (this.state.searchAssigneeValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            assignedOption: newArray,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            assignedOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };
  /**
  *
  * @param {value} search
  * TO FETCH THE SERVICE TYPE FILTER
  */
  serviceTypeFilter = (search) => {
    const { serviceTypeOption } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/getServicetypes",{ params:{
        search: search? search : ""
      }})
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = serviceTypeOption.splice();
          if (this.state.searchTypeServiceValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.serviceType;
              newArray.push(obj);
            });
          }
          this.setState({
            serviceTypeOption: newArray,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            serviceTypeOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };

  /**
   * @author Benakesha
   * @param {Task Id} taskId
   * TO NAVIGATE TO TASK DETAIL PAGE
   */
  onTaskDetailHandler = (taskId) => {
    this.props.history.push(`task/${taskId}`);
  };
  handleCustomPaginagtionChange = (event, nextpage) => {
    this.setState({ nextpage: nextpage }, this.filter);
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ nextpage: 1, pagesize: +event.target.value }, this.filter);
  };
  onDateChanage = (dateString) => {
    this.setState(
      { nextpage: 1, dateFilter: { from: dateString[0], to: dateString[1] } },
      () => {
        this.filter();
      }
    );
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleStatusSelect = (item) => {
    var { statusFilter, statusFilterName, statusConfig } = this.state;
    statusFilter = item._id;
    statusFilterName = item.name;
    statusConfig.anchorElStatus = null;
    this.setState(
      {
        statusConfig,
        statusFilterName,
        statusFilter,
        nextpage: 1,
        statusFilterValue:item.name === "All" ? "All" : item.name
      },
      () => {
        this.filter();
      }
    );
  };
  /**
 *
 * @param {item object} item
 * TO ADD SERVICE TYPE FILTER VALUE ON SELECT
 */
  handleServiceTypeSelect = (item) => {
    var { serviceTypeFilter, serviceTypeFilterName, serviceTypeConfig } = this.state;
    serviceTypeFilter = item._id;
    serviceTypeFilterName = item.serviceType;
    serviceTypeConfig.anchorElService = null;
    this.setState(
      {
        serviceTypeConfig,
        serviceTypeName: item.name === "All" ? "" : item.name,
        serviceTypeValue: item.name === "All" ? "All" : item.name,
        serviceTypeFilterName,
        serviceTypeFilter,
        nextpage: 1,
      },
      () => {
        this.filter();
      }
    );
  };
  /**
   *
   * @param {item object} item
   * TO ADD ASSIGNEE FILTER VALUE ON SELECT
   */
  handleAssigneeSelect = (item) => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = null;

    this.setState(
      {
        assignedId: item._id,
        assignedName: item.name === "All" ? "" : item.name,
        assignedToFilterValue: item.name === "All" ? "All" : item.name,
        assigneeConfig,
        searchAssigneeValue: "",
        nextpage: 1,
      },
      () => {
        this.filter();
      }
    );
  };
  /**
   *
   * @param {item object} item
   * TO ADD ACCOUNT FILTER VALUE ON SELECT
   */
  handleAccountSelect = (item) => {
    const { accountConfig } = this.state;
    accountConfig.anchorElAccount = null;
    this.setState(
      {
        accountId: item._id,
        accountName: item.name === "All" ? "" : item.name,
        accountFilterValue: item.name === "All" ? "All" : item.name,
        accountConfig,
        searchAccountValue: "",
        nextpage: 1,
      },
      () => {
        this.filter();
      }
    );
  };
  /**
   *
   * @param {item object} item
   * TO HANDLE ASSIGNEE FILTER VALUE ON SEARCH
   */
  onAssigneeSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchAssigneeValue: value,
    });
    this.employeeFilter(value);
  };
  /**
   *
   * @param {item object} item
   * TO HANDLE ACCOUNT FILTER VALUE ON SEARCH
   */
  onAccountSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchAccountValue: value,
    });
    this.accountFilter(value);
  };
  /**
*
* @param {item object} item
* TO HANDLE SERVICE TYPE FILTER VALUE ON SEARCH
*/
  onServiceTypeSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchTypeServiceValue: value,
    });
    this.serviceTypeFilter(value);
  };
  /**
*
* @param {item object} item
* TO HANDLE SERVICE TYPE FILTER VALUE ON SEARCH
*/
  onServiceTypeSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchTypeServiceValue: value,
    });
    this.serviceTypeFilter(value);
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeStatusFilterMenu = () => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
    });
  };
  /**
   *
   * TO HANDLE ASSIGNEE MENU CLOSE
   */
  closeAssigneeFilterMenu = () => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = null;
    this.setState({
      assigneeConfig,
    });
  };
  /**
   *
   * TO HANDLE ACCOUNT MENU CLOSE
   */
  closeAccountFilterMenu = () => {
    const { accountConfig } = this.state;
    accountConfig.anchorElAccount = null;
    this.setState({
      accountConfig,
    });
  };
  /**
  *
  * TO HANDLE SERVICE TYPE MENU CLOSE
  */
  closeServiceTypeFilterMenu = () => {
    const { serviceTypeConfig } = this.state;
    serviceTypeConfig.anchorElService = null;
    this.setState({
      serviceTypeConfig,
    });
  };
  /**
  *
  * TO HANDLE SERVICE TYPE MENU CLOSE
  */
  closeServiceTypeFilterMenu = () => {
    const { serviceTypeConfig } = this.state;
    serviceTypeConfig.anchorElService = null;
    this.setState({
      serviceTypeConfig,
    });
  };
  /**
   *
   * TO HANDLE STATUS MENU OPEN
   */
  openStatusFilterMenu = (event) => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      statusConfig,
    });
  };
  /**
   *
   * TO HANDLE ASSIGNEE MENU OPEN
   */
  openAssigneeFilterMenu = (event) => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = event.currentTarget;
    this.setState(
      {
        assigneeConfig,
      },
      () => {
        this.employeeFilter("");
      }
    );
  };
  /**
    *
    * TO HANDLE SERVICE TYPE MENU OPEN
    */
  openServiceTypeFilterMenu = (event) => {
    const { serviceTypeConfig } = this.state;
    serviceTypeConfig.anchorElService = event.currentTarget;
    this.setState(
      {
        serviceTypeConfig,
      },
      () => {
        this.serviceTypeFilter("");
      }
    );
  };
  /**
    *
    * TO HANDLE SERVICE TYPE MENU OPEN
    */
  openServiceTypeFilterMenu = (event) => {
    const { serviceTypeConfig } = this.state;
    serviceTypeConfig.anchorElService = event.currentTarget;
    this.setState(
      {
        serviceTypeConfig,
      },
      () => {
        this.serviceTypeFilter("");
      }
    );
  };
  /**
   *
   * TO ACCOUNT STATUS MENU OPEN
   */
  openAccountFilterMenu = (event) => {
    const { accountConfig } = this.state;
    accountConfig.anchorElAccount = event.currentTarget;
    this.setState(
      {
        accountConfig,
      },
      () => {
        this.accountFilter("");
      }
    );
  };
  companyListHandler = async (e) => {
    var name = e.target.value;
    var data = {
      companyName: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : 'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/companylist", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            companyOption: response.finalCompany,
          });
        } else {
          this.setState({
            companyOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    var { companyOption, formError } = this.state;
    var Data = await companyOption.filter((item) => {
      return item.name === name;
    });
    Data.map((companyData) => {
      this.setState({
        companyId: companyData.id,
        companyName: companyData.name,
        [formError.companyId]: "",
      });
    });
  };

  onSelectHandler = (e) => {
    var name = e.target.value;
    if (name !== "") {
      var { companyOption } = this.state;
      var data = companyOption.filter((item) => {
        return item.name === name;
      });
      data.map((companyData) => {
        this.setState(
          {
            companyId: companyData.id,
            companyName: companyData.name,
            nextpage: 1,
          },
          this.filter
        );
      });
    }
  };
  compAutoClose = (e) => {
    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ companyId: "" }, this.filter);
    }
  };
  taskSearch = (e) => {
    let name = e.target.value;
    this.setState({ nextpage:1,taskSerch: name }, () => {
      this.filter();
    });
  };
  updateURL = () => {
    let startDate, formattedEndDate
    if(this.state.dateFilter.from && this.state.dateFilter.to){
      const inputstartDate = this.state.dateFilter.from;
      const inputendDate = this.state.dateFilter.to;
      const timezone = this.state.timeZoneName;
      const parsedstartDate = momentTimeZone(inputstartDate, "MM-DD-YYYY");
      const parsedendDate = momentTimeZone(inputendDate, "MM-DD-YYYY");
      parsedstartDate.tz(timezone);
      parsedendDate.tz(timezone);
      startDate = parsedstartDate.toISOString();
      const endDate = parsedendDate.toISOString();
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
      adjustedEndDate.setSeconds(adjustedEndDate.getSeconds() - 1);
      formattedEndDate = adjustedEndDate.toISOString();
    }


    const {serviceTypeFilter, statusFilter, assignedId, accountId, emergencyFilter, assignedToFilterValue, statusFilterValue, accountFilterValue, serviceTypeValue, priorityValue, taskSerch, startDateValue, endDateValue} = this.state
    const url =  new URL(window.location.href);
    const queryParams = {
      account: accountId || accountFilterValue || url.searchParams.get('account') || '',
      status: statusFilter || statusFilterValue || url.searchParams.get('status') || '',
      assignedto: assignedId || assignedToFilterValue || url.searchParams.get('assignedto') || '',
      servicetype: serviceTypeFilter || serviceTypeValue || url.searchParams.get('servicetype') || '',
      priority: emergencyFilter || priorityValue || url.searchParams.get('priority') || '',
      search: taskSerch || searchFilterValue || '',
      startDate: startDate || startDateValue || url.searchParams.get('startDate') || '',
      endDate: formattedEndDate || endDateValue || url.searchParams.get('endDate') || ''
    };

    const filteredParams = Object.entries(queryParams)
    .filter(([key, value]) => value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    this.props.history.push(`/task?page=${this.state.nextpage?this.state.nextpage:'1'}&${filteredParams}`);
  };

  filter = () => {
    this.setState({ isloading: true });
    let data = {
      pagesize: this.state.pagesize,
      nextpage: this.state.nextpage,
      companyId: this.state.companyId,
      accountId: this.state.accountId !== "All" ? this.state.accountId : "",
      assignedTo: this.state.assignedId !== "All" ? this.state.assignedId : "",
      taskStatus: this.state.statusFilter !== "All" ? this.state.statusFilter :'',
      service_type: this.state.serviceTypeFilter !== "All" ? this.state.serviceTypeFilter : '',
      // startDate: momentTimeZone(momentTimeZone(this.state.dateFilter.from)
      //   .tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00')).utc(),
      // endDate: momentTimeZone(momentTimeZone(this.state.dateFilter.to)
      //   .tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59')).utc(),

      startDate: startDateValue ? startDateValue :
        moment.tz(momentTimeZone(this.state.dateFilter.from, 'MM-DD-YYYY').format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
      // .format('YYYY-MM-DD HH:mm:ss'),

      endDate: endDateValue ? endDateValue :
        moment.tz(momentTimeZone(this.state.dateFilter.to, 'MM-DD-YYYY').format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc(),
      // momentTimeZone(momentTimeZone(this.state.dateFilter.to)
      // .tz(this.state.timeZoneName)
      //  .format('YYYY-MM-DD 23:59:59')).utc(),

      taskSerch: this.state.taskSerch,
      emergency: this.state.emergencyFilter !== "All" ? this.state.emergencyFilter : "",

    };
    this.props.onSearchByCompany(data);
    this.updateURL()
  };

  /**
     *
     * TO HANDLE Emergency MENU OPEN
     */
  openEmergencyFilterMenu = (event) => {
    const { emergencyConfig } = this.state;
    emergencyConfig.anchorElStatus = event.currentTarget;
    this.setState({
      emergencyConfig,
    });
  };
  /**
    *
    * @param {item object} item
    * TO ADD Emergency FILTER VALUE ON SELECT
    */
  handleEmergencySelect = (item) => {
    var { emergencyFilter, emergencyFilterName, emergencyConfig } = this.state;
    emergencyFilter = item._id;
    emergencyFilterName = item.name;
    emergencyConfig.anchorElStatus = null;
    this.setState(
      {
        emergencyConfig,
        emergencyFilterName,
        emergencyFilter,
        priorityValue: item.name === "" ? "All" : item.name,
        nextpage: 1,
      },
      () => {
        this.filter();
      }
    );
  };
  /**
    *
    * TO HANDLE Emergency MENU CLOSE
    */
  closeEmergencyFilterMenu = () => {
    const { emergencyConfig } = this.state;
    emergencyConfig.anchorElStatus = null;
    this.setState({
      emergencyConfig,
    });
  };

  navigateDetail = (row) => {
    if (row.dataStatus == "AutoGenerated") {
      this.props.history.push(`/task/${row._id}?dateId=${row.dateId}&status=AutoGenerated`);
    } else {
      this.props.history.push("task/" + row._id);
    }
  }

  render() {
    var statusOptions = [
      { _id: "", name: "All"},
      { _id: "upcomming", name: "Upcoming" },
      { _id: "Unassigned", name: "Unassigned" },
      { _id: "Pending", name: "Pending" },
      { _id: "assigned", name: "Assigned" },
      { _id: "inprogress", name: "In Progress" },
      { _id: "completed", name: "Completed" },
      { _id: "cancelled", name: "Cancelled" },
      { _id: "uncovered", name: "Uncovered" },
    ];

    var emergencyOptions = [
      { _id: "emergency", name: "Emergency" },
      { _id: "notemergency", name: "Normal" },

    ];
    let TaskPage = (
      <div className="body-bg-color">
        <HeaderComponent />
        <div className="header-add-btn">
          <Typography variant="h5" className="tableTitle" component="div">
            Tasks
          </Typography>
          <NavLink
            to={"/schedule-task"}
            className="navbars"
          >
            <Button
              variant="contained"
              className="tableaddBtn grey-btn"
              color="default"
              style={{ boxShadow: "none" }}

            >
              Task Scheduler
            </Button>
          </NavLink>
        </div>
        <div className="taskfilter">
          {this.state.role.toLowerCase() === "super admin" ? (
            <InputLabel
              className="task_custom_filter_label"
              id="demo-simple-select-outlined-label"
            >
              Company
            </InputLabel>
          ) : (
            ""
          )}
          {this.state.role.toLowerCase() === "super admin" ? (
            <Autocomplete
              id="companyName"
              className="cusautocomplete"
              onInputChange={this.compAutoClose}
              options={this.state.companyOption}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="companyName"
                  onChange={this.companyListHandler}
                  onSelect={this.onSelectHandler}
                />
              )}
            />
          ) : (
            ""
          )}
        </div>
        <div className="Acc-Table">
          <div className="filter-search">
            {this.state.role.toLowerCase() !== "super admin" ? (
              <>
                <div className="new_custom_filters">
                  <Suspense fallback={<div></div>}>
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openStatusFilterMenu}
                    >
                      {this.state.statusFilterName !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Status: </label>
                      )}
                      <label
                        aria-label="status_filter"
                        aria-controls="status_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.statusFilterName === ""
                          ? `All`
                          : upperFirst(this.state.statusFilterName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectComponent
                      filterArray={statusOptions}
                      config={this.state.statusConfig.config}
                      anchorEl={this.state.statusConfig.anchorElStatus}
                      handleClick={this.handleStatusSelect}
                      closeCustomPopup={this.closeStatusFilterMenu}
                      isAllRequired={true}
                    />
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openAssigneeFilterMenu}
                    >
                      {this.state.assignedName !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Assigned to: </label>
                      )}
                      <label
                        aria-label="assignee_filter"
                        aria-controls="assignee_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.assignedName === ""
                          ? `All`
                          : upperFirst(this.state.assignedName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.assignedOption}
                      config={this.state.assigneeConfig.config}
                      anchorEl={this.state.assigneeConfig.anchorElAssignee}
                      searchValue={this.state.searchAssigneeValue}
                      handleClick={this.handleAssigneeSelect}
                      closeCustomPopup={this.closeAssigneeFilterMenu}
                      onSearchFilter={this.onAssigneeSearchFilter}
                      placeholder="Search Assignee"
                    />
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openAccountFilterMenu}
                    >
                      {this.state.accountName !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">{this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"}: </label>
                      )}
                      <label
                        aria-label="account_filter"
                        aria-controls="account_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.accountName === ""
                          ? `All`
                          : upperFirst(this.state.accountName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.accountOption}
                      config={this.state.accountConfig.config}
                      anchorEl={this.state.accountConfig.anchorElAccount}
                      searchValue={this.state.searchAccountValue}
                      handleClick={this.handleAccountSelect}
                      closeCustomPopup={this.closeAccountFilterMenu}
                      onSearchFilter={this.onAccountSearchFilter}
                      placeholder="Search Account"
                    />
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openServiceTypeFilterMenu}
                    >
                      {this.state.serviceTypeName !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Service Type: </label>
                      )}
                      <label
                        aria-label="assignee_filter"
                        aria-controls="service_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.serviceTypeName === ""
                          ? `All`
                          : upperFirst(this.state.serviceTypeName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.serviceTypeOption}
                      config={this.state.serviceTypeConfig.config}
                      anchorEl={this.state.serviceTypeConfig.anchorElService}
                      searchValue={this.state.searchTypeServiceValue}
                      handleClick={this.handleServiceTypeSelect}
                      closeCustomPopup={this.closeServiceTypeFilterMenu}
                      onSearchFilter={this.onServiceTypeSearchFilter}
                      placeholder="Search ServiceType"
                    />
                    <div
                      className="new_custom_filter_div"
                      onClick={this.openEmergencyFilterMenu}
                    >
                      {this.state.emergencyFilterName !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Priority: </label>
                      )}
                      <label
                        aria-label="status_filter"
                        aria-controls="status_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.emergencyFilterName === ""
                          ? `All`
                          : upperFirst(this.state.emergencyFilterName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectComponent
                      filterArray={emergencyOptions}
                      config={this.state.emergencyConfig.config}
                      anchorEl={this.state.emergencyConfig.anchorElStatus}
                      handleClick={this.handleEmergencySelect}
                      closeCustomPopup={this.closeEmergencyFilterMenu}
                    />
                  </Suspense>
                  <div className="new_custom_filter_div">
                    <label className="leftName">Date Range: </label>
                    <RangePicker
                      className="filter_date_range "
                      name="datepicker"
                      format="MM-DD-YYYY"
                      separator="-"
                      placeholder={["MM-DD-YYYY", "MM-DD-YYYY"]}
                      suffixIcon={
                        <img
                          className="carret"
                          alt="carret"
                          src={carret_down}
                        />
                      }
                      onChange={(date, dateString) =>
                        this.onDateChanage(dateString)
                      }
                      defaultValue={[defaultStartDate !== null && (moment(defaultStartDate)), defaultEndDate !== null && (moment(defaultEndDate))]}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <CssTextField
              id="outlined-basic"
              name="taskSerch"
              variant="outlined"
              type="text"
              placeholder="Search Here"
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon} alt="searchIcon" />
                  </InputAdornment>
                ),
              }}
              value={this.state.taskSerch}
              onChange={this.taskSearch}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "1%" }}>#</TableCell>
                    <TableCell style={{ width: "22%" }}>TASK</TableCell>
                    <TableCell style={{ width: "12%" }}>TASK STATUS</TableCell>
                    <TableCell style={{ width: "10%" }}>ASSIGNEE</TableCell>
                    <TableCell style={{ width: "12%" }}>{this.state.cmsLabelChange.Department ? this.state.cmsLabelChange.Department.toUpperCase() : "DEPARTMENT"}</TableCell>
                    <TableCell style={{ width: "12%" }}>SERVICE TYPE</TableCell>
                    <TableCell style={{ width: "16%" }}>
                      START DATE & TIME
                    </TableCell>
                    <TableCell style={{ width: "16%" }}>
                      END DATE & TIME
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablecell_container">
                  {this.props?.isLoading ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={this.props?.isLoading}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ) : this.props.pageOfItems &&
                    this.props.pageOfItems?.length > 0 ? (
                    (this.state.rowsPerPage > 0
                      ? this.props.pageOfItems.slice()
                      : this.props.pageOfItems
                    ).map((row, index) => (
                      <StyledTableRow key={row._id}>
                        <TableCell onClick={() => this.navigateDetail(row)} >
                          {(this.props.pager.currentPage - 1) *
                            this.state.pagesize +
                            (index + 1)}
                        </TableCell>
                        {row.isEmergency === true ? (
                          <TableCell onClick={() => this.navigateDetail(row)} className="priority_icon_wrapper">
                            <img
                              className="priority_icon"
                              src={emergency} alt="emergency"
                            ></img>
                            <span>
                              {row.service
                                ? row.service.charAt(0).toUpperCase() +
                                row.service.slice(1)
                                : "-"}{" "}
                              at {row.account_name}
                            </span>
                          </TableCell>

                        ) :
                          (row.scheduleRules?.isRecurring === true || row.isRecurringTask === true) ?
                            (<TableCell onClick={() => this.navigateDetail(row)}>
                              {row.service
                                ? row.service.charAt(0).toUpperCase() +
                                row.service.slice(1)
                                : "-"}{" "}
                              at {row.account_name}
                              {row.status === "assigned" ?
                                <img
                                  className="priority_icon"
                                  src={recurring_assigned} alt="recurring"
                                ></img> :
                                row.status === "Pending" ?
                                  <img
                                    className="priority_icon"
                                    src={recurring_pending} alt="recurring"
                                  ></img> : row.status === "inprogress" ?
                                    <img
                                      className="priority_icon"
                                      src={recurring_inprogress} alt="recurring"
                                    ></img> : row.status === "completed" ?
                                      <img
                                        className="priority_icon"
                                        src={recurring_completed} alt="recurring"
                                      ></img> : row.status === "uncovered" ?
                                      <img
                                          className="priority_icon"
                                          src={recurring_uncovered} alt="recurring"
                                        ></img> : row.status === "Unassigned" ?
                                        <img
                                          className="priority_icon"
                                          src={recurring_unassigned} alt="recurring"
                                          ></img> : row.status === "cancelled" ?
                                            <img
                                              className="priority_icon"
                                              src={recurring_cancelled} alt="recurring"
                                            ></img> : ""
                              }
                            </TableCell>) :
                            (<TableCell onClick={() => this.navigateDetail(row)}>
                              {row.service !== ""
                                ? row.service.charAt(0).toUpperCase() +
                                row.service.slice(1)
                                : "-"}{" "}
                              at {row.account_name}
                            </TableCell>)
                        }

                        {row.status.toLowerCase() === "unassigned" ? (
                          <TableCell onClick={() => this.navigateDetail(row)}>
                            {" "}
                            <span style={{ color: "hsla(228, 100%, 69%, 1)" }}>
                              {row.status
                                ? row.status.charAt(0).toUpperCase() +
                                row.status.slice(1)
                                : "-"}
                            </span>
                          </TableCell>
                        ) : row.status.toLowerCase() === "pending" ? (
                          <TableCell onClick={() => this.navigateDetail(row)}>
                            {" "}
                            <span style={{ color: "hsla(0, 100%, 61%, 1)" }}>
                              {row.status
                                ? row.status.charAt(0).toUpperCase() +
                                row.status.slice(1)
                                : "-"}
                            </span>
                          </TableCell>
                        ) : row.status.toLowerCase() === "assigned" ? (
                          <TableCell onClick={() => this.navigateDetail(row)}>
                            {" "}
                            <span style={{ color: "#617FFF" }}>
                              {row.status
                                ? row.status.charAt(0).toUpperCase() +
                                row.status.slice(1)
                                : "-"}
                            </span>
                          </TableCell>
                        ) : row.status.toLowerCase() === "inprogress" ? (
                          <TableCell onClick={() => this.navigateDetail(row)}>
                            {" "}
                            <span style={{ color: "#0022FF" }}>
                              {row.status
                                ? "In Progress"
                                : "-"}
                            </span>
                          </TableCell>
                        ) : row.status.toLowerCase() === "completed" ? (
                          <TableCell onClick={() => this.navigateDetail(row)}>
                            {" "}
                            <span style={{ color: "#21BF73" }}>
                              {row.status
                                ? row.status.charAt(0).toUpperCase() +
                                row.status.slice(1)
                                : "-"}
                            </span>
                          </TableCell>
                        ) : row.status.toLowerCase() === "cancelled" ? (
                          <TableCell onClick={() => this.navigateDetail(row)}>
                            {" "}
                            <span style={{ color: "#979797" }}>
                              {row.status
                                ? row.status.charAt(0).toUpperCase() +
                                row.status.slice(1)
                                : "-"}
                            </span>
                          </TableCell>
                        ) : row.status.toLowerCase() === "uncovered" ? (
                          <TableCell onClick={() => this.navigateDetail(row)}>
                            {" "}
                            <span style={{ color: "#617FFF" }}>
                              {row.status
                                ? row.status.charAt(0).toUpperCase() +
                                row.status.slice(1)
                                : "-"}
                            </span>
                          </TableCell>
                        ) : row.status === "" ? (
                          <TableCell onClick={() => this.navigateDetail(row)}>
                            {" "}
                            <span style={{ color: "#617FFF" }}>
                              {row.status
                                ? row.status.charAt(0).toUpperCase() +
                                row.status.slice(1)
                                : "-"}
                            </span>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell onClick={() => this.navigateDetail(row)}>
                          {row.assigned_to
                            ? row.assigned_to.charAt(0).toUpperCase() +
                            row.assigned_to.slice(1)
                            : "-"}
                        </TableCell>
                        <TableCell onClick={() => this.navigateDetail(row)}>
                          {row.department_details
                            ? row.department_details.charAt(0).toUpperCase() +
                            row.department_details.slice(1)
                            : "-"}
                        </TableCell>
                        <TableCell onClick={() => this.navigateDetail(row)}>
                          {row.service_type_name
                            ? row.service_type_name.charAt(0).toUpperCase() +
                            row.service_type_name.slice(1)
                            : "-"}
                        </TableCell>
                        <TableCell onClick={() => this.navigateDetail(row)}>
                          {" "}
                          {momentTimeZone(row.startDate)
                            .tz(this.state.timeZoneName)
                            .format("MM-DD-YYYY hh:mm A")}
                        </TableCell>
                        <TableCell onClick={() => this.navigateDetail(row)}>
                          {" "}
                          {momentTimeZone(row.endDate)
                            .tz(this.state.timeZoneName)
                            .format("MM-DD-YYYY hh:mm A")}
                        </TableCell>
                        <TableCell onClick={() => this.navigateDetail(row)}>
                          <div
                            className="table_action_btns"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <span
                              className="navbars">
                              <LightTooltip title="View Task" {...this.state.tooltipprops} arrow>
                                <div
                                >
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={OpenMenu}
                                    alt="openmenu"
                                  ></img>
                                </div>
                              </LightTooltip>
                            </span>

                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ))
                  ) :
                    < StyledTableRow >
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        No data found
                      </TableCell>
                    </StyledTableRow>

                  }
                </TableBody>
                <TableFooter>
                  {this.props.pageOfItems &&
                    this.props.pageOfItems.length !== 0 ?
                    <TableRow>
                      <td colSpan={20} className="pagination_container">
                        <CustomPaginationComponent
                          count={this.props.pager.endPage}
                          rowsPerPage={this.state.pagesize}
                          page={this.state.nextpage}
                          onPageChange={this.handleCustomPaginagtionChange}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </td>
                    </TableRow>
                    : null}
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
    return <div>{TaskPage}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
    pager: state.taskpagination.pager,
    pageOfItems: state.taskpagination.pageOfItems,
    isLoading: state.taskpagination.isLoading,
  };
};

let apiCallCount = 0;
const onClickHandler = (count) => {
  apiCallCount = count + 1;
  localStorage.setItem('apiCallCount',apiCallCount)
  return apiCallCount;
};

const mapDispatchToProps = function (dispatch) {
  return {
    onPagination: function (pageNo, pagerole,account,service,assignedToValue,status,priority,search,startDate,endDate) {
      const updatedCount = onClickHandler(apiCallCount);
      dispatch(action.taskpagination(pageNo, pagerole,account,service,assignedToValue,status,priority,search,startDate,endDate,updatedCount));
    },
    onSearchByCompany: function (value) {
      const updatedCount = onClickHandler(apiCallCount);
      dispatch(action.onSearchByCompany(value, updatedCount));
    },
    onSearchByStatus: (value) => dispatch(action.onSearchByStatus(value)),
    onSearchByAssigend: (value) => dispatch(action.onSearchByAssigend(value)),
    onSearchByDate: (value) => dispatch(action.onSearchByDate(value)),
    onSearchByAccount: (value) => dispatch(action.onSearchByAccount(value)),
    onSearchByService: (value) => dispatch(action.onSearchByService(value))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TaskComponent));
