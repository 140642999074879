import * as actionTypes from "../Actions/ActionTypes";
import { UpdateObjects } from "../../SharedComponents/UpdateObject";

/**
 * initializing pagination states
 */
const initialState = {
    isLoading: false,
    error: null,
    pager: {},
    pageOfItems: []
};

/**
 * updating initial state of table
 */
const paginationRequest = (state, action) => {
    return UpdateObjects(state, {
        isLoading: true,
        error: null,
        pager: {},
        pageOfItems: [],
        responseData:{}
    });
};

const paginationSuccess = (state, action) => {
    return UpdateObjects(state, {
        isLoading: false,
        error: null,
        pager: action.pager,
        pageOfItems: action.pageOfItems,
        responseData: action.responseData
    });
};

const paginationFailure = (state, action) => {
    return UpdateObjects(state, {
        isLoading: false,
        error: action.error,
        pager: {},
        pageOfItems: [],
        responseData:{},
        error:{},
    });
};

/**
 *  Table reducer 
 */
const TableReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PAGINATION_REQUEST: return paginationRequest(state, action);
        case actionTypes.PAGINATION_SUCCESS: return paginationSuccess(state, action);
        case actionTypes.PAGINATION_FAILURE: return paginationFailure(state, action);
        default: return state;
    }
}


export default TableReducer;