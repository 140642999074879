import React, { Component, Suspense } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  DialogTitle,
  Select,
  Grid,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import addAccountIcon from "../../Assets/images/add_account_icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import add_dept_icon from "../../Assets/images/add_dept_icon.svg";
import IconButton from "@material-ui/core/IconButton";
import { AxiosInstance, checkCmsLabel } from "../../Utilities";
import "./AccountsComponent.css";
import Menu from "@material-ui/core/Menu";
import { Autocomplete, Alert } from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import {
  emailRegx,
  phoneRegx,
} from "../../SharedComponents/Validation/formValidation";
import { connect } from "react-redux";
import * as action from "../../Store/Actions/index";
import { withRouter } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import {
  getGooglePlaceObjectValues,
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { Transition } from '../../SharedComponents/CommonUtilities/CommonUtilities';
var AutoCompleteMapComponent = React.lazy(() =>
  import(
    "../../SharedComponents/AutoCompleteMapComponent/AutoCompleteMapComponent"
  )
);
var DepartmentComponent = React.lazy(() => import("./DepartmentComponent"));
var AutocompleteMap = React.lazy(() => import("./AutocompleteMap"));
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
var i = 0;



class AddAccountModal extends Component {
  constructor(props) {
    super(props);
    this.dialogContent = React.createRef()  
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      cmsLabelChange:[],
      accountName: "",
      eventLocation:[],
      lng:"",
      lat:"",
      accountId: "",
      accountAddress: "",
      depError: "",
      accountWebsite: "",
      accountDescription: "",
      role: userData.role,
      responseMsg: "",
      alertType: "",
      companySize: userData.companySize,
      departments: [
        {
          index: Math.random(),
          deptName: "",
          depError: "",
          contacts: [
            {
              index: Math.random(),
              cname: "",
              phone: [
                {
                  index: Math.random(),
                  phoneNo: "",
                  phoneType: "work",
                  phoneErr: "",
                },
              ],
              email: [
                {
                  index: Math.random(),
                  emailid: "",
                  emailType: "work",
                  emailErr: "",
                },
              ],
              isPrimary: false,
            },
          ],
        },
      ],
      accountOption: [
        {
          id: "one",
          name: "",
          type: "new",
        },
      ],
      filters: {
        company: [],
        area: [],
        division: [],
        region: [],
        territory: [],
      },
      formError: {
        accountName: "",
        accountAddress: "",
        accountWebsite: '',
      },
      isValid: false,
      anchorEl: false,
      anchorElData: "",
      parentOption: [
        {
          id: "",
          name: "No Data",
        },
      ],
      parentId: "",
      parentName: "",
      selectedParent: "",
      autocompleteVisible: false,
      city: "",
      area: "",
      state: "",
      zipcode: "",
      mapPosition: {
        lat: "",
        lng: "",
      },
      markerPosition: {
        lat: "",
        lng: "",
      },
      levelError: "",
      level: "",
      levelName: "",
      levelId: "",
      division_id: "",
      area_id: "",
      region_id: "",
      territory_id: "",
      isShow: false,
      inValid: true,
      selectTeam: false,
      accttype: "",
      isSubmit: false,
      isdep:false,
      disable: false,
    };
    this.addRowHandler = this.addRowHandler.bind(this);
    this.onAccountSubmit = this.onAccountSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.openParent = this.openParent.bind(this);
    this.closeParent = this.closeParent.bind(this);
    this.parentListHandler = this.parentListHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.autocompleteClose = this.autocompleteClose.bind(this);
    // this.AutocompleteHandler = this.AutocompleteHandler.bind(this);
  }
  componentDidMount = () => {
    const fetchCmsData = checkCmsLabel()
      if(fetchCmsData){
        this.setState({ cmsLabelChange : fetchCmsData.cmsData})
      }
    setTimeout(() => {
      this.temModel()
    }, 500);

  }
  temModel = () => {
    if (this.props.teamData.teamModel === true) {
      this.openDialog();
    } else {
      this.setState({
        dialog: false,
      })
    }
  }
  addRowHandler = (e) => {
    this.setState(
      (prevState) => ({
        departments: [
          ...prevState.departments,
          {
            index: Math.random(),
            deptName: "",
            contacts: [
              {
                index: Math.random(),
                cname: "",
                phone: [
                  {
                    index: Math.random(),
                    phoneNo: "",
                    phoneType: "work",
                    phoneErr: "",
                  },
                ],
                email: [
                  {
                    index: Math.random(),
                    emailid: "",
                    emailType: "work",
                    emailErr: "",
                  },
                ],
                isPrimary: false,
              },
            ],
          },
        ],
      }),
      () => {
        i++;
      }
    );
  };
  deleteRowHandler = (index) => {
    const taskList1 = [...this.state.departments];
    taskList1.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  addContHandler = (id) => {
    const elementsIndex = this.state.departments.findIndex(
      (element) => element.index == id
    );
    let newArray = [...this.state.departments];
    let newId = newArray[elementsIndex].contacts.length;
    newArray[elementsIndex].contacts = Object.values({
      ...newArray[elementsIndex].contacts,
      [newId]: {
        index: Math.random(),
        cname: "",
        phone: [
          {
            index: Math.random(),
            phoneNo: "",
            phoneType: "work",
            phoneErr: "",
          },
        ],
        email: [
          {
            index: Math.random(),
            emailid: "",
            emailType: "work",
            emailErr: "",
          },
        ],
        isPrimary: false,
      },
    });
    this.setState({ departments: newArray });
  };
  deleteContactRowHandler = (depId, index) => {
    const elementsIndex = this.state.departments.findIndex(
      (element) => element.index == depId
    );
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  addPhoneHandler = (did, cid) => {
    const deptIndex = this.state.departments.findIndex(
      (element) => element.index == did
    );
    let newArray = [...this.state.departments];
    let newId = newArray[deptIndex].contacts[cid].phone.length;
    newArray[deptIndex].contacts[cid].phone = Object.values({
      ...newArray[deptIndex].contacts[cid].phone,
      [newId]: {
        index: Math.random(),
        phoneNo: "",
        phoneType: "work",
        phoneErr: "",
      },
    });
    this.setState({ departments: newArray });
  };
  deletePhoneRowHandler = (depId, contId, index) => {
    const elementsIndex = this.state.departments.findIndex(
      (element) => element.index == depId
    );
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts[contId].phone.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  addEmailHandler = (did, cid) => {
    const deptIndex = this.state.departments.findIndex(
      (element) => element.index == did
    );
    let newArray = [...this.state.departments];
    let newId = newArray[deptIndex].contacts[cid].email.length;
    newArray[deptIndex].contacts[cid].email = Object.values({
      ...newArray[deptIndex].contacts[cid].email,
      [newId]: {
        index: Math.random(),
        emailid: "",
        emailType: "work",
        emailErr: "",
      },
    });
    this.setState({ departments: newArray });
  };
  deleteEmailRowHandler = (depId, contId, index) => {
    const elementsIndex = this.state.departments.findIndex(
      (element) => element.index == depId
    );
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts[contId].email.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  departments
  onChangeHandler = (e) => {
    e.preventDefault();
    const { formError } = this.state;

    if (["deptName"].includes(e.target.name)) {
      let departments = [...this.state.departments];

     if(e.target.value.length === 0) {
        departments[e.target.dataset.id]['depError'] = "";
        this.setState({isdep:false})
       } else if (!/\S\w*/.test(e.target.value)) {
        departments[e.target.dataset.id]['depError'] = "* Please enter valid department name";
        this.setState({isdep:true})
      }else {
        departments[e.target.dataset.id]['depError'] = "";
        this.setState({isdep:false})
      }
      var dep = this.state.departments.filter(value => value.deptName === e.target.value)
      if (dep.length > 0) {
        this.setState({ depError: "Department name already exists" })
      } else {
        this.setState({ depError: "" })
      }
      departments[e.target.dataset.id][e.target.name] = e.target.value;
    } else if (["cname"].includes(e.target.name)) {
      let contacts = [...this.state.departments[e.target.dataset.did].contacts];
      contacts[e.target.dataset.cid][e.target.name] = e.target.value;
    } else if (["phoneNo", "phoneType"].includes(e.target.name)) {
      let phone = [
        ...this.state.departments[e.target.dataset.did].contacts[
          e.target.dataset.cid
        ].phone,
      ];
      if (e.target.name === "phoneNo") {
        if (
          !phoneRegx.test(e.target.value) &&
          e.target.value.trim().length > 0
        ) {
          phone[e.target.dataset.pid]["phoneErr"] =
            "* Please enter a vaild phone number";
          this.setState({ isSubmit: true, disable: true })
        } else {
          phone[e.target.dataset.pid]["phoneErr"] = "";
          this.setState({ isSubmit: false, disable: false })
        }
      }
      phone[e.target.dataset.pid][e.target.name] = e.target.value;
      this.setState({ phone });
    } else if (["emailid", "emailType"].includes(e.target.name)) {
      let email = [
        ...this.state.departments[e.target.dataset.did].contacts[
          e.target.dataset.cid
        ].email,
      ];
      if (e.target.name === "emailid") {
        if (
          !emailRegx.test(e.target.value) &&
          e.target.value.trim().length > 0
        ) {
          email[e.target.dataset.eid]["emailErr"] = "* Pleas enter an email address";
          this.setState({ isSubmit: true, disable: true })
        } else {
          email[e.target.dataset.eid]["emailErr"] = "";
          this.setState({ isSubmit: false, disable: false })
        }
      }
      email[e.target.dataset.eid][e.target.name] = e.target.value;
      this.setState({ email });
    } else if (["accountWebsite"].includes(e.target.name)) {
      var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      var regex = new RegExp(expression);
      if (!e.target.value.match(regex) && e.target.value.trim().length > 0) {
        formError.accountWebsite = "* Enter valid website url";
      } else {
        formError.accountWebsite = "";
        this.setState({ accountWebsite: e.target.value })
      }
      this.setState({ formError })
    } else if (["isPrimary"].includes(e.target.name)) {
      let contacts = [...this.state.departments[e.target.dataset.did].contacts];
      contacts[e.target.dataset.cid]["isPrimary"] = !contacts[e.target.dataset.cid]["isPrimary"];
    } else {
      this.setState({ [e.target.name]: e.target.value });

    }
  };
  onAccountSubmit = (e) => {
    e.preventDefault();
    const {
      formError,
      accountName,
      accountAddress,
      accountWebsite,
      city,
      state,
      zipcode,
      parentId,
      departments,
      accountDescription,
      mapPosition,
      accountId,
      selectTeam,
      accttype,
      territoryFilter,
    } = this.state;

    if (!this.state.isValid && this.state.depError === "") {
      this.setState({ isSubmit: true });
      if (accttype === "old") {
        let data = {
          accountId: accountId,
          territoryId: territoryFilter,
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
          .get(`/account/add-territory/${accountId}/${territoryFilter}`)
          .then((resp) => {


            if (resp.data.status === "204" || resp.data.status === "200" || resp.data.status === 200) {
              this.onCloseModal();
              this.props.onPagination(0, 10)
              this.setState(
                {
                  loading: true,
                  showdelete: false,
                  isSubmit: false,
                },
                () => {
                  var props = {
                    message: resp.data.msg,
                    icon: <img src={iconNotificationSuccess} alt="success" />,
                    type: "success",
                    placement: "topRight",
                    duration: 3,
                    top: 200,
                    className: "task_notification update-notify",
                  };
                  NotificationComponent(props);
                  this.props.onPagination(0, 10)
                }
              );

            }
            else {
              this.setState({
                isSubmit: false,
                responseMsg: resp.data.msg,
                alertType: "error",
              });
              var props = {
                message: resp.data.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props);
              this.props.onPagination(0, 10)
            }

            // if (resp.data.status === "200" || resp.data.status === 200) {
            //   this.props.onClose()
            //   this.props.onPagination(0, 10)
            // } else {
            //   this.setState({
            //     isSubmit: false,
            //     responseMsg: resp.data.msg,
            //     alertType: "error",
            //   });
            // }

          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      } else {

        let data = {
          accountName: accountName,
          territoryId: territoryFilter,
          accountAddress: accountAddress,
          accountWebsite: accountWebsite,
          accountDescription: accountDescription,
          departments: departments,
          parentId: parentId,
          city: city,
          state: state,
          zipcode: zipcode,
          coordinates: this.state.eventLocation,
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/account", data)
          .then((resp) => {

            if (resp.data.status === "201" || resp.data.status === "200" || resp.data.status === 200) {
              this.props.onClose()
              this.props.onPagination(0, 10)
              this.setState(
                {
                  loading: true,
                  showdelete: false,
                  isSubmit: false,
                },
                () => {
                  var props = {
                    message: resp.data.msg,
                    icon: <img src={iconNotificationSuccess} alt="success" />,
                    type: "success",
                    placement: "topRight",
                    duration: 3,
                    top: 200,
                    className: "task_notification update-notify",
                  };
                  NotificationComponent(props);
                }
              );

            }
            else {
              this.setState({
                isSubmit: false,
                responseMsg: resp.data.msg,
                alertType: "error",
              });
              var props = {
                message: resp.data.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props);

            }

          })


          //   if (resp.data.status === "201" || resp.status === 201) {
          //     this.props.onClose()
          //     this.props.onPagination(0, 10)
          //   } else {
          //     this.setState({ isSubmit: false });
          //   }
          // })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      }
    }
  };
  onclickBtn = (e) => {
      // window.scrollTo({ top: 0, behavior: 'smooth' })

  // this.myRef.curren .scrollTop = this.listContainer.scrollHeight;
  this.dialogContent.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
       });
   const {
      formError,
      accountName,
      accountId,
      selectTeam,
      accountAddress,
      accttype,
    } = this.state;
    if (accttype === "old") {
      if (accountName === "" || accountId === "" || selectTeam === false) {
        this.setState({ isValid: true });
      } else {
        this.setState({ isValid: false });
      }
    } else {
      if (
        accountName === "" ||
        accountId === "" ||
        accountAddress === "" ||
        selectTeam === false
      ) {
        this.setState({ isValid: true });
      } else {
        this.setState({ isValid: false });
      }
    }
  };
  openParent = (event) => {
    //this.setState({ anchorEl : true, anchorElData : event.currentTarget});
    this.setState({ autocompleteVisible: true });
  };
  closeParent = () => {
    this.setState({ anchorEl: false, anchorElData: "" });
  };
  parentListHandler = async (event) => {
    var name = event.target.value;
    var data = {
      searchText: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/parentAccount", data)
      .then((res) => {
        if (res.data.status == "200") {
          this.setState({
            parentOption: res.data.data,
          });
        } else {
          this.setState({
            parentOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    var { parentOption } = this.state;
    var data = await parentOption.filter((item) => {
      return item.name === name;
    });
    data.map((parentData) => {
      this.setState({
        parentId: parentData.id,
        parentName: parentData.name,
      });
    });
  };
  onSelectHandler = (e) => {
    var name = e.target.value;
    var { parentOption } = this.state;
    var data = parentOption.filter((item) => {
      return item.name === name;
    });
    data.map((parentData) => {
      this.setState({
        parentId: parentData.id,
        parentName: parentData.name,
        selectedParent: parentData.name,
      });
    });
  };

  autocompleteClose = (event) => {
    var name = event.target.value;
    if (typeof name === "undefined") {
      this.setState({ autocompleteVisible: false });
    }
  };


  // AutocompleteHandler = (place) => {
  //   const { formError } = this.state;
  //   formError.accountAddress = '';
  //   this.setState({
  //     accountAddress: place.address,
  //     city: place.city,
  //     state: place.state,
  //     zipcode: place.zipcode,
  //     mapPosition: place.mapPosition,
  //     formError
  //   });
  // };

  onGooglePlaceSelect = (place) => {
    var { formError } = this.state;
    formError.accountAddress = '';
    this.setState({ formError });
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    this.setState({
      accountAddress: formattedPlace.address,
      city: formattedPlace.city,
      state: formattedPlace.state,
      zipcode: formattedPlace.zipcode,
      mapPosition: formattedPlace.mapPosition,
      formError,


      Address: formattedPlace.address,
      eventAddress: formattedPlace.address,
      eventLocation: {lng:formattedPlace.lng, lat:formattedPlace.lat},

    });
    console.log(this.state.mapPosition, this.state.Address, this.state.zipcode)
  };
  onlevelClose = () => {
    this.setState({
      dialog: !this.state.dialog,

    });
  };
  onLevel = () => {
    this.setState({
      dialog: true,
    });
  };
  openDialog = () => {
    var { role } = this.state;
    this.setState(
      {
        dialog: true,
      },
      () => {
        let data = {
          // companyId: role === 'super admin' ? '' : companyId,
          roles: role,
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'user', "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/users/filters", data)
          .then((resp) => {
            if (resp.status === 200) {
              this.setState({ filters: resp.data.data.data });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      }
    );
  };
  Userfilter = (event) => {
    let name = event.target.name;
    let filterdata = "";
    this.setState(
      { [event.target.name]: event.target.value, loading: true },
      () => {
        const {
          companyFilter,
          divisionFilter,
          areaFilter,
          regionFilter,
          territoryFilter,
        } = this.state;
        switch (name) {
          case "companyFilter":
            filterdata = {
              companyData: this.state.filters.company,
              companyId: companyFilter,
            };
            break;
          case "divisionFilter":
            this.setState(
              {
                areaFilter: "",
                regionFilter: "",
                territoryFilter: "",
              },
              () => { }
            );
            filterdata = {
              companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              divisionId: divisionFilter,
            };
            break;
          case "areaFilter":
            this.setState(
              {
                regionFilter: "",
                territoryFilter: "",
              },
              () => { }
            );
            filterdata = {
              companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              areaData: this.state.filters.area,
              areaId: areaFilter,
            };

            break;
          case "regionFilter":
            this.setState(
              {
                territoryFilter: "",
              },
              () => { }
            );
            filterdata = {
              companyData: this.state.filters.company,
              divisionData: this.state.filters.division,
              areaData: this.state.filters.area,
              regionData: this.state.filters.region,
              regionId: regionFilter,
            };
            break;
          default:
            break;
        }

        if (territoryFilter !== "") {
          this.setState({ inValid: false });
        } else {
          this.setState({ inValid: true });
        }
        if (
          name !== "roleFilter" &&
          name !== "territoryFilter" &&
          name !== "searchFilter"
        ) {
          AxiosInstance(
            (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : 'user',
            "application/x-www-form-urlencoded; charset=UTF-8"
          )
            .post("/users/filters", filterdata)
            .then((resp) => {
              if (resp.status === 200) {
                this.setState({ filters: resp.data.data });
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
            });
        }
      }
    );
  };
  addLevelOnClick = () => {
    this.setState({
      dialog: false,
      selectTeam: true,
    });
  };
  accountListHandler = async (e) => {
    var name = e.target.value;
    this.setState({ accountName: name });
    var data = {
      search: name,
    };
    if (e.target.value.length > 100) {
      this.setState({
        accountError: "* Account should not exceed 100 characters.",
      });
    } else {
      this.setState({ accountError: "" });
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/account/search", { params:data })
      .then((res) => {
        if (res.data.status == "200") {
          var finaldata = res.data.data;
          var datas = [];
          finaldata.map((data) => {
            var accopt = {};
            accopt.id = data._id;
            accopt.name = data.accountName;
            accopt.type = "old";
            datas.push(accopt);
          });
          this.setState({
            //setData
            accountOption: datas,
          });
        } else if(res.data.status == "404" || res.data.status == 404){
          if(this.state.role.toLowerCase() !== "super admin"){
            this.setState({
              isShow: false,
              // accountOption: [
              //   {
              //     id: name,
              //     name: name,
              //     type: "new",
              //   },
              // ],
              // accttype: "new",
              // accountName: name,
            });
          }else{
            this.setState({
              isShow: true,
              accountOption: [
                {
                  id: name,
                  name: name,
                  type: "new",
                },
              ],
              accttype: "new",
              accountName: name,
            });
          }
        
        }else{
          this.setState({
            isShow: true,
            accountOption: [
              {
                id: name,
                name: name,
                type: "new",
              },
            ],
            accttype: "new",
            accountName: name,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    var { accountOption, formError } = this.state;
    var data = await accountOption.filter((item) => {
      return item.name === name;
    });
    data.map((accountData) => {
      this.setState({
        accountName: accountData.name,
        accountId: accountData.id,
        accttype: accountData.type,
        [formError.accountId]: "",
      });
    });
  };

  onSelectactHandler = (e) => {
    if (!e.target.value) {
      this.accountListHandler(e);
      this.setState({ accttype: "new" });
    } else {
      var name = e.target.value;
      var { accountOption, formError } = this.state;
      var data = accountOption.filter((item) => {
        return item.name === name;
      });
      if (data.length !== 0) {
        data.map((accountData) => {
          this.setState({
            accountId: accountData.id,
            accountName: accountData.name,
            accttype: accountData.type,
            [formError.accountId]: "",
            isdep:false,
            isShow: false
          });
        });
      } else {
        if(this.state.role.toLowerCase() !== "super admin"){
          this.setState({
            accttype: "",
            isShow:false,
            accountName:""
          })
        }else{        
        this.setState({ accttype: "", accountName: accountOption[0].name });
        }
      }
    }
  };
  onCloseModal = () => {
    this.setState({       departments: [
      {
        index: Math.random(),
        deptName: "",
        depError: "",
        contacts: [
          {
            index: Math.random(),
            cname: "",
            phone: [
              {
                index: Math.random(),
                phoneNo: "",
                phoneType: "work",
                phoneErr: "",
              },
            ],
            email: [
              {
                index: Math.random(),
                emailid: "",
                emailType: "work",
                emailErr: "",
              },
            ],
            isPrimary: false,
          },
        ],
      },
    ],
    isShow: false, responseMsg: "", alertType: "", isSubmit: false, isValid: false, selectTeam: false, accountName: '' ,}, () => {
      this.props.onClose()
    });
  };

  render() {
    const inputProps = {
      step: 500,
    };
    const { departments, role } = this.state;
    let err_msg_name = "";
    if (
      (this.state.territoryFilter === "" || this.state.selectTeam === false) &&
      this.state.accountName !== ""
    ) {
      err_msg_name = "one-msg";
    } else {
      err_msg_name = "two-msg";
    }
    return (
      <div 
     >

        <Dialog 
          {...this.props}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog add_account_dialog"
          TransitionComponent={Transition}
          transitionDuration={600}
          disableBackdropClick
          onExit={this.onCloseModal}
      
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <img className="add-company_img" src={addAccountIcon}></img>
            <span>Add {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Accounts"}</span>
            <IconButton
              onClick={this.onCloseModal}
              className="closeBtn"
              color="inherit"
            >
              <img src={closeBtn}></img>
            </IconButton>
          </DialogTitle>
          <Scrollbars
            className="custom_scrollbar"
            style={this.state.isShow === true ? { width: "auto", height: 680 } : { width: "auto", height: 185 }}>
            <DialogContent
                ref={node => {
                  this.dialogContent = node;
                }}
            >
              {this.state.responseMsg !== "" ? (
                <Alert severity={this.state.alertType}>
                  {this.state.responseMsg}
                </Alert>
              ) : null}
              <form onSubmit={this.onAccountSubmit} >
                <label
                  className={
                    this.state.autocompleteVisible === false ? "" : "hide", "label bl-label"
                  }
                >
                  Name <span className="labelAsterisk">*</span>
                </label>
                <div
                  className={
                    "add_account_name " +
                    (this.state.autocompleteVisible === false
                      ? "custom_upload"
                      : "hide")
                  }
                >
                  {/* <CssTextField className="account_custom_upload" id="outlined-basic" name='accountName' variant="outlined" autoFocus margin="dense" type="text"  placeholder="Enter name" error={this.state.accountName === '' && this.state.isValid ? true : false} onChange={this.onChangeHandler}/> */}
                  <span className="add_account_name_input">
                    <Autocomplete
                      id="accountName"
                      className="cusautocomplete account_custom_upload"
                      options={this.state.accountOption}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            this.state.accountName === "" && this.state.isValid
                              ? true
                              : false
                          }
                          variant="outlined"
                          name="accountName"
                          margin="dense"
                          placeholder="Search here"
                          onChange={this.accountListHandler}
                          onSelect={this.onSelectactHandler}
                        />
                      )}
                    />
                    <span className="error">
                      {this.state.accountName === "" && this.state.isValid ? (
                        '* Please enter an Account Name'
                      ) : (
                          ""
                        )}
                    </span>
                  </span>
                  <span className="add_team_btn">
                    <Button
                      className="upload_btn primary-btn"
                      variant="contained"
                      onClick={this.openDialog}
                    >
                      Add Team {this.state.selectTeam ? <CheckIcon /> : ""}
                    </Button>
                    <span className="error">
                      {(this.state.territoryFilter === "" ||
                        this.state.selectTeam === false) &&
                        this.state.isValid ? (
                          '* Please select a territory'
                        ) : (
                          ""
                        )}
                    </span>
                  </span>
                  {/* <Button className="upload_btn"  variant="contained" onClick={this.openParent}>
            Select Parent
           </Button> */}

                  <Menu
                    id="customized-menu"
                    anchorEl={this.state.anchorElData}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    keepMounted
                    open={this.state.anchorEl}
                    onClose={this.closeParent}
                  ></Menu>
                </div>
                <div
                  className={
                    this.state.autocompleteVisible === false ? "hide" : ""
                  }
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <label>Name</label>
                      <CssTextField
                        className="account_custom_upload"
                        id="outlined-basic"
                        name="accountName"
                        variant="outlined"
                        autoFocus
                        margin="dense"
                        fullWidth
                        type="text"
                        placeholder="Enter name"
                        error={
                          this.state.formError.accountName ? (
                            <span style={{ color: "red", fontSize: "16px" }}>
                              {this.state.formError.accountName}
                            </span>
                          ) : (
                              ""
                            )
                        }
                        onChange={this.onChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label className="label bl-label">Parent Account Name</label>
                      <Autocomplete
                        id="parentName"
                        freeSolo
                        className="parent-autocomplete"
                        options={this.state.parentOption}
                        getOptionLabel={(option) => option.name}
                        onInputChange={this.autocompleteClose}
                        //clearOnEscape= { this.autocompleteClose }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="parentName"
                            placeholder="Search here"
                            onChange={this.parentListHandler}
                            onSelect={this.onSelectHandler}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                {this.state.formError.accountName ? (
                  <span className="error">
                    {this.state.formError.accountName}
                  </span>
                ) : (
                    ""
                  )}
                <span
                  className={
                    this.state.accttype === "old" ? "hide-act" : "show-act"
                  }
                >

                  {this.state.isShow === true ?
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <label className="label bl-label">Website</label>
                          <CssTextField
                            id="outlined-basic"
                            name="accountWebsite"
                            variant="outlined"
                            margin="dense"
                            type="text"
                            fullWidth
                            placeholder="Enter website"
                            error={this.state.formError.accountWebsite !== '' ? true : false}
                            onChange={this.onChangeHandler}
                          />

                          <span className="error">
                            {this.state.formError.accountWebsite ?
                              this.state.formError.accountWebsite
                              : (
                                ""
                              )}
                          </span>

                        </Grid>
                      </Grid>
                      <label className="label bl-label">
                        Address <span className="labelAsterisk">*</span>
                      </label>
                      <Suspense fallback={<div></div>}>

                        <AutoCompleteMapComponent
                          onPlaceSelect={this.onGooglePlaceSelect}
                        />
                      </Suspense>


                      <span className="error">
                        {this.state.accountAddress === "" && this.state.isValid ? (
                          '* Please enter the accounts address'
                        ) : (
                            ""
                          )}
                      </span>


                      <label className="label bl-label">Description</label>
                      <CssTextField
                        id="outlined-basic"
                        name="accountDescription"
                        variant="outlined"
                        margin="dense"
                        type="text"
                        fullWidth
                        placeholder="Enter Description"
                        rowsMax={3}
                        multiline
                        className="Desc_height text_area"
                        onChange={this.onChangeHandler}
                      />

                      <Suspense fallback={<div></div>}>
                        <DepartmentComponent
                          modelData={this.state}
                          departments={departments}
                          addRow={this.addRowHandler}
                          deleteRow={this.deleteRowHandler.bind(this)}
                          addcontact={this.addContHandler}
                          deleteContactRow={this.deleteContactRowHandler}
                          addPhone={this.addPhoneHandler}
                          deletePhoneRow={this.deletePhoneRowHandler}
                          addEmail={this.addEmailHandler}
                          deleteEmailRow={this.deleteEmailRowHandler}
                          onChangeHandler={this.onChangeHandler}
                        />
                      </Suspense>
                      <span className="error">{this.state.depError}</span>

                      <Button
                        className="add_dept_btn"
                        variant="outlined"
                        onClick={this.addRowHandler}
                      >
                        <img src={add_dept_icon} />
                     Add department
                    </Button>
                    </>
                    : ""}
                </span>
                <div className="add_company_action">
                  <Button
                    className="cancel_btn white-btn"
                    onClick={this.onCloseModal}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="ok_btn green-btn"
                    type="submit"
                    variant="contained"
                    onClick={this.onclickBtn}
                    disabled={this.state.isSubmit || this.state.isdep}
                  >
                    {this.state.isSubmit && !this.state.disable ? <CircularProgress size={20} /> : ""}
                    Add {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"}
                </Button>
                </div>
              </form>
            </DialogContent>
          </Scrollbars>
          {/* <DialogActions>
          <Button onClick={this.props.onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={this.props.onClose} color="primary" style={{backgroundColor: '#21bf73'}} variant="contained">
            Add Account
          </Button>
        </DialogActions> */}
        </Dialog>
        <Dialog
          open={this.state.dialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog"
          TransitionComponent={Transition}
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            {/* <img className="add-company_img" src={addUserIcon}></img> */}
            <span>Add Team</span>
            <IconButton
              onClick={this.onlevelClose}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn}></img>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {this.state.levelError === "" ? (
              ""
            ) : (
                <Alert severity="error">{this.state.levelError}</Alert>
              )}
            {/* admin for medium start*/}
            {role.toLowerCase() === "admin" &&
              this.state.companySize === "large" ? (
                <>
                  <label className="label bl-label">Division</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      margin="dense"
                      className="customSelect"
                      placeholder="Division"
                      name="divisionFilter"
                      fullWidth
                      onChange={this.Userfilter}
                    >
                      <MenuItem value="">None</MenuItem>
                      {this.state.filters !== undefined &&
                        this.state.filters.division.length !== 0 ? (
                          this.state.filters.division.map((val) => (
                            <MenuItem key={val._id} value={val._id}>
                              {val.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem></MenuItem>
                        )}
                    </Select>
                  </FormControl>
                </>
              ) : (
                ""
              )}
            {(role.toLowerCase() === "admin" ||
              role.toLowerCase() === "division manager") &&
              this.state.companySize !== "small" ? (
                <>
                  <label className="label bl-label">Area</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      className="customSelect"
                      placeholder="Area"
                      name="areaFilter"
                      fullWidth
                      margin="dense"
                      onChange={this.Userfilter}
                    >
                      <MenuItem value="">None</MenuItem>
                      {this.state.filters !== undefined &&
                        this.state.filters.area.length !== 0 ? (
                          this.state.filters.area.map((area) => (
                            <MenuItem key={area._id} value={area._id}>
                              {area.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem></MenuItem>
                        )}
                    </Select>
                  </FormControl>
                  <span className="error"></span>
                </>
              ) : (
                ""
              )}
            {role.toLowerCase() === "admin" ||
              role.toLowerCase() === "division manager" ||
              role.toLowerCase() === "area manager" ? (
                <>
                  <label className="label bl-label">Region</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      className="customSelect"
                      placeholder="Region"
                      name="regionFilter"
                      fullWidth
                      margin="dense"
                      onChange={this.Userfilter}
                    >
                      <MenuItem value="">None</MenuItem>
                      {this.state.filters !== undefined &&
                        this.state.filters.region.length !== 0 ? (
                          this.state.filters.region.map((region) => (
                            <MenuItem key={region._id} value={region._id}>
                              {region.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem></MenuItem>
                        )}
                    </Select>
                  </FormControl>
                  <span className="error"></span>
                </>
              ) : (
                ""
              )}
            <label className="label bl-label">Territory</label>
            <FormControl variant="outlined" fullWidth>
              <Select
                className="customSelect"
                placeholder="Territory"
                name="territoryFilter"
                fullWidth
                margin="dense"
                onChange={this.Userfilter}
              >
                <MenuItem value="">None</MenuItem>
                {this.state.filters !== undefined &&
                  this.state.filters.territory.length !== 0 ? (
                    this.state.filters.territory.map((territory) => (
                      <MenuItem value={territory._id}>{territory.name}</MenuItem>
                    ))
                  ) : (
                    <MenuItem></MenuItem>
                  )}
              </Select>
            </FormControl>
            <span className="error"></span>
            <div className="add_company_action">
              <Button
                className="cancel_btn white-btn"
                onClick={this.onlevelClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                // disabled={this.state.levelError === "" ? true : false}
                className="ok_btn green-btn"
                onClick={this.addLevelOnClick}
                variant="contained"
                disabled={this.state.inValid}
              >
                Select Team
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pageSize) =>
      dispatch(
        action.accountpagination(pageNo, pageSize)
      ),
  };
};
export default connect(null,
  mapDispatchToProps
)(withRouter(AddAccountModal));
