import React, { Component, Suspense } from "react";
import "./CalendarComponent.css";
import { Box, Button, Grid } from "@material-ui/core";
import { Popover, Tooltip } from "antd";
import DirectionsIcon from "@material-ui/icons/Directions";
import { Calendar, ConfigProvider } from "antd";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import { AxiosInstance } from "../../Utilities";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentTimeZone from "moment-timezone";
import moment from "moment";
import "moment/locale/en-nz";
import en_GB from 'antd/lib/locale-provider/en_GB';
import 'moment/locale/en-gb';
import {
  getGooglePlaceObjectValues,
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { upperFirst } from "lodash";
import { emailRegx } from "../../SharedComponents/Validation/formValidation";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationUpdate from "../../Assets/images/icon-notify-update.svg";
import recurring_assigned from "../../Assets/images/recurring_assigned.svg";
import recurring_pending from "../../Assets/images/recurring_pending.svg";
import recurring_inprogress from "../../Assets/images/recurring_inprogress.svg";
import recurring_completed from "../../Assets/images/recurring_completed.svg";
import recurring_uncovered from "../../Assets/images/recurring_uncovered.svg";
import resetBtn from "../../Assets/images/reset.svg";
import carret_down from "../../Assets/images/carret_down.svg";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { Select } from 'antd';
import userIcon from "../../Assets/images/user-icon.png";
const { Option } = Select;
// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
var AddEventComponent = React.lazy(() =>
  import("../EventComponent/AddEventComponent/AddEventComponent")
);
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
var CancelTaskModalComponent = React.lazy(() =>
  import("../TaskComponent/TaskDetails/RecurringComponent/CancelTaskModalComponent")
);

class CalendarComponent extends Component {
  calendarComponentRef = React.createRef();
  // dialogContent = React.createRef();
  constructor(props) {
    super(props);
    var zoneName = momentTimeZone.tz.guess();
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var tempDate = momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName);
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      this.daysInMonth(date.getMonth() + 1, date.getFullYear())
    );
    var zoneNameForTer = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    var fdate = momentTimeZone(firstDay).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD");
    var ldate = momentTimeZone(lastDay).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD");
    var calenderTypeData = JSON.parse(localStorage.getItem("calendarType"))
    var calendarTypeItems = calenderTypeData === "me" || calenderTypeData === "All" || calenderTypeData === "team" || calenderTypeData === "area"
        ? calenderTypeData
        : calenderTypeData?.name !== ""
        ? calenderTypeData?.name
        : "me";
    var calendarEventData = localStorage.getItem("eventType");
    var eventTypeItems =
    calendarEventData === "All"
    ? "All"
    : calendarEventData === "Task"
    ? "task"
    : calendarEventData === "Work Event"
    ? "meeting"
    : calendarEventData === "Personal Event"
    ? "personal"
    : "All";
    this.state = {
      profile_id:"",
      levelId:"",
      recurringTask: "",
      scheduleRules: "",
      timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
      userRole: userData.role,
      value: momentTimeZone(tempDate).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
      selectedValue: momentTimeZone(tempDate).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
      events: [],
      eventStatus: [],
      recurrenceTaskStatus: "",
      scheduleRule: false,
      calendarType: "",
      recurrenceDateId: "",
      customView: "dayGridMonth",
      taskStatus: "",
      taskName: "",
      service_name: "",
      account_name: "",
      taskId: "",
      taskType: "",
      mainTaskId: "",
      assignedTo: "",
      additionalRepresentative: "",
      startDate: "",
      endDate: "",
      lat: "",
      long: "",
      dateChange: "timeGridDay,timeGridWeek,dayGridMonth",
      addEventFormError: {
        eventType: "",
        eventTitle: "",
        eventDescription: "",
        eventAttendees: "",
        eventLocation: "",
        eventDateError: "",
      },
      addEventOpen: false,
      cancelTaskOpen: false,
      noteError: "",
      taskMessage: "",
      recurrenceTaskId: "",
      eventPriority: false,
      eventType: "",
      eventType_type: "1",
      eventTitle: "",
      filteredData: {},
      eventAttendeesArray: [],
      attendeesDefault: [],
      eventAttendees: [],
      eventTypeOption: null,
      eventDescription: "",
      eventAddress: "",
      organizer: "",
      eventLocation: null,
      isLocation: true,
      checkId: false,
      isMultipleAttendees: true,
      selectValues: [],
      hideAttendeeField: false,
      isAddEventSubmit: false,
      statusFilterName: "",
      territoryName: "",
      salesrepName: "",
      salesrepId: "",
      territoryOption: [],
      salesrepOption: [],
      territoryId: "",
      searchTerritoryValue: "",
      searchSalesrepValue: "",
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      territoryConfig: {
        anchorElTerritory: null,
        config: {
          id: "territory_filter_select",
        },
      },
      salesrepConfig: {
        anchorElSalesrep: null,
        config: {
          id: "salesrep_filter_select",
        },
      },
      firstDate: fdate,
      lastDate: ldate,
      startDates: "",


      ccEmails: [],
      formError: {
        cc: '',
      },

      dateRangeProps: {
        startDate: {
          initialViewDate: momentTimeZone()
            .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            value: ""
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate: momentTimeZone()
            .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control"
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
      },
      territoryFilterForTer: [],
      regionFilter:[],
      area:"",
      currentDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), zoneNameForTer).startOf("month").utc(),
      endDateForTer: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), zoneNameForTer).endOf("month").utc(),
      weekStartDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), zoneNameForTer).startOf("week").utc(),
      weekEndDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), zoneNameForTer).endOf("week").utc(),
      filterTerritoryId: "",
      calendarDate: moment(),
      calendarTypeItem: calendarTypeItems,
      eventTypeItem: eventTypeItems
    };
  }

  /**
   * @author Murali
   * To get events and tasks for the current month
   */
  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      const calendarTypeData = localStorage.getItem("calendarType");
      const eventTypeData = localStorage.getItem("eventType");
      if (!calendarTypeData && !eventTypeData) {
        localStorage.setItem("calendarType", JSON.stringify("me"));
        localStorage.setItem("eventType", "All");
      }
      this.setState({
        statusFilterName:localStorage.getItem("eventType")
      })
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6009
          : "level",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .get("/getDivisionList")
        .then((resp) => {
          if (resp.data.status === 200) {
            this.setState({ region: resp.data.data, loading: false }, () => {
              if (this.state.region.length !== 0) {
                this.fecthRegionFilters(this.state.region[0]._id);
              }
            });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    });

    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6006
        : "user",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .get("/profile")
      .then((resp) => {
        if (resp.data.status === "200") {
          this.setState({ profile_id: resp.data._id });
          this.profileId(resp.data.data._id);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });

    if (this.state.userRole.toLowerCase() === "sales rep") {
      this.fetchDefaultAttendee();
      this.setState({
        hideAttendeeField: true,
        assignedTo: "me",
        dateChange: "timeGridDay,timeGridWeek,dayGridMonth"
      });
    }
    this.fecthTerritoryFilters("");
    this.fetchEventTypeList({ search: "" });
    var firstDay = momentTimeZone()
      .tz(this.state.timeZoneName)
      .startOf("month")
      .utc();
    var lastDay = momentTimeZone()
      .tz(this.state.timeZoneName)
      .endOf("month")
      .utc();
    let calenderData, eventData;
    if (this.state.calendarTypeItem === "me"  ) {
      calenderData = {
        startDate: firstDay,
        endDate: lastDay,
        assignedTo: "me",
      };
      this.setState({
        dateChange: "timeGridDay,timeGridWeek,dayGridMonth",
      });
      this.calendarComponentRef.current.getApi().changeView("dayGridMonth");
    } else if (this.state.calendarTypeItem === "All") {
      calenderData = {
        startDate: firstDay,
        endDate: lastDay,
        assignedTo: "All",
      };
      this.setState({
        dateChange: "timeGridDay,timeGridWeek",
      });
      this.calendarComponentRef.current.getApi().changeView("timeGridWeek");
    } else if (
      this.state.calendarTypeItem === "area" &&
      this.state.userRole === "Area Manager"
    ) {
      calenderData = {
        startDate: firstDay,
        endDate: lastDay,
        assignedTo: "area",
        areaId: localStorage.getItem("areaFilterId"),
      };
      this.setState({
        dateChange: "timeGridDay,timeGridWeek",
      });
      this.calendarComponentRef.current.getApi().changeView("timeGridWeek");
    } else if (
      this.state.calendarTypeItem === "team" &&
      this.state.userRole !== "Area Manager" 
    ) {
      calenderData = {
        startDate: firstDay,
        endDate: lastDay,
        assignedTo: "team",
      };
      this.setState({
        dateChange: "timeGridDay,timeGridWeek",
      });
      this.calendarComponentRef.current.getApi().changeView("timeGridWeek");
    }
    else if (
      this.state.userRole === "Area Manager" && this.state.calendarTypeItem
    ) {
      calenderData = {
        startDate: firstDay,
        endDate: lastDay,
        assignedTo: "team",
        regionId: JSON.parse(localStorage.getItem("calendarType"))?._id,
      };
      this.setState({
        dateChange: "timeGridDay,timeGridWeek",
      });
      this.calendarComponentRef.current.getApi().changeView("timeGridWeek");
    }else if (
      this.state.userRole === "Area Manager"
    ) {
      calenderData = {
        startDate: firstDay,
        endDate: lastDay,
        assignedTo: "me",
      };
      this.setState({
        dateChange: "timeGridDay,timeGridWeek,dayGridMonth",
      });
      this.calendarComponentRef.current.getApi().changeView("dayGridMonth");
    } else if(this.state.calendarTypeItem) {
      calenderData = {
        startDate: firstDay,
        endDate: lastDay,
        territoryId: JSON.parse(localStorage.getItem("calendarType"))?._id,
      };
      this.setState({
        dateChange: "timeGridDay,timeGridWeek",
      });
      this.calendarComponentRef.current.getApi().changeView("timeGridWeek");
    } else {
      calenderData = {
        startDate: firstDay,
        endDate: lastDay
      };
      this.setState({
        dateChange: "timeGridDay,timeGridWeek,dayGridMonth",
      });
      this.calendarComponentRef.current.getApi().changeView("dayGridMonth");
    }
    if (this.state.eventTypeItem !== "All") {
      eventData = {
        type : this.state.eventTypeItem
      }
    }
    let data = Object.assign(calenderData, eventData);
    this.setState({
      filteredData: data,
      filterTerritoryId: "me",
      calendarTypeItem: "me",
    });
    this.fetchCalenderDetail(data);
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6012
        : "calender",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/calendar", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            events: resp.data.data,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  fetchEventTypeList = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .get("/eventTypeList", {
        params:data
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            eventTypeOption: response.data,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  }
  fetchCalenderDetail = (data) => {
    this.setState({
      checkId: false
    })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6012 : 'calender', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/calendar", data)
      .then((resp) => {
        var response = resp && resp.data;

        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            events: resp.data.data,
            eventStatus: resp.data.status
          });
          if (this.state.taskId) {
            resp.data.data.map((item) => {
              if (item._id == this.state.taskId) {
                this.setState({
                  checkId: false
                })
              }
            })
          }
          else {
            this.setState({
              checkId: true
            })
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  profileId=(data)=>{
    let datas={
      employee_id:data
    }
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .get(`/user-detail/${data}`)
        .then((resp) => {
          this.setState({levelId:resp.data.data.levelId})
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
  }

  fecthRegionFilters = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : 'level', "application/json")
      .post("/getLevelDetail", {
        data,
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (response.status === "200" || response.status === 200) {
          var data = response.data;
          this.setState({ regionFilter: data.region, area:data.area })
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
        this.setState({
          searchLoader: false,
        });
      });
  };

  fecthTerritoryFilters = (search) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : 'level', "application/json")
      .post("/dashboardLevelFilter", {
        search,
        limit: 15,
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (response.status === "200" || response.status === 200) {
          var data = response.data;
          this.setState({ territoryFilterForTer: data })
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
        this.setState({
          searchLoader: false,
        });
      });
  };

onterritoryChange = (value) => {
    if (
      this.state.userRole.toLowerCase() === "team lead" ||
      this.state.userRole.toLowerCase() === "sales rep" ||
      this.state.userRole.toLowerCase() === "territory manager"
    ) {
      var item = this.state.territoryFilterForTer;
    }
    if (this.state.userRole.toLowerCase() === "area manager") {
      var item = this.state.regionFilter;
      localStorage.setItem("areaFilterId",item[0].area_id)
      localStorage.setItem("calendarType", value ? JSON.stringify(value) : JSON.stringify("me"));
    }
    if (value === "me" || value === "team") {
      localStorage.setItem("calendarType", value ? JSON.stringify(value) : JSON.stringify("me"));
    } else {
      item.map((item) => {
        if (item._id == value) {
          localStorage.setItem(
            "calendarType",
            item ? JSON.stringify(item) : JSON.stringify("me")
          );
        }
      });
    }

    const { calendarDate } = this.state;
    this.setState({ filterTerritoryId: value, events: [], eventStatus: [] });
    var fdate = momentTimeZone(calendarDate._d)
      .tz(this.state.timeZoneName)
      .startOf("week")
      .utc();
    var ldate = momentTimeZone(calendarDate._d)
      .tz(this.state.timeZoneName)
      .endOf("week")
      .utc();
    let data1;
    if (this.state.statusFilter) {
      data1 = { type: this.state.statusFilter };
    }
    if (value === "me") {
      this.setState({
        dateChange: "timeGridDay,timeGridWeek,dayGridMonth",
      });
      this.calendarComponentRef.current.getApi().changeView("dayGridMonth");
      let data2 = {
        assignedTo: value,
        startDate: momentTimeZone(calendarDate._d)
          .tz(this.state.timeZoneName)
          .startOf("month")
          .utc(),
        endDate: momentTimeZone(calendarDate._d)
          .tz(this.state.timeZoneName)
          .endOf("month")
          .utc(),
      };
      let data = Object.assign(data2, data1);
      this.setState({
        filteredData: data,
      });
      this.fetchCalenderDetail(data);
    } else if (value === "team") {
      this.setState({
        dateChange: "timeGridDay,timeGridWeek",
      });
      this.calendarComponentRef.current.getApi().changeView("timeGridWeek");
      let data2 = {
        assignedTo: value,
        startDate: fdate,
        endDate: ldate,
      };
      let data = Object.assign(data2, data1);
      this.setState({
        filteredData: data,
      });
      this.fetchCalenderDetail(data);
    } else if (value === "area") {
      this.setState({
        dateChange: "timeGridDay,timeGridWeek",
      });
      this.calendarComponentRef.current.getApi().changeView("timeGridWeek");
      let data2 = {
        assignedTo: value,
        areaId: this.state.levelId,
        startDate: fdate,
        endDate: ldate,
      };
      let data = Object.assign(data2, data1);
      this.setState({
        filteredData: data,
      });
      this.fetchCalenderDetail(data);
    } else {
      this.setState({
        dateChange: "timeGridDay,timeGridWeek",
      });
      this.calendarComponentRef.current.getApi().changeView("timeGridWeek");
      if (this.state.userRole.toLowerCase() === "area manager") {
        var data2 = {
          assignedTo: "team",
          regionId: value,
          startDate: fdate,
          endDate: ldate,
        };
      } else {
        var data2 = {
          territoryId: value,
          startDate: fdate,
          endDate: ldate,
        };
      }
      let data = Object.assign(data2, data1);
      this.setState({
        filteredData: data,
      });
      this.fetchCalenderDetail(data);
    }
    if (value === "") {
      this.fecthTerritoryFilters("");
    }
};

clearBtnHandler = () => {
    localStorage.setItem("calendarType", JSON.stringify("me"));
    localStorage.setItem("eventType", "All");
    window.location.href = "/calendar";
};

  onSearch = (search) => {
    this.fecthTerritoryFilters(search);
  }
  /**
   * @author Murali
   * @param {month,year}
   * To get firstdate and last date of a month
   */
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  /**
   * @author Murali
   * @param {event}
   * To get details of the selected event/task
   */
  eventClick = ({ event }) => {
    var recurringData = event.extendedProps?.scheduleRules;
    this.setState({
      recurrenceTaskStatus: event.extendedProps.status,
      recurrenceDateId: event.extendedProps.dateId,
      calendarType: event.extendedProps.type,
      checkId: true,
      scheduleRule: recurringData?.isRecurring ? recurringData?.isRecurring : false
    })
    var recurrenceData = {}
    var normalData = {}
    var recurrenceStatus = event.extendedProps.status;
    var recurrenceDateId = event.extendedProps.dateId;
    if (recurrenceStatus === "AutoGenerated") {
      recurrenceData = {
        id: event._def.extendedProps._id,
        type: event._def.extendedProps.type,
        status: event.extendedProps.status,
        dateId: event.extendedProps.dateId
      }
    } else {
      normalData = {
        id: event._def.extendedProps._id,
        type: event._def.extendedProps.type,
      }
    }
    var calendarData = Object.assign(recurrenceData, normalData);
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6012 : 'calender', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/calendar/detail", calendarData)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let responseData = response.data[0];
          this.setState({
            taskStatus:
              responseData.taskStatus !== undefined
                ? responseData.taskStatus
                : "",
            taskName: responseData.title,
            service_name: responseData.service_name,
            account_name: responseData.account_name,
            organizer: responseData.organizer,
            taskId: responseData._id,
            taskType: responseData.type,
            assignedTo:
              responseData.assignedTo !== "" ||
                responseData.assignedTo !== undefined
                ? responseData.assignedTo
                : "",
            additionalRepresentative: [responseData.additionalRepresentative !== "" ||
              responseData.additionalRepresentative !== undefined
              ? responseData.additionalRepresentative
              : ""],
            startDate: momentTimeZone(responseData.start).tz(this.state.timeZoneName).format("MMM DD, YYYY h:mm A"),
            endDate: momentTimeZone(responseData.end).tz(this.state.timeZoneName).format("MMM DD, YYYY h:mm A"),
            lat: responseData?.location ? responseData?.location[0] : "",
            long: responseData?.location ? responseData?.location[1] : "",
            mainTaskId: responseData?.mainTaskId ? responseData?.mainTaskId : "",
            isRecurring: responseData.isRecurring ? responseData.isRecurring : false,
            scheduleRules: responseData.scheduleRules ? responseData.scheduleRules : ""

          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Murali
   * @param {events}
   * To display the events/tasks based on status
   */
  formatEvents(events) {
    return events.map((event) => {
      const { title, end, start, taskStatus, type, account_name, service_name } = event;
      var task_title = `${this.capitalizeTaskName(service_name ? service_name : "")} in ${this.capitalizeTaskName(account_name ? account_name : "")}`;
      var taskTitle = ((task_title === " in ") ? upperFirst(title) : task_title)
      let startTime = momentTimeZone(start)
        .tz(this.state.timeZoneName)
        .format("YYYY-MM-DD");
      let endTime = momentTimeZone(end)
        .tz(this.state.timeZoneName)
        .format("YYYY-MM-DD");
      let startTime1 = momentTimeZone(start)
        .tz(this.state.timeZoneName).format()
      let endTime1 = momentTimeZone(end)
        .tz(this.state.timeZoneName).format()
      if (startTime === endTime) {
        return {
          title: taskTitle,
          start: this.getDate(startTime1),
          end: this.getDate(endTime1),
          className:
            type === "task"
              ? taskStatus === "unassigned"
                ? "unassigned"
                : taskStatus === "pending"
                  ? "pending"
                  : taskStatus === "assigned"
                    ? "assigned"
                    : taskStatus === "inprogress"
                      ? "inprogress"
                      : taskStatus === "completed"
                        ? "completed"
                        : taskStatus === "cancelled"
                          ? "cancelled"
                          : "uncovered"
              : "event-class",
          extendedProps: { ...event },
        };
      } else {
        return {
          title: taskTitle,
          start: this.getDate(startTime1),
          end: this.getDate(endTime1),
          className:
            type === "task"
              ? taskStatus === "unassigned"
                ? "unassigned"
                : taskStatus === "pending"
                  ? "pending"
                  : taskStatus === "assigned"
                    ? "assigned"
                    : taskStatus === "inprogress"
                      ? "inprogress"
                      : taskStatus === "completed"
                        ? "completed"
                        : taskStatus === "cancelled"
                          ? "cancelled"
                          : "uncovered"
              : "event-class",
          extendedProps: { ...event },
        };
      }
    });
  }
  /**
   * @author Murali
   * @param {dayString}
   * To format date string to date
   */
  getDate(dayString) {
    const today = new Date();
    // const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    return dayString;
  }
  /**
   * @author Murali
   * @param {date/time} value
   * To get the data for particular day/month
   */
  onSelect = (value) => {
    this.setState({ calendarDate: value })
    const { filterTerritoryId } = this.state
    let data, data1, data2;
    if (this.state.statusFilter) {
      data1 = { type: this.state.statusFilter }
    }
    this.setState({ value: value }, () => {
      var fdate = momentTimeZone(value._d).tz(this.state.timeZoneName).startOf("week").utc();
      var ldate = momentTimeZone(value._d).tz(this.state.timeZoneName).endOf("week").utc();
      this.setState({ firstDate: fdate, lastDate: ldate });
      if (filterTerritoryId === "me") {
        this.calendarComponentRef.current
          .getApi()
          .changeView('dayGridMonth')
        data2 = {
          assignedTo: filterTerritoryId,
          startDate: momentTimeZone(value._d).tz(this.state.timeZoneName).startOf("month").utc(),
          endDate: momentTimeZone(value._d).tz(this.state.timeZoneName).endOf("month").utc(),
        };
        data = Object.assign(data2, data1)
        this.setState({
          filteredData: data
        })
        this.fetchCalenderDetail(data)
      }
      if (filterTerritoryId === "team") {
        data2 = {
          startDate: fdate,
          endDate: ldate,
          assignedTo: filterTerritoryId,
        };
        data = Object.assign(data2, data1)
        this.fetchCalenderDetail(data);
      } else if(filterTerritoryId === "area"){
        data2 = {
          startDate: fdate,
          endDate: ldate,
          assignedTo: filterTerritoryId,
          areaId:this.state.levelId
        };
        data = Object.assign(data2, data1)
        this.fetchCalenderDetail(data);
      } else if (filterTerritoryId !== "me" && filterTerritoryId !== "" && this.state.userRole.toLowerCase() === "area manager") {
        data2 = {
          startDate: fdate,
          endDate: ldate,
          regionId: filterTerritoryId,
          assignedTo:"team"
        };
        data = Object.assign(data2, data1)
        this.fetchCalenderDetail(data);
      } else if (filterTerritoryId !== "me" && filterTerritoryId !== "") {
        data2 = {
          startDate: fdate,
          endDate: ldate,
          territoryId: filterTerritoryId
        };
        data = Object.assign(data2, data1)
        this.fetchCalenderDetail(data);
      } else if (filterTerritoryId === "") {
        data2 = {
          startDate: momentTimeZone(value._d).tz(this.state.timeZoneName).startOf("month").utc(),
          endDate: momentTimeZone(value._d).tz(this.state.timeZoneName).endOf("month").utc(),
        };
        data = Object.assign(data2, data1)
        this.fetchCalenderDetail(data);
      }
      var val = momentTimeZone(value._d)
        .tz(this.state.timeZoneName)
        .format("YYYY-MM-DD");
      let calendarApi = this.calendarComponentRef.current.getApi();
      calendarApi.gotoDate(val);
    });
  };
  /**
   * @author Murali
   * @param {lat,lon}
   * To open map view based on latitude and longitude
   */
  gotoMap = (lat, lon) => {
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" + lat + "," + lon
    );
  };

  onAddEventModal = () => {
    this.setState({
      addEventOpen: true,
    });
  };

  cancelTask = () => {
    this.setState({
      cancelTaskOpen: true,
    });
  }
  onAddEventClose = () => {
    var { dateRangeProps, addEventFormError, timeZoneName } = this.state;
    dateRangeProps.startDate.initialViewDate = momentTimeZone().tz(timeZoneName);
    dateRangeProps.endDate.initialViewDate = momentTimeZone().tz(timeZoneName);
    addEventFormError.eventType = "";
    addEventFormError.eventTitle = "";
    addEventFormError.eventDescription = "";
    addEventFormError.eventAttendees = "";
    addEventFormError.eventLocation = "";
    addEventFormError.eventDateError = "";

    this.setState({
      addEventOpen: false,
      eventPriority: false,
      eventType: "",
      eventType_type: "",
      eventTitle: "",
      eventAttendeesArray: [],
      attendeesDefault: [],
      eventDescription: "",
      eventAddress: "",
      eventLocation: null,
      dateRangeProps,
      isLocation: true,
      isMultipleAttendees: true,
      selectValues: [],
      hideAttendeeField: false,
      isAddEventSubmit: false,
      cancelValue: "",
      addEventFormError,
    });
  };
  searchEventTypes = (value) => {

    this.fetchEventTypeList({ search: value });
  }
  onEventChangeHandler = (e) => {
    var { name, value } = e.target;
    const { addEventFormError } = this.state;
    switch (name) {
      case "eventPriority":
        this.setState({ [name]: e.target.checked });
        break;
      case "eventTitle":
        value?.trim().length <= 0
          ? addEventFormError.eventTitle = "Please enter an event title"
          : addEventFormError.eventTitle = ""
        this.setState({ eventTitle: value, addEventFormError });
        break;
      case "eventDescription":
        this.setState({ eventDescription: value });
        break;
      default:
        break;
    }
  };
  onEventTypeChangeHandler = (value) => {
    var {
      isLocation,
      isMultipleAttendees,
      hideAttendeeField,

      addEventFormError,
    } = this.state;
    var type = value.split("type")[1];
    if (type === "0" || type === 0) {
      if (
        this.state.userRole.toLowerCase() === "admin" ||
        this.state.userRole.toLowerCase() === "area manager" ||
        this.state.userRole.toLowerCase() === "division manager" ||
        this.state.userRole.toLowerCase() === "team lead"
      ) {
        isMultipleAttendees = false;
        hideAttendeeField = false;
      } else if (this.state.userRole.toLowerCase() === "sales rep") {
        isMultipleAttendees = false;
        hideAttendeeField = true;
        this.fetchDefaultAttendee();
      } else {
        isMultipleAttendees = true;
        hideAttendeeField = false;
      }
      addEventFormError.eventType = "";
      addEventFormError.eventLocation = "";
      isLocation = false;
      this.setState({
        isMultipleAttendees,
        isLocation,
        eventType: value,
        eventType_type: type,
        hideAttendeeField,
        eventAttendees: [],
        eventAttendeesArray: [],
        attendeesDefault: [],
        addEventFormError,
        selectValues: [],
      });
    } else if (type === "1" || type === 1) {
      // if(this.state.userRole.toLowerCase() === 'sales rep' ){
      //   isMultipleAttendees=true;
      //   hideAttendeeField=false;
      //   isLocation=true;
      //   this.fetchDefaultAttendee();
      // }else{
      //   hideAttendeeField=false
      //   isLocation=true;
      //   isMultipleAttendees=true;
      // }
      addEventFormError.eventType = "";
      addEventFormError.eventLocation = "";
      hideAttendeeField = false;
      isLocation = true;
      isMultipleAttendees = true;
      this.setState({
        isMultipleAttendees,
        isLocation,
        addEventFormError,
        eventType: value,
        eventType_type: type,
        hideAttendeeField,
        eventAttendees: [],
        eventAttendeesArray: [],
        attendeesDefault: [],
        selectValues: [],
      });
    }
  };
  // handleDateSelectHandler = (value, name) => {
  //   var { eventDuration } = this.state;
  //   eventDuration = { ...this.state.eventDuration, [name]: value };
  //   this.setState({
  //     eventDuration,
  //   });
  // };
  addEventHandler = (e) => {
    e.preventDefault();
    var {

      addEventFormError,
      eventType,
      isLocation,
      eventAttendees,
      eventDescription,
      eventLocation,
      eventTitle,
      timeZoneName,
    } = this.state;
    if (this.state.dateRangeProps.startDate.inputProps.value === "" && this.state.dateRangeProps.endDate.inputProps.value === "") {
      addEventFormError.eventDateError = "Please enter start date and end date";
    } else if (
      momentTimeZone(this.state.dateRangeProps.startDate.inputProps.value)
        .tz(this.state.timeZoneName)
        .format("MM-DD-YYYY HH:mm A") >=
      momentTimeZone(this.state.dateRangeProps.endDate.inputProps.value)
        .tz(this.state.timeZoneName)
        .format("MM-DD-YYYY HH:mm A")
    ) {
      addEventFormError.eventDateError =
        "End date should not be less than or equal to start date";
    } else {
      addEventFormError.eventDateError = "";
    }
    if (eventTitle.trim().length === 0 || eventTitle.trim() === "") {
      addEventFormError.eventTitle = "Please enter an event title";
    } else if (eventTitle.trim().length >= 100) {
      addEventFormError.eventTitle =
        "Event title length should be less than 100 characters";
    } else {
      addEventFormError.eventTitle = "";
    }
    if (eventType === "") {
      addEventFormError.eventType = "Please select an event type";
    } else {
      addEventFormError.eventType = "";
    }
    // if (eventLocation === null && isLocation) {
    //   addEventFormError.eventLocation = "Please enter an event location";
    // } else {
    //   addEventFormError.eventLocation = "";
    // }
    if (eventDescription.trim().length >= 3000) {
      addEventFormError.eventDescription =
        "Event description length should be less than 3000 characters";
    } else {
      addEventFormError.eventDescription = "";
    }
    this.setState(
      {
        addEventFormError,
      },
      () => {
        const isEmpty = Object.values(addEventFormError).every(
          (x) => x === null || x === ""
        );
        if (isEmpty) {
          this.setState({
            isAddEventSubmit: true,
          });
          var attendee = this.state.eventAttendees;
          var attendeesArray = [];
          attendee.map((item) => {
            var items = item.split("name")[0];
            attendeesArray.push(items);
          });

          var data = {
            title: this.state.eventTitle,
            description: this.state.eventDescription,
            // startDate: new Date(
            //   this.state.dateRangeProps.startDate.initialViewDate
            // ).toUTCString(),
            // endDate: new Date(
            //   this.state.dateRangeProps.endDate.initialViewDate
            // ).toUTCString(),

            startDate: moment.tz(momentTimeZone(new Date(this.state.dateRangeProps.startDate.initialViewDate).toUTCString()), this.state.timeZoneName).utc(),
            endDate: moment.tz(momentTimeZone(new Date(this.state.dateRangeProps.endDate.initialViewDate).toUTCString()), this.state.timeZoneName).utc(),

            eventType_type: +this.state.eventType_type,
            attendees: attendeesArray,
            eventType: this.state.eventType.split("type")[0],
            isEmergency: this.state.eventPriority,
            ccEmails: this.state.ccEmails,
            timezone: timeZoneName,
          };
          if (this.state.isLocation && this.state.eventLocation !== null && this.state.eventAddress !== "") {
            data.location = this.state.eventLocation;
            data.address = this.state.eventAddress;
          }
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
            .post("/addEvent", data)
            .then((resp) => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                this.setState(
                  {
                    isAddEventSubmit: false,
                  },
                  () => {
                    this.onAddEventClose();
                    var date = new Date();
                    var firstDay = new Date(
                      date.getFullYear(),
                      date.getMonth(),
                      1
                    );
                    var lastDay = new Date(
                      date.getFullYear(),
                      date.getMonth(),
                      this.daysInMonth(date.getMonth() + 1, date.getFullYear())
                    );
                    var fdate = momentTimeZone().tz(this.state.timeZoneName).startOf("month").utc();
                    var ldate = momentTimeZone().tz(this.state.timeZoneName).endOf("month").utc();
                    let data = {
                      startDate: fdate,
                      endDate: ldate,
                    };
                    this.fetchCalenderDetail(data);
                    var props = {
                      message: response.msg,
                      icon: <img src={iconNotificationUpdate} alt="success" />,
                      type: "success",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification update-notify",
                    };
                    NotificationComponent(props);
                  }
                );
              } else if (
                response &&
                (response.status === "400" || response.status === 400)
              ) {
                this.setState(
                  {
                    isAddEventSubmit: false,
                  },
                  () => {
                    this.onAddEventClose();
                    var props = {
                      message: response.msg,
                      icon: <img src={iconNotificationError} alt="error" />,
                      type: "error",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification error-notify",
                    };
                    NotificationComponent(props);
                  }
                );
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
                console.log("Internal Server Error");
              }
              this.setState({
                isAddEventSubmit: false,
              });
            });
        }
      }
    );
  };

  onAttendeeSelectHandler = (e) => {
    const { value } = e.target;
    if (!value) {
      this.onAttendeeChangeHandler(e);
    }
  };

  onAttendeeChangeHandler = (value) => {
    const { addEventFormError } = this.state;
    if (this.state.isMultipleAttendees) {
      // var dataId=[];
      // var dataName=[];
      // search.map(item=>{
      //   console.log("items---",item,typeof item)

      // var vals=item.toString().split('name');

      // // console.log("value---",value,vals);
      // dataId.push(vals[0]);
      // dataName.push(vals[1]);
      // })

      addEventFormError.eventAttendees = "";
      this.setState({
        eventAttendees: value,
        selectValues: value,
        addEventFormError,
      });
    } else {
      var dataArray = [];
      var vals = value.split("name");
      dataArray.push(value);
      addEventFormError.eventAttendees = "";
      this.setState(
        {
          eventAttendees: [],
          selectValues: [],
          addEventFormError,
        },
        () => {
          this.setState({
            eventAttendees: dataArray,
            selectValues: vals[1],
          });
        }
      );
    }
  };
  onAttendeeOnFocusHandler = (e) => {
    var search = e.target.value;
    this.getEmployeeFilter(search);
  };
  onAttendeeSearch = (value) => {
    var search = value;
    this.getEmployeeFilter(search);
  };
  getEmployeeFilter = (search) => {
    this.setState(
      {
        eventAttendeesArray: [],
        // eventAttendees: [],
        attendeesDefault: [],
      },
      () => {
        let data = {
          search: search,
          endDate: this.state.dateRangeProps.endDate.initialViewDate,
          startDate: this.state.dateRangeProps.startDate.initialViewDate,
        }
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
          .post("/employeeFilterStatus", data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                eventAttendeesArray: response.data,
              });
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              let propserror = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                eventAttendeesArray: [],
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }

            this.setState({
              eventAttendeesArray: [],
            });
          });
      }
    );
  };
  fetchDefaultAttendee = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .get("/userDetail")
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var respdata = [];
          // var obj={};

          respdata.push(response.data._id + "name" + response.data.name);
          this.setState(
            {
              eventAttendees: respdata,
            }
            // ,()=>{
            //   this.setState({
            //     attendeesDefault:respdata
            //   })
            // }
          );
        } else {
          this.setState({
            eventAttendees: [],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
        this.setState({
          eventAttendees: [],
        });
      });
  };

  onGooglePlaceSelect = (place) => {
    const { addEventFormError } = this.state;
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    addEventFormError.eventLocation = "";
    this.setState({
      addEventFormError,
      eventAddress: formattedPlace.address,
      eventLocation: [formattedPlace.lng, formattedPlace.lat],
    });
  };

  /**
   * @author Benakesh
   * to navigate to task / event detail page
   */
  eventDetailView = () => {
    const { taskId } = this.state;
    const status = this.state.recurrenceTaskStatus;
    const dateId = this.state.recurrenceDateId;
    if (this.state.taskType === "event") {
      let loc =
        window.location.href.indexOf("eventdetail") > -1
          ? taskId
          : `eventdetail/${taskId}`;
      this.props.history.replace(loc);
    } else if (this.state.taskType === "task") {
      if (status === "AutoGenerated") {
        let loc = window.location.href.indexOf("task") > -1 ? taskId : `/task/${taskId}?dateId=${dateId}&status=AutoGenerated`;
        this.props.history.replace(loc);
      } else {
        let loc = window.location.href.indexOf("task") > -1 ? taskId : `task/${taskId}`;
        this.props.history.replace(loc);
      }

    }
  };

  onAddEventType = () => {
    window.location.href = "/event-types";
  };
  /**
   * @author Murali
   * To open status filter menu
   */
  openStatusFilterMenu = (event) => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author Murali
   * To close status filter menu
   */
  closeStatusFilterMenu = () => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author Murali
   * To display data based on selected status.
   */
  handleStatusSelect = (item) => {
    var {
      statusFilter,
      statusFilterName,
      statusConfig,
      firstDate,
      lastDate,
    } = this.state;
    statusFilter = item._id;
    statusFilterName = item.name;
    statusConfig.anchorElStatus = null;
    let data;
    var data1, data2;
    if ((this.state.filterTerritoryId !== "me" ||
      this.state.filterTerritoryId !== "team") && this.state.filterTerritoryId) {
      data1 = { territoryId: this.state.filterTerritoryId }
    }
    if (this.state.filterTerritoryId === "me" ||
      this.state.filterTerritoryId === "team") {
      data1 = { assignedTo: this.state.filterTerritoryId }
    }

    if (statusFilter === "") {
      data2 = {
        startDate: momentTimeZone(firstDate).tz(this.state.timeZoneName).startOf("month").utc(),
        endDate: momentTimeZone(lastDate).tz(this.state.timeZoneName).endOf("month").utc(),
      };
      data = Object.assign(data2, data1)
    } else {

      data2 = {
        startDate: momentTimeZone(firstDate).tz(this.state.timeZoneName).startOf("month").utc(),
        endDate: momentTimeZone(lastDate).tz(this.state.timeZoneName).endOf("month").utc(),
        type: statusFilter,
      };
      data = Object.assign(data2, data1)
    }
    this.setState(
      {
        statusConfig,
        statusFilterName,
        statusFilter,
      },
      () => {
        localStorage.setItem(
          "eventType",
          this.state.statusFilterName !== ""
            ? this.state.statusFilterName
            : "All"
        );
        this.setState({
          statusFilterName:localStorage.getItem("eventType")
        })
        if (this.state.filterTerritoryId === "me" || this.state.filterTerritoryId === "") {
          this.fetchCalenderDetail(data);
        } else {
          this.onterritoryChange(this.state.filterTerritoryId);
        }
      }
    );
  };
  /**
   * @author Murali
   * To open territory filter menu
   */
  openTerritoryFilterMenu = (event) => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = event.currentTarget;
    this.setState(
      {
        territoryConfig,
      },
      () => {
        this.territoryFilter("");
      }
    );
  };
  /**
   * @author Murali
   * To close territory filter menu
   */
  closeTerritoryFilterMenu = () => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = null;
    this.setState({
      territoryConfig,
    });
  };
  /**
   * @author Murali
   * To display territory based on search
   */
  onTerritorySearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchTerritoryValue: value,
    });
    this.territoryFilter(value);
  };
  /**
   * @author Murali
   * To display data based on selected territory
   */
  handleTerritorySelect = (item) => {
    const { territoryConfig, firstDate, lastDate, statusFilter } = this.state;
    territoryConfig.anchorElTerritory = null;
    let data = "";
    if (statusFilter === "") {
      data = {
        startDate: firstDate,
        endDate: lastDate,
        territory: item._id,
      };
    } else {
      data = {
        startDate: firstDate,
        endDate: lastDate,
        type: statusFilter,
        territory: item._id,
      };
    }

    this.setState(
      {
        territoryId: item._id,
        territoryName: item.name === "All" ? "" : item.name,
        territoryConfig,
        searchTerritoryValue: "",
        salesrepName: "",
        salesrepOption: [
          {
            _id: "",
            name: "All",
          },
        ],
      },
      () => {
        this.fetchCalenderDetail(data);
      }
    );
  };
  /**
   * @author Murali
   * To get territory names in filter
   */
  territoryFilter = (search) => {
    const { territoryOption } = this.state;

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : 'level', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/dashboardLevelFilter", { search })
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = territoryOption.splice();
          if (this.state.searchTerritoryValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            territoryOption: newArray,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            territoryOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };
  /**
   * @author Murali
   * To open sales rep filter menu
   */
  openSalesrepFilterMenu = (event) => {
    const { salesrepConfig, territoryId } = this.state;
    salesrepConfig.anchorElSalesrep = event.currentTarget;
    this.setState(
      {
        salesrepConfig,
      },
      () => {
        this.getterritoryEmployee(territoryId, "");
      }
    );
  };
  /**
   * @author Murali
   * To close salesrep filter menu
   */
  closeSalesrepFilterMenu = () => {
    const { salesrepConfig } = this.state;
    salesrepConfig.anchorElSalesrep = null;
    this.setState({
      salesrepConfig,
    });
  };
  /**
   * @author Murali
   * To display salesrep name based on search
   */
  onSalesrepSearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchSalesrepValue: value,
    });
    this.getterritoryEmployee(this.state.territoryId, value);
  };
  /**
   * @author Murali
   * To display data based on selected salesrep
   */
  handleSalesrepSelect = (item) => {
    const {
      salesrepConfig,
      firstDate,
      lastDate,
      statusFilter,
      territoryId,
    } = this.state;
    salesrepConfig.anchorElSalesrep = null;
    let data = "";
    if (statusFilter === "") {
      data = {
        startDate: firstDate,
        endDate: lastDate,
        territory: territoryId,
        assignedTo: item._id,
        additionalRepresentative: []
      };
    } else {
      data = {
        startDate: firstDate,
        endDate: lastDate,
        type: statusFilter,
        territory: territoryId,
        assignedTo: item._id,
        additionalRepresentative: []
      };
    }

    this.setState(
      {
        salesrepId: item._id,
        salesrepName: item.name === "All" ? "" : item.name,
        salesrepConfig,
        searchSalesrepValue: "",
      },
      () => {
        this.fetchCalenderDetail(data);
      }
    );
  };
  /**
   * @author Murali
   * To get salesrep based on selected territory
   */
  getterritoryEmployee = (id, text) => {
    const { salesrepOption } = this.state;
    let reqdata = {
      territory: id,
      searchText: text,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6012 : 'calender', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/employeeFilter", reqdata)
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = salesrepOption.splice();
          if (this.state.searchSalesrepValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            salesrepOption: newArray,
          });
        } else {
          this.setState({
            salesrepOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  onPopupClose = () => {
    setTimeout(() => {
      this.setState({
        cancelTaskOpen: false
      })
    }, 1000);
  }

  cancelTaskHandler = (item, date) => {
    var monthData = {
      assignedTo: "me",
      startDate: momentTimeZone(date).tz(this.state.timeZoneName).startOf("month").utc(),
      endDate: momentTimeZone(date).tz(this.state.timeZoneName).endOf("month").utc(),
    };
    this.setState({
      cancelValue: item
    })
    var data;
    if (item == "recurrenceOnce") {
      if (this.state.recurrenceDateId) {
        data = {
          notes: this.state.taskMessage,
          dateId: this.state.recurrenceDateId,
          timeZone: this.state.timeZoneName,
          taskStatus: "cancelled"
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
          .put(`/recurringTask/${this.state.mainTaskId}/${this.state.taskId}`, data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                cancelTaskOpen: false
              })
              var propsErr = {
                message: "Task status updated successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(propsErr);
              this.fetchCalenderDetail(monthData)

            } else {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
              this.setState({
                cancelTaskOpen: false
              })
            };
          });

      } else {
        data = {
          notes: this.state.taskMessage,
          _id: this.state.taskId,
          timeZone: this.state.timeZoneName,
          taskStatus: "cancelled"
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
          .post("/assign_task", data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                cancelTaskOpen: false
              })
              var props = {
                message: "Task status updated successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.fetchCalenderDetail(monthData)

            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
              this.setState({
                cancelTaskOpen: false
              })
              this.fetchCalenderDetail(this.state.filteredData)
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === '403' || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500)
            } else if (error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }

          });

      }
    }
    else {
      data = {
        notes: this.state.taskMessage,
        timeZone: this.state.timeZoneName
      };
      var cancelId = this.state.recurrenceDateId ? this.state.mainTaskId : this.state.taskId;
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
        .post(`/recurringTask/cancelTask/${cancelId}`, { data })
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            this.setState({
              cancelTaskOpen: false
            })
            var propsErr = {
              message: "Task status updated successfully",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(propsErr);
            this.fetchCalenderDetail(monthData)
          } else {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
            this.setState({
              cancelTaskOpen: false
            })
          };
        });
    }
  };

  //

  onChange = (newTags) => {
    const { formError } = this.state;
    formError.cc = '';
    this.setState({ ccEmails: newTags, formError })

  }
  validator = (value) => {
    const { formError } = this.state;
    if (!emailRegx.test(value)) {
      formError.cc = '* Pleas enter an email address';
      this.setState({ formError })
      return false;
    } else {
      formError.cc = '';
      this.setState({ formError })
      return true;
    }
  }
  valid = (current) => {
    // return current.isAfter(moment().subtract(1, "day"));
    return current && current < moment().subtract(1, 'day');
  };

  // disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current < moment().endOf('day');
  // }
  disabledDates = (current) => {
    return current && current.isBefore(moment(new Date(this.state.dateRangeProps.startDate.initialViewDate)));//.subtract(1, "day"));
  };
  onStartDateChange = (date, dateString) => {
    const { dateRangeProps, addEventFormError } = this.state;
    dateRangeProps.startDate.initialViewDate = momentTimeZone(date);
    dateRangeProps.startDate.inputProps.value = momentTimeZone(date);
    dateRangeProps.endDate.initialViewDate = momentTimeZone(date).add(1, 'hours');
    dateRangeProps.endDate.inputProps.value = momentTimeZone(date).add(1, 'hours');
    dateRangeProps.endDate.inputProps.disabled = false;
    addEventFormError.eventDateError = ""
    this.setState({
      dateRangeProps,
      addEventFormError
    });
  }
  onEndDateChange = (date, dateString) => {
    const { dateRangeProps, addEventFormError } = this.state;
    dateRangeProps.endDate.initialViewDate = momentTimeZone(date);
    dateRangeProps.endDate.inputProps.value = momentTimeZone(date);
    addEventFormError.eventDateError = "";
    this.setState({
      dateRangeProps,
      addEventFormError
    });
  }

  resetStartDateDialog = () => {
    const { dateRangeProps } = this.state;
    dateRangeProps.endDate.initialViewDate = momentTimeZone();
    // .tz(this.state.timeZoneName)
    // .format("MM-DD-YYYY hh:mm A");
    dateRangeProps.startDate.initialViewDate = momentTimeZone();
    // .tz(this.state.timeZoneName)
    // .format("MM-DD-YYYY hh:mm A");
    dateRangeProps.startDate.inputProps.value = ""
    dateRangeProps.endDate.inputProps.value = "";
    dateRangeProps.endDate.inputProps.disabled = true;
    this.setState({
      ccEmails: [],
      dateRangeProps,
    });
  }
  capitalizeTaskName = (value) => {
    return value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }
  eventTitles = (service_name, account_name, taskName) => {
    var task_title = `${this.capitalizeTaskName(service_name ? service_name : "")} in ${this.capitalizeTaskName(account_name ? account_name : "")}`;
    var taskTitle = ((task_title === " in ") ? upperFirst(taskName) : task_title)
    return taskTitle;
  }
  render() {
    const {
      events,
      customView,
      taskName,
      account_name,
      service_name,
      taskStatus,
      startDate,
      endDate,
      assignedTo,
      additionalRepresentative,
      lat,
      long,
      userRole,
      territoryFilterForTer,
      regionFilter,
      recurrenceTaskStatus,
      scheduleRule,
      scheduleRules,
      calendarType,
      isRecurring
    } = this.state;
    var statusOptions = [
      { _id: "meeting", name: "Work Event" },
      { _id: "personal", name: "Personal Event" },
    ];
    if (this.state.userRole.toLowerCase() === "team lead" ||
      this.state.userRole.toLowerCase() === "sales rep" ||
      this.state.userRole.toLowerCase() === "area manager"
      ) {
      statusOptions.push({ _id: "task", name: "Task" })
    }
    let className = '';
    if (userRole.toLowerCase() !== 'admin') {
      className = 'admin-only';
    } else {
      className = '';
    }
    let CompanyPage = (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        <Suspense fallback={<div></div>}>
          <AddEventComponent
            validator={this.validator}
            onChange={this.onChange}
            modelData={this.state}
            timeZoneName={this.state.timeZoneName}
            addEventOpen={this.state.addEventOpen}
            eventType={this.state.eventType}
            // eventDuration={this.state.eventDuration}
            attendees={this.state.eventAttendeesArray}
            attendeesDefault={this.state.attendeesDefault}
            addEventFormError={this.state.addEventFormError}
            eventTypeOption={this.state.eventTypeOption}
            searchEventTypes={this.searchEventTypes}
            isLocation={this.state.isLocation}
            isMultipleAttendees={this.state.isMultipleAttendees}
            selectValues={this.state.selectValues}
            isAddEventSubmit={this.state.isAddEventSubmit}
            onClose={this.onAddEventClose}
            onEventChangeHandler={this.onEventChangeHandler}
            onEventTypeChange={this.onEventTypeChangeHandler}
            // handleDateSelect={this.handleDateSelectHandler}
            onAttendeeChange={this.onAttendeeChangeHandler}
            onAttendeeSelect={this.onAttendeeSelectHandler}
            addEventHandler={this.addEventHandler}
            onGooglePlaceSelect={this.onGooglePlaceSelect}
            onAttendeeOnFocusHandler={this.onAttendeeOnFocusHandler}
            onAttendeeSearch={this.onAttendeeSearch}
            hideAttendeeField={this.state.hideAttendeeField}

            eventType_type={this.state.eventType_type}
            dateRangeProps={this.state.dateRangeProps}

            valid={this.valid}
            disabledDates={this.disabledDates}
            onStartDateChange={this.onStartDateChange}
            onEndDateChange={this.onEndDateChange}
            resetStartDateDialog={this.resetStartDateDialog}
          />
          {this.state.cancelTaskOpen &&
            <CancelTaskModalComponent
              cancelTaskOpen={this.state.cancelTaskOpen}
              timeZoneName={this.state.timeZoneName}
              startDates={this.state.startDate}
              dateId={this.state.recurrenceDateId}
              taskId={this.state.taskId}
              cancelValue={this.state.cancelValue}
              mainTaskId={this.state.mainTaskId}
              cancelTaskHandler={this.cancelTaskHandler}
              onPopupClose={this.onPopupClose}
            />
          }

        </Suspense>

        <Box component="span" className="company-comp">
          <div className="calender-page">
            <div
              className="new_custom_filters calender-custom-filters"
            >
              <div className="d-flex territory_filter">
                <Suspense fallback={<div />}>
                  <div
                    className="new_custom_filter_div"
                  >
                    {this.state.statusFilterName === "Personal Event" || this.state.statusFilterName === "Task" || this.state.statusFilterName === "Work Event"? (
                      ""
                    ) : (
                      <label className="leftName">Event type: </label>
                    )}
                    <div
                    onClick={this.openStatusFilterMenu}
                    >
                    <label
                      aria-label="status_filter"
                      aria-controls="status_filter_select"
                      area-aria-haspopup="true"
                      className={(this.state.statusFilterName === "Personal Event" || this.state.statusFilterName === "Task" || this.state.statusFilterName === "Work Event")
                      ? "event_type"
                      : "event_type_all"}
                    >
                      {!localStorage.getItem("eventType")
                        ? `All`
                        : (this.state.statusFilterName === "Personal Event" || this.state.statusFilterName === "Task" || this.state.statusFilterName === "Work Event")
                        ? upperFirst(this.state.statusFilterName)
                        : 'All'}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <Tooltip className="event_titles" title="Reset" placement="top">
                      <img
                      className = {this.state.userRole.toLowerCase() === "admin" ?  "reset_btn_admin": "reset_btn" }
                      src={resetBtn}
                      onClick={this.clearBtnHandler}
                      alt="reset"
                    />
                    </Tooltip>
                  </div>
                  <CustomSelectComponent
                    filterArray={statusOptions}
                    config={this.state.statusConfig.config}
                    anchorEl={this.state.statusConfig.anchorElStatus}
                    handleClick={this.handleStatusSelect}
                    closeCustomPopup={this.closeStatusFilterMenu}
                  />
                 

                  {
                    this.state.userRole.toLowerCase() === "team lead" ||
                      this.state.userRole.toLowerCase() === "sales rep" ||
                      this.state.userRole.toLowerCase() === "territory manager" ?
                      (<Select
                        showSearch
                        style={{ width: '307px', position: 'absolute', margin: '8px', cursor: 'pointer' }}
                        optionFilterProp="children"
                        defaultValue={this.state.calendarTypeItem ? this.state.calendarTypeItem : 'me'}
                        onChange={this.onterritoryChange}
                        onSearch={this.onSearch}
                      >
                        <Option value="me">My Calendar</Option>
                        <Option value="team">Regional Calendar</Option>
                        {territoryFilterForTer.map((options, index) =>
                          <Option value={options._id} key={options._id}>{options.name}</Option>
                        )}
                      </Select>) : ("")}

                  {this.state.userRole.toLowerCase() === "team lead" ||
                    this.state.userRole.toLowerCase() === "sales rep" ? (
                    <>
                      {/* <div
                      className="new_custom_filter_div"
                      onClick={this.openTerritoryFilterMenu}
                    >
                      {this.state.territoryName !== "" ? (
                        ""
                      ) : (
                        <label className="leftName">Territory: </label>
                      )}
                      <label
                        aria-label="territory_filter"
                        aria-controls="territory_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.territoryName === ""
                          ? `All`
                          : upperFirst(this.state.territoryName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.territoryOption}
                      config={this.state.territoryConfig.config}
                      anchorEl={this.state.territoryConfig.anchorElTerritory}
                      searchValue={this.state.searchTerritoryValue}
                      handleClick={this.handleTerritorySelect}
                      closeCustomPopup={this.closeTerritoryFilterMenu}
                      onSearchFilter={this.onTerritorySearchFilter}
                      placeholder="Search Territory"
                    /> */}
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.territoryName !== "" &&
                    (this.state.userRole.toLowerCase() === "team lead" ||
                      this.state.userRole.toLowerCase() === "sales rep") ? (
                    <>
                      {/* <div
                      className="new_custom_filter_div"
                      onClick={this.openSalesrepFilterMenu}
                    >
                      {this.state.salesrepName !== "" ? (
                        ""
                      ) : (
                        <label className="leftName rightName">Sales Rep: </label>
                      )}
                      <label
                        aria-label="salesrep_filter"
                        aria-controls="salesrep_filter_select"
                        area-aria-haspopup="true"
                        className="rightName"
                      >
                        {this.state.salesrepName === ""
                          ? `All`
                          : upperFirst(this.state.salesrepName)}
                      </label>
                      <img className="carret" src={carret_down} alt="carret" />
                    </div>
                    <CustomSelectWithSearchComponent
                      filterArray={this.state.salesrepOption}
                      config={this.state.salesrepConfig.config}
                      anchorEl={this.state.salesrepConfig.anchorElSalesrep}
                      searchValue={this.state.searchSalesrepValue}
                      handleClick={this.handleSalesrepSelect}
                      closeCustomPopup={this.closeSalesrepFilterMenu}
                      onSearchFilter={this.onSalesrepSearchFilter}
                      placeholder="Search Salesrep"
                    /> */}
                    </>
                  ) : (
                    ""
                  )}
                  {
                  this.state.userRole.toLowerCase() === "area manager" ? 
                  (<Select
                    showSearch
                    style={{ width: '307px', position: 'absolute', margin: '8px', cursor: 'pointer' }}
                    optionFilterProp="children"
                    defaultValue={this.state.calendarTypeItem ? this.state.calendarTypeItem : 'me'}
                    onChange={this.onterritoryChange}
                    onSearch={this.onSearch}
                  >
                    <Option value="area">Area Calendar</Option>
                    {regionFilter.map((options, index) =>
                    ((options.area_id == this.state.levelId)&&
                        <Option value={options._id} key={options._id}>{options.name}</Option>
                    )
                    )}
                    <Option value="me">My Calendar</Option>
                  </Select>):""}
                </Suspense>
              </div>
            </div>
            <Grid container>
              <Grid item xs={12} md={4} lg={4} className="calender-left-grid">

                <div className="calender-left-container">
                  <ConfigProvider locale={en_GB}>
                    <Calendar
                      fullscreen={false}
                      value={this.state.value}
                      onSelect={this.onSelect}
                    />
                  </ConfigProvider>
                  {this.state.checkId && account_name !== "" && service_name !== "" ? (
                    <div className="cal-task-selected">
                      <h2 className="cal-task-selected-title">
                        {this.state.taskType === "event"
                          ? `Event Selected `
                          : `Task selected`}
                      </h2>
                      <span
                        className={taskStatus + " cal-task-selected-status"}
                      >
                        {taskStatus.charAt(0).toUpperCase() +
                          taskStatus.slice(1)}
                      </span>
                      <span className="recurring_icon">
                        {((recurrenceTaskStatus === "AutoGenerated" || scheduleRules.isRecurring === true || isRecurring === true || scheduleRule === true) && taskStatus == "assigned") ?
                          <img className="recurring_assigned" src={recurring_assigned} alt="img" />
                          : ((recurrenceTaskStatus === "AutoGenerated" || scheduleRules.isRecurring === true || isRecurring === true || scheduleRule === true) && taskStatus == "pending") ?
                            <img className="recurring_assigned" src={recurring_pending} alt="img" />
                            : ((recurrenceTaskStatus === "AutoGenerated" || scheduleRules.isRecurring === true || isRecurring === true || scheduleRule === true) && taskStatus == "inprogress") ?
                              <img className="recurring_assigned" src={recurring_inprogress} alt="img" />
                              : ((recurrenceTaskStatus === "AutoGenerated" || scheduleRules.isRecurring === true || isRecurring || scheduleRule === true) && taskStatus == "completed") ?
                                <img className="recurring_assigned" src={recurring_completed} alt="img" />
                                : ((recurrenceTaskStatus === "AutoGenerated" || scheduleRules.isRecurring === true || isRecurring || scheduleRule === true) && taskStatus == "uncovered") ?
                                  <img className="recurring_assigned" src={recurring_uncovered} alt="img" />
                                  : ""
                        }
                      </span>
                      <Tooltip className="event_titles" title={this.state.taskName.length > 40 ? this.eventTitles(service_name, account_name, taskName) : "View Detail"} placement="topLeft">
                        <p
                          className="cal-task-selected-text"
                          onClick={this.eventDetailView}
                        >
                          {this.eventTitles(service_name, account_name, taskName)}
                        </p>
                      </Tooltip>
                      {this.state.organizer ?
                        <div className="dashboard_task_event_attendees">
                          <span className="dashboard_task_event_attendee">Organizer :</span>
                          <img
                            className="event_attendee_tab_icon event_organizer_icon"
                            src={
                              this.state.organizer.profilePath
                                ? `https://salesc2userprofile.s3.amazonaws.com/${this.state.organizer.profilePath}`
                                : userIcon
                            }
                            alt="back"
                          />
                          {this.state.organizer.name}
                        </div>
                        : ""}
                      {assignedTo ? (
                        <div className="cal-task-selected-assigne">
                          Assigned To:<span>{assignedTo}
                            <div >
                              <span >
                                {additionalRepresentative && additionalRepresentative[0].length > 0
                                  ?
                                  additionalRepresentative[0].map((names, index) =>
                                    additionalRepresentative[0].length > 0 && index < 3 ? (
                                      additionalRepresentative[0].length - 1 === index ? (
                                        names.name
                                      ) : (
                                        names.name + ", "
                                      )
                                    ) : (
                                      ""
                                    )
                                  )

                                  : ""}
                                {additionalRepresentative && additionalRepresentative[0].length > 3 ?
                                  <Popover
                                    title="Other Attendees"
                                    content={additionalRepresentative[0].map(
                                      (names, index) =>
                                        index >= 3 ? (
                                          <>
                                            {/* <img
                                            className="event_attendee_tab_icon"
                                            src={
                                              names.profilePath
                                                ? `https://salesc2userprofile.s3.amazonaws.com/${names.profilePath}`
                                                : userIcon
                                            }
                                            alt="back"
                                          /> */}
                                            <p key={names._id}>{names.name}</p>
                                          </>
                                        ) : (
                                          ""
                                        )
                                    )}
                                  >
                                    <span className="dashboard_task_event_attendees_plus" type="primary">
                                      +{additionalRepresentative[0].length - 3} others
                                    </span>
                                  </Popover>
                                  : ""}
                              </span>
                            </div>
                            {/* )) : ""} */}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="cal-task-selected-date">
                        On {startDate} to {endDate}
                      </div>
                      {(calendarType === "task" || scheduleRule == false) ?
                        <div className="cal-selected-cancel">
                          {(lat !== "" && long !== "") && <Button
                            className={`${((recurrenceTaskStatus === "AutoGenerated") || (scheduleRule == true)) ? "cal-task-selected-btn" : "cal-task-auto-selected-btn"}`}
                            onClick={() => this.gotoMap(long, lat,)}
                            // variant="contained"
                            startIcon={<DirectionsIcon />}
                          >
                            Get Direction
                          </Button>}
                          {(((recurrenceTaskStatus === "AutoGenerated") || (scheduleRule == true)) && (taskStatus == "pending" || taskStatus == "assigned")) &&
                            <Button
                              className={`${((recurrenceTaskStatus === "AutoGenerated") || (scheduleRule == true)) && (lat === "" && long === "") ? "cal-task-cancel-selected-btn" : "cal-task-cancel-btn"}`}
                              variant="outlined"
                              onClick={this.cancelTask}
                            >
                              Cancel
                            </Button>}

                        </div>
                        : <div className="cal-selected-cancel">
                          {(lat !== "" && long !== "") && <Button
                            className={`${((recurrenceTaskStatus === "AutoGenerated") || (scheduleRule == true) && (taskStatus == "pending" || taskStatus == "assigned")) ? "cal-task-selected-btn" : "cal-task-auto-selected-btn"}`}
                            onClick={() => this.gotoMap(long, lat,)}
                            startIcon={<DirectionsIcon />}
                          >
                            Get Direction
                          </Button>}
                          {((recurrenceTaskStatus === "AutoGenerated") || (scheduleRule == true) && (taskStatus == "pending" || taskStatus == "assigned")) &&
                            <Button
                              className={`${((recurrenceTaskStatus === "AutoGenerated") || (scheduleRule == true)) && (lat === "" && long === "") && (taskStatus == "Pending" || taskStatus == "assigned") ? "cal-task-cancel-selected-btn" : "cal-task-cancel-btn"}`}
                              variant="outlined"
                              onClick={this.cancelTask}
                            >
                              Cancel
                            </Button>}

                        </div>}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>

              {/* <Button variant="contained" className="tableaddBtn" color="default"
                style={{ boxShadow: 'none' }}>Event Types</Button> */}

              <Grid item xs={12} md={8} lg={8}>

                {this.state.userRole.toLowerCase() === "admin" ?
                  <FullCalendar
                    customButtons={{
                      myEventButton: {
                        text: "+ Add Event",
                        click: this.onAddEventModal,
                      },
                      eventTypeBtn: {
                        text: "+ Add Event Types",
                        className: className,
                        click: this.onAddEventType,
                      },
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    timeZone={this.state.timeZoneName}
                    firstDay={1}
                    headerToolbar={{
                      left: "myEventButton,eventTypeBtn,prev,next",
                      center: "title",
                      right: this.state.dateChange,
                    }}
                    dayMaxEventRows={4}
                    events={this.formatEvents(events)}
                    eventClick={this.eventClick}
                    displayEventTime={false}
                    ref={this.calendarComponentRef}
                    dateClick={this.handleDateClick}
                    // eventMouseEnter={()=>this.eventMouseEnter}
                    slotLabelFormat={{
                      hour: 'numeric',
                      omitZeroMinute: false,
                      hour12: true
                    }}
                  />
                  : <FullCalendar
                    customButtons={{
                      myEventButton: {
                        text: "+ Add Event",
                        click: this.onAddEventModal,
                      },
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    timeZone={this.state.timeZoneName}
                    firstDay={1}
                    headerToolbar={{
                      left: "myEventButton,prev,next",
                      center: "title",
                      right: this.state.dateChange,
                    }}
                    dayMaxEventRows={4}
                    events={this.formatEvents(events)}
                    eventClick={this.eventClick}
                    displayEventTime={false}
                    ref={this.calendarComponentRef}
                    dateClick={this.handleDateClick}
                    slotLabelFormat={{
                      hour: 'numeric',
                      omitZeroMinute: false,
                      hour12: true
                    }}
                  // eventMouseEnter={()=>this.eventMouseEnter}
                  />
                }
              </Grid>
            </Grid>
          </div>
        </Box >
      </div >
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <div>{CompanyPage}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CalendarComponent));
