import * as React from "react";
import {
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  Inject,
  TimelineViews,
  Resize,
  DragAndDrop,
  TimelineMonth,
} from "@syncfusion/ej2-react-schedule";
import Loader from "react-loader-spinner";
import { withStyles } from "@material-ui/core/styles";
import { upperFirst, startCase } from "lodash";
import { extend, closest, remove, addClass } from "@syncfusion/ej2-base";
import { SampleBase } from "./sample-base";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { Empty } from "antd";
import "./SchedulerComponent.css";
import { Box, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import { AxiosInstance } from "../../Utilities";
import userIcon from "../../Assets/images/user-icon.png";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import moment from "moment";
import { ProfileURL } from "../../client-config";
import momentTimeZone from "moment-timezone";
import { Select } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { isSafari } from "react-device-detect";
import recurring_unassigned from "../../Assets/images/recurring_unassigned.svg";
import ReassignRepRecurringComponent from "../../Components/TaskComponent/TaskDetails/RecurringComponent/ReassignRepRecurringComponent"

const { Option } = Select;
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

class SchedulerComponent extends SampleBase {
  constructor() {
    super(...arguments);
    var zoneName = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    this.isTreeItemDropped = false;
    this.draggedItemId = "";
    this.allowDragAndDrops = true;

    this.fetchss();
    this.state = {
      timeZoneName: zoneName,
      i: 0,
      fields: {
        dataSource: [],
        id: "_id",
      },
      assignedTasks: [],
      assigneeTask: [],
      users: [],
      dataIsReturned: false,
      userReturned: false,
      eventStart: "",
      eventEnd: "",
      IniStartdate: "10:00",
      Today: moment.tz(momentTimeZone(), zoneName),
      taskslist: {
        tasks: {},
        config: {
          task_sort_by: {
            selectProps: {
              variant: "outlined",
              displayEmpty: true,
              name: "sortBy",
            },
            defaultMenu: "Sort By",
            menuItems: [],
          },
          task_filter: {
            autocompletedProps: {
              className: "task_filter",
              id: "task_filter",
            },
            textFieldProps: {
              variant: "outlined",
              name: "task_filter_name",
              placeholder: "Users",
            },
          },
          territory_filter: {
            autocompletedProps: {
              id: "territory_filter_control",
            },
            textFieldProps: {
              variant: "outlined",
              name: "territory_filter_name",
              placeholder: "Territory",
            },
          },
        },
        task_filter: [],
        territory_filter: [],
        territory_search_filter: [],
        anchorEl: null,
        anchorElUser: null,
        userSearchValue: "",
        territorySearchValue: "",
        task_filter_name: "",
        territory_filter_name: "All",
        territory_filter_id: "",
        task_filter_id: "",
        task_list_data: [],
        updatestatusdialog: false,
        selected: null,
        status: "",
        territoryid: "",
        statusNote: "",
        statusSelected: "",
      },
      territoryFilter: [],
      anchorEl: null,
      currentDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
      endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
      tooltipprops: {},
      reassignModal:false,
      taskData : [],
      schedulerType :"",
      recurringTaskData:[],
      scheduleRules : false,
      taskScheduleId : "",
      dateId : "",
    };
  }
  /**
   * @author Murali
   * To get tasks for the current day
   */

  fetchss = () => {
    var zoneName = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    const startDate = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), zoneName).utc();
    const endDate = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), zoneName).utc();
    let data = {
      territory: "",
      startDate,
      endDate
    };
    this.getPendingTasks(data);
  }
  UNSAFE_componentWillMount() {
    let obj = {}
    if (isSafari) {
      obj.open = false;
      this.setState({ tooltipprops: obj })
    }
    const startDate = moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc()
    const endDate = moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
    this.getSchedulerdata("", startDate, endDate);
  }
  componentDidMount = () => {
    this.fecthTerritoryFilters("");
  };

  /**
   * @author Murali
   * To get tasks for the current day with filters
   */
  getSchedulerdata = (territory, startDate, endDate) => {
    this.setState({ userReturned: false });
    let data = {
      territory: territory,
      startDate,
      endDate
    };

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6012 : 'calender', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/scheduler/unassigned_task", data)
      .then((resp) => {
        let response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          const { fields } = this.state;
          this.setState({fields: {
            dataSource: []
          },})
          fields.dataSource = response.data;
          this.setState({ fields, dataIsReturned: true });
        } else {
          const { fields } = this.state;

          fields.dataSource = [];
          this.setState({ fields, dataIsReturned: true });
        }

      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Murali
   * To get unassigned tasks for the current day.
   */
  getPendingTasks = (data) => {
    data.timezone = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6012 : 'calender', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/scheduler/assigned_task", data)
      .then((resp1) => {
        let response = resp1 && resp1.data;
        if (response && (response.status === "200" || response.status === 200)) {
          let response1 = response.data;
          this.setState({
            users: response1.users,
            assignedTasks: response1.tasks,
            userReturned: true,
          });
          if (response1.tasks.length !== 0) {
            let start_date = moment(response1.tasks[0].startDate).format(
              "HH:mm"
            );
            this.setState({ IniStartdate: start_date });
          }
        } else {
          this.setState({ userReturned: true })
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  }
  /**
   * @author Murali
   * To get employee name
   */
  getConsultantName(value) {
    return value.resourceData[value.resource.textField];
  }
  /**
   * @author Murali
   * To get employee image
   */
  getConsultantImage(value) {
    return value.resourceData.profilePath;
  }
  getConsultantDesignation(value) {
    return value.resourceData.Designation;
  }
  /**
   * @author Murali
   * To display user name and image
   */
  resourceHeaderTemplate = (props) => {
    let image = this.getConsultantImage(props);
    let imgUrl = "";
    if (image !== "" && image !== undefined) {
      imgUrl = ProfileURL + image;
    } else {
      imgUrl = userIcon;
    }
    return (
      <div className="template-wrap">
        <div className="specialist-category">
          <div className={"specialist-image " + this.getConsultantImage(props)}>
            <img src={imgUrl} className="user-profile-img user-profile-img-task" alt="user icon" />
          </div>
          <LightTooltip title={this.getConsultantName(props)} {...this.state.tooltipprops} arrow><div className="specialist-name">{this.getConsultantName(props)}</div></LightTooltip>
          <div className="specialist-designation">
            {this.getConsultantDesignation(props)}
          </div>
        </div>
      </div>
    );
  }

  /**
   * @author Murali
   * To display unassigned tasks
   */
  treeTemplate = (props) => {
    var unassigned_title = (props.account_name && props.service_name) ? this.convertFirstLetterToUpperCase(props.service_name.trim()) + " in " + this.convertFirstLetterToUpperCase(props.account_name.trim()) : upperFirst(props.title_check);
    return (
      <div id="waiting">
        <div id="waitdetails">
          <div id="waitlist" className="task-name">
            {unassigned_title}
            {props.scheduleRules.isRecurring === true ?
              <span style={{ paddingLeft: "5px" }}><img src={recurring_unassigned} title="recurring"></img></span> : ""
            }
          </div>
          <div id="waitcategory" className="task-duration">
            On{" "}
            {momentTimeZone(props.startDate)
              .tz(this.state.timeZoneName)
              .format("MMM DD, YYYY h:mm A")}{" "}
            to{" "}
            {momentTimeZone(props.endDate)
              .tz(this.state.timeZoneName)
              .format("h:mm A")}
            <span style={{ display: "none" }}>
              {" "}
              ((
              {momentTimeZone(props.startDate)
                .tz(this.state.timeZoneName)
                .format("HH:mm")}
              )) ++
              {momentTimeZone(props.startDate)
                .tz(this.state.timeZoneName)
                .format("YYYY,M,D")}
              --
            </span>
          </div>
        </div>
      </div>
    );
  }
  /**
   * @author Murali
   * Event Drag and Drop function
   */
  onItemDrag = (event) => {
    this.draggedItemId = event?.data?._id
    if (event.draggedNodeData !== undefined) {
      var data = event.draggedNodeData.text;
      var startDate = data.substring(
        data.lastIndexOf("((") + 2,
        data.lastIndexOf("))")
      );
      this.scheduleObj.scrollTo(startDate);
    }

    if (this.scheduleObj.isAdaptive) {
      let classElement = this.scheduleObj.element.querySelector(
        ".e-device-hover"
      );
      if (classElement) {
        classElement.classList.remove("e-device-hover");
      }
      if (event.target.classList.contains("e-work-cells")) {
        addClass([event.target], "e-device-hover");
      }
    }
    if (document.body.style.cursor === "not-allowed") {
      document.body.style.cursor = "";
    }
    if (event.name === "nodeDragging") {
      let dragElementIcon = document.querySelectorAll(
        ".e-drag-item.treeview-external-drag .e-icon-expandable"
      );
      for (let i = 0; i < dragElementIcon.length; i++) {
        dragElementIcon[i].style.display = "none";
      }
    }
  }
  /**
   * @author Murali
   * On Click on save button function
   */

  onConfirmClick = (event) => {
    if (event.requestType === "eventCreate" && this.isTreeItemDropped) {
      if(this.scheduleRules  == true ){
        var datas = [];
        datas.push({
          taskId : this.draggedItemId,
          taskStatus : "Pending",
          assignedTo : event.addedRecords[0].assignedTo,
          dateId: this.dateId,
          taskScheduleId : this.taskScheduleId
        })
        this.setState({
          reassignModal : true,
          taskData : datas,
          schedulerType : "recurring"
        })
        var userId = event.addedRecords[0].assignedTo;

      let treeViewdata = this.treeObj.fields.dataSource;
      const filteredPeople = treeViewdata.filter(
        (item) => item._id !== this.draggedItemId
      );
      this.treeObj.fields.dataSource = filteredPeople;
      let elements = document.querySelectorAll(
        ".e-drag-item.treeview-external-drag"
      );
      for (let i = 0; i < elements.length; i++) {
        remove(elements[i]);
      }
      this.isTreeItemDropped = false
      }else{
      var userId = event.addedRecords[0].assignedTo;
      var data = {};
      data.assignedTo = userId;
      data.taskStatus = "Pending";
      data._id = this.draggedItemId;
      this.setState({ assigneeTask: [...this.state.assigneeTask, data] })
      let treeViewdata = this.treeObj.fields.dataSource;
      const filteredPeople = treeViewdata.filter(
        (item) => item._id !== this.draggedItemId
      );
      this.treeObj.fields.dataSource = filteredPeople;
      let elements = document.querySelectorAll(
        ".e-drag-item.treeview-external-drag"
      );
      for (let i = 0; i < elements.length; i++) {
        remove(elements[i]);
      }
      this.isTreeItemDropped = false
      }
    }
    if (event.requestType === "eventChange" && !this.isTreeItemDropped) {
      const assigneeTask = this.state.assigneeTask.map(task => {
        if (task._id === event?.data?.taskStatus) {
          task.assignedTo = event.changedRecords[0].assignedTo
        }
        return task
      })
      this.setState({ assigneeTask })
    }
  }

  onDragStop = (event) => {
    const field = this.state.fields?.dataSource?.find(fie => fie._id === event?.data?.taskStatus)
    if (field?.startDate?.slice(0, 19) !== moment(event?.data?.startDate)?.toISOString()?.slice(0, 19)) {
      event.cancel = true
    }
  }

  onActionBegin = () => {
    if (this.state.assigneeTask.length === 0 ) return
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
      .post("/scheduler_task", this.state.assigneeTask)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var props = {
            message: "Task Assigned Successfully",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(props);
          const { currentDate, endDate, territoryid } = this.state;
          this.setState({ assigneeTask : [] })
          this.getPendingTasks({
            startDate: currentDate, endDate, territory: territoryid
          });
          this.getSchedulerdata(territoryid, currentDate, endDate);
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          var propsErr = {
            message: "Failed To Assign Task",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }

      });



  }
  /**
   * @author Murali
   * On item drag stop function
   */
  onTreeDragStop = (event) => {
    this.setState({ i: 0 });
    let treeElement = closest(event.target, ".e-treeview");
    let classElement = this.scheduleObj.element.querySelector(
      ".e-device-hover"
    );
    if (classElement) {
      classElement.classList.remove("e-device-hover");
    }
    if (!treeElement) {
      event.cancel = true;
      let scheduleElement = closest(event.target, ".e-content-wrap");
      if (scheduleElement) {
        let treeviewData = this.treeObj.fields.dataSource;
        if (event.target.classList.contains("e-work-cells")) {
          const filteredData = treeviewData.filter(
            (item) => item?._id === event?.draggedNodeData?.id
          );
          let cellData = this.scheduleObj.getCellDetails(event.target);
          let resourceDetails = this.scheduleObj.getResourcesByIndex(
            cellData.groupIndex
          );
          let eventData = {
            title: (filteredData[0]?.account_name && filteredData[0]?.service_name) ? this.convertFirstLetterToUpperCase(filteredData[0]?.service_name.trim()) + " in " + this.convertFirstLetterToUpperCase(filteredData[0]?.account_name.trim()) : upperFirst(filteredData[0]?.title_check),
            startDate: filteredData[0]?.startDate,
            endDate: filteredData[0]?.endDate,
            assignedTo: resourceDetails?.resourceData._id,
            taskStatus: event?.draggedNodeData?.id
          };
          this.scheduleObj.openEditor(eventData, "Add", true);
          this.scheduleRules = filteredData[0]?.scheduleRules?.isRecurring;
          this.taskScheduleId = filteredData[0]?.taskScheduleId;
          this.dateId = filteredData[0]?.dateId;
          this.isTreeItemDropped = true;
          this.draggedItemId = event.draggedNodeData.id;
          document.getElementById("title").disabled = true;
          document.getElementById("startDate").disabled = true;
          document.getElementById("endDate").disabled = true;
          document.getElementsByClassName("e-title-text")[0].innerHTML =
            "Assign Task";
          document.getElementsByClassName("e-event-save")[0].innerHTML =
            "Confirm";
        }
      }
    }
  }
  /**
   * @author Murali
   * Date change function
   */
  onNavigation = (args) => {
    this.setState(
      {
        dataIsReturned: false,
        Today: args.currentDate,
        currentDate: moment.tz(momentTimeZone(args.currentDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc(),
        endDate: moment.tz(momentTimeZone(args.currentDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc()
      }
    );
    const startDate = moment.tz(momentTimeZone(args.currentDate).format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
    const endDate = moment.tz(momentTimeZone(args.currentDate).format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
    let data = {
      territory: this.state.territoryid,
      startDate,
      endDate
    };
    this.getSchedulerdata(this.state.territoryid, startDate, endDate);
    this.getPendingTasks(data);
  };
  /**
   * @author Murali
   * To get territory names for territory filter
   */
  fecthTerritoryFilters = (search) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : 'level', "application/json")
      .post("/dashboardLevelFilter", {
        search,
        limit: 15,
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (response.status === "200" || response.status === 200) {
          var data = response.data;
          this.setState({ territoryFilter: data })
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
        this.setState({
          searchLoader: false,
        });
      });
  };
  /**
   * @author Murali
   * To display Quickpopup footer
   */
  footer = (props) => {
    return (
      <div>
        <div className="e-cell-footer">
          <button
            className="e-event-details"
            title="View Details"
            style={{ cursor: "pointer", textTransform: "capitalize" }}
            onClick={() => this.taskDetailView(props)}
          >
            View Details
          </button>
        </div>
      </div>
    );
  }
  /**
   * @author Murali
   * To redirect to current task detail
   */
  taskDetailView = (item) => {
    if (item && item.type === "task") {
      if(item.recurringStatus=="AutoGenerated"){
        this.props.history.replace(`task/${item.taskScheduleId}?dateId=${item.dateId}&status=AutoGenerated`);
      }else{
        this.props.history.replace(`task/${item._id}`);
      }
    } else if (item && item.type === "event") {
      this.props.history.replace(`eventDetail/${item._id}`);
    }
    // window.location.href = `task/${taskId}`;
  };
  /**
   * @author Murali
   * To display tasks based on territory filter
   */
  onterritoryChange = (value) => {
    const { currentDate, endDate, taskslist } = this.state;
    let Id = value;
    this.setState({ territoryid: value })
    let data = {
      territory: value,
      startDate: currentDate,
      endDate
    };
    this.getPendingTasks(data);
    this.getSchedulerdata(Id, currentDate, endDate);
    if (value === '') {
      this.fecthTerritoryFilters('');
    }
  }
  /**
   * @author Murali
   * Search function for territory filter
   */
  onSearch = (search) => {
    this.fecthTerritoryFilters(search);
  }

  convertFirstLetterToUpperCase = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  getField = (data) => {
    return {
      subject: { title: "Task title", name: "title" },
      startTime: { title: "From", name: "startDate" },
      endTime: { title: "To", name: "endDate" },
      description: {
        title: "Task Status",
        name: "taskStatus",
      }
    }
  }
  getDatas = () => {
    let data = [];
    const { fields, users, userReturned, assignedTasks, Today, territoryFilter } = this.state;
    if (assignedTasks.length > 0) {
      assignedTasks.map((item) => {
        item.title = (item.account_name && item.service_name) ? this.convertFirstLetterToUpperCase(item.service_name.trim()) + " in " + this.convertFirstLetterToUpperCase(item.account_name.trim()) : upperFirst(item.title_check);
      });
    }
    if (assignedTasks !== undefined && assignedTasks.length !== 0) {
      data = extend([], assignedTasks, null, true);
    } else {
      data = extend([], [], null, true);
    }
    return data;
  }

  onReassignPopupClose = () => {
    this.setState({
      reassignModal : false
    }, () => {
      window.location.href = "/schedule-task";
    })
  }

  reassignTaskHandler = (item ,value) => {
    this.setState({assigneeTask : []})
    var data = {};
      data.assignedTo = value[0].assignedTo;
      data.taskStatus = "Pending";
      data._id = value[0].taskId;
      data.timezone = this.state.timeZoneName
    if(item == "recurrenceOnce"){
      if(!value[0].dateId){
        data.recurringTask = false;
        this.setState({
          reassignModal:false,
          assigneeTask: [...this.state.assigneeTask,data] 
        })
      }else{
        data.dateId = value[0].dateId;
        this.setState({
          reassignModal:false,
          assigneeTask: [...this.state.assigneeTask,data] 
        })
      }
    }else{
      data.recurringTask = true;
      this.setState({
        reassignModal:false,
        assigneeTask: [...this.state.assigneeTask,data] 
      })
    }
  }
  render() {
    const { fields, users, userReturned, assignedTasks, Today, territoryFilter } = this.state;
    let CompanyPage = (
      <div className="body-bg-color">
        <HeaderComponent />
        <Box component="span" className="company-comp">
          <div className="schedule-control-section">
            <div className="col-lg-12 control-section">
              <Select
                showSearch
                style={{ width: '307px', position: 'absolute', margin: '8px', cursor: 'pointer' }}
                placeholder="Territory"
                optionFilterProp="children"
                onChange={this.onterritoryChange}
                onSearch={this.onSearch}
              >
                <Option value="">All</Option>
                {territoryFilter?.length>0 && territoryFilter.map((options, index) =>
                  <Option value={options._id} key={options._id}>{options.name}</Option>
                )}
              </Select>
              <div className="control-wrapper drag-sample-wrapper schedule-task-container">
                <div className="treeview-container">

                  <div className="title-container">
                    <h1 className="title-text">Pending Assignments</h1>
                  </div>
                  {this.state.userReturned === false ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        visible={true}
                      />{" "}
                    </div>
                  ) : this.state.fields.dataSource.length === 0 ? (
                    <div className="empty_div">
                      <Empty
                        description={
                          <span
                            style={{
                              color: "#303960",
                            }}
                          >
                            No pending assignments
                          </span>
                        }
                      />
                    </div>
                  ) : (
                    <Scrollbars className="custom_scrollbar"
                      style={{ width: "auto", height: 564, maxHeight: "calc(100vh - 156px)" }}>
                      <TreeViewComponent
                        ref={(tree) => (this.treeObj = tree)}
                        cssClass="treeview-external-drag"
                        nodeTemplate={this.treeTemplate}
                        fields={this.state.fields}
                        nodeDragStop={this.onTreeDragStop}
                        nodeDragging={this.onItemDrag}
                        allowDragAndDrop={this.allowDragAndDrops}
                      />
                    </Scrollbars>
                  )}
                </div>
                <div className="schedule-container">
                  <div className="title-container">
                    <h1 className="title-text">Today's Tasks</h1>
                    <button onClick={this.onActionBegin}>Publish Schedule</button>
                  </div>

                  <ScheduleComponent
                    ref={(schedule) => (this.scheduleObj = schedule)}
                    cssClass="schedule-drag-drop task_list_container"
                    width="100%"
                    height="650px"
                    selectedDate={Today}
                    currentView="TimelineDay"
                    resourceHeaderTemplate={this.resourceHeaderTemplate}
                    // workHours={{ highlight: false, start: "09:00" }}
                    // startHour="00:00"
                    timeScale={{ enable: true, interval: 30, slotCount: 2 }}
                    allowDragAndDrop
                    allowResizing={false}
                    timezone={this.state.timeZoneName}
                    navigating={this.onNavigation}
                    eventSettings={{
                      dataSource: this.getDatas(),
                      fields: this.getField()
                    }}
                    group={{ enableCompactView: false, resources: ["Users"] }}
                    actionBegin={this.onConfirmClick}
                    drag={this.onItemDrag}
                    quickInfoTemplates={{ footer: this.footer }}
                    dragStop={this.onDragStop}
                  >
                    <ResourcesDirective>
                      <ResourceDirective
                        field="assignedTo"
                        title="Assigned To"
                        name="Users"
                        allowMultiple={false}
                        dataSource={this.state.users}
                        textField="name"
                        idField="_id"

                      ></ResourceDirective>
                    </ResourcesDirective>
                    <ViewsDirective >
                      <ViewDirective option="TimelineDay" allowVirtualScrolling={true} />
                      <ViewDirective option="TimelineMonth" allowVirtualScrolling={true} />
                    </ViewsDirective>
                    <Inject
                      services={[
                        TimelineViews,
                        TimelineMonth,
                        Resize,
                        DragAndDrop,
                      ]}
                    />
                  </ScheduleComponent>
                </div>
              </div>
            </div>
          </div>
        </Box>
        {this.state.reassignModal == true &&
        <ReassignRepRecurringComponent
        data={this.state.reassignModal}
        onReassignPopupClose={this.onReassignPopupClose}
        reassignTaskHandler={this.reassignTaskHandler}
        taskData={this.state.taskData}
        schedulerType={this.state.schedulerType}
        />
        }
      </div>
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <div>{CompanyPage}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SchedulerComponent));
