import React, { Component, Suspense } from "react";
import "./EventDetailComponent.css";
import {
  Typography,
  Button,
  TextField,
  withStyles,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import DirectionsIcon from "@material-ui/icons/Directions";
import pendingIcon from "../../../Assets/images/Group 777.svg";
import conformIcon from "../../../Assets/images/Group 788.svg";
import unavailableIcon from "../../../Assets/images/Group 776.svg";
import { Breadcrumbs, Link, Grid } from "@material-ui/core";
import backIcon from "../../../Assets/images/breadcrumb_back.svg";
import attendeTabClose from "../../../Assets/images/attende_tab_close.svg";
import attendeTabAdd from "../../../Assets/images/event_tab_add.svg";
import edit_icon_white from "../../../Assets/images/edit_icon_white.svg";
import editIcon from "../../../Assets/images/view_edit_icon.svg";
import userIcon from "../../../Assets/images/user-icon.png";
import { AxiosInstance } from "../../../Utilities";
import momentTimeZone from "moment-timezone";
import moment from "moment";
import "moment/locale/en-nz";
import { upperFirst } from "lodash";
import { onGetDirectionHandler } from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import close_icon_red from "../../../Assets/img/close_icon_red.svg";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";
import {
  getGooglePlaceObjectValues,
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import iconNotificationUpdate from "../../../Assets/images/icon-notify-update.svg";
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import AutoComplete from "react-google-autocomplete";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { withRouter, Redirect } from 'react-router-dom';
import { GoogleMapsAPI } from "../../../client-config";
import HeaderComponent from "../../../SharedComponents/HeaderComponent/HeaderComponent";
import { Transition } from '../../../SharedComponents/CommonUtilities/CommonUtilities';
import { isSafari } from "react-device-detect";

import {
  emailRegx,
} from "../../../SharedComponents/Validation/formValidation";
var AddEmail = React.lazy(() => import("./EditDetailModal/AddEmail"));
var PopConfirmComponent = React.lazy(() =>
  import("../../../SharedComponents/PopConfirmComponent/PopConfirmComponent")
);
var PopEmailConfirm = React.lazy(() =>
  import("../../../SharedComponents/PopConfirmComponent/PopConfirmComponent")
);
// var HeaderComponent = React.lazy(() =>
//   import("../../../SharedComponents/HeaderComponent/HeaderComponent")
// );

var SmallMapComponent = React.lazy(() =>
  import("../../../SharedComponents/SmallMapComponent/SmallMapComponent")
);
var TypeModel = React.lazy(() => import("./EditDetailModal/EditTypeModel"));
var TitleModal = React.lazy(() => import("./EditDetailModal/EditTitleModal"));
var EditDateTimeModal = React.lazy(() =>
  import("./EditDetailModal/EditDateTimeModal")
);
var AddAttendeeModel = React.lazy(() =>
  import("./EditDetailModal/AddAttendeeModel")
);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
// momentTimeZone.tz.setDefault(localStorage.getItem("timeZone"))
class EventDetailComponent extends Component {
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var zoneName = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : momentTimeZone.tz.guess();
    this.state = {
      timeZoneName:  zoneName,
      userRole: userData.role,
      eventDetail: null,
      hideType: "showType",
      hidetitle: "showTitle",
      hideadd: "showAdd",
      hidedate: "showDate",
      hidedisc: "showDis",
      hidecc: "showcc",
      emailCC: "showemailCC",
      disable: false,
      showdelete: false,
      showdeleteAttende: false,
      popConfirmTitle: "Event",
      action: "Cancel",
      actionName: "Cancel",
      popEmailConfirmTitle: "Email",
      Emailaction: "Remove",
      showtype: true,
      showtitle: true,
      address: true,
      description: true,
      CC: true,
      showaddModal: false,
      DatesizeIncrease: "",
      showTitModal: false,
      showTimeModal: false,
      showAttModal: false,
      showatendis: true,
      showdeleteshowdelete: false,
      eventAttendessvalue: [],
      indexid: "",
      map: true,
      newEndDate:"",
      newStartDate:"",
      addatend: true,
      eventTypeOption: [],
      eventData: {},
      neweventAttendeesArray: [],
      eventAttendeesArray: [],
      Address: "",
      increaseSize: "auto_incress",
      eventDescription: "",
      eventLocation: [],
      eventAddress: "",
      eventDuration: {
        eventStartDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
        eventEndDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
      },
      addEventFormError: {
        eventTitle: "",
        eventDateError: "",
        eventDescription: "",
        eventAttendees: "",
        eventLocation: "",
      },
      eventTitle: "",
      id: "",
      attendees: "",
      type: "",
      isMultipleAttendees: true,
      isLocation: "",
      eventType: "",
      eventType_type: "",
      hideAttendeeField: "",
      isEmail: true,
      eventAttendees: [],
      attendeesDefault: [],
      selectValues: [],
      isSubmit: false,
      inSubmit: false,
      showEmailModal: false,
      organizer:{},
      organizer_id:"",
      loggedIn_id:"",
      lat: "",
      lng: "",
      ccEmails: [],
      ccEmailsData: [],
      Emailindexid: "",
      formError: {
        cc: "",
      },
      dateRangeProps: {
        startDate: {
          initialViewDate: moment.tz(momentTimeZone().format("MM-DD-YYYY HH:mm A"),localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            value: ""
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate:moment.tz(momentTimeZone().format("MM-DD-YYYY HH:mm A"),localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control"
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
      },

      ids: "",
      tooltipprops: {}
    };
  }
  /**
   * @author Shashi
   */
  UNSAFE_componentWillMount() {
    let obj = {}
    if (isSafari) {
      obj.open = false;
      this.setState({ tooltipprops: obj })
    }
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.fetchEventDetail();
    this.setState({ increaseSize: "", DatesizeIncrease: "" })
    this.fetchEventTypeList({
      search:""
    })
    setTimeout(() => {
      this.setDataa();
    }, 2000);
  };
  componentDidUpdate() {
    var { id } = this.props.match.params;
    if (id !== this.state.ids) {
      this.fetchEventDetail(id);
    }
  }
  fetchEventTypeList=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
    .get("/eventTypeList", {
      params:data
    })
    .then((resp) => {
      var response = resp && resp.data;
      if (
        response &&
        (response.status === "200" || response.status === 200)
      ) {
        this.setState({
          eventTypeOption: response.data,
        });
      }
    })
    .catch((err) => {
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else {
        console.log("Internal Server Error");
      }
      this.handleClose();
    });
  }
  fetchEventDetail = () => {
    var { id } = this.props.match.params;
    this.setState({
      ids: id
    })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .post("/eventDetail", { id })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var ccEmailsArray = [];
          if (response.data.ccEmails !== undefined) {
            response.data.ccEmails.map(item => {
              var items = item.email
              ccEmailsArray.push(items);
            });
          }
          this.setState({
            eventDetail: response.data,
            eventData: response.data,
            loggedIn_id:response.loggedIn,
            organizer: response.data.organizer,
            organizer_id: response.data.organizer._id,
            lat: response.data.locations.lat,
            lng: response.data.locations.lng,
            ccEmails: ccEmailsArray,
            ccEmailsData: response.data.ccEmails,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          var propsErr = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  };
  componentWillReceiveProps = () => {
    this.setState({
      lat: this.state.lata,
      lng: this.state.lnga,
    });
  };
  /**
   * @author Shashi
   * To set event details data in current state
   */
  setDataa = () => {
    var zoneName = momentTimeZone.tz.guess();
    var attend = this.state.eventData.attendees;
    var atteArray = [];
    if (attend !== undefined) {
      attend.map((item) => {
        var items = item._id;
        atteArray.push(items);
      });
    }
    var attenamArray = [];
    if (attend !== undefined) {
      attend.map(item => {
        var items = item.name
        attenamArray.push(items);
      });
    }
    var location = []
    location.push(this.state.eventData.locations !== undefined ? this.state.eventData.locations.lng : '')
    location.push(this.state.eventData.locations !== undefined ? this.state.eventData.locations.lat : '')
    if (this.state.eventData.type === 0) {
      this.setState({ isMultipleAttendees: false });
    } else {
      this.setState({ isMultipleAttendees: true });
    }
    if (this.state.eventData.type === 1) {
      this.setState({ eventLocation: location });
    } else {
      this.setState({ eventLocation: null });
    }
    const { dateRangeProps } = this.state;
    dateRangeProps.startDate.initialViewDate = momentTimeZone(this.state.eventData.startDate)
      .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName);
    dateRangeProps.startDate.inputProps.value = momentTimeZone(this.state.eventData.startDate)
      .tz(this.state.timeZoneName);
    dateRangeProps.endDate.initialViewDate = momentTimeZone(this.state.eventData.endDate)
      .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName);
    dateRangeProps.endDate.inputProps.value = momentTimeZone(this.state.eventData.endDate)
      .tz(this.state.timeZoneName)
    this.setState({
      dateRangeProps,
      eventTitle: this.state.eventData.title,
      eventAddress: this.state.eventData.address,
      eventDuration: {
        eventStartDate: this.state.eventData.startDate,
        eventEndDate: this.state.eventData.endDate,
      },
      eventDescription: this.state.eventData.description,
      id: this.state.eventData._id,
      eventType: this.state.eventData.eventTypeId,
      eventAttendees: atteArray,
      eventAttendessvalue: atteArray,
      eventType_type: this.state.eventData.type,
    });
    this.getEmployeeFilter();
    this.newgetEmployeeFilter();
  };
  getDirection = (geometry) => {
    onGetDirectionHandler(geometry);
  };
  showAddress = () => {
    this.setState({ address: false });
  };
  hidAddress = () => {
    this.setState({ address: true });
    this.setDataa();
  };
  /**
   * @author Shashi
   * close function all models
   */
  handleClose = () => {
    this.setState({
      showdeleteAttende: false,
      showaddModal: false,
      showTitModal: false, showTimeModal: false,
      showAttModal: false,
      addatend: true,
      showatendis: true,
      showEmailModal: false,
      isEmail: true,
      increaseSize: "",
      DatesizeIncrease: "",
      addEventFormError: {
        eventAttendees: "",
      },
    });
    this.componentDidMount();
  };
  searchEventType=(value)=>{
    this.fetchEventTypeList({
      search:value
    })
  }
  /**
   * @author Shashi
   * @param {value}
   * select event type function
   */
  onEventTypeChangeHandler = (value) => {
    var {
      isLocation,
      isMultipleAttendees,
      hideAttendeeField,
    } = this.state;
    var type = value.split("type")[1];
    if (type === "0" || type === 0) {
      if (
        this.state.userRole.toLowerCase() === "admin" ||
        this.state.userRole.toLowerCase() === "area manager" ||
        this.state.userRole.toLowerCase() === "division manager" ||
        this.state.userRole.toLowerCase() === "team lead"
      ) {
        isMultipleAttendees = false;
        hideAttendeeField = false;
      } else if (this.state.userRole.toLowerCase() === "sales rep") {
        isMultipleAttendees = false;
        hideAttendeeField = true;
      } else {
        isMultipleAttendees = true;
        hideAttendeeField = false;
      }
      isLocation = false;
      this.setState({
        eventAttendessvalue: [], eventAttendees: [],
        showatendis: false,
        isMultipleAttendees,
        eventAddress: null,
        eventType: value,
        eventType_type: type,
        attendeesDefault: [],
        selectValues: [],
        increaseSize: "",
      });
    } else if (type === "1" || type === 1) {
      hideAttendeeField = false;
      isLocation = true;
      isMultipleAttendees = true;
      this.setState({
        isMultipleAttendees,
        eventAddress: this.state.eventData.address,
        eventType: value,
        eventType_type: type,
        hideAttendeeField,
        attendeesDefault: [],
        selectValues: [],
        increaseSize: "",
      });
    }
  };
  onAttendeeOnFocusHandler = (e) => {
    var search = e.target.value;
    this.getEmployeeFilter(search);
    this.newgetEmployeeFilter(search);
  };
  onAttendeeSearch = (value) => {
    var search = value;
    this.getEmployeeFilter(search);
    this.newgetEmployeeFilter(search);
  };
  /**
   * @author Shashi
   * @param {search}
   * To get details of the selected AttendeeSearch
   */
  getEmployeeFilter = (search) => {
    this.setState(
      {
        eventAttendeesArray: [],
        attendeesDefault: [],
      },
      () => {
        var statusData = {
          attendees: this.state.eventAttendessvalue,
          search: search,
          endDate: this.state.dateRangeProps.endDate.initialViewDate,
          startDate: this.state.dateRangeProps.startDate.initialViewDate,
        }
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
          .post("/employeeFilterStatus", statusData)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                eventAttendeesArray: response.data,
              });
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              let propserror = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                eventAttendeesArray: [],
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
            this.setState({
              eventAttendeesArray: [],
            });
          });
      }
    );
  };
  newgetEmployeeFilter = (search) => {
    this.setState(
      {
        neweventAttendeesArray: [],
        attendeesDefault: [],
      },
      () => {
        var statusData = {
          // attendees: this.state.eventAttendessvalue,
          search: search,
          endDate: this.state.dateRangeProps.endDate.initialViewDate,
          startDate: this.state.dateRangeProps.startDate.initialViewDate,
        }
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
          .post("/employeeFilterStatus", statusData)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                neweventAttendeesArray: response.data,
              });
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              let propserror = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                neweventAttendeesArray: [],
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
            this.setState({
              neweventAttendeesArray: [],
            });
          });
      }
    );
  };
  /**
   * @author Shashi
   * To get details of Attendee
   */
  fetchDefaultAttendee = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .get("/userDetail")
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var respdata = [];
          respdata.push(response.data._id + "name" + response.data.name);
          this.setState({
            eventAttendees: respdata,
          });
        } else {
          this.setState({
            eventAttendees: [],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
        this.setState({
          eventAttendees: [],
        });
      });
  };
  /**
   * @author Shashi
   * @param {value, name}
   * Date onchange function
   */
  handleDateSelectHandler = (value, name) => {
    var { eventDuration } = this.state;
    eventDuration = { ...this.state.eventDuration, [name]: value };
    this.setState({
      eventDuration,
    });
  };
  /**
   * @author Shashi
   * @param {place}
   * Google Address function
   */
  onGooglePlaceSelect = (place) => {
    var { addEventFormError } = this.state;
    if (place !== "") {
      addEventFormError.eventLocation = "";
    }
    this.setState({ addEventFormError });
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    this.setState({
      Address: formattedPlace.address,
      eventAddress: formattedPlace.address,
      eventLocation: [formattedPlace.lng, formattedPlace.lat],
    });
  };
  /**
   * @author Shashi
   * @param {value}
   * Attendee onChange function
   */
  onAttendeeChangeHandler = (value) => {
    var { addEventFormError } = this.state;
    if (value !== "") {
      addEventFormError.eventAttendees = "";
    }
    this.setState({ addEventFormError });

    if (this.state.isMultipleAttendees) {
      this.setState({
        eventAttendees: value,
        selectValues: value,
        addatend: false,
      });
    } else {
      var dataArray = [];
      var vals = value.split("name");
      dataArray.push(value);
      this.setState(
        {
          eventAttendees: [],
          selectValues: [],
        },
        () => {
          this.setState({
            eventAttendees: dataArray,
            selectValues: vals[1],
            addatend: false,
          });
        }
      );
    }
  };
  /**
   * @author Shashi
   * @param {e}
   *Submit data from input fields
   */
  onSubmit = (stateVal) => {
    switch (stateVal) {
      case "Address":
        this.setState({ map: false })
        break;
    }
    var {
      eventTitle,
      addEventFormError,
      eventDescription,
      eventAttendees,
      eventAddress,
      eventType_type,
      eventLocation,
    } = this.state;
    if (eventTitle === "") {
      addEventFormError.eventTitle = "Please enter an event title";
    } else if (eventTitle.length > 100) {
      addEventFormError.eventTitle =
        "Event title length should be less than 100 characters";
    } else {
      addEventFormError.eventTitle = "";
    }
    if (
      momentTimeZone(this.state.dateRangeProps.startDate.initialViewDate)
        .tz(this.state.timeZoneName)
         >=
      momentTimeZone(this.state.dateRangeProps.endDate.initialViewDate)
        .tz(this.state.timeZoneName)
        
    ) {
      addEventFormError.eventDateError =
        "End date should not be less than or equal to start date";
    } else {
      addEventFormError.eventDateError = "";
    }
    if ( eventDescription && eventDescription.trim().length > 3000) {
      addEventFormError.eventDescription =
        "Event description length should be less than 3000 characters";
    } else {
      addEventFormError.eventDescription = "";
    }
    if (eventType_type === "1") {
      if (eventAddress === null || eventLocation === null) {
        addEventFormError.eventLocation = "Please enter an event location";
      } else {
        addEventFormError.eventLocation = "";
      }
    } else {
      addEventFormError.eventLocation = "";
    }
    this.setState(
      {
        addEventFormError,
      },
      () => {
        const isEmpty = Object.values(addEventFormError).every(
          (x) => x === null || x === ""
        );
        if (isEmpty) {
          this.setState({ isSubmit: true });
          var attendee = this.state.eventAttendees;
          var attendeesArray = [];
          attendee.map((item) => {
            var items = item.split("name")[0];
            attendeesArray.push(items);
          });
          var Data = {
            title: this.state.eventTitle,
            description: this.state.eventDescription,
            startDate: this.state.dateRangeProps.startDate.initialViewDate,
            endDate: this.state.dateRangeProps.endDate.initialViewDate,
            location: this.state.eventLocation,
            address: this.state.eventAddress,
            attendees: attendeesArray,
            eventType_type: +this.state.eventType_type,
            eventType: this.state.eventType.split("type")[0],
            id: this.state.id,
            ccEmails: this.state.ccEmails,
            timezone: this.state.timeZoneName

          };
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
            .post("/editEventDetail", Data)
            .then((resp) => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                this.setState(
                  {
                    isSubmit: false,
                    inSubmit: false,
                    address: true,
                    CC: true,
                    description: true,
                    // map: false,
                  },
                  () => {
                    var props = {
                      message: response.msg,
                      icon: <img src={iconNotificationUpdate} alt="success" />,
                      type: "success",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification update-notify",
                    };
                    NotificationComponent(props);
                  }
                );
                this.handleClose();
                this.componentDidMount();
              } else if (
                response &&
                (response.status === "400" || response.status === 400)
              ) {
                this.setState(
                  {
                    isSubmit: false,
                    inSubmit: false,
                  },
                  () => {
                    var props = {
                      message: response.msg,
                      icon: <img src={iconNotificationError} alt="error" />,
                      type: "error",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification error-notify",
                    };
                    NotificationComponent(props);
                  }
                );
              }
              setTimeout(() => {
                this.setState({ map: true, })
              }, 1000);
            });
        }
      }
    );
  };
  onSubmitatt = (stateVal) => {
    switch (stateVal) {
      case "Address":
        this.setState({ map: false })
        break;
    }
    var {
      eventTitle,
      addEventFormError,
      eventDescription,
      eventAttendees,
      eventAddress,
      eventType_type,
      eventLocation,
    } = this.state;
    if (eventTitle === "") {
      addEventFormError.eventTitle = "Please enter an event title";
    } else if (eventTitle.length > 100) {
      addEventFormError.eventTitle =
        "Event title length should be less than 100 characters";
    } else {
      addEventFormError.eventTitle = "";
    }
    if (
      momentTimeZone(this.state.dateRangeProps.startDate.initialViewDate)
        .tz(this.state.timeZoneName)
        .format("MM-DD-YYYY HH:mm A") >=
      momentTimeZone(this.state.dateRangeProps.endDate.initialViewDate)
        .tz(this.state.timeZoneName)
        .format("MM-DD-YYYY HH:mm A")
    ) {
      addEventFormError.eventDateError =
        "End date should not be less than or equal to start date";
    } else {
      addEventFormError.eventDateError = "";
    }
    if ( eventDescription && eventDescription.trim().length > 3000) {
      addEventFormError.eventDescription =
        "Event description length should be less than 3000 characters";
    } else {
      addEventFormError.eventDescription = "";
    }
    if (eventType_type === "1") {
      if (eventAddress === null || eventLocation === null) {
        addEventFormError.eventLocation = "Please enter an event location";
      } else {
        addEventFormError.eventLocation = "";
      }
    } else {
      addEventFormError.eventLocation = "";
    }
    this.setState(
      {
        addEventFormError,
      },
      () => {
        const isEmpty = Object.values(addEventFormError).every(
          (x) => x === null || x === ""
        );
        if (isEmpty) {
          this.setState({ isSubmit: true });
          var attendee = this.state.eventAttendees;
          var attendeesArray = [];
          attendee.map((item) => {
            var items = item.split("name")[0];
            attendeesArray.push(items);
          });
          var newArray = attendeesArray.concat(this.state.eventAttendessvalue);
          var Data = {
            title: this.state.eventTitle,
            description: this.state.eventDescription,
            startDate: new Date(this.state.dateRangeProps.startDate.initialViewDate).toUTCString(),
            endDate: new Date(this.state.dateRangeProps.endDate.initialViewDate).toUTCString(),
            location: this.state.eventLocation,
            address: this.state.eventAddress,
            attendees: this.state.eventData.type === 0 ? attendeesArray : newArray,
            eventType_type: +this.state.eventType_type,
            eventType: this.state.eventType.split("type")[0],
            id: this.state.id,
            ccEmails: this.state.ccEmails,
            timezone: this.state.timeZoneName
          };
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
            .post("/editEventDetail", Data)
            .then((resp) => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "200" || response.status === 200)
              ) {
                this.setState(
                  {
                    isSubmit: false,
                    inSubmit: false,
                    address: true,
                    CC: true,
                    description: true,
                    // map: false,
                  },
                  () => {
                    var props = {
                      message: response.msg,
                      icon: <img src={iconNotificationUpdate} alt="success" />,
                      type: "success",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification update-notify",
                    };
                    NotificationComponent(props);
                  }
                );
                this.handleClose();
                this.componentDidMount();
              } else if (
                response &&
                (response.status === "400" || response.status === 400)
              ) {
                this.setState(
                  {
                    isSubmit: false,
                    inSubmit: false,
                  },
                  () => {
                    var props = {
                      message: response.msg,
                      icon: <img src={iconNotificationError} alt="error" />,
                      type: "error",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification error-notify",
                    };
                    NotificationComponent(props);
                  }
                );
              }
              setTimeout(() => {
                this.setState({ map: true, })
              }, 1000);
            });
        }
      }
    );
  };
  onEventChangeHandler = (e) => {
    var { name, value } = e.target;
    this.setState({ [name]: value });
  };
  deleteAttendees = () => {
    this.setState({ isSubmit: true, })
    var { indexid } = this.state
    var attendeesArray = [];
    var selectValues = this.state.selectValues;
    var attendee = this.state.eventAttendees;
    var removedAttendees=[]
    attendee.map((item) => {
      var items = item.split("name")[0];
      attendeesArray.push(items);
    });
    removedAttendees.push(...attendeesArray.splice(indexid, 1));
    this.setState({ selectValues });
    var Data = {
      title: this.state.eventTitle,
      description: this.state.eventDescription,
      startDate: this.state.eventDuration.eventStartDate,
      endDate: this.state.eventDuration.eventEndDate,
      location: this.state.eventLocation,
      address: this.state.eventAddress,
      attendees: attendeesArray,
      eventType_type: +this.state.eventType_type,
      eventType: this.state.eventType.split("type")[0],
      id: this.state.id,
      ccEmails: this.state.ccEmails,
      timezone: this.state.timeZoneName,
      removedAttendee: removedAttendees[0]
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .post("/editEventDetail", Data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState(
            {
              isSubmit: false,
              inSubmit: false,
              address: true,
              description: true,
            },
            () => {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationUpdate} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.handleClose();
          this.componentDidMount();
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          this.setState(
            {
              isSubmit: false,
              inSubmit: false,
            },
            () => {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props);
            }
          );
        }
      });
  };
  cancelEventHandler = () => {
    this.setState({
      showdelete: true,
    });
  };
  handlePopConfirmClose = () => {
    this.setState({
      showdelete: false,
      showdeleteshowdelete: false,
    });
  };
  onPopConfirm = () => {
    this.setState({
      disable: true,
    });
    let data = {
      id: this.props.match.params.id,
      timezone: this.state.timeZoneName
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .post("/cancelEvent", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            disable: false,
            showdelete: false,
          });
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationUpdate} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1500);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          this.setState({
            disable: false,
            showdelete: false,
          });
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        } else if (
          response &&
          (response.status === "201" || response.status === 201)
        ) {
          this.setState({
            disable: false,
            showdelete: false,
          });
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        this.setState({
          disable: false,
          showdelete: false,
        });
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  deleteAtte = (index) => {
    this.setState({ showdeleteAttende: true, indexid: index });
  };
  handleChange = (e) => {
    var addresss = e.target.value;
    if (addresss === "") {
      this.setState({ Address: addresss , eventLocation: [],
        eventAddress: "", })
    }
  }
  sizeIncrease = (e) => {
    if (e === true) {
      this.setState({ increaseSize: "auto_incress" })
    } else {
      this.setState({ increaseSize: "" }
      )
    }
  }
  DatesizeIncrease = (e) => {
    if (e === true) {
      setTimeout(() => {
        this.setState({ DatesizeIncrease: "auto_incress" })
      }, 100);
    } else if (e === false) {
      this.setState({ DatesizeIncrease: "" }
      )
    }
  }
  DatesizeDecreas = (e) => {
    if (e === true) {
      setTimeout(() => {
        this.setState({ DatesizeIncrease: "auto_incress" })
      }, 100);
    } else if (e === false) {
      this.setState({ DatesizeIncrease: "" }
      )
    }
  }
  showButton = (showvalue) => {
    switch (showvalue) {
      case "hideType":
        this.setState({ hideType: "" })
        break;
      case "hidetitle":
        this.setState({ hidetitle: "" })
        break;
      case "hideadd":
        this.setState({ hideadd: "" })
        break;
      case "hidedate":
        this.setState({ hidedate: "" })
        break;
      case "hidedisc":
        this.setState({ hidedisc: "" })
        break;
      case "hidecc":
        this.setState({ hidecc: "" })
        break;
      case "emailCC":
        this.setState({ emailCC: "" })
        break;
      default:
        break;
    }
  };
  hideButton = (hidevalue) => {
    switch (hidevalue) {
      case "hideType":
        this.setState({ hideType: "showType" })
        break;
      case "hidetitle":
        this.setState({ hidetitle: "showTitle" })
        break;
      case "hideadd":
        this.setState({ hideadd: "showAdd" })
        break;
      case "hidedate":
        this.setState({ hidedate: "showDate" })
        break;
      case "hidedisc":
        this.setState({ hidedisc: "showDis" })
        break;
      case "hidecc":
        this.setState({ hidecc: "showcc" })
        break;
      case "emailCC":
        this.setState({ emailCC: "showemailCC" })
        break;
      default:
        break;
    }
  }
  valid = (current) => {
    return current && current < moment().subtract(1, 'day');
  };
  onStartDateChange = (date, dateString) => {
    
    const y = moment.tz(momentTimeZone(date).format("MM-DD-YYYY hh:mm A"),this.state.timeZoneName).toString();
    
   const x = moment.tz(momentTimeZone(date).format("MM-DD-YYYY hh:mm A"), this.state.timeZoneName).toISOString();

   const z = moment.tz(momentTimeZone(date).format("MM-DD-YYYY hh:mm A"), this.state.timeZoneName).utc();
   
  console.log("aaaaaaaaaa",y)
    const { dateRangeProps } = this.state;
    dateRangeProps.startDate.initialViewDate = momentTimeZone(date);
    dateRangeProps.startDate.inputProps.value = momentTimeZone(date);
    dateRangeProps.endDate.inputProps.disabled = false;
    dateRangeProps.endDate.inputProps.value = "";
    this.setState({
      dateRangeProps
    });
  }
  disabledDates = (current) => {
    return current && current.isBefore(moment(this.state.dateRangeProps.startDate.initialViewDate));//.subtract(1, "day"));
  };
  onEndDateChange = (date, dateString) => {
    const { dateRangeProps, addEventFormError } = this.state;
    dateRangeProps.endDate.initialViewDate = momentTimeZone(date);
    dateRangeProps.endDate.inputProps.value = momentTimeZone(date);
    addEventFormError.eventDateError = "";
    this.setState({
      dateRangeProps,
      addEventFormError
    });
  }
  onChangeEmail = (newTags) => {
    const { formError } = this.state;
    formError.cc = '';
    this.setState({ isEmail: false, ccEmails: newTags, formError })
  }
  validatorEmail = (value) => {
    const { formError } = this.state;
    if (!emailRegx.test(value)) {
      formError.cc = '* Pleas enter an email address';
      this.setState({ formError })
      return false;
    } else {
      formError.cc = '';
      this.setState({ formError })
      return true;
    }

  }
  EmaildeleteAtte = (index) => {
    this.setState({ showdeleteshowdelete: true, Emailindexid: index });
  };
  EmailConfirm = () => {
    this.setState({ disable: true, })
    var { Emailindexid, ccEmails } = this.state
    var attendeesArray = [];
    var attendee = this.state.eventAttendees;
    attendee.map((item) => {
      var items = item.split("name")[0];
      attendeesArray.push(items);
    });
    ccEmails.splice(Emailindexid, 1);
    var Data = {
      title: this.state.eventTitle,
      description: this.state.eventDescription,
      startDate: this.state.eventDuration.eventStartDate,
      endDate: this.state.eventDuration.eventEndDate,
      location: this.state.eventLocation,
      address: this.state.eventAddress,
      attendees: attendeesArray,
      eventType_type: +this.state.eventType_type,
      eventType: this.state.eventType.split("type")[0],
      id: this.state.id,
      ccEmails: ccEmails,
      timezone: this.state.timeZoneName
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : 'event', "application/json")
      .post("/editEventDetail", Data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState(
            {
              disable: false,
              showdeleteshowdelete: false,
            },
            () => {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationUpdate} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.handlePopConfirmClose();
          this.componentDidMount();
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          this.setState(
            {
              isSubmit: false,
              inSubmit: false,
            },
            () => {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props);
            }
          );
        }
      });
  }
  onFocus = (e) => {
    this.setState({ increaseSize: "auto_incress" })

  }

  render() {
    const { eventDetail, ccEmailsData, addEventFormError, organizer } = this.state;
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />
    } else {
      return (
        <div className="body-bg-color">
          <HeaderComponent />
          <Suspense fallback={<div></div>}>
            <PopConfirmComponent
              disable={this.state.disable}
              showdelete={this.state.showdelete}
              title={this.state.popConfirmTitle}
              action={this.state.action}
              actionName={this.state.actionName}
              onPopConfirm={this.onPopConfirm}
              handlePopConfirmClose={this.handlePopConfirmClose}
            />
          </Suspense>
          <Suspense fallback={<div></div>}>
            <PopEmailConfirm
              disable={this.state.disable}
              showdelete={this.state.showdeleteshowdelete}
              title={this.state.popEmailConfirmTitle}
              action={this.state.Emailaction}
              onPopConfirm={this.EmailConfirm}
              handlePopConfirmClose={this.handlePopConfirmClose}
            />
          </Suspense>
          <Suspense fallback={<div></div>}>
            <TypeModel
              onGooglePlaceSelect={this.onGooglePlaceSelect}
              onAttendeeChange={this.onAttendeeChangeHandler}
              onAttendeeSearch={this.onAttendeeSearch}
              onAttendeeOnFocusHandler={this.onAttendeeOnFocusHandler}
              onEventTypeChange={this.onEventTypeChangeHandler}
              modelData={this.state}
              attendees={this.state.eventAttendeesArray}
              open={this.state.showaddModal}
              handleClose={this.handleClose}
              onSearchEventType={this.searchEventType}
              onSubmit={this.onSubmit}
              decreseSize={this.decreseSize}
              sizeIncrease={this.sizeIncrease}
            />
            <TitleModal
              onSubmit={this.onSubmit}
              onEventChangeHandler={this.onEventChangeHandler}
              modelData={this.state}
              open={this.state.showTitModal}
              handleClose={this.handleClose}
            />
            <EditDateTimeModal
              DatesizeDecreas={this.DatesizeDecreas}
              DatesizeIncrease={this.DatesizeIncrease}
              onEndDateChange={this.onEndDateChange}
              disabledDates={this.disabledDates}
              onStartDateChange={this.onStartDateChange}
              valid={this.valid}
              handleDateSelect={this.handleDateSelectHandler}
              modelData={this.state}
              open={this.state.showTimeModal}
              handleClose={this.handleClose}
              onSubmit={this.onSubmit}
            />
            <AddAttendeeModel
              onFocus={this.onFocus}
              sizeIncrease={this.sizeIncrease}
              onAttendeeChange={this.onAttendeeChangeHandler}
              onAttendeeSearch={this.onAttendeeSearch}
              modelData={this.state}
              open={this.state.showAttModal}
              handleClose={this.handleClose}
              onSubmit={this.onSubmitatt}
              onAttendeeOnFocusHandler={this.onAttendeeOnFocusHandler}
              organizerData={this.state.eventDetail}
            />
            <AddEmail
              onChangeEmail={this.onChangeEmail}
              validatorEmil={this.validatorEmail}
              open={this.state.showEmailModal}
              modelData={this.state}
              handleClose={this.handleClose}
              onSubmit={this.onSubmit}
            />
          </Suspense>
          <Dialog open={this.state.showdeleteAttende} className="deleteServiceDialog" TransitionComponent={Transition} transitionDuration={600}>
            <DialogTitle>
              {" "}
              <center> Remove Attendees</center>{" "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <center>
                  {" "}
                  Are you sure you want to remove this Attendees? This action cannot be undone.
              </center>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="cancel"
                onClick={this.handleClose}
                color="primary"
              >
                Cancel
            </Button>
              <Button
                disabled={this.state.isSubmit}
                className="delete"
                onClick={this.deleteAttendees}
                style={{ color: "red" }}
                autoFocus
              >
                {this.state.isSubmit ? <CircularProgress size={20} /> : ""} Remove
            </Button>
            </DialogActions>
          </Dialog>
          <div className="select_parent">
            <div className="breadCrumbs">
              <img className="bread-crumb-back" src={backIcon} alt="back" />
              <Breadcrumbs className="bread-crumb-main" aria-label="breadcrumb">
                <Link
                  className="bread-crumb-link"
                  color="inherit"
                  href="/calendar"
                >
                  Calender
              </Link>
                <Typography className="bread-crumb-name">
                  Event Details
              </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="event_detail">
            <div className="event_detail_header" >
              <span className="event_detail_name" onMouseEnter={() => this.showButton("hideType")} onMouseLeave={() => this.hideButton("hideType")}>
                {eventDetail && eventDetail.eventTypeName
                  ? eventDetail.eventTypeName
                  : ""}
                {eventDetail && eventDetail.status ? <img
                  className={this.state.hideType}
                  style={{ cursor: "pointer" }}
                  src={edit_icon_white}
                  alt="edit icon"
                  onClick={() => this.setState({ showaddModal: true })}
                /> : ""}
              </span>
                <LightTooltip title={eventDetail && eventDetail.title
                    ? upperFirst(eventDetail.title)
                : ""} {...this.state.tooltipprops} placement="top-start">
              <h1 className="event_detail_text" onMouseEnter={() => this.showButton("hidetitle")} onMouseLeave={() => this.hideButton("hidetitle")}>
                <div className="event_title">
                
                {eventDetail && eventDetail.title
                  ? upperFirst(eventDetail.title)
                  : ""}
                  
                </div> 
                {eventDetail && eventDetail.status ? <img
                  className={this.state.hidetitle}
                  style={{ cursor: "pointer" }}
                  src={edit_icon_white}
                  alt="edit icon"
                  onClick={() => this.setState({ showTitModal: true })}
                /> : ""}
              </h1>
            </LightTooltip>

            </div>
            <div className="event_detail_container">
              <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                  <div className="event_detail_container_item event_detail_item_name">
                    <p>Organizer :</p>
                    <img
                      className="event_attendee_tab_icon"
                      src={
                        organizer.profilePath
                          ? `https://salesc2userprofile.s3.amazonaws.com/${organizer.profilePath}`
                          : userIcon
                      }
                      alt="back"
                    />
                    <span>
                      {organizer && organizer.name
                        ? organizer.name
                        : "-"}

                    </span>
                  </div>

                  {eventDetail && (!eventDetail.address || eventDetail.address) && eventDetail.type === 1 ? (
                    <div className="event_detail_container_item event_detail_address_item" >
                      {this.state.address && (
                        <>
                          <p>Address :</p>
                          <span onMouseEnter={() => this.showButton("hideadd")} onMouseLeave={() => this.hideButton("hideadd")}>
                            {eventDetail && eventDetail.address
                              ? eventDetail.address
                              :
                              <div onClick={this.showAddress} className="event_attendee_tab event_attendee_tab_add event_address_tab ">
                                <div>
                                  <img
                                    className="event_attendee_tab_icon"
                                    src={attendeTabAdd}
                                    alt="back"
                                  />
                                  <span>Add Event Location</span>
                                </div>
                              </div>}
                            {eventDetail && eventDetail.status && eventDetail.address ? <img
                              className={this.state.hideadd}
                              style={{ cursor: "pointer" }}
                              src={editIcon}
                              alt="edit icon"
                              onClick={this.showAddress}
                            /> : ""}
                          </span>
                        </>
                      )}
                      {!this.state.address && (
                        <>
                          <p className="al_ssel">Address :</p>
                          <div className="info-card-detail-left">
                            <Suspense fallback={<div></div>}>
                              <AutoComplete
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  paddingLeft: "16px",
                                  marginTop: "2px",
                                }}
                                name="Address"
                                apiKey={GoogleMapsAPI}
                                types={[]}
                                defaultValue={this.state.eventData.address}
                                onPlaceSelected={this.onGooglePlaceSelect}
                                onChange={this.handleChange}
                              />
                            </Suspense>
                            <Button
                              // disabled={this.state.Address === "" ? true : false}
                              type="submit"
                              className="add_more_btn"
                              onClick={(e) => this.onSubmit("Address")}
                            >
                              {this.state.isSubmit ? (
                                <CircularProgress size={20} />
                              ) : (
                                  ""
                                )}{" "}
                            Save
                          </Button>
                            <img
                              alt="close"
                              src={close_icon_red}
                              style={{ cursor: "pointer" }}
                              onClick={this.hidAddress}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                      ""
                    )}

                  <div className="event_detail_container_item" onMouseEnter={() => this.showButton("hidedate")} onMouseLeave={() => this.hideButton("hidedate")}>
                    Date and Time :
                  <span  >
                      {eventDetail && eventDetail.startDate && eventDetail.endDate
                        ? momentTimeZone(eventDetail.startDate)
                          .tz(this.state.timeZoneName)
                          .format("MM/DD/YYYY hh:mm A") +
                        " to " +
                        momentTimeZone(eventDetail.endDate)
                          .tz(this.state.timeZoneName)
                          .format("MM/DD/YYYY hh:mm A")
                        : "-"}
                    </span>
                    {eventDetail && eventDetail.status ? <img
                      className={this.state.hidedate}
                      style={{ cursor: "pointer" }}
                      src={editIcon}
                      alt="edit icon"
                      onClick={() => this.setState({ showTimeModal: true })}
                    /> : ""}
                  </div>
                  <div className="event_detail_container_item event_detail_address_item">
                    {this.state.description && (
                      <>
                        <p>Description :</p>
                        <span onMouseEnter={() => this.showButton("hidedisc")} onMouseLeave={() => this.hideButton("hidedisc")}>
                          {eventDetail && eventDetail.description
                            ? eventDetail.description
                            :
                            <div onClick={() => this.setState({ description: false })} className="event_attendee_tab event_attendee_tab_add event_address_tab ">
                              <div>
                                <img
                                  className="event_attendee_tab_icon"
                                  src={attendeTabAdd}
                                  alt="back"
                                />
                                <span>Add Description</span>
                              </div>
                            </div>
                          }
                          {eventDetail && eventDetail.status && eventDetail.description ? <img
                            className={this.state.hidedisc}
                            style={{ cursor: "pointer" }}
                            src={editIcon}
                            alt="edit icon"
                            onClick={() => this.setState({ description: false })}
                          /> : ""}
                        </span>
                      </>
                    )}
                    {!this.state.description && (
                      <>
                        <p className="al_ssel">Description :</p>
                        <div className="info-card-detail-left add_company_dialog ">
                          <CssTextField
                            className="text_area"
                            rowsMax={3}
                            multiline
                            variant="outlined"
                            id="outlined-basic"
                            name="eventDescription"
                            margin="dense"
                            fullWidth
                            placeholder="Enter Description"
                            onChange={this.onEventChangeHandler}
                            defaultValue={this.state.eventDetail.description}
                          />
                          <Button
                            disabled={this.state.isSubmit}
                            type="submit"
                            className="add_more_btn"
                            onClick={this.onSubmit}
                          >
                            {this.state.isSubmit ? (
                              <CircularProgress size={20} />
                            ) : (
                                ""
                              )}{" "}
                          Save
                        </Button>
                          <img
                            alt="close"
                            src={close_icon_red}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState(
                                { description: true },
                                this.componentDidMount
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {addEventFormError.eventDescription !== "" ? (
                    <span className="error">
                      {addEventFormError.eventDescription}
                    </span>
                  ) : (
                      ""
                    )}
                  {this.state.eventType_type === 1 &&
                  <>
                  <div className="event_detail_container_item">Attendees :</div>
                  <div className="event_attendee_tabs">
                    <Grid container spacing={2} onMouseEnter={() => this.showButton("hidecc")} className="cc_email_container" onMouseLeave={() => this.hideButton("hidecc")}>
                        <>
                          { this.state.eventType_type === 1 && eventDetail && eventDetail.attendees.length > 0
                            ? eventDetail.attendees.map((attendee, index) => (
                              <Grid item xs={12} sm={6} md={4} key={attendee._id}>
                                <div className="event_attendee_tab">
                                  <div >
                                    <img
                                      className="event_attendee_tab_icon"
                                      src={
                                        attendee.profilePath
                                          ? `https://salesc2userprofile.s3.amazonaws.com/${attendee.profilePath}`
                                          : userIcon
                                      }
                                      alt="back"
                                    />
                                    <span onClick={() => this.setState({ showAttModal: true })} >
                                      <LightTooltip title={attendee.name} {...this.state.tooltipprops} arrow >
                                        <span style={{ cursor: "pointer" }}>{attendee.name}</span>
                                      </LightTooltip>
                                    </span>
                                    {attendee.status === 'pending' ?
                                      <LightTooltip title='Pending' {...this.state.tooltipprops} arrow >
                                        <img src={pendingIcon} alt="pendingIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                                      </LightTooltip>
                                      : attendee.status === 'accepted' ?
                                        <LightTooltip title='Accepted' {...this.state.tooltipprops} arrow >
                                          <img src={conformIcon} alt="conformIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                                        </LightTooltip>
                                        : attendee.status === 'rejected' ?
                                          <LightTooltip title='Rejected' {...this.state.tooltipprops} arrow >
                                            <img src={unavailableIcon} alt="unavailableIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                                          </LightTooltip>
                                          : null}
                                  </div>
                                  {eventDetail && eventDetail.status ?
                                    <img
                                      className={"event_attendee_tab_close " + this.state.hidecc}
                                      onClick={() => this.deleteAtte(index)}
                                      style={{ cursor: "pointer" }}
                                      src={attendeTabClose}
                                      alt="back"
                                    />
                                    : ""}
                                </div>
                              </Grid>
                            ))
                            : ""}
                        </>
                          <Grid item xs={12} sm={6} md={4}>
                            <div
                              onClick={() => this.setState({ showAttModal: true })}
                              className="event_attendee_tab event_attendee_tab_add"
                            >
                              <div>
                                <img
                                  className="event_attendee_tab_icon"
                                  src={attendeTabAdd}
                                  alt="back"
                                />
                                <span>Add Attendee </span>
                              </div>
                            </div>
                          </Grid>
                    </Grid>
                  </div>
                  </>
                  }
                  <div className="event_detail_container_item event_detail_container_cc">
                    {this.state.CC && (
                      <>
                        <p>Email Address of External Attendees :</p>
                      </>
                    )}
                  </div>
                  <div className="event_attendee_tabs">
                    <Grid container spacing={2}>
                      {ccEmailsData.length > 0
                        ? ccEmailsData.map((ccemails, index) => (
                          <Grid item xs={12} sm={6} md={4} key={ccemails._id}>
                            <div onMouseEnter={() => this.showButton("emailCC")} className="cc_email_container" onMouseLeave={() => this.hideButton("emailCC")} className="event_attendee_tab">
                              <div >
                                <span  >
                                  <LightTooltip title={ccemails.email} {...this.state.tooltipprops} arrow >
                                    <span href={"mailto:" + ccemails.email}>{ccemails.email}</span>
                                  </LightTooltip>
                                </span>
                                {ccemails.type === 0 ?
                                  <LightTooltip title="Pending" {...this.state.tooltipprops} arrow >
                                    <img src={pendingIcon} alt="pendingIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                                  </LightTooltip>
                                  : ccemails.type === 1 ?
                                    <LightTooltip title="Confirmed" {...this.state.tooltipprops} arrow >
                                      <img src={conformIcon} alt="conformIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                                    </LightTooltip>
                                    : ccemails.type === 2 ?
                                      <LightTooltip title="Unavailable" {...this.state.tooltipprops} arrow >
                                        <img src={unavailableIcon} alt="unavailableIcon" className="auto-assignee-priority-icon attendees_status_icon" />
                                      </LightTooltip>
                                      : null}
                              </div>

                              {eventDetail && eventDetail.status ?
                                <img
                                  className={"event_attendee_tab_close " + this.state.emailCC}
                                  onClick={() => this.EmaildeleteAtte(index)}
                                  style={{ cursor: "pointer" }}
                                  src={attendeTabClose}
                                  alt="back"
                                />
                                : ""}


                            </div>
                          </Grid>
                        ))
                        : ""}
                      <Grid item xs={12} sm={6} md={4}>
                        <span>
                          <div
                            onClick={() => this.setState({ showEmailModal: true })}
                            className="event_attendee_tab event_attendee_tab_add"
                          >
                            <div>
                              {/* <img
                                className="event_attendee_tab_icon"
                                src={attendeTabAdd}
                                alt="back"
                              /> */}
                              <span>Email Address</span>
                            </div>
                          </div>
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid className="getDirectionBtn" item xs={12} md={3}>
                  {eventDetail && eventDetail.address ? (
                    <>
                      {this.state.map === true ?
                        <>
                          <div className="event_detail_map">
                            <Suspense fallback={<div></div>}>
                              <SmallMapComponent
                                lat={this.state.lat}
                                lng={this.state.lng}
                              />
                            </Suspense>
                          </div>
                          <div className="select_parent">
                            <Button
                              className="upload_btn primary-btn"
                              variant="contained"
                              onClick={() => this.getDirection(eventDetail.locations)}
                              startIcon={<DirectionsIcon />}
                            >
                              Get Direction
                      </Button>
                          </div>
                        </>
                        : ""}
                    </>
                  ) : (
                      ""
                    )}
                </Grid>
                <Grid className="getDirectionBtn" item xs={12} >

                      {eventDetail && eventDetail.status ? <div className="select_parents">
                      {this.state.loggedIn_id === this.state.organizer_id ?
                    <>
                        <Button
                          className="upload_btns red-btn"
                          variant="contained"
                          onClick={this.cancelEventHandler}
                        >
                          Cancel Event
                  </Button>
                        {/* <Button
                      className="ok_btn green-btn"
                      variant="contained"
                    >
                      Accept
                     </Button> */}
                      </>
                    : ""}
                      </div> : ""}
                   
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true
  };
};

export default connect(
  mapStateToProps
)(withRouter(EventDetailComponent));
