import React from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputAdornment,
  TextField,
  withStyles,
} from "@material-ui/core";
import momentTimeZone from "moment-timezone";
import { upperFirst } from "lodash";
import { Transition } from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import iconClose from "../../../Assets/images/close_btn.svg";
import logo from "../../../Assets/images/Sales_C2_logo.svg";
import carret_down from "../../../Assets/images/carret_down.svg";

import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField);
const SubscriptionLevelModal = (props) => {
  return (
    <Dialog
      open={props.subscriptionData.openModal}
      aria-labelledby="form-dialog-title"
      className="add_company_dialog add_subscription_level_dialog"
      TransitionComponent={Transition}
      transitionDuration={600}
      onExit={props.subscriptionModalClose}
    >
      <DialogTitle
        id="form-dialog-title"
        className="add_user_header task_assignment_header"
      >
        <span>Add Subscription Level</span>
        <IconButton
          aria-label="close"
          className="closeBtn updateStatusCloseBtn"
          onClick={props.subscriptionModalClose}
        >
          <img src={iconClose}></img>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="task_assign_model_container">
          <label className="label bl-label">
            Billing type<span className="labelAsterisk"> *</span>
          </label>
          <FormControl variant="outlined" fullWidth margin="dense">
            <Select
              margin="dense"
              className="customSelect"
              placeholder="Select billing type"
              name="billingConfigType"
              fullWidth
              onChange={props.handleChange}
              defaultValue="2"
            >
              <MenuItem value="2">Team</MenuItem>
              <MenuItem value="3">Individual</MenuItem>
            </Select>
          </FormControl>
          <span className="error">
            {props.formError.billingConfigType === ""
              ? ""
              : `* ${props.formError.billingConfigType}`}
          </span>

          <Grid container spacing={2}>
            <Grid item xs={12} md={7} className="trail_field_left">
              <label className="label bl-label">
                Trial Ends on<span className="labelAsterisk"> *</span>
              </label>
              <div className="trail_field">
                <RangePicker
                  className="filter_date_range "
                  name="datepicker"
                  format="MM/DD/YYYY"
                  allowClear={false}
                  inputReadOnly={true}
                  separator="to"
                  value={[
                    moment(
                      props.subscriptionData.billingTrialEnds[0],
                      "MM/DD/YYYY"
                    ),
                    moment(
                      props.subscriptionData.billingTrialEnds[1],
                      "MM/DD/YYYY"
                    ),
                  ]}
                  disabledDate={props.valid}
                  placeholder={["MM/DD/YYYY", "MM/DD/YYYY"]}
                  suffixIcon={
                    <img className="carret" alt="carret" src={carret_down} />
                  }
                  getPopupContainer={(node) => node.parentNode}
                  onChange={props.onDateRangeChanage}
                />
                <span className="remaining_days">
                  {props.subscriptionData.billingTrialDays} days
                </span>
              </div>
              <span className="error"></span>
            </Grid>
            <Grid item xs={12} md={5} className="trail_field_right">
              <label className="label bl-label">
                Pricing/User<span className="labelAsterisk"> *</span>
              </label>
              <CssTextField
                variant="outlined"
                margin="dense"
                fullWidth
                className="pricing_field"
                placeholder=""
                name="billingPrice"
                helperText={<em>Note: Per User Per Month</em>}
                onChange={props.handleChange}
                value={
                  props.subscriptionData.billingPrice
                    ? props.subscriptionData.billingPrice
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="dollar_symbol">
                      $
                    </InputAdornment>
                  ),
                }}
              />
              <span className="error" style={{marginTop:'8%'}}>
                {props.formError.billingPrice === ""
                  ? ""
                  : `* ${props.formError.billingPrice}`}
              </span>
            </Grid>
          </Grid>
          <div className="add_company_action add_subscription_action">
            <Button
              className="cancel_btn white-btn"
              onClick={props.subscriptionModalClose}
            >
              Cancel
            </Button>
            <Button
              className="ok_btn green-btn"
              onClick={props.subscriptionDataAdd}
            >
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionLevelModal;
