import { Label } from '@material-ui/icons';
import { DatePicker, Select } from 'antd';
import moment from 'moment-timezone';
import { MomentTimezone } from 'moment-timezone';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from 'react';
import "./RecurringComponent.css"
const { Option } = Select;
const RecurringComponent = props => {
  const repeatDate = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const monthDate = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  const date = props.taskEndDate;
  const endDate=new Date(props.taskEndDate);
  const finalDate = endDate.setDate(endDate.getDate() + 1)
  const recurringDate = moment(finalDate)
  const [endRecurringDate,setEndRecurringDate]=useState(new Date(recurringDate))
  const [disableBtn, setDisableBtn] = useState(false)
  const [disableBtns, setDisableBtns] = useState(true)
  var format = moment(date).format('MM-DD-YYYY hh:mm A')
  var sliced = format.toString().slice(3, 5)
  var slicedData = []
  if (sliced < 10) {
    slicedData = parseInt(sliced.toString().slice(1, 2))
  }
  else {
    slicedData = parseInt(sliced)
  }
  const DAYS = [
    {
      key: "Sunday",
      label: "S",
      weekday: "SU"
    },
    {
      key: "Monday",
      label: "M",
      weekday: "MO",
    },
    {
      key: "Tuesday",
      label: "T",
      weekday: "TU"
    },
    {
      key: "Wednesday",
      label: "W",
      weekday: "WE"
    },
    {
      key: "Thursday",
      label: "T",
      weekday: "TH"
    },
    {
      key: "Friday",
      label: "F",
      weekday: "FR"
    },
    {
      key: "Saturday",
      label: "S",
      weekday: "SA"
    }
  ];
  const WEEKDAYS = [
    {
      key: 1,
      label: "First"
    },
    {
      key: 2,
      label: "Second"
    },
    {
      key: 3,
      label: "Third"
    },
    {
      key: 4,
      label: "Fourth"
    },
    {
      key: -1,
      label: "Last"
    }
  ];
  const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
      margin: theme.spacing(2),
      padding: theme.spacing(0, 1),
      "&:not(:first-child)": {
        border: "1px solid",
        borderColor: "#F5F5F5",
        borderRadius: "50%",
        background: "#F5F5F5"
      },
      "&:first-child": {
        border: "1px solid",
        borderColor: "#F5F5F5",
        borderRadius: "50%",
        background: "#F5F5F5"
      }
    }
  }))(ToggleButtonGroup);

  const StyledToggle = withStyles({
    root: {
      color: "#000000",
      "&$selected": {
        color: "white",
        background: "#21BF73"
      },
      "&:hover": {
        borderColor: "#21BF73",
        background: "#21BF73"
      },
      "&:hover$selected": {
        borderColor: "#21BF73",
        background: "#21BF73"
      },
      minWidth: 32,
      maxWidth: 32,
      height: 32,
      textTransform: "unset",
      fontSize: "0.75rem"
    },
    selected: {}
  })(ToggleButton);
  const [days, setDays] = useState([]);
  const [weekdays, setWeekDates] = useState([]);

  useEffect(() => {
    if (props.data === "DAILY") {
      props.onFrequencyRecurringHandler("DAILY")
      props.onIntervalRecurringHandler(1)
      props.onWeekDayRecurringHandler(slicedData)
      props.endDateRecurringHandler(date);
    } else if (props.data === "WEEKLY") {
      props.onFrequencyRecurringHandler("WEEKLY")
      props.onIntervalRecurringHandler(1)
      props.onWeekDayRecurringHandler(slicedData)
      props.endDateRecurringHandler(date);
    } else {
      props.onFrequencyRecurringHandler("MONTHLY")
      props.onIntervalRecurringHandler(1)
      props.onWeekDayRecurringHandler(slicedData)
      props.endDateRecurringHandler(date);
    }

  }, [props.data]
  )
  useEffect(() => {
    if (props.data === 'WEEKLY' || props.data === "MONTHLY") {
      var dayName = moment(props.taskStartDate).format('dd').toUpperCase();
      setDays([dayName]);
      setWeekDates(1);
      props.onWeekRecurringHandler("", [dayName])
    } else {
      setDays(["MO"]);
      setWeekDates(1);
      props.onWeekRecurringHandler("", ["MO"])
    }
    setEndRecurringDate(recurringDate);
  }, [props.data, props.taskStartDate])
  const onWeekChangeHandler = (event, value) => {
    setDays(value)
    props.onWeekRecurringHandler(event, value)
  };
  const onMonthlyChangeHandler = (value) => {
    setDays(value);
    monthlyHandler(value, weekdays)
  }
  const onMonthlyChangeHandlers = (value) => {
    setWeekDates(value)
    monthlyHandler(days, value)
  }
  const disabledRecurrenceDates = (current) => {
    return current && current.isBefore(moment(recurringDate));
  };
  const endDateHandler = (date) => {
    setEndRecurringDate(date);
    props.endDateRecurringHandler(date);
  }
  const radioHandlers = () => {
    setDisableBtn(true)
    setDisableBtns(false)
  }
  const radioHandler = () => {
    setDisableBtn(false)
    setDisableBtns(true)
  }
  const monthlyHandler = (days, weekdays) => {
    var data = [days.toString(), weekdays.toString()]
    props.onWeekMonthRecurringHandler(data)
  }

  return (
    <div>
      {props.data === "DAILY" &&
        <div>
          <div class="container_weekly">
            <label className="fieldName">End Date </label>
            <DatePicker
              className="antd_startDate_picker"
              format="MM-DD-YYYY"
              placeholder="MM/DD/YY"
              size="small"
              inputReadOnly={false}
              style={{ height: "32px" }}
              disabledDate={disabledRecurrenceDates}
              getPopupContainer={(node) => node.parentNode}
              showTime={false}
              value={endRecurringDate ? moment(endRecurringDate) : date}
              onChange={endDateHandler}
            />
          </div>
          <div class="container_weekly">
            <label className="fieldName">Repeat Every</label>
            <Select getPopupContainer={(node) => node.parentNode}
              onChange={props.onIntervalRecurringHandler}
              size="medium"
              defaultValue={repeatDate[0] ? repeatDate[0] : ''}
            >
              {repeatDate?.length > 0 && repeatDate.map((month, idx) => (
                <Option key={idx} value={month}>
                  {month}
                </Option>
              ))}
            </Select>
          </div>
          <b>Day(s)</b>
        </div>}
      {props.data === "WEEKLY" &&
        <div>
          <div class="container_weekly">
            <label className="fieldName">End Date </label>
            <DatePicker
              className="antd_startDate_picker"
              style={{ height: "32px" }}
              format="MM-DD-YYYY"
              placeholder="MM/DD/YY"
              size="small"
              disabledDate={disabledRecurrenceDates}
              getPopupContainer={(node) => node.parentNode}
              showTime={false}
              value={endRecurringDate ? moment(endRecurringDate) : date}
              onChange={endDateHandler}
            />
          </div>
          <div class="container_weekly">
            <label className="fieldName">Repeat Every</label>
            <Select getPopupContainer={(node) => node.parentNode}
              onChange={props.onIntervalRecurringHandler}
              size="medium"
              defaultValue={repeatDate[0] ? repeatDate[0] : ''}
            >
              {repeatDate?.length > 0 && repeatDate.map((month, idx) => (
                <Option key={idx} value={month}>
                  {month}
                </Option>
              ))}
            </Select>
          </div>
          <b>Week(s)</b>
          <div>
            <div class="container_weekly">
              <label className="fieldName">Repeat Every</label>
              <StyledToggleButtonGroup
                minimum
                size="medium"
                arial-label="Days of the week"
                value={days}
                onChange={(event, value) => value.length > 0 && onWeekChangeHandler(event, value)}
              >
                {DAYS.map((day, index) => (
                  <StyledToggle key={day.key} value={day.weekday} selected={day.weekday[2]} aria-label={day.key}>
                    {day.label}
                  </StyledToggle>
                ))}
              </StyledToggleButtonGroup>
            </div>
          </div>
        </div>
      }

      {props.data === "MONTHLY" &&
        <div>
          <div class="container_weekly">
            <input type="radio" className='radioBtn' id="one" name="one" onChange={() => { radioHandler(); monthlyHandler(days, weekdays); props.onMonthHandler() }} defaultChecked />
            <label className={disableBtn == true ? 'fieldName_monthlys' : 'fieldName_monthly'} >Repeat Every</label>
            <Select getPopupContainer={(node) => node.parentNode}
              onChange={props.onIntervalRecurringHandler}
              size="medium"
              disabled={disableBtn}
              defaultValue={repeatDate[0] ? repeatDate[0] : ''}
            >
              {repeatDate?.length > 0 && repeatDate.map((month, idx) => (
                <Option key={idx} value={month}>
                  {month}
                </Option>
              ))}
            </Select>
            <label className={disableBtn == true ? 'fieldName_monthlys' : 'fieldName_monthly'} >Month(s)</label>
            <label className={disableBtn == true ? 'fieldName_monthlys' : 'fieldName_monthly'} >On Date</label>
            <Select getPopupContainer={(node) => node.parentNode}
              onChange={props.onWeekDayRecurringHandler}
              style={{ width: "80px" }}
              disabled={disableBtn}
              defaultValue={slicedData}
            >
              {monthDate.map((month, idx) => (
                <Option key={idx} value={month}>
                  {month}
                </Option>
              ))}

            </Select>
          </div>
          <div>
            <div class="container_weekly">
              <input type="radio" id="two" className='radioBtn' name="one" onChange={() => { radioHandlers(); monthlyHandler(days, weekdays); props.onMonthHandlers() }} />
              <label className={disableBtns == true ? 'fieldName_monthlys' : 'fieldName_monthly'} >The</label>
              <Select getPopupContainer={(node) => node.parentNode}
                onChange={onMonthlyChangeHandlers}
                size="medium"
                className="fieldName_monthly"
                disabled={disableBtns}
                value={weekdays}
                style={{ width: "150px" }}
                defaultValue={WEEKDAYS[0] ? WEEKDAYS[0] : ''}
              >
                {WEEKDAYS.map((day, index) => (
                  <Option key={day.label} value={day.key} aria-label={day.key}>
                    {day.label}
                  </Option>
                ))}
              </Select>
              <Select getPopupContainer={(node) => node.parentNode}
                className="fieldName_monthly"
                size="medium"
                style={{ width: "150px" , marginLeft:"10px"}}
                disabled={disableBtns}
                onChange={onMonthlyChangeHandler}
                value={days}
              >
                {DAYS.map((day, index) => (
                  <Option key={day.key} value={day.weekday} aria-label={day.key}>
                    {day.key}
                  </Option>
                ))}
              </Select>
              <label className={disableBtns == true ? 'fieldName_monthlys' : 'fieldName_monthly'} >of every</label>
              <Select getPopupContainer={(node) => node.parentNode}
                onChange={props.onIntervalRecurringHandler}
                style={{ width: "80px" }}
                disabled={disableBtns}
                defaultValue={repeatDate[0] ? repeatDate[0] : ''}
              >
                {repeatDate?.length > 0 && repeatDate.map((month, idx) => (
                  <Option key={idx} value={month}>
                    {month}
                  </Option>
                ))}
              </Select>
              <label className={disableBtns == true ? 'fieldName_monthlys' : 'fieldName_monthly'} >Month(s)</label>
            </div>
          </div>
          <div>
            <div class="container_enddate">
              <label className="fieldName">End Date </label>
              <DatePicker
                className="antd_startDate_picker"
                format="MM-DD-YYYY"
                placeholder="MM/DD/YY"
                size="small"
                style={{ height: "32px" }}
                disabledDate={disabledRecurrenceDates}
                getPopupContainer={(node) => node.parentNode}
                showTime={false}
                value={endRecurringDate ? moment(endRecurringDate) : date}
                onChange={endDateHandler}
              />
            </div>
          </div>
        </div>
      }
    </div >
  );
};
export default RecurringComponent;