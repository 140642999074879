import React, { Component } from "react";
import "date-fns";
import './AddTaskModal.css'
import { Radio } from 'antd';
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import * as action from "../../Store/Actions/index";
import add_product_icon from "../../Assets/images/add-product-icon.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import { Select, DatePicker, TimePicker } from "antd";
import { connect } from "react-redux";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import { withRouter } from "react-router-dom";
import event_type_arrow from "../../Assets/images/event_type_arrow.svg";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import addTaskIcon from "../../Assets/images/add_task_icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import { Alert, Autocomplete } from "@material-ui/lab";
import { AxiosInstance, checkCmsLabel } from "../../Utilities";
import momentTimeZone from 'moment-timezone';
import moment from "moment";
import "moment/locale/en-nz";
import { Scrollbars } from 'react-custom-scrollbars';
import { Transition } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import DateTimeRangePickerComponent from "../../SharedComponents/DateTimeRangePickerComponent/DateTimeRangePickerComponent";
import userBusy from "../../Assets/images/user-busy.svg";
import taskWarningIcon from "../../Assets/images/task_warning_icon.svg";
import userIcon from "../../Assets/images/user-icon.png";
import { AWS_AxiosInstance } from "../../AWS_AxiosInstance";
import RecurringComponent from "./TaskDetails/RecurringComponent/RecurringComponent";
const { Option } = Select;
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    overflow: "initial",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#21BF73",
        border: `1px solid #21BF73`,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
var i = 0;
export class AddTaskModal extends Component {
  constructor(props) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);
    var zoneName = momentTimeZone.tz.guess();
    moment.tz.setDefault(localStorage.getItem("timeZone"));
    this.dialogContent = React.createRef();
    this.state = {
      zoneNameValue: zoneName,
      cmsLabelChange: [],
      roleLabelChange: [],
      patientNameChange: "",
      caseNumberChange: "", 
      priceChange: "",
      quantityChange: "",
      statusValue: false,
      i: 0,
      timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
      price: [],
      itemaarray: [
        {
          price: 0,
          index: "",
          name: "",
        },
      ],
      accountOption: [
        {
          id: "one",
          name: "",
        },
      ],

      deptNew: [
        { id: "", name: "" }
      ],

      deptOption: [
        {
          id: "one",
          name: "",
        },
      ],

      serviceOption: [
        {
          id: "one",
          name: "",
        },
      ],
      salesrepOption: [
        {
          id: "",
          name: "",
        },
      ],
      salesrepOptions: [
        {
          id: "",
          name: "",
        },
      ],
      productOption: [
        {
          id: "one",
          name: "",
        },
      ],
      accountNameError: "",
      departmentNameError: "",
      serviceNameError: "",
      representativeNameError: "",
      startDateError: "",
      endDateError: "",
      startTimeError: "",
      endTimeError: "",
      pastTaskStatusError: "",
      formError: {
        accountName: "",
        departmentName: "",
        serviceName: "",
        startDate: "",
        endDate: "",
      },
      accountName: "",
      departmentName: "",
      serviceName: "",
      addprdlist: "none",
      addproductcheck: false,
      products: [
        {
          index: Math.random(),
          productId: "",
          price: 0,
          quantity: 0,
          amount: 0,
          // priceError: "",
          // quantityError: "",
          // dupError: "",
        },
      ],
      accountId: "",
      priceErrors: "",
      quantityErrors: "",
      departmentId: "",
      serviceId: "",
      assignedBy: "",
      assignedTo: "",
      priority: "",
      // startDate: momentTimeZone(new Date()).tz(zoneName),
      // endDate: momentTimeZone(new Date()).add(1, 'hours').tz(zoneName),
      taskDescription: "",
      taskStatus: "",
      alertType: "",
      responseMsg: "",
      accountError: "",
      deprtError: "",
      selError: "",
      assignedname: "",
      multipleassignedTo: [],
      mainAssignees: [],
      selectTaskValues: [],
      additionalReps: [],
      taskattendeesDefault: [],
      taskAttendees: [],
      repeatTask: "NEVER",
      intervalRecurrence: "",
      weekIntervalRecurrence: "",
      frequencyRecurrence: "",
      monthDayRecurrence: [],
      weekRecurrence: [],
      onWeekMonthly: false,
      endDateRecurrence: "",
      isMultipleAttendees: true,
      multiple: "",
      servError: "",
      despError: "",
      repeat: "",
      multiple_repeat: [],
      attendeesArrays: [],
      isvalid: false,
      changeColorComplete: false,
      changeColorUncovered: false,
      changeColorCancelled: false,
      dateRangeProps: {
        startDate: {
          initialViewDate:
            momentTimeZone()
              .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
              .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            value: ""
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endDate: {
          initialViewDate: momentTimeZone()
            .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
            .format("MM-DD-YYYY hh:mm A"),
          inputProps: {
            placeholder: "MM-DD-YYYY hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control"
          },
          dateFormat: "MM-DD-YYYY",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
      },
      timeRangeProps: {
        startTime: {
          initialViewDate:
            momentTimeZone()
              .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
              .format("hh:mm A"),
          inputProps: {
            placeholder: "hh:mm A",
            readOnly: true,
            disabled: true,
            value: ""
          },
          dateFormat: "hh:mm A",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
        endTime: {
          initialViewDate: momentTimeZone()
            .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
            .format("hh:mm A"),
          inputProps: {
            placeholder: "hh:mm A",
            readOnly: true,
            disabled: true,
            value: "",
            className: "endDate form-control"
          },
          dateFormat: "hh:mm A",
          timeFormat: true,
          locale: "en-nz",
          timeConstraint: { minutes: { step: 15 } },
        },
      },
      addQuantityError: true,
      addPriceError: true,
      addProductError: true,
      patientName: "",
      caseNumber: "",
      combinedStartDateTime: "",
      combinedEndDateTime: "",
      timezones: [
        { name: 'US/Pacific' },
        { name: 'US/Eastern' },
        { name: 'US/Central' },
        { name: 'US/Mountain' }
      ],
      note: ""
    };
  };
  componentDidMount = async () => {
    const userData = JSON.parse(localStorage.getItem("userdata"))
    if (userData.role !== "Super Admin" && localStorage.getItem('cms') && localStorage.getItem('cms') != "undefined" && "companyId" in JSON.parse(localStorage.getItem('cms'))) {
      const labelChange = (JSON.parse(localStorage.getItem('cms')))?.labelChange?.label
      labelChange.forEach((item) => {
        if (item.value == "patientName") {
          this.setState({
            patientNameChange: item.checked
          })
        }
        if (item.value == "caseNumber") {
          this.setState({
            caseNumberChange: item.checked
          })
        }
        if (item.value == "price") {
          this.setState({
            priceChange: item.checked
          })
        }
        if (item.value == "quantity") {
          this.setState({
            quantityChange: item.checked
          })
        }
      })
    }
    const fetchCmsData = checkCmsLabel()
    if (fetchCmsData) {
      this.setState({
        cmsLabelChange: fetchCmsData.cmsData,
        roleLabelChange: fetchCmsData.roleData
      })
    }
    this.setState({ accountName: "" });
    this.setState({ alertType: "", responseMsg: "" });

    var data = {
      accountName: "",
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/account-filter", data)
      .then((res) => {
        if (res.data.status === "200" || res.data.status === 200) {
          var finaldata = res.data.data;
          var datas = [];
          finaldata.map(data => {
            var accopt = {};
            accopt.id = data._id;
            accopt.name = data.accountName;
            datas.push(accopt);
          });
          this.setState({
            accountOption: datas,
          });
        } else {
          this.setState({
            accountOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
    var { accountOption, formError } = this.state;
    var data1 = await accountOption.filter((item) => {
    });
    data1.map((accountData) => {
      this.setState({
        accountName: accountData.name,
        accountId: accountData.id,
        [formError.accountId]: "",
      });
    });
  };
  onValueChange(event) {
    this.setState({
      pastTaskStatusError: "",
      taskStatus: event.target.value
    });
  }
  addRowHandler = (e) => {
    this.setState({ addprdlist: true, addproductcheck: true });
    if (this.state.i === 0) {
      this.setState({ i: i + 1 });
    } else {
      this.setState(
        (prevState) => ({
          products: [
            ...prevState.products,
            {
              index: Math.random(),
              productId: "",
              price: "",
              quantity: "",
            },
          ],
        }),
        () => {
          this.setState({ i: i + 1 });
        }
      );
    }
  };
  addProductHandler = (e) => {
    this.setState({ addprdlist: "block" });
  };
  clearInput() {
    this.setState({
      departmentId: '',
      deptName: '',
    });
  }
  handleShow = () => {
    this.setState({ isActive: true });
  };

  handleHide = () => {
    this.setState({ isActive: false });
  };
  onAccountChange = () => {
  };

  accountListHandler = async (e) => {
    var name = e.target.value;
    this.setState({ accountName: name });

    var data = {
      accountName: name,
    };
    if (e.target.value.length > 100) {
      this.setState({ accountError: "* Account should not exceed 100 characters." })
    } else {
      this.setState({ accountError: "" })
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/account-filter", { params:data })
      .then((res) => {
        if (res.data.status === "200" || res.data.status === 200) {
          var finaldata = res.data.data;
          var datas = [];
          finaldata.map(data => {
            var accopt = {};
            accopt.id = data._id;
            accopt.name = data.accountName;
            datas.push(accopt);
          });
          this.setState({
            accountOption: datas,
          });
        } else {
          this.setState({
            accountOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
    var { accountOption, formError } = this.state;
    var data1 = await accountOption.filter((item) => {
      return item.name === name;
    });
    data1.map((accountData) => {
      this.setState({
        accountName: accountData.name,
        accountId: accountData.id,
        [formError.accountId]: "",
      });
    });
  };
  onSelectHandler = (e) => {
    if (!e.target.value) {
      this.accountListHandler(e);
    } else {
      var name = e.target.value;
      var { accountOption, formError } = this.state;
      var data = accountOption.filter((item) => {
        return item.name === name;
      });
      data.map((accountData) => {
        this.setState({
          accountNameError: "",
          accountId: accountData.id,
          accountName: accountData.name,
          [formError.accountId]: "",
        });
      });
      if (this.state.accountId !== "") {
      }
    }
  };

  timeZoneListHandler = async (e) => {
    var value = e.target.value;
    this.setState({
      timeZoneName: value
    })
  }
  onSelectTimeZoneHandler = (e) => {
    if (!e.target.value) {
      this.setState({
        timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : this.state.zoneNameValue,
      })
    } else {
      this.setState({
        timeZoneName: e.target.value
      })
    }
  }
  onChangeStart = async (e) => {
    var name = e.target.value;
    this.setState({ start: name });

  };
  departmentListHandler = async (e) => {
    var name = e.target.value;
    this.setState({ departmentName: name });
    var accountId = this.state.accountId;
    var data = {
      deptName: name,
      accountId: accountId,
    };
    if (e.target.value.length > 100) {
      this.setState({ deprtError: "* Department should not exceed 100 characters." })
    } else {
      this.setState({ deprtError: "" })
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6005 : 'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/account/department", data)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === '200') {
          this.setState({
            //setData
            deptOption: res.data.finalDept.length !== 0 ? res.data.finalDept[0].departmentsDetails : [],
          });
        } else {
          this.setState({
            deptOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
            // deptNew:[{
            //   id:"",
            //   name:""
            // }]
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
    var { deptOption, formError } = this.state;
    var data1 = await deptOption.filter((item) => {
      return item.name === name;
    });
    data1.map((deptData) => {
      this.setState({
        departmentId: deptData.id,
        departmentName: deptData.name,
        [formError.deptId]: "",
      });
    });
  };
  onSelectDeptHandler = (e) => {
    this.setState({
      deptOption: [{ id: "", name: "" }]
    })
    if (!e.target.value) {
      this.departmentListHandler(e);
    } else {
      var name = e.target.value;
      var { deptOption, formError } = this.state;
      var data = deptOption.filter((item) => {
        return item.name === name;
      });
      data.map((deptData) => {
        this.setState({
          departmentNameError: "",
          departmentId: deptData.id,
          departmentName: deptData.name,
          deptNew: [{
            id: deptData.id,
            name: deptData
          }],
          [formError.deptId]: "",
        });
      });
    }
  };
  salesrepListHandler = async (e) => {
    var name = e.target.value;
    var accountId = this.state.accountId;
    var data = {
      search: name,
      acountId: accountId,
      endDate: this.state.dateRangeProps.endDate.initialViewDate,
      startDate: this.state.dateRangeProps.startDate.initialViewDate,
      limit: false
    };
    if (e.target.value.length > 100) {
      this.setState({ selError: "* Assign Representative should not exceed 100 characters" })
    } else { this.setState({ selError: "" }) }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/employeeFilterStatus", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          var respData = response.data;
          var assignee = [];
          respData.map(assigne => {
            var assigneObj = {};
            assigneObj._id = assigne._id;
            assigneObj.name = assigne.name;
            assigneObj.status = assigne.status;
            if (assigne.hasOwnProperty('profilePath')) {
              assigneObj.profilePath = assigne.profilePath;
            } else {
              assigneObj.profilePath = "";
            }
            assignee.push(assigneObj);
          });
          if (this.state.multiple_repeat.length > 0) {
            var datas = [];
            for (let i = 0; i < respData.length; i++) {

              for (let j = 0; j < this.state.multiple_repeat.length; j++) {
                if (respData[i]._id === this.state.multiple_repeat[j]) {

                  if (datas.length < 1) {
                    datas = respData.filter((data) => data._id !== this.state.multiple_repeat[j])
                  }
                  else {
                    datas = datas.filter((data) => data._id !== this.state.multiple_repeat[j])
                  }
                }
              }
            }
          }
          this.setState({
            //setData
            salesrepOption: this.state.multiple_repeat.length > 0 ? datas : respData,
            mainAssignees: this.state.multiple_repeat.length > 0 ? datas : respData
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            salesrepOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
    var { salesrepOption, formError } = this.state;
    var data1 = await salesrepOption.filter((item) => {
      return item.name === name;
    });
    data1.map((repData) => {
      this.setState({
        assignedTo: repData.id,
        assignedName: repData.name,
        [formError.repId]: "",
      });
    });
  };
  onSelectSalesrepHandler = (e) => {
    if (!e.target.value) {
      this.salesrepListHandler(e);
    } else {
      var name = e.target.value;
      var { salesrepOption, formError } = this.state;
      var data;
      if (this.state.selectTaskValues.length > 0) {
        var attendeesArrayss = [];
        this.state.selectTaskValues.map((item) => {
          var items = item.split("name")[0];
          attendeesArrayss.push(items);
        });
        var mainAssignee = this.state.mainAssignees
        for (let i = 0; i < attendeesArrayss.length; i++) {
          mainAssignee = mainAssignee.filter((data) => data._id != attendeesArrayss[i])
        }
        data = mainAssignee
        this.setState({
          salesrepOption: mainAssignee
        })
        data = salesrepOption.filter((item) => {
          return item.name === name;
        });
      } else {
        data = salesrepOption.filter((item) => {
          return item.name === name;
        });
      }
      data.map((repData) => {
        this.setState({
          representativeNameError: "",
          assignedname: repData.name,
          assignedTo: repData._id,
          [formError.deptId]: "",
          repeat: repData._id,

        });

      });
    }
  };
  onMultipleAttendeeOnFocusHandler = (e) => {
    var search = e.target.value;
    this.getRepresentativeFilter(search);
  };

  onMultipleAttendeeSearch = (value) => {
    var search = value;
    this.getRepresentativeFilter(search);
  };
  onMultipleAttendeeChangeHandler = (value) => {
    if (this.state.isMultipleAttendees) {
      var attendees = value;
      var attendeesArrays = [];
      attendees.map((item) => {
        var items = item.split("name")[0];
        attendeesArrays.push(items);
      });
      this.setState({
        taskAttendees: attendeesArrays,
        selectTaskValues: value,
        multiple_repeat: attendeesArrays,
      });
    } else {
      var dataArray = [];
      var multipleData = value.split("name");
      dataArray.push(value);
      this.setState(
        {
          eventAttendees: [],
          selectTaskValues: [],
        },
        () => {
          this.setState({
            taskAttendees: dataArray,
            selectTaskValues: multipleData[1],
          });
        }
      );
    }
  };

  onRepeatChangeHandler = (value) => {
    this.setState({
      repeatTask: value ? value : "NEVER",
    });
  };
  onIntervalRecurringHandler = (value) => {
    this.setState({
      intervalRecurrence: value,
    });
  };
  onWeekIntervalRecurringHandler = (value) => {
    this.setState({
      weekIntervalRecurrence: value
    });
  };
  onWeekDayRecurringHandler = (value) => {
    this.setState({
      monthDayRecurrence: [value],
      onWeekMonthly: false

    })
  }
  onFrequencyRecurringHandler = (value) => {
    this.setState({
      frequencyRecurrence: value
    });
  }
  onWeekRecurringHandler = (event, value) => {
    this.setState({
      ...this.props.weekRecurrence, weekRecurrence: value
    })
  };
  onWeekMonthRecurringHandler = (value) => {
    this.setState({
      onWeekMonthly: true,
      weekRecurrence: [value]
    })
  };

  onMonthHandler = () => {
    this.setState({ onWeekMonthly: false });
  };

  onMonthHandlers = () => {
    this.setState({ onWeekMonthly: true });
  };
  endDateRecurringHandler = (value) => {
    console.log(new Date(value))
    if (value && value != "") {
      this.setState({
        endDateRecurrence: value
      })
    }
  }
  getEmployeeFilter = (search) => {
    this.setState(
      {
        additionalReps: [],
      }
    )
  };

  getRepresentativeFilter = (search) => {
    var accountId = this.state.accountId;
    this.setState(
      {
        additionalReps: [],
        taskattendeesDefault: [],
      },
      () => {
        let data = {
          search: search,
          acountId: accountId,
          endDate: this.state.dateRangeProps.endDate.initialViewDate,
          startDate: this.state.dateRangeProps.startDate.initialViewDate,
          limit: false
        }
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/json")
          .post("/employeeFilterStatus", data)
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState({
                additionalReps: response.data,
              });
              const data = response.data.filter((data) =>
                data._id !== this.state.repeat
              )
              this.setState({
                additionalReps: data
              });

            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              let propserror = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                additionalReps: [],
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }

            this.setState({
              additionalReps: [],
            });
          });
      }
    );
  };

  serviceListHandler = async (e) => {
    var name = e.target.value;
    this.setState({ serviceName: name });
    var data = {
      search: name,
    };
    if (e.target.value.length > 100) {
      this.setState({ servError: "* Service should not exceed 100 characters" })
    } else {
      this.setState({ servError: "" })
    }
    /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
    AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ?6007
    :process.env.REACT_APP_NODE_ENV === "stage"
    ?'/'
    :'service', "application/x-www-form-urlencoded; charset=UTF-8") */

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/getServiceFilter", data)
      .then((res) => {
        if (res.data.status === "200" || res.data.status === 200) {
          this.setState({
            serviceOption: res.data.data,
          });
        } else {
          this.setState({
            serviceOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
    var { serviceOption, formError } = this.state;
    var data1 = await serviceOption.filter((item) => {
      return item.name === name;
    });
    data1.map((serviceData) => {
      this.setState({
        serviceName: serviceData.name,
        serviceId: serviceData.id,
        [formError.serviceId]: "",
      });
    });
  };
  onSelectServiceHandler = (e) => {
    if (!e.target.value) {
      this.serviceListHandler(e);
    } else {
      var name = e.target.value;
      var { serviceOption, formError } = this.state;
      var data = serviceOption.filter((item) => {
        return item.name === name;
      });
      data.map((serviceData) => {
        this.setState({
          serviceNameError: "",
          serviceId: serviceData._id,
          serviceName: serviceData.name,
          [formError.serviceId]: "",
        });
      });
    }
  };
  onChangeprdHandler = async (e) => {
    // let products = [...this.state.products];
    var name = e.target.value;
    // if (products[e.target.id] !== undefined) {
    //   products[e.target.id][e.target.name] = e.target.value;
    //   this.setState({ products });
    var data = {
      search: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/productFilter", data)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === '200') {
          if (res.data.data.length !== 0) {
            this.setState({
              productOption: res.data.data,
            });
          }
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
    // var { productOption, formError } = this.state;
    // var data1 = await productOption.filter((item) => {
    //   return item.name === name;
    // });
    // data1.map((productData) => {
    //   this.setState({
    //     productName: productData.name,
    //     productId: productData.id,
    //     price: productData.price,
    //     [formError.productName]: "",
    //   });
    // });
    // }

  };
  onSelectPrdHandler = async (e) => {
    var { name, value } = e.target;
    const quant = /^[0-9]{1,3}$/;
    const pric = /^\d{1,5}(\.\d{1,2})?$/;
    if (!e.target.value) {
      let products = [...this.state.products];
      products[e.target.dataset.id][e.target.name] = 0;
      this.onChangeprdHandler(e);
    } else {
      e.preventDefault();

      var { productOption } = this.state;
      let products = [...this.state.products];
      let price = 0;
      let quantity = 0;

      if (e.target.name === "quantity") {
        if (quant.test(e.target.value)) {
          this.setState({ isvalid: false, addQuantityError: true })
          products[e.target.dataset.id]['quantityError'] = "";
        }
        else {
          if(this.state.priceChange && this.state.quantityChange){
            this.setState({ isvalid: true, addQuantityError: false })
             products[e.target.dataset.id]['quantityError'] = "Please enter the valid Quantity. e.g: 50, 10..etc";
          }
        }
        products[e.target.dataset.id][e.target.name] = e.target.value;
        quantity = e.target.value;
      } else if (e.target.name === "price") {
        if (pric.test(e.target.value)) {
          this.setState({ isvalid: false, addPriceError: true })
          products[e.target.dataset.id]['priceError'] = "";
        }
        else {
          if(this.state.priceChange && this.state.quantityChange){
          this.setState({ isvalid: true, addPriceError: false })
          products[e.target.dataset.id]['priceError'] = "Please enter the valid Price/Amount/Rate: Eg: 95.50, 100";
          }
        }
        products[e.target.dataset.id][e.target.name] = e.target.value;
        price = e.target.value;
      }
    }
  };

  onSelectproductIdHandler = (e) => {
    this.onChangeprdHandler(e);
    var { name, value } = e.target;
    let products = [...this.state.products];
    const { productOption } = this.state
    var data = productOption.filter((item) => {
      return item.name === value;
    });
    data.map((item) => {
      products[e.target.id]["productId"] = item._id;
    });
    this.setState({ products });
  }


  addTaskHandler = (e) => {
    e.preventDefault();
    var {
      accountNameError,
      departmentNameError,
      serviceNameError,
      representativeNameError,
      startDateError,
      accountName,
      departmentName,
      serviceName,
      endDateError,
      pastTaskStatusError,
      accountId,
      departmentId,
      deprtError,
      serviceId,
      assignedTo,
      attendeesArrays,
      priority,
      repeatTask,
      taskAttendees,
      intervalRecurrence,
      endDateRecurrence,
      frequencyRecurrence,
      monthDayRecurrence,
      weekRecurrence,
      weekIntervalRecurrence,
      taskDescription,
      products,
      taskStatus,
      priceErrors,
      quantityErrors,
      caseNumber,
      patientName,
      combinedStartDateTime,
      combinedEndDateTime,
      note
    } = this.state;
    if (accountName === "") {
      accountNameError = "Please select an Account";
    } else {
      accountNameError = "";
    }
    if (taskStatus === "" && this.state.combinedStartDateTime <= new Date()) {
      pastTaskStatusError = "Please select a status of the task";
    } else {
      pastTaskStatusError = "";
    }
    if (departmentName === "") {
      departmentNameError = "Please select a Department";
    } else {
      departmentNameError = "";
    }
    if (serviceName === "") {
      serviceNameError = "Please select a Service";
    } else {
      serviceNameError = "";
    }
    if (this.state.combinedStartDateTime === "") {
      startDateError = "Please select start date and end date";
    } else {
      startDateError = "";
    }
    if (this.state.combinedEndDateTime === "") {
      startDateError = "Please select start date and end date";
    } else {
      startDateError = "";
    }
    if (this.state.taskAttendees.length >= 1 && this.state.assignedTo === "") {
      representativeNameError = "Please select Representative";
    } else {
      representativeNameError = "";
    }
    if (this.state.combinedStartDateTime === "" && this.state.combinedEndDateTime === "") {
      endDateError = "Please enter start date and end date";
    } else if (
      this.state.combinedStartDateTime >=
      this.state.combinedEndDateTime
    ) {
      endDateError =
        "End date should not be less than or equal to start date";
    } else {
      endDateError = "";
    }

    products.forEach((product, index) => {
      if (product.productId === undefined || product.productId === null || product.productId === '') {
        this.setState({ addProductError: false });
        const updatedProducts = [...products];  
        updatedProducts[index]['addProductError'] = "*Please select the Product";
        this.setState({ products: updatedProducts });
      }
    });
    

    this.setState({
      accountNameError,
      departmentNameError,
      serviceNameError,
      startDateError,
      representativeNameError,
      accountName,
      departmentName,
      serviceName,
      endDateError,
      pastTaskStatusError,
      repeatTask,
      taskAttendees,
      taskStatus,
      endDateRecurrence
    }, () => {
      if (products[0] === "" || products[0] === undefined) {
        products = [];
      } else {
        products = products;
      }
      var recurrenceOptions = this.state.repeatTask ? this.state.repeatTask : "NEVER";
      let formatStartDate, formatEndDate;
      if (this.state.combinedStartDateTime && this.state.combinedEndDateTime && this.state.timeZoneName) {
        const startingDate = this.state.combinedStartDateTime.utc().toISOString().slice(0, -1)
        const endingDate = this.state.combinedEndDateTime.utc().toISOString().slice(0, -1)
        const centralStartDate = momentTimeZone.tz(startingDate, this.state.timeZoneName);
        const centralEndDate = momentTimeZone.tz(endingDate, this.state.timeZoneName);
        const startDateTime = centralStartDate.clone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : this.state.zoneNameValue);
        const endDateTime = centralEndDate.clone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : this.state.zoneNameValue);
        formatStartDate = startDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        formatEndDate = endDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      }
      var userData = {
        timezone: this.state.timeZoneName,
        accountName: this.state.accountName,
        departmentName: this.state.departmentName,
        serviceName: this.state.serviceName,
        assignedToName: this.state.assignedname,
        recurringOption: this.state.repeatTask ? this.state.repeatTask : "NEVER",
        taskStatus: this.state.taskStatus,
        accountId,
        departmentId,
        serviceId,
        assignedTo,
        additionalReps: this.state.taskAttendees,
        startDate: formatStartDate ? formatStartDate : moment.tz(momentTimeZone(this.state.combinedStartDateTime), this.state.timeZoneName).utc(),
        endDate: formatEndDate ? formatEndDate : moment.tz(momentTimeZone(this.state.combinedEndDateTime), this.state.timeZoneName).utc(),
        priority: priority ? priority : false,
        taskDescription,
        products,
        patientName,
        caseNumber,
        note
      };
      var dailyRecurrence = {}
      var weeklyRecurrence = {}
      var monthlyRecurrence = {}
      if (recurrenceOptions === "DAILY") {
        dailyRecurrence = {
          interval: this.state.intervalRecurrence
        }
        if (this.state.endDateRecurrence) {
          let tast_start_date = moment(this.state.dateRangeProps.endDate.inputProps.value);
          let tast_end_date = moment(this.state.dateRangeProps.startDate.inputProps.value);
          //Task with different start and end date
          if (!tast_start_date.isSame(tast_end_date, 'day')) {
            dailyRecurrence.endDate =  moment(this.state.endDateRecurrence).add(24, 'hours');
          }else{
            dailyRecurrence.endDate = this.state.endDateRecurrence
          }
        }
      } else if (recurrenceOptions === "WEEKLY") {
        weeklyRecurrence = {
          interval: this.state.intervalRecurrence,
          byDayOfWeek: this.state.weekRecurrence,
        }
        if (this.state.endDateRecurrence) {
          let tast_start_date = moment(this.state.dateRangeProps.endDate.inputProps.value);
          let tast_end_date = moment(this.state.dateRangeProps.startDate.inputProps.value);
          //Task with different start and end date
          if (!tast_start_date.isSame(tast_end_date, 'day')) {
            weeklyRecurrence.endDate =  moment(this.state.endDateRecurrence).add(24, 'hours');
          }else{
            weeklyRecurrence.endDate = this.state.endDateRecurrence
          }
        }
      } else if (recurrenceOptions === "MONTHLY") {
        if (this.state.onWeekMonthly === true) {
          monthlyRecurrence = {
            interval: this.state.intervalRecurrence,
            byDayOfWeek: this.state.weekRecurrence
          }
        } else {
          monthlyRecurrence = {
            interval: this.state.intervalRecurrence,
            byDayOfMonth: this.state.monthDayRecurrence
          }
        }
        if (this.state.endDateRecurrence) {
          let tast_start_date = moment(this.state.dateRangeProps.endDate.inputProps.value);
          let tast_end_date = moment(this.state.dateRangeProps.startDate.inputProps.value);
          //Task with different start and end date
          if (!tast_start_date.isSame(tast_end_date, 'day')) {
            weeklyRecurrence.endDate =  moment(this.state.endDateRecurrence).add(24, 'hours');
          }else{
            weeklyRecurrence.endDate = this.state.endDateRecurrence
          }
        }
      }
      var recurringRules = {}
      recurringRules = Object.assign(dailyRecurrence, weeklyRecurrence, monthlyRecurrence)
      var recurringRulesValidators = { recurringRules }
      var userTaskData = {}
      if (recurrenceOptions === "NEVER") {
        userTaskData = Object.assign(userData);
      } else {
        userTaskData = Object.assign(userData, recurringRulesValidators);
      }
      this.dialogContent.scrollIntoView({
        behavior: "smooth",
        block: this.state.pastTaskStatusError ? "end" : "start",
      })
      const isEmpty = Object.values(accountNameError,
        departmentNameError,
        deprtError,
        serviceNameError,
        representativeNameError,
        accountName,
        departmentName,
        serviceName,
        startDateError,
        endDateError,
        pastTaskStatusError).every(
          (x) => x === null || x === ""
        );
        let pr = true;
        if(this.state.products.length > 0 && this.state.addprdlist === true){
          pr = products.every((product) => {
            return product.productId !== undefined && product.productId !== null && product.productId !== '';
          });
        }
      if (pr===true && pastTaskStatusError === "" && this.state.priceErrors === "" && this.state.quantityErrors === "" && departmentNameError === "" && serviceNameError === "" && this.state.addQuantityError === true && this.state.addPriceError === true && representativeNameError === "" && isEmpty && Object.values(endDateError).every(x => x === null || x === "") && Object.values(startDateError).every(x => x === null || x === "")) {
        this.setState({ isLoading: true, isvalid: true })
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : 'task', "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/add_task", userTaskData)
          .then((res) => {
            var response = res.data;
            if (res.data.status === 200 || res.data.status === "200") {
              this.setState(
                {
                  responseMsg: 'Task created successfully',
                  alertType: "success",
                  isLoading: false,
                },
                () => {
                  var props = {
                    message: response.msg,
                    icon: <img src={iconNotificationSuccess} alt="success" />,
                    type: "success",
                    placement: "topRight",
                    duration: 3,
                    top: 200,
                    className: "task_notification update-notify",
                  };
                  NotificationComponent(props);
                  setTimeout(() => {
                    this.setState({ responseMsg: '' }, () => {
                      this.props.onPagination(1, 10);
                      this.props.onClose();
                    })
                  }, 2000);
                }
              );

            } else {
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isLoading: false,
                isvalid: false
              })
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props);

            }
          }).catch(err => {
            var error = err.response;
            if (error && (error.status === '403' || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500)
            } else {
              console.log("Internal Server Error")
            }
            this.setState({
              isLoading: false,
              isvalid: false
            });
          });
      }
    });
  };
  handlechecked = (event) => {
    if (event.target.checked === true) {
      this.setState({
        statusValue: true
      })
    } else {
      this.setState({
        statusValue: false
      })
    }
    if (event.target.value.length > 3000) {
      this.setState({ despError: "* Description should not exceed 3000 characters" })
    } else {
      this.setState({
        despError: "",
        [event.target.name]: !event.target.checked
          ? event.target.value
          : event.target.checked,
      });
    }
  };
  deleteRow = (index) => {
    const productlist = [...this.state.products];
    productlist.splice(index, 1);
    this.setState({ products: productlist });
  };
  clearForm = () => {
    const { dateRangeProps, timeRangeProps } = this.state;
    dateRangeProps.startDate.inputProps.value = "";
    dateRangeProps.endDate.inputProps.value = "";
    timeRangeProps.startTime.inputProps.value = "";
    timeRangeProps.endTime.inputProps.value = "";
    dateRangeProps.endDate.inputProps.disabled = true;
    timeRangeProps.startTime.inputProps.disabled = true;
    timeRangeProps.endTime.inputProps.disabled = true;
    this.setState(
      {
        dateRangeProps,
        departmentId: "",
        accountName: "",
        departmentName: "",
        serviceName: "",
        isvalid: false,
        assignedBy: "",
        assignedTo: "",
        multipleassignedTo: [],
        selectTaskValues: [],
        additionalReps: [],
        taskAttendees: [],
        taskStatus: "",
        repeatTask: "",
        isMultipleAttendees: true,
        attendeesDefault: [],
        priority: "",
        taskDescription: "",
        accountNameError: "",
        departmentNameError: "",
        serviceNameError: "",
        representativeNameError: "",
        startDateError: "",
        endDateError: "",
        pastTaskStatusError: "",
        deptNew: [{ id: "", name: "" }],
        formError: {
          accountName: "",
          departmentName: "",
          serviceName: "",
          startDate: "",
          endDate: "",
        },
        products: [],
        selectTaskValues: [],
        taskAttendees: [],
        repeatTask: "",
        additionalReps: [],
        taskattendeesDefault: [],
        isMultipleAttendees: true,
        additionalReps: [],
        taskattendeesDefault: [],
        isMultipleAttendees: true,
        repeat: "",
        multiple_repeat: [],
        endDateRecurrence: "",
        intervalRecurrence: "",
        frequencyRecurrence: "",
        weekRecurrence: [],
        weekIntervalRecurrence: "",
        monthDayRecurrence: [],
        caseNumber: "",
        patientName: "",
        combinedStartDateTime: "",
        combinedEndDateTime: "",
        note: ""
      }
    );
  };
  valid = (current) => {
    return current && current < moment().subtract(1, 'day');
  };

  disabledDates = (current) => {
    return current && current.isBefore(moment(this.state.dateRangeProps.startDate.initialViewDate));//.subtract(1, "day"));
  };

  onStartDateChange = (date, dateString) => {
    this.setState({ newStartDate: dateString });
    const { dateRangeProps, timeRangeProps } = this.state;
    dateRangeProps.startDate.initialViewDate = momentTimeZone(date);
    dateRangeProps.startDate.inputProps.value = momentTimeZone(date);
    timeRangeProps.startTime.inputProps.disabled = false;
    this.setState({
      endDateError: "",
      dateRangeProps
    });
    if (this.state.timeRangeProps.startTime.inputProps.value !== "") {
      const startDate = momentTimeZone(this.state.dateRangeProps.startDate.inputProps.value);
      const startTime = momentTimeZone(this.state.timeRangeProps.startTime.inputProps.value);
      const combinedStartDateTime = startDate.set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: startTime.get('second'),
      });
      dateRangeProps.endDate.initialViewDate = momentTimeZone(combinedStartDateTime).add(1, 'hours');
      dateRangeProps.endDate.inputProps.value = momentTimeZone(combinedStartDateTime).add(1, 'hours');
      timeRangeProps.endTime.initialViewDate = momentTimeZone(combinedStartDateTime).add(1, 'hours');
      timeRangeProps.endTime.inputProps.value = momentTimeZone(combinedStartDateTime).add(1, 'hours');
      const endDate = momentTimeZone(this.state.dateRangeProps.endDate.inputProps.value);
      const endTime = momentTimeZone(this.state.timeRangeProps.endTime.inputProps.value);
      const combinedEndDateTime = endDate.set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: endTime.get('second'),
      });
      //Reset recurrence
      this.onRepeatChangeHandler("NEVER")
      this.setState({
        endDateError: "",
        dateRangeProps,
        combinedStartDateTime: momentTimeZone(combinedStartDateTime),
        combinedEndDateTime: momentTimeZone(combinedEndDateTime)
      });
    }
  }

  onStartTimeChange = (time, timeString) => {
    this.setState({ newStartTime: timeString });
    const { dateRangeProps, timeRangeProps } = this.state;
    const selectedTime = momentTimeZone(time)
    timeRangeProps.startTime.initialViewDate = selectedTime;
    timeRangeProps.startTime.inputProps.value = selectedTime;
    timeRangeProps.endTime.initialViewDate = momentTimeZone(selectedTime).add(1, 'hours');
    timeRangeProps.endTime.inputProps.value = momentTimeZone(selectedTime).add(1, 'hours');
    dateRangeProps.endDate.inputProps.disabled = false;
    timeRangeProps.endTime.inputProps.disabled = false;
    const startDate = momentTimeZone(this.state.dateRangeProps.startDate.inputProps.value);
    const startTime = momentTimeZone(this.state.timeRangeProps.startTime.inputProps.value);
    const combinedStartDateTime = startDate.set({
      hour: startTime.get('hour'),
      minute: startTime.get('minute'),
      second: startTime.get('second'),
    });
    dateRangeProps.endDate.initialViewDate = momentTimeZone(combinedStartDateTime).add(1, 'hours');
    dateRangeProps.endDate.inputProps.value = momentTimeZone(combinedStartDateTime).add(1, 'hours');
    timeRangeProps.endTime.initialViewDate = momentTimeZone(combinedStartDateTime).add(1, 'hours');
    timeRangeProps.endTime.inputProps.value = momentTimeZone(combinedStartDateTime).add(1, 'hours');
    const endDate = momentTimeZone(this.state.dateRangeProps.endDate.inputProps.value);
    const endTime = momentTimeZone(this.state.timeRangeProps.endTime.inputProps.value);
    const combinedEndDateTime = endDate.set({
      hour: endTime.get('hour'),
      minute: endTime.get('minute'),
      second: endTime.get('second'),
    });
    //Reset recurrence
    this.onRepeatChangeHandler("NEVER")
    this.setState({
      endDateError: "",
      timeRangeProps,
      combinedStartDateTime: momentTimeZone(combinedStartDateTime),
      combinedEndDateTime: momentTimeZone(combinedEndDateTime)
    });
  }

  onEndDateChange = (date, dateString) => {
    this.setState({ newEndDate: dateString })
    const { dateRangeProps, addEventFormError } = this.state;
    dateRangeProps.endDate.initialViewDate = momentTimeZone(date);
    dateRangeProps.endDate.inputProps.value = momentTimeZone(date);
    //Reset recurrence
    this.onRepeatChangeHandler("NEVER")
    this.setState({
      endDateError: "",
      dateRangeProps,
    });
  }

  onEndTimeChange = (time, timeString) => {
    this.setState({ newStartTime: timeString });
    const { timeRangeProps, addEventFormError } = this.state;
    const selectedTime = momentTimeZone(time)
    timeRangeProps.endTime.initialViewDate = momentTimeZone(selectedTime);
    timeRangeProps.endTime.inputProps.value = momentTimeZone(selectedTime);
    const endDate = momentTimeZone(this.state.dateRangeProps.endDate.inputProps.value);
    const endTime = momentTimeZone(this.state.timeRangeProps.endTime.inputProps.value);
    const combinedEndDateTime = endDate.set({
      hour: endTime.get('hour'),
      minute: endTime.get('minute'),
      second: endTime.get('second'),
    });
    //Reset recurrence
    this.onRepeatChangeHandler("NEVER")
    this.setState({
      combinedEndDateTime: momentTimeZone(combinedEndDateTime),
      endDateError: "",
      timeRangeProps
    });
  }

  clearAccount = (event, value, reason) => {
    if (reason === 'reset' || reason === 'clear') {
      this.setState({
        accountName: "",
        accountId: "",
        deptNew: [{
          id: "",
          name: ""
        }],
        departmentId: "",
        departmentName: ""
      })
    }
  }
  clearRecurrence = (event, value, reason) => {
    if (reason === 'reset') {
      this.setState({
        endDateRecurrence: "",
        intervalRecurrence: "",
        frequencyRecurrence: "",
        weekRecurrence: [],
        weekIntervalRecurrence: "",
        monthDayRecurrence: [],
      })
    } else if (reason === 'clear') {
      this.setState({
        endDateRecurrence: "",
        intervalRecurrence: "",
        frequencyRecurrence: "",
        weekRecurrence: [],
        weekIntervalRecurrence: "",
        monthDayRecurrence: [],
      })
    }
  }
  clearDepartment = (event, value, reason) => {
    if (reason === 'reset') {
      this.setState({
        deptNew: [{
          id: "",
          name: ""
        }],
        departmentId: "",
        departmentName: ""
      })
    } else if (reason === 'clear') {
      this.setState({
        deptNew: [{
          id: "",
          name: ""
        }],
        departmentId: "",
        departmentName: ""
      })
    }
  }
  clearRepresentative = (event, value, reason) => {
    if (reason === 'reset') {
      this.setState({
        assignedname: "",
        repeat: "",
        assignedTo: ""


      })
    } else if (reason === 'clear') {
      this.setState({
        assignedname: "",
        repeat: "",
        assignedTo: ""
      })
    }
  }
  clearAdditionalRepresentative = (event, value, reason) => {
    if (reason === 'reset') {
      this.setState({
        taskAttendees: "",
        selectTaskValues: "",
        multiple_repeat: ""


      })
    } else if (reason === 'clear') {
      this.setState({
        taskAttendees: "",
        selectTaskValues: "",
        multiple_repeat: ""
      })
    }
  }

  clearTimeZone = (event, value, reason) => {
    if (reason === 'reset' || reason === 'clear') {
      this.setState({
        timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : this.state.zoneNameValue,
      })
    }
  }



  render() {
    var { accountNameError, departmentNameError, serviceNameError, startDateError, endDateError, pastTaskStatusError, representativeNameError } = this.state;
    let amount = [];

    this.state.products.map(
      (prod, index) => (amount[index] = prod.price * prod.quantity)
    );
    const { accountName } = this.state;
    const { assignedTo } = this.state;
    const enabled = accountName.length > 0;
    const enabledreps = assignedTo.length > 0;
    return (
      <div>
        <Dialog
          onExit={this.clearForm}
          {...this.props}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog add_task_dialog"
          TransitionComponent={Transition}
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" >
            <img src={addTaskIcon} className="add-company_img" alt="add task" />
            <span>Add Task</span>
            <IconButton
              onClick={this.props.onClose}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn} alt="close btn" />
            </IconButton>
          </DialogTitle>
          <Scrollbars className="custom_scrollbar" style={{ width: "auto", height: 541 }} >
            <DialogContent
              ref={node => {
                this.dialogContent = node;
              }}
            >
              <form>
                {this.state.responseMsg !== "" ? (<Alert severity={this.state.alertType}>{this.state.responseMsg}</Alert>) : null}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <label className="label bl-label">
                      {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"} <span className="labelAsterisk">*</span>
                    </label>
                    <>
                      <Autocomplete
                        id="accountName"
                        className="cusautocomplete"
                        options={this.state.accountOption}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => true}
                        onInputChange={this.clearAccount}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="accountName"
                            margin="dense"
                            placeholder={`Search and Select ${this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"}`}
                            onChange={this.accountListHandler}
                            onSelect={this.onSelectHandler}
                          />
                        )}
                      />
                      <span className="error">   {accountNameError !== "" ? (
                        <>
                          {" "}
                          <span className="error">*{accountNameError}</span>
                        </>
                      ) : (
                        ""
                      )}
                        {this.state.accountError !== "" ? (
                          <>
                            {" "}
                            <p className="error_massage">{this.state.accountError} </p>
                          </>
                        ) : (
                          ""
                        )}</span>
                    </>
                    <label className="label bl-label">
                      {this.state.cmsLabelChange.Department ? this.state.cmsLabelChange.Department : "Department"}<span className="labelAsterisk">*</span>
                    </label>
                    <>
                      <Autocomplete
                        disabled={!enabled}
                        id="departmentName"
                        className="cusautocomplete"
                        options={this.state.deptOption}
                        getOptionLabel={(option) => option.name}
                        value={this.state.deptNew && this.state.deptNew.length > 0 ? this.state.deptNew[0].name : ""}
                        onInputChange={this.clearDepartment}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="departmentName"
                            margin="dense"
                            placeholder={`Search and Select ${this.state.cmsLabelChange.Department ? this.state.cmsLabelChange.Department : "Department"}`}
                            onChange={this.departmentListHandler}
                            onSelect={this.onSelectDeptHandler}
                          />
                        )}
                      />
                      <span className="error">{departmentNameError !== "" ? (
                        <>
                          {" "}
                          <span className="error">
                            *{departmentNameError}
                          </span>
                        </>
                      ) : (
                        ""
                      )}

                        {this.state.deprtError !== "" ? (
                          <>
                            {" "}
                            <p className="error_massage">{this.state.deprtError} </p>
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </>
                    <div className="custom-ant-select time-zone">
                      <label className="label bl-label">Time Zone </label>
                      <>
                        <Autocomplete
                          id="timeZone"
                          className="cusautocomplete"
                          options={this.state.timezones}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => true}
                          onInputChange={this.clearTimeZone}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              name="timeZone"
                              margin="dense"
                              placeholder="Search Time Zone"
                              onChange={this.timeZoneListHandler}
                              onSelect={this.onSelectTimeZoneHandler}
                            />
                          )}
                        />
                      </>
                    </div>
                    <label className="label bl-label">{this.state.roleLabelChange.Representative ? "Assign" + " " + this.state.roleLabelChange.Representative : "Assign Representative "} </label>
                    <Autocomplete
                      disabled={!enabled}
                      id="assignedTo"
                      className="cusautocomplete"
                      options={this.state.salesrepOption}
                      onInputChange={this.clearRepresentative}
                      getOptionLabel={(option) => option.name}
                      getOptionDisabled={(option) => option.status === 'unavailable' ? true : false}
                      renderOption={(option) => (
                        <>
                          {option.profilePath ?
                            <img src={`https://salesc2userprofile.s3.amazonaws.com/${option.profilePath}`}
                              className="auto-assignee-profile-pic profile-pic"
                              alt={option.profilePath}
                            /> :
                            <img src={userIcon} alt="noImage"
                              className="auto-assignee-profile-pic profile-pic" />}
                          {option.default === "true" ?
                            <span className="auto-assignee-profile-name">{option.name + " (Assign to me) "}{option.status === 'busy' ?
                              <img src={taskWarningIcon} alt="taskWarningIcon" className="auto-assignee-priority-icon" />
                              : option.status === 'unavailable' ?
                                <img src={userBusy} alt="userBusy" className="auto-assignee-priority-icon" /> : null}
                            </span>
                            :
                            <span className="auto-assignee-profile-name">{option.name}{option.status === 'busy' ?
                              <img src={taskWarningIcon} alt="taskWarningIcon" className="auto-assignee-priority-icon" />
                              : option.status === 'unavailable' ?
                                <img src={userBusy} alt="userBusy" className="auto-assignee-priority-icon" /> : null}
                            </span>
                          }

                        </>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="dense"
                          name="assignedTo"
                          placeholder={`Search and Select ${this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative"}`}
                          onChange={this.salesrepListHandler}
                          onSelect={this.onSelectSalesrepHandler}
                        />
                      )}
                    />

                    <span className="error">{representativeNameError !== "" ? (
                      <>
                        {" "}
                        <span className="error">*{representativeNameError}</span>
                      </>
                    ) : (
                      ""
                    )}

                      {this.state.selError !== "" ? (
                        <>
                          {" "}
                          <p className="error_massage">{this.state.selError} </p>
                        </>
                      ) : (
                        ""
                      )}</span>

                    {this.state.caseNumberChange === true &&
                      <><label className="label bl-label">{this.state.cmsLabelChange.CaseNumber ? this.state.cmsLabelChange.CaseNumber : "Case Number"}</label><CssTextField
                        id="outlined-basic"
                        name="caseNumber"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        placeholder={`Enter ${this.state.cmsLabelChange.CaseNumber ? this.state.cmsLabelChange.CaseNumber : "Case Number"}`}
                        onChange={this.handlechecked} /></>}

                    <label className="label bl-label description">
                      {this.state.cmsLabelChange.Description
                        ? this.state.cmsLabelChange.Description
                        : "Description"}
                    </label>
                    <CssTextField
                      id="outlined-basic"
                      name="taskDescription"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      placeholder={`Enter ${this.state.cmsLabelChange.Description ? this.state.cmsLabelChange.Description : "Description"}`}
                      onChange={this.handlechecked}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {!((this.state.repeatTask == "DAILY") || (this.state.repeatTask == "WEEKLY") || (this.state.repeatTask == "MONTHLY")) ?
                      <div className="switch-container">
                        <FormControlLabel
                          name="priority"
                          control={<AntSwitch color="primary" />}
                          onChange={this.handlechecked}
                          label="Priority"
                          margin="dense"
                          labelPlacement="start"
                        />

                        <label className="label labelEmergency">
                          <span className="labelAsterisk ">Emergency</span>
                        </label>
                        <span className="error"></span>
                      </div> :
                      <div className="switch-container">
                        <FormControlLabel
                          name="priority"
                          control={<AntSwitch color="primary" />}
                          onChange={this.handlechecked}
                          label="Priority"
                          margin="dense"
                          labelPlacement="start"
                          disabled
                        />

                        <label className="label labelEmergency">
                          <span className="labelAsterisk ">Emergency</span>
                        </label>
                        <span className="error"></span>
                      </div>}
                    <label className="label bl-label">
                      {this.state.cmsLabelChange.Service ? this.state.cmsLabelChange.Service : "Service"}<span className="labelAsterisk">*</span>
                    </label>
                    <>
                      <Autocomplete
                        id="service"
                        className="cusautocomplete"
                        options={this.state.serviceOption}
                        getOptionLabel={(option) => option.name}
                        onInputChange={() => { }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            name="serviceName"
                            placeholder={`Search and Select ${this.state.cmsLabelChange.Service ? this.state.cmsLabelChange.Service : "Service"}`}
                            onChange={this.serviceListHandler}
                            onSelect={this.onSelectServiceHandler}
                          />
                        )}
                      />
                      <span className="error">{serviceNameError !== "" ? (
                        <>
                          {" "}
                          <span className="error">*{serviceNameError}</span>
                        </>
                      ) : (
                        ""
                      )}

                        {this.state.servError !== "" ? (
                          <>
                            {" "}
                            <p className="error_massage">{this.state.servError} </p>
                          </>
                        ) : (
                          ""
                        )}</span>
                    </>
                    <div className="custom-ant-select date-time-separator" >
                      <Grid container spacing={2} style={{ padding: "8px 0px 0px 8px" }}>
                        <Grid item xs={6} style={{ padding: "0px" }} className="mobile-label-start">
                          <label className="label bl-label">
                            Start Date & Time <span className="labelAsterisk">*</span>
                          </label>
                        </Grid>
                        <Grid item xs={6} style={{ padding: "0px" }} className="mobile-label-end">
                          <label className="label bl-label" style={{ marginRight: "0px" }}>
                            To Date & Time <span className="labelAsterisk">*</span>
                          </label>
                        </Grid>
                      </Grid>
                      <div className="custom-ant-picker-select">
                        <Grid container spacing={2} className="mobile-view-container">
                          <Grid item xs={3} className="start-date-picker-container">
                            <DatePicker
                              id="picker_addtask"
                              className="antd_startDate_picker"
                              format="MM-DD-YYYY"
                              placeholder="MM-DD-YYYY"
                              size="large"
                              value={this.state.dateRangeProps.startDate.inputProps.value ? momentTimeZone(this.state.dateRangeProps.startDate.inputProps.value) : ""}
                              inputReadOnly={true}
                              showNow={false}
                              allowClear={false}
                              onSelect={this.onStartDateChange}
                              getPopupContainer={(node) => node.parentNode}
                            />
                            <span className="error-wrapper">   {endDateError !== "" ? (
                              <>
                                {" "}
                                <span className="error error-message">*{endDateError}</span>
                              </>
                            ) : (
                              ""
                            )}</span>
                          </Grid>
                          <Grid item xs={2} className="time-picker-container">
                            <TimePicker
                              id="picker_addtask"
                              className="antd_startDate_picker"
                              format="hh:mm A"
                              placeholder="HH:MM"
                              size="large"
                              inputReadOnly={true}
                              showNow={false}
                              allowClear={false}
                              getPopupContainer={(node) => node.parentNode}
                              disabled={this.state.timeRangeProps && this.state.timeRangeProps.startTime.inputProps.disabled ? true : false}
                              showTime={{ defaultValue: moment('00:00', 'HH:mm'), minuteStep: 15 }}
                              value={this.state.timeRangeProps.startTime.inputProps.value ? momentTimeZone(this.state.timeRangeProps.startTime.inputProps.value) : ""}
                              onSelect={this.onStartTimeChange}
                            />
                          </Grid>
                          <Grid item xs={2} className="to-label">
                            <span>  TO </span>
                          </Grid>
                          <Grid item xs={3} className="end-date-picker-container">
                            <DatePicker
                              id="picker_addtask"
                              className="antd_endDate_picker "
                              format="MM-DD-YYYY"
                              placeholder="MM-DD-YYYY"
                              size="large"
                              inputReadOnly={true}
                              showNow={false}
                              allowClear={false}
                              onSelect={this.onEndDateChange}
                              disabled={this.state.dateRangeProps && this.state.dateRangeProps.endDate.inputProps.disabled ? true : false}
                              value={this.state.dateRangeProps.endDate.inputProps.value ? momentTimeZone(this.state.dateRangeProps.endDate.inputProps.value) : ""}
                              getPopupContainer={(node) => node.parentNode}
                              disabledDate={this.disabledDates}
                            />
                          </Grid>
                          <Grid item xs={2} className="time-picker-container">
                            <TimePicker
                              id="picker_addtask"
                              className="antd_startDate_picker"
                              format="hh:mm A"
                              placeholder="HH:MM"
                              size="large"
                              inputReadOnly={true}
                              showNow={false}
                              allowClear={false}
                              getPopupContainer={(node) => node.parentNode}
                              disabled={this.state.timeRangeProps && this.state.timeRangeProps.endTime.inputProps.disabled ? true : false}
                              showTime={{ defaultValue: moment('00:00', 'HH:mm'), minuteStep: 15 }}
                              value={this.state.timeRangeProps.endTime.inputProps.value ? momentTimeZone(this.state.timeRangeProps.endTime.inputProps.value) : ""}
                              onSelect={this.onEndTimeChange}
                            />
                          </Grid>
                        </Grid>
                        <span className="error-messageor">   {endDateError !== "" ? (
                          <span className="error error-message">*{endDateError}</span>
                        ) : (
                          ""
                        )}</span>
                      </div>
                    </div>
                    <div className="custom-ant-select addtional_rep">
                      <label className="label bl-label">{this.state.roleLabelChange.Representative ? "Additional" + " " + this.state.roleLabelChange.Representative : "Additional Representative "} </label>
                      <>
                        <Select
                          className="cusautocomplete"
                          mode="multiple"
                          margin="dense"
                          disabled={!enabledreps}
                          style={{ width: 250 }}
                          placeholder={`Search and Select ${this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative"}`}
                          name="eventAttendees"
                          showArrow
                          suffixIcon={<img src={event_type_arrow} alt="type" />}
                          showSearch
                          autoClearSearchValue={true}
                          value={this.selectTaskValues}
                          onFocus={this.onMultipleAttendeeOnFocusHandler}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={this.onMultipleAttendeeChangeHandler}
                          onSearch={this.onMultipleAttendeeSearch}
                          onInputChange={this.clearAdditionalRepresentative}
                        >
                          {this.state.additionalReps.length > 0
                            ? this.state.additionalReps.map((option) => (
                              <Option
                                disabled={option.status === 'unavailable' ? true : false}
                                key={option._id}
                                value={option._id + "name" + option.name}
                              >
                                {option.profilePath ?
                                  <img src={`https://salesc2userprofile.s3.amazonaws.com/${option.profilePath}`}
                                    className="auto-assignee-profile-pic profile-pic"
                                    alt={option.profilePath}
                                  /> :
                                  <img src={userIcon} alt="noImage"
                                    className="auto-assignee-profile-pic profile-pic" />}
                                {option.default === "true" ?
                                  <span className="auto-assignee-profile-name">{option.name + " (Assign to me) "}{option.status === 'busy' ?
                                    <img src={taskWarningIcon} alt="taskWarningIcon" className="auto-assignee-priority-icon" />
                                    : option.status === 'unavailable' ?
                                      <img src={userBusy} alt="userBusy" className="auto-assignee-priority-icon" /> : null}
                                  </span>
                                  :
                                  <span className="auto-assignee-profile-name">{option.name}{option.status === 'busy' ?
                                    <img src={taskWarningIcon} alt="taskWarningIcon" className="auto-assignee-priority-icon" />
                                    : option.status === 'unavailable' ?
                                      <img src={userBusy} alt="userBusy" className="auto-assignee-priority-icon" /> : null}
                                  </span>
                                }
                              </Option>
                            ))
                            : ""}
                        </Select>
                        <span className="error">{this.state.selError !== "" ? (
                          <>
                            {" "}
                            <p className="error_massage">{this.state.selError} </p>
                          </>
                        ) : (
                          ""
                        )}</span>
                      </>
                    </div>

                    {this.state.patientNameChange === true &&
                      <><label className="label bl-label">{this.state.cmsLabelChange.PatientName ? this.state.cmsLabelChange.PatientName : "Patient Name"}</label><CssTextField
                        id="outlined-basic"
                        name="patientName"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        placeholder={`Enter ${this.state.cmsLabelChange.PatientName ? this.state.cmsLabelChange.PatientName : "Patient Name"}`}
                        onChange={this.handlechecked} /></>
                    }
                    <label className="label bl-label notes">Notes</label>
                    <CssTextField
                      id="outlined-basic"
                      name="note"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      placeholder="Enter notes here"
                      onChange={this.handlechecked}
                    />
                  </Grid>
                </Grid>
                {this.state.statusValue === false && (this.state.combinedStartDateTime >= new Date()) ?
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div className="custom-ant-select">
                        <label className="label bl-label">Recurrence </label>
                        <Select autoClearSearchValue={true}
                          value={this.state.repeatTask}
                          disabled={!this.state.dateRangeProps.startDate.inputProps.value}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={this.onRepeatChangeHandler}
                          defaultValue="Never">
                          <Option value="NEVER">Never</Option>
                          <Option value="DAILY">Daily</Option>
                          <Option value="WEEKLY">Weekly</Option>
                          <Option value="MONTHLY">Monthly</Option>
                        </Select>
                      </div>
                    </Grid>
                  </Grid> : ""}
                {this.state.repeatTask !== "never" ? (
                  <RecurringComponent data={this.state.repeatTask}
                    taskEndDate={this.state.dateRangeProps.endDate.inputProps.value}
                    startDateRecurrence={this.state.dateRangeProps.startDate.inputProps.value}
                    disableData={this.state.dateRangeProps.startDate.initialViewDate}
                    onInputChange={this.clearRecurrence}
                    taskStartDate={this.state.dateRangeProps.startDate.initialViewDate}
                    endDateRecurringHandler={this.endDateRecurringHandler}
                    onIntervalRecurringHandler={this.onIntervalRecurringHandler}
                    onFrequencyRecurringHandler={this.onFrequencyRecurringHandler}
                    onWeekRecurringHandler={this.onWeekRecurringHandler}
                    onWeekMonthRecurringHandler={this.onWeekMonthRecurringHandler}
                    onMonthHandler={this.onMonthHandler}
                    onMonthHandlers={this.onMonthHandlers}
                    onWeekIntervalRecurringHandler={this.onWeekIntervalRecurringHandler}
                    onWeekDayRecurringHandler={this.onWeekDayRecurringHandler}
                  />
                ) : ""}
                <span className="error">{this.state.selError !== "" ? (
                  <>
                    {" "}
                    <p className="error_massage">{this.state.selError} </p>
                  </>
                ) : (
                  ""
                )}</span>
                <p className="error_massage" >{this.state.despError} </p>
                <label className="label products-label" style={{ marginTop: "2px" }}>
                  {this.state.cmsLabelChange.Product ? this.state.cmsLabelChange.Product : "Product"}
                </label>
                <div style={{ display: this.state.addprdlist }}>
                  {this.state.products.map((value, index) => (
                    <>
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={12} sm={!this.state.priceChange && !this.state.quantityChange ? 11  : this.state.priceChange && this.state.quantityChange ? 5 :   this.state.priceChange || this.state.quantityChange ? 9 : 5} >
                          <label className="label bl-label">{this.state.cmsLabelChange.Item  ? this.state.cmsLabelChange.Item  : "Item"} </label>
                          <Autocomplete
                            id={index}
                            className="cusautocomplete"
                            margin="dense"
                            data-id={index}
                            options={this.state.productOption}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <CssTextField
                                inputProps={{ "data-id": index }}
                                {...params}
                                variant="outlined"
                                //  name="item"
                                margin="dense"
                                placeholder={`Search ${this.state.cmsLabelChange.Item  ? this.state.cmsLabelChange.Item : "Name"}`}
                                onChange={this.onChangeprdHandler}
                                onSelect={this.onSelectproductIdHandler}
                              />
                            )}
                          />
                          {/* Product error */}
                          {value.productId ? "" :
                          <span className="error"> {this.state.addProductError === false && value.addProductError  &&  value?.addProductError !== "" ? (
                            value.addProductError
                           ) : (
                             ""
                           )}</span>}

                        </Grid>
                        {this.state.priceChange  && 
                        <Grid item xs={12} sm={2}>
                          <label className="label bl-label"> {this.state.cmsLabelChange.Price  ? this.state.cmsLabelChange.Price  : "Price"} </label>
                          <CssTextField
                            inputProps={{ "data-id": index }}
                            id="outlined-basic"
                            margin="dense"
                            name="price"
                            variant="outlined"
                            type="number"
                            fullWidth
                            placeholder="0"
                            onChange={this.onSelectPrdHandler}
                          />

                          {this.state.priceChange && this.state.quantityChange  && <span className="error"> {this.state.addPriceError === false ? (
                           "Please enter the valid Price/Amount/Rate: Eg: 95.50, 100"
                          ) : (
                            ""
                          )}</span>}
                        </Grid>}
                        {this.state.quantityChange && 
                        <Grid item xs={12} sm={2}>
                          <label className="label bl-label">{this.state.cmsLabelChange.Quantity  ? this.state.cmsLabelChange.Quantity   : "Quantity"}  </label>
                          <CssTextField
                            inputProps={{ "data-id": index }}
                            id="outlined-basic"
                            margin="dense"
                            name="quantity"
                            variant="outlined"
                            type="number"
                            fullWidth
                            placeholder="0"
                            onChange={this.onSelectPrdHandler}
                          />
                          {this.state.priceChange && this.state.quantityChange  && <span className="error"> {this.state.addQuantityError === false ? (
                            "Please enter the valid Quantity. e.g: 50, 10..etc"
                          ) : (
                            ""
                          )}</span> }
                        </Grid>}
                         {this.state.priceChange && this.state.quantityChange  &&
                        <Grid item xs={12} sm={2}>
                          <label className="label bl-label">{this.state.cmsLabelChange.Amount  ? this.state.cmsLabelChange.Amount   : "Amount"}  </label>
                          <CssTextField
                            inputProps={{ "data-id": index }}
                            id="outlined-basic"
                            margin="dense"
                            name="amount"
                            variant="outlined"
                            type="text"
                            fullWidth
                            placeholder="0"
                            value={"$ " + Number((this.state.products[index]["price"] *
                              this.state.products[index]["quantity"]).toFixed(2))
                            }
                            disabled
                          />
                        
                        </Grid>
                         }
                        <Grid item xs={12} sm={1} >
                          <div style={{ transform: 'translate(20px, 35px)' }}>
                          {index >= 0 && (
                            <RemoveCircleOutlineIcon
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => this.deleteRow(index)}
                            />
                          )}
                          </div>
                          
                        </Grid>
                      </Grid>
                    </>
                  ))}
                  {this.state.priceChange && this.state.quantityChange  &&
                  <Grid item xs={12} sm={12}>
                    <label
                      className="label bl-label"
                      style={{ float: "right", paddingTop: "18px" }}
                      margin="dense"
                    >
                      <b>Total:</b>{" "}
                      <span>
                        {"$ " + amount.reduce(
                          (totalCalories, amount) => Number((totalCalories + amount).toFixed(1)),
                          0
                        )}
                      </span>
                    </label>
                  </Grid>}
                </div>
                <Button
                  className="add_dept_btn"
                  variant="outlined"
                  onClick={this.addRowHandler}
                >
                  <img src={add_product_icon} alt="add" />
                  Add {this.state.cmsLabelChange.Product ? this.state.cmsLabelChange.Product : "Product"}
                </Button>

                <div className="add_company_action">
                  {this.state.combinedStartDateTime !== "" && (this.state.combinedStartDateTime <= new Date()) ?
                    <>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                          <Radio.Group defaultValue="uncovered" buttonStyle="solid" className="status_btn">
                            <Radio.Button value="Completed"
                              className={this.state.changeColorComplete === true ? "completed_btn2" : "completed_btn"}
                              checked={this.state.selectedOption === "completed"}
                              onChange={this.onValueChange}
                              onClick={() => this.setState({ changeColorComplete: !this.state.changeColorComplete, changeColorUncovered: false, changeColorCancelled: false })}
                            >
                              <span style={{ position: 'relative', top: '6px' }}>Completed</span>
                            </Radio.Button>
                            <Radio.Button value="Uncovered"
                              className={this.state.changeColorUncovered === true ? "uncovered_btn2" : "uncovered_btn"}
                              checked={this.state.selectedOption === "uncovered"}
                              onChange={this.onValueChange}
                              onClick={() => this.setState({ changeColorUncovered: !this.state.changeColorUncovered, changeColorCancelled: false, changeColorComplete: false })}
                            >
                              <span style={{ position: 'relative', top: '6px' }}>Uncovered</span>
                            </Radio.Button>
                            <Radio.Button value="Cancelled"
                              className={this.state.changeColorCancelled === true ? "cancelled_btn2" : "cancelled_btn"}
                              checked={this.state.selectedOption === "cancelled"}
                              onChange={this.onValueChange}
                              onClick={() => this.setState({ changeColorCancelled: !this.state.changeColorCancelled, changeColorUncovered: false, changeColorComplete: false })}
                            >
                              <span style={{ position: 'relative', top: '6px' }}>Cancelled</span>
                            </Radio.Button>
                          </Radio.Group>
                          <span className="error"> {pastTaskStatusError !== "" ? (
                            <>
                              {" "}
                              <span className="error">*{pastTaskStatusError}</span>
                            </>
                          ) : (
                            ""
                          )}
                          </span>
                        </Grid>
                      </Grid>
                    </>
                    : ""
                  }
                  <Button
                    className="cancel_btn white-btn"
                    onClick={this.props.onClose}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button disabled={this.state.isvalid}
                    className="ok_btn green-btn"
                    type="submit"
                    variant="contained"
                    onClick={this.addTaskHandler}
                  >
                    Add Task
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Scrollbars>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole) =>
      dispatch(action.taskpagination(pageNo, pagerole)),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(withRouter(AddTaskModal));