import React from 'react';
import './CustomPaginationComponent.css';
import Pagination from '@material-ui/lab/Pagination';
import {Select,MenuItem} from '@material-ui/core';
const CustomPaginationComponent = (props) => {
    return (
        <div className="custom_pagination">
             <div className="custom_select">
                 <span>Rows per page:</span>
                 <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.rowsPerPage}
                    onChange={props.onChangeRowsPerPage}
                    >
                    {/* <MenuItem value={1}>1</MenuItem> */}
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
             </div>
             <div className="mat-pagination">
             <Pagination 
                defaultPage={1}
                count={props.count}
                page={props.page} 
                showFirstButton 
                showLastButton 
                onChange={props.onPageChange}
                />
                </div>
        </div>
    );
}
 
export default CustomPaginationComponent;