import React, { Component, Suspense } from "react";
import "../AccountsComponent.css";
import { withStyles } from "@material-ui/core/styles";
import {
  emailRegx,
  phoneRegx,
} from "../../../SharedComponents/Validation/formValidation";
import {
  Checkbox,
  FormControlLabel,
  Box,
  Breadcrumbs,
 
  Typography,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  TextField,
  Select,
  Divider,
} from "@material-ui/core";
import {lowerCase } from "lodash";
import backIcon from "../../../Assets/images/breadcrumb_back.svg";
import ExpandMoreIcon from "../../../Assets/images/expansion_dd.svg";
import editIcon from "../../../Assets/images/view_edit_icon.svg";
import iconCity from "../../../Assets/images/Icon-location-city.svg";
import iconLocation from "../../../Assets/images/Icon-location-on.svg";
import iconAsset from "../../../Assets/images/Icon-web-asset.svg";
import add_dept_icon from "../../../Assets/images/add_dept_icon.svg";
import close_icon_red from "../../../Assets/img/close_icon_red.svg";
import { AxiosInstance, checkCmsLabel } from "../../../Utilities";
import { Autocomplete } from "@material-ui/lab";
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import validator from "validator";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationSuccess from "../../../Assets/images/icon-notify-success.svg";
import { NavLink } from "react-router-dom";
import HeaderComponent from "../../../SharedComponents/HeaderComponent/HeaderComponent";
import { GoogleMapsAPI } from "../../../client-config";
import AutoComplete from "react-google-autocomplete";
import {
  getGooglePlaceObjectValues,
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../../SharedComponents/CommonUtilities/CommonUtilities";
import ChangeParentComponent from "./ChangeParentComponent";
// var HeaderComponent = React.lazy(() =>
//   import("../../../SharedComponents/HeaderComponent/HeaderComponent")
// );
var EditDepartmentComponent = React.lazy(() =>
  import("./EditDepartmentComponent")
);
var EditContactComponent = React.lazy(() => import("./EditContactComponent"));
var AccountDetailsSuperadminComponent = React.lazy(() =>
  import("./AccountDetailsSuperadminComponent")
);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
var i = 0;
var j = 0;
class AccountDetailsComponent extends Component {
  constructor(props) {
    super(props);
    let userRole = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata")).role
      : "";
    let accDta = window.location.href;
    const [url,accId] = accDta.split("/account-details/");
    this.state = {
      cmsLabelChange:[],
      userRole: userRole.toLowerCase(),
      accountId: accId,
      accountName: "",
      editing:false,
      showChangeParent:false,
      hasParent:false,
      parentAccount:null,
      address: "",
      city: "",
      department: [],
      state: "",
      website: "",
      new_website:"",
      zipCode: "",
      description: "",
      isState: true,
      isAddress: true,
      isAccountname: true,
      isDesc: true,
      isWebsite: true,
      showDept: false,
      showCont: false,
      autocompleteVisible: false,
      parentId: "",
      parentName: "",
      selectedParent: "",
      eventLocation:[],
      formError: {
        address: "",
        website: "",
        state: "",
        accountName:'',
      },
      editedDept: "",
      parentOption: [
        {
          id: "",
          name: "No Data",
        },
      ],
      departments: [
        {
          index: Math.random(),
          name: "",
          dError:'',
          dbutton:true,
          cbutton:false,
          pbutton:false,
          ebutton:false,
          contacts: [
            {
              index: Math.random(),
              name: "",
              cError:'',
              phone: [
                {
                  index: Math.random(),
                  phoneNo: "",
                  phoneType: "work",
                  phoneErr: "",
                  
                },
              ],
              email: [
                {
                  index: Math.random(),
                  emailId: "",
                  emailType: "work",
                  emailErr: "",
                  
                },
              ],
              isPrimary: false,
            },
          ],
        },
      ],
      contacts: [
        {
          index: Math.random(),
          name: "",
          cError:"",
          cbutton:true,
          pbutton:false,
          ebutton:false,
          phone: [
            {
              index: Math.random(),
              phoneNo: "",
              phoneType: "work",
              phoneErr: "",
            },
          ],
          email: [
            {
              index: Math.random(),
              emailId: "",
              emailType: "work",
              emailErr: "",
            },
          ],
          isPrimary: false,
        },
      ],
    };
    this.myDivToFocus = React.createRef();
    this.editInput = this.editInput.bind(this);
    this.addContactRow = this.addContactRow.bind(this);
  }
  componentDidMount = () => {
  const fetchCmsData = checkCmsLabel()
  if(fetchCmsData){
    this.setState({ cmsLabelChange : fetchCmsData.cmsData})
  }
    this.fetchAccountDetail();
  };
  fetchAccountDetail=()=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get(`/account-details/${this.state.accountId}`)
      .then((resp) => {
        var responseData = resp && resp.data;
        if (
          responseData &&
          (responseData.status === "200" || responseData.status === 200)
        ) {
          let response = "";
          if (this.state.userRole === "super admin") {
            response = responseData.data;
          } else {
            response = responseData.data[0];
          }

          this.setState({
            accountName: response.accountName,
            address: response.address,
            city: response.city,
            website: response.website,
            department: response.departments,
            state: response.state,
            zipCode: response.zipCode,
            description: response.description,
            hasParent:response.hasParent,
            parentAccount:response.parentAccount
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  }
  handleOnScroll = (event) => {
    if (this.myDivToFocus.current) {
      this.myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  editInputParent=()=>{
    this.setState({
      showChangeParent:true,
      editing:true,
    })
  }
  deleteParent=()=>{
    this.updateParentAccount({accountId:this.state.accountId})
  }
  editInputParentClose=()=>{
    this.setState({
      showChangeParent:false,
      editing:false,
    })
  }
  editInput = (name) => {
    // this.componentDidMount()
    const {formError} = this.state;
    switch (name) {
      case "isState":
        this.setState({ isState: !this.state.isState });
        break;
      case "isAddress":
        formError.address = '';
        this.setState({ isAddress: !this.state.isAddress,formError });
        break;
      case "isAccountname":
          formError.accountName = '';
          this.setState({ isAccountname: !this.state.isAccountname,formError });
          break;
      case "isDesc":        
        this.setState({ isDesc: !this.state.isDesc });
        break;
      case "isWebsite":
        formError.website = '';
        this.setState({ isWebsite: !this.state.isWebsite,formError });
        break;
      default:
        break;
    }
  };
  editdeptInput = (e) => {
    e.preventDefault();
    var { formError } = this.state
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    if (["deptStatus", "departmentName"].includes(e.target.name)) {
      if (e.target.name === "deptStatus") {
        let department1 = [...this.state.department];
        department1[e.target.dataset.did][e.target.name] = !department1[
          e.target.dataset.did
        ][e.target.name];
        this.setState({ department1 });
      } else {
        let department1 = [...this.state.department];
        department1[e.target.dataset.did]["name"] = e.target.value;
        this.setState({ department1 });
      }
    } else if (["contStatus", "contactName"].includes(e.target.name)) {
      if (e.target.name === "contStatus") {
        let contacts = [...this.state.department[e.target.dataset.did].contact];
        contacts[e.target.dataset.cid][e.target.name] = !contacts[
          e.target.dataset.cid
        ][e.target.name];
        this.setState({ contacts });
      } else {
        let contacts = [...this.state.department[e.target.dataset.did].contact];
        contacts[e.target.dataset.cid]["name"] = e.target.value;
        this.setState({ contacts });
      }
    } else if (["phoneStatus", "phonenum"].includes(e.target.name)) {
      if (e.target.name === "phoneStatus") {
        let phone = [
          ...this.state.department[e.target.dataset.did].contact[
            e.target.dataset.cid
          ].phone,
        ];
        phone[e.target.dataset.pid][e.target.name] = !phone[
          e.target.dataset.pid
        ][e.target.name];
        phone[e.target.dataset.pid]["phoneErr"] = "";
        this.setState({ phone });
      } else {
        let phone = [
          ...this.state.department[e.target.dataset.did].contact[
            e.target.dataset.cid
          ].phone,
        ];
        if (
          !phoneRegx.test(e.target.value.trim()) &&
          e.target.value.trim().length > 0
        ) {
          phone[e.target.dataset.pid]["phoneErr"] = "Please enter a vaild phone number";
        } else if(e.target.value.trim().length === 0){
          phone[e.target.dataset.pid]["phoneErr"] = "Enter a phone number";
        }else {
          phone[e.target.dataset.pid]["phoneErr"] = "";
          phone[e.target.dataset.pid]["phoneNo"] = e.target.value;
        }
        
        this.setState({ phone });
      }
    } else if (["emailStatus", "emailId"].includes(e.target.name)) {
      if (e.target.name === "emailStatus") {
        let email = [
          ...this.state.department[e.target.dataset.did].contact[
            e.target.dataset.cid
          ].email,
        ];
        email[e.target.dataset.eid][e.target.name] = !email[
          e.target.dataset.eid
        ][e.target.name];
        email[e.target.dataset.eid]["emailErr"] = "";
        this.setState({ email });
      } else {
        let email = [
          ...this.state.department[e.target.dataset.did].contact[
            e.target.dataset.cid
          ].email,
        ];
        if (
          !emailRegx.test(e.target.value) &&
          e.target.value.trim().length > 0
        ) {
          email[e.target.dataset.eid]["emailErr"] = "Enter a valid email";
        } else if(e.target.value.trim().length === 0){
          email[e.target.dataset.eid]["emailErr"] = "Enter a email id";
        }else {
          email[e.target.dataset.eid]["emailErr"] = "";
          email[e.target.dataset.eid]["emailid"] = e.target.value;
        }
        this.setState({ email });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value })

      switch (e.target.name) {
        case "address":
          validator.isEmpty(e.target.value)
            ? (formError.address = "Please enter address")
            : (formError.address = "");
          break;
        case "accountName":
            validator.isEmpty(e.target.value.trim())
              ? (formError.accountName = "Please enter an Account Name")
              : (formError.accountName = "");
            break;
        case "state":
          validator.isEmpty(e.target.value)
            ? (formError.state = "Please enter state")
            : (formError.state = "");
          break;
        case "new_website":
          var expressions = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
          validator.isEmpty(e.target.value)
            ? (formError.website = "Please enter website")
            : !expressions.test(e.target.value)
            ?(formError.website = "Enter valid website url")
            :(formError.website = "");
          break;
        default:
          break;
      }
      this.setState({
        formError,
      });

    }
  };
  updateHandler = (name, value, stateVal) => {
    var{formError}=this.state;
    let data = {};
    if(stateVal === 'isAddress'){
      data = {
        accountId: this.state.accountId,
        edit_type: "edit_field",
        editedField: name,
        editedValue: value,
        coordinates:this.state.eventLocation,
      };
    }else{
      data = {
        accountId: this.state.accountId,
        edit_type: "edit_field",
        editedField: name,
        editedValue: value,
      };
    }
    
if(formError.address===""
&&formError.state===""
&&formError.website===""){
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .put("/account", data)
      .then((res) => {
        if (res.data.status === "204" || res.data.status === "200" || res.data.status === 200) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false
            },
            () => {
              var props = {
                message: "Updated successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.editInput(stateVal);
          this.componentDidMount();
        }
         else {
          var props = {
            message: "Failed to update. Try again later",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.componentDidMount();
        }
        
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    }
  };
  updatedeptHandler = (
    field,
    deptId,
    value,
    dindex,
    contId,
    cindex,
    phoneId,
    pindex,
    emailId,
    eindex
  ) => {
    let data = "";
    if (field === "department") {
      data = {
        accountId: this.state.accountId,
        edit_type: "edit_field",
        departmentId: deptId,
        departmentName: value,
        editedField: "departmentName",
        editedValue: value,
      };
    } else if (field === "contact") {
      data = {
        accountId: this.state.accountId,
        edit_type: "edit_field",
        departmentId: deptId,
        contactId: contId,
        editedField: "name",
        editedValue: value,
      };
    } else if (field === "phone") {
      data = {
        accountId: this.state.accountId,
        edit_type: "edit_field",
        departmentId: deptId,
        contactId: contId,
        editedField: "phone",
        phoneId: phoneId,
        phoneNo: value,
        editedValue: value,
        phoneType: "work",
      };
    } else {
      data = {
        accountId: this.state.accountId,
        edit_type: "edit_field",
        departmentId: deptId,
        contactId: contId,
        editedField: "email",
        emailId: emailId,
        email: value,
        editedValue: value,
        emailType: "work",
      };
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .put("/account", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "204" || response.status === 200)
        ) {
          var props = {
            message:
              field.charAt(0).toUpperCase() +
              field.slice(1) +
              " updated successfully.",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 4,
            top: 200,
            className: "task_notification success-notify level-error",
          };
          NotificationComponent(props);
          if (field === "department") {
            let department1 = [...this.state.department];
            department1[dindex]["deptStatus"] = !department1[dindex][
              "deptStatus"
            ];
            this.setState({ department1 });
          } else if (field === "contact") {
            let contacts = [...this.state.department[dindex].contact];
            contacts[cindex]["contStatus"] = !contacts[cindex]["contStatus"];
            this.setState({ contacts });
          } else if (field === "phone") {
            let phone = [
              ...this.state.department[dindex].contact[cindex].phone,
            ];
            phone[pindex]["phoneStatus"] = !phone[pindex]["phoneStatus"];
            this.setState({ phone });
          } else {
            let email = [
              ...this.state.department[dindex].contact[cindex].email,
            ];
            email[eindex]["emailStatus"] = !email[eindex]["emailStatus"];
            this.setState({ email });
          }
        }else{
          var props = {
            message:" Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 4,
            top: 200,
            className: "task_notification error-notify level-error",
          };
          NotificationComponent(props);
          
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  addRowHandler = (e) => {
    if (i === 0) {
      this.setState({ showDept: true });
      i = i + 1;
    } else {
      this.setState({ showDept: true });
      this.setState(
        (prevState) => ({
          departments: [
            ...prevState.departments,
            {
              index: Math.random(),
              name: "",
              dError:'',
              dbutton:true,
              cbutton:false,
              pbutton:false,
              ebutton:false,
              contacts: [
                {
                  index: Math.random(),
                  name: "",
                  cError:'',
                  phone: [
                    { index: Math.random(), phoneNo: "", phoneType: "work",phoneErr: "", },
                  ],
                  email: [
                    { index: Math.random(), emailId: "", emailType: "work",emailErr: "", },
                  ],
                  isPrimary: false,
                },
              ],
            },
          ],
        }),
        () => {
          i = i + 1;
        }
      );
    }
  };

  deleteRowHandler = (index) => {
    const taskList1 = [...this.state.departments];
    taskList1.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  addContHandler = (id) => {
    const elementsIndex = this.state.departments.findIndex(
      (element) => element.index === id
    );
    let newArray = [...this.state.departments];
    let newId = newArray[elementsIndex].contacts.length;
    newArray[elementsIndex].contacts = Object.values({
      ...newArray[elementsIndex].contacts,
      [newId]: {
        index: Math.random(),
        name: "",
        cError:'',
        cbutton:true,
        pbutton:false,
        ebutton:false,
        phone: [
          {
            index: Math.random(),
            phoneNo: "",
            phoneType: "work",
            phoneErr: "",
          },
        ],
        email: [
          {
            index: Math.random(),
            emailid: "",
            emailType: "work",
            emailErr: "",
          },
        ],
        isPrimary: false,
      },
    });
    this.setState({ departments: newArray });
  };
  deleteContactRowHandler = (depId, index) => {
    const elementsIndex = this.state.departments.findIndex(
      (element) => element.index === depId
    );
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  addPhoneHandler = (did, cid) => {
    const deptIndex = this.state.departments.findIndex(
      (element) => element.index === did
    );
    let newArray = [...this.state.departments];
    let newId = newArray[deptIndex].contacts[cid].phone.length;
    newArray[deptIndex].contacts[cid].phone = Object.values({
      ...newArray[deptIndex].contacts[cid].phone,
      [newId]: {
        index: Math.random(),
        phoneNo: "",
        phoneType: "work",
        phoneErr: "",
      },
    });
    this.setState({ departments: newArray });
  };
  deletePhoneRowHandler = (depId, contId, index) => {
    const elementsIndex = this.state.departments.findIndex(
      (element) => element.index === depId
    );
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts[contId].phone.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  addEmailHandler = (did, cid) => {
    const deptIndex = this.state.departments.findIndex(
      (element) => element.index === did
    );
    let newArray = [...this.state.departments];
    let newId = newArray[deptIndex].contacts[cid].email.length;
    newArray[deptIndex].contacts[cid].email = Object.values({
      ...newArray[deptIndex].contacts[cid].email,
      [newId]: {
        index: Math.random(),
        emailid: "",
        emailType: "work",
        emailErr: "",
      },
    });
    this.setState({ departments: newArray });
  };
  deleteEmailRowHandler = (depId, contId, index) => {
    const elementsIndex = this.state.departments.findIndex(
      (element) => element.index === depId
    );
    const taskList1 = [...this.state.departments];
    taskList1[elementsIndex].contacts[contId].email.splice(index, 1);
    this.setState({ departments: taskList1 });
  };
  onChangeHandler = (e) => {
    e.preventDefault();
    let departments = [...this.state.departments];
    if (["deptName"].includes(e.target.name)) {
  
      if (!validator.isLength(e.target.value, { max: 100 })) {
        departments[e.target.dataset.id]["dError"] =
          "* Name should not exceed 100 characters.";
          departments[e.target.dataset.id]["dbutton"] = true;
      } else {
        departments[e.target.dataset.id]["dError"] = "";
        departments[e.target.dataset.id]["dbutton"] = false;
      }
      if(e.target.value.trim().length === 0){
        departments[e.target.dataset.id]["dError"] =
          "* Enter department name";
        departments[e.target.dataset.id]["dbutton"] = true;
      }else{
        departments[e.target.dataset.id]["dbutton"] = false;
        departments[e.target.dataset.id]["name"] = e.target.value;
      }
      
      this.setState({departments})
    } else if (["cname"].includes(e.target.name)) {
      let contacts = [...this.state.departments[e.target.dataset.did].contacts];
      if (!validator.isLength(e.target.value, { max: 100 })) {
        contacts[e.target.dataset.cid]["cError"] =
          "* Name should not exceed 100 characters.";
          departments[e.target.dataset.did]["cbutton"] = true;

      } else {
        contacts[e.target.dataset.cid]["cError"] = "";
        departments[e.target.dataset.did]["cbutton"] = false;
      }
      contacts[e.target.dataset.cid]["name"] = e.target.value;
    } else if (["phoneNo", "phoneType"].includes(e.target.name)) {
      let phone = [
        ...this.state.departments[e.target.dataset.did].contacts[
          e.target.dataset.cid
        ].phone,
      ];
      if (e.target.name === "phoneNo") {
        if (
          !phoneRegx.test(e.target.value) &&
          e.target.value.trim().length > 0
        ) {
          phone[e.target.dataset.pid]["phoneErr"] = "* Please enter a vaild phone number";
          departments[e.target.dataset.did]["pbutton"] = true;
        } else {
          phone[e.target.dataset.pid]["phoneErr"] = "";
          departments[e.target.dataset.did]["pbutton"] = false;
        }
      }

      phone[e.target.dataset.pid][e.target.name] = e.target.value;
      this.setState({ phone });
    } else if (["emailId", "emailType"].includes(e.target.name)) {
      let email = [
        ...this.state.departments[e.target.dataset.did].contacts[
          e.target.dataset.cid
        ].email,
      ];
      if (e.target.name === "emailId") {
        if (
          !emailRegx.test(e.target.value) &&
          e.target.value.trim().length > 0
        ) {
          email[e.target.dataset.eid]["emailErr"] = "* Pleas enter an email address";
          departments[e.target.dataset.did]["ebutton"] = true;
        } else {
          email[e.target.dataset.eid]["emailErr"] = "";
          departments[e.target.dataset.did]["ebutton"] = false;
        }
      }
      email[e.target.dataset.eid][e.target.name] = e.target.value;
      this.setState({ email });
    } else if(["isPrimary"].includes(e.target.name)){
      let contacts = [...this.state.departments[e.target.dataset.did].contacts];
    contacts[e.target.dataset.cid]["isPrimary"] = !contacts[e.target.dataset.cid]["isPrimary"];
    // this.setState({contacts});
  } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onDeptSubmit = (e) => {
    e.preventDefault();

    let data = {
      edit_type: "add_department",
      accountId: this.state.accountId,
      departments: this.state.departments[0],
    };

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .put("/account", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "201" || response.status === 200)
        ) {
          this.setState(
            {
              showDept: false,
            },
            () => {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification success-notify",
              };
              NotificationComponent(props);
              this.componentDidMount();
            }
          );
        }else{
          var props = {
            message:response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  addContactRow = (id) => {
    if (j === 0) {
      this.setState({ showCont: true, editedDept: id });
      j = j + 1;
    } else {
      this.setState(
        (prevState) => ({
          contacts: [
            ...prevState.contacts,
            {
              index: Math.random(),
              name: "",
              cError:'',
              cbutton:true,
              pbutton:false,
              ebutton:false,
              phone: [
                {
                  index: Math.random(),
                  phoneNo: "",
                  phoneType: "work",
                  phoneErr: "",
                },
              ],
              email: [
                {
                  index: Math.random(),
                  emailid: "",
                  emailType: "work",
                  emailErr: "",
                },
              ],
              isPrimary: false,
            },
          ],
          editedDept: id,
        }),
        () => {
          j = j + 1;
        }
      );
    }
  };
  contactDeleteHandler = (index) => {
    const taskList1 = [...this.state.contacts];
    taskList1.splice(index, 1);
    this.setState({ contacts: taskList1 });
  };

  contaddPhoneHandler = (cid) => {
    this.state.contacts.findIndex(
      (element) => element.index === cid
    );
    let newArray = [...this.state.contacts];
    let newId = newArray[cid].phone.length;
    newArray[cid].phone = Object.values({
      ...newArray[cid].phone,
      [newId]: {
        index: Math.random(),
        phoneNo: "",
        phoneType: "work",
        phoneErr: "",
      },
    });
    this.setState({ contacts: newArray });
  };
  contdeletePhoneRowHandler = (contId, index) => {
    this.state.contacts.findIndex(
      (element) => element.index === contId
    );
    const taskList1 = [...this.state.contacts];
    taskList1[contId].phone.splice(index, 1);
    this.setState({ contacts: taskList1 });
  };
  contaddEmailHandler = (cid) => {
     this.state.contacts.findIndex(
      (element) => element.index === cid
    );
    let newArray = [...this.state.contacts];
    let newId = newArray[cid].email.length;
    newArray[cid].email = Object.values({
      ...newArray[cid].email,
      [newId]: {
        index: Math.random(),
        emailid: "",
        emailType: "work",
        emailErr: "",
      },
    });
    this.setState({ contacts: newArray });
  };
  contdeleteEmailRowHandler = (contId, index) => {
     this.state.contacts.findIndex(
      (element) => element.index === contId
    );
    const taskList1 = [...this.state.contacts];
    taskList1[contId].email.splice(index, 1);
    this.setState({ contacts: taskList1 });
  };
  oncontChangeHandler = (e) => {
    e.preventDefault();
    let contacts = [...this.state.contacts];
    if (["cname"].includes(e.target.name)) {    
      if(e.target.value.trim().length === 0){
        contacts[e.target.dataset.cid]["cError"] =
          "* Please enter a contact name";
        contacts[e.target.dataset.cid]["cbutton"] = true;
      }else if (!validator.isLength(e.target.value, { max: 100 })) {
        contacts[e.target.dataset.cid]["cError"] =
          "* Name should not exceed 100 characters.";
        contacts[e.target.dataset.cid]["cbutton"] = true;
      } else {
        contacts[e.target.dataset.cid]["cError"] = "";
        contacts[e.target.dataset.cid]["cbutton"] = false;
      }
      contacts[e.target.dataset.cid]["name"] = e.target.value;
      this.setState({contacts});
    } else if (["phoneNo", "phoneType"].includes(e.target.name)) {
      let phone = [...this.state.contacts[e.target.dataset.cid].phone];
      if (e.target.name === "phoneNo") {
        if (e.target.value.trim().length === 0) {
          phone[e.target.dataset.pid]["phoneErr"] = "* Enter phone number";
          contacts[e.target.dataset.cid]["pbutton"] = true;
        }else if (
          !phoneRegx.test(e.target.value) &&
          e.target.value.trim().length > 0
        ) {
          phone[e.target.dataset.pid]["phoneErr"] = "* Please enter a vaild phone number";
          contacts[e.target.dataset.cid]["pbutton"] = true;
        } else {
          phone[e.target.dataset.pid]["phoneErr"] = "";
          contacts[e.target.dataset.cid]["pbutton"] = false;
        }
      }
      phone[e.target.dataset.pid][e.target.name] = e.target.value;
      this.setState({ phone });
    } else if (["emailId", "emailType"].includes(e.target.name)) {
      let email = [...this.state.contacts[e.target.dataset.cid].email];
      if (e.target.name === "emailId") {
        if ( e.target.value.trim().length === 0 ) {
          email[e.target.dataset.eid]["emailErr"] = "* Please enter an email address";
          contacts[e.target.dataset.cid]["ebutton"] = true;
        }else if (
          !emailRegx.test(e.target.value) &&
          e.target.value.trim().length > 0
        ) {
          email[e.target.dataset.eid]["emailErr"] = "* Pleas enter an email address";
          contacts[e.target.dataset.cid]["ebutton"] = true;
        } else {
          email[e.target.dataset.eid]["emailErr"] = "";
          contacts[e.target.dataset.cid]["ebutton"] = false;
        }
      }
      email[e.target.dataset.eid][e.target.name] = e.target.value;
      this.setState({ email });
    }else if(["isPrimary"].includes(e.target.name)){
      contacts[e.target.dataset.cid]["isPrimary"] = !contacts[e.target.dataset.cid]["isPrimary"];
      this.setState({contacts});
    } 
    else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onContSubmit = (deptId) => {    
    let data = {
      edit_type: "add_contact",
      departmentId: deptId,
      contact: this.state.contacts[0],
    };
    let newArray = {...this.state.contacts[0]};
    let phone={...newArray.phone[0]};
    let email={...newArray.email[0]};

    if(newArray.name === ""){
      newArray.cError="* Please enter a contact name";
    }else{
      newArray.cError="";
    }
    if(phone.phoneNo === ""){
      newArray.phone[0].phoneErr="* Please enter a phone number";
    }else{
      newArray.phone[0].phoneErr="";
    }

    if(email.emailId === ""){
      newArray.email[0].emailErr="* Please enter an email address";
    }else{
      newArray.email[0].emailErr="";
    }
   let arraysData=[];
   arraysData.push(newArray)
    this.setState({
      contacts:arraysData
    },()=>{
      let newArray1 = { ...this.state.contacts[0]}
      let phone1={...newArray1.phone[0]};
      let email1={...newArray1.email[0]};
      if(newArray1.cError === "" && phone1.phoneErr === "" && email1.emailErr === ""){

    
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .put("/account", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "201" || response.status === 200)
        ) {
          j=0;
          this.setState(
            {
              showCont: false,
              
      contacts: [
        {
          index: Math.random(),
          name: "",
          cError:'',
          cbutton:true,
          pbutton:false,
          ebutton:false,
          phone: [
            {
              index: Math.random(),
              phoneNo: "",
              phoneType: "work",
              phoneErr: "",
            },
          ],
          email: [
            {
              index: Math.random(),
              emailId: "",
              emailType: "work",
              emailErr: "",
            },
          ],
          isPrimary: false,
        },
      ],
            },
            () => {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification success-notify",
              };
              NotificationComponent(props);
              this.componentDidMount();
            }
          );
        }else{
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    }
  });
  };
  onPrimarySelect=(departmentId,contactId,isPrimary)=>{
    let data = {
      accountId: this.state.accountId,
      departmentId: departmentId,
      contactId: contactId,
      isPrimary: !isPrimary
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/account/contact/primary", data)
      .then((resp) => {
        let response = resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          this.componentDidMount();
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  }
  openParent = (event) => {
    //this.setState({ anchorEl : true, anchorElData : event.currentTarget});
    this.setState({ autocompleteVisible: true });
  };

  autocompleteClose = (event) => {
    var name = event.target.value;
    if (typeof name === "undefined") {
      this.setState({ autocompleteVisible: false,
        // parentId: "",
        // parentName: "",
        // selectedParent: ""
      });
    }
  };
  onFocusComplete=(e)=>{
   const event={
    target:{
      value:""
    }
   }
   this.parentListHandler(event);
  }

  parentListHandler = async (event) => {
    var name = event.target.value;
    var data = {
      searchText: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/parentAccount", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            parentOption: response.data,
          });
        } else {
          this.setState({
            parentOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    var { parentOption } = this.state;
    var data = await parentOption.filter((item) => {
      return item.name === name;
    });
    data.map((parentData) => {
      this.setState({
        parentId: parentData.id,
        parentName: parentData.name,
      });
    });
  };
  onSelectHandler = (e) => {
    var name = e.target.value;
    var { parentOption } = this.state;
    var data = parentOption.filter((item) => {
      return item.name === name;
    });
    data.map((parentData) => {
      this.setState({
        parentId: parentData.id,
        parentName: parentData.name,
        selectedParent: parentData.name,
      });
    });
  };
  onSelectParentHandler = (e) => {
    var name = e.target.value;
    var { parentOption } = this.state;
    var data = parentOption.filter((item) => {
      return item.name === name;
    });
    data.map((parentData) => {
      this.setState({
        parentId: parentData.id,
        parentName: parentData.name,
        selectedParent: parentData.name,
      },()=>{
        this.updateParentAccount({
          accountId:this.state.accountId,
          parentId:this.state.parentId
        });
      });
    });
  };

  updateParentAccount=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/changeParent", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(props);
          this.fetchAccountDetail();
          this.setState({
            showChangeParent:false
          })

        }else  if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  
  onGooglePlaceSelect = (place) => {
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    this.setState({
      address: formattedPlace.address,
      eventLocation: [formattedPlace.lng, formattedPlace.lat],
      disableAdress: false,
      lat: formattedPlace.lat,
      lng: formattedPlace.lng
    });
  };
  handleAddressChange = (e) => {
    this.setState({address: e.target.value,disableAdress:false });
    let {formError} = this.state;
    if(e.target.value.length === 0) {
      formError.address= "*Please enter address.";
      this.setState({ formError });
    } else if (e.target.value) {
      formError.address= "*Please enter address.";
      this.setState({ formError });
      return false;
    }
  }
  onChangeParentHandler=(e)=>{
    e.preventDefault();
    this.setState({
      showChangeParent:true
    })
  }
  handleGoBack = () =>{
    this.props.history.goBack();
  }
  render() {
    const { departments, contacts, userRole, editedDept, formError } = this.state;
    let classadmin = "";
    if (userRole === "super admin") {
      classadmin = "show-edit";
    } else {
      classadmin = "hide-edit";
    }
    let AccountDetailsPage = (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
          <HeaderComponent />
        {/* </Suspense> */}

        <Box component="div" className="company-comp account-detail">
          <div className="select_parent">
            <div className="breadCrumbs">
              <img className="bread-crumb-back" src={backIcon} alt="back" />
              <Breadcrumbs className="bread-crumb-main" aria-label="breadcrumb">
                <a
                  className="bread-crumb-link"
                  color="inherit"
                  onClick={this.handleGoBack}
                >
                  {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"}
                </a>
                <Typography className="bread-crumb-name">
                {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"} Details
                </Typography>
              </Breadcrumbs>
            </div>
            <div className="account-detail-header account-detail-title" style={{display: "flex"}}>
            {this.state.isAccountname && (
              <Typography variant="h5" className="tableTitle" component="div" style={{marginRight:'10px'}}>
                {this.state.accountName}
              </Typography>
            )}
            {!this.state.isAccountname && (   
                            <span
                              className="edit-input"
                              style={{ alignItems: "center" }}
                            >
                              <CssTextField
                                id="outlined-basic"
                                name="accountName"
                                variant="outlined"
                                type="text"
                                placeholder="Enter name"
                                defaultValue={this.state.accountName}
                                onChange={this.editdeptInput}
                              />
                             
                              <Button
                                className="add_more_btn"
                                disabled={this.state.accountName.trim() === '' ? true : false}
                                onClick={() =>
                                  this.updateHandler(
                                    "accountName",
                                    this.state.accountName,
                                    "isAccountname"
                                  )
                                }
                              >
                                Save
                              </Button>
                              <img
                                alt="close"
                                src={close_icon_red}
                                style={{ cursor: "pointer" }}
                                onClick={() => this.editInput("isAccountname")}
                              />
                               
                            </span>
                           
                          )}{" "}
                            <span className="error">{this.state.formError.accountName}</span>
                          {this.state.isAccountname && (
                          <img
                            alt="edit"
                            className={classadmin}
                            src={editIcon}
                            style={{ cursor: "pointer",marginBottom: "4px"}}
                            onClick={() => this.editInput("isAccountname")}
                          />
                        )}
                       
              {/* <div
                className={
                  this.state.autocompleteVisible === false
                    ? "custom_upload"
                    : "hide"
                }
              >

                <Button className="upload_btn" variant="contained" onClick={this.openParent}>
                  Select parent
              </Button>
              </div> */}

              <div
                className={
                  this.state.autocompleteVisible === false ? "hide" : ""
                }
              >
                <label>Parent Account Name</label>
                <Autocomplete
                  style={{ width: 300 }}
                  id="parentName"
                  freeSolo
                  className="parent-autocomplete"
                  options={this.state.parentOption}
                  getOptionLabel={(option) => option.name}
                  onInputChange={this.autocompleteClose}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="parentName"
                      placeholder="Search here"
                      onChange={this.parentListHandler}
                      onSelect={this.onSelectHandler}
                    />
                  )}
                />
              </div>
            </div>
            {/* {this.state.userRole.toLowerCase() === "super admin"?
            <ChangeParentComponent
              hasParent={this.state.hasParent}
              parentAccount={this.state.parentAccount}
              parentOption={this.state.parentOption}
              parentListHandler={this.parentListHandler}
              onFocusComplete={this.onFocusComplete}
              onSelectHandler={this.onSelectParentHandler}
              autocompleteClose={this.autocompleteClose}
              onChangeParentHandler={this.onChangeParentHandler}
              editInput={this.editInputParent}
              deleteParent={this.deleteParent}
              editInputClose={this.editInputParentClose}
              showChangeParent={this.state.showChangeParent}
            />:""} */}
          </div>
          <div className="account-detail-container">
            <Grid spacing={2} container>
              <Grid item xs={12} md={4}>
                <ExpansionPanel
                  className="account-expansion"
                  defaultExpanded={true}
                >
                  <ExpansionPanelSummary
                    expandIcon={<img src={ExpandMoreIcon} alt="expand" />}
                    style={{ borderBottom: "1px solid #0000000F" }}
                  >
                    <Typography
                      className="account-expansion-text"
                      variant="h6"
                      component="span"
                    >
                      {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Account"} Information
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    
                    <div className="info-card">
                    {this.state.userRole.toLowerCase() === "super admin"?
                    <> <p className="info-card-sub-name">Parent Account :</p>
            <ChangeParentComponent
              hasParent={this.state.hasParent}
              parentAccount={this.state.parentAccount}
              parentOption={this.state.parentOption}
              parentListHandler={this.parentListHandler}
              onFocusComplete={this.onFocusComplete}
              onSelectHandler={this.onSelectParentHandler}
              autocompleteClose={this.autocompleteClose}
              onChangeParentHandler={this.onChangeParentHandler}
              editInput={this.editInputParent}
              deleteParent={this.deleteParent}
              editInputClose={this.editInputParentClose}
              showChangeParent={this.state.showChangeParent}
            />
            </>
            :""}
                      <p className="info-card-sub-name">Address :</p>
                      <div className="info-card-detail">
                        <div className="info-card-detail-left">
                          <img className="info-card-img-align-start" src={iconLocation} alt="loc"/>
                          {this.state.isAddress && (
                            <span className="custom-span">
                              {this.state.address}
                            </span>
                          )}
                          {!this.state.isAddress && (   
                            <span
                              className="edit-input"
                              style={{ alignItems: "center" }}
                            >
                              <Suspense fallback={<div></div>}>
                              <AutoComplete
                                style={{
                                  width: "100%",
                                }}
                                name="address"
                                autoFocus
                                autocomplete="off"
                                apiKey={GoogleMapsAPI}
                                types={[]}
                                defaultValue={this.state.address }
                                onPlaceSelected={this.onGooglePlaceSelect}
                                onChange={this.editdeptInput}

                              />
                            </Suspense>
                              {/* <CssTextField
                                id="outlined-basic"
                                name="address"
                                variant="outlined"
                                type="text"
                                placeholder="Enter address"
                                defaultValue={this.state.address}
                                onChange={this.editdeptInput}
                              /> */}
                              <Button
                                className="add_more_btn"
                                disabled={this.state.address === '' ? true : false}
                                onClick={() =>
                                  this.updateHandler(
                                    "address.formatedAddress",
                                    this.state.address,
                                    "isAddress"
                                  )
                                }
                              >
                                Save
                              </Button>
                              <img
                                alt="close"
                                src={close_icon_red}
                                style={{ cursor: "pointer" }}
                                onClick={() => this.editInput("isAddress")}
                              />
                            </span>
                          )}{" "}
                        </div>
                        {this.state.isAddress && (
                          <img
                            alt="edit"
                            className={classadmin}
                            src={editIcon}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isAddress")}
                          />
                        )}
                      </div>
                      {formError.address !== "" ? (
                        <span className="error">*{formError.address}</span>
                      ) : null}

                      <p className="info-card-sub-name">State :</p>
                      <div className="info-card-detail">
                        <div className="info-card-detail-left">
                          <img src={iconCity} alt="icon"/>
                          {this.state.isState && (
                            <span className="custom-span">
                              {this.state.state}
                            </span>
                          )}
                          {!this.state.isState && (
                            <span
                              className="edit-input"
                              style={{ alignItems: "center" }}
                            >
                              <CssTextField
                                id="outlined-basic"
                                name="state"
                                variant="outlined"
                                type="text"
                                placeholder="Enter state"
                                defaultValue={this.state.state}
                                onChange={this.editdeptInput}
                              />
                              <Button
                                className="add_more_btn"
                                disabled={this.state.state === '' ? true : false}
                                onClick={() =>
                                  this.updateHandler(
                                    "address.state",
                                    this.state.state,
                                    "isState"
                                  )
                                }
                              >
                                Save
                              </Button>
                              <img
                                alt="close"
                                src={close_icon_red}
                                style={{ cursor: "pointer" }}
                                onClick={() => this.editInput("isState")}
                              />
                            </span>
                          )}{" "}
                        </div>
                        {/* {this.state.isState && (
                          <img
                          alt="edit"
                            className={classadmin}
                            src={editIcon}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isState")}
                          />
                        )} */}
                      </div>
                      {formError.state !== "" ? (
                        <span className="error">*{formError.state}</span>
                      ) : null}
                      <p className="info-card-sub-name">Website :</p>
                      <div className="info-card-detail info-card-detail-last info-card-url">
                        <div className="info-card-detail-left">
                          <img src={iconAsset} className="info-card-img-align-start info-card-img-website" alt="icon"/>
                          {this.state.isWebsite && (
                            <span className="custom-span">
                              {/* {this.state.website} */}
                              {this.state.website ?
                              <a
                                    href={lowerCase(this.state.website.substring(0, 3)) === 'www' ? '//' + this.state.website : this.state.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {this.state.website
                                      ? this.state.website
                                        .replace(
                                          /^(?:https?:\/\/)?(?:www\.)?/i,
                                          ""
                                        )
                                        .split("/")[0]
                                      : ""}
                                  </a>
                                   : "--"}
                            </span>
                          )}
                          {!this.state.isWebsite && (
                            <span
                              className="edit-input"
                              style={{ alignItems: "center" }}
                            >
                              <CssTextField
                                id="outlined-basic"
                                name="new_website"
                                variant="outlined"
                                type="text"
                                placeholder="Enter website"
                                defaultValue={this.state.website}
                                onChange={this.editdeptInput}
                              />
                              <Button
                                className="add_more_btn"
                                disabled={this.state.new_website === '' ? true : false}
                                onClick={() =>
                                  this.updateHandler(
                                    "website",
                                    this.state.new_website,
                                    "isWebsite"
                                  )
                                }
                              >
                                Save
                              </Button>
                              <img
                              alt="close"
                                src={close_icon_red}
                                style={{ cursor: "pointer" }}
                                onClick={() => this.editInput("isWebsite")}
                              />
                            </span>
                          )}{" "}
                        </div>
                        {this.state.isWebsite && (
                          <img
                          alt="close"
                            className={classadmin}
                            src={editIcon}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editInput("isWebsite")}
                          />
                        )}
                      </div>
                      {formError.website !== "" ? (
                        <span className="error">*{formError.website}</span>
                      ) : null}

                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {userRole.toLowerCase() !== "super admin" ? (
                  <ExpansionPanel className="account-expansion">
                    <ExpansionPanelSummary
                      expandIcon={<img src={ExpandMoreIcon} alt="expand"/>}
                      style={{ borderBottom: "1px solid #0000000F" }}
                    >
                      <Typography
                        className="account-expansion-text"
                        variant="h6"
                        component="span"
                      >
                        {this.state.cmsLabelChange.Description?this.state.cmsLabelChange.Description:"Description"}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="info-card">
                        <div className="info-card-detail info-card-detail-last">
                          <div className="info-card-detail-left">
                            {this.state.isDesc && (
                              <span className="account-des-span custom-span">
                                {this.state.description}
                              </span>
                            )}
                            {!this.state.isDesc && (
                              <span
                                className="edit-input"
                                style={{ alignItems: "center" }}
                              >
                                <CssTextField
                                  id="outlined-basic"
                                  name="description"
                                  variant="outlined"
                                  type="text"
                                  placeholder="Enter state"
                                  defaultValue={this.state.description}
                                  onChange={this.editdeptInput}
                                />
                                <Button
                                  className="add_more_btn"
                                  disabled={this.state.description === '' ? true : false}
                                  onClick={() =>
                                    this.updateHandler(
                                      "description",
                                      this.state.description,
                                      "isDesc"
                                    )
                                  }
                                >
                                  Save
                                </Button>
                                <img
                                alt="close"
                                  src={close_icon_red}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.editInput("isDesc")}
                                />
                              </span>
                            )}{" "}
                          </div>
                          {this.state.isDesc && (
                            <img
                            alt="edit"
                              className={classadmin}
                              src={editIcon}
                              style={{ cursor: "pointer" }}
                              onClick={() => this.editInput("isDesc")}
                            />
                          )}
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : (
                    ""
                  )}
              </Grid>
              {userRole.toLowerCase() !== "super admin" ? (
                <Grid item xs={12} md={8}>
                  <div className="account-detail-container-right-btns">
                    <div>
                      {/* <Button
                      variant="outlined"
                      color="primary"
                      className="account-btns"
                      onClick={this.handleOnScroll}
                    >
                      Notes
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="account-btns"
                    >
                      Timeline
                    </Button> */}
                    </div>
                    <div>{/* Hide all */}</div>
                  </div>
                  {this.state.department.map((data, index) => (
                    <ExpansionPanel
                      className="account-expansion"
                      defaultExpanded={true}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<img src={ExpandMoreIcon} alt="expand"/>}
                        style={{ borderBottom: "1px solid #0000000F" }}
                      >
                        <Typography
                          className="account-expansion-text"
                          variant="h6"
                          component="span"
                        >
                           {this.state.cmsLabelChange.Department?this.state.cmsLabelChange.Department:"Department"}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div className="info-card">
                          <div className="info-card-detail-two">
                            <div className="info-card-detail-right info-card-detail-right-justify mar-bot-12">
                              <div className="info-card-wo-add">
                                <p className="info-card-sub-name">
                                {this.state.cmsLabelChange.Department?this.state.cmsLabelChange.Department:"Department"} Name :
                                </p>
                                <div className="displayFlex">
                                  {this.state.department[index][
                                    "deptStatus"
                                  ] && (
                                      <span className="custom-span">
                                        {data.name}
                                      </span>
                                    )}
                                  {!this.state.department[index][
                                    "deptStatus"
                                  ] && (
                                      <span className="edit-input">
                                        <CssTextField
                                          id="outlined-basic"
                                          inputProps={{
                                            name: "departmentName",
                                            "data-did": index,
                                          }}
                                          variant="outlined"
                                          type="text"
                                          placeholder="Enter name"
                                          defaultValue={data.name}
                                          onChange={this.editdeptInput}
                                        />
                                        <Button
                                          className="add_more_btn"
                                          onClick={() =>
                                            this.updatedeptHandler(
                                              "department",
                                              data._id,
                                              this.state.department[index][
                                              "name"
                                              ],
                                              index,
                                              "",
                                              ""
                                            )
                                          }
                                        >
                                          Save
                                      </Button>
                                      </span>
                                    )}
                                
                                </div>
                              </div>
                           
                            </div>
                          
                            <div className="info-card-detail-right info-card-detail-right-justify info-card-detail-right-last">
                              <div className="info-card-detail-right">
                                <span
                                  className="custom-span"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Contact Person :
                                </span>
                                <div
                                  className="account-detail-add-more"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.addContactRow(data._id)}
                                >
                                  +Add more contacts
                                </div>
                              </div>
                              
                            </div>
                          </div>

                          {data.contact !== undefined
                            ? data.contact.map((contact, cindex) => (
                              <>
                                <div className="info-card-detail-two">
                                  <div className="info-card-detail-right mar-bot-12 info-card-wo-add">
                                    <p className="info-card-sub-name align_start">
                                      Name:
                                      </p>
                                    <div className="nameFlex">
                                      <span className="custom-span">
                                        {this.state.department[index][
                                          "contact"
                                        ][cindex]["contStatus"] && (
                                            <span>{contact.name}</span>
                                          )}
                                        {!this.state.department[index][
                                          "contact"
                                        ][cindex]["contStatus"] && (
                                            <span className="edit-input">
                                              <CssTextField
                                                id="outlined-basic"
                                                inputProps={{
                                                  name: "contactName",
                                                  "data-cid": cindex,
                                                  "data-did": index,
                                                }}
                                                variant="outlined"
                                                type="text"
                                                placeholder="Enter name"
                                                defaultValue={contact.name}
                                                onChange={this.editdeptInput}
                                              />
                                              <Button
                                                className="add_more_btn"
                                                onClick={() =>
                                                  this.updatedeptHandler(
                                                    "contact",
                                                    data._id,
                                                    this.state.department[
                                                    index
                                                    ]["contact"][cindex][
                                                    "name"
                                                    ],
                                                    index,
                                                    contact._id,
                                                    cindex
                                                  )
                                                }
                                              >
                                                Save
                                              </Button>
                                            </span>
                                          )}
                                      </span>

                                     
                                    </div>
                                  </div>

                                  <div className="info-card-detail-right mar-bot-12 info-card-wo-add">
                                    <p className="info-card-sub-name">
                                      Phone Number:
                                      </p>{" "}
                                    {contact.phone.map((phone, pindex) => (
                                      <>
                                        <span className="flexSpan">
                                          {this.state.department[index][
                                            "contact"
                                          ][cindex]["phone"][pindex][
                                            "phoneStatus"
                                          ] && (
                                              <span className="custom-span">
                                                {phone.phoneNo}
                                                {/* - {phone.phoneType.toLowerCase()} */}
                                              </span>
                                            )}
                                          {!this.state.department[index][
                                            "contact"
                                          ][cindex]["phone"][pindex][
                                            "phoneStatus"
                                          ] && (
                                              <span className="edit-input" style={{alignItems:'center'}}>
                                                <CssTextField
                                                  className="sub_select_input"
                                                  inputProps={{
                                                    name: "phonenum",
                                                    "data-pid": pindex,
                                                    "data-did": index,
                                                    "data-cid": cindex,
                                                  }}
                                                  variant="outlined"
                                                  margin="dense"
                                                  type="text"
                                                  fullWidth
                                                  placeholder="Enter Number"
                                                  defaultValue={phone.phoneNo}
                                                  onChange={this.editdeptInput}
                                                // InputProps={{
                                                //   endAdornment: (
                                                //     <InputAdornment position="end">
                                                //       <FormControl variant="outlined">
                                                //         <Select
                                                //           className="sub_select_edit"
                                                //           labelId="demo-simple-select-filled-label"
                                                //           defaultValue={
                                                //             phone.phoneType.toLowerCase()
                                                //           }
                                                //         >
                                                //           <MenuItem value="work">
                                                //             Work
                                                //           </MenuItem>
                                                //           <MenuItem value="home">
                                                //             Home
                                                //           </MenuItem>
                                                //         </Select>
                                                //       </FormControl>
                                                //     </InputAdornment>
                                                //   ),
                                                // }}
                                                />

                                                <Button
                                                  className="add_more_btn"
                                                  onClick={() =>
                                                    this.updatedeptHandler(
                                                      "phone",
                                                      data._id,
                                                      this.state.department[
                                                      index
                                                      ]["contact"][cindex][
                                                      "phone"
                                                      ][pindex]["phoneNo"],
                                                      index,
                                                      contact._id,
                                                      cindex,
                                                      phone._id,
                                                      pindex
                                                    )
                                                  }
                                                  disabled={
                                                    phone.phoneErr !== ""
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  Save
                                                </Button>
                                                <img
                                                  alt="close"
                                                  src={close_icon_red}
                                                  style={{ cursor: "pointer" }}
                                                  name="phoneStatus"
                                            data-did={index}
                                            data-cid={cindex}
                                            data-pid={pindex}
                                            onClick={this.editdeptInput}
                                            alt="edit"
                                                />
                                              </span>
                                            )}
                                            {this.state.department[index][
                                            "contact"
                                          ][cindex]["phone"][pindex][
                                            "phoneStatus"
                                          ] && (
                                            <img
                                            src={editIcon}
                                            style={{ cursor: "pointer" }}
                                            name="phoneStatus"
                                            data-did={index}
                                            data-cid={cindex}
                                            data-pid={pindex}
                                            onClick={this.editdeptInput}
                                            alt="edit"
                                          />
                                            )}
                                          
                                        </span>
                                        <span className="modal-err-msg">
                                          {phone.phoneErr !== ""
                                            ? phone.phoneErr
                                            : ""}
                                        </span>
                                      </>
                                    ))}
                                    {/* <div className="account-detail-add-more">
                                    + Add one more
                                    </div> */}
                                  </div>
                                  <div className="info-card-detail-right info-card-wo-add">
                                    <p className="info-card-sub-name">
                                      Email:
                                      </p>{" "}
                                    {contact.email.map((email, eindex) => (
                                      <>
                                        <span className="flexSpan">
                                          {this.state.department[index][
                                            "contact"
                                          ][cindex]["email"][eindex][
                                            "emailStatus"
                                          ] && (
                                              <span className="custom-span">
                                                {email.emailid}
                                                {/* - {email.emailType} */}
                                              </span>
                                            )}
                                          {!this.state.department[index][
                                            "contact"
                                          ][cindex]["email"][eindex][
                                            "emailStatus"
                                          ] && (
                                              <span className="edit-input" style={{alignItems:'center'}}>
                                                <CssTextField
                                                  className="sub_select_input"
                                                  inputProps={{
                                                    name: "emailId",
                                                    "data-eid": eindex,
                                                    "data-did": index,
                                                    "data-cid": cindex,
                                                  }}
                                                  variant="outlined"
                                                  margin="dense"
                                                  type="text"
                                                  fullWidth
                                                  placeholder="Enter email"
                                                  defaultValue={email.emailid}
                                                  onChange={this.editdeptInput}
                                                // InputProps={{
                                                //   endAdornment: (
                                                //     <InputAdornment position="end">
                                                //       <Select
                                                //         variant="outlined"
                                                //         className="sub_select_edit"
                                                //         name="emailType"
                                                //         placeholder="Select Type"
                                                //         defaultValue={
                                                //           email.emailType
                                                //         }
                                                //       >
                                                //         <MenuItem value="work">
                                                //           Work
                                                //         </MenuItem>
                                                //         <MenuItem value="home">
                                                //           Home
                                                //         </MenuItem>
                                                //       </Select>
                                                //     </InputAdornment>
                                                //   ),
                                                // }}
                                                />

                                                <Button
                                                  className="add_more_btn"
                                                  onClick={() =>
                                                    this.updatedeptHandler(
                                                      "email",
                                                      data._id,
                                                      this.state.department[
                                                      index
                                                      ]["contact"][cindex][
                                                      "email"
                                                      ][eindex]["emailid"],
                                                      index,
                                                      contact._id,
                                                      cindex,
                                                      "",
                                                      "",
                                                      email._id,
                                                      eindex
                                                    )
                                                  }
                                                  disabled={
                                                    email.emailErr !== ""
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  Save
                                                </Button>
                                                <img
                                                  alt="close"
                                                  src={close_icon_red}
                                                  style={{ cursor: "pointer" }}
                                                  name="emailStatus"
                                            data-did={index}
                                            data-cid={cindex}
                                            data-eid={eindex}
                                            onClick={this.editdeptInput}
                                            alt="edit"
                                                />
                                              </span>
                                            )}
                                            {this.state.department[index][
                                            "contact"
                                          ][cindex]["email"][eindex][
                                            "emailStatus"
                                          ] && (
                                            <img
                                            alt="edit"
                                              src={editIcon}
                                              style={{ cursor: "pointer" }}
                                              name="emailStatus"
                                              data-did={index}
                                              data-cid={cindex}
                                              data-eid={eindex}
                                              onClick={this.editdeptInput}
                                            />
                                            )}
                                          
                                        </span>
                                        <span className="modal-err-msg">
                                          {email.emailErr !== ""
                                            ? email.emailErr
                                            : ""}
                                        </span>
                                      </>
                                    ))}{" "}
                                    {/* <div className="account-detail-add-more">
                                    + Add one more
                                    </div> */}
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      className="add_account_check"
                                      control={
                                        <Checkbox
                                          defaultChecked={contact.isPrimary}
                                          name="isPrimary"
                                          color="primary"
                                          onChange={() => this.onPrimarySelect(data._id,contact._id,contact.isPrimary)}
                                        />
                                      }
                                      label="Make as a primary contact"
                                    />
                                  </div>
                                </div>
                                <Divider
                                  style={{ margin: "10px 0 10px 0" }}
                                />
                              </>
                            ))
                            : ""}
                          {this.state.showCont && editedDept === data._id ? (
                            <form
                              onSubmit={this.onContSubmit}
                              onChange={this.oncontChangeHandler}
                            >
                              <Suspense fallback={<div></div>}>
                                <EditContactComponent
                                  deptId={data._id}
                                  deptIndex={data.index}
                                  contacts={contacts}
                                  deleteContactRow={this.contactDeleteHandler}
                                  addPhone={this.contaddPhoneHandler}
                                  deletePhoneRow={
                                    this.contdeletePhoneRowHandler
                                  }
                                  addEmail={this.contaddEmailHandler}
                                  deleteEmailRow={
                                    this.contdeleteEmailRowHandler
                                  }
                                  onChangeHandler={this.oncontChangeHandler}
                                  onSubmitFn={this.onContSubmit}
                                />
                              </Suspense>
                            </form>
                          ) : (
                              ""
                            )}
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                  <form
                    onSubmit={this.onDeptSubmit}
                    onChange={this.onChangeHandler}
                    className={this.state.showDept ? "show-dept" : "hide-dept"}
                  >
                    <Suspense fallback={<div></div>}>
                      <EditDepartmentComponent
                        departments={departments}
                        deleteRow={this.deleteRowHandler.bind(this)}
                        addcontact={this.addContHandler}
                        deleteContactRow={this.deleteContactRowHandler}
                        addPhone={this.addPhoneHandler}
                        deletePhoneRow={this.deletePhoneRowHandler}
                        addEmail={this.addEmailHandler}
                        deleteEmailRow={this.deleteEmailRowHandler}
                        onChangeHandler={this.onChangeHandler}
                        onSubmitFn={this.onDeptSubmit}
                      />
                    </Suspense>
                   
                  </form>
                   
                  {/* <Button
                    className="add_dept_btn"
                    variant="outlined"
                    onClick={this.addRowHandler}
                  >
                    <img src={add_dept_icon} alt="add"/>
                    Add department
                  </Button> */}
                  
                </Grid>
              ) : (
                  <Grid item xs={12} md={8}>
                    <Suspense fallback={<div></div>}>
                      <AccountDetailsSuperadminComponent />
                    </Suspense>
                  </Grid>
                )}
            </Grid>
          </div>
        </Box>
      </div>
    );
    return <div>{AccountDetailsPage}</div>;
  }
}
export default AccountDetailsComponent;
