import React, { Component } from "react";
import "./LevelsComponent.css";
import iconNotificationUpdate from "../../Assets/images/icon-notify-update.svg";
import { withStyles, InputAdornment, FormControl } from "@material-ui/core";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import DeviceHubOutlinedIcon from "@material-ui/icons/DeviceHubOutlined";
import { AxiosInstance } from "../../Utilities";
import Loader from "react-loader-spinner";
import editIcon from "../../Assets/images/view_edit_icon.svg";
import close_icon_red from "../../Assets/img/close_icon_red.svg";

import carret_down from "../../Assets/images/carret_down.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import momentTimeZone from "moment-timezone";
import DateDiff from "date-diff";
import moment from "moment";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import ChangeParentModel from "./ChangeParentModel/ChangeParentModel";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import {
  Box,
  Breadcrumbs,
  Link,
  CardContent,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  TextField,
  Select,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import backIcon from "../../Assets/images/breadcrumb_back.svg";
import ExpandMoreIcon from "../../Assets/images/level_icon.svg";
import { Redirect } from "react-router-dom";
import validator from "validator";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import SubscriptionLevelModal from "./SubscriptionLevelModal/SubscriptionLevelModal";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
const IconLeftAccordionSummary = withStyles({
  expandIcon: {
    order: -1,
  },
})(AccordionSummary);

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);

class LevelsComponent extends Component {
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var zoneName = momentTimeZone.tz.guess();
    this.state = {
      timeZoneName: localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName,
      userRole: userData.role,
      clickedDiv: null,
      companyId: userData.companyId,
      companySize: userData.companySize,
      divisions: [],
      division: [],
      area: [],
      region: [],
      territory: [],
      loading: false,
      devid:"",
      isArea: true,
      isRegion: true,
      isTerritory: true,
      isDivision: true,
      isHospital: true,
      isDivname: true,
      isAreaname: true,
      isRegionname: true,
      isHospname: true,
      isAreaEmpty: true,
      isRegionEmpty: true,
      editId: "",
      tindex: "",
      isTerritoryname: true,
      areaname: "",
      regionname: "",
      territoryname: "",
      divisionname: "",
      anchorEl: "",
      anchorElarea: "",
      open: false,
      openarea: false,
      anchorElregion: "",
      openregion: false,
      accounts: [],
      tags: [],
      openModal: false,
      childType: "",
      divId: "",
      areaId: "",
      regId: "",
      divisionFilter: [],
      areaFilter: [],
      regionFilter: [],
      Ptdivision: "",
      Ptarea: "",
      Ptregion: "",
      editType: "",
      editArea: "",
      editTerritory: "",
      editRegion: "",
      anchorEldiv: null,
      openDiv: false,
      isChangeParentValid: true,
      inChangeParentValid :false,
      changeParentError: {
        division: "",
        area: "",
        region: "",
      },
      subscriptionLevel: {
        billingConfigType: "2",
        billingTrialEnds: [
          momentTimeZone().tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName),
          momentTimeZone().tz(localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName).add(14, "days"),
        ],
        billingTrialDays: 14,
        billingPrice: 29.99,
        level: "",
        levelId: "",
        openModal: false,
      },
      showSubscribe: false,
      subscriptionFormError: {
        billingConfigType: "",
        billingPrice: "",
      },

      isBillingType: true,
      isBillingPeriod: true,
      isBillingPrice: true,

      billingPriceEdit: false,
      billingTypeEdit: false,
      billingPeriodEdit: false,
      editIds: "",

      subscriptionEditPrice: 0,
      subscriptionTypeEdit: "2",
      subscriptionTrialEnds: [],
      subscriptionLevelTrialDays: 14,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSelectactHandler = this.onSelectactHandler.bind(this);
  }
  /*For getting division list & childs of first division*/
  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .get("/getDivisionList")
        .then((resp) => {
          if (resp.data.status === 200) {
            this.setState({ divisions: resp.data.data, loading: false }, () => {
              if (this.state.divisions.length !== 0) {
                this.onDivisionClick(this.state.divisions[0]._id);
                this.getAccountslist(this.state.divisions[0]._id);
              }
              this.getFilters(
                this.state.companyId,
                this.state.userRole,
                "Company"
              );
            });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    });
  };
  /*Dropdown filters for change parent modal*/
  getFilters = (id, role, type) => {
    let data = "";
    if (type === "Company") {
      data = {
        companyId: id,
        roles: role,
      };
    } else if (type === "Division") {
      data = {
        divisionId: id,
        roles: role,
      };
    } else if (type === "Area") {
      data = {
        areaId: id,
        roles: role,
      };
    }

    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/users/filters", data)
      .then((resp) => {
        if (resp.status === 200) {
          let response = resp.data.data;
          if (type === "Company") {
            this.setState({ divisionFilter: response.division });
          } else if (type === "Division") {
            this.setState({ areaFilter: response.area });
          } else if (type === "Area") {
            this.setState({ regionFilter: response.region });
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /*For getting childs of particular division*/
  onDivisionClick = (id) => {
    this.setState({
      clickedDiv: id,
    });
    let data = {
      id: id,
    };
    this.getAccountslist(id);
    this.setState({ loading: true, isDivision: true }, () => {
      this.get_division_list(data);
    });
  };

  get_division_list = (data) => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/getLevelDetail", data)
      .then((resp) => {
        let response = resp.data;
        if (response.status === 200) {
          this.setState({
            loading: false,
            area: response.data.area,
            region: response.data.region,
            territory: response.data.territory,
            division: response.data.division,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /*Get accounts for Autocomplete textbox */
  getAccountslist = async (id, event) => {
    this.setState({devid:id})
    let text = event !== undefined ? event.target.value : "";
    let data = {
      division_id: id,
      searchText: text,
    };

    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/accounts", data)
      .then((resp) => {
        let response = resp.data;
        if (response.status === "200" || response.status === 200) {
          this.setState({
            accounts: response.data,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    var { accounts } = this.state;
    var data1 = await accounts.filter((item) => {
      return item.text === text;
    });
    data1.map((accountData) => {
      this.setState({
        accountsid: accountData._id,
      });
    });
  };
  /*Saving selected accounts*/
  onSelectactHandler = (event, values) => {
    this.setState({ tags: values });
  };
  /*Add levels enabling textboxes*/
  addLevel = (name, id) => {
    switch (name) {
      case "area":
        this.setState({ isArea: !this.state.isArea, editId: id });
        break;
      case "region":
        this.setState({ isRegion: !this.state.isRegion, editId: id });
        break;
      case "territory":
        this.setState({ isTerritory: !this.state.isTerritory, editId: id });
        break;
      case "division":
        this.setState({ isDivision: !this.state.isDivision });
        break;
      case "hospital":
        this.setState({ isHospital: !this.state.isHospital, editId: id });
        break;
      default:
        break;
    }
  };
  /** Edit levels changing strings to textboxes */
  editLevel = (e, name, id, index) => {
    e.stopPropagation();
    this.setState({
      anchorEl: null,
      open: false,
      anchorElarea: null,
      openarea: false,
      anchorElregion: null,
      openregion: false,
    });
    switch (name) {
      case "area":
        this.setState({ isAreaname: !this.state.isAreaname });
        break;
      case "region":
        this.setState({ isRegionname: !this.state.isRegionname });
        break;
      case "territory":
        this.setState({ isTerritoryname: !this.state.isTerritoryname });
        break;
      case "division":
        this.setState({ isDivname: !this.state.isDivname });
        break;
      case "hospital":
        this.setState({
          isHospname: !this.state.isHospname,
          editId: id,
          tindex: index,
        });
        break;
      default:
        break;
    }
  };
  /** Saving textbox values and modal dropdown values */
  onChangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const { changeParentError, subscriptionFormError } = this.state;
    var valid = true;
    switch (name) {
      case "Ptdivision":
        if (value.trim() === "") {
          valid = false;
          changeParentError.division = "Please select division name";
        } else {
          valid = true;
          changeParentError.division = "";
        }
        if (this.state.Ptarea.trim() === "") {
          valid = false;
        }
        break;
      case "Ptarea":
        if (value.trim() === "") {
          valid = false;
          changeParentError.area = "Please select area name";
        } else {
          valid = true;
          changeParentError.area = "";
        }
        if (this.state.Ptregion.trim() === "") {
          valid = false;
        }
        break;
      case "Ptregion":
        if (value.trim() === "") {
          valid = false;
          changeParentError.region = "Please select region name";
        } else {
          valid = true;
          changeParentError.region = "";
        }
        break;
      case "subscriptionEditPrice":
        validator.isEmpty(value)
          ? (subscriptionFormError.billingPrice =
              "Please enter a billing price")
          : !/^(?!0+$)\d{1,6}(\.\d{1,2})?$/.test(value)
          ? (subscriptionFormError.billingPrice =
              "Please enter the valid price. e.g: $2,500")
          : (subscriptionFormError.billingPrice = "");
        break;
      default:
        break;
    }
    this.setState({ [name]: value.trim(), isChangeParentValid: valid,inChangeParentValid :valid }, () => {
      if (name === "Ptdivision") {
        this.getFilters(value, this.state.userRole, "Division");
      } else if (name === "Ptarea") {
        this.getFilters(value, this.state.userRole, "Area");
      }
    });
  };   
  /** Add level API calls */
  addLevelHandler = (type, divId, areaId, regionId) => {
    const {
      areaname,
      regionname,
      territoryname,
      divisionname,
      companyId,
    } = this.state;
    let data = {};
    switch (type) {
      case "area":
        data.division_id = divId;
        data.type = type;
        data.companyId = companyId;
        data.name = areaname;
        break;
      case "region":
        data.division_id = divId;
        data.area_id = areaId;
        data.type = type;
        data.companyId = companyId;
        data.name = regionname;
        break;
      case "territory":
        data.division_id = divId;
        data.area_id = areaId;
        data.region_id = regionId;
        data.type = type;
        data.companyId = companyId;
        data.name = territoryname;
        break;
      default:
        data.type = type;
        data.companyId = companyId;
        data.name = divisionname;
        break;
    }
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/level/add", data)
      .then((resp) => {
        let response = resp.data;
        const newLocal = "200";
        if (response.status === newLocal) {
          if (type === "area") {
            this.setState(
              { isArea: !this.state.isArea, areaname: "" },
              () => {}
            );
            this.onDivisionClick(divId);
          } else if (type === "region") {
            this.setState(
              { isRegion: !this.state.isRegion, regionname: "" },
              () => {}
            );
            this.onDivisionClick(divId);
          } else if (type === "territory") {
            this.setState(
              { isTerritory: !this.state.isTerritory, territoryname: "" },
              () => {}
            );
            this.onDivisionClick(divId);
          } else if (type === "division") {
            this.setState(
              { isDivision: !this.state.isDivision, divisionname: "" },
              () => {}
            );
            this.componentDidMount();
          }
        }

        if (resp.data.status === 200 || resp.data.status === "200") {
          var props = {
            message: resp.data.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
        } else {
          this.setState({ disable: false });
          var props1 = {
            message: resp.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props1);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /** Add level API calls */
  addEmptyLevelHandler = (type, divId, areaId, regionId) => {
    const {
      areaname,
      regionname,

      divisionname,
      companyId,
    } = this.state;
    let data = {};
    switch (type) {
      case "area":
        data.division_id = divId;
        data.type = type;
        data.companyId = companyId;
        data.name = areaname;
        break;
      case "region":
        data.division_id = divId;
        data.area_id = areaId;
        data.type = type;
        data.companyId = companyId;
        data.name = regionname;
        break;
      default:
        data.type = type;
        data.companyId = companyId;
        data.name = divisionname;
        break;
    }
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/level/add", data)
      .then((resp) => {
        let response = resp.data;
        const newLocal = "200";
        if (response.status === newLocal) {
          if (type === "area") {
            this.setState({ isAreaEmpty: !this.state.isAreaEmpty }, () => {});
            this.onDivisionClick(divId);
          } else if (type === "region") {
            this.setState(
              { isRegionEmpty: !this.state.isRegionEmpty },
              () => {}
            );
            this.onDivisionClick(divId);
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /** Update levels API call */
  updateHandler = (type, id, divId) => {
    const { areaname, regionname, territoryname, divisionname } = this.state;
    let data = {};
    switch (type) {
      case "area":
        data.id = id;
        data.name = areaname;
        break;
      case "region":
        data.id = id;
        data.name = regionname;
        break;
      case "territory":
        data.id = id;
        data.name = territoryname;
        break;
      default:
        data.id = id;
        data.name = divisionname;
        break;
    }
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .put("/level/edit", data)
      .then((resp) => {
        let response = resp.data;
        const newLocal = "200";
        if (response.status === newLocal) {
          if (type === "area") {
            this.setState({ isAreaname: !this.state.isAreaname }, () => {});
            this.onDivisionClick(divId);
          } else if (type === "region") {
            this.setState({ isRegionname: !this.state.isRegionname }, () => {});
            this.onDivisionClick(divId);
          } else if (type === "territory") {
            this.setState(
              { isTerritoryname: !this.state.isTerritoryname },
              () => {}
            );
            this.onDivisionClick(divId);
          } else if (type === "division") {
            this.setState(
              { isDivname: !this.state.isDivname, divisionname: "" },
              () => {}
            );
            this.componentDidMount();
          }
        }
        if (resp.data.status === 200 || resp.data.status === "200") {
          var props = {
            message: resp.data.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
        } else {
          this.setState({ disable: false });
          var props1 = {
            message: resp.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props1);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /** Delete levels API call */
  deleteLevel = (e, id, type, divId) => {
    if (window.confirm("Are you sure to delete?")) {
      e.stopPropagation();
      this.setState({
        anchorEl: null,
        open: false,
        anchorElarea: null,
        openarea: false,
        anchorElregion: null,
        openregion: false,
      });
      let data = {
        id: this.state.editId,
        type: type,
      };
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .post("/level/remove", data)
        .then((resp) => {
          let response = resp.data;
          const newLocal = "200";
         
          if (resp.data.status === 200 || resp.data.status === "200") {
            if (type === "division") {
              this.componentDidMount();
            } else {
              this.onDivisionClick(divId);
            }
            var props2 = {
              message: resp.data.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(props2);
          } else {
            this.setState({ disable: false });
            var props1 = {
              message: resp.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props1);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };
  /**Menu open for territory */
  handleClick = (event, id, areaId, regId) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
      editId: id,
      editArea: areaId,
      editRegion: regId,
      editTerritory: id,
      editType: "territory",
    });
  };

  handleClickDivision = (event, id) => {
    event.stopPropagation();
    this.setState({
      anchorEldiv: event.currentTarget,
      openDiv: true,
    });
  };

  handleCloseDiv = (event) => {
    this.setState({ anchorEldiv: null, openDiv: false });
  };
  /**Menu close for territory */
  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };
  /**Menu open for region */
  handleClickregion = (event, id, areaId) => {
    event.stopPropagation();
    this.setState({
      anchorElregion: event.currentTarget,
      openregion: true,
      editId: id,
      editRegion: id,
      editArea: areaId,
      editType: "region",
    });
  };
  /**Menu open for region */
  handleCloseregion = () => {
    this.setState({ anchorElregion: null, openregion: false });
  };

  /**Menu open for area */
  handleClickarea = (event, id) => {
    event.stopPropagation();
    this.setState({
      anchorElarea: event.currentTarget,
      openarea: true,
      editId: id,
      editArea: id,
      editType: "area",
    });
  };
  /**Menu open for area */
  handleClosearea = () => {
    this.setState({ anchorElarea: null, openarea: false });
  };
  /**Add accounts API call */
  addAccountHandler = (id, divId) => {
    let data = {
      id: id,
      accounts: this.state.tags,
    };
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/accounts/change", data)
      .then((resp) => {
        let response = resp.data;
        const newLocal = "200";
        if (response.status === newLocal) {
          this.setState(
            { editId: "", tindex: "", isHospname: true, isHospital: true },
            () => {
              this.onDivisionClick(divId);
            }
          );
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**For opening Change parent modal */
  openParentmodal = (type, divId, areaId, regId) => {
    const { editArea, editRegion } = this.state;
    this.getFilters(divId, this.state.userRole, "Division");
    this.setState(
      {
        childType: type,
        divId: divId,
        areaId: editArea,
        regId: editRegion,
        openModal: true,
      },
      () => {
        this.getFilters(editArea, this.state.userRole, "Area");
      }
    );
  };
  /**For closing Change parent modal */
  onCloseModal = () => {
    const { changeParentError } = this.state;
    changeParentError.division = "";
    changeParentError.area = "";
    changeParentError.region = "";

    this.setState({
      openModal: false,
      changeParentError,
      isChangeParentValid: true,
    });
  };
  /**Change parent API call on submit*/
  onChangeparent = () => {
    const {
      editType,
      editId,
      Ptarea,
      Ptdivision,
      Ptregion,
      divId,
      areaId,
      regionId,
    } = this.state;
    let data = {
      from: {},
      to: {},
    };

    switch (editType) {
      case "area":
        this.setState({ anchorElarea: null, openarea: false });
        data.from._id = editId;
        data.from.type = editType;
        data.to._id = Ptdivision !== "" ? Ptdivision : divId;
        data.to.type = "division";
        break;
      case "region":
        this.setState({ anchorElregion: null, openregion: false });
        data.from._id = editId;
        data.from.type = editType;
        data.to._id = Ptarea !== "" ? Ptarea : areaId;
        data.to.type = "area";
        data.to.division_id = Ptdivision !== "" ? Ptdivision : divId;
        break;
      case "territory":
        this.setState({ anchorEl: null, open: false });
        data.from._id = editId;
        data.from.type = editType;
        data.to._id = Ptregion !== "" ? Ptregion : regionId;
        data.to.type = "region";
        data.to.division_id = Ptdivision !== "" ? Ptdivision : divId;
        data.to.area_id = Ptarea !== "" ? Ptarea : areaId;
        break;
      default:
        break;
    }
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/level/parentChange", data)
      .then((resp) => {
        let response = resp.data;
        const newLocal = "200";
        if (response.status === newLocal) {
          this.componentDidMount();
          this.onCloseModal();
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  getSelectedItem(options, selectedVal) {
    const item = options.find((opt) => {
      if (opt.value === selectedVal) return opt;
    });
    return item || {};
  }
  handleDelete = (id, tindex, index, tid) => {
    if (window.confirm("Are you sure to remove?")) {
      let data = {
        accountId: id,
      };
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .post("/accounts/remove", data)
        .then((resp) => {
          let response = resp.data;
          const newLocal = "200";
          if (response.status === newLocal) {
            let newArray = [...this.state.territory];
            const elementsIndex = this.state.territory.findIndex(
              (element) => element._id === tid
            );
            var actDetail = newArray[elementsIndex].accountDetails;
            actDetail.splice(index, 1);
            this.setState({ tags: actDetail });
            newArray[elementsIndex] = {
              ...newArray[elementsIndex],
              accountDetails: actDetail,
            };
            this.setState({ territory: newArray,  });
          //  this.getAccountslist(this.state.devid);
          } else {
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };
  addEmptyLevel = (name) => {
    switch (name) {
      case "area":
        this.setState({ isAreaEmpty: !this.state.isAreaEmpty });
        break;
      case "region":
        this.setState({ isRegionEmpty: !this.state.isRegionEmpty });
        break;

      default:
        break;
    }
  };

  //Billing Subscription functionalities start
  /**
   * @author Benakesha
   * @param {level id} level
   * @param {division id} id
   */

  openSubscriptionLevelModal = (level, id) => {
    const { subscriptionLevel } = this.state;
    subscriptionLevel.openModal = true;
    // subscriptionLevel.level=level;
    // subscriptionLevel.levelId=id;
    // console.log("test-",level,id);
    this.setState({
      subscriptionLevel,
      openarea: false,
      openregion: false,
      openDiv: false,
    });
  };

  checkSubscriptionLevel = (levelName, id) => {
    const { subscriptionLevel } = this.state;
    subscriptionLevel.level = levelName;
    subscriptionLevel.levelId = id;
    this.setState({
      subscriptionLevel,
    });
    let data = {
      level: levelName,
      levelId: id,
    };
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/json"
    )
      .post("/checkSubscription", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            showSubscribe: response.data.showSubscribe,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Internal Server Error",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };

  onDateRangeChanage = (date, dateString) => {
    const { subscriptionLevel } = this.state;
    var diffDate = new DateDiff(
      momentTimeZone(dateString[1]),
      momentTimeZone(dateString[0])
    );
    subscriptionLevel.billingTrialDays = diffDate.days();
    subscriptionLevel.billingTrialEnds = [
      momentTimeZone(dateString[0]),
      momentTimeZone(dateString[1]),
    ];
    this.setState({
      subscriptionLevel,
    });
  };

  subscriptionHandleChange = (e) => {
    const { name, value } = e.target;
    const { subscriptionLevel, subscriptionFormError } = this.state;
    switch (name) {
      case "billingPrice":
        validator.isEmpty(value)
          ? (subscriptionFormError.billingPrice =
              "Please enter a billing price")
          : !/^(?!0+$)\d{1,6}(\.\d{1,2})?$/.test(value)
          ? (subscriptionFormError.billingPrice =
              "Please enter the valid price. e.g: $2,500")
          : (subscriptionFormError.billingPrice = "");
        break;
      default:
        break;
    }

    subscriptionLevel[name] = value;
    this.setState({
      subscriptionLevel,
      subscriptionFormError,
    });
  };
  subscriptionModalClose = () => {
    const { subscriptionLevel, subscriptionFormError } = this.state;
    subscriptionLevel.openModal = false;
    subscriptionLevel.billingTrialEnds = [
      momentTimeZone().tz(this.state.timeZoneName),
      momentTimeZone().tz(this.state.timeZoneName).add(13, "days"),
    ];
    subscriptionLevel.billingTrialDays = 14;
    subscriptionLevel.billingPrice = 29.99;
    subscriptionLevel.level = "";
    subscriptionLevel.levelId = "";
    subscriptionLevel.openModal = false;

    subscriptionFormError.billingConfigType = "";
    subscriptionFormError.billingPrice = "";

    this.setState({
      subscriptionFormError,
      subscriptionLevel,
    });
  };
  valid = (current) => {
    return current && current < moment().subtract(1, "day");
  };
  subscriptionDataAdd = (e) => {
    e.preventDefault();
    const { subscriptionLevel, companyId } = this.state;
    var data = {
      level: subscriptionLevel.level,
      levelId: subscriptionLevel.levelId,
      companyId: companyId,
      startDate: subscriptionLevel.billingTrialEnds[0],
      endDate: subscriptionLevel.billingTrialEnds[1],
      type: parseInt(subscriptionLevel.billingConfigType),
      price: parseFloat(subscriptionLevel.billingPrice),
    };
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/json"
    )
      .post("/addSubscription", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(propserror);
          const { subscriptionLevel } = this.state;
          subscriptionLevel.openModal = false;
          let datas = {
            id: this.state.clickedDiv,
          };
          this.get_division_list(datas);
          this.setState({
            subscriptionLevel,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Internal Server Error",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  editInput = (field, editIds, editName, editValue, startDate, endDate) => {
    const { subscriptionFormError } = this.state;
    subscriptionFormError.billingPrice = "";
    this.setState({
      [field]: !this.state[field],
      editIds,
      subscriptionFormError,
      [editName]: editValue,
      subscriptionTrialEnds: [startDate, endDate],
    });
  };
  editPeriodInput = (field, editIds, startDate, endDate) => {
    const { subscriptionFormError } = this.state;
    subscriptionFormError.billingPrice = "";
    this.setState(
      {
        editIds,
        subscriptionFormError,
        subscriptionTrialEnds: [
          momentTimeZone(startDate),
          momentTimeZone(endDate),
        ],
      },
      () => {
        this.setState({
          [field]: !this.state[field],
        });
      }
    );
  };
  closeEdit = (field, editIds) => {
    const { subscriptionFormError } = this.state;
    subscriptionFormError.billingPrice = "";
    this.setState({
      [field]: true,
      editIds,
      subscriptionFormError,
    });
  };
  showEditIcon = (field, editIds) => {
    this.setState({
      [field]: true,
      editIds,
    });
  };
  hideEditIcon = (field, editIds) => {
    this.setState({
      [field]: false,
      editIds,
    });
  };

  onDateRangePeriodChanage = (date, dateString) => {
    // const { subscriptionLevel } = this.state;
    var diffDate = new DateDiff(
      momentTimeZone(dateString[1]),
      momentTimeZone(dateString[0])
    );

    this.setState({
      subscriptionTrialEnds: [
        momentTimeZone(dateString[0]),
        momentTimeZone(dateString[1]),
      ],
      subscriptionLevelTrialDays: diffDate.days(),
    });
  };
  updateSubscriptionPeriodHandler = (level, levelId, type, price, divId) => {
    var data = {};

    data.level = level;
    data.levelId = levelId;
    data.startDate = momentTimeZone(
      momentTimeZone(this.state.subscriptionTrialEnds[0])
    ).utc();
    data.endDate = momentTimeZone(
      momentTimeZone(this.state.subscriptionTrialEnds[1])
    ).utc();
    data.type = parseInt(type);
    data.price = parseFloat(price);
    this.editSubscriptionHandler(data, divId);
  };

  updateSubscriptionHandler = (field, level, levelId, type, divId) => {
    const { subscriptionFormError } = this.state;
    const isEmpty = Object.values(subscriptionFormError).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      var data = {};

      data.level = level;
      data.levelId = levelId;
      data.startDate = momentTimeZone(this.state.subscriptionTrialEnds[0]).tz(
        this.state.timeZoneName
      );
      data.endDate = momentTimeZone(this.state.subscriptionTrialEnds[1]).tz(
        this.state.timeZoneName
      );
      switch (field) {
        case "isBillingPrice":
          data.type = parseInt(type);
          data.price = parseFloat(this.state.subscriptionEditPrice);
          break;
        case "isBillingType":
          data.type = parseInt(this.state.subscriptionTypeEdit);
          data.price = parseFloat(type);
          break;
        default:
          break;
      }
      this.editSubscriptionHandler(data, divId);
    }
  };

  editSubscriptionHandler = (data, divId) => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6009 : "level",
      "application/json"
    )
      .post("/editSubscription", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationUpdate} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
          this.get_division_list({ id: divId });
          this.setState(
            {
              isBillingType: true,
              isBillingPeriod: true,
              isBillingPrice: true,

              billingPriceEdit: false,
              billingTypeEdit: false,
              billingPeriodEdit: false,

              subscriptionFormError: {
                billingPrice: "",
                billingConfigType: "",
              },
            }
          );
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  //Billing Subscription functionalities end

  render() {
    const {
      loading,
      isArea,
      isRegion,
      isTerritory,
      isDivision,
      isHospital,
      divisions,
      division,
      area,
      region,
      territory,
      userRole,
      isHospname,
      companySize,
      isAreaEmpty,
      isRegionEmpty,
    } = this.state;

    let classadmin = "";
    if (userRole === "admin") {
      classadmin = "show-edit";
    } else {
      classadmin = "show-edit";
    }
    let LargeCompany = (
      <>
        <div className="account-detail-container">
          <Grid spacing={2} container>
            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEldiv}
              keepMounted
              open={this.state.openDiv}
              onClose={this.handleCloseDiv}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                },
              }}
            >
              {this.state.showSubscribe ? (
                <MenuItem
                  key={3}
                  onClick={() =>
                    this.openSubscriptionLevelModal("division", division[0]._id)
                  }
                >
                  Add Subscription level
                </MenuItem>
              ) : (
                ""
              )}
            </Menu>
            <Grid item xs={12} md={4} className="division-grid">
              <Card variant="outlined">
                <CardContent>
                  <div className="add_division_btn_div">
                    <Button
                      className="add_division_btn primary-btn"
                      variant="outlined"
                      onClick={() => this.addLevel("division")}
                    >
                      <AddCircleOutlinedIcon style={{ marginRight: "9px" }} />
                      Add Division
                    </Button>
                  </div>

                  {divisions.length !== 0
                    ? divisions.map((divisions, index) => (
                        <>
                          <List
                            className={
                              division[0] !== undefined &&
                              division[0]._id === divisions._id
                                ? "active_level_nav"
                                : ""
                            }
                            component="nav"
                            aria-label="main mailbox folders"
                            onClick={() => this.onDivisionClick(divisions._id)}
                            onMouseEnter={() =>
                              this.checkSubscriptionLevel(
                                "division",
                                divisions._id
                              )
                            }
                          >
                            <ListItem button>
                              <ListItemIcon>
                                <DeviceHubOutlinedIcon className="device-hub" />
                              </ListItemIcon>
                              <ListItemText className="division-list">
                                {divisions.divisionName !== ""
                                  ? divisions.divisionName
                                  : ""}

                                <Typography
                                  variant="h6"
                                  className="division-mgr"
                                  component="div"
                                >
                                  <span>Managers</span> -{" "}
                                  {divisions.managersname.length !== 0
                                    ? divisions.managersname.map((emp, index) =>
                                        divisions.managersname.length - 1 ===
                                        index
                                          ? emp.name
                                          : emp.name + " , "
                                      )
                                    : "No managers assigned"}
                                </Typography>
                              </ListItemText>
                              {this.state.showSubscribe &&
                              this.state.subscriptionLevel.levelId ===
                                divisions._id ? (
                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(event) =>
                                    this.handleClickDivision(
                                      event,
                                      divisions._id
                                    )
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </ListItem>
                          </List>
                          <Divider />
                        </>
                      ))
                    : ""}
                </CardContent>
              </Card>
            </Grid>
            {loading ? (
              <Grid item xs={12} md={8} className="accordion-load">
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={75}
                  width={75}
                  visible={loading}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={8} className="accordion-box">
                {division.length !== 0
                  ? isDivision && (
                      <>
                        <div className="level_select_parent division-flex">
                          {this.state.isDivname && (
                            <>
                              <Typography
                                variant="h5"
                                className="divisionTitle"
                                component="div"
                              >
                                <DeviceHubOutlinedIcon className="device-hub" />
                                {division[0] !== undefined
                                  ? division[0].name
                                  : ""}
                                <img
                                  className={classadmin}
                                  src={editIcon}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  onClick={(e) => this.editLevel(e, "division")}
                                  alt="edit"
                                />
                              </Typography>
                              {/* <Button
                              className="open_all_btn"
                              variant="contained"
                            >
                              Open all
                            </Button> */}
                            </>
                          )}
                          {!this.state.isDivname && (
                            <span className="edit-input-level">
                              <CssTextField
                                id="outlined-basic"
                                name="divisionname"
                                variant="outlined"
                                type="text"
                                placeholder="Enter division name"
                                className="add-level-input"
                                defaultValue={division[0].name}
                                onChange={this.onChangeHandler}
                              />
                              <Button
                                className="level_save primary-btn primary-btn"
                                variant="contained"
                                disabled={
                                  this.state.divisionname === "" ? true : false
                                }
                                onClick={() =>
                                  this.updateHandler(
                                    "division",
                                    division[0]._id,
                                    division[0]._id
                                  )
                                }
                              >
                                Save
                              </Button>
                              <Button
                                className="level_cancel"
                                variant="contained"
                                onClick={() =>
                                  this.setState({
                                    isDivname: !this.state.isDivname,
                                  })
                                }
                              >
                                X
                              </Button>
                            </span>
                          )}

                          {/* <Button 
                        onClick={()=>this.openSubscriptionLevelModal("division",division[0]._id)}
                      >
                        Add Subscription   
                      </Button>   */}
                        </div>
                        <Accordion
                          className="accordion-first"
                          style={{ background: "#f2f4fb" }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            expandIcon={
                              <img src={ExpandMoreIcon} alt="expand" />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="accordion-summary-first"
                          >
                            {division[0].subscriptionStatus ? (
                              <div className="add_company_dialog accordion-users billing_detail_item billing_top_detail_item">
                                <p
                                  onMouseEnter={() =>
                                    this.showEditIcon(
                                      "billingTypeEdit",
                                      division[0]._id
                                    )
                                  }
                                  onMouseLeave={() =>
                                    this.hideEditIcon(
                                      "billingTypeEdit",
                                      division[0]._id
                                    )
                                  }
                                >
                                  Billing Type :
                                  {this.state.isBillingType ? (
                                    <>
                                      <span>
                                        {division[0].subscription.type === 1
                                          ? "Organizational"
                                          : division[0].subscription.type === 2
                                          ? "Team"
                                          : division[0].subscription.type === 3
                                          ? "Individual"
                                          : "-"}
                                          {this.state.billingTypeEdit &&
                                      this.state.editIds === division[0]._id ? (
                                        <img
                                          src={editIcon}
                                          alt="edit"
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() =>
                                            this.editInput(
                                              "isBillingType",
                                              division[0]._id,
                                              "subscriptionTypeEdit",
                                              division[0].subscription.type,
                                              division[0].subscription
                                                .startDate,
                                              division[0].subscription.endDate
                                            )
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="edit-input">
                                      <FormControl
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                      >
                                        <Select
                                          margin="dense"
                                          className="customSelect"
                                          placeholder="Select billing type"
                                          name="subscriptionTypeEdit"
                                          fullWidth
                                          onChange={this.onChangeHandler}
                                          value={
                                            this.state.subscriptionTypeEdit ===
                                              2 ||
                                            this.state.subscriptionTypeEdit ===
                                              "2"
                                              ? "2"
                                              : this.state
                                                  .subscriptionTypeEdit === 3 ||
                                                this.state
                                                  .subscriptionTypeEdit === "3"
                                              ? "3"
                                              : ""
                                          }
                                        >
                                          <MenuItem value="2">Team</MenuItem>
                                          <MenuItem value="3">
                                            Individual
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                      <Button
                                        className="add_more_btn"
                                        onClick={() =>
                                          this.updateSubscriptionHandler(
                                            "isBillingType",
                                            "division",
                                            division[0]._id,
                                            division[0].subscription.price,
                                            division[0]._id
                                          )
                                        }
                                        //disabled={props.billingTypeDisable?false:true}
                                      >
                                        Save
                                      </Button>
                                      <img
                                        src={close_icon_red}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.closeEdit(
                                            "isBillingType",
                                            division[0]._id
                                          )
                                        }
                                      />
                                    </span>
                                  )}
                                </p>
                                <p
                                className="billing-period"
                                  onMouseEnter={() =>
                                    this.showEditIcon(
                                      "billingPeriodEdit",
                                      division[0]._id
                                    )
                                  }
                                  onMouseLeave={() =>
                                    this.hideEditIcon(
                                      "billingPeriodEdit",
                                      division[0]._id
                                    )
                                  }
                                >
                                  Trial Period :
                                  {this.state.isBillingPeriod ? (
                                    <>
                                      <span>
                                        {momentTimeZone(
                                          division[0].subscription.startDate
                                        )
                                          .tz(this.state.timeZoneName)
                                          .format("MM-DD-YYYY")}{" "}
                                        to{" "}
                                        {momentTimeZone(
                                          division[0].subscription.endDate
                                        )
                                          .tz(this.state.timeZoneName)
                                          .format("MM-DD-YYYY")}
                                          {this.state.billingPeriodEdit &&
                                      this.state.editIds === division[0]._id ? (
                                        <img
                                          src={editIcon}
                                          alt="edit"
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() =>
                                            this.editPeriodInput(
                                              "isBillingPeriod",
                                              division[0]._id,
                                              division[0].subscription
                                                .startDate,
                                              division[0].subscription.endDate
                                            )
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="edit-input">
                                      <div className="trail_field">
                                        <RangePicker
                                          className="filter_date_range "
                                          name="datepicker"
                                          format="MM/DD/YYYY"
                                          allowClear={false}
                                          inputReadOnly={true}
                                          separator="to"
                                          value={[
                                            moment(
                                              this.state
                                                .subscriptionTrialEnds[0],
                                              "MM/DD/YYYY"
                                            ),
                                            moment(
                                              this.state
                                                .subscriptionTrialEnds[1],
                                              "MM/DD/YYYY"
                                            ),
                                          ]}
                                          disabledDate={this.valid}
                                          placeholder={[
                                            "MM/DD/YYYY",
                                            "MM/DD/YYYY",
                                          ]}
                                          suffixIcon={
                                            <img
                                              className="carret"
                                              alt="carret"
                                              src={carret_down}
                                            />
                                          }
                                          getPopupContainer={(node) =>
                                            node.parentNode
                                          }
                                          onChange={
                                            this.onDateRangePeriodChanage
                                          }
                                        />
                                        {/* <span className="remaining_days">{this.state.subscriptionLevelTrialDays?this.state.subscriptionLevelTrialDays:0} days</span> */}
                                      </div>
                                      <span>
                                        <Button
                                          className="add_more_btn"
                                          onClick={() =>
                                            this.updateSubscriptionPeriodHandler(
                                              "division",
                                              division[0]._id,
                                              division[0].subscription.type,
                                              division[0].subscription.price,
                                              division[0]._id
                                            )
                                          }
                                          // disabled={props.billingsDatesDisable?false:true}
                                        >
                                          Save
                                        </Button>
                                        <img
                                          src={close_icon_red}
                                          alt="close"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.closeEdit(
                                              "isBillingPeriod",
                                              division[0]._id
                                            )
                                          }
                                        />
                                      </span>
                                    </span>
                                  )}
                                </p>
                                <p
                                  onMouseEnter={() =>
                                    this.showEditIcon(
                                      "billingPriceEdit",
                                      division[0]._id
                                    )
                                  }
                                  onMouseLeave={() =>
                                    this.hideEditIcon(
                                      "billingPriceEdit",
                                      division[0]._id
                                    )
                                  }
                                >
                                  Billing Price/User :
                                  {this.state.isBillingPrice ? (
                                    <>
                                      <span>
                                        ${" "}
                                        {parseFloat(
                                          division[0].subscription.price
                                        )}
                                        {this.state.billingPriceEdit &&
                                      this.state.editIds === division[0]._id ? (
                                        <img
                                          src={editIcon}
                                          alt="edit"
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() =>
                                            this.editInput(
                                              "isBillingPrice",
                                              division[0]._id,
                                              "subscriptionEditPrice",
                                              division[0].subscription.price,
                                              division[0].subscription
                                                .startDate,
                                              division[0].subscription.endDate
                                            )
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="edit-input">
                                      <CssTextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="subscriptionEditPrice"
                                        className="comp_name"
                                        autoFocus
                                        placeholder="Enter price per user"
                                        type="text"
                                        value={this.state.subscriptionEditPrice}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment
                                              position="start"
                                              className="dollar_symbol"
                                            >
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={this.onChangeHandler}
                                      />
                                      <Button
                                        className="add_more_btn"
                                        onClick={() =>
                                          this.updateSubscriptionHandler(
                                            "isBillingPrice",
                                            "division",
                                            division[0]._id,
                                            division[0].subscription.type,
                                            division[0]._id
                                          )
                                        }
                                        // disabled={props.isPriceDisable?false:true}
                                      >
                                        Save
                                      </Button>
                                      <img
                                        src={close_icon_red}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.closeEdit(
                                            "isBillingPrice",
                                            division[0]._id
                                          )
                                        }
                                      />
                                    </span>
                                  )}
                                </p>
                                <div className="modal-err-msg">
                                  {this.state.subscriptionFormError
                                    .billingPrice !== ""
                                    ? `* ${this.state.subscriptionFormError.billingPrice}`
                                    : ""}
                                </div>
                              </div>
                            ) : null}
                            <Typography
                              variant="h6"
                              className="accordion-first-mgr"
                            >
                              Managers -{" "}
                              {division[0] !== undefined
                                ? division[0].employeeDetails.length !== 0
                                  ? division[0].employeeDetails.map(
                                      (emp, divIndex) =>
                                        division[0].employeeDetails.length -
                                          1 ===
                                        divIndex
                                          ? emp.name
                                          : emp.name + " , "
                                    )
                                  : "No managers assigned"
                                : ""}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accord-details-area">
                            {area === undefined
                              ? ""
                              : area.map((area, index) => (
                                  <div className="accord-padd accord-padd-ext">
                                    <Accordion
                                      className="accordion-area"
                                      defaultExpanded={
                                        index === 0 ? true : false
                                      }
                                    >
                                      <IconLeftAccordionSummary
                                        expandIcon={
                                          <img
                                            src={ExpandMoreIcon}
                                            alt="expand"
                                          />
                                        }
                                        IconButtonProps={{ edge: "start" }}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                      >
                                        <div style={{ width: "100%" }}>
                                          {this.state.isAreaname && (
                                            <>
                                              <div className="accordion-head menu-icon">
                                                {area.name}

                                                <IconButton
                                                  aria-label="more"
                                                  aria-controls="long-menu"
                                                  aria-haspopup="true"
                                                  onMouseEnter={() =>
                                                    this.checkSubscriptionLevel(
                                                      "area",
                                                      area._id
                                                    )
                                                  }
                                                  onClick={(event) =>
                                                    this.handleClickarea(
                                                      event,
                                                      area._id
                                                    )
                                                  }
                                                >
                                                  <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                  id="long-menu"
                                                  anchorEl={
                                                    this.state.anchorElarea
                                                  }
                                                  keepMounted
                                                  open={this.state.openarea}
                                                  onClose={this.handleClosearea}
                                                  PaperProps={{
                                                    style: {
                                                      maxHeight: 48 * 4.5,
                                                    },
                                                  }}
                                                >
                                                  <MenuItem
                                                    key={1}
                                                    onClick={() =>
                                                      this.openParentmodal(
                                                        "area",
                                                        division[0]._id
                                                      )
                                                    }
                                                  >
                                                    Change parent
                                                  </MenuItem>
                                                  <MenuItem
                                                    key="area"
                                                    onClick={(e) =>
                                                      this.editLevel(e, "area")
                                                    }
                                                  >
                                                    Edit
                                                  </MenuItem>
                                                  <MenuItem
                                                    key={3}
                                                    onClick={(e) =>
                                                      this.deleteLevel(
                                                        e,
                                                        area._id,
                                                        "area",
                                                        division[0]._id
                                                      )
                                                    }
                                                  >
                                                    Remove
                                                  </MenuItem>
                                                  {this.state.showSubscribe ? (
                                                    <MenuItem
                                                      key={3}
                                                      onClick={() =>
                                                        this.openSubscriptionLevelModal(
                                                          "area",
                                                          area._id
                                                        )
                                                      }
                                                    >
                                                      Add Subscription level
                                                    </MenuItem>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Menu>
                                              </div>
                                            </>
                                          )}
                                          {!this.state.isAreaname && (
                                            <span className="edit-input-level">
                                              {this.state.editId ===
                                              area._id ? (
                                                <>
                                                  <CssTextField
                                                    id="outlined-basic"
                                                    name="areaname"
                                                    variant="outlined"
                                                    type="text"
                                                    placeholder="Enter area name"
                                                    className="add-level-input"
                                                    defaultValue={area.name}
                                                    onChange={
                                                      this.onChangeHandler
                                                    }
                                                  />
                                                  <Button
                                                    className="level_save primary-btn"
                                                    variant="contained"
                                                    disabled={
                                                      this.state.areaname === ""
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={() =>
                                                      this.updateHandler(
                                                        "area",
                                                        area._id,
                                                        division[0]._id
                                                      )
                                                    }
                                                  >
                                                    Save
                                                  </Button>
                                                  <Button
                                                    className="level_cancel"
                                                    variant="contained"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      this.setState({
                                                        isAreaname: !this.state
                                                          .isAreaname,
                                                      });
                                                    }}
                                                  >
                                                    X
                                                  </Button>
                                                </>
                                              ) : (
                                                <div className="accordion-head">
                                                  {area.name}
                                                </div>
                                              )}
                                            </span>
                                          )}
                                          {area.subscriptionStatus ? (
                                            <div className="add_company_dialog accordion-users billing_detail_item">
                                              <p
                                                onMouseEnter={() =>
                                                  this.showEditIcon(
                                                    "billingTypeEdit",
                                                    area._id
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.hideEditIcon(
                                                    "billingTypeEdit",
                                                    area._id
                                                  )
                                                }
                                              >
                                                Billing Type :
                                                {this.state.isBillingType ? (
                                                  <>
                                                    <span>
                                                      {area.subscription
                                                        .type === 1
                                                        ? "Organizational"
                                                        : area.subscription
                                                            .type === 2
                                                        ? "Team"
                                                        : area.subscription
                                                            .type === 3
                                                        ? "Individual"
                                                        : "-"}
                                                        {this.state
                                                      .billingTypeEdit &&
                                                    this.state.editIds ===
                                                      area._id ? (
                                                      <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        onClick={() =>
                                                          this.editInput(
                                                            "isBillingType",
                                                            area._id,
                                                            "subscriptionTypeEdit",
                                                            area.subscription
                                                              .type,
                                                            area.subscription
                                                              .startDate,
                                                            area.subscription
                                                              .endDate
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <span className="edit-input">
                                                    <FormControl
                                                      variant="outlined"
                                                      margin="dense"
                                                    >
                                                      <Select
                                                        margin="dense"
                                                        className="customSelect"
                                                        placeholder="Select billing type"
                                                        name="subscriptionTypeEdit"
                                                        onChange={
                                                          this.onChangeHandler
                                                        }
                                                        value={
                                                          this.state
                                                            .subscriptionTypeEdit ===
                                                            2 ||
                                                          this.state
                                                            .subscriptionTypeEdit ===
                                                            "2"
                                                            ? "2"
                                                            : this.state
                                                                .subscriptionTypeEdit ===
                                                                3 ||
                                                              this.state
                                                                .subscriptionTypeEdit ===
                                                                "3"
                                                            ? "3"
                                                            : ""
                                                        }
                                                      >
                                                        <MenuItem value="2">
                                                          Team
                                                        </MenuItem>
                                                        <MenuItem value="3">
                                                          Individual
                                                        </MenuItem>
                                                      </Select>
                                                    </FormControl>
                                                    <Button
                                                      className="add_more_btn"
                                                      onClick={() =>
                                                        this.updateSubscriptionHandler(
                                                          "isBillingType",
                                                          "area",
                                                          area._id,
                                                          area.subscription
                                                            .price,
                                                          division[0]._id
                                                        )
                                                      }
                                                      //disabled={props.billingTypeDisable?false:true}
                                                    >
                                                      Save
                                                    </Button>
                                                    <img
                                                      src={close_icon_red}
                                                      alt=""
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.closeEdit(
                                                          "isBillingType",
                                                          area._id
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                )}
                                              </p>
                                              <p
                                              className="billing-period"
                                                onMouseEnter={() =>
                                                  this.showEditIcon(
                                                    "billingPeriodEdit",
                                                    area._id
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.hideEditIcon(
                                                    "billingPeriodEdit",
                                                    area._id
                                                  )
                                                }
                                              >
                                                Trial Period :
                                                {this.state.isBillingPeriod ? (
                                                  <>
                                                    <span>
                                                      {momentTimeZone(
                                                        area.subscription
                                                          .startDate
                                                      )
                                                        .tz(
                                                          this.state
                                                            .timeZoneName
                                                        )
                                                        .format(
                                                          "MM-DD-YYYY"
                                                        )}{" "}
                                                      to{" "}
                                                      {momentTimeZone(
                                                        area.subscription
                                                          .endDate
                                                      )
                                                        .tz(
                                                          this.state
                                                            .timeZoneName
                                                        )
                                                        .format("MM-DD-YYYY")}
                                                        {this.state
                                                      .billingPeriodEdit &&
                                                    this.state.editIds ===
                                                      area._id ? (
                                                      <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        onClick={() =>
                                                          this.editPeriodInput(
                                                            "isBillingPeriod",
                                                            area._id,
                                                            area.subscription
                                                              .startDate,
                                                            area.subscription
                                                              .endDate
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <span className="edit-input">
                                                    <div className="trail_field">
                                                      <RangePicker
                                                        className="filter_date_range "
                                                        name="datepicker"
                                                        format="MM/DD/YYYY"
                                                        allowClear={false}
                                                        inputReadOnly={true}
                                                        separator="to"
                                                        value={[
                                                          moment(
                                                            this.state
                                                              .subscriptionTrialEnds[0],
                                                            "MM/DD/YYYY"
                                                          ),
                                                          moment(
                                                            this.state
                                                              .subscriptionTrialEnds[1],
                                                            "MM/DD/YYYY"
                                                          ),
                                                        ]}
                                                        disabledDate={
                                                          this.valid
                                                        }
                                                        placeholder={[
                                                          "MM/DD/YYYY",
                                                          "MM/DD/YYYY",
                                                        ]}
                                                        suffixIcon={
                                                          <img
                                                            className="carret"
                                                            alt="carret"
                                                            src={carret_down}
                                                          />
                                                        }
                                                        getPopupContainer={(
                                                          node
                                                        ) => node.parentNode}
                                                        onChange={
                                                          this
                                                            .onDateRangePeriodChanage
                                                        }
                                                      />
                                                      {/* <span className="remaining_days">{this.state.subscriptionLevelTrialDays?this.state.subscriptionLevelTrialDays:0} days</span> */}
                                                    </div>
                                                    <span>
                                                      <Button
                                                        className="add_more_btn"
                                                        onClick={() =>
                                                          this.updateSubscriptionPeriodHandler(
                                                            "area",
                                                            area._id,
                                                            area.subscription
                                                              .type,
                                                            area.subscription
                                                              .price,
                                                            division[0]._id
                                                          )
                                                        }
                                                        // disabled={props.billingsDatesDisable?false:true}
                                                      >
                                                        Save
                                                      </Button>
                                                      <img
                                                        src={close_icon_red}
                                                        alt="close"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          this.closeEdit(
                                                            "isBillingPeriod",
                                                            area._id
                                                          )
                                                        }
                                                      />
                                                    </span>
                                                  </span>
                                                )}
                                              </p>
                                              <p
                                                onMouseEnter={() =>
                                                  this.showEditIcon(
                                                    "billingPriceEdit",
                                                    area._id
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.hideEditIcon(
                                                    "billingPriceEdit",
                                                    area._id
                                                  )
                                                }
                                              >
                                                Billing Price/User :
                                                {this.state.isBillingPrice ? (
                                                  <>
                                                    <span>
                                                      ${" "}
                                                      {parseFloat(
                                                        area.subscription.price
                                                      )}
                                                       {this.state
                                                      .billingPriceEdit &&
                                                    this.state.editIds ===
                                                      area._id ? (
                                                      <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        onClick={() =>
                                                          this.editInput(
                                                            "isBillingPrice",
                                                            area._id,
                                                            "subscriptionEditPrice",
                                                            area.subscription
                                                              .price,
                                                            area.subscription
                                                              .startDate,
                                                            area.subscription
                                                              .endDate
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <span className="edit-input">
                                                    <CssTextField
                                                      id="outlined-basic"
                                                      variant="outlined"
                                                      name="subscriptionEditPrice"
                                                      className="comp_name"
                                                      autoFocus
                                                      placeholder="Enter price per user"
                                                      type="text"
                                                      value={
                                                        this.state
                                                          .subscriptionEditPrice
                                                      }
                                                      InputProps={{
                                                        startAdornment: (
                                                          <InputAdornment
                                                            position="start"
                                                            className="dollar_symbol"
                                                          >
                                                            $
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                      onChange={
                                                        this.onChangeHandler
                                                      }
                                                    />
                                                    <Button
                                                      className="add_more_btn"
                                                      onClick={() =>
                                                        this.updateSubscriptionHandler(
                                                          "isBillingPrice",
                                                          "area",
                                                          area._id,
                                                          area.subscription
                                                            .type,
                                                          division[0]._id
                                                        )
                                                      }
                                                      // disabled={props.isPriceDisable?false:true}
                                                    >
                                                      Save
                                                    </Button>
                                                    <img
                                                      src={close_icon_red}
                                                      alt=""
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.closeEdit(
                                                          "isBillingPrice",
                                                          area._id
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                )}
                                              </p>
                                              <div className="modal-err-msg">
                                                {this.state
                                                  .subscriptionFormError
                                                  .billingPrice !== ""
                                                  ? `* ${this.state.subscriptionFormError.billingPrice}`
                                                  : ""}
                                              </div>
                                            </div>
                                          ) : null}
                                          <div className="accordion-users">
                                            Managers -{" "}
                                            {area.employeeDetails.length !== 0
                                              ? area.employeeDetails.map(
                                                  (emp, index) =>
                                                    area.employeeDetails
                                                      .length -
                                                      1 ===
                                                    index
                                                      ? emp.name
                                                      : emp.name + " , "
                                                )
                                              : "No managers assigned"}
                                          </div>
                                        </div>
                                      </IconLeftAccordionSummary>
                                      <AccordionDetails
                                        className="accord-details"
                                        style={{ background: "#f2f4fb" }}
                                      >
                                        {region === undefined
                                          ? ""
                                          : region.map((region, rindex) =>
                                              region.area_id === area._id ? (
                                                <div className="accord-padd accord-padd-ext">
                                                  <Accordion className="accordion-region">
                                                    <IconLeftAccordionSummary
                                                      expandIcon={
                                                        <img
                                                          src={ExpandMoreIcon}
                                                          alt="expand"
                                                        />
                                                      }
                                                      IconButtonProps={{
                                                        edge: "start",
                                                      }}
                                                      aria-controls="panel2a-content"
                                                      id="panel2a-header"
                                                    >
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        {this.state
                                                          .isRegionname && (
                                                          <>
                                                            <div className="accordion-head menu-icon">
                                                              {region.name}
                                                              <IconButton
                                                                aria-label="more"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                onMouseEnter={() =>
                                                                  this.checkSubscriptionLevel(
                                                                    "region",
                                                                    region._id
                                                                  )
                                                                }
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  this.handleClickregion(
                                                                    event,
                                                                    region._id,
                                                                    area._id
                                                                  )
                                                                }
                                                              >
                                                                <MoreVertIcon />
                                                              </IconButton>
                                                              <Menu
                                                                id="long-menu"
                                                                anchorEl={
                                                                  this.state
                                                                    .anchorElregion
                                                                }
                                                                keepMounted
                                                                open={
                                                                  this.state
                                                                    .openregion
                                                                }
                                                                onClose={
                                                                  this
                                                                    .handleCloseregion
                                                                }
                                                                PaperProps={{
                                                                  style: {
                                                                    maxHeight:
                                                                      48 * 4.5,
                                                                  },
                                                                }}
                                                              >
                                                                <MenuItem
                                                                  key={1}
                                                                  data-areaid={
                                                                    area._id
                                                                  }
                                                                  onClick={() =>
                                                                    this.openParentmodal(
                                                                      "region",
                                                                      division[0]
                                                                        ._id,
                                                                      area._id
                                                                    )
                                                                  }
                                                                >
                                                                  Change parent
                                                                </MenuItem>
                                                                <MenuItem
                                                                  key="region"
                                                                  data-id={
                                                                    rindex
                                                                  }
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this.editLevel(
                                                                      e,
                                                                      "region"
                                                                    )
                                                                  }
                                                                >
                                                                  Edit
                                                                </MenuItem>
                                                                <MenuItem
                                                                  key={3}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this.deleteLevel(
                                                                      e,
                                                                      region._id,
                                                                      "region",
                                                                      division[0]
                                                                        ._id
                                                                    )
                                                                  }
                                                                >
                                                                  Remove
                                                                </MenuItem>
                                                                {this.state
                                                                  .showSubscribe ? (
                                                                  <MenuItem
                                                                    key={3}
                                                                    onClick={() =>
                                                                      this.openSubscriptionLevelModal(
                                                                        "region",
                                                                        region._id
                                                                      )
                                                                    }
                                                                  >
                                                                    Add
                                                                    Subscription
                                                                    level
                                                                  </MenuItem>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </Menu>
                                                            </div>
                                                          </>
                                                        )}
                                                        {!this.state
                                                          .isRegionname && (
                                                          <span className="edit-input-level">
                                                            {this.state
                                                              .editId ===
                                                            region._id ? (
                                                              <>
                                                                <CssTextField
                                                                  id="outlined-basic"
                                                                  name="regionname"
                                                                  variant="outlined"
                                                                  type="text"
                                                                  placeholder="Enter region name"
                                                                  className="add-level-input"
                                                                  defaultValue={
                                                                    region.name
                                                                  }
                                                                  onChange={
                                                                    this
                                                                      .onChangeHandler
                                                                  }
                                                                />
                                                                <Button
                                                                  className="level_save primary-btn"
                                                                  variant="contained"
                                                                  disabled={
                                                                    this.state
                                                                      .regionname ===
                                                                    ""
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    this.updateHandler(
                                                                      "region",
                                                                      region._id,
                                                                      division[0]
                                                                        ._id
                                                                    )
                                                                  }
                                                                >
                                                                  Save
                                                                </Button>
                                                                <Button
                                                                  className="level_cancel"
                                                                  variant="contained"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                    this.setState(
                                                                      {
                                                                        isRegionname: !this
                                                                          .state
                                                                          .isRegionname,
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  X
                                                                </Button>
                                                              </>
                                                            ) : (
                                                              <div className="accordion-head">
                                                                {region.name}
                                                              </div>
                                                            )}
                                                          </span>
                                                        )}
                                                        {region.subscriptionStatus ? (
                                                          <div className="add_company_dialog accordion-users billing_detail_item">
                                                            <p
                                                              onMouseEnter={() =>
                                                                this.showEditIcon(
                                                                  "billingTypeEdit",
                                                                  region._id
                                                                )
                                                              }
                                                              onMouseLeave={() =>
                                                                this.hideEditIcon(
                                                                  "billingTypeEdit",
                                                                  region._id
                                                                )
                                                              }
                                                            >
                                                              Billing Type :
                                                              {this.state
                                                                .isBillingType ? (
                                                                <>
                                                                  <span>
                                                                    {region
                                                                      .subscription
                                                                      .type ===
                                                                    1
                                                                      ? "Organizational"
                                                                      : region
                                                                          .subscription
                                                                          .type ===
                                                                        2
                                                                      ? "Team"
                                                                      : region
                                                                          .subscription
                                                                          .type ===
                                                                        3
                                                                      ? "Individual"
                                                                      : "-"}
                                                                      {this.state
                                                                    .billingTypeEdit &&
                                                                  this.state
                                                                    .editIds ===
                                                                    region._id ? (
                                                                    <img
                                                                      src={
                                                                        editIcon
                                                                      }
                                                                      alt="edit"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                        marginLeft:
                                                                          "10px",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.editInput(
                                                                          "isBillingType",
                                                                          region._id,
                                                                          "subscriptionTypeEdit",
                                                                          region
                                                                            .subscription
                                                                            .type,
                                                                          region
                                                                            .subscription
                                                                            .startDate,
                                                                          region
                                                                            .subscription
                                                                            .endDate
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <span className="edit-input">
                                                                  <FormControl
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                  >
                                                                    <Select
                                                                      margin="dense"
                                                                      className="customSelect"
                                                                      placeholder="Select billing type"
                                                                      name="subscriptionTypeEdit"
                                                                      onChange={
                                                                        this
                                                                          .onChangeHandler
                                                                      }
                                                                      value={
                                                                        this
                                                                          .state
                                                                          .subscriptionTypeEdit ===
                                                                          2 ||
                                                                        this
                                                                          .state
                                                                          .subscriptionTypeEdit ===
                                                                          "2"
                                                                          ? "2"
                                                                          : this
                                                                              .state
                                                                              .subscriptionTypeEdit ===
                                                                              3 ||
                                                                            this
                                                                              .state
                                                                              .subscriptionTypeEdit ===
                                                                              "3"
                                                                          ? "3"
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <MenuItem value="2">
                                                                        Team
                                                                      </MenuItem>
                                                                      <MenuItem value="3">
                                                                        Individual
                                                                      </MenuItem>
                                                                    </Select>
                                                                  </FormControl>
                                                                  <Button
                                                                    className="add_more_btn"
                                                                    onClick={() =>
                                                                      this.updateSubscriptionHandler(
                                                                        "isBillingType",
                                                                        "region",
                                                                        region._id,
                                                                        region
                                                                          .subscription
                                                                          .price,
                                                                        division[0]
                                                                          ._id
                                                                      )
                                                                    }
                                                                    //disabled={props.billingTypeDisable?false:true}
                                                                  >
                                                                    Save
                                                                  </Button>
                                                                  <img
                                                                    src={
                                                                      close_icon_red
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.closeEdit(
                                                                        "isBillingType",
                                                                        region._id
                                                                      )
                                                                    }
                                                                  />
                                                                </span>
                                                              )}
                                                            </p>
                                                            <p
                                                            className="billing-period"
                                                              onMouseEnter={() =>
                                                                this.showEditIcon(
                                                                  "billingPeriodEdit",
                                                                  region._id
                                                                )
                                                              }
                                                              onMouseLeave={() =>
                                                                this.hideEditIcon(
                                                                  "billingPeriodEdit",
                                                                  region._id
                                                                )
                                                              }
                                                            >
                                                              Trial Period :
                                                              {this.state
                                                                .isBillingPeriod ? (
                                                                <>
                                                                  <span>
                                                                    {momentTimeZone(
                                                                      region
                                                                        .subscription
                                                                        .startDate
                                                                    )
                                                                      .tz(
                                                                        this
                                                                          .state
                                                                          .timeZoneName
                                                                      )
                                                                      .format(
                                                                        "MM-DD-YYYY"
                                                                      )}{" "}
                                                                    to{" "}
                                                                    {momentTimeZone(
                                                                      region
                                                                        .subscription
                                                                        .endDate
                                                                    )
                                                                      .tz(
                                                                        this
                                                                          .state
                                                                          .timeZoneName
                                                                      )
                                                                      .format(
                                                                        "MM-DD-YYYY"
                                                                      )}
                                                                      {this.state
                                                                    .billingPeriodEdit &&
                                                                  this.state
                                                                    .editIds ===
                                                                    region._id ? (
                                                                    <img
                                                                      src={
                                                                        editIcon
                                                                      }
                                                                      alt="edit"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                        marginLeft:
                                                                          "10px",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.editPeriodInput(
                                                                          "isBillingPeriod",
                                                                          region._id,
                                                                          region
                                                                            .subscription
                                                                            .startDate,
                                                                          region
                                                                            .subscription
                                                                            .endDate
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <span className="edit-input">
                                                                  <div className="trail_field">
                                                                    <RangePicker
                                                                      className="filter_date_range "
                                                                      name="datepicker"
                                                                      format="MM/DD/YYYY"
                                                                      allowClear={
                                                                        false
                                                                      }
                                                                      inputReadOnly={
                                                                        true
                                                                      }
                                                                      separator="to"
                                                                      value={[
                                                                        moment(
                                                                          this
                                                                            .state
                                                                            .subscriptionTrialEnds[0],
                                                                          "MM/DD/YYYY"
                                                                        ),
                                                                        moment(
                                                                          this
                                                                            .state
                                                                            .subscriptionTrialEnds[1],
                                                                          "MM/DD/YYYY"
                                                                        ),
                                                                      ]}
                                                                      disabledDate={
                                                                        this
                                                                          .valid
                                                                      }
                                                                      placeholder={[
                                                                        "MM/DD/YYYY",
                                                                        "MM/DD/YYYY",
                                                                      ]}
                                                                      suffixIcon={
                                                                        <img
                                                                          className="carret"
                                                                          alt="carret"
                                                                          src={
                                                                            carret_down
                                                                          }
                                                                        />
                                                                      }
                                                                      getPopupContainer={(
                                                                        node
                                                                      ) =>
                                                                        node.parentNode
                                                                      }
                                                                      onChange={
                                                                        this
                                                                          .onDateRangePeriodChanage
                                                                      }
                                                                    />
                                                                    {/* <span className="remaining_days">{this.state.subscriptionLevelTrialDays?this.state.subscriptionLevelTrialDays:0} days</span> */}
                                                                  </div>
                                                                  <span>
                                                                    <Button
                                                                      className="add_more_btn"
                                                                      onClick={() =>
                                                                        this.updateSubscriptionPeriodHandler(
                                                                          "region",
                                                                          region._id,
                                                                          region
                                                                            .subscription
                                                                            .type,
                                                                          region
                                                                            .subscription
                                                                            .price,
                                                                          division[0]
                                                                            ._id
                                                                        )
                                                                      }
                                                                      // disabled={props.billingsDatesDisable?false:true}
                                                                    >
                                                                      Save
                                                                    </Button>
                                                                    <img
                                                                      src={
                                                                        close_icon_red
                                                                      }
                                                                      alt="close"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.closeEdit(
                                                                          "isBillingPeriod",
                                                                          region._id
                                                                        )
                                                                      }
                                                                    />
                                                                  </span>
                                                                </span>
                                                              )}
                                                            </p>
                                                            <p
                                                              onMouseEnter={() =>
                                                                this.showEditIcon(
                                                                  "billingPriceEdit",
                                                                  region._id
                                                                )
                                                              }
                                                              onMouseLeave={() =>
                                                                this.hideEditIcon(
                                                                  "billingPriceEdit",
                                                                  region._id
                                                                )
                                                              }
                                                            >
                                                              Billing Price/User
                                                              :
                                                              {this.state
                                                                .isBillingPrice ? (
                                                                <>
                                                                  <span>
                                                                    ${" "}
                                                                    {parseFloat(
                                                                      region
                                                                        .subscription
                                                                        .price
                                                                    )}
                                                                    {this.state
                                                                    .billingPriceEdit &&
                                                                  this.state
                                                                    .editIds ===
                                                                    region._id ? (
                                                                    <img
                                                                      src={
                                                                        editIcon
                                                                      }
                                                                      alt="edit"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                        marginLeft:
                                                                          "10px",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.editInput(
                                                                          "isBillingPrice",
                                                                          region._id,
                                                                          "subscriptionEditPrice",
                                                                          region
                                                                            .subscription
                                                                            .price,
                                                                          region
                                                                            .subscription
                                                                            .startDate,
                                                                          region
                                                                            .subscription
                                                                            .endDate
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <span className="edit-input">
                                                                  <CssTextField
                                                                    id="outlined-basic"
                                                                    variant="outlined"
                                                                    name="subscriptionEditPrice"
                                                                    className="comp_name"
                                                                    autoFocus
                                                                    placeholder="Enter price per user"
                                                                    type="text"
                                                                    value={
                                                                      this.state
                                                                        .subscriptionEditPrice
                                                                    }
                                                                    InputProps={{
                                                                      startAdornment: (
                                                                        <InputAdornment
                                                                          position="start"
                                                                          className="dollar_symbol"
                                                                        >
                                                                          $
                                                                        </InputAdornment>
                                                                      ),
                                                                    }}
                                                                    onChange={
                                                                      this
                                                                        .onChangeHandler
                                                                    }
                                                                  />
                                                                  <Button
                                                                    className="add_more_btn"
                                                                    onClick={() =>
                                                                      this.updateSubscriptionHandler(
                                                                        "isBillingPrice",
                                                                        "region",
                                                                        region._id,
                                                                        region
                                                                          .subscription
                                                                          .type,
                                                                        division[0]
                                                                          ._id
                                                                      )
                                                                    }
                                                                    // disabled={props.isPriceDisable?false:true}
                                                                  >
                                                                    Save
                                                                  </Button>
                                                                  <img
                                                                    src={
                                                                      close_icon_red
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.closeEdit(
                                                                        "isBillingPrice",
                                                                        region._id
                                                                      )
                                                                    }
                                                                  />
                                                                </span>
                                                              )}
                                                            </p>
                                                            <div className="modal-err-msg">
                                                              {this.state
                                                                .subscriptionFormError
                                                                .billingPrice !==
                                                              ""
                                                                ? `* ${this.state.subscriptionFormError.billingPrice}`
                                                                : ""}
                                                            </div>
                                                          </div>
                                                        ) : null}
                                                        <div className="accordion-users">
                                                          Managers -{" "}
                                                          {region
                                                            .employeeDetails
                                                            .length !== 0
                                                            ? region.employeeDetails.map(
                                                                (emp, index) =>
                                                                  emp.role.toLowerCase() ===
                                                                  "team lead"
                                                                    ? region
                                                                        .employeeDetails
                                                                        .length -
                                                                        1 ===
                                                                      index
                                                                      ? emp.name
                                                                      : emp.name +
                                                                        " , "
                                                                    : ""
                                                              )
                                                            : "No managers assigned"}
                                                        </div>
                                                      </div>
                                                    </IconLeftAccordionSummary>
                                                    <AccordionDetails
                                                      className="accord-details accord-territory"
                                                      style={{
                                                        background: "#f2f4fb",
                                                      }}
                                                    >
                                                      {territory === undefined
                                                        ? ""
                                                        : territory.map(
                                                            (
                                                              territory,
                                                              tindex
                                                            ) =>
                                                              territory.area_id ===
                                                                area._id &&
                                                              territory.region_id ===
                                                                region._id ? (
                                                                <div className="accord-padd accord-padd-ext">
                                                                  <Accordion className="accordion-territory">
                                                                    <IconLeftAccordionSummary
                                                                      expandIcon={
                                                                        <img
                                                                          src={
                                                                            ExpandMoreIcon
                                                                          }
                                                                          alt="expand"
                                                                        />
                                                                      }
                                                                      IconButtonProps={{
                                                                        edge:
                                                                          "start",
                                                                      }}
                                                                      aria-controls="panel2a-content"
                                                                      id="panel2a-header"
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                        }}
                                                                      >
                                                                        {this
                                                                          .state
                                                                          .isTerritoryname && (
                                                                          <>
                                                                            <div className="accordion-head menu-icon">
                                                                              {
                                                                                territory.name
                                                                              }
                                                                              <IconButton
                                                                                aria-label="more"
                                                                                aria-controls="long-menu"
                                                                                aria-haspopup="true"
                                                                                onClick={(
                                                                                  event
                                                                                ) =>
                                                                                  this.handleClick(
                                                                                    event,
                                                                                    territory._id,
                                                                                    area._id,
                                                                                    region._id
                                                                                  )
                                                                                }
                                                                              >
                                                                                <MoreVertIcon />
                                                                              </IconButton>
                                                                              <Menu
                                                                                id="long-menu"
                                                                                anchorEl={
                                                                                  this
                                                                                    .state
                                                                                    .anchorEl
                                                                                }
                                                                                keepMounted
                                                                                open={
                                                                                  this
                                                                                    .state
                                                                                    .open
                                                                                }
                                                                                onClose={
                                                                                  this
                                                                                    .handleClose
                                                                                }
                                                                                PaperProps={{
                                                                                  style: {
                                                                                    maxHeight:
                                                                                      48 *
                                                                                      4.5,
                                                                                    width:
                                                                                      "20ch",
                                                                                  },
                                                                                }}
                                                                              >
                                                                                <MenuItem
                                                                                  key={
                                                                                    1
                                                                                  }
                                                                                  onClick={() =>
                                                                                    this.openParentmodal(
                                                                                      "territory",
                                                                                      division[0]
                                                                                        ._id,
                                                                                      area._id,
                                                                                      region._id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Change
                                                                                  parent
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                  key={
                                                                                    2
                                                                                  }
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    this.editLevel(
                                                                                      e,
                                                                                      "territory"
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Edit
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                  key={
                                                                                    3
                                                                                  }
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    this.deleteLevel(
                                                                                      e,
                                                                                      territory._id,
                                                                                      "territory",
                                                                                      division[0]
                                                                                        ._id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Remove
                                                                                </MenuItem>
                                                                              </Menu>
                                                                            </div>
                                                                          </>
                                                                        )}
                                                                        {!this
                                                                          .state
                                                                          .isTerritoryname && (
                                                                          <span className="edit-input-level">
                                                                            {this
                                                                              .state
                                                                              .editId ===
                                                                            territory._id ? (
                                                                              <>
                                                                                <CssTextField
                                                                                  id="outlined-basic"
                                                                                  name="territoryname"
                                                                                  variant="outlined"
                                                                                  type="text"
                                                                                  placeholder="Enter territory name"
                                                                                  className="add-level-input"
                                                                                  defaultValue={
                                                                                    territory.name
                                                                                  }
                                                                                  onChange={
                                                                                    this
                                                                                      .onChangeHandler
                                                                                  }
                                                                                />
                                                                                <Button
                                                                                  className="level_save primary-btn"
                                                                                  variant="contained"
                                                                                  disabled={
                                                                                    this
                                                                                      .state
                                                                                      .territoryname ===
                                                                                    ""
                                                                                      ? true
                                                                                      : false
                                                                                  }
                                                                                  onClick={() =>
                                                                                    this.updateHandler(
                                                                                      "territory",
                                                                                      territory._id,
                                                                                      division[0]
                                                                                        ._id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  Save
                                                                                </Button>
                                                                                <Button
                                                                                  className="level_cancel"
                                                                                  variant="contained"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    e.stopPropagation();
                                                                                    this.setState(
                                                                                      {
                                                                                        isTerritoryname: !this
                                                                                          .state
                                                                                          .isTerritoryname,
                                                                                      }
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  X
                                                                                </Button>
                                                                              </>
                                                                            ) : (
                                                                              <div className="accordion-head">
                                                                                {
                                                                                  territory.name
                                                                                }
                                                                              </div>
                                                                            )}
                                                                          </span>
                                                                        )}
                                                                      </div>
                                                                    </IconLeftAccordionSummary>
                                                                    <AccordionDetails className="accordion-details">
                                                                      <div className="accordion-users">
                                                                        Managers{" "}
                                                                        -{" "}
                                                                        {territory
                                                                          .employeeDetails
                                                                          .length !==
                                                                        0
                                                                          ? territory.employeeDetails.map(
                                                                              (
                                                                                emp,
                                                                                index
                                                                              ) =>
                                                                                emp.role ===
                                                                                "manager"
                                                                                  ? emp.name +
                                                                                    " , "
                                                                                  : ""
                                                                            )
                                                                          : "No managers assigned"}
                                                                      </div>
                                                                      <div className="accordion-users">
                                                                        Sales
                                                                        Rep -{" "}
                                                                        {territory
                                                                          .employeeDetails
                                                                          .length !==
                                                                        0
                                                                          ? territory.employeeDetails.map(
                                                                              (
                                                                                emp,
                                                                                index
                                                                              ) =>
                                                                                emp.role.toLowerCase() ===
                                                                                "sales rep"
                                                                                  ? territory
                                                                                      .employeeDetails
                                                                                      .length -
                                                                                      1 ===
                                                                                    index
                                                                                    ? emp.name
                                                                                    : emp.name +
                                                                                      " , "
                                                                                  : ""
                                                                            )
                                                                          : "No Representative assigned"}
                                                                      </div>
                                                                      {territory
                                                                        .accountDetails
                                                                        .length === 0
                                                                        ?  isHospital && (
                                                                            <Button
                                                                              className="add_level_btn"
                                                                              variant="outlined"
                                                                              onClick={() =>
                                                                                this.addLevel(
                                                                                  "hospital",
                                                                                  territory._id
                                                                                )
                                                                              }
                                                                            >
                                                                              +Add
                                                                              Hospital
                                                                              
                                                                            </Button>
                                                                          )
                                                                        :  territory
                                                                        .accountDetails
                                                                        .length !== 0 && isHospname && (
                                                                            <div className="accordion-users accordion-acts">
                                                                              <span className="accordion-acts-span">
                                                                                Accounts{" "}
                                                                                -{" "}
                                                                                {territory.accountDetails.map(
                                                                                  (
                                                                                    hosp,
                                                                                    index
                                                                                  ) =>
                                                                                    territory
                                                                                      .accountDetails
                                                                                      .length -
                                                                                      1 ===
                                                                                    index
                                                                                      ? hosp.accountName
                                                                                      : hosp.accountName +
                                                                                        " , "
                                                                                )}
                                                                                <img
                                                                                  src={
                                                                                    editIcon
                                                                                  }
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) =>
                                                                                    this.editLevel(
                                                                                      e,
                                                                                      "hospital",
                                                                                      territory._id,
                                                                                      tindex
                                                                                    )
                                                                                  }
                                                                                  alt="edit"
                                                                                />
                                                                              </span>
                                                                            </div>
                                                                          )}
                                                                      {!isHospname &&
                                                                        (this
                                                                          .state
                                                                          .editId ===
                                                                          territory._id &&
                                                                        this
                                                                          .state
                                                                          .tindex ===
                                                                          tindex && territory
                                                                          .accountDetails
                                                                          .length !== 0  ?  (
                                                                          <div className="accordion-users accordion-acts">
                                                                            <span className="accordion-acts-span">
                                                                              Accounts
                                                                              -{" "}
                                                                            </span>
                                                                            <Autocomplete
                                                                              multiple
                                                                              className="cusautocomplete"
                                                                              id="tags-standard"
                                                                              options={
                                                                                this
                                                                                  .state
                                                                                  .accounts
                                                                              }
                                                                              getOptionLabel={(
                                                                                option
                                                                              ) =>
                                                                                option.accountName
                                                                              }
                                                                              onChange={
                                                                                this
                                                                                  .onSelectactHandler
                                                                              }
                                                                              defaultValue={
                                                                                territory.accountDetails
                                                                              }
                                                                              renderTags={(
                                                                                tagValue,
                                                                                getTagProps
                                                                              ) =>
                                                                                tagValue.map(
                                                                                  (
                                                                                    option,
                                                                                    index
                                                                                  ) => (
                                                                                    <Chip
                                                                                      label={
                                                                                        option.accountName
                                                                                      }
                                                                                      {...getTagProps(
                                                                                        {
                                                                                          index,
                                                                                        }
                                                                                      )}
                                                                                      onDelete={() =>
                                                                                        this.handleDelete(
                                                                                          option._id,
                                                                                          tindex,
                                                                                          index,
                                                                                          territory._id
                                                                                        )
                                                                                      }
                                                                                    />
                                                                                  )
                                                                                )
                                                                              }
                                                                              renderInput={(
                                                                                params
                                                                              ) => (
                                                                                <TextField
                                                                                  {...params}
                                                                                  variant="standard"
                                                                                  name="accountsid"
                                                                                  placeholder="Enter Accounts"
                                                                                />
                                                                              )}
                                                                            />
                                                                            <Button
                                                                              className="level_save primary-btn"
                                                                              variant="contained"
                                                                              onClick={() =>
                                                                                this.addAccountHandler(
                                                                                  territory._id,
                                                                                  division[0]
                                                                                    ._id
                                                                                )
                                                                              }
                                                                            >
                                                                              Save
                                                                            </Button>
                                                                            <Button
                                                                              className="level_cancel"
                                                                              variant="contained"
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.stopPropagation();
                                                                                this.setState(
                                                                                  {
                                                                                    isHospname: !this
                                                                                      .state
                                                                                      .isHospname,
                                                                                  }
                                                                                );
                                                                              }}
                                                                            >
                                                                              X
                                                                            </Button>
                                                                          </div>
                                                                        ) :  territory
                                                                        .accountDetails
                                                                        .length !== 0  ? (
                                                                          <div className="accordion-users accordion-acts">
                                                                            <span className="accordion-acts-span">
                                                                              Accounts{" "}
                                                                              -{" "}
                                                                              {territory.accountDetails.map(
                                                                                (
                                                                                  hosp,
                                                                                  index
                                                                                ) =>
                                                                                  territory
                                                                                    .accountDetails
                                                                                    .length -
                                                                                    1 ===
                                                                                  index
                                                                                    ? hosp.accountName
                                                                                    : hosp.accountName +
                                                                                      " , "
                                                                              )}
                                                                              <img
                                                                                src={
                                                                                  editIcon
                                                                                }
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                  marginLeft:
                                                                                    "10px",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  this.editLevel(
                                                                                    e,
                                                                                    "hospital",
                                                                                    territory._id,
                                                                                    tindex
                                                                                  )
                                                                                }
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        ):"")}

                                                                      {territory
                                                                        .accountDetails
                                                                        .length ===
                                                                      0
                                                                        ? !isHospital &&
                                                                          (this
                                                                            .state
                                                                            .editId ===
                                                                          territory._id ? (
                                                                            <div className="accordion-users accordion-acts">
                                                                              <span className="accordion-acts-span">
                                                                                Accounts
                                                                                -{" "}
                                                                              </span>
                                                                              <Autocomplete
                                                                                multiple
                                                                                className="cusautocomplete"
                                                                                id="tags-standard"
                                                                                options={
                                                                                  this
                                                                                    .state
                                                                                    .accounts
                                                                                }
                                                                                getOptionLabel={(
                                                                                  option
                                                                                ) =>
                                                                                  option.accountName
                                                                                }
                                                                                onChange={
                                                                                  this
                                                                                    .onSelectactHandler
                                                                                }
                                                                                renderTags={(
                                                                                  tagValue,
                                                                                  getTagProps
                                                                                ) =>
                                                                                  tagValue.map(
                                                                                    (
                                                                                      option,
                                                                                      index
                                                                                    ) => (
                                                                                      <Chip
                                                                                        label={
                                                                                          option.accountName
                                                                                        }
                                                                                        {...getTagProps(
                                                                                          {
                                                                                            index,
                                                                                          }
                                                                                        )}
                                                                                      />
                                                                                    )
                                                                                  )
                                                                                }
                                                                                renderInput={(
                                                                                  params
                                                                                ) => (
                                                                                  <TextField
                                                                                    {...params}
                                                                                    variant="standard"
                                                                                    name="accountsid"
                                                                                    placeholder="Enter Accounts"
                                                                                  />
                                                                                )}
                                                                              />
                                                                              <Button
                                                                                className="level_save primary-btn"
                                                                                variant="contained"
                                                                                onClick={() =>
                                                                                  this.addAccountHandler(
                                                                                    territory._id,
                                                                                    division[0]
                                                                                      ._id
                                                                                  )
                                                                                }
                                                                              >
                                                                                Save
                                                                              </Button>
                                                                              <Button
                                                                                className="level_cancel"
                                                                                variant="contained"
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.setState(
                                                                                    {
                                                                                      isHospital: !this
                                                                                        .state
                                                                                        .isHospital,
                                                                                    }
                                                                                  );
                                                                                }}
                                                                              >
                                                                                X
                                                                              </Button>
                                                                            </div>
                                                                          ) : (
                                                                            <Button
                                                                              className="add_level_btn"
                                                                              variant="outlined"
                                                                              onClick={() =>
                                                                                this.addLevel(
                                                                                  "hospital",
                                                                                  territory._id
                                                                                )
                                                                              }
                                                                            >
                                                                              +Add
                                                                              Hospital
                                                                            </Button>
                                                                          ))
                                                                        : ""}

                                                                      {/* <div className="accordion-users accordion-acts"> 
                                                                  <span><b>Hospitals</b> - {territory.accountDetails.map((hosp,index)=>
                                                                  hosp.name)}</span></div> */}
                                                                    </AccordionDetails>
                                                                  </Accordion>
                                                                </div>
                                                              ) : (
                                                                ""
                                                              )
                                                          )}
                                                      <div className="accord-padd">
                                                        {isTerritory && (
                                                          <Button
                                                            className="add_level_btn"
                                                            variant="outlined"
                                                            onClick={() =>
                                                              this.addLevel(
                                                                "territory",
                                                                region._id
                                                              )
                                                            }
                                                          >
                                                            +Add Territory
                                                          </Button>
                                                        )}
                                                        {!isTerritory &&
                                                          this.state.editId ===
                                                            region._id && (
                                                            <Accordion
                                                              className="accordion-territory"
                                                              expanded={false}
                                                            >
                                                              <IconLeftAccordionSummary
                                                                expandIcon={
                                                                  <img
                                                                    src={
                                                                      ExpandMoreIcon
                                                                    }
                                                                    alt="expand"
                                                                  />
                                                                }
                                                                IconButtonProps={{
                                                                  edge: "start",
                                                                }}
                                                                aria-controls="panel2a-content"
                                                                id="panel2a-header"
                                                              >
                                                                <div className="accordion-head add-level">
                                                                  <CssTextField
                                                                    id="outlined-basic"
                                                                    name="territoryname"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    placeholder="Enter territory name"
                                                                    className="add-level-input"
                                                                    onChange={
                                                                      this
                                                                        .onChangeHandler
                                                                    }
                                                                  />
                                                                  <Button
                                                                    className="level_save primary-btn"
                                                                    variant="contained"
                                                                    disabled={
                                                                      this.state
                                                                        .territoryname ===
                                                                      ""
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onClick={() =>
                                                                      this.addLevelHandler(
                                                                        "territory",
                                                                        division[0]
                                                                          ._id,
                                                                        area._id,
                                                                        region._id
                                                                      )
                                                                    }
                                                                  >
                                                                    Save
                                                                  </Button>
                                                                </div>
                                                              </IconLeftAccordionSummary>
                                                              <AccordionDetails></AccordionDetails>
                                                            </Accordion>
                                                          )}
                                                      </div>
                                                    </AccordionDetails>
                                                  </Accordion>
                                                </div>
                                              ) : (
                                                ""
                                              )
                                            )}
                                        <div className="accord-padd">
                                          {isRegion && (
                                            <Button
                                              className="add_level_btn"
                                              variant="outlined"
                                              onClick={() =>
                                                this.addLevel(
                                                  "region",
                                                  area._id
                                                )
                                              }
                                            >
                                              +Add Region
                                            </Button>
                                          )}
                                          {!isRegion &&
                                            this.state.editId === area._id && (
                                              <Accordion
                                                expanded={false}
                                                className="accordion-region"
                                              >
                                                <IconLeftAccordionSummary
                                                  expandIcon={
                                                    <img
                                                      src={ExpandMoreIcon}
                                                      alt="expand"
                                                    />
                                                  }
                                                  IconButtonProps={{
                                                    edge: "start",
                                                  }}
                                                  aria-controls="panel2a-content"
                                                  id="panel2a-header"
                                                >
                                                  <div
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="accordion-head add-level">
                                                      <CssTextField
                                                        id="outlined-basic"
                                                        name="regionname"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="Enter region name"
                                                        className="add-level-input"
                                                        onChange={
                                                          this.onChangeHandler
                                                        }
                                                      />
                                                      <div className="add-level-btns">
                                                        <Button
                                                          className="level_save primary-btn"
                                                          variant="contained"
                                                          disabled={
                                                            this.state
                                                              .regionname === ""
                                                              ? true
                                                              : false
                                                          }
                                                          onClick={() =>
                                                            this.addLevelHandler(
                                                              "region",
                                                              division[0]._id,
                                                              area._id
                                                            )
                                                          }
                                                        >
                                                          Save
                                                        </Button>
                                                        <Button
                                                          className="level_cancel"
                                                          variant="contained"
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.setState({
                                                              isRegion: !this
                                                                .state.isRegion,
                                                            });
                                                          }}
                                                        >
                                                          X
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </IconLeftAccordionSummary>
                                                <AccordionDetails></AccordionDetails>
                                              </Accordion>
                                            )}
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                ))}
                            <div className="accord-padd">
                              {isArea && (
                                <Button
                                  className="add_level_btn"
                                  variant="outlined"
                                  onClick={() =>
                                    this.addLevel("area", division[0]._id)
                                  }
                                >
                                  +Add Area
                                </Button>
                              )}
                              {!isArea &&
                                this.state.editId === division[0]._id && (
                                  <Accordion
                                    expanded={false}
                                    className="accordion-area"
                                  >
                                    <IconLeftAccordionSummary
                                      expandIcon={
                                        <img
                                          src={ExpandMoreIcon}
                                          alt="expand"
                                        />
                                      }
                                      IconButtonProps={{
                                        edge: "start",
                                      }}
                                      aria-controls="panel2a-content"
                                      id="panel2a-header"
                                    >
                                      <div style={{ width: "100%" }}>
                                        <div className="accordion-head add-level">
                                          <CssTextField
                                            id="outlined-basic"
                                            name="areaname"
                                            variant="outlined"
                                            type="text"
                                            placeholder="Enter area name"
                                            className="add-level-input"
                                            onChange={this.onChangeHandler}
                                          />
                                          <div className="add-level-btns">
                                            <Button
                                              className="level_save primary-btn"
                                              variant="contained"
                                              disabled={
                                                this.state.areaname === ""
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                this.addLevelHandler(
                                                  "area",
                                                  division[0]._id,
                                                  ""
                                                )
                                              }
                                            >
                                              Save
                                            </Button>
                                            <Button
                                              className="level_cancel"
                                              variant="contained"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({
                                                  isArea: !this.state.isArea,
                                                });
                                              }}
                                            >
                                              X
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </IconLeftAccordionSummary>
                                    <AccordionDetails></AccordionDetails>
                                  </Accordion>
                                )}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )
                  : isDivision && (
                      <div className="no-data-level">
                        Welcome , you can start creating the levels....
                      </div>
                    )}
                {!isDivision && (
                  <Accordion expanded={false}>
                    <IconLeftAccordionSummary
                      expandIcon={<img src={ExpandMoreIcon} alt="expand" />}
                      IconButtonProps={{
                        edge: "start",
                      }}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <div style={{ width: "100%" }}>
                        <div className="accordion-head add-level">
                          <CssTextField
                            id="outlined-basic"
                            name="divisionname"
                            variant="outlined"
                            type="text"
                            placeholder="Enter division name"
                            className="add-level-input"
                            onChange={this.onChangeHandler}
                          />
                          <Button
                            className="level_save primary-btn"
                            variant="contained"
                            disabled={
                              this.state.divisionname === "" ? true : false
                            }
                            onClick={() => this.addLevelHandler("division")}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </IconLeftAccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </>
    );
    let MediumCompany = (
      <div className="account-detail-container">
        <Grid spacing={2} container>
          {loading ? (
            <Grid item xs={12} md={12} className="accordion-load">
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={75}
                width={75}
                visible={loading}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={12} className="accordion-box">
              {area.length !== 0
                ? isAreaEmpty && (
                    <>
                      <Accordion
                        className="accordion-first"
                        style={{ background: "#f2f4fb" }}
                        defaultExpanded={true}
                      >
                        <AccordionDetails className="accord-details-area">
                          {area === undefined
                            ? ""
                            : area.map((area, index) => (
                                <div className="accord-padd accord-padd-ext">
                                  <Accordion
                                    className="accordion-area"
                                    defaultExpanded={index === 0 ? true : false}
                                  >
                                    <IconLeftAccordionSummary
                                      expandIcon={
                                        <img
                                          src={ExpandMoreIcon}
                                          alt="expand"
                                        />
                                      }
                                      IconButtonProps={{ edge: "start" }}
                                      aria-controls="panel2a-content"
                                      id="panel2a-header"
                                    >
                                      <div style={{ width: "100%" }}>
                                        {this.state.isAreaname && (
                                          <>
                                            <div className="accordion-head menu-icon">
                                              {area.name}

                                              <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onMouseEnter={() =>
                                                  this.checkSubscriptionLevel(
                                                    "area",
                                                    area._id
                                                  )
                                                }
                                                onClick={(event) =>
                                                  this.handleClickarea(
                                                    event,
                                                    area._id
                                                  )
                                                }
                                              >
                                                <MoreVertIcon />
                                              </IconButton>
                                              <Menu
                                                id="long-menu"
                                                anchorEl={
                                                  this.state.anchorElarea
                                                }
                                                keepMounted
                                                open={this.state.openarea}
                                                onClose={this.handleClosearea}
                                                PaperProps={{
                                                  style: {
                                                    maxHeight: 48 * 4.5,
                                                  },
                                                }}
                                              >
                                                {/* <MenuItem
                                              key={1}
                                              onClick={() =>
                                                this.openParentmodal(
                                                  "area",
                                                  division[0]._id
                                                )
                                              }
                                            >
                                              Change parent
                                          </MenuItem> */}
                                                <MenuItem
                                                  key="area"
                                                  onClick={(e) =>
                                                    this.editLevel(e, "area")
                                                  }
                                                >
                                                  Edit
                                                </MenuItem>
                                                <MenuItem
                                                  key={3}
                                                  onClick={(e) =>
                                                    this.deleteLevel(
                                                      e,
                                                      area._id,
                                                      "area",
                                                      division[0]._id
                                                    )
                                                  }
                                                >
                                                  Remove
                                                </MenuItem>
                                                {this.state.showSubscribe ? (
                                                  <MenuItem
                                                    key={3}
                                                    onClick={() =>
                                                      this.openSubscriptionLevelModal(
                                                        "area",
                                                        area._id
                                                      )
                                                    }
                                                  >
                                                    Add Subscription level
                                                  </MenuItem>
                                                ) : (
                                                  ""
                                                )}
                                              </Menu>
                                            </div>
                                          </>
                                        )}
                                        {!this.state.isAreaname && (
                                          <span className="edit-input-level">
                                            {this.state.editId === area._id ? (
                                              <>
                                                <CssTextField
                                                  id="outlined-basic"
                                                  name="areaname"
                                                  variant="outlined"
                                                  type="text"
                                                  placeholder="Enter area name"
                                                  className="add-level-input"
                                                  defaultValue={area.name}
                                                  onChange={
                                                    this.onChangeHandler
                                                  }
                                                />
                                                <Button
                                                  className="level_save primary-btn"
                                                  variant="contained"
                                                  disabled={
                                                    this.state.areaname === ""
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() =>
                                                    this.updateHandler(
                                                      "area",
                                                      area._id,
                                                      division[0]._id
                                                    )
                                                  }
                                                >
                                                  Save
                                                </Button>
                                                <Button
                                                  className="level_cancel"
                                                  variant="contained"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                      isAreaname: !this.state
                                                        .isAreaname,
                                                    });
                                                  }}
                                                >
                                                  X
                                                </Button>
                                              </>
                                            ) : (
                                              <div className="accordion-head">
                                                {area.name}
                                              </div>
                                            )}
                                          </span>
                                        )}
                                        {area.subscriptionStatus ? (
                                          <div className="add_company_dialog accordion-users billing_detail_item">
                                            <p
                                              onMouseEnter={() =>
                                                this.showEditIcon(
                                                  "billingTypeEdit",
                                                  area._id
                                                )
                                              }
                                              onMouseLeave={() =>
                                                this.hideEditIcon(
                                                  "billingTypeEdit",
                                                  area._id
                                                )
                                              }
                                            >
                                              Billing Type :
                                              {this.state.isBillingType ? (
                                                <>
                                                  <span>
                                                    {area.subscription.type ===
                                                    1
                                                      ? "Organizational"
                                                      : area.subscription
                                                          .type === 2
                                                      ? "Team"
                                                      : area.subscription
                                                          .type === 3
                                                      ? "Individual"
                                                      : "-"}
                                                      {this.state.billingTypeEdit &&
                                                  this.state.editIds ===
                                                    area._id ? (
                                                    <img
                                                      src={editIcon}
                                                      alt="edit"
                                                      style={{
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                      }}
                                                      onClick={() =>
                                                        this.editInput(
                                                          "isBillingType",
                                                          area._id,
                                                          "subscriptionTypeEdit",
                                                          area.subscription
                                                            .type,
                                                          area.subscription
                                                            .startDate,
                                                          area.subscription
                                                            .endDate
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  </span>
                                                </>
                                              ) : (
                                                <span className="edit-input">
                                                  <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="dense"
                                                  >
                                                    <Select
                                                      margin="dense"
                                                      className="customSelect"
                                                      placeholder="Select billing type"
                                                      name="subscriptionTypeEdit"
                                                      fullWidth
                                                      onChange={
                                                        this.onChangeHandler
                                                      }
                                                      value={
                                                        this.state
                                                          .subscriptionTypeEdit ===
                                                          2 ||
                                                        this.state
                                                          .subscriptionTypeEdit ===
                                                          "2"
                                                          ? "2"
                                                          : this.state
                                                              .subscriptionTypeEdit ===
                                                              3 ||
                                                            this.state
                                                              .subscriptionTypeEdit ===
                                                              "3"
                                                          ? "3"
                                                          : ""
                                                      }
                                                    >
                                                      <MenuItem value="2">
                                                        Team
                                                      </MenuItem>
                                                      <MenuItem value="3">
                                                        Individual
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                  <Button
                                                    className="add_more_btn"
                                                    onClick={() =>
                                                      this.updateSubscriptionHandler(
                                                        "isBillingType",
                                                        "area",
                                                        area._id,
                                                        area.subscription.price,
                                                        division[0]._id
                                                      )
                                                    }
                                                    //disabled={props.billingTypeDisable?false:true}
                                                  >
                                                    Save
                                                  </Button>
                                                  <img
                                                    src={close_icon_red}
                                                    alt=""
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.closeEdit(
                                                        "isBillingType",
                                                        area._id
                                                      )
                                                    }
                                                  />
                                                </span>
                                              )}
                                            </p>
                                            <p
                                            className="billing-period"
                                              onMouseEnter={() =>
                                                this.showEditIcon(
                                                  "billingPeriodEdit",
                                                  area._id
                                                )
                                              }
                                              onMouseLeave={() =>
                                                this.hideEditIcon(
                                                  "billingPeriodEdit",
                                                  area._id
                                                )
                                              }
                                            >
                                              Trial Period :
                                              {this.state.isBillingPeriod ? (
                                                <>
                                                  <span>
                                                    {momentTimeZone(
                                                      area.subscription
                                                        .startDate
                                                    )
                                                      .tz(
                                                        this.state.timeZoneName
                                                      )
                                                      .format(
                                                        "MM-DD-YYYY"
                                                      )}{" "}
                                                    to{" "}
                                                    {momentTimeZone(
                                                      area.subscription.endDate
                                                    )
                                                      .tz(
                                                        this.state.timeZoneName
                                                      )
                                                      .format("MM-DD-YYYY")}
                                                      {this.state
                                                    .billingPeriodEdit &&
                                                  this.state.editIds ===
                                                    area._id ? (
                                                    <img
                                                      src={editIcon}
                                                      alt="edit"
                                                      style={{
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                      }}
                                                      onClick={() =>
                                                        this.editPeriodInput(
                                                          "isBillingPeriod",
                                                          area._id,
                                                          area.subscription
                                                            .startDate,
                                                          area.subscription
                                                            .endDate
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  </span>
                                                </>
                                              ) : (
                                                <span className="edit-input">
                                                  <div className="trail_field">
                                                    <RangePicker
                                                      className="filter_date_range "
                                                      name="datepicker"
                                                      format="MM/DD/YYYY"
                                                      allowClear={false}
                                                      inputReadOnly={true}
                                                      separator="to"
                                                      value={[
                                                        moment(
                                                          this.state
                                                            .subscriptionTrialEnds[0],
                                                          "MM/DD/YYYY"
                                                        ),
                                                        moment(
                                                          this.state
                                                            .subscriptionTrialEnds[1],
                                                          "MM/DD/YYYY"
                                                        ),
                                                      ]}
                                                      disabledDate={this.valid}
                                                      placeholder={[
                                                        "MM/DD/YYYY",
                                                        "MM/DD/YYYY",
                                                      ]}
                                                      suffixIcon={
                                                        <img
                                                          className="carret"
                                                          alt="carret"
                                                          src={carret_down}
                                                        />
                                                      }
                                                      getPopupContainer={(
                                                        node
                                                      ) => node.parentNode}
                                                      onChange={
                                                        this
                                                          .onDateRangePeriodChanage
                                                      }
                                                    />
                                                    {/* <span className="remaining_days">{this.state.subscriptionLevelTrialDays?this.state.subscriptionLevelTrialDays:0} days</span> */}
                                                  </div>
                                                  <span>
                                                    <Button
                                                      className="add_more_btn"
                                                      onClick={() =>
                                                        this.updateSubscriptionPeriodHandler(
                                                          "area",
                                                          area._id,
                                                          area.subscription
                                                            .type,
                                                          area.subscription
                                                            .price,
                                                          division[0]._id
                                                        )
                                                      }
                                                      // disabled={props.billingsDatesDisable?false:true}
                                                    >
                                                      Save
                                                    </Button>
                                                    <img
                                                      src={close_icon_red}
                                                      alt="close"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.closeEdit(
                                                          "isBillingPeriod",
                                                          area._id
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                </span>
                                              )}
                                            </p>
                                            <p
                                              onMouseEnter={() =>
                                                this.showEditIcon(
                                                  "billingPriceEdit",
                                                  area._id
                                                )
                                              }
                                              onMouseLeave={() =>
                                                this.hideEditIcon(
                                                  "billingPriceEdit",
                                                  area._id
                                                )
                                              }
                                            >
                                              Billing Price/User :
                                              {this.state.isBillingPrice ? (
                                                <>
                                                  <span>
                                                    ${" "}
                                                    {parseFloat(
                                                      area.subscription.price
                                                    )}
                                                     {this.state
                                                    .billingPriceEdit &&
                                                  this.state.editIds ===
                                                    area._id ? (
                                                    <img
                                                      src={editIcon}
                                                      alt="edit"
                                                      style={{
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                      }}
                                                      onClick={() =>
                                                        this.editInput(
                                                          "isBillingPrice",
                                                          area._id,
                                                          "subscriptionEditPrice",
                                                          area.subscription
                                                            .price,
                                                          area.subscription
                                                            .startDate,
                                                          area.subscription
                                                            .endDate
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  </span>
                                                </>
                                              ) : (
                                                <span className="edit-input">
                                                  <CssTextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    name="subscriptionEditPrice"
                                                    className="comp_name"
                                                    autoFocus
                                                    placeholder="Enter price per user"
                                                    type="text"
                                                    value={
                                                      this.state
                                                        .subscriptionEditPrice
                                                    }
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment
                                                          position="start"
                                                          className="dollar_symbol"
                                                        >
                                                          $
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    onChange={
                                                      this.onChangeHandler
                                                    }
                                                  />
                                                  <Button
                                                    className="add_more_btn"
                                                    onClick={() =>
                                                      this.updateSubscriptionHandler(
                                                        "isBillingPrice",
                                                        "area",
                                                        area._id,
                                                        area.subscription.type,
                                                        division[0]._id
                                                      )
                                                    }
                                                    // disabled={props.isPriceDisable?false:true}
                                                  >
                                                    Save
                                                  </Button>
                                                  <img
                                                    src={close_icon_red}
                                                    alt=""
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.closeEdit(
                                                        "isBillingPrice",
                                                        area._id
                                                      )
                                                    }
                                                  />
                                                </span>
                                              )}
                                            </p>
                                            <div className="modal-err-msg">
                                              {this.state.subscriptionFormError
                                                .billingPrice !== ""
                                                ? `* ${this.state.subscriptionFormError.billingPrice}`
                                                : ""}
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="accordion-users">
                                          Managers -{" "}
                                          {area.employeeDetails.length !== 0
                                            ? area.employeeDetails.map(
                                                (emp, index) =>
                                                  area.employeeDetails.length -
                                                    1 ===
                                                  index
                                                    ? emp.name
                                                    : emp.name + " , "
                                              )
                                            : "No managers assigned"}
                                        </div>
                                      </div>
                                    </IconLeftAccordionSummary>
                                    <AccordionDetails
                                      className="accord-details"
                                      style={{ background: "#f2f4fb" }}
                                    >
                                      {region === undefined
                                        ? ""
                                        : region.map((region, rindex) =>
                                            region.area_id === area._id ? (
                                              <div className="accord-padd accord-padd-ext">
                                                <Accordion className="accordion-region">
                                                  <IconLeftAccordionSummary
                                                    expandIcon={
                                                      <img
                                                        src={ExpandMoreIcon}
                                                        alt="expand"
                                                      />
                                                    }
                                                    IconButtonProps={{
                                                      edge: "start",
                                                    }}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                  >
                                                    <div
                                                      style={{ width: "100%" }}
                                                    >
                                                      {this.state
                                                        .isRegionname && (
                                                        <>
                                                          <div className="accordion-head menu-icon">
                                                            {region.name}
                                                            <IconButton
                                                              aria-label="more"
                                                              aria-controls="long-menu"
                                                              aria-haspopup="true"
                                                              onMouseEnter={() =>
                                                                this.checkSubscriptionLevel(
                                                                  "region",
                                                                  region._id
                                                                )
                                                              }
                                                              onClick={(
                                                                event
                                                              ) =>
                                                                this.handleClickregion(
                                                                  event,
                                                                  region._id,
                                                                  area._id
                                                                )
                                                              }
                                                            >
                                                              <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                              id="long-menu"
                                                              anchorEl={
                                                                this.state
                                                                  .anchorElregion
                                                              }
                                                              keepMounted
                                                              open={
                                                                this.state
                                                                  .openregion
                                                              }
                                                              onClose={
                                                                this
                                                                  .handleCloseregion
                                                              }
                                                              PaperProps={{
                                                                style: {
                                                                  maxHeight:
                                                                    48 * 4.5,
                                                                },
                                                              }}
                                                            >
                                                              <MenuItem
                                                                key={1}
                                                                onClick={() =>
                                                                  this.openParentmodal(
                                                                    "region",
                                                                    division[0]
                                                                      ._id,
                                                                    area._id
                                                                  )
                                                                }
                                                              >
                                                                Change parent
                                                              </MenuItem>
                                                              <MenuItem
                                                                key="region"
                                                                data-id={rindex}
                                                                onClick={(e) =>
                                                                  this.editLevel(
                                                                    e,
                                                                    "region"
                                                                  )
                                                                }
                                                              >
                                                                Edit
                                                              </MenuItem>
                                                              <MenuItem
                                                                key={3}
                                                                onClick={(e) =>
                                                                  this.deleteLevel(
                                                                    e,
                                                                    region._id,
                                                                    "region",
                                                                    division[0]
                                                                      ._id
                                                                  )
                                                                }
                                                              >
                                                                Remove
                                                              </MenuItem>
                                                              {this.state
                                                                .showSubscribe ? (
                                                                <MenuItem
                                                                  key={3}
                                                                  onClick={() =>
                                                                    this.openSubscriptionLevelModal(
                                                                      "region",
                                                                      region._id
                                                                    )
                                                                  }
                                                                >
                                                                  Add
                                                                  Subscription
                                                                  level
                                                                </MenuItem>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </Menu>
                                                          </div>
                                                        </>
                                                      )}
                                                      {!this.state
                                                        .isRegionname && (
                                                        <span className="edit-input-level">
                                                          {this.state.editId ===
                                                          region._id ? (
                                                            <>
                                                              <CssTextField
                                                                id="outlined-basic"
                                                                name="regionname"
                                                                variant="outlined"
                                                                type="text"
                                                                placeholder="Enter region name"
                                                                className="add-level-input"
                                                                defaultValue={
                                                                  region.name
                                                                }
                                                                onChange={
                                                                  this
                                                                    .onChangeHandler
                                                                }
                                                              />
                                                              <Button
                                                                className="level_save primary-btn"
                                                                disabled={
                                                                  this.state
                                                                    .regionname ===
                                                                  ""
                                                                    ? true
                                                                    : false
                                                                }
                                                                variant="contained"
                                                                onClick={() =>
                                                                  this.updateHandler(
                                                                    "region",
                                                                    region._id,
                                                                    division[0]
                                                                      ._id
                                                                  )
                                                                }
                                                              >
                                                                Save
                                                              </Button>
                                                              <Button
                                                                className="level_cancel"
                                                                variant="contained"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.stopPropagation();
                                                                  this.setState(
                                                                    {
                                                                      isRegionname: !this
                                                                        .state
                                                                        .isRegionname,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                X
                                                              </Button>
                                                            </>
                                                          ) : (
                                                            <div className="accordion-head">
                                                              {region.name}
                                                            </div>
                                                          )}
                                                        </span>
                                                      )}

                                                      {region.subscriptionStatus ? (
                                                        <div className="add_company_dialog accordion-users billing_detail_item">
                                                          <p
                                                            onMouseEnter={() =>
                                                              this.showEditIcon(
                                                                "billingTypeEdit",
                                                                region._id
                                                              )
                                                            }
                                                            onMouseLeave={() =>
                                                              this.hideEditIcon(
                                                                "billingTypeEdit",
                                                                region._id
                                                              )
                                                            }
                                                          >
                                                            Billing Type :
                                                            {this.state
                                                              .isBillingType ? (
                                                              <>
                                                                <span>
                                                                  {region
                                                                    .subscription
                                                                    .type === 1
                                                                    ? "Organizational"
                                                                    : region
                                                                        .subscription
                                                                        .type ===
                                                                      2
                                                                    ? "Team"
                                                                    : region
                                                                        .subscription
                                                                        .type ===
                                                                      3
                                                                    ? "Individual"
                                                                    : "-"}
                                                                    {this.state
                                                                  .billingTypeEdit &&
                                                                this.state
                                                                  .editIds ===
                                                                  region._id ? (
                                                                  <img
                                                                    src={
                                                                      editIcon
                                                                    }
                                                                    alt="edit"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      marginLeft:
                                                                        "10px",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.editInput(
                                                                        "isBillingType",
                                                                        region._id,
                                                                        "subscriptionTypeEdit",
                                                                        region
                                                                          .subscription
                                                                          .type,
                                                                        region
                                                                          .subscription
                                                                          .startDate,
                                                                        region
                                                                          .subscription
                                                                          .endDate
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                                </span>
                                                              </>
                                                            ) : (
                                                              <span className="edit-input">
                                                                <FormControl
                                                                  variant="outlined"
                                                                  fullWidth
                                                                  margin="dense"
                                                                >
                                                                  <Select
                                                                    margin="dense"
                                                                    className="customSelect"
                                                                    placeholder="Select billing type"
                                                                    name="subscriptionTypeEdit"
                                                                    fullWidth
                                                                    onChange={
                                                                      this
                                                                        .onChangeHandler
                                                                    }
                                                                    value={
                                                                      this.state
                                                                        .subscriptionTypeEdit ===
                                                                        2 ||
                                                                      this.state
                                                                        .subscriptionTypeEdit ===
                                                                        "2"
                                                                        ? "2"
                                                                        : this
                                                                            .state
                                                                            .subscriptionTypeEdit ===
                                                                            3 ||
                                                                          this
                                                                            .state
                                                                            .subscriptionTypeEdit ===
                                                                            "3"
                                                                        ? "3"
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <MenuItem value="2">
                                                                      Team
                                                                    </MenuItem>
                                                                    <MenuItem value="3">
                                                                      Individual
                                                                    </MenuItem>
                                                                  </Select>
                                                                </FormControl>
                                                                <Button
                                                                  className="add_more_btn"
                                                                  onClick={() =>
                                                                    this.updateSubscriptionHandler(
                                                                      "isBillingType",
                                                                      "region",
                                                                      region._id,
                                                                      region
                                                                        .subscription
                                                                        .price,
                                                                      division[0]
                                                                        ._id
                                                                    )
                                                                  }
                                                                  //disabled={props.billingTypeDisable?false:true}
                                                                >
                                                                  Save
                                                                </Button>
                                                                <img
                                                                  src={
                                                                    close_icon_red
                                                                  }
                                                                  alt=""
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    this.closeEdit(
                                                                      "isBillingType",
                                                                      region._id
                                                                    )
                                                                  }
                                                                />
                                                              </span>
                                                            )}
                                                          </p>
                                                          <p
                                                          className="billing-period"
                                                            onMouseEnter={() =>
                                                              this.showEditIcon(
                                                                "billingPeriodEdit",
                                                                region._id
                                                              )
                                                            }
                                                            onMouseLeave={() =>
                                                              this.hideEditIcon(
                                                                "billingPeriodEdit",
                                                                region._id
                                                              )
                                                            }
                                                          >
                                                            Trial Period :
                                                            {this.state
                                                              .isBillingPeriod ? (
                                                              <>
                                                                <span>
                                                                  {momentTimeZone(
                                                                    region
                                                                      .subscription
                                                                      .startDate
                                                                  )
                                                                    .tz(
                                                                      this.state
                                                                        .timeZoneName
                                                                    )
                                                                    .format(
                                                                      "MM-DD-YYYY"
                                                                    )}{" "}
                                                                  to{" "}
                                                                  {momentTimeZone(
                                                                    region
                                                                      .subscription
                                                                      .endDate
                                                                  )
                                                                    .tz(
                                                                      this.state
                                                                        .timeZoneName
                                                                    )
                                                                    .format(
                                                                      "MM-DD-YYYY"
                                                                    )}
                                                                    {this.state
                                                                  .billingPeriodEdit &&
                                                                this.state
                                                                  .editIds ===
                                                                  region._id ? (
                                                                  <img
                                                                    src={
                                                                      editIcon
                                                                    }
                                                                    alt="edit"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      marginLeft:
                                                                        "10px",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.editPeriodInput(
                                                                        "isBillingPeriod",
                                                                        region._id,
                                                                        region
                                                                          .subscription
                                                                          .startDate,
                                                                        region
                                                                          .subscription
                                                                          .endDate
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                                </span>
                                                              </>
                                                            ) : (
                                                              <span className="edit-input">
                                                                <div className="trail_field">
                                                                  <RangePicker
                                                                    className="filter_date_range "
                                                                    name="datepicker"
                                                                    format="MM/DD/YYYY"
                                                                    allowClear={
                                                                      false
                                                                    }
                                                                    inputReadOnly={
                                                                      true
                                                                    }
                                                                    separator="to"
                                                                    value={[
                                                                      moment(
                                                                        this
                                                                          .state
                                                                          .subscriptionTrialEnds[0],
                                                                        "MM/DD/YYYY"
                                                                      ),
                                                                      moment(
                                                                        this
                                                                          .state
                                                                          .subscriptionTrialEnds[1],
                                                                        "MM/DD/YYYY"
                                                                      ),
                                                                    ]}
                                                                    disabledDate={
                                                                      this.valid
                                                                    }
                                                                    placeholder={[
                                                                      "MM/DD/YYYY",
                                                                      "MM/DD/YYYY",
                                                                    ]}
                                                                    suffixIcon={
                                                                      <img
                                                                        className="carret"
                                                                        alt="carret"
                                                                        src={
                                                                          carret_down
                                                                        }
                                                                      />
                                                                    }
                                                                    getPopupContainer={(
                                                                      node
                                                                    ) =>
                                                                      node.parentNode
                                                                    }
                                                                    onChange={
                                                                      this
                                                                        .onDateRangePeriodChanage
                                                                    }
                                                                  />
                                                                  {/* <span className="remaining_days">{this.state.subscriptionLevelTrialDays?this.state.subscriptionLevelTrialDays:0} days</span> */}
                                                                </div>
                                                                <span>
                                                                  <Button
                                                                    className="add_more_btn"
                                                                    onClick={() =>
                                                                      this.updateSubscriptionPeriodHandler(
                                                                        "region",
                                                                        region._id,
                                                                        region
                                                                          .subscription
                                                                          .type,
                                                                        region
                                                                          .subscription
                                                                          .price,
                                                                        division[0]
                                                                          ._id
                                                                      )
                                                                    }
                                                                    // disabled={props.billingsDatesDisable?false:true}
                                                                  >
                                                                    Save
                                                                  </Button>
                                                                  <img
                                                                    src={
                                                                      close_icon_red
                                                                    }
                                                                    alt="close"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.closeEdit(
                                                                        "isBillingPeriod",
                                                                        region._id
                                                                      )
                                                                    }
                                                                  />
                                                                </span>
                                                              </span>
                                                            )}
                                                          </p>
                                                          <p
                                                            onMouseEnter={() =>
                                                              this.showEditIcon(
                                                                "billingPriceEdit",
                                                                region._id
                                                              )
                                                            }
                                                            onMouseLeave={() =>
                                                              this.hideEditIcon(
                                                                "billingPriceEdit",
                                                                region._id
                                                              )
                                                            }
                                                          >
                                                            Billing Price/User :
                                                            {this.state
                                                              .isBillingPrice ? (
                                                              <>
                                                                <span>
                                                                  ${" "}
                                                                  {parseFloat(
                                                                    region
                                                                      .subscription
                                                                      .price
                                                                  )}
                                                                   {this.state
                                                                  .billingPriceEdit &&
                                                                this.state
                                                                  .editIds ===
                                                                  region._id ? (
                                                                  <img
                                                                    src={
                                                                      editIcon
                                                                    }
                                                                    alt="edit"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      marginLeft:
                                                                        "10px",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.editInput(
                                                                        "isBillingPrice",
                                                                        region._id,
                                                                        "subscriptionEditPrice",
                                                                        region
                                                                          .subscription
                                                                          .price,
                                                                        region
                                                                          .subscription
                                                                          .startDate,
                                                                        region
                                                                          .subscription
                                                                          .endDate
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                                </span>
                                                              </>
                                                            ) : (
                                                              <span className="edit-input">
                                                                <CssTextField
                                                                  id="outlined-basic"
                                                                  variant="outlined"
                                                                  name="subscriptionEditPrice"
                                                                  className="comp_name"
                                                                  autoFocus
                                                                  placeholder="Enter price per user"
                                                                  type="text"
                                                                  value={
                                                                    this.state
                                                                      .subscriptionEditPrice
                                                                  }
                                                                  InputProps={{
                                                                    startAdornment: (
                                                                      <InputAdornment
                                                                        position="start"
                                                                        className="dollar_symbol"
                                                                      >
                                                                        $
                                                                      </InputAdornment>
                                                                    ),
                                                                  }}
                                                                  onChange={
                                                                    this
                                                                      .onChangeHandler
                                                                  }
                                                                />
                                                                <Button
                                                                  className="add_more_btn"
                                                                  onClick={() =>
                                                                    this.updateSubscriptionHandler(
                                                                      "isBillingPrice",
                                                                      "region",
                                                                      region._id,
                                                                      region
                                                                        .subscription
                                                                        .type,
                                                                      division[0]
                                                                        ._id
                                                                    )
                                                                  }
                                                                  // disabled={props.isPriceDisable?false:true}
                                                                >
                                                                  Save
                                                                </Button>
                                                                <img
                                                                  src={
                                                                    close_icon_red
                                                                  }
                                                                  alt=""
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    this.closeEdit(
                                                                      "isBillingPrice",
                                                                      region._id
                                                                    )
                                                                  }
                                                                />
                                                              </span>
                                                            )}
                                                          </p>
                                                          <div className="modal-err-msg">
                                                            {this.state
                                                              .subscriptionFormError
                                                              .billingPrice !==
                                                            ""
                                                              ? `* ${this.state.subscriptionFormError.billingPrice}`
                                                              : ""}
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      <div className="accordion-users">
                                                        Managers -{" "}
                                                        {region.employeeDetails
                                                          .length !== 0
                                                          ? region.employeeDetails.map(
                                                              (emp, index) =>
                                                                emp.role.toLowerCase() ===
                                                                "team lead"
                                                                  ? region
                                                                      .employeeDetails
                                                                      .length -
                                                                      1 ===
                                                                    index
                                                                    ? emp.name
                                                                    : emp.name +
                                                                      " , "
                                                                  : ""
                                                            )
                                                          : "No managers assigned"}
                                                      </div>
                                                    </div>
                                                  </IconLeftAccordionSummary>
                                                  <AccordionDetails
                                                    className="accord-details accord-territory"
                                                    style={{
                                                      background: "#f2f4fb",
                                                    }}
                                                  >
                                                    {territory === undefined
                                                      ? ""
                                                      : territory.map(
                                                          (territory, tindex) =>
                                                            territory.area_id ===
                                                              area._id &&
                                                            territory.region_id ===
                                                              region._id ? (
                                                              <div className="accord-padd accord-padd-ext">
                                                                <Accordion className="accordion-territory">
                                                                  <IconLeftAccordionSummary
                                                                    expandIcon={
                                                                      <img
                                                                        src={
                                                                          ExpandMoreIcon
                                                                        }
                                                                      ></img>
                                                                    }
                                                                    IconButtonProps={{
                                                                      edge:
                                                                        "start",
                                                                    }}
                                                                    aria-controls="panel2a-content"
                                                                    id="panel2a-header"
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                      }}
                                                                    >
                                                                      {this
                                                                        .state
                                                                        .isTerritoryname && (
                                                                        <>
                                                                          <div className="accordion-head menu-icon">
                                                                            {
                                                                              territory.name
                                                                            }
                                                                            <IconButton
                                                                              aria-label="more"
                                                                              aria-controls="long-menu"
                                                                              aria-haspopup="true"
                                                                              onClick={(
                                                                                event
                                                                              ) =>
                                                                                this.handleClick(
                                                                                  event,
                                                                                  territory._id,
                                                                                  area._id,
                                                                                  region._id
                                                                                )
                                                                              }
                                                                            >
                                                                              <MoreVertIcon />
                                                                            </IconButton>
                                                                            <Menu
                                                                              id="long-menu"
                                                                              anchorEl={
                                                                                this
                                                                                  .state
                                                                                  .anchorEl
                                                                              }
                                                                              keepMounted
                                                                              open={
                                                                                this
                                                                                  .state
                                                                                  .open
                                                                              }
                                                                              onClose={
                                                                                this
                                                                                  .handleClose
                                                                              }
                                                                              PaperProps={{
                                                                                style: {
                                                                                  maxHeight:
                                                                                    48 *
                                                                                    4.5,
                                                                                  width:
                                                                                    "20ch",
                                                                                },
                                                                              }}
                                                                            >
                                                                              <MenuItem
                                                                                key={
                                                                                  1
                                                                                }
                                                                                onClick={() =>
                                                                                  this.openParentmodal(
                                                                                    "territory",
                                                                                    division[0]
                                                                                      ._id,
                                                                                    area._id,
                                                                                    region._id
                                                                                  )
                                                                                }
                                                                              >
                                                                                Change
                                                                                parent
                                                                              </MenuItem>
                                                                              <MenuItem
                                                                                key={
                                                                                  2
                                                                                }
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  this.editLevel(
                                                                                    e,
                                                                                    "territory"
                                                                                  )
                                                                                }
                                                                              >
                                                                                Edit
                                                                              </MenuItem>
                                                                              <MenuItem
                                                                                key={
                                                                                  3
                                                                                }
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  this.deleteLevel(
                                                                                    e,
                                                                                    territory._id,
                                                                                    "territory",
                                                                                    division[0]
                                                                                      ._id
                                                                                  )
                                                                                }
                                                                              >
                                                                                Remove
                                                                              </MenuItem>
                                                                              {/* <MenuItem 
                                                                          key={3}
                                                                          onClick={()=>this.openSubscriptionLevelModal(territory._id,division[0]._id)}
                                                                        >
                                                                          Add Subscription level
                                                                        </MenuItem> */}
                                                                            </Menu>
                                                                          </div>
                                                                        </>
                                                                      )}
                                                                      {!this
                                                                        .state
                                                                        .isTerritoryname && (
                                                                        <span className="edit-input-level">
                                                                          {this
                                                                            .state
                                                                            .editId ===
                                                                          territory._id ? (
                                                                            <>
                                                                              <CssTextField
                                                                                id="outlined-basic"
                                                                                name="territoryname"
                                                                                variant="outlined"
                                                                                type="text"
                                                                                placeholder="Enter territory name"
                                                                                className="add-level-input"
                                                                                defaultValue={
                                                                                  territory.name
                                                                                }
                                                                                onChange={
                                                                                  this
                                                                                    .onChangeHandler
                                                                                }
                                                                              />
                                                                              <Button
                                                                                className="level_save primary-btn"
                                                                                variant="contained"
                                                                                disabled={
                                                                                  this
                                                                                    .state
                                                                                    .territoryname ===
                                                                                  ""
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                onClick={() =>
                                                                                  this.updateHandler(
                                                                                    "territory",
                                                                                    territory._id,
                                                                                    division[0]
                                                                                      ._id
                                                                                  )
                                                                                }
                                                                              >
                                                                                Save
                                                                              </Button>
                                                                              <Button
                                                                                className="level_cancel"
                                                                                variant="contained"
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.setState(
                                                                                    {
                                                                                      isTerritoryname: !this
                                                                                        .state
                                                                                        .isTerritoryname,
                                                                                    }
                                                                                  );
                                                                                }}
                                                                              >
                                                                                X
                                                                              </Button>
                                                                            </>
                                                                          ) : (
                                                                            <div className="accordion-head">
                                                                              {
                                                                                territory.name
                                                                              }
                                                                            </div>
                                                                          )}
                                                                        </span>
                                                                      )}
                                                                    </div>
                                                                  </IconLeftAccordionSummary>
                                                                  <AccordionDetails className="accordion-details">
                                                                    <div className="accordion-users">
                                                                      Managers -{" "}
                                                                      {territory
                                                                        .employeeDetails
                                                                        .length !==
                                                                      0
                                                                        ? territory.employeeDetails.map(
                                                                            (
                                                                              emp,
                                                                              index
                                                                            ) =>
                                                                              emp.role ===
                                                                              "manager"
                                                                                ? emp.name +
                                                                                  " , "
                                                                                : ""
                                                                          )
                                                                        : "No managers assigned"}
                                                                    </div>
                                                                    <div className="accordion-users">
                                                                    Representative{" "}
                                                                      -{" "}
                                                                      {territory
                                                                        .employeeDetails
                                                                        .length !==
                                                                      0
                                                                        ? territory.employeeDetails.map(
                                                                            (
                                                                              emp,
                                                                              index
                                                                            ) =>
                                                                              emp.role.toLowerCase() ===
                                                                              "sales rep"
                                                                                ? territory
                                                                                    .employeeDetails
                                                                                    .length -
                                                                                    1 ===
                                                                                  index
                                                                                  ? emp.name
                                                                                  : emp.name +
                                                                                    " , "
                                                                                : ""
                                                                          )
                                                                        : "No Representative assigned"}
                                                                    </div>
                                                                    {territory
                                                                      .accountDetails
                                                                      .length ===
                                                                    0
                                                                      ? isHospital && (
                                                                          <Button
                                                                            className="add_level_btn"
                                                                            variant="outlined"
                                                                            onClick={() =>
                                                                              this.addLevel(
                                                                                "hospital",
                                                                                territory._id
                                                                              )
                                                                            }
                                                                          >
                                                                            +Add
                                                                            Hospital
                                                                          </Button>
                                                                        )
                                                                      : isHospname && (
                                                                          <div className="accordion-users accordion-acts">
                                                                            <span className="accordion-acts-span">
                                                                              Accounts{" "}
                                                                              -{" "}
                                                                              {territory.accountDetails.map(
                                                                                (
                                                                                  hosp,
                                                                                  index
                                                                                ) =>
                                                                                  territory
                                                                                    .accountDetails
                                                                                    .length -
                                                                                    1 ===
                                                                                  index
                                                                                    ? hosp.accountName
                                                                                    : hosp.accountName +
                                                                                      " , "
                                                                              )}
                                                                              <img
                                                                                src={
                                                                                  editIcon
                                                                                }
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                  marginLeft:
                                                                                    "10px",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) =>
                                                                                  this.editLevel(
                                                                                    e,
                                                                                    "hospital",
                                                                                    territory._id
                                                                                  )
                                                                                }
                                                                                alt="hospital"
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        )}
                                                                    {!isHospname &&
                                                                      (this
                                                                        .state
                                                                        .editId ===
                                                                      territory._id ? (
                                                                        <div className="accordion-users accordion-acts">
                                                                          <span className="accordion-acts-span">
                                                                            Accounts
                                                                            -{" "}
                                                                          </span>
                                                                          <Autocomplete
                                                                            multiple
                                                                            className="cusautocomplete"
                                                                            id="tags-standard"
                                                                            options={
                                                                              this
                                                                                .state
                                                                                .accounts
                                                                            }
                                                                            getOptionLabel={(
                                                                              option
                                                                            ) =>
                                                                              option.accountName
                                                                            }
                                                                            onChange={
                                                                              this
                                                                                .onSelectactHandler
                                                                            }
                                                                            defaultValue={
                                                                              territory.accountDetails
                                                                            }
                                                                            renderTags={(
                                                                              tagValue,
                                                                              getTagProps
                                                                            ) =>
                                                                              tagValue.map(
                                                                                (
                                                                                  option,
                                                                                  index
                                                                                ) => (
                                                                                  <Chip
                                                                                    label={
                                                                                      option.accountName
                                                                                    }
                                                                                    {...getTagProps(
                                                                                      {
                                                                                        index,
                                                                                      }
                                                                                    )}
                                                                                    onDelete={() =>
                                                                                      this.handleDelete(
                                                                                        option._id,
                                                                                        tindex,
                                                                                        index,
                                                                                        territory._id
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                )
                                                                              )
                                                                            }
                                                                            renderInput={(
                                                                              params
                                                                            ) => (
                                                                              <TextField
                                                                                {...params}
                                                                                variant="standard"
                                                                                name="accountsid"
                                                                                placeholder="Enter Accounts"
                                                                              />
                                                                            )}
                                                                          />
                                                                          <Button
                                                                            className="level_save primary-btn"
                                                                            variant="contained"
                                                                            onClick={() =>
                                                                              this.addAccountHandler(
                                                                                territory._id
                                                                              )
                                                                            }
                                                                          >
                                                                            Save
                                                                          </Button>
                                                                          <Button
                                                                            className="level_cancel"
                                                                            variant="contained"
                                                                            onClick={(
                                                                              e
                                                                            ) => {
                                                                              e.stopPropagation();
                                                                              this.setState(
                                                                                {
                                                                                  isHospname: !this
                                                                                    .state
                                                                                    .isHospname,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            X
                                                                          </Button>
                                                                        </div>
                                                                      ) : (
                                                                        ""
                                                                      ))}

                                                                    {territory
                                                                      .accountDetails
                                                                      .length ===
                                                                    0
                                                                      ? !isHospital &&
                                                                        (this
                                                                          .state
                                                                          .editId ===
                                                                        territory._id ? (
                                                                          <div className="accordion-users accordion-acts">
                                                                            <span className="accordion-acts-span">
                                                                              Accounts
                                                                              -{" "}
                                                                            </span>
                                                                            <Autocomplete
                                                                              multiple
                                                                              className="cusautocomplete"
                                                                              id="tags-standard"
                                                                              options={
                                                                                this
                                                                                  .state
                                                                                  .accounts
                                                                              }
                                                                              getOptionLabel={(
                                                                                option
                                                                              ) =>
                                                                                option.accountName
                                                                              }
                                                                              onChange={
                                                                                this
                                                                                  .onSelectactHandler
                                                                              }
                                                                              renderTags={(
                                                                                tagValue,
                                                                                getTagProps
                                                                              ) =>
                                                                                tagValue.map(
                                                                                  (
                                                                                    option,
                                                                                    index
                                                                                  ) => (
                                                                                    <Chip
                                                                                      label={
                                                                                        option.accountName
                                                                                      }
                                                                                      {...getTagProps(
                                                                                        {
                                                                                          index,
                                                                                        }
                                                                                      )}
                                                                                    />
                                                                                  )
                                                                                )
                                                                              }
                                                                              renderInput={(
                                                                                params
                                                                              ) => (
                                                                                <TextField
                                                                                  {...params}
                                                                                  variant="standard"
                                                                                  name="accountsid"
                                                                                  placeholder="Enter Accounts"
                                                                                />
                                                                              )}
                                                                            />
                                                                            <Button
                                                                              className="level_save primary-btn"
                                                                              variant="contained"
                                                                              onClick={() =>
                                                                                this.addAccountHandler(
                                                                                  territory._id
                                                                                )
                                                                              }
                                                                            >
                                                                              Save
                                                                            </Button>
                                                                            <Button
                                                                              className="level_cancel"
                                                                              variant="contained"
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.stopPropagation();
                                                                                this.setState(
                                                                                  {
                                                                                    isHospital: !this
                                                                                      .state
                                                                                      .isHospital,
                                                                                  }
                                                                                );
                                                                              }}
                                                                            >
                                                                              X
                                                                            </Button>
                                                                          </div>
                                                                        ) : (
                                                                          <Button
                                                                            className="add_level_btn"
                                                                            variant="outlined"
                                                                            onClick={() =>
                                                                              this.addLevel(
                                                                                "hospital",
                                                                                territory._id
                                                                              )
                                                                            }
                                                                          >
                                                                            +Add
                                                                            Hospital
                                                                          </Button>
                                                                        ))
                                                                      : ""}

                                                                    {/* <div className="accordion-users accordion-acts"> 
                                                            <span><b>Hospitals</b> - {territory.accountDetails.map((hosp,index)=>
                                                            hosp.name)}</span></div> */}
                                                                  </AccordionDetails>
                                                                </Accordion>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )
                                                        )}
                                                    <div className="accord-padd">
                                                      {isTerritory && (
                                                        <Button
                                                          className="add_level_btn"
                                                          variant="outlined"
                                                          onClick={() =>
                                                            this.addLevel(
                                                              "territory",
                                                              region._id
                                                            )
                                                          }
                                                        >
                                                          +Add Territory
                                                        </Button>
                                                      )}
                                                      {!isTerritory &&
                                                        this.state.editId ===
                                                          region._id && (
                                                          <Accordion
                                                            className="accordion-territory"
                                                            expanded={false}
                                                          >
                                                            <IconLeftAccordionSummary
                                                              expandIcon={
                                                                <img
                                                                  src={
                                                                    ExpandMoreIcon
                                                                  }
                                                                  alt="expand"
                                                                />
                                                              }
                                                              IconButtonProps={{
                                                                edge: "start",
                                                              }}
                                                              aria-controls="panel2a-content"
                                                              id="panel2a-header"
                                                            >
                                                              <div className="accordion-head add-level">
                                                                <CssTextField
                                                                  id="outlined-basic"
                                                                  name="territoryname"
                                                                  variant="outlined"
                                                                  type="text"
                                                                  placeholder="Enter territory name"
                                                                  className="add-level-input"
                                                                  onChange={
                                                                    this
                                                                      .onChangeHandler
                                                                  }
                                                                />
                                                                <Button
                                                                  className="level_save primary-btn"
                                                                  variant="contained"
                                                                  disabled={
                                                                    this.state
                                                                      .territoryname ===
                                                                    ""
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    this.addLevelHandler(
                                                                      "territory",
                                                                      division[0]
                                                                        ._id,
                                                                      area._id,
                                                                      region._id
                                                                    )
                                                                  }
                                                                >
                                                                  Save
                                                                </Button>
                                                              </div>
                                                            </IconLeftAccordionSummary>
                                                            <AccordionDetails></AccordionDetails>
                                                          </Accordion>
                                                        )}
                                                    </div>
                                                  </AccordionDetails>
                                                </Accordion>
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          )}
                                      <div className="accord-padd">
                                        {isRegion && (
                                          <Button
                                            className="add_level_btn"
                                            variant="outlined"
                                            onClick={() =>
                                              this.addLevel("region", area._id)
                                            }
                                          >
                                            +Add Region
                                          </Button>
                                        )}
                                        {!isRegion &&
                                          this.state.editId === area._id && (
                                            <Accordion
                                              expanded={false}
                                              className="accordion-region"
                                            >
                                              <IconLeftAccordionSummary
                                                expandIcon={
                                                  <img
                                                    src={ExpandMoreIcon}
                                                    alt="expand"
                                                  />
                                                }
                                                IconButtonProps={{
                                                  edge: "start",
                                                }}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                              >
                                                <div style={{ width: "100%" }}>
                                                  <div className="accordion-head add-level">
                                                    <CssTextField
                                                      id="outlined-basic"
                                                      name="regionname"
                                                      variant="outlined"
                                                      type="text"
                                                      placeholder="Enter region name"
                                                      className="add-level-input"
                                                      onChange={
                                                        this.onChangeHandler
                                                      }
                                                    />
                                                    <div className="add-level-btns">
                                                      <Button
                                                        className="level_save primary-btn"
                                                        disabled={
                                                          this.state
                                                            .regionname === ""
                                                            ? true
                                                            : false
                                                        }
                                                        variant="contained"
                                                        onClick={() =>
                                                          this.addLevelHandler(
                                                            "region",
                                                            division[0]._id,
                                                            area._id
                                                          )
                                                        }
                                                      >
                                                        Save
                                                      </Button>
                                                      <Button
                                                        className="level_cancel"
                                                        variant="contained"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          this.setState({
                                                            isRegion: !this
                                                              .state.isRegion,
                                                          });
                                                        }}
                                                      >
                                                        X
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </IconLeftAccordionSummary>
                                              <AccordionDetails></AccordionDetails>
                                            </Accordion>
                                          )}
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              ))}
                          <div className="accord-padd">
                            {isArea && (
                              <Button
                                className="add_level_btn"
                                variant="outlined"
                                onClick={() =>
                                  this.addLevel("area", division[0]._id)
                                }
                              >
                                +Add Area
                              </Button>
                            )}
                            {!isArea && this.state.editId === division[0]._id && (
                              <Accordion
                                expanded={false}
                                className="accordion-area"
                              >
                                <IconLeftAccordionSummary
                                  expandIcon={
                                    <img src={ExpandMoreIcon} alt="exapnd" />
                                  }
                                  IconButtonProps={{
                                    edge: "start",
                                  }}
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                >
                                  <div style={{ width: "100%" }}>
                                    <div className="accordion-head add-level">
                                      <CssTextField
                                        id="outlined-basic"
                                        name="areaname"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Enter area name"
                                        className="add-level-input"
                                        onChange={this.onChangeHandler}
                                      />
                                      <div className="add-level-btns">
                                        <Button
                                          className="level_save primary-btn"
                                          variant="contained"
                                          disabled={
                                            this.state.areaname === ""
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            this.addLevelHandler(
                                              "area",
                                              division[0]._id,
                                              ""
                                            )
                                          }
                                        >
                                          Save
                                        </Button>
                                        <Button
                                          className="level_cancel"
                                          variant="contained"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                              isArea: !this.state.isArea,
                                            });
                                          }}
                                        >
                                          X
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </IconLeftAccordionSummary>
                                <AccordionDetails></AccordionDetails>
                              </Accordion>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )
                : isAreaEmpty && (
                    <div className="no-data-level">
                      Welcome , you can start creating the levels....
                      <Button
                        className="add_level_btn"
                        variant="outlined"
                        onClick={() => this.addEmptyLevel("area")}
                      >
                        +Add Area
                      </Button>
                    </div>
                  )}
              {!isAreaEmpty && (
                <Accordion expanded={false}>
                  <IconLeftAccordionSummary
                    expandIcon={<img src={ExpandMoreIcon} alt="exapnd" />}
                    IconButtonProps={{
                      edge: "start",
                    }}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div style={{ width: "100%" }}>
                      <div className="accordion-head add-level">
                        <CssTextField
                          id="outlined-basic"
                          name="areaname"
                          variant="outlined"
                          type="text"
                          placeholder="Enter Area name"
                          className="add-level-input"
                          onChange={this.onChangeHandler}
                        />
                        <Button
                          className="level_save primary-btn"
                          variant="contained"
                          disabled={this.state.areaname === "" ? true : false}
                          onClick={() =>
                            this.addEmptyLevelHandler(
                              "area",
                              division[0]._id,
                              ""
                            )
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </IconLeftAccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
    let SmallCompany = (
      <div className="account-detail-container">
        <Grid spacing={2} container>
          {loading ? (
            <Grid item xs={12} md={12} className="accordion-load">
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={75}
                width={75}
                visible={loading}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={12} className="accordion-box">
              {region.length !== 0
                ? isRegionEmpty && (
                    <>
                      <Accordion
                        className="accordion-first"
                        style={{ background: "#f2f4fb" }}
                        defaultExpanded={true}
                      >
                        <AccordionDetails className="accord-details-area">
                          {region === undefined
                            ? ""
                            : region.map((region, rindex) =>
                                region.area_id === area[0]._id ? (
                                  <div className="accord-padd accord-padd-ext">
                                    <Accordion className="accordion-region">
                                      <IconLeftAccordionSummary
                                        expandIcon={
                                          <img
                                            src={ExpandMoreIcon}
                                            alt="exapnd"
                                          />
                                        }
                                        IconButtonProps={{
                                          edge: "start",
                                        }}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                      >
                                        <div style={{ width: "100%" }}>
                                          {this.state.isRegionname && (
                                            <>
                                              <div className="accordion-head menu-icon">
                                                {region.name}
                                                <IconButton
                                                  aria-label="more"
                                                  aria-controls="long-menu"
                                                  aria-haspopup="true"
                                                  onMouseEnter={() =>
                                                    this.checkSubscriptionLevel(
                                                      "region",
                                                      region._id
                                                    )
                                                  }
                                                  onClick={(event) =>
                                                    this.handleClickregion(
                                                      event,
                                                      region._id,
                                                      area[0]._id
                                                    )
                                                  }
                                                >
                                                  <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                  id="long-menu"
                                                  anchorEl={
                                                    this.state.anchorElregion
                                                  }
                                                  keepMounted
                                                  open={this.state.openregion}
                                                  onClose={
                                                    this.handleCloseregion
                                                  }
                                                  PaperProps={{
                                                    style: {
                                                      maxHeight: 48 * 4.5,
                                                    },
                                                  }}
                                                >
                                                  {/* <MenuItem
                                                  key={1}
                                                  onClick={() =>
                                                    this.openParentmodal(
                                                      "region",
                                                      division[0]
                                                        ._id,
                                                      area[0]._id
                                                    )
                                                  }
                                                >
                                                  Change parent
                                                        </MenuItem> */}
                                                  <MenuItem
                                                    key="region"
                                                    data-id={rindex}
                                                    onClick={(e) =>
                                                      this.editLevel(
                                                        e,
                                                        "region"
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </MenuItem>
                                                  <MenuItem
                                                    key={3}
                                                    onClick={(e) =>
                                                      this.deleteLevel(
                                                        e,
                                                        region._id,
                                                        "region",
                                                        division[0]._id
                                                      )
                                                    }
                                                  >
                                                    Remove
                                                  </MenuItem>
                                                  {this.state.showSubscribe ? (
                                                    <MenuItem
                                                      key={3}
                                                      onClick={() =>
                                                        this.openSubscriptionLevelModal(
                                                          "region",
                                                          region._id
                                                        )
                                                      }
                                                    >
                                                      Add Subscription level
                                                    </MenuItem>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Menu>
                                              </div>
                                            </>
                                          )}
                                          {!this.state.isRegionname && (
                                            <span className="edit-input-level">
                                              {this.state.editId ===
                                              region._id ? (
                                                <>
                                                  <CssTextField
                                                    id="outlined-basic"
                                                    name="regionname"
                                                    variant="outlined"
                                                    type="text"
                                                    placeholder="Enter region name"
                                                    className="add-level-input"
                                                    defaultValue={region.name}
                                                    onChange={
                                                      this.onChangeHandler
                                                    }
                                                  />
                                                  <Button
                                                    className="level_save primary-btn"
                                                    variant="contained"
                                                    disabled={
                                                      this.state.regionname ===
                                                      ""
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={() =>
                                                      this.updateHandler(
                                                        "region",
                                                        region._id,
                                                        division[0]._id
                                                      )
                                                    }
                                                  >
                                                    Save
                                                  </Button>
                                                  <Button
                                                    className="level_cancel"
                                                    variant="contained"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      this.setState({
                                                        isRegionname: !this
                                                          .state.isRegionname,
                                                      });
                                                    }}
                                                  >
                                                    X
                                                  </Button>
                                                </>
                                              ) : (
                                                <div className="accordion-head">
                                                  {region.name}
                                                </div>
                                              )}
                                            </span>
                                          )}
                                          {region.subscriptionStatus ? (
                                            <div className="add_company_dialog accordion-users billing_detail_item">
                                              <p
                                                onMouseEnter={() =>
                                                  this.showEditIcon(
                                                    "billingTypeEdit",
                                                    region._id
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.hideEditIcon(
                                                    "billingTypeEdit",
                                                    region._id
                                                  )
                                                }
                                              >
                                                Billing Type :
                                                {this.state.isBillingType ? (
                                                  <>
                                                    <span>
                                                      {region.subscription
                                                        .type === 1
                                                        ? "Organizational"
                                                        : region.subscription
                                                            .type === 2
                                                        ? "Team"
                                                        : region.subscription
                                                            .type === 3
                                                        ? "Individual"
                                                        : "-"}
                                                        {this.state
                                                      .billingTypeEdit &&
                                                    this.state.editIds ===
                                                      region._id ? (
                                                      <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        onClick={() =>
                                                          this.editInput(
                                                            "isBillingType",
                                                            region._id,
                                                            "subscriptionTypeEdit",
                                                            region.subscription
                                                              .type,
                                                            region.subscription
                                                              .startDate,
                                                            region.subscription
                                                              .endDate
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <span className="edit-input">
                                                    <FormControl
                                                      variant="outlined"
                                                      fullWidth
                                                      margin="dense"
                                                    >
                                                      <Select
                                                        margin="dense"
                                                        className="customSelect"
                                                        placeholder="Select billing type"
                                                        name="subscriptionTypeEdit"
                                                        fullWidth
                                                        onChange={
                                                          this.onChangeHandler
                                                        }
                                                        value={
                                                          this.state
                                                            .subscriptionTypeEdit ===
                                                            2 ||
                                                          this.state
                                                            .subscriptionTypeEdit ===
                                                            "2"
                                                            ? "2"
                                                            : this.state
                                                                .subscriptionTypeEdit ===
                                                                3 ||
                                                              this.state
                                                                .subscriptionTypeEdit ===
                                                                "3"
                                                            ? "3"
                                                            : ""
                                                        }
                                                      >
                                                        <MenuItem value="2">
                                                          Team
                                                        </MenuItem>
                                                        <MenuItem value="3">
                                                          Individual
                                                        </MenuItem>
                                                      </Select>
                                                    </FormControl>
                                                    <Button
                                                      className="add_more_btn"
                                                      onClick={() =>
                                                        this.updateSubscriptionHandler(
                                                          "isBillingType",
                                                          "region",
                                                          region._id,
                                                          region.subscription
                                                            .price,
                                                          division[0]._id
                                                        )
                                                      }
                                                      //disabled={props.billingTypeDisable?false:true}
                                                    >
                                                      Save
                                                    </Button>
                                                    <img
                                                      src={close_icon_red}
                                                      alt=""
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.closeEdit(
                                                          "isBillingType",
                                                          region._id
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                )}
                                              </p>
                                              <p
                                                className="billing-period"
                                                onMouseEnter={() =>
                                                  this.showEditIcon(
                                                    "billingPeriodEdit",
                                                    region._id
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.hideEditIcon(
                                                    "billingPeriodEdit",
                                                    region._id
                                                  )
                                                }
                                              >
                                                Trial Period :
                                                {this.state.isBillingPeriod ? (
                                                  <>
                                                    <span>
                                                      {momentTimeZone(
                                                        region.subscription
                                                          .startDate
                                                      )
                                                        .tz(
                                                          this.state
                                                            .timeZoneName
                                                        )
                                                        .format(
                                                          "MM-DD-YYYY"
                                                        )}{" "}
                                                      to{" "}
                                                      {momentTimeZone(
                                                        region.subscription
                                                          .endDate
                                                      )
                                                        .tz(
                                                          this.state
                                                            .timeZoneName
                                                        )
                                                        .format("MM-DD-YYYY")}
                                                        {this.state
                                                      .billingPeriodEdit &&
                                                    this.state.editIds ===
                                                      region._id ? (
                                                      <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        onClick={() =>
                                                          this.editPeriodInput(
                                                            "isBillingPeriod",
                                                            region._id,
                                                            region.subscription
                                                              .startDate,
                                                            region.subscription
                                                              .endDate
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <span className="edit-input">
                                                    <div className="trail_field">
                                                      <RangePicker
                                                        className="filter_date_range "
                                                        name="datepicker"
                                                        format="MM/DD/YYYY"
                                                        allowClear={false}
                                                        inputReadOnly={true}
                                                        separator="to"
                                                        value={[
                                                          moment(
                                                            this.state
                                                              .subscriptionTrialEnds[0],
                                                            "MM/DD/YYYY"
                                                          ),
                                                          moment(
                                                            this.state
                                                              .subscriptionTrialEnds[1],
                                                            "MM/DD/YYYY"
                                                          ),
                                                        ]}
                                                        disabledDate={
                                                          this.valid
                                                        }
                                                        placeholder={[
                                                          "MM/DD/YYYY",
                                                          "MM/DD/YYYY",
                                                        ]}
                                                        suffixIcon={
                                                          <img
                                                            className="carret"
                                                            alt="carret"
                                                            src={carret_down}
                                                          />
                                                        }
                                                        getPopupContainer={(
                                                          node
                                                        ) => node.parentNode}
                                                        onChange={
                                                          this
                                                            .onDateRangePeriodChanage
                                                        }
                                                      />
                                                      {/* <span className="remaining_days">{this.state.subscriptionLevelTrialDays?this.state.subscriptionLevelTrialDays:0} days</span> */}
                                                    </div>
                                                    <span>
                                                      <Button
                                                        className="add_more_btn"
                                                        onClick={() =>
                                                          this.updateSubscriptionPeriodHandler(
                                                            "region",
                                                            region._id,
                                                            region.subscription
                                                              .type,
                                                            region.subscription
                                                              .price,
                                                            division[0]._id
                                                          )
                                                        }
                                                        // disabled={props.billingsDatesDisable?false:true}
                                                      >
                                                        Save
                                                      </Button>
                                                      <img
                                                        src={close_icon_red}
                                                        alt="close"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          this.closeEdit(
                                                            "isBillingPeriod",
                                                            region._id
                                                          )
                                                        }
                                                      />
                                                    </span>
                                                  </span>
                                                )}
                                              </p>
                                              <p
                                                onMouseEnter={() =>
                                                  this.showEditIcon(
                                                    "billingPriceEdit",
                                                    region._id
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.hideEditIcon(
                                                    "billingPriceEdit",
                                                    region._id
                                                  )
                                                }
                                              >
                                                Billing Price/User :
                                                {this.state.isBillingPrice ? (
                                                  <>
                                                    <span>
                                                      ${" "}
                                                      {parseFloat(
                                                        region.subscription
                                                          .price
                                                      )}
                                                      {this.state
                                                      .billingPriceEdit &&
                                                    this.state.editIds ===
                                                      region._id ? (
                                                      <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        onClick={() =>
                                                          this.editInput(
                                                            "isBillingPrice",
                                                            region._id,
                                                            "subscriptionEditPrice",
                                                            region.subscription
                                                              .price,
                                                            region.subscription
                                                              .startDate,
                                                            region.subscription
                                                              .endDate
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <span className="edit-input">
                                                    <CssTextField
                                                      id="outlined-basic"
                                                      variant="outlined"
                                                      name="subscriptionEditPrice"
                                                      className="comp_name"
                                                      autoFocus
                                                      placeholder="Enter price per user"
                                                      type="text"
                                                      value={
                                                        this.state
                                                          .subscriptionEditPrice
                                                      }
                                                      InputProps={{
                                                        startAdornment: (
                                                          <InputAdornment
                                                            position="start"
                                                            className="dollar_symbol"
                                                          >
                                                            $
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                      onChange={
                                                        this.onChangeHandler
                                                      }
                                                    />
                                                    <Button
                                                      className="add_more_btn"
                                                      onClick={() =>
                                                        this.updateSubscriptionHandler(
                                                          "isBillingPrice",
                                                          "region",
                                                          region._id,
                                                          region.subscription
                                                            .type,
                                                          division[0]._id
                                                        )
                                                      }
                                                      // disabled={props.isPriceDisable?false:true}
                                                    >
                                                      Save
                                                    </Button>
                                                    <img
                                                      src={close_icon_red}
                                                      alt=""
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.closeEdit(
                                                          "isBillingPrice",
                                                          region._id
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                )}
                                              </p>
                                              <div className="modal-err-msg">
                                                {this.state
                                                  .subscriptionFormError
                                                  .billingPrice !== ""
                                                  ? `* ${this.state.subscriptionFormError.billingPrice}`
                                                  : ""}
                                              </div>
                                            </div>
                                          ) : null}
                                          <div className="accordion-users">
                                            Managers -{" "}
                                            {region.employeeDetails.length !== 0
                                              ? region.employeeDetails.map(
                                                  (emp, index) =>
                                                    emp.role.toLowerCase() ===
                                                    "team lead"
                                                      ? region.employeeDetails
                                                          .length -
                                                          1 ===
                                                        index
                                                        ? emp.name
                                                        : emp.name + " , "
                                                      : ""
                                                )
                                              : "No managers assigned"}
                                          </div>
                                        </div>
                                      </IconLeftAccordionSummary>
                                      <AccordionDetails
                                        className="accord-details accord-territory"
                                        style={{
                                          background: "#f2f4fb",
                                        }}
                                      >
                                        {territory === undefined
                                          ? ""
                                          : territory.map((territory, tindex) =>
                                              territory.area_id ===
                                                area[0]._id &&
                                              territory.region_id ===
                                                region._id ? (
                                                <div className="accord-padd accord-padd-ext">
                                                  <Accordion className="accordion-territory">
                                                    <IconLeftAccordionSummary
                                                      expandIcon={
                                                        <img
                                                          src={ExpandMoreIcon}
                                                          alt="exapnd"
                                                        />
                                                      }
                                                      IconButtonProps={{
                                                        edge: "start",
                                                      }}
                                                      aria-controls="panel2a-content"
                                                      id="panel2a-header"
                                                    >
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        {this.state
                                                          .isTerritoryname && (
                                                          <>
                                                            <div className="accordion-head menu-icon">
                                                              {territory.name}
                                                              <IconButton
                                                                aria-label="more"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  this.handleClick(
                                                                    event,
                                                                    territory._id,
                                                                    area[0]._id,
                                                                    region._id
                                                                  )
                                                                }
                                                              >
                                                                <MoreVertIcon />
                                                              </IconButton>
                                                              <Menu
                                                                id="long-menu"
                                                                anchorEl={
                                                                  this.state
                                                                    .anchorEl
                                                                }
                                                                keepMounted
                                                                open={
                                                                  this.state
                                                                    .open
                                                                }
                                                                onClose={
                                                                  this
                                                                    .handleClose
                                                                }
                                                                PaperProps={{
                                                                  style: {
                                                                    maxHeight:
                                                                      48 * 4.5,
                                                                    width:
                                                                      "20ch",
                                                                  },
                                                                }}
                                                              >
                                                                <MenuItem
                                                                  key={1}
                                                                  onClick={() =>
                                                                    this.openParentmodal(
                                                                      "territory",
                                                                      division[0]
                                                                        ._id,
                                                                      area[0]
                                                                        ._id,
                                                                      region._id
                                                                    )
                                                                  }
                                                                >
                                                                  Change parent
                                                                </MenuItem>
                                                                <MenuItem
                                                                  key={2}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this.editLevel(
                                                                      e,
                                                                      "territory"
                                                                    )
                                                                  }
                                                                >
                                                                  Edit
                                                                </MenuItem>
                                                                <MenuItem
                                                                  key={3}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this.deleteLevel(
                                                                      e,
                                                                      territory._id,
                                                                      "territory",
                                                                      division[0]
                                                                        ._id
                                                                    )
                                                                  }
                                                                >
                                                                  Remove
                                                                </MenuItem>
                                                              </Menu>
                                                            </div>
                                                          </>
                                                        )}
                                                        {!this.state
                                                          .isTerritoryname && (
                                                          <span className="edit-input-level">
                                                            {this.state
                                                              .editId ===
                                                            territory._id ? (
                                                              <>
                                                                <CssTextField
                                                                  id="outlined-basic"
                                                                  name="territoryname"
                                                                  variant="outlined"
                                                                  type="text"
                                                                  placeholder="Enter territory name"
                                                                  className="add-level-input"
                                                                  defaultValue={
                                                                    territory.name
                                                                  }
                                                                  onChange={
                                                                    this
                                                                      .onChangeHandler
                                                                  }
                                                                />
                                                                <Button
                                                                  className="level_save primary-btn"
                                                                  variant="contained"
                                                                  disabled={
                                                                    this.state
                                                                      .territoryname ===
                                                                    ""
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    this.updateHandler(
                                                                      "territory",
                                                                      territory._id,
                                                                      division[0]
                                                                        ._id
                                                                    )
                                                                  }
                                                                >
                                                                  Save
                                                                </Button>
                                                                <Button
                                                                  className="level_cancel"
                                                                  variant="contained"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                    this.setState(
                                                                      {
                                                                        isTerritoryname: !this
                                                                          .state
                                                                          .isTerritoryname,
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  X
                                                                </Button>
                                                              </>
                                                            ) : (
                                                              <div className="accordion-head">
                                                                {territory.name}
                                                              </div>
                                                            )}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </IconLeftAccordionSummary>
                                                    <AccordionDetails className="accordion-details">
                                                      <div className="accordion-users">
                                                        Managers -{" "}
                                                        {territory
                                                          .employeeDetails
                                                          .length !== 0
                                                          ? territory.employeeDetails.map(
                                                              (emp, index) =>
                                                              emp.role.toLowerCase() ===
                                                                "manager"
                                                                  ? territory
                                                                      .employeeDetails
                                                                      .length -
                                                                      1 ===
                                                                    index
                                                                    ? emp.name
                                                                    : emp.name +
                                                                      " , "
                                                                  : ""
                                                                 
                                                            )
                                                          : "No managers assigned"}
                                                      </div>
                                                      <div className="accordion-users">
                                                      Representative -{" "}
                                                        {territory
                                                          .employeeDetails
                                                          .length !== 0
                                                          ? territory.employeeDetails.map(
                                                              (emp, index) =>
                                                                emp.role.toLowerCase() ===
                                                                "sales rep"
                                                                  ? territory
                                                                      .employeeDetails
                                                                      .length -
                                                                      1 ===
                                                                    index
                                                                    ? emp.name
                                                                    : emp.name +
                                                                      " , "
                                                                  : ""
                                                            )
                                                          : "No Representative assigned"}
                                                      </div>
                                                      {territory.accountDetails
                                                        .length === 0
                                                        ? isHospital && (
                                                            <Button
                                                              className="add_level_btn"
                                                              variant="outlined"
                                                              onClick={() =>
                                                                this.addLevel(
                                                                  "hospital",
                                                                  territory._id
                                                                )
                                                              }
                                                            >
                                                              +Add Hospital
                                                            </Button>
                                                          )
                                                        : isHospname && (
                                                            <div className="accordion-users accordion-acts">
                                                              <span className="accordion-acts-span">
                                                                Accounts -{" "}
                                                                {territory.accountDetails.map(
                                                                  (
                                                                    hosp,
                                                                    index
                                                                  ) =>
                                                                    territory
                                                                      .accountDetails
                                                                      .length -
                                                                      1 ===
                                                                    index
                                                                      ? hosp.accountName
                                                                      : hosp.accountName +
                                                                        " , "
                                                                )}
                                                                <img
                                                                  src={editIcon}
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    marginLeft:
                                                                      "10px",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this.editLevel(
                                                                      e,
                                                                      "hospital",
                                                                      territory._id
                                                                    )
                                                                  }
                                                                  alt="edit icon"
                                                                />
                                                              </span>
                                                            </div>
                                                          )}
                                                      {!isHospname &&
                                                        (this.state.editId ===
                                                        territory._id ? (
                                                          <div className="accordion-users accordion-acts">
                                                            <span className="accordion-acts-span">
                                                              Accounts -{" "}
                                                            </span>
                                                            <Autocomplete
                                                              multiple
                                                              className="cusautocomplete"
                                                              id="tags-standard"
                                                              options={
                                                                this.state
                                                                  .accounts
                                                              }
                                                              getOptionLabel={(
                                                                option
                                                              ) =>
                                                                option.accountName
                                                              }
                                                              onChange={
                                                                this
                                                                  .onSelectactHandler
                                                              }
                                                              defaultValue={
                                                                territory.accountDetails
                                                              }
                                                              renderTags={(
                                                                tagValue,
                                                                getTagProps
                                                              ) =>
                                                                tagValue.map(
                                                                  (
                                                                    option,
                                                                    index
                                                                  ) => (
                                                                    <Chip
                                                                      label={
                                                                        option.accountName
                                                                      }
                                                                      {...getTagProps(
                                                                        {
                                                                          index,
                                                                        }
                                                                      )}
                                                                      onDelete={() =>
                                                                        this.handleDelete(
                                                                          option._id,
                                                                          tindex,
                                                                          index,
                                                                          territory._id
                                                                        )
                                                                      }
                                                                    />
                                                                  )
                                                                )
                                                              }
                                                              renderInput={(
                                                                params
                                                              ) => (
                                                                <TextField
                                                                  {...params}
                                                                  variant="standard"
                                                                  name="accountsid"
                                                                  placeholder="Enter Accounts"
                                                                />
                                                              )}
                                                            />
                                                            <Button
                                                              className="level_save primary-btn"
                                                              variant="contained"
                                                              onClick={() =>
                                                                this.addAccountHandler(
                                                                  territory._id
                                                                )
                                                              }
                                                            >
                                                              Save
                                                            </Button>
                                                            <Button
                                                              className="level_cancel"
                                                              variant="contained"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({
                                                                  isHospname: !this
                                                                    .state
                                                                    .isHospname,
                                                                });
                                                              }}
                                                            >
                                                              X
                                                            </Button>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        ))}

                                                      {territory.accountDetails
                                                        .length === 0
                                                        ? !isHospital &&
                                                          (this.state.editId ===
                                                          territory._id ? (
                                                            <div className="accordion-users accordion-acts">
                                                              <span className="accordion-acts-span">
                                                                Accounts -{" "}
                                                              </span>
                                                              <Autocomplete
                                                                multiple
                                                                className="cusautocomplete"
                                                                id="tags-standard"
                                                                options={
                                                                  this.state
                                                                    .accounts
                                                                }
                                                                getOptionLabel={(
                                                                  option
                                                                ) =>
                                                                  option.accountName
                                                                }
                                                                onChange={
                                                                  this
                                                                    .onSelectactHandler
                                                                }
                                                                renderTags={(
                                                                  tagValue,
                                                                  getTagProps
                                                                ) =>
                                                                  tagValue.map(
                                                                    (
                                                                      option,
                                                                      index
                                                                    ) => (
                                                                      <Chip
                                                                        label={
                                                                          option.accountName
                                                                        }
                                                                        {...getTagProps(
                                                                          {
                                                                            index,
                                                                          }
                                                                        )}
                                                                      />
                                                                    )
                                                                  )
                                                                }
                                                                renderInput={(
                                                                  params
                                                                ) => (
                                                                  <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    name="accountsid"
                                                                    placeholder="Enter Accounts"
                                                                  />
                                                                )}
                                                              />
                                                              <Button
                                                                className="level_save primary-btn"
                                                                variant="contained"
                                                                onClick={() =>
                                                                  this.addAccountHandler(
                                                                    territory._id
                                                                  )
                                                                }
                                                              >
                                                                Save
                                                              </Button>
                                                              <Button
                                                                className="level_cancel"
                                                                variant="contained"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.stopPropagation();
                                                                  this.setState(
                                                                    {
                                                                      isHospital: !this
                                                                        .state
                                                                        .isHospital,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                X
                                                              </Button>
                                                            </div>
                                                          ) : (
                                                            <Button
                                                              className="add_level_btn"
                                                              variant="outlined"
                                                              onClick={() =>
                                                                this.addLevel(
                                                                  "hospital",
                                                                  territory._id
                                                                )
                                                              }
                                                            >
                                                              +Add Hospital
                                                            </Button>
                                                          ))
                                                        : ""}

                                                      {/* <div className="accordion-users accordion-acts"> 
                                                            <span><b>Hospitals</b> - {territory.accountDetails.map((hosp,index)=>
                                                            hosp.name)}</span></div> */}
                                                    </AccordionDetails>
                                                  </Accordion>
                                                </div>
                                              ) : (
                                                ""
                                              )
                                            )}
                                        <div className="accord-padd">
                                          {isTerritory && (
                                            <Button
                                              className="add_level_btn"
                                              variant="outlined"
                                              onClick={() =>
                                                this.addLevel(
                                                  "territory",
                                                  region._id
                                                )
                                              }
                                            >
                                              +Add Territory
                                            </Button>
                                          )}
                                          {!isTerritory &&
                                            this.state.editId ===
                                              region._id && (
                                              <Accordion
                                                className="accordion-territory"
                                                expanded={false}
                                              >
                                                <IconLeftAccordionSummary
                                                  expandIcon={
                                                    <img
                                                      src={ExpandMoreIcon}
                                                      alt="expand"
                                                    />
                                                  }
                                                  IconButtonProps={{
                                                    edge: "start",
                                                  }}
                                                  aria-controls="panel2a-content"
                                                  id="panel2a-header"
                                                >
                                                  <div className="accordion-head add-level">
                                                    <CssTextField
                                                      id="outlined-basic"
                                                      name="territoryname"
                                                      variant="outlined"
                                                      type="text"
                                                      placeholder="Enter territory name"
                                                      className="add-level-input"
                                                      onChange={
                                                        this.onChangeHandler
                                                      }
                                                    />
                                                    <Button
                                                      className="level_save primary-btn"
                                                      variant="contained"
                                                      disabled={
                                                        this.state
                                                          .territoryname === ""
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() =>
                                                        this.addLevelHandler(
                                                          "territory",
                                                          division[0]._id,
                                                          area[0]._id,
                                                          region._id
                                                        )
                                                      }
                                                    >
                                                      Save
                                                    </Button>
                                                  </div>
                                                </IconLeftAccordionSummary>
                                                <AccordionDetails></AccordionDetails>
                                              </Accordion>
                                            )}
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                          <div className="accord-padd">
                            {isRegion && (
                              <Button
                                className="add_level_btn"
                                variant="outlined"
                                onClick={() =>
                                  this.addLevel("region", area[0]._id)
                                }
                              >
                                +Add Region
                              </Button>
                            )}
                            {!isRegion && this.state.editId === area[0]._id && (
                              <Accordion
                                expanded={false}
                                className="accordion-region"
                              >
                                <IconLeftAccordionSummary
                                  expandIcon={
                                    <img src={ExpandMoreIcon} alt="expand" />
                                  }
                                  IconButtonProps={{
                                    edge: "start",
                                  }}
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                >
                                  <div style={{ width: "100%" }}>
                                    <div className="accordion-head add-level">
                                      <CssTextField
                                        id="outlined-basic"
                                        name="regionname"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Enter region name"
                                        className="add-level-input"
                                        onChange={this.onChangeHandler}
                                      />
                                      <div className="add-level-btns">
                                        <Button
                                          className="level_save primary-btn"
                                          variant="contained"
                                          disabled={
                                            this.state.regionname === ""
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            this.addLevelHandler(
                                              "region",
                                              division[0]._id,
                                              area[0]._id
                                            )
                                          }
                                        >
                                          Save
                                        </Button>
                                        <Button
                                          className="level_cancel"
                                          variant="contained"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                              isRegion: !this.state.isRegion,
                                            });
                                          }}
                                        >
                                          X
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </IconLeftAccordionSummary>
                                <AccordionDetails></AccordionDetails>
                              </Accordion>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )
                : isRegionEmpty && (
                    <div className="no-data-level">
                      Welcome , you can start creating the levels....
                      <Button
                        className="add_level_btn"
                        variant="outlined"
                        onClick={() => this.addEmptyLevel("region")}
                      >
                        +Add Region
                      </Button>
                    </div>
                  )}
              {!isRegionEmpty && (
                <Accordion expanded={false}>
                  <IconLeftAccordionSummary
                    expandIcon={<img src={ExpandMoreIcon} alt="expand" />}
                    IconButtonProps={{
                      edge: "start",
                    }}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div style={{ width: "100%" }}>
                      <div className="accordion-head add-level">
                        <CssTextField
                          id="outlined-basic"
                          name="regionname"
                          variant="outlined"
                          type="text"
                          placeholder="Enter region name"
                          className="add-level-input"
                          onChange={this.onChangeHandler}
                        />
                        <Button
                          className="level_save primary-btn"
                          variant="contained"
                          disabled={this.state.regionname === "" ? true : false}
                          onClick={() =>
                            this.addEmptyLevelHandler(
                              "region",
                              division[0]._id,
                              area[0]._id
                            )
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </IconLeftAccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );

    let LevelsPage = (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        <SubscriptionLevelModal
          subscriptionData={this.state.subscriptionLevel}
          formError={this.state.subscriptionFormError}
          subscriptionModalClose={this.subscriptionModalClose}
          handleChange={this.subscriptionHandleChange}
          onDateRangeChanage={this.onDateRangeChanage}
          subscriptionDataAdd={this.subscriptionDataAdd}
          valid={this.valid}
        />
        <Box component="div" className="company-comp account-detail">
          <ChangeParentModel
            modelData={this.state}
            openModel={this.state.openModal}
            onClose={this.onCloseModal}
            onChangeparent={this.onChangeparent}
            onChangeHandler={this.onChangeHandler}
            companySize={this.state.companySize}
            inChangeParentValid ={this.state.inChangeParentValid}
            isChangeParentValid={this.state.isChangeParentValid}
            changeParentError={this.state.changeParentError}
          />
          <div className="select_parent">
            <div className="breadCrumbs">
              <img className="bread-crumb-back" src={backIcon} alt="back" />
              <Breadcrumbs className="bread-crumb-main" aria-label="breadcrumb">
                <Link
                  className="bread-crumb-link"
                  color="inherit"
                  href="/account"
                >
                  Accounts
                </Link>
                <Typography className="bread-crumb-name">
                  Organization Structure
                </Typography>
              </Breadcrumbs>
            </div>
            <div className="account-detail-header">
              <Typography variant="h5" className="tableTitle" component="div">
                Organization Structure
              </Typography>
            </div>
          </div>
          {companySize.toLowerCase() === "large"
            ? LargeCompany
            : companySize.toLowerCase() === "medium"
            ? MediumCompany
            : SmallCompany}
        </Box>
      </div>
    );
    if (userRole.toLowerCase() === "admin") {
      return <div>{LevelsPage}</div>;
    } else {
      return <Redirect to="/dashboard" />;
    }
  }
}
export default LevelsComponent;
