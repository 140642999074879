import React from 'react';
import date_img from "../../../Assets/images/Icon material-date-range.svg";
import location_img from "../../../Assets/images/Icon material-location-on.svg";
import warning_img from "../../../Assets/images/Icon ionic-ios-warning.svg";
import { Popover,Tooltip,Empty } from "antd";
import momentTimeZone from 'moment-timezone';
const PendingEventAcceptanceComponent = (props) => {
    return (  
        <div className="acceptance_container">
            {props.pendingAcceptanceList && props.pendingAcceptanceList.length>0?
             props.pendingAcceptanceList.map(item=>(
              <div className="event_acceptance" key={item._id}>
                <div className="event_acceptance_details">
                    <div className="event_acceptance_header" 
                      onClick={() => props.detailViewHandler(item._id,'event')}>
                        <h5>
                          <Tooltip title="View Detail" placement="bottom">
                          {item.typeName? item.typeName:null}
                          </Tooltip>
                        </h5>
                    <div className="event_acceptance_location">
                        <img src={location_img} />
                        <span>{item.address?item.address:null}</span>
                    </div>
                        <div className="event_acceptance_type">
                            <span>(</span>
                                <span>{item.type === 'personal'?'Personal Event':'Work Event'}</span>
                            <span>)</span>
                        </div>
                    </div>
                            <h3 className="event_acceptance_title">{item.title?item.title:null}</h3>
                    <div className="event_acceptance_attendees">
                    <span className="attendees_title">Attendees :</span>
                    <span className="attendees_name">
                    { item.attendees && item.attendees.length > 0
                        ?  
                        item.attendees.map((names, index) =>
                        item.attendees.length >0 && index < 3 ? (
                            item.attendees.length - 1 === index ? (
                                names.name
                              ) : (
                                names.name + ", "
                              )
                            ) : (
                              ""
                            )
                          )
                        
                        : "-"}
                        {item.attendees && item.attendees.length>3?
                        <Popover
                          title="Other Attendees"
                          content={item.attendees.map(
                            (names, index) =>
                              index >= 3 ? (
                                <p key={names.employeeId}>{names.name}</p>
                              ) : (
                                ""
                              )
                          )}
                        >
                          <span className="dashboard_task_event_attendees_plus" type="primary">
                            +{item.attendees.length - 3} others
                          </span>
                        </Popover>
                        :""}
                    </span>
                    {/* <span>2+others</span> */}
                    </div>
                    <div className="event_acceptance_time">
                    <img src={date_img}/>
                    <span>
                    {momentTimeZone(item.startDate).tz(props.timeZoneName).format("MMM-DD-yyyy")}
                     &nbsp;From&nbsp;
                    {momentTimeZone(item.startDate).tz(props.timeZoneName).format("hh:mm A")}
                    &nbsp;to&nbsp;
                    {momentTimeZone(item.endDate).tz(props.timeZoneName).format("hh:mm A")}
                    </span>
                    </div>
                </div>
                <div className="event_acceptance_actions">
                    <button 
                      className="reject_btn grey-btn"
                     onClick={() => props.onAcceptanceHandler(item, 'reject' , props.page, props.pendingAcceptanceList.length)}
                      disabled={props.taskRejectBtnClicked && item._id === props.btnClickId}
                      >Reject</button>
                      {item.conflicts?
            <Tooltip title={`This event conflicts with ${item.conflict_event_title}`} placement="bottom">
                    <button 
                      className={item.conflicts?"warning_btn yellow-btn":"accept_btn green-btn"}
                         onClick={() => props.onAcceptanceHandler(item, 'accept', props.page, props.pendingAcceptanceList.length)}
                      disabled={props.taskAcceptBtnClicked && item._id === props.btnClickId}
                      >
                        {item.conflicts?<img src={warning_img}/>:""}Accept</button>
                        </Tooltip>:<button 
                      className={item.conflicts?"warning_btn yellow-btn":"accept_btn green-btn"}
                       onClick={() => props.onAcceptanceHandler(item, 'accept', props.page, props.pendingAcceptanceList.length)}
                      disabled={props.taskAcceptBtnClicked && item._id === props.btnClickId}
                      >
                        {item.conflicts?<img src={warning_img}/>:""}Accept</button>}
                </div>
                {/* {item.isEmergency ? (
                  <Tooltip title="Emergency Event" placement="bottom">
                    <div className="error_task">
                      <span>!</span>
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )} */}
              </div>
             ))
   
        :<Empty
        description={
          <span
            style={{
              color: "#303960",
            }}
          >
            No pending acceptance events
          </span>
        }
      />}

      </div>
    );
}
 
export default PendingEventAcceptanceComponent;