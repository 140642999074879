import React from 'react';
import {
    Button
  } from "@material-ui/core";
import warning from "../../../Assets/images/warning.svg";
const SubscriptionErrorMessageComponent = (props) => {
    return (
        <div className="subscribing_section">
            <div className="card_added_failure">
                <img alt="green_tick" src={warning} style={{marginRight: "14px"}}/> 
                <span>
                    {props.billStatusNew === 1?
                    "You are not subscribed to SalesC2. Please subscribe now to continue accessing the modules."
                    :props.billStatusNew === 2?
                    "Your payment for subscription is failed. Kindly add another card and retry payment."
                    :props.billStatusNew === 3?
                    "Payment is under process."
                    :""
                    }
                    </span>
            </div>
            {props.billStatusNew === 1 || props.billStatusNew === 2?
            <div className="add_company_action">
                <Button
                    className="ok_btn green-btn"
                    type="submit"
                    onClick={props.openCardModal}
                  >
                    Subscribe Now
                </Button>
            </div>:""}
        </div>
    );
}
 
export default SubscriptionErrorMessageComponent;