import React, { Component } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  DialogTitle,
  Select,
  FormControl,
  CircularProgress,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import momentTimeZone from 'moment-timezone';
import { connect } from "react-redux";
import * as action from "../../Store/Actions/index";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core/styles";
import addUserIcon from "../../Assets/images/add_user_icon.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import { Autocomplete, Alert } from "@material-ui/lab";
import "./AddEmployeeModal.css";
import { AxiosInstance, checkCmsLabel } from "../../Utilities";
import { upperFirst } from "lodash";
import { emailRegx,phoneRegx } from "../../SharedComponents/Validation/formValidation";
import validator from "validator";
import { Scrollbars } from 'react-custom-scrollbars';
import { Transition } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: "flex",
    overflow: "initial",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#21BF73",
        border: `1px solid #21BF73`,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);

class AddEmployeeModal extends Component {
  constructor(props) {
    super(props);
    var zoneName = momentTimeZone.tz.guess();
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var roles = userData.role;
    this.dialogContent = React.createRef(); 
    this.state = {
      roleLabelChange:[],
      timeZoneName: localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName,
      responseMsg: "",
      alertType: "",
      loggedInRole: userData.role,
      companySize:userData.companySize,
      createdBy: userData.username,
      companyOption: [
        {
          id: "",
          name: "No data",
        },
      ],
      companyName: "a",
      companyId:
        roles.toLowerCase() !== "super admin" && userData.companyId
          ? userData.companyId
          : "",
      role: "",
      levelsOption: [
        {
          id: "",
          name: "No data",
        },
      ],
      level: "",
      levelId: "",
      levelName: "",
      email: "",
      phone: "",
      userName: "",
      isLoading: false,
      isSubmit:false,
      selectedRoleSuperAdmin: false,
      formError: {
        companyId: "",
        levelId: "",
        phone: "",
        email: "",
        userName: "",
        role: "",
        level: "",
        createdBy: "",
      },
      dialog: false,
      mainDialog: this.props.openModel,
      division: [],
      area: [],
      region: [],
      territory: [],

      division_id: "",
      area_id: "",
      region_id: "",
      territory_id: "",
      levelError: "",
      isTerritoryManager:false
    };
    this.onAddUserSubmit = this.onAddUserSubmit.bind(this);
  }

  componentDidMount=()=>{
    const fetchCmsData = checkCmsLabel()
    if(fetchCmsData){
      this.setState({ 
        cmsLabelChange : fetchCmsData.cmsData,
        roleLabelChange: fetchCmsData.roleData
      })
    }
  }

  /**
   * @author Benakesh
   * @param {target element} e
   *  To set state and 
   *  call api to fetch the list of levels based on roles selected
   */
  onChangeHandler = (e) => {
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var { name, value } = e.target;
    if (name === "role") {
      this.setState({
        level: "",
        levelId: "",
        levelName: "",
        area:[],
        region:[],
        division:[],
        territory:[]
        
      });
    }
    if (name === "role") {
      this.setState({
        division_id: "",
        area_id: "",
        region_id: "",
        territory_id: "",
      });
    }
    if (
      name === "role" &&
      this.state.loggedInRole.toLowerCase() === "admin" &&
      (value.toLowerCase() !== "super admin" ||
        value.toLowerCase() !== "admin") &&
      (value.toLowerCase() === "area manager" ||
        value.toLowerCase() === "team lead" ||
        value.toLowerCase() === "sales rep" ||
        value.toLowerCase() === "division manager")
    ) {
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/json")
        .post("/get_level_list",{size:this.state.companySize.toLowerCase().trim()})
        .then((respo) => {
          if(this.state.companySize.toLowerCase() === 'large'){
            var { division } = this.state;
            this.setState({ division: [{_id:"",name:"No Division"}] });
            let response = respo && respo.data;
            if (response && response.status === 200) {
              if(response.data.length>0){ response.data.map((divi) => {
                var div = {};
                div._id = divi._id;
                div.name = divi.name;
                division.push(div);
              });
              this.setState({ division });
            }}else{
              this.setState({ division: [{_id:"",name:"No Division"}] });
            }
          }else if(this.state.companySize.toLowerCase() === 'medium'){
            var { area } = this.state;
            this.setState({ area: [{_id:"",name:"No Area"}] });
            let response = respo && respo.data;
            if (response && response.status === 200) {
              if(response.data.length>0){
              response.data.map((divi) => {
                var div = {};
                div._id = divi._id;
                div.name = divi.name;
                area.push(div);
              });
              this.setState({ area });
            }}else{
              this.setState({ area: [{_id:"",name:"No Area"}] });
            }
          }else if(this.state.companySize.toLowerCase() === 'small'){
              var { region } = this.state;
              this.setState({ region: [{_id:"",name:"No Region"}] });
              let response = respo && respo.data;
              if (response && response.status === 200) {
                if(response.data.length>0){
                  
                response.data.map((divi) => {
                  var div = {};
                  div._id = divi._id;
                  div.name = divi.name;
                  region.push(div);
                });
                this.setState({ region });
            }
          }else{
            this.setState({ region: [{_id:"",name:"No Region"}] });
          }
          }
        }).catch(err=>{
          var error=err.response;
              if(error && (error.status === '403' || error.status === 403)){
                  localStorage.clear();
                  setTimeout(()=>{
                      window.location.href="/";
                  },500)
              }else{
                  console.log("Internal Server Error")
              }
        });
    }
    if (
      name === "role" &&
      this.state.loggedInRole.toLowerCase() === "division manager" &&
      (value.toLowerCase() !== "super admin" ||
        value.toLowerCase() !== "admin" ||
        value.toLowerCase() !== "division manager") &&
      (value.toLowerCase() === "area manager" ||
        value.toLowerCase() === "team lead" ||
        value.toLowerCase() === "sales rep")
    ) {
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/json")
        .post("/get_level_list",{size:this.state.companySize.toLowerCase().trim()})
        .then((respo) => {
          var { area } = this.state;
          this.setState({ area: [{_id:"",name:"No Area"}] });
          var response = respo && respo.data;
          if (response && response.status === 200) {
            if(response.data.length>0){ response.data.map((divi) => {
              var div = {};
              div._id = divi._id;
              div.name = divi.name;
              area.push(div);
            });
            this.setState({ area });
          }}else if (
            (response && response.status === "400") ||
            response.status === 400
          ) {
            let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            this.setState({ area: [{_id:"",name:"No Area"}] });}
        }).catch(err=>{
          var error=err.response;
              if(error && (error.status === '403' || error.status === 403)){
                  localStorage.clear();
                  setTimeout(()=>{
                      window.location.href="/";
                  },500)
              }else if(error && (error.status === '500' || error.status === 500)) {
                var propsErr = {
                  message: "Something went wrong",
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propsErr);
              }
        });
    }
    if (
      name === "role" &&
      this.state.loggedInRole.toLowerCase() === "area manager" &&
      (value.toLowerCase() !== "super admin" ||
        value.toLowerCase() !== "admin" ||
        value.toLowerCase() !== "division manager" ||
        value.toLowerCase() !== "area manager") &&
      (value.toLowerCase() === "team lead" ||
        value.toLowerCase() === "sales rep")
    ) {
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/json")
        .post("/get_level_list",{size:this.state.companySize.toLowerCase().trim()})
        .then((respo) => {
          var { region } = this.state;
          this.setState({ region: [{_id:"",name:"No Region"}] });
          var response = respo && respo.data;
          if (response && response.status === 200) {
            if(response.data.length>0){ response.data.map((divi) => {
              var div = {};
              div._id = divi._id;
              div.name = divi.name;
              region.push(div);
            });
            this.setState({ region });
          }
        }else if (
            (response && response.status === "400") ||
            response.status === 400
          ) {
            let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            this.setState({ region: [{_id:"",name:"No Region"}] });
          }
        }).catch(err=>{
          var error=err.response;
              if(error && (error.status === '403' || error.status === 403)){
                  localStorage.clear();
                  setTimeout(()=>{
                      window.location.href="/";
                  },500)
              }else if(error && (error.status === '500' || error.status === 500)) {
                var propsErr = {
                  message: "Something went wrong",
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propsErr);
              }
        });
    }
    if (
      name === "role" &&
      this.state.loggedInRole.toLowerCase() === "team lead" &&
      (value.toLowerCase() !== "super admin" ||
        value.toLowerCase() !== "admin" ||
        value.toLowerCase() !== "division manager" ||
        value.toLowerCase() !== "area manager" ||
        value.toLowerCase() !== "team lead") &&
      value.toLowerCase() === "sales rep"
    ) {
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/json")
        .post("/get_level_list",{size:this.state.companySize.toLowerCase().trim()})
        .then((respo) => {
          var { territory } = this.state;
          this.setState({ territory: [{_id:"",name:"No Territory"}] });
          var response = respo && respo.data;
          if (response && response.status === 200) {
            if(response.data.length>0)
            { response.data.map((divi) => {
              var div = {};
              div._id = divi._id;
              div.name = divi.name;
              territory.push(div);
            });
            this.setState({ territory });
          }else{
            this.setState({ territory: [{_id:"",name:"No Territory"}] });
          }
          }else if (
            (response && response.status === "400") ||
            response.status === 400
          ) {
            let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        }).catch(err=>{
          var error=err.response;
              if(error && (error.status === '403' || error.status === 403)){
                  localStorage.clear();
                  setTimeout(()=>{
                      window.location.href="/";
                  },500)
              }else if(error && (error.status === '500' || error.status === 500)) {
                var propsErr = {
                  message: "Something went wrong",
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 2,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propsErr);
              }
        });
    }
    if (name === "role" && value.toLowerCase() === "super admin") {
      this.setState({
        selectedRoleSuperAdmin: true,
        companyId: userData.companyId,
      });
    } else if (
      name === "role" &&
      (value.toLowerCase() === "admin" ||
        value.toLowerCase() === "manager" ||
        value.toLowerCase() === "team lead" ||
        value.toLowerCase() === "sales rep")
    ) {
      this.setState({
        selectedRoleSuperAdmin: false,
      });
    }
    this.setState({ [name]: value });
    var { formError } = this.state;
    switch (name) {
      case "companyId":
        validator.isEmpty(value)
          ? (formError.companyId = "Please select company")
          : (formError.companyId = "");
        break;
      case "email":
        validator.isEmpty(value)
          ? (formError.email = "Please enter an email address")
          : !emailRegx.test(value)
          ? (formError.email = "Please check the email address is in valid format e.g. name@domain.com")
          : !validator.isLength(value,{min:5,max:100})
          ?(formError.email="Email length should be less than 100 character")
          :(formError.email = "");
        break;
      case "phone":
        validator.isEmpty(value)
          ? (formError.phone = "")
          :(!phoneRegx.test(value))
          ? (formError.phone = "Please enter the valid phone number e.g.: 800-555-5555")
          : (formError.phone = "");
        break;
        case "role":
          ((this.state.loggedInRole.toLowerCase() === "admin" ||
          this.state.loggedInRole.toLowerCase() === "super admin") &&
            (value.toLowerCase() === "admin" || value.toLowerCase() === "super admin"))?
          
            formError.levelId = ""
          :  formError.levelId = "";
          validator.isEmpty(value)
            ? (formError.role = "Please select a user role")
            : (formError.role = "");
          break;
      case "userName":
        validator.isEmpty(value)
          ? (formError.userName = "Please enter the Name of the person")
          : !/^[a-zA-Z,'. ]*$/.test(value)
          ? (formError.userName = "Special characters are not allowed")
          : !validator.isLength(value,{min:2,max:50})
          ? (formError.userName = "Name of the person length should be min 2 and max 50 character")
          : (formError.userName = "");

        break;
      default:
        break;
    }
    this.setState({
      formError,
    });
     if (name === "role") {
      var data = {
        companyId: this.state.companyId,
        roles: value,
      };

      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8003:'companyfilter', "application/json")
        .post("/companyfilter/getlist", data)
        .then((res) => {
          if (res.data.status === "200") {
            var levelsData = res.data.levels;
            this.setState({
              levelsOption: levelsData,
            });
          } else {
            this.setState({
              levelsOption: [{ id: 0, name: "no data" }],
            });
          }
        }).catch(err=>{
          var error=err.response;
              if(error && (error.status === '403' || error.status === 403)){
                  localStorage.clear();
                  setTimeout(()=>{
                      window.location.href="/";
                  },500)
              }else{
                  console.log("Internal Server Error")
              }
        });
    }
  };

  /**
   * @author Benakesh
   * @param {target element} e 
   *  To fetch the company names and id
   */
  companyListHandler = async (e) => {
    var name = e.target.value;
    var data = {
      companyName: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/companylist", data)
      .then((res) => {
        var response = res && res.data;
        if ( response && (response.status === "200" || response.status === 200)) {
          this.setState({
            companyOption: res.data.finalCompany,
          });
        } else if(response && (response.status === "401" || response.status === 401)){
          this.setState({
            companyOption: [
              {
                id: "",
                name: "No data",
              },
            ],
            levelsOption: [
              {
                id: "",
                name: "No data",
              },
            ],
          });
        }
      }).catch(err=>{
  			var error=err.response;
            if(error && (error.status === '403' || error.status === 403)){
                localStorage.clear();
                setTimeout(()=>{
                    window.location.href="/";
                },500)
            }else if (error && (error.status === "500" || error.status === 500)) {
              let propserror = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
            }
			});
    var { companyOption, formError } = this.state;
    var datas = await companyOption.filter((item) => {
      return item.name === name;
    });
    datas.map((companyData) => {
      this.setState({
        companyId: companyData.id,
        companyName: companyData.name,
        [formError.companyId]: "",
      });
    });
  };

  /**
   * @author Benakesh
   * @param {target element} e 
   * To set companyId and name on select in autocomplete field
   */
  onSelectHandler = (e) => {
    var name = e.target.value;
    if(!e.target.value){
      this.companyListHandler(e)
    }else{
    var { companyOption, formError } = this.state;
    var data = companyOption.filter((item) => {
      return item.name === name;
    });
    data.map((companyData) => {
      this.setState({
        companyId: companyData.id,
        companyName: companyData.name,
        [formError.companyId]: "",
      });
    });
  }
  };

  handlechecked=(e)=>{
    const {checked}=e.target;
    this.setState({
      isTerritoryManager:checked
    })
  }
 
  /**
   * @author Benakesh
   * @param {target element} e
   * Add user on click of add user button 
   */
  addUserHandler = (e) => {
    e.preventDefault();
    this.dialogContent.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
         });
    var {
      companyId,
      levelId,
      phone,
      email,
      userName,
      role,
      level,
      levelName,
      createdBy,
      formError,
      loggedInRole,
    } = this.state;

    if (
      (loggedInRole.toLowerCase() === "admin" ||
        loggedInRole.toLowerCase() === "super admin") &&
      levelId === "" &&
      (this.state.role.toLowerCase() === "admin" || this.state.role.toLowerCase() === "super admin")
    ) {
      formError.levelId = "";
    } 
    else if (
      (loggedInRole.toLowerCase() === "admin" ||
        loggedInRole.toLowerCase() === "super admin") &&
      levelId === "" &&
      (role.toLowerCase() !== "admin" || role.toLowerCase() !== "super admin")
    ) {
      formError.levelId = `Please select  ${
        this.state.role.toLowerCase() === "sales rep"?"Territory"
        :this.state.role.toLowerCase() === "team lead" ?"Region"
				: this.state.role.toLowerCase() ==="area manager"?"Area"
				: this.state.role.toLowerCase() === "division manager"?"Division"
        :""}`;
    } 
    else if (
      (loggedInRole.toLowerCase() !== "admin" ||
        loggedInRole.toLowerCase() !== "super admin") &&
      levelId === "" &&
      (role.toLowerCase() !== "admin" || role.toLowerCase() !== "super admin")
    ) {
      formError.levelId = `Please select  ${
        this.state.role.toLowerCase() === "sales rep"?"Territory"
        :this.state.role.toLowerCase() === "team lead" ?"Region"
				: this.state.role.toLowerCase() ==="area manager"?"Area"
				: this.state.role.toLowerCase() === "division manager"?"Division"
        :""}`;
    } else {
      formError.levelId = "";
    }

    if (companyId === "" && (loggedInRole.toLowerCase() !== "admin")) {
      if(role.toLowerCase() === 'super admin'){
        formError.companyId ="";
      }else{
      formError.companyId = "Please select a company";
    }
    } else {
      formError.companyId = "";
    }

    if (role === "") {
      formError.role = "Please select a user role";
    } else {
      formError.role = "";
    }

    if (email === "") {
      formError.email = "Please enter an email address";
    }else if(!validator.isLength(email,{min:2,max:100})){
      formError.email="Email length should be less than 100 character"
    }
    if (!phoneRegx.test(phone) && phone.trim() !== ""){
      formError.phone ="Please enter the valid phone number e.g.: 800-555-5555";
    }else{
      formError.phone = "";
    }
    
    if (userName === "") {
      formError.userName = "Please enter the Name of the person";
    } else if(!validator.isLength(userName,{min:2,max:50})) {
      formError.userName = "Name of the person length should be min 2 and max 50 character"
    }else if(!/^[a-zA-Z,'. ]*$/.test(userName)){
      formError.userName = "Special characters are not allowed";
    }
    else{
      formError.userName = "";
    }
    this.setState({ formError }, () => {
      var userData = {
        name: userName,
        companyId,
        role,
        levelId,
        level,
        levelName,
        email:email.toLowerCase(),
        phone,
        createdBy,
        loggedInRole,
        status: "Registered",
        timezone: this.state.timeZoneName
      };
      if(role.toLowerCase() === 'sales rep'){
        userData.isTerritoryManager=this.state.isTerritoryManager;
      }
      const isEmpty = Object.values(formError).every(
        (x) => x === null || x === ""
      );
      console.log("userData................",userData)
       if (isEmpty) {
        this.setState({ isLoading: true,isSubmit:true });
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6001:'authentication', "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/employee", userData)
          .then((res) => {
            var response = res.data;
            this.territoryManagerHandler()
            if (response && response.status === "403") {
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isLoading: false,
                isSubmit:false 
              });
            } else if (response && response.status === "201") {
              this.setState({
                responseMsg: response.msg,
                alertType: "success",
                isLoading: false,
                isSubmit:false
              });
              setTimeout(() => (
               this.props.onClose(),
               this.props.onPagination(0, 10)
              ), 3500);
            } else if (response && response.status === "409") {
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isLoading: false,
                isSubmit:false
              });
            }else{
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isLoading: false,
                isSubmit:false
              });
            }
          })
          .catch((err) => {
              var error=err.response;
                  if(error && (error.status === '403' || error.status === 403)){
                      localStorage.clear();
                      setTimeout(()=>{
                          window.location.href="/";
                      },500)
                  }else{
                      console.log("Internal Server Error")
                  }
            this.setState({
              isLoading: false,
              isSubmit:false
            });
          });
      }
    });
  };

  territoryManagerHandler = () =>{
    this.setState({
        isTerritoryManager:false
    })
  }

  onAddUserSubmit = () => {
    console.log("clicked add user sumbmit");
    // var {
    //   companyId,
    //   email,
    //   levelId,
    //   phone,
    //   email,
    //   userName,
    //   role,
    //   level,
    //   createdBy,
    // } = this.state;

    // var userData = {
    //   userName,
    //   companyId,
    //   role,
    //   levelId,
    //   level,
    //   email,
    //   phone,
    //   createdBy,
    //   status: "Registered",
    // };
  };

  /**
   * @author Benakesh
   * To show the dialog on select of Select Level button
   */
  openDialog = () => {
    this.setState({
      dialog: true,
      level: "",
      levelName: "",
      levelId: "",
      division_id: "",
      area_id: "",
      region_id: "",
      territory_id: "",
      levelError: "",
    });
  };
  
  /**
   * @author Benakesh
   * To close select level dialog
   */
  onlevelClose = () => {
    this.setState({
      dialog: !this.state.dialog,
      levelId:"",
      levelName:""
    });
  };

  /**
   * To fetch the levels on select of levels
   * @author Benakesh
   * @param {
   *    {
            level: levelName,
            levelId: levelId,
            type: next level,
          }
        } data 

   */
  onlevelfetch = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/json")
      .post("/get_level_list", data)
      .then((respo) => {
        var datas = [];
        var response = respo && respo.data;
        if (response && response.status === 200) {
          if(response.data.length >0){
          response.data.map((divi) => {
            var div = {};
            div._id = divi._id;
            div.name = divi.name;
            datas.push(div);
          });
          this.setState({ [data.type]: datas });
         }else{
          this.setState({ [data.type]: [{_id:"",name:"No Data Found"}] });
         }
        }else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      }).catch(err=>{
  			var error=err.response;
            if(error && (error.status === '403' || error.status === 403)){
                localStorage.clear();
                setTimeout(()=>{
                    window.location.href="/";
                },500)
            }else if(error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
			});
  };

  /**
   * @author Benakesh
   * @param {target elemenet} e 
   * To fetch the next possible levels on select of parent level
   * based on roles
   */
  onLevelChangeHandler = (e) => {
    var { name, value } = e.target;
    if (name === "division") {
      let levelValue = value.split("_");

      this.setState(
        {
          division_id: levelValue[0],
          level: "division",
          levelId: levelValue[0],
          levelName: levelValue[1],
        },
        () => {
          this.onlevelfetch({
            level: "division",
            levelId: this.state.division_id,
            type: "area",
          });
        }
      );
    } else if (name === "area") {
      let levelValue = value.split("_");

      this.setState(
        {
          area_id: levelValue[0],
          level: "area",
          levelId: levelValue[0],
          levelName: levelValue[1],
        },
        () => {
          this.onlevelfetch({
            level: "area",
            levelId: this.state.area_id,
            type: "region",
          });
        }
      );
    } else if (name === "region") {
      let levelValue = value.split("_");
      this.setState(
        {
          region_id: levelValue[0],
          level: "region",
          levelId: levelValue[0],
          levelName: levelValue[1],
        },
        () => {
          this.onlevelfetch({
            level: "region",
            levelId: this.state.region_id,
            type: "territory",
          });
        }
      );
    } else if (name === "territory") {
      let levelValue = value.split("_");
      this.setState({
        territory_id: levelValue[0],
        level: "territory",
        levelId: levelValue[0],
        levelName: levelValue[1],
      });
    }
  };

  /**
   * @author Benakesh
   * @param {target element} e
   * To set the state on click of Add Level button
   * based on roles 
   */
  addLevelOnClick = (e) => {
    e.preventDefault();
    var {
      loggedInRole,
      role,
     } = this.state;

    switch (loggedInRole.toLowerCase()) {
      case "admin":
        return this.validateLevel("admin", role.toLowerCase());
      case "division manager":
        return this.validateLevel("division manager", role.toLowerCase());
      case "area manager":
        return this.validateLevel("area manager", role.toLowerCase());
      case "team lead":
        return this.validateLevel("team lead", role.toLowerCase());
      default:
        break;
    }
  };

  /**
   * @author Benakesh
   * @param {logged in roles} loggedinrole 
   * @param {selected role} selectedrole 
   *  validate the level dialog form on click of Add level button
   *  to show error message if not selected
   */
  validateLevel = (loggedinrole, selectedrole) => {
    var {
      loggedInRole,
      division_id,
      area_id,
      region_id,
      territory_id,
      formError,
    } = this.state;
    var error = "";
    if (loggedinrole === loggedInRole.toLowerCase()) {
      switch (selectedrole) {
        case "division manager":
          division_id === ""
            ? (error = "Please select division")
            : (error = "");
           break;
        case "area manager":
          area_id === "" ? (error = "Please select area") : (error = "");
            break;
        case "team lead":
          region_id === "" ? (error = "Please select region") : (error = "");
           break;
        case "sales rep":
          territory_id === ""
            ? (error = "Please select a territory")
            : (error = "");
            break;
        default:
          error = "";
          break;
      }
      this.setState(
        {
          levelError: error,
        },
        () => {
          formError = { ...formError, levelId: "", level: "" };
          if (this.state.levelError === "") {
            this.setState({
              dialog: false,
              formError,
            });
          }
        }
      );
    }
  };

  /**
   * @author Benakesh
   * to clear add user dialog form on exit of dialog
   */
  clearForm = () => {
    var error = {
      companyId: "",
      levelId: "",
      phone: "",
      email: "",
      userName: "",
      role: "",
      level: "",
      createdBy: "",
    };
    this.setState(
      {
        companyName: "",
        role: "",
        level: "",
        levelId: "",
        levelName: "",
        email: "",
        phone: "",
        userName: "",
        levelError: "",
        responseMsg: "",
        formError: error,
          area:[],
        region:[],
        division:[],
        territory:[]
      }
    );
  };

  render() {
    var { formError, loggedInRole ,companySize} = this.state;
    var options = [];
    if (loggedInRole.toLowerCase() === "super admin") {
      options.push(
        { key: "2", value: "Super Admin",name: "Super Admin" },
        { key: "3", value: "Admin", name: "Admin" },
      ); 
    }
     else if (loggedInRole.toLowerCase() === "admin" && 
        (companySize.toLowerCase() !== 'medium' && 
        companySize.toLowerCase() !== 'small')) {
      options.push(
        { key: "2", value: "Admin" ,name: this.state.roleLabelChange.Admin ? this.state.roleLabelChange.Admin : "Admin"},
        { key: "3", value: "Division Manager" ,name:this.state.roleLabelChange.DivisionLeader ? this.state.roleLabelChange.DivisionLeader : "Division Leader"},
        { key: "4", value: "Area Manager" ,name:this.state.roleLabelChange.AreaDirector ? this.state.roleLabelChange.AreaDirector : "Area Director"},
        { key: "5", value: "Team Lead" ,name:this.state.roleLabelChange.RegionalManager ? this.state.roleLabelChange.RegionalManager : "Regional Manager"},
        { key: "6", value: "Sales Rep",name:this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative" }
      );
    } else if (loggedInRole.toLowerCase() === "admin" && 
      (companySize.toLowerCase() === 'small')) {
      options.push(
        { key: "2", value: "Admin" ,name:this.state.roleLabelChange.Admin ? this.state.roleLabelChange.Admin : "Admin"},
        { key: "3", value: "Team Lead" ,name:this.state.roleLabelChange.RegionalManager ? this.state.roleLabelChange.RegionalManager : "Regional Manager"},
        { key: "4", value: "Sales Rep" ,name:this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative"}
      );
    }else if (loggedInRole.toLowerCase() === "admin" && 
    (this.state.companySize.toLowerCase() === 'medium' )) {
    options.push(
      { key: "2", value: "Admin" ,name:this.state.roleLabelChange.Admin ? this.state.roleLabelChange.Admin : "Admin"},
      { key: "3", value: "Area Manager" ,name:this.state.roleLabelChange.AreaDirector ? this.state.roleLabelChange.AreaDirector : "Area Director"},
      { key: "4", value: "Team Lead" ,name:this.state.roleLabelChange.RegionalManager ? this.state.roleLabelChange.RegionalManager : "Regional Manager"},
      { key: "5", value: "Sales Rep" ,name:this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative"}
    );
    }else if (loggedInRole.toLowerCase() === "division manager") {
      options.push(
        { key: "2", value: "Area Manager" ,name:this.state.roleLabelChange.AreaDirector ? this.state.roleLabelChange.AreaDirector : "Area Director"},
        { key: "3", value: "Team Lead",name:this.state.roleLabelChange.RegionalManager ? this.state.roleLabelChange.RegionalManager : "Regional Manager" },
        { key: "4", value: "Sales Rep" ,name:this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative"}
      );
    } else if (loggedInRole.toLowerCase() === "area manager") {
      options.push(
        { key: "2", value: "Team Lead" ,name:this.state.roleLabelChange.RegionalManager ? this.state.roleLabelChange.RegionalManager : "Regional Manager"},
        { key: "3", value: "Sales Rep",name:this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative" }
      );
    } else if (loggedInRole.toLowerCase() === "team lead") {
      options.push({ key: "3", value: "Sales Rep",name:this.state.roleLabelChange.Representative ? this.state.roleLabelChange.Representative : "Representative" });
    }

    return (
      <div>
        <Dialog
          onExit={this.clearForm}
          open={this.props.openModel}
          {...this.props}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog"
          TransitionComponent={Transition} 
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <img className="add-company_img" src={addUserIcon} alt="add"/>
            <span>Add User</span>
            <IconButton
              onClick={()=>{this.props.onClose();this.territoryManagerHandler()}}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn} alt="close"/>
            </IconButton>
          </DialogTitle>
          <Scrollbars className="custom_scrollbar" style={{ width: "auto", height: 466}}>
          <DialogContent
           ref={node => {
            this.dialogContent = node;
          }}
          >
            {/* {this.state.responseMsg !== "" ? (
              <Alert severity={this.state.alertType}>
                {this.state.responseMsg}
              </Alert>
            ) : null} */}
            <form onSubmit={this.onAddUserSubmit} className="adduserform">
              <div style={{ display: this.state.isLoading ? "block" : "none" }}>
                <CircularProgress
                  variant={this.state.isLoading ? "indeterminate" : "static"}
                />
              </div>
              <label className="label bl-label">Role <span className="labelAsterisk">*</span></label>
              <FormControl margin="dense" variant="outlined" fullWidth>
                <Select
                  margin="dense"
                  className="customSelect"
                  name="role"
                  fullWidth
                  value={this.state.role}
                  defaultValue={this.state.role}
                  displayEmpty={true}
                  onChange={this.onChangeHandler}
                >
                  {options.map((mitem) => (
                    <MenuItem
                      key={mitem.key}
                      value={mitem.value}
                    >
                      {mitem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            
                 <span className="error">  {formError.role !== "" ? `*${formError.role}` : null}</span>
                 
              {this.state.loggedInRole.toLowerCase() !== "super admin" ||
              this.state.selectedRoleSuperAdmin ? (
                ""
              ) : (
                <>
                  <label className="label bl-label">Company <span className="labelAsterisk">*</span></label>
                  <Autocomplete
                    id="companyName"
                    className="cusautocomplete"
                    options={this.state.companyOption}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="dense"
                        name="companyName"
                        placeholder="Select Company"
                        onChange={this.companyListHandler}
                        onSelect={this.onSelectHandler}
                      />
                    )}
                  />
                  
                      <span className="error">{formError.companyId !== "" ? `*${formError.companyId}` : null}</span>
                    
                </>
              )}
              <label className="label bl-label">Name <span className="labelAsterisk">*</span></label>
              <CssTextField
                id="outlined-basic"
                name="userName"
                variant="outlined"
                margin="dense"
                type="text"
                fullWidth
                placeholder="Enter name"
                onChange={this.onChangeHandler}
              />
                 <span className="error">{formError.userName !== "" ?`*${formError.userName}` : null}</span>
                
              
              <label className="label bl-label">Email <span className="labelAsterisk">*</span></label>
              <CssTextField
                id="outlined-basic"
                name="email"
                variant="outlined"
                margin="dense"
                type="text"
                fullWidth
                placeholder="Enter email"
                onChange={this.onChangeHandler}
              />
              
                 <span className="error">{formError.email !== "" ? `*${formError.email}`: null}</span>
                 
              <label className="label bl-label">Phone 
              {/* <span className="labelAsterisk">*</span> */}
              </label>
              <CssTextField
                id="outlined-basic"
                name="phone"
                variant="outlined"
                margin="dense"
                type="text"
                fullWidth
                placeholder="Enter phone number"
                onChange={this.onChangeHandler}
              />
              
                   <span className="error">{formError.phone !== "" ?`*${formError.phone}`: null}</span>
               

              {this.state.levelId !== "" ? (
                  <><label className="label bl-label">
                    {upperFirst(this.state.level)}
                  </label>
                <CssTextField
                    id="outlined-basic"
                    name="selectedRole"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    fullWidth
                    aria-readonly
                    value={this.state.levelName}
                  />
                  </>
                 ) : (
                ""
              )}
              <div className="add_user_select_team">
              {this.state.role.toLowerCase() === "super admin" ||
              this.state.role.toLowerCase() === "admin" ||
              this.state.role.toLowerCase() === "" ? (
                ""
              ) : (
                  <>
                  <Button  
                  className="green-btn"
                  style={{  backgroundColor: "#21bf73",
                  boxShadow: "none",
                  color: "#ffffff",
                  font: "normal 16px/21px 'Roboto' !important",
                  textTransform: "capitalize",
                  height: "40px",
                  
                  }} onClick={this.openDialog}  variant="contained">Select {
                    this.state.role.toLowerCase() === "sales rep"?"Territory"
                    :this.state.role.toLowerCase() === "team lead" ?"Region"
                    : this.state.role.toLowerCase() ==="area manager"?"Area"
                    : this.state.role.toLowerCase() === "division manager"?"Division"
                    :""} </Button>
                  

                  {this.state.role.toLowerCase() === 'sales rep'?
                  <>
                    <FormControlLabel
                      value="false"
                      name="isTerritoryManager"
                      control={<AntSwitch color="primary" />}
                      onChange={this.handlechecked}
                      margin="dense"
                      labelPlacement="start"
                    />

                    <label className="label labelEmergency">
                      <span className="labelAsterisk ">Territory Manager</span>
                    </label>
                    </>
                 :""}
                  </>
                  
              )}
              </div>
                    <span className="error"> {this.state.role !== "" && formError.levelId !== "" ? `*${formError.levelId}`:null}</span>
                    {this.state.responseMsg !== "" ? (
              <Alert severity={this.state.alertType}>
                {this.state.responseMsg}
              </Alert>
            ) : null}
              <div className="add_company_action">
                <Button
                  className="cancel_btn white-btn"
                  onClick={()=>{this.props.onClose();this.territoryManagerHandler()}}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  className="ok_btn green-btn"
                  onClick={this.addUserHandler}
                  variant="contained"
                  disabled={this.state.isSubmit}
                >
                  {this.state.isSubmit ? <CircularProgress size={20}/> : ''} Add User
                </Button>
              </div>
            </form>
          </DialogContent>
          </Scrollbars>
        </Dialog>
        <Dialog
          open={this.state.dialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog"
          TransitionComponent={Transition} 
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <span>Select  {
        this.state.role.toLowerCase() === "sales rep"?"Territory"
        :this.state.role.toLowerCase() === "team lead" ?"Region"
				: this.state.role.toLowerCase() ==="area manager"?"Area"
				: this.state.role.toLowerCase() === "division manager"?"Division"
        :""}</span>
            <IconButton
              onClick={this.onlevelClose}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn} alt="close"/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {this.state.levelError === "" ? (
              ""
            ) : (
              <Alert severity="error">{this.state.levelError}</Alert>
            )}
            {this.state.loggedInRole.toLowerCase() === "admin" 
            && this.state.companySize.toLowerCase() === "medium"?
              <>
                 <div>
                    <label className="label bl-label">Area</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="area"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.area.length>0
                          ? this.state.area.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          :  <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>

                  {this.state.role.toLowerCase() !== "area manager"?<div>
                    <label className="label bl-label">Region</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="region"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.region.length>0
                          ? this.state.region.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          :<MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>:""}
                  {this.state.role.toLowerCase() !== "area manager" && 
                  this.state.role.toLowerCase() !== "team lead"?
                  <div>
                    <label className="label bl-label">Territory</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="territory"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.territory.length>>0
                          ? this.state.territory.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          : <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>:""}
              </>
            :""}
           
           {this.state.loggedInRole.toLowerCase() === "admin" 
            && this.state.companySize.toLowerCase() === "small"?
              <>
                  <div>
                    <label className="label bl-label">Region</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="region"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.region.length>0
                          ? this.state.region.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          :<MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>
                  {this.state.role.toLowerCase() !== "team lead"?
                  <div>
                    <label className="label bl-label">Territory</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="territory"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.territory.length>0
                          ? this.state.territory.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          : <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>:""}
              </>
            :""}

            {this.state.loggedInRole.toLowerCase() === "admin" 
            && this.state.companySize.toLowerCase() === "large"?
              <>
                <div>
                <label className="label bl-label">Division</label>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    margin="dense"
                    className="customSelect"
                    name="division"
                    fullWidth
                    onChange={this.onLevelChangeHandler}
                  >
                    {this.state.division.length>0
                      ? this.state.division.map((divi) => (
                          <MenuItem
                            value={divi._id + "_" + divi.name}
                            key={divi._id}
                          >
                            {divi.name}
                          </MenuItem>
                        ))
                      : <MenuItem value="">No Data</MenuItem>}
                  </Select>
                </FormControl>
                </div>  
                  {this.state.role.toLowerCase() !== "division manager"?
                  <div>
                    <label className="label bl-label">Area</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="area"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.area.length>0
                          ? this.state.area.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          : <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>
                  :""}
                  {this.state.role.toLowerCase() !== "division manager" &&
                  this.state.role.toLowerCase() !== "area manager"?<div>
                    <label className="label bl-label">Region</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="region"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.region.length>0
                          ? this.state.region.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          : <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>:""}
                  {this.state.role.toLowerCase() !== "division manager" &&
                  this.state.role.toLowerCase() !== "area manager" && 
                  this.state.role.toLowerCase() !== "team lead"?
                  <div>
                    <label className="label bl-label">Territory</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="territory"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.territory.length>0
                          ? this.state.territory.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          : <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>:""}
              </>
            :""}

            {this.state.loggedInRole.toLowerCase() === "division manager" &&
              (this.state.role.toLowerCase() === "team lead" ||
                this.state.role.toLowerCase() === "sales rep" ||
                this.state.role.toLowerCase() === "area manager") ? (
              <>
                <div>
                  <label className="label bl-label">Area</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      margin="dense"
                      className="customSelect"
                      name="area"
                      fullWidth
                      onChange={this.onLevelChangeHandler}
                    >
                      {this.state.area.length>0
                        ? this.state.area.map((divi) => (
                            <MenuItem
                              value={divi._id + "_" + divi.name}
                              key={divi._id}
                            >
                              {divi.name}
                            </MenuItem>
                          ))
                        : <MenuItem value="">No Data</MenuItem>}
                    </Select>
                  </FormControl>
                </div>

                {this.state.role.toLowerCase() === "team lead" ||
                this.state.role.toLowerCase() === "sales rep" ? (
                  <div
                    style={
                      this.state.area_id === ""
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <label className="label bl-label">Region</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="region"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.region.length>0
                          ? this.state.region.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          : <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                {this.state.role.toLowerCase() === "sales rep" ? (
                  <div
                    style={
                      this.state.region_id === ""
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <label className="label bl-label">Territory</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="territory"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.territory.length>0
                          ? this.state.territory.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          : <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : this.state.loggedInRole.toLowerCase() === "area manager" &&
              (this.state.role.toLowerCase() === "team lead" ||
                this.state.role.toLowerCase() === "sales rep") ? (
              <>
                <div>
                  <label className="label bl-label">Region</label>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      margin="dense"
                      className="customSelect"
                      name="region"
                      fullWidth
                      onChange={this.onLevelChangeHandler}
                    >
                      {this.state.region.length>0
                        ? this.state.region.map((divi) => (
                            <MenuItem
                              value={divi._id + "_" + divi.name}
                              key={divi._id}
                            >
                              {divi.name}
                            </MenuItem>
                          ))
                        : <MenuItem value="">No Data</MenuItem>}
                    </Select>
                  </FormControl>
                </div>

                {this.state.role.toLowerCase() === "sales rep" ? (
                  <div
                    style={
                      this.state.region_id === ""
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <label className="label bl-label">Territory</label>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        margin="dense"
                        className="customSelect"
                        name="territory"
                        fullWidth
                        onChange={this.onLevelChangeHandler}
                      >
                        {this.state.territory.length>0
                          ? this.state.territory.map((divi) => (
                              <MenuItem
                                value={divi._id + "_" + divi.name}
                                key={divi._id}
                              >
                                {divi.name}
                              </MenuItem>
                            ))
                          : <MenuItem value="">No Data</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : this.state.loggedInRole.toLowerCase() === "team lead" &&
              this.state.role.toLowerCase() === "sales rep" ? (
              <div>
                <label className="label bl-label">Territory</label>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    margin="dense"
                    className="customSelect"
                    name="territory"
                    fullWidth
                    onChange={this.onLevelChangeHandler}
                  >
                    {this.state.territory.length>0
                      ? this.state.territory.map((divi) => (
                          <MenuItem
                            value={divi._id + "_" + divi.name}
                            key={divi._id}
                          >
                            {divi.name}
                          </MenuItem>
                        ))
                      : <MenuItem value="">No Data</MenuItem>}
                  </Select>
                </FormControl>
              </div>
            ) : (
              ""
            )}
            <div className="add_company_action">
              <Button
                className="cancel_btn"
                onClick={this.onlevelClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="ok_btn"
                onClick={this.addLevelOnClick}
                variant="contained"
              >
                Select  {
        this.state.role.toLowerCase() === "sales rep"?"Territory"
        :this.state.role.toLowerCase() === "team lead" ?"Region"
				: this.state.role.toLowerCase() ==="area manager"?"Area"
				: this.state.role.toLowerCase() === "division manager"?"Division"
        :""}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo,pageSize) =>
      dispatch(
        action.userpagination(pageNo,pageSize)
      ),
  };
};
export default  connect(null,
  mapDispatchToProps
)(withRouter(AddEmployeeModal));
