import React from "react";
import "./CustomSelectWithSearchComponent.css";
import { Menu, TextField, InputAdornment } from "@material-ui/core";
import search_icon from "../../Assets/images/search_icon.svg";

const CustomSelectWithSearchComponent = (props) => {
  return (
    <Menu
      {...props.config}
      className="new_filter_modal"
      anchorEl={props.anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(props.anchorEl)}
      onClose={props.closeCustomPopup}

    >
      <div className="new_filter_search">
        <TextField
          type="search"
          placeholder={props.placeholder ? props.placeholder : "Search here"}
          size="small"
          value={props.searchValue}
          onChange={props.onSearchFilter}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img src={search_icon} alt="search" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="new_filter_list">
        {props.filterArray.length > 0 ?
          props.filterArray.map((item, index) => {
            return (
              <div className="new_filter_lists" key={item._id} onClick={() => props.handleClick(item)}>
                <span>{item.name}</span>
              </div>
            );
          }) : ""}
      </div>
    </Menu>
  );
};

export default CustomSelectWithSearchComponent;
