import { Button } from "antd";
import React from "react";
import { AxiosInstance } from "../../../Utilities";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../../Assets/images/icon-notify-success.svg";

const SaveComponent = ({ listData, keyValue, getCancelNoteList }) => {
  console.log(listData)
  const saveNotesHandler = () => {
    const newData = listData
      .map((item) => {
        if (item.text === "") {
          return undefined;
        }
        if (!item.isDeleted) {
          const { isEdited, isDeleted, index, ...newItem } = item;
          return newItem;
        }
        return undefined;
      })
      .filter(Boolean);
    console.log("newData", newData);
    updateCms(newData, keyValue);
  };

  const cancelNotesHandler = () => {
    getCancelNoteList();
  };

  const updateCms = (newData, keyValue) => {
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/cms", { [keyValue]: newData })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var props = {
            message: "Updated Successfully",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification update-notify",
          };
          NotificationComponent(props);
          getCancelNoteList();
        } else {
          var props = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          getCancelNoteList();
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  return (
    <div style={{ padding: "24px 0px", textAlign: "right" }}>
      <Button className="cancel_note_btn" onClick={cancelNotesHandler}>
        Cancel
      </Button>
      <Button className="save_note_btn" onClick={saveNotesHandler}>
        Save
      </Button>
    </div>
  );
};

export default SaveComponent;
