import axios from "axios";
export const AWS_AxiosInstance = (port, contentType) => {
  const Axios = axios.create({
    baseURL:
      process.env.REACT_APP_NODE_ENV === "development"
        ? `https://api.salesc2-dev.demo-fsit.com:${port}/api/v1`
        : process.env.REACT_APP_NODE_ENV === "qa"
        ? `http://34.236.38.97:${port}/api/v1`
        : process.env.REACT_APP_NODE_ENV === "stage"
        ? `https://stage-api-service.salesc2.com${port}`
        : process.env.REACT_APP_NODE_ENV === "production"
        ? `https://api.salesc2.com/${port}/api/v1`
        : "",
    // baseURL: `https://stage-api.salesc2.com/${port}/api/v1`,
    // baseURL: `https://api.salesc2.com/${port}/api/v1`
  });
  Axios.defaults.headers.common["Authorization"] = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : null;
  Axios.defaults.headers.common["Content-Type"] = contentType;
  Axios.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      console.log("err--", error);
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("err--", error);
      return Promise.reject(error);
    }
  );

  return Axios;
};
