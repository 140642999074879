import React, { Component, Suspense } from "react";
import "./ProductComponent.css";
import { withStyles } from "@material-ui/core/styles";
import * as action from "../../Store/Actions/index";
import Loader from "react-loader-spinner";
import {
  Typography,
  Button,
  Select,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import Delete from "../../Assets/images/delete.svg";
import Editicon from "../../Assets/images/Icon material-mode-edit.svg";
import { AxiosInstance, checkCmsLabel } from "../../Utilities";
import searchIcon from "../../Assets/images/search_icon.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { upperFirst } from 'lodash';
import carret_down from "../../Assets/images/carret_down.svg";
import "./ProductComponent.css";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { TransitionGrow} from '../../SharedComponents/CommonUtilities/CommonUtilities';
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";
var EditProductModel = React.lazy(() =>
  import("./EditProductModel/EditProductModel")
);
var AddProductModel = React.lazy(() =>
  import("./AddProductModel/AddProductModel")
);
var CustomSelectWithSearchComponent = React.lazy(() => import("../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"));
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    maxWidth: "150px"

  },
}))(Tooltip);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);

const url =  new URL(window.location.href);  
const categoryValue = url.searchParams.get('category');
const searchFilterValue = url.searchParams.get('search');
class ProductComponent extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      serviceName:'',
      categoryNameValue:'',
      cmsLabelChange:[],
      auth_username: userData.username,
      role: userData.role,
      companysiz: userData.companySize,
      auth_email: userData.email,
      auth_companyId: userData.companyId,
      createdBy: userData.username,
      vertical: "top",
      horizontal: "center",
      rowsPerPage: 10,
      page: 1,
      pageNumber:1,
      modelData: {},
      isValid: !this.props.isValid,
      showaddModal: false,
      showEditModal: false,
      make: "",
      categoryFilterlist:[],
      categorylist: [],
      model: "",
      productCode: "",
      add_categoryId:"",
      description: "",
      editmake: "",
      editcategory: "",
      categoryfilterName:"",
      editmodel: "",
      editproductCode: "",
      editid: "",
      divisionOption: [],
      divisionName: "",
      deleteRecord: "",
      categoryfId: categoryValue ? categoryValue : "",
      categoryName: "",
      divisionId: "",
      makeError: "",
      modelError: "",
      productCodeError: "",
      descError: "",
      loading: true,
      editcategoryName: "",
      editmakError: "",
      editmodelError: "",
      editproductCodeError: "",
      isvalid: true,
      disable: false,
      isSubmit: false,
      searCategoryValue: "",
      searDivisionValue: "",
      categoryConfig: {
        anchorElAssignee: null,
        config: {
          id: "category_filter_select"
        }
      },
      devisionConfig: {
        anchorElAssignee: null,
        config: {
          id: "category_filter_select"
        }
      },
      tooltipprops:{}
    };
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    window.onpopstate = () =>{
      window.location.reload()
    }
    this.setState({
      searchFilter:searchFilterValue
    })
    const queryString = this.props.location.search;
    const pageParameter = new URLSearchParams(queryString).get("page");
    const pageNumber = parseInt(pageParameter);
    this.props.onPagination(pageNumber ? pageNumber : this.state.pageNumber, this.state.rowsPerPage, categoryValue);
    this.categoryFilter();
    this.devisionFilter();
    const fetchCmsData = checkCmsLabel()
      if(fetchCmsData){
        this.setState({ cmsLabelChange : fetchCmsData.cmsData})
      }
    var data = {
      search: "",
      divisionId: this.state.divisionId,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/SearchProductCategory", {
        params:{
          search: "",
          divisionId: this.state.divisionId,
        }
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            categorylist: res.data.data,
          });
          if(categoryValue){
            const category = res.data.data.find(item => item._id === categoryValue)
            this.setState({
              categoryfilterName: category?.name ? category?.name : ""
            })
          }
        } else {
          this.setState({
            categorylist: [
              {
                id: "one",
                name: "no",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });

    this.setState({ isValid: false });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  handleClose = () => {
    this.setState({

      isValid: false,
      alertType: "",
      adderrorMassage: "",
      responseMsg: "",
      showdelete: false,
      showEditModal: false,
      showaddModal: false,
      make: "",
      model: "",
      productCode: "",
      description: "",
      editmake: "",
      editcategory: "",
      editmodel: "",
      editproductCode: "",
      makeError: "",
      modelError: "",
      productCodeError: "",
      descError: "",
      editmakError: "",
      editmodelError: "",
      categoryId: "",
      add_categoryId:"",
      categoryName: "",
      editproductCodeError: "",
      disable: false
    });
  };
  addmakehandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (!/\S\w*/.test(e.target.value)) {
      this.setState({ makeError: "* Please enter model" })
    } else if (e.target.value.length > 100) {
      this.setState({ makeError: "* Make should not exceed 100 characters" })
    } else {
      this.setState({ makeError: "" })
    }
   
  };
  getCategory = async (e) => {
    var name = e.target.value;
    var data = {
      search: name,
      divisionId: this.state.divisionId,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/SearchProductCategory", {
        params:{
          search: name,
          divisionId: this.state.divisionId,
        }
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            categorylist: res.data.data,
          });
        } else {
          this.setState({
            categorylist: [
              {
                id: "one",
                name: "no",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  };
  selectCategory = (e) => {
    var name = e.target.value;
    if (name !== "") {
      var { categorylist } = this.state;
      var data = categorylist.filter((item) => {
        return item.name === name;
      });
      data.map((productData) => {
        this.setState({
           add_categoryId: productData._id,
          categoryName: productData.name,
          editcategory: productData._id,
        });
      });
    }
  };
  selectCategoryFilter = (e) => {
    var name = e.target.value;
    if (name !== "") {
      var { categorylist } = this.state;
      var data = categorylist.filter((item) => {
        return item.name === name;
      });
      data.map((productData) => {
        this.setState(
          {
            categoryId: productData._id,
            categoryName: productData.name,
          },
          this.filter
        );
      });
    }
  };
  categAutoClose = (e) => {
    // var name = e.target.value;
    // if (typeof name === "undefined") {
      this.setState({ add_categoryId: "", categoryName: "" , editcategory: "" },
      );
    // }
  }
  adddeshandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length >250) {
      this.setState({ descError: "* Description length should be  max 250 character" })
    } else {
      this.setState({ descError: "" })

    }
  }
  selectCategoryAutoClose = (e) => {
    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ categoryId: "" }, this.filter);
    }
  };

  addmodelhandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length > 100) {
      this.setState({ modelError: "* Make should not exceed 100 characters" })
    } else {
      this.setState({ modelError: "" })
    }
  }
  addprohandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length > 100) {
      this.setState({ productCodeError: "* Product should not exceed 100 characters" })
    } else {
      this.setState({ productCodeError: "" })
    }
  }
  onAddProductSubmit = (e) => {
    e.preventDefault();
    var { make, model, productCode, description,
      add_categoryId,
      makeError,
      modelError,
      productCodeError,
      descError } = this.state;
    var productData = {
      name: make,
      model: model,
      productCode: productCode,
      description: description,
      categoryId: add_categoryId,
    };
    if (!this.state.isValid && makeError === ""
      && modelError === "" && productCodeError === ""
      && descError === ""
    ) {
      this.setState({ disable: true })
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/product", productData)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false,
            },
            () => {
              var props = {
                message: "Product added successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.handleClose()
              this.filter();
            }
          );
         
        } else {
          this.setState({ disable: false, })
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.filter();
        }
       
      })
    }
  };
  edithandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    var { name, value } = e.target;
    if (name === "editmake" && value.length > 100) {
      this.setState({ editmakError: "* Make should not exceed 100 characters" })
    } else {
      this.setState({ editmakError: "" })
    }
    if (name === "editmake" && !/\S\w*/.test(e.target.value)) {
      this.setState({ editmodelError: "* Please enter model" })
    } else if (e.target.value.length > 100) {
      this.setState({ editmodelError: "* Make should not exceed 100 characters" })
    } else {
      this.setState({ editmodelError: "" })
    }
    if (name === "editproductCode" && value.length > 100) {
      this.setState({ editproductCodeError: "* ProductCode should not exceed 100 characters" })
    } else {
      this.setState({ editproductCodeError: "" })
    }
  };
  editProductSubmit = (e) => {
    e.preventDefault();
    var { editmakError, editmodelError, editproductCodeError } = this.state
    var productData = {
      _id: this.state.editid,
      name: this.state.editmake,
      model: this.state.editmodel,
      productCode: this.state.editproductCode,
      description: this.state.editdescription,
      categoryId: this.state.editcategory,
    };
    if (!this.state.isValid && editmakError === ""
      && editmodelError === "" && editproductCodeError === "") {
      this.setState({ disable: true })
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/update_product", productData)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false,
            },
            () => {
              var props = {
                message: "Product updated Successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.handleClose()
              this.filter();
            }
          );
         
        } else {
          this.setState({ disable: false, })
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.filter();
        }
       
      })
   }
  };
  onViewHandleClick = (row) => {
    console.log("abcccc",row)
    this.setState({
      editid: row._id,
      editmake: row.name,
      editcategory: row.categoryId,
      editcategoryName: [{ 'name': row.category }],
      editmodel: row.model,
      editproductCode: row.productCode,
      showEditModal: true,
    });
    setTimeout(() => {
      this.setState({   editcategory: row.categoryId,});
    }, 100);
  };
  onDeleteHandleClick = (row) => {
    this.setState({ showdelete: true, deleteRecord: row._id });
  };
  onDelete = () => {
    this.setState({ disable: true })
    var productData = {
      id: this.state.deleteRecord,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/delete_product", productData)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false
            },
            () => {
              var props = {
                message: res.data.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.filter();
            }
          );
          this.componentDidMount();
        } else {
          this.setState({ disable: false })
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.filter();
        }
      })
  };
  onclickBtn = (e) => {
    const { make, add_categoryId } = this.state;
    if (
      make === "" ||
      add_categoryId === ""

    ) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  oneditclickBtn = () => {
    const { editmake, editcategory } = this.state;
    if (
      editmake === "" ||
      editcategory === ""
    ) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  handleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ pageNumber: newPage}, this.filter)
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ pageNumber:1, rowsPerPage: +event.target.value },
      this.filter)
  };
  DivisionFilter = async (e) => {
    var name = e.target.value;
    var data = {
      divisionName: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/service_division_search", data)
      .then((res) => {
        if (res.data.status === "200") {
          this.setState({
            divisionOption: res.data.data,
          });
        } else {
          this.setState({
            divisionOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  };
  onDivisionSelectHandler = (e) => {
    var name = e.target.value;
    if (name !== "") {
      var { divisionOption } = this.state;
      var data = divisionOption.filter((item) => {
        return item.name === name;
      });
      data.map((devisionData) => {
        this.setState(
          {
            divisionId: devisionData._id,
            divisionName: devisionData.name,
            isvalid: false,
          },
          this.filter,
        );
        setTimeout(() => {
          this.componentDidMount();
          this.filter();
        }, 100);
      });
    }

  };
  DivisionAutoClose = (e) => {

    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ companyId: "", categoryName: "", divisionId: "", isvalid: true, },
        this.filter
      );
    }
  };
  serviceSearch = (e) => {
    let name = e.target.value;
    this.setState({ nextpage:1, serviceName: name }, this.filter);
  };
  serviceTypefilter = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.filter);
  };
  filter = () => {
    this.setState({ loading: true });
    let data = {
      pagesize: this.state.rowsPerPage,
      nextpage: this.state.nextpage ? this.state.nextpage : this.state.pageNumber,
      search: this.state.serviceName,
      divisionId: this.state.divisionId,
      categoryId: this.state.categoryfId,
    };
    this.props.productfilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
    this.updateURL()
  };

  updateURL = () => {
    const {categoryfId, categoryNameValue, serviceName} = this.state
    const url =  new URL(window.location.href);  
    const queryParams = {
      category: categoryfId || categoryNameValue || url.searchParams.get('category') || '',
      search: serviceName || searchFilterValue || ''
    };

    const filteredParams = Object.entries(queryParams)
    .filter(([key, value]) => value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    this.props.history.push(`/products?page=${this.state.pageNumber?this.state.pageNumber:'1'}&${filteredParams}`);
  }
  /**
   * 
   * TO HANDLE ASSIGNEE MENU OPEN
   */
  openCategoryFilterMenu = (event) => {
    const { categoryConfig } = this.state;
    categoryConfig.anchorElAssignee = event.currentTarget;
    this.setState({
      categoryConfig
    }, () => {
      this.categoryFilter("");
    });
  }
  /**
  * 
  * TO HANDLE Category MENU CLOSE
  */
  closeCategoryFilterMenu = () => {
    const { categoryConfig } = this.state;
    categoryConfig.anchorElAssignee = null;
    this.setState({
      categoryConfig
    })
  }
  /**
   * 
   * @param {item object} item 
   * TO ADD Category FILTER VALUE ON SELECT
   */
  handleCategorySelect = (item) => {
    const { categoryConfig, categoryNameValue } = this.state;
    categoryConfig.anchorElAssignee = null;
    this.setState({
      categoryfId: item._id,
      categoryfilterName: item.name === "All" ? "" : item.name,
      categoryNameValue: item.name === "All" ? "All" : item.name,
      categoryConfig,
      searCategoryValue: "",
      pageNumber: 1,
    }, () => {
      this.filter();
    });
  }
  /**
  * 
  * @param {item object} item 
  * TO HANDLE Category FILTER VALUE ON SEARCH
  */
  onCategorySearchFilter = (e) => {
    const {value } = e.target;
    this.setState({
      searCategoryValue: value
    })
    this.categoryFilter(value);
  }
  /**
  * 
  * @param {value} search 
  * TO FETCH THE Category FILTER
  */
  categoryFilter = (search) => {
    const { categoryFilterlist } = this.state;
    var Data = {
      search,
      divisionId: this.state.divisionId,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/SearchProductCategory",{
        params:{
          search,
          divisionId: this.state.divisionId,
        }
      })
      .then((res) => {
        var response = res.data;
        if (response && (response.status === 200 || response.status === "200")) {
          var data = response.data;
          var newArray = categoryFilterlist.splice();
          if (this.state.searCategoryValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map(item => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            categoryFilterlist: newArray,
          });
        } else {
          this.setState({
            categoryFilterlist: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  }
  /**
 * 
 * @param {item object} item 
 * TO HANDLE Devision FILTER VALUE ON SEARCH
 */
  onDevisionSearchFilter = (e) => {
    const {value } = e.target;
    this.setState({
      searDivisionValue: value
    })
    this.devisionFilter(value);
  }
  /**
  * 
  * TO HANDLE Devision MENU CLOSE
  */
  closeDevisionFilterMenu = () => {
    const { devisionConfig } = this.state;
    devisionConfig.anchorElAssignee = null;
    this.setState({
      devisionConfig
    })
  }
  /**
   * 
   * @param {item object} item 
   * TO ADD Devision FILTER VALUE ON SELECT
   */
  handleDevisionSelect = (item) => {
    const { devisionConfig } = this.state;
    devisionConfig.anchorElAssignee = null;
    this.setState({
      divisionId: item._id,
      divisionName: item.name === "All" ? "" : item.name,
      devisionConfig,
      searDivisionValue: ""
    }, () => {
      this.filter();
    });
  }

  /**
  * 
  * TO HANDLE Devision MENU OPEN
  */
  openDevisionFilterMenu = (event) => {
    const { devisionConfig } = this.state;
    devisionConfig.anchorElAssignee = event.currentTarget;
    this.setState({
      devisionConfig
    }, () => {
      this.devisionFilter("");
    });
  }
  /**
    * 
    * @param {value} search 
    * TO FETCH THE DEVISION FILTER
    */
  devisionFilter = (search) => {
    const { divisionOption } = this.state;
    var Data = {
      search,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/service_division_search", Data,)
      .then((res) => {
        var response = res.data;
        if (response && (response.status === 200 || response.status === "200")) {
          var data = response.data;
          var newArray = divisionOption.splice();
          if (this.state.searCategoryValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map(item => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            divisionOption: newArray,
          });
        } else {
          this.setState({
            divisionOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  }
  render() {
    console.log(this.props.pager.currentPage,this.state.pageNumber)
    let { loading } = this.state;
    let PendingRegistrations = (
      <div className="body-bg-color">
        <HeaderComponent/>
        <Suspense fallback={<div></div>}>
        <EditProductModel
          getCategory={this.getCategory}
          selectCategory={this.selectCategory}
          modelData={this.state}
          open={this.state.showEditModal}
          handleClose={this.handleClose}
          edithandleChange={this.edithandleChange}
          editProductSubmit={this.editProductSubmit}
          oneditclickBtn={this.oneditclickBtn}
          row={this.state.modalRecord}
          categAutoClose={this.categAutoClose}
        />
        <AddProductModel
          getCategory={this.getCategory}
          modelData={this.state}
          open={this.state.showaddModal}
          handleClose={this.handleClose}
          addmakehandleChange={this.addmakehandleChange}
          onAddProductSubmit={this.onAddProductSubmit}
          selectCategory={this.selectCategory}
          onclickBtn={this.onclickBtn}
          addmodelhandleChange={this.addmodelhandleChange}
          addprohandleChange={this.addprohandleChange}
          adddeshandleChange={this.adddeshandleChange}
           categAutoClose={this.categAutoClose}
        />
          </Suspense>
        
        <Dialog 
          open={this.state.showdelete} 
          className="deleteServiceDialog"
          TransitionComponent={TransitionGrow} 
          transitionDuration={600}>
          <DialogTitle>
            {" "}
            <center>Delete Product</center>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <center>
                {" "}
                Are you sure you want to delete this Product? This action cannot be undone.
              </center>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="cancel"
              onClick={this.handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button disabled={this.state.disable}
              className="delete"
              onClick={() => this.onDelete()}
              style={{ color: "red" }}
              autoFocus
            >
              {this.state.disable ? <CircularProgress size={20} /> : ''}   Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="header-add-btn header-add-btn-service header-add-btn-product">
          <Typography variant="h5" className="tableTitle" component="div">
          {this.state.cmsLabelChange.Product ? this.state.cmsLabelChange.Product : "Products"}
          </Typography>
          {this.state.role.toLowerCase() === 'admin' || this.state.role.toLowerCase() === ' super admin' ?
            <>
              <div className="products_btn_right">
                <Button
                  variant="contained"
                  className="tableaddBtn add_product_main_btn grey-btn"
                  color="default"
                  style={{ boxShadow: "none" }}
                  onClick={() => this.setState({ showaddModal: true })}
                >
                  Add Product
            </Button>
                <NavLink to= "/product-category">
                 <Button
                    variant="contained"
                    className="tableaddBtn grey-btn"
                    color="default"
                    style={{ boxShadow: "none" }}>
                    Product Category
                  </Button>
                </NavLink>
              </div>
            </>
            : ''}
        </div>
        <div className="Acc-Table">
          <div className="filter-search">
            <div style={{ display: "flex" }}>
              {(this.state.role.toLowerCase() === "super admin" &&
                this.state.companysiz.toLowerCase() === "large") ||
                (this.state.role.toLowerCase() === "admin" &&
                  this.state.companysiz.toLowerCase() === "large") ? (
                  <>
                    <div className="new_custom_filters">
                      <Suspense fallback={<div></div>}>
                        <div className="new_custom_filter_div"
                          onClick={this.openDevisionFilterMenu}
                        >
                          {this.state.divisionName !== "" ? "" : <label className="leftName">Division : </label>}
                          <label
                            aria-label="assignee_filter"
                            aria-controls="assignee_filter_select"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.divisionName === "" ? `All` : upperFirst(this.state.divisionName)}

                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectWithSearchComponent
                          filterArray={this.state.divisionOption}
                          config={this.state.devisionConfig.config}
                          anchorEl={this.state.devisionConfig.anchorElAssignee}
                          searchValue={this.state.searDivisionValue}
                          handleClick={this.handleDevisionSelect}
                          closeCustomPopup={this.closeDevisionFilterMenu}
                          onSearchFilter={this.onDevisionSearchFilter}
                          placeholder="Search Division"
                        />
                      </Suspense>
                    </div>
                  </>
                ) : (
                  ""
                )}
              <div className="new_custom_filters">
                <Suspense fallback={<div></div>}>
                  <div className="new_custom_filter_div"
                    onClick={this.openCategoryFilterMenu}
                  >
                    {this.state.categoryfilterName !== "" ? "" : <label className="leftName">Category : </label>}
                    <label
                      aria-label="assignee_filter"
                      aria-controls="assignee_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.categoryfilterName === "" ? `All` : upperFirst(this.state.categoryfilterName)}

                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <span className="ffffffff">
                  <CustomSelectWithSearchComponent
                    filterArray={this.state.categoryFilterlist}
                    config={this.state.categoryConfig.config}
                    anchorEl={this.state.categoryConfig.anchorElAssignee}
                    searchValue={this.state.searCategoryValue}
                    handleClick={this.handleCategorySelect}
                    closeCustomPopup={this.closeCategoryFilterMenu}
                    onSearchFilter={this.onCategorySearchFilter}
                    placeholder="Search Category"
                  />
                  </span>
                </Suspense>
              </div>
            </div>
            <CssTextField
              id="outlined-basic"
              name="serviceName"
              variant="outlined"
              type="text"
              placeholder="Search Here"
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon}
                     alt="searchIcon"
                    />
                  </InputAdornment>
                ),
              }}
              value={this.state.searchFilter}
              onChange={this.serviceSearch}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '5%' }}>#</TableCell>
                    <TableCell style={{ width: '30%' }}>MODEL</TableCell>
                    <TableCell style={{ width: '15%' }}>CATEGORY</TableCell>
                    <TableCell style={{ width: '20%' }}>PRODUCT CODE</TableCell>
                    {this.state.role.toLowerCase() === 'admin' || this.state.role.toLowerCase() === ' super admin' ?
                      <TableCell>ACTION</TableCell>
                      : ''}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ?
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={loading}
                        />
                      </TableCell>
                    </StyledTableRow> :
                    !this.props.pageOfItems || this.props.pageOfItems.length === 0 ?
                      <StyledTableRow><TableCell colSpan={9} style={{ textAlign: 'center' }}>
                        No data found
                      </TableCell></StyledTableRow> : (this.state.rowsPerPage > 0
                        ? this.props.pageOfItems.slice()

                        : this.props.pageOfItems
                      ).map((row, index) => (
                        <StyledTableRow key={row._id}>
                          <TableCell>
                            {(this.props.pager.currentPage - 1) *
                              this.state.rowsPerPage +
                              (index + 1)}
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: "0",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }} >
                            <LightTooltip placement="bottom-start" {...this.state.tooltipprops} title={row.name} arrow >
                              <span> {row.name ? row.name.charAt(0).toUpperCase() + row.name.slice(1) : '--'}</span>
                            </LightTooltip>
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: "0",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <LightTooltip placement="bottom-start" {...this.state.tooltipprops} title={row.category ? row.category.charAt(0).toUpperCase() + row.category.slice(1) : 'Uncategorized'} arrow  >
                              <span> {row.category ? row.category.charAt(0).toUpperCase() + row.category.slice(1) : '--'}</span>
                            </LightTooltip>
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: "0",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}>          
                            <LightTooltip placement="bottom-start" {...this.state.tooltipprops} title={row.productCode ? row.productCode : '--'} arrow >
                              <span href={"mailto:" + row.productCode ? row.productCode : '--'}>{row.productCode ? row.productCode : '--'}</span>
                            </LightTooltip>
                          </TableCell>
                          {this.state.role.toLowerCase() === 'admin' || this.state.role.toLowerCase() === ' super admin' ?
                            <TableCell>
                              <div
                                className="table_action_btns"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <div>
                                <LightTooltip title="Delete" {...this.state.tooltipprops} arrow>
                                
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.onDeleteHandleClick(row)}
                                    src={Delete}
                                    alt="add"
                                  ></img>
                                 
                                  </LightTooltip>
                                </div>
                                <div></div>
                                <div>
                                <LightTooltip title="Edit Product" {...this.state.tooltipprops} arrow>
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.onViewHandleClick(row)}
                                    src={Editicon}
                                    alt="openmenu"
                                  ></img>
                                    </LightTooltip>
                                </div>
                              </div>
                            </TableCell>
                            : ''}
                        </StyledTableRow>
                      ))}
                </TableBody>
                <TableFooter>
                  {this.props.pageOfItems &&
                      this.props.pageOfItems.length !== 0?
                  <TableRow>
                    <td colSpan={20} className="pagination_container">
                    <CustomPaginationComponent
                    count={this.props.pager.endPage}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.props.pager.currentPage? this.props.pager.currentPage : this.state.pageNumber}
                    onPageChange={this.handleCustomPaginagtionChange}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                  </td>
                  </TableRow>
                  :null}
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div >
    );
    return <div>{PendingRegistrations}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
    responseData: state.pagination.responseData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole, category) =>
      dispatch(action.productpagination(pageNo, pagerole, category)),
    productfilter: (value) => dispatch(action.productfilter(value)),
    AddProduct: (value) => dispatch(action.AddProduct(value)),
    updateProduct: (value) => dispatch(action.updateProduct(value)),
    deleteProduct: (value) => dispatch(action.deleteProduct(value)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductComponent));
