import React from "react";
import "../../HeaderComponent/HeaderComponent.css";
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import momentTimeZone from 'moment-timezone';
import {upperFirst} from 'lodash';
import {TransitionGrow} from "../../CommonUtilities/CommonUtilities";
import iconClose from "../../../Assets/images/close_btn.svg";
import logo from "../../../Assets/images/Sales_C2_logo.svg";
import taskAssignThree from "../../../Assets/images/task_asign_three.png";
import taskWarningIcon from "../../../Assets/images/task-warning-big-icon.svg";
const TaskConflictModal = (props) => {
    return (
        <Dialog
        open={props.taskConflictModalOpen}
        aria-labelledby="form-dialog-title"
        className={
          `add_company_dialog 
          task_assignment_dialog 
          update_status_dialog 
          task_conflict_border
          task_assignment_accept_dialog
          ${
            props.taskConflictModalData && props.taskConflictModalData.conflict
              ? "task_conflict"
              : ""
          }
          ${
            props.taskConflictModalData &&
            props.taskConflictModalData.conflict &&
            props.taskConflictModalData.priority
              ? "task_priority_conflict"
              : ""
          }
          `}
        TransitionComponent={TransitionGrow} 
        transitionDuration={600}
      >
        <DialogTitle
          id="form-dialog-title"
          className="add_user_header task_assignment_header task_assignment_modal_header"
        >
          <img src={logo} alt="Sales-Icon" width="142px" height="auto"></img>
          <IconButton
            aria-label="close"
            className="closeBtn updateStatusCloseBtn"
            onClick={props.openConflictModalClose}
          >
            <img src={iconClose}></img>
          </IconButton>
        </DialogTitle>
        <DialogContent className="task_assignment_modal_content">
          <div className="task_assign_model_container">
            {props.taskConflictModalData ?
              <Grid container spacing={4}>
                <Grid item xs={12} sm={5}>
                  <img className="task-img-width" src={taskAssignThree}></img>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="tasking-assign-right">
                    <h1>{
                      props.taskConflictModalData.type === "0" || props.taskConflictModalData.type === 0
                        ?"Task Assignment"
                      :props.taskConflictModalData.type === "1" || props.taskConflictModalData.type === 1
                        ?"Conflict Found":""}</h1>
                    <p><img src={taskWarningIcon}></img>
                    {props.taskConflictModalData.type === "0" ||  props.taskConflictModalData.type === 0? 
                    `This task conflicts with ${upperFirst(props.taskConflictModalData.conflictName)}.`
                    :props.taskConflictModalData.type === "1" || props.taskConflictModalData.type === 1
                    ?`This event conflicts with ${upperFirst(props.taskConflictModalData.conflictName)}.`
                    :""}
                    </p>
                    <span>{`
                  ${momentTimeZone(props.taskConflictModalData.startDate).tz(props.timeZoneName).format('MMMM DD YYYY')} 
                  at 
                  ${momentTimeZone(props.taskConflictModalData.startDate).tz(props.timeZoneName).format('hh:mm A')} 
                  - 
                  ${momentTimeZone(props.taskConflictModalData.endDate).tz(props.timeZoneName).format('hh:mm A')} 
                  (${props.diffTime ? props.diffTime : ""})`}</span>
                  </div>
                  <div className="add_company_action task_assign_action task_reassign_action task_assign_action_buttons ">
                    <Button className="cancel_btn white-btn skip-btn" onClick={props.onSkipTaskHandler}>Skip</Button>
                    <Button className="ok_btn green-btn" onClick={() => props.onRescheduleTaskHandler(props.taskConflictModalData)}>Reassign/Reschedule</Button>
                   </div>
                </Grid>
              </Grid>
              : ""}
          </div>
        </DialogContent>
      </Dialog>
    );
}
 
export default TaskConflictModal;