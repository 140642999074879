import React from "react";
const BillingSideBarComponent = (props) => {
  return (
    <ul className="billing_list">
      {props.userData && props.userData.role.toLowerCase() !== "super admin" ?
        !props.showConfigure?
          <>
          <li className="billing_list_item" onClick={() => props.onClickHandler(1)}>
            <a className={`configure_btn ${props.compNo === 1 ? 'active' : ""}`}>Overview</a>
          </li>
          <li className="billing_list_item" onClick={() => props.onClickHandler(2)}>
            <a className={`configure_btn ${props.compNo === 2 ? 'active' : ''}`}>Billing Details</a>
          </li>
          <li className="billing_list_item" onClick={() => props.onClickHandler(3)}>
            <a className={`configure_btn ${props.compNo === 3 ? 'active' : ""}`}>Billing History</a>
          </li>
          <li className="billing_list_item" onClick={() => props.onClickHandler(7)}>
            <a className={`configure_btn ${props.compNo === 7 ? 'active' : ""}`}>Manage Subscription</a>
          </li>
          </>:
            <li className="billing_list_item" onClick={() => props.onClickHandler(5)}>
              <a className={`configure_btn ${props.compNo === 5 ? 'active' : ""}`}>Configure Billing</a>
            </li>         
        :
        <>
          <li className="billing_list_item" onClick={() => props.onClickHandler(6)}>
            <a className={`configure_btn ${props.compNo === 6 ? 'active' : ""}`}>Subscriptions</a>
          </li>
          <li className="billing_list_item" onClick={() => props.onClickHandler(8)}>
            <a className={`configure_btn ${props.compNo === 8 ? 'active' : ""}`}>Payment Status</a>
          </li>
        </>
      }
    </ul>
  );
};

export default BillingSideBarComponent;
