import React, { useState, useEffect } from "react";
import { Col, Collapse, Row } from "antd";
import "./CMSComponent.css";
import CancelNoteComponent from "./CancelNoteComponent";
import AccountLabelComponent from "./AccountLabelComponent";
import { AxiosInstance } from "../../../Utilities";

const CMSComponent = () => {
  const { Panel } = Collapse;

  const accountLabelChange = [
    { Service: "", isEdited: false, value:"service" },
    { Department: "", isEdited: false, value:"department" },
    { "Patient Name": "", isEdited: false, value:"patientName", checked: false },
    { "Case Number": "", isEdited: false, value:"caseNumber", checked: false },
    { Description: "", isEdited: false, value:"description" },
    { Account : "", isEdited: false, value:"account" },
    { Product : "", isEdited: false, value:"product" },
    { Amount : "", isEdited: false, value:"amount" },
    { "Price ": "", isEdited: false, value:"price", checked: false },
    { "Quantity ": "", isEdited: false, value:"quantity", checked: false },
    { Item : "", isEdited: false, value:"item" }
  ];

  const roleLabelChange = [
    { Admin: "", isEdited: false, value:"admin" },
    { "Division Leader": "", isEdited: false, value:"divisionLeader" },
    { "Area Director": "", isEdited: false, value:"areaDirector" },
    { "Regional Manager": "", isEdited: false, value:"regionalManager" },
    { "Territory Manager": "", isEdited: false, value:"territoryManager" },
    { Representative: "", isEdited: false, value:"representative" },
  ];

  const [cmsModule, setCmsModule] = useState();
  const [cmsCancelNoteData, setCmsCancelNoteData] = useState();
  const [cmsLabelData, setCmsLabelData] = useState();
  const [cmsRoleLabelData, setCmsRoleLabelData] = useState();

  useEffect(() => {
    setCmsModule(cmsModule);
    getCancelNoteList();
  }, []);

  const getCancelNoteList = () => {
    AxiosInstance(
      process.env.REACT_APP_NODE_ENV === "development" ||
        process.env.REACT_APP_NODE_ENV === "qa"
        ? 6008
        : "task",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .get("/cms")
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          initializeCmsModule(response.data[0]);
          const roleLabelData = response.data[0]?.roleChange.label;
          const labelData = response.data[0]?.labelChange.label;
          const cancelData = response.data[0]?.canceledNotes.notes;
          const updatedCancelList =
            cancelData &&
            cancelData.map((item, index) => ({
              ...item,
              index: index + 1,
              isEdited: false,
              isDeleted: false,
            }));
          setCmsCancelNoteData(updatedCancelList);
          if (labelData && labelData.length > 0) {
            const updatedLabel = accountLabelChange.map((item) => {     // Condition to add new field, if any update in accountLabelChange array
              const existingLabel = labelData.find(
                (labelItem) => labelItem.value === item.value
              );
          
              return existingLabel || item;     // Use the existing label if it exists, otherwise use the new one
            });

            const cmsLabelDataArray = updatedLabel.map((data, index) => ({
              ...data,
              index: index + 1,
              isEdited: false,
            }));
            setCmsLabelData(cmsLabelDataArray);
          } else {
            setCmsLabelData(accountLabelChange);
          }
          if (roleLabelData && Object.keys(roleLabelData).length > 0) {
            const cmsRoleLabelDataArray = Object.keys(roleLabelData).map(
              (key, index) => ({
                ...roleLabelData[key],
                isEdited: false,
                index: index + 1,
              })
            );
            setCmsRoleLabelData(cmsRoleLabelDataArray);
          } else {
            setCmsRoleLabelData(roleLabelChange);
          }
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  const initializeCmsModule = (cmsData) => {
    const cmsModuleData = [
      {
        index: 1,
        name: "Reasons for Cancellation",
        lastUpdatedName:
          cmsData?.canceledNotes?.noteUpdatedBy?.employeeName || "-",
        date: cmsData?.canceledNotes?.noteUpdatedBy?.updatedAt
          ? new Date(
              cmsData.canceledNotes.noteUpdatedBy.updatedAt
            ).toLocaleDateString()
          : "-",
        time: cmsData?.canceledNotes?.noteUpdatedBy?.updatedAt
          ? new Date(
              cmsData.canceledNotes.noteUpdatedBy.updatedAt
            ).toLocaleTimeString()
          : "-",
        activeStatus: false,
      },
      {
        index: 2,
        name: "Task Labels",
        lastUpdatedName: cmsData?.labelChange?.updatedBy?.employeeName || "-",
        date: cmsData?.labelChange?.updatedBy?.updatedAt
          ? new Date(
              cmsData.labelChange.updatedBy.updatedAt
            ).toLocaleDateString()
          : "-",
        time: cmsData?.labelChange?.updatedBy?.updatedAt
          ? new Date(
              cmsData.labelChange.updatedBy.updatedAt
            ).toLocaleTimeString()
          : "-",
        activeStatus: false,
      },
      {
        index: 3,
        name: "User Roles",
        lastUpdatedName: cmsData?.roleChange?.updatedBy?.employeeName || "-",
        date: cmsData?.roleChange?.updatedBy?.updatedAt
          ? new Date(
              cmsData.roleChange.updatedBy.updatedAt
            ).toLocaleDateString()
          : "-",
        time: cmsData?.roleChange?.updatedBy?.updatedAt
          ? new Date(
              cmsData.roleChange.updatedBy.updatedAt
            ).toLocaleTimeString()
          : "-",
        activeStatus: false,
      },
    ];
    setCmsModule(cmsModuleData);
  };

  return (
    <div className="cms_container">
      <span className="cms_label">Customization</span>
      <Collapse accordion>
        {cmsModule &&
          cmsModule.map((item) => (
            <Panel
              className="custom-panel"
              header={
                <Row className="cms_rows">
                  <Col className="module_name">{item.name}</Col>
                  <Col className="module_updated_date">
                    {item.lastUpdatedName !== "-" && (
                      <>
                        <span className="last_updated_by">
                          {" "}
                          Last update by :{" "}
                        </span>
                        <span className="date_time">{`${item.lastUpdatedName} | ${item.date} | ${item.time}`}</span>
                      </>
                    )}
                    <i
                      className={`arrow-icon ${
                        item.activeStatus ? "up" : "down"
                      }`}
                    />
                  </Col>
                </Row>
              }
              key={item.index}
            >
              {item.index === 1 ? (
                <CancelNoteComponent
                  cmsCancelNoteData={cmsCancelNoteData}
                  getCancelNoteList={getCancelNoteList}
                />
              ) : item.index === 2 || item.index === 3 ? (
                <AccountLabelComponent
                  value={item.index}
                  cmsLabelData={cmsLabelData}
                  cmsRoleLabelData={cmsRoleLabelData}
                  getCancelNoteList={getCancelNoteList}
                />
              ) : (
                <div>No data</div>
              )}
            </Panel>
          ))}
      </Collapse>
    </div>
  );
};

export default CMSComponent;
