import React, { Component, Suspense } from "react";
import "./ServicesComponent.css";
import { withStyles } from "@material-ui/core/styles";
import * as action from "../../Store/Actions/index";
import Loader from "react-loader-spinner";
import {
  Tooltip,
  Typography,
  Button,
  Select,
  CircularProgress,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import Delete from "../../Assets/images/delete.svg";
import OpenMenu from "../../Assets/images/Icon material-mode-edit.svg";
import { AxiosInstance, checkCmsLabel } from "../../Utilities";
import searchIcon from "../../Assets/images/search_icon.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import { upperFirst } from "lodash";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import carret_down from "../../Assets/images/carret_down.svg";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { TransitionGrow} from '../../SharedComponents/CommonUtilities/CommonUtilities';
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";
import { AWS_AxiosInstance } from "../../AWS_AxiosInstance";
var AddServiceMode = React.lazy(() =>
  import("./AddServiceModel/AddServiceModel")
);
var EditServiceModel = React.lazy(() =>
  import("./EditServicesModel/EditServiceModel")
);
var AddServiceTypeModel = React.lazy(() =>
  import("./AddServiceTypeModel/AddServiceTypeModel")
);

var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"));
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    placement:"top"
  },
}))(Tooltip);
class ServicesComopnent extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    this.state = {
      cmsLabelChange:[],
      auth_username: userData.username,
      role: userData.role,
      companysiz: userData.companySize,
      auth_email: userData.email,
      auth_companyId: userData.companyId,
      createdBy: userData.username,
      showaddModal: false,
      showEditModal: false,
      showaddTypeModal: false,
      servicelist: [],
      divisionOption: [],
      addDivisionOption: [],
      searchDivisionValue: "",
      divisionConfig: {
        anchorElAssignee: null,
        config: {
          id: "assignee_filter_select",
        },
      },
      modalRecord: {
        _id: "",
        service: "",
        serviceType: "",
      },
      deleteRecord: {},
      showdelete: false,
      addTypeShow: true,
      responseMs: "",
      service: "",
      serviceType: "",
      isValid: !this.props.isValid,
      vertical: "top",
      horizontal: "center",
      errorMsg: "",
      responseMsg: "",
      alertType: "",
      servicetypes: "",
      isLoading: false,
      id: "",
      serviceTypelist: [{}],
      editservice: "",
      editserviceType: "",
      rowsPerPage: 10,
      page: 1,
      serviceName: "",
      serviceTypeFilter: "",
      serviceTypeName: "",
      divisionId: "",
      divisionName: "",
      adddivisionId: "",
      adddivisionName: "",
      hideedit: true,
      adhide: true,
      editServiceTypeId: "",
      editinputServiceType: "",
      addServiceType: "",
      deleteServiceTypeId: "",
      adderrorMassage: "",
      isMouseInside: true,
      onHoverItem: null,
      loading: true,
      addserviceerror: "",
      editservicetyperr: [],
      serviceindex: "",
      isSubmit: false,
      inhide: false,
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      tooltipprops:{}
    };
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    this.props.onPagination(this.state.page, this.state.rowsPerPage);
    this.setState({ isValid: false });

    const fetchCmsData = checkCmsLabel()
      if(fetchCmsData){
        this.setState({ cmsLabelChange : fetchCmsData.cmsData})
      }
    /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
    AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ?6007
    :process.env.REACT_APP_NODE_ENV === "stage"
    ?'/'
    :'service', "application/x-www-form-urlencoded; charset=UTF-8") */
    
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/getServicetypes")
      .then((resp) => {
        if (resp.data.status === 200) {
          this.setState({ serviceTypelist: resp.data.data });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
        }
      });

    var data = {
      divisionName: "",
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/service_division_search", data)
      .then((res) => {
        if (res.data.status === "200" || res.data.status === 200) {
          this.setState({
            addDivisionOption: res.data.data,
          });
        } else {
          this.setState({
            addDivisionOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
  };
  handleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ page: newPage, loading: true }, this.filter);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      { page:1,rowsPerPage: +event.target.value, loading: true },
      this.filter
    );
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  onViewHandleClick = (row) => {
    this.setState({
      _id: row._id,
      editservice: row.service,
      editserviceType: row.serviceTypeId,
      modalRecord: row,
      showEditModal: true,
    });
  };
  onDeleteHandleClick = (row) => {
    this.setState({ showdelete: true, deleteRecord: row });
  };
  onDelete = () => {
    var serviceData = {
      deleteBy: this.state.createdBy,
      id: this.state.deleteRecord._id,
    };
    this.setState({ isSubmit: true });
    /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
    AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ?6007
    :process.env.REACT_APP_NODE_ENV === "stage"
    ?'/'
    :'service', "application/x-www-form-urlencoded; charset=UTF-8") */
    
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
      .delete(`/deleteService/${this.state.deleteRecord._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState(
            {
              loading: true,
              showdelete: false,
              disable: false,
              isSubmit: false,
            },
            () => {
              var props = {
                message: "Service deleted successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              this.handleClose()
            }
          );
          this.filter();
        } else {
          this.setState({ isSubmit: false })
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
  };
  handleClose = () => {
    this.hideedit();
    this.setState({
      showEditModal: false,
      hideedit: true,
      showaddModal: false,
      showaddTypeModal: false,
      addTypeShow: true,
    });
    this.setState({
      adhide: true,
      isValid: false,
      alertType: "",
      serviceType: "",
      service: "",
      adddivisionId: "",
      adderrorMassage: "",
      responseMsg: "",
      showdelete: false,
    });
  };
  addhandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  addserviceChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
   
    if (!/\S\w*/.test(e.target.value)) {
      this.setState({
        addserviceerror: "* Please enter the service name",
      });
    } else if (e.target.value.length > 100) {
      this.setState({
        addserviceerror: "* Service should not exceed 100 characters",
      });
    } else {
      this.setState({
        addserviceerror: "",
      });
    }
  };
  onServiceSubmit = (e) => {
    e.preventDefault();
    var serviceData = {
      createdBy: this.state.createdBy,
      service: this.state.service,
      serviceType: this.state.serviceType,
      divisionId: this.state.adddivisionId,
    };
    if (!this.state.isValid && this.state.addserviceerror === "") {
      this.setState({ isSubmit: true });
      /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
      AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ?6007
      :process.env.REACT_APP_NODE_ENV === "stage"
      ?'/'
      :'service', "application/x-www-form-urlencoded; charset=UTF-8") */
      
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/addService", serviceData)
        .then((res) => {
          if (res.data.status === 200) {
            this.setState(
              {
                loading: true,
                showdelete: false,
                disable: false,
                isSubmit: false,
              },
              () => {
                var props = {
                  message: "Service added successfully",
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "success",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification update-notify",
                };
                NotificationComponent(props);
                this.handleClose()
              }
            );
            this.filter();
          } else {
            this.setState({ isSubmit: false })
            var props = {
              message: res.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props);
          }
        })
    }
  };
  edithandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onEditServiceSubmit = (e) => {
    e.preventDefault();
    var serviceData = {
      editBy: this.state.createdBy,
      service: this.state.editservice,
      serviceType: this.state.editserviceType,
      id: this.state.modalRecord._id,
    };
    if (!this.state.isValid && this.state.addserviceerror === "") {
      this.setState({ isSubmit: true });
       /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
      AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ?6007
      :process.env.REACT_APP_NODE_ENV === "stage"
      ?'/'
      :'service', "application/x-www-form-urlencoded; charset=UTF-8") */
      
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
      .patch("/updateService", serviceData)
        .then((res) => {
          if (res.data.status === 200 || res.data.status === "200") {
            this.setState(
              {
                loading: true,
                showdelete: false,
                disable: false,
                isSubmit: false,
              },
              () => {
                var props = {
                  message: "Service Update successfully",
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "success",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification update-notify",
                };
                NotificationComponent(props);
                this.handleClose()
              }
            );
            this.filter();
          } else {
            this.setState({ isSubmit: false })
            var props = {
              message: res.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props);
          }
        })
    }
  };
  DivisionFilter = async (e) => {
    var name = e.target.value;
    var data = {
      search: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/service_division_search", data)
      .then((res) => {
        if (res.data.status === "200" ||res.data.status === 200) {
          this.setState({
            addDivisionOption: res.data.data,
          });
        } else {
          this.setState({
            addDivisionOption: [
              {
                id: "one",
                name: "No data",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
        }
      });
  };
  onDivisionSelectHandler = (e) => {
    var name = e.target.value;
    if (name !== "") {
      var { addDivisionOption } = this.state;
      var data = addDivisionOption.filter((item) => {
        return item.name === name;
      });
      data.map((devisionData) => {
        this.setState(
          {
            divisionId: devisionData._id,
            divisionName: devisionData.name,
          },
          this.filter
        );
      });
    }
  };
  onDivisionaddHandler = (e) => {
    var name = e.target.value;
    if (name !== "") {
      var { addDivisionOption } = this.state;
      var data = addDivisionOption.filter((item) => {
        return item.name === name;
      });
      data.map((devisionData) => {
        this.setState({
          adddivisionId: devisionData._id,
          adddivisionName: devisionData.name,
        });
      });
    }
  };
  DivisionAutoClose = (e) => {
    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ divisionId: "" }, this.filter);
    }
  };
  serviceSearch = (e) => {
    let name = e.target.value;
    this.setState({ serviceName: name }, this.filter);
  };
  serviceTypefilter = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.filter);
  };
  filter = () => {
    this.setState({ loading: true });
    let data = {
      pagesize: this.state.rowsPerPage,
      nextpage: this.state.page,
      serviceType: this.state.serviceTypeFilter,
      search: this.state.serviceName,
      divisionId: this.state.divisionId,
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  onclickBtn = (e) => {
    const { service, serviceType, adddivisionId, companysiz } = this.state;
    if (service === "" || serviceType === "" || companysiz.toLowerCase() === 'large' && adddivisionId === "") {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  editonclickBtn = (e) => {
    const { editservice, editserviceType } = this.state;
    if (editservice === "" || editserviceType === "") {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  oneditTypeckBtn = (e) => {
    const { addServiceType } = this.state;
    if (addServiceType === "") {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };
  onhideButton = (e) => {
    this.setState({ adhide: false });
  };
  hideedit = (e) => {
    this.setState({
      serviceindex: "",
      hideedit: true,
      inhide: false,
      editservicetyperr: "",
      addServiceType: "",
    });
  };
  showedit = (data, index) => {
    this.setState({
      serviceindex: index,
      editServiceType: data.name,
      hideedit: false,
      inhide: true,
      addServiceType: "",
    });
  };
  editServiceTypeInput = (e, index) => {
    let editservicetyperr = [...this.state.editservicetyperr];
    this.setState({ [e.target.name]: e.target.value });
    
     
    if (e.target.value === "") {
      editservicetyperr[index] = "* Please enter the service type";
    }else if (!/\S\w*/.test(e.target.value)) {
      editservicetyperr[index] = "* Please enter the service type";
    } else if (e.target.value.length > 100) {
      editservicetyperr[index] =
        "* Service type should not exceed 100 characters";
    } else {
      editservicetyperr[index] = "";
    }
    
    
    
    
    
    
    
    // if (e.target.value === "") {
    //   editservicetyperr[index] = "* Please enter the service type";
    // } else if (e.target.value.length > 100) {
    //   editservicetyperr[index] =
    //     "* Service type should not exceed 100 characters";
    // } else {
    //   editservicetyperr[index] = "";
    // }
    this.setState({ editservicetyperr });
  };
  onEditServiceTypeSubmit = (e, index) => {
    e.preventDefault();
    var Data = {
      createdBy: this.state.createdBy,
      id: this.state.editServiceTypeId,
      serviceType: this.state.editServiceType,
    };
    if (
      this.state.editservicetyperr[index] === "" ||
      this.state.editservicetyperr.length === 0
    ) {
       /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
      AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ?6007
      :process.env.REACT_APP_NODE_ENV === "stage"
      ?'/'
      :'service', "application/x-www-form-urlencoded; charset=UTF-8") */
      
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
      .patch("/updateServiceType", Data)
        .then((res) => {
          if (res.data.status === 200) {
            this.setState(
              {
                hideedit: true,
                serviceTypelist: res.data.data,
                responseMsg: "Service type updated successfully",
                alertType: "success",
              },

              () => {
                var props = {
                  message: "Service Type updated successfully",
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "success",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification update-notify",
                };
                NotificationComponent(props);
                setTimeout(() => {
                  this.setState({ responseMsg: "" });
                }, 2000);
              }
            );
            this.hideedit();
          } else {
            this.setState({ responseMsg: res.data.msg, alertType: "error" });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };
  editTypes = (data) => {
    this.setState({ editServiceTypeId: data._id });
  };
  addServiceTypeInput = (e) => {
    this.hideedit();
    this.setState({ [e.target.name]: e.target.value });
    if (!/\S\w*/.test(e.target.value)) {
      this.setState({
        adderrorMassage: "* Please enter the service type",
      });
    } else if (e.target.value.length > 100) {
      this.setState({
        adderrorMassage: "* Service type should not exceed 100 characters",
      });
    } else {
      this.setState({
        adderrorMassage: "",
      });
    }
  };


  onAddServiceTypeSubmit = (e) => {
    e.preventDefault();
    this.hideedit();
    var Data = {
      createdBy: this.state.createdBy,
      serviceType: this.state.addServiceType,
    };
    if (!this.state.isValid && this.state.adderrorMassage === "") {
      this.setState({ isSubmit: true });
       /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
      AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ?6007
      :process.env.REACT_APP_NODE_ENV === "stage"
      ?'/'
      :'service', "application/x-www-form-urlencoded; charset=UTF-8") */
      
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
        .post("/addServicetype", Data)
        .then((res) => {
          if (res.data.status === 200) {
            this.setState(
              {
                serviceTypelist: res.data.data,
                responseMsg: "Service type added successfully",
                alertType: "success",
                isSubmit: false,
                addServiceType: "",
                addTypeShow: true,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    responseMsg: "",
                    adhide: true,
                    addServiceType: "",
                  });
                }, 2000);
              }
            );
          } else {
            this.setState({
              responseMsg: res.data.msg,
              alertType: "error",
              isSubmit: false,
            });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };
  deleteRowData = (data) => {
    this.setState(
      { deleteServiceTypeId: data._id, addServiceType: "" },
      this.deleteRow
    );
    this.hideedit();
  };
  deleteRow = () => {
    this.setState({ addServiceType: "" });
    var Data = {
      id: this.state.deleteServiceTypeId,
    };
    /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
    AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ?6007
    :process.env.REACT_APP_NODE_ENV === "stage"
    ?'/'
    :'service', "application/x-www-form-urlencoded; charset=UTF-8") */
    
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
      .delete(`/deleteServiceType/${this.state.deleteServiceTypeId}`)
      .then((res) => {
        console.log("res..........",res)
        if (res.data.status === 200) {
          this.setState(
            {
              serviceTypelist: res.data.data,
              responseMsg: "Service type deleted successfully",
              alertType: "success",
            },
            () => {
              var props = {
                message: "Service Type deleted successfully",
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
              setTimeout(() => {
                this.setState({ responseMsg: "" });
              }, 2000);
            }
          );
        } else {
          this.setState(
            { responseMsg: res.data.msg, alertType: "error" },
            () => {
              setTimeout(() => {
                this.setState({ responseMsg: "" });
              }, 2000);
            }
          );
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          var props = {
            message: "Cannot delete serviceType which has services",
            icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
          };
          NotificationComponent(props);
          console.log("Internal Server Error");
        }
      });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleStatusSelect = (item) => {
    var { serviceTypeName, serviceTypeFilter, statusConfig } = this.state;
    serviceTypeFilter = item._id;
    serviceTypeName = item.name;
    statusConfig.anchorElStatus = null;
    this.setState(
      {
        statusConfig,
        serviceTypeFilter,
        serviceTypeName,
      },
      () => {
        this.filter();
      }
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeStatusFilterMenu = () => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
    });
  };
  /**
   *
   * TO HANDLE STATUS MENU OPEN
   */
  openStatusFilterMenu = (event) => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      statusConfig,
    });
  };
  employeeFilter = (search) => {
    const { divisionOption } = this.state;
    var data = {
      search: search,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/service_division_search", data)
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = divisionOption.splice();
          if (this.state.searchDivisionValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            divisionOption: newArray,
          });
        } else {
          this.setState({
            divisionOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
    *
    * TO HANDLE division MENU OPEN
    */
  opendivisionFilterMenu = (event) => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElAssignee = event.currentTarget;
    this.setState(
      {
        divisionConfig,
      },
      () => {
        this.employeeFilter("");
      }
    );
  };
  /**
  *
  * @param {item object} item
  * TO ADD division FILTER VALUE ON SELECT
  */
  handledivisionSelect = (item) => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElAssignee = null;
    this.setState(
      {
        divisionId: item._id,
        divisionName: item.name === "All" ? "" : item.name,
        divisionConfig,
        searchDivisionValue: "",
      },
      () => {
        this.filter();
      }
    );
  };
  /**
  *
  * TO HANDLE division MENU CLOSE
  */
  closedivisionFilterMenu = () => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElAssignee = null;
    this.setState({
      divisionConfig,
    });
  };
  /**
  *
  * @param {item object} item
  * TO HANDLE division FILTER VALUE ON SEARCH
  */
  ondivisionSearchFilter = (e) => {
    const {value } = e.target;
    this.setState({
      searchDivisionValue: value,
    });
    this.employeeFilter(value);
  };
  showType = () => {
    this.setState({ addTypeShow: false })
  }
  hideType = () => {
    this.setState({ addTypeShow: true })
  }
  render() {
    let { loading } = this.state;
    let ServicePage = (
      <div className="body-bg-color">
        <HeaderComponent/>
        <Suspense fallback={<div></div>}>
          <AddServiceMode
            modelData={this.state}
            open={this.state.showaddModal}
            handleClose={this.handleClose}
            addhandleChange={this.addhandleChange}
            addserviceChange={this.addserviceChange}
            onServiceSubmit={this.onServiceSubmit}
            services={this.state}
            onclickBtn={this.onclickBtn}
            DivisionFilter={this.DivisionFilter}
            onDivisionaddHandler={this.onDivisionaddHandler}
          />
          <EditServiceModel
            addserviceChange={this.addserviceChange}
            modelData={this.state}
            open={this.state.showEditModal}
            handleClose={this.handleClose}
            addhandleChange={this.edithandleChange}
            onServiceSubmit={this.onEditServiceSubmit}
            services={this.state}
            onclickBtn={this.editonclickBtn}
            row={this.state.modalRecord}
          />
          <AddServiceTypeModel
            hideType={this.hideType}
            showType={this.showType}
            modelData={this.state}
            open={this.state.showaddTypeModal}
            handleClose={this.handleClose}
            services={this.state}
            hideedit={this.hideedit}
            showedit={this.showedit}
            editTypes={this.editTypes}
            editServiceTypeInput={this.editServiceTypeInput}
            onEditServiceTypeSubmit={this.onEditServiceTypeSubmit}
            onAddServiceTypeSubmit={this.onAddServiceTypeSubmit}
            addServiceTypeInput={this.addServiceTypeInput}
            deleteRowData={this.deleteRowData}
            onclickBtn={this.oneditTypeckBtn}
            onhideButton={this.onhideButton}
            mouseEnter={this.mouseEnter}
            mouseLeave={this.mouseLeave}
            tooltipprops={this.state.tooltipprops}
          />
        </Suspense>

        <Dialog open={this.state.showdelete} className="deleteServiceDialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
          {this.state.responseMsg !== "" ? (
            <Alert severity={this.state.alertType}>
              {this.state.responseMsg}
            </Alert>
          ) : null}
          <DialogTitle>
            {" "}
            <center> Delete Service</center>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <center>
                {" "}
                Are you sure you want to delete this service? This action cannot be undone.
              </center>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="cancel"
              onClick={this.handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.isSubmit}
              className="delete"
              onClick={() => this.onDelete()}
              style={{ color: "red" }}
              autoFocus
            >
              {this.state.isSubmit ? <CircularProgress size={20} /> : ""} Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="header-add-btn header-add-btn-service">
          <Typography variant="h5" className="tableTitle" component="div">
          {this.state.cmsLabelChange.Service ? this.state.cmsLabelChange.Service : "Services"}
          </Typography>
          {this.state.role.toLowerCase() === "admin" ||
            this.state.role.toLowerCase() === " super admin" ? (
              <div className="services_btn_right">
                <Button
                  variant="contained"
                  className="tableaddBtn add_service_main_btn grey-btn"
                  color="default"
                  style={{ boxShadow: "none" }}
                  onClick={() => this.setState({ showaddTypeModal: true })}
                >
                  Service Types
              </Button>
                <Button
                  variant="contained"
                  className="tableaddBtn grey-btn"
                  color="default"
                  style={{ boxShadow: "none" }}
                  onClick={() => this.setState({ showaddModal: true })}
                >
                  Add {this.state.cmsLabelChange.Service ? this.state.cmsLabelChange.Service : "Service"}
              </Button>
              </div>
            ) : (
              ""
            )}
        </div>
        <div className="Acc-Table">
          <div className="filter-search">
            <div style={{ display: "flex" }}>
              {(this.state.companysiz.toLowerCase() === "large" &&
                this.state.role.toLowerCase() === "admin") ? (
                  <>
                    <div className="new_custom_filters">
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div"
                          onClick={this.opendivisionFilterMenu}
                        >
                          {this.state.divisionName !== "" ? (
                            ""
                          ) : (
                              <label className="leftName">Division: </label>
                            )}
                          <label
                            aria-label="assignee_filter"
                            aria-controls="assignee_filter_select"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.divisionName === ""
                              ? `All`
                              : upperFirst(this.state.divisionName)}
                          </label>
                          <img className="carret" src={carret_down} alt="carret" />
                        </div>
                        <CustomSelectWithSearchComponent
                          filterArray={this.state.divisionOption}
                          config={this.state.divisionConfig.config}
                          anchorEl={this.state.divisionConfig.anchorElAssignee}
                          searchValue={this.state.searchDivisionValue}
                          handleClick={this.handledivisionSelect}
                          closeCustomPopup={this.closedivisionFilterMenu}
                          onSearchFilter={this.ondivisionSearchFilter}
                          placeholder="Division"
                        />
                      </Suspense>
                    </div>
                  </>
                ) : (
                  ""
                )}
              <div className="new_custom_filters">
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openStatusFilterMenu}
                  >
                    {this.state.serviceTypeName !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Service Type: </label>
                      )}
                    <label
                      aria-label="status_filter"
                      aria-controls="status_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.serviceTypeName === ""
                        ? `All`
                        : upperFirst(this.state.serviceTypeName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectComponent
                    filterArray={this.state.serviceTypelist}
                    config={this.state.statusConfig.config}
                    anchorEl={this.state.statusConfig.anchorElStatus}
                    handleClick={this.handleStatusSelect}
                    closeCustomPopup={this.closeStatusFilterMenu}
                  />
                </Suspense>
              </div>
            </div>
            <CssTextField
              id="outlined-basic"
              name="serviceName"
              variant="outlined"
              type="text"
              placeholder="Search Here"
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon} alt = "searchIcon" />
                  </InputAdornment>
                ),
              }}
              onChange={this.serviceSearch}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>SERVICE TYPE</TableCell>
                    <TableCell>{this.state.cmsLabelChange.Service ? this.state.cmsLabelChange.Service.toUpperCase() : "SERVICE"}</TableCell>
                    {this.state.role.toLowerCase() === "admin" ||
                      this.state.role.toLowerCase() === " super admin" ? (
                        <TableCell>ACTIONS</TableCell>
                      ) : (
                        ""
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={loading}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ) : !this.props.pageOfItems ||
                    this.props.pageOfItems.length === 0 ? (
                        <StyledTableRow>
                          <TableCell colSpan={9} style={{ textAlign: "center" }}>
                            No data found
                      </TableCell>
                        </StyledTableRow>
                      ) : (
                        (this.state.rowsPerPage > 0
                          ? this.props.pageOfItems.slice()
                          : this.props.pageOfItems
                        ).map((row, index) => (
                          <StyledTableRow key={row._id}>
                            <TableCell>
                              {(this.props.pager.currentPage - 1) *
                                this.state.rowsPerPage +
                                (index + 1)}
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip title={row.serviceType} {...this.state.tooltipprops} arrow>
                                <span href={"mailto:" + row.serviceType}>
                                  {row.serviceType}
                                </span>
                              </LightTooltip>
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip title={row.service} {...this.state.tooltipprops} placement="left" arrow>
                                <span href={"mailto:" + row.service}>
                                  {row.service}
                                </span>
                              </LightTooltip>
                            </TableCell>
                            {this.state.role.toLowerCase() === "admin" ||
                              this.state.role.toLowerCase() === " super admin" ? (
                                <TableCell>
                                  <div
                                    className="table_action_btns"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "left",
                                    }}
                                  >
                                    <div>
                                      <LightTooltip title="Delete" {...this.state.tooltipprops} arrow>
                                        
                                        <img
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onDeleteHandleClick(row)
                                          }
                                          src={Delete}
                                          alt="add"
                                        ></img>
                                      
                                      </LightTooltip>
                                    </div>
                                    <div>
                                      <LightTooltip title="Edit service" {...this.state.tooltipprops} arrow>
                                        
                                        <img
                                          style={{ cursor: "pointer" }}
                                          onClick={() => this.onViewHandleClick(row)}
                                          src={OpenMenu}
                                          alt="openmenu"
                                        ></img>
                                      
                                      </LightTooltip>
                                    </div>
                                  </div>
                                </TableCell>
                              ) : (
                                ""
                              )}
                          </StyledTableRow>
                        ))
                      )}
                </TableBody>
                <TableFooter>
                  {this.props.pageOfItems &&
                      this.props.pageOfItems.length !== 0?
                  <TableRow>
                    <td colSpan={20} className="pagination_container">
                    <CustomPaginationComponent
                    count={this.props.pager.endPage}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleCustomPaginagtionChange}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                  </td>
                  </TableRow>
                  :null}
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
    return <div>{ServicePage}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
    responseData: state.pagination.responseData,
    error: state.pagination.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole) =>
      dispatch(action.servicepagination(pageNo, pagerole)),
    onFilter: (value) => dispatch(action.onservicefilter(value)),
    onDeleteService: (value) => dispatch(action.onDeleteService(value)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ServicesComopnent));
