import React, { Suspense, Component } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import "./LoginComponent.css";
import logoIcon from "../../Assets/images/logo_icon.svg";
import iconFacebook from "../../Assets/images/Icon-awesome-facebook-square.svg";
import iconInstagram from "../../Assets/images/Icon-awesome-instagram.svg";
import iconLinkedin from "../../Assets/images/Icon-awesome-linkedin.svg";
import iconTwitter from "../../Assets/images/Icon-awesome-twitter.svg";
import logo from "../../Assets/images/Sales_C2_logo.svg";
import emailIcon from "../../Assets/images/email-@.svg";
import eyeIcon from "../../Assets/images/eye-icon.svg";
import eyeCloseIcon from "../../Assets/images/eye-close-icon.svg";
import appStore from "../../Assets/images/app_store.svg";
import googlePlay from "../../Assets/images/google-play.png";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "@material-ui/core/Button";
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import { formValidation,emailRegx } from "../../SharedComponents/Validation/formValidation";
import * as action from "../../Store/Actions/index";
import { connect } from "react-redux";
import {withRouter,Redirect}  from "react-router-dom";
import repeatGrid from "../../Assets/images/Repeat_grid_5.svg";
import jwt from "jsonwebtoken";
import validator from "validator";
import {AxiosInstance} from '../../Utilities';
import {Tooltip} from 'antd';
var ForgotPasswordModal = React.lazy(() =>
  import("./ForgotPasswordModal/ForgotPasswordModal")
);
const GreenCheckbox = withStyles({
  root: {
    color: "#424242",
    '&$checked': {
      color: "#21BF73",
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const CssTextField = withStyles({
  root: {
   
    '& .MuiInput-underline:after': {
      borderBottomColor: '#14193A',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E2E5F2',
        borderWidth: 1
      },
      
      '&.Mui-focused fieldset': {
        borderColor: '#14193A',
        borderWidth: 1
      },
    },
  },
})(TextField);

class LoginComponent extends Component{
  constructor(props) {
    super(props);
    var check=(sessionStorage.getItem('isChecked') && sessionStorage.getItem('userEmail') && sessionStorage.getItem('userPassword')) 
    && (sessionStorage.getItem('isChecked') === 'true' && sessionStorage.getItem('userEmail') !== "" && sessionStorage.getItem('userPassword') !== "")?true:false
    this.state = {
      userEmail: sessionStorage.getItem('userEmail')?sessionStorage.getItem('userEmail'):"",
      userPassword:sessionStorage.getItem('userPassword')?sessionStorage.getItem('userPassword'):"",
      formError: {
        userEmail: "",
        userPassword: "",
      },
      loginError:'',
      hidden: true,
      forgotPassword:false,
      forgotEmail:'',
      forgotEmailError:'',
      responseError:"",
      alertType:"",
      isChecked:check
    }
    this.handleChange = this.handleChange.bind(this);
  }
  /**
   * @author Benakesh
   * To open forgot password modal
   */
  onForgotModalOpen=()=>{
    this.setState({
      forgotPassword:true
    })
  }
  /**
   * @author Benakesh
   * To close forgot password modal
   */
  onForgotModalClose=()=>{
    this.setState({
      forgotPassword:!this.state.forgotPassword
    })
  }

  /**
   * @author Benakesh
   * @param {target element} e 
   *  To set forgot password email state 
   */
  onChangeForgotPassword=(e)=>{
    var {name,value}=e.target;
    var {forgotEmailError} =this.state;
    this.setState({
      [name]:value
    },()=>{
      if(validator.isEmpty(this.state.forgotEmail)){
        forgotEmailError="Please enter an email address";
      }else if(!emailRegx.test(this.state.forgotEmail)){
        forgotEmailError="Please check the email address is in valid format e.g. name@domain.com";
      }else if(!validator.isLength(this.state.forgotEmail,{min:5,max:100})){
        forgotEmailError="Email length should be less than 100 character";
       }else{
        forgotEmailError="";
      }
      this.setState({
        forgotEmailError
      })
     
    })
  }
  /**
   * @author Benakesh
   * @param {target element} e 
   * To handle forgot password 
   */
   onForgotPasswordHandler=(e)=>{
    e.preventDefault();
    var {forgotEmail,forgotEmailError}=this.state;
   
    if(validator.isEmpty(forgotEmail)){
      forgotEmailError="Please enter an email address";
    }else if(!emailRegx.test(forgotEmail)){
      forgotEmailError="Please check the email address is in valid format e.g. name@domain.com";
    } else if(!validator.isLength(forgotEmail,{min:5,max:100})){
      forgotEmailError="Email length should be less than 100 character";
     }else{
      forgotEmailError="";
    }
    this.setState({
      forgotEmailError
    },()=>{
      var {forgotEmail}=this.state;
      if(validator.isEmpty(this.state.forgotEmailError)){
       AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6001:'authentication',"application/json")
       .post("/employee/resetpassword",{email:forgotEmail})
       .then(resp=>{
         var response=resp && resp.data;
         if(response && (response.status === '201' || response.status === 201)){
              this.setState({
                responseError:response.msg,
                alertType:"success"
              },()=>{
                setTimeout(()=>{
                  this.setState({
                    responseError:"",
                    alertType:"",
                    forgotPassword:false
                  })
                },4000)
              })
         }else{
            this.setState({
              responseError:response.msg,
              alertType:"error"
            },()=>{
              setTimeout(()=>{
                this.setState({
                  responseError:"",
                  alertType:""
                })
              },4000)
            })
         }
       }).catch(err=>{
  			  var error=err.response;
            if(error && (error.status === '403' || error.status === 403)){
                localStorage.clear();
                setTimeout(()=>{
                    window.location.href="/";
                },500)
            }else{
              if(error.data.message = "There's no SalesC2 Account with the email address you provided. Please enter your registered email address."){
                this.setState({
                  responseError:error.data.message,
                  alertType:"error"
                },()=>{
                  setTimeout(()=>{
                    this.setState({
                      responseError:"",
                      alertType:""
                    })
                  },4000)
                })
              } else{
                this.setState({
                  responseError:"Internal server error",
                  alertType:"error"
                },()=>{
                  setTimeout(()=>{
                    this.setState({
                      responseError:"",
                      alertType:""
                    })
                  },9000)
                })
              }
            }
       })
      }
    })
   }
  /** 
   * @author Benakesh
   * clear form data after dialog close
  */
  clearForgotDialog=()=>{
    this.setState({
      forgotEmail:"",
      forgotEmailError:"",
      responseError:""
    })
  }


  handleChange = (event) => {
    event.preventDefault();
    const { formError } = this.state;
    let identifier = event.target.name;
    let value = event.target.value;
    switch (identifier) {
      case "userEmail":
        if (!emailRegx.test(value) && value.trim().length > 0) {
          formError.userEmail = "";
        } else {
          formError.userEmail = "";
        }
        break;
      case "userPassword":
        if (value.trim().length < 0) {
          formError.userPassword ="*Please enter password";
        } else {
          formError.userPassword = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      [event.target.name]: event.target.value,
      formError,
      loginError:'loginError'
    });
  };

  checkLogin= async (e) => {
    e.preventDefault();
    const { formError,userEmail,userPassword } = this.state;
    if(userEmail === "" && userPassword === ""){
      formError.userEmail = "* Please enter an email address";
      formError.userPassword = "*Please enter a password";
      this.setState({loginError:'loginError'},()=>{});
    }else if(userEmail === "" && userPassword !== ""){
      formError.userEmail = "* Please enter an email address";
      formError.userPassword = "";
      this.setState({loginError:'loginError'},()=>{});
    }else if(userEmail !== "" && userPassword === ""){
      if(!emailRegx.test(userEmail)){
        formError.userEmail = "* Please enter an email address";
      }else{
        formError.userEmail = "";
      }      
      formError.userPassword = "* Please enter a password";
      this.setState({loginError:'loginError'},()=>{});
    }else if(userEmail !== "" && !emailRegx.test(userEmail)){
      formError.userEmail = "* Please enter an email address";
      formError.userPassword = "";
      this.setState({loginError:'loginError'},()=>{});
    }else{
      formError.userEmail = "";
      formError.userPassword = "";
    }
    this.setState({formError,loginError:''});
    if (formValidation(this.state)) {
    const userData = {
      email: this.state.userEmail,
      password: this.state.userPassword,
    };
    this.props.onAuth(userData);      
    }
  }
  onRememberMe=(e)=>{
    this.setState({
      isChecked:e.target.checked
    },()=>{
      if(this.state.isChecked){
        sessionStorage.setItem('userEmail',this.state.userEmail);
        sessionStorage.setItem('userPassword',this.state.userPassword);
        sessionStorage.setItem('isChecked',true)
      }else{
        sessionStorage.setItem('userEmail','');
        sessionStorage.setItem('userPassword','');
        sessionStorage.setItem('isChecked',false);
      }
    })
  }
  render() {
   let LoginForm = (
   <div>
       <Suspense fallback={<div></div>}>
     <ForgotPasswordModal
        clearForgotDialog={this.clearForgotDialog}
        onForgotPassword={this.onForgotPasswordHandler} 
        onForgotModalClose={this.onForgotModalClose}         
        onChangeForgotPassword={this.onChangeForgotPassword}
        forgotPassword={this.state.forgotPassword}
        forgotEmailError={this.state.forgotEmailError}
        responseMsg={this.state.responseError}
        alertType={this.state.alertType}
      />
      </Suspense>
    <Grid container className="login_form_container">
       <Grid className="login_form" item xs={12} sm={5} >
         <div className="login_form_div"> 
         <img className="logo" src={logo} alt="logo"/>
         <p className="login-header">Please login to your account</p>
         <form onSubmit={this.checkLogin} autoComplete="off">
         {this.props.loginError ? <p style={{color:'red',fontSize:'16px',background: 'antiquewhite',textAlign:'center'}}>{this.props.loginError}</p> : ''}
         <InputLabel className="loginLabel" data-test="loginLabel" >
         Email
         </InputLabel>
         <CssTextField id="outlined-basic-email" name="userEmail" type="text" placeholder="Enter an email address" className="inputHeight" variant="outlined" onChange={this.handleChange} value={this.state.userEmail} InputProps={{
            endAdornment: <InputAdornment position="end"><img src={emailIcon} alt="email"/></InputAdornment>,
          }} helperText={this.state.formError.userEmail ? <span style={{color:'red',fontSize:'16px'}} className={this.state.loginError}>{this.state.formError.userEmail}</span> : ''} error={this.state.formError.userEmail ? <span style={{color:'red',fontSize:'16px'}} className={this.state.loginError}>{this.state.formError.userEmail}</span> : ''}/>         
         <InputLabel  className="loginLabel" data-test="loginLabel">
         Password
         </InputLabel>
         <CssTextField  id="outlined-basic-password" type={this.state.hidden ? "password" : "text"} name="userPassword" placeholder="Enter a password" className="inputHeight mar-bot-10" variant="outlined" onChange={this.handleChange} value={ this.state.userPassword} InputProps={{
            endAdornment: <InputAdornment position="end" style={{cursor:'pointer'}} onClick={() => this.setState({ hidden: !this.state.hidden })}>
              <Tooltip title={this.state.hidden?"Show Password":"Hide Password"} placement="bottom">
                <img src={this.state.hidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
              </Tooltip>
              </InputAdornment>,
          }} error={this.state.formError.userPassword ? <span style={{color:'red',fontSize:'16px'}} className={this.state.loginError}>{this.state.formError.userPassword}</span> : ''}/>
          {this.state.formError.userPassword ? <span className='err-msg'>{this.state.formError.userPassword}</span> : ''}
         
         <Grid
         container
         direction="row"
         justify="space-between"
         alignItems="flex-start"
         >
         <FormControlLabel className="loginCheckbox"
         control={<GreenCheckbox  name="checkedG" checked={
           this.state.isChecked} onChange={this.onRememberMe}/>}
         label="Remember me"/>
         <span className="forget_password" onClick={this.onForgotModalOpen}>Forgot Password?</span>
         </Grid>
         <Button className="LoginBtn" type="submit" variant="contained" color="primary">
           Login
         </Button>
         </form>
        
         </div>
         <Grid className="social-link" container direction="row" justify="space-between" alignItems="flex-start">  
           <Grid item>
             <div className="mobile_app"><span>Available on:</span><a  href="https://apps.apple.com/app/salesc2/id1534555813" target="_blank" ><img src={appStore} alt="appstore" rel="noopener noreferrer"/></a>
             <a  href="#" rel="noopener noreferrer"><img src={googlePlay}/></a></div>
           </Grid>
           <Grid item>
           <div className="social_links">
             <a href="https://www.facebook.com/salesc2" target="_blank" rel="noopener noreferrer"><img src={iconFacebook} alt="fb"/></a> 
             <a href="https://www.instagram.com/salesc2" target="_blank" rel="noopener noreferrer"><img src={iconInstagram} alt="insta"/></a> 
             <a href="https://www.twitter.com/sales_c2" target="_blank" rel="noopener noreferrer"> <img src={iconTwitter} alt="twitter"/></a>
             <a href="http://linkedin.com/company/salesc2" target="_blank" rel="noopener noreferrer"><img src={iconLinkedin} alt="li"/></a> 
            </div>
           </Grid>
         </Grid>
       </Grid>
       <Grid item xs={12} sm={7} >
         <div className="login_banner">
           {/* <p>SalesC2 offers a totally updated medical customer service management.</p> */}
           <img className="icon_small" src={logoIcon} alt="logo"/>
           <span>©{new Date().getFullYear()} All Rights Reserved. SalesC2. Cookie Preferences. Privacy, and Terms</span>
           <img className="repeat_grid_bottom" src={repeatGrid} alt="repeat"/>
           <img className="repeat_grid_right" src={repeatGrid} alt="right"/>
         </div>
       </Grid>
    </Grid>
  </div>
  );
   if (this.props.isAuthenticated) {
    return <Redirect to="/dashboard"/>;
  } else {
    return <div>{LoginForm}</div>;
  }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? true : false,
    loginError: state.auth.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (userData) => dispatch(action.LoginActions(userData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginComponent));
