import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import closeBtn from "../../Assets/images/close_btn.svg";
import IconButton from '@material-ui/core/IconButton';
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    Select,
    DialogActions,
    Tooltip,
    CircularProgress,
    DialogContentText,
} from "@material-ui/core";
import { TransitionGrow } from '../CommonUtilities/CommonUtilities';
const { Option, OptGroup } = Select;
const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#14193A',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E2E5F2',
                borderWidth: 1
            },
            '&.Mui-focused fieldset': {
                borderColor: '#14193A',
                borderWidth: 1
            },
        },
    },
})(TextField, Select);
/**
   * @author Shashi
   */
const BillingDetailsConfirmation = props => {
    return (
        <div >
            <div>
            <Dialog open={props.open} className="add_company_dialog not_subscribe_dialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
          <DialogTitle>
            {" "}
            <center> Not Subscribed Yet</center>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <center>
                {" "}
                Looks like there is no billing account associated with selected subscription. This page will be available once they subscribe to SalesC2
              </center>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
           <div className="add_company_action">
            <Button
             className="ok_btn green-btn"
              onClick={props.handleClose}
              color="primary"
            >
              Ok
            </Button>
            </div>
          </DialogActions>
        </Dialog>
            </div>
        </div >
    );
};
export default BillingDetailsConfirmation;