import React, { Component, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import * as action from "../../Store/Actions/index";
import { upperFirst } from "lodash";
import {
  Box,
  Typography,
  Button,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { AxiosInstance } from "../../Utilities";
import { connect } from "react-redux";
import carret_down from "../../Assets/images/carret_down.svg";
import { Redirect, withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import searchIcon from "../../Assets/images/search_icon.svg";
import accept from "../../Assets/images/Group 679.svg";
import reject from "../../Assets/images/Group 680.svg";
import RejectModal from "../../SharedComponents/RejectModal/RejectModal"
import AcceptInviteAccount from "./AcceptInviteAccount/AcceptInviteAccount"
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";
// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustemPendingSelectComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
  )
);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
class PendingInviteAccountComponent extends Component {
  constructor(props) {
    super(props);
    let userRole = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata")).role
      : "";
    this.state = {
      showaddModal: false,
      userRole: userRole,
      companyList: [],
      assignedOption: [],
      searchAssigneeValue: "",
      assignedName: "",
      companyName: "",
      companyCode: "",
      companyLogo: null,
      descriptioneror: {
        error: "",
      },
      description: "",
      file: null,
      companyPhone: "",
      accpaddModal: false,
      companyEmail: "",
      isAdd: true,
      disable: false,
      companyAddress: "",
      divisionId: "",
      divisionData: [],
      areaFilter: "",
      regionFilter: "",
      territoryFilter: "",
      accountId: "",
      areaList: [],
      territoryList: [],
      regionList: [],
      companyWebsite: "",
      companySize: "",
      companyDescription: "",
      showaddModal: false,
      responseMsg: "",
      regId: "",
      alertType: "",
      page: 0,
      pageNumber: 1,
      rowsPerPage: 10,
      _id: "",
      loading: true,
      isValid: false,
      assigneeConfig: {
        anchorElAssignee: null,
        config: {
          id: "assignee_filter_select",
        },
      },
      formError: {
        companyName: "",
        companyCode: "",
        companyPhone: "",
        companyEmail: "",
        companyAddress: "",
        companyWebsite: "",
        companySize: "",
      },
      isSubmit: false,
      delete_id: "",
      statusFilterName: "",
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      tooltipprops:{}
    };
  }
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      this.props.onPagination(this.state.pageNumber - 1, this.state.rowsPerPage);
      var filterdata = {
        roles: this.state.userRole
      }
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6006:'user',
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .post("/users/filters", filterdata)
        .then((resp) => {
          if (resp.status === 200) {
            this.setState({ divisionData: resp.data.data.division });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });


    });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };
  handleCustomPaginagtionChange = (event, newPage) => {
    this.setState({ pageNumber: newPage, loading: true },
      () => {
        this.filter();
      }
    );

    // this.props.onPagination(newPage, this.state.rowsPerPage);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, loading: true, pageNumber: 1 },
      () => {
        this.filter();
      }
    );
    // this.props.onPagination(this.state.pageNumber, +event.target.value);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  companyFilter = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, loading: true,pageNumber: 1 },
      () => {
        this.filter();
      }

    );
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  onViewHandleClick = (row) => {
    this.setState({ delete_id: row._id, showdelete: true });
  };
  /**
   * @author shashi
   * To open status filter menu
   */
  openStatusFilterMenu = (event) => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author shashi
   * To close status filter menu
   */
  closeStatusFilterMenu = () => {
    const { statusConfig } = this.state;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
    });
  };
  /**
   * @author shashi
   * To display data based on selected status.
   */
  handleStatusSelect = (item) => {
    var { companySize, statusFilterName, statusConfig } = this.state;
    companySize = item._id;
    statusFilterName = item.name;
    statusConfig.anchorElStatus = null;

    this.setState(
      {
        statusConfig,
        statusFilterName,
        companySize,
        pageNumber: 1
      },
      () => {
        this.filter();
      }
    );
  };
  /**
  *
  * TO HANDLE MENU OPEN
  */
  openAssigneeFilterMenu = (event) => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = event.currentTarget;
    this.setState(
      {
        assigneeConfig,
      },
      () => {
        this.employeeFilter("");
      }
    );
  };
  /**
  *
  * @param {item object} item
  * TO ADD ASSIGNEE FILTER VALUE ON SELECT
  */
  handleAssigneeSelect = (item) => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = null;

    this.setState(
      {
        assignedId: item._id,
        assignedName: item.name === "All" ? "" : item.name,
        assigneeConfig,
        searchAssigneeValue: "",
      },
      () => {
        this.filter();
      }
    );
  };
  /**
   *
   * TO HANDLE ASSIGNEE MENU CLOSE
   */
  closeAssigneeFilterMenu = () => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = null;
    this.setState({
      assigneeConfig,
    });
  };
  /**
 *
 * @param {item object} item
 * TO HANDLE ASSIGNEE FILTER VALUE ON SEARCH
 */
  onAssigneeSearchFilter = (e) => {
    const { name, value } = e.target;
    this.setState({
      searchAssigneeValue: value,
    });
    this.employeeFilter(value);
  };
  /**
  *
  * @param {value} search
  * TO FETCH THE EMPLOYEE FILTER
  */
  employeeFilter = (search) => {
    const { assignedOption } = this.state;

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account', "application/x-www-form-urlencoded; charset=UTF-8")
      .get("/account-filter", { params:{accountName:search} })
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = assignedOption.splice();
          if (this.state.searchAssigneeValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.accountName;
              newArray.push(obj);
            });
          }
          this.setState({
            assignedOption: newArray,
          });
        } else {
          this.setState({
            assignedOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  filter = () => {
    this.setState({ loading: true })
    let data = {
      page: this.state.pageNumber,
      pageSize: this.state.rowsPerPage,
      status: this.state.companySize,
      searchText: this.state.searchFilter,
      accountId: this.state.assignedId,
    };
    this.props.onFilter(data);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }
  onViewReject = (row) => {
    this.setState({ showaddModal: true, regId: row._id })
  }
  onViewAccept = (row) => {
    this.setState({ accpaddModal: true, _id: row._id, divisionId: row.divisionId, accountId: row.accountId })
    var filterdata = {
      divisionId: row.divisionId,
      divisionData: this.state.divisionData,
    }
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6006:'user',
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .post("/users/filters", filterdata)
      .then((resp) => {
        if (resp.status === 200) {
          this.setState({ areaList: resp.data.data.area, divisionData: resp.data.data.division });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  }
  adddeshandleChange = (e) => {
    var { descriptioneror } = this.state;
    this.setState({ [e.target.name]: e.target.value });
    if (!/\S\w*/.test(e.target.value)) {
      descriptioneror.error =
        "* Please enter a note";
    } else if (e.target.value.length > 250) {
      descriptioneror.error =
        "* Description length should not be max 250 character";
    } else {
      descriptioneror.error = "";
    }
  };
  onSubmitReject = () => {
    var { descriptioneror } = this.state;
    if (this.state.description === "") {
      descriptioneror.error = "* Please enter a note";
    }
    this.setState(
      {
        descriptioneror,
      },
      () => {
        const isEmpty = Object.values(descriptioneror).every(
          (x) => x === null || x === ""
        );
        if (isEmpty || descriptioneror.error === "") {
          this.setState({ isSubmit: true });
          var data = {
            id: this.state.regId,
            notes: this.state.description,
          };
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/json")
            .post("/team_invites/reject", data)
            .then((res) => {
              if (res.data.status === "200" || res.data.status === 200) {
                this.setState(
                  {
                    isSubmit: false,
                  },
                  () => {
                    var props = {
                      message: res.data.msg,
                      icon: <img src={iconNotificationSuccess} alt="success" />,
                      type: "success",
                      placement: "topRight",
                      duration: 3,
                      top: 200,
                      className: "task_notification update-notify",
                    };
                    NotificationComponent(props);
                  }
                );
                this.onCloseModal();
                this.filter();
              } else {
                this.setState({ isSubmit: false });
                var props = {
                  message: res.data.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(props);
                this.filter();
              }
            });
        }
      }
    );

  }
  onCloseModal = () => {
    this.setState({
      accpaddModal: false,
      showaddModal: false,
      description: "",
      descriptioneror: {
        error: "",
      },
    });
  };
  territoryHandelchnge = (e) => {
    this.setState({ [e.target.name]: e.target.value },
      () => {
        if (this.state.territoryFilter === "") {
          this.setState({ isAdd: true })
        } else {
          this.setState({ isAdd: false })
        }
      }
    );
  }
  regionHandelChange = (e) => {
    this.setState({ isAdd: true, [e.target.name]: e.target.value },
      () => {
        var filterdata = {
          regionId: this.state.regionFilter,
          divisionData: this.state.divisionData,
          regionData: this.state.regionList,
          areaData: this.state.areaList,
        }
        AxiosInstance(
          (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6006:'user',
          "application/x-www-form-urlencoded; charset=UTF-8"
        )
          .post("/users/filters", filterdata)
          .then((resp) => {
            if (resp.status === 200) {
              this.setState({ territoryList: resp.data.data.territory });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      }
    );
  }
  areaHandelChange = (e) => {
    this.setState({ isAdd: true, [e.target.name]: e.target.value },
      () => {
        var filterdata = {
          areaId: this.state.areaFilter,
          divisionData: this.state.divisionData
        }
        AxiosInstance(
          (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6006:'user',
          "application/x-www-form-urlencoded; charset=UTF-8"
        )
          .post("/users/filters", filterdata)
          .then((resp) => {
            if (resp.status === 200) {
              this.setState({ regionList: resp.data.data.region, territoryList: resp.data.data.territory });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      }
    );
  }
  acceptRequest = () => {
    var data = {
      id: this.state._id,
      accountId: this.state.accountId,
      territoryId: this.state.territoryFilter
    }
    this.setState({ isAdd: true, isSubmit: true })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company', "application/json")
      .post("/team_invites/accept", data)
      .then((res) => {
        if (res.data.status === "201" || res.data.status === 201) {
          this.setState(
            {
              isSubmit: false,
              isAdd: false,
            },
            () => {
              var props = {
                message: res.data.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.onCloseModal();
          this.filter();
        } else {
          this.setState({
            isSubmit: false,
            isAdd: false,
          });
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.filter();
        }
      });
  }
  render() {
    let { loading } = this.state;
    var statusOptions = [
      { _id: "accepted", name: "Accepted" },
      { _id: "rejected", name: "Rejected" },
    ];
    let CompanyPage = (
      <div className="body-bg-color">
        {/* <Suspense fallback={<div></div>}> */}
        <HeaderComponent />
        {/* </Suspense> */}
        <RejectModal
          onSubmitReject={this.onSubmitReject}
          adddeshandleChange={this.adddeshandleChange}
          modelData={this.state}
          open={this.state.showaddModal}
          handleClose={this.onCloseModal}
        />
        <AcceptInviteAccount
          acceptRequest={this.acceptRequest}
          modelData={this.state}
          open={this.state.accpaddModal}
          handleClose={this.onCloseModal}
          territoryHandelchnge={this.territoryHandelchnge}
          regionHandelChange={this.regionHandelChange}
          areaHandelChange={this.areaHandelChange}
        />
        <Box component="span" className="company-comp">
          <div className="header-add-btn">
            <Typography variant="h5" className="tableTitle" component="div">
              Pending Invites
            </Typography>
          </div>
          <div style={{ maxWidth: "100%" }} className="Comp-Table Acc-Table">
            <div className="filter-search">
              <div className="new_custom_filters">
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openStatusFilterMenu}
                  >
                    {this.state.statusFilterName !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Status: </label>
                      )}
                    <label
                      aria-label="status_filter"
                      aria-controls="status_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.statusFilterName === ""
                        ? `Pending`
                        : upperFirst(this.state.statusFilterName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectComponent
                    filterArray={statusOptions}
                    config={this.state.statusConfig.config}
                    anchorEl={this.state.statusConfig.anchorElStatus}
                    handleClick={this.handleStatusSelect}
                    closeCustomPopup={this.closeStatusFilterMenu}
                  />

                </Suspense>
              </div>
              <CssTextField
                id="outlined-basic"
                name="searchFilter"
                variant="outlined"
                type="text"
                placeholder="Search Here"
                onChange={this.companyFilter}
                style={{
                  color: "#303960",
                  fontFamily: "Roboto",
                  backgroundColor: "#F9F9F9",
                  opacity: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: "pointer" }}
                    >
                      <img src={searchIcon} alt="search-icon" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="table_container">
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Division Name</TableCell>
                      <TableCell>Account Name</TableCell>
                      <TableCell>Requester Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell style={{ width: "10%" }}>
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <StyledTableRow>
                        <TableCell colSpan={9} style={{ textAlign: "center" }}>
                          <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={50}
                            width={50}
                            visible={loading}
                          />
                        </TableCell>
                      </StyledTableRow>
                    ) : !this.props.pageOfItems ||
                      this.props.pageOfItems.length === 0 ? (
                          <StyledTableRow>
                            <TableCell colSpan={9} style={{ textAlign: "center" }}>
                              No data Found
                        </TableCell>
                          </StyledTableRow>
                        ) : (
                          (this.state.rowsPerPage > 0
                            ? this.props.pageOfItems.slice()
                            : this.props.pageOfItems
                          ).map((row, index) => (
                            <StyledTableRow key={row._id}>
                              <TableCell>
                                {(this.props.pager.currentPage - 1) *
                                  this.state.rowsPerPage +
                                  (index + 1)}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row.divisionName ? row.divisionName : "- -"}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.accountName} {...this.state.tooltipprops} arrow>
                                  <span>{row.accountName}</span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.created_by_name} {...this.state.tooltipprops} arrow>
                                  <span>{row.created_by_name}</span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "0",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LightTooltip title={row.created_by_phone} {...this.state.tooltipprops} arrow>
                                  <a
                                    href={"tel:" + row.created_by_phone}
                                    rel="noopener noreferrer"
                                  >
                                    {row.created_by_phone}
                                  </a>
                                </LightTooltip>
                              </TableCell>
                              <TableCell>
                                {row.status === "accepted" ? (
                                  <div
                                    className="table_action_btns"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <LightTooltip title="Accepted" {...this.state.tooltipprops} arrow>
                                      <div
                                      >
                                        <img src={accept} alt="openmenu"></img>
                                      </div>
                                    </LightTooltip>
                                  </div>
                                ) : row.status === "rejected" ? (

                                  <div
                                    className="table_action_btns"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <LightTooltip title="Rejected" {...this.state.tooltipprops} arrow>
                                      <img
                                        src={reject}
                                        alt="openmenu"
                                      ></img>
                                    </LightTooltip>
                                  </div>
                                ) : (
                                      <div
                                        className="table_action_btns"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <LightTooltip title="Accept" {...this.state.tooltipprops} arrow>
                                          <div
                                            onClick={() => this.onViewAccept(row)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img src={accept} alt="openmenu"></img>
                                          </div>
                                        </LightTooltip>
                                        <LightTooltip title="Reject" {...this.state.tooltipprops} arrow>
                                          <img
                                            src={reject}
                                            alt="openmenu"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.onViewReject(row)}
                                          ></img>
                                        </LightTooltip>
                                      </div>
                                    )}
                              </TableCell>
                            </StyledTableRow>
                          ))
                        )}
                  </TableBody>
                  <TableFooter>
                    {/* <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={9}
                        count={
                          !this.props.pager ? 0 : this.props.pager.totalItems
                        }
                        rowsPerPage={this.state.rowsPerPage}
                        page={
                          !this.props.pager
                            ? 0
                            : this.props.pager.currentPage - 1
                        }
                        labelDisplayedRows={({ from, to, count }) =>
                          `Showing ${!to ? 0 : to} of ${count === undefined ? 0 : count
                          } entries`
                        }
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </TableRow> */}
                    {this.props.pageOfItems &&
                      this.props.pageOfItems.length !== 0 ?
                      <TableRow>
                        <td colSpan={20} className="pagination_container">
                          <CustomPaginationComponent
                            count={this.props.pager.endPage}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.pageNumber}
                            onPageChange={this.handleCustomPaginagtionChange}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </td>
                      </TableRow> : null}
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Box>
      </div>
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <div>{CompanyPage}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
    pager: state.pagination.pager,
    pageOfItems: state.pagination.pageOfItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole) =>
      dispatch(action.pendingAccountPagination(pageNo, pagerole)),
    onFilter: (states) => dispatch(action.onpendingAccountFilter(states)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PendingInviteAccountComponent));
