/** actions for login */
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const PAGINATION_REQUEST = "PAGINATION_REQUEST";
export const PAGINATION_SUCCESS = "PAGINATION_SUCCESS";
export const PAGINATION_FAILURE = "PAGINATION_FAILURE"; 

export const TASKPAGINATION_REQUEST = "TASKPAGINATION_REQUEST";
export const TASKPAGINATION_SUCCESS = "TASKPAGINATION_SUCCESS";
export const TASKPAGINATION_FAILURE = "TASKPAGINATION_FAILURE"; 

export const SERVICEPAGINATION_REQUEST = "SERVICEPAGINATION_REQUEST";
export const SERVICEPAGINATION_SUCCESS = "SERVICEPAGINATION_SUCCESS";
export const SERVICEPAGINATION_FAILURE = "SERVICEPAGINATION_FAILURE"; 