import React, { useState, useEffect } from "react";
import "./CMSComponent.css";
import iconlyLightEdit from "../../../Assets/images/Iconly-Light-Edit-Square.svg";
import iconlyLightDelete from "../../../Assets/images/Iconly-Light-Delete.svg";
import iconlyLightDeleteRed from "../../../Assets/images//Iconly-Light-Delete-Red.svg";
import SaveComponent from "./SaveComponent";


const CancelNoteComponent = ({ cmsCancelNoteData, getCancelNoteList }) => {
  const [cancelNoteList, setCancelNoteList] = useState([]);

  useEffect(() => {
    setCancelNoteList(cmsCancelNoteData ? cmsCancelNoteData : []);
  }, [cmsCancelNoteData]);

  const onChangeHandler = (e, id) => {
    const { value } = e.target;
    setCancelNoteList((notes) =>
      notes?.map((list, index) =>
        index === id ? { ...list, text: value } : list
      )
    );
  };

  const editHandler = (item) => {
    setCancelNoteList((notes) =>
      notes?.map((list) =>
        list.index === item.index
          ? { ...list, isEdited: true, isDeleted: false }
          : list
      )
    );
  };

  const cancelHandler = (item) => {
    setCancelNoteList((notes) => {
      let updatedNotes = notes?.map((list) =>
        list.index === item.index
          ? { ...list, isDeleted: !item.isDeleted, isEdited: false }
          : list
      );
      const lastEmptyItemIndex = updatedNotes
        .slice()
        .reverse()
        .findIndex((list) => list.text === "");
      if (lastEmptyItemIndex !== -1) {
        const indexToRemove = updatedNotes.length - 1 - lastEmptyItemIndex;
        updatedNotes = updatedNotes.filter(
          (_, index) => index !== indexToRemove
        );
      }
      return updatedNotes;
    });
  };

  const reasonAddHandler = () => {
    setCancelNoteList((prevList) => {
      const lastEntry = prevList[prevList.length - 1];
      if (!lastEntry || lastEntry.text !== "") {
        return [
          ...prevList,
          {
            index: prevList.length + 1,
            text: "",
            isEdited: true,
            isDeleted: false,
          },
        ];
      } else {
        return prevList;
      }
    });
  };

  return (
    <div>
      <div className="customized-scrollbar-container">
      {cancelNoteList &&
        cancelNoteList.length > 0 &&
        cancelNoteList.map((item, index) => (
          <div key={index} className="item_container">
            <input
              className="cancel_inputbox"
              type="text"
              value={item.text}
              disabled={!item.isEdited}
              style={{
                border: item.isEdited
                  ? "1px solid #295195"
                  : item.isDeleted
                  ? "1px solid #BF3353"
                  : "1px solid #E2E5F2",
                outline: "none",
              }}
              onChange={(e) => item.isEdited && onChangeHandler(e, index)}
            />
            <img
              className="cancel_edit"
              src={iconlyLightEdit}
              alt="iconlyLightEdit"
              onClick={() => editHandler(item)}
            />
            {item.isDeleted ? (
              <img
                className="cancel_delete"
                src={iconlyLightDeleteRed}
                alt="iconlyLightDeleteRed"
                onClick={() => cancelHandler(item)}
              />
            ) : (
              <img
                className="cancel_delete"
                src={iconlyLightDelete}
                alt="iconlyLightDelete"
                onClick={() => cancelHandler(item)}
              />
            )}
          </div>
        ))}
        </div>
      <span className="add_reason_cancel_note" onClick={reasonAddHandler}>
        +Add Reason
      </span>
      <div>
        <SaveComponent
          listData={cancelNoteList}
          keyValue="notes"
          getCancelNoteList={getCancelNoteList}
        />
      </div>
    </div>
  );
};

export default CancelNoteComponent;
