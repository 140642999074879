import React, { Suspense, useState, useEffect } from "react";
import { Card, TextField, Button } from "@material-ui/core";
import email from "../../../Assets/images/Icon simple-email.svg";
import phone from "../../../Assets/images/phone.svg";
import iconLocation from "../../../Assets/images/Icon-location-on.svg";
import division from "../../../Assets/images/Icon material-device-hub1.svg";
import editprofile from "../../../Assets/images/Group 427.svg";
import editIcon from "../../../Assets/images/draw.svg";
import DefaultProfile from "../../../Assets/images/dummy-profile-image.png";
import { Alert } from "@material-ui/lab";
import "./ProfileComponent.css";
import { withStyles } from "@material-ui/core/styles";
import close_icon_red from "../../../Assets/img/close_icon_red.svg";
import Loader from "react-loader-spinner";
import { GoogleMapsAPI } from "../../../client-config";
import AutoComplete from "react-google-autocomplete";
import {checkCmsLabel} from "../../../Utilities";

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField);

const ProfileComponent = (props) => {
  const [roleLabelChange, setRoleLabelChange] = useState([])
  useEffect(()=>{
    const fetchCmsData = checkCmsLabel()
    if(fetchCmsData){
      setRoleLabelChange(fetchCmsData?.roleData)
    }
  }, [])
  return (
    <Card className="signup-root">
      <div className="card-header pro_box_ht">
        <div className="profile-header-content ">
          <div className={props.prohidden}>
            {props.profilePath === "" ||
              props.profilePath === undefined ? (
              <div className="prof_avtar">
                <img
                  className="avatar_img"
                  alt={props.profileData.name}
                  src={DefaultProfile}
                />
                <span className={"avatar_input_file_container " + props.hidePro}>
                  <input onMouseEnter={() => props.showButton("hidePro")} onMouseLeave={() => props.hideButton("hidePro")}
                    className="avatar_edit_img avatar_input_file"
                    type="file"
                    name="profilePic"
                    onChange={props.uplodProf}
                  />
                </span>
                <img
                  className="avatar_edit_img"
                  src={editprofile}
                  style={{ cursor: "pointer" }}
                />
                <p style={{ color: "red", fontSize: "14px" }}>
                  {props.invalidImage}{" "}
                </p>
              </div>
            ) : (
              <div className="prof_avtar">
                <img
                  className="avatar_img"
                  alt={props.profileData.name}
                  src={
                    "https://salesc2userprofile.s3.amazonaws.com/" +
                    props.profilePath
                  }
                />
                <span className={"avatar_input_file_container " + props.hidePro}>
                  <input
                    onMouseEnter={() => props.showButton("hidePro")} onMouseLeave={() => props.hideButton("hidePro")}
                    className="avatar_edit_img avatar_input_file"
                    type="file"
                    name="profilePic"
                    onChange={props.uplodProf}
                  />
                </span>
                <img
                  className="avatar_edit_img"
                  src={editprofile}
                  style={{ cursor: "pointer" }}
                />
                <p style={{ color: "red", fontSize: "14px" }}>
                  {props.invalidImage}{" "}
                </p>
              </div>
            )}{" "}
            <Loader
              className="pro_loding"
              type="ThreeDots"
              color="#00BFFF"
              height={50}
              width={50}
              visible={props.loading}
            />
          </div>
          <div className="prof_name">
            {" "}
            <h2>{props.profileData.name}</h2>{" "}
            <p>
              {props.profileData.role ?
                <>
                  {props.profileData.role.toLowerCase() === "super admin" ? (
                    <>
                      {" "}
                      <span>
                        {props.profileData.role
                          ? "Super Admin"
                          : "-"}
                      </span>
                    </>
                  ) : props.profileData.role.toLowerCase() === "admin" ? (
                    <>
                      {" "}
                      <span >
                        {props.profileData.role
                          ? (roleLabelChange.Admin ? roleLabelChange.Admin :"Admin")
                          : "-"}
                      </span>
                    </>
                  ) : props.profileData.role.toLowerCase() === "division manager" ? (
                    <>
                      {" "}
                      <span >
                        {props.profileData.role
                          ? "Division Leader" (roleLabelChange ? roleLabelChange.DivisionLeader :"Division Leader")
                          : "-"}
                      </span>
                    </>
                  ) : props.profileData.role.toLowerCase() === "territory manager" ? (
                    <>
                      {" "}
                      <span>
                        {props.profileData.role
                          ? (roleLabelChange.TerritoryManager ? roleLabelChange.TerritoryManager :"Territory Manager")
                          : "-"}
                      </span>
                    </>

                  ) : props.profileData.role.toLowerCase() === "sales rep" ? (
                    <>
                      {" "}
                      <span>
                        {props.profileData.role
                          ? (roleLabelChange.Representative ? roleLabelChange.Representative :"Representative")
                          : "-"}
                      </span>
                    </>

                  ) : props.profileData.role.toLowerCase() === "area manager" ? (
                    <>
                      {" "}
                      <span>
                        {props.profileData.role
                          ?  (roleLabelChange.AreaDirector ? roleLabelChange.AreaDirector :"Area Director")
                          : "-"}
                      </span>
                    </>
                  ) : props.profileData.role.toLowerCase() === "team lead" ? (
                    <>
                      {" "}
                      <span >
                        {props.profileData.role
                          ?  (roleLabelChange.RegionalManager ? roleLabelChange.RegionalManager :"Regional Manager")
                          : "-"}
                      </span>
                    </>
                  ) : ("")}
                </>
                : ""}

            </p>
          </div>
        </div>
      </div>
      {props.responseMsg != "" ? (
        <Alert severity={props.alertType}>
          {props.responseMsg}
        </Alert>
      ) : null}
      <div className="profile-header-content profile-header-content-two">
        <div className="key_name">Email</div>
        <div className="profile_value">
          <img src={email} />
          <span className="profile_value_span">
            {" "}
            {props.profileData.email}
          </span>
        </div>
        <div className="key_name">Number</div>
        <div className="profile_value" onMouseEnter={() => props.showButton("hidePhone")} onMouseLeave={() => props.hideButton("hidePhone")} >
          <img src={phone} />
          <div className={props.hidden}>
            <span className="profile_value_span">
              {" "}
              {props.phone}
            </span>
            <img
              className={props.hidePhone + " edit_icon"}
              src={editIcon}
              style={{ cursor: "pointer" }}
              onClick={(event) => props.showMobEdit(event)}
            />
          </div>
          <div className="info-card-detail-left " className={props.show}>
            <span className="edit-input add_company_dialog">
              <form
                className="edit-input add_company_dialog"
                onSubmit={props.onSubmit}
              >
                <CssTextField
                  id="outlined-basic"
                  name="editphone"
                  variant="outlined"
                  type="text"
                  placeholder="Enter Phone Number"
                  value={props.editphone}
                  onChange={props.handlepPhoneChange}
                />
                <Button
                  type="submit"
                  className="add_more_btn"
                >
                  Save
                      </Button>
              </form>
              <img
                className={props.show}
                src={close_icon_red}
                style={{ cursor: "pointer" }}
                onClick={(event) => props.hideMobEdit(event)}
              />
            </span>
          </div>
        </div>
        <div className="profile_ErrorMassage">
          <span className="modal-err-msg">{props.formError}</span>
        </div>
        <div className="key_name">Address</div>
        <div className="profile_value" onMouseEnter={() => props.showButton("hideAddress")} onMouseLeave={() => props.hideButton("hideAddress")} >
          <img src={iconLocation} />
          {props.isAddress && (
            <div>
              <span className="profile_value_span">
                {" "}
                {props.address}
              </span>
              <img
                className={props.hideAddress + " edit_icon"}
                src={editIcon}
                style={{ cursor: "pointer" }}
                onClick={(event) => props.showAddress(event)}
              />
            </div>
          )}
          {!props.isAddress && (
            <div className="info-card-detail-left ">
              <span className="edit-input add_company_dialog">
                <Suspense fallback={<div></div>}>
                  <AutoComplete
                    style={{
                      width: "100%",
                    }}
                    name="address"
                    autoFocus
                    apiKey={GoogleMapsAPI}
                    types={[]}
                    defaultValue={props.address}
                    onPlaceSelected={props.onGooglePlaceSelect}
                    onChange={props.handleAddressChange}

                  />
                </Suspense>
                <Button
                  disabled={props.disableAdress}
                  className="add_more_btn"
                  onClick={(event) => props.onAddressSubmit(event)}
                >
                  Save
                      </Button>
                <img
                  src={close_icon_red}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => props.hideAddress(event)}
                />
              </span>
            </div>
          )}
        </div>
        <div className="profile_ErrorMassage">
          <span className="modal-err-msg">{props.formErrAddress}</span>
        </div>
        {props.role.toLowerCase() == "super admin" ||
          props.role.toLowerCase() == "admin" ? (
          ""
        ) : (
          <>
            <div className="key_name">Team</div>
            <div className="profile_value">
              <img className="division_img" src={division} />
              <div>
                {props.profileData.division === undefined ? (
                  ""
                ) : (
                  <div className="division_value">
                    <span className="profile_value_span profile_value_span_key">
                      Division
                          </span>
                    <span className="profile_value_seperator profile_value_span">
                      :
                          </span>
                    <span className="profile_value_two profile_value_span">
                      {props.profileData.division}
                    </span>
                  </div>
                )}
                {props.profileData.area === undefined ? (
                  ""
                ) : (
                  <div className="division_value">
                    <span className="profile_value_span profile_value_span_key">
                      Area
                          </span>
                    <span className="profile_value_seperator profile_value_span">
                      :
                          </span>
                    <span className="profile_value_two profile_value_span">
                      {props.profileData.area}
                    </span>
                  </div>
                )}
                {props.profileData.region === undefined ? (
                  ""
                ) : (
                  <div className="division_value">
                    <span className="profile_value_span profile_value_span_key">
                      Region
                          </span>
                    <span className="profile_value_seperator profile_value_span">
                      :
                          </span>
                    <span className="profile_value_two profile_value_span">
                      {props.profileData.region}
                    </span>
                  </div>
                )}
                {props.profileData.territory === undefined ? (
                  ""
                ) : (
                  <div className="division_value">
                    <span className="profile_value_span profile_value_span_key">
                      Territory
                          </span>
                    <span className="profile_value_seperator profile_value_span">
                      :
                          </span>
                    <span className="profile_value_two profile_value_span">
                      {props.profileData.territory}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Card>

  )
}

export default ProfileComponent;
