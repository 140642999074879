import React, { Component, Suspense } from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  MenuItem,
  Menu,
  Button,
  Divider,
  Checkbox,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  AccountCircle,
  Mail,
  NotificationsNoneOutlined,
} from "@material-ui/icons";

import { MobileHeaderModal } from "./MobileHeaderModal";
import AddTaskModal from "../../Components/TaskComponent/AddTaskModal";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import logo from "../../Assets/images/Sales_C2_logo.svg";
import mobileMenuIcon from "../../Assets/images/Icon feather-menu.svg";
import notificationIcon from "../../Assets/images/notification-icon.svg";
import moment from "moment";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import userIcon from "../../Assets/images/user-icon.png";
import settingsIcon from "../../Assets/images/settings-icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import carret_down from "../../Assets/images/carret_down.svg";
import { ProfileURL } from "../../client-config";
import ReactTimeAgo from "react-time-ago";
import "./HeaderComponent.css";
import io from "socket.io-client";
import {
  AxiosInstance,
  NOTIFICATION_ENDPOINT,
  basedUrlForSuperAdmin,
  checkTimeZone,
  checkCmsLabel
} from "../../Utilities";
import momentTimeZone from "moment-timezone";
import { NotificationComponent } from "../NotificationComponent/NotificationComponent";
import { upperFirst } from "lodash";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";
import Axios from "axios";
import { notification } from "antd";
import TaskAssignModal from "../SocketNotificationPopUpComponent/TaskAssignModal/TaskAssignModal";
import TaskConflictModal from "../SocketNotificationPopUpComponent/TaskConflictModal/TaskConflictModal";
import TimeAgo from "timeago-react";
import ReminderModal from "../SocketNotificationPopUpComponent/ReminderModal/ReminderModal";

var PopConfirmComponent = React.lazy(() =>
  import("../../SharedComponents/PopConfirmComponent/PopConfirmComponent")
);
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
const GreenCheckbox = withStyles({
  root: {
    color: "#424242",
    "&$checked": {
      color: "#21BF73",
    },
  },
  checked: {},
})(Checkbox);

var socket;
class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.handleRedirect = this.handleRedirect.bind(this);
    var zoneName = momentTimeZone.tz.guess();
    this.getTimeZones();
    this.state = {
      cmsLabelChange: [],
      scheduleRules: "",
      recurringText: "",
      timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
      disable: false,
      showdelete: false,
      showBilling: false,
      popConfirmTitle: "Notification",
      action: "clear",
      actionName: "Clear",
      singleClearId: null,
      lengths: "",
      userData: localStorage.getItem("userdata")
        ? JSON.parse(localStorage.getItem("userdata"))
        : "",
      profilePath: localStorage.getItem("userdata")
        ? JSON.parse(localStorage.getItem("userdata")).profilePath
        : "",
      notification_endpoint: NOTIFICATION_ENDPOINT,
      anchorEl: null,
      showModal: false,
      mobileMoreAnchorEl: null,
      menuMoreAnchorEl: null,
      open: false,
      openTask: false,
      anchorNotifyEl: null,
      markAsRead: false,
      notifyItems: [],
      notifyMarkCount: null,
      onHoverItem: null,
      notifyLoader: false,
      hasMore: true,
      nextpage: 1,
      pagesize: 3,
      endPage: null,
      taskAssignModalOpen: false,
      taskAssigneeData: null,
      taskConflictModalData: null,
      taskConflictModalOpen: false,
      diffTime: null,
      diffTime1: null,
      readId: "",
      moreOptionFilter: "",
      more_option: {
        id: "moreoption_filter_control",
        anchorElmoreOption: null,
      },

      billStatus: null,
      restrictAccessModal: false,
      reminderModalOpen: false,
      reminderData: null,
      reminderDatas: [],
      item_id: "",
      notification_id: ""
    };
  }

  handleRedirect(event,value) {
    const { pathname } = this.props.location;
    if (pathname === value) {
      event.preventDefault();
    } else{
      window.location.href=`${value}?page=1`
    }
  }
  UNSAFE_componentWillMount() {
    if (this.props.location.pathname !== "/subscription" && this.props.location.pathname !== "/billing") {
      this.fetchBillingStatus();
    }

    if (
      this.state.userData &&
      this.state.userData.role.toLowerCase() !== "super admin"
    ) {
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
          ? 6019
          : "subscription",
        "application/json"
      )
        .get("/show_billing")
        .then((resp) => {
          let response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            this.setState({
              showBilling: response.data.showBilling,
            });
          } else if (
            response &&
            (response.status === "400" || response.status === 400)
          ) {
            // let propserror = {
            //   message: response.msg,
            //   icon: <img src={iconNotificationError} alt="success" />,
            //   type: "error",
            //   placement: "topRight",
            //   duration: 3,
            //   top: 200,
            //   className: "task_notification error-notify",
            // };
            // NotificationComponent(propserror);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        });
    }
  }
  componentDidMount() {
    const fetchCmsData = checkCmsLabel()
    if (fetchCmsData) {
      this.setState({ cmsLabelChange: fetchCmsData.cmsData })
    }
    socket = io.connect(this.state.notification_endpoint);
    socket.on(`notificationCount${this.state.userData.email}`, (data) => {
      this.setState(
        {
          notifyMarkCount: data.count,
        },
        () => {
          this.getNotificationItems();
        }
      );
    });
    socket.on(`notificationPopup${this.state.userData.email}`, (data) => {
      var datas = {
        _id: data._id,
        conflict: data.conflict,
        startDate: data.startDate,
        endDate: data.endDate,
        priority: data.priority,
        taskName: data.taskName,
        conflictName: data.conflictName,
        conflictId: data.conflictId,
        notificationId: data.notificationId,
        email: data.email,
        scheduleRules: data.scheduleRules,
        recurringText: data.recurringText,
        type: data.type === "1" ? "0" : "1"
      }
      var end_date = momentTimeZone(datas.endDate).tz(this.state.timeZoneName);
      var start_date = momentTimeZone(datas.startDate).tz(
        this.state.timeZoneName
      );
      var diffTime = end_date.diff(start_date, "hours");
      var diffMinutes = end_date.diff(start_date, "minutes");
      var diffSeconds = end_date.diff(start_date, "seconds");
      diffTime =
        diffTime === 0
          ? diffMinutes + " Minutes"
          : diffMinutes === 0
            ? diffSeconds + " Seconds"
            : diffTime + " Hours";
      this.setState(
        {
          taskAssignModalOpen: true,
          taskAssigneeData: datas,
          diffTime,
          notification_id: datas.notificationId,
          scheduleRules: datas.isRecurring,
          recurringText: datas.recurringText
        },
        () => {
          this.getNotificationItems();
        }
      );
    });

    socket.on(`eventNotificationPopup${this.state.userData.email}`, (data) => {
      var data = {
        _id: data._id,
        conflict: data.conflict,
        conflictId: data.conflictId,
        startDate: data.startDate,
        endDate: data.endDate,
        description: data.description,
        title: data.title,
        conflictName: data.conflictName,
        conflictType: data.conflictType,
        notificationId: data.notificationId,
        email: data.email,
        priority: data.priority,
        organizer: data.organizer,
        eventType: data.eventType,
        eventType_type: data.eventType_type,
        type: data.type === "1" ? "0" : "1"
      }
      var end_date = momentTimeZone(data.endDate).tz(this.state.timeZoneName);
      var start_date = momentTimeZone(data.startDate).tz(
        this.state.timeZoneName
      );
      var diffTime = end_date.diff(start_date, "hours");
      var diffMinutes = end_date.diff(start_date, "minutes");
      var diffSeconds = end_date.diff(start_date, "seconds");
      diffTime =
        diffTime === 0
          ? diffMinutes + " Minutes"
          : diffMinutes === 0
            ? diffSeconds + " Seconds"
            : diffTime + " Hours";
      this.setState(
        {
          taskAssignModalOpen: true,
          taskAssigneeData: data,
          diffTime,
          notification_id: data.notificationId
        },
        () => {
          this.getNotificationItems();
        }
      );
    });

    socket.on(`notificationReminders${this.state.userData.email}`, (data) => {
      var finalData = data;
      var reminderArray = this.state.reminderDatas;
      reminderArray.push(finalData)
      if (this.state.userData.email == data.email) {
        this.setState({
          reminderModalOpen: true,
          reminderDatas: reminderArray
        })
      }
    })

    socket.on(`notificationReminderAdditionalRep${this.state.userData.email}`, (data) => {
      var finalData = data;
      var reminderArray = this.state.reminderDatas;
      reminderArray.push(finalData)
      for (var i = 0; i < data.additionalRep.length; i++) {
        if (this.state.userData.email == data.additionalRep[i].email) {
          this.setState({
            reminderModalOpen: true,
            reminderDatas: reminderArray
          })
        }
      }
    })

    setTimeout(() => {
      this.getNotifications()
    }, 2000)
  }

  getNotifications = () => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : "task"
    )
      .post("/notifications/get_notifications", {
        timeZone: this.state.timeZoneName ? this.state.timeZoneName : momentTimeZone.tz.guess(),
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            notifyMarkCount: response.count,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            notifyMarkCount: null,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
        this.setState({
          notifyMarkCount: null,
        });
      });
  }

  getTimeZones = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
      .get("/employee/timezone")
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          let names = response.data.timezone;
          this.setState({
            timeZoneName: names === null || names === undefined ? this.state.timeZoneName : names
          }, () => {
            localStorage.setItem("timeZone", this.state.timeZoneName)
            checkTimeZone();
          });
        } else if (response && (response.status === "400" || response.status === 400)) {

        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  fetchBillingStatus = () => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      "application/json"
    )
      .get("/employee/billingStatus")
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          this.setState({
            billStatus: response.billStatus,
            restrictAccessModal: response.billStatus === 0 ? false : true,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  // timeSince=(date)=> {
  //   console.log("date--", moment(date).format("YYYY-MM-DD HH:mm:ss").replace(/ /g,"T"))
  //   var seconds = Math.floor((new Date() - date) / 1000);

  //   var interval = seconds / 31536000;

  //   if (interval > 1) {
  //     return Math.floor(interval) + " years";
  //   }
  //   interval = seconds / 2592000;
  //   if (interval > 1) {
  //     return Math.floor(interval) + " months";
  //   }
  //   interval = seconds / 86400;
  //   if (interval > 1) {
  //     return Math.floor(interval) + " days";
  //   }
  //   interval = seconds / 3600;
  //   if (interval > 1) {
  //     return Math.floor(interval) + " hours";
  //   }
  //   interval = seconds / 60;
  //   if (interval > 1) {
  //     return Math.floor(interval) + " minutes";
  //   }
  //   return Math.floor(seconds) + " seconds";
  // }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleClickOpenTaskmodal = () => {
    this.setState({
      openTask: true,
    });
  };
  handleCloseTaskmodal = () => {
    this.setState({
      openTask: !this.state.openTask,
    });
  };

  handleProfileMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMobileMenuClose = () => {
    this.setState({
      mobileMoreAnchorEl: null,
    });
  };
  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      mobileMoreAnchorEl: null,
    });
  };
  handleMobileMenuOpen = (event) => {
    this.setState({
      mobileMoreAnchorEl: event.currentTarget,
    });
  };

  menuMoreClick = (event) => {
    this.setState({
      menuMoreAnchorEl: event.currentTarget,
    });
  };

  menuMoreClose = () => {
    this.setState({
      menuMoreAnchorEl: null,
    });
  };

  logoutHandler = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  onMoreOptionFilterOpen = (event) => {
    const { more_option } = this.state;
    more_option.anchorElmoreOption = event.currentTarget;
    this.setState({
      more_option,
    });
  };
  onMoreOptionFilterClose = () => {
    const { more_option } = this.state;
    more_option.anchorElmoreOption = null;
    this.setState({
      more_option,
    });
  };
  onMoreOptionFilter = (item) => {
    const { more_option } = this.state;
    more_option.anchorElmoreOption = null;

    this.setState(
      {
        more_option,
      },
      () => {
        if (item._id === "services") {
          if (
            window.location.pathname.indexOf("/services") === 0 ||
            window.location.pathname.indexOf("/services") === -1
          ) {
            this.props.history.replace(`/services`);
          }
        } else if (item._id === "org-structure") {
          if (
            window.location.pathname.indexOf("/org-structure") === 0 ||
            window.location.pathname.indexOf("/org-structure") === -1
          ) {
            this.props.history.replace(`/org-structure`);
          }
        } else if (item._id === "reports") {
          if (
            window.location.pathname.indexOf("/reports") === 0 ||
            window.location.pathname.indexOf("/reports") === -1
          ) {
            this.props.history.replace(`/reports`);
          }
        } else if (item._id === "billing") {
          if (
            window.location.pathname.indexOf("/billing") === 0 ||
            window.location.pathname.indexOf("/billing") === -1
          ) {
            this.props.history.replace(`/billing`);
          }
        }
      }
    );
  };

  switchBackToSuperAdminHandler = () => {
    if (localStorage.getItem("old_token")) {
      Axios.defaults.headers["Authorization"] =
        `Bearer ` + localStorage.getItem("old_token");
      Axios.get(
        `${basedUrlForSuperAdmin}${(process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : "company"
        }/api/v1/superadmin/createSuperAdminAccess`
      )
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            localStorage.clear();
            localStorage.setItem("userdata", JSON.stringify(response.data));
            localStorage.setItem("token", response.token.split(" ")[1]);
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
          } else if (
            response &&
            (response.status === "400" || response.status === 400)
          ) {
            let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        });
    } else {
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6004 : "company"
      )
        .get("/superadmin/createSuperAdminAccess")
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            localStorage.clear();
            localStorage.setItem("userdata", JSON.stringify(response.data));
            localStorage.setItem("token", response.token.split(" ")[1]);
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
          } else if (
            response &&
            (response.status === "400" || response.status === 400)
          ) {
            let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        });
    }
  };
  /**
   * @author Benakesh
   * @param {target element} event
   * To open notification dialog
   */
  handleNotificationMenuOpen = (event) => {
    this.setState(
      {
        notifyLoader: true,
        anchorNotifyEl: event.currentTarget,
        nextpage: 1,
        pagesize: 3,
        hasMore: true,
        notifyItems: [],
      },
      () => {
        this.getNotificationItems();
      }
    );
  };
  getNotificationItems = () => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : "task"
    )
      .post("/notifications/get_notifications", {
        nextpage: this.state.nextpage,
        pagesize: this.state.pagesize,
        timeZone: this.state.timeZoneName,
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var newArray = [];
          response.data.map((items) => {
            var itemObj = {};
            itemObj._id = items?._id;
            itemObj.description = items?.description;
            itemObj.read = items?.read;
            itemObj.refId = items?.refId;
            itemObj.type = items?.type;
            itemObj.showEvent = items?.showEvent;
            itemObj.title = items?.title;
            itemObj.showPopup = items?.showPopup;
            itemObj.recurringId = items?.recurringId;
            itemObj.dataStatus = items?.dataStatus;
            itemObj.enddate = items?.enddate;
            itemObj.createdAt = new Date(items.createdAt);
            newArray.push(itemObj);
          });
          this.setState({
            notifyLoader: false,
            notifyMarkCount: response.count,
            notifyItems: newArray,
            nextpage: response.pagination.currentPage,
            endPage: response.pagination.endPage,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
          this.setState({
            notifyLoader: false,
            notifyItems: [],
            nextpage: 1,
            endPage: null,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
        this.setState({
          notifyLoader: false,
          notifyItems: [],
          nextpage: 1,
          endPage: null,
        });
      });
  };
  /**
   * @author Benakesh
   * To close notification dialog
   */
  handleNotificationMenuClose = () => {
    this.setState({
      anchorNotifyEl: null,
    });
  };

  /**
   * @author Benakesh
   *  To fetch list of notification on scroll
   */
  fetchMoreData = () => {
    setTimeout(() => {
      if (this.state.endPage !== this.state.nextpage) {
        AxiosInstance(
          (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : "task"
        )
          .post("/notifications/get_notifications", {
            nextpage: this.state.nextpage + 1,
            pagesize: this.state.pagesize,
            timeZone: this.state.timeZoneName,
          })
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              var newArray = [];
              response.data.map((items) => {
                var itemObj = {};
                itemObj._id = items?._id;
                itemObj.description = items?.description;
                itemObj.read = items?.read;
                itemObj.refId = items?.refId;
                itemObj.title = items?.title;
                itemObj.type = items?.type;
                itemObj.recurringId = items?.recurringId;
                itemObj.dataStatus = items?.dataStatus;
                itemObj.enddate = items?.enddate;
                itemObj.showEvent = items?.showEvent;
                itemObj.showPopup = items?.showPopup;
                itemObj.createdAt = new Date(items?.createdAt);
                newArray.push(itemObj);
              });
              this.setState({
                notifyLoader: false,
                notifyMarkCount: response.count,
                notifyItems: newArray,
                nextpage: this.state.nextpage + 1,
              });
            } else if (
              (response && response.status === "400") ||
              response.status === 400
            ) {
              let propserror = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                notifyLoader: false,
                notifyItems: [],
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (
              error &&
              (error.status === "500" || error.status === 500)
            ) {
              let propserror = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
            }
            this.setState({
              notifyLoader: false,
              notifyItems: [],
            });
          });
      } else {
        this.setState({
          hasMore: false,
        });
      }
    }, 1000);
  };

  /**
   * @author Benakesh
   *  To clear items on exit of dialog
   */
  clearItems = () => {
    this.setState({
      onHoverItem: null,
      anchorNotifyEl: null,
      hasMore: true,
    });
  };

  /**
   * @author Benakesh
   * @param {Index} index
   *
   */
  onMouseOverHandler = (index) => {
    this.setState({ onHoverItem: index });
  };
  /**
   * @author Benakesh
   *  To set mark all as read
   */
  mark_all_as_read_handler = () => {
    this.update_all_notification("read");
  };

  /**
   * @author Benakesh
   *  To clear all notification
   */
  clear_all_handler = () => {
    this.setState({
      showdelete: true,
      lengths: "all",
    });
  };

  /**
   * @author Benakesh
   * @param {read/clear} type
   * @param {notification_id} id
   * To clear/read all notification
   */
  update_all_notification = (type) => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : "task"
    )
      .post("/notifications/edit_all_notifications", {
        type,
        timeZone: this.state.timeZoneName,
      })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          this.setState({
            notifyItems: response.data,
            notifyMarkCount: response.count,
            disable: false,
            showdelete: false,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        this.setState({
          disable: true,
        });
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };

  /**
   * @author Benakesh
   * @param {notification id} index
   * To set mark as read on click of switch icon
   */
  on_mark_as_read_handler = (id, notification_id, read) => {
    var types = read ? "unread" : "read";
    if (types === "read") {
      this.setState({
        readId: notification_id ? notification_id : id,
      });
    } else {
      this.setState({
        readId: "",
      });
    }
    this.update_notification(types, notification_id, id);
  };

  /**
   * @author Benakesh
   * @param {notification id} index
   * Clear specific notification
   */
  on_clear_notification_handler = (id) => {
    this.setState({
      showdelete: true,
      lengths: "single",
      singleClearId: id,
    });
  };

  /**
   * @author Benakesh
   * @param {read/clear} type
   * @param {notification_id} id
   * To clear/read single notification
   */
  update_notification = (type, id, notification_id) => {
    let resId = notification_id ? notification_id : this.state.notification_id ? this.state.notification_id : id ? id : this.state.item_id
    let resData = {
      type: type,
      id: resId,
      timeZone: this.state.timeZoneName,
    }
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : "task"
    )
      .post("/notifications/edit_single_notifications",
        resData
      )
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          this.setState({
            notifyItems: response.data,
            notifyMarkCount: response.count,
            disable: false,
            showdelete: false,
          });
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          let propserror = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      })
      .catch((err) => {
        this.setState({
          disable: true,
        });
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };

  /**
   * @author Benakesha
   * @param {task id} refId
   * TO OPEN TASK ASSIGNEE POPUP ON CLICK OF TASK IN NOTIFICATION LIST
   * IF showPopuP Is TRUE
   */
  onOpenTaskAssigneeModalHandler = (item) => {
    this.update_notification("read", item._id);
    this.getNotificationItems();
    this.setState({
      item_id: item._id
    })
    if (
      this.state.userData &&
      this.state.userData.role.toLowerCase() !== "super admin"
    ) {
      if (
        item.showPopup &&
        (item.type === 0 || item.type === "0")
      ) {
        AxiosInstance(
          (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : "task",
          "application/json"
        )
          .post("/notifications/task_notification", { id: item.refId })
          .then((resp) => {
            var response = resp && resp.data;
            if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              var data = response.data;
              var end_date = momentTimeZone(data.endDate).tz(
                this.state.timeZoneName
              );
              var start_date = momentTimeZone(data.startDate).tz(
                this.state.timeZoneName
              );
              var diffTime = end_date.diff(start_date, "hours");
              var diffMinutes = end_date.diff(start_date, "minutes");
              var diffSeconds = end_date.diff(start_date, "seconds");
              diffTime =
                diffTime === 0
                  ? diffMinutes + " Minutes"
                  : diffMinutes === 0
                    ? diffSeconds + " Seconds"
                    : diffTime + " Hours";
              this.setState({
                taskAssignModalOpen: true,
                diffTime,
                taskAssigneeData: { ...data, type: item.type.toString() },
              });
            } else if (
              response &&
              (response.status === "400" || response.status === 400)
            ) {
              var props = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(props);
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (
              error &&
              (error.status === "500" || error.status === 500)
            ) {
              let propserror = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
            }
          });
      } else if (
        item.showPopup === false &&
        (item.type === 0 || item.type === "0")
      ) {
        if (
          window.location.pathname.indexOf("/task") === 0 ||
          window.location.pathname.indexOf("/task") === -1
        ) {
          window.location.href = `/task/${item.refId}`;
        }
      } else if (item.type === 1 || item.type === "1") {
        if (
          window.location.pathname.indexOf("/eventdetail") === 0 ||
          window.location.pathname.indexOf("/eventdetail") === -1
        ) {
          window.location.href = `/eventdetail/${item.refId}`;
        }
      }
    } else {
      notification.info({
        duration: 2,
        message: (
          <div>
            <p>Please switch to company admin to view detail</p>
          </div>
        ),
      });
    }
  };
  /**
   * TO CLOSE THE TASK ASSIGNEE MODAL
   */
  taskAssignModalClose = () => {
    this.setState({
      taskAssignModalOpen: false,
    });
  };

  /**
   *
   * @author Benakesha
   * @param {type of button} type
   * type=accept/reject
   * @param {task id} id
   * TO HANDLE ACCEPT/REJECT TASK ON NOTIFICATION PUPUP OPEN
   */
  onTaskHandler = (type, item) => {
    var data = {};
    data.taskStatus = type === "accept" ? "assigned" : "rejected";
    data._id = item._id;
    if (this.state.taskAssigneeData?.scheduleRules?.isRecurring === true) {
      data.recurringType = true
    }
    this.on_mark_as_read_handler(this.state.notification_id, false);
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6008 : "task",
      "application/json"
    )
      .post("/assign_task", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            taskAssignModalOpen: false,
            taskAssigneeData: null,
            anchorNotifyEl: null,
          });
          this.getNotificationItems();

          var props = {
            message: `Task ${type === "accept" ? "Accepted" : "Rejected"
              } Successfully`,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(props);
        } else if (
          (response && response.status === "400") ||
          response.status === 400
        ) {
          var props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };

  onReminderTaskHandler = (type, item, arrayData) => {
    let data = {};
    switch (type) {
      case "start":
        data.taskStatus = "inprogress";
        break;
      case "stop":
        data.taskStatus = "completed";
        break;
      default:
        data.taskStatus = "inprogress";
        break;
    }
    if (item?.recurringStatus == "AutoGenerated") {
      data.dateId = item?.dateId;
      data.timeZone = item?.timezone;
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6008
          : "task",
        "application/json"
      )
        .put(`/recurringTask/${item?._id}/${item?.taskScheduleId}`, data)
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            if (arrayData.length <= 1) {
              this.setState({
                reminderDatas: [],
                reminderModalOpen: false,
              });
            } else {
              this.setState({
                reminderDatas: arrayData,
                reminderModalOpen: false,
              });
              setTimeout(() => {
                this.setState({
                  reminderModalOpen: true,
                });
              }, 200);
              arrayData.shift();
            }

            var props = {
              message: `Task updated Successfully`,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(props);
          } else if (
            (response && response.status === "201") ||
            response.status === 201
          ) {
            if (arrayData.length <= 1) {
              this.setState({
                reminderDatas: [],
                reminderModalOpen: false,
              });
            } else {
              this.setState({
                reminderDatas: arrayData,
                reminderModalOpen: false,
              });
              setTimeout(() => {
                this.setState({
                  reminderModalOpen: true,
                });
              }, 200);
              arrayData.shift();
            }
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(props);
          } else if (
            (response && response.status === "400") ||
            response.status === 400
          ) {
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        });
    } else {
      data.type = "popup";
      data._id = item?._id;
      AxiosInstance(
        process.env.REACT_APP_NODE_ENV === "development" ||
          process.env.REACT_APP_NODE_ENV === "qa"
          ? 6008
          : "task",
        "application/json"
      )
        .post("/assign_task", data)
        .then((resp) => {
          var response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            if (arrayData.length <= 1) {
              this.setState({
                reminderDatas: [],
                reminderModalOpen: false,
              });
            } else {
              this.setState({
                reminderDatas: arrayData,
                reminderModalOpen: false,
              });
              setTimeout(() => {
                this.setState({
                  reminderModalOpen: true,
                });
              }, 200);
              arrayData.shift();
            }
            var props = {
              message: `Task updated Successfully`,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(props);
          } else if (
            (response && response.status === "201") ||
            response.status === 201
          ) {
            if (arrayData.length <= 1) {
              this.setState({
                reminderDatas: [],
                reminderModalOpen: false,
              });
            } else {
              this.setState({
                reminderDatas: arrayData,
                reminderModalOpen: false,
              });
              setTimeout(() => {
                this.setState({
                  reminderModalOpen: true,
                });
              }, 200);
              arrayData.shift();
            }
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(props);
          } else if (
            (response && response.status === "400") ||
            response.status === 400
          ) {
            var props = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(props);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        });
    }
  };
  editTaskDetailPage = (item, data) => {
    if (data.length <= 1) {
      this.setState({
        reminderDatas: [],
        reminderModalOpen: false,
      });
    } else {
      data.shift();
      this.setState({
        reminderDatas: data,
      });
    }
    if (item?.recurringStatus === "AutoGenerated") {
      this.props.history.replace(
        `/task/${item?.taskScheduleId}?dateId=${item?.dateId}&status=AutoGenerated`
      );
    } else {
      this.props.history.replace(`/task/${item?._id}`);
    }
  };
  /**
   * @author Benakesha
   * @param {task data} data
   * TO OPEN CONFLICT POPUP DIALOG
   */
  openConflictModalHandler = (data) => {
    var end_date = momentTimeZone(data.endDate).tz(this.state.timeZoneName);
    var start_date = momentTimeZone(data.startDate).tz(this.state.timeZoneName);
    var diffTime = end_date.diff(start_date, "hours");
    var diffMinutes = end_date.diff(start_date, "minutes");
    var diffSeconds = end_date.diff(start_date, "seconds");
    diffTime =
      diffTime === 0
        ? diffMinutes + " Minutes"
        : diffMinutes === 0
          ? diffSeconds + " Seconds"
          : diffTime + " Hours";

    this.setState(
      {
        taskAssignModalOpen: false,
      },
      () => {
        this.on_mark_as_read_handler(data.notificationId, false);
        this.onTaskHandler("accept", data);
        this.setState({
          taskConflictModalOpen: true,
          taskConflictModalData: data,
          diffTime1: diffTime,
        });
      }
    );
  };

  openEventConflictModalHandler = (data) => {
    var end_date = momentTimeZone(data.endDate).tz(this.state.timeZoneName);
    var start_date = momentTimeZone(data.startDate).tz(this.state.timeZoneName);
    var diffTime = end_date.diff(start_date, "hours");
    var diffMinutes = end_date.diff(start_date, "minutes");
    var diffSeconds = end_date.diff(start_date, "seconds");
    diffTime =
      diffTime === 0
        ? diffMinutes + " Minutes"
        : diffMinutes === 0
          ? diffSeconds + " Seconds"
          : diffTime + " Hours";

    this.setState(
      {
        taskAssignModalOpen: false,
      },
      () => {
        this.on_mark_as_read_handler(data.notificationId, false);
        this.onEventAcceptRejectHandler(data, "accept");
        this.setState({
          taskConflictModalOpen: true,
          taskConflictModalData: data,
          diffTime1: diffTime,
        });
      }
    );
  };

  onEventAcceptRejectHandler = (item, type) => {
    let data = {};
    data.id = item._id;
    data.type = type === "accept" ? 1 : 2;
    this.on_mark_as_read_handler(this.state.notification_id, false);
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6013 : "event",
      "application/json"
    )
      .post("/eventAcceptReject", data)
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            taskAssignModalOpen: false,
            taskAssigneeData: null,
            anchorNotifyEl: null,
          });
          this.getNotificationItems();
          let props = {
            message: `Event ${type === "accept" ? "Accepted" : "Rejected"
              } Successfully`,
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification success-notify",
          };
          NotificationComponent(props);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };

  /**
   * TO CLOSE TASK CONFLICT MODAL
   */
  openConflictModalClose = () => {
    this.setState({
      taskConflictModalOpen: false,
    });
  };
  /**
   * TO SKIP THE TASK FROM TASK CONFLICT MODAL
   */
  onSkipTaskHandler = () => {
    this.setState({
      taskConflictModalOpen: false,
    });
  };
  /**
   *
   * @param {task id} refId
   * TO NAVIGATE TO TASK DETAIL ON CLICK OF RESCHEDULE/REASSIGNE BUTTON
   * FROM CONFLICT MODAL
   */
  onRescheduleTaskHandler = (item) => {
    if (item.type === "0" || item.type === 0) {
      if (
        window.location.pathname.indexOf("/task") === 0 ||
        window.location.pathname.indexOf("/task") === -1
      ) {
        this.props.history.replace(`/task/${item.conflictId ? item.conflictId : item._id}`);
      }
    } else if (item.type === "1" || item.type === 1) {
      if (item.conflictType === 1 || item.conflictType === "1") {
        if (
          window.location.pathname.indexOf("/eventdetail") === 0 ||
          window.location.pathname.indexOf("/eventdetail") === -1
        ) {
          this.props.history.replace(`/eventdetail/${item.conflictId ? item.conflictId : item._id}`);
        }
      } else if (item.conflictType === 0 || item.conflictType === "0") {
        if (
          window.location.pathname.indexOf("/task") === 0 ||
          window.location.pathname.indexOf("/task") === -1
        ) {
          this.props.history.replace(`/eventdetail/${item.conflictId ? item.conflictId : item._id}`);
        }
      }
    }
    this.setState({
      taskConflictModalOpen: false,
    });
  };

  handlePopConfirmClose = () => {
    this.setState({
      showdelete: false,
    });
  };

  onPopConfirm = () => {
    this.setState({
      disable: true,
    });
    if (this.state.lengths === "all") {
      this.update_all_notification("clear");
    } else if (this.state.lengths === "single") {
      this.update_notification("clear", this.state.singleClearId);
    }
  };

  onBillingStatusConfirm = (status) => {
    if (
      window.location.pathname.indexOf("/subscription") === 0 ||
      window.location.pathname.indexOf("/subscription") === -1
    ) {
      this.props.history.replace(`/subscription`);
    }
  };
  reminderModalClose = (data) => {
    if (data.length <= 1) {
      this.setState({
        reminderDatas: [],
        reminderModalOpen: false
      })
    } else {
      this.setState({
        reminderDatas: data,
        reminderModalOpen: false
      })
      setTimeout(() => {
        this.setState({
          reminderModalOpen: true
        })
      }, 200)
      data.shift()
    }
  }

  render() {
    const { userData, profilePath } = this.state;
    const rofilePath = this.props.profile;
    let profileImg = "";
    if (rofilePath !== undefined && rofilePath != "") {
      profileImg = ProfileURL + rofilePath;
    } else if (profilePath !== undefined && profilePath != "") {
      profileImg = ProfileURL + profilePath;
    } else {
      profileImg = userIcon;
    }
    var options = [];

    if (userData && userData.role.toLowerCase() === "admin") {
      options.push(
        { name: `${this.state.cmsLabelChange.Service ? this.state.cmsLabelChange.Service : "Service"}`, _id: "services" },
        { name: "Reports", _id: "reports" },
        { name: "Organization Structure", _id: "org-structure" }
      );
      if (this.state.showBilling) {
        options.push({ name: "Billing", _id: "billing" });
      }
    } else if (
      (userData && userData.role.toLowerCase() === "sales rep") ||
      (userData && userData.role.toLowerCase() === "area manager") ||
      (userData && userData.role.toLowerCase() === "team lead")
    ) {
      options.push(
        { name: `${this.state.cmsLabelChange.Service ? this.state.cmsLabelChange.Service : "Service"}`, _id: "services" },
        { name: "Reports", _id: "reports" }
      );
      if (this.state.showBilling) {
        options.push({ name: "Billing", _id: "billing" });
      }
    }
    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleMenuClose}
        className="header-profile"
      >
        <img src={profileImg} className="profile-pic"></img>
        <Typography className="user-name">{userData.username}</Typography>
        <Typography className="user-email">{userData.email}</Typography>
        <NavLink to="/profile" onClick={this.handleMenuClose}>
          <img src={settingsIcon} className="accountimg"></img>
          <Typography variant="inherit">Manage Settings</Typography>
        </NavLink>
        <Divider component="li" className="hr-line" />
        {localStorage.getItem("switchedToSuperAdmin") ||
          localStorage.getItem("switchedToSuperAdminFromUser") ? (
          <Button
            className="logout-button"
            variant="contained"
            onClick={this.switchBackToSuperAdminHandler}
          >
            Switch to Super Admin
          </Button>
        ) : (
          <Button
            className="logout-button primary-btn"
            variant="contained"
            onClick={this.logoutHandler}
          >
            Logout
          </Button>
        )}
      </Menu>
    );

    /**
     * @author Benakesh
     * DOM for notification dialog
     */
    const renderNotificationMenu = (
      <Menu
        onExit={this.clearItems}
        anchorEl={this.state.anchorNotifyEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="primary-search-account-menu-notify"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(this.state.anchorNotifyEl)}
        onClose={this.handleNotificationMenuClose}
        className="main_notification"
      >
        {this.state.notifyItems.length > 0 ? (
          <InfiniteScroll
            height={300}
            dataLength={this.state.notifyItems.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore ? true : false}
            initialScrollY={0}
            className="infinite_scroll"
            loader={
              <Loader
                style={{ textAlign: "center" }}
                type="TailSpin"
                color="#00BFFF"
                height={45}
                width={45}
                visible={true}
              />
            }
          >
            {this.state.notifyItems.length > 0 ? (
              <div className="notification_main_div">
                <div className="notification_header">
                  <div>
                    <span className="notification_header_name">
                      Notifications{" "}
                    </span>
                    <span className="notification_header_count">
                      {this.state.notifyMarkCount}
                    </span>
                  </div>

                  {this.state.notifyMarkCount > 0 ? (
                    <span
                      className="mark_all"
                      onClick={this.mark_all_as_read_handler}
                    >
                      Mark all as read
                    </span>
                  ) : (
                    <span className="mark_all" onClick={this.clear_all_handler}>
                      Clear all
                    </span>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.notifyItems.map((item) => (
              <div
                key={item._id}
                className={`notification_card_div 
                  ${this.state.onHoverItem === item._id
                    ? "notification_mouse_hover"
                    : ""
                  }
                   ${item.read ? "" : "read_card"}`}
                onMouseOver={() => this.onMouseOverHandler(item._id)}
              >
                <div className="notification_title">
                  <div
                    className="notification_title_text"
                    onClick={() => this.onOpenTaskAssigneeModalHandler(item)}
                  >
                    {item.title}
                  </div>
                  <div className="notification_title_btns">
                    {this.state.onHoverItem === item._id ? (
                      <Tooltip title="Clear notification">
                        <IconButton
                          onClick={() =>
                            this.on_clear_notification_handler(item._id)
                          }
                          className="notification_clear"
                          aria-label="show 17 new notifications"
                          color="inherit"
                          size="small"
                        >
                          <img src={closeBtn} width="13" height="13"></img>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    {this.state.onHoverItem === item._id ? (
                      <Tooltip
                        title={item.read ? "Mark as unread" : "Mark as read"}
                      >
                        <GreenCheckbox
                          checked={
                            this.state.readId === item._id || item.read
                              ? true
                              : false
                          }
                          size="small"
                          className="notification_read_check"
                          onChange={() =>
                            this.on_mark_as_read_handler(item._id, item.notification_id, item.read)
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="notification_description">
                  {item.description}
                </div>
                {/* <div className="notification_hrs">  */}
                {/* {this.timeSince(item.createdAt)} agos */}
                {/* {moment(item.createdAt,"YYYY-MM-DD HH:mm:ss").fromNow()} */}
                {/* {moment(item.createdAt,"mm/dd/yyyy HH:mm:ss").utc().fromNow()} agoose */}
                {/* <TimeAgo
                        datetime={item.createdAt}
                        locale='vi'
                      /> */}
                {/* </div> */}
                {/* <div className="notification_hrs"><ReactTimeAgo date={new Date(item.createdAt)} /></div> */}
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div
            style={{ padding: "15px", marginTop: "30px", textAlign: "center" }}
          >
            <Loader
              type="TailSpin"
              color="#00BFFF"
              height={45}
              width={45}
              visible={this.state.notifyLoader}
            />
            <div>No notifications right now</div>
            <span>
              When your team starts working on things, we’ll show the
              notifications here.
            </span>
          </div>
        )}
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        keepMounted
        id="primary-search-account-menu-mobile"
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(this.state.mobileMoreAnchorEl)}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
              <Mail />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsNoneOutlined />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    const renderMoreMenu = (
      <Menu
        id="more-menu"
        keepMounted
        anchorEl={this.state.menuMoreAnchorEl}
        open={Boolean(this.state.menuMoreAnchorEl)}
        onClose={this.menuMoreClose}
      >
        <MenuItem onClick={this.menuMoreClose}>Services</MenuItem>
        <MenuItem onClick={this.menuMoreClose}>Tasks</MenuItem>
      </Menu>
    );

    const restrictAccessModal = (
      <Dialog open={this.state.restrictAccessModal}>
        <DialogContent>
          {this.state.billStatus === 1 ? (
            <p>
              You are not subscribed to SalesC2. Please enable subscription to continue accessing the modules.
            </p>
          ) : this.state.billStatus === 2 ? (
            <p>Your payment for subscription is failed. Kindly add another card and retry payment. </p>
          ) : this.state.billStatus === 3 ? (
            <p>Payment is under process...</p>
          ) : (
            ""
          )}
          {this.state.billStatus === 1 || this.state.billStatus === 2 ? (
            <>
              <div className="add_company_action subscribe_btns">
                <Button
                  className="ok_btn green-btn"
                  type="button"
                  variant="contained"
                  onClick={() =>
                    this.props.history.replace("/billing")
                  }
                >
                  View Billing Page
                </Button>
              </div>
            </>
          ) : this.state.billStatus === 3 ? (
            <div className="add_company_action subscribe_btn">
              <Button
                className="ok_btn green-btn"
                type="button"
                variant="contained"
                onClick={() =>
                  this.props.history.replace("/billing")
                }
              >
                View Billing Page
              </Button>
            </div>
          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    );
    return (
      <div id="header-navbar" className="grow">
        <Suspense fallback={<div></div>}>
          <PopConfirmComponent
            disable={this.state.disable}
            showdelete={this.state.showdelete}
            title={this.state.popConfirmTitle}
            action={this.state.action}
            actionName={this.state.actionName}
            onPopConfirm={this.onPopConfirm}
            handlePopConfirmClose={this.handlePopConfirmClose}
          />
        </Suspense>
        <MobileHeaderModal
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
        />
        <AddTaskModal
          open={this.state.openTask}
          onClose={this.handleCloseTaskmodal}
        />
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className="header-logo"
              color="inherit"
              aria-label="open drawer"
            >
              <img src={logo} alt="Sales-Icon" width="35px" height="auto"></img>
            </IconButton>
            <NavLink to="/dashboard" className="navbars">
              Dashboard
            </NavLink>
            {userData && userData.role.toLowerCase() === "super admin" ? (
              <NavLink to="/company?page=1" className="navbars" onClick={(e)=>this.handleRedirect(e,"/company")}>
                Company
              </NavLink>
            ) : (
              ""
            )}
            {userData.role.toLowerCase() === "super admin" ||
              userData.role.toLowerCase() === "admin" ? (
              <NavLink to="/user?page=1" className="navbars" onClick={(e)=>this.handleRedirect(e,"/user")}>
                Users
              </NavLink>
            ) : (
              <NavLink to="/user?page=1" className="navbars" onClick={(e)=>this.handleRedirect(e,"/user")}>
                Team
              </NavLink>
            )}
            <Tooltip title={this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Accounts"} placement="top">
            <NavLink to="/account?page=1" className="navbars account-product-header" onClick={(e)=>this.handleRedirect(e,"/account")}>
              {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Accounts"}
            </NavLink>
            </Tooltip>
            {userData && userData.role.toLowerCase() === "super admin" ? (
              ""
            ) : (
              <React.Fragment>
                <Tooltip title=  {this.state.cmsLabelChange.Product ? this.state.cmsLabelChange.Product : "Products"} placement="top">
                <NavLink to="/products?page=1" className="navbars account-product-header" onClick={(e)=>this.handleRedirect(e,"/products")}>
                  {this.state.cmsLabelChange.Product ? this.state.cmsLabelChange.Product : "Products"}
                </NavLink>
                </Tooltip>
              </React.Fragment>
            )}
            {userData && userData.role.toLowerCase() === "super admin" ? (
              ""
            ) : (
              <React.Fragment>
                <NavLink to="/task?page=1" className="navbars" onClick={(e)=>this.handleRedirect(e,"/task")}>
                  Tasks
                </NavLink>
              </React.Fragment>
            )}
            {userData &&
              (userData.role.toLowerCase() === "super admin" ||
                userData.role.toLowerCase() === "admin") ? (
              ""
            ) : (
              <React.Fragment>
                <NavLink to="/map" className="navbars">
                  Map
                </NavLink>
              </React.Fragment>
            )}
            {userData && userData.role.toLowerCase() === "super admin" ? (
              ""
            ) : (
              <React.Fragment>
                <NavLink to="/calendar" className="navbars">
                  Calendar
                </NavLink>
              </React.Fragment>
            )}
            {userData && userData.role.toLowerCase() === "super admin" ? (
              <NavLink to="/reports" className="navbars">
                Reports
              </NavLink>
            ) : (
              ""
            )}
            {userData && userData.role.toLowerCase() === "super admin" ? (
              <NavLink to="/billing" className="navbars">
                Subscription
              </NavLink>
            ) : userData &&
              (userData.role.toLowerCase() === "area manager" ||
                userData.role.toLowerCase() === "division manager") &&
              this.state.showBilling ? (
              <NavLink to="/billing" className="navbars">
                Billing
              </NavLink>
            ) : (
              ""
            )}
            {userData.role.toLowerCase() === "admin" ||
              userData.role.toLowerCase() === "sales rep" ||
              userData.role.toLowerCase() === "area manager" ||
              userData.role.toLowerCase() === "team lead" ? (
              <Suspense fallback={<div></div>}>
                <div
                  className="new_custom_filter_div navbars more-option"
                  onClick={this.onMoreOptionFilterOpen}
                >
                  <label
                    aria-label="summary_date_filter"
                    aria-controls="moreoption_filter_control"
                    area-aria-haspopup="true"
                    className="rightName"
                  >
                    {this.state.moreOptionFilter
                      ? upperFirst(this.state.moreOptionFilter)
                      : `More`}
                  </label>
                  <img className="carret" src={carret_down} alt="carret" />
                </div>
                <CustomSelectComponent
                  isAllRequired={true}
                  filterArray={options}
                  closeCustomPopup={this.onMoreOptionFilterClose}
                  config={this.state.more_option.id}
                  anchorEl={this.state.more_option.anchorElmoreOption}
                  handleClick={this.onMoreOptionFilter}
                />
              </Suspense>
            ) : (
              ""
            )}
            <div className="grow" />
            <div className="sectionDesktop">
              {userData && userData.role.toLowerCase() === "super admin" ? (
                ""
              ) : (
                <Button
                  className="add_task_btn green-btn"
                  variant="contained"
                  color="primary"
                  // disabled={this.state.billStatus !== 0 ? true : false}
                  onClick={this.handleClickOpenTaskmodal}
                >
                  Task
                </Button>
              )}

              <IconButton
                className="add_task_btn_mobile green-btn"
                onClick={this.handleClickOpenTaskmodal}
              >
                +
              </IconButton>
              <IconButton
                aria-haspopup="true"
                aria-label="show 17 new notifications"
                aria-controls="primary-search-account-menu-notify"
                color="inherit"
                onClick={this.handleNotificationMenuOpen}
                disabled={this.state.billStatus !== 0 ? true : false}
              >
                <Badge
                  badgeContent={this.state.notifyMarkCount}
                  color="secondary"
                >
                  <img src={notificationIcon}></img>
                </Badge>
              </IconButton>
              <IconButton
                className="mobile-menu-icon"
                onClick={this.handleClickOpen}
              >
                <img src={mobileMenuIcon}></img>
              </IconButton>
              <IconButton
                edge="start"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                className="user_icon user_profile"
              >
                <img src={profileImg} className="profile-pic"></img>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        {renderMoreMenu}
        {renderNotificationMenu}
        {restrictAccessModal}
        <TaskAssignModal
          taskAssignModalOpen={this.state.taskAssignModalOpen}
          taskAssigneeData={this.state.taskAssigneeData}
          timeZoneName={this.state.timeZoneName}
          diffTime={this.state.diffTime}
          taskAssignModalClose={this.taskAssignModalClose}
          onTaskHandler={this.onTaskHandler}
          openConflictModalHandler={this.openConflictModalHandler}
          onEventHandler={this.onEventAcceptRejectHandler}
          openEventConflictModalHandler={this.openEventConflictModalHandler}
        />
        <TaskConflictModal
          timeZoneName={this.state.timeZoneName}
          diffTime={this.state.diffTime1}
          taskConflictModalData={this.state.taskConflictModalData}
          taskConflictModalOpen={this.state.taskConflictModalOpen}
          openConflictModalClose={this.openConflictModalClose}
          onSkipTaskHandler={this.onSkipTaskHandler}
          onRescheduleTaskHandler={this.onRescheduleTaskHandler}
        />
        <ReminderModal
          open={this.state.reminderModalOpen}
          handleClose={this.reminderModalClose}
          reminderHandler={this.onReminderTaskHandler}
          editHandler={this.editTaskDetailPage}
          data={this.state.reminderDatas} />
      </div>
    );
  }
}

export default withRouter(HeaderComponent);
