import React, { Component,Suspense } from "react";
import { AxiosInstance } from "../../Utilities";
import {
  Breadcrumbs, Typography, Button, Menu, TextField, Select,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import editIcon from "../../Assets/images/view_edit_icon.svg";
import Delete from "../../Assets/images/delete.svg";
import "./ProductCategories.css";
import { Autocomplete } from "@material-ui/lab";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import validator from "validator";
import Loader from "react-loader-spinner";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import backIcon from "../../Assets/images/breadcrumb_back.svg";
import { Alert } from "@material-ui/lab";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { TransitionGrow } from '../../SharedComponents/CommonUtilities/CommonUtilities';
import { isSafari } from "react-device-detect";
// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
var EditProductCategoryModal = React.lazy(() =>
  import("./EditProductCategoryModal/EditProductCategoryModal")
);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
class ProductCategories extends Component {
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    let accDta = window.location.href;
    const [baseUrl, divId] = accDta.split("/product-details/");
    this.state = {
      divisionId: divId,
      anchorEl: null,
      userRole: userData.role,
      products: [],
      isDivision: true,
      divisions: [],
      divName: "",
      companySize: userData.companySize,
      divisionOption: [],
      divId: "",
      productName: "",
      productId: "",
      catName: "",
      editName: "",
      editId: "",
      hideId: "",
      divisionId: "",
      loading: true,
      disable: false,
      isSubmit: false,
      deleteId: "",
      alertType: "",
      responseMsg: "",
      showEditModal: false,
      formError: {
        editName: "",
        deptName: "",
      },
      tooltipprops:{}
    };
  }
  /**
   * @author Akshatha
   * To get list of divisions
   */
  UNSAFE_componentWillMount(){
    let obj = {}
    if(isSafari){
      obj.open = false;
      this.setState({tooltipprops:obj})
    }
  }
  componentDidMount = () => {
    let data = {
      divisionId: this.state.divisionId,
    };
    this.setState({ loading: true, isDivision: true }, () => {
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/x-www-form-urlencoded; charset=UTF-8")
        .get("/getDivisionList", data)
        .then((resp) => {
          let response = resp.data;
          if (
            response &&
            (resp.data.status === "200" || resp.data.status === 200)
          ) {
            this.setState(
              { divisions: resp.data.data, divId: resp.data.data[0]._id },
              () => {
                this.onDivisionClick(
                  this.state.divisions[0]._id,
                  this.state.divisions[0].divisionName,
                  this.state.divisions[0].catName
                );
              }
            );
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    });
  };
  setMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
      catName: "",
    });
  };
  closeMenu = (e, id, name) => {
    this.setState({
      editId: id,
      editName: name,
      showEditModal: true,
      formError: {
        editName: "",
      },
    });
  };
  /**
   * @author Akshatha
   * For autoclosing of Menu
   */
  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      formError: {
        deptName: "",
      },
    });
  };
  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    const { formError } = this.state;
    if (!validator.isLength(value, { max: 100 })) {
      formError.deptName = "* Category Name should not exceed 100 characters.";
    } else {
      formError.deptName = "";
    }
    this.setState({
      [name]: value,
      formError,
    });
  };
  handleAddChange = (event) => {
    let value = event.target.value;
    const { formError } = this.state;
    if (!validator.isLength(value, { max: 100 })) {
      formError.editName = "* Category Name should not exceed 100 characters.";
    } else {
      formError.editName = "";
    }
    this.setState({
      editName: value,
      formError,
    });
  };
  /**
   * @author Akshatha
   * To add the products to particular divisions
   */
  addProdCat = () => {
    const { formError } = this.state;
    let data = "";
    if (this.state.companySize === "large") {
      data = {
        divisionId: this.state.divisionId,
        name: this.state.catName,
      };
    } else {
      data = {
        name: this.state.catName,
      };
    }
    if (formError.deptName === "") {
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
        .post("/add_productCategory", data)
        .then((resp) => {
          let response = resp.data.data;
          if (
            response &&
            (resp.data.status === "200" || resp.data.status === 200)
          ) {
            this.setState({
              anchorEl: null,
              products: response,
            });
            var props = {
              message: "Product category added successfully.",
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification success-notify level-error",
            };
            NotificationComponent(props);
            // this.componentDidMount();
          } else {
            var propsmsg = {
              message: resp.data.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 4,
              top: 200,
              className: "task_notification error-notify level-error",
            };
            NotificationComponent(propsmsg);
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };
  /**
   * @author Akshatha
   * @param { id,name }
   * To get products list of the division
   */
  onDivisionClick = (id, name) => {
    let data = {
      divisionId: id,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/get_productCategory", data)
      .then((resp) => {
        let response = resp.data.data;
        if (
          response &&
          (resp.data.status === "200" || resp.data.status === 200)
        ) {
          this.setState({
            products: response,
            divName: name,
            divId: id,
            loading: false,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Akshatha
   * @param { event, id, name }
   * To update the product category
   */
 
  editProdCat = ( name,id) => {
    let data = {
      id: id,
      name: this.state.editName,
      divisionId: this.state.divId,
    };
    if(this.state.formError.editName === ""){
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/update_productCategory", data)
      .then((resp) => {
        let response = resp.data.data;
        if (
          response &&
          (resp.data.status === "200" || resp.data.status === 200)
        ) {
          this.handleClose();
          this.setState({
            showEditModal: false,
          });
          var props = {
            message: "Category updated successfully.",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 4,
            top: 200,
            className: "task_notification success-notify level-error",
          };
          NotificationComponent(props);
          this.onDivisionClick(this.state.divId,this.state.divName);
        } else {
          this.setState({
            responseMsg: resp.data.msg,
            alertType: "error",
            isSubmit: false,
          });

          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
    }
  };
  /**
   * @author Akshatha
   * @param { event, id }
   * To delete the product category
   */
  handleClose = () => {
    this.setState({
      responseMsg: "",
      showdelete: false,
      disable: false,
      showEditModal: false,
    });
  };
  onDeleteHandleClick = (id) => {
    this.setState({ showdelete: true, deleteId: id });
  };
  delProdCat = (e, id) => {
    var data = {
      id: id,
      divisionId: this.state.divId,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/delete_productCategory", data)
      .then((resp) => {
        let response = resp.data.data;
        if (
          response &&
          (resp.data.status === "200" || resp.data.status === 200)
        ) {
          this.setState({
            showdelete: false,
            disable: false,
          });
          var props = {
            message: "Product category deleted successfully.",
            icon: <img src={iconNotificationSuccess} alt="success" />,
            type: "success",
            placement: "topRight",
            duration: 4,
            top: 200,
            className: "task_notification success-notify level-error",
          };
          NotificationComponent(props);
          this.componentDidMount();
        } else {
          this.setState({
            responseMsg: resp.data.msg,
            alertType: "error",
            isSubmit: false,
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Akshatha
   * @param { event }
   * Autocomplete division search filter
   */
  DivisionFilter = async (e) => {
    var name = e.target.value;
    var data = {
      divisionName: name,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6009:'level', "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/service_division_search", data)
      .then((resp) => {
        let response = resp.data.data;
        if (
          response &&
          (resp.data.status === "200" || resp.data.status === 200)
        ) {
          this.setState({
            divisionOption: resp.data.data,
          });
        } else {
          this.setState({
            divisionOption: [
              {
                id: "",
                name: "",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   * @author Akshatha
   * @param { event }
   * Division select handler
   */
  onDivisionSelectHandler = (e) => {
    if (!e.target.value) {
      this.DivisionFilter(e);
    } else {
      var name = e.target.value;

      var { divisionOption } = this.state;
      var data = divisionOption.filter((item) => {
        return item.name === name;
      });
      data.map((devisionData) => {
        this.setState({
          divisionId: devisionData._id,
          divisionName: devisionData.name,
        });
      });
    }
  };
  DivisionAutoClose = (e) => {
    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ divisionId: "" });
    }
  };
  hideButton = (id) => {
    this.setState({
      hideId: id
    })
  }
  onKeyDown = (event,id,name) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.editProdCat(id,name);
    }
  }
  render() {
    const { divisions } = this.state;
    return (
      <>
        <Dialog open={this.state.showdelete} className="deleteServiceDialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
          {this.state.responseMsg !== "" ? (
            <Alert severity={this.state.alertType}>
              {this.state.responseMsg}
            </Alert>
          ) : null}

          <DialogTitle>
            {" "}
            <center>Delete Product category</center>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <center>
                {" "}
                Are you sure you want to delete this Product Category? Your
                action cannot be undone
              </center>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="cancel"
              onClick={this.handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.disable}
              className="delete"
              onClick={(e) =>
                this.delProdCat(e, this.state.deleteId, this.state.divisionId)
              }
              style={{ color: "red" }}
              autoFocus
            >
              {this.state.disable ? <CircularProgress size={20} /> : ""} Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Suspense fallback={<div></div>}>
          <EditProductCategoryModal
            open={this.state.showEditModal}
            handleClose={this.handleClose}
            editProdCat={this.editProdCat}
            onKeyDown={this.onKeyDown}
            handleAddChange={this.handleAddChange}
            modaldata={this.state}
          />
        </Suspense>

        <div className="body-bg-color">
          {/* <Suspense fallback={<div></div>}> */}
            <HeaderComponent />
          {/* </Suspense> */}
          <div className="select_parent">
            <div className="breadCrumbs">
              <img className="bread-crumb-back" src={backIcon} alt="back" />
              <Breadcrumbs className="bread-crumb-main" aria-label="breadcrumb">
                <NavLink
                  className="bread-crumb-link"
                  color="inherit"
                  to="/Products"
                >
                  Products
                  </NavLink>
                 <Typography className="bread-crumb-name">
                  Product Categories
                  </Typography>
              </Breadcrumbs>
            </div>
            <div className="account-detail-header">
              <Typography variant="h5" className="tableTitle" component="div">Product Categories</Typography>
              <Button variant="contained" className="tableaddBtn grey-btn" color="default" style={{ boxShadow: 'none' }} onClick={this.setMenu}>Add Category</Button>
              <Menu
                anchorEl={this.state.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                id="primary-search-account-menu"
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
                className="header-profile"
              >
                {this.state.companySize === "large" ? (
                  <>
                    <div className="add_btn_labels">
                      Division<span className="labelAsterisk">*</span>
                    </div>
                    <Autocomplete
                      style={{ marginRight: "25px", width: "100%" }}
                      options={this.state.divisionOption}
                      onInputChange={this.DivisionAutoClose}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          name="divisionFilter"
                          placeholder="Division"
                          onChange={this.DivisionFilter}
                          onSelect={this.onDivisionSelectHandler}
                        />
                      )}
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="add_btn_labels">
                  Category<span className="labelAsterisk">*</span>
                </div>
                <CssTextField
                  name="catName"
                  className="txt_field"
                  placeholder="Enter category name"
                  value={this.state.catName}
                  onChange={this.handleChange}
                />
                <div>
                  {this.state.formError.deptName !== "" ? (
                    <span className="modal-err-msg">
                      {this.state.formError.deptName}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {this.state.companySize === "large" ? (
                    <Button
                      disabled={
                        this.state.catName !== "" &&
                        this.state.divisionId !== ""
                          ? false
                          : true
                      }
                      className="add_btn primary-btn"
                      variant="contained"
                      onClick={this.addProdCat}
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      disabled={this.state.catName !== "" ? false : true}
                      className="add_btn primary-btn"
                      variant="contained"
                      onClick={this.addProdCat}
                    >
                      Add
                    </Button>
                  )}
                </div>
              </Menu>
            </div>
          </div>
          <div className="prod_category_main_div">
            <Grid container spacing={2}>
              {this.state.companySize === "large" ? (
                <Grid item xs={12} md={3}>
                  {divisions.length !== 0
                    ? divisions.map((divisions, index) => (
                        <>
                          <div className="prod_list">
                            <div
                              className={
                                this.state.divId !== undefined &&
                                this.state.divId === divisions._id
                                  ? "prod_list_text_main active_level"
                                  : "prod_list_text_main"
                              }
                              onClick={() =>
                                this.onDivisionClick(
                                  divisions._id,
                                  divisions.divisionName
                                )
                              }
                            >
                              <div className="division-list">
                                {divisions.divisionName !== ""
                                  ? divisions.divisionName
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    : ""}
                </Grid>
              ) : (
                ""
              )}
              <Grid
                item
                xs={12}
                md={this.state.companySize === "large" ? 9 : 12}
              >
                <div className="divison_main">
                  {this.state.companySize === "large" ? (
                    <div className="division_head">{this.state.divName}</div>
                  ) : (
                    ""
                  )}
                  <div className="prod_cat_division_one">
                    <Grid container>
                      {this.state.loading ? (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={50}
                            width={50}
                            visible={this.state.loading}
                          />
                        </div>
                      ) : this.state.products.length !== 0 ? (
                        this.state.products.map((row) => (
                          <>
                            <Grid item xs={12} md={4}>
                              <div
                                className="prod_cat_list_one"
                                onMouseEnter={() => this.hideButton(row._id)}
                              >
                                <span className="prod_list_one">
                                  <LightTooltip
                                    title={row.name}
                                    {...this.state.tooltipprops}
                                    placement="bottom-start"
                                  >
                                    <a href={"category:" + row.name} rel="noopener noreferrer">
                                      {row.name}
                                    </a>
                                  </LightTooltip>
                                </span>
                                {this.state.hideId === row._id ? (
                                  <div>
                                    {" "}
                                    <img
                                      className="prod_edit_icon"
                                      src={editIcon}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        this.closeMenu(e, row._id, row.name)
                                      }
                                    ></img>
                                    <img
                                      className="prod_del_icon"
                                      style={{ cursor: "pointer" }}
                                      src={Delete}
                                      alt="add"
                                      onClick={(e) =>
                                        this.onDeleteHandleClick(row._id)
                                      }
                                    ></img>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Grid>
                          </>
                        ))
                      ) : (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          No data found
                        </div>
                      )}
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default ProductCategories;
