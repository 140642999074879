import React from 'react';
import {
  Box,
  Typography,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  withStyles
} from "@material-ui/core";
import { upperFirst } from "lodash";
import Loader from "react-loader-spinner";
import carret_down from "../../../Assets/images/carret_down.svg";
import searchIcon from "../../../Assets/images/search_icon.svg";
import momentTimeZone from "moment-timezone";
import CustomPaginationComponent from "../../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import CustomSelectComponent from "../../../SharedComponents/CustomSelectComponent/CustomSelectComponent";
import viewAction from "../../../Assets/images/view_action.svg";
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
const BillingSubscriptionComponent = (props) => {
  return (
    <div className="billing_page subscriptions_page">
      <Box component="span" className="company-comp">
        <div className="header-add-btn">
          <Typography variant="h5" className="tableTitle" component="div">
            Subscriptions
            </Typography>
        </div>
        <div style={{ maxWidth: "100%" }} className="Comp-Table Acc-Table">
          <div className="filter-search subscriptions_custom_filter">
            <div className="new_custom_filters">
              <div
                className="new_custom_filter_div"
                onClick={props.openStatusFilterMenu}
              >
                {props.statusFilterName !== "" ? (
                  ""
                ) : (
                    <label className="leftName">Status: </label>
                  )}
                <label
                  aria-label="status_filter"
                  aria-controls="status_filter_select"
                  area-aria-haspopup="true"
                  className="rightName"
                >
                  {props.statusFilterName === ""
                    ? `All`
                    : upperFirst(props.statusFilterName)}
                </label>
                <img className="carret" src={carret_down} alt="carret" />
              </div>
              <CustomSelectComponent
                filterArray={[
                  { _id: 0, name: "Trial" },
                  { _id: 1, name: "Active" },
                  { _id: 2, name: "Inactive" },
                ]}
                config={props.statusConfig.config}
                anchorEl={props.statusConfig.anchorElStatus}
                handleClick={props.handleStatusSelect}
                closeCustomPopup={props.closeStatusFilterMenu}
              />
              <div
                className="new_custom_filter_div"
                onClick={props.openTypeFilterMenu}
              >
                {props.typeFilterName !== "" ? (
                  ""
                ) : (
                    <label className="leftName">Type: </label>
                  )}
                <label
                  aria-label="status_filter"
                  aria-controls="status_filter_select"
                  area-aria-haspopup="true"
                  className="rightName"
                >
                  {props.typeFilterName === ""
                    ? `All`
                    : upperFirst(props.typeFilterName)}
                </label>
                <img className="carret" src={carret_down} alt="carret" />
              </div>
              <CustomSelectComponent
                filterArray={[
                  { _id: 1, name: "Organization" },
                  { _id: 2, name: "Team" },
                  { _id: 3, name: "Individual" },
                ]}
                config={props.typeConfig.config}
                anchorEl={props.typeConfig.anchorElStatus}
                handleClick={props.handleTypeSelect}
                closeCustomPopup={props.closeTypeFilterMenu}
              />

            </div>
            <CssTextField
              id="outlined-basic"
              name="searchFilter"
              variant="outlined"
              type="text"
              value={props.searchFilter}
              placeholder="Search Here"
              onChange={props.companyFilter}
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon} alt="search-icon" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "1%" }}>#</TableCell>
                    <TableCell style={{ width: "19%" }}>COMPANY</TableCell>
                    <TableCell style={{ width: "14%" }}>TYPE</TableCell>
                    <TableCell style={{ width: "4%" }}>STATUS</TableCell>
                    <TableCell style={{ width: "9%" }}>TEAM</TableCell>
                    <TableCell style={{ width: "12%" }}>PRICE ( $ )</TableCell>
                    <TableCell style={{ width: "19%" }}>BILLING START DATE</TableCell>
                    <TableCell style={{ width: "17%" }}>BILLING END DATE</TableCell>
                    <TableCell style={{ width: "4%" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {props.loading ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={props.loading}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ) : !props.pageOfItems ||
                    props.pageOfItems.length === 0 ? (
                        <StyledTableRow>
                          <TableCell colSpan={9} style={{ textAlign: "center" }}>
                            No data Found
                        </TableCell>
                        </StyledTableRow>
                      ) : (
                        (props.rowsPerPage > 0
                          ? props.pageOfItems.slice()
                          : props.pageOfItems
                        ).map((row, index) => (
                          <StyledTableRow className="tablecell_container" key={row._id}>
                            <TableCell onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}>
                              {(props.pager.currentPage - 1) *
                                props.rowsPerPage +
                                (index + 1)}
                            </TableCell>
                            <TableCell onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip title={row.companyName ? upperFirst(row.companyName) : "- -"} {...props.tooltipprops} arrow>
                                <span> {row.companyName ? upperFirst(row.companyName) : "- -"}</span>
                              </LightTooltip>
                            </TableCell>
                            <TableCell onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip title={row.type === 1 ? "Organizational" : row.type === 2 ? "Team" : row.type === 3 ? "Individual" : "--"} {...props.tooltipprops} arrow>
                                <span> {row.type === 1 ? "Organizational" : row.type === 2 ? "Team" : row.type === 3 ? "Individual" : "--"}</span>
                              </LightTooltip>

                            </TableCell>
                            <TableCell onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip title={row.status === 0 ? "Trial" : row.status === 1 ? "Active" : row.status === 2 ? "Inactive" : "--"} {...props.tooltipprops} arrow>
                                <span> {row.status === 0 ? "Trial" : row.status === 1 ? "Active" : row.status === 2 ? "Inactive" : "--"}</span>
                              </LightTooltip>
                            </TableCell>
                            <TableCell onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip title={row.team ? upperFirst(row.team) :"--"} {...props.tooltipprops} arrow>
                                <span>{row.team ? upperFirst(row.team) :"--"}</span>
                              </LightTooltip>
                            </TableCell  >
                            <TableCell onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip title={row.price ? row.price.toFixed(2) :"--"} {...props.tooltipprops} arrow>
                                <span>{row.price ? row.price.toFixed(2) :"--"}</span>
                              </LightTooltip>
                            </TableCell>

                            <TableCell onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}>
                            <LightTooltip {...props.tooltipprops} title={momentTimeZone(row.startDate)
                                .tz(props.timeZoneName)
                                .format("MM-DD-YYYY hh:mm A")} arrow>
                                <span>{momentTimeZone(row.startDate)
                                .tz(props.timeZoneName)
                                .format("MM-DD-YYYY")}</span>
                              </LightTooltip>
                            </TableCell>
                            <TableCell onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}>
                            <LightTooltip {...props.tooltipprops} title={momentTimeZone(row.endDate)
                                .tz(props.timeZoneName)
                                .format("MM-DD-YYYY hh:mm A")} arrow>
                                <span>{momentTimeZone(row.endDate)
                                .tz(props.timeZoneName)
                                .format("MM-DD-YYYY")}</span>
                              </LightTooltip>
                            </TableCell>
                            <TableCell>
                          <LightTooltip title="Subscriptions Details" {...props.tooltipprops} arrow>
                            <a>
                                          <img
                                            src={viewAction}
                                            alt="openmenu"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => props.sub_detail_restriction(9,row._id,row.showDetails)}
                                          >
                                          </img>
                                          </a>
                                      </LightTooltip>
                                      </TableCell>
                          </StyledTableRow>
                        ))
                      )}
                </TableBody>
                <TableFooter>
                  {props.pageOfItems &&
                    props.pageOfItems.length !== 0 ?
                    <TableRow>
                      <td colSpan={20} className="pagination_container">
                        <CustomPaginationComponent
                          count={props.pager.endPage}
                          rowsPerPage={props.rowsPerPage}
                          page={props.pageNumber}
                          onPageChange={props.handleCustomPaginagtionChange}
                          onChangeRowsPerPage={props.handleChangeRowsPerPage}
                        />
                      </td>
                    </TableRow> : null}
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default BillingSubscriptionComponent;