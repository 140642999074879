import React, { Component } from "react";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import credit_card_img from "../../Assets/images/credit_card_icon.svg";
import bill_edit_img from "../../Assets/images/bill_edit_icon.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import tick_green from "../../Assets/images/upload_img.png";
import { AxiosInstance } from "../../Utilities";
import { upperFirst } from "lodash";
import { Button,Grid } from "@material-ui/core";
import {withRouter} from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PayWithNewCardComponent from "./PayWithNewCardComponent";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

class CancelSubscriptionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billStatus: 1,
      customerId:"",
      price:"",
      types:0,
      showAddCardModal: false,
      cardErrors: {
        numberError: "",
        expiryError: "",
      },
      billingDetails: {
      },
      cardDetail: {
      },
      stripeData: {},
    };
  }

  UNSAFE_componentWillMount = () => {
    this.fetchBillingStatus();
    this.fetch_subscription_details();
  };

  fetch_subscription_details=()=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
    "application/json")
    .get("/get_sub_details")
    .then(resp=>{
      let response = resp && resp.data;
      if(response && (response.status === "200" || response.status === 200)){
        this.setState({
          customerId:response.data.customerId,
          price: response.data.price
        })
      }
    }).catch(err=>{
      var error = err.response;
      if (error && (error.status === "403" || error.status === 403)) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
      }
    });
  }

  fetchBillingStatus = () => {
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      "application/json"
    )
      .get("/employee/billingStatus")
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          let billingDetails=response.billingDetails?response.billingDetails.billingDetails:{};
          let cardDetail = response.billingDetails?response.billingDetails.cardDetail:{};

          this.setState({
            billStatus:response.billStatus,
            billingDetails,
            cardDetail
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          //   let propserror = {
          //     message: "Something went wrong",
          //     icon: <img src={iconNotificationError} alt="success" />,
          //     type: "error",
          //     placement: "topRight",
          //     duration: 3,
          //     top: 200,
          //     className: "task_notification error-notify",
          //   };
          //   NotificationComponent(propserror);
        }
      });
  };

  handleChangeCard = ({ error }) => {
    const { cardErrors } = this.state;
    if (error) {
      cardErrors.numberError = error.message;
    } else {
      cardErrors.numberError = "";
    }
    this.setState({
      cardErrors,
    });
  };

  handleChangeExpiry = ({ error }) => {
    const { cardErrors } = this.state;
    if (error) {
      cardErrors.expiryError = error.message;
    } else {
      cardErrors.expiryError = "";
    }
    this.setState({
      cardErrors,
    });
  };

  closeAddCardHandler = () => {
    this.setState(
      {
        showAddCardModal: false,
      },
      () => {
        localStorage.removeItem("billingDetail");
      }
    );
  };

  payWithOldCardHandler = () => {
    let data={
      type:0
    }
    this.resubscribe_patment_handler(data);
    // let data = {
    //   name: this.state.billingDetails.name,
    //   phone: this.state.billingDetails.phone,
    //   email: this.state.billingDetails.email,
    //   period: parseInt(this.state.billingDetails.billingCycle),
    //   subscriptionId: this.state.billingDetails.subscriptionId,
    //   addresLine1: this.state.billingDetails.address.line1,
    //   addressLine2: this.state.billingDetails.address.line2,
    //   city: this.state.billingDetails.address.city,
    //   state: this.state.billingDetails.address.state,
    //   country: this.state.billingDetails.address.country,
    //   zipCode: this.state.billingDetails.address.postal_code,
    //   lastDigit: parseInt(this.state.cardDetail.lastDigit),
    //   cardHolder: this.state.cardDetail.cardHolder,
    //   expire: this.state.cardDetail.expire,
    //   customerId: this.state.billingDetails.customerId,
    //   paymentMethodId: this.state.billingDetails.paymentMethodId,
    // };
    // localStorage.setItem("billingDetail", JSON.stringify(data));
  };

  payWithNewCardHandler = (type) => {
    if(Object.entries(this.state.billingDetails).length !== 0){
    let data = {
      name: this.state.billingDetails.name,
      phone: this.state.billingDetails.phone,
      email: this.state.billingDetails.email,
      period: parseInt(this.state.billingDetails.billingCycle),
      subscriptionId: this.state.billingDetails.subscriptionId,
      addresLine1: this.state.billingDetails.address.line1,
      addressLine2: this.state.billingDetails.address.line2,
      city: this.state.billingDetails.address.city,
      state: this.state.billingDetails.address.state,
      country: this.state.billingDetails.countryCode,
      zipCode: this.state.billingDetails.address.postal_code,
      lastDigit: parseInt(this.state.cardDetail.lastDigit),
      cardHolder: this.state.cardDetail.cardHolder,
      expire: this.state.cardDetail.expire,
      customerId: this.state.billingDetails.customerId,
      paymentMethodId: this.state.billingDetails.paymentMethodId,
    };
    localStorage.setItem("billingDetail", JSON.stringify(data));

    this.setState({
      showAddCardModal: true,
      types:type
    });
  }
  };

  setCustomerData = (data) => {
    this.setState(
      {
        stripeData: data,
        showAddCardModal: false,
        showUpdateCardModal: false,
        showAddCard: false,
        disableSave: false,
      },
      () => {
        localStorage.removeItem("billingDetail");
        if(this.state.types === 1 && Object.entries(this.state.stripeData).length !== 0){
          let appData={
            paymentMethodId:this.state.stripeData.paymentMethodId,
            type:1
          }
          this.resubscribe_patment_handler(appData);
        }else if(this.state.types === 2 && Object.entries(this.state.stripeData).length !== 0){
          let newCard={
            paymentMethodId:this.state.stripeData.paymentMethodId,
            customerId:this.state.customerId
          }
          this.retry_payment_handler(newCard)
        }
      }
    );
  };
  retry_payment_handler = (data)=>{
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      "application/json"
    )
      .post("/retryPayment",data)
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(propserror);
            setTimeout(() => {
              this.props.history.replace("/billing")
            }, 1000);
        }else         if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            setTimeout(() => {
              this.props.history.replace("/billing")
            }, 1000);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
            // let propserror = {
            //   message: "Something went wrong",
            //   icon: <img src={iconNotificationError} alt="success" />,
            //   type: "error",
            //   placement: "topRight",
            //   duration: 3,
            //   top: 200,
            //   className: "task_notification error-notify",
            // };
            // NotificationComponent(propserror);
        }
      });
  }
  resubscribe_patment_handler=(data)=>{
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6019 : "subscription",
      "application/json"
    )
      .post("/reSubscribe",data)
      .then((resp) => {
        let response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          let propserror = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(propserror);
            setTimeout(() => {
              this.props.history.replace("/billing")
            }, 500);
        }else         if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
            setTimeout(() => {
              this.props.history.replace("/billing")
            }, 500);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
            // let propserror = {
            //   message: "Something went wrong",
            //   icon: <img src={iconNotificationError} alt="success" />,
            //   type: "error",
            //   placement: "topRight",
            //   duration: 3,
            //   top: 200,
            //   className: "task_notification error-notify",
            // };
            // NotificationComponent(propserror);
        }
      });
  }
  render() {
    return (
      <div className="body-bg-color billing-page">
        <HeaderComponent />
        <Elements stripe={stripePromise}>
          <PayWithNewCardComponent
            handleChangeCard={this.handleChangeCard}
            handleChangeExpiry={this.handleChangeExpiry}
            cardErrors={this.state.cardErrors}
            setCustomerData={this.setCustomerData}
            openCardModal={this.state.showAddCardModal}
            closeCardModal={this.closeAddCardHandler}
            subscribePrice={this.state.subscribePrice}
          />
        </Elements>
        {this.state.billStatus === 1 ? (
          <div className="subscription_page">
            <h5 className="overview_section_heading billing_page_title">Billing details</h5>
            <div className="subscription_section">
            <div className="bill_details_card_container">
              <div className="bill_details_image_section">
                <img
                  src={credit_card_img}
                  alt="profile-image"
                  className="bill_contact_card_image"
                />
              </div>
              <div className="bill_details_section">
                <h4 className="bill_details_card_heading">Credit card</h4>
                <h6 className="bill_details_card_number">
                  <span>.... .... .... </span>
                  {this.state.cardDetail && this.state.cardDetail.lastDigit
                    ? this.state.cardDetail.lastDigit
                    : "4242"}
                </h6>
                <h6 className="bill_details_card_holder">
                  {this.state.cardDetail && this.state.cardDetail.cardHolder
                    ? upperFirst(this.state.cardDetail.cardHolder)
                    : "Benakesha"}
                </h6>
                <h6 className="bill_details_card_expire">
                  Expires{" "}
                  {this.state.cardDetail && this.state.cardDetail.expire
                    ? this.state.cardDetail.expire
                    : "10/23"}
                </h6>
              </div>
            </div>
            <div className="add_company_action">
            <Button
              className="ok_btn green-btn bill_edit_btn"
              type="button"
              onClick={() => this.payWithOldCardHandler()}
            >
              Subscribe
            </Button>
            </div>
            </div>
            <div className="add_company_action">
            <Button
              className="ok_btn green-btn bill_edit_btn"
              type="button"
              onClick={() => this.payWithNewCardHandler(1)}
            >
              Subscribe With New Card
            </Button>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.billStatus === 2 ? (
          <>
            <p>
              Payment failed because of wrong card data / insufficient fund,
              please pay with new card{" "}
            </p>
            <h5 className="overview_section_heading">Billing details</h5>
            <div className="bill_details_card_container">
              <div className="bill_details_image_section">
                <img
                  src={credit_card_img}
                  alt="profile-image"
                  className="bill_contact_card_image"
                />
              </div>
              <div className="bill_details_section">
                <h4 className="bill_details_card_heading">Credit card</h4>
                <h6 className="bill_details_card_number">
                  <span>.... .... .... </span>
                  {this.state.cardDetail && this.state.cardDetail.lastDigit
                    ? this.state.cardDetail.lastDigit
                    : "4242"}
                </h6>
                <h6 className="bill_details_card_holder">
                  {this.state.cardDetail && this.state.cardDetail.cardHolder
                    ? upperFirst(this.state.cardDetail.cardHolder)
                    : "Benakesha"}
                </h6>
                <h6 className="bill_details_card_expire">
                  Expires{" "}
                  {this.state.cardDetail && this.state.cardDetail.expire
                    ? this.state.cardDetail.expire
                    : "10/23"}
                </h6>
              </div>
            </div>
            <Button
              className="bill_edit_btn"
              type="button"
              onClick={() => this.payWithNewCardHandler(2)}
            >
              Pay With New Card
            </Button>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(CancelSubscriptionComponent);
