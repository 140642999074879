import React, { Component, Suspense } from "react";
import "./AdminManagerDashboardComponent.css";
import { Grid, Dialog,
  DialogContent,
  DialogTitle,IconButton, Button} from "@material-ui/core";
import carret_down from "../../Assets/images/carret_down.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationUpdate from "../../Assets/images/icon-notify-update.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconClose from "../../Assets/images/close_btn.svg";
import taskAssignThree from "../../Assets/images/task_asign_three.png";
import taskWarningIcon from "../../Assets/images/task_warning_icon.svg";
import logo from "../../Assets/images/Sales_C2_logo.svg";
import momentTimeZone from "moment-timezone";
import moment from "moment";
import { AxiosInstance, checkTimeZone, checkCmsLabel } from "../../Utilities";
import { upperFirst } from "lodash";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import { exportToCSV, fetchDateFilterData } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { TransitionGrow } from "../../SharedComponents/CommonUtilities/CommonUtilities";

import Pagination from '@material-ui/lab/Pagination';
import PendingEventAcceptanceComponent from "./PendingEventAcceptanceComponent/PendingEventAcceptanceComponent";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import {withRouter,Redirect} from 'react-router-dom';

var SummaryReportComponent = React.lazy(() =>
  import("./SummaryReportComponent/SummaryReportComponent")
);
// var HeaderComponent = React.lazy(() =>
//   import("../../SharedComponents/HeaderComponent/HeaderComponent")
// );
var CommonSummaryComponent = React.lazy(() =>
  import("../../SharedComponents/CommonSummaryComponent/CommonSummaryComponent")
);
var CommonBarGraphComponent = React.lazy(() =>
  import(
    "../../SharedComponents/ChartsComponent/CommonBarGraphComponent/CommonBarGraphComponent"
  )
);
var CommonGraph = React.lazy(() =>
  import(
    "../../SharedComponents/ChartsComponent/CommonBarGraphComponent/CommonGraph"
  )
);
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);

class AdminManagerDashboardComponent extends Component {
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var dateFilterArray = [
      { _id: "", name: "This Week" },
      { _id: "last7days", name: "Last 7 Days" },
      { _id: "month", name: "Last 30 Days" },
      { _id: "quarter", name: "This Quarter" },
      { _id: "year", name: "This Year" },
    ];
    // var zoneName = momentTimeZone.tz.guess();
    var zoneName =localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") :momentTimeZone.tz.guess();
    this.state = {
      cmsLabelChange:[],
      pageNumber:1,
      pendingAcceptanceList:[],
      pendingAcceptancePagination:null,
      loggedInData: userData,
      timeZoneName: zoneName,      
      summaryMonthFilter: "",
      forecastedSummaryMonthFilter:"",
      summaryTaskMonthFilter: "",
      summaryProductMonthFilter: "",
      summaryAccountMonthFilter: "",
      statusFilterName: "",
      summaryRepFilterName:"",
      summaryReportFilter:"",
      summarySelected: "today",
      summary_date: {
        id: "date_filter_control",
        anchorElDateFilter: null,
        summaryDate: dateFilterArray,
      },
      summary_rep: {
        id: "rep_filter_control",
        anchorElRepFilter: null,
        summaryDate: dateFilterArray,
      },
      summary_month: {
        id: "summary_date_filter_control",
        anchorElDate: null,
        summaryDate: dateFilterArray,
      },
       forecasted_summary_month: {
        id: "forecasted_date_filter_control",
        anchorElForecastedDate :null,
         summaryDate: dateFilterArray,
      },
      task_summary_month: {
        id: "summary_task_date_filter_control",
        anchorElTaskDate: null,
        summaryDate: dateFilterArray,
      },
      product_summary_month: {
        id: "summary_product_date_filter_control",
        anchorElProductDate: null,
        summaryDate: dateFilterArray,
      },
      account_summary_month: {
        id: "summary_account_date_filter_control",
        anchorElAccountDate: null,
        summaryDate: [
          { _id: "month", name: "Last 30 Days" },
          { _id: "quarter", name: "This Quarter" },
          { _id: "year", name: "This Year" },
        ],
      },
      statusConfig: {
        id: "status_filter_select",
        anchorElStatus: null,
        statusOptions: [
          { _id: "", name: "Completed" },
          { _id: "Unassigned", name: "Unassigned" },
          { _id: "Pending", name: "Pending" },
          { _id: "assigned", name: "Assigned" },
          { _id: "inprogress", name: "In Progress" },
          { _id: "cancelled", name: "Cancelled" },
          { _id: "uncovered", name: "Uncovered" },
        ],
      },
      taskSummaryData: {},
      regionChartData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
            minPointLength: 2,
            pointWidth: 25,
          },
        },
        config: {
          // legend: {
          //   layout: "horizontal",
          //   align: "center",
          //   verticalAlign: "bottom",
          //   x: 10,
          //   y: 20,
          //   floating: true,
          //   borderWidth: 1,
          //   backgroundColor: "#FFFFFF",
          //   shadow: true,

          // },
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.2f} $</b>`
        }
      },
      RepChartData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          bar: {
            stacking: "percent",
            pointWidth: 25,
            minPointLength: 2,
          },
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.2f} hr</b>`
        },
        startDate: moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'), zoneName).utc(),

        endDate: moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
      },
      NoOfTaskData: {
        xAxis: [],
        yAxis: [],
        config: {
          legend:{
            enabled:false
          }
          // legend: {
          //   layout: "vertical",
          //   align: "right",
          //   verticalAlign: "top",
          //   x: -5,
          //   y: 10,
          //   floating: true,
          //   borderWidth: 1,
          //   backgroundColor: "#FFFFFF",
          //   shadow: true,
          // },
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`
        },
        startDate: moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'),zoneName).utc(),
        endDate: moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
      },
      productSalesData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          column: {
            pointPadding: 0.2,
            minPointLength: 2,
            borderWidth: 0,
            pointWidth: 8,
            color: "#a37eba",
          },
        },
        config: {
          legend:{
            enabled:false
          }
          // legend: {
          //   layout: "vertical",
          //   align: "right",
          //   verticalAlign: "top",
          //   x: -5,
          //   y: 10,
          //   floating: true,
          //   borderWidth: 1,
          //   backgroundColor: "#FFFFFF",
          //   shadow: true,
          // },
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.2f} $</b>`
        },
        startDate: moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'), zoneName).utc(),
        endDate: moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
      },
      ActiveNoOfAccount: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
            color: "#407088",
          },
        },
        config: {
          legend:{
            enabled:false
          }
          // legend: {
          //   layout: "horizontal",
          //   align: "center",
          //   verticalAlign: "bottom",
          //   x: -5,
          //   y: 10,
          //   floating: true,
          //   borderWidth: 1,
          //   backgroundColor: "#FFFFFF",
          //   shadow: true,
          // },
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`
        },
      },
      ForecastedRevenue: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
            color: "#407088",
          },
        },
        config: {
          legend:{
            enabled:true
          }
          // legend: {
          //   layout: "horizontal",
          //   align: "center",
          //   verticalAlign: "bottom",
          //   x: -5,
          //   y: 10,
          //   floating: true,
          //   borderWidth: 1,
          //   backgroundColor: "#FFFFFF",
          //   shadow: true,
          // },
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`
        },
      },
      summaryReports:{
        reportList:[],
        id: "summary_report_filter_control",
        anchorElReport: null,
        summaryDate: dateFilterArray,

        startDate: moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'),zoneName).utc(),
        endDate: moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), zoneName).utc(),
      },
      loader:false,

      taskConflictModalOpen:false,
      taskConflictModalData:null,
      diffTime:null,
      taskRejectBtnClicked:false,
      taskAcceptBtnClicked:false,
      btnClickId:null

    };
  }
  componentWillMount() {
    this.getTimeZones();

    const fetchCmsData = checkCmsLabel()
      if(fetchCmsData){
        this.setState({ cmsLabelChange : fetchCmsData.cmsData})
      }
   
    window.scrollTo(0, 0);
    var data = {};
      data.startDate = moment.tz(momentTimeZone().startOf("week").format('YYYY-MM-DD 00:00:00'), this.state.timeZoneName).utc();
      data.endDate = moment.tz(momentTimeZone().endOf("week").format('YYYY-MM-DD 23:59:59'), this.state.timeZoneName).utc();
      // const start = momentTimeZone().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
      // const end = momentTimeZone().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
    this.fetchTaskSummaryCardData({
      startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc(),
      endDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'),this.state.timeZoneName).utc()
    });
    this.fetchForecastedRevenue({
      startDate:data.startDate,//momentTimeZone().utc(),
      endDate:data.endDate//momentTimeZone(data.endDate).utc()
    });
    this.fetchCostRevenue({
      startDate:data.startDate,//momentTimeZone().utc(),
      endDate:data.endDate//momentTimeZone(data.endDate).utc()
    });
    this.fetchRepUtilizationData({
      startDate:this.state.RepChartData.startDate,// momentTimeZone(this.state.RepChartData.startDate).utc(),
      endDate:this.state.RepChartData.endDate// momentTimeZone(this.state.RepChartData.endDate).utc()
    });
    this.fetchTaskByStatus({
      taskStatus: "completed",
      startDate: this.state.NoOfTaskData.startDate,//momentTimeZone(this.state.NoOfTaskData.startDate).utc(),
      endDate:this.state.NoOfTaskData.endDate,// momentTimeZone(this.state.NoOfTaskData.endDate).utc(),
    });
    this.fetchProductSales({
      startDate:this.state.productSalesData.startDate,//momentTimeZone(this.state.productSalesData.startDate).utc(),
      endDate: this.state.productSalesData.endDate,//momentTimeZone(this.state.productSalesData.endDate).utc(),
    });
    this.fetchActiveNoOfAccount();
    this.fetchSummaryReports();
    // this.state.summaryReports
    this.fetchPendingEvents({
      page:this.state.pageNumber,
      // startDate: momentTimeZone(start).utc(),
      // endDate: momentTimeZone(end).utc()
      today: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc(),
    });
  }
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  getTimeZones=()=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user","application/json")
    .get("/employee/timezone")
    .then(resp=>{
      var response = resp && resp.data;
      if(response && (response.status === "200" || response.status === 200)){
        this.setState({
          timeZoneName:response.data.timezone?response.data.timezone:momentTimeZone.tz.guess(),
         
        });
        localStorage.setItem("timeZone",response.data.timezone?response.data.timezone:momentTimeZone.tz.guess());
        checkTimeZone();
      }else if(response && (response.status === "400" || response.status === 400)){

      }
    }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  changePagination=(event,value)=>{
    this.setState({
      pageNumber:value
    },()=>{
      // const start = momentTimeZone().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
      // const end = momentTimeZone().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
      this.fetchPendingEvents({
        page:this.state.pageNumber,
        today: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc()
      });
    })
  }
  fetchPendingEvents=(data)=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6013:'event',"application/json")
    .post('/events/pending',data)
    .then(resp=>{
      let response= resp && resp.data;
      if(response && (response.status === '200' || response.status === 200)){
          this.setState({
            pendingAcceptanceList:response.data,
            pendingAcceptancePagination:response.pager
          })
      }else if(response && (response.status === '401' || response.status === 401)){
        this.setState({
          pendingAcceptanceList:[],
          pendingAcceptancePagination:null
        })
      }else if(response && (response.status === '400' || response.status === 400)){
        this.setState({
          pendingAcceptanceList:[],
          pendingAcceptancePagination:null
        })
        let props = {
          message: response.msg,
          icon: <img src={iconNotificationError} alt="error" />,
          type: "error",
          placement: "topRight",
          duration: 2,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(props);
      }
    }).catch(err=>{
      this.setState({
        pendingAcceptanceList:[],
        pendingAcceptancePagination:null
      })
      var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }                    
    })
  }
  fetchRepUtilizationData = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .post("/report/repUtilisation", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { RepChartData } = this.state;
          RepChartData.xAxis = response.data.xaxis;
         
          // var newArr=[];
          // let repData=response.data.yaxis;
          // if(repData.length>0){
          //   repData.map(item=>{
          //     let obj={};
          //     obj.name=item.name;
          //     obj.data=item.data;
          //     obj.color=randomColor();
          //     newArr.push(obj);

          //   });
          // }
          RepChartData.yAxis = response.data.yaxis;
          this.setState({
            RepChartData,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }
  fetchTaskSummaryCardData = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .post("/report/dashboardCards", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          this.setState({
            taskSummaryData: response.data
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  fetchCostRevenue = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .post("/report/costRevenue", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { regionChartData } = this.state;
          regionChartData.xAxis = response.data.xaxis;
           
          // var newArry=[];
          // let repData=response.data.yaxis;
          // if(repData.length>0){
          //   repData.map(item=>{
          //     let obj={};
          //     obj.name=item.name;
          //     obj.data=item.data;
          //     obj.color=randomColor();
          //     newArry.push(obj);

          //   });
          // }
          regionChartData.yAxis = response.data.yaxis;
          this.setState({
            regionChartData,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };

  fetchForecastedRevenue = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .post("/report/forecaste", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { ForecastedRevenue } = this.state;
          ForecastedRevenue.xAxis = response.data.xaxis;
          ForecastedRevenue.yAxis = response.data.yaxis;
          this.setState({
            ForecastedRevenue,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };

  fetchTaskByStatus = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .post("/report/levelTask", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { NoOfTaskData } = this.state;
          NoOfTaskData.xAxis = response.data.xaxis;
          NoOfTaskData.yAxis = response.data.yaxis;
          this.setState({
            NoOfTaskData,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  fetchProductSales = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .post("/report/productSales", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { productSalesData } = this.state;
          productSalesData.xAxis = response.data.xaxis;
          productSalesData.yAxis = response.data.yaxis;
          this.setState({
            productSalesData,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  fetchActiveNoOfAccount = (data) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .post("/report/activeAccounts", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { ActiveNoOfAccount } = this.state;
          ActiveNoOfAccount.xAxis = response.data.xaxis;
          ActiveNoOfAccount.yAxis = response.data.yaxis;
          this.setState({
            ActiveNoOfAccount,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  fetchSummaryReports = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .get("/report/levelList")
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { summaryReports } = this.state;
          summaryReports.reportList = response.data;
          this.setState({
            summaryReports,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }
  onSummaryDateFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "summary_month",
      "anchorElDate",
      event.currentTarget
    );
  };
  onForecastedSummaryDateFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "forecasted_summary_month",
      "anchorElForecastedDate",
      event.currentTarget
    );
  };

  onDateFilterOpen = (event) => {
    this.setState({
      summarySelected: "",
    });
    this.setCustomFilterOpenClose(
      "summary_date",
      "anchorElDateFilter",
      event.currentTarget
    );
  };
  onRepFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "summary_rep",
      "anchorElRepFilter",
      event.currentTarget
    );
  };
  onSummaryTaskDateFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "task_summary_month",
      "anchorElTaskDate",
      event.currentTarget
    );
  };
  onSummaryProductDateFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "product_summary_month",
      "anchorElProductDate",
      event.currentTarget
    );
  };
  onSummaryAccountDateFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "account_summary_month",
      "anchorElAccountDate",
      event.currentTarget
    );
  };
  openStatusFilterMenu = (event) => {
    this.setCustomFilterOpenClose(
      "statusConfig",
      "anchorElStatus",
      event.currentTarget
    );
  };
  onSummaryReportFilterOpen = (event) => {
    this.setCustomFilterOpenClose(
      "summaryReports",
      "anchorElReport",
      event.currentTarget
    );
  };
  onSummaryReportFilterClose = (event) => {
    this.setCustomFilterOpenClose(
      "summaryReports",
      "anchorElReport",
      null
    );
  };
  onRepFilterClose = () => {
    this.setCustomFilterOpenClose(
      "summary_rep",
      "anchorElRepFilter",
      null
    );
  };
  onSummaryProductDateFilterClose = () => {
    this.setCustomFilterOpenClose(
      "product_summary_month",
      "anchorElProductDate",
      null
    );
  };
  onSummaryAccountDateFilterClose = () => {
    this.setCustomFilterOpenClose(
      "account_summary_month",
      "anchorElAccountDate",
      null
    );
  };
  onSummaryDateFilterClose = () => {
    this.setCustomFilterOpenClose("summary_month", "anchorElDate", null);
  };
  onForecastedDateFilterClose = () => {
    this.setCustomFilterOpenClose("forecasted_summary_month", "anchorElForecastedDate", null);
  };
  onSummaryTaskDateFilterClose = () => {
    this.setCustomFilterOpenClose(
      "task_summary_month",
      "anchorElTaskDate",
      null
    );
  };

  onDateFilterClose = () => {
    this.setCustomFilterOpenClose("summary_date", "anchorElDateFilter", null);
  };
  closeStatusFilterMenu = () => {
    this.setCustomFilterOpenClose("statusConfig", "anchorElStatus", null);
  };

  /**
   *
   * @param {state field name} stateName
   * @param {anchor field id} anchorField
   * @param {event current target} event
   * TO OPEN/CLOSE FILTERS
   */
  setCustomFilterOpenClose = (stateName, anchorField, event) => {
    var field = this.state[stateName];
    field[anchorField] = event;
    this.setState({
      [stateName]: field,
    });
  };

  onSummaryDateFilter = async (item) => {
    var { summary_month } = this.state;
    summary_month.anchorElDate = null;
    var data = await fetchDateFilterData(item,this.state.timeZoneName);
   
    this.fetchCostRevenue(data);
    this.setState({
      summary_month,
      summaryMonthFilter:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  };
  onForecastedRevenueDateFilter = async (item) => {
    var { forecasted_summary_month } = this.state;
    forecasted_summary_month.anchorElForecastedDate = null;
    var data = await fetchDateFilterData(item,this.state.timeZoneName);
   
    this.fetchForecastedRevenue(data);
    this.setState({
      forecasted_summary_month,
      forecastedSummaryMonthFilter: item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  };
  onSummaryReportFilter = async (item) => {
    var { summaryReports } = this.state;
    summaryReports.anchorElReport = null;
    var data = await fetchDateFilterData(item,this.state.timeZoneName);
   
    summaryReports.startDate=data.startDate;
    summaryReports.endDate=data.endDate;
    this.setState({
      summaryReports,
      summaryReportFilter:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  };

  onSummaryAccountDateFilter = async (item) => {
    var { account_summary_month } = this.state;
    account_summary_month.anchorElAccountDate = null;
    var data = await fetchDateFilterData(item,this.state.timeZoneName);
   
    this.fetchActiveNoOfAccount({ startDate: data.startDate,endDate:data.endDate });
    this.setState({
      account_summary_month,
      summaryAccountMonthFilter: item.name,
    });
  };

  onSummaryTaskDateFilter = async (item) => {
    var { task_summary_month, NoOfTaskData } = this.state;
    task_summary_month.anchorElTaskDate = null;
    var data = await fetchDateFilterData(item,this.state.timeZoneName);
   
    // this.fetchCostRevenue(data);
    NoOfTaskData.startDate = data.startDate;
    NoOfTaskData.endDate = data.endDate;
    data.taskStatus = this.state.statusFilterName;
    this.fetchTaskByStatus(data);
    this.setState({
      NoOfTaskData,
      task_summary_month,
      summaryTaskMonthFilter:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  };

  onDateFilter = async (item) => {
    var { summary_date } = this.state;
    summary_date.anchorElDateFilter = null;
    var data = await fetchDateFilterData(item,this.state.timeZoneName);
   
    this.fetchTaskSummaryCardData(data);
    this.setState({
      summary_date,
      summaryFilterName:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  };

  onSummaryProductDateFilter = async (item) => {
    var { product_summary_month } = this.state;
    product_summary_month.anchorElProductDate = null;
    var data = await fetchDateFilterData(item,this.state.timeZoneName);
   
    this.fetchProductSales(data);
    this.setState({
      product_summary_month,
      summaryProductMonthFilter:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  };

  onRepFilter = async (item) => {
    var { summary_rep } = this.state;
    summary_rep.anchorElRepFilter = null;
    var data = await fetchDateFilterData(item,this.state.timeZoneName);
    

    this.fetchRepUtilizationData(data);
    this.setState({
      summary_rep,
      summaryRepFilterName:
        item.name.toLowerCase() === "this week" ? "" : item.name,
    });
  }
 
  handleStatusSelect = (item) => {
    var { statusConfig, NoOfTaskData } = this.state;
    var data = {};
    data.taskStatus = item._id === "" ? "completed" : item._id;
    statusConfig.anchorElStatus = null;
    this.setState({
      statusConfig,
      statusFilterName: item._id === "" ? "" : item.name,
    });
    data.startDate = momentTimeZone(NoOfTaskData.startDate).utc();
    data.endDate = momentTimeZone(NoOfTaskData.endDate).utc();
    this.fetchTaskByStatus(data);
  };

  onTodayHandler = () => {
    // const start = momentTimeZone().tz(this.state.timeZoneName).format('YYYY-MM-DD 00:00:00');
    // const end = momentTimeZone().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
    let data = {
      startDate: moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc(),
      endDate:  moment.tz(momentTimeZone().format('YYYY-MM-DD 23:59:59'),this.state.timeZoneName).utc()
    };
    this.setState({
      summarySelected: "today",
    });
    this.fetchTaskSummaryCardData(data);
  };
  onThisWeekHandler = () => {
    // const toDay = momentTimeZone(); 
    // moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc()
    // .format('YYYY-MM-DD 00:00:00');
    //   const end = momentTimeZone().tz(this.state.timeZoneName).format('YYYY-MM-DD 23:59:59');
    this.setState({
      summarySelected: "week",
    });
    let data = {
      startDate:  moment.tz(momentTimeZone(moment().startOf("week")._i).format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc(),
      // momentTimeZone(momentTimeZone(toDay.startOf("week")._i).format("YYYY-MM-DD 00:00:00")).utc(),
      endDate: moment.tz(momentTimeZone(moment().endOf("week")._d).format('YYYY-MM-DD 23:59:59'),this.state.timeZoneName).utc()
      //  momentTimeZone(momentTimeZone(toDay.endOf("week")._d).format("YYYY-MM-DD 23:59:59")).utc(),
    };
    this.fetchTaskSummaryCardData(data);
  };

  downloadReportsHandler = (item) => {
    var data = {
      startDate:this.state.summaryReports.startDate,// momentTimeZone().utc(),
      endDate: this.state.summaryReports.endDate,//momentTimeZone().utc(),
      level: item.type,
      levelId: item._id
    }
    this.setState({
      loader:true
    })
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6016:'report', "application/json")
      .post("/report/taskReport", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          var data = response.data;
          this.setState({
            loader:false
          })
          if (data.length > 0) {
            exportToCSV(data, item.name + Date.now())
          } else {
            var props = {
              message: "No Data found for this team",
              icon: (
                <img src={iconNotificationUpdate} alt="success" />
              ),
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification update-notify",
            };
            NotificationComponent(props);
          }


        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          this.setState({
            loader:false
          })
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        this.setState({
          loader:false
        })
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }


  onAcceptanceHandler=(item,type)=>{
    if(type === 'accept' && !item.conflict){
      this.setState({
        taskAcceptBtnClicked:true,
        btnClickId:item._id
      })
    }else if(type === 'reject'){
      this.setState({
        taskRejectBtnClicked:true,
        btnClickId:item._id
      })
    }
    if(item.conflicts && (item.type === 'personal' || item.type === 'meeting')){
      if(type === 'accept'){
        this.onTaskAcceptRejectHandler(item._id,type)
        
        var end_date = momentTimeZone(item.endDate).tz(this.state.timeZoneName);
        var start_date = momentTimeZone(item.startDate).tz(this.state.timeZoneName);
        var diffTime = end_date.diff(start_date, 'hours');
        var diffMinutes = end_date.diff(start_date, 'minutes');
        var diffSeconds = end_date.diff(start_date, 'seconds');
        diffTime = diffTime === 0 ? diffMinutes + " Minutes" : diffMinutes === 0 ? diffSeconds + " Seconds" : diffTime + " Hours";
    
        this.setState({ taskConflictModalData: item,diffTime
          },()=>{
          this.setState({
            taskConflictModalOpen: true
          })
        });
        }else{
          this.onTaskAcceptRejectHandler(item._id,type)
        }
    }else if(!item.conflicts && (item.type === 'personal' || item.type === 'meeting')){
      //event accept
      this.onTaskAcceptRejectHandler(item._id,type)
    }
  }

  onTaskAcceptRejectHandler=(id,type)=>{
    let data={};
    data.id=id;
    data.type=type === 'accept'?1:2;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6013:'event',"application/json")
    .post('/eventAcceptReject',data)
    .then(resp=>{
      let response=resp && resp.data;
      if(response && (response.status === '200' || response.status === 200)){
        this.fetchPendingEvents({
          page:this.state.pageNumber,
          today:moment.tz(momentTimeZone().format('YYYY-MM-DD 00:00:00'),this.state.timeZoneName).utc()
          // momentTimeZone(momentTimeZone().tz(this.state.timeZoneName).format("YYYY-MM-DD 00:00:00")).utc()
        });
        let props = {
          message: `Task ${type === 'accept' ? "Accepted" : "Rejected"} Successfully`,
          icon: <img src={iconNotificationSuccess} alt="success" />,
          type: "success",
          placement: "topRight",
          duration: 2,
          top: 200,
          className: "task_notification success-notify",
        };
        NotificationComponent(props);
      }else if(response && (response.status === '400' || response.status === 400)){
        let props = {
          message: response.msg,
          icon: <img src={iconNotificationError} alt="error" />,
          type: "error",
          placement: "topRight",
          duration: 2,
          top: 200,
          className: "task_notification error-notify",
        };
        NotificationComponent(props);
      }
    }).catch(err=>{
      var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "topRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
    })
  }

  openConflictModalClose = () => {
    this.setState({
      taskConflictModalOpen: false,
      taskAcceptBtnClicked:false,
      taskRejectBtnClicked:false,
      diffTime:null
    })
  }

  onRescheduleTaskHandler = (item,type) => {
    if(type === 'task'){
      if(window.location.pathname.indexOf("/task") === 0 || window.location.pathname.indexOf("/task") === -1){
        this.props.history.replace(`/task/${item.conflict_event_id}`);
      }
      this.setState({
        taskConflictModalOpen: false
      })
   }else{
    if(window.location.pathname.indexOf("/eventdetail") === 0 || window.location.pathname.indexOf("/eventdetail") === -1){
      this.props.history.replace(`/eventdetail/${item.conflict_event_id}`);
     }
   
   }
  }

  render() {
    const taskConflictModal = (
      <Dialog
        open={this.state.taskConflictModalOpen}
        aria-labelledby="form-dialog-title"
        className="add_company_dialog task_assignment_dialog update_status_dialog task_conflict_border"
        TransitionComponent={TransitionGrow} 
        transitionDuration={600}
      >
        <DialogTitle
          id="form-dialog-title"
          className="add_user_header task_assignment_header"
        >
          <img src={logo} alt="Sales-Icon" width="142px" height="auto" />
          <IconButton
            aria-label="close"
            className="closeBtn updateStatusCloseBtn"
            onClick={this.openConflictModalClose}
          >
            <img src={iconClose} alt="close"/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="task_assign_model_container">
            {this.state.taskConflictModalData ?
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <img className="task-img-width" src={taskAssignThree} alt="task img"/>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="tasking-assign-right">
                    <h1>Conflict Found</h1>
                    <p><img src={taskWarningIcon} alt="warning"/>
                    This event conflicts with&nbsp;{upperFirst(this.state.taskConflictModalData.conflict_event_title)}.</p>
                    <span>{`
                  ${momentTimeZone(this.state.taskConflictModalData.startDate).tz(this.state.timeZoneName).format('MMMM DD YYYY')} 
                  at 
                  ${momentTimeZone(this.state.taskConflictModalData.startDate).tz(this.state.timeZoneName).format('hh:mm A')} 
                  - 
                  ${momentTimeZone(this.state.taskConflictModalData.endDate).tz(this.state.timeZoneName).format('hh:mm A')} 
                  (${this.state.diffTime ? this.state.diffTime : ""})`}</span>
                  </div>
                  <div className="add_company_action task_assign_action">
                    <Button className="cancel_btn white-btn" onClick={this.openConflictModalClose}>Skip</Button>
                    <Button className="ok_btn green-btn" onClick={() => this.onRescheduleTaskHandler(this.state.taskConflictModalData,'event')}>Reassign/Reschedule</Button>
                   </div>
                </Grid>
              </Grid>
              : ""}
          </div>
        </DialogContent>
      </Dialog>
    )
    if(this.props.isAuthenticated){
      return <Redirect to="/" />
    }else{
    return (
      <React.Fragment>
        <div className="body-bg-color">
          {/* <Suspense fallback={<div></div>}> */}
            <HeaderComponent />
            {taskConflictModal}
          {/* </Suspense> */}
          <div className="chart-conatiner">
            <div className="dashboard_task_filters">
              <label className="tableTitle">Task Summary</label>
              <div className="new_custom_filters">
                <div className="new_custom_filter_div current-selected">
                  <span
                    className={`rightName ${
                      this.state.summarySelected === "today" ? "selected" : ""
                      }`}
                    onClick={this.onTodayHandler}
                  >
                    Today
                  </span>
                </div>
                {/* <div className="new_custom_filter_div filter-option">
                  <span
                    onClick={this.onThisWeekHandler}
                    className={`rightName ${
                      this.state.summarySelected === "week" ? "selected" : ""
                      }`}
                  >
                    This week
                  </span>
                </div> */}
                {/* <div className="todays_tasks_grid_heading_two"> */}
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div filter-option"
                    onClick={this.onDateFilterOpen}
                  >
                    {this.state.summaryFilterName ? (
                      ""
                    ) : (
                        <label className="leftName">Filter By: </label>
                      )}
                    <label
                      aria-label="summary_date_filter"
                      aria-controls="date_filter_control"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.summaryFilterName
                        ? upperFirst(this.state.summaryFilterName)
                        : `This Week`}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectComponent
                    isAllRequired={true}
                    filterArray={this.state.summary_date.summaryDate}
                    closeCustomPopup={this.onDateFilterClose}
                    config={this.state.summary_date.id}
                    anchorEl={this.state.summary_date.anchorElDateFilter}
                    handleClick={this.onDateFilter}
                  />
                </Suspense>
              </div>
            </div>
            <Suspense fallback={<div></div>}>
              <CommonSummaryComponent
                taskSummary={this.state.taskSummaryData}
              />
            </Suspense>
            <Grid container spacing={2} className="graph-section">
              {this.state.pendingAcceptanceList && this.state.pendingAcceptanceList.length>0?
            <Grid item xs={12}>
                <div className="task-graph cost-revenue acceptance_section">
                  <div className="chart-header">
                    <h5 className="chart-title">Pending Events Acceptance</h5>
                  </div>
                  <PendingEventAcceptanceComponent
                    pendingAcceptanceList={this.state.pendingAcceptanceList}
                    timeZoneName={this.state.timeZoneName}
                    onAcceptanceHandler={this.onAcceptanceHandler}
                    taskRejectBtnClicked={this.state.taskRejectBtnClicked}
                    taskAcceptBtnClicked={this.state.taskAcceptBtnClicked}
                    btnClickId={this.state.btnClickId}
                    detailViewHandler={this.onRescheduleTaskHandler}
                  />
                  {this.state.pendingAcceptancePagination && this.state.pendingAcceptancePagination.totalPages.length !== 0 && this.state.pendingAcceptancePagination.totalItems > 4?
                  <div className="mat-pagination">
                    <Pagination count={this.state.pendingAcceptancePagination.totalPages} page={this.state.pageNumber} onChange={this.changePagination}
                    showFirstButton 
                    showLastButton 
                    />
                  </div>: null}
                </div>
              </Grid>:null}
              <Grid item xs={12} md={6}>
                <div className="task-graph">
                  <div className="chart-header">
                    <h5 className="chart-title">
                      {this.state.loggedInData.role.toLowerCase() ===
                        "division manager"
                        ? `Area`
                        : this.state.loggedInData.role.toLowerCase() === "admin"
                          ? this.state.loggedInData.companySize.toLowerCase() ===
                            "medium"
                            ? `Area`
                            : this.state.loggedInData.companySize.toLowerCase() ===
                              "small"
                              ? `Region`
                              : `Division`
                          : `Region`}
                      {" "}Summary Report
                    </h5>
                    <Suspense fallback={<div></div>}>
                      <div
                        className="new_custom_filter_div chart-filter"
                        onClick={this.onSummaryReportFilterOpen}
                      >
                        {this.state.summaryReportFilter ? (
                          ""
                        ) : (
                          <label className="leftName"> </label>
                        )}
                        <label
                          aria-label="summary_date_filter"
                          aria-controls="summary_report_filter_control"
                          area-aria-haspopup="true"
                          className="rightName"
                        >
                          {this.state.summaryReportFilter
                            ? upperFirst(this.state.summaryReportFilter)
                            : `This Week`}
                        </label>
                        <img
                          className="carret"
                          src={carret_down}
                          alt="carret"
                        />
                      </div>
                      <CustomSelectComponent
                        isAllRequired={true}
                        filterArray={
                          this.state.summaryReports.summaryDate
                        }
                        closeCustomPopup={this.onSummaryReportFilterClose}
                        config={this.state.summaryReports.id}
                        anchorEl={
                          this.state.summaryReports.anchorElReport
                        }
                        handleClick={this.onSummaryReportFilter}
                      />
                    </Suspense>
                  </div>
                  <Suspense fallback={<div></div>}>
                    <SummaryReportComponent
                      reportList={this.state.summaryReports.reportList}
                      downloadReports={this.downloadReportsHandler}
                      loader={this.state.loader}
                    />
                  </Suspense>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="task-graph utilization">
                  <div className="chart-header">
                    <h5 className="chart-title">
                      Rep Utilization by{" "}
                      {this.state.loggedInData.role.toLowerCase() ===
                        "division manager"
                        ? `Area`
                        : this.state.loggedInData.role.toLowerCase() === "admin"
                          ? this.state.loggedInData.companySize.toLowerCase() ===
                            "medium"
                            ? `Area`
                            : this.state.loggedInData.companySize.toLowerCase() ===
                              "small"
                              ? `Region`
                              : `Division`
                          : `Region`}
                    </h5>
                    <Suspense fallback={<div></div>}>
                      <div
                        className="new_custom_filter_div chart-filter"
                        onClick={this.onRepFilterOpen}
                      >
                        {this.state.summaryRepFilterName ? (
                          ""
                        ) : (
                          <label className="leftName"></label>
                        )}
                        <label
                          aria-label="summary_date_filter"
                          aria-controls="rep_filter_control"
                          area-aria-haspopup="true"
                          className="rightName"
                        >
                          {this.state.summaryRepFilterName
                            ? upperFirst(this.state.summaryRepFilterName)
                            : `This Week`}
                        </label>
                        <img className="carret" src={carret_down} alt="carret" />
                      </div>
                      <CustomSelectComponent
                        isAllRequired={true}
                        filterArray={this.state.summary_rep.summaryDate}
                        closeCustomPopup={this.onRepFilterClose}
                        config={this.state.summary_rep.id}
                        anchorEl={this.state.summary_rep.anchorElRepFilter}
                        handleClick={this.onRepFilter}
                      />
                    </Suspense>
                  </div>
                  <Suspense fallback={<div></div>}>
                    <CommonGraph
                      configuration={this.state.RepChartData.config}
                      plotOptions={this.state.RepChartData.plotOptions}
                      chartType="bar"
                      xAxis={this.state.RepChartData.xAxis}
                      yAxisTitle="Rep Utilization (hr)"
                      xAxisTitle={
                        this.state.loggedInData.role.toLowerCase() ===
                          "division manager"
                          ? `Area`
                          : this.state.loggedInData.role.toLowerCase() ===
                            "admin"
                            ? this.state.loggedInData.companySize.toLowerCase() ===
                              "medium"
                              ? `Area`
                              : this.state.loggedInData.companySize.toLowerCase() ===
                                "small"
                                ? `Region`
                                : `Division`
                            : `Region`
                      }
                      labelJustify="justify"
                      tooltip={
                        this.state.RepChartData.tooltip
                      }
                      xAxisLabelRotate={-60}
                      gridLineWidth={true}
                      seriesData={this.state.RepChartData.yAxis}
                    />
                  </Suspense>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="task-graph num-tasks">
                  <div className="chart-header">
                    <h5 className="chart-title">
                      Number of Task by{" "}
                      {this.state.loggedInData.role.toLowerCase() ===
                        "division manager"
                        ? `Area`
                        : this.state.loggedInData.role.toLowerCase() === "admin"
                          ? this.state.loggedInData.companySize.toLowerCase() ===
                            "medium"
                            ? `Area`
                            : this.state.loggedInData.companySize.toLowerCase() ===
                              "small"
                              ? `Region`
                              : `Division`
                          : `Region`}
                    </h5>
                    <div className="filter-wrapper">
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div chart-filter"
                          onClick={this.onSummaryTaskDateFilterOpen}
                        >
                          {this.state.summaryTaskMonthFilter ? (
                            ""
                          ) : (
                            <label className="leftName"></label>
                          )}
                          <label
                            aria-label="summary_date_filter"
                            aria-controls="summary_task_date_filter_control"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.summaryTaskMonthFilter
                              ? upperFirst(this.state.summaryTaskMonthFilter)
                              : `This Week`}
                          </label>
                          <img
                            className="carret"
                            src={carret_down}
                            alt="carret"
                          />
                        </div>
                        <CustomSelectComponent
                          isAllRequired={true}
                          filterArray={this.state.task_summary_month.summaryDate}
                          closeCustomPopup={this.onSummaryTaskDateFilterClose}
                          config={this.state.task_summary_month.id}
                          anchorEl={
                            this.state.task_summary_month.anchorElTaskDate
                          }
                          handleClick={this.onSummaryTaskDateFilter}
                        />
                      </Suspense>

                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div chart-filter"
                          onClick={this.openStatusFilterMenu}
                        >
                          {this.state.statusFilterName !== "" ? (
                            ""
                          ) : (
                            <label className="leftName">Status: </label>
                          )}
                          <label
                            aria-label="status_filter"
                            aria-controls="status_filter_select"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.statusFilterName === ""
                              ? `Completed`
                              : upperFirst(this.state.statusFilterName)}
                          </label>
                          <img
                            className="carret"
                            src={carret_down}
                            alt="carret"
                          />
                        </div>
                        <CustomSelectComponent
                          isAllRequired={true}
                          filterArray={this.state.statusConfig.statusOptions}
                          config={this.state.statusConfig.id}
                          anchorEl={this.state.statusConfig.anchorElStatus}
                          handleClick={this.handleStatusSelect}
                          closeCustomPopup={this.closeStatusFilterMenu}
                        />
                      </Suspense>
                    </div>
                  </div>
                  <Suspense fallback={<div></div>}>
                    <CommonBarGraphComponent
                      configuration={this.state.NoOfTaskData.config}
                      plotOptions={{
                        column: {
                          pointPadding: 0.2,
                          borderWidth: 0,
                          pointWidth: 25,
                          minPointLength: 2,
                          color:
                            this.state.statusFilterName === ""
                              ? "#67f3b1"
                              : this.state.statusFilterName.toLowerCase() ===
                                "unassigned"
                                ? "#617FFF"
                                : this.state.statusFilterName.toLowerCase() ===
                                  "pending"
                                  ? "#617FFF"
                                  : this.state.statusFilterName.toLowerCase() ===
                                    "assigned"
                                    ? "#303960"
                                    : this.state.statusFilterName.toLowerCase() ===
                                      "inprogress"
                                      ? "#69F3B1"
                                      : this.state.statusFilterName.toLowerCase() ===
                                        "cancelled"
                                        ? "#888888"
                                        : this.state.statusFilterName.toLowerCase() ===
                                          "uncovered"
                                          ? "#D24856"
                                          : "",
                        },
                      }}
                      chartType="column"
                      xAxis={this.state.NoOfTaskData.xAxis}
                      yAxisTitle="No of Tasks"
                      xAxisTitle={
                        this.state.loggedInData.role.toLowerCase() ===
                          "division manager"
                          ? `Area`
                          : this.state.loggedInData.role.toLowerCase() ===
                            "admin"
                            ? this.state.loggedInData.companySize.toLowerCase() ===
                              "medium"
                              ? `Area`
                              : this.state.loggedInData.companySize.toLowerCase() ===
                                "small"
                                ? `Region`
                                : `Division`
                            : `Region`
                      }
                      labelJustify="justify"
                      tooltip={
                        this.state.NoOfTaskData.tooltip
                      }
                      xAxisLabelRotate={0}
                      seriesData={this.state.NoOfTaskData.yAxis}
                    />
                  </Suspense>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="task-graph active-acc">
                  <div className="chart-header">
                    <h5 className="chart-title">Active Number of {this.state.cmsLabelChange.Account ? this.state.cmsLabelChange.Account : "Accounts"}</h5>
                    <Suspense fallback={<div></div>}>
                      <div
                        className="new_custom_filter_div chart-filter"
                        onClick={this.onSummaryAccountDateFilterOpen}
                      >
                        {this.state.summaryAccountMonthFilter ? (
                          ""
                        ) : (
                          <label className="leftName"></label>
                        )}
                        <label
                          aria-label="summary_date_filter"
                          aria-controls="summary_account_date_filter_control"
                          area-aria-haspopup="true"
                          className="rightName"
                        >
                          {this.state.summaryAccountMonthFilter
                            ? upperFirst(this.state.summaryAccountMonthFilter)
                            : `Last 30 Days`}
                        </label>
                        <img
                          className="carret"
                          src={carret_down}
                          alt="carret"
                        />
                      </div>
                      <CustomSelectComponent
                        isAllRequired={true}
                        filterArray={
                          this.state.account_summary_month.summaryDate
                        }
                        closeCustomPopup={this.onSummaryAccountDateFilterClose}
                        config={this.state.account_summary_month.id}
                        anchorEl={
                          this.state.account_summary_month.anchorElAccountDate
                        }
                        handleClick={this.onSummaryAccountDateFilter}
                      />
                    </Suspense>
                  </div>
                  <Suspense fallback={<div></div>}>
                    <CommonBarGraphComponent
                      configuration={this.state.ActiveNoOfAccount.config}
                      plotOptions={this.state.ActiveNoOfAccount.plotOptions}
                      chartType="column"
                      xAxis={this.state.ActiveNoOfAccount.xAxis}
                      yAxisTitle="No of Accounts"
                      xAxisTitle={
                        this.state.loggedInData.role.toLowerCase() ===
                          "division manager"
                          ? `Area`
                          : this.state.loggedInData.role.toLowerCase() ===
                            "admin"
                            ? this.state.loggedInData.companySize.toLowerCase() ===
                              "medium"
                              ? `Area`
                              : this.state.loggedInData.companySize.toLowerCase() ===
                                "small"
                                ? `Region`
                                : `Division`
                            : `Region`
                      }
                      labelJustify="justify"
                      tooltip={
                        this.state.ActiveNoOfAccount.tooltip
                      }
                      xAxisLabelRotate={-45}
                      seriesData={this.state.ActiveNoOfAccount.yAxis}
                    />
                  </Suspense>
                </div>
              </Grid>
              {this.state.loggedInData.role.toLowerCase() ===
                "area manager" &&
                <Grid item xs={12} md={6}>
                  <div className="task-graph active-acc">
                    <div className="chart-header">
                      <h5 className="chart-title">
                        Forecasted Revenue by{" "}
                        {this.state.loggedInData.role.toLowerCase() ===
                          "division manager"
                          ? `Area`
                          : this.state.loggedInData.role.toLowerCase() === "admin"
                            ? this.state.loggedInData.companySize.toLowerCase() ===
                              "medium"
                              ? `Area`
                              : this.state.loggedInData.companySize.toLowerCase() ===
                                "small"
                                ? `Region`
                                : `Division`
                            : `Region`}
                      </h5>
                      <Suspense fallback={<div></div>}>
                        <div
                          className="new_custom_filter_div chart-filter"
                          onClick={this.onForecastedSummaryDateFilterOpen}
                        >
                          {this.state.forecastedSummaryMonthFilter ? (
                            ""
                          ) : (
                            <label className="leftName"></label>
                          )}
                          <label
                            aria-label="summary_date_filter"
                            aria-controls="forecasted_date_filter_control"
                            area-aria-haspopup="true"
                            className="rightName"
                          >
                            {this.state.forecastedSummaryMonthFilter
                              ? upperFirst(this.state.forecastedSummaryMonthFilter)
                              : `This Week`}
                          </label>
                          <img
                            className="carret"
                            src={carret_down}
                            alt="carret"
                          />
                        </div>
                        <CustomSelectComponent
                          isAllRequired={true}
                          filterArray={this.state.forecasted_summary_month.summaryDate}
                          closeCustomPopup={this.onForecastedDateFilterClose}
                          config={this.state.forecasted_summary_month.id}
                          anchorEl={this.state.forecasted_summary_month.anchorElForecastedDate}
                          handleClick={this.onForecastedRevenueDateFilter}
                        />
                      </Suspense>
                    </div>
                    <Suspense fallback={<div></div>}>
                      <CommonBarGraphComponent
                        plotOptions={this.state.ForecastedRevenue.plotOptions}
                        chartType="column"
                        xAxis={this.state.ForecastedRevenue.xAxis}
                        yAxisTitle="Forecasted Revenue"
                        labelJustify="justify"
                        tooltip={
                          this.state.ForecastedRevenue.tooltip
                        }
                        xAxisLabelRotate={0}
                        seriesData={this.state.ForecastedRevenue.yAxis}
                        configuration={this.state.ForecastedRevenue.config}
                      />
                    </Suspense>
                  </div>
                </Grid>
              }
              <Grid item xs={12} md={6}>
                <div className="task-graph sales">
                  <div className="chart-header">
                    <h5 className="chart-title">
                      Product Sales by{" "}
                      {this.state.loggedInData.role.toLowerCase() ===
                        "division manager"
                        ? `Area`
                        : this.state.loggedInData.role.toLowerCase() === "admin"
                          ? this.state.loggedInData.companySize.toLowerCase() ===
                            "medium"
                            ? `Area`
                            : this.state.loggedInData.companySize.toLowerCase() ===
                              "small"
                              ? `Region`
                              : `Division`
                          : `Region`}
                    </h5>
                    <Suspense fallback={<div></div>}>
                      <div
                        className="new_custom_filter_div chart-filter"
                        onClick={this.onSummaryProductDateFilterOpen}
                      >
                        {this.state.summaryProductMonthFilter ? (
                          ""
                        ) : (
                            <label className="leftName"></label>
                          )}
                        <label
                          aria-label="summary_date_filter"
                          aria-controls="summary_product_date_filter_control"
                          area-aria-haspopup="true"
                          className="rightName"
                        >
                          {this.state.summaryProductMonthFilter
                            ? upperFirst(this.state.summaryProductMonthFilter)
                            : `This Week`}
                        </label>
                        <img
                          className="carret"
                          src={carret_down}
                          alt="carret"
                        />
                      </div>
                      <CustomSelectComponent
                        isAllRequired={true}
                        filterArray={
                          this.state.product_summary_month.summaryDate
                        }
                        closeCustomPopup={this.onSummaryProductDateFilterClose}
                        config={this.state.product_summary_month.id}
                        anchorEl={
                          this.state.product_summary_month.anchorElProductDate
                        }
                        handleClick={this.onSummaryProductDateFilter}
                      />
                    </Suspense>
                  </div>
                  <Suspense fallback={<div></div>}>
                    <CommonBarGraphComponent
                      configuration={this.state.productSalesData.config}
                      plotOptions={this.state.productSalesData.plotOptions}
                      chartType="column"
                      xAxis={this.state.productSalesData.xAxis}
                      yAxisTitle="Product Sales ($)"
                      xAxisTitle={
                        this.state.loggedInData.role.toLowerCase() ===
                          "division manager"
                          ? `Area`
                          : this.state.loggedInData.role.toLowerCase() ===
                            "admin"
                            ? this.state.loggedInData.companySize.toLowerCase() ===
                              "medium"
                              ? `Area`
                              : this.state.loggedInData.companySize.toLowerCase() ===
                                "small"
                                ? `Region`
                                : `Division`
                            : `Region`
                      }
                      labelJustify="justify"
                      tooltip={
                        this.state.productSalesData.tooltip
                      }
                      xAxisLabelRotate={-45}
                      seriesData={this.state.productSalesData.yAxis}
                    />
                  </Suspense>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="task-graph cost-revenue">
                  <div className="chart-header">
                    <h5 className="chart-title">
                       Expense vs Revenue by{" "}
                      {this.state.loggedInData.role.toLowerCase() ===
                        "division manager"
                        ? `Area`
                        : this.state.loggedInData.role.toLowerCase() === "admin"
                          ? this.state.loggedInData.companySize.toLowerCase() ===
                            "medium"
                            ? `Area`
                            : this.state.loggedInData.companySize.toLowerCase() ===
                              "small"
                              ? `Region`
                              : `Division`
                          : `Region`}
                    </h5>
                    <Suspense fallback={<div></div>}>
                      <div
                        className="new_custom_filter_div chart-filter"
                        onClick={this.onSummaryDateFilterOpen}
                      >
                        {this.state.summaryMonthFilter ? (
                          ""
                        ) : (
                            <label className="leftName"></label>
                          )}
                        <label
                          aria-label="summary_date_filter"
                          aria-controls="summary_date_filter_control"
                          area-aria-haspopup="true"
                          className="rightName"
                        >
                          {this.state.summaryMonthFilter
                            ? upperFirst(this.state.summaryMonthFilter)
                            : `This Week`}
                        </label>
                        <img
                          className="carret"
                          src={carret_down}
                          alt="carret"
                        />
                      </div>
                      <CustomSelectComponent
                        isAllRequired={true}
                        filterArray={this.state.summary_month.summaryDate}
                        closeCustomPopup={this.onSummaryDateFilterClose}
                        config={this.state.summary_month.id}
                        anchorEl={this.state.summary_month.anchorElDate}
                        handleClick={this.onSummaryDateFilter}
                      />
                    </Suspense>
                  </div>
                  <Suspense fallback={<div></div>}>
                    <CommonBarGraphComponent
                      plotOptions={this.state.regionChartData.plotOptions}
                      chartType="bar"
                      xAxis={this.state.regionChartData.xAxis}
                      yAxisTitle="Expense vs Revenue ($)"
                      xAxisTitle={
                        this.state.loggedInData.role.toLowerCase() ===
                          "division manager"
                          ? `Area`
                          : this.state.loggedInData.role.toLowerCase() ===
                            "admin"
                            ? this.state.loggedInData.companySize.toLowerCase() ===
                              "medium"
                              ? `Area`
                              : this.state.loggedInData.companySize.toLowerCase() ===
                                "small"
                                ? `Region`
                                : `Division`
                            : `Region`
                      }
                      labelJustify="justify"
                      tooltip={
                        this.state.regionChartData.tooltip
                      }
                      xAxisLabelRotate={-60}
                      seriesData={this.state.regionChartData.yAxis}
                      configuration={this.state.regionChartData.config}
                    />
                  </Suspense>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true
  };
};

export default connect(
  mapStateToProps
)(withRouter(AdminManagerDashboardComponent));
