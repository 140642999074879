import React from 'react';
import "date-fns";
import {ExpansionPanel,ExpansionPanelSummary,ExpansionPanelDetails,Typography,Button,TextField,Select,withStyles} from "@material-ui/core";
import {upperFirst} from "lodash";
import "./RecurringScheduleComponent.css"
const CssTextField = withStyles({
    root: {
      "& .MuiInput-underline:after": {
        borderBottomColor: "#14193A",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#E2E5F2",
          borderWidth: 1,
        },
  
        "&.Mui-focused fieldset": {
          borderColor: "#14193A",
          borderWidth: 1,
        },
      },
    },
  })(TextField, Select);
const RecurringScheduleComponent = (props) => {
    return (
        <ExpansionPanel className="account-expansion" defaultExpanded={true}>
        <span
          style={{ paddingLeft:"20px" }}
        >
          <span className='recurring-header'>
            Recurring schedule
          </span>
        </span>
        <ExpansionPanelDetails>
          <div className="info-card">
            <div className="info-card-detail info-card-detail-last">
              {(props.taskSchedules.isRecurring===true || props.isRecurring === true)?
              <>
              <div className="recurring-text-detail">
                {props.recurringText?upperFirst(props.recurringText):""}    
              </div>
            </>:"-"}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

    );
}
 
export default RecurringScheduleComponent;