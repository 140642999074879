import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import Loader from "react-loader-spinner";
import momentTimeZone from "moment-timezone";
import CustomPaginationComponent from "../../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import download_img from "../../../Assets/images/download_action.svg";
const BillingHistoryComponent = (props) => {
  return (
    <div className="billing_page billing_history">
      <h3 className="billing_page_title">Billing History</h3>
      <div className="billing_history_table">
        <TableContainer >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={'20%'}>Date</TableCell>
                <TableCell width={'20%'}>Invoice number</TableCell>
                <TableCell width={'15%'}>Billing Plan</TableCell>
                <TableCell width={'15%'}>Amount ( $ )</TableCell>
                <TableCell width={'10%'}>Status</TableCell>
                {/* <TableCell width={'10%'}>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody className="tablecell_container">
              {props.loading ? (
                <TableCell colSpan={9} style={{ textAlign: "center" }}>
                  <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={50}
                    width={50}
                    visible={props.loading}
                  />
                </TableCell>
              ) : !props.pageOfItems ||
                props.pageOfItems.length === 0 ? (
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <div className="no-data-found">
                      No data Found
                      </div>
                    </TableCell>
                  ) : (
                    (props.rowsPerPage > 0
                      ? props.pageOfItems.slice()
                      : props.pageOfItems
                    ).map((row, index) => (
                      <TableRow>
                        <TableCell onClick={() => props.onClickHandler (10,row._id,)} ><><div className="billing_history_date">{ row.startDate ? momentTimeZone(row.startDate)
                          .tz(props.timeZoneName)
                          .format("MMM DD YYYY") :"--"}</div></></TableCell>
                        <TableCell onClick={() => props.onClickHandler (10,row._id,)} ><><div className="billing_history_number">{row.invoiceNumber ? row.invoiceNumber:"--" }</div></></TableCell>
                        <TableCell onClick={() => props.onClickHandler (10,row._id,)}><><div className="billing_history_plan">{row.billingCycle ? row.billingCycle.charAt(0).toUpperCase() + row.billingCycle.slice(1) : '--'}</div></></TableCell>
                        <TableCell onClick={() => props.onClickHandler (10,row._id,)}><><div className="billing_history_amount"> {row.amount ? row.amount.toFixed(2) :"--"}</div></></TableCell>
                        <TableCell onClick={() => props.onClickHandler (10,row._id,)}><><div className="billing_history_status"><p class={row.status === 1 ? "billing_history_paid" : row.status === 2 ? "billing_history_unpaid" :"billing_history_paid"}> {row.status === 1 ? "Paid" : row.status === 2 ? "Unpaid" :"--"}</p></div></></TableCell>
                        {/* <TableCell><><div className="billing_history_action"><img style={{ cursor: "pointer" }} src={download_img} onClick={() =>
                          props.downlod_pdf(row)
                        } /></div></></TableCell> */}
                      </TableRow>
                    ))
                  )}
            </TableBody>
            <TableFooter>
              {props.pageOfItems &&
                props.pageOfItems.length !== 0 ?
                <TableRow>
                  <td colSpan={20} className="pagination_container">
                    <CustomPaginationComponent
                      count={props.pager.endPage}
                      rowsPerPage={props.rowsPerPage}
                      page={props.pageNumber}
                      onPageChange={props.handleCustomPaginagtionChange}
                      onChangeRowsPerPage={props.handleChangeRowsPerPage}
                    />
                  </td>
                </TableRow> : null}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default BillingHistoryComponent;
