import * as actionTypes from "../Actions/ActionTypes";
import { UpdateObjects } from "../../SharedComponents/UpdateObject";

/**
 * initializing taskPagination states
 */
const initialState = {
    isLoading: false,
    error: null,
    pager: {},
    pageOfItems: []
};

/**
 * updating initial state of table
 */
const taskPaginationRequest = (state, action) => {
    return UpdateObjects(state, {
        isLoading: true,
        error: null,
        pager: {},
        pageOfItems: []
    });
};
 
const taskPaginationSuccess = (state, action) => {
    return UpdateObjects(state, {
        isLoading: false,
        error: null,
        pager: action.pager,
        pageOfItems: action.pageOfItems
    });
};

const taskPaginationFailure = (state, action) => {
    return UpdateObjects(state, {
        isLoading: false,
        error: action.error,
        pager: {},
        pageOfItems: []
    });
};

/**
 *  Table reducer 
 */
const TaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TASKPAGINATION_REQUEST: return taskPaginationRequest(state, action);
        case actionTypes.TASKPAGINATION_SUCCESS: return taskPaginationSuccess(state, action);
        case actionTypes.TASKPAGINATION_FAILURE: return taskPaginationFailure(state, action);
        default: return state;
    }
}


export default TaskReducer;