import * as actionType from "./ActionTypes";
import { AxiosInstance } from "../../Utilities";
import { AWS_AxiosInstance } from "../../AWS_AxiosInstance";
/**
 * pagination actions
 */
const paginationRequest = () => {
  return {
    type: actionType.PAGINATION_REQUEST,
  };
};
const paginationSuccess = (pager, pageOfItems,responseData) => {
  return {
    type: actionType.PAGINATION_SUCCESS,
    pager: pager,
    pageOfItems: pageOfItems,
    responseData: responseData,
  };
};
const paginationFailure = (error) => {
  return {
    type: actionType.PAGINATION_FAILURE,
    error: error,
  };
};
/**
 * For Company listing
 */
export const companyPagination = (pageNo, pageSize, status, company, search) => {
  const pageData = {
    page: pageNo,
    pageSize: pageSize,
    deleted: status === "Deleted" ? "true" : "",
    companySize: company,
    searchText:search
  };
  let type = "/company/list";
  return companyapis(pageData, type);
};
export const oncompanyFilter = (value) => {
  let type = "/company/list";
  return companyapis(value, type);
};
const companyapis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.admin));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};

/**
 * For New Company listing
 */
export const newcompanyPagination = (pageNo, pageSize) => {
  const pageData = {
    page: pageNo,
    pageSize: pageSize
  };
  let type = "/pendingCompany";
  return Newcompanyapis(pageData, type);
};
export const onNewcompanyFilter = (value) => {
  let type = "/pendingCompany";
  return Newcompanyapis(value, type);
};
const Newcompanyapis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};

/**
 * For Subscriptions
 */
export const SubscPagination = (pageNo, pageSize) => {
  const pageData = {
    page: pageNo,
    pageSize: pageSize
  };
  let type = "/subscriptions";
  return Subscriptionsapis(pageData, type);
};
export const SubscFilter = (value) => {
  let type = "/subscriptions";
  return Subscriptionsapis(value, type);
};
const Subscriptionsapis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};

/**
 * For billing history
 */
export const historyPagination = (pageNo, pageSize ,subscribeId) => {
  const pageData = {
    page: pageNo,
    pageSize: pageSize,
    subscriptionId: subscribeId,
  };
  let type = "/billing/history";
  return historyptionsapis(pageData, type);
};
export const historyFilter = (value) => {
  let type = "/";
  return historyptionsapis(value, type);
};
const historyptionsapis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6019:'subscription',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};

/**
 * For Payment Status
 */
export const paym_statusPagination = (pageNo, pageSize) => {
  const pageData = {
    page: pageNo,
    pageSize: pageSize,
  };
  let type = "/billing/paymentStatus";
  return paym_statusptionsapis(pageData, type);
};
export const paym_statusFilter = (value) => {
  let type = "/billing/paymentStatus";
  return paym_statusptionsapis(value, type);
};
const paym_statusptionsapis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6019:'subscription',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.data.status === 200 || response.data.status === "200") {
          dispatch(paginationSuccess(response.data.pager, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};

/**
 * For pending Account list listing
 */
export const pendingAccountPagination = (pageNo, pageSize) => {
  const pageData = {
    page: pageNo,
    pageSize: pageSize
  };
  let type = "/team_invites";
  return pendingAccount(pageData, type);
};
export const onpendingAccountFilter = (value) => {
  let type = "/team_invites";
  return pendingAccount(value, type);
};
const pendingAccount = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6004:'company',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};

/**
 * dispatching pagination actions
 */
export const userpagination = (pageNo, pageSize, companyId, role, userId, territory, search,  company, division, area, region, status, filterRole) => {
  const pageData = {
    page: +pageNo,
    pagesize: +pageSize,
    companyId: companyId,
    role: role,
    userId: userId,
    status:status,
    territory: territory !== "All" ? territory : '',
    searchText: search ? search : "",
    company: company !== "All" ? company : '',
    division: division !== "All" ? division : '',
    area: area !== "All" ? area : '',
    status: status,
    region: region !== "All" ? region : "",
    filterRole: filterRole
  };
  let type = "/users";
  return apis(pageData, type);
};
/**
 * For Account
 */
export const accountpagination = (pageNo, pageSize, territory, state, search) => {
  const pageData = {
    page: pageNo,
    pageSize: pageSize,
    territory: territory !== "All" ? territory : '',
    state: state !== "All" ? state : '',
    searchText: search ? search : ""
  };
  let type = "/account/list";
  return accountapis(pageData, type);
};
export const onaccountfilter = (value) => {
  let type = "/account/list";
  return accountapis(value, type);
};
const accountapis = (value, type) => {
  console.log(value)
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};
/**
 * For Pending Registrations
 */
export const pendingpagination = (pageNo, pageSize) => {
  const pageData = {
    nextpage: pageNo,
    pagesize: pageSize
  };
  let type = "/pendingAccount";
  return pendingapis(pageData, type);
};
export const pendingfilter = (value) => {
  let type = "/pendingAccount";
  return pendingapis(value, type);
};
const pendingapis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6005:'account',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pagination, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};
/**
 * For Services
 */
export const servicepagination = (pageNo, pageSize) => {
  const pageData = {
    nextpage: pageNo,
    pagesize: pageSize
  };
  let type = "/getService";
  return serviceapis(pageData, type,"post");
};
export const onservicefilter = (value) => {
  console.log(value)
  let type = "/getService";
  return serviceapis(value, type, "post");
};
export const onDeleteService = (value) => {
  console.log(value)
  let type = `/deleteService/${value.deleteBy}`;
  return serviceapis(value, type, "delete");
};
const serviceapis = (value, type, method) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    /* // This code is using separate axios instance for the purpose of calling lambda services. Commenting this code for time being as CICD deployment to lambda is not happening.
    AWS_AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ?6007
    :process.env.REACT_APP_NODE_ENV === "stage"
    ?'/'
    :'service', "application/x-www-form-urlencoded; charset=UTF-8") */
    
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6007 : 'service', "application/x-www-form-urlencoded; charset=UTF-8")
      [method](`${type}`, value)
      .then((response) => {
        if (response.status === 200) {
          console.log("response.data0000---",response.data)
          dispatch(paginationSuccess(response.data.pagination, 
            response.data.data,response.data.status));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};
/**
 * For EventType List Add Update 
 */
export const eventgination = () => {
  const pageData = {
  };
  let type = "/get_eventType";
  return eventapis(pageData, type);
};
export const eventfilter = (value) => {
  let type = "/get_eventType";
  return eventapis(value, type);
};
export const eventadd = (value) => {
  let type = "/add_eventType";
  return eventapis(value, type);
};
export const eventedit = (value) => {
  let type = "/update_eventType";
  return eventapis(value, type);
};
export const deleteevent = (value) => {
  let type = "/delete_eventType";
  return eventapis(value, type);
};
const eventapis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6013:'event',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pagination, 
            response.data.data,response.data.status));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};
/**
 * For Product
 */
export const productpagination = (pageNo, pageSize, category) => {
  const pageData = {
    nextpage: pageNo,
    pagesize: pageSize,
    categoryId: category !== "All" ? category : '',
    // divisionId: division ? division : ''
  };
  let type = "/getProduct";
  return productapis(pageData, type);
};
export const productfilter = (value) => {
  let type = "/getProduct";
  return productapis(value, type);
};
export const AddProduct = (value) => {
  let type = "/product";
  return productapis(value, type);
};
export const updateProduct = (value) => {
  let type = "/update_product";
  return productapis(value, type);
};
export const deleteProduct = (value) => {
  let type = "/delete_product";
  return productapis(value, type);
};
const productapis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?8011:'product',"application/x-www-form-urlencoded; charset=UTF-8")
      .get(`${type}`, {
        params:value
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pagination, response.data.data,response.data.status));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};
const apis = (value, type) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6006:'user',"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};

/**
 * calling pagination search api and updating pageination states
 */
export const onuserfilter = (searchValue) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6006:'user',"application/x-www-form-urlencoded; charset=UTF-8")
      .post("/users",  searchValue )
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            paginationSuccess(response.data.pager, response.data.data)
          );
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(paginationFailure(error));
      });
  };
};
