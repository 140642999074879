import React from "react";
import notification_img from "../../../Assets/images/notification-icon.png";
import notification_blue_img from "../../../Assets/images/notification-icon-blue.png";
import passoword_img from "../../../Assets/images/password-icon.png";
import passoword_blue_img from "../../../Assets/images/password-icon-blue.png";
import profile_img from "../../../Assets/images/manage-profile.png";
import profile_blue_img from "../../../Assets/images/manage-profile-blue.png";
import TimeZoneBlue from "../../../Assets/images/time-zone.svg";
import TimeZoneGrey from "../../../Assets/images/time-zone-grey.svg";
import Integrations from "../../../Assets/images/link.png";
import IntegrationsBlue from "../../../Assets/images/link-blue.png";
import cms from "../../../Assets/images/Iconly-Bold-Paper-Grey.svg";
import cmsBlue from "../../../Assets/images/Iconly-Bold-Paper-Blue.svg";

const SideBarComponent = (props) => {
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  console.log("userdata", userdata, userdata.role);
  return (
    <ul className="billing_list">
      <li className="billing_list_item" onClick={() => props.changeSideBar(1)}>
        <a className={`configure_btn ${props.compNo === 1 ? "active" : ""}`}>
          <span className="manage-profile-images">
            <img src={profile_img} className="grey-img" alt="profile-img" />{" "}
            <img
              src={profile_blue_img}
              className="blue-img"
              alt="profile-img"
            />{" "}
          </span>
          Manage Profile
        </a>
      </li>

      <li className="billing_list_item" onClick={() => props.changeSideBar(2)}>
        <a className={`configure_btn ${props.compNo === 2 ? "active" : ""}`}>
          <span className="passoword-images">
            <img src={passoword_img} className="grey-img" alt="passoword-img" />{" "}
            <img
              src={passoword_blue_img}
              className="blue-img"
              alt="passoword-img"
            />{" "}
          </span>
          Password & Security
        </a>
      </li>
      <li className="billing_list_item" onClick={() => props.changeSideBar(3)}>
        <a className={`configure_btn ${props.compNo === 3 ? "active" : ""}`}>
          <span className="notication-images">
            <img
              src={notification_img}
              className="grey-img"
              alt="notification-img"
            />{" "}
            <img
              src={notification_blue_img}
              className="blue-img"
              alt="notification-img"
            />{" "}
          </span>
          Notifications
        </a>
      </li>
      <li className="billing_list_item" onClick={() => props.changeSideBar(4)}>
        <a className={`configure_btn ${props.compNo === 4 ? "active" : ""}`}>
          <span className="notication-images">
            <img
              src={TimeZoneGrey}
              className="grey-img"
              alt="notification-img"
            />{" "}
            <img
              src={TimeZoneBlue}
              className="blue-img"
              alt="notification-img"
            />{" "}
          </span>
          Time Zone
        </a>
      </li>
      {/* calenderIntergration */}
      <li className="billing_list_item" onClick={() => props.changeSideBar(5)}>
        <a className={`configure_btn ${props.compNo === 5 ? "active" : ""}`}>
          <span className="notication-images">
            <img
              src={Integrations}
              className="grey-img"
              alt="notification-img"
            />{" "}
            <img
              src={IntegrationsBlue}
              className="blue-img"
              alt="notification-img"
            />{" "}
          </span>
          Integrations
        </a>
      </li>
      {/* CMS */}
      {userdata && userdata.role && userdata.role.toLowerCase() === "admin" && (
        <li
          className="billing_list_item"
          onClick={() => props.changeSideBar(6)}
        >
          <a className={`configure_btn ${props.compNo === 6 ? "active" : ""}`}>
            <span className="notication-images">
              <img src={cms} className="grey-img" alt="notification-img" />{" "}
              <img src={cmsBlue} className="blue-img" alt="notification-img" />{" "}
            </span>
            Customization
          </a>
        </li>
      )}
    </ul>
  );
};

export default SideBarComponent;
