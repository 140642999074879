import React from 'react';
import {
  Box,
  Typography,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  withStyles,
  Button,
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import Loader from "react-loader-spinner";
import { DatePicker } from "antd";
import { upperFirst } from "lodash";
import CustomSelectComponent from "../../../SharedComponents/CustomSelectComponent/CustomSelectComponent";
import carret_down from "../../../Assets/images/carret_down.svg";
import searchIcon from "../../../Assets/images/search_icon.svg";
import momentTimeZone from "moment-timezone";
import CustomPaginationComponent from "../../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
const { RangePicker } = DatePicker;
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: "",
        borderWidth: 0,
      },
    },
  },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);
const BillingPaymentStatus = (props) => {
  return (
    <div className="billing_page pending_status">
      <Box component="span" className="company-comp">
        <div className="header-add-btn">
          <Typography variant="h5" className="tableTitle" component="div">
            Payment Status
            </Typography>
            <div  className="Comp-Table Acc-Table reports_down_tab export_btn">
            <Button
                className="report_header_btn_down green-btn"
                variant="contained"
                color="default"
                 onClick={props.ExportPaymentStatus}
              >
                <GetAppIcon />
                Export
              </Button>
              </div>
        </div>
        <div style={{ maxWidth: "100%" }} className="Comp-Table Acc-Table">
          <div className="filter-search">
            <div className="new_custom_filters">
              <div
                className="new_custom_filter_div"
                onClick={props.paym_StatusOpenStatusFilterMenu}
              >
                {props.paym_status_StatusFilterName !== "" ? (
                  ""
                ) : (
                    <label className="leftName">Billing Status: </label>
                  )}
                <label
                  aria-label="status_filter"
                  aria-controls="status_filter_select"
                  area-aria-haspopup="true"
                  className="rightName"
                >
                  {props.paym_status_StatusFilterName === ""
                    ? `All`
                    : upperFirst(props.paym_status_StatusFilterName)}
                </label>
                <img className="carret" src={carret_down} alt="carret" />
              </div>
              <CustomSelectComponent
                filterArray={[
                  { _id: "paid", name: "Paid" },
                  { _id: "pending", name: "Pending" },
                  { _id: "fail", name: "Failed" },
                ]}
                config={props.paym_StatusConfig.config}
                anchorEl={props.paym_StatusConfig.anchorElStatus}
                handleClick={props.paym_StatusHandleStatusSelect}
                closeCustomPopup={props.paym_StatusCloseStatusFilterMenu}
              />

              <div
                className="new_custom_filter_div"
                onClick={props.paym_StatusOpenTypeFilterMenu}
              >
                {props.pym_StatusTypeFilterName !== "" ? (
                  ""
                ) : (
                    <label className="leftName">Type: </label>
                  )}
                <label
                  aria-label="status_filter"
                  aria-controls="status_filter_select"
                  area-aria-haspopup="true"
                  className="rightName"
                >
                  {props.pym_StatusTypeFilterName === ""
                    ? `All`
                    : upperFirst(props.pym_StatusTypeFilterName)}
                </label>
                <img className="carret" src={carret_down} alt="carret" />
              </div>
              <CustomSelectComponent
                filterArray={[
                  { _id: "organization", name: "Organization" },
                  { _id: "team", name: "Team" },
                  { _id: "individual", name: "Individual" },
                ]}
                config={props.paym_StatusTypeConfig.config}
                anchorEl={props.paym_StatusTypeConfig.anchorElStatus}
                handleClick={props.paym_StatusHandleTypeSelect}
                closeCustomPopup={props.paym_StatusCloseTypeFilterMenu}
              />


              <div
                className="new_custom_filter_div"
                onClick={props.paym_StatusOpenBillingCycleFilterMenu}
              >
                {props.pym_StatusBillingCycleFilterName !== "" ? (
                  ""
                ) : (
                    <label className="leftName">Billing Cycle: </label>
                  )}
                <label
                  aria-label="status_filter"
                  aria-controls="status_filter_select"
                  area-aria-haspopup="true"
                  className="rightName"
                >
                  {props.pym_StatusBillingCycleFilterName === ""
                    ? `All`
                    : upperFirst(props.pym_StatusBillingCycleFilterName)}
                </label>
                <img className="carret" src={carret_down} alt="carret" />
              </div>
              <CustomSelectComponent
                filterArray={[
                  { _id: "monthly", name: "Monthly" },
                  { _id: "annual", name: "Annual" },
                ]}
                config={props.paym_StatusBillingCycleConfig.config}
                anchorEl={props.paym_StatusBillingCycleConfig.anchorElStatus}
                handleClick={props.paym_StatusHandleBillingCycleSelect}
                closeCustomPopup={props.paym_StatusCloseBillingCycleFilterMenu}
              />
              <div className="new_custom_filter_div">
                <label className="leftName">Payment Date: </label>
                <RangePicker
                  className="filter_date_range "
                  name="datepicker"
                  format="MM-DD-YYYY"
                  separator="-"
                  placeholder={["MM-DD-YYYY", "MM-DD-YYYY"]}
                  suffixIcon={
                    <img
                      className="carret"
                      alt="carret"
                      src={carret_down}
                    />
                  }
                  onChange={(date, dateString) =>
                    props.onDateChanage(dateString)
                  }
                />
              </div>
            </div>
            <CssTextField
              id="outlined-basic"
              name="pay_statusSearchFilter"
              variant="outlined"
              type="text"
              // value={props.searchFilter}
              placeholder="Search Here"
              onChange={props.pay_statusFilter}
              style={{
                color: "#303960",
                fontFamily: "Roboto",
                backgroundColor: "#F9F9F9",
                opacity: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={searchIcon} alt="search-icon" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="table_container">
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "1%" }}>#</TableCell>
                    <TableCell style={{ width: "20%" }}>NAME</TableCell>
                    <TableCell style={{ width: "18%" }}>TYPE</TableCell>
                    <TableCell style={{ width: "14%" }}>BILLING CYCLE</TableCell>
                    <TableCell style={{ width: "5%" }}>STATUS</TableCell>
                    <TableCell style={{ width: "15%" }}>BILL AMOUNT ( $ )</TableCell>
                    <TableCell style={{ width: "15%" }}>PAYMENT DATE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.loading ? (
                    <StyledTableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={props.loading}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ) : !props.pageOfItems ||
                    props.pageOfItems.length === 0 ? (
                        <StyledTableRow>
                          <TableCell colSpan={9} style={{ textAlign: "center" }}>
                            No data Found
                        </TableCell>
                        </StyledTableRow>
                      ) : (
                        (props.rowsPerPage > 0
                          ? props.pageOfItems.slice()
                          : props.pageOfItems
                        ).map((row, index) => (
                          <StyledTableRow key={row._id}>
                            <TableCell>
                              {(props.pager.currentPage - 1) *
                                props.rowsPerPage +
                                (index + 1)}
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip {...props.tooltipprops} title={row.name
                                ? row.name.charAt(0).toUpperCase() +
                                row.name.slice(1)
                                : "-"} arrow>
                                <span> {row.name ? row.name.charAt(0).toUpperCase() + row.name.slice(1) : "--"}</span>
                              </LightTooltip>
                            </TableCell>

                            <TableCell
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip {...props.tooltipprops} title={row ? upperFirst(row.type) : "-"} arrow>
                                <span> {row ? upperFirst(row.type) : "--"}</span>
                              </LightTooltip>
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}  
                            >
                              <LightTooltip {...props.tooltipprops} title={row.billingCycle ? row.billingCycle.charAt(0).toUpperCase() + row.billingCycle.slice(1) : "-"} arrow>
                                <span>{row.billingCycle ? row.billingCycle.charAt(0).toUpperCase() + row.billingCycle.slice(1) : "--"}</span>
                              </LightTooltip>
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip {...props.tooltipprops} title={row.status
                                ? upperFirst(row.status)
                                : "-"} arrow>
                                <span>{row.status ? upperFirst(row.status) : "--"}</span>
                              </LightTooltip>
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "0",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <LightTooltip {...props.tooltipprops} title={row.billAmount ? row.billAmount.toFixed(2) : "--"} arrow>
                                <span>{row.billAmount ? row.billAmount.toFixed(2) : "--"}</span>
                              </LightTooltip>
                            </TableCell>

                            <TableCell>
                            <LightTooltip {...props.tooltipprops} title={row.startDate ? momentTimeZone(row.startDate)
                                .tz(props.timeZoneName)
                                .format("MM-DD-YYYY hh:mm A") : "--"} arrow>
                                <span>{row.startDate ? momentTimeZone(row.startDate)
                                .tz(props.timeZoneName)
                                .format("MM-DD-YYYY") : "--"}</span>
                              </LightTooltip>
                            </TableCell>
                          </StyledTableRow>
                        ))
                      )}
                </TableBody>
                <TableFooter>
                  {props.pageOfItems &&
                    props.pageOfItems.length !== 0 ?
                    <TableRow>
                      <td colSpan={20} className="pagination_container">
                        <CustomPaginationComponent
                          count={props.pager.endPage}
                          rowsPerPage={props.rowsPerPage}
                          page={props.pageNumber}
                          onPageChange={props.handleCustomPaginagtionChange}
                          onChangeRowsPerPage={props.handleChangeRowsPerPage}
                        />
                      </td>
                    </TableRow> : null}
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default BillingPaymentStatus;