import React from 'react';
import { Grid,Button } from "@material-ui/core";
import profile_img from "../../../Assets/images/dummy-profile-image.png";
import credit_card_img from "../../../Assets/images/credit_card_icon.svg";
import momentTimeZone from "moment-timezone";
import {startCase} from 'lodash';
const BillingOverviewComponent = (props) => {
  return (
    <div className="billing_page billing_overview">
      <h3 className="billing_page_title">Billing Overview</h3>
      <div className="billing_overview_sections">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <div className="billing_overview_section">
              <h5 className="overview_section_heading">Bill Estimate</h5>
              <div className="bill_estimate_container">
                <h4>USD {props.estimateData.estimatedAmt? parseFloat(props.estimateData.estimatedAmt).toFixed(2): "0.0"} <span>(Exclusive of Taxes)</span></h4>
                <p>Next Charge: {props.estimateData.endDate ? momentTimeZone(props.estimateData.endDate)
                  .tz(props.timeZoneName)
                  .format("MMMM DD, YYYY"):"--"}</p>
              </div>
              <div className="billing_estimate">
              <Button
                className="view_full_estimate_btn logout-button"
                onClick={props.onViewEstimate}
              >
                View full estimate
              </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <div className="billing_overview_section">
              <h5 className="overview_section_heading">Billing Details</h5>
              <div className="bill_details_container">
                <h5 className="bill_period_heading">Billing period</h5>
                <h5 className="bill_period">{props.billingDetail.billingCycle === 0 ? 'Monthly' : props.billingDetail.billingCycle === 1 ? 'Annual' :"--"}</h5>
              </div>
              <div className="bill_details_card_container">
                <div className="bill_details_image_section">
                  <img src={credit_card_img} alt="profile-image" className="bill_contact_card_image" />
                </div>
                <div className="bill_details_section">
                  <h4 className="bill_details_card_heading">Credit card</h4>
                  <h6 className="bill_details_card_number"><span>....  ....  ....  </span>{props.cardDetail.lastDigit ? props.cardDetail.lastDigit : "--"}</h6>
                  <h6 className="bill_details_card_holder">{props.cardDetail.cardHolder}</h6>
                  <h6 className="bill_details_card_expire">Expires: {props.cardDetail.expire ? props.cardDetail.expire : "--"} </h6>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <div className="billing_overview_section">
              <h5 className="overview_section_heading">Billing History</h5>
              <ul className="billing_history_list">
                {props.paymentHistory.length === 0 ?
                  <li style={{ textAlign: "center" }}>
                    No Data
                 </li>
                  :
                  <>
                    {props.paymentHistory.map((row, index) => (
                      <li key={row._id}>
                        <p>{momentTimeZone(row.startDate)
                          .tz(props.timeZoneName)
                          .format("MMMM DD YYYY")}</p>
                        <p>$ {row.amount}</p>
                      </li>
                    ))}
                  </>}
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <div className="billing_overview_section">
              <h5 className="overview_section_heading">Billing Contacts</h5>
              <div className="bill_contact_container">
                {/* <div className="bill_contact_image_section">
                  <img src={profile_img} alt="profile-image" className="bill_contact_profile" />
                </div> */}
                <div className="bill_contact_content_section">
                  <h4 className="bill_contact_username">{props.contact.firstName ? startCase(props.contact.firstName) + " " + startCase(props.contact.lastName) : "--"}</h4>
                  <h6 className="bill_contact_email"> {props.contact ? props.contact.email : "--"}</h6>
                </div>
              </div>
              <div className="bill_contact_copies">
                <h5 className="send_copies_heading">Send copies of invoices to</h5>
                <h6 className="bill_contact_email">{
                props.over_invoiceCC && props.over_invoiceCC.length>0 ? 
                props.over_invoiceCC.map((item,index)=>(
                  index === props.over_invoiceCC.length-1?item:item+", "
                ))
                
                : "--"}</h6>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default BillingOverviewComponent;