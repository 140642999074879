
import { notification } from "antd";
import "./NotificationComponent.css";
export const NotificationComponent = (props) => {
  notification[props.type]({
    message: props.message,
    icon: props.icon,
    placement: 'bottomRight',
    duration: props.duration,
    top: props.top,
    className: props.className,
  });
};
