import React from "react";
import {
  TextField,
  Select,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
const SuperadminDepartmentComponent = (props) => {
  return (
    <div>
      {props.departments.map((val, idx) => {
        var deptName = `deptName-${idx}`;
        return (
          <div key={val.index}>
            <ExpansionPanel
              style={{
                backgroundColor: "rgba(0, 0, 0, .03)",
                marginTop: "15px",
              }}
              className="department-expansion"
              name="departmentvalues"
              defaultExpanded={true}
            >
              <ExpansionPanelSummary
                style={{ borderBottom: "1px solid rgba(0, 0, 0, .125)" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="department-expansion-text">
                  Departments
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ flexDirection: "column" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <label>Department Name</label>
                  </div>
                  <div>
                    {idx === 0 ? (
                      <span></span>
                    ) : (
                      <RemoveCircleOutlineIcon
                        onClick={() => props.deleteRow(idx)}
                        style={{ color: "red", fontSize: "18px" }}
                      />
                    )}
                  </div>
                </div>
                <CssTextField
                  inputProps={{
                    name: "deptName",
                    "data-id": idx,
                  }}
                  id={deptName}
                  autoFocus
                  name="departmentName"
                  variant="outlined"
                  margin="dense"
                  type="text"
                  fullWidth
                  placeholder="Enter Department"
                  onChange={props.onChangeHandler}
                />
                 <span className="error">{props.departments[idx]["depError"]} </span>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        );
      })}
    </div>
  );
};

export default SuperadminDepartmentComponent;
