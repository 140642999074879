import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  withStyles,
  Switch,
  FormControlLabel,
  TextField,
  Grid,
  Button,
  RadioGroup,
  Radio,
  FormControl,
  Label

} from "@material-ui/core";
import { Scrollbars } from 'react-custom-scrollbars';
import { Transition, TransitionGrow } from "../../../../SharedComponents/CommonUtilities/CommonUtilities";
import closeBtn from "../../../../Assets/images/close_btn.svg";
import warning_img from "../../../../Assets/images/Icon ionic-ios-warning-yellow.svg";
import "./RecurringComponent.css"
import { Select } from "antd";
const { Option } = Select;
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);


function ReassignRepRecurringComponent(props) {
  const [item, setItem] = useState("series")
  const handleChange = (event) => {
    event.preventDefault()
    setItem(event.target.value)
  }
  return (
    <Dialog
      open={props.data}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={600}
    >
      <DialogTitle id="form-dialog-title">
        <IconButton
          className="closeBtn"
          onClick={props.onReassignPopupClose}
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <img src={closeBtn} alt="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent className="recurring_popup">
          <DialogContent>
            {/* <form> */}
            <Grid container spacing={2} className="register-form-top">
              <Grid item xs={12} sm={6} className="cancel-task-img">
                <img className="waring-img" src={warning_img} alt="warring" />


              </Grid>

              <Grid item xs={12} sm={6} className="register-form-right">
                <label className='cancel-task-label'>
                  Reassign Representative
                </label>
                <div className='cancel-task-msg'>
                  This is one appointment in a series What do you want to open?
                </div>
                <div>
                  <FormControl>

                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      defaultValue="series"
                      onChange={handleChange}
                    >
                      <FormControlLabel value="recurrenceOnce" control={<Radio />} label="Just this once" />
                      <FormControlLabel value="series" control={<Radio />} label="The entire series" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <div className="cancel-task_action">
              <Button
                className="cancel_btn white-btn"
                onClick={props.onReassignPopupClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="ok_btn green-btn"
                type="submit"
                variant="contained"
                onClick={() => props.reassignTaskHandler(item , (props?.schedulerType == "recurring" && props.taskData))}
              >
                Submit
              </Button>
            </div>
            {/* </form> */}
          </DialogContent>
      </DialogContent>

    </Dialog>
  )
}

export default ReassignRepRecurringComponent