import React from "react";
import closeBtn from "../../../Assets/images/close_btn.svg";
import IconButton from "@material-ui/core/IconButton";
import {
  Button,
  Dialog,
  DialogContent,
  MenuItem,
  DialogTitle,
  Select,
  FormControl,
} from "@material-ui/core";
import { Transition } from "../../../SharedComponents/CommonUtilities/CommonUtilities";

const ChangeParentModel = (props) => {
  return (
    <div>
      <div>
        <Dialog
          {...props.modelData}
          open={props.openModel}
          onClose={props.onClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog add_account_dialog"
          TransitionComponent={Transition}
          transitionDuration={600}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <span>Change Parent</span>
            <IconButton
              onClick={props.onClose}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn} alt="close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <form>
              {props.companySize && props.companySize === "large" ? (
                <>
                  <label className="label bl-label">Division</label>

                  <FormControl variant="outlined" fullWidth margin="dense">
                    <Select
                      margin="dense"
                      className="customSelect"
                      placeholder="Division"
                      labelId="demo-simple-select-outlined-label"
                      name="Ptdivision"
                      id="demo-simple-select-outlined"
                      fullWidth
                      defaultValue={props.modelData.divId}
                      onChange={props.onChangeHandler}
                    >
                      <MenuItem value="">None</MenuItem>
                      {props.modelData.divisionFilter.map((val) => (
                        <MenuItem value={val._id}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className="error">
                    {props.changeParentError.division !== ""?`* ${props.changeParentError.division}`:""}
                  </span>
                </>
              ) : (
                ""
              )}
              {(props.modelData.childType === "region" ||
                props.modelData.childType === "territory") &&
              props.companySize !== "small" ? (
                <>
                  <label className="label">Area</label>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <Select
                      margin="dense"
                      className="customSelect"
                      placeholder="Area"
                      labelId="demo-simple-select-outlined-label"
                      name="Ptarea"
                      id="demo-simple-select-outlined"
                      fullWidth
                      defaultValue={props.modelData.areaId}
                      onChange={props.onChangeHandler}
                    >
                      <MenuItem value="">None</MenuItem>
                      {props.modelData.areaFilter.map((val) => (
                        <MenuItem value={val._id}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className="error">
                    {props.changeParentError.area !== ""?`* ${props.changeParentError.area}`:""}
                  </span>
                </>
              ) : (
                ""
              )}
              {props.modelData.childType === "territory" ? (
                <>
                  <label className="label">Region</label>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <Select
                      margin="dense"
                      className="customSelect"
                      placeholder="Region"
                      labelId="demo-simple-select-outlined-label"
                      name="Ptregion"
                      id="demo-simple-select-outlined"
                      fullWidth
                      defaultValue={props.modelData.regId}
                      onChange={props.onChangeHandler}
                    >
                      <MenuItem value="">None</MenuItem>
                      {props.modelData.regionFilter.map((val) => (
                        <MenuItem value={val._id}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className="error">
                    {props.changeParentError.region !== ""?`* ${props.changeParentError.region}`:""}
                  </span>
                </>
              ) : (
                ""
              )}
              <div className="add_company_action">
                <Button
                  className="cancel_btn white-btn"
                  onClick={props.onClose}
                  variant="outlined"
                >
                  Cancel
                </Button>    
                <Button
                  className="ok_btn grey-btn"
                  onClick={props.onChangeparent}
                  variant="contained"
                  // disabled={props.inChangeParentValid ? false : true}
                >
                  Submit
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ChangeParentModel;
