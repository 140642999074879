import React, { useState } from 'react';
import { Dialog, Button } from '@material-ui/core';
import reminderImg from '../../../Assets/images/reminder-bell.svg';
import IconButton from "@material-ui/core/IconButton";
import closeBtn from "../../../Assets/images/close_btn.svg";
import "./RemainderModal.css"

const ReminderModal = (props) => {
  const handleData = props.data;
  const initialData = props?.data[0]
 
  return (
    <>
          <div className='dialog' >
            <Dialog {...props} style={{ border: "none" }}>
              <div className='dialog-box'>
                <IconButton
                  onClick={()=>props.handleClose(handleData)}
                  className="closeBtn reminderBtn"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <img src={closeBtn} alt="close btn" />
                </IconButton>
                <img src={reminderImg} alt="" />
                <h1>Reminder !!</h1>
                <p>{initialData && initialData.type === "1" ? 'You are yet to start this' : 'You have not yet stopped this'}</p>
            <h5>{initialData && initialData.taskName ? initialData.taskName : ""}</h5>
                <Button
                  className="cancel_btn"
              onClick={() => props.editHandler(initialData, handleData)}
                  variant="outlined"
                >
                  Edit
                </Button>
                {initialData && initialData.type === '1' ? <Button
                  className="ok_btn-green"
                  variant="contained"
              onClick={() => props.reminderHandler("start", initialData, handleData)}> Start</Button>
                  :
                  <Button
                    className="ok_btn-red"
                    variant="contained"
                onClick={() => props.reminderHandler("stop", initialData, handleData)}> Stop</Button>}
              </div>
            </Dialog>
          </div >
  
    </>
  )
}


export default ReminderModal;