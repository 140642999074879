import React, { Component } from "react";
import { Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { AxiosInstance, checkTimeZone } from "../../Utilities";
import { Alert } from "@material-ui/lab";
import "./ManageAccountComponent.css";
import { withStyles } from "@material-ui/core/styles";
import close_icon_red from "../../Assets/img/close_icon_red.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import Loader from "react-loader-spinner";
import { GoogleMapsAPI } from "../../client-config";
import AutoComplete from "react-google-autocomplete";
import {
  getGooglePlaceObjectValues,
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../SharedComponents/CommonUtilities/CommonUtilities";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import ProfileComponent from "./ProfileComponent/ProfileComponent";
import SideBarComponent from "./SideBarComponent/SideBarComponent";
import PasswordAndSecurityComponent from "./PasswordAndSecurityComponent/PasswordAndSecurityComponent";
import NotificationSettingComponent from "./NotificationSettingComponent/NotificationSettingComponent";
import CalendarIntegrationComponent from "./CalendarIntegration/CalendarIntegrationComponent";
import CMSComponent from './CMSComponent/CMSComponent';
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import { passwordReg } from "../../SharedComponents/Validation/formValidation";
import momentTimeZone from 'moment-timezone';
import TimeZoneComponent from "./TimeZoneComponent/TimeZoneComponent";

class ManageAccountComponent extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";      
    var tzNames = momentTimeZone.tz.names();
    this.state = {
      compNo: 1,
      tzNameArray:tzNames,
      tzSelectName:"",
      tzList:tzNames,
      role: userData.role,
      profileData: {},
      territorye: "",
      loading: true,
      image: "",
      mnumber: false,
      eprofile: false,
      formError: "",
      editphone: "",
      formErrAddress: "",
      eventLocation: [],
      lat: "",
      lng: "",
      address: "",
      location: "",
      hidePro: "",
      hidePhone: "showPhone",
      hideAddress: "showAddress",
      disableAdress: true,
      isAddress: true,
      phone: "",
      profilePic: "",
      file: null,
      responseMsg: "",
      alertType: "",
      invalidImage: "",
      isValid: !this.props.isValid,
      profilePathh: "",
      loading: false,

      notificationSetting: {},
      calendarIntegration: {},

      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      oldHidden: true,
      newHidden: true,
      confirmHidden: true,
      passwordSettingSubmit: false,
      changePasswordError: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      integrations: [],
    };
    this.signOutIntegrations = this.signOutIntegrations.bind(this)
  }

  UNSAFE_componentWillMount=()=>{
    this.getTimeZones();
  }
  componentDidMount = () => {
    this.setState({ isValid: false });
    AxiosInstance(
      (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user",
      "application/x-www-form-urlencoded; charset=UTF-8"
    )
      .get("/profile")
      .then((resp) => {
        if (resp.data.status == "200") {
          this.setState({
            profileData: resp.data.data,
            phone: resp.data.data.phone,
            profilePath: resp.data.data.profilePath,
            territory: resp.data.data.territory,
            address: resp.data.data.address,
            location: resp.data.data.location,
            lat: resp.data.data.location.lat,
            lng: resp.data.data.location.lng,
          });
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let integrations = params.get('inetegrations');
      let status=params.get('status');
      if(integrations && integrations === 'yes'){
        this.setState({
          compNo:5
        });
        this.fetchIntegrationsData()
      }
      if(status&& status==='success'){
        var propsErr = {
          message: "Success",
          icon: <img src={iconNotificationSuccess} alt="success" />,
          type: "success",
          placement: "topRight",
          duration: 2,
          top: 200,
          className: "task_notification success-notify",
        };
        NotificationComponent(propsErr);
      }
  };

  getTimeZones=()=>{
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user","application/json")
    .get("/employee/timezone")
    .then(resp=>{
      var response = resp && resp.data;
      if(response && (response.status === "200" || response.status === 200)){
        this.setState({
          tzSelectName:response.data.timezone,
         
        });
        localStorage.setItem("timeZone",response.data.timezone);
        checkTimeZone()
      }else if(response && (response.status === "400" || response.status === 400)){

      }
    }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  onUpdateTimeZones=(value)=>{
    let data={
      timezone:value
    }
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user","application/json")
    .put("/employee/timezone",data)
    .then(resp=>{
      var response = resp && resp.data;
      if(response && (response.status === "200" || response.status === 200)){
        this.getTimeZones();
        this.setState({
          tzSelectName:value
        })
        var propsErr = {
          message: response.msg,
          icon: <img src={iconNotificationSuccess} alt="success" />,
          type: "success",
          placement: "topRight",
          duration: 2,
          top: 200,
          className: "task_notification success-notify",
        };
        NotificationComponent(propsErr);
        this.getTimeZones();
        // checkTimeZone();
        // window.location.href = "/profile";
      }else if(response && (response.status === "400" || response.status === 400)){

      }
    }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  onProSubmit = (e) => {
    const { profilePic } = this.state;
    let data = "";
    if (profilePic !== null) {
      data = new FormData();
      data.append("profilePhoto", profilePic);
    }
    if (this.state.invalidImage === "") {
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .put("/profile", data)
        .then((resp) => {
          if (resp.data.status === 200) {
            this.setState({
              eprofile: false,
              responseMsg: "Updated successfully",
              alertType: "success",
              profilePath: resp.data.profilePath,
            });
            var userData = localStorage.getItem("userdata")
              ? JSON.parse(localStorage.getItem("userdata"))
              : "";
            userData.profilePath = resp.data.profilePath;
            var jsonData = JSON.stringify(userData);
            localStorage.setItem("userdata", jsonData);
            setTimeout(() => {
              this.setState({ responseMsg: "" });
            }, 2000);
          } else {
            this.setState({ responseMsg: resp.data.msg, alertType: "error" });
          }
        }).catch(err => {
          var error = err.response;
          if (error && (error.status === '403' || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500)
          } else if (error && (error.status === '500' || error.status === 500)) {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        });
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };
  uplodProf = (e) => {
    this.setState({ loading: true });
    const proimage = e.target.files[0];

    if (!proimage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({
        responseMsg: "Please select valid image in jpg, jpeg, png or gif format.",
        alertType: "error",
        loading: false
      });
      return false;
    } else {
      this.setState(
        { profilePic: proimage, responseMsg: "", alertType: "" },
        this.onProSubmit
      );
    }
  };
  handlepPhoneChange = (e) => {
    this.setState({ editphone: e.target.value });
    var phoneno = /^(\+\d{1}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (e.target.value.length === 0) {
      this.setState({ formError: "*Please enter phone no." });
    } else if (e.target.value.match(phoneno)) {
      this.setState({ formError: "" });
    } else {
      this.setState({
        formError: "*Please enter the valid phone number e.g.: 800-555-5555 ",
      });
      return false;
    }
  };
  handleAddressChange = (e) => {
    this.setState({ address: e.target.value, disableAdress: false });
    if (e.target.value.length === 0) {
      this.setState({ formErrAddress: "*Please enter address." });
    } else if (e.target.value) {
      this.setState({ formErrAddress: "" });
      return false;
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    var profile = {
      phone: this.state.editphone,
    };
    if (this.state.formError === "") {
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .put("/profile", profile)
        .then((resp) => {
          if (resp.data.status === 200) {
            this.setState({
              formError: "",
              responseMsg: "Updated successfully",
              alertType: "success",
              phone: this.state.editphone,
              mnumber: false,
            });
            setTimeout(() => {
              this.setState({ responseMsg: "" });
            }, 2000);
          } else {
            this.setState({
              formError: "",
              responseMsg: resp.data.msg,
              alertType: "error",
            });
          }
        }).catch(err => {
          var error = err.response;
          if (error && (error.status === '403' || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500)
          } else if (error && (error.status === '500' || error.status === 500)) {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        });
    }
  };
  onAddressSubmit = (e) => {
    e.preventDefault();
    var profileAddress = {
      address: this.state.address,
      lat: this.state.lat,
      lng: this.state.lng,
    };
    const { formErrAddress } = this.state;
    if (this.state.formErrAddress === "") {
      AxiosInstance(
        (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user",
        "application/x-www-form-urlencoded; charset=UTF-8"
      )
        .put("/profile", profileAddress)
        .then((resp) => {
          if (resp.data.status === 200) {
            this.setState({
              formErrAddress: "",
              responseMsg: "Updated successfully",
              alertType: "success",
              address: this.state.address,
              isAddress: true,
              disableAdress: true,
            });
            setTimeout(() => {
              this.setState({ responseMsg: "" });
            }, 2000);
          } else {
            this.setState({
              formErrAddress,
              responseMsg: resp.data.msg,
              alertType: "error",
            });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            let propserror = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="success" />,
              type: "error",
              placement: "topRight",
              duration: 3,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propserror);
          }
        });
    }
  };
  onGooglePlaceSelect = (place) => {
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    this.setState({
      address: formattedPlace.address,
      eventLocation: [formattedPlace.lat, formattedPlace.lng],
      disableAdress: false,
      lat: formattedPlace.lat,
      lng: formattedPlace.lng,
    });
  };
  hideMobEdit = () => {
    this.setState({ isValid: false, mnumber: false, formError: "" });
  };
  showMobEdit = () => {
    this.setState({
      responseMsg: "",
      alertType: "",
      invalidImage: "",
      editphone: this.state.phone,
      mnumber: true,
    });
  };
  hideProEdit = () => {
    this.setState({ invalidImage: "" });
  };
  showProEdit = () => {
    this.setState({
      responseMsg: "",
      alertType: "",
      eprofile: true,
    });
  };
  hideAddress = () => {
    this.setState({ isValid: false, formErrAddress: "", isAddress: true });
    this.componentDidMount();
  };
  showAddress = () => {
    this.setState({
      isAddress: false,
      address: this.state.address,
    });
  };
  abc = () => {
    alert();
  };

  showButton = (showvalue) => {
    switch (showvalue) {
      case "hidePro":
        this.setState({ hidePro: "avatar_input_file_note" });
        break;
      case "hidePhone":
        this.setState({ hidePhone: "" });
        break;
      case "hideAddress":
        this.setState({ hideAddress: "" });
        break;
      default:
        break;
    }
  };
  hideButton = (hidevalue) => {
    switch (hidevalue) {
      case "hidePro":
        this.setState({ hidePro: "" });
        break;
      case "hidePhone":
        this.setState({ hidePhone: "showPhone" });
        break;
      case "hideAddress":
        this.setState({ hideAddress: "showAddress" });
      default:
        break;
    }
  };

  /**
  * Benakesh code start
  */
  changeSideBar = (id) => {
    this.setState({
      compNo: id,
    }, () => {
      console.log("Sidebar change.....",this.state);
      if (this.state.compNo === 3) {
        this.fetchNotificationData();
      } else if (this.state.compNo === 2) {
        const { changePasswordError } = this.state;
        changePasswordError.oldPassword = "";
        changePasswordError.newPassword = "";
        changePasswordError.confirmPassword = "";
        this.setState({
          changePasswordError,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
          oldHidden: true,
          newHidden: true,
          confirmHidden: true,
          passwordSettingSubmit: false
        })
      } else if(this.state.compNo === 5){
        this.fetchIntegrationsData()
      }
    });
  };

  fetchNotificationData = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
      .get("/employee/notificationSettings")
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          this.setState({
            notificationSetting: response.data
          })
        }
        // else if(response && (response.status === "400" || response.status === 400)){

        // }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  fetchIntegrationsData = () =>{
    console.log("Coming fetch data");
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
      .get("/user/integrations")
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          this.setState({
            integrations: response.data
          })
          // console.log("response....", response.data);
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }
  onSettingChange = (value, type, to) => {
    const elementIndex = this.state.notificationSetting[type].findIndex(element => element.name === value.name);
    let newArray = [...this.state.notificationSetting[type]];
    newArray[elementIndex] = { ...newArray[elementIndex], [to]: !newArray[elementIndex][to] }
    const { notificationSetting } = this.state;
    notificationSetting[type] = newArray;
    this.setState({
      // notificationSetting
    }, () => {
      var data = {};
      if (type === 'allNotification' && to === "notification") {
        data.name = "allNotification";
        data.notification = newArray[elementIndex][to];
      } else if (type === 'allNotification' && to === "email") {
        data.name = "allNotification";
        data.email = newArray[elementIndex][to];
      } else {
        data = newArray[elementIndex];
      }

      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
        .put("/employee/notificationSettings", data)
        .then(resp => {
          var response = resp && resp.data;
          if (response && (response.status === "200" || response.status === 200)) {
            this.fetchNotificationData();
          } else if (response && (response.status === "400" || response.status === 400)) {
            var propsErr = {
              message: response.msg,
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        }).catch(err => {
          var error = err.response;
          if (error && (error.status === '403' || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500)
          } else if (error && (error.status === '500' || error.status === 500)) {
            var propsErr = {
              message: "Something went wrong",
              icon: <img src={iconNotificationError} alt="error" />,
              type: "error",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification error-notify",
            };
            NotificationComponent(propsErr);
          }
        });
    });

  }
  calendarIntegration = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
    .get("/outlook/signing")
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          var auth=response.authUrl;
          window.location.href = `${auth}`;
        }
        // else if(response && (response.status === "400" || response.status === 400)){

        // }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
    }
  GoogleCalendarIntegration = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
    .get("/google/signing")
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          var auth=response.authUrl;
          window.location.href = `${auth}`;
        }
        // else if(response && (response.status === "400" || response.status === 400)){

        // }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
  }

  onIconChange = (field) => {
    this.setState({
      [field]: !this.state[field]
    })
  }

  onPasswordChange = (e) => {
    const { name, value } = e.target;
    const { changePasswordError } = this.state;

    switch (name) {
      case 'oldPassword':
        value.trim().length > 0 && value.trim() !== ""
          ? changePasswordError.oldPassword = ""
          : changePasswordError.oldPassword = "Please enter current password";
        break;
      case 'newPassword':
        value.trim() === ""
          ? changePasswordError.newPassword = "Please enter new password"
          : !passwordReg.test(value.trim())
            ? changePasswordError.newPassword = "Please enter valid password"
            : changePasswordError.newPassword = "";
        break;
      case 'confirmPassword':
        value.trim() === ""
          ? changePasswordError.confirmPassword = "Please confirm the password"
          : !passwordReg.test(value.trim())
            ? changePasswordError.confirmPassword = "Please enter valid password"
            : changePasswordError.confirmPassword = "";
        break;
      default:
        break;
    }
    this.setState({
      changePasswordError,
      [name]: value
    })
  }
  onPasswordCancelHandler = (e) => {
    e.preventDefault();
    const { changePasswordError } = this.state;
    changePasswordError.oldPassword = "";
    changePasswordError.newPassword = "";
    changePasswordError.confirmPassword = "";
    this.setState({
      changePasswordError,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      oldHidden: true,
      newHidden: true,
      confirmHidden: true,
    })
  }
  cancelBtn=()=>{
    this.setState({compNo:1})
  }
  onPasswordSubmitHandler = (e) => {
    e.preventDefault();
    const { changePasswordError } = this.state;
    if (this.state.oldPassword.trim() === "") {
      changePasswordError.oldPassword = "Please enter current password";
    } else {
      changePasswordError.oldPassword = "";
    }
    if (this.state.newPassword.trim() === "") {
      changePasswordError.newPassword = "Please enter new password";
    } else {
      changePasswordError.newPassword = "";
    }
    if (this.state.confirmPassword.trim() === "") {
      changePasswordError.confirmPassword = "Please confirm the password";
    } else if (this.state.newPassword.trim() !== this.state.confirmPassword.trim()) {
      changePasswordError.confirmPassword = "Confirm password should be same as new password";
    } else {
      changePasswordError.confirmPassword = "";
    }
    const isEmpty = Object.values(changePasswordError).every(
      (x) => x === null || x === ""
    );
    this.setState({
      changePasswordError
    }, () => {
      if (isEmpty) {
        this.setState({
          passwordSettingSubmit: true
        })
        let data = {
          oldPassword: this.state.oldPassword,
          newPassword: this.state.newPassword,
          confirmPassword: this.state.confirmPassword
        }
        AxiosInstance(
          (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user",
          "application/json"
        )
          .put("/employee/changePassword", data)
          .then((resp) => {
            var response = resp && resp.data;
            if (response && (response.status === 200 || response.status === "200")) {
              let propserror = {
                message: response.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification success-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
                oldHidden: true,
                newHidden: true,
                confirmHidden: true,
                passwordSettingSubmit: false
              })
            } else if (response && (response.status === 400 || response.status === "400")) {
              let propserror = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                passwordSettingSubmit: false
              })
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else if (
              error &&
              (error.status === "500" || error.status === 500)
            ) {
              let propserror = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="success" />,
                type: "error",
                placement: "topRight",
                duration: 3,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propserror);
              this.setState({
                passwordSettingSubmit: false
              })
            }
          });
      }
    })
  }

  onTzFocus=()=>{
     this.setState({
      tzList:this.state.tzNameArray
    })
  }
  handleTzSearch=(value)=>{
    const {tzNameArray}=this.state;
    let tzNewArray = tzNameArray.filter((item) => {
      return item.toLowerCase().includes(value.toLowerCase());
    });
    if (tzNewArray.length > 0) {
      this.setState({
        tzList:tzNewArray
      });
    } else {
      this.setState({
        tzList:tzNameArray
      });
    }
  }

  onChangeTz=(value)=>{
    this.onUpdateTimeZones(value);
  }
  /**
   * Benakesh code end
   */
  /* Vignesh code starts here */
  signOutIntegrations(type){
    this.setState({},()=>{
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 6006 : "user", "application/json")
      .put("/user/integrations/signOut",{type})
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === "200" || response.status === 200)) {
          // this.setState({
          //   integrations: response.data
          // })
          console.log("coming here....927");
          this.setState({},()=>{
            this.fetchIntegrationsData();
          })
          console.log("coming here....934");
          // console.log("response....", response.data);
        }
      }).catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else if (error && (error.status === '500' || error.status === 500)) {
          var propsErr = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "topRight",
            duration: 2,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propsErr);
        }
      });
    })
    
  }
  render() {
    let { loading } = this.state;
    let show = "";
    let hidden = "";
    if (this.state.mnumber === true) {
      hidden = "hidden";
    } else if (this.state.mnumber === false) {
      show = "hidden";
    }
    let proshow = "";
    let prohidden = "";
    if (this.state.eprofile === true) {
      prohidden = "hidden";
    } else if (this.state.eprofile === false) {
      proshow = "hidden";
    }
    return (
      <div className="body-bg-color billing-page settings-page">
        <HeaderComponent profile={this.state.profilePath} />
        <div className="billing_container settings_container">
          <div className="billing_sidebar settings_sidebar">
            <h5 className="sidebar_heading">Account Settings</h5>
            <SideBarComponent
              compNo={this.state.compNo}
              changeSideBar={this.changeSideBar}
            />
          </div>
          <div className="billing_page settings_page">
            {this.state.compNo === 1 ? (
              <ProfileComponent
                hidePro={this.state.hidePro}
                prohidden={prohidden}
                profilePath={this.state.profilePath}
                profileData={this.state.profileData}
                invalidImage={this.state.invalidImage}
                uplodProf={this.uplodProf}
                hideButton={this.hideButton}
                showButton={this.showButton}
                showMobEdit={this.showMobEdit}
                onSubmit={this.onSubmit}
                showAddress={this.showAddress}
                handlepPhoneChange={this.handlepPhoneChange}
                hideMobEdit={this.hideMobEdit}
                onGooglePlaceSelect={this.onGooglePlaceSelect}
                handleAddressChange={this.handleAddressChange}
                onAddressSubmit={this.onAddressSubmit}
                // hideAddress={this.hideAddress}
                loading={this.state.loading}
                role={this.state.role}
                formErrAddress={this.state.formErrAddress}
                disableAdress={this.state.disableAdress}
                isAddress={this.state.isAddress}
                address={this.state.address}
                hideAddress={this.hideAddress}
                formError={this.state.formError}
                editphone={this.state.editphone}
                phone={this.state.phone}
                hidePhone={this.state.hidePhone}
                hidden={hidden}
                show={show}
                responseMsg={this.state.responseMsg}
                alertType={this.state.alertType}
              />
            ) : this.state.compNo === 2 ? (
              <PasswordAndSecurityComponent
                oldHidden={this.state.oldHidden}
                newHidden={this.state.newHidden}
                confirmHidden={this.state.confirmHidden}

                oldPassword={this.state.oldPassword}
                newPassword={this.state.newPassword}
                confirmPassword={this.state.confirmPassword}
                changePasswordError={this.state.changePasswordError}
                passwordSettingSubmit={this.state.passwordSettingSubmit}
                onIconChange={this.onIconChange}
                onPasswordChange={this.onPasswordChange}
                onPasswordSubmitHandler={this.onPasswordSubmitHandler}
                onPasswordCancelHandler={this.onPasswordCancelHandler}
                cancelBtn={this.cancelBtn}
              />
            ) : this.state.compNo === 3 ? (
              <NotificationSettingComponent
                notificationSetting={this.state.notificationSetting}
                onSettingChange={this.onSettingChange}
              />
            ) : this.state.compNo === 4
            ?(<TimeZoneComponent
              tzNameArray={this.state.tzList}
              tzSelectName={this.state.tzSelectName}
              handleStateSearch={this.handleTzSearch}
              onChangeState={this.onChangeTz}
              onTzFocus={this.onTzFocus}
            />)  
             : this.state.compNo === 5 ? (
              <CalendarIntegrationComponent
               CalendarIntegration={this.calendarIntegration}
               GoogleCalendarIntegration={this.GoogleCalendarIntegration}
               integrations={this.state.integrations}
               signOut={this.signOutIntegrations}
              />): this.state.compNo === 6 ? (
                <CMSComponent
                />):("")}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ManageAccountComponent);
