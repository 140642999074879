import React, { Component } from "react";
import {
  Container,
  Grid,
  Card,
  TextField,
  InputLabel,
  Button,
} from "@material-ui/core";
import {Tooltip} from 'antd';
import logo2 from "../../Assets/images/logo_icon_signup.svg";
import dropzoneLogo from "../../Assets/images/dropzone-picture.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import eyeIcon from "../../Assets/images/eye-icon.svg";
import eyeCloseIcon from "../../Assets/images/eye-close-icon.svg";
import { formValidation } from "../../SharedComponents/Validation/formValidation";
import { withRouter } from "react-router-dom";
import { AxiosInstance } from "../../Utilities";
import Dropzone from "react-dropzone";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import upload_img from "../../Assets/images/upload_img.png";
import "./SignupComponent.css";
import PageNotFoundComponent from "../PageNotFoundComponent/PageNotFoundComponent";

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField);

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "",
      userPassword: "",
      imageFiles: [],
      img_upload:"",
      userLogo: "",
      notValidUser:false,
      formError: {
        userPassword: "",
        //imageFiles:'',
      },
      responseMsg: "",
      alertType: "",
      hidden: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.fileHandle = this.fileHandle.bind(this);
  }

  onhandledrop(imageFiles) {
    this.setState({
      imageFiles: imageFiles[0],
    });
  }

  fileHandle = (event) => {
    this.setState({
      imageFiles: event.target.files[0],
      img_upload:"img_upload"
    });
  };
  UNSAFE_componentWillMount(){
    const { params } = this.props.match;
    let { id } = params;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6001:'authentication', "application/json")
      .get(`/employee/${id}`)
      .then((resp) => {
        var response = resp && resp.data;
        if (response && (response.status === 200 || response.status === "200")) {
          this.setState({ employeeName: response.data.name });
          if(response?.data?.status== "Activated"){
            window.location.href = "/"
          }
        }else if(response && (response.status === 400 || response.status === "400" || response.status === 404 || response.status === "404")){
          this.setState({
            notValidUser:true
          })
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  handleChange(event) {
    event.preventDefault();
    const { formError } = this.state;
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === "") {
      formError.userPassword = "Please enter your Password";
    } else {
      formError.userPassword = "";
    }
  }
  goTologin() {
    window.location.href = "/";
  }
  checksignup = async (e) => {
    e.preventDefault();
    const { formError, userPassword } = this.state;
    if (userPassword === "") {
      formError.userPassword = "Please enter your Password";
    } else {
      formError.userPassword = "";
    }
    this.setState({ formError, loginError: "" });
    if (formValidation(this.state)) {
     
      let FileData = new FormData();
      FileData.append("profilePhoto", this.state.imageFiles);
      FileData.append("password", this.state.userPassword);
      let data = {};
      data.password = this.state.userPassword;
      data.profilePhoto = FileData.entries();
      const { params } = this.props.match;
      let { id } = params;
      AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6001:'authentication', "application/json")
        .put(`employee/${id}`, FileData)
        .then((resp) => {
          if (resp.data.status === "204") {
            this.setState(
              { responseMsg: resp.data.msg, alertType: "success" },
              () => {
                setTimeout(() => {
                  window.location.href = "/";
                }, 2000);
              }
            );
          } else {
            this.setState({ responseMsg: resp.data.msg, alertType: "error" });
          }
        })
        .catch((err) => {
          var error = err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          } else {
            console.log("Internal Server Error");
          }
        });
    }
  };
  render() {
    let signupForm = (
      <div className="signup-body-bg-color signup-section">
        <Container fixed className="signup-body">
          <Card className="signup-root">
            <Grid container>
              <Grid item xs={12} className="card-header">
                <div className="header-content">
                  <div className="signup-logo">
                    <img src={logo2} alt="Sales-Icon" height="auto"></img>
                  </div>
                  <p className="signup-name">Hi {this.state.employeeName}</p>
                  <h3 className="pragraph-text">
                    Welcome to SalesC2. Fill in your details to continue to CRM
                  </h3>
                </div>
              </Grid>
            </Grid>
            <Grid container className="header-content">
              <Grid item xs={12}>
                <form onSubmit={this.checksignup} autoComplete="off">
                  {this.state.responseMsg !== "" ? (
                    <Alert severity={this.state.alertType}>
                      {this.state.responseMsg}
                    </Alert>
                  ) : null}
                  <InputLabel className="loginLabel">
                    Upload Profile Image
                  </InputLabel>
                  <Dropzone
                    onDrop={this.onhandledrop.bind(this)}
                    accept={"image/*"}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section  className= {"MuiDropzoneArea-root " + this.state.img_upload } >
                        <div {...getRootProps()} className="dropzone-icon" tabindex="-1">
                          <input
                            {...getInputProps()}
                            onChange={this.fileHandle}
                          />
                          <img src={dropzoneLogo} alt="dropzone logo"/>
                          <p className="dropzone-paragraph">
                            Drag your image here or <span>Browse</span>
                          </p>
                          <p className="dropzone-inst">
                          <img src={upload_img} alt="upload_img"></img>
                           { this.state.imageFiles.length===0 ? "Supports: JPG,JPEG,PNG & SVG" : this.state.imageFiles.name} 
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {/* {this.state.formError.imageFiles ? <span style={{color:'red',fontSize:'16px'}}>{this.state.formError.imageFiles}</span> : ''} */}
                  <InputLabel className="loginLabel">
                    Create Password <span className="labelAsterisk"> *</span>
                  </InputLabel>
                  <CssTextField
                    id="outlined-basic-password"
                    type={this.state.hidden ? "password" : "text"}
                    name="userPassword"
                    placeholder="Enter password"
                    className="inputHeight mar-bot-10"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.userPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ hidden: !this.state.hidden })
                          }
                        >
                          <Tooltip title={this.state.hidden?"Show Password":"Hide Password"} placement="bottom">
                            <img src={this.state.hidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                    <span className="error">
                      {this.state.formError.userPassword?`*${this.state.formError.userPassword}`:""}
                    </span>
                  <div className="buttonGroup">
                    <Button
                      className="cancelBtn cancel-button white-btn"
                      onClick={this.goTologin}
                      variant="contained"
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="signupBtn success-button green-btn"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Join SalesC2
                    </Button>
                  </div>
                </form>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </div>
    );
     if(this.state.notValidUser){
       return <PageNotFoundComponent/>
     }else{
        return <div>{signupForm}</div>;
     }
  }
}

export default withRouter(Signup);
