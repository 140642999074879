import React, { Suspense } from "react";
import "../DashboardComponent.css";
import carret_down from "../../../Assets/images/bold-arrow-down.svg";
import { upperFirst } from 'lodash';
import { Popover, Tooltip, Empty } from "antd";
import momentTimeZone from 'moment-timezone';
import Pagination from '@material-ui/lab/Pagination';
import warning_img from "../../../Assets/images/Icon ionic-ios-warning.svg";
import iconDashHospital from "../../../Assets/images/icon-dashboard-hospital.svg";
import recurring_acceptance from "../../../Assets/images/recurring_acceptance.svg";
import recurring_pending from "../../../Assets/images/recurring_pending.svg";
import iconDashLocation from "../../../Assets/images/icon-dashboard-location.svg";
import userIcon from "../../../Assets/images/user-icon.png";

var CustomSelectComponent = React.lazy(() => import("../../../SharedComponents/CustomSelectComponent/CustomSelectComponent"));

const PendingAcceptanceComponent = (props) => {
  var weeklyRecurrence = [];
  return (
    <React.Fragment>
      <div className="todays_tasks_grid_heading">
        <div className="todays_tasks_grid_heading_one">
          <Suspense fallback={<div></div>}>
            <div className="todays_tasks_grid_heading_two">
              <div className="new_custom_filter_div"
                onClick={props.pendingTypeSelectSelectPopupOpen}>
                <label
                  aria-label="pendings_filter"
                  aria-controls="pendings_filter_control"
                  area-aria-haspopup="true"
                  className="tableTitle cursor_pointer"
                >
                  {props.pendingTypeList.pendingType ? upperFirst(props.pendingTypeList.pendingType) : `All`}
                </label>
                <img className="bold_carret" src={carret_down} alt="carret" />
              </div>
              <CustomSelectComponent
                filterArray={props.pendingTypeList.menuItems}
                closeCustomPopup={props.pendingTypeSelectSelectPopupClose}
                config={{ id: "pendings_filter_control" }}
                anchorEl={props.pendingTypeList.anchorElPendingTypes}
                handleClick={props.handlePendingChange}
                isAllRequired={true}
              />
            </div>
          </Suspense>
        </div>
        <Suspense fallback={<div></div>}>
          <div className="todays_tasks_grid_heading_two">
            <div className="new_custom_filter_div"
              onClick={props.typeSelectSelectPopupOpen}>
              <label
                aria-label="events_filter"
                aria-controls="events_filter_control"
                area-aria-haspopup="true"
                className="rightName"
              >
                {props.list.type ? upperFirst(props.list.type) : `All`}
              </label>
              <img className="carret" src={carret_down} alt="carret" />
            </div>
            <CustomSelectComponent
              filterArray={props.list.menuItems}
              closeCustomPopup={props.typeSelectSelectPopupClose}
              config={{ id: "events_filter_control" }}
              anchorEl={props.list.anchorElevents}
              handleClick={props.handleEventTypeClick}
              isAllRequired={true}
            />
          </div>
        </Suspense>
      </div>
      <div className="todays_tasks_grid_list">
        {props.pendingAcceptanceList && props.pendingAcceptanceList.length > 0 ?
          props.pendingAcceptanceList.map((item) => (
            item.type.toLowerCase() === 'task' ?
              <div className="dashboard_task_card" key={item._id}>
                {window && window.innerWidth > 600 ? <div className="dashboard_task_wrapper">
                  <div className="dashboard_task_card_item dashboard_task_card_item_one">
                    <a
                      className="dashboard_task_card_item_name"
                      onClick={() => props.detailViewHandler(item._id, 'task')}
                    >
                      <Tooltip title="View Detail" placement="bottom">
                        {upperFirst(item.title ? item.title : "")}
                        {item?.scheduleRules?.isRecurring == true ? <img className="recurring_acceptance" src={recurring_pending} alt="img" /> : ""}

                      </Tooltip>
                    </a>
                    <div className="dashboard_task_card_item_flex">
                      <div className="hosp_loc">
                        <img src={iconDashHospital} alt="iconDashHospital" />
                        <span>{item.department ? <Tooltip title={upperFirst(item.department)} placement="bottom">{upperFirst(item.department)}</Tooltip> : null}</span>
                      </div>
                      <div className="hosp_loc">
                        <img src={iconDashLocation} alt="iconDashLocation" />
                        <span>{item.address ? <Tooltip title={upperFirst(item.address)} placement="bottom">{upperFirst(item.address)}</Tooltip> : null}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard_task_card_item dashboard_task_card_item_two">
                    {item.taskStatus && item.taskStatus.toLowerCase() === 'overdue' ?
                      <p className="update_status_btn_common in_progress_btn update_status_btn_common_overdue_pending ">
                        <span>{upperFirst(item.taskStatus)}</span>
                      </p> : null}
                  </div>
                </div> :
                  <React.Fragment>
                    <div className="dashboard_task_card_item dashboard_task_card_item_one">
                      <a className="dashboard_task_card_item_name" onClick={() => props.detailViewHandler(item._id, 'task')}>
                        <Tooltip title="View Detail" placement="bottom">
                          {upperFirst(item.title ? item.title : "")}
                        </Tooltip>
                        {item?.scheduleRules?.isRecurring == true ? <img className="recurring_acceptance" src={recurring_pending} alt="img" /> : ""}
                      </a>
                      <div className="dashboard_task_card_item_flex">
                        <div className="hosp_loc">
                          <img src={iconDashHospital} alt="iconDashHospital" />
                          <span>{item.department ? <Tooltip title={upperFirst(item.department)} placement="bottom">{upperFirst(item.department)}</Tooltip> : null}</span>
                        </div>
                        <div className="hosp_loc">
                          <img src={iconDashLocation} alt="iconDashLocation" />
                          <span>{item.address ? <Tooltip title={upperFirst(item.address)} placement="bottom">{upperFirst(item.address)}</Tooltip> : null}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard_task_card_item dashboard_task_card_item_two">
                      <p className="update_status_btn_common in_progress_btn update_status_btn_common_overdue_pending ">
                        <span>{item.taskStatus}</span>
                      </p>
                    </div>
                  </React.Fragment>
                }
                <div className="dashboard_task_card_item dashboard_task_card_item_three">
                  <div className="event_acceptance_actions">
                    {item.conflict ?
                      <Tooltip title={`This task conflicts with ${item.conflict_title}`} placement="bottom">
                        <button
                          className={item.conflict ? "warning_btn yellow-btn" : "accept_btn green-btn"}
                          onClick={() => props.onAcceptanceHandler(item, 'accept', props.page, props.pendingAcceptanceList.length)}
                          disabled={props.taskAcceptBtnClicked && item._id === props.btnClickId}
                        >
                          {item.conflict ? <img src={warning_img} alt="warning icon" /> : ""}Accept</button>
                      </Tooltip>
                      : <button
                        className={item.conflict ? "warning_btn yellow-btn" : "accept_btn green-btn"}
                        onClick={() => props.onAcceptanceHandler(item, 'accept', props.page, props.pendingAcceptanceList.length)}
                        disabled={props.taskAcceptBtnClicked && item._id === props.btnClickId}
                      >
                        {item.conflict ? <img src={warning_img} alt="warning icon" /> : ""}Accept</button>}
                    <button
                      className="reject_btn grey-btn"
                      onClick={() => props.onAcceptanceHandler(item, 'reject', props.page, props.pendingAcceptanceList.length)}
                      disabled={props.taskRejectBtnClicked && item._id === props.btnClickId}
                    >Reject</button>
                  </div>
                </div>
                <div className="dashboard_task_card_item dashboard_task_card_item_four">
                  <p className="dashboard_task_card_item_start">
                    {momentTimeZone(item.startDate).tz(props.timeZoneName).format("hh:mm A")}
                  </p>
                  <span className="dashboard_task_card_item_to">To</span>
                  <p className="dashboard_task_card_item_end">
                    {momentTimeZone(item.endDate).tz(props.timeZoneName).format("hh:mm A")}
                  </p>
                </div>
                {item?.scheduleRules?.isRecurring == true ?
                  <div className="dashboard_get_dir_btn_lefts"> <img className="recurring_acceptance" src={recurring_acceptance} alt="img" />
                    <span><Tooltip title={(item?.recurringText)} placement="bottom">{item?.recurringText}</Tooltip></span>
                  </div>
                  :
                  <div className="dashboard_get_dir_btn">
                    <span>{momentTimeZone(item?.startDate).tz(props.timeZoneName).format("MM-DD-YYYY")}</span>
                  </div>
                }
                {item.isEmergency ? (
                  <Tooltip title="Emergency Task" placement="bottom">
                    <div className="error_task">
                      <span style={{ cursor: 'default' }}>!</span>
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div> : item.type.toLowerCase() === 'meeting' || item.type.toLowerCase() === 'personal' ?
                <div className="dashboard_task_card  ">
                  <div className="dashboard_task_card_item pending_acceptance_event">
                    <div className="dashboard_task_event" onClick={() => props.detailViewHandler(item._id, 'event')}>
                      <a className="dashboard_task_card_item_name">
                        <Tooltip title="View Detail" placement="bottom">
                          {item.typeName ? item.typeName : null}
                        </Tooltip></a>
                      {item.type === 'meeting' ?
                        <span className="dashboard_task_event_location">
                          <img src={iconDashLocation} alt="event_location" />
                          <span>{item.address ? item.address : null}</span>
                        </span> : null}
                      <span className="dashboard_task_event_type">({item.type === 'meeting' ? 'WORK' : 'PERSONAL'})</span>
                    </div>
                    <p>
                      <Tooltip placement="topLeft" title={upperFirst(item.title ? item.title : null)} className="event_title">
                        <div>
                          {upperFirst(item.title ? item.title : null)}
                        </div>
                      </Tooltip>
                    </p>
                    <div className="dashboard_task_event_attendees_section">
                      <div className="dashboard_task_event_attendees dashboard_task_event_organizer">
                        <span className="dashboard_task_event_attendee">Organizer :</span>
                        <Popover
                          content={
                            <div className="dashboard_task_event_attendees">
                              <img
                                className="event_attendee_tab_icon event_organizer_icon"
                                src={
                                  item.organizer.profilePath
                                    ? `https://salesc2userprofile.s3.amazonaws.com/${item.organizer.profilePath}`
                                    : userIcon
                                }

                                alt="back"
                              />
                              {item.organizer.name}
                            </div>
                          }
                        >
                          <img
                            className="event_attendee_tab_icon event_organizer_icon"
                            src={
                              item.organizer.profilePath
                                ? `https://salesc2userprofile.s3.amazonaws.com/${item.organizer.profilePath}`
                                : userIcon
                            }
                            alt="back"
                          />
                        </Popover>
                      </div>
                      <div className="dashboard_task_event_attendees">
                        <span className="dashboard_task_event_attendee">Attendees :</span>
                        <span className="dashboard_task_event_attendee_images">
                          {item.attendees && item.attendees.length > 0
                            ?
                            item.attendees.map((names, index) =>

                              item.attendees.length > 0 && index < 3 ? (

                                <Popover
                                  content={
                                    <span>
                                      <img
                                        className="event_attendee_tab_icon"
                                        src={
                                          names.image
                                            ? `https://salesc2userprofile.s3.amazonaws.com/${names.image}`
                                            : userIcon
                                        }
                                        alt="back"
                                      />
                                      {names.name}
                                    </span>
                                  }
                                >
                                  <span>
                                    <img
                                      className="event_attendee_tab_icon event_organizer_icon"
                                      src={
                                        names.image
                                          ? `https://salesc2userprofile.s3.amazonaws.com/${names.image}`
                                          : userIcon
                                      }
                                      alt="back"
                                    />
                                  </span>
                                </Popover>
                              ) : (
                                ""
                              )
                            )
                            : "-"}
                        </span>
                        {item.attendees && item.attendees.length > 3 ?
                          <Popover
                            content={item.attendees.map(
                              (names, index) =>
                                index >= 0 ? (
                                  <p key={names.employeeId}>
                                    <img
                                      className="event_attendee_tab_icon"
                                      src={
                                        names.image
                                          ? `https://salesc2userprofile.s3.amazonaws.com/${names.image}`
                                          : userIcon
                                      }
                                      alt="back"
                                    />
                                    {names.name}</p>
                                ) : (
                                  ""
                                )
                            )}
                          >
                            <span className="dashboard_task_event_attendees_plus" type="primary">
                              +{item.attendees.length - 3} More
                            </span>
                          </Popover>
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="dashboard_task_card_item dashboard_task_card_item_three">
                    <div className="event_acceptance_actions">
                      {item.conflicts ?
                        <Tooltip title={`This event conflicts with ${item.conflict_title}`} placement="bottom">
                          <button
                            className={item.conflicts ? "warning_btn yellow-btn" : "accept_btn green-btn"}
                            onClick={() => props.onAcceptanceHandler(item, 'accept', props.page, props.pendingAcceptanceList.length)}
                          >
                            {item.conflicts ? <img src={warning_img} alt="warning icon" /> : ""}Accept</button>
                        </Tooltip> : <button
                          className={item.conflicts ? "warning_btn yellow-btn" : "accept_btn green-btn"}
                          onClick={() => props.onAcceptanceHandler(item, 'accept', props.page, props.pendingAcceptanceList.length)}
                        >
                          {item.conflicts ? <img src={warning_img} alt="warning icon" /> : ""}Accept</button>}
                      <button
                        className="reject_btn grey-btn"
                        onClick={() => props.onAcceptanceHandler(item, 'reject', props.page, props.pendingAcceptanceList.length)}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                  <div className="dashboard_task_card_item dashboard_task_card_item_four">
                    <p className="dashboard_task_card_item_start"> {momentTimeZone(item.startDate).tz(props.timeZoneName).format("hh:mm A")}</p>
                    <span className="dashboard_task_card_item_to">To</span>
                    <p className="dashboard_task_card_item_end"> {momentTimeZone(item.endDate).tz(props.timeZoneName).format("hh:mm A")}</p>
                  </div>
                  <div className="dashboard_get_dir_btn">
                    <span> {momentTimeZone(item.startDate).tz(props.timeZoneName).format("MM-DD-YYYY")}</span>
                  </div>
                  {item.isEmergency ? (
                    <Tooltip title="Emergency Event" placement="bottom">
                      <div className="error_task">
                        <span style={{ cursor: 'default' }}>!</span>
                      </div>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div> : null
          ))
          : <Empty
            description={
              <span
                style={{
                  color: "#303960",
                }}
              >
                No pending acceptance tasks/events
              </span>
            }
          />}
      </div>
      {props.count && props.count.totalItems.length !== 0 && props.count.totalItems > 5 ?
        <div className="task-list-pagination">
          <div className="mat-pagination">
            <Pagination count={props.count.totalPages}
              page={props.page} onChange={props.onPendingPagination}
              showFirstButton
              showLastButton
            />
          </div>
        </div> : ""}
    </React.Fragment>
  );
};

export default PendingAcceptanceComponent;
