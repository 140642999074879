import * as actionTypes from "./ActionTypes";
import { AxiosInstance, checkTimeZone } from "../../Utilities";
import jwt from "jsonwebtoken";

export const loginRequest = () => {
  return {
    type: actionTypes.LOGIN_REQUEST,
  };
};
export const loginSuccess = (token, data) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: token,
    data: data,
  };
};
export const loginFailure = (error) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    error: error,
  };
};

export const logout = () => {
  localStorage.clear();
  return {
    type: actionTypes.LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      console.log("logout");
    }, expirationTime * 1000);
  };
};

export const LoginActions = (formData) => {
  return (dispatch) => {
    dispatch(loginRequest());
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6001:'authentication',"application/x-www-form-urlencoded; charset=UTF-8")
      .post("/employee/login", formData)
      .then((response) => {
        var loginResponse = response.data;
        if (loginResponse.status === "200") {
          let token = loginResponse.token.split(" ");
          let decode = jwt.decode(token[1]);
          localStorage.setItem("token", token[1]);
          localStorage.setItem("userdata", JSON.stringify(loginResponse.data));
          localStorage.setItem("timeZone", loginResponse.data.timeZone)
          dispatch(loginSuccess(token[1], JSON.stringify(decode)));
          checkTimeZone()
          const userData = JSON.parse(localStorage.getItem("userdata"))
          if(userData.role !== "Super Admin"){
            checkCmsData()
          } else{
            window.location.href = "/dashboard";
          }
        } else {
          dispatch(loginFailure(loginResponse.msg));
        }
      })
      .catch((e) => {
        dispatch(loginFailure("Something went wrong"));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    let decode = jwt.decode(token);
    if (!jwt.decode(token)) {
      dispatch(logout());
    } else {
      let dec = parseInt(decode.exp);
      if (dec <= (Date.now() / 1000).toFixed(0)) {
        dispatch(logout());
      } else {
      const data = localStorage.getItem("data");
      dispatch(loginSuccess(token, data));
      }
    }
  };
};

const checkCmsData = () =>{
  AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?6008:'task',"application/x-www-form-urlencoded; charset=UTF-8")
  .get("/cms")
  .then((response) => {
    if(response.status === 200) {
      if(JSON.stringify(response.data.data[0])){
        localStorage.setItem("cms",JSON.stringify(response.data.data[0]))
      } else{
        localStorage.setItem("cms",JSON.stringify({}))
      }
      window.location.href = "/dashboard";
    } else{
      console.log("Something went wrong")
    }
  })
}
